import React from "react";
import tw from "tailwind-rn";
import { colors, backgroundColors, theme } from "styles/theme";
import { PopupMenu, Tooltip } from "components/elements";
import { IMAGES, DIMENSIONS } from "static/assets/images";
import { Animated } from "react-native";
import { View, Image } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Text, Button, Icon } from "react-native-elements";
import I18NContext from "library/contexts/i18N";
import { AppSettingsContext } from "library/contexts/appSettings";
import UserProfileStorage from "library/storage/userProfile";
import { Entitlements } from "library/utils/entitlements";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import toLower from "lodash/toLower";
import moment from "moment";
import { isAdhocStop } from "components/views/drawer/delivery/routes-dashboard/helper";
import {
  convertToTimeZone,
  timedOrderBuffer,
} from "library/utils/deliveryService";

//eslint-disable-next-line
const ListItem = React.memo(
  ({
    dragOverlay = false,
    enableDND,
    index,
    item,
    itemKey,
    columnsList,
    subColumnsList = [],
    isSelectedRecord,
    isSmallScreen,
    isMobile,
    recordId,
    listType,
    page,
    setRouteMoveAction,
    totalOrdersInRoute,
    enableMoves = true,
    enableMoveStop = false,
    enableAddOrder = false,
    enableRemoveStop = false,
    setRecordId,
    styles,
    menuOptions,
    ordersToHighlight = [],
    routeData,
    loading,
    isClickable = true,
    routeEditAllowed = false,
    localPermissions = {},
  }) => {
    const { messages: locMessages, Localise } = React.useContext(I18NContext);
    const { permissions } = React.useContext(AppSettingsContext);

    const {
      displayOrderStatus,
      statusColor,
      imageName,
      addressVerificationInfo = {},
      orderItemId = "",
      zoneName = "",
      isRushOrder = false,
      receivingMember: { memberCode: shopCode } = {},
      status: orderStatus,
      messageNumber = "",
      stopType = "",
      orderDeliveryTime = "",
    } = item;

    const enableEditStop =
      localPermissions.adhocStops && ["CUSTOM"].includes(stopType);
    const isRouted = listType === "routed";

    const selectedShopPermissions = get(permissions, shopCode, {});

    const {
      status: routeStatus,
      stops = [],
      recommendedStartTime,
      departureTime,
    } = routeData;
    const { deliverByTime, expectedArrivalTime, delayedDelivery } =
      stops[index] || {};

    const {
      isAvsPerformed = "",
      avsConfidence = "",
      isAvsSuggestedAddress = "",
    } = addressVerificationInfo;

    const isAvsVerified =
      isAvsPerformed === "Y" &&
      isAvsSuggestedAddress === "Y" &&
      avsConfidence === "HIGH";

    const { imageWidth = 20, imageHeight = 20 } = DIMENSIONS[imageName] || {};

    const shopTimeZone = UserProfileStorage.getShopTimeZone(shopCode);
    const floristTimeZone = shopTimeZone || "America/Chicago";
    const showAVSAlectIcon = !isAvsVerified && !enableMoves && !isClickable;

    //checking if timed Order will be delayed for delivery
    const isDelayedTimedOrder = () => {
      const isCFROptimiseEnabled = selectedShopPermissions[
        Entitlements.DELIVERY
      ]?.includes(Entitlements.DELIVERY_PAGE.ROUTE_CFR_OPTIMIZATION);

      const isOptimizeEnabled =
        isCFROptimiseEnabled ||
        selectedShopPermissions[Entitlements.DELIVERY]?.includes(
          Entitlements.DELIVERY_PAGE.ROUTE_OPTIMIZATION
        );

      const currentFloristTimewithBuffer = moment(
        convertToTimeZone(moment.utc().format(), floristTimeZone)
      ).add(timedOrderBuffer, "minutes");
      const localDepartureTime = moment(
        convertToTimeZone(departureTime, floristTimeZone)
      );
      const localRecommendedStartTime = moment(
        convertToTimeZone(recommendedStartTime, floristTimeZone)
      );

      const routeStartTime =
        departureTime && routeStatus === "INPROGRESS"
          ? localDepartureTime
          : currentFloristTimewithBuffer;
      if (
        listType === "routed" &&
        !isEmpty(routeData) &&
        ["DRAFT", "PLANNED", "INPROGRESS"].includes(routeStatus) &&
        !["CANCELLED", "DELIVERED"].includes(orderStatus) &&
        (isRushOrder || !!orderDeliveryTime) &&
        deliverByTime
      ) {
        if (
          isOptimizeEnabled &&
          recommendedStartTime &&
          moment(routeStartTime).isAfter(localRecommendedStartTime)
        ) {
          const routes = get(routeData, "routeMap.routes", []);
          const legs = get(routes, "0.legs", []);
          const totalDuration = legs
            .slice(0, index + 1)
            .reduce(
              (accumulator, item) => accumulator + item.duration.value,
              0
            );

          //calculating estimatedDeliveryTime for order if driver starts in florist timeZone
          const estimatedDeliveryTime = moment(routeStartTime)
            .add(totalDuration, "seconds")
            .format();

          //deliverByTime for timedOrder in florist timeZone
          const timedOrderDeliverByTime = moment(
            convertToTimeZone(deliverByTime, floristTimeZone)
          );

          if (moment(estimatedDeliveryTime).isAfter(timedOrderDeliverByTime)) {
            return true;
          }
        }
        if (expectedArrivalTime) {
          if (moment(expectedArrivalTime).isAfter(deliverByTime)) {
            return true;
          }
        }
        return !!delayedDelivery;
      }

      return false;
    };

    const submitAction = (e) => {
      // Future implementation for the add to route meatball actions
      console.log("e :>> ", e);
    };

    const AVSAlertIcon = () => {
      return (
        <View>
          <Tooltip
            text={Localise(
              locMessages,
              "FTD was not able to verify this address. Please confirm address with the florist partner, customer, or recipient"
            )}
            isSmallScreen={isSmallScreen}
            height={60}
          >
            <View
              style={[
                tw("flex justify-center"),
                {
                  marginTop: !isClickable ? 2 : 0,
                  marginRight: !isClickable ? 8 : 0,
                },
              ]}
            >
              <Image
                style={{
                  width: 20,
                  height: 20,
                  marginLeft: 5,
                  marginBottom: 2,
                }}
                resizeMode="cover"
                source={require("static/assets/exclamation-alert-white-border.png")}
              />
            </View>
          </Tooltip>
        </View>
      );
    };

    const OrderPosition = ({ customStyle = {} } = {}) => {
      return (
        <View
          style={{
            backgroundColor:
              listType === "routed"
                ? backgroundColors.primary
                : !isAvsVerified
                ? "#E5175E"
                : "#b50d81",
            width: 20,
            height: 20,
            borderRadius: 20,
            marginVertical: 5,
            justifyContent: "center",
            alignItems: "center",
            ...customStyle,
          }}
        >
          <Text style={{ color: "#fff", fontWeight: "600" }}>{index + 1}</Text>
        </View>
      );
    };

    const OrderContainer = () => {
      return (
        <View style={[tw("flex flex-row items-center"), { paddingBottom: 5 }]}>
          {page === "routeDetails" &&
          !isMobile &&
          (dragOverlay || !enableDND) ? (
            <OrderPosition customStyle={{ marginRight: 10 }} />
          ) : null}
          <View
            style={[
              styles.recordContainer,
              isSelectedRecord && styles.activeRecordContainer,
              !isMobile
                ? { flex: 1 }
                : { flex: isRouted ? 1 : routeEditAllowed ? 0.95 : 1 },
              { backgroundColor: backgroundColors.secondary },
              ordersToHighlight.includes(orderItemId) && {
                borderColor: backgroundColors.primary,
                backgroundColor: "#fafafa",
              },
            ]}
            fsClass="fs-unmask"
          >
            <View
              style={[
                styles.record,
                {
                  borderColor: statusColor || "transparent",
                  alignItems:
                    itemKey === "primaryMemberCodeWithEmailId"
                      ? "center"
                      : "baseline",
                },
                {
                  width: isRouted ? "85%" : "90%",
                },
              ]}
              fsClass="fs-unmask"
            >
              {displayOrderStatus && (
                <View
                  style={[
                    tw("justify-between items-center"),
                    styles.recordItemView,
                    styles.status,
                  ]}
                >
                  <View
                    style={tw(
                      `flex flex-row items-center w-full flex-wrap ${
                        isSmallScreen ? "justify-between" : "justify-start"
                      }`
                    )}
                  >
                    <View style={styles.statusText}>
                      <Text
                        numberOfLines={2}
                        style={{
                          ...styles.recordItem,
                          textAlign: "left",
                          fontSize: 13,
                          fontWeight: "600",
                          width: isMobile || isClickable ? "auto" : 75,
                          color: [
                            "#e5175e",
                            "#b50d81",
                            "#23a587",
                            "#037c77",
                            "#6c0057",
                          ].includes(statusColor)
                            ? ["Designed", "In Design"].includes(
                                displayOrderStatus
                              )
                              ? colors.primary
                              : statusColor
                            : colors.primary,
                        }}
                      >
                        {Localise(locMessages, displayOrderStatus)}
                      </Text>
                    </View>
                    <View style={styles.statusIcon}>
                      <Image
                        style={{
                          width: imageWidth,
                          height: imageHeight,
                        }}
                        containerStyle={{
                          marginHorizontal: isSmallScreen ? 5 : 10,
                          marginVertical: 5,
                        }}
                        source={IMAGES[imageName]}
                      />
                    </View>
                  </View>
                </View>
              )}
              {columnsList.map((column) => {
                return (
                  <React.Fragment key={column}>
                    <View
                      style={[
                        styles.recordItemView,
                        styles[column],
                        {
                          ...(column === "zoneName" &&
                            !zoneName?.length && { display: "none" }),
                          ...(column === "messageNumber" &&
                            zoneName?.length && { width: "10%" }),
                          ...(["recipientName", "displayDeliveryDate"].includes(
                            column
                          ) &&
                            zoneName?.length && { width: "15%" }),
                          ...(!item[column]?.length &&
                            !item[`${subColumnsList[column]}`]?.length &&
                            page === "driverDashboard" && {
                              paddingVertical: 0,
                              display: "none",
                            }),
                        },
                      ]}
                    >
                      <View style={[tw("flex flex-row items-center")]}>
                        <Text
                          numberOfLines={2}
                          style={{
                            ...styles.recordItem,
                            textAlign:
                              isMobile && column === "displayPrice"
                                ? "right"
                                : "left",
                            ...(isSelectedRecord && {
                              color: backgroundColors.primary,
                            }),
                            ...(column === "deliveryInstructions" &&
                              isDelayedTimedOrder() && { color: colors.red }),
                            fontWeight: isSelectedRecord ? "600" : "500",
                          }}
                          title={item[column]}
                          fsClass={
                            toLower(column) === "phone"
                              ? "fs-exclude"
                              : "fs-unmask"
                          }
                        >
                          {item[column]}
                        </Text>
                        {!isAvsVerified &&
                        !enableMoves &&
                        isClickable &&
                        column === "recipientCity" ? (
                          <AVSAlertIcon />
                        ) : null}
                      </View>

                      <Text
                        numberOfLines={2}
                        style={{
                          ...styles.subRecordItem,
                          ...(isSelectedRecord && {
                            color: backgroundColors.primary,
                          }),
                          fontWeight: isSelectedRecord ? "600" : "500",
                          ...(column === "displayDeliveryDate" &&
                            isDelayedTimedOrder() && { color: colors.red }),
                        }}
                      >
                        {item[`${subColumnsList[column]}`]}
                      </Text>
                      {column === "displayDeliveryDate" &&
                      isAdhocStop(stopType) ? (
                        <Text
                          numberOfLines={2}
                          style={{
                            ...styles.subRecordItem,
                            ...(isSelectedRecord && {
                              color: backgroundColors.primary,
                            }),
                            fontWeight: isSelectedRecord ? "600" : "500",
                          }}
                        >
                          {item["deliveredDateTime"]}
                        </Text>
                      ) : null}
                    </View>
                  </React.Fragment>
                );
              })}
            </View>
            <View style={styles.menuContainer}>
              {enableMoves && (
                <>
                  {enableAddOrder &&
                    itemKey === "orderItemId" &&
                    listType === "nonRouted" && (
                      <TouchableOpacity
                        testID="plusOrder"
                        accessibilityLabel="plusOrder"
                        disallowInterruption={true}
                        onPress={() => {
                          setRouteMoveAction({
                            action: "add",
                            id: item[itemKey],
                          });
                        }}
                      >
                        <Button
                          icon={
                            <Icon
                              name={"plus"}
                              size={12}
                              type="font-awesome"
                              iconStyle={{ color: colors.primary }}
                            />
                          }
                          titleStyle={theme.Button.secondaryTitleStyle}
                          buttonStyle={{
                            ...theme.Button.secondaryButtonStyle,
                            paddingVertical: 5,
                            paddingHorizontal: 5,
                            minWidth: 25,
                          }}
                          containerStyle={{
                            margin: 5,
                            justifyContent: "center",
                          }}
                        />
                      </TouchableOpacity>
                    )}
                  {itemKey === "orderItemId" && listType === "routed" && (
                    <>
                      {!loading && (
                        <React.Fragment>
                          {enableEditStop && (
                            <TouchableOpacity
                              testID="editOrder"
                              accessibilityLabel="editOrder"
                              disallowInterruption={true}
                              onPress={() => {
                                setRecordId(item[itemKey]);
                              }}
                            >
                              <Image
                                style={{
                                  width: 17,
                                  height: 25,
                                }}
                                source={IMAGES["edit-dark"]}
                              />
                            </TouchableOpacity>
                          )}
                          {enableRemoveStop ? (
                            <TouchableOpacity
                              testID="minusOrder"
                              accessibilityLabel="minusOrder"
                              disallowInterruption={true}
                              onPress={() => {
                                setRouteMoveAction({
                                  action: "remove",
                                  id: item[itemKey],
                                });
                              }}
                            >
                              <Button
                                icon={
                                  <Icon
                                    name={"minus"}
                                    size={12}
                                    type="font-awesome"
                                    iconStyle={{ color: colors.primary }}
                                  />
                                }
                                titleStyle={theme.Button.secondaryTitleStyle}
                                buttonStyle={{
                                  ...theme.Button.secondaryButtonStyle,
                                  paddingVertical: 5,
                                  paddingHorizontal: 5,
                                  minWidth: 25,
                                }}
                                containerStyle={{
                                  margin: 5,
                                  justifyContent: "center",
                                }}
                              />
                            </TouchableOpacity>
                          ) : null}
                        </React.Fragment>
                      )}
                      {enableMoveStop ? (
                        <>
                          {index !== 0 && (
                            <TouchableOpacity
                              testID="moveUpOrder"
                              accessibilityLabel="moveUpOrder"
                              disallowInterruption={true}
                              onPress={() => {
                                if (index === 0) return;
                                setRouteMoveAction({
                                  action: "moveUp",
                                  index,
                                });
                              }}
                            >
                              <Button
                                icon={
                                  <Icon
                                    name={"arrow-up"}
                                    size={12}
                                    type="font-awesome"
                                    iconStyle={{ color: colors.primary }}
                                  />
                                }
                                buttonStyle={{
                                  ...theme.Button.secondaryButtonStyle,
                                  paddingVertical: 5,
                                  paddingHorizontal: 5,
                                  minWidth: 25,
                                }}
                                containerStyle={{
                                  margin: 5,
                                  justifyContent: "center",
                                }}
                              />
                            </TouchableOpacity>
                          )}
                          {index !== totalOrdersInRoute - 1 && (
                            <TouchableOpacity
                              testID="moveDownOrder"
                              accessibilityLabel="moveDownOrder"
                              disallowInterruption={true}
                              onPress={() => {
                                if (index === totalOrdersInRoute - 1) return;
                                setRouteMoveAction({
                                  action: "moveDown",
                                  index,
                                });
                              }}
                            >
                              <Button
                                icon={
                                  <Icon
                                    name={"arrow-down"}
                                    size={12}
                                    type="font-awesome"
                                    iconStyle={{ color: colors.primary }}
                                  />
                                }
                                buttonStyle={{
                                  ...theme.Button.secondaryButtonStyle,
                                  paddingVertical: 5,
                                  paddingHorizontal: 5,
                                  minWidth: 25,
                                }}
                                containerStyle={{
                                  margin: 5,
                                  justifyContent: "center",
                                }}
                              />
                            </TouchableOpacity>
                          )}
                        </>
                      ) : null}
                    </>
                  )}
                </>
              )}
              {showAVSAlectIcon ? <AVSAlertIcon /> : null}
              {page === "driverDashboard" || isMobile ? (
                <OrderPosition />
              ) : null}
              {menuOptions && menuOptions.length > 0 && (
                <PopupMenu
                  optionsContainerStyle={{
                    width: 150,
                    padding: 5,
                  }}
                  menuOptions={menuOptions}
                  onSelect={submitAction}
                  testID="list_item_menu"
                  accessibilityLabel="list_item_menu"
                >
                  <Image
                    style={{
                      width: 20,
                      height: 20,
                      marginVertical: 5,
                      ...(!isSmallScreen && { marginLeft: 10 }),
                    }}
                    resizeMode="contain"
                    source={IMAGES[`three-dots`]}
                  />
                </PopupMenu>
              )}
            </View>
          </View>
        </View>
      );
    };

    return (
      <Animated.View key={recordId}>
        {isClickable ? (
          <TouchableOpacity
            activeOpacity={0.6}
            onPress={() => {
              setRecordId(item[itemKey]);
            }}
            style={{
              minWidth: 300,
            }}
            testID={`${messageNumber}_${displayOrderStatus}`}
            accessibilityLabel={`${messageNumber}_${displayOrderStatus}`}
          >
            <OrderContainer />
          </TouchableOpacity>
        ) : (
          <View
            style={{
              minWidth: 300,
            }}
            testID={`${messageNumber}_${displayOrderStatus}`}
            accessibilityLabel={`${messageNumber}_${displayOrderStatus}`}
          >
            <OrderContainer />
          </View>
        )}
      </Animated.View>
    );
  }
);

export default ListItem;
