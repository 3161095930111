export const UIConfig = [
  {
    key: "firstChoiceFlorists",
    title: "First Choice",
    displayStatus: "open",
    countDownTimer: true,
  },
  {
    key: "preferredFlorists",
    title: "Preferred Florists",
    displayStatus: "open",
  },
  { key: "doNotUseFlorists", title: "Do Not Use", displayStatus: "open" },
];

export const InitFloristsData = {
  firstChoiceFlorists: [],
  preferredFlorists: [],
  doNotUseFlorists: [],
};

export const ListOfColumns = ["name", "memberCode", "minPrice"];

export const ListOfActions = {
  first_choice: [
    { key: "preferred", label: "Change to 'Preferred'" },
    { key: "normal", label: "Change to 'Normal'" },
    { key: "do_not_use", label: "Change to 'Do Not Use'" },
  ],
  preferred: [
    { key: "first_choice", label: "Change to 'First Choice'" },
    { key: "normal", label: "Change to 'Normal'" },
    { key: "do_not_use", label: "Change to 'Do Not Use'" },
  ],
  do_not_use: [
    { key: "first_choice", label: "Change to 'First Choice'" },
    { key: "preferred", label: "Change to 'Preferred'" },
    { key: "normal", label: "Change to 'Normal'" },
  ],
};

export const PreferencesList = [
  { label: "First Choice", value: "first_choice" },
  { label: "Preferred", value: "preferred" },
  { label: "Normal", value: "normal" },
  { label: "Do Not Use", value: "do_not_use" },
];
