import React from "react";
import { ScrollView, View } from "react-native";
import { useSelector } from "react-redux";

import { setYOffSetScroll } from "library/sagas/ongoing/global-data/slice";
import { selectIsAutoFill } from "library/sagas/ongoing/global-data/selector";

import NavHeader from "../header/sub-header";
import ScreenHeader from "./header";

import { colors, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";

const NonDesktop = ({
  ScreenOne,
  ScreenTwo,
  CustomHeader,
  headerProps,
  rootNavigation,
  navHeaderProps,
  screenOneRef,
  screenTwoRef,
  showSecondScreen,
}) => {
  const isAutoFill = useSelector(selectIsAutoFill);

  return (
    <View style={tw("flex flex-row h-full")} fsClass="fs-unmask">
      <View
        style={[showSecondScreen ? tw("p-0 w-0 h-0 opacity-0") : tw("w-full")]}
      >
        <NavHeader type="primary" rootNavigation={rootNavigation} />
        <ScrollView
          contentContainerStyle={{}}
          style={[
            showSecondScreen ? tw("p-0 w-0 h-0 opacity-0") : tw("w-full"),
          ]}
          ref={screenOneRef}
          keyboardShouldPersistTaps={isAutoFill ? "always" : "handled"}
          onScroll={(e) => {
            isAutoFill && setYOffSetScroll(e.nativeEvent.contentOffset.y);
          }}
        >
          <View style={{ paddingHorizontal: 10 }}>
            {CustomHeader ? (
              <CustomHeader />
            ) : (
              <ScreenHeader {...headerProps} />
            )}
            {ScreenOne}
          </View>
        </ScrollView>
      </View>
      <View
        style={showSecondScreen ? tw("w-full") : tw("p-0 w-0 h-0 opacity-0")}
      >
        <ScrollView
          contentContainerStyle={{}}
          style={[
            {
              backgroundColor: backgroundColors.sideCar,
            },
            showSecondScreen ? tw("w-full") : tw("p-0 w-0 h-0 opacity-0"),
          ]}
          ref={screenTwoRef}
          keyboardShouldPersistTaps={isAutoFill ? "always" : "handled"}
        >
          <View style={{ backgroundColor: colors.secondary }}>
            <NavHeader
              type="secondary"
              {...navHeaderProps}
              rootNavigation={rootNavigation}
            />
            <View style={{ padding: 10 }}>{ScreenTwo}</View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

export default NonDesktop;
