import React, { useEffect } from "react";
import {
  View,
  TouchableOpacity,
  Platform,
  Image,
  Dimensions,
} from "react-native";
import { Text, Button } from "react-native-elements";
import { connect, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { useFormikContext } from "formik";
import tw from "tailwind-rn";
import { fonts, colors, shapes, theme } from "styles/theme";
import { FormField } from "components/elements/forms";
import { CustomModal } from "components/elements";
import {
  selectActiveItemTab,
  selectCustomerOrders,
} from "library/sagas/views/home/drawer/create-order/selector";
import { preloadInitialVals } from "components/views/drawer/create-order/config";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { Tooltip } from "components/elements";

import I18NContext from "library/contexts/i18N";
import {
  AppSettingsContext,
  DeviceContext,
} from "library/contexts/appSettings";
import { Entitlements } from "library/utils/entitlements";
import UserProfileStorage from "library/storage/userProfile";
import { isSameAddress } from "library/utils/createOrder";
import moment from "moment";

const CustomerInsights = ({ setSideCarOpen, global }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const orders = useSelector(selectCustomerOrders) || [];
  const activeItemTab = useSelector(selectActiveItemTab);
  const { values, setValues } = useFormikContext();
  const { permissions } = React.useContext(AppSettingsContext);
  const { isDesktop, isTablet } = React.useContext(DeviceContext);
  const shopNames = UserProfileStorage.getAllShopNames();
  const [customerOrders, setCustomerOrders] = useStateIfMounted([]);
  const [modalVisible, setModalVisibile] = useStateIfMounted(false);
  const orderRef = React.useRef(null);

  const memberCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.CREATE_ORDER in permissions[memberCode])
      memberCodes.push(memberCode);
  });
  const orderChannel =
    Platform.OS === "web" ? (isTablet ? "Tablet" : "Web") : "Mobile";

  const { width: screenWidth } = Dimensions.get("window");

  const {
    customerInfo: { totalAverageSpend = 0 },
    orderItems = [],
  } = values;

  const {
    recipientInfo: formRecipientInfo = {},
    lineItems: formLineItems = [],
  } = orderItems[0];
  const { addressId = "" } = formRecipientInfo;

  const sortCustomerOrders = (a, b) => {
    const dateA = moment(a?.deliveryInfo?.deliveryDate);
    const dateB = moment(b?.deliveryInfo?.deliveryDate);
    return dateB - dateA;
  };

  useEffect(() => {
    if (!addressId) return;
    const filteredOrders = orders.filter((order) => {
      return isSameAddress(
        { ...formRecipientInfo, addressLine2: formRecipientInfo.suite },
        order?.recipientInfo
      );
    });
    setCustomerOrders(filteredOrders.sort(sortCustomerOrders));
  }, [addressId]);

  useEffect(() => {
    setCustomerOrders([...orders].sort(sortCustomerOrders));
  }, [orders]);

  const reOrder = () => {
    const data = preloadInitialVals({
      memberCodes,
      orderChannel,
      preloadOrderDetails: {
        customerInfo: values.customerInfo,
        orderItems: [orderRef.current],
      },
      shopNames,
      global,
      action: "",
      permissions,
      sendingCode: values?.sendingMember,
    });
    const updatedValues = cloneDeep(values);
    updatedValues.orderItems[activeItemTab] = data.orderItems[0];
    setValues(updatedValues);
    setSideCarOpen(isDesktop ? "additional_info" : "");
  };

  const WarningModal = () => {
    const modalContent = {
      content: (
        <Text
          style={{
            ...tw("p-4"),
            fontSize: 15,
            color: colors.highlighter,
            textAlign: "center",
          }}
        >
          {Localise(
            messages,
            "Do you want to apply these order details to the current order and override what you have entered?"
          )}
        </Text>
      ),
      buttons: [
        { type: "secondary", title: Localise(messages, "Nevermind") },
        { type: "primary", title: Localise(messages, "Continue") },
      ],
    };

    return (
      <CustomModal
        modalVisible={modalVisible}
        modalContent={modalContent}
        primaryhandler={() => {
          setModalVisibile(false);
          reOrder();
        }}
        secondaryhandler={() => {
          setModalVisibile(false);
        }}
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: "white" },
        ]}
        modalStyle={
          Platform.OS !== "web"
            ? {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#00000070",
                color: "#FFFFFF",
              }
            : { width: "35%" }
        }
        isReduceBtnSpacing={true}
      ></CustomModal>
    );
  };

  return (
    <View
      style={{
        ...shapes.sectionBorder,
        paddingHorizontal: 20,
        paddingVertical: 15,
        marginTop: 15,
      }}
    >
      <View style={[tw("flex flex-row")]}>
        <Text
          style={{
            ...fonts.sectionHeading,
            ...tw("mr-3"),
            color: colors.highlighter,
            marginBottom: 20,
          }}
        >
          {Localise(messages, "Customer Insights")}
        </Text>
        <TouchableOpacity
          style={{ marginLeft: "auto" }}
          onPress={() => {
            setSideCarOpen("additional_info");
          }}
          testID="close"
          accessibilityLabel="close"
        >
          <Tooltip
            text={Localise(messages, "Close Customer Insights")}
            renderForWebOnly={true}
          >
            <Image
              style={{ width: 20, height: 20 }}
              resizeMode="cover"
              source={require("static/assets/cancelled.png")}
            />
          </Tooltip>
        </TouchableOpacity>
      </View>
      <View>
        <FormField
          name="average_spend"
          label={Localise(messages, "Average Spend")}
          labelStyle={{ fontWeight: "normal" }}
          value={`${parseFloat(totalAverageSpend).toFixed(2)}`}
          containerStyle={{ width: 100, paddingHorizontal: 0 }}
          iconType="material-community"
          iconName="currency-usd"
          iconSize={14}
          inputContainerStyle={{
            padding: 6,
          }}
          leftIconContainerStyle={{ paddingRight: 0 }}
          isPrice={true}
          editable={false}
          grayedOutOnDisable={true}
        />
      </View>
      {customerOrders.length > 0 && (
        <View>
          <Text
            style={{
              marginTop: 10,
              marginBottom: 5,
              color: colors.highlighter,
              fontWeight: "bold",
            }}
          >
            {Localise(messages, "Previous Orders")}
          </Text>
          {customerOrders.slice(0, 5).map((order = {}, id) => {
            const {
              lineItems = [],
              price = [],
              deliveryInfo: { deliveryDate },
              recipientInfo: { firstName = "", lastName = "" },
            } = order;

            const productsTitle = lineItems
              .filter((item) => item.type === "Product")
              ?.map((each) => {
                if (each.productFirstChoiceDescription)
                  return `${each.productFirstChoiceCode} ${each.productFirstChoiceDescription}`;
              })
              .join(",\n");
            const addonsTitle = lineItems
              .filter((item) => item.type === "Addon")
              ?.map((each) => {
                if (each.productFirstChoiceDescription)
                  return `${each.productFirstChoiceCode} ${each.productFirstChoiceDescription}`;
              })
              .join(",\n");
            const name = `${firstName} ${lastName}`;
            const orderTotal = parseFloat(
              price.find((x) => x.name === "orderTotal")?.value
            ).toFixed(2);

            return (
              <View
                key={id}
                style={{
                  ...shapes.sectionBorder,
                  flexDirection: "row",
                  marginVertical: 5,
                  padding: 5,
                  borderRadius: 2,
                }}
              >
                <View
                  style={{ backgroundColor: colors.highlighter, width: 10 }}
                />
                <View style={tw("flex-1 pl-2 pr-1 py-2")}>
                  <View
                    style={tw("flex-row justify-between items-center mb-1")}
                  >
                    <Text style={tw("font-bold")}>
                      {moment(deliveryDate).format("MM/DD/YYYY")}
                    </Text>
                    <Text style={tw("font-bold")}>${orderTotal}</Text>
                  </View>
                  <View
                    style={tw(
                      `${
                        isDesktop && screenWidth >= 1024 && screenWidth < 1280
                          ? "flex-col"
                          : "flex-row"
                      }`
                    )}
                  >
                    <View style={tw("flex-1 pr-1")}>
                      <Text style={tw("mb-1")}>{name}</Text>
                      {productsTitle?.length > 0 && (
                        <Text style={tw("mb-1")}>{productsTitle}</Text>
                      )}
                      {addonsTitle?.length > 0 && (
                        <Text style={tw("mb-1")}>{addonsTitle}</Text>
                      )}
                    </View>
                    <View style={tw("items-end justify-end")}>
                      <Button
                        titleStyle={theme.Button.secondaryTitleStyle}
                        buttonStyle={{
                          ...theme.Button.secondaryButtonStyle,
                          paddingVertical: 5,
                          paddingHorizontal: 10,
                        }}
                        containerStyle={{
                          marginVertical: 0,
                          marginHorizontal: 0,
                          justifyContent: "center",
                          width: 80,
                        }}
                        onPress={() => {
                          orderRef.current = order;
                          const hasLineItems = formLineItems.length > 0;
                          const {
                            firstName = "",
                            lastName = "",
                            addressLine1 = "",
                          } = formRecipientInfo;

                          const hasRecipientInfo =
                            firstName.length > 0 ||
                            lastName.length > 0 ||
                            addressLine1.length > 0;

                          if (hasLineItems || hasRecipientInfo) {
                            setModalVisibile(true);
                          } else {
                            reOrder();
                          }
                        }}
                        title={"Reorder"}
                        testID={"reOrder"}
                        accessibilityLabel={"reOrder"}
                      />
                    </View>
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      )}
      <WarningModal />
    </View>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    global: state.mhq.ongoing.global,
  };
};

export default connect(mapStateToProps, null)(CustomerInsights);
