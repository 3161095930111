export const UpdatePopupRequiredTabs = {
    CREATEORDER: 'Create Order',
    CREATEEVENT: 'Create Event',
    CREATEROUTE: 'Create Route',
    CREATECollection: 'Collection',
    CREATEProduct: 'Product',
    EDITEVENT: 'Edit Event',
    EDITROUTE: 'Edit Route',
    CREATESUBSCRIPTION: 'Create Subscription',
    GIFTCARD: 'Gift Card',
    EDITORDER: 'Edit Order',
    CREATECUSTOMER: 'Create Customer',
    EDITCUSTOMER: 'Customer Directory',
    STAFFMANAGEMENT: 'Staff Management'
};