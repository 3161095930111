import React, { useContext, useEffect } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useRoute } from "@react-navigation/native";

import { AccordionWithBorder } from "components/elements";

import {
  selectShopDetails,
  selectShopPreferences,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { setAutoScroll } from "library/sagas/views/home/drawer/shop-settings/common/slice";

import I18NContext from "library/contexts/i18N";

import { memberEntitlements } from "library/utils/entitlements";

import CardSettings from "./card-settings";
import TaxRules from "./tax-rules";
import ShopHours from "../shop-hours";
import QRCodeShopActions from "./qrcode-shop-actions";
import OrderInvoiceActions from "./order-invoices";
import { DeviceContext } from "library/contexts/appSettings";
import { Divider as RNDivider } from "react-native-elements";
import tw from "tailwind-rn";
import UserProfileStorage from "library/storage/userProfile";

const ShopDetails = ({
  shopCodes,
  error,
  setCardSettingsFormikData,
  setFileInfo,
  fileInfo,
  loadingKey,
  saveSettings,
  screenOneRef,
}) => {
  const dispatch = useDispatch();
  const shopDetails = useSelector(selectShopDetails);
  const shopSettings = useSelector(selectShopPreferences);
  const { messages, Localise } = useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const { params: { openPrintPreference = false } = {} } = useRoute();

  const isEntitlementExpired =
    shopDetails?.memberEntitlements?.message?.includes("expired") ?? false;

  const entitlement = shopDetails?.memberEntitlements?.memberEntitlementName;

  const isMOLOnlyUser = entitlement === memberEntitlements.MERCURY_ONLINE;

  const isCoreConnectUser =
    entitlement?.includes(memberEntitlements.MHQ_CONN) ||
    entitlement?.includes(memberEntitlements.MHQ_CORE) ||
    isEntitlementExpired;

  const isAdminUser = UserProfileStorage.getRole() === "ADMIN";

  const isNonCoreMember =
    entitlement?.includes(memberEntitlements.MHQ_STANDARD) ||
    entitlement?.includes(memberEntitlements.MHQ_PREMIUM) ||
    entitlement?.includes(memberEntitlements.MHQ_ESSENTIALS);

  const showTaxSettings =
    isNonCoreMember && !isEntitlementExpired && isAdminUser;

  useEffect(() => {
    return () => {
      screenOneRef?.current?.scrollTo({ x: 0, y: 0 });
    };
  }, []);

  return (
    <>
      <ShopHours saveSettings={saveSettings} />

      {!isMOLOnlyUser && (
        <>
          {isDesktop && (
            <AccordionWithBorder
              title={Localise(messages, "Printing Preferences")}
              defaultOpen={openPrintPreference}
            >
              <CardSettings
                error={error}
                shopSettings={shopSettings}
                shopDetails={shopDetails}
                filteredShopCodes={shopCodes}
                setCardSettingsFormikData={setCardSettingsFormikData}
                fileInfo={fileInfo}
                setFileInfo={setFileInfo}
                saveSettings={saveSettings}
                loadingKey={loadingKey}
              />
              {!isCoreConnectUser && (
                <View
                  onLayout={(event) => {
                    if (!openPrintPreference) return;
                    const { y } = event.nativeEvent.layout;
                    console.log(event.nativeEvent.layout);
                    dispatch(
                      setAutoScroll({
                        value: true,
                        scrollYTo: y + 120,
                        screen: 1,
                      })
                    );
                  }}
                >
                  <RNDivider style={tw("my-6")} />
                  <QRCodeShopActions
                    shopSettings={shopSettings}
                    loadingKey={loadingKey}
                    saveSettings={saveSettings}
                    openPrintPreference={openPrintPreference}
                  />

                  <RNDivider style={tw("my-6")} />

                  <OrderInvoiceActions
                    shopSettings={shopSettings}
                    loadingKey={loadingKey}
                    saveSettings={saveSettings}
                  />
                </View>
              )}
            </AccordionWithBorder>
          )}
        </>
      )}

      {showTaxSettings && <TaxRules />}
    </>
  );
};

export default ShopDetails;
