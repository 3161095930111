import React, { useEffect } from "react";
import { ScrollView, View } from "react-native";
import tw from "tailwind-rn";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import SplitScreen from "components/containers/split-screen";

import { useSelector, useDispatch } from "react-redux";

import { TabBar } from "components/elements";
import ScreenHeader from "components/containers/header/screen";

import SubHeader from "components/containers/header/sub-header";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { TermsandConditionsContent } from "../../login/terms-conditions";
import { DSTermsAndConditionsContent } from "../../login/delivery-service-terms-conditions";
import { PrivacyPolicyContent } from "../../login/privacy-policy";
import I18NContext from "library/contexts/i18N";
import UserAuthContext from "library/contexts/userAuth";
import TrainingMaterials from "./training-materials";
import ContactUs from "./contact-us";
import ReleaseNotes from "./release-notes";

import {
  setActiveHelpTab,
  setActiveHelpSubTab,
} from "library/sagas/views/home/drawer/help/slice";

import {
  selectHelpPageTabNames,
  selectHelpPageSubTabNames,
  selectActiveHelpTab,
  selectActiveHelpSubTab,
} from "library/sagas/views/home/drawer/help/selector";

const Help = () => {
  const dispatch = useDispatch();

  const [showPreview, setShowPreview] = useStateIfMounted(false);
  const [content, setContent] = useStateIfMounted("");
  const {
    isDesktop,
    //  isTablet, isMobile
  } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const { proxyUser } = React.useContext(UserProfileContext);

  const { messages, Localise } = React.useContext(I18NContext);
  const { authToken } = React.useContext(UserAuthContext);

  const currentTab = useSelector(selectActiveHelpTab);
  const tabNames = useSelector(selectHelpPageTabNames);

  const currentSubTab = useSelector(selectActiveHelpSubTab);
  const subTabNames = useSelector(selectHelpPageSubTabNames);

  const setCurrentTab = (name) => {
    dispatch(setActiveHelpTab(name));
  };

  const setCurrentSubTab = (name) => {
    dispatch(setActiveHelpSubTab(name));
  };

  useEffect(() => {
    setShowPreview(
      currentTab === "training-materials" && currentSubTab === " mhq-sub-tab"
    );
  }, [currentTab, currentSubTab]);

  return (
    <SplitScreen
      showTwoScreens={showPreview}
      subHeaderExists={isSmallScreen && !!authToken}
      proxyHeaderExists={proxyUser ? true : false}
      removePaddingHorizontal={true}
    >
      <View>
        {isSmallScreen && <SubHeader />}

        <View>
          <ScreenHeader
            title={Localise(messages, "Help")}
            settingsRoute={{
              page: "shop-settings",
            }}
            setShowQRScan={false}
            // createRoute={createRoute}
            // sideCarIdentifier={recordId}
            // setSideCarIdentifier={setRecordId}
            // recordAction={recordAction}
            // setRecordAction={setRecordAction}
            // listingType={listingType}
          />

          <TabBar
            containerStyle={tw("w-full")}
            tabNames={tabNames}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            style={{
              paddingHorizontal: 7,
            }}
          />
          {currentTab !== "contact-us" && (
            <TabBar
              containerStyle={tw("w-full")}
              tabNames={subTabNames}
              currentTab={currentSubTab}
              setCurrentTab={setCurrentSubTab}
              showBorder={false}
              style={{
                marginTop: -5,
                paddingHorizontal: 7,
              }}
              // siblings={() => <TabBarSiblings shopCodes={shopCodes} />}
            />
          )}
        </View>

        <ScrollView contentContainerStyle={{ padding: isDesktop ? 5 : 0 }}>
          {currentTab === "contact-us" && <ContactUs />}
          {currentTab === "release-notes" && (
            <ReleaseNotes currentSubTab={currentSubTab} />
          )}
          {currentTab === "training-materials" && (
            <TrainingMaterials
              setShowPreview={setShowPreview}
              setContent={setContent}
            />
          )}
        </ScrollView>
      </View>
      <View>
        {isSmallScreen && (
          <SubHeader
            title={`Help`}
            onTrigger={() => {
              setShowPreview(false);
            }}
          />
        )}
        <View>
          <View>{content === "terms" && <TermsandConditionsContent />}</View>
          <View>
            {content === "DSTerms" && <DSTermsAndConditionsContent />}
          </View>
          <View>{content === "privacy" && <PrivacyPolicyContent />}</View>
        </View>
      </View>
    </SplitScreen>
  );
};

export default Help;
