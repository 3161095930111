import React, { useState } from "react";
import { View, Platform, Linking } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "react-native-elements";

import { ToasterHandler } from "components/elements";
import { ConfirmModal, SecondaryButton } from "components/wrappers";

import I18NContext from "library/contexts/i18N";
import UserProfileStorage from "library/storage/userProfile";
import { deleteMiddlewareData } from "library/sagas/views/home/drawer/ftd-admin/mol-settings/slice";
import {
  selectApiResponse,
  selectScheduledContentScreen,
} from "library/sagas/views/home/drawer/ftd-admin/mol-settings/selector";
import {
  selectShopCode,
  selectAllowSyndicate,
} from "library/sagas/views/home/drawer/mercury-online/selector";

import { fonts, colors } from "styles/theme";
import tw from "tailwind-rn";

const OverrideHeader = ({
  component,
  title,
  actualContext,
  link,
  context,
  assetId,
  scheduledContent,
}) => {
  const dispatch = useDispatch();
  const allowSyndicate = useSelector(selectAllowSyndicate);
  const shopCode = useSelector(selectShopCode);
  const { status } = useSelector(selectApiResponse);
  const { components: scheduledContentComponents = [], activeTab } =
    useSelector(selectScheduledContentScreen);

  const { messages, Localise } = React.useContext(I18NContext);
  const [showPopup, setShowPopup] = useState(false);

  const openURL = (uri) => {
    const { memberCodes = [] } = UserProfileStorage.getAuthGroup();
    const code = shopCode === "all" ? memberCodes[0] : shopCode;

    const { shopify_store_url: shopifyAdmin } =
      UserProfileStorage.getShopPreferences(code);
    let parsedValues = {};
    try {
      parsedValues = JSON.parse(scheduledContent);
    } catch {
      //DO Nothing
    }
    const link = `${shopifyAdmin}${uri}${
      activeTab === "scheduled-content"
        ? `?preview_date=${parsedValues?.startDate}`
        : ""
    }`;

    Platform.OS === "web" ? window.open(link) : Linking.openURL(link);
  };

  const onDelete = () => {
    dispatch(
      deleteMiddlewareData({
        params: {
          component,
          context,
          assetId,
        },
        resolve: () => {
          ToasterHandler(
            "looks good",
            Localise(
              messages,
              `${title} has been reverted to FTD Global Content.`
            )
          );
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
        },
      })
    );
  };

  return (
    <View style={tw("py-2")}>
      {component !== "brandSelectorConfig" &&
        (actualContext === "global" ? (
          <>
            {activeTab === "default-content" && (
              <>
                <Text
                  style={{
                    ...fonts.heading3,
                    fontWeight: "normal",
                    fontSize: 14,
                    marginRight: 5,
                    padding: 4,
                  }}
                >
                  {Localise(
                    messages,
                    "FTD generates this content for you, but you can customize it as you like."
                  )}
                </Text>
              </>
            )}
          </>
        ) : (
          activeTab === "default-content" && (
            <View style={tw("flex-row items-center justify-start")}>
              <Text
                style={{
                  ...fonts.heading3,
                  fontWeight: "normal",
                  fontSize: 14,
                  marginRight: 5,
                }}
              >
                {Localise(messages, "You have customized this content.")}
              </Text>
              <SecondaryButton
                title={"Revert to Original"}
                action={
                  allowSyndicate ? () => setShowPopup(!showPopup) : onDelete
                }
                style={{ minWidth: 120 }}
                loading={status === "progress"}
                loadingProps={{ color: colors.primary2 }}
              />
            </View>
          )
        ))}
      {link ? (
        <View style={tw("flex flex-row flex-1 flex-wrap my-3")}>
          <Text style={fonts.heading4}>
            {Localise(
              messages,
              `Click to ${
                scheduledContentComponents.includes(component)
                  ? `preview`
                  : `see`
              } the`
            )}{" "}
          </Text>
          <Text
            style={fonts.link1}
            onPress={() => openURL(link)}
            numberOfLines={1}
            ellipsizeMode={"tail"}
          >
            {title}
          </Text>
          <Text style={fonts.heading4}>
            {" "}
            {Localise(messages, "on your Shopify website")}
          </Text>
          {activeTab === "scheduled-content" && (
            <Text style={fonts.heading4}>
              {" "}
              {Localise(messages, "for the below scheduled dates")}
            </Text>
          )}
        </View>
      ) : null}
      <ConfirmModal
        modalVisible={!!showPopup}
        handlePrimary={() => {
          onDelete();
          setShowPopup(!showPopup);
        }}
        handleSecondary={() => {
          setShowPopup(!showPopup);
        }}
        data={{
          modal: {
            content: `This change will apply to All shops and will override any local shop changes.`,
          },
        }}
      />
    </View>
  );
};

export default OverrideHeader;
