import React from "react";
import { View, Text, Platform } from "react-native";
import { Button, Image } from "react-native-elements";
import * as FileSystem from "expo-file-system";
import * as DocumentPicker from "expo-document-picker";

import { Tooltip } from "components/elements";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

import { fonts, theme, colors } from "styles/theme";
import tw from "tailwind-rn";

const ImagePicker = ({ value, setValue, title = "", toolTipTitle = "" }) => {
  const { isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({});

    if (Platform.OS !== "web") {
      const base64Image = await FileSystem.readAsStringAsync(result.uri, {
        encoding: "base64",
      });

      result.uri = base64Image.startsWith("data:image")
        ? base64Image
        : `data:image/png;base64,${base64Image}`;
    }
    if (result.type == "success") {
      setValue(result);
    }
  };

  return (
    <View style={[tw(`flex flex-col flex-wrap mr-5`)]}>
      <Text style={{ ...fonts.heading4, paddingVertical: 5 }}>{title}</Text>
      <View style={tw(`flex flex-col flex-wrap`)}>
        <View style={tw("pr-2")}>
          {value ? (
            <Tooltip text={toolTipTitle} height={isMobile ? null : 40}>
              <Image
                style={{
                  height: 200,
                  width: 200,
                  borderWidth: 0.5,
                  borderColor: colors.light,
                }}
                containerStyle={{ margin: "auto" }}
                source={{ uri: value }}
                resizeMode={"contain"}
              />
            </Tooltip>
          ) : (
            <Text
              style={[
                tw("flex flex-row justify-center items-center"),
                {
                  borderWidth: 1,
                  borderColor: "#CCC",
                  height: 200,
                  width: 250,
                },
              ]}>
              {title == "Standard Logo" && "Choose Logo (130x130px)"}
              {title == "Standard Banner" && "Choose Banner Logo (380x120px)"}
              {title == "" && "No Image"}
            </Text>
          )}
        </View>
        <View style={tw("flex flex-row items-end mt-2")}>
          <Button
            testID={"choose_image"}
            accessibilityLabel="choose_image"
            titleStyle={{
              ...theme.Button.secondaryTitleStyle,
              ...fonts.heading5,
            }}
            buttonStyle={{
              ...theme.Button.secondaryButtonStyle,
              paddingVertical: 3,
            }}
            containerStyle={{
              margin: 0,
              marginTop: 0,
              paddingLeft: 0,
              width: "100%",
              maxWidth: 100,
            }}
            title={Localise(messages, value != "" ? "Replace" : "Upload")}
            onPress={pickDocument}
          />
          {value ? (
            <Tooltip text={Localise(messages, "Delete")} renderForWebOnly = {true}>
              <Image
                onPress={() => {
                  setValue("");
                }}
                style={{
                  width: 25,
                  height: 25,
                  marginLeft: 5,
                  marginTop: 5,
                }}
                source={require("static/assets/trash-can.png")}
              />
            </Tooltip>
          ) : null}
        </View>
      </View>
    </View>
  );
};

export default ImagePicker;
