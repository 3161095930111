import React from "react";
import { Image } from "react-native-elements";
import { View } from "react-native";
import LabelField from "../label-field";
import { Tooltip } from "components/elements";
import tw from "tailwind-rn";
import { fonts } from "styles/theme";

const defaultLabelStyles = {
  ...fonts.heading4,
  marginRight: 8,
  paddingLeft: 5,
  paddingBottom: 0,
};

const LabelWithHelpIcon = (props) => {
  const {
    labelText = "",
    tooltipText = "",
    labelStyles = {},
    imageSize = 15,
  } = props;
  return (
    <View style={[tw("flex flex-row items-center"), { marginBottom: 6 }]}>
      <LabelField
        text={labelText}
        style={{ ...defaultLabelStyles, ...labelStyles }}
        numberOfLines={2}
      />
      <Tooltip text={tooltipText} height={40}>
        <Image
          style={{
            width: imageSize,
            height: imageSize,
          }}
          source={require("static/assets/help.png")}
        />
      </Tooltip>
    </View>
  );
};

export default LabelWithHelpIcon;
