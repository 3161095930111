import find from "lodash/find";
import uniq from "lodash/uniq";
import get from "lodash/get";
import toUpper from "lodash/toUpper";
import moment from "moment-timezone";

import { Platform } from "react-native";
import Storage from "library/utils/storage";

const UserContextCache = new Storage("UserContextCache", {
  enableLocal: false,
  enableSession: Platform.OS === "web" ? true : false,
  throttleWait: 0,
  //ttl: 24 * 60 * 60 * 1000, //PAR-2635 - issue when session is more than 24 hours
});

const load = () => UserContextCache.load();

const getUser = () => UserContextCache.get("userProfile") || {};

const setUser = (profile) => {
  UserContextCache.put("userProfile", profile);
  setRole(get(profile, "authGroups.0.roles.0"));
};

const getAuthGroup = () => {
  const authGroup = UserContextCache.get("userAuthGroup");
  if (!authGroup) {
    return get(getUser(), "authGroups.0", {});
  }
  return authGroup;
};

const setAuthGroup = (authGroup) => {
  UserContextCache.put("userAuthGroup", authGroup);
};

const getRole = () => {
  const role = UserContextCache.get("userRole");
  if (!role) {
    return get(getUser(), "authGroups.0.roles.0");
  }
  return role;
};

const getUserRoles = () => {
  return get(getUser(), "authGroups.0.roles", []);
};

const setRole = (role) => {
  const authGroups = get(getUser(), "authGroups", []);
  const authGroup = authGroups.find((e) => e.roles.includes(role));
  if (authGroup) {
    if (role !== "ADMIN" && authGroup?.memberCodes) {
      setAuthGroup(authGroup);
      setProfileMemberCodes(authGroup.memberCodes);
    }

    if (role === "ADMIN" && authGroup?.shopGroups.length) {
      setAuthGroup(authGroup);
      setProfileMemberCodes(getAccountMemberCodes());
    }

    UserContextCache.put("userRole", role);
  }
};

const setOperatorRole = (role) => {
  UserContextCache.put("operatorRole", role);
};

const getOperatorRole = () => {
  const operatorRole = UserContextCache.get("operatorRole");
  return operatorRole;
};

const getGroupMemberCodes = () => UserContextCache.get("groupMemberCodes");
const setGroupMemberCodes = (memberCodes) =>
  UserContextCache.put("groupMemberCodes", memberCodes);

const getProfileMemberCodes = () => {
  const memberCodes = UserContextCache.get("userMemberCodes");
  if (!memberCodes) {
    return get(getUser(), "authGroups.0.memberCodes", []);
  }
  return uniq(memberCodes);
};

const setProfileMemberCodes = (memberCodes) =>
  UserContextCache.put("userMemberCodes", memberCodes);

const setAccountMemberCodes = (memberCodes) => {
  UserContextCache.put("accountMemberCodes", memberCodes);

  // In order to get all member codes in all screens
  if (getRole() === "ADMIN") {
    setProfileMemberCodes(memberCodes);
  }
};

const getAccountMemberCodes = () => UserContextCache.get("accountMemberCodes");

const getShopName = (memberCode) =>
  get(UserContextCache.get("shopNames"), memberCode, "");

const setShopName = (memberCode, shopName) => {
  UserContextCache.put("shopNames", {
    ...getAllShopNames(),
    [memberCode]: shopName,
  });
};

const setShopEmail = (memberCode, shopEmail) => {
  UserContextCache.put("shopEmail", {
    ...(UserContextCache.get("shopEmail") || {}),
    [memberCode]: shopEmail,
  });
};

const setShopWebsites = (memberCode, shopEmail) => {
  UserContextCache.put("shopWebsites", {
    ...(UserContextCache.get("shopWebsites") || []),
    [memberCode]: shopEmail,
  });
};

const setShopCurrency = (memberCode, currency) => {
  UserContextCache.put("shopCurrency", {
    ...(UserContextCache.get("shopCurrency") || {}),
    [memberCode]: currency,
  });
};

const getShopEmail = (memberCode) =>
  get(UserContextCache.get("shopEmail"), memberCode, "");

const getShopPhoneNumber = (memberCode) =>
  get(UserContextCache.get("shopPhone"), memberCode, "");

const getShopWebsites = (memberCode) =>
  get(UserContextCache.get("shopWebsites"), memberCode, []);

const getShopCurrency = (memberCode) =>
  get(UserContextCache.get("shopCurrency"), memberCode, {});

const setShopPhoneNumber = (memberCode, shopPhone) => {
  UserContextCache.put("shopPhone", {
    ...getAllShopPhoneNumbers(),
    [memberCode]: shopPhone,
  });
};

const getAllShopPhoneNumbers = () => UserContextCache.get("shopPhone") || {};

const setAllShopPhoneNumbers = (memberShopNameMap) => {
  UserContextCache.put("shopPhone", memberShopNameMap);
};

const getSuspendedMembers = () => UserContextCache.get("suspendedMembers");

const setSuspendedMembers = (suspendedMembers) => {
  UserContextCache.put("suspendedMembers", suspendedMembers);
};

const getFloristTypes = () => UserContextCache.get("floristTypes");

const setFloristTypes = (floristTypes) => {
  UserContextCache.put("floristTypes", floristTypes);
};

const getAllShopNames = () => UserContextCache.get("shopNames") || {};

const setAllShopNames = (memberShopNameMap) => {
  UserContextCache.put("shopNames", memberShopNameMap);
};

const getShopPreferences = (memberCode) =>
  get(UserContextCache.get("shopPreferences"), memberCode, {});

const setShopPreferences = (memberCode, preferences) => {
  UserContextCache.put("shopPreferences", {
    ...getAllShopPreferences(),
    [memberCode]: {
      ...getShopPreferences(memberCode),
      ...preferences,
    },
  });
};

const getAllShopPreferences = () =>
  UserContextCache.get("shopPreferences") || {};

const setAllShopPreferences = (memberPreferencesMap) => {
  UserContextCache.put("shopPreferences", memberPreferencesMap);
};

const clear = () => UserContextCache.clear();

const setProxyUser = (proxy) => {
  proxy
    ? UserContextCache.put("proxyUser", proxy)
    : UserContextCache.delete("proxyUser");
};

const getProxyUser = () => UserContextCache.get("proxyUser");

const setAutoRouteId = (autoRouteId) => {
  UserContextCache.put("autoRouteId", autoRouteId);
};

const getAutoRouteId = () => {
  return UserContextCache.get("autoRouteId");
};

const getUserType = () => {
  const role = getRole();
  const isFTDUser =
    role &&
    ["FTD_ADMIN", "FTD_POWER_USER", "FTD_VIEW_ONLY", "FTD_CSR"].includes(
      toUpper(role)
    );

  return isFTDUser ? "FTD" : "FLORIST";
};

const timeZonesMap = moment.tz.names().map((zone) => {
  return {
    [moment.tz(zone).zoneAbbr()]: zone,
  };
});

const getShopTimeZone = (memberCode) =>
  get(UserContextCache.get("shopTimeZone"), memberCode, "");

const setShopTimeZone = (
  memberCode,
  floristTimeZone,
  floristTimeZoneData = ""
) => {
  const timeZone = floristTimeZoneData
    ? floristTimeZoneData
    : find(timeZonesMap, floristTimeZone)[floristTimeZone] || "America/Chicago";

  UserContextCache.put("shopTimeZone", {
    ...getAllShopTimeZones(),
    [memberCode]: timeZone,
  });
};

const getAllShopTimeZones = () => UserContextCache.get("shopTimeZone") || {};

const setAllShopTimeZones = (memberShopNameMap) => {
  UserContextCache.put("shopTimeZone", memberShopNameMap);
};

const getShopHours = (memberCode) =>
  get(UserContextCache.get("shopHours"), memberCode, []);

const setShopHours = (memberCode, openHours) => {
  UserContextCache.put("shopHours", {
    ...getAllShopHours(),
    [memberCode]: openHours,
  });
};

const getAllShopHours = () => UserContextCache.get("shopHours") || {};

const setShopLocation = (memberCode, shopAddress) => {
  UserContextCache.put("shopLocation", {
    ...getAllShopLocation(),
    [memberCode]: shopAddress,
  });
};
const getShopLocation = (memberCode) =>
  get(UserContextCache.get("shopLocation"), memberCode, {});

const getAllShopLocation = () => UserContextCache.get("shopLocation") || [];

const setAllShopHours = (memberShopNameMap) => {
  UserContextCache.put("shopHours", memberShopNameMap);
};

const setAllShopGroups = (shopGroups) =>
  UserContextCache.put("shopGroups", shopGroups);

const getAllShopGroups = () => UserContextCache.get("shopGroups") || [];

const setSelectedShopGroup = (shopGroupName) => {
  UserContextCache.put("selectedShopGroup", shopGroupName);
};

const getSelectedShopGroup = () =>
  UserContextCache.get("selectedShopGroup") || "";

const setSelectedShopCode = (shopCode) => {
  UserContextCache.put("selectedShopCode", shopCode);
};

const getSelectedShopCode = () =>
  UserContextCache.get("selectedShopCode") || "";

const setRouteShopList = (shopCode) => {
  UserContextCache.put("routeShopList", shopCode);
};
const getRouteShopList = () => UserContextCache.get("routeShopList") || "";

const setRouteDeliveryDate = (deliveryDate) => {
  UserContextCache.put("routeDeliveryDate", deliveryDate);
};

const getRouteDeliveryDate = () =>
  UserContextCache.get("routeDeliveryDate") || moment().format("YYYY-MM-DD");

const setSelectedRouteId = (routeId) => {
  UserContextCache.put("selectedRouteId", routeId);
};

const getSelectedRouteId = () => UserContextCache.get("selectedRouteId") || "";

const setMemberEntitlement = (memberCode, entitlement) =>
  UserContextCache.put("memberEntitlement", {
    ...UserContextCache.get("memberEntitlement"),
    [memberCode]: entitlement,
  });

const getEntitlementAllMembers = (entitlement = "") => {
  const entitlememtObj = UserContextCache.get("memberEntitlement");

  let memberCodes = [];

  if (entitlememtObj) {
    Object.keys(entitlememtObj).forEach((key) => {
      if (
        entitlememtObj[key]?.toLowerCase().includes(entitlement.toLowerCase())
      ) {
        memberCodes.push(key);
      }
    });
  }

  return memberCodes;
};

const getMemberEntitlement = (memberCode) =>
  get(UserContextCache.get("memberEntitlement"), memberCode, "");

const setCurrentTab = (tabKey) => {
  UserContextCache.put("currentTabKey", tabKey);
};

const getCurrentTab = () => {
  return UserContextCache.get("currentTabKey");
};

const setJobsList = (jobId) => {
  UserContextCache.put("jobsList", jobId);
};
const getJobsList = () => {
  return UserContextCache.get("jobsList");
};
const setFloristTechnology = (memberCode, technology) =>
  UserContextCache.put("floristTechnology", {
    ...UserContextCache.get("floristTechnology"),
    [memberCode]: technology,
  });

const getFloristTechnology = (memberCode) =>
  get(UserContextCache.get("floristTechnology"), memberCode, "");

const setCreditRequest = (data) => {
  UserContextCache.put("creditRequestData", data);
};

const setTrackingId = (id = "") => {
  return UserContextCache.put("TrackingId", id);
};

const getTrackingId = () => {
  return UserContextCache.get("TrackingId") || "";
};

const setEmailContentId = (id = "") => {
  return UserContextCache.put("EmailContentId", id);
};

const getEmailContentId = () => {
  return UserContextCache.get("EmailContentId") || "";
};

export default {
  getUser,
  setUser,
  getRole,
  getUserRoles,
  setRole,
  setOperatorRole,
  getOperatorRole,
  getAuthGroup,
  setAuthGroup,
  getProfileMemberCodes,
  setProfileMemberCodes,
  getAccountMemberCodes,
  setAccountMemberCodes,
  getShopName,
  setShopName,
  setSuspendedMembers,
  getSuspendedMembers,
  getFloristTypes,
  setFloristTypes,
  getAllShopNames,
  setAllShopNames,
  getShopPreferences,
  setShopPreferences,
  getAllShopPreferences,
  setAllShopPreferences,
  getShopTimeZone,
  setShopTimeZone,
  getShopHours,
  setShopHours,
  getAllShopLocation,
  setShopLocation,
  getShopLocation,
  getAllShopTimeZones,
  setAllShopTimeZones,
  getAllShopHours,
  setAllShopHours,
  getAllShopGroups,
  setAllShopGroups,
  getSelectedShopGroup,
  setSelectedShopGroup,
  getSelectedShopCode,
  setSelectedShopCode,
  setRouteDeliveryDate,
  getRouteDeliveryDate,
  setSelectedRouteId,
  getSelectedRouteId,
  getShopPhoneNumber,
  setShopPhoneNumber,
  setShopEmail,
  getShopEmail,
  setShopWebsites,
  getShopWebsites,
  getAllShopPhoneNumbers,
  setAllShopPhoneNumbers,
  setMemberEntitlement,
  getMemberEntitlement,
  setProxyUser,
  getProxyUser,
  getUserType,
  clear,
  load,
  setAutoRouteId,
  getAutoRouteId,
  getCurrentTab,
  setCurrentTab,
  setJobsList,
  getJobsList,
  getFloristTechnology,
  setFloristTechnology,
  setCreditRequest,
  getEntitlementAllMembers,
  getRouteShopList,
  setRouteShopList,
  setTrackingId,
  getTrackingId,
  setShopCurrency,
  getShopCurrency,
  setEmailContentId,
  getEmailContentId,
  setGroupMemberCodes,
  getGroupMemberCodes,
};
