import React from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";

import tw from "tailwind-rn";

import { fonts, shapes } from "styles/theme";

import { FormField } from "components/elements/forms";

import I18NContext from "library/contexts/i18N";

//eslint-disable-next-line
export const ExternalPartner = React.memo(
  ({
    index,
    title,
    orderInfo,
    canModifyOrder = true,
    isMultiOrderEligible,
  }) => {
    const { messages, Localise } = React.useContext(I18NContext);

    const {
      deliveryInfo: { deliveryMethod = "" },
    } = orderInfo;

    const path = `orderItems.${index}`;

    const isPhoneOut = deliveryMethod === "PHONE_OUT";

    return isPhoneOut ? (
      <View
        style={[
          {
            marginTop: 15,
            opacity: canModifyOrder ? 1 : 0.7,
          },
          !isMultiOrderEligible && { ...shapes.sectionBorder },
        ]}
        pointerEvents={canModifyOrder ? "auto" : "none"}
      >
        <View style={[tw("flex flex-row pb-3"), { paddingHorizontal: 5 }]}>
          <Text style={[fonts.sectionHeading]}>
            {Localise(messages, title)}
          </Text>
        </View>

        <FormField
          autoCapitalize="none"
          autoCorrect={false}
          name="externalPartnerBusinessName"
          label={"Florist Partner Name"}
          labelStyle={{ fontWeight: "normal" }}
          placeholder={"Enter Florist Partner Name"}
          path={path}
          editable={true}
        />
      </View>
    ) : null;
  }
);

export default ExternalPartner;
