/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useSelector } from "react-redux";
import { Text } from "react-native-elements";
import { View, Image } from "react-native";
import { Accordion } from "components/elements";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import tw from "tailwind-rn";
import { colors, backgroundColors, theme } from "styles/theme";

import IMAGES from "static/assets/images";

export const AccordionWithBorder = ({
  title,
  children,
  subTitleSelector,
  subtitle,
  defaultOpen = false,
  overrideZindex = 0,
  handleOnPress = () => {},
  titleStyle = {},
  labelStyle = {},
  subtitleStyle = {},
}) => {
  const { isMobile } = React.useContext(DeviceContext);
  
  const subTitle =
    subtitle || (subTitleSelector ? useSelector(subTitleSelector) : "");
  const { messages, Localise } = React.useContext(I18NContext);
  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
        opacity: 1,
        marginBottom: 10,
        zIndex: overrideZindex,
      }}
    >
      <Accordion
        headerContent={
          subTitle &&
          ((openAccordion) => (
            <View
              style={[tw("flex flex-row items-center justify-between w-full")]}
            >
              <View style={[tw("flex flex-row items-center")]}>
                <Text
                  style={{
                    ...theme.Accordion.titleStyle,
                    color: colors.primary,
                    paddingLeft: 10,
                    ...titleStyle,
                  }}
                  onPress={() => {}}
                >
                  {Localise(messages, title)}
                </Text>
                <Text style={[tw("ml-4"), subtitleStyle]}>
                  {Localise(messages, subTitle)}
                </Text>
              </View>
              <Image
                style={{ width: 20, height: 20 }}
                containerStyle={{ marginLeft: "auto" }}
                source={
                  IMAGES[
                    openAccordion ? "expanded-section" : "collapsed-section"
                  ]
                }
              />
            </View>
          ))
        }
        title={Localise(messages, title)}
        defaultOpen={defaultOpen}
        handleOnPress={handleOnPress}
        labelStyle={{
          marginTop: 0,
          height: 50,
          backgroundColor: backgroundColors.navBar,
          borderBottomWidth: 1,
          borderColor: colors.grayScaleLight,
          ...labelStyle,
        }}
        closeStyle={{
          borderBottomWidth: 0,
        }}
        titleStyle={{
          color: colors.primary,
          paddingLeft: 10,
        }}
        iconStyle={{
          color: colors.primary,
        }}
      >
        <View
          style={{
            backgroundColor: backgroundColors.secondary,
            padding: isMobile ? 10 : 20,
          }}
        >
          {children}
        </View>
      </Accordion>
    </View>
  );
};

export default AccordionWithBorder;
