import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "react-native";

import Input from "./input";
import CustomComponent from "./custom";
import { ExtendedScreenHeader } from "../helper";
import { DeviceContext } from "library/contexts/appSettings";

import {
  selectExtendedScreen,
  selectScheduledContentScreen,
} from "library/sagas/views/home/drawer/ftd-admin/mol-settings/selector";
import {
  setExtendedScreen,
  setScheduledContentScreenTabs,
} from "library/sagas/views/home/drawer/ftd-admin/mol-settings/slice";

import { colors } from "styles/theme";

const ExtendedScreen = () => {
  const dispatch = useDispatch();
  const { isMobile, isTablet } = React.useContext(DeviceContext);
  const {
    component,
    context,
    assetId,
    title,
    fieldType,
    fieldProps,
    category,
    subTitle1 = "",
    subTitle2 = "",
  } = useSelector(selectExtendedScreen);
  const { activeTab = "" } = useSelector(selectScheduledContentScreen);

  useEffect(() => {
    return () => {
      dispatch(setScheduledContentScreenTabs({ value: "default-content" }));
    };
  }, [component]);

  if (!component) return null;
  const onClose = () => dispatch(setExtendedScreen());

  return (
    <View
      style={{
        backgroundColor: colors.secondary,
        borderColor: colors.grayScaleLight,
        borderWidth: 1,
        borderRadius: 10,
        ...(isTablet && { padding: 10 }),
      }}
    >
      <ExtendedScreenHeader
        title={title}
        onClose={onClose}
        context={context}
        component={component}
        category={category}
        subTitle={
          activeTab === "default-content" || activeTab === "scheduled-content"
            ? subTitle1
            : subTitle2
        }
      />
      <View
        style={{
          paddingHorizontal: isMobile ? 10 : 20,
        }}
      >
        {component === "custom" ? (
          <CustomComponent fieldType={fieldType} fieldProps={fieldProps} />
        ) : (
          <Input
            title={title}
            context={context}
            assetId={assetId}
            component={component}
            fieldType={fieldType}
            fieldProps={fieldProps}
          />
        )}
      </View>
    </View>
  );
};

export default ExtendedScreen;
