import React, { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { View, Text, TouchableOpacity, Image, Platform } from "react-native";
import tw from "tailwind-rn";
import { theme, colors } from "styles/theme";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { CheckBox, Button } from "react-native-elements";
import IMAGES from "static/assets/images";
import UserProfileStorage from "library/storage/userProfile";
import { Entitlements } from "library/utils/entitlements";
import Environment from "library/utils/environment";
import I18NContext from "library/contexts/i18N";
import { selectShopCode } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import AppSettingsContext from "library/contexts/appSettings";
import shopPermissions from "library/utils/featureAvailability";

const PaymentTypes = ({ shopSettings, saveSettings, loadingKey }) => {
  const shopCode = useSelector(selectShopCode);
  const [paymentsInfo, setPaymentsInfo] = useStateIfMounted([]);
  const [actualPaymentsInfo, setActualPaymentsInfo] = useStateIfMounted([]);
  const { messages, Localise } = useContext(I18NContext);

  const { permissions = {} } = useContext(AppSettingsContext);
  const selectedShopPermissions = get(permissions, shopCode, {});
  const isGiftCardsPermissionEnabled =
    Entitlements.GIFT_CARDS in selectedShopPermissions;
  const getUserRoles = UserProfileStorage.getUserRoles();
  const isEligibleUserRole =
    getUserRoles.includes("ADMIN") || getUserRoles.includes("MANAGER");
  const isPayLaterAvailable = shopPermissions.isPayLaterAvailable(
    permissions,
    shopCode
  );

  const isGiftCardsEnabled = Environment.get("GIFT_CARDS_ENABLED", false);
  const giftCardsAccess =
    isGiftCardsEnabled && isGiftCardsPermissionEnabled ? ["Gift Cards"] : [];
  const enableGiftCardsPayment =
    isGiftCardsEnabled && isGiftCardsPermissionEnabled && isEligibleUserRole
      ? ["Gift Cards"]
      : [];

  const payLaterAccess = isPayLaterAvailable ? ["Pay Later"] : [];
  const enablePayLaterPayment =
    isPayLaterAvailable && isEligibleUserRole ? ["Pay Later"] : [];
  const { isDesktop } = React.useContext(AppSettingsContext);

  // const isMHQNonCoreUser = isMHQNonCoreMember(shopCode);

  // const digitalPaymentsAccess = Environment.get(
  //   "DIGITAL_PAYMENTS_ACCESS",
  //   ""
  // ).split(",");

  // const haveAccessToDigitalPayments =
  //   digitalPaymentsAccess.includes("ALL") ||
  //   digitalPaymentsAccess.includes(shopCode)
  //     ? true
  //     : false;

  // const digitalPayments =
  //   isMHQNonCoreUser && haveAccessToDigitalPayments
  //     ? ["ApplePay", "GooglePay", "SamsungPay", "PayPal"]
  //     : [];

  const enablePaymentTerminal = Environment.get(
    "ENABLE_PAYMENT_TERMINAL",
    ""
  ).split(",");

  const hasAccessToPaymentTerminal =
    enablePaymentTerminal.includes("ALL") ||
    enablePaymentTerminal.includes(shopCode);

  const paymentTerminalOption = hasAccessToPaymentTerminal
    ? ["Payment Terminal"]
    : [];

  const paymentFields = [
    "Cash Or Check",
    "Credit Card",
    "Paid Elsewhere",
    "House Account Invoice",
    ...paymentTerminalOption,
    ...giftCardsAccess,
    ...payLaterAccess,
    //...digitalPayments,
  ];

  const enabledPaymentFields = [
    "Cash Or Check",
    "Paid Elsewhere",
    "House Account Invoice",
    ...paymentTerminalOption,
    ...enableGiftCardsPayment,
    ...enablePayLaterPayment,
    //...digitalPayments,
  ];

  useEffect(() => {
    if (isEmpty(shopSettings)) return;
    let fields = [];
    paymentFields.forEach((title) => {
      if (
        shopSettings[`${title.toLowerCase().split(" ").join("_")}`] === "true"
      )
        fields.push(title);
    });
    setActualPaymentsInfo(fields);
    setPaymentsInfo(fields);
  }, [shopSettings]);

  const handleSelection = (title) => {
    const index = paymentsInfo.indexOf(title);
    if (index === -1) {
      setPaymentsInfo([...paymentsInfo, title]);
    } else {
      const data = [...paymentsInfo];
      data.splice(index, 1);
      setPaymentsInfo([...data]);
    }
  };

  const isNotModified =
    actualPaymentsInfo.sort().join() === paymentsInfo.sort().join();

  return (
    <React.Fragment>
      {Platform.OS !== "web" && <View style={tw("my-2 flex-1")} />}
      <Text style={[tw("mb-4")]}>
        {Localise(messages, "Select the payment types your shop will accept")}
      </Text>
      <View style={[tw("flex-1 items-start pb-2 flex-wrap"), { height: 200 }]}>
        {paymentFields.map((title, id) => {
          return (
            <View style={[tw("flex-row justify-start pb-4")]} key={id}>
              <CheckBox
                key={title}
                testID={title}
                accessibilityLabel={title}
                Component={TouchableOpacity}
                checked={paymentsInfo.includes(title)}
                disabled={!enabledPaymentFields.includes(title)}
                checkedIcon={
                  <Image
                    source={IMAGES["checkbox-checked"]}
                    style={{ width: 20, height: 20 }}
                  />
                }
                uncheckedIcon={
                  <Image
                    source={IMAGES["checkbox-unchecked"]}
                    style={{ width: 20, height: 20 }}
                  />
                }
                containerStyle={[
                  theme.CheckBox.inputContainerStyle,
                  tw("mr-6"),
                ]}
                iconRight={false}
                onPress={() => handleSelection(title)}
                size={20}
                textStyle={{
                  ...theme.CheckBox.textStyle,
                  minWidth: 150,
                }}
                title={Localise(messages, title)}
              />
            </View>
          );
        })}
      </View>
      <View style={tw("flex flex-row-reverse items-center")}>
        <Button
          onPress={() => {
            let preferences = enabledPaymentFields.map((title, id) => {
              return {
                id: title.toLocaleLowerCase().split(" ").join("_"),
                values: [paymentsInfo?.includes(title) ? "true" : "false"],
              };
            });

            saveSettings({ preferences });
          }}
          loading={loadingKey === "cash_or_check"}
          disabled={isNotModified}
          title={Localise(messages, "Save")}
          testID="save"
          accessibilityLabel="save"
        />
        <View
          style={[
            tw("flex flex-row items-center"),
            {
              ...theme.Button.cancelButtonStyle,
              ...(isNotModified && {
                borderColor: colors.grayScaleLight,
              }),
              width: isDesktop ? 72 : 80,
              height: 35,
            },
          ]}
        >
          <TouchableOpacity
            onPress={() => {
              setPaymentsInfo(actualPaymentsInfo);
            }}
            testID="clear_tax_rules"
            accessibilityLabel="clear_tax_rules"
          >
            <Text
              style={{
                ...theme.Button.secondaryTitleStyle,
                color: isNotModified
                  ? colors.grayScaleLight
                  : colors.highlighter,
                fontSize: 12,
              }}
            >
              {Localise(messages, "Cancel")}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </React.Fragment>
  );
};

export default PaymentTypes;
