import React, { useEffect } from "react";
import { View, TouchableOpacity, Platform } from "react-native";
import { Image, Text, Divider } from "react-native-elements";
import tw from "tailwind-rn";
import { fonts, backgroundColors, colors, shapes } from "styles/theme";
import { FormField } from "components/elements/forms";
import I18NContext from "library/contexts/i18N";
import moment from "moment";
import { TextField } from "../../styles";
import UserProfileStorage from "library/storage/userProfile";
import { EventTypes } from "../../config";
import Confirmation from "../confirmation";
import CustomerActions from "../customer-actions";
import { DeviceContext } from "library/contexts/appSettings";
import Location from "../location";
import { useSelector } from "react-redux";
import { selectLogoURLs } from "library/sagas/ongoing/global-data/selector";
import { phoneNumberFormatter } from "library/utils/formatter";

const ProposalInfo = (props) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const {
    values,
    initialValues: {
      customerInfo: { customerId: initailCustomerId } = {},
      locations: initialLocations = [],
    } = {},
    componentRef,
    isCustomerPage,
    isProposalPage,
    handleProposalPrint,
    setFieldValue,
    eventOrdersStatusInfo = {},
    setSideCarInfo,
  } = props;
  const {
    name,
    locations = [],
    type,
    description,
    proposal,
    status,
    memberCode,
    eventId,
    customerInfo: {
      customerType,
      firstName = "",
      lastName = "",
      businessName = "",
      phone = "",
      email = "",
      taxExemptCode = "",
      customerId,
    } = {},
    orderIds = [],
  } = values;

  const {
    amountPaid = 0,
    refunded = 0,
    unpaidBalance = 0,
  } = eventOrdersStatusInfo;

  const customerName =
    customerType === "Individual" ? `${firstName} ${lastName}` : businessName;

  const logoURLs = useSelector(selectLogoURLs);
  const { tax_delivery_fee, tax_retail_delivery_fee } =
    UserProfileStorage.getShopPreferences(memberCode);
  const floristWebsites = UserProfileStorage.getShopWebsites(memberCode);
  const floristEmail = UserProfileStorage.getShopEmail(memberCode) || "";
  const floristPhone = UserProfileStorage.getShopPhoneNumber(memberCode) || "";
  const floristAddress = UserProfileStorage.getShopLocation(memberCode) || "";
  const floristBusinessName = UserProfileStorage.getShopName(memberCode) || "";
  const { addressLine1 = "", city = "", state = "", zip = "" } = floristAddress;
  const floristAddressLine1 = `${addressLine1}`;
  const floristAddressLine2 = `${city}, ${state} ${zip}`;

  const isAcceptedOrDeclined = ["approved", "rejected", "completed"].includes(
    proposal?.status?.toLowerCase()
  );
  const eventType = EventTypes.find((x) => x.value === type)?.label;
  const locationTime =
    locations[0]?.pickupDateTime || locations[0]?.deliveryDate;
  const fileURL = logoURLs[memberCode];
  let subtotal = 0,
    deliveryFee = 0,
    retailDeliveryFee = 0,
    tax = 0;

  const statusInLower = status?.toLowerCase();
  const canSendProposal =
    !eventId ||
    ["new", "rejected", "pending"].includes(statusInLower) ||
    (["approved"].includes(statusInLower) && !orderIds.length);

  useEffect(() => {
    setSideCarInfo &&
      setSideCarInfo(
        isProposalPage && canSendProposal ? { key: "send_proposal" } : {}
      );
  }, [isProposalPage, canSendProposal]);

  return (
    <View
      style={{
        display: isProposalPage || isCustomerPage ? "flex" : "none",
        width: "100%",
      }}
    >
      <View style={{ width: "100%" }} ref={componentRef}>
        <View
          style={[
            shapes.sectionBorder,
            { padding: 0 },
            tw("flex items-center"),
          ]}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
              width: "100%",
              padding: 18,
            }}
          >
            <View style={{ maxWidth: "50%" }}>
              <Text
                style={{
                  ...fonts.heading4,
                  color: backgroundColors.primary,
                  marginBottom: 2,
                }}
              >{`${name} & ${eventType}`}</Text>
              <Text>
                {locationTime && moment(locationTime).format("MM/DD/YYYY")}
              </Text>
              <Text
                style={{ ...fonts.heading5, marginTop: 8, marginBottom: 6 }}
              >
                {Localise(messages, "Prepared for")}
              </Text>
              <Text style={{ marginBottom: 2 }}>{customerName}</Text>
              <Text style={{ marginBottom: 2 }}>{email}</Text>
              <Text style={{ marginBottom: 2 }}>
                {phoneNumberFormatter(phone)}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: isDesktop ? "row" : "column",
                maxWidth: "50%",
              }}
            >
              <View style={{ width: fileURL ? "calc(100% - 110px)" : "100%" }}>
                <Text
                  style={{
                    ...fonts.heading4,
                    marginBottom: 6,
                    color: backgroundColors.primary,
                  }}
                >
                  {floristBusinessName}
                </Text>
                <Text style={{ marginBottom: 2 }}>{floristEmail}</Text>
                <Text style={{ marginBottom: 2 }}>{floristPhone}</Text>
                <Text style={{ marginBottom: 2 }}>
                  {floristWebsites[0] || ""}
                </Text>
                <Text style={{ marginBottom: 2 }}>{floristAddressLine1}</Text>
                <Text style={{ marginBottom: 2 }}>{floristAddressLine2}</Text>
              </View>
              {fileURL ? (
                <View
                  style={[
                    { marginLeft: 10 },
                    !isDesktop ? { marginTop: 10 } : {},
                  ]}
                >
                  <Image source={fileURL} style={{ width: 100, height: 100 }} />
                </View>
              ) : (
                <></>
              )}
            </View>
          </View>
          <View style={{ width: "100%", padding: 18, paddingTop: 0 }}>
            <Text>{description}</Text>
          </View>

          {locations.map((locationInfo, index) => (
            <Location
              key={index}
              index={index}
              locationInfo={locationInfo}
              Localise={Localise}
              messages={messages}
              setFieldValue={setFieldValue}
              memberCode={memberCode}
              eventId={eventId}
              initialLocationInfo={initialLocations[index]}
              isProposalPage={isProposalPage}
              taxExemptCode={taxExemptCode}
              taxOnDeliveryFee={tax_delivery_fee === "true"}
              taxOnRetailDeliveryFee={tax_retail_delivery_fee === "true"}
              customerId={customerId}
              initailCustomerId={initailCustomerId}
            />
          ))}

          <Divider style={{ width: "100%", margin: 0 }} />
          <View style={{ width: "100%", padding: 18 }}>
            <View style={tw("flex flex-row mb-2")}>
              <Text
                style={{
                  ...fonts.heading4,
                  color: backgroundColors.primary,
                  width: "40%",
                }}
              >
                {Localise(messages, "Order Summary")}
              </Text>
              <Text
                style={{
                  ...fonts.heading5,
                  ...TextField,
                  width: "20%",
                }}
              >
                {Localise(messages, "Qty")}
              </Text>
              <Text style={{ ...fonts.heading5, ...TextField, width: "20%" }}>
                {Localise(messages, "Cost")}
              </Text>
              <Text style={{ ...fonts.heading5, ...TextField, width: "20%" }}>
                {Localise(messages, "Total")}
              </Text>
            </View>
            {locations?.map((location, index) => {
              deliveryFee =
                deliveryFee + parseFloat(location?.deliveryFee || 0);
              retailDeliveryFee =
                retailDeliveryFee +
                parseFloat(location?.retailDeliveryFee || 0);
              tax = tax + parseFloat(location?.taxInfo?.taxAmount || 0);
              return location?.products?.map((product, idx) => {
                const { quantity, productLabel, amount } = product;
                subtotal = subtotal + quantity * amount;
                return (
                  <View
                    style={tw("flex flex-row mb-2")}
                    key={`${index}_${idx}`}
                  >
                    <Text
                      style={{
                        width: "40%",
                      }}
                    >{`${productLabel}`}</Text>
                    <Text
                      style={{
                        ...TextField,
                        width: "20%",
                      }}
                    >
                      {quantity}
                    </Text>
                    <Text
                      style={{
                        ...TextField,
                        width: "20%",
                      }}
                    >
                      ${amount}
                    </Text>
                    <Text
                      style={{
                        ...TextField,
                        width: "20%",
                      }}
                    >
                      ${parseFloat(quantity * amount).toFixed(2)}
                    </Text>
                  </View>
                );
              });
            })}

            <View style={tw("flex flex-row mb-2 mt-2")}>
              <Text
                style={{
                  ...fonts.heading4,
                  width: "40%",
                }}
              >
                {Localise(messages, "Subtotal")}
              </Text>
              <Text
                style={{
                  ...fonts.heading5,
                  ...TextField,
                  width: "20%",
                }}
              ></Text>
              <Text
                style={{ ...fonts.heading5, ...TextField, width: "20%" }}
              ></Text>
              <Text style={{ ...fonts.heading5, ...TextField, width: "20%" }}>
                ${parseFloat(subtotal).toFixed(2)}
              </Text>
            </View>
            <View>
              {isProposalPage && !orderIds.length ? (
                <View style={{ width: "100%" }}>
                  {values.locations.map((location, index) => {
                    if (location.deliveryType === "FLORIST_DELIVERED") {
                      return (
                        <View
                          style={tw(
                            "flex flex-row justify-between items-center"
                          )}
                          key={index}
                        >
                          <FormField
                            name="deliveryFee"
                            placeholder={Localise(messages, "Delivery Fee")}
                            label={`${Localise(messages, "Delivery Fee")} (${
                              location.title
                            })`}
                            labelStyle={{ fontWeight: "normal" }}
                            path={`locations.${index}`}
                            containerStyle={{
                              width: isDesktop ? 190 : 110,
                              paddingHorizontal: 0,
                              marginVertical: 8,
                            }}
                            errorStyle={{ paddingBottom: 0 }}
                            isPrice={true}
                            iconType="material-community"
                            iconName="currency-usd"
                            iconSize={14}
                            inputContainerStyle={{
                              padding: 6,
                            }}
                            leftIconContainerStyle={{ paddingRight: 0 }}
                            keyboardType="numeric"
                            grayedOutOnDisable={true}
                            autoCapitalize="none"
                            autoCorrect={false}
                            autoComplete="new-password"
                            testID="delivery_fee"
                            accessibilityLabel="delivery_fee"
                          />
                          <Text style={{ ...TextField }}>
                            ${parseFloat(location.deliveryFee).toFixed(2)}
                          </Text>
                        </View>
                      );
                    } else {
                      return (
                        <View style={tw("flex flex-row mb-2 mt-2")} key={index}>
                          <Text
                            style={{
                              width: "40%",
                            }}
                          >
                            {`${Localise(messages, "Delivery Fee")} (${
                              location.title
                            })`}
                          </Text>
                          <Text
                            style={{
                              width: "20%",
                            }}
                          ></Text>
                          <Text style={{ width: "20%" }}></Text>
                          <Text style={{ ...TextField, width: "20%" }}>
                            ${parseFloat(location.deliveryFee).toFixed(2)}
                          </Text>
                        </View>
                      );
                    }
                  })}
                </View>
              ) : (
                <View style={tw("flex flex-row mb-2 mt-2")}>
                  <Text
                    style={{
                      width: "40%",
                    }}
                  >
                    {Localise(messages, "Delivery Fee")}
                  </Text>
                  <Text
                    style={{
                      width: "20%",
                    }}
                  ></Text>
                  <Text style={{ width: "20%" }}></Text>
                  <Text style={{ ...TextField, width: "20%" }}>
                    ${parseFloat(deliveryFee).toFixed(2)}
                  </Text>
                </View>
              )}
            </View>
            {parseFloat(retailDeliveryFee) > 0 && (
              <View
                style={tw(`flex flex-row items-center py-${isDesktop ? 2 : 1}`)}
              >
                <View
                  style={tw(
                    `flex flex-row items-${
                      Platform.OS !== "web" ? "center" : "baseline"
                    } w-3/4`
                  )}
                >
                  <Text
                    style={[
                      fonts.heading4,
                      tw("pr-4"),
                      { fontWeight: "normal" },
                    ]}
                  >
                    {Localise(messages, `Retail Delivery Fee`)}
                  </Text>
                </View>

                <View
                  style={tw("w-1/4 flex flex-row items-center justify-end")}
                >
                  <Text
                    style={[
                      fonts.heading4,
                      { fontWeight: "normal", textAlign: "right" },
                    ]}
                    testID="retailDeliveryFee"
                  >
                    ${parseFloat(retailDeliveryFee).toFixed(2)}
                  </Text>
                </View>
              </View>
            )}
            <View style={tw("flex flex-row mb-2 mt-2")}>
              <Text
                style={{
                  width: "40%",
                }}
              >
                {Localise(messages, "Tax")}
              </Text>
              <Text
                style={{
                  width: "20%",
                }}
              ></Text>
              <Text style={{ width: "20%" }}></Text>
              <Text style={{ ...fonts.heading5, ...TextField, width: "20%" }}>
                ${parseFloat(tax).toFixed(2)}
              </Text>
            </View>
            <View style={tw("flex flex-row mb-2 mt-2")}>
              <Text
                style={{
                  ...fonts.heading4,
                  width: "40%",
                }}
              >
                {Localise(messages, "Total")}
              </Text>
              <Text
                style={{
                  width: "20%",
                }}
              ></Text>
              <Text style={{ width: "20%" }}></Text>
              <Text style={{ ...fonts.heading5, ...TextField, width: "20%" }}>
                $
                {parseFloat(
                  subtotal + tax + deliveryFee + retailDeliveryFee
                ).toFixed(2)}
              </Text>
            </View>
            {orderIds.length > 0 && (
              <React.Fragment>
                <View style={tw("flex flex-row mb-2 mt-2")}>
                  <Text
                    style={{
                      ...fonts.heading4,
                      width: "40%",
                    }}
                  >
                    {Localise(messages, "Amount Paid")}
                  </Text>
                  <Text
                    style={{
                      width: "20%",
                    }}
                  ></Text>
                  <Text style={{ width: "20%" }}></Text>
                  <Text
                    style={{ ...fonts.heading5, ...TextField, width: "20%" }}
                  >
                    ${amountPaid}
                  </Text>
                </View>
                <View style={tw("flex flex-row mb-2 mt-2")}>
                  <Text
                    style={{
                      ...fonts.heading4,
                      width: "40%",
                    }}
                  >
                    {Localise(messages, "Refunded")}
                  </Text>
                  <Text
                    style={{
                      width: "20%",
                    }}
                  ></Text>
                  <Text style={{ width: "20%" }}></Text>
                  <Text
                    style={{ ...fonts.heading5, ...TextField, width: "20%" }}
                  >
                    ${parseFloat(refunded).toFixed(2)}
                  </Text>
                </View>
                <View style={tw("flex flex-row mb-2 mt-2")}>
                  <Text
                    style={{
                      ...fonts.heading4,
                      width: "40%",
                    }}
                  >
                    {Localise(messages, "Unpaid Balance")}
                  </Text>
                  <Text
                    style={{
                      width: "20%",
                    }}
                  ></Text>
                  <Text style={{ width: "20%" }}></Text>
                  <Text
                    style={{ ...fonts.heading5, ...TextField, width: "20%" }}
                  >
                    ${parseFloat(unpaidBalance).toFixed(2)}
                  </Text>
                </View>
              </React.Fragment>
            )}
          </View>
        </View>
        {proposal?.changeRequestNotes && !isCustomerPage ? (
          <View
            style={{
              ...shapes.sectionBorder,
              marginTop: 15,
              paddingHorizontal: 18,
              paddingVertical: 15,
            }}
          >
            <View style={[tw("flex flex-col")]}>
              <Text
                style={{
                  ...fonts.sectionHeading,
                  ...tw("mr-3"),
                  color: colors.highlighter,
                  marginBottom: 10,
                }}
              >
                {Localise(messages, "Customer Feedback")}
              </Text>

              <Text>{proposal?.changeRequestNotes}</Text>
            </View>
          </View>
        ) : null}
        {isAcceptedOrDeclined ? (
          <Confirmation
            text={proposal?.status}
            date={proposal?.date}
            fullLegalName={proposal?.fullLegalName}
          />
        ) : null}
      </View>
      {isCustomerPage ? (
        <CustomerActions
          isDesktop={isDesktop}
          values={values}
          handlePrint={handleProposalPrint}
        />
      ) : canSendProposal ? (
        <TouchableOpacity
          style={{
            marginLeft: "auto",
            marginVertical: 20,
            marginRight: 10,
          }}
          onPress={() => {
            setSideCarInfo({ key: "send_proposal" });
          }}
          testID="send_proposal"
          accessibilityLabel="send_proposal"
        >
          <Text style={{ ...fonts.link1, fontWeight: "400" }}>
            {Localise(messages, "Send Proposal")}
          </Text>
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

export default ProposalInfo;
