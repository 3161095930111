import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as parentSlice } from "../constants";
import { SLICE_NAME as sliceName } from "./constants";

export const initialState = {
  status: "",
  data: {
    catalogs: { data: [], status: "", error: false },
    collections: {
      data: [],
      status: "progress",
      error: false,
    },
  },
  actions: {
    searchText: "",
    filters: [],
    collectionSortBy: "name::asc",
    collectionLimit: 0,
    catalogLimit: 0,
  },
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: `view::${parentSlice}::${sliceName}`,
  initialState,
  reducers: {
    setInit: (state) => {
      state.status = "progress";
    },
    setReset: (state) => {
      Object.assign(state, initialState);
    },
    setData: (state, action) => {
      state.status = "done";
      state.error = false;
      state.data = { ...state.data, ...action.payload };
    },
    setError: (state) => {
      state.error = true;
    },
    setViewMore: (state, action) => {
      state.actions.collectionLimit =
        state.actions.collectionLimit + action.payload;
    },
    setViewMoreCatalog: (state, action) => {
      state.actions.catalogLimit = state.actions.catalogLimit + action.payload;
    },
    setAction: (state, action) => {
      const { type, value } = action.payload;
      if (type === "search") state.actions.searchText = value;
      else if (type === "collectionSortBy") {
        state.actions.collectionSortBy = value;
      } else if (type === "filters") {
        state.actions.filters = [...new Set([].concat(...value))];
      }
    },
  },
});

export const {
  setInit,
  setReset,
  setData,
  setViewMore,
  setAction,
  setViewMoreCatalog,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
