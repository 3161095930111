import { takeLatest, takeEvery } from "redux-saga/effects";

import {
  saveStorePickup,
  saveGlobalFees,
  saveCitiesFees,
  saveCityZipcodesFees,
  saveDeliveryExceptions,
  fetchGlobalFees,
  fetchCitiesZipcodes,
  fetchCitiesFees,
  fetchCityZipcodesFees,
  fetchCities,
  fetchDeliveryExceptions,
  fetchCitiesForExceptions,
  fetchZipCodesForExceptions,
  fetchStorePickup,
  fetchStorePickupStatus,
  clearCityZipcodesFees,
  deleteDeliveryExceptions,
} from "./slice";

import {
  handleFetchGlobalFees,
  handleFetchCitiesFees,
  handleFetchCityZipcodesFees,
  handleFetchCitiesZipcodes,
  handleFetchCities,
  handleSaveGlobalFees,
  handleSaveCitiesFees,
  handleSaveCityZipcodesFees,
  handleClearZipCodeFeesData,
} from "./sections/fee-coverage";
import {
  handleFetchDeliveryExceptions,
  handleFetchCitiesForExceptions,
  handleFetchZipCodesForExceptions,
  handleSaveDeliveryExceptions,
  handleDeleteDeliveryExceptions,
} from "./sections/delivery-exceptions";
import {
  handleFetchStorePickup,
  handleFetchStorePickupStatus,
  handleSaveStorePickup,
} from "./sections/store-pickup";
import { saveShopGroupMembers } from "../common/slice";

export function* watchSaga() {
  yield takeLatest(fetchGlobalFees.type, handleFetchGlobalFees);
  yield takeLatest(fetchCitiesFees.type, handleFetchCitiesFees);
  yield takeLatest(fetchCityZipcodesFees.type, handleFetchCityZipcodesFees);
  yield takeLatest(fetchDeliveryExceptions.type, handleFetchDeliveryExceptions);
  yield takeLatest(
    fetchCitiesForExceptions.type,
    handleFetchCitiesForExceptions
  );
  yield takeEvery(
    fetchZipCodesForExceptions.type,
    handleFetchZipCodesForExceptions
  );
  yield takeEvery(fetchCitiesZipcodes.type, handleFetchCitiesZipcodes);
  yield takeLatest(fetchCities.type, handleFetchCities);

  yield takeLatest(saveGlobalFees.type, handleSaveGlobalFees);
  yield takeLatest(saveCitiesFees.type, handleSaveCitiesFees);
  yield takeLatest(saveCityZipcodesFees.type, handleSaveCityZipcodesFees);
  yield takeLatest(saveDeliveryExceptions.type, handleSaveDeliveryExceptions);

  yield takeLatest(clearCityZipcodesFees.type, handleClearZipCodeFeesData);

  yield takeLatest(
    deleteDeliveryExceptions.type,
    handleDeleteDeliveryExceptions
  );

  yield takeLatest(fetchStorePickup.type, handleFetchStorePickup);
  yield takeLatest(fetchStorePickupStatus.type, handleFetchStorePickupStatus);
  yield takeLatest(saveShopGroupMembers.type, handleFetchStorePickupStatus);
  yield takeLatest(saveStorePickup.type, handleSaveStorePickup);
}

export default watchSaga;
