import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";
import isEqual from "lodash/isEqual";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectPageStatus = createSelector(
  selectState,
  ({ status }) => status
);

export const selectPageError = createSelector(
  selectState,
  ({ error }) => error
);

export const selectCatalogs = createSelector(
  selectState,
  ({ catalogs }) => catalogs
);

export const selectCatalogLookup = createSelector(
  selectState,
  ({ catalogLookup }) => catalogLookup
);

export const selectMemberMappings = createSelector(
  selectState,
  ({ memberMappings }) => memberMappings
);

export const selectFilteredMemberMappings = createSelector(
  selectState,
  ({ filteredMemberMappings }) => filteredMemberMappings
);

export const selectActions = createSelector(
  selectState,
  ({ actions = {} }) => actions
);

export const selectFilters = createSelector(
  selectActions,
  ({ filters = [] }) => filters
);

export const selectSearchText = createSelector(
  selectActions,
  ({ searchText = "" }) => searchText
);

export const selectMembersSelected = createSelector(
  selectActions,
  ({ selectedMembers = [] }) => selectedMembers
);

export const selectHasChanges = createSelector(
  selectActions,
  ({ initialSelectedMembers = [], selectedMembers = [] }) =>
    !isEqual([...initialSelectedMembers].sort(), [...selectedMembers].sort())
);

export const selectCurrentCatalog = createSelector(
  selectState,
  ({ current }) => current
);
