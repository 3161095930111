/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { View, Text, Platform } from "react-native";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { Form } from "components/elements/forms";
import { basicPaymentInfo } from "components/views/drawer/create-order/config.js";
import { SaveCancelButtons } from "../../helper";
import * as Yup from "yup";
import set from "lodash/set";
import get from "lodash/get";
import tw from "tailwind-rn";
import { fonts } from "styles/theme";
import { ToasterHandler } from "components/elements";
import { selectCustomerDetails } from "library/sagas/views/home/drawer/customer-directory/selector";
import useStateIfMounted from "library/utils/useStateIfMounted";
import {
  saveCreditDebitMemoHouseAccount,
  fetchOpenItemUnpaidOrders,
} from "library/sagas/views/home/drawer/customer-directory/slice";

export const getValidationSchema = (Localise, messages) => {
  return Yup.object().shape({
    amount: Yup.string()
      .required(Localise(messages, "Please enter Amount"))
      .matches(/^\d+\.?\d*$/, Localise(messages, "Invalid Amount"))
      .isNonZero(Localise(messages, "Invalid Amount")),
    memberCode: Yup.string().required(
      Localise(messages, "Please select a shop")
    ),
    creditDebitMemoType: Yup.string().required(
      Localise(messages, "Please select memo type")
    ),
  });
};

const CreditDebitMemo = ({ UIConfig = {} }) => {
  const {
    InputFields: { fields },
  } = UIConfig;
  const shopField = fields.filter((item) => item.title === "Shop");
  const shopGroupCodes = get(shopField, "0.formFieldProps.data", []);
  const memberCode =
    shopGroupCodes.length === 1 ? get(shopGroupCodes, "0.value", "") : "";
  const isStandAlone = memberCode.length > 0;

  const dispatch = useDispatch();
  const { isMobile } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const customerDetails = useSelector(selectCustomerDetails);

  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);

  const {
    houseAccountInfo = {},
    customerId,
    storeOrigin = "",
  } = customerDetails;

  const isAccountType = houseAccountInfo?.accountType || "";

  const houseAccountID = houseAccountInfo?.houseAccountId || "";

  const paymentDetails = { ...basicPaymentInfo };
  set(paymentDetails, "paymentMethod.0.enablePayment", true);

  const initialValues = {
    memberCode,
    creditDebitMemoType: "",
    amount: "",
    optionalNotes: "",
    paymentDetails,
  };
  const memoType = {
    CREDIT_MEMO: "Credit Memo",
    DEBIT_MEMO: "Debit Memo",
  };

  const saveCreditDemoPayment = (values, formikBag) => {
    let paymentDetails = { currencyCode: "USD" };
    paymentDetails = {
      ...paymentDetails,
      paymentMethod: [
        {
          paymentMethodType: values.creditDebitMemoType,
          paymentMethodDetails: {
            amount: values.amount,
            note: values.optionalNotes,
          },
        },
      ],
    };
    dispatch(
      saveCreditDebitMemoHouseAccount({
        params: {
          houseAccountId: houseAccountID,
          memberCode: values.memberCode,
          customerId,
          storeOrigin,
          paymentDetails,
          houseAccountType: isAccountType,
        },
        resolve: () => {
          ToasterHandler(
            "success",
            Localise(
              messages,
              `${memoType[values?.creditDebitMemoType]} Applied Successfully`
            )
          );
          if (
            houseAccountInfo?.accountType?.length &&
            houseAccountInfo?.accountType === "Open_Item" &&
            values?.creditDebitMemoType === "DEBIT_MEMO"
          ) {
            dispatch(
              fetchOpenItemUnpaidOrders({
                storeOrigin: storeOrigin,
                houseAccountId: houseAccountInfo?.houseAccountId,
                shopCode: values?.memberCode,
              })
            );
          }
          setValidationOnChange(false);
          formikBag.setSubmitting(false);
          formikBag.resetForm(initialValues);
        },
        reject: (error) => {
          formikBag.setSubmitting(false);
          ToasterHandler("error", Localise(messages, error));
        },
      })
    );
  };

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={(values, formikBag) => {
          saveCreditDemoPayment(values, formikBag);
        }}
        validationSchema={getValidationSchema(Localise, messages)}
        validateOnChange={validationOnChange}
        validateOnBlur={validationOnChange}
        render={({ submitCount }) => {
          useEffect(() => {
            if (submitCount > 0) {
              setValidationOnChange(true);
            }
          }, [submitCount]);

          return (
            <View style={!isMobile ? { paddingLeft: 5 } : {}}>
              {fields.map((fieldInfo, index) => {
                const {
                  formFieldElement: Component,
                  title,
                  formFieldProps,
                } = fieldInfo;

                return title === "Shop" && isStandAlone ? null : (
                  <View
                    key={index}
                    style={tw(
                      `flex flex-row justify-start pb-2 items-${
                        Platform.OS !== "web" ? "center" : "baseline"
                      }`
                    )}
                  >
                    <Text
                      style={{
                        height: 40,
                        width: isMobile ? "25%" : "30%",
                        ...fonts.heading5,
                      }}
                    >
                      {title}
                    </Text>
                    <View style={{ width: isMobile ? "75%" : "70%" }}>
                      <Component {...formFieldProps} />
                    </View>
                  </View>
                );
              })}
              <SaveCancelButtons title={"Save"} />
            </View>
          );
        }}
      />
    </>
  );
};

export default CreditDebitMemo;
