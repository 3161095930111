import { actionMap } from "components/views/drawer/order-details/order-head/order-breadcrumb/helper";
import get from "lodash/get";

export const getActionEligibleOrders = (orders, action, orderActionsList) => {
  return orders.filter((each) =>
    orderActionsList[each.orderItemId].includes(actionMap[action])
  );
};

export const getUniqMembers = (orders, orderActionsList) => {
  const eligibleOrders = getActionEligibleOrders(
    orders,
    "assign",
    orderActionsList
  );
  // Initialize an empty object to store the results
  const sortedOrders = {};
  // Iterate through each order
  eligibleOrders.forEach((order) => {
    // Extract the memberCode
    const {
      receivingMember: { memberCode },
    } = order || {};

    // Initialize an array for this memberCode if it does not exist
    if (!sortedOrders[memberCode]) {
      sortedOrders[memberCode] = [];
    }

    // Add the order to the corresponding memberCode array
    sortedOrders[memberCode].push(order);
  });
  return sortedOrders;
};

export const filterDesignerSuggestions = (designers, memberCodes) => {
  return designers.filter((item) => {
    const authMemberCodes = get(item, "authGroups.0.memberCodes", []);
    return memberCodes.every((code) => authMemberCodes.includes(code));
  });
};
