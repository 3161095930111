import replace from "lodash/replace";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import toLower from "lodash/toLower";
import sortBy from "lodash/sortBy";
import startCase from "lodash/startCase";

export const refineUserAccounts = (
  usersList = [],
  { filters = [], search = {}, sort = {} }
) => {
  const UserDisplayNameToKeyMap = {
    "Name: Ascending": "name",
    "Name: Descending": "name",
    "Role: Ascending": "role",
    "Role: Descending": "role",
  };

  let sortField = "Name: Ascending";
  const filterCategoryMap = {};

  if (filters.length) {
    filters.map((e) => {
      const [type, { title }] = Object.entries(e).pop();
      filterCategoryMap[type] = filterCategoryMap[type]
        ? filterCategoryMap[type].concat(":" + title)
        : title;
    });
  }

  usersList =
    usersList &&
    usersList.filter((employee) => {
      let matchesFilter = true;

      // Filtering the list based on the search query
      if (!isEmpty(search) && matchesFilter) {
        const val = toLower(search.value);
        // search in name, role, department and emailId fields
        matchesFilter =
          toLower(employee.name).includes(val) ||
          toLower(employee.role).includes(val) ||
          toLower(employee.department).includes(val) ||
          toLower(employee.emailId).includes(val)
            ? true
            : false;
      }

      // Filtering list by filters
      if (filters.length && matchesFilter) {
        matchesFilter = Object.keys(filterCategoryMap).reduce(
          (matches, category) => {
            const value = filterCategoryMap[category];

            // filter users with type
            if (matches && category === "Type of User") {
              const multiValues = value.split(":");

              const results = multiValues.filter((value) => {
                return employee.role === value;
              });

              matches = results.length ? true : false;
            }

            // filter users with status
            if (matches && category === "Status") {
              const multiValues = value.split(":");

              const results = multiValues.filter((value) => {
                return employee.status === value;
              });

              matches = results.length ? true : false;
            }

            return matches;
          },
          true
        );
      }
      if (matchesFilter) return employee;
    });

  // sort matches all as it just sorts
  if (!isEmpty(sort)) {
    sortField = sort.value;
  }

  usersList = sortBy(usersList, (record) =>
    toLower(record[UserDisplayNameToKeyMap[sortField]])
  );

  if (sortField === "Name: Descending" || sortField === "Role: Descending")
    usersList = usersList.reverse();

  return usersList;
};

export const processResponse = (record) => {
  return {
    id: get(record, "id", ""),
    name: `${get(record, "firstName", "")} ${get(record, "lastName", "")}`,
    role: startCase(get(record, "authGroups.0.roles.0", ""), "_", " "),
    department: startCase(get(record, "department", ""), "_", " "),
    emailId: get(record, "emailId", ""),
    status: replace(get(record, "status", "ACTIVE"), "-", ""),
    authGroups: get(record, "authGroups", ""),
    firstName: get(record, "firstName", ""),
    lastName: get(record, "lastName", ""),
    memberCode: get(record, "memberCode", ""),
    partnerGroup: get(record, "partnerGroup", ""),
    phone: get(record, "phone", ""),
    type: get(record, "type", ""),
    primaryMemberCodeWithEmailId: `${get(record, "memberCode", "")}:${get(
      record,
      "emailId",
      ""
    )}`,
    isAccountLocked: get(record, "isAccountLocked", false),
  };
};

export const processFTDStaffListResponse = (response) => {
  return response.map((record) => processResponse(record));
};
