import moment from "moment";

import toLower from "lodash/toLower";
import startCase from "lodash/startCase";
import get from "lodash/get";

import {
  formatPrice,
  phoneNumberFormatter,
  formatQuillEditorStyles,
  isValidJSON,
  formatCardMessage,
  concatenateStrings,
} from "library/utils/formatter";
import { getDiscount } from "library/utils/createOrder";
import UserProfileStorage from "library/storage/userProfile";
import { getSortedChargesList } from "components/views/drawer/order-details/payment-details/order-details/charges-summary";
import { isMHQNonCoreMember } from "library/utils/entitlements";
import { qrPlaceHolder } from "components/views/drawer/order-details/helper.js";
import { keyMap } from "./helper";
import {
  extractTimeFromDI,
  DELIVER_BY_REGEX,
} from "components/views/drawer/create-order/helper";
import { Platform } from "react-native";

const getInvoiceCSS = () => {
  return `@page {
        font-family: Helvetica, sans-serif;
        font-size: 12px;
        size: legal;
        margin: 0px;
        -webkit-margin-before: 10px;
        -webkit-margin-after: 0px;
       /* this affects the margin in the printer settings */
   }
    .editorContent p, ol, ul, pre, blockquote, h1, h2, h3, h4, h5, h6 {
        margin: 0px;
        padding: 0px;
   }
    .cardMessage {
        text-align:center;
        word-break:break-word;
        white-space: pre-wrap;
   }
    .ql-font-arial {
        font-family: "Arial";
   }
    .ql-font-georgia {
        font-family: "Georgia";
   }
    .ql-font-verdana {
        font-family: "Verdana";
   }
    .ql-font-impact {
        font-family: "Impact";
   }
    .ql-font-tahoma {
        font-family: "Tahoma";
   }
    .ql-font-times {
        font-family: "Times New Roman", Times, serif;
   }
    .ql-align-left {
        text-align: left;
   }
    .ql-align-right {
        text-align: right;
   }
    .ql-align-center {
        text-align: center;
   }
    .ql-align-justify {
        text-align: justify;
   }
    @media print {
        html, body {
            height: auto;
            font-size: 12px;
            -ms-overflow-style: none;
       }
       .page-break-flow-root {
          display: flow-root;
          page-break-after: always;
        }
        .divFooter {
            position: fixed;
            bottom: 0;
            right: 0;
            margin-right: 50px;
            margin-bottom: 15px;
       }
        table, th, tfoot {
            border: 1px solid grey;
            border-collapse: collapse;
       }
        table td {
            border-left: 1px solid grey;
            font-size: 11px;
       }
        .no-border {
            border-bottom: hidden;
            border-left: hidden;
       }
        .section1, .section2, .section3, .section4, .section5 {
            //padding: 10px;
            //border: 1px solid grey;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            align-items: center;
       }
        .section1 {
            height: 81mm;
       }
        .section2 {
            height: 85mm;
            position: relative;
       }
        .section1, .section2 {
            align-items: flex-start;
       }
        .section3 {
            height: 44mm;
       }
        .section4 {
            height: 69mm;
       }
        .section5 {
            height: 56mm;
       }
        .center {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
       }
        .heading2 {
            font-weight: bold;
       }
        .fs-16 {
            font-size: 16px;
       }
        .fs-14 {
            font-size: 14px;
       }
        .p-5 {
            padding: 2px 5px 2px 5px;
       }
   }`;
};

export const getSingleInvoicePrintHTML = ({
  orderDetails,
  memberCodes = [],
  messages = {},
  Localise = () => {},
  customerInfo = {},
  paymentDetails = {},
  // userRole, Commented as part of this: https://ftdcorp.atlassian.net/browse/MSOL-18898
  operatorName = "",
  zoneDetails,
  qrImageData = "",
  isSinglePrint = true,
  numberOfPrints = 1,
}) => {
  const {
    direction,
    deliveryInfo = {},
    lineItems = [],
    multiProductEligible = false,
    recipientInfo,
    receivingMember = {},
    sendingMember = {},
    specialInstructions = "",
    createdOn,
    messageNumber = "",
    status,
    promoCode,
    orderAmounts = [],
    fees = {},
    orderSource = "",
    isRushOrder = false,
    externalPartnerBusinessName = "",
    pickupInfo,
  } = orderDetails;
  const {
    cardMessage = "",
    deliveryDate,
    deliveryInstructions = "",
    occasion,
    deliveryMethod = "",
    orderDeliveryTime = "",
    pickUpDateTime = "",
    pickUpBy = "",
  } = deliveryInfo;

  let floristShopContactSettings = "";
  let orderInvoiceSettings = "";

  const deliveryZoneName =
    zoneDetails.length > 0
      ? zoneDetails[0].name
      : deliveryInfo?.deliveryZoneName || "";

  const isSenderOrder =
    (direction === "OUTBOUND" &&
      memberCodes.includes(sendingMember.memberCode) &&
      sendingMember.memberCode !== receivingMember.memberCode) ||
    deliveryMethod === "PHONE_OUT";

  const shopPreferences = UserProfileStorage.getShopPreferences(
    isSenderOrder ? sendingMember.memberCode : receivingMember.memberCode
  );

  let isNonCoreEntitlement = isMHQNonCoreMember(
    isSenderOrder ? sendingMember.memberCode : receivingMember.memberCode
  );
  let isShopNameOverridden = false;
  let isStreetAddressOverridden = false;
  let isPhoneOverridden = false;
  let isWebsiteOverridden = false;

  floristShopContactSettings = get(
    shopPreferences,
    "florist_contact_information_settings",
    ""
  );

  orderInvoiceSettings = get(
    shopPreferences,
    "invoice_print_card_section3_settings",
    ""
  );

  if (floristShopContactSettings == "JDE" || floristShopContactSettings == "") {
    floristShopContactSettings =
      '{"shopName":"","streetAddress":"","aptSuite":"","city":"","state":"","zip":"","phone":"","email":"","website":"","additionalMessage":"","contactName":"","printed":[],"web":[],"network":[]}';
  }

  if (!isValidJSON(orderInvoiceSettings) || orderInvoiceSettings == "") {
    orderInvoiceSettings =
      '{"shopMessage":"","careInstructions":"","storeAddressLocation":"","section3Content":"","section4Content":"","shopName":"","streetAddress":"","aptSuite":"","city":"","state":"","zip":"","phone":"","email":""}';
  }

  let {
    shopName = "",
    streetAddress = "",
    aptSuite = "",
    city = "",
    state = "",
    zip = "",
    phone = "",
    website = "",
    printed = [],
  } = JSON.parse(floristShopContactSettings);

  let {
    shopMessage = "",
    careInstructions = "",
    storeAddressLocation = "",
    section3Content = "",
    section4Content = "",
    shopName: orderInvoiceShopName = "",
    streetAddress: orderInvoiceStreetAddress = "",
    aptSuite: orderInvocieaptSuite = "",
    city: orderInvoiceCity = "",
    state: orderInvoiceState = "",
    zip: orderInvoiceZip = "",
    phone: orderInvoicePhone = "",
    email: orderInvocieEmail = "",
  } = JSON.parse(orderInvoiceSettings);

  let invocieSection3Content = "";
  let invocieSection4Content = "";
  const defaultCareInstructions =
    "Care Instructions. For long–lasting blooms, replace the water daily. We suggest trimming the stems every couple days. Place your plants in full sun to partial shade. Water thoroughly when the surface is dry to the touch. Keep evenly moist, but avoid over-watering.";
  if (careInstructions.trim() == "") {
    careInstructions = defaultCareInstructions;
  }

  let shopInfo = `<div><div>${orderInvoiceShopName}</div><div>${orderInvoiceStreetAddress}, ${orderInvocieaptSuite}</div><div>${orderInvoiceCity}, ${orderInvoiceState}, ${orderInvoiceZip}</div><div>${orderInvoicePhone}</div><div>${orderInvocieEmail}</div></div>`;
  if (isNonCoreEntitlement == true && section3Content != "") {
    switch (section3Content) {
      case "shopMessage":
        invocieSection3Content = shopMessage;
        break;
      case "shopInformation":
        invocieSection3Content = shopInfo;
        break;
      case "careInstructions":
        invocieSection3Content = careInstructions;
        break;
      case "blank":
        invocieSection3Content = "";
        break;
      default:
        invocieSection3Content = "";
    }
  }

  invocieSection3Content = formatQuillEditorStyles(invocieSection3Content);

  if (isNonCoreEntitlement == true && section4Content != "") {
    switch (section4Content) {
      case "shopMessage":
        invocieSection4Content = shopMessage;
        break;
      case "shopInformation":
        invocieSection4Content = shopInfo;
        break;
      case "careInstructions":
        invocieSection4Content = careInstructions;
        break;
      case "blank":
        invocieSection4Content = "";
        break;
      default:
        invocieSection4Content = "";
    }
  } else if (section4Content == "") {
    invocieSection4Content = defaultCareInstructions;
  }

  invocieSection4Content = formatQuillEditorStyles(invocieSection4Content);

  if (printed.includes("shopName") && shopName != "") {
    isShopNameOverridden = true;
  }
  if (printed.includes("streetAddress") && streetAddress != "") {
    isStreetAddressOverridden = true;
  }
  if (printed.includes("phone") && phone != "") {
    isPhoneOverridden = true;
  }
  if (printed.includes("website") && website != "") {
    isWebsiteOverridden = true;
  }

  let { paymentMethodType } = get(paymentDetails, "paymentMethod.0", {});
  const paymentMethod = get(paymentDetails, "paymentMethod", []);
  const lastPayment = paymentMethod.length
    ? paymentMethod[paymentMethod.length - 1]
    : {};

  const authorizationDetails = get(
    lastPayment,
    "paymentMethodDetails.authorizationDetails",
    []
  );

  const authorizationDate = authorizationDetails?.find(
    (obj) => obj.name === "authorizationRequestDate"
  )?.value;

  const isCancelledOrder = status === "CANCELLED";

  const { storePickupDateTime = "" } = pickupInfo || {};

  const isStorePickupOrder = deliveryMethod === "STORE_PICKUP";

  const { accessories: oldAccessories = [] } = lineItems[0];

  const accessories = multiProductEligible
    ? lineItems.filter((obj) => obj.type === "Addon")
    : oldAccessories;

  const products = multiProductEligible
    ? lineItems.filter((lineItem) => lineItem.type === "Product")
    : lineItems;

  const deliveryFee =
    get(fees, "applicableCharges.deliveryCharges", []).find(
      (obj) => obj.type === "Standard"
    )?.value || 0;

  const retailDeliveryFee =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "retailDeliveryFee"
    )?.value || 0;

  const relayFee =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "relayFee"
    )?.value || 0;
  const serviceCharge =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "serviceFee"
    )?.value || 0;

  const retransFee =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "retransFee"
    )?.value || 0;

  const feeSummary =
    orderAmounts?.find((obj) => obj.name === "feeSummary")?.value || "{}";
  const parseSummary = JSON.parse(feeSummary);
  const serviceFee = parseSummary.fuelSurcharge || 0;
  const rushDeliveryFee = parseSummary.rushDeliveryFee || 0;

  const isMOLOrder = orderSource === "MOL";
  const totalDeliveryFee =
    isRushOrder && rushDeliveryFee > 0 && deliveryFee > 0
      ? deliveryFee - rushDeliveryFee
      : deliveryFee;

  const formattedDeliveryDate = moment(deliveryDate).format("ddd, MM/DD/YYYY");

  const deliverByTime =
    orderDeliveryTime ||
    (isRushOrder && extractTimeFromDI(deliveryInstructions)); //For old rush orders before timed delivery feature

  const dateTime = storePickupDateTime || pickUpDateTime;
  const formattedDeliveryTime =
    deliverByTime || (dateTime ? moment(dateTime).format("h:mm A") : "");

  let deliveryInstructionsFormatted = deliveryInstructions;

  if (deliverByTime) {
    deliveryInstructionsFormatted = deliveryInstructions.replace(
      DELIVER_BY_REGEX,
      ""
    );
  }

  const amountChargedToCustomer = formatPrice(
    orderAmounts?.find((obj) => obj.name === "amountChargedToCustomer")
      ?.value || 0
  );
  const taxAmount = formatPrice(
    orderAmounts?.find((obj) => obj.name === "taxAmount")?.value || 0
  );

  let subTotal = 0;
  let otherQty = 0;
  let otherPrice = 0;
  let otherDiscount = 0;
  let otherExtended = 0;

  const {
    addressLine1: delAddress = "",
    addressLine2: delAddress2 = "",
    city: delCity = "",
    firstName = "",
    lastName = "",
    phone: delPhone = "",
    state: delState = "",
    zip: delZip = "",
    locationName = "",
  } = recipientInfo;

  const chargesList = getSortedChargesList(paymentDetails?.paymentMethod || []);

  const chargesInfo = (chargesList = []) => {
    const result = chargesList.map((each, index) => {
      const {
        paymentMethodType,
        creditCardType,
        creditCardNumber = "",
        transactionDate,
        transactionAmount,
        chargeType,
        gcLastChars = "",
      } = each;

      return `${
        ["CASH_OR_CHECK", "PAID_ELSEWHERE", "PAY_LATER"].includes(
          paymentMethodType
        )
          ? `<div style="display: flex; flex-direction:row;">
              <div> ${moment(transactionDate).format("MM/DD/YYYY")} </div>
              <div style="margin-left: 10px; width: 100px;"> ${Localise(
                messages,
                keyMap[paymentMethodType]
              )} </div>
              <div style="margin-left: 10px;"> ${
                chargeType === "refund"
                  ? `$(${formatPrice(transactionAmount || 0)})`
                  : `$${formatPrice(transactionAmount || 0)}`
              } </div>
            </div>`
          : ``
      } 
        ${
          ["GIFT_CARD"].includes(paymentMethodType)
            ? `<div style="display: flex; flex-direction:row;">
              <div> ${moment(transactionDate).format("MM/DD/YYYY")} </div>
              <div style="margin-left: 10px; width: 100px;"> ${Localise(
                messages,
                keyMap[paymentMethodType]
              )} **${gcLastChars} </div>
              <div style="margin-left: 10px;"> ${
                chargeType === "refund"
                  ? `$(${formatPrice(transactionAmount || 0)})`
                  : `$${formatPrice(transactionAmount || 0)}`
              } </div>
            </div>`
            : ``
        }
        ${
          paymentMethodType === "CREDIT_CARD"
            ? ` <div style="display: flex; flex-direction:row">
              <div> ${moment(transactionDate).format("MM/DD/YYYY")} </div>
              <div style="margin-left: 10px; width: 100px;"> ${creditCardType} **** ${creditCardNumber} </div>
              <div style="margin-left: 10px;"> ${
                chargeType === "refund"
                  ? `$(${formatPrice(transactionAmount || 0)})`
                  : `$${formatPrice(transactionAmount || 0)}`
              } </div>
            </div>`
            : ``
        }`;
    });
    return result.join("");
  };

  const getProductsContent = (products = []) => {
    const content = products.map((product, index) => {
      const {
        productFirstChoiceDescription = "",
        lineItemAmounts,
        discountValue,
        discountType,
        quantity,
        designNotes = "",
      } = product;
      const retailPrice = lineItemAmounts?.find(
        (amount) => amount.name === "retailProductAmount"
      ).value;
      const productPrice = parseFloat(retailPrice);
      const discount = discountValue
        ? getDiscount(
            Object.values(discountValue)[0],
            productPrice,
            discountType
          )
        : 0;
      const discountedAmount = productPrice - parseFloat(discount);
      subTotal += discountedAmount;
      if (index < 3) {
        return `<tr>
            <td class="p-5">${productFirstChoiceDescription} ${designNotes}</td>
            <td class="p-5" style="text-align: right">${quantity}</td>
            <td class="p-5" style="text-align: right">$${parseFloat(
              productPrice / quantity
            ).toFixed(2)}</td>
            <td class="p-5" style="text-align: right">$${parseFloat(
              discount
            ).toFixed(2)}</td>
            <td class="p-5" style="text-align: right"> $${formatPrice(
              discountedAmount
            )} </td>
          </tr> `;
      } else {
        otherQty += quantity;
        otherPrice += productPrice;
        otherDiscount += parseFloat(discount);
        otherExtended += discountedAmount;
        return "";
      }
    });
    return content.join("");
  };

  const getAccessoriesContent = (accessories = []) => {
    const content = accessories.map((acc, index) => {
      const retailPrice = acc.lineItemAmounts?.find(
        (amount) => amount.name === "retailProductAmount"
      ).value;
      const accessoryPrice = parseFloat(acc.accessoryPrice || retailPrice);
      const accDiscount = acc.discountValue
        ? getDiscount(
            Object.values(acc.discountValue)[0],
            accessoryPrice,
            acc.discountType
          )
        : 0;
      const discountedAccAmount = accessoryPrice - parseFloat(accDiscount);
      subTotal += discountedAccAmount;
      if (products.length + index < 3) {
        return `<tr>
            <td class="p-5">${
              acc.accessoryDesc || acc.productFirstChoiceDescription
            } ${acc.designNotes || ""}</td>
            <td class="p-5" style="text-align: right">${
              acc.accessoryQty || acc.quantity
            }</td>
            <td class="p-5" style="text-align: right">$${parseFloat(
              accessoryPrice / (acc.accessoryQty || acc.quantity)
            ).toFixed(2)}</td>
            <td class="p-5" style="text-align: right">$${parseFloat(
              accDiscount
            ).toFixed(2)}</td>
            <td class="p-5" style="text-align: right"> $${formatPrice(
              discountedAccAmount
            )} </td>
          </tr> `;
      } else {
        otherQty = otherQty + (acc.accessoryQty || acc.quantity);
        otherPrice += accessoryPrice;
        otherDiscount += parseFloat(accDiscount);
        otherExtended += discountedAccAmount;
        return "";
      }
    });
    return content.join("");
  };

  const getOthersConent = () => {
    if (!otherQty) return "";
    return `<tr>
            <td class="p-5">Other</td>
            <td class="p-5" style="text-align: right">${otherQty}</td>
            <td class="p-5" style="text-align: right">$${parseFloat(
              otherPrice
            ).toFixed(2)}</td>
            <td class="p-5" style="text-align: right">$${parseFloat(
              otherDiscount
            ).toFixed(2)}</td>
            <td class="p-5" style="text-align: right"> $${formatPrice(
              otherExtended
            )} </td>
          </tr> `;
  };

  const {
    addressLine1: custAddressLine = "",
    city: custCity,
    zip: custZip,
    state: custState,
    firstName: custFirstName = "",
    lastName: custLastName = "",
    businessName = "",
    customerType = "",
    isBusinessProfile = false,
    phones = [""],
    houseAccountInfo: { houseAccountId = "" } = {},
    customerContacts = [],
  } = customerInfo;

  const selectedContact = customerContacts?.find(
    (contact) => contact.isSelected
  );
  const isBusinessAccount = isBusinessProfile || customerType === "Business";
  const customerName = isBusinessAccount
    ? businessName
    : `${custFirstName} ${custLastName}`.trim();

  const delName = firstName + " " + lastName;
  let { businessName: recBusinessName } = receivingMember;
  const hasRecipientAddr = delCity.length > 0 || delState.length > 0;

  recBusinessName = isNonCoreEntitlement
    ? isShopNameOverridden
      ? shopName
      : receivingMember.businessName
    : receivingMember.businessName;

  const cityStateAndZip = (city = "", state = "", zip = "") =>
    `${city && state ? `${city}, ${state}` : city || state} ${zip}`;

  let customerInformation = "";
  if (customerName != "") {
    customerInformation = `<div><div>${
      customerName || ""
    }</div><div>${custAddressLine}</div><div>${cityStateAndZip(
      custCity,
      custState,
      custZip
    )}</div><div>${phones[0] ? `${phones[0]}` : ``}</div></div>`;
  } else {
    customerInformation = defaultCareInstructions;
  }

  if (occasion == "FUNERAL" && section3Content == "careInstructions") {
    invocieSection3Content = customerInformation;
  }

  if (occasion == "FUNERAL" && section4Content == "careInstructions") {
    invocieSection4Content = customerInformation;
  }

  const isOutgoingOrder =
    direction === "OUTBOUND" &&
    memberCodes?.includes(sendingMember?.memberCode) &&
    sendingMember?.memberCode !== receivingMember?.memberCode;

  let {
    businessName: floristBusinessName = "",
    websites = [""],
    phone: floristPhone = "",
    addressLine1: floristAddressLine1 = "",
    city: floristCity = "",
    state: floristState = "",
    zip: floristZip = "",
  } = isOutgoingOrder ? sendingMember : receivingMember || {};

  floristBusinessName = isNonCoreEntitlement
    ? isShopNameOverridden
      ? shopName
      : floristBusinessName
    : floristBusinessName;
  floristPhone = isNonCoreEntitlement
    ? isPhoneOverridden
      ? phone
      : floristPhone
    : floristPhone;
  floristAddressLine1 = isNonCoreEntitlement
    ? isStreetAddressOverridden
      ? streetAddress + ", " + aptSuite
      : floristAddressLine1
    : floristAddressLine1;
  floristCity = isNonCoreEntitlement
    ? isStreetAddressOverridden
      ? city
      : floristCity
    : floristCity;
  floristState = isNonCoreEntitlement
    ? isStreetAddressOverridden
      ? state
      : floristState
    : floristState;
  floristZip = isNonCoreEntitlement
    ? isStreetAddressOverridden
      ? zip
      : floristZip
    : floristZip;
  let websiteValue = isNonCoreEntitlement
    ? isWebsiteOverridden
      ? website
      : websites[0]
    : websites[0];
  const hasRetailDeliveryFee = Number(retailDeliveryFee) > 0;
  const hasRelayFee = Number(relayFee) > 0;
  const hasServiceFee = isMOLOrder && serviceFee > 0 && totalDeliveryFee > 0;
  const hasMHQServiceFee = Number(serviceCharge) > 0;
  const hasRetransFee = Number(retransFee) > 0;

  let rowSpan = 4;
  if (hasRetailDeliveryFee) rowSpan = rowSpan + 1;
  if (promoCode) rowSpan = rowSpan + 1;
  if (hasRelayFee) rowSpan = rowSpan + 1;
  if (hasServiceFee) rowSpan = rowSpan + 1; // only either hasServiceFee or hasMHQServiceFee will be true
  if (hasMHQServiceFee) rowSpan = rowSpan + 1;
  if (hasRetransFee) rowSpan = rowSpan + 1;
  const isIOS = Platform.OS === "ios";

  let invoiceBody = `<div style="margin-left: 10px; margin-right: 10px; background-color: white;">
  <div class="section1">
    <div style="width:100%; margin-top: 5px;">
      <div style="display:flex; flex-direction:row;justify-content: center;">
      ${
        storeAddressLocation == "Left"
          ? `<div style="width:33%; padding-left:10px; padding-right:10px;">
          <div class="heading2 fs-14 left" data-testid="shopName_header_left">${floristBusinessName}</div>
          <div style="text-align:left">${floristAddressLine1}</div>
          <div style="text-align:left">${floristCity}, ${floristState}, ${floristZip} </div>
          <div style="text-align:left">${phoneNumberFormatter(
            floristPhone
          )}</div>
          <div style="text-align:left">${websiteValue}</div>
        </div>
        <div style="width:33%"></div>`
          : storeAddressLocation == "Center" || storeAddressLocation == ""
          ? `<div style="width:33%"></div> 
        <div style="width:33%; padding-left:10px; padding-right:10px;">
          <div class="heading2 fs-14 center" data-testid="shopName_header_center">${floristBusinessName}</div>
          <div style="text-align:center">${floristAddressLine1}</div>
          <div style="text-align:center">${floristCity}, ${floristState}, ${floristZip} </div>
          <div style="text-align:center">${phoneNumberFormatter(
            floristPhone
          )}</div>
          <div style="text-align:center">${websiteValue}</div>
        </div>`
          : `<div style="width:66%"></div>`
      }
        <div style="width:33%; padding-left:10px">
          <div>${Localise(messages, "ORDER NO")}: ${messageNumber}</div>
          <div> ${Localise(
            messages,
            isStorePickupOrder ? "Pickup Date" : "DELIVERY DATE"
          )}: ${formattedDeliveryDate} </div>
          <div data-testid="section1_delivery_time"> ${Localise(
            messages,
            isStorePickupOrder ? "Pickup Time" : "DELIVERY TIME"
          )}: ${formattedDeliveryTime} </div>
${
  paymentMethodType === "INVOICE"
    ? ` <div style="margin-top: 10px;">
${
  isCancelledOrder
    ? `<div>${Localise(messages, "Order canceled")}</div>`
    : `<div>$_____________________</div>
            <div>${Localise(messages, "AMOUNT REMITTED")}</div>`
}
          </div>`
    : ``
}
        </div>
      </div>
      <div class="center fs-16" style="margin-top: 20px; font-weight: bold"> ${Localise(
        messages,
        "INVOICE"
      )} </div>
      <div class="center" style="justify-content: space-between; margin-top: 20px">
        <div style="width: 33%"></div>
        <div style="width: 33%">
          <div>${Localise(messages, "SOLD TO")}:</div>
          <div class="heading2">
            <div>${customerName || ""}</div>
            ${
              selectedContact
                ? `<div>${selectedContact.firstName}${
                    selectedContact.lastName
                      ? ` ${selectedContact.lastName}`
                      : ""
                  }</div>`
                : ""
            }
            <div>${custAddressLine}</div>
            <div>${cityStateAndZip(custCity, custState, custZip)}</div>
            <div>${
              selectedContact?.phone
                ? `${phoneNumberFormatter(selectedContact.phone)}`
                : phones[0]
                ? `${phoneNumberFormatter(phones[0])}`
                : ""
            }</div>
          </div>
        </div>
        <div>
          <div class="heading2"> ${Localise(messages, "DELIVERED TO")}: </div>
          <div>
            <div>${delName}</div>
            ${
              recipientInfo.locationName
                ? `<div style = "white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${locationName}</div>`
                : ``
            }
            <div>${delAddress}${delAddress2 ? `, ${delAddress2}` : ``}</div>
            ${
              hasRecipientAddr
                ? `<div>${delCity}, ${delState}, ${delZip}</div>`
                : ``
            }
            <div>${phoneNumberFormatter(delPhone)}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section2">
    <div style="width: 100%; margin-top: 5px;">
    <div class="center" style="justify-content: space-between;">
        <div> ${
          houseAccountId
            ? ` <div>${Localise(
                messages,
                "ACCT. NO"
              )}: ${houseAccountId}</div> `
            : ``
        } <div>${Localise(messages, "EMPLOYEE")}: ${operatorName}</div>
          <div>${Localise(messages, "PRINTED")}: ${moment().format(
    "MM/DD/YYYY HH:mm A"
  )}</div>
        </div>
        <div>
          <div>${Localise(messages, "ORDER NO")}: ${messageNumber}</div>
          <div> ${Localise(messages, "ORDER DATE")}: ${moment(createdOn).format(
    "MM/DD/YYYY HH:mm A"
  )} </div>
          <div>${Localise(messages, "ORDER BY")}: ${customerName}</div>
        </div>
        <div>
          <div> ${Localise(
            messages,
            isStorePickupOrder ? "Pickup Date" : "DELIVERY DATE"
          )}: <span class="fs-16 heading2">${formattedDeliveryDate} </span>
          </div>
          <div data-testid="section2_delivery_time"> ${Localise(
            messages,
            isStorePickupOrder ? "Pickup Time" : "DELIVERY TIME"
          )}: <span class="fs-16 heading2">${formattedDeliveryTime} </span></div>
          ${
            isStorePickupOrder
              ? `<div> ${Localise(
                  messages,
                  "Pickup By"
                )}:<span class="fs-16 heading2"> ${pickUpBy}</span></div>`
              : ``
          }
        </div>            
      </div>
      <div style="display:flex; flex-direction:row; margin-top: 2mm;">
        <div style="width: 30%"> ${
          paymentMethodType
            ? ` <div>
            <div>${Localise(messages, "PAYMENT INFORMATION")}</div>
            <div>${
              isCancelledOrder
                ? `<div style="display: flex; flex-direction:row;">
                    <div> ${Localise(messages, "Order canceled")}</div>
                  </div>`
                : paymentMethodType === "INVOICE"
                ? `<div style="display: flex; flex-direction:row;">
                <div> ${moment(authorizationDate).format("MM/DD/YYYY")} </div>
                <div style="margin-left: 10px; width: 100px;"> ${Localise(
                  messages,
                  keyMap[paymentMethodType]
                )} </div>
                <div style="margin-left: 10px;"> ${formatPrice(
                  amountChargedToCustomer
                )} </div>
              </div>`
                : chargesInfo(chargesList)
            }</div>
          </div> `
            : ``
        } <div style="margin-top: 20px;">
            <div>${Localise(messages, "CARD/OCCASION")}</div>
            <div>${startCase(toLower(occasion))}</div>
            <div class="editorContent">${formatCardMessage(cardMessage)}</div>
          </div>
        </div>
        <table style="width:70%; margin-left: 15px;">
          <tr>
            <th>${Localise(messages, "PRODUCT")}</th>
            <th>${Localise(messages, "QTY")}</th>
            <th>${Localise(messages, "PRICE/UT")}</th>
            <th>${Localise(messages, "DISCOUNT")}</th>
            <th>${Localise(messages, "EXTENDED")}</th>
          </tr>
            ${getProductsContent(products)}
            ${getAccessoriesContent(accessories)}
            ${getOthersConent()}
          <tfoot>
            <tr>
              <td colspan="2" rowspan=${rowSpan} class="no-border">${
    isSinglePrint ? `` : `${Localise(messages, "ORDER NOTES")}:`
  } </td>
              <td colspan="2" class="p-5"> ${Localise(
                messages,
                "SUB-TOTAL"
              )} </td>
              <td class="p-5" style="text-align: right">$${formatPrice(
                subTotal
              )}</td>
            </tr>
            ${
              promoCode
                ? `<tr>
              <td colspan="2" class="p-5"> ${Localise(
                messages,
                "Promo Code"
              )} (${promoCode})
               </td>
              <td class="p-5" style="text-align: right">  </td>
            </tr>`
                : ``
            }
            <tr>
              <td colspan="2" class="p-5"> ${Localise(
                messages,
                "Delivery Fee"
              )} </td>
              <td class="p-5" style="text-align: right"> $${formatPrice(
                deliveryFee
              )} </td>
            </tr>
            ${
              hasRetailDeliveryFee
                ? `<tr>
              <td colspan="2" class="p-5"> ${Localise(
                messages,
                "Retail Delivery Fee"
              )} </td>
              <td class="p-5" style="text-align: right"> $${formatPrice(
                retailDeliveryFee
              )} </td>
            </tr>`
                : ``
            }
            ${
              hasRelayFee
                ? `<tr>
              <td colspan="2" class="p-5"> ${Localise(
                messages,
                "Relay Fee"
              )} </td>
              <td class="p-5" style="text-align: right"> $${formatPrice(
                relayFee
              )} </td>
            </tr>`
                : ``
            }
            ${
              hasServiceFee
                ? `<tr>
              <td colspan="2" class="p-5"> ${Localise(
                messages,
                "Service Fee"
              )} </td>
              <td class="p-5" style="text-align: right"> $${formatPrice(
                serviceFee
              )} </td>
            </tr>`
                : ``
            }
            ${
              hasMHQServiceFee
                ? `<tr>
              <td colspan="2" class="p-5"> ${Localise(
                messages,
                "Service Fee"
              )} </td>
              <td class="p-5" style="text-align: right"> $${formatPrice(
                serviceCharge
              )} </td>
            </tr>`
                : ``
            }
            ${
              hasRetransFee
                ? `<tr>
              <td colspan="2" class="p-5"> ${Localise(
                messages,
                "Retrans Fee"
              )} </td>
              <td class="p-5" style="text-align: right"> $${formatPrice(
                retransFee
              )} </td>
            </tr>`
                : ``
            }
            <tr>
              <td colspan="2" class="p-5"> ${Localise(
                messages,
                "Sales Tax"
              )} </td>
              <td class="p-5" style="text-align: right"> $${formatPrice(
                taxAmount
              )} </td>
            </tr>
            <tr>
              <td colspan="2" class="p-5"> ${Localise(messages, "Total")} </td>
              <td class="p-5" style="text-align: right"> $${formatPrice(
                amountChargedToCustomer
              )} </td>
            </tr>
          </tfoot>
        </table>
      </div>
      ${
        specialInstructions && specialInstructions.length > 0
          ? `<div style="width:33%; bottom: 0; position: absolute">
            <div><span class="fs-12 heading2">${Localise(
              messages,
              "Special Instructions"
            )}</span>: ${
              specialInstructions?.length > 120
                ? `${specialInstructions.substring(0, 120)}...`
                : specialInstructions
            }</div>
          </div>`
          : ""
      }
    </div>
  </div>
  <div class="section3">
    <div style="width:100%">
      <div style="display:flex; flex-direction:row; justify-content: space-between;">
        <div style="width:33%;">
          <div>${Localise(messages, "ORDER NO")}: ${messageNumber}</div>
          <div>${Localise(
            messages,
            "DELIVERY ZONE"
          )}: ${deliveryZoneName} </div> 
          <div>${qrPlaceHolder(qrImageData, {
            width: 75,
            height: 75,
          })}</div>
        </div>
        <div class="heading2 fs-16">
        ${
          isStorePickupOrder
            ? `<div> ${Localise(
                messages,
                "Pickup By"
              )}:<span class="fs-16 heading2"> ${pickUpBy}</span></div>`
            : ``
        }
          <div>${delName}</div>
          ${
            recipientInfo.locationName
              ? `<div style = "white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${locationName}</div>`
              : ``
          }
          <div>${delAddress}${delAddress2 ? `, ${delAddress2}` : ``}</div>
          ${
            hasRecipientAddr
              ? `<div>${delCity}, ${delState}, ${delZip}</div>`
              : ``
          }
          <div>${phoneNumberFormatter(delPhone)}</div>
        </div>
        <div style="width: 30%;">
          <div> ${Localise(
            messages,
            isStorePickupOrder ? "Pickup Date" : "DELIVERY DATE"
          )}: <span class="heading2 fs-16">${formattedDeliveryDate}</span>
          </div>
          <div data-testid="section3_delivery_time"> ${Localise(
            messages,
            isStorePickupOrder ? "Pickup Time" : "DELIVERY TIME"
          )}: <span class="heading2 fs-16">${formattedDeliveryTime}</span></div>
          <div>${
            deliveryInstructionsFormatted.length > 120
              ? `${deliveryInstructionsFormatted.substring(0, 120)}...`
              : deliveryInstructionsFormatted
          }</div>
        </div>
      </div>
      <div class="center" style="margin-top: 10px; justify-content: space-between">
        <div>
          <div class="heading2"> ${moment(deliveryDate).format(
            "ddd, MM/DD/YYYY"
          )} </div>
          <div> ${Localise(
            messages,
            "RECEIVED BY"
          )}:__________________________ </div>
        </div>
        <div class="heading2" style="margin-left:20px;"> ${
          deliveryMethod === "PHONE_OUT"
            ? externalPartnerBusinessName
            : recBusinessName
        } </div>
      </div>
    </div>
  </div>
  <div class="section4 center fs-16">
    <div style="width: 100%; display: flex;">        
      <div class="cardMessage editorContent" style="width: 50%; padding: 10px;">${formatCardMessage(
        cardMessage
      )}</div>
      <div class="center" style="width: 50%;">
        <div>
          <div>${delName}</div>
          ${
            recipientInfo.locationName
              ? `<div style = "white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${locationName}</div>`
              : ``
          }
          <div>${delAddress}${delAddress2 ? `, ${delAddress2}` : ``}</div>
          <div>${delCity}, ${delState}, ${delZip}</div>
          <div>${phoneNumberFormatter(delPhone)}</div>
        </div>
      </div>
    </div>
  </div>
<div class="section5  fs-16">
  <div class="editorContent" style="width: 100%; height: 100%; display: flex;" data-testid="invoice_section3_section4_container">
    <div style="width: 50%;word-break: break-word;padding: 10px; ${
      section3Content !== "shopMessage"
        ? "display:flex;justify-content:center;align-items:center;"
        : ""
    }" data-testid="invoice_section3">
      ${invocieSection3Content}
    </div>
    <div style="width: 50%;word-break: break-word;padding: 10px;${
      section4Content !== "shopMessage"
        ? "display:flex;justify-content:center;align-items:center;"
        : ""
    }" data-testid="invoice_section4">
      ${invocieSection4Content}
    </div>
  </div>
</div>
</div>         
<div class="divFooter heading2" data-testid="shopName_footer">${floristBusinessName}</div>`;

  //page-break-flow-root has issue with IOS.
  invoiceBody = !isIOS
    ? `<div class="page-break-flow-root">${invoiceBody}</div>`
    : invoiceBody;

  const invoiceContent = `${
    isSinglePrint
      ? `<!DOCTYPE html>
    <html xmlns="http://www.w3.org/1999/xhtml">
      <head>
        <title>Mercury HQ</title>
      </head>
      <body onload="window.parent.postMessage({ action: 'iframe-loaded' }, '*');">
        <div style="display: none">
          <style type="text/css" media="print,screen">
            ${getInvoiceCSS()}
          </style>
        </div>`
      : ``
  }
  ${concatenateStrings(
    invoiceBody,
    `<div style="margin-top: 8px">${invoiceBody}</div>`,
    isIOS ? 1 : numberOfPrints
  )}
      ${
        isSinglePrint
          ? `</body>
    </html>`
          : ``
      }`;

  return invoiceContent;
};

export const getMultiInvoicePrintHTML = ({
  data,
  memberCodes,
  messages = {},
  Localise = () => {},
  iframeId,
  qrImagesData,
  additional_sympathy_invoice,
}) => {
  const displayOrdersInvoice = data
    .map((order = {}) => {
      const {
        orderItems = [],
        customerInfo = {},
        paymentDetails = {},
        orderAmounts = [],
      } = order.orderDetails || {};
      const {
        orderItemId = "",
        deliveryInfo: { occasion = "" },
      } = orderItems[0] || {};

      const zoneDetails = [];
      const qrImageData = qrImagesData[orderItemId];
      const numberOfPrints =
        additional_sympathy_invoice === "true" && occasion === "FUNERAL"
          ? 2
          : 1;

      return getSingleInvoicePrintHTML({
        orderDetails: { ...orderItems[0], orderAmounts } || {},
        memberCodes,
        messages,
        Localise,
        customerInfo,
        paymentDetails,
        userRole: "userRole",
        zoneDetails,
        qrImageData,
        isSinglePrint: false,
        numberOfPrints,
      });
    })
    .join("");

  const invoiceContent = `<!DOCTYPE html>
    <html xmlns="http://www.w3.org/1999/xhtml">
      <head>
        <title>Mercury HQ</title>
      </head>
      <body onload="window.parent.postMessage({ action: '${iframeId}-loaded'
     }, '*');">
        <div style="display: none">
          <style type="text/css" media="print,screen">
            ${getInvoiceCSS()}
          </style>
        </div>
        <div>
        ${displayOrdersInvoice}
        </div>
      </body>
    </html>`;
  return invoiceContent;
};
