import React, { useEffect, useMemo } from "react";
import { Platform } from "react-native";
import I18NContext from "library/contexts/i18N";
import useStateIfMounted from "library/utils/useStateIfMounted";
import AppSettingsStorage from "library/storage/appSettings";
import { Messages, AllowedLocales as Locales } from "static/language/config";
import Environment from "library/utils/environment";

export const I18nProvider = ({ children }) => {
  const [currentLocale, setCurrentLocale] = useStateIfMounted(
    AppSettingsStorage.getLanguage()
  );
  const supportedLanguages = Environment.get("SUPPORTED_LANGUAGES", "en").split(
    ","
  );
  const AllowedLocales = Locales.filter((item) =>
    supportedLanguages.includes(item.value)
  );

  const Localise = (object = {}, key = "") => {
    if (currentLocale === "en") {
      // Since both values in english and keys are same returning the key if user selects the english as preffered language.
      return key;
    }

    const value = object[key];

    if (value) {
      return value;
    } else {
      return key;
    }
  };

  const context = useMemo(() => {
    return {
      AllowedLocales,
      messages: Messages[currentLocale] || {},
      currentLocale,
      setCurrentLocale,
      Localise,
    };
  }, [currentLocale]);

  useEffect(() => {
    // update "html" tag with current language
    if (Platform.OS === "web") document.documentElement.lang = currentLocale;
  }, [currentLocale]);

  return (
    <I18NContext.Provider value={context}>{children}</I18NContext.Provider>
  );
};

export default I18nProvider;
