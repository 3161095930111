import React from "react";
import { View, ScrollView, Platform } from "react-native";
import { Text } from "react-native-elements";
import { Accordion } from "components/elements";
import { fonts, colors, backgroundColors } from "styles/theme";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { getMOLReleaseNotes } from "./ui-config";
import { Template } from "../common-questions/input";
import WebView from "react-native-webview";
import Environment from "library/utils/environment";

export const MHQReleaseNotes = ({ component }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const scribeHelpPageURL = Environment.get("SCRIBE_HELP_PAGE_URL", "");

  // const UIConfig =
  //   component === "Mercury Online Releases"
  //     ? getMOLReleaseNotes(Localise, messages, isDesktop)
  //     : getReleaseNotes(Localise, messages, isDesktop);
  // Commented this lines for: https://ftdcorp.atlassian.net/browse/MSOL-20117

  return (
    <>
      <ScrollView
        contentContainerStyle={{
          paddingHorizontal: 20,
          paddingBottom: 30,
        }}
      >
        <Text
          style={{
            ...fonts.heading2,
          }}
        >
          {Localise(messages, "Features & Updates")}
        </Text>
        <Text style={{ paddingVertical: 10 }}>
          {Localise(
            messages,
            `We release updates for ${
              component === "Mercury Online Releases"
                ? "Mercury Online"
                : "Mercury HQ"
            } on a regular basis. Below are summaries of what you can find in each release.`
          )}
        </Text>
        <View>
          {component === "Mercury Online Releases" ? (
            getMOLReleaseNotes(Localise, messages, isDesktop).map(
              ({ title, component }, index) => (
                <Accordion
                  type="secondary"
                  key={index}
                  title={Localise(messages, title)}
                  titleStyle={{
                    ...fonts.heading4,
                    fontWeight: "600",
                  }}
                  labelStyle={{
                    marginVertical: 5,
                    backgroundColor: backgroundColors.secondary,
                    marginTop: 0,
                    paddingHorizontal: 15,
                  }}
                  containerStyle={{
                    borderWidth: 1,
                    borderColor: colors.light,
                    marginTop: 10,
                  }}
                  contentStyle={{
                    backgroundColor: "white",
                    borderWidth: 0,
                    paddingHorizontal: 15,
                  }}
                  iconStyle={{
                    color: colors.primary,
                  }}
                  closeStyle={{ borderBottomWidth: 0 }}
                  handleOnPress={() => {}}
                >
                  <Template
                    childContent={component.childContent}
                    isDesktop={isDesktop}
                    style={{ paddingLeft: 0 }}
                  />
                </Accordion>
              )
            )
          ) : Platform.OS === "web" ? (
            <iframe
              src={scribeHelpPageURL}
              width="100%"
              height="640"
              allowFullScreen
            />
          ) : (
            <View style={{ flex: 1, width: "100%", height: 315 }}>
              <WebView
                source={{
                  uri: scribeHelpPageURL,
                }}
              />
            </View>
          )}
        </View>
      </ScrollView>
    </>
  );
};
