import React, { useState } from "react";
import { View, TouchableOpacity } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { ToasterHandler } from "components/elements";
import {
  SecondaryButton,
  ConfirmModal,
  ImageField,
  PrimaryButton,
} from "components/wrappers";

import I18NContext from "library/contexts/i18N";
import { selectAPIResponse } from "library/sagas/views/home/drawer/product-catalog/collection/selector";
import { deleteCollection } from "library/sagas/views/home/drawer/product-catalog/collection/slice";
import {
  navigateScreen,
  setSideCar,
} from "library/sagas/views/home/drawer/product-catalog/common/slice";
import { selectAllowSyndicate } from "library/sagas/views/home/drawer/product-catalog/common/selector";

import tw from "tailwind-rn";

export const DeleteCollectionButton = () => {
  const dispatch = useDispatch();
  const allowSyndicate = useSelector(selectAllowSyndicate);
  const { id, name, handle, group } = useSelector(selectAPIResponse);
  const { Localise, messages } = React.useContext(I18NContext);
  const [modalVisible, setModalVisibile] = useState(false);
  const isLocalCollection = group === "florist";

  const buttonProps = {
    width: 150,
  };

  const onContinueAction = () => {
    setModalVisibile(false);
    onDelete(true);
  };

  const onAbortAction = () => {
    setModalVisibile(false);
  };

  const onDelete = (approved) => {
    if (!approved) {
      setModalVisibile(true);
      return true;
    }

    dispatch(
      deleteCollection({
        params: {
          id,
          handle,
        },
        resolve: () => {
          ToasterHandler(
            "nice",
            `${Localise(messages, "Collection")} "${name}" ${Localise(
              messages,
              "has been deleted"
            )}`
          );
          setTimeout(() => {
            dispatch(
              navigateScreen({
                name: "dashboard",
                params: {
                  handle: "",
                },
              })
            );
          }, 1000);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(messages, "Deletion failed, please try again.")
          );
        },
      })
    );
  };

  return (
    <View>
      {isLocalCollection && handle ? (
        <>
          <SecondaryButton
            title={"Delete Collection"}
            action={onDelete}
            style={buttonProps}
          />
          <ConfirmModal
            modalVisible={modalVisible}
            handleSecondary={onAbortAction}
            handlePrimary={onContinueAction}
            data={{
              modal: {
                primary: "Delete",
                secondary: "Nevermind",
                content: `${
                  allowSyndicate
                    ? "This change will apply to All shops and will override any local shop changes." +
                      "\n\n"
                    : ""
                }Are you sure you want to delete this collection?`,
              },
            }}
          />
        </>
      ) : null}
    </View>
  );
};

export const AddProductToCollectionWidget = () => {
  const dispatch = useDispatch();
  const handleAddProduct = () => {
    dispatch(setSideCar("addProductsToCollection"));
  };

  return (
    <TouchableOpacity
      onPress={handleAddProduct}
      style={[
        tw("flex flex-col items-center mb-3"),
        { width: 160, height: 238, backgroundColor: "#F2F2F2" },
      ]}
    >
      <ImageField
        containerStyle={{
          backgroundColor: "#F2F2F2",
          borderWidth: 0,
        }}
        innerStyle={{
          height: 140,
          width: 140,
        }}
      />
      <View style={tw("mt-2")}>
        <PrimaryButton title={"Add Products"} action={handleAddProduct} />
      </View>
    </TouchableOpacity>
  );
};
