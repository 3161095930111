import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";
import set from "lodash/set";
import get from "lodash/get";
import UserProfileStorage from "library/storage/userProfile";

export const initialState = {
  status: "progress",
  apiResponse: {
    websiteStatus: {},
    siteContent: {},
    analytics: {},
    websiteSettings: {},
  },
  extendedScreen: {
    type: "",
    data: {},
  },
  actions: {
    pastSuspensionsLimit: { type: "limit", value: 10, increment: 10 },
    shopCode: "",
    allowSyndicate: false,
  },
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: `view::${sliceName}`,
  initialState,
  reducers: {
    setPageInitialize: (state, action) => {
      const memberCodes = UserProfileStorage.getProfileMemberCodes();
      const userRole = UserProfileStorage.getRole();

      state.status = "progress";
      state.apiResponse = initialState.apiResponse;
      state.extendedScreen = initialState.extendedScreen;
      state.actions.allowSyndicate =
        (state?.actions?.shopCode === "all" || action.payload === "all") &&
        memberCodes?.length > 1 &&
        userRole !== "FTD_ADMIN";
    },
    setPageInitSuccess: (state) => {
      state.status = "done";
    },
    setPageInitFailure: (state) => {
      state.status = "fail";
    },
    setPageReset: (state) => {
      Object.assign(state, initialState);
    },
    setApiResponse: (state, action) => {
      const { path, content, isPatch, contentType } = action.payload;
      if (isPatch) {
        if (contentType === "object") {
          const prevContent = get(state, `apiResponse.${path}.content`, {});
          set(state.apiResponse, `${path}.content`, {
            ...prevContent,
            ...content,
          });
        } else {
          const prevContent = get(state, `apiResponse.${path}.content`, []);
          set(state.apiResponse, `${path}.content`, [
            ...prevContent,
            ...content,
          ]);
        }
      } else {
        set(state.apiResponse, `${path}.content`, content);
      }
    },
    setApiError: (state, action) => {
      const { path, error } = action.payload;
      set(state.apiResponse, `${path}.error`, error);
    },
    setShopCode: (state, action) => {
      state.actions.shopCode = action.payload;
    },
    setSearchCity: (state, action) => {
      state.actions.searchCity = action.payload;
    },
    setSearchZipcode: (state, action) => {
      state.actions.searchZipcode = action.payload;
    },
    setPastSuspensionsLimit: (state) => {
      state.actions.pastSuspensionsLimit.value =
        state.actions.pastSuspensionsLimit.value +
        state.actions.pastSuspensionsLimit.increment;
    },

    fetchWebsiteStatus: () => {},
    deleteSuspension: (state) => {
      state.apiResponse.websiteStatus.error = "";
    },
    saveSuspension: (state) => {
      state.apiResponse.websiteStatus.error = "";
    },

    fetchSiteContent: () => {},
    fetchAnalytics: () => {},

    fetchWebsiteSettings: () => {},
    saveWebsiteSettings: (state) => {
      state.apiResponse.websiteSettings.error = "";
    },

    setExtendedScreen: (state, action) => {
      const { type = "", data = {} } = action.payload || {};
      state.extendedScreen = { type, data };
    },

    setWebsiteStatusPollStart: () => {},
    setWebsiteStatusPollStop: () => {},
  },
});

export const {
  setPageInitialize,
  setPageInitSuccess,
  setPageInitFailure,
  setPageReset,
  setApiResponse,
  setApiError,
  setShopCode,
  setSearchCity,
  setSearchZipcode,
  setPastSuspensionsLimit,
  fetchWebsiteStatus,
  deleteSuspension,
  saveSuspension,
  fetchSiteContent,
  fetchAnalytics,
  fetchWebsiteSettings,
  saveWebsiteSettings,
  setExtendedScreen,
  setWebsiteStatusPollStart,
  setWebsiteStatusPollStop,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
