import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "react-native";

import ListingScreen from "./listing-screen";
import UpsertScreen from "./upsert-screen";
import SplitScreen from "components/containers/split-screen";
import SubHeader from "components/containers/header/sub-header";

import {
  setPageInitialize,
  setPageReset,
} from "library/sagas/views/home/drawer/gift-cards/slice";

import { selectShowUpsert } from "library/sagas/views/home/drawer/gift-cards/selector";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import { selectDashboardShopCode } from "library/sagas/ongoing/global-data/selector";
import UserProfileStorage from "library/storage/userProfile";
import { setPageAction as setGiftCardActions } from "library/sagas/views/home/drawer/gift-cards/slice";

const GiftCard = () => {
  const dispatch = useDispatch();
  const showUpsert = useSelector(selectShowUpsert);
  const { isDesktop } = React.useContext(DeviceContext);
  const { proxyUser } = React.useContext(UserProfileContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const dashboardShopCode = useSelector(selectDashboardShopCode);
  const shopNames = UserProfileStorage.getAllShopNames();

  const sideCarComponents = [<UpsertScreen key={"content"} />];
  if (!isDesktop) {
    sideCarComponents.unshift(
      <SubHeader
        key={"header"}
        showTwoScreens={showUpsert}
        title={Localise(messages, "Gift Card")}
        onTrigger={onBack}
      />
    );
  }

  useEffect(() => {
    if (dashboardShopCode !== "all") {
      // Updating selected shop in gift cards redux slices in order show it as pre selected in shops filter.
      const selectedShop = [
        {
          label: `${dashboardShopCode} ${shopNames[dashboardShopCode]}`,
          value: dashboardShopCode,
        },
      ];

      dispatch(setGiftCardActions({ type: "shops", value: selectedShop }));
    }

    dispatch(setPageInitialize());

    return () => dispatch(setPageReset());
  }, []);

  const onBack = () => {};

  return (
    <SplitScreen
      showTwoScreens={showUpsert}
      subHeaderExists={!isDesktop}
      proxyHeaderExists={!!proxyUser}
      hasStickyHeader={true}
    >
      <View fsClass="fs-unmask">
        {[<SubHeader key={"header"} showTwoScreens={showUpsert} />].concat(
          ListingScreen()
        )}
      </View>
      <View fsClass="fs-unmask">{sideCarComponents}</View>
    </SplitScreen>
  );
};

export default GiftCard;
