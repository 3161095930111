import React from "react";
import { View } from "react-native";

const VideoPlayer = ({ sourceUrl = "" }) => {
  if( sourceUrl === "" ) return null;
  return (
    <View
      style={{
        width: "60%",
        height: 0,
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        paddingBottom: "40.25%",
        overflow: "hidden",
        maxWidth: "100%",
        marginVertical: 0,
        marginHorizontal: "auto",
      }}
    >
      <iframe
        width="100%"
        height="100%"
        style={{ position: "absolute", top: 0, left: 0 }}
        src={`${sourceUrl}?rel=0&modestbranding=1`}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </View>
  );
};

export default VideoPlayer;
