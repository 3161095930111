import React, { useContext, useEffect, useCallback } from "react";
import { ActivityIndicator, View, TouchableOpacity } from "react-native";
import { Button, Image, Input, Text } from "react-native-elements";

import tw from "tailwind-rn";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import { Accordion, FieldSet, Maps } from "components/elements";
import { Form, FormField, SubmitButton } from "components/elements/forms";

import IMAGES from "static/assets/images";
import { fonts, backgroundColors, colors, theme, shapes } from "styles/theme";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { request } from "library/utils/request";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { formatData, getMembersInfo } from "library/utils/createOrder";

const generateContent = (data) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { messages, Localise } = React.useContext(I18NContext);
  return Object.keys(data).map((key) => {
    const value = data[key];
    const dataTestId = key?.toLowerCase()?.trim()?.replace(/ /g, "_");
    return (
      <View
        style={tw("flex flex-row py-1")}
        key={key}
        fsClass={
          key === "Contact Phone" || key === "Phone"
            ? "fs-exclude"
            : "fs-unmask"
        }
      >
        <Text style={{ ...fonts.heading6, minWidth: 125, width: "30%" }}>
          {Localise(messages, key)}
        </Text>
        {typeof value !== "string" ? (
          <View
            style={{ width: "60%" }}
            testID={dataTestId}
            accessibilityLabel={dataTestId}
          >
            {!!value &&
              Object.keys(value).map((key) => (
                <Text style={[fonts.style1, tw("pb-1")]} key={`${key}-value`}>
                  {value[key]}
                </Text>
              ))}
          </View>
        ) : (
          <Text
            style={{
              ...fonts.style1,
              width: "60%",
            }}
            testID={dataTestId}
            accessibilityLabel={dataTestId}
          >
            {value}
          </Text>
        )}
      </View>
    );
  });
};

const FloristHeaderContent = ({
  index,
  title,
  isSmallScreen,
  isDigitalAdAvailable,
  isMasterFlorist,
  isPremierFlorist,
  openAccordion,
  partnerPreference,
  unavailabilityReason,
  memberCode,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  return (
    <View
      style={[
        theme.Accordion.titleContainer,
        {
          flex: 1,
          justifyContent: "space-between",
        },
      ]}
      testID={`floristPartner_${memberCode}`}
    >
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          flexWrap: "wrap",
          alignItems: "center",
          marginRight: 10,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
          }}
        >
          {!!index && (
            <View
              style={{
                height: 25,
                width: 25,
                backgroundColor: "#3A7D8D",
                borderRadius: 15,
                marginRight: 10,
              }}
            >
              <Text
                style={{
                  color: "white",
                  textAlign: "center",
                  lineHeight: 25,
                }}
              >
                {index}
              </Text>
            </View>
          )}
          <View
            style={{
              flexDirection: "row",
              flexShrink: 1,
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                ...theme.Accordion.titleStyle,
                ...theme.Accordion.secondaryTitleStyle,
                ...{ ...fonts.heading6, color: colors.primary },
                ...{ flex: 1, flexWrap: "wrap" },
              }}
              numberOfLines={isSmallScreen ? 7 : 5}
            >
              {title}
            </Text>
          </View>

          {isMasterFlorist && (
            <Image
              style={{
                width: 35,
                height: 35,
                marginLeft: 20,
              }}
              source={IMAGES["master-florist"]}
            />
          )}
          {isPremierFlorist && (
            <Image
              style={{
                width: 35,
                height: 35,
                marginLeft: 20,
              }}
              source={IMAGES["premier-florist"]}
            />
          )}
          {isDigitalAdAvailable && (
            <Image
              style={{
                width: 35,
                height: 35,
                marginLeft: 20,
              }}
              source={IMAGES["florist-ad"]}
            />
          )}
        </View>
      </View>
      <View style={{ flexDirection: "row", flexWrap: "wrap", height: 18 }}>
        {!!unavailabilityReason && partnerPreference !== "DO_NOT_USE" && (
          <Text
            style={{
              ...theme.Accordion.errorStyle,
              ...{ width: isSmallScreen ? 100 : "" },
            }}
            numberOfLines={2}
          >
            {unavailabilityReason}
          </Text>
        )}
        {partnerPreference === "DO_NOT_USE" && (
          <Text
            style={{
              ...theme.Accordion.errorStyle,
              ...{ width: isSmallScreen ? 100 : "" },
            }}
            numberOfLines={2}
          >
            {Localise(messages, "Do Not Use")}
          </Text>
        )}
        <Image
          style={{
            width: 14,
            height: 14,
          }}
          source={
            IMAGES[openAccordion ? "expanded-section" : "collapsed-section"]
          }
        />
      </View>
    </View>
  );
};

export const FloristListItem = ({
  florist = {},
  index,
  isSmallScreen,
  memberCodes,
  onSelect,
  selectedMemberCode,
  submitPreferences,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);

  const { basicInfo, memberDisplayData, memberInfoData } = florist;
  const listDisplayValues = [
    "name",
    "memberCode",
    "minPrice",
    "distance",
    "phone",
  ];

  const { phone, ...other } = basicInfo;
  const { memberCode: targetMemberCode } = other;

  const {
    fieldSetLabel,
    floristNotes,
    imageData,
    isAvailable,
    isMasterFlorist,
    isPremierFlorist,
    isDigitalAdAvailable,
    partnerPreference,
    unavailabilityReason,
  } = memberInfoData;

  return (
    <FieldSet label={fieldSetLabel} containerStyle={{ marginHorizontal: 0 }}>
      <Accordion
        labelStyle={{
          backgroundColor: "white",
          marginTop: 0,
          paddingHorizontal: 5,
          marginHorizontal: 5,
          height: "auto",
        }}
        closeStyle={{ borderBottomWidth: 0 }}
        headerContent={(openAccordion) => (
          <FloristHeaderContent
            isAvailable={isAvailable}
            isDigitalAdAvailable={isDigitalAdAvailable}
            isMasterFlorist={isMasterFlorist}
            isPremierFlorist={isPremierFlorist}
            index={index + 1}
            isSmallScreen={isSmallScreen}
            openAccordion={openAccordion}
            partnerPreference={partnerPreference}
            title={formatData(other, listDisplayValues, "space")}
            unavailabilityReason={unavailabilityReason}
            memberCode={targetMemberCode}
          />
        )}
        iconStyle={{ fontSize: 12, color: colors.primary }}
        defaultOpen={false}
        handleOnPress={() => {}}
        contentStyle={{
          backgroundColor: "white",
          borderWidth: 0,
          paddingVertical: 10,
          paddingHorizontal: 10,
        }}
        isAccessible={true}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent:
              isMasterFlorist || isPremierFlorist
                ? "space-between"
                : "flex-end",
            marginVertical: 15,
          }}
        >
          {isMasterFlorist && (
            <Image
              style={{
                width: 75,
                height: 75,
                marginLeft: 20,
              }}
              source={IMAGES["master-florist"]}
            />
          )}
          {isPremierFlorist && (
            <Image
              style={{
                width: 75,
                height: 75,
                marginLeft: 20,
              }}
              source={IMAGES["premier-florist"]}
            />
          )}
          <Button
            onPress={() => {
              onSelect(basicInfo);
            }}
            disabled={selectedMemberCode === targetMemberCode}
            title={
              selectedMemberCode === targetMemberCode
                ? Localise(messages, "Selected")
                : Localise(messages, "Select for Order")
            }
            testID="select_for_order"
            accessibilityLabel="select_for_order"
          />
        </View>
        {isDigitalAdAvailable && (
          <Image
            containerStyle={{ flex: 1 }}
            style={{ height: 260 }}
            resizeMode="contain"
            source={{
              uri: `data:image/png;base64,${imageData}`,
            }}
          />
        )}
        <View style={tw("p-3")}>{generateContent(memberDisplayData)}</View>
        <Form
          initialValues={{
            preference: partnerPreference.toLocaleLowerCase(),
            floristNotes: floristNotes || "",
            memberCode: memberCodes[0],
            targetMemberCode: targetMemberCode,
          }}
          onSubmit={(values, formikBag) => {
            submitPreferences(values, formikBag);
          }}
          render={() => (
            <>
              <View style={tw("p-3")}>
                <FormField
                  inputStyle={{ height: 80 }}
                  containerStyle={{
                    width: "100%",
                    marginLeft: -5,
                    padding: 0,
                    margin: 0,
                  }}
                  placeholder="Add florist to address book"
                  label="Florist Notes"
                  name="floristNotes"
                  multiline={true}
                  numberOfLines={3}
                />
              </View>
              <SubmitButton
                containerStyle={{ flexDirection: "row-reverse" }}
                title="Submit"
              />
            </>
          )}
        />
      </Accordion>
    </FieldSet>
  );
};

export const FloristSelection = ({
  onComplete,
  sideCarInfo = {},
  onSelect,
  formIKPath,
  formValues,
}) => {
  const [floristQuery, setFloristQuery] = useStateIfMounted("");
  const [florists, setFlorists] = useStateIfMounted([]);
  const [latLong, setLatLong] = useStateIfMounted({});
  const [loading, setLoading] = useStateIfMounted(false);
  const [locations, setLocations] = useStateIfMounted();
  const { isDesktop, isTablet } = useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const queryFieldRef = React.createRef();

  const { messages, Localise } = React.useContext(I18NContext);

  const {
    deliveryInfo: { deliveryDate = "", floristInfo } = {},
    recipientInfo: {
      zip = "",
      addressLine1,
      suite = "",
      city,
      state,
      country,
    } = {},
    lineItems = [],
    price = [],
  } = get(formValues, formIKPath) || sideCarInfo;
  const { productFirstChoiceCode = "", actualPrice } = lineItems.length
    ? lineItems[0]
    : {};
  const { value: updatedPrice } = price.length ? price[0] : {};
  const { memberCodes } = sideCarInfo;
  const { memberCode = "" } = floristInfo || {};
  const selectedMemberCode = memberCode || "";
  const { hasMultipleShops = false, sendingMember = "" } =
    formValues || sideCarInfo;
  const sendingMemberCode = hasMultipleShops
    ? sendingMember || memberCodes[0]
    : memberCodes[0];

  const filterFlorists = useCallback(() => {
    const key = floristQuery?.toLocaleLowerCase().trim() || "";
    if (key === "") return florists;
    const results = florists.filter(
      ({ basicInfo: florist }) =>
        florist.name.toLocaleLowerCase().includes(key) ||
        florist.memberCode.toLocaleLowerCase().includes(key)
    );
    return results;
  });

  useEffect(() => {
    if (zip && zip.length >= 3 && addressLine1 && city && state && country) {
      setLoading(true);
      request("get-lat-long", {
        zipCode: zip,
        addressLine1: encodeURIComponent(addressLine1),
        suite: encodeURIComponent(suite),
        city: encodeURIComponent(city),
        state: state,
        country: country,
      })
        .then((res) => {
          const { latitude = "", longitude = "" } = res || {};
          setLatLong({ latitude, longitude });
        })
        .catch(() => {
          setLatLong({});
        });
    } else {
      setLatLong({});
    }
  }, [zip, addressLine1, city, suite, state, country]);

  useEffect(() => {
    if (
      zip &&
      zip.length >= 3 &&
      deliveryDate &&
      !isEmpty(latLong) &&
      productFirstChoiceCode &&
      addressLine1 &&
      updatedPrice &&
      city &&
      state &&
      country
    ) {
      const { latitude = "", longitude = "" } = latLong;
      const requestParams = {
        memberCode: memberCodes[0],
        startDeliveryDate: deliveryDate,
        endDeliveryDate: deliveryDate,
        latitude: latitude,
        longitude: longitude,
        productCode: productFirstChoiceCode,
        zipCode: zip,
        addressLine1: encodeURIComponent(addressLine1),
        suite: encodeURIComponent(suite),
        city: encodeURIComponent(city),
        state: state,
        country: country,
        sendingMemberCode: sendingMemberCode,
        actualPrice: actualPrice || updatedPrice,
        updatedPrice,
      };
      setLoading(true);
      request("get-florists-new", requestParams)
        .then((res) => {
          const { membersInfo, shopLocations } = getMembersInfo(
            (res && res) || [],
            false
          );
          setFlorists(membersInfo);
          setLocations(shopLocations);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setFlorists([]);
        });
    } else {
      setFlorists([]);
    }
  }, [deliveryDate, latLong, productFirstChoiceCode, updatedPrice]);

  useEffect(() => {
    queryFieldRef.current.focus();
  }, []);

  const submitPreferences = useCallback((values, formikBag) => {
    request("save-florist-preferences", values).then((res) => {
      formikBag.setSubmitting(false);
    });
  });
  const {
    latitude: recipientLatitude = "",
    longitude: recipientLongitude = "",
  } = latLong;

  return (
    <View
      style={{
        ...shapes.sectionBorder,
        padding: isDesktop ? 20 : 10,
        marginVertical: 15,
      }}
    >
      <View style={[tw("flex flex-row justify-between")]}>
        <Text
          style={{
            ...fonts.sectionHeading,
            color: colors.highlighter,
          }}
        >
          {Localise(messages, "Florist Partner Selection")}
        </Text>

        {!isTablet && (
          <TouchableOpacity
            onPress={() => onComplete()}
            testID="close"
            accessibilityLabel="close"
          >
            <Image
              style={{ width: 20, height: 20 }}
              resizeMode="cover"
              source={IMAGES["close"]}
            />
          </TouchableOpacity>
        )}
      </View>

      <View style={tw("mt-4")}>
        <Input
          containerStyle={{
            width: "100%",
            paddingHorizontal: 0,
          }}
          placeholder={Localise(messages, "Search for a Florist Partner")}
          name="floristSearch"
          onChangeText={(text) => {
            setFloristQuery(text);
          }}
          ref={queryFieldRef}
          testID="search_florist"
          accessibilityLabel="search_florist"
        />
        {loading && <ActivityIndicator color={colors.activityIndicator} />}
        <View style={[tw("flex flex-row justify-between"), { marginTop: 10 }]}>
          {!loading && (
            <Text style={[fonts.heading6]}>
              {florists.length > 0
                ? `${Localise(messages, "Availability for")} "${zip}"`
                : `${Localise(messages, "No florists available for")} "${zip}"`}
            </Text>
          )}
        </View>
        <View>
          {filterFlorists().map((florist, index) => {
            return (
              <FloristListItem
                key={index}
                florist={florist}
                zip={zip}
                onSelect={onSelect}
                index={index}
                submitPreferences={submitPreferences}
                memberCodes={memberCodes}
                isSmallScreen={isSmallScreen}
                selectedMemberCode={selectedMemberCode}
              />
            );
          })}
        </View>
      </View>
      {!!(!loading && !isEmpty(locations)) &&
        florists.length > 0 &&
        !!(recipientLatitude && recipientLongitude) && (
          <View
            style={[
              tw("my-5"),
              {
                height: 400,
                borderColor: backgroundColors.medium,
                borderWidth: 1,
                borderStyle: "solid",
                marginHorizontal: 0,
              },
            ]}
          >
            <Maps
              mapMarkers={{
                ...locations,
                ...{
                  recipientLatLong: {
                    recipientLatitude: parseFloat(recipientLatitude),
                    recipientLongitude: parseFloat(recipientLongitude),
                  },
                },
              }}
            />
          </View>
        )}
    </View>
  );
};

export default FloristSelection;
