import React, { useContext } from "react";
import I18NContext from "library/contexts/i18N";
import SearchFilters from "components/containers/search-filters";
import IMAGES from "static/assets/images";
import { View } from "react-native";
import { Image } from "react-native-elements";
import { connect } from "react-redux";

const Filters = ({
  filterOptions,
  multiSelectedShopCodes,
  setActions,
  actions,
}) => {
  const { messages, Localise } = useContext(I18NContext);

  return (
    <View
      style={{
        marginTop: 20,
        marginRight: 2,
        marginLeft: -16,
        zIndex: 10,
      }}
    >
      <SearchFilters
        enableShopSelection={true}
        enableSort={false}
        enableSearch={true}
        placeholder={Localise(messages, "Search Orders")}
        enableFilter={true}
        filterOptions={filterOptions}
        memberCodes={multiSelectedShopCodes}
        actions={actions}
        setActions={setActions}
        showFiltersTitle={false}
        showDivider={false}
        params={{
          filters: [],
          shops: [],
        }}
        filterIcon={
          <Image
            style={{
              width: 18,
              height: 18,
            }}
            resizeMode="cover"
            source={IMAGES["filter"]}
          />
        }
      />
    </View>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    actions: state.mhq.ongoing.currentDelivery.actions,
  };
};

export default connect(mapStateToProps, null)(Filters);
