import moment from "moment";

export const getNewDatesTimesObj = (
  startDate = "",
  daysArray = [],
  setFieldValue,
  rule = ""
) => {
  const date = new Date();
  const currentDate = moment(date).format("YYYY-MM-DD");
  const showCurrentDate = ![
    "IN_STORE_PICKUP_ONLY",
    "OVERWRITE_DELIVERY_FEE",
  ].includes(rule);

  setFieldValue(
    "startDate",
    startDate ? "" : showCurrentDate ? currentDate : ""
  );
  setFieldValue("endDate", startDate ? "" : showCurrentDate ? currentDate : "");
  setFieldValue("day", daysArray?.length ? [] : daysOfWeek);
};

export const newException = [
  {
    label: "",
    criteria: "",
    cityZipInfo: {},
    addressInfo: { country: "US" },
    status: true,
    additionalFee: "",
    overWriteDeliveryFee: "",
    day: [],
    productType: "",
    startDate: "",
    endDate: "",
    rule: "",
  },
];

export const daysOfWeek = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];
