// Imports: Dependencies
import { takeLatest, put, call, select, takeEvery } from "redux-saga/effects";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";

import {
  setAddonsData,
  setLocalAddonsData,
  setCurrentAddon,
  setMOLAddonsData,
  setSelectedProduct,
  setLoading,
  setCurrentProduct,
  setMember,
  setImageData,
  fetchAddons,
  fetchlocalAddons,
  fetchMOLAddons,
  fetchProduct,
  fetchImageData,
  fetchMember,
  setDesignersData,
  setDistanceInMiles,
  fetchDesignerSuggestions,
  fetchDistanceInMiles,
  setDesigner,
  fetchQuickBooksSessionExpired,
  setIsQuickBooksSessionExpired,
  setUpsertDesignerStatus,
} from "./slice";
import { selectSelectedProducts } from "./selector";
import fetchOrders from "library/utils/fetchOrders";

import Logger from "library/utils/logger";
import { request } from "library/utils/request";
import { trimSpecialChars } from "library/utils/formatter";
import AppSettingsStorage from "library/storage/appSettings";
import { hasQuickBooksAccess } from "library/utils/featureAvailability";

import {
  processSelectedProductResponse,
  processSelectedMOLProductResponse,
} from "library/utils/productDetails";
import {
  processAddonsResponse,
  processLocalAddonsResponse,
} from "library/utils/createOrder";

const setDesignerRequest = ({ designer, orders, memberCode }) =>
  request("assign-designer", { designer, orders, memberCode });

/**
 * WORKER: Fetches data and publishes the successful result, or an error
 */
function* handleFetchData() {
  const serviceRequest = () => request("get-addons");
  try {
    const response = yield call(serviceRequest);
    const result = processAddonsResponse(
      (response && response.productSummaries) || []
    );
    yield put(setAddonsData(result));
  } catch (error) {
    console.log(error);
    yield put(setLoading(false));
  }
}

function* handleFetchLocalAddons(action = {}) {
  const { addonId, siteId, orderSource } = get(action, "payload", "");
  const serviceRequest = (payload) => request("get-addon-details", payload);
  try {
    const response = yield call(serviceRequest, {
      addonId,
      siteId,
      orderSource,
    });
    const result = processLocalAddonsResponse(
      (response && response.products) || []
    );
    yield put(setLocalAddonsData(result));
    yield put(setCurrentAddon(result?.[0]));
  } catch (error) {
    console.log(error);
    yield put(setLoading(false));
  }
}

function* handleFetchMOLAddonData(action = {}) {
  const { accessoryId, sourceMemberCode } = get(action, "payload", "");
  const molServiceRequest = (payload) => request("get-product", payload);
  try {
    const molResponse = yield call(molServiceRequest, {
      productId: accessoryId,
      memberCode: sourceMemberCode,
    });
    const result = processSelectedMOLProductResponse(molResponse);
    yield put(setMOLAddonsData(result));
  } catch (error) {
    console.log(error);
    yield put(setLoading(false));
  }
}

/**
 * WORKER: Fetches data and publishes the successful result, or an error
 * @param {action} action the fetch data action definition
 */
function* handleFetchProductData(action = {}) {
  try {
    const {
      productId = "",
      orderSource = "",
      siteId = "",
    } = get(action, "payload", {});
    const selectedproducts = yield select(selectSelectedProducts);
    const currentproduct = selectedproducts.find((product) => {
      const updatedProdId = trimSpecialChars(productId?.toLowerCase(), ".");
      return (
        trimSpecialChars(product.refNumberId?.toLowerCase()) ===
          updatedProdId ||
        trimSpecialChars(product.productId?.toLowerCase()) === updatedProdId
      );
    });

    if (currentproduct) {
      yield put(setCurrentProduct(cloneDeep(currentproduct)));
    } else {
      const serviceRequest = (payload) =>
        request("get-product-details", payload);
      const response = yield call(serviceRequest, {
        productId: productId.toUpperCase(),
        siteId,
        orderSource,
      });
      const result =
        response?.products && response.products.length > 0
          ? processSelectedProductResponse(
              (response && response.products) || []
            )
          : {};
      let updatedResult = { ...result };
      // if (productInfo?.refNumberId) {
      //   updatedResult.refNumberId = productInfo.refNumberId;
      // }

      yield put(setCurrentProduct(updatedResult));
      yield put(setSelectedProduct(updatedResult));
    }
  } catch (error) {
    console.log(error);
    yield put(setLoading(false));
  }
}

/**
 * WORKER: Fetches data and publishes the successful result, or an error
 * @param {action} action the fetch data action definition
 */
function* handleFetchMemberData(action = {}) {
  try {
    const memberCode = get(action, "payload", "");
    const serviceRequest = () =>
      request("get-shop-basic-info", { requestedMemberCodes: [memberCode] });
    const response = yield call(serviceRequest);

    if (!isEmpty(response)) yield put(setMember(response));
  } catch (error) {
    Logger.error("Unable to fetch shop details. " + JSON.stringify(error));
  }
}

/**
 * WORKER: Fetches data and publishes the successful result, or an error
 * @param {action} action the fetch data action definition
 */
function* handleFetchImageData(action = {}) {
  try {
    const name = get(action, "payload", "");
    const serviceRequest = (payload) => request("download-image", payload);
    const response = yield call(serviceRequest, { imageName: name });

    if (!isEmpty(response))
      yield put(setImageData({ name, data: response.source }));
  } catch (error) {
    Logger.error("Unable to download image. ", JSON.stringify(error));
  }
}

function* handleFetchQuickBooksSessionExpired({ payload = {} }) {
  const { shopCode } = payload;
  const permissions = AppSettingsStorage.getAllPermissions();
  const hasQuickBooksFeature = hasQuickBooksAccess(permissions, shopCode);
  if (hasQuickBooksFeature) {
    const quickBooksRequest = (params) =>
      request("get-quickBooks-authorization-status", params);
    try {
      const quickBooksResponse = yield call(quickBooksRequest, {
        memberCode: shopCode,
      });
      yield put(setIsQuickBooksSessionExpired(quickBooksResponse.isExpired));
    } catch (error) {
      console.log(error);
    }
  }
}

export function* handleFetchDesignerSuggestions({ payload = {} }) {
  const { resolve, reject, members = "" } = payload;
  const serviceRequest = (members) =>
    request("get-designer-suggestions", {
      members,
    });
  try {
    const response = yield call(serviceRequest, members);
    yield put(setDesignersData(response));
    resolve && resolve();
  } catch (error) {
    console.log(error);
    reject && reject();
  }
}
function* handleSetDesigner({ payload = {} }) {
  const {
    designer,
    orders,
    memberCode,
    listingInfo,
    timeStampLocal,
    statusList,
    initData,
    fetchData,
    resolve,
    reject,
    refetchListing = true,
    type = "",
  } = payload;
  try {
    const response = yield call(setDesignerRequest, {
      designer,
      orders,
      memberCode,
    });
    if (response) {
      const responseMsg =
        designer?.id?.length > 0
          ? "Order has been assigned to a Designer"
          : "Designer has been removed from this order";
      resolve && resolve(type === "BULK_ASSIGNMENT" ? response : responseMsg);
      yield put(setUpsertDesignerStatus(""));
      refetchListing &&
        fetchOrders(
          "current",
          { ...listingInfo, timeStamp: timeStampLocal },
          statusList,
          initData,
          fetchData
        );
    } else {
      yield put(setUpsertDesignerStatus(""));
      reject && reject("error");
    }
  } catch (error) {
    console.log(error);
    yield put(setUpsertDesignerStatus(""));
    reject && reject(error);
  }
}

function* handleFetchDistanceInMiles({ payload = {} }) {
  const {
    memberCode = "",
    destination = "",
    origin = "",
    resolve,
    reject,
  } = payload;
  const serviceRequest = (memberCode, destination, origin) =>
    request("get-distance-in-miles", {
      memberCode,
      destination,
      origin,
    });
  try {
    const response = yield call(
      serviceRequest,
      memberCode,
      destination,
      origin
    );
    yield put(setDistanceInMiles(response?.distance));
    resolve && resolve();
  } catch (error) {
    console.log(error);
    reject && reject();
  }
}

/**
 * WATCHER: Subscribe to Global actions
 */
export default function* globalDataSaga() {
  yield takeLatest(fetchAddons.type, handleFetchData);
  yield takeEvery(fetchlocalAddons.type, handleFetchLocalAddons);
  yield takeEvery(fetchMOLAddons.type, handleFetchMOLAddonData);
  yield takeEvery(fetchProduct.type, handleFetchProductData);
  yield takeLatest(fetchMember.type, handleFetchMemberData);
  yield takeLatest(fetchImageData.type, handleFetchImageData);
  yield takeLatest(
    fetchDesignerSuggestions.type,
    handleFetchDesignerSuggestions
  );
  yield takeLatest(setDesigner.type, handleSetDesigner);
  yield takeLatest(
    fetchQuickBooksSessionExpired.type,
    handleFetchQuickBooksSessionExpired
  );
  yield takeLatest(fetchDistanceInMiles.type, handleFetchDistanceInMiles);
}
