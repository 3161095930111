export const customisePushNotificationsOptions = [
  {
    title: "New Order",
    name: "New Order",
    type: "opted_order_push_notifications",
    entitlement: "ORDERS",
  },
  {
    title: "New Pickup Order",
    name: "New Pickup Order",
    type: "opted_order_push_notifications",
    entitlement: "ORDERS",
  },
  {
    title: "Order Modification",
    name: "Order Modification By Sender",
    type: "opted_order_push_notifications",
    entitlement: "ORDERS",
  },
  {
    title: "Order Fulfillment Error",
    name: "Order Fulfillment Error",
    type: "opted_order_push_notifications",
    entitlement: "ORDERS",
  },
  {
    title: "Pickup Driver Approaching",
    name: "Pickup",
    type: "opted_delivery_push_notifications",
    entitlement: "DELIVERY_TASKS",
  },
  {
    title: "Hurry Pickup Driver Approaching",
    name: "Hurry Pickup",
    type: "opted_delivery_push_notifications",
    entitlement: "DELIVERY_TASKS",
  },
  {
    title: "Delivery Service Fulfillment Error",
    name: "Fulfillment Error",
    type: "opted_delivery_push_notifications",
    entitlement: "DELIVERY_TASKS",
  },
];
