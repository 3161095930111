import * as Yup from "yup";
import { SHOP_SETTINGS, COMMON } from "library/constants";

export const validationSchema = (Localise, messages) =>
  Yup.object().shape({
    promoCode: Yup.string()
      .min(2, Localise(messages, SHOP_SETTINGS.PROMO_CODE_INVALID))
      .required(Localise(messages, SHOP_SETTINGS.PROMO_CODE_REQUIRED)),
    discountType: Yup.string().required(
      Localise(messages, COMMON.DISCOUNT_TYPE_REQUIRED)
    ),
    discountValue: Yup.number().when("discountType", {
      is: (value) => !!value,
      then: Yup.number()
        .required(COMMON.AMOUNT_REQUIRED)
        .min(0, COMMON.AMOUNT_INVALID)
        .when("discountType", {
          is: COMMON.PERCENTAGE,
          then: Yup.number()
            .max(100, COMMON.INVALID_PERCENTAGE)
            .typeError(COMMON.AMOUNT_INVALID),
          otherwise: Yup.number()
            .max(9999, COMMON.AMOUNT_INVALID)
            .typeError(COMMON.AMOUNT_INVALID),
        }),
      otherwise: Yup.number().notRequired(),
    }),
    startDate: Yup.date().required(
      Localise(messages, COMMON.START_DATE_REQUIRED)
    ),
    endDate: Yup.date()
      .required(Localise(messages, COMMON.END_DATE_REQUIRED))
      .when("startDate", (startDate, schema) => {
        return startDate
          ? schema.min(
              Yup.ref("startDate"),
              Localise(messages, COMMON.END_DATE_BEFORE_START)
            )
          : schema;
      }),
  });

export const initialValues = {
  promoCode: "",
  discountType: "",
  discountValue: "",
  startDate: "",
  endDate: "",
};

export const discountTypes = (Localise, messages) => [
  {
    label: `$ ${Localise(messages, COMMON.DISCOUNT)}`,
    value: COMMON.DOLLAR,
  },
  {
    label: `% ${Localise(messages, COMMON.DISCOUNT)}`,
    value: COMMON.PERCENTAGE,
  },
];
