import React, { useEffect } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import Analytics from "./analytics";
import ExtendedScreen from "./extended";
import WebsiteStatus from "./website-status";
import SiteContent from "./site-content";
import WebsiteSettings from "./website-settings";

import { Spinner } from "components/elements";
import SplitScreen from "components/containers/split-screen-new";
import { Section, EmptyScreen, Header } from "./helper";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import {
  setPageInitialize,
  setPageReset,
} from "library/sagas/views/home/drawer/mercury-online/slice";
import {
  selectShopCode,
  selectIsPageLoading,
  selectWebsiteCurrentStatus,
  selectExtendedScreen,
  selectAllowSyndicate,
} from "library/sagas/views/home/drawer/mercury-online/selector";
import { fetchAllCollections } from "library/sagas/views/home/drawer/ftd-admin/mol-settings/slice";
import { setPageReset as setMOLSettingReset } from "library/sagas/views/home/drawer/ftd-admin/mol-settings/slice";
import { backgroundColors } from "styles/theme";

import tw from "tailwind-rn";

const MercuryOnline = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectIsPageLoading);
  const shopCode = useSelector(selectShopCode);
  const allowSyndicate = useSelector(selectAllowSyndicate);
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const { type } = useSelector(selectExtendedScreen);
  const { proxyUser, memberCodes } = React.useContext(UserProfileContext);

  const showExtendedScreen = !!type;

  useEffect(() => {
    shopCode && dispatch(setPageInitialize(shopCode));
    dispatch(fetchAllCollections({ memberCodes, proxyUser }));
  }, [shopCode]);

  useEffect(() => {
    return () => {
      dispatch(setPageReset());
      dispatch(setMOLSettingReset());
    };
  }, []);

  return (
    <SplitScreen
      ScreenOne={
        <View fsClass="fs-unmask">
          <Header title={Localise(messages, "Mercury Online")} />
          <View
            style={{
              minHeight: 150,
              width: isDesktop && !showExtendedScreen ? "60%" : "100%",
            }}
          >
            <View style={tw("flex flex-col")}>
              {loading ? (
                <View style={{ minHeight: 150 }}>
                  <Spinner size="large" />
                </View>
              ) : (
                <>
                  {!allowSyndicate && (
                    <Section
                      title={Localise(messages, "Website Status")}
                      subTitleSelector={selectWebsiteCurrentStatus}
                      overrideZindex={10}
                    >
                      <WebsiteStatus />
                    </Section>
                  )}
                  <Section title={Localise(messages, "Site Content")}>
                    <SiteContent />
                  </Section>
                  <Section title={Localise(messages, "Analytics")}>
                    <Analytics />
                  </Section>
                  {!allowSyndicate && (
                    <Section title={Localise(messages, "Website Settings")}>
                      <WebsiteSettings />
                    </Section>
                  )}
                </>
              )}
            </View>
          </View>
        </View>
      }
      ScreenTwo={
        showExtendedScreen ? (
          <View
            fsClass="fs-unmask"
            style={{ backgroundColor: backgroundColors.sideCar }}
          >
            <ExtendedScreen />
          </View>
        ) : (
          <EmptyScreen />
        )
      }
      showSecondScreen={showExtendedScreen}
    />
  );
};

export default MercuryOnline;
