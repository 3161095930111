import React, { useContext, useEffect } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { fetchFloristSubscrptionsList } from "library/sagas/views/home/drawer/shop-settings/addons/slice";
import {
  selectAddonsList,
  selectAddonsListLoading,
  selectAddonsDetails,
} from "library/sagas/views/home/drawer/shop-settings/addons/selector";
import { selectShopCode } from "library/sagas/views/home/drawer/shop-settings/common/selector";

import { fonts, colors } from "styles/theme";
import tw from "tailwind-rn";

import { Spinner, ToasterHandler } from "components/elements";
import AddonComponent from "./addonComponent";

const Addons = ({ shopCodes, screenOneRef }) => {
  const dispatch = useDispatch();
  const { Localise, messages } = useContext(I18NContext);
  const { isMobile } = useContext(DeviceContext);

  const shopCode = useSelector(selectShopCode);
  const addonsList = useSelector(selectAddonsList);
  const pageLoading = useSelector(selectAddonsListLoading);
  const addonsDetails = useSelector(selectAddonsDetails);

  useEffect(() => {
    screenOneRef?.current?.scrollTo({ x: 0, y: 0 });
    return () => {
      screenOneRef?.current?.scrollTo({ x: 0, y: 0 });
    };
  }, []);

  useEffect(() => {
    /* get list of addon features */
    dispatch(
      fetchFloristSubscrptionsList({
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(messages, "Something went wrong. Please try again!")
          );
        },
      })
    );
  }, [shopCode]);

  if (pageLoading && !addonsDetails?.length) {
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );
  }

  return (
    <View style={{ padding: isMobile ? 10 : 0 }}>
      <View style={[tw("flex flex-row items-center")]}>
        <Text style={[fonts.heading2, tw("mr-2")]}>
          {Localise(messages, `Add-On Features`)}{" "}
        </Text>
        {pageLoading && <ActivityIndicator color={colors.activityIndicator} />}
      </View>
      <Text
        style={[
          tw("text-justify"),
          { width: isMobile ? "100%" : "70%", marginTop: 10 },
        ]}
      >
        {Localise(
          messages,
          `Add-On Features can be added to your account individually based on your business needs. You can review and manage status and settings of these features below.`
        )}{" "}
        {Localise(
          messages,
          `Billing will occur monthly on your FTD statement.`
        )}
      </Text>

      {/* Addons */}
      <View
        style={[
          tw(
            `flex ${
              isMobile ? "flex-col" : "flex-row"
            } flex-wrap justify-between`
          ),
        ]}
      >
        {addonsList
          ?.filter((each) => each?.enabled && ["ADDON"].includes(each?.type))
          ?.map((eachAddonFeatureDetails, index) => {
            return (
              <View key={index} style={{ width: isMobile ? "100%" : "49%" }}>
                <AddonComponent
                  featureDetails={eachAddonFeatureDetails}
                  shopCodes={shopCodes}
                />
              </View>
            );
          })}
      </View>
    </View>
  );
};

export default Addons;
