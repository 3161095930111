import React from "react";
import { View } from "react-native";
import { Accordion } from "components/elements";
import I18NContext from "library/contexts/i18N";
import { backgroundColors, colors } from "styles/theme";

const Section = ({
  title,
  children,
  overrideZindex = 0,
  defaultOpen = true,
  titleStyle = {},
  ...otherprops
}) => {
  const { Localise, messages } = React.useContext(I18NContext);

  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
        marginBottom: 8,
        opacity: 1,
        zIndex: overrideZindex,
      }}
    >
      <Accordion
        title={Localise(messages, title)}
        defaultOpen={defaultOpen}
        handleOnPress={() => {}}
        labelStyle={{
          marginTop: 0,
          height: 40,
          backgroundColor: backgroundColors.navBar,
          borderBottomWidth: 1,
          borderColor: colors.grayScaleLight,
          paddingHorizontal: 12,
        }}
        titleStyle={{
          color: colors.primary,
          fontSize: 15,
          fontWeight: "500",
          ...titleStyle,
        }}
        closeStyle={{
          borderBottomWidth: 0,
        }}
        iconStyle={{
          color: colors.primary,
        }}
        testID={title}
        {...otherprops}
      >
        <View
          style={{
            backgroundColor: backgroundColors.secondary,
            padding: 10,
          }}
        >
          {children}
        </View>
      </Accordion>
    </View>
  );
};

export default Section;
