import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text } from "react-native";

import { ToasterHandler, Spinner } from "components/elements";
import { Form } from "components/elements/forms";
import { ConfirmModal, SecondaryButton } from "components/wrappers";

import {
  ABTesting,
  HTMLTextEditor,
  SEOTextEditor,
  AnnouncementBanner,
  ShopperApprovedReviews,
  BrandSelector,
  BannerMetaData,
  SaveCancelButtons,
  SortableNavigation,
  CheckoutCustomizations,
} from "components/views/drawer/ftd-admin/mol-settings/helper";
import { getValidationSchema } from "components/views/drawer/ftd-admin/mol-settings/extended/yup";

import I18NContext from "library/contexts/i18N";
import UserProfileContext from "library/contexts/userProfile";
import { selectAllowSyndicate } from "library/sagas/views/home/drawer/mercury-online/selector";
import {
  selectApiResponse,
  selectScheduledContentScreen,
} from "library/sagas/views/home/drawer/ftd-admin/mol-settings/selector";
import {
  fetchMiddlewareData,
  saveMiddlewareData,
  deleteMiddlewareData,
  setScheduledContentScreenTabs,
} from "library/sagas/views/home/drawer/ftd-admin/mol-settings/slice";

import { theme } from "styles/theme";
import tw from "tailwind-rn";
import moment from "moment";

const SiteContentFields = ({
  component,
  context = "",
  assetId,
  title,
  fieldType,
  fieldProps,
  overrideHeader,
}) => {
  const dispatch = useDispatch();
  const allowSyndicate = useSelector(selectAllowSyndicate);
  const [showPopup, setShowPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    components: scheduledContentComponents,
    activeTab,
    tabs = [],
  } = useSelector(selectScheduledContentScreen);

  const isFutureTabExists =
    tabs.findIndex((val) => val.key === "scheduled-content") > -1;
  const {
    userProfile: { type: userType },
  } = React.useContext(UserProfileContext);

  const isFTDUser = userType === "FTD_USER";

  const {
    fetching,
    component: current,
    actualContext,
    content,
    error,
    scheduledContent = "",
  } = useSelector(selectApiResponse);
  const { messages, Localise } = React.useContext(I18NContext);

  const validationSchema = getValidationSchema(
    component,
    title,
    Localise,
    messages,
    activeTab
  );

  useEffect(() => {
    dispatch(
      fetchMiddlewareData({
        component,
        context,
        assetId,
      })
    );
  }, [component]);

  if (fetching || component != current)
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );

  const onSubmit = (formValues, formikBag) => {
    const { scheduledContent = "", assetId = "" } = formValues;
    let values = formValues;

    if (
      activeTab === "scheduled-content" &&
      assetId === "json" &&
      scheduledContent
    ) {
      let startDate, endDate, rest;
      try {
        ({
          startDate = "",
          endDate = "",
          ...rest
        } = JSON.parse(scheduledContent));
      } catch {
        //
      }

      startDate = startDate || moment().format("YYYY-MM-DD");
      endDate = endDate || moment().format("YYYY-MM-DD");

      values = {
        ...formValues,
        scheduledContent: JSON.stringify({
          ...rest,
          startDate,
          endDate,
        }),
      };
    }

    dispatch(
      saveMiddlewareData({
        params: {
          values,
          type:
            activeTab === "scheduled-content"
              ? "scheduled-content"
              : "default-content",
        },
        resolve: () => {
          ToasterHandler(
            "looks good",
            Localise(messages, `${title} content has been updated.`)
          );
          formikBag.setSubmitting(false);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  };

  return (
    <Form
      initialValues={{
        component,
        context,
        scheduledContent: scheduledContent === "" ? content : scheduledContent,
        assetId,
        actualContext,
        content,
        error,
      }}
      onSubmit={allowSyndicate ? () => setShowPopup(!showPopup) : onSubmit}
      validationSchema={validationSchema}
      render={({
        values,
        errors,
        initialValues: {
          content: initialDefaultContent,
          scheduledContent: initialScheduledContent,
        },
        dirty,
        ...formikBag
      }) => {
        const {
          content: changedDefaultContent,
          scheduledContent: changedScheduledContent,
        } = values;
        const triggerScheduledContentModal =
          initialScheduledContent !== changedScheduledContent &&
          activeTab === "default-content";
        const triggerActiveContentModal =
          initialDefaultContent !== changedDefaultContent &&
          activeTab === "scheduled-content";
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (
            (triggerActiveContentModal || triggerScheduledContentModal) &&
            dirty
          )
            setShowModal(!showModal);
        }, [triggerActiveContentModal, triggerScheduledContentModal, dirty]);

        return (
          <View style={tw("flex flex-col")}>
            {scheduledContentComponents.includes(component) &&
              scheduledContent === "" &&
              activeTab === "default-content" &&
              !isFutureTabExists && (
                <SecondaryButton
                  title={"Create Temporary Content"}
                  action={() => {
                    dispatch(
                      setScheduledContentScreenTabs({
                        type: "add",
                        value: {
                          key: "scheduled-content",
                          title: "Temporary Content",
                        },
                      })
                    );
                  }}
                  style={tw("flex flex-row items-start justify-end pb-1")}
                />
              )}
            {activeTab === "scheduled-content" && (
              <SecondaryButton
                title={"Delete Temporary Content"}
                action={() => {
                  dispatch(
                    deleteMiddlewareData({
                      params: {
                        component,
                        context,
                        assetId,
                        type: "scheduled-content",
                      },
                      resolve: () => {
                        ToasterHandler(
                          "Looks Good!",
                          Localise(
                            messages,
                            `Temporary Content has been deleted.`
                          )
                        );
                      },
                      reject: () => {
                        ToasterHandler(
                          "uh oh",
                          Localise(
                            messages,
                            "We were unable to process your request, please try again."
                          )
                        );
                      },
                    })
                  );
                  dispatch(
                    setScheduledContentScreenTabs({
                      type: "delete",
                      value: {
                        key: "scheduled-content",
                        title: "Temporary Content",
                      },
                    })
                  );
                }}
                style={tw("flex flex-row items-start justify-end pb-1")}
              />
            )}
            {fieldProps.ignoreHeading
              ? null
              : overrideHeader &&
                overrideHeader({
                  component,
                  context,
                  assetId,
                  actualContext,
                  title,
                  scheduledContent,
                  ...fieldProps,
                })}

            {fieldType === "HTMLText" && <HTMLTextEditor field="content" />}
            {fieldType === "BannerMetaData" && (
              <BannerMetaData
                field={
                  activeTab === "scheduled-content"
                    ? "scheduledContent"
                    : "content"
                }
                props={fieldProps}
                overrideHeader={overrideHeader}
              />
            )}
            {fieldType === "Navigation" && (
              <SortableNavigation
                field={
                  activeTab === "scheduled-content"
                    ? "scheduledContent"
                    : "content"
                }
                props={fieldProps}
              />
            )}
            {fieldType === "SEOText" && <SEOTextEditor field="content" />}
            {fieldType === "AnnouncementBanner" && (
              <AnnouncementBanner
                field={
                  activeTab === "scheduled-content"
                    ? "scheduledContent"
                    : "content"
                }
              />
            )}

            {fieldType === "ShopperApprovedReviews" && (
              <ShopperApprovedReviews
                field="content"
                enabled={fieldProps.ftdAdminOnly && isFTDUser}
              />
            )}
            {fieldType === "BrandSelector" && (
              <BrandSelector field="content" fieldProps />
            )}
            {fieldType === "ABTesting" && (
              <ABTesting
                field="content"
                enabled={fieldProps.ftdAdminOnly && isFTDUser}
              />
            )}
            {fieldType === "CheckoutCustomizations" && (
              <CheckoutCustomizations field="content" />
            )}
            <Text style={theme.CheckBox.errorStyle}>
              {Localise(messages, error)}
            </Text>
            <SaveCancelButtons />
            <ConfirmModal
              modalVisible={!!showPopup}
              handlePrimary={() => {
                onSubmit(values, formikBag);
                setShowPopup(!showPopup);
              }}
              handleSecondary={() => {
                setShowPopup(!showPopup);
                formikBag.setSubmitting(false);
              }}
              data={{
                modal: {
                  primary: "Update",
                  content: Localise(
                    messages,
                    "This change will apply to All shops and will override any local shop changes."
                  ),
                },
              }}
            />
            <ConfirmModal
              modalVisible={!!showModal}
              handlePrimary={() => {
                activeTab === "scheduled-content" &&
                  dispatch(
                    setScheduledContentScreenTabs({
                      type: "delete",
                      value: {
                        key: "scheduled-content",
                        title: "Temporary Content",
                      },
                    })
                  );
                dispatch(
                  setScheduledContentScreenTabs({
                    value:
                      activeTab === "default-content"
                        ? "scheduled-content"
                        : "default-content",
                  })
                );
                setShowModal(!showModal);
              }}
              handleSecondary={() => {
                setShowModal(!showModal);
                formikBag.setSubmitting(false);
              }}
              data={{
                modal: {
                  primary: "Save",
                  content: `Please save the content before you switch to next tab`,
                },
              }}
            />
          </View>
        );
      }}
    />
  );
};

export default SiteContentFields;
