import React, { useEffect, useContext } from "react";
import { Text, Button } from "react-native-elements";
import { Platform, View, ActivityIndicator } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { useFormikContext } from "formik";

import tw from "tailwind-rn";
import moment from "moment";

import { ToasterHandler } from "components/elements";
import { FormFieldAutoComplete } from "components/elements/forms";

import { fonts, colors } from "styles/theme";

import {
  setDesigner,
  setUpsertDesignerStatus,
} from "library/sagas/ongoing/global-data/slice";
import { setActions } from "library/sagas/ongoing/current-orders/slice";
import { selectRecordData } from "library/sagas/ongoing/current-orders/selector";
import {
  selectDesignerInfo,
  selectUpsertDesignerStatus,
} from "library/sagas/ongoing/global-data/selector";

import I18NContext from "library/contexts/i18N";

import useStateIfMounted from "library/utils/useStateIfMounted";
import { AppSettingsContext } from "library/contexts/appSettings";
import { hasDesignCenterFeature } from "library/utils/featureAvailability";
import { filterDesignerSuggestions } from "components/views/drawer/bulk-scan/helper";

const UpsertDesigner = ({
  triggerAction,
  doNotFill,
  index,
  listingInfo = {},
  statusList,
  initData,
  fetchData,
  scrollViewRef,
  selectedActionRef,
  tabDetailScrollRef,
}) => {
  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext();

  const { messages: locMessages, Localise } = useContext(I18NContext);
  const { permissions } = useContext(AppSettingsContext);

  const orderDetailResponse = useSelector(selectRecordData);
  const designersInfo = useSelector(selectDesignerInfo);
  const upsertDesignerStatus = useSelector(selectUpsertDesignerStatus);

  const [designerQuery, setDesignerQuery] = useStateIfMounted("");
  const orderDetails = orderDetailResponse.orderItems[index];

  const {
    direction: orderDirection,
    deliveryInfo: { deliveryMethod },
    designer: designerInfo = { name: "" },
    messages,
    receivingMember,
    sendingMember,
    status,
    orderItemId,
    hasSettlementError = false,
  } = orderDetails;

  const assignDesigner = ![
    "CANCELLED",
    "REJECTED",
    "FORFEITED",
    "ERROR",
    "DELIVERED",
    "DESIGNED",
    "OUT_FOR_DELIVERY",
    "FORWARDED",
    "NEW",
    "INCOMING",
  ].includes(status);

  const showAssignedDesigner = ![
    "ACKNOWLEDGED",
    "NEW",
    "DESIGN",
    "ACCEPTED",
    "INCOMING",
    "DS_REQUESTED",
    "DS_REQUEST_PENDING",
  ].includes(status);

  const sourceMemberCode =
    orderDirection === "INBOUND"
      ? receivingMember?.memberCode
      : sendingMember?.memberCode;

  const hasDesignCenter = hasDesignCenterFeature(permissions, sourceMemberCode);

  useEffect(() => {
    designerInfo.name?.length > 0 &&
      setFieldValue(`designerName`, designerInfo.name);
  }, []);

  useEffect(() => {
    // scrolling to end of the page after assigning or removing of designer.
    if (selectedActionRef.current === "assign-to-designer") {
      scrollToEndOfPage();
      selectedActionRef.current = "";
    }
  }, [selectedActionRef.current]);

  const scrollToEndOfPage = () => {
    // Adding setTimeout here in order to scroll to bottom payment section o/w it is scrolling upto 90% only
    setTimeout(() => {
      scrollViewRef &&
        scrollViewRef.current.scrollToEnd({
          animated: true,
        });
      // order details tab structure scroll
      tabDetailScrollRef &&
        tabDetailScrollRef?.current?.scrollToEnd({ animated: true });
    }, 50);
  };

  // Filtering designersInfo by sourceMemberCode to avoid common designer data.
  const designersList = designersInfo?.length
    ? filterDesignerSuggestions(designersInfo, [sourceMemberCode]).filter(
        ({ fullName = "" }) =>
          fullName.toLowerCase().includes(designerQuery.trim().toLowerCase())
      )
    : [];

  const { actions = {} } = listingInfo;
  const { appliedFilters = [], filters = [] } = actions;

  const updateDesigner = (updateData = {}) => {
    const {
      selectedValue = {},
      recordId = "",
      deliveryMethod = "",
      sourceMemberCode = "",
    } = updateData;

    const { fullName = "", id = "" } = selectedValue;
    fullName !== "" && setFieldValue(`designerName`, `${fullName}`);

    const timeStampLocal = moment().subtract(2, "minute").utc().format();

    dispatch(
      setDesigner({
        designer: {
          id: id,
          name: `${fullName}`,
        },
        orders: [
          {
            orderId: orderDetailResponse?.orderId,
            orderItemId: orderItemId,
            deliveryMethod: deliveryMethod,
          },
        ],
        memberCode: sourceMemberCode,
        listingInfo,
        timeStampLocal,
        statusList,
        initData,
        fetchData,
        resolve: (text) => {
          ToasterHandler("success", Localise(messages, text));
          triggerAction({ action: "assign-to-designer", recordId });
        },
        reject: (error) => {
          ToasterHandler("error", Localise(messages, error));
        },
      })
    );

    if (appliedFilters?.length > 0 || filters?.length > 0) {
      dispatch(
        setActions({
          appliedFilters: [...appliedFilters],
          filters: [...filters],
        })
      );
    }
  };

  const handleDeleteDesigner = () => {
    if (designerInfo.name?.length !== 0) {
      dispatch(setUpsertDesignerStatus("progress"));
      updateDesigner({
        recordId: orderItemId,
        deliveryMethod,
        sourceMemberCode,
      });
    }
    setDesignerQuery("");
  };

  const isDesignerAssigned = designerInfo.name?.length > 0;
  return (
    <View
      style={{
        zIndex: 5,
        ...tw("my-2"),
      }}
      pointerEvents={doNotFill ? "none" : "auto"}
    >
      {!hasSettlementError &&
        orderDirection === "INBOUND" &&
        assignDesigner &&
        hasDesignCenter && (
          <View testID="assign-designer" style={[tw("flex"), { zIndex: 1 }]}>
            <View style={tw("flex flex-row items-center pb-2")}>
              <Text style={{ fontSize: 15, fontWeight: "bold" }}>
                {Localise(locMessages, "Designer")}
              </Text>
              {upsertDesignerStatus === "progress" && (
                <ActivityIndicator
                  style={{ marginLeft: 5 }}
                  color={colors.activityIndicator}
                  testID={"loader"}
                />
              )}
            </View>
            <View
              style={{ opacity: isDesignerAssigned ? 0.7 : 1 }}
              pointerEvents={isDesignerAssigned ? "none" : "auto"}
            >
              <FormFieldAutoComplete
                testID="designersList-info"
                data={designersList}
                showOnFocus={true}
                updateOnBlur={true}
                clearTextOnBackTab={true}
                onChangeText={(text) => {
                  setDesignerQuery(text);
                }}
                placeholder={"Select Designer"}
                listDisplayValues={["fullName"]}
                outerContainerStyle={{
                  width: "100%",
                  paddingHorizontal: 0,
                }}
                name="designerName"
                isMultiSelect={false}
                isFormatData={true}
                handleOnBlur={handleDeleteDesigner}
                onSelect={(selectedValue) => {
                  dispatch(setUpsertDesignerStatus("progress"));
                  updateDesigner({
                    recordId: orderItemId,
                    deliveryMethod,
                    sourceMemberCode,
                    selectedValue,
                  });
                }}
                onClear={() => {
                  setFieldValue(`designerName`, ``);
                  setDesignerQuery("");
                }}
                popperPlacement={"top"}
                showOnSelect={Platform.OS === "web" ? true : false}
              />
            </View>
            {isDesignerAssigned && (
              <Button
                testID={"remove-designer"}
                accessibilityLabel={"remove-designer"}
                buttonStyle={{
                  paddingVertical: 5,
                  paddingHorizontal: 10,
                  marginRight: 0,
                }}
                containerStyle={{
                  justifyContent: "center",
                  alignItems: "flex-end",
                  marginRight: 0,
                  margin: 0,
                }}
                onPress={() => handleDeleteDesigner()}
                title={Localise(locMessages, "Remove")}
                disabled={upsertDesignerStatus === "progress"}
              />
            )}
          </View>
        )}

      {designerInfo.name?.length > 0 && showAssignedDesigner && (
        <View style={{ zIndex: -1 }}>
          <Text
            style={{
              fontSize: 15,
              fontWeight: "bold",
              color: colors.primary,
              paddingVertical: 2,
              paddingBottom: 10,
            }}
            testID={"showing_assigned_designer"}
          >
            {Localise(messages, "Designer")}
          </Text>
          <Text
            style={[
              {
                ...fonts.default,
                fontSize: 14,
              },
            ]}
          >
            {designerInfo.name}
          </Text>
        </View>
      )}
    </View>
  );
};

export default UpsertDesigner;
