import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";

export const initialState = {
  scannedOrders: [],
  orderActions: {},
  actionsQR: {},
  ordersCount: 0,
  actions: {
    limit: {
      orders: 0,
    },
  },
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    resetSlice: (state) => {
      Object.assign(state, initialState);
    },
    setPageAction: (state, action) => {
      const { type, value } = action.payload;
      if (type === "limit") {
        const { section } = action.payload;
        state.actions[type][section] = value;
      } else state.actions[type] = value;
    },
    setScannedOrders: (state, action) => {
      const index = state.scannedOrders.findIndex(
        (item) => item.orderItemId === action.payload.orderItemId
      );

      // If the orderItemId exists in the array, update the object
      if (index !== -1) {
        state.scannedOrders[index] = action.payload;
      } else {
        // If the orderItemId doesn't exist, add the updated object to the array
        state.scannedOrders.push(action.payload);
      }
      state.ordersCount = state.scannedOrders.length;
    },
    setMeatBallMenuActions: (state, action) => {
      const { recordId, menuOptions, hasPendingPayLaterPayment } =
        action.payload;
      const filterMenuOptions = [];
      menuOptions.forEach((each) => {
        if (
          [
            "accept",
            "design",
            "designed",
            "out-for-delivery",
            "assign-to-designer",
            ...(!hasPendingPayLaterPayment ? ["delivery-confirmation"] : []),
          ].includes(each.key)
        ) {
          filterMenuOptions.push(each.key);
        }
      });
      state.scannedOrders.forEach((item) => {
        if (item.orderItemId === recordId) {
          state.orderActions = {
            ...state.orderActions,
            [`${recordId}`]: filterMenuOptions,
          };
        }
      });
    },
    updateActionsForOrder: (state, action) => {
      const orderActions = state.orderActions[action.payload.recordId] || [];
      state.orderActions[action.payload.recordId] = [...orderActions];
    },
    removeTileById: (state, action) => {
      state.scannedOrders.forEach((item, index) => {
        if (item.orderItemId === action.payload) {
          state.scannedOrders.splice(index, 1);
        }
      });
      delete state.orderActions[action.payload];
      state.ordersCount = state.scannedOrders.length;
    },
    removeTileByIds: (state, action) => {
      // removing from the scannedOrders list
      state.scannedOrders = state.scannedOrders.filter(
        (each) => !action.payload.includes(each.orderItemId)
      );
      // Removing respective order actions
      action.payload.forEach((each) => delete state.orderActions[each]);
      state.ordersCount = state.scannedOrders.length;
    },
    setActionsQR: (state, action) => {
      state.actionsQR = action.payload;
    },

    getOrderDetailsFromScan: () => {},
  },
});

export const {
  getOrderDetailsFromScan,
  setScannedOrders,
  setMeatBallMenuActions,
  removeTileById,
  removeTileByIds,
  resetSlice,
  setPageAction,
  setActionsQR,
  updateActionsForOrder,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
