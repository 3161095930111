import * as Yup from "yup";

export const getValidationSchema = (
  authToken,
  memberCodes = [],
  Localise,
  messages
) => {
  const validationSchema = Yup.object().shape({
    customerName: Yup.string()
      .label("customeName")
      .min(2, Localise(messages, "Name must have at least 2 characters"))
      .required(Localise(messages, "Enter Name")),
    customerEmail: Yup.string()
      .label("customerEmail")
      .email(Localise(messages, "Enter a valid email"))
      .required(Localise(messages, "Enter Email")),
    ...(authToken && {
      storeName: Yup.string()
        .label("storeName")
        .when("hasMultipleShops", {
          is: (value) => value === true,
          then: Yup.string().required(Localise(messages, "Select a Shop")),
        }),
    }),
    ...(authToken &&
      memberCodes.length === 1 && {
        storeName: Yup.string()
          .label("storeName")
          .min(
            2,
            Localise(messages, "Store Name must have at least 2 characters")
          )
          .required(Localise(messages, "Enter Store Name")),
      }),
    ...(!authToken && {
      shopName: Yup.string()
        .label("shopName")
        .min(
          2,
          Localise(messages, "Store Name must have at least 2 characters")
        )
        .required(Localise(messages, "Enter Store Name")),
      storeCode: Yup.string()
        .label("storeCode")
        .min(2, Localise(messages, "Shop Code must have at least 2 characters"))
        .required(Localise(messages, "Enter Shop Code")),
    }),

    feedbackCategory: Yup.string()
      .label("feedbackCategory")
      .required(Localise(messages, "Select Feedback Category")),
    feedbackNotes: Yup.string()
      .label("feedbackNotes")
      .min(10, Localise(messages, "Feedback must have at least 10 characters"))
      .max(1000, Localise(messages, "Feedback cannot exceed 1000 characters"))
      .required(Localise(messages, "Enter Feedback Details")),
  });
  return validationSchema;
};
