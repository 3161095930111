import React from "react";
import { Text, View } from "react-native";
import { colors } from "styles/theme";
import I18NContext from "library/contexts/i18N";

export const SetupInstructions = () => {
  const { Localise, messages } = React.useContext(I18NContext);

  return (
    <View
      style={{
        padding: 20,
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
        borderRadius: 5,
      }}
    >
      <View style={{ paddingBottom: 20, color: colors.primary }}>
        <Text
          style={{
            color: colors.primary,
          }}
        >
          {Localise(messages, "How to Setup Your Terminal")}
        </Text>
        <Text
          style={{
            color: colors.primary,
          }}
        >
          {Localise(
            messages,
            "Once you have plugged in and turned on your new device,"
          )}
        </Text>
        <Text
          style={{
            color: colors.primary,
          }}
        >
          {Localise(
            messages,
            "follow the steps below to connect your Terminal to MercuryHQ"
          )}
        </Text>
      </View>
      <View>
        <Text
          style={{
            color: colors.primary,
          }}
        >
          {Localise(
            messages,
            "1 - Click Generate Code and enter the Activation code (expires in 24 hours) into your device. This step is only required the first time you link your terminal to MercuryHQ."
          )}
        </Text>
        <Text
          style={{
            color: colors.primary,
          }}
        >
          {Localise(messages, "2 - Name your terminal")}
        </Text>
        <Text
          style={{
            color: colors.primary,
          }}
        >
          {Localise(
            messages,
            "3 - Add the Serial Number found on the back of your device"
          )}
        </Text>
        <Text
          style={{
            color: colors.primary,
          }}
        >
          {Localise(messages, "4 - Click Save to complete activation")}
        </Text>
      </View>
    </View>
  );
};

export default SetupInstructions;
