import { createStyles, minWidth } from "react-native-media-queries";
import { colors } from "styles/theme";

const base = {
  labelText: {
    width: "30%",
  },
  valueText: {
    width: "70%",
    textTransform: "capitalize",
  },
  priceLabelText: {
    width: "40%",
  },
  orderAmount: {
    width: "20%",
    color: colors.primary,
  },
  refundAmount: {
    width: "20%",
    color: colors.red,
  },
  refundedMargin: {
    width: "60%",
  },
  newPriceWidth: { width: "20%" },
  paymentType: {
    width: "auto",
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.grayScaleLight,
    textAlign: "center",
    paddingHorizontal: 15,
    paddingVertical: 8,
  },
  paymentValue: {
    width: "20%",
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.grayScaleLight,
    paddingHorizontal: 10,
    paddingVertical: 8,
  },
};

const desktopStyles = {
  labelText: {
    width: "30%",
  },
  valueText: {
    width: "70%",
    textTransform: "capitalize",
  },
  priceLabelText: {
    width: "35%",
  },
  orderAmount: {
    width: "20%",
    color: colors.primary,
  },
  refundedMargin: {
    width: "55%",
  },
  refundAmount: {
    width: "20%",
    color: colors.red,
  },
  newPriceWidth: { width: "25%" },
};

export default createStyles(base, minWidth(1550, desktopStyles));
