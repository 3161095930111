import { select, call, put, takeLatest } from "redux-saga/effects";
import * as Navigation from "library/utils/navigation.js";
import { setData, setAction, setInit } from "./slice";
import { selectActions } from "./selector";
import { setAPIResponse as setCommonAPIResponse } from "../common/slice";
import {
  selectCollectionLookup,
  selectProductLookup,
} from "../common/selector";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import toLower from "lodash/toLower";

function* handleUIRefresh() {
  const collectionLookup = yield select(selectCollectionLookup);
  if (isEmpty(collectionLookup)) return;

  const productLookup = yield select(selectProductLookup);
  const actions = yield select(selectActions);
  const newData = applyPageActions(collectionLookup, actions);
  const collectionsData = processData(productLookup, actions, newData);

  yield put(setData(collectionsData));
}

function* handleDataRefresh(action = {}) {
  const { section } = get(action, "payload", {});

  const { name: screen } = yield Navigation.getCurrentRoute(true);
  if (section === "collections" && screen === "listCollections") {
    yield call(handleUIRefresh);
  }
}

const applyPageActions = (collections, { searchText = "", filters = [] }) => {
  return Object.values(collections).filter((entry) => {
    let matchesFilter = true;

    if (searchText) {
      matchesFilter = toLower(entry.name).includes(toLower(searchText));
    }

    if (filters.length) {
      const filterValues = filters.map((e) => e.value);

      if (
        matchesFilter &&
        (filterValues.includes("myCollections") ||
          filterValues.includes("globalCollections"))
      ) {
        if (filterValues.includes("myCollections") && entry.group === "florist")
          matchesFilter = true;
        else if (
          filterValues.includes("globalCollections") &&
          entry.group === "mol"
        )
          matchesFilter = true;
        else matchesFilter = false;
      }

      if (
        matchesFilter &&
        (filterValues.includes("active") || filterValues.includes("inactive"))
      ) {
        if (filterValues.includes("active") && entry.status === "active")
          matchesFilter = true;
        else if (
          filterValues.includes("inactive") &&
          entry.status === "inactive"
        )
          matchesFilter = true;
        else matchesFilter = false;
      }
    }

    return matchesFilter;
  });
};

function processData(productLookup, actions, newData) {
  const { collectionSortBy } = actions;
  const [sortField, sortOrder] = collectionSortBy.split(/::/);

  let collectionImages = [];

  const findUniqueImage = (mappedProducts = [], exclusion = []) => {
    const pid =
      mappedProducts.find((p) => {
        const { image = "" } = productLookup[p] || {};
        return !!image && !exclusion.includes(image);
      }) ||
      mappedProducts.find((p) => {
        const { image = "" } = productLookup[p] || {};
        return !!image;
      });
    return get(productLookup[pid], "image", "");
  };

  return orderBy(
    newData.map((c = {}) => {
      const { productIds = [] } = c;
      const image = findUniqueImage(productIds, collectionImages);

      collectionImages.push(image);

      return {
        ...c,
        image,
      };
    }),
    (e) => toLower(e[sortField]),
    sortOrder
  );
}

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeLatest(setInit.type, handleUIRefresh);
  yield takeLatest(setAction.type, handleUIRefresh);
  yield takeLatest(setCommonAPIResponse.type, handleDataRefresh);
}

export default watchSaga;
