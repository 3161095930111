/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, memo, useMemo } from "react";
import { View, Platform } from "react-native";
import { Text } from "react-native-elements";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useSelector } from "react-redux";
import tw from "tailwind-rn";

import { selectQRScanReprintWarning } from "library/sagas/views/home/drawer/delivery/selector";
import { selectQrScanVariant } from "library/sagas/ongoing/global-data/selector";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { colors, backgroundColors, theme, fontWeights } from "styles/theme";

import QrCodeScanner from "components/views/drawer/qr-code-scanner";

// eslint-disable-next-line react/display-name
const RouteQRScan = memo(
  ({
    ordersList,
    setScannedOrder,
    disableSave,
    showCFROptimiseIcon,
    isPastRoute,
    eraseMap,
    routeData,
    loading,
    localPermissions,
    OptimizeAction,
    saveAndOptimize,
  }) => {
    const { messages, Localise } = useContext(I18NContext);
    const { isMobile } = useContext(DeviceContext);
    const qrScanVariant = useSelector(selectQrScanVariant);
    const qrScanReprintWarning = useSelector(selectQRScanReprintWarning);

    const ordersCount = useMemo(
      () =>
        ordersList.ordersInRoute?.filter(
          (ord) => ord.stopType === "ORDER" || !ord.stopType
        )?.length,
      [JSON.stringify(ordersList.ordersInRoute)]
    );

    const showSaveOptimize = useMemo(() => ordersCount > 0, [ordersCount]);
    const disableSaveOptimise =
      isPastRoute ||
      (!!routeData?.isOptimized && !eraseMap) ||
      !localPermissions?.isOptimizeEnabled;

    return (
      <>
        {qrScanVariant === "order-route-scan" ? (
          <View
            style={[
              qrScanVariant === "order-route-scan"
                ? {
                    backgroundColor: backgroundColors.secondary,
                  }
                : tw("p-0 w-0 h-0 opacity-0"),
            ]}
          >
            {Platform.OS !== "web" && (
              <View
                style={[
                  tw("items-center mb-3"),
                  { backgroundColor: backgroundColors.secondary },
                ]}
              >
                <Text
                  style={{
                    fontWeight: fontWeights.bold,
                    color: colors.primary,
                    fontSize: 16,
                  }}
                >
                  {Localise(messages, "Scan Orders")}
                </Text>
              </View>
            )}
            <View
              style={{
                backgroundColor: backgroundColors.secondary,
                height:
                  qrScanVariant === "order-route-scan"
                    ? Platform.OS === "ios"
                      ? 330
                      : Platform.OS === "android"
                      ? 290
                      : 0
                    : 0,
                zIndex: 0,
              }}
            >
              {!qrScanReprintWarning?.needReprint ? (
                <QrCodeScanner
                  setScannedOrder={setScannedOrder}
                  ordersList={ordersList}
                />
              ) : null}
            </View>
            {Platform.OS !== "web" ? (
              <View
                style={[
                  tw("items-center my-3"),
                  {
                    borderWidth: 1,
                    borderRadius: 5,
                    borderColor: colors.grayScaleLight,
                    padding: 10,
                    marginTop: 30,
                    backgroundColor: backgroundColors.secondary,
                    width: isMobile
                      ? Platform.OS === "ios"
                        ? "100%"
                        : "103%"
                      : 500,
                    top: Platform.OS === "ios" ? 0 : 12,
                  },
                ]}
              >
                {!loading ? (
                  <Text
                    style={{
                      color: colors.highlighter,
                      fontWeight: fontWeights.semiBold,
                      fontSize: 16,
                    }}
                  >
                    {ordersCount} {Localise(messages, "orders added")}
                  </Text>
                ) : null}

                {showSaveOptimize ? (
                  <TouchableOpacity
                    style={{
                      ...theme.Button.buttonStyle,
                      padding: 10,
                      margin: 5,
                      ...(disableSaveOptimise && {
                        backgroundColor: "#d3d3d380",
                      }),
                    }}
                    disabled={disableSaveOptimise}
                    onPress={() => {
                      if (disableSave) {
                        OptimizeAction();
                      } else {
                        saveAndOptimize();
                      }
                    }}
                    testID="scanOptimize"
                    accessibilityLabel="scanOptimize"
                  >
                    <Text
                      style={{
                        ...theme.Button.titleStyle,
                        ...(disableSaveOptimise && { color: "#808080" }),
                      }}
                    >
                      {Localise(
                        messages,
                        disableSave ? "Optimize" : "Save & Optimize"
                      )}{" "}
                      {showCFROptimiseIcon ? `$` : ``}
                    </Text>
                  </TouchableOpacity>
                ) : null}
              </View>
            ) : null}
          </View>
        ) : null}
      </>
    );
  }
);

export default RouteQRScan;
