import isObject from "lodash/isObject";

const parseToObject = (value) => {
  let response = value;
  while (response && !isObject(response)) {
    response = JSON.parse(response);
  }
  return response;
};

const processResponse = (res) => {
  let obj = {};
  const arrayValueKeys = [
    "addon_advance_route_optimization_access_roles",
    "pickup_locations",
  ];
  res?.preferences.map((ele) => {
    let key = ele["id"];
    obj[key] =
      key === "card_settings" ||
      key.includes("print_settings") ||
      key.includes("terminal_settings")
        ? parseToObject(ele.values[0])
        : arrayValueKeys.includes(key)
        ? ele.values
        : ele.values[0];
  });
  return obj;
};

const processShopSettingsResponse = (res) => {
  let obj = {};
  res.preferences.map((ele) => {
    let key = ele["id"];
    obj[key] = ele.values[0];
  });
  return obj;
};

const processNotificationMessages = (response, shopSettings) => {
  let final = [];
  response &&
    response.forEach((eachObj, index) => {
      const customisedValue =
        shopSettings[eachObj.id]?.length > 0
          ? JSON.parse(shopSettings[eachObj.id])
          : "";
      const processedEmailBody = (
        customisedValue?.emailBody || response[index]?.values?.emailBody
      )?.replace(/`/g, '"');

      const fetchEachstatus = {
        smsToggle:
          customisedValue?.smsToggle === undefined
            ? response[index]?.values?.smsToggle
            : customisedValue?.smsToggle,
        smsBody: customisedValue?.smsBody || response[index]?.values?.smsBody,
        emailToggle:
          customisedValue?.emailToggle === undefined
            ? response[index]?.values?.emailToggle
            : customisedValue?.emailToggle,
        emailSubject:
          customisedValue?.emailSubject ||
          response[index]?.values?.emailSubject,
        emailBody: processedEmailBody,
        remindInDays:
          customisedValue?.remindInDays ||
          response[index]?.values?.remindInDays,
      };

      final.push({ id: eachObj.id, values: fetchEachstatus });
    });

  return final;
};

export {
  processResponse,
  processShopSettingsResponse,
  processNotificationMessages,
};
