import React from "react";
import { View } from "react-native";
import { colors } from "styles/theme";

const Divider = ({ style }) => (
  <View
    style={{
      borderBottomColor: colors.light,
      borderBottomWidth: 1,
      marginVertical: 10,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.4,
      shadowRadius: 7,
      ...style,
    }}
  />
);

export default Divider;
