import { setQuickAction as setQuickCollectionAction } from "library/sagas/views/home/drawer/product-catalog/collection/slice";
import { setQuickAction as setQuickProductAction } from "library/sagas/views/home/drawer/product-catalog/product/slice";
import { setQuickAction as setQuickCatalogAction } from "library/sagas/views/home/drawer/product-catalog/catalog/slice";
import {
  navigateScreen,
  setSideCar,
} from "library/sagas/views/home/drawer/product-catalog/common/slice";

import { colors } from "styles/theme";

export const getColorCodes = (input) => {
  let bgColor = colors.ftd,
    textColor = colors.secondary;

  if (input === "local") {
    bgColor = "#3A7D8D";
  } else if (input === "reserved") {
    bgColor = colors.highlighter;
  } else if (input === "inactive") {
    bgColor = "#F2F2F2";
    textColor = colors.primary;
  }

  return {
    bgColor,
    textColor,
  };
};

export const ProductSortProps = (Localise, messages) => [
  {
    label: Localise(messages, "Price: Low to High"),
    value: "price::asc",
  },
  {
    label: Localise(messages, "Price: High to Low"),
    value: "price::desc",
  },
  {
    label: Localise(messages, "Name: A-Z"),
    value: "name::asc",
  },
  {
    label: Localise(messages, "Name: Z-A"),
    value: "name::desc",
  },
];

export const CollectionSortProps = (Localise, messages) => [
  {
    label: Localise(messages, "Name: A-Z"),
    value: "name::asc",
  },
  {
    label: Localise(messages, "Name: Z-A"),
    value: "name::desc",
  },
];

export const CatalogMeatBallsMenu = (Localise, messages) =>
  [
    {
      label: "Activate",
      key: "status::active",
      condition: (entry = {}) =>
        entry.groupScope !== "reserved" && entry.status === "inactive",
      action: setQuickCatalogAction,
      success: (entry = {}) =>
        `${Localise(messages, "Catalog")} "${entry.name}" ${Localise(
          messages,
          "has been activated"
        )}`,
      error: () => "We were unable to process your request, please try again.",
    },
    {
      label: "Deactivate",
      key: "status::inactive",
      condition: (entry = {}) =>
        entry.groupScope !== "reserved" && entry.status === "active",
      action: setQuickCatalogAction,
      success: (entry = {}) =>
        `${Localise(messages, "Catalog")} "${entry.name}" ${Localise(
          messages,
          "has been de-activated"
        )}`,
      error: () => "We were unable to process your request, please try again.",
    },
  ].filter(() => false);

export const CollectionMeatBallsMenu = (Localise, messages) => [
  {
    label: "Activate",
    key: "status::active",
    condition: (entry = {}) =>
      entry.status === "inactive" && entry.totalActiveProductsCount > 0,
    action: setQuickCollectionAction,
    success: (entry = {}) =>
      `${Localise(messages, "Collection")} "${entry.name}" ${Localise(
        messages,
        "has been activated"
      )}`,
    error: () => "We were unable to process your request, please try again.",
  },
  {
    label: "Deactivate",
    key: "status::inactive",
    condition: (entry = {}) => entry.status === "active",
    action: setQuickCollectionAction,
    success: (entry = {}) =>
      `${Localise(messages, "Collection")} "${entry.name}" ${Localise(
        messages,
        "has been de-activated"
      )}`,
    error: () => "We were unable to process your request, please try again.",
  },
  {
    label: "Delete",
    key: "delete",
    condition: (entry = {}) => entry.group !== "mol" && entry.catalogManager,
    action: setQuickCollectionAction,
    modal: {
      primary: "Delete",
      secondary: "Nevermind",
      content: "Are you sure you want to delete this collection?",
    },
    success: (entry = {}) =>
      `${Localise(messages, "Collection")} "${entry.name}" ${Localise(
        messages,
        "has been deleted"
      )}`,
    error: () => "We were unable to process your request, please try again.",
  },
];

export const ProductMeatBallsMenu = (Localise, messages) => [
  {
    label: "Activate",
    key: "status::active",
    condition: (entry = {}) => entry.status === "inactive",
    action: setQuickProductAction,
    success: (entry = {}) =>
      `${Localise(messages, "Product")} "${entry.name}" ${Localise(
        messages,
        "has been activated"
      )}`,
    error: () => "We were unable to process your request, please try again.",
  },
  {
    label: "Deactivate",
    key: "status::inactive",
    condition: (entry = {}) => entry.status === "active",
    action: setQuickProductAction,
    success: (entry = {}) =>
      `${Localise(messages, "Product")} "${entry.name}" ${Localise(
        messages,
        "has been de-activated"
      )}`,
    error: () => "We were unable to process your request, please try again.",
  },
  {
    label: "Delete",
    key: "delete",
    condition: (entry = {}) => entry.group !== "mol" && entry.catalogManager,
    action: setQuickProductAction,
    modal: {
      primary: "Delete",
      secondary: "Nevermind",
      content: "Are you sure you want to delete this product?",
    },
    success: (entry = {}) =>
      `${Localise(messages, "Product")} "${entry.name}" ${Localise(
        messages,
        "has been deleted"
      )}`,
    error: () => "We were unable to process your request, please try again.",
  },
  {
    label: "Duplicate",
    key: "duplicate",
    condition: (entry = {}) =>
      entry.catalogManager && entry.productId !== "GIFT_CARD",
    action: setQuickProductAction,
    error: () => "We were unable to process your request, please try again.",
  },
];

export const CollectionProductMeatBallsMenu = (Localise, messages, handle) => [
  {
    label: "Remove from Collection",
    key: `removeFromCollection::${handle}`,
    condition: () => true,
    action: setQuickCollectionAction,
    success: (entry = {}) =>
      `${Localise(messages, "Product")} "${entry.name}" ${Localise(
        messages,
        "has been removed from collection"
      )}`,
    error: () => "We were unable to process your request, please try again.",
  },
];

export const CatalogBulkActions = (
  Localise,
  messages,
  allowSyndicate,
  catalogManager
) => {
  const modal = allowSyndicate
    ? {
        primary: "Update",
        secondary: "Nevermind",
        content:
          "This change will apply to All shops and will override any local shop changes.",
      }
    : {};
  const deleteProducts = catalogManager
    ? [
        {
          label: Localise(messages, "Delete"),
          value: "delete::true",
          modal: {
            ...modal,
            primary: "Delete",
            content: `${
              allowSyndicate ? modal.content + "\n\n" : ""
            }Are you sure you want to delete the selected Product(s)? Only products you have created will be deleted.`,
          },
        },
      ]
    : [];
  const showRushAndLocalDel = false;

  return [
    {
      label: Localise(messages, "Take Action"),
      value: "",
    },
    {
      label: Localise(messages, "Activate"),
      value: "active::true",
      modal,
    },
    {
      label: Localise(messages, "Deactivate"),
      value: "active::false",
      modal,
    },
    {
      label: Localise(messages, "Change Price"),
      value: "prices::",
      modal,
    },
    {
      label: Localise(messages, "Add to Collection"),
      value: "collections::::add",
      modal,
    },
    {
      label: Localise(messages, "Remove from Collection"),
      value: "collections::::remove",
      modal: {
        ...modal,
        primary: "Remove",
        content: `${
          allowSyndicate ? modal.content + "\n\n" : ""
        }Are you sure you want to remove the selected Product(s) from collection?`,
      },
    },
    {
      label: Localise(messages, "Sold Out"),
      value: "soldOut::true",
      modal,
    },
    {
      label: Localise(messages, "Remove Sold Out"),
      value: "soldOut::false",
      modal,
    },
    {
      label: Localise(messages, "Call For Price"),
      value: "callForPrice::true",
      modal,
    },
    {
      label: Localise(messages, "Remove Call For Price"),
      value: "callForPrice::false",
      modal,
    },
    {
      label: Localise(messages, "In-Store Pickup Only"),
      value: "instore::true",
      modal,
    },
    {
      label: Localise(messages, "Remove In-Store Pickup Only"),
      value: "instore::false",
      modal,
    },
    ...(showRushAndLocalDel
      ? [
          {
            label: Localise(messages, "Rush Delivery"),
            value: "excludeFromRushDelivery::false",
          },
          {
            label: Localise(messages, "Remove Rush Delivery"),
            value: "excludeFromRushDelivery::true",
          },
          {
            label: Localise(messages, "Local Delivery Only"),
            value: "localDelOnly::true",
          },
          {
            label: Localise(messages, "Remove Local Delivery Only"),
            value: "localDelOnly::false",
          },
        ]
      : []),
    ...deleteProducts,
  ];
};

export const AddonBulkActions = (
  Localise,
  messages,
  allowSyndicate,
  catalogManager,
  isProductPage
) => {
  const modal = allowSyndicate
    ? {
        primary: "Update",
        secondary: "Nevermind",
        content:
          "This change will apply to All shops and will override any local shop changes.",
      }
    : {};
  const deleteAddons = catalogManager
    ? [
        {
          label: Localise(messages, "Delete"),
          value: "delete::true",
          modal: {
            ...modal,
            primary: "Delete",
            content: `${
              allowSyndicate ? modal.content + "\n\n" : ""
            }Are you sure you want to delete the selected Product(s)? Only products you have created will be deleted.`,
          },
        },
      ]
    : [];

  return isProductPage
    ? [
        {
          label: Localise(messages, "Take Action"),
          value: "",
        },
        {
          label: Localise(messages, "Add to Collection"),
          value: "addons::::add",
        },

        {
          label: Localise(messages, "Remove from Collection"),
          value: "addons::::remove",
        },
      ]
    : [
        {
          label: Localise(messages, "Take Action"),
          value: "",
        },
        {
          label: Localise(messages, "Activate"),
          value: "active::true",
          modal,
        },
        {
          label: Localise(messages, "Deactivate"),
          value: "active::false",
          modal,
        },
        {
          label: Localise(messages, "Change Price"),
          value: "prices::",
          modal,
        },
        {
          label: Localise(messages, "Sold Out"),
          value: "soldOut::true",
          modal,
        },
        {
          label: Localise(messages, "Remove Sold Out"),
          value: "soldOut::false",
          modal,
        },
        {
          label: Localise(messages, "Add to Collection"),
          value: "addons::::add",
          modal,
        },
        {
          label: Localise(messages, "Remove from Collection"),
          value: "addons::::remove",
          modal: {
            ...modal,
            primary: "Remove",
            content: `${
              allowSyndicate ? modal.content + "\n\n" : ""
            }Are you sure you want to remove the selected addons(s) from collection(s)?`,
          },
        },
        ...deleteAddons,
      ];
};

export const CollectionBulkActions = (
  Localise,
  messages,
  allowSyndicate,
  catalogManager,
  entity = {}
) => {
  const modal = allowSyndicate
    ? {
        primary: "Update",
        secondary: "Nevermind",
        content:
          "This change will apply to All shops and will override any local shop changes.",
      }
    : {};
  const deleteCollections = catalogManager
    ? [
        {
          label: Localise(messages, "Delete"),
          value: "delete::true",
          modal: {
            ...modal,
            primary: "Delete",
            content: `${
              allowSyndicate ? modal.content + "\n\n" : ""
            }Are you sure you want to delete the selected Product(s)? Only products you have created will be deleted.`,
          },
        },
      ]
    : [];
  const showRushAndLocalDel = false;

  return [
    {
      label: Localise(messages, "Take Action"),
      value: "",
    },
    {
      label: Localise(messages, "Activate"),
      value: "active::true",
      modal,
    },
    {
      label: Localise(messages, "Deactivate"),
      value: "active::false",
      modal,
    },
    {
      label: Localise(messages, "Change Price"),
      value: "prices::",
      modal,
    },
    {
      label: Localise(messages, `Remove from "${entity.name}"`),
      value: `collections::${entity.handle}::remove`,
      modal: {
        ...modal,
        primary: "Remove",
        content: `${
          allowSyndicate ? modal.content + "\n\n" : ""
        }Are you sure you want to remove the selected Product(s) from collection?`,
      },
    },
    {
      label: Localise(messages, "Sold Out"),
      value: "soldOut::true",
      modal,
    },
    {
      label: Localise(messages, "Remove Sold Out"),
      value: "soldOut::false",
      modal,
    },
    {
      label: Localise(messages, "Call For Price"),
      value: "callForPrice::true",
      modal,
    },
    {
      label: Localise(messages, "Remove Call For Price"),
      value: "callForPrice::false",
      modal,
    },
    {
      label: Localise(messages, "In-Store Pickup Only"),
      value: "instore::true",
      modal,
    },
    {
      label: Localise(messages, "Remove In-Store Pickup Only"),
      value: "instore::false",
      modal,
    },
    ...(showRushAndLocalDel
      ? [
          {
            label: Localise(messages, "Rush Delivery"),
            value: "excludeFromRushDelivery::false",
          },
          {
            label: Localise(messages, "Remove Rush Delivery"),
            value: "excludeFromRushDelivery::true",
          },
          {
            label: Localise(messages, "Local Delivery Only"),
            value: "localDelOnly::true",
          },
          {
            label: Localise(messages, "Remove Local Delivery Only"),
            value: "localDelOnly::false",
          },
        ]
      : []),
    ...deleteCollections,
  ];
};

export const CatalogPriceMinimumBulkActions = (
  Localise,
  messages,
  allowSyndicate
) => {
  const modal = allowSyndicate
    ? {
        primary: "Update",
        secondary: "Nevermind",
        content:
          "This change will apply to All shops and will override any local shop changes.",
      }
    : {};

  return [
    {
      label: Localise(messages, "Take Action"),
      value: "",
    },
    {
      label: Localise(messages, "Exclude From Minimum"),
      value: "excludeFromPriceMinimum::true",
      modal,
    },
    {
      label: Localise(messages, "Include in Minimum"),
      value: "excludeFromPriceMinimum::false",
      modal,
    },
  ];
};

export const CollectionPageFilters = (Localise, messages) => [
  {
    label: Localise(messages, "My Collections"),
    value: "myCollections",
  },
  {
    label: Localise(messages, "Global Collections"),
    value: "globalCollections",
  },
  {
    label: Localise(messages, "Active"),
    value: "active",
  },
  {
    label: Localise(messages, "Inactive"),
    value: "inactive",
  },
];

// Collection Page
export const ProductsFilter = (Localise, messages) => [
  {
    label: Localise(messages, "My Catalog"),
    value: "local",
  },
  {
    label: Localise(messages, "Global Catalog"),
    value: "global",
  },
  {
    label: Localise(messages, "Active"),
    value: "active",
  },
  {
    label: Localise(messages, "Inactive"),
    value: "inactive",
  },
  {
    label: Localise(messages, "Sold Out"),
    value: "soldOut",
  },
  {
    label: Localise(messages, "Call For Price"),
    value: "callForPrice",
  },
  {
    label: Localise(messages, "In-Store Pickup Only"),
    value: "instore",
  },
  {
    label: Localise(messages, "Rush Delivery"),
    value: "rushDelivery",
  },
  {
    label: Localise(messages, "Local Delivery Only"),
    value: "localDelOnly",
  },
  {
    label: Localise(messages, "Dropship"),
    value: "dropship",
  },
  // {
  //   label: Localise(messages, "Codified"),
  //   value: "codified",
  // },
];

export const CatalogFilter = (Localise, messages) => [
  {
    label: Localise(messages, "Active"),
    value: "active",
  },
  {
    label: Localise(messages, "Inactive"),
    value: "inactive",
  },
  {
    label: Localise(messages, "Sold Out"),
    value: "soldOut",
  },
  {
    label: Localise(messages, "Call For Price"),
    value: "callForPrice",
  },
  {
    label: Localise(messages, "In-Store Pickup Only"),
    value: "instore",
  },
  {
    label: Localise(messages, "Rush Delivery"),
    value: "rushDelivery",
  },
  {
    label: Localise(messages, "Local Delivery Only"),
    value: "localDelOnly",
  },
  {
    label: Localise(messages, "Dropship"),
    value: "dropship",
  },
  // {
  //   label: Localise(messages, "Codified"),
  //   value: "codified",
  // },
];

export const AddonFilters = (Localise, messages) => [
  {
    label: Localise(messages, "My Add-on(s)"),
    value: "myAddons",
  },
  {
    label: Localise(messages, "Global Add-on(s)"),
    value: "globalAddons",
  },
  {
    label: Localise(messages, "Sold Out"),
    value: "soldOut",
  },
  {
    label: Localise(messages, "Active"),
    value: "active",
  },
  {
    label: Localise(messages, "Inactive"),
    value: "inactive",
  },
];

export const CreateMenuOptions = () => [
  {
    key: "createProduct",
    label: "Create Product",
    route: "product",
    action: navigateScreen,
    handle: "create_product",
  },
  {
    key: "createAddon",
    label: "Create Addon",
    route: "product",
    action: navigateScreen,
    handle: "create_addon",
  },
  {
    key: "createCollection",
    label: "Create Collection",
    route: "collection",
    action: navigateScreen,
    handle: "create_collection",
  },
];

export const SettingsMenuOptions = [
  {
    key: "catalogSettings",
    label: "Catalog Settings",
    sideCarName: "catalogSettings",
    action: setSideCar,
  },
  {
    key: "collectionSettings",
    label: "Collection Settings",
    sideCarName: "collectionSettings",
    action: setSideCar,
  },
];
