export const RulesMapping = {
  ADDITIONAL_FEE: "additionalFeeInfo",
  DO_NOT_DELIVER: "doNotDeliverInfo",
  CUT_OFF_TIME: "cutOffTimeInfo",
};

export const getExceptionsConfig = (Localise, messages) => {
  return {
    cutoffDaysDropdown: {
      placeholder: Localise(messages, "Select day(s)"),
      data: [
        {
          label: Localise(messages, "Sunday"),
          value: "SUNDAY",
        },
        {
          label: Localise(messages, "Monday"),
          value: "MONDAY",
        },
        {
          label: Localise(messages, "Tuesday"),
          value: "TUESDAY",
        },
        {
          label: Localise(messages, "Wednesday"),
          value: "WEDNESDAY",
        },
        {
          label: Localise(messages, "Thursday"),
          value: "THURSDAY",
        },
        {
          label: Localise(messages, "Friday"),
          value: "FRIDAY",
        },
        {
          label: Localise(messages, "Saturday"),
          value: "SATURDAY",
        },
      ],
    },
    criteriaDropdown: {
      placeholder: {
        label: Localise(messages, "Select Criteria"),
        value: "",
      },
      data: [
        {
          label: Localise(messages, "All Areas"),
          value: "ALL_AREAS",
        },
        {
          label: Localise(messages, "All Local"),
          value: "ALL_LOCAL",
        },
        {
          label: Localise(messages, "Outside Local"),
          value: "OUTSIDE_LOCAL",
        },
        {
          label: Localise(messages, "City/Zip Code"),
          value: "CITY_ZIP_CODE",
        },
      ],
    },
    cityDropdown: {
      placeholder: {
        label: Localise(messages, "Select City"),
        value: "",
      },
    },
  };
};
