import React, { useEffect, useContext } from "react";
import { Text } from "react-native-elements";
import { View, ActivityIndicator } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import Environment from "library/utils/environment";

import { DeviceContext } from "library/contexts/appSettings";
import {
  selectShopCode,
  selectShopLogoData,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { setSideCar } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { isMHQNonCoreMember } from "library/utils/entitlements";
import {
  FormField,
  FormFieldCheckBox,
  FormFieldPicker,
  FormFieldSwitch,
} from "components/elements/forms";
import { Form } from "components/elements/forms";
import { SaveCancelButtons } from "components/wrappers";
import * as Yup from "yup";
import tw from "tailwind-rn";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import {
  getContactInfoPlacements,
  getFormikContactInfoPlacement,
  triFoldOrderInfoFields,
} from "./helper";
import { fonts, colors } from "styles/theme";
import { BannerPlacementPositions, logoOrientationOptions } from "../../config";

const contactInfoPlacementOptions = [
  {
    label: "Florist Top Half / Recipient Bottom Half",
    value: "Florist Info",
  },
  {
    label: "Recipient Top Half / Florist Bottom Half",
    value: "Recipient Info",
  },
  {
    label: "Recipient Top Half / Delivery Information Bottom Half",
    value: "RI_DI", // RecipientInfo_OrderInfo
  },
];

const CardMessage = (props) => {
  const { Localise, messages, isDesktop, setFieldValue } = props;

  const handleChange = (itemValue) => {
    const isCornerPlacement = [
      "Top Left",
      "Top Right",
      "Bottom Left",
      "Bottom Right",
    ].includes(itemValue);
    const isBanerPlacement = ["Top", "Bottom"].includes(itemValue);
    if (isCornerPlacement == true) {
      setFieldValue("bannerOrientation", "None");
    } else if (isBanerPlacement == true) {
      setFieldValue("logoOrientation", "None");
    }
  };
  return (
    <View>
      <View style={tw(`flex-row flex-wrap`)}>
        <View>
          <Text style={[fonts.heading5, tw("mb-3")]}>
            {Localise(messages, "Corner Placement")}{" "}
          </Text>
          <FormFieldPicker
            placeholder={{
              label: "Select Corner Placement",
              value: "selectCorner",
            }}
            testID="logoOrientation"
            accessibilityLabel="logoOrientation"
            containerStyle={{
              width: isDesktop ? 250 : "100%",
              marginLeft: -5,
            }}
            data={logoOrientationOptions}
            onChange={handleChange}
            name="logoOrientation"
          />
        </View>
        <View>
          <Text style={[fonts.heading5, tw("mb-3")]}>
            {Localise(messages, "Banner Placement")}
          </Text>
          <FormFieldPicker
            placeholder={{
              label: "Select Banner Placement",
              value: "selectBaner",
            }}
            testID="bannerOrientation"
            accessibilityLabel="bannerOrientation"
            containerStyle={{
              width: isDesktop ? 250 : "100%",
              marginLeft: -5,
            }}
            data={BannerPlacementPositions}
            onChange={handleChange}
            name="bannerOrientation"
          />
        </View>
      </View>
    </View>
  );
};

const FloristMessage = (props) => {
  const { error, Localise, messages } = props;
  return (
    <View style={tw("mt-2")}>
      <FormField
        autoCapitalize="none"
        autoCorrect={false}
        name="floristMessage"
        label="Florist Message"
        placeholder=""
        containerStyle={[tw("mb-2 pl-0")]}
        inputStyle={{ textAlign: "center" }}
        multiline={true}
        numberOfLines={4}
        isUpdateOnChange={true}
      />
      {error.keys.includes("section2") && (
        <Text
          style={[
            {
              ...fonts.error,
              marginTop: 0,
              marginBottom: 12,
              textAlign: "left",
            },
          ]}
        >
          {Localise(messages, "Message is too long, please edit.")}
        </Text>
      )}
    </View>
  );
};

const FloristContactInfo = (props) => {
  const { Localise, messages, error, floristFields } = props;

  return (
    <React.Fragment>
      <View>
        <Text style={[fonts.heading5, tw("mb-2")]}>
          {Localise(messages, "Tri-Fold Contact Info")}
        </Text>
        <View
          style={[
            tw("flex flex-row flex-wrap items-center"),
            { marginLeft: -5 },
          ]}
        >
          {floristFields.map((field, index) => (
            <FormFieldCheckBox
              key={index}
              iconRight={false}
              name="floristContactInfo"
              size={20}
              title={field}
              isGroup={true}
            />
          ))}
        </View>
        {error.keys.includes("section3") && (
          <Text
            style={[
              {
                ...fonts.error,
                marginTop: 0,
                marginBottom: 12,
                textAlign: "left",
              },
            ]}
          >
            {Localise(messages, "Card info is too long, please edit.")}
          </Text>
        )}
      </View>
      <View>
        <Text style={[fonts.heading5, tw("mb-2")]}>
          {Localise(messages, "Tri-Fold Delivery Info")}
        </Text>
        <View
          style={[
            tw("flex flex-row flex-wrap items-center"),
            { marginLeft: -5 },
          ]}
        >
          {triFoldOrderInfoFields.map((field, index) => (
            <FormFieldCheckBox
              key={index}
              iconRight={false}
              name="orderInfo"
              size={20}
              title={field}
              isGroup={true}
            />
          ))}
        </View>
      </View>
      <View>
        <Text style={[fonts.heading5, tw("mb-2")]}>
          {Localise(messages, "Florist / Recipient Info Placement")}
        </Text>
        <View style={tw("flex flex-row items-center")}>
          <FormFieldPicker
            placeholder={{
              label: "Select Florist / Recipient Info Placement",
              value: "",
            }}
            testID="contactInfoTopHalf"
            accessibilityLabel="contactInfoTopHalf"
            containerStyle={{
              width: 270,
              marginLeft: -5,
            }}
            data={contactInfoPlacementOptions}
            name="contactInfoTopHalf"
          />
        </View>
      </View>
    </React.Fragment>
  );
};

const mapping = {
  "Card Message": CardMessage,
  "Florist Message": FloristMessage,
  "Contact Info": FloristContactInfo,
};

const CardSettings = ({
  error,
  shopSettings = {},
  shopDetails = {},
  filteredShopCodes,
  setCardSettingsFormikData,
  saveSettings,
  loadingKey,
}) => {
  const dispatch = useDispatch();
  const shopCode = useSelector(selectShopCode);
  const { messages, Localise } = useContext(I18NContext);
  const { isDesktop } = useContext(DeviceContext);
  const [floristFields, setFloristFields] = useStateIfMounted([]);
  const [defaultSettings, setDefaultSettings] = useStateIfMounted({});

  const { websites = [] } = shopDetails;
  const cardSettings = shopSettings["card_settings"];
  const shopLogoData = useSelector(selectShopLogoData);

  const {
    logoInfo: { name: logoName = "" } = {},
    bannerInfo: { name: bannerName = "" } = {},
  } = shopLogoData || {};

  useEffect(() => {
    if (!isEmpty(cardSettings)) {
      const formattedCardSettings = {
        ...cardSettings,
        contactInfoTopHalf: getFormikContactInfoPlacement(
          cardSettings?.contactInfoTopHalf,
          cardSettings?.contactInfoBottomHalf
        ),
      };
      setDefaultSettings(formattedCardSettings);
    }
  }, [cardSettings]);

  useEffect(() => {
    if (!shopDetails || isEmpty(shopDetails)) return;

    // In case of mutli florist account, user may change shop from dropdown so when shop changed we are resetting FloristFields with empty array.
    setFloristFields([]);

    var fieldsToDisplay = [];

    fieldsToDisplay.push("Florist Name");

    fieldsToDisplay.push("Florist Website");

    fieldsToDisplay.push("Florist Email");

    fieldsToDisplay.push("Florist Phone");

    fieldsToDisplay.push("Florist Address");

    setFloristFields(fieldsToDisplay);
  }, [shopDetails]);

  let validationSchema = Yup.object().shape({
    logoOrientation: Yup.string()
      .label("logoOrientation")
      .required(Localise(messages, "Please Select Corner Placement")),

    bannerOrientation: Yup.string()
      .label("bannerOrientation")
      .when("logoOrientation", {
        is: (value) => value !== "None" || value !== "selectCorner",
        then: Yup.string().required(
          Localise(messages, "Please Select Baner Position")
        ),
      }),
    /*flroistMessage: Yup.string()
      .label("floristMessage")
      .required("Please enter a Florist Message"),
    floristContactInfo: Yup.array().of(
      Yup.string()
        .label("floristContactInfo")
        .required("Please choose florist Contact Info")
    ),*/
    deliveryFee: Yup.string().matches(
      new RegExp("^([0-9]{1,2}){1}(.[0-9]{1,2})?$"),
      Localise(messages, "Please enter a value between 0-99.99")
    ),
    //.required(Localise(messages, "Please enter fee")),
  });

  const floristWebsite = defaultSettings.floristWebsite || websites[0] || "";

  const {
    logoImageURL,
    logoOrientation,
    bannerImageURL,
    bannerOrientation,
    storeMessage = "",
    floristContactInfo = [],
    orderInfo = [],
    isLogoOpted = false,
    isBannerOpted = false,
    contactInfoTopHalf = "Florist Info",
  } = defaultSettings;

  const initialValues = {
    hasMultipleShops: filteredShopCodes.length > 0,
    logoOrientation: isLogoOpted
      ? logoOrientation
        ? logoOrientation
        : "None"
      : "None",
    bannerOrientation: isBannerOpted
      ? bannerOrientation
        ? bannerOrientation
        : "None"
      : "None",
    logoPath: (isLogoOpted ? logoName : bannerName) || "",
    floristMessage: storeMessage.replace(/<br\s*[\/]?>/gi, "\n"), // eslint-disable-line
    floristWebsite,
    floristContactInfo: floristContactInfo,
    orderInfo: orderInfo,
    contactInfoTopHalf,
  };

  const isNonCoreUser = isMHQNonCoreMember(shopCode);
  const showFuneralLog = Environment.get("SHOW_FUNERAL_LOG", false);

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={(values, formikBag) => {
          if (error.keys.length) {
            formikBag.setSubmitting(false);
            return;
          }
          const {
            logoOrientation,
            bannerOrientation,
            floristWebsite,
            floristMessage,
            floristContactInfo,
            orderInfo,
            contactInfoTopHalf: section3PlacementSelection,
          } = values;
          const isLogoOpted = [
            "Top Left",
            "Top Right",
            "Bottom Left",
            "Bottom Right",
          ].includes(logoOrientation);
          const { contactInfoTopHalf, contactInfoBottomHalf } =
            getContactInfoPlacements(section3PlacementSelection);
          const isBannerOpted = ["Top", "Bottom"].includes(bannerOrientation);
          const cardSettings = {
            memberCode: shopCode,
            isLogoOpted,
            logoImageURL,
            logoOrientation,
            isBannerOpted,
            bannerImageURL,
            bannerOrientation,
            storeMessage: floristMessage.replace(/\n/g, "<br />"),
            floristWebsite,
            floristContactInfo,
            orderInfo,
            printStoreInfo: false,
            logoBannerStyling: "",
            logoStoreInfoStyling: "",
            logoStoreMessageStyling: "",
            contactInfoTopHalf,
            contactInfoBottomHalf,
          };

          let payload = {
            preferences: [
              {
                id: "card_settings",
                values: [JSON.stringify(cardSettings)],
              },
            ],
          };
          saveSettings(payload);
        }}
        validationSchema={validationSchema}
        render={(props) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            isDesktop &&
              props.dirty &&
              dispatch(setSideCar({ type: "enclosure-card" }));
          }, [props.values]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            //When there is change in florist website field, update display Fields and formik contact Info to show/hide Florist Website.
            if (websites.length) return;
            const { floristWebsite, floristContactInfo } = props.values;
            const contactInfo = cloneDeep(floristContactInfo);
            const fieldsToDisplay = cloneDeep(floristFields);
            const displayFieldsWebsiteIndex = fieldsToDisplay.findIndex(
              (x) => x === "Florist Website"
            );
            if (!floristWebsite && displayFieldsWebsiteIndex >= 0) {
              //Remove from displayFields and formik floristContactInfo
              const index = contactInfo.findIndex(
                (x) => x === "Florist Website"
              );
              index >= 0 && contactInfo.splice(index, 1);
              fieldsToDisplay.splice(displayFieldsWebsiteIndex, 1);
              props.setFieldValue("floristContactInfo", contactInfo);
              setFloristFields(fieldsToDisplay);
            } else if (floristWebsite && displayFieldsWebsiteIndex === -1) {
              //Add to displayFields and formik floristContactInfo
              const displayFieldsNameIndex = fieldsToDisplay.findIndex(
                (x) => x === "Florist Name"
              );
              fieldsToDisplay.splice(
                displayFieldsNameIndex + 1,
                0,
                "Florist Website"
              );
              defaultSettings.floristContactInfo?.includes("Florist Website") &&
                !contactInfo?.includes("Florist Website") &&
                contactInfo.push("Florist Website");
              props.setFieldValue("floristContactInfo", contactInfo);
              setFloristFields(fieldsToDisplay);
            }
          }, [props.values.floristWebsite]);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            setCardSettingsFormikData(props.values);
          }, [props.values]);

          return (
            <React.Fragment>
              <View style={{ ...tw("flex flex-row"), width: "100%" }}>
                <View style={{ width: "30%" }}>
                  <Text style={[fonts.heading3, tw("mb-2")]}>
                    {Localise(messages, "Tri-Fold Card")}
                  </Text>
                </View>
                <View style={{ width: "70%" }}>
                  <Text
                    style={[
                      fonts.link1,
                      tw("mb-4 flex flex-row-reverse"),
                      { textAlign: "right" },
                    ]}
                    onPress={() => {
                      dispatch(setSideCar({ type: "enclosure-card" }));
                    }}
                  >
                    {Localise(messages, "Preview Tri-Fold Card")}
                  </Text>
                </View>
              </View>

              {Object.keys(mapping).map((key, id) => {
                const Comp = mapping[key];
                return (
                  <View key={key}>
                    <Comp
                      Localise={Localise}
                      messages={messages}
                      values={props.values}
                      error={error}
                      isDesktop={isDesktop}
                      floristFields={floristFields}
                      logoOrientation={logoOrientation}
                      bannerOrientation={bannerOrientation}
                      setFieldValue={props.setFieldValue}
                    />
                  </View>
                );
              })}
              {showFuneralLog && isNonCoreUser && (
                <>
                  <View
                    style={[
                      tw("flex flex-row items-center"),
                      { marginBottom: 12, marginTop: 8 },
                    ]}
                  >
                    <View
                      style={{ paddingRight: 20, maxWidth: 200, minWidth: 140 }}
                    >
                      <Text style={fonts.heading5}>
                        {Localise(messages, "Additional Sympathy Tri-fold")}
                      </Text>
                    </View>
                    <FormFieldSwitch
                      value={
                        shopSettings["additional_sympathy_card"] === "true"
                      }
                      onValueChange={(val) => {
                        let payload = {
                          preferences: [
                            {
                              id: "additional_sympathy_card",
                              values: [val ? "true" : "false"],
                            },
                          ],
                        };
                        saveSettings(payload);
                      }}
                      testID="additional_sympathy_card"
                    />
                    <View style={{ paddingHorizontal: 20 }}>
                      <Text>
                        {shopSettings["additional_sympathy_card"] === "true"
                          ? Localise(messages, "Enabled")
                          : Localise(messages, "Disabled")}
                      </Text>
                    </View>
                    {loadingKey === "additional_sympathy_card" && (
                      <ActivityIndicator
                        color={colors.activityIndicator}
                        testID={"loader"}
                      />
                    )}
                  </View>
                  <View
                    style={[
                      tw("flex flex-row items-center"),
                      { marginBottom: 12, marginTop: 8 },
                    ]}
                  >
                    <View
                      style={{ paddingRight: 20, maxWidth: 200, minWidth: 140 }}
                    >
                      <Text style={fonts.heading5}>
                        {Localise(messages, "Additional Sympathy Invoice")}
                      </Text>
                    </View>
                    <FormFieldSwitch
                      value={
                        shopSettings["additional_sympathy_invoice"] === "true"
                      }
                      onValueChange={(val) => {
                        let payload = {
                          preferences: [
                            {
                              id: "additional_sympathy_invoice",
                              values: [val ? "true" : "false"],
                            },
                          ],
                        };
                        saveSettings(payload);
                      }}
                      testID="additional_sympathy_invoice"
                    />
                    <View style={{ paddingHorizontal: 20 }}>
                      <Text>
                        {shopSettings["additional_sympathy_invoice"] === "true"
                          ? Localise(messages, "Enabled")
                          : Localise(messages, "Disabled")}
                      </Text>
                    </View>
                    {loadingKey === "additional_sympathy_invoice" && (
                      <ActivityIndicator
                        color={colors.activityIndicator}
                        testID={"loader"}
                      />
                    )}
                  </View>
                </>
              )}
              <SaveCancelButtons
                buttonTitle={Localise(messages, "Save")}
                saveTestId="Save"
              />
            </React.Fragment>
          );
        }}
      />
    </>
  );
};

export default CardSettings;
