import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as parentSlice } from "../constants";
import { SLICE_NAME as sliceName } from "./constants";
import { setAction as setDashboardAction } from "../dashboard/slice";
import isNumber from "lodash/isNumber";

export const initialState = {
  status: "",
  data: [],
  api: {},
  error: false,
  actions: {
    searchText: "",
    sortBy: "",
    filters: [],
    bulkActionType: {},
    catalogType: "all_catalogs",
    selectedProducts: [],
    catalogLimit: 0,
  },
  sideCar: "",
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: `view::${parentSlice}::${sliceName}`,
  initialState,
  reducers: {
    setInit: (state, action) => {
      const { handle, baseLimit } = action.payload;

      state.status = "progress";
      if (handle) {
        state.actions.catalogType = handle;
      }
      if (baseLimit) {
        state.actions.catalogLimit = baseLimit;
      }
    },
    setReset: (state) => {
      Object.assign(state, initialState);
    },
    setData: (state, action) => {
      const { data } = action.payload;
      state.data = data;
      state.status = "done";
      state.error = false;
    },
    setAPIResponse: (state, action) => {
      const { content, patch } = action.payload;
      if (patch) {
        state.api = { ...state.api, ...patch };
      } else {
        const { ...data } = content;
        state.api = data;
      }
    },
    setAPIError: (state, action) => {
      state.status = "done";
      state.error = action.payload;
    },
    setViewMore: (state, action) => {
      const { currentLimit, actual } = action.payload;
      if (isNumber(actual)) {
        state.actions.catalogLimit = actual;
      } else if (isNumber(currentLimit))
        state.actions.catalogLimit = state.actions.catalogLimit + currentLimit;
    },
    setAction: (state, action) => {
      const { type, value, extraValue } = action.payload;
      if (type === "search") state.actions.searchText = value;
      else if (type === "catalogType") state.actions.catalogType = value;
      else if (type === "bulkActionType") state.actions.bulkActionType = value;
      else if (type === "sort") {
        state.actions.sortBy = value;
        state.actions.manualSortOrder = extraValue;
      } else if (type === "filters") {
        state.actions.filters = [...new Set([].concat(...value))];
      }
    },
    setQuickAction: () => {},
    setProductSelection: (state, action) => {
      const { productId } = action.payload;
      if (productId === "all") {
        state.actions.selectedProducts = state.data;
      } else if (productId) {
        if (state.actions.selectedProducts.includes(productId)) {
          const index = state.actions.selectedProducts.findIndex(
            (pid) => pid === productId
          );
          state.actions.selectedProducts.splice(index, 1);
        } else {
          state.actions.selectedProducts = [
            ...state.actions.selectedProducts,
            productId,
          ];
        }
      } else {
        state.actions.selectedProducts = [];
      }
    },
    createCatalog: () => {},
    updateCatalog: () => {},
    deleteCatalog: () => {},
    fetchCatalog: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(setDashboardAction, (state, action) => {
      const { type, value } = action.payload;
      if (type === "search") state.actions.searchText = value;
    });
  },
});

export const {
  setInit,
  setReset,
  setData,
  setAction,
  setQuickAction,
  setViewMore,
  setProductSelection,
  setAPIResponse,
  setAPIError,
  createCatalog,
  updateCatalog,
  deleteCatalog,
  fetchCatalog,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
