import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectActions = createSelector(
  selectState,
  ({ actions }) => actions
);

export const selectSearchCityText = createSelector(
  selectActions,
  ({ searchCity }) => searchCity.value
);

export const selectSearchZipcodeText = createSelector(
  selectActions,
  ({ searchZipcode }) => searchZipcode.value
);

export const selectStorePickup = createSelector(
  selectState,
  ({ storePickup }) => storePickup
);

export const selectFeesCoverage = createSelector(
  selectState,
  ({ feesCoverage }) => feesCoverage
);

export const selectSiteFees = createSelector(
  selectFeesCoverage,
  ({ siteFees = {} }) => siteFees.content || {}
);

export const selectCitiesFees = createSelector(
  selectFeesCoverage,
  ({ citiesFees = {} }) => citiesFees.content || []
);

export const selectExceptions = createSelector(
  selectFeesCoverage,
  ({ deliveryExceptions }) => deliveryExceptions?.content || []
);

export const selectExceptionCities = createSelector(
  selectFeesCoverage,
  ({ deliveryExceptionCities }) => deliveryExceptionCities?.content || []
);

export const selectExceptionZips = createSelector(
  selectFeesCoverage,
  ({ deliveryExceptionZips }) => deliveryExceptionZips?.content || {}
);

export const selectCitiesZipcodes = createSelector(
  selectFeesCoverage,
  ({ citiesZipcodes }) => citiesZipcodes || []
);

export const selectCitiesPlaceIds = createSelector(
  selectFeesCoverage,
  ({ citiesplaceIDs }) => citiesplaceIDs || []
);

export const selectReloadDeliveryZoneMap = createSelector(
  selectFeesCoverage,
  ({ reloadDeliveryZoneMap }) => reloadDeliveryZoneMap || false
);
