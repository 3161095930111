import React, { Component } from "react";
import {
  Platform,
  StyleSheet,
  Text,
  TextInput,
  View,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { Icon } from "react-native-elements";
import { connect } from "react-redux";
import { fonts } from "styles/theme";

// Keep this line for downwards compatibility with RN.
// eslint-disable-next-line react/forbid-foreign-prop-types

// Added new component to support multi select dropdown with caret symbol for TextInput
// eslint-disable-next-line react/display-name
const MultiSelectTextInput = React.forwardRef((props, ref) => {
  return props.isMultiSelect ? (
    <TouchableOpacity
      style={{
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
      onPress={(e) => (props.isFocused ? props.onFocus(e) : props.onBlur(e))}
    >
      <TextInput
        {...props}
        style={{
          flex: 1,
          padding: 10,
          height: props.height,
          outline: "none",
          width: "100%",
          ...fonts.default,
        }}
        ref={ref}
        testID={props?.testID || props.name || ""}
        accessibilityLabel={props?.accessibilityLabel || props.name || ""}
      />
      <Icon
        name={props.showSuggestions ? "chevron-up" : "chevron-down"}
        size={11}
        type="font-awesome"
        iconStyle={{
          marginRight: 20,
          paddingTop: Platform.OS !== "web" ? 10 : 0,
          height: "100%",
        }}
        testID="auto-complete-input"
        accessibilityLabel="auto-complete-input"
      />
    </TouchableOpacity>
  ) : (
    <TextInput
      {...props}
      ref={ref}
      testID={props?.testID || props.name || ""}
      accessibilityLabel={props?.accessibilityLabel || props.name || ""}
    />
  );
});

class Autocomplete extends Component {
  static defaultProps = {
    data: [],
    activeOption: -1,
    keyboardShouldPersistTaps: "always",
    onStartShouldSetResponderCapture: () => false,
    renderItem: ({ item }) => <Text>{item}</Text>,
    renderSeparator: null,
    renderTextInput: (props) => <MultiSelectTextInput {...props} />,
    flatListProps: {},
  };

  constructor(props) {
    super(props);
    this.resultList = null;
    this.textInput = null;

    this.state = {
      autoCompleteViewStyles: {},
    };

    this.onRefListView = this.onRefListView.bind(this);
    this.onRefTextInput = this.onRefTextInput.bind(this);
    this.onEndEditing = this.onEndEditing.bind(this);
    this.isFocused = this.isFocused.bind(this);
    this.onFocusTextInput = this.onFocusTextInput.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isFocus, activeOption, YOffSetScroll } = this.props;
    if (isFocus) {
      this.textInput.focus();
    }
    const enableScroll =
      prevProps.activeOption !== activeOption &&
      (prevProps.activeOption < activeOption
        ? activeOption > 4 && activeOption % 4 === 1
        : true);
    if (enableScroll) {
      this.resultList &&
        this.resultList.scrollToIndex({
          animated: true,
          index: activeOption,
        });
    }
    if (prevProps.YOffSetScroll !== YOffSetScroll) {
      this.textInput.isFocused() &&
        !this.props.isMultiSelect &&
        this.showSuggestionsByScreenPosition();
    }
  }

  onFocusTextInput(e) {
    this.props.onFocus && this.props.onFocus(e);
    !this.props.isMultiSelect && this.showSuggestionsByScreenPosition();
  }

  onEndEditing(e) {
    this.props.onEndEditing && this.props.onEndEditing(e);
  }

  onRefListView(resultList) {
    this.resultList = resultList;
  }

  onRefTextInput(textInput) {
    this.textInput = textInput;
  }

  /**
   * Proxy `blur()` to autocomplete's text input.
   */
  blur() {
    const { textInput } = this;
    textInput && textInput.blur();
  }

  /**
   * Proxy `focus()` to autocomplete's text input.
   */
  focus() {
    const { textInput } = this;
    textInput && textInput.focus();
  }

  /**
   * Proxy `isFocused()` to autocomplete's text input.
   */
  isFocused() {
    const { textInput } = this;
    return textInput && textInput.isFocused();
  }

  showSuggestionsByScreenPosition() {
    const { dataLimit, popperPlacement = "" } = this.props;
    const maxHeight =
      Platform.OS === "android"
        ? dataLimit === 10
          ? 400
          : 200
        : this.props.suggestionsHeight || 160;
    this.textInput.measure((fx, fy, width, height, px, py) => {
      const maxKeyBoardHeight = 542;
      const spaceAboveElement = Platform.OS === "web" ? py : py - 300;
      const spaceBelowElement =
        Platform.OS === "web"
          ? Dimensions.get("window").height - (py + height)
          : Dimensions.get("window").height -
            (maxKeyBoardHeight + py + height + 2);

      !popperPlacement &&
        this.setState({
          autoCompleteViewStyles: {
            top: height + 2,
            maxHeight: maxHeight,
          },
        });

      if (!popperPlacement && spaceAboveElement > spaceBelowElement) {
        this.setState({
          autoCompleteViewStyles: {
            bottom: height + 2,
            maxHeight: maxHeight,
          },
        });
      } else if (popperPlacement === "top") {
        this.setState({
          autoCompleteViewStyles: {
            bottom: height + 2,
            maxHeight: maxHeight,
          },
        });
      } else if (popperPlacement === "bottom") {
        this.setState({
          autoCompleteViewStyles: {
            top: height + 2,
            maxHeight: maxHeight,
          },
        });
      }
    });
  }

  renderResultList() {
    const {
      data,
      listStyle,
      renderItem,
      renderSeparator,
      keyboardShouldPersistTaps,
      flatListProps,
      onEndReached,
      onEndReachedThreshold,
    } = this.props;

    return (
      <FlatList
        ref={this.onRefListView}
        data={data}
        keyboardShouldPersistTaps={keyboardShouldPersistTaps}
        renderItem={renderItem}
        keyExtractor={(item, index) => index.toString()}
        renderSeparator={renderSeparator}
        onEndReached={onEndReached}
        onEndReachedThreshold={onEndReachedThreshold}
        style={[styles.list, listStyle, border]}
        {...flatListProps}
      />
    );
  }

  renderTextInput() {
    const {
      renderTextInput,
      style,
      autoComplete,
      onBlur,
      onChangeText,
      onKeyPress,
      onStartShouldSetResponderCapture,
      placeholder,
      placeholderTextColor,
      editable,
      value,
      isMultiSelect,
      showSuggestions,
      height,
      name,
      fsClass = "fs-unmask",
      testID,
      accessibilityLabel,
    } = this.props;
    const props = {
      style: [styles.input, style],
      ref: this.onRefTextInput,
      onEndEditing: this.onEndEditing,
      onFocus: this.onFocusTextInput,
      isFocused: this.isFocused,
      autoComplete,
      onBlur,
      onChangeText,
      onKeyPress,
      onStartShouldSetResponderCapture,
      placeholder,
      placeholderTextColor,
      editable,
      value,
      isMultiSelect,
      showSuggestions,
      height,
      name,
      fsClass,
      testID,
      accessibilityLabel,
    };

    return renderTextInput(props);
  }

  render() {
    const {
      data,
      containerStyle,
      hideResults,
      inputContainerStyle,
      listContainerStyle,
      onShowResults,
      isMultiSelect,
      onStartShouldSetResponderCapture,
    } = this.props;
    const showResults = data.length > 0;
    const autoStyles = !this.props.isMultiSelect
      ? {
          position: "absolute",
          left: 0,
          right: 0,
          zIndex: 1,
          elevation: 2,
          ...this.state.autoCompleteViewStyles,
        }
      : {};

    // Notify listener if the suggestion will be shown.
    onShowResults && onShowResults(showResults);
    return (
      <View style={[styles.container, containerStyle]}>
        <View style={[styles.inputContainer, inputContainerStyle]}>
          {this.renderTextInput()}
        </View>
        {!hideResults && (
          <View
            style={[
              listContainerStyle,
              { maxHeight: !isMultiSelect ? 0 : 180 },
              autoStyles,
            ]}
            onStartShouldSetResponderCapture={onStartShouldSetResponderCapture}
          >
            {showResults && this.renderResultList()}
          </View>
        )}
      </View>
    );
  }
}

const border = {
  borderColor: "#b9b9b9",
  borderRadius: 1,
  borderWidth: 1,
};

const mobileStyles = {
  container: {
    zIndex: 1,
    flex: 1,
  },
  inputContainer: {
    ...border,
    marginBottom: 0,
    borderWidth: 0,
  },
  input: {
    backgroundColor: "white",
    height: 35,
  },
  list: {
    ...border,
    backgroundColor: "white",
  },
};

const styles = StyleSheet.create({
  input: {
    backgroundColor: "white",
    height: 35,
    paddingLeft: 3,
  },
  ...Platform.select({
    android: { ...mobileStyles },
    ios: { ...mobileStyles },
  }),
});

const mapStateToProps = (state = {}) => {
  return {
    YOffSetScroll: state.mhq.ongoing.global.YOffSetScroll,
  };
};

export default connect(mapStateToProps, null)(Autocomplete);
