import React from "react";
import { View, Text } from "react-native";
import tw from "tailwind-rn";
import {
  formatPrice,
  // phoneNumberFormatter,
  // formatCardMessage,
} from "library/utils/formatter";
// import UserProfileStorage from "library/storage/userProfile";
// import get from "lodash/get";
// import { isMHQNonCoreMember } from "library/utils/entitlements";

// trifold card manual print
// export const getPrintHTML = (
//   recipientInfo,
//   receivingMember,
//   cardSettings,
//   cardMessage,
//   fileInfo,
//   isPickUpOrder,
//   orderSource = "",
//   isAdhocTriFold = false,
//   pickUpBy = "",
//   numberOfPrints = 1
// ) => {
//   const {
//     addressLine1,
//     addressLine2 = "",
//     city,
//     firstName = "",
//     lastName = "",
//     phone: recPhone = "",
//     state,
//     zip,
//     locationName = "",
//   } = recipientInfo || {};

//   const recipientFirstLine = addressLine2
//     ? `${addressLine1}, ${addressLine2}`
//     : addressLine1;

//   const recName = firstName + " " + lastName;

//   let {
//     businessName,
//     websites = [],
//     email,
//     phone: floristPhone = "",
//     addressLine1: floristAddressLine1 = "",
//     city: floristCity = "",
//     state: floristState = "",
//     zip: floristZip = "",
//   } = receivingMember;

//   let floristShopContactSettings = "";

//   const shopPreferences = UserProfileStorage.getShopPreferences(
//     receivingMember.memberCode
//   );

//   let isNonCoreEntitlement = isMHQNonCoreMember(receivingMember.memberCode);
//   let isShopNameOverridden = false;
//   let isStreetAddressOverridden = false;
//   let isPhoneOverridden = false;
//   let isEmailOverridden = false;
//   let isWebsiteOverridden = false;

//   floristShopContactSettings = get(
//     shopPreferences,
//     "florist_contact_information_settings",
//     ""
//   );

//   if (floristShopContactSettings == "JDE" || floristShopContactSettings == "") {
//     floristShopContactSettings =
//       '{"shopName":"","streetAddress":"","aptSuite":"","city":"","state":"","zip":"","phone":"","email":"","website":"","additionalMessage":"","contactName":"","printed":[],"web":[],"network":[]}';
//   }

//   let {
//     shopName = "",
//     streetAddress = "",
//     aptSuite = "",
//     city: overriddenCity = "",
//     state: overriddenState = "",
//     zip: overriddenZip = "",
//     phone: overriddenPhone = "",
//     email: overriddenEmail = "",
//     website = "",
//     printed = [],
//   } = JSON.parse(floristShopContactSettings);

//   if (printed.includes("shopName") && shopName != "") {
//     isShopNameOverridden = true;
//   }
//   if (printed.includes("streetAddress") && streetAddress != "") {
//     isStreetAddressOverridden = true;
//   }
//   if (printed.includes("phone") && overriddenPhone != "") {
//     isPhoneOverridden = true;
//   }
//   if (printed.includes("email") && overriddenEmail != "") {
//     isEmailOverridden = true;
//   }
//   if (printed.includes("website") && website != "") {
//     isWebsiteOverridden = true;
//   }

//   businessName = isNonCoreEntitlement
//     ? isShopNameOverridden
//       ? shopName
//       : businessName
//     : businessName;
//   floristPhone = isNonCoreEntitlement
//     ? isPhoneOverridden
//       ? overriddenPhone
//       : floristPhone
//     : floristPhone;
//   floristAddressLine1 = isNonCoreEntitlement
//     ? isStreetAddressOverridden
//       ? streetAddress + " " + aptSuite
//       : floristAddressLine1
//     : floristAddressLine1;
//   floristCity = isNonCoreEntitlement
//     ? isStreetAddressOverridden
//       ? overriddenCity
//       : floristCity
//     : floristCity;
//   floristState = isNonCoreEntitlement
//     ? isStreetAddressOverridden
//       ? overriddenState
//       : floristState
//     : floristState;
//   floristZip = isNonCoreEntitlement
//     ? isStreetAddressOverridden
//       ? overriddenZip
//       : floristZip
//     : floristZip;
//   email = isNonCoreEntitlement
//     ? isEmailOverridden
//       ? overriddenEmail
//       : email
//     : email;

//   let {
//     storeMessage,
//     floristContactInfo,
//     contactInfoTopHalf = "Florist Info",
//     floristWebsite = "",
//     isLogoOpted,
//     logoOrientation,
//     isBannerOpted,
//     bannerOrientation,
//   } = cardSettings;

//   floristWebsite = isNonCoreEntitlement
//     ? isWebsiteOverridden
//       ? website
//       : floristWebsite
//     : floristWebsite;

//   const isFloristInfoTop = contactInfoTopHalf === "Florist Info";

//   let isTop = true;
//   let isLeft = true;
//   let width = 0;
//   let height = 0;

//   if (isLogoOpted) {
//     const positions = logoOrientation.split(" ");
//     isTop = positions[0] !== "Bottom";
//     isLeft = positions[1] !== "Right";
//     width = 130;
//     height = 130;
//   } else if (isBannerOpted) {
//     isTop = bannerOrientation === "Top";
//     width = 380;
//     height = 120;
//   }

//   const pickupBy = isPickUpOrder
//     ? orderSource === "UberEats"
//       ? "Uber Eats"
//       : orderSource === "DoorDash"
//       ? "DoorDash"
//       : orderSource === "MOL"
//       ? "MOL"
//       : pickUpBy
//     : "";
//   const floristContactData = floristContactInfo || [];
//   const ua = (navigator && navigator.userAgent) || "";
//   const isAndroidBrowser = ua.includes("Android");
//   const bodyHeight =
//     Platform.OS === "android" ? `99%` : isAndroidBrowser ? `auto` : `auto`;
//   const defaultStoreMsg =
//     "This Handcrafted Bouquet is Designed Especially for you.";

//   const printCardContent = `<div class="page-break-flow-root">
//      <div class="printCardContainer">
//     <div class="content" style="justify-content:${
//       !!fileInfo.uri && isTop ? "flex-start" : "center"
//     }; overflow:hidden;">
//           <div class="message">
//              <div  class="cardMessage editorContent" style=display:${
//                isTop ? "none" : "block"
//              }; data-testid="sender_recipient_message"
//             > ${formatCardMessage(cardMessage)}</div>
//              ${
//                fileInfo.uri
//                  ? `<div class="${isLeft ? "left" : "right"}" >
//                       <svg
//                       version="1.1"
//                       id="Layer_1"
//                       xmlns="http://www.w3.org/2000/svg"
//                       xmlns:xlink="http://www.w3.org/1999/xlink"
//                       x="0px"
//                       y="0px"
//                       width="${width}px"
//                       height="${height}px"
//                       viewBox="0 0 ${width} ${height}"
//                       enable-background="new 0 0 ${width} ${height}"
//                       xml:space="preserve"
//                       >
//                          <image
//                             id="image0"
//                             width="${width}"
//                             height="${height}"
//                             x="0"
//                             y="0"
//                             href="${fileInfo.uri}"
//                          />
//                       </svg>
//                    </div>`
//                  : ``
//              }
//              <div class="cardMessage editorContent" style=padding:5px;text-align:center;display:${
//                isTop ? "block" : "none"
//              } data-testid="sender_recipient_message"> ${formatCardMessage(
//     cardMessage
//   )}</div>
//           </div>
//        </div>
//        <div class="content">
//           <div style="font-size:16px;text-align:center;justify-content:center;">
//               ${storeMessage ?? defaultStoreMsg}
//           </div>
//        </div>
//        <div class=${isAdhocTriFold ? "content" : "content center"}>
//        ${
//          !isFloristInfoTop
//            ? isAdhocTriFold
//              ? `<div class="editorContent" style="width:100%;text-align:center;margin-bottom: 5px;">${recipientInfo}</div>`
//              : `<div class="address center">
//             <div class="heading3 mb-2 center recipName">
//               ${recName}
//             </div>
//             <div class="mb-2">${phoneNumberFormatter(recPhone)}</div>
//             ${
//               !isPickUpOrder
//                 ? `${
//                     recipientInfo.locationName
//                       ? `<div style = "white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${locationName}</div>`
//                       : ``
//                   }
//                 <div class="mb-2">${recipientFirstLine}</div>
//              <div class="mb-2">${city}, ${state}, ${zip}</div>`
//                 : `Store Pickup by ${pickupBy}`
//             }
//           </div>`
//            : ``
//        }
//           <div class="address center">
//              ${
//                floristContactData.includes("Florist Name")
//                  ? `<div class="heading3 center mb-2" data-testid="shopName">${businessName}</div>`
//                  : ``
//              }
//              ${
//                floristContactData.includes("Florist Website")
//                  ? `<div class="mb-2" data-testid="shopWebsite">${
//                      floristWebsite || websites[0] || ""
//                    }</div>`
//                  : ``
//              }
//              ${
//                floristContactData.includes("Florist Email") && email
//                  ? `<div class="mb-2" data-testid="shopEmailId">${email}</div>`
//                  : ``
//              }
//              ${
//                floristContactData.includes("Florist Phone")
//                  ? `<div class="mb-2" data-testid="shopPhoneNumber">${phoneNumberFormatter(
//                      floristPhone
//                    )}</div>`
//                  : ``
//              }
//              ${
//                floristContactData.includes("Florist Address")
//                  ? `<div>
//                          <div class="mb-2">${floristAddressLine1}</div>
//                          <div>${floristCity}, ${floristState}, ${floristZip}</div>
//                       </div>`
//                  : ``
//              }
//           </div>
//           ${
//             isFloristInfoTop
//               ? isAdhocTriFold
//                 ? `<div class="editorContent" style="width:100%;text-align:center;margin-top: 5px;" data-testid="recipient_address">${recipientInfo}</div>`
//                 : `<div class="address center">
//                    <div class="heading3 mb-2 center recipName">
//                      ${recName}
//                    </div>
//                    <div class="mb-2">${phoneNumberFormatter(recPhone)}</div>
//                    ${
//                      !isPickUpOrder
//                        ? `${
//                            recipientInfo.locationName
//                              ? `<div style = "white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${locationName}</div>`
//                              : ``
//                          }
//                        <div class="mb-2">${recipientFirstLine}</div>
//                     <div class="mb-2">${city}, ${state}, ${zip}</div>
//                    `
//                        : `Store Pickup by ${pickupBy}`
//                    }
//                  </div>`
//               : ``
//           }
//        </div>
//     </div>
//   </div>`;

//   return `<!DOCTYPE html>
//         <html xmlns="http://www.w3.org/1999/xhtml">
//         <style>
//         .ql-font-arial {
//           font-family: "Arial";
//         }
//         .ql-font-georgia {
//           font-family: "Georgia";
//         }
//         .ql-font-verdana {
//           font-family: "Verdana";
//         }
//         .ql-font-impact {
//           font-family: "Impact";
//         }
//         .ql-font-tahoma {
//           font-family: "Tahoma";
//         }
//         .ql-font-times {
//           font-family: "Times New Roman", Times, serif;
//         }
//         .ql-align-left {
//           text-align: left;
//         }
//         .ql-align-right {
//           text-align: right;
//         }
//         .ql-align-center {
//           text-align: center;
//         }
//         .ql-align-justify {
//           text-align: justify;
//         }
//         </style>
//         <head>
//            <title>Mercury HQ</title>
//         </head>
//         <body onload="window.parent.postMessage({ action: 'iframe-loaded' }, '*');">
//           <div style="display: none">
//             <style type="text/css" media="print,screen">
//               @page {
//               size: auto;
//               margin: 0px;
//               margin-bottom:30mm;
//               /* this affects the margin in the printer settings */
//               }
//               .rdw-left-aligned-block {
//                   text-align: left !important;
//               }
//               .rdw-right-aligned-block {
//                   text-align: right !important;
//               }
//               .rdw-center-aligned-block {
//                   text-align: center !important;
//               }
//               .rdw-justify-aligned-block {
//                   text-align: justify !important;
//               }
//               .printCardContainer{
//                   display: flex;
//                   flex-direction: column;
//                   align-items: center;
//               }
//               .content {
//                   padding: 5px;
//                   display: flex;
//                   flex-direction: column;
//                   width: 390px;
//                   height: 300px;
//                   align-items: center;
//                   font-size: 16px;
//               }
//               .message {
//                 width: 100%;
//               }
//               .address {
//                   display: flex;
//                   flex-direction: column;
//                   justify-content: center;
//                   align-items: center;
//                   font-size: 14px;
//                   padding: 5px;
//               }
//               displayNone{
//                   display: none;
//               }
//               .heading3{
//                   font-size: 20px !important;
//                   font-weight: bold;
//               }
//               .mb-2{
//                   margin-bottom: 4px;
//               }
//               .left{
//                   display:flex;
//                   flex-direction: row;
//                   width: 100%;
//               }
//               .right{
//                   display:flex;
//                   flex-direction: row-reverse;
//                   width: 100%;
//               }
//               .center{
//                   text-align: center;
//                   align-items: center;
//               }
//               .recipName{
//                 word-break: break-all;
//               }
//               .cardMessage{
//                 text-align:center;
//                 word-break:break-word;
//                 white-space: pre-wrap;
//               }
//               .editorContent {
//                 p, ol, ul, pre, blockquote, h1, h2, h3, h4, h5, h6 {
//                   margin: 0px;
//                   padding: 0px;
//                 }
//               }
//               @media print {
//               html,
//               body {
//                 width: 100%;
//                 height: ${bodyHeight};
//                 -ms-overflow-style: none;
//                 -webkit-print-color-adjust: exact;
//                 }
//               .page-break-flow-root {
//                 display: flow-root;
//                 page-break-after: always;
//                 }
//               }
//             </style>
//           </div>
//           <div>
//             ${printCardContent}
//             ${numberOfPrints === 2 ? printCardContent : ``}
//           </div>
//         </body>
//       </html>
//      `;
// };

export const getRecipeInfo = (
  productDetails,
  Localise = () => {},
  messages = {},
  isFTDOrder = false
) => {
  let data = "";
  productDetails.recipeList.forEach((ele, index) => {
    data += `<tr class='${ele.css || "-"} p-6' >
       <td>${Localise(messages, ele.quantity) || "-"}</td>
       ${!isFTDOrder ? `<td>${Localise(messages, ele.color) || "-"}</td>` : ``}
       <td>${Localise(messages, ele.item) || "-"}</td>
       </tr>`;
  });
  return `<!DOCTYPE html>
        <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
           <title>Florist OS</title>
        </head>
        <body onload="window.parent.postMessage({ action: 'iframe-loaded' }, '*');">
           <div style="display: none">
              <style type="text/css" media="print,screen">
                 @page {
                 size: auto;
                 margin: 0px;
                 /* this affects the margin in the printer settings */
                 }  
                 .rdw-left-aligned-block {
                    text-align: left !important;
                 } 
                 .rdw-right-aligned-block {
                    text-align: right !important;
                 }   
                .rdw-center-aligned-block {
                    text-align: center !important;
                 }      
                 .rdw-justify-aligned-block {
                    text-align: justify !important;
                 }
                 .printCardContainer{               
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width:100%;
                 }
                 .content {
                    width:80%;
                    padding:10px;                                
                    display: flex;
                    flex-direction: column; 
                    font-size: 16px;  
                    margin: 10px;           
                 }
                 .heading1{
                    color:#FFF;
                    background-color: #114258;
                    padding: 20px;
                    font-size: 24px !important;
                 }
                 .heading3{
                    font-size: 20px !important;
                    font-weight: bold;
                 }
                 .font-bold{
                    font-weight:bold;
                 }
                 .desciption{
                    display:flex;
                    flex-direction:row;
                    justify-content:space-between;
                    padding:20px 0px;
                    margin: 20px 0px;
                 }
                 .image{
                    heigth: 400px;
                    width:200px;
                 }
                 .breakLine{
                    border: 1px solid #BDB6B5;
                    border-style:inset;
                    margin:10px;
                 }
                 .p-6{
                   padding: 6px;
                }
                 @media print {
                 html,
                 body {
                    height: auto;
                    -ms-overflow-style: none;
                    width:100%;
                 }
                 *{ color-adjust: exact;  -webkit-print-color-adjust: exact; print-color-adjust: exact; }             
                 }
              </style>
           </div>
           <div class="printCardContainer">
              <div class="content">
                 <div class="heading1">
                    ${Localise(messages, "Product Details")}
                 </div>
                 <div class="desciption">
                    <div>
                    <div class="font-bold">${
                      productDetails.productFirstChoiceDescription
                    }</div>
                    <div> ${productDetails.productFirstChoiceCode}</div>
                    <div>${Localise(messages, "Suggested Retail Price")} : $${
    productDetails.formattedPrice
  }</div>
                    <div>${Localise(messages, "Size / Style")} : ${
    productDetails.dimension || ""
  }</div>
                    <div>${Localise(messages, "Occasion")} : ${
    productDetails.occasion || ""
  }</div>
                    </div>
                    <div>
                    <img src="${
                      productDetails.img || productDetails.primaryImg
                    }"  class="image" />
                    </div>
                 </div>
                 <div class="breakLine"></div>
                 <div  class="heading3" >
                   ${Localise(messages, "Recipe")}
                 </div>
                 <table style="width:100%">
                  ${data}
               </table>
              </div>
           </div>
        </body>
        </html>
     `;
};

// export default getPrintHTML;

export const getDimensionAndSRP = (
  lineItemInfo,
  actPrice,
  Localise,
  locMessages
) => {
  //Product detial API response fields.
  const { dimension = "", actualPrice } = lineItemInfo;
  const srp = actPrice || actualPrice;
  return (
    <>
      {!!dimension && (
        <View style={tw("flex flex-row my-1")}>
          <Text>
            {Localise(locMessages, "Size / Style")}: {dimension}
          </Text>
        </View>
      )}
      {!!srp && (
        <View style={tw("flex flex-row my-1")}>
          <Text>
            {Localise(locMessages, "Suggested Retail Price")}
            {" : "}${formatPrice(srp)}
          </Text>
        </View>
      )}
    </>
  );
};

export const fetchAddonInfo = (pid, global) => {
  const { addons = [], molAddons = [], localAddons = [] } = global;
  return addons.length || molAddons.length || localAddons.length
    ? addons.find((addon) => addon.pid === pid) ||
        molAddons.find((addon) => addon.productId === pid) ||
        localAddons.find((addon) => addon.pid === pid)
    : {};
};

export const getOccasionLabel = (occasionsList, occasion) => {
  if (!!occasionsList.length && occasion !== "") {
    const occasionobj = occasionsList.find((occ) => occ.value === occasion);
    return occasionobj ? occasionobj.label : occasion;
  }
  return occasion;
};

export const formatProductSecondChoice = (
  productSecondChoiceCode,
  productSecondChoiceDescription,
  isPrint = false
) => {
  let productSecondChoice = "";

  if (productSecondChoiceCode.length > 0 && productSecondChoiceCode !== "NONE")
    productSecondChoice = productSecondChoice.concat(productSecondChoiceCode);

  if (
    productSecondChoiceCode.length > 0 &&
    productSecondChoiceDescription.length > 0 &&
    productSecondChoiceDescription !== "NONE"
  )
    productSecondChoice = productSecondChoice.concat(isPrint ? " " : " - ");

  if (
    productSecondChoiceDescription.length > 0 &&
    productSecondChoiceDescription !== "NONE"
  )
    productSecondChoice = productSecondChoice.concat(
      productSecondChoiceDescription
    );

  return productSecondChoice;
};
