/* eslint-disable react/display-name */
import * as React from "react";
import { View } from "react-native";
import ListItem from "components/views/drawer/list-item";
import { request } from "library/utils/request";
import { ToasterHandler } from "components/elements";
import UserProfileContext from "library/contexts/userProfile";
import { PermissionsforCreateUser as FTDUserPermissions } from "../../ftd-admin/user-settings/config";
import toUpper from "lodash/toUpper";
import I18NContext from "library/contexts/i18N";
import { phoneNumberFormatter } from "library/utils/formatter";

const statusMap = {
  FULLTIME: "Full Time",
  PARTTIME: "Part Time",
  SEASONAL: "Seasonal",
  TERMINATED: "Terminated",
};

const actionItems = {
  disabled: { key: "enableAccount", label: "Enable Account" },
  enabled: { key: "disableAccount", label: "Disable Account" },
};

const submitAction = (
  action,
  onAction,
  recordId,
  record,
  isFTDUsersAccountsListing,
  messages,
  setRecordId,
  setMenuAction,
  Localise
) => {
  const disableAccount = action === "disableAccount";

  if (isFTDUsersAccountsListing && record.department === "") {
    ToasterHandler(
      "error",
      disableAccount
        ? "Please update the department in user’s account before disabling the account."
        : "Please update the department in user’s account before enabling the account."
    );

    // To open staff details in side car we are setting primaryMemberCodeWithEmailId as recordId bcoz we are not setting recordId for ListItem of StaffList below.
    setRecordId(record.primaryMemberCodeWithEmailId);

    // Sending selected menu action to staff details screen
    setMenuAction(action + record.primaryMemberCodeWithEmailId);
  } else {
    let otherParams = {};
    if (isFTDUsersAccountsListing) {
      otherParams = {
        department: record.department,
        type: record.type,
      };
    } else {
      otherParams = {
        profileNotes: record.profileNotes,
      };
    }

    const payload = {
      id: record.id,
      firstName: record.firstName,
      lastName: record.lastName,
      emailId: record.emailId,
      phone: record.phone,
      status: disableAccount ? "INACTIVE" : "ACTIVE",
      employmentStatus: disableAccount ? "TERMINATED" : "FULLTIME",
      partnerGroup: record.partnerGroup,
      authGroups: record.authGroups,
      memberCode: record.memberCode,
      ...otherParams,
    };

    request("edit-staff-profile", payload)
      .then((res) => {
        onAction(`${record.emailId}_${action}`, recordId);
        ToasterHandler(
          disableAccount ? "until we meet again" : "nice",
          disableAccount
            ? Localise(messages, "This account is now disabled")
            : Localise(messages, "This account is now active")
        );
      })
      .catch((err) => {
        ToasterHandler(
          "uh oh",
          disableAccount
            ? Localise(messages, "Please try to disable the account again")
            : Localise(messages, "Please try to enable the account again")
        );
      });
  }
};

export const StaffList = React.memo(
  ({
    data,
    recordId,
    setRecordId,
    columnsList,
    onAction,
    isSmallScreen,
    isMobile,
    listingType,
    setMenuAction,
    showaction,
  }) => {
    const { userRole } = React.useContext(UserProfileContext);

    const isFTDUsersAccountsListing = listingType === "ftd-user-accounts";

    const isEditEligible = isFTDUsersAccountsListing
      ? FTDUserPermissions[toUpper(userRole)]
      : showaction;

    const { messages, Localise } = React.useContext(I18NContext);

    return (
      <View style={{ paddingTop: 10 }}>
        {data.map((record, index) => {
          const isSelectedRecord =
            recordId === record.primaryMemberCodeWithEmailId;
          const employmentStatus = Localise(
            messages,
            statusMap[record.employmentStatus]
          );

          const menuOptions = isEditEligible
            ? [actionItems[record.status === "ACTIVE" ? "enabled" : "disabled"]]
            : [];

          return (
            <React.Fragment key={index}>
              <ListItem
                item={{
                  ...record,
                  employmentStatus,
                  statusColor: record.isAccountLocked ? "#E5175E" : "#3A7D8D",
                  phone: phoneNumberFormatter(record.phone),
                }}
                // we need primary member code also while updating staff from side car so sending member code to side car by combining with staff email id.
                itemKey={"primaryMemberCodeWithEmailId"}
                setRecordId={setRecordId}
                columnsList={columnsList}
                isSelectedRecord={isSelectedRecord}
                submitAction={(e) =>
                  submitAction(
                    e,
                    onAction,
                    recordId,
                    record,
                    isFTDUsersAccountsListing,
                    messages,
                    setRecordId,
                    setMenuAction,
                    Localise
                  )
                }
                menuOptions={menuOptions}
                isSmallScreen={isSmallScreen}
                isMobile={isMobile}
                listingType={listingType}
              />
            </React.Fragment>
          );
        })}
      </View>
    );
  }
);

export default StaffList;
