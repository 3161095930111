import moment from "moment";

export const getNewDatesTimesObj = (hideDateFields, setFieldValue) => {
  const date = new Date();
  setFieldValue(
    "startDate",
    hideDateFields ? "" : moment(date).format("YYYY-MM-DD")
  );
  setFieldValue(
    "endDate",
    hideDateFields ? "" : moment(date).format("YYYY-MM-DD")
  );
};

export const newException = [
  {
    label: "",
    criteria: "",
    cityZipInfo: {},
    status: true,
    day: [],
    cutOffTime: "",
    startDate: "",
    endDate: "",
    rule: "CUT_OFF_TIME",
  },
];
