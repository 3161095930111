import * as Yup from "yup";

export const capacityDropdownValues = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "15", value: 15 },
  { label: "20", value: 20 },
  { label: "25", value: 25 },
  { label: "30", value: 30 },
  { label: "35", value: 35 },
  { label: "40", value: 40 },
  { label: "45", value: 45 },
  { label: "50", value: 50 },
];

export const validationSchema = (messages, Localise) => {
  return Yup.object({
    name: Yup.string()
      .label("name")
      .required(Localise(messages, "Enter a Title"))
      .min(3, Localise(messages, "Title must have atleast 3 characters"))
      .max(200, Localise(messages, "Title must not exceed 200 characters")),
    capacity: Yup.string()
      .required()
      .matches(/^[0-9]/, Localise(messages, "Select Capacity")),
  });
};
