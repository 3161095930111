import React, { useEffect, useState } from "react";
import { View, ActivityIndicator } from "react-native";
import { Text, Image } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";

import { ToasterHandler, TabBar, RadioButton } from "components/elements";

import { SaveCancelButtons, Currency } from "components/wrappers";

import I18NContext from "library/contexts/i18N";
import { handleRefund } from "library/sagas/views/home/drawer/mol-refunds/slice";
import {
  selectTabNames,
  selectExtendedScreenData,
  selectOrderLookup,
  selectIsPageLoading,
} from "library/sagas/views/home/drawer/mol-refunds/selector";
import { fonts, colors } from "styles/theme";
import IMAGES from "static/assets/images";
import { Form, FormField, FormFieldPicker } from "components/elements/forms";
import { useFormikContext } from "formik";
import { DeviceContext } from "library/contexts/appSettings";
import { formatPrice } from "library/utils/formatter";
import tw from "tailwind-rn";
import { getRefundInitialValues, refundReasonsPickerData } from "./ui-config";

const Refunds = () => {
  const dispatch = useDispatch();
  const sideCarTabNames = useSelector(selectTabNames);
  const { activeRecord = "" } = useSelector(selectExtendedScreenData);
  const orderLookup = useSelector(selectOrderLookup(activeRecord));
  const isPageLoading = useSelector(selectIsPageLoading);
  const { Localise, messages } = React.useContext(I18NContext);
  const [currentTab, setCurrentTab] = useState("payments");
  const [isFullRefund, setIsFullRefund] = useState(false);
  const [refundOptionValue, setRefundOptionValue] = useState("");
  const refundOptions = [
    {
      id: 1,
      name: Localise(messages, "Full Refund"),
      key: "fullRefund",
      selected: true,
    },
    {
      id: 2,
      name: Localise(messages, "Partial Refund"),
      key: "partialRefund",
      selected: false,
    },
  ];
  const {
    lineItems = [],
    orderTotal = "",
    deliveryTipTotal = "",
    subTotal = "",
    totalTaxAmount = "",
    totalTaxRate = "",
    totalTaxTypes = [],
    paymentMethodDetails = {},
    memberCode = "",
    feeSummary = {},
    refundDetails = [],
    refundedAmounts = {},
    discountAmount = "",
  } = orderLookup || {};
  const currency = Currency(memberCode);

  const { deliveryTipRefundedAmount = 0, taxRefundedAmount = 0 } =
    refundedAmounts || {};
  const { refundTotal = 0 } = lineItems[0]?.lineItemRefundAmounts || {};
  const refundedTotalAmount =
    Number(refundTotal) + Number(deliveryTipRefundedAmount);

  const { paymentStatus = "" } = paymentMethodDetails || {};
  const isFullyRefunded =
    paymentStatus === "REFUNDED" ||
    Number(refundedTotalAmount) === Number(orderTotal);

  const onSubmit = (values, formikBag) => {
    const {
      serviceFeeAmount,
      rushFeeAmount,
      retailDeliveryFeeAmount,
      deliveryTip,
      feeAmount,
      lineItems,
      refundInitiatedAmount,
    } = values;
    const lineItemRefundAmounts = lineItems.map((lineItem) => {
      return {
        productId: lineItem.productId,
        productType: lineItem.productType,
        refundAmount: formatPrice(lineItem.refundAmount),
      };
    });
    const isTotalRefundAmountMatching =
      Number(refundInitiatedAmount).toFixed(2) ===
      Number(orderTotal).toFixed(2);

    const isPartial = isTotalRefundAmountMatching ? false : values.isPartial;
    const payload = {
      ...values,
      serviceFeeAmount: formatPrice(serviceFeeAmount),
      rushFeeAmount: formatPrice(rushFeeAmount),
      retailDeliveryFeeAmount: formatPrice(retailDeliveryFeeAmount),
      deliveryTip: formatPrice(deliveryTip),
      feeAmount: formatPrice(feeAmount),
      lineItems: lineItemRefundAmounts,
      isPartial,
    };

    dispatch(
      handleRefund({
        params: payload,
        resolve: (text) => {
          ToasterHandler("success", Localise(messages, text));
          formikBag.setSubmitting(false);
          setIsFullRefund(false);
          setRefundOptionValue("");
        },
        reject: (text) => {
          ToasterHandler("error", Localise(messages, text));
          formikBag.setSubmitting(false);
          setIsFullRefund(false);
        },
      })
    );
  };

  const onFullRefund = (formikBag) => {
    const { setFieldValue } = formikBag;

    const {
      lineItems = [],
      feeSummary = {},
      refundedAmounts: {
        feeRefundedAmount = 0,
        serviceFeeRefundedAmount = 0,
        rushFeeRefundedAmount = 0,
        deliveryTipRefundedAmount = 0,
        taxRefundedAmount = 0,
      } = {},
    } = orderLookup || {};
    const lineItemRefundAmounts = lineItems.map((lineItem) => {
      const {
        retailProductAmount = 0,
        refundedAmount = 0,
        discountAmount: lineItemDiscountAmount = 0,
      } = lineItem?.lineItemAmounts;
      const eligibleRefundAmount =
        Number(retailProductAmount) -
        Number(lineItemDiscountAmount) -
        Number(refundedAmount);

      return {
        productId: lineItem.productId,
        productType: lineItem.type === "addon" ? "Addon" : "Product",
        refundAmount: parseFloat(eligibleRefundAmount).toFixed(2),
      };
    });

    const {
      standardDelivery = 0,
      rushDeliveryFee = 0,
      fuelSurcharge = 0,
    } = feeSummary;

    const eligibleServiceFeeRefundedAmount =
      serviceFeeRefundedAmount > 0
        ? Number(fuelSurcharge) - Number(serviceFeeRefundedAmount)
        : fuelSurcharge;

    const eligibleDeliveryFeeRefundAmount =
      feeRefundedAmount > 0
        ? Number(standardDelivery) - Number(feeRefundedAmount)
        : standardDelivery;

    const eligibleRushFeeRefundedAmount =
      rushFeeRefundedAmount > 0
        ? Number(rushDeliveryFee) - Number(rushFeeRefundedAmount)
        : rushDeliveryFee;

    const eligibleDeliveryTipRefundAmount =
      deliveryTipRefundedAmount > 0
        ? Number(deliveryTipTotal) - Number(deliveryTipRefundedAmount)
        : deliveryTipTotal;
    const eligibleTaxRefundAmount =
      taxRefundedAmount > 0
        ? Number(totalTaxAmount) - Number(taxRefundedAmount)
        : totalTaxAmount;

    setFieldValue("lineItems", lineItemRefundAmounts);
    setFieldValue(
      "feeAmount",
      parseFloat(eligibleDeliveryFeeRefundAmount).toFixed(2)
    );
    setFieldValue(
      "serviceFeeAmount",
      parseFloat(eligibleServiceFeeRefundedAmount || 0).toFixed(2)
    );
    setFieldValue(
      "rushFeeAmount",
      parseFloat(eligibleRushFeeRefundedAmount || 0).toFixed(2)
    );
    setFieldValue(
      "deliveryTip",
      parseFloat(eligibleDeliveryTipRefundAmount || 0).toFixed(2)
    );
    setFieldValue(
      "taxAmount",
      parseFloat(eligibleTaxRefundAmount || 0).toFixed(2)
    );
    setFieldValue("isPartial", false);
  };

  const initialValues = getRefundInitialValues({
    deliveryGroupId: activeRecord,
    orderLookup,
  });
  let totalRefundAmount = 0;

  if (isPageLoading) {
    return <ActivityIndicator size="small" />;
  }

  return (
    <>
      {orderLookup ? (
        <Form
          initialValues={initialValues}
          validateOnChange={true}
          validateOnBlur={false}
          onSubmit={onSubmit}
          render={(formikBag) => {
            const {
              setFieldValue,
              values: {
                lineItems: updatedLineItems = [],
                feeAmount = 0,
                serviceFeeAmount = 0,
                taxAmount = 0,
                rushFeeAmount = 0,
                deliveryTip = 0,
                retailDeliveryFeeAmount = 0,
                refundInitiatedAmount = 0,
              },
            } = formikBag;

            const lineItemsRefunds = updatedLineItems.reduce((acc, obj) => {
              acc = acc + Number(obj.refundAmount);
              return acc;
            }, 0);

            totalRefundAmount =
              lineItemsRefunds +
              Number(taxAmount) +
              Number(feeAmount) +
              Number(rushFeeAmount) +
              Number(deliveryTip) +
              Number(isFullRefund ? retailDeliveryFeeAmount : 0) +
              Number(serviceFeeAmount);

            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              setFieldValue(
                "refundInitiatedAmount",
                totalRefundAmount.toFixed(2)
              );
              setFieldValue("partialAmount", totalRefundAmount.toFixed(2));
            }, [totalRefundAmount]);

            return (
              <>
                {activeRecord && (
                  <View>
                    <TabBar
                      tabNames={sideCarTabNames}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                      scrollTabBar={true}
                      showScrollBar={false}
                      showsScrollIndicators={true}
                      showBorder={false}
                      style={{
                        paddingHorizontal: 0,
                      }}
                    />
                    {currentTab === "payments" ? (
                      <View>
                        {!isFullyRefunded && (
                          <View
                            style={tw("flex flex-col my-2 items-start ml-3")}
                          >
                            <View style={tw("flex flex-row my-1 mt-5")}>
                              <Text
                                style={{
                                  ...fonts.heading5,
                                  textTransform: "capitalize",
                                }}
                              >
                                {`${Localise(
                                  messages,
                                  "Choose A Refund Method"
                                )}`}
                              </Text>
                            </View>
                            <RadioButton
                              data={refundOptions.map((e) => ({
                                ...e,
                                selected: e.key === refundOptionValue,
                              }))}
                              onPress={(evt) => {
                                setRefundOptionValue(evt.key);
                                if (evt.key === "fullRefund") {
                                  setIsFullRefund(true);
                                  onFullRefund(formikBag);
                                }
                              }}
                            />
                          </View>
                        )}

                        <View
                          style={[
                            tw("flex flex-col ml-3 mt-3 pr-5"),
                            {
                              width:
                                isFullyRefunded || refundOptionValue == ""
                                  ? "80%"
                                  : "100%",
                            },
                          ]}
                        >
                          <View
                            style={[
                              tw(
                                "flex flex-row items-start justify-between  mb-5"
                              ),
                            ]}
                          >
                            <View
                              style={[tw("flex flex-wrap"), { width: "60%" }]}
                            ></View>
                            <Text style={[{ ...fonts.default }]}></Text>
                            <Text
                              style={[
                                {
                                  ...fonts.default,
                                  fontWeight: "600",
                                  width: "20%",
                                },
                              ]}
                            >
                              Refunded
                            </Text>
                            {!isFullyRefunded && refundOptionValue !== "" ? (
                              <Text
                                style={[
                                  {
                                    ...fonts.default,
                                    fontWeight: "600",
                                    width: "20%",
                                  },
                                ]}
                              >
                                Refund
                              </Text>
                            ) : null}
                          </View>
                          {lineItems.map((item, index) => {
                            const {
                              refundedAmount: lineItemRefundedAmount = 0,
                              retailProductAmount = 0,
                              discountAmount: lineItemDiscountAmount = 0,
                            } = item.lineItemAmounts || {};
                            const eligibleLineitemRefundAmount =
                              Number(retailProductAmount) -
                              Number(lineItemDiscountAmount) -
                              Number(lineItemRefundedAmount);
                            const {
                              quantity: productQuantity = 0,
                              productName = "",
                            } = item;

                            return (
                              <View
                                key={index}
                                style={[
                                  tw(
                                    "flex flex-row items-center justify-between flex-wrap mb-1"
                                  ),
                                ]}
                              >
                                <View
                                  style={[
                                    tw("flex flex-wrap pr-2"),
                                    { width: "40%" },
                                  ]}
                                >
                                  <Text
                                    style={[{ ...fonts.default }]}
                                  >{`${productName} x ${productQuantity}`}</Text>
                                </View>
                                <Text
                                  style={[
                                    {
                                      ...fonts.default,
                                      width: "20%",
                                    },
                                  ]}
                                >{`$${parseFloat(retailProductAmount).toFixed(
                                  2
                                )}`}</Text>
                                <Text
                                  style={[
                                    {
                                      ...fonts.default,
                                      color: colors.red,
                                      width: "20%",
                                    },
                                  ]}
                                >{`$${parseFloat(
                                  lineItemRefundedAmount
                                ).toFixed(2)}`}</Text>

                                {!isFullyRefunded &&
                                refundOptionValue !== "" ? (
                                  <FormField
                                    name={`lineItems.${index}.refundAmount`}
                                    testID={"productRefundAmount"}
                                    isPrice={true}
                                    containerStyle={{
                                      width: "20%",
                                      paddingHorizontal: 0,
                                    }}
                                    editable={
                                      refundOptionValue === "partialRefund"
                                    }
                                    grayedOutOnDisable={
                                      refundOptionValue === "fullRefund"
                                    }
                                    accessibilityLabel={"productRefundAmount"}
                                    handleOnBlur={(newVal) => {
                                      if (
                                        Number(newVal) >
                                        Number(eligibleLineitemRefundAmount)
                                      ) {
                                        setFieldValue(
                                          `lineItems.${index}.refundAmount`,
                                          0
                                        );
                                        ToasterHandler(
                                          "error",
                                          `Refund Amount should not be greater than $${parseFloat(
                                            eligibleLineitemRefundAmount
                                          ).toFixed(2)} `
                                        );
                                      }
                                    }}
                                    iconType={"material-community"}
                                    iconName={"currency-usd"}
                                    iconSize={14}
                                    currency={currency}
                                  />
                                ) : null}
                              </View>
                            );
                          })}

                          <View
                            style={[
                              tw(
                                "flex flex-row items-center justify-between my-2"
                              ),
                            ]}
                          >
                            <View
                              style={[
                                tw("flex flex-wrap"),
                                {
                                  width:
                                    isFullyRefunded || !refundOptionValue
                                      ? "50%"
                                      : "40%",
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  {
                                    ...fonts.default,
                                    fontWeight: "600",
                                    maxWidth: 100,
                                  },
                                ]}
                              >
                                Subtotal
                              </Text>
                            </View>
                            <Text
                              style={[
                                {
                                  ...fonts.default,
                                  fontWeight: "600",
                                  width: "60%",
                                },
                              ]}
                            >
                              {`$${parseFloat(subTotal).toFixed(2)}`}
                            </Text>
                          </View>
                          <FeeSummaryFields
                            feeSummary={feeSummary}
                            isFullyRefunded={isFullyRefunded}
                            refundOptionValue={refundOptionValue}
                          />
                          {Number(deliveryTipTotal) > 0 ? (
                            <View
                              style={[
                                tw(
                                  "flex flex-row items-center justify-between"
                                ),
                              ]}
                            >
                              <Text
                                style={[
                                  tw("my-1"),
                                  { ...fonts.default, width: "40%" },
                                ]}
                              >
                                Tip
                              </Text>
                              <Text
                                style={[
                                  tw("my-1"),
                                  { ...fonts.default, width: "20%" },
                                ]}
                              >
                                {`$${parseFloat(deliveryTipTotal).toFixed(2)}`}
                              </Text>
                              <Text
                                style={[
                                  tw("my-1"),
                                  {
                                    ...fonts.default,
                                    width: "20%",
                                    color: colors.red,
                                  },
                                ]}
                              >
                                {`$${parseFloat(
                                  deliveryTipRefundedAmount || 0
                                ).toFixed(2)}`}
                              </Text>
                              {!isFullyRefunded && refundOptionValue !== "" ? (
                                <FormField
                                  name={`deliveryTip`}
                                  testID={"deliveryTip"}
                                  isPrice={true}
                                  containerStyle={{
                                    width: "20%",
                                    paddingHorizontal: 0,
                                  }}
                                  editable={
                                    refundOptionValue === "partialRefund"
                                  }
                                  grayedOutOnDisable={
                                    refundOptionValue === "fullRefund"
                                  }
                                  accessibilityLabel={""}
                                  handleOnBlur={(newVal) => {
                                    if (
                                      Number(newVal) > Number(deliveryTipTotal)
                                    ) {
                                      setFieldValue("deliveryTip", 0);
                                      ToasterHandler(
                                        "error",
                                        `Refund Amount should not be greater than $${parseFloat(
                                          deliveryTipTotal
                                        ).toFixed(2)} `
                                      );
                                    }
                                  }}
                                  iconType={"material-community"}
                                  iconName={"currency-usd"}
                                  iconSize={14}
                                  currency={currency}
                                />
                              ) : null}
                            </View>
                          ) : null}
                          {Number(discountAmount) > 0 ? (
                            <View
                              style={[
                                tw("flex flex-row items-center justify-start"),
                              ]}
                            >
                              <Text
                                style={[
                                  tw("my-1"),
                                  {
                                    ...fonts.default,
                                    width:
                                      isFullyRefunded || !refundOptionValue
                                        ? "50%"
                                        : "40%",
                                  },
                                ]}
                              >
                                Discounts
                              </Text>
                              <Text
                                style={[
                                  tw("my-1"),
                                  {
                                    ...fonts.default,
                                    width: "20%",
                                    color: colors.red,
                                  },
                                ]}
                              >
                                {`$${parseFloat(discountAmount).toFixed(2)}`}
                              </Text>
                            </View>
                          ) : null}
                          <Taxes
                            totalTaxAmount={totalTaxAmount}
                            totalTaxRate={totalTaxRate}
                            totalTaxTypes={totalTaxTypes}
                            refundedLineItemTaxAmount={taxRefundedAmount}
                            isFullyRefunded={isFullyRefunded}
                            isFullRefund={isFullRefund}
                            orderLookup={orderLookup}
                            refundOptionValue={refundOptionValue}
                          />
                          <View
                            style={[
                              tw(
                                "flex flex-row items-center justify-start mb-2 mt-5"
                              ),
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  width:
                                    isFullyRefunded || !refundOptionValue
                                      ? "50%"
                                      : "40%",
                                  ...fonts.default,
                                  fontWeight: "600",
                                },
                              ]}
                            >
                              Total
                            </Text>
                            <Text
                              style={[
                                {
                                  ...fonts.default,
                                  fontWeight: "600",
                                  width:
                                    isFullyRefunded || !refundOptionValue
                                      ? "30%"
                                      : "20%",
                                },
                              ]}
                            >
                              {`$${parseFloat(orderTotal).toFixed(2)}`}
                            </Text>
                            <Text
                              style={[
                                {
                                  ...fonts.default,
                                  fontWeight: "600",
                                  width: "20%",
                                  color: colors.red,
                                },
                              ]}
                            >
                              {`$${parseFloat(refundedTotalAmount).toFixed(2)}`}
                            </Text>
                          </View>
                          <PaymentCharges
                            paymentDetails={paymentMethodDetails}
                            refundDetails={refundDetails}
                            isFullyRefunded={isFullyRefunded}
                          />
                          {refundedTotalAmount > 0 && (
                            <View style={tw("flex flex-row my-1 mt-2")}>
                              <Text
                                style={{
                                  ...fonts.heading5,
                                  width: isFullyRefunded ? "80%" : "60%",
                                  textTransform: "capitalize",
                                }}
                              >
                                {Localise(messages, "Total Refunded Amount")}
                              </Text>
                              <Text
                                style={{
                                  ...fonts.heading5,
                                  width: "20%",
                                  color: colors.red,
                                }}
                              >
                                {`$${parseFloat(refundedTotalAmount).toFixed(
                                  2
                                )}`}
                              </Text>
                            </View>
                          )}
                          {!isFullyRefunded ? (
                            <>
                              <View
                                style={[
                                  tw("flex flex-row items-center mb-2 mt-5"),
                                ]}
                              >
                                <Text
                                  style={[
                                    {
                                      width: "30%",
                                      ...fonts.default,
                                      fontWeight: "600",
                                    },
                                  ]}
                                >
                                  Refund Amount
                                </Text>
                                <Text
                                  style={[
                                    {
                                      ...fonts.default,
                                      width: "20%",
                                      backgroundColor: colors.inactive,
                                      borderWidth: 1,
                                      borderRadius: 2,
                                      borderColor: colors.grayScaleLight,
                                      textAlign: "center",
                                      paddingHorizontal: 10,
                                      paddingVertical: 8,
                                      marginLeft: 10,
                                    },
                                  ]}
                                >
                                  {`$${parseFloat(
                                    refundInitiatedAmount
                                  ).toFixed(2)}`}
                                </Text>
                              </View>
                              <View style={tw("flex flex-row my-1 mt-6")}>
                                <Text
                                  style={{
                                    ...fonts.heading5,
                                    paddingTop: 8,
                                    width: "30%",
                                  }}
                                >
                                  {Localise(messages, "Refund Reason")}
                                </Text>
                                <FormFieldPicker
                                  name="refundReason"
                                  placeholder={{
                                    label: "Select Reason",
                                    value: "",
                                  }}
                                  data={refundReasonsPickerData()}
                                  label=""
                                  containerStyle={{
                                    width: "50%",
                                    paddingLeft: 0,
                                    marginLeft: 10,
                                  }}
                                />
                              </View>
                            </>
                          ) : null}
                        </View>
                        {!isFullyRefunded && totalRefundAmount > 0 ? (
                          <SaveCancelButtons
                            buttonTitle={Localise(messages, "Refund")}
                            onCancel={() => {
                              setIsFullRefund(false);
                              setRefundOptionValue("");
                            }}
                            customContainerStyles={{
                              width: 100,
                              marginLeft: 0,
                            }}
                          />
                        ) : null}
                      </View>
                    ) : null}
                  </View>
                )}
              </>
            );
          }}
        />
      ) : null}
    </>
  );
};

const Taxes = ({
  totalTaxAmount = "",
  totalTaxRate = "",
  totalTaxTypes = [],
  refundedLineItemTaxAmount = 0,
  isFullyRefunded = false,
  isFullRefund = false,
  orderLookup = {},
  refundOptionValue,
}) => {
  const [showTaxSplits, setShowTaxSplits] = useState(false);
  const {
    retailDeliveryFee = 0,
    refundedAmounts: { taxRefundedAmount = 0 },
  } = orderLookup || {};
  const {
    values: {
      taxAmount = 0,
      refundInitiatedAmount = 0,
      lineItems = [],
      feeAmount = 0,
      serviceFeeAmount = 0,
      rushFeeAmount = 0,
    },
    setFieldValue,
  } = useFormikContext();

  const lineItemsRefunds = lineItems.reduce((acc, obj) => {
    acc = acc + Number(obj.refundAmount);
    return acc;
  }, 0);

  const eligibleTaxRefundAmount =
    taxRefundedAmount > 0
      ? Number(totalTaxAmount) - Number(taxRefundedAmount)
      : totalTaxAmount;
  let refundInitiatedTaxAmount = 0;

  useEffect(() => {
    refundInitiatedTaxAmount = (
      ((Number(lineItemsRefunds) +
        Number(feeAmount) +
        Number(serviceFeeAmount) +
        Number(rushFeeAmount) +
        Number(isFullRefund ? retailDeliveryFee : 0)) *
        totalTaxRate) /
      100
    ).toFixed(2);

    const refundTaxAmount =
      Number(refundInitiatedTaxAmount) > Number(eligibleTaxRefundAmount)
        ? eligibleTaxRefundAmount
        : refundInitiatedTaxAmount;
    !isFullRefund && setFieldValue("taxAmount", refundTaxAmount);
  }, [refundInitiatedAmount]);

  return (
    <View style={tw("flex flex-col")}>
      <View style={[tw("flex flex-row items-start justify-start")]}>
        <View
          style={[
            tw("flex flex-row items-center"),
            { width: isFullyRefunded || !refundOptionValue ? "50%" : "40%" },
          ]}
        >
          <Text>Taxes</Text>
          <Text style={tw("mx-2")}>{`${totalTaxRate} %`}</Text>
          <Image
            style={{
              width: 20,
              height: 20,
            }}
            onPress={() => setShowTaxSplits(!showTaxSplits)}
            source={
              IMAGES[showTaxSplits ? "expanded-section" : "collapsed-section"]
            }
          />
        </View>
        <Text
          style={[
            tw("my-1"),
            {
              ...fonts.default,
              width: isFullyRefunded || !refundOptionValue ? "30%" : "20%",
            },
          ]}
        >
          {`$${parseFloat(totalTaxAmount).toFixed(2)}`}
        </Text>
        <Text
          style={[
            tw("my-1"),
            { ...fonts.default, width: "20%", color: colors.red },
          ]}
        >
          {`$${parseFloat(refundedLineItemTaxAmount).toFixed(2)}`}
        </Text>
        {!isFullyRefunded && refundOptionValue !== "" ? (
          <Text
            style={[
              tw("my-1"),
              { ...fonts.default, width: "20%", color: colors.red },
            ]}
          >
            {`$${parseFloat(taxAmount).toFixed(2)}`}
          </Text>
        ) : null}
      </View>
      {showTaxSplits && (
        <>
          {totalTaxTypes.map((taxType, index) => {
            return (
              <View
                key={index}
                style={[tw("flex flex-row items-end justify-start")]}
              >
                <View
                  style={[tw("flex flex-row items-center"), { width: "40%" }]}
                >
                  <Text>{taxType?.taxDescription}</Text>
                  <Text style={tw("mx-2 my-1")}>{`${
                    taxType?.taxRate * 100
                  } %`}</Text>
                </View>
                <Text style={[tw("my-1"), { ...fonts.default, width: "20%" }]}>
                  {`$${parseFloat(taxType.taxAmount).toFixed(2)}`}
                </Text>
              </View>
            );
          })}
        </>
      )}
    </View>
  );
};

const PaymentCharges = ({
  paymentDetails = {},
  refundDetails = [],
  isFullyRefunded,
  refundOptionValue,
}) => {
  const { paymentAmount, paymentDate, paymentCardType, paymentCardNumber } =
    paymentDetails || {};
  const { isMobile } = React.useContext(DeviceContext);

  return (
    <View style={tw("flex flex-col my-5")}>
      <Text
        style={[
          {
            width: isMobile ? "100%" : "50%",
            ...fonts.default,
            fontWeight: "600",
          },
        ]}
      >
        Charges
      </Text>
      <View style={[tw("flex flex-row items-end justify-start mb-2 mt-2")]}>
        <View
          style={[
            tw("flex flex-row"),
            {
              width:
                isFullyRefunded || !refundOptionValue || isMobile
                  ? "80%"
                  : "60%",
            },
          ]}
        >
          <Text style={[tw("mr-3"), { ...fonts.default, width: "55%" }]}>
            {paymentDate}
          </Text>
          <Text style={[{ ...fonts.default }]}>
            {`${paymentCardType} ***** ${paymentCardNumber}`}
          </Text>
        </View>
        <Text style={[{ width: 250, ...fonts.default }]}>
          {`$${paymentAmount}`}
        </Text>
      </View>
      {refundDetails?.map((refundData, index) => {
        return (
          <View
            style={[tw("flex flex-row items-end justify-start mb-2 mt-2")]}
            key={index}
          >
            <View
              style={[
                tw("flex flex-row"),
                {
                  width:
                    isFullyRefunded || !refundOptionValue || isMobile
                      ? "80%"
                      : "60%",
                },
              ]}
            >
              <Text style={[tw("mr-3"), { ...fonts.default, width: "55%" }]}>
                {refundData.refundDate}
              </Text>
              <Text style={[{ ...fonts.default }]}>
                {`${refundData.paymentCardType} ***** ${refundData.paymentCardNumber}`}
              </Text>
            </View>
            <Text style={[{ width: 250, ...fonts.default, color: colors.red }]}>
              {`$${refundData.refundAmount}`}
            </Text>
          </View>
        );
      })}
    </View>
  );
};

const FeeSummaryFields = ({
  feeSummary = {},
  isFullyRefunded = false,
  refundOptionValue,
}) => {
  const { activeRecord = "" } = useSelector(selectExtendedScreenData);
  const { memberCode = [], refundedAmounts = {} } =
    useSelector(selectOrderLookup(activeRecord)) || {};
  const feeSummaryFields = Object.keys(feeSummary);
  const currency = Currency(memberCode);

  const { setFieldValue } = useFormikContext();
  const {
    feeRefundedAmount: standardDeliveryRefundedAmount = 0,
    serviceFeeRefundedAmount: fuelSurchargeRefundedAmount = 0,
    rushFeeRefundedAmount: rushDeliveryFeeRefundedAmount = 0,
  } = refundedAmounts;

  const {
    standardDelivery = 0,
    rushDeliveryFee = 0,
    fuelSurcharge = 0,
  } = feeSummary;

  const eligibleServiceFeeRefundedAmount =
    fuelSurchargeRefundedAmount > 0
      ? Number(fuelSurcharge) - Number(fuelSurchargeRefundedAmount)
      : fuelSurcharge;

  const eligibleDeliveryFeeRefundAmount =
    standardDeliveryRefundedAmount > 0
      ? Number(standardDelivery) - Number(standardDeliveryRefundedAmount)
      : standardDelivery;

  const eligibleRushFeeRefundedAmount =
    rushDeliveryFeeRefundedAmount > 0
      ? Number(rushDeliveryFee) - Number(rushDeliveryFeeRefundedAmount)
      : rushDeliveryFee;

  const feeMappings = {
    standardDelivery: "Delivery Fee",
    rushDeliveryFee: "Rush Delivery Fee",
    retailDeliveryFee: "Retail Delivery Fee",
    fuelSurcharge: "Service Fee",
  };

  return (
    <>
      {feeSummaryFields.map((feeField, index) => {
        const fieldName =
          feeField === "standardDelivery"
            ? "feeAmount"
            : feeField === "fuelSurcharge"
            ? "serviceFeeAmount"
            : feeField === "rushDeliveryFee"
            ? "rushFeeAmount"
            : `${feeField}Amount`;

        const refundedFieldAmounts =
          feeField == "standardDelivery"
            ? standardDeliveryRefundedAmount
            : feeField === "fuelSurcharge"
            ? fuelSurchargeRefundedAmount
            : feeField === "rushDeliveryFee"
            ? rushDeliveryFeeRefundedAmount
            : 0;

        return (
          <View key={index}>
            {feeSummary[feeField] > 0 ? (
              <View
                style={[
                  tw(
                    `flex flex-row items-center ${
                      !isFullyRefunded &&
                      feeField === "retailDeliveryFee" &&
                      refundOptionValue
                        ? `justify-start`
                        : `justify-between`
                    }`
                  ),
                ]}
              >
                <View style={[tw("flex flex-wrap"), { width: "40%" }]}>
                  <Text style={[tw("my-1"), { ...fonts.default }]}>
                    {feeMappings[feeField]}
                  </Text>
                </View>
                <Text style={[tw("my-1"), { ...fonts.default, width: "20%" }]}>
                  {`$${parseFloat(feeSummary[feeField]).toFixed(2)}`}
                </Text>
                <Text
                  style={[
                    tw("my-1"),
                    {
                      ...fonts.default,
                      width: "20%",
                      color: colors.red,
                    },
                  ]}
                >
                  {`$${parseFloat(refundedFieldAmounts || 0).toFixed(2)}`}
                </Text>

                {!isFullyRefunded &&
                feeField !== "retailDeliveryFee" &&
                refundOptionValue !== "" ? (
                  <FormField
                    name={`${fieldName}`}
                    testID={`${fieldName}`}
                    isPrice={true}
                    containerStyle={{
                      width: "20%",
                      paddingHorizontal: 0,
                    }}
                    editable={refundOptionValue === "partialRefund"}
                    grayedOutOnDisable={refundOptionValue === "fullRefund"}
                    accessibilityLabel={`${fieldName}`}
                    handleOnBlur={(newVal) => {
                      const eligibleRefundAmount =
                        fieldName === "feeAmount"
                          ? eligibleDeliveryFeeRefundAmount
                          : fieldName === "serviceFeeAmount"
                          ? eligibleServiceFeeRefundedAmount
                          : eligibleRushFeeRefundedAmount;

                      if (Number(newVal) > eligibleRefundAmount) {
                        setFieldValue(`${fieldName}`, 0);
                        ToasterHandler(
                          "error",
                          `Refund Amount should not be greater than - $${parseFloat(
                            eligibleRefundAmount
                          ).toFixed(2)} `
                        );
                      }
                    }}
                    iconType={"material-community"}
                    iconName={"currency-usd"}
                    iconSize={14}
                    currency={currency}
                  />
                ) : null}
              </View>
            ) : null}
          </View>
        );
      })}
    </>
  );
};

export default Refunds;
