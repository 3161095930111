import React, { useState } from "react";
import { View } from "react-native";
import { Text, Image } from "react-native-elements";
import { createPortal } from "react-dom";
import { backgroundColors } from "styles/theme";
import { Tooltip } from "components/elements";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  restrictToParentElement,
  restrictToFirstScrollableAncestor,
} from "@dnd-kit/modifiers";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { IMAGES } from "static/assets/images";
import tw from "tailwind-rn";

function SortableItem(props) {
  const {
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
    setNodeRef, // Ref for Draggable node
    setActivatorNodeRef, // Ref for Drag Handle
  } = useSortable({ id: props.id, animateLayoutChanges: () => false });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 999 : 10,
    opacity: isDragging ? 0 : 1,
    display: "flex",
    alignItems: "center",
  };

  return (
    <div ref={setNodeRef} style={style}>
      <View style={{ cursor: "grab" }}>
        <Tooltip text={"Drag to reorder"} isSmallScreen={false}>
          <View style={tw("flex flex-row")}>
            {props.dragDropIcon && (
              <View ref={setActivatorNodeRef} {...listeners} {...attributes}>
                <Image
                  style={{ width: 20, height: 20, marginBottom: 5 }}
                  source={IMAGES["drag-drop-icon"]}
                />
              </View>
            )}
            <button
              ref={setActivatorNodeRef}
              style={{
                backgroundColor: backgroundColors.primary,
                borderColor: backgroundColors.secondary,
                width: 20,
                height: 20,
                marginBottom: 5,
                justifyContent: "center",
                alignItems: "center",
                marginRight: 10,
                boxShadow: "none",
                padding: 0,
                borderRadius: 20,
                border: "none",
                cursor: "grab",
              }}
              {...listeners}
              {...attributes}
            >
              <Text
                style={{
                  width: 20,
                  height: 20,
                  color: "#fff",
                  fontWeight: "600",
                }}
              >
                {props.index + 1}
              </Text>
            </button>
          </View>
        </Tooltip>
      </View>
      {/* List Item */}
      <View
        style={{ flex: 1 }}
        ref={props.dragDropIcon ? setActivatorNodeRef : undefined}
        {...(props.dragDropIcon ? { ...listeners, ...attributes } : {})}
      >
        {props.renderItem(props.id, props.index)}
      </View>
    </div>
  );
}

const DraggableFlatList = ({
  data = [],
  renderItem,
  onDragEnd,
  dragDropIcon = false,
}) => {
  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      activationConstraint: {
        distance: 1,
      },
    })
  );

  function handleDragStart(event) {
    const { active } = event;
    setActiveId(active.id);
  }

  return (
    <DndContext
      sensors={sensors}
      autoScroll={{
        layoutShiftCompensation: true,
        acceleration: 5,
      }}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      modifiers={[restrictToFirstScrollableAncestor, restrictToParentElement]}
    >
      <SortableContext items={data} strategy={verticalListSortingStrategy}>
        <View>
          {data.map((id, index) => (
            <SortableItem
              key={id}
              id={id}
              activeId={activeId}
              index={id?.index || index}
              renderItem={renderItem}
              dragDropIcon={dragDropIcon}
            />
          ))}
        </View>
      </SortableContext>

      {/* Drag Overlay when item if dragging */}
      {createPortal(
        <DragOverlay
          dropAnimation={{
            duration: 1000,
            easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
          }}
          modifiers={[
            restrictToFirstScrollableAncestor,
            restrictToParentElement,
          ]}
        >
          {activeId ? (
            <View style={{ cursor: "grabbing" }}>
              {renderItem(activeId, activeId?.orderIndex, true)}
            </View>
          ) : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = data.indexOf(active.id);
      const newIndex = data.indexOf(over.id);
      onDragEnd(arrayMove(data, oldIndex, newIndex));
    }
    setActiveId(null);
  }
};

export default DraggableFlatList;
