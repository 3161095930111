import React, { useCallback, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import { Picker } from "components/elements";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import isNil from "lodash/isNil";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { colors } from "styles/theme";

const FormFieldPicker = ({
  data,
  name,
  label,
  labelFieldSet,
  placeholder,
  labelStyle,
  containerStyle,
  path,
  parentPath = "",
  defaultValue = "",
  onChange,
  disabled,
  pickerRef,
  disabledFieldTouch = false,
  innerContainerStyle = {},
  isRequired = false,
  placeHolderExists = true,
  customDisabledStyle = false,
}) => {
  const { errors, setStatus, setFieldTouched, setFieldValue, touched, values } =
    useFormikContext();
  const key = (path && `${path}.${name}`) || name;
  const fieldValue = get(values, key) || defaultValue;
  const touchedVal = get(touched, key);
  const errorMsg = touchedVal && get(errors, key);
  const [value, setValue] = useStateIfMounted(fieldValue);
  const { messages, Localise } = React.useContext(I18NContext);

  useEffect(() => {
    //When current state and formik values are same, we can skip setting formik again
    if (value === fieldValue) return;
    if (fieldValue == "" || isNil(fieldValue)) {
      setValue("");
    }
    setValue(fieldValue);
    //Todo:- Below code can be removed. Setting Field value on change of field values seems invalid
    !disabledFieldTouch && setFieldTouched(key);
    setFieldValue(key, fieldValue);
    if (!isEmpty(parentPath) && fieldValue !== "") {
      //workaround for setting deliveryMethod in outer object structure for validation
      setFieldValue(`${parentPath}.${name}`, fieldValue);
      setFieldValue(`${parentPath}.recipientInfo.${name}`, fieldValue);
    }
  }, [fieldValue]);

  const handleChange = useCallback(
    (itemValue) => {
      if (!isNil(itemValue)) {
        if (name === "deliveryMethod") {
          setStatus(true);
        }
        let val = itemValue;
        if (
          placeHolderExists &&
          Localise(messages, placeholder.label) === itemValue
        )
          val = "";
        setValue(val);
        setFieldTouched(key);
        setFieldValue(key, val);
        onChange && onChange(val);
      }
    },
    [fieldValue]
  );
  return useMemo(() => {
    return (
      <Picker
        placeholder={
          !isEmpty(placeholder)
            ? {
                ...placeholder,
                label: Localise(messages, placeholder.label),
                value: null,
                color: "#9EA0A4",
              }
            : placeHolderExists
            ? {
                ...placeholder,
                label: Localise(messages, placeholder.label),
              }
            : {}
        }
        label={`${Localise(messages, label)}${isRequired ? "*" : ""}`}
        labelFieldSet={labelFieldSet}
        labelStyle={labelStyle}
        value={value}
        containerStyle={containerStyle}
        onValueChange={handleChange}
        items={data.map((item) => {
          const { value, label, enabled = true, color = colors.dark } = item;
          return {
            value: value,
            label: Localise(messages, label),
            enabled: enabled,
            color: enabled ? color : colors.medium,
          };
        })}
        errorMsg={errorMsg}
        name={name}
        disabled={disabled}
        customDisabledStyle={customDisabledStyle}
        pickerRef={pickerRef}
        innerContainerStyle={
          errorMsg
            ? { borderColor: colors.error, ...innerContainerStyle }
            : { ...innerContainerStyle }
        }
      />
    );
  }, [data, errorMsg, fieldValue, touchedVal, value, messages, disabled]);
};

export default React.memo(FormFieldPicker);
