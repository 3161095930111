import { takeLatest, put, call } from "redux-saga/effects";
import {
  fetchData,
  setData,
  fetchDSRequestDetails,
  setDSRequestDetails,
  setDSRequestLoading,
} from "./slice";
import { request } from "library/utils/request";
import {
  processDSResponse,
  refineDSListings,
} from "library/utils/deliveryService";

import get from "lodash/get";
import moment from "moment";
import { hashString } from "react-hash-string";
import Environment from "library/utils/environment";
import { convertToTimeZone } from "library/utils/deliveryService";
import UserProfileStorage from "library/storage/userProfile";
import { ListOfApproachingDriversStates } from "components/views/drawer/delivery/delivery-service-dashboard/config.js";

//service requests
const getDSRequestList = (params) => request("get-delivery-tasks-list", params);
const getDSRequestDetails = (params) =>
  request("get-delivery-request-details", params);

const getApproachingDriversList = (refinedDSRequestsCollection) => {
  let ApproachingDriversList = [];

  Object.keys(refinedDSRequestsCollection).map((DSCategory) => {
    if (DSCategory !== "approachingDrivers") {
      refinedDSRequestsCollection[DSCategory].list
        .map((request) => {
          const shopTimeZone = UserProfileStorage.getShopTimeZone(
            request.siteId
          );
          const floristTimeZone = shopTimeZone || "America/Chicago";
          const pickUpDateTime = convertToTimeZone(
            request?.pickup?.timeWindow?.start,
            floristTimeZone
          );
          const deliveryDate = convertToTimeZone(
            request.delivery.timeWindow.start,
            floristTimeZone
          );
          const displayDate = moment(deliveryDate).format("MM/DD/YYYY");
          const pickupTime = moment(pickUpDateTime).format("h:mm A");
          const currentFloristTime = convertToTimeZone(
            moment.utc().add(20, "minutes").format(),
            floristTimeZone
          );
          let expectedTime;
          if (request?.pickup?.expectedTime) {
            expectedTime = convertToTimeZone(
              request?.pickup?.expectedTime,
              floristTimeZone
            );
          }

          const isHurryPickup = expectedTime
            ? currentFloristTime >= expectedTime
            : currentFloristTime >= pickUpDateTime;
          return {
            ...request,
            pickupTime,
            floristTimeZone,
            displayDate,
            deliveryDate,
            isHurryPickup,
            expectedTime: expectedTime
              ? moment(expectedTime).format("h:mm A")
              : null,
          };
        })
        .filter((request) => {
          const currentFloristDate = convertToTimeZone(
            moment.utc().format(),
            request.floristTimeZone
          );
          return moment(request.displayDate).isSame(currentFloristDate, "day");
        })
        .map((request) => {
          if (ListOfApproachingDriversStates.includes(request.state)) {
            let filteredRequests = ApproachingDriversList.filter((driver) => {
              //getting unique drivers list based on shop,driver,pickupTime
              return (
                driver.name == request?.courier?.name &&
                driver?.shipments?.some((shipment) => {
                  return shipment?.pickup?.expectedTime
                    ? shipment.pickup.expectedTime === request.expectedTime
                    : shipment.pickupTime === request.pickupTime;
                }) &&
                driver?.shipments?.some((shipment) => {
                  return shipment.siteId === request.siteId;
                })
              );
            });
            if (filteredRequests.length) {
              filteredRequests[0].shipments = [
                ...filteredRequests[0].shipments,
                request,
              ];
            } else if (request?.courier?.name) {
              ApproachingDriversList.push({
                name: request.courier.name,
                shipments: [request],
              });
            }
          }
        });
    }
  });

  ApproachingDriversList = ApproachingDriversList.map((pickupDriverBatch) => {
    const { shipments = [], name: driverName } = pickupDriverBatch;
    const {
      pickupTime,
      expectedTime,
      deliveryDate,
      siteId: shopCode,
    } = shipments[0];

    const ids = shipments
      .map((each) => each.shipmentId)
      .sort()
      .join("_");

    const reqBody = {
      printKind: "DRIVER_PICKUP",
      printKindId: hashString(
        `${shopCode}_${driverName}_${moment(deliveryDate).format(
          "MM-DD-YYYY"
        )}_${expectedTime || pickupTime}_${ids}`
      ),
    };

    return {
      ...pickupDriverBatch,
      reqParams: reqBody,
    };
  }).filter((pickupDriverBatch) => {
    const { shipments = [] } = pickupDriverBatch;
    const shipmentsToPickup = shipments?.filter((shipment) =>
      ["COURIER_ASSIGNED", "PICKUP_IN_PROGRESS", "AT_PICKUP"].includes(
        shipment.state
      )
    );
    return !!shipmentsToPickup.length;
  });

  return ApproachingDriversList;
};

const applySearchFilters = (DSRequestsCollection, actions) => {
  const refinedDSRequestsCollection = DSRequestsCollection;
  Object.keys(refinedDSRequestsCollection).map((DSCategory) => {
    if (DSCategory !== "approachingDrivers") {
      refinedDSRequestsCollection[DSCategory].list = refineDSListings(
        refinedDSRequestsCollection[DSCategory].list,
        DSCategory,
        actions
      );
    }
  });
  const isQuickFiltersVisible = Environment.get(
    "SHOW_DELIVERY_QUICK_FILTERS",
    false
  );
  refinedDSRequestsCollection["approachingDrivers"].list = isQuickFiltersVisible
    ? getApproachingDriversList(refinedDSRequestsCollection)
    : [];

  return refinedDSRequestsCollection;
};

/**
 * WORKER: Fetches data and publishes the successful result, or an error
 * @param {action} action the fetch data action definition
 */
function* handleFetchDSRequests(action = {}) {
  try {
    const {
      params,
      initData,
      setLoading,
      actions,
      auto_print = false,
    } = get(action, "payload", {});
    const response = yield call(getDSRequestList, params);

    const deliveryDateMap = response?.map((DSRequest) => {
      return moment(get(DSRequest, "delivery.timeWindow.start", ""));
    });

    const minDeliveryDate = deliveryDateMap.length
      ? moment.min(deliveryDateMap)
      : params.deliveryStartDate;
    const maxDeliveryDate = deliveryDateMap.length
      ? moment.max(deliveryDateMap)
      : "";

    const updatedResp = processDSResponse(response, initData);

    const { DSRequestsCollection, timeStamp } = updatedResp;

    const organisedDSData = applySearchFilters(DSRequestsCollection, actions);

    yield put(
      setData({
        originalData: DSRequestsCollection,
        ...(auto_print
          ? {
              AutoPrintData: organisedDSData,
              pickupManifestTimeStamp: timeStamp,
            }
          : {
              data: organisedDSData,
              timeStamp: timeStamp,
            }),
        minDeliveryDate: minDeliveryDate,
        maxDeliveryDate: maxDeliveryDate,
      })
    );
    setLoading && setLoading(false);
  } catch (error) {
    const { setLoading } = get(action, "payload", {});
    setLoading && setLoading(false);
    console.log("DS listing error: ", error);
  }
}

/**
 * WORKER: Fetches data and publishes the successful result, or an error
 * @param {action} action the fetch data action definition
 */
function* handleFetchDSRequestDetails(action = {}) {
  yield put(setDSRequestLoading(true));
  try {
    const { params } = get(action, "payload", {});
    const response = yield call(getDSRequestDetails, params);
    yield put(setDSRequestDetails(response));
    yield put(setDSRequestLoading(false));
  } catch (error) {
    yield put(setDSRequestDetails({}));
    console.log(error);
  }
}

/**
 * WATCHER: Subscribe to Page actions
 */
export default function* watchSaga() {
  yield takeLatest(fetchData.type, handleFetchDSRequests);
  yield takeLatest(fetchDSRequestDetails.type, handleFetchDSRequestDetails);
}
