import React, { useEffect } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import MainScreen from "./main";
import ExtendedScreen from "./extended";

import SplitScreen from "components/containers/split-screen-new";

import { EmptyScreen, Header } from "./helper";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";

import {
  fetchAllCollections,
  setPageReset,
} from "library/sagas/views/home/drawer/ftd-admin/mol-settings/slice";

import { selectExtendedScreen } from "library/sagas/views/home/drawer/ftd-admin/mol-settings/selector";
import { backgroundColors } from "styles/theme";

const MOLSettings = () => {
  const dispatch = useDispatch();
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const { component } = useSelector(selectExtendedScreen);
  const { memberCodes } = React.useContext(UserProfileContext);
  const showExtendedScreen = !!component;

  useEffect(() => {
    dispatch(fetchAllCollections({ memberCodes }));
    return () => {
      dispatch(setPageReset());
    };
  }, []);

  return (
    <>
      <SplitScreen
        ScreenOne={
          <View fsClass="fs-unmask">
            <Header
              title={Localise(messages, "Mercury Online Global Settings")}
            />
            <View
              style={{
                minHeight: 150,
                width: isDesktop && !showExtendedScreen ? "60%" : "100%",
              }}
            >
              <MainScreen />
            </View>
          </View>
        }
        ScreenTwo={
          showExtendedScreen ? (
            <View
              fsClass="fs-unmask"
              style={{ backgroundColor: backgroundColors.sideCar }}
            >
              <ExtendedScreen />
            </View>
          ) : (
            <EmptyScreen />
          )
        }
        showSecondScreen={showExtendedScreen}
      />
    </>
  );
};

export default MOLSettings;
