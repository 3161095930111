import { createSelector } from "reselect";
import { ROOT_SLICE_NAME } from "./root-actions";

/**
 * Gets the state slice combined entity states
 * @param {object} state the store state
 * @returns {object} returns the entity state
 */
export const getStateSlice = (state = {}) => state[ROOT_SLICE_NAME] || {};

/**
 * A memoized selector function for the combined entity states
 */
export const selectState = createSelector(
  getStateSlice,
  (state) => state || {}
);

export default selectState;
