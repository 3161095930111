import React from "react";
import { Text, View } from "react-native";
import { Button } from "react-native-elements";
import moment from "moment";

import SuspensionItem from "./item";
import { Accordion } from "components/elements";
import { FieldArray } from "formik";

import I18NContext from "library/contexts/i18N";
import { fonts, theme, colors, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";

export const ActiveSuspensions = ({ data }) =>
  data.map((item, index) => (
    <SuspensionItem key={index} item={item} index={index} />
  ));

export const CreateNewSuspension = ({ formikArrayHelper }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const getNewSuspensionObj = () => {
    const date = new Date();

    return {
      type: "new",
      startDate: moment(date).format("YYYY-MM-DD"),
      startTime: moment(date)
        .add(2, "minutes")
        .second(0)
        .format("YYYY-MM-DDTHH:mm:ss"),

      endDate: moment(date).format("YYYY-MM-DD"),
      endTime: moment(date)
        .add(32, "minutes")
        .second(0)
        .format("YYYY-MM-DDTHH:mm:ss"),
    };
  };

  return (
    <View style={tw("items-start")}>
      <Button
        testID={"create_suspension"}
        accessibilityLabel="create_suspension"
        titleStyle={{
          ...theme.Button.secondaryTitleStyle,
          ...fonts.heading5,
        }}
        buttonStyle={{
          ...theme.Button.secondaryButtonStyle,
          paddingTop: 8,
          paddingBottom: 6,
          height: 30,
        }}
        containerStyle={{ marginLeft: 0 }}
        title={Localise(messages, "Create Suspension")}
        onPress={() => formikArrayHelper.push(getNewSuspensionObj())}
      />
    </View>
  );
};

export const NoSuspensions = () => {
  const { Localise, messages } = React.useContext(I18NContext);
  return (
    <View style={tw("items-start py-2")}>
      <Text>{Localise(messages, "No suspensions scheduled")}</Text>
    </View>
  );
};

export const PastSuspensions = ({ data, limit, onViewMore }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  return (
    <Accordion
      title={Localise(messages, "Past Suspensions")}
      titleStyle={{
        ...fonts.heading5,
        fontWeight: "600",
        color: colors.primary,
      }}
      separatorAlways={true}
      labelStyle={{
        marginTop: 10,
        backgroundColor: backgroundColors.navBar,
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
        paddingHorizontal: 13,
      }}
      iconStyle={{ color: colors.primary }}
      defaultOpen={false}
      handleOnPress={() => {}}
    >
      <FieldArray
        name="pastSuspensions"
        render={() => {
          return data
            .slice(0, limit)
            .map((item, index) => (
              <SuspensionItem key={index} index={index} item={item} />
            ));
        }}
      />
      {data.length > limit ? (
        <View style={tw(`flex flex-row justify-end items-center py-4`)}>
          <FooterButton
            title={Localise(messages, "View More")}
            action={onViewMore}
          />
        </View>
      ) : null}
    </Accordion>
  );
};

const FooterButton = ({ title, action }) => {
  return (
    <Button
      titleStyle={theme.Button.secondaryTitleStyle}
      buttonStyle={{
        ...theme.Button.secondaryButtonStyle,
        paddingVertical: 5,
        paddingHorizontal: 5,
      }}
      containerStyle={{
        marginVertical: 0,
        marginHorizontal: 0,
        justifyContent: "center",
      }}
      onPress={action}
      title={title}
      testID={title}
      accessibilityLabel={title}
    />
  );
};

export default {
  CreateNewSuspension,
  ActiveSuspensions,
  PastSuspensions,
  NoSuspensions,
};
