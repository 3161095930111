import { select, put, call } from "redux-saga/effects";
import {
  setPageData,
  setApiError,
  fetchGiftCardActivityDetails,
} from "../slice";
import { selectCurrentGiftCardId } from "../selector";
import { request } from "library/utils/request";
import { formatPrice } from "library/utils/formatter";

import get from "lodash/get";
import moment from "moment";

export function* handleFetchGiftCardDetails({ payload }) {
  const { storeOrigin, resolve, reject } = payload;
  const serviceRequest = (giftCardId) =>
    request("get-gift-card-details", { giftCardId, storeOrigin });

  try {
    const giftCardId = yield select(selectCurrentGiftCardId);
    const response = yield call(serviceRequest, giftCardId);
    const giftCardDetails = processGiftCardDetailsResponse(response);

    yield put(
      setPageData({
        giftCardDetails,
      })
    );

    yield put(fetchGiftCardActivityDetails());

    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        section: "giftCardDetails",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleResendGiftCard(action = {}) {
  const updateServiceRequest = (payload) =>
    request("update-recipient-email", payload);

  const { resolve, reject, params } = get(action, "payload", {});

  try {
    const res = yield call(updateServiceRequest, params);
    resolve && resolve(res);
  } catch (error) {
    let message = `Failed to send gift card.`;
    yield put(
      setApiError({
        section: "customerDetails",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject(message);
  }
}

export function* handleFetchGiftCardActivityDetails({ payload = {} }) {
  const { resolve, reject } = payload;
  const serviceRequest = (giftCardId, userTimeZone) =>
    request("get-giftCard-activities", {
      giftCardId,
      userTimeZone,
    });
  const userTimeZone = encodeURIComponent(moment.tz.guess());

  try {
    const giftCardId = yield select(selectCurrentGiftCardId);
    const response = yield call(serviceRequest, giftCardId, userTimeZone);

    yield put(
      setPageData({
        activityInfo: {
          ...response,
          activities: response.activities,
        },
      })
    );
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        section: "giftCardDetails",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

const processGiftCardDetailsResponse = (response = {}) => {
  const firstName = get(response, "recipientInfo.firstName", "");
  const lastName = get(response, "recipientInfo.lastName", "");
  const giftCodeId = get(response, "lastChars", "");
  const formattedGcNumber = `**${giftCodeId}`;

  return {
    giftCardNumber: formattedGcNumber,
    giftCardType: get(response, "type", ""),
    openingBalance: formatPrice(get(response, "issueAmount", "")),
    currentBalance: formatPrice(get(response, "remainingAmount", "")),
    issueDate: moment(
      get(response, "issueDate", ""),
      "YYYY-MM-DDTHH:mm:ss"
    ).format("MM/DD/YYYY"),
    expirationDate: moment(
      get(response, "expirationDate", ""),
      "YYYY-MM-DDTHH:mm:ss"
    ).format("MM/DD/YYYY"),
    status: get(response, "status", ""),
    createdBy: get(response, "createdBy", ""),
    multiUse: get(response, "multiUse", ""),
    id: get(response, "id", ""),
    recipientInfo: {
      giftCodeId: formattedGcNumber,
      firstName: firstName,
      lastName: lastName,
      email: get(response, "recipientInfo.email", ""),
    },
    recipientEmail: get(response, "recipientInfo.email", ""),
    recipientName: `${firstName} ${lastName}`,
  };
};
