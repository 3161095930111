import React, { useEffect, useContext } from "react";
import {
  View,
  Text,
  Image,
  Platform,
  ScrollView,
  StyleSheet,
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import UserProfileStorage from "library/storage/userProfile";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import { Entitlements } from "library/utils/entitlements";
import { setShopCodeSelected } from "library/sagas/views/home/drawer/delivery/slice";
import { selectDashboardShopCode } from "library/sagas/ongoing/global-data/selector";
import I18NContext from "library/contexts/i18N";
import tw from "tailwind-rn";
import moment from "moment";
import { Form } from "components/elements/forms";

import { getShopDetails } from "../delivery-service-dashboard/upsert-screen/helper";
import { IMAGES } from "static/assets/images";

//components
import DeliveryDashboardHeader from "./delivery-dashboard-header";
import DeliveryDashboardComponent from "./delivery-dashboard-listing";

const DeliveryDashboardTab = ({ params = {}, loading, setLoading }) => {
  const dispatch = useDispatch();
  const { messages, Localise } = useContext(I18NContext);
  const { isDesktop } = useContext(DeviceContext);
  const { permissions } = useContext(AppSettingsContext);

  const today = moment();
  const { dashboardStartDate = today, dashboardEndDate = today } = params;
  const dashboardSelectedShopCode = useSelector(selectDashboardShopCode);
  const shopNames = UserProfileStorage.getAllShopNames();

  //filter shops based on permissions
  const filteredShopCodes = [];
  const allShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    allShopCodes.push(memberCode);
    if (Entitlements.DELIVERY_DASHBOARD in permissions[memberCode])
      filteredShopCodes.push(memberCode);
  });

  const isShopEligible = (memberCode) => {
    return (
      filteredShopCodes.includes(memberCode) ||
      memberCode?.toLowerCase().includes("all")
    );
  };

  const sendingOriginData = filteredShopCodes?.map((each) => {
    const shopName = shopNames[each] || "";
    return { code: each, shopName: shopName };
  });
  filteredShopCodes.length > 1 &&
    sendingOriginData?.unshift({ code: "All Shops", shopName: "" });

  const shopCodeInStorage = UserProfileStorage.getSelectedShopCode();

  const selectedShopCode =
    filteredShopCodes.length === 1
      ? filteredShopCodes[0]
      : shopCodeInStorage.length &&
        shopCodeInStorage !== "all" &&
        isShopEligible(shopCodeInStorage)
      ? shopCodeInStorage
      : dashboardSelectedShopCode !== "all" &&
        isShopEligible(dashboardSelectedShopCode)
      ? dashboardSelectedShopCode
      : "All Shops";

  //boolean
  const prefillShopCode =
    ((shopCodeInStorage.length
      ? isShopEligible(shopCodeInStorage)
      : isShopEligible(dashboardSelectedShopCode)) ||
      filteredShopCodes.length === 1) &&
    selectedShopCode !== "all";

  useEffect(() => {
    if (prefillShopCode && !selectedShopCode?.toLowerCase().includes("all")) {
      UserProfileStorage.setSelectedShopCode(selectedShopCode);
      dispatch(setShopCodeSelected(selectedShopCode));
    }
  }, []);

  const getInitialValues = (details) => {
    const initalValues = {
      startDate: moment(dashboardStartDate).format("YYYY-MM-DD"),
      endDate: moment(dashboardEndDate).format("YYYY-MM-DD"),
      shopField: "All Shops",
      shopCode: "",
      shopName: undefined,
      isSingleShop: allShopCodes.length === 1,
    };
    return {
      ...initalValues,
      ...details,
    };
  };

  const initialValues = getInitialValues(
    prefillShopCode
      ? selectedShopCode === "All Shops"
        ? { shopCode: "All Shops" }
        : getShopDetails(selectedShopCode)
      : { shopCode: "All Shops" }
  );

  const styles = StyleSheet.create({
    deliveryDashboard: {
      marginHorizontal: isDesktop ? 35 : 10,
    },
  });

  return (
    <>
      <View fsClass="fs-unmask" style={[styles.deliveryDashboard]}>
        <ScrollView>
          <View fsClass="fs-unmask" style={[tw("pt-3", "pb-3")]}>
            <>
              <Form
                initialValues={initialValues}
                render={(props) => {
                  const { values } = props;
                  const { shopCode } = values;

                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  useEffect(() => {
                    dispatch(
                      setShopCodeSelected(
                        shopCode === "All Shops" ? undefined : shopCode
                      )
                    );
                  }, [shopCode]);

                  return (
                    <View>
                      <DeliveryDashboardHeader
                        sendingOriginData={sendingOriginData}
                        allShopCodes={allShopCodes}
                        setLoading={setLoading}
                        params={params}
                      />

                      {(filteredShopCodes.length >= 1 && shopCode) ||
                      allShopCodes.length === 1 ? (
                        <View
                          style={{
                            minHeight: 500,
                            zIndex: -1,
                            marginBottom: 10,
                          }}
                        >
                          <DeliveryDashboardComponent
                            loading={loading}
                            filteredShopCodes={filteredShopCodes}
                          />
                        </View>
                      ) : (
                        <View
                          style={{
                            flex: 1,
                            marginTop: Platform.OS === "web" ? 0 : 20,
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: Platform.OS === "web" ? 300 : 200,
                            zIndex: -1,
                          }}
                        >
                          <Image
                            style={{
                              width: 100,
                              height: 90,
                            }}
                            resizeMode="cover"
                            source={IMAGES["select-shop"]}
                          />
                          <Text
                            style={{
                              width: 180,
                              textAlign: "center",
                              fontSize: 20,
                              fontWeight: "400",
                              lineHeight: 23,
                              marginTop: 40,
                            }}
                          >
                            {Localise(
                              messages,
                              "Select a shop above to get started"
                            )}
                          </Text>
                        </View>
                      )}
                    </View>
                  );
                }}
              />
            </>
          </View>
        </ScrollView>
      </View>
    </>
  );
};

export default DeliveryDashboardTab;
