import omit from "lodash/omit";
import lowerCase from "lodash/lowerCase";
import isArray from "lodash/isArray";
import capitalize from "lodash/capitalize";

export const formatData = (data, listDisplayValues, delimiter) => {
  if (!data || !Object.keys(data).length) return "";
  return listDisplayValues
    .map((e) => {
      let val = Array.isArray(e) ? data[e[0]] : data[e];
      if (e === "price") val = `$${val}`;
      else if (e === "distance") val = `${val}`;
      return val;
    })
    .join((delimiter && delimiter === "space" && "    ") || ", ");
};

const formatFloristsInfo = (florist) => {
  const {
    address,
    businessName,
    displayAd = {},
    email,
    floristName,
    floristNotes,
    hours,
    isAvailable,
    latitude = "",
    longitude = "",
    memberId: memberCode,
    noteFromFlorist,
    orderMinimum: floristMinimum,
    partnerPreference = "NORMAL",
    phoneNumber,
    rating = "-",
    subscriptions,
    unavailabilityReason = "",
    floristContactPreference = "Name,Phone,Email",
  } = florist;

  const displayInfo = floristContactPreference?.split(",") || [];
  const { imageData = "" } = displayAd;
  const isMasterFlorist = isArray(subscriptions)
    ? subscriptions.includes("MASTER_FLORIST")
    : subscriptions === "MASTER_FLORIST";
  const isPremierFlorist = isArray(subscriptions)
    ? subscriptions.includes("PREMIER_FLORIST")
    : subscriptions === "PREMIER_FLORIST";
  const isDigitalAdAvailable = imageData !== "";

  const { streetAddress, streetAddress2 = "", cityName, state, zip } = address;

  const fieldSetLabel =
    lowerCase(partnerPreference) === "first choice"
      ? "First Choice"
      : lowerCase(partnerPreference) === "preferred"
      ? "Preferred"
      : lowerCase(partnerPreference) === "do not use"
      ? "Do Not Use"
      : "Normal";

  let memberDisplayData = {
    "Partner Preference": fieldSetLabel,
  };

  if (displayInfo.includes("Name")) {
    memberDisplayData["Contact Name"] = floristName || "";
  }
  if (displayInfo.includes("Phone")) {
    memberDisplayData["Contact Phone"] = phoneNumber || "";
  }
  if (displayInfo.includes("Email")) {
    memberDisplayData["Contact Email"] = email || "";
  }

  memberDisplayData = {
    ...memberDisplayData,
    Address: {
      line1: `${streetAddress}, ${streetAddress2}`,
      line2: `${cityName}, ${state}, ${zip}`,
    },
    "Business Name": businessName,
    Hours: hours,
    "Current Availability": isAvailable ? "Yes" : "No",
    "Note from Florist": noteFromFlorist,
  };

  memberDisplayData = omit(memberDisplayData, [
    "Current Availability",
    "Partner Preference",
  ]);

  const basicInfo = {
    name: businessName,
    memberCode,
    rating,
    minPrice: `$${parseFloat(floristMinimum || 0).toFixed(2)}`,
    imageName: "florist-shop",
    statusColor: "#3A7D8D",
    isMasterFlorist,
    isPremierFlorist,
    isDigitalAdAvailable,
    partnerPreference,
  };

  const memberInfoData = {
    fieldSetLabel,
    floristNotes,
    isAvailable,
    imageData,
    isMasterFlorist,
    isPremierFlorist,
    isDigitalAdAvailable,
    latitude,
    longitude,
    partnerPreference,
    subscriptions,
    unavailabilityReason: capitalize(
      unavailabilityReason?.split(/_/).join(" ")
    ),
  };

  return { basicInfo, memberDisplayData, memberInfoData };
};

export const getMembersInfo = (members = {}) => {
  const {
    firstChoiceCount = 0,
    preferredCount = 0,
    doNotUseCount = 0,
    firstChoiceFlorists = [],
    preferredFlorists = [],
    doNotUseFlorists = [],
  } = members;

  if (firstChoiceCount === 0 && preferredCount === 0 && doNotUseCount === 0)
    return {
      membersInfo: {
        firstChoiceFlorists: [],
        preferredFlorists: [],
        doNotUseFlorists: [],
      },
    };
  const firstChoice = firstChoiceFlorists.map((florist) => {
    return formatFloristsInfo(florist);
  });

  const preferredChoice = preferredFlorists.map((florist) => {
    return formatFloristsInfo(florist);
  });

  const doNotUseChoice = doNotUseFlorists.map((florist) => {
    return formatFloristsInfo(florist);
  });

  return {
    membersInfo: {
      firstChoiceFlorists: firstChoice,
      preferredFlorists: preferredChoice,
      doNotUseFlorists: doNotUseChoice,
    },
  };
};

const usZipPattern = "^[0-9]{5}$";
const canadaZipPattern =
  "^[A-Za-z](\\d([A-Za-z][ -]?(\\d([A-Za-z](\\d)?)?)?)?)?$";
const memberCodePattern = "^[0-9]{2}[-][0-9]";
const notNumbersPattern = "^([^0-9]*)$";

export const isValidZipPattern = (zipCode) => {
  const isValidPatternUS = RegExp(`${usZipPattern}`).test(zipCode);
  const isValidPatternCA = RegExp(`${canadaZipPattern}`).test(zipCode);
  return isValidPatternUS || isValidPatternCA;
};

export const isValidMemberCodePattern = (memberCode) => {
  return RegExp(`${memberCodePattern}`).test(memberCode);
};

export const isValidCityPattern = (city) => {
  return RegExp(`${notNumbersPattern}`).test(city);
};
