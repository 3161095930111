import { put, call, select, delay } from "redux-saga/effects";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import set from "lodash/set";
import Environment from "library/utils/environment";

import {
  setApiResponse,
  setApiError,
  setPageData,
  setOrderInfo,
  fetchAllEvents,
  setPageStatus,
} from "../slice";
import {
  selectApiResponse,
  selectPageActions,
  selectSelectedFuneralLogs,
} from "../selector";

import { request } from "library/utils/request";

import cloneDeep from "lodash/cloneDeep";
import orderBy from "lodash/orderBy";
import toLower from "lodash/toLower";
import moment from "moment";

const today = moment().utc().format("YYYY-MM-DD");
const tomorrow = moment().utc().add(1, "days").format("YYYY-MM-DD");
const week = moment().utc().add(1, "week").format("YYYY-MM-DD");
const month = moment().utc().add(1, "month").format("YYYY-MM-DD");
const section = "events";

export function* handleFetchAllEvents(action = {}) {
  const { eventType = "" } = get(action, "payload", {});
  yield put(setPageStatus("progress"));
  const serviceRequest = (offset, limit, searchText) =>
    // changing for local api dev, will stash changes once gift-listing call issues are resolved
    request(searchText ? "get-search-events" : "get-events", {
      offset,
      limit: Environment.get("EVENTS_LIMIT", 200),
      searchText,
      includeTotalCount: true,
      eventType,
      start:
        !searchText && eventType === "FUNERAL_LOG"
          ? moment().subtract(1, "month")?.format("YYYY-MM-DD")
          : "",
    });
  try {
    const { search = "" } = yield select(selectPageActions);
    const resp = yield call(serviceRequest, 0, 50, search);
    const processedResp = resp?.data?.map((event) => {
      //const count = event.locations.length;
      let startAndEndDate = event?.locations[0]?.deliveryDate;
      // if (count > 1) {
      //   startAndEndDate = `${startAndEndDate} - ${
      //     event?.locations[count - 1]?.deliveryDate
      //   }`;
      // }
      const { status, changeRequestNotes, fullLegalName } = event?.proposal;
      const proposalInfo = ["APPROVED", "DECLINED"].includes(status)
        ? `${fullLegalName ? "Customer" : "Florist"} ${status?.toLowerCase()}`
        : changeRequestNotes;
      return {
        ...event,
        startAndEndDate,
        startTime: event?.locations[0]?.locationTimes[0]?.startTime,
        proposalInfo,
      };
    });
    yield put(
      setApiResponse({
        section,
        events: processedResp,
      })
    );
    yield put(setPageData({ totalRecords: resp?.count }));
    yield put(setPageStatus("done"));
  } catch (error) {
    yield put(
      setApiError({
        section,
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleFetchAllEventsBySearch(action = {}) {
  const { eventType = "" } = get(action, "payload", {});
  try {
    const { search = "" } = yield select(selectPageActions);
    if (search.length > 2 || search === "") {
      yield delay(1000);
      yield put(fetchAllEvents({ eventType }));
    }
  } catch (error) {
    yield put(
      setApiError({
        section: "events",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleFetchAllFuneralEventsBySearch() {
  try {
    const { search = "" } = yield select(selectPageActions);
    if (search.length > 2 || search === "") {
      yield delay(1000);
      yield put(fetchAllEvents({ eventType: "FUNERAL_LOG" }));
    }
  } catch (error) {
    yield put(
      setApiError({
        section: "events",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleUIRefresh() {
  const selectedFuneralLogs = yield select(selectSelectedFuneralLogs);
  const origData = yield select(selectApiResponse);
  const actions = yield select(selectPageActions);
  const newData = applyPageActions(origData, actions);
  const dateRange = Environment.get("NEEDS_ACTION_DATE_RANGE", 14);

  let needsAction = [];
  let active = [];
  let drafts = [];
  let completed = [];
  let cancelled = [];
  let inactive = [];
  let funeralLog = [];
  let funeralLogActual = [];

  newData.events.forEach((event) => {
    let startAndEndDate = event?.locations[0]?.deliveryDate;
    const updated = {
      ...event,
      startAndEndDate,
      startTime: event?.locations[0]?.locationTimes[0]?.startTime,
    };
    event.status === "";
    if (event.type === "FUNERAL_LOG") {
      const matchedIndex = funeralLog.findIndex((x) => x?.name === event?.name);
      if (matchedIndex < 0) {
        funeralLog.push({
          ...updated,
          customer: {
            details: {
              orderIds: {
                [event.memberCode]: event?.customer?.details?.orderIds,
              },
            },
          },
        });
      } else {
        //Merge all orderIds into single funeral
        let clonedCustomer = cloneDeep(funeralLog[matchedIndex].customer) || {
          details: {},
        };
        const existingOrderIds = get(clonedCustomer, "details.orderIds", []);
        set(clonedCustomer, "details.orderIds", {
          ...existingOrderIds,
          [event.memberCode]: event?.customer?.details?.orderIds,
        });
        funeralLog.splice(matchedIndex, 1, {
          ...funeralLog[matchedIndex],
          customer: {
            ...clonedCustomer,
          },
        });
      }
      funeralLogActual.push(updated);
    } else if (
      !["COMPLETED", "CANCELLED"].includes(event.status) &&
      moment(moment().format("YYYY-MM-DD")).isAfter(
        moment(event.locations[0].deliveryDate)
      )
    ) {
      inactive.push(updated);
    } else if (
      (["PENDING"].includes(event?.status) &&
        ["CHANGE_REQUEST", "APPROVED", "DECLINED"].includes(
          event?.proposal?.status
        )) ||
      (["NEW", "PENDING"].includes(event.status) &&
        moment()
          .add(dateRange, "days")
          .isAfter(event.locations[0].deliveryDate))
    ) {
      needsAction.push(updated);
    } else if (event.status === "NEW") {
      drafts.push(updated);
    } else if (event.status === "COMPLETED") {
      completed.push(updated);
    } else if (event.status === "CANCELLED") {
      cancelled.push(updated);
    } else {
      active.push(updated);
    }
  });

  yield put(
    setPageData({
      needsAction,
      active,
      drafts,
      completed,
      cancelled,
      inactive,
      funeralLog,
      funeralLogActual,
      selectedFuneralLogs,
    })
  );
}

export function* handleFetchEventOrder(action = {}) {
  const { orderId, deliveryMethod, memberCode } = get(action, "payload", {});
  const serviceRequest = (orderId, deliveryMethod, memberCode) =>
    request("order-details", {
      recordId: orderId,
      deliveryMethod,
      sourceMemberCode: memberCode,
    });
  try {
    const resp = yield call(
      serviceRequest,
      orderId,
      deliveryMethod,
      memberCode
    );
    yield put(setOrderInfo(resp));
  } catch (error) {
    yield put(
      setApiError({
        section,
        error: "Something went wrong, please try again",
      })
    );
  }
}

const applyPageActions = (data, actions) => {
  const isFuneralList = data?.events?.some(
    (each) => each.type === "FUNERAL_LOG"
  );
  const {
    shops = [],
    sort: {
      value: sortBy = isFuneralList ? "deliveryDate::desc" : "name::asc",
    },
    filters = [],
    search = "",
  } = actions;

  const [sortField, sortOrder] = sortBy.split(/::/);

  const applyControls = (data = []) => {
    const filterByShop = data.filter((entry) => {
      let matchesFilter = true;

      // Filtering the events based on the search query
      if (!isEmpty(search) && matchesFilter) {
        matchesFilter =
          toLower(entry.name).includes(toLower(search)) ||
          toLower(entry.eventId).includes(toLower(search)) ||
          (entry.type === "FUNERAL_LOG"
            ? toLower(entry.locations[0].name)?.includes(toLower(search))
            : false)
            ? true
            : false;
      }

      if (shops.length && matchesFilter) {
        if (shops.filter((item) => item.value === entry.memberCode).length)
          matchesFilter = true;
        else matchesFilter = false;
      }
      return matchesFilter;
    });

    const resp = filterByShop.filter((entry) => {
      let matchesFilter = true;

      if (filters.length) {
        const filterValues = filters.map((e) => e.value);

        if (
          filterValues.includes("date_0") ||
          filterValues.includes("date_1") ||
          filterValues.includes("date_7") ||
          filterValues.includes("this-month")
        ) {
          //const startDate = entry.created; //locations[0].deliveryDate;
          const startDate = entry.locations[0].deliveryDate;
          if (
            filterValues.includes("date_0") &&
            today === moment(startDate).format("YYYY-MM-DD")
          )
            matchesFilter = true;
          else if (
            filterValues.includes("date_1") &&
            tomorrow === moment(startDate).format("YYYY-MM-DD")
          )
            matchesFilter = true;
          else if (
            filterValues.includes("date_7") &&
            moment(startDate).isBefore(week) &&
            moment(startDate).isAfter(today)
          )
            matchesFilter = true;
          else if (
            filterValues.includes("this-month") &&
            moment(startDate).isBefore(month) &&
            moment(startDate).isAfter(today)
          )
            matchesFilter = true;
          else matchesFilter = false;
        }

        if (
          (filterValues.includes("NEW") ||
            filterValues.includes("DRAFT") ||
            filterValues.includes("APPROVED") ||
            filterValues.includes("PENDING") ||
            filterValues.includes("CANCELLED") ||
            filterValues.includes("REJECTED") ||
            filterValues.includes("COMPLETED")) &&
          matchesFilter
        ) {
          if (filterValues.includes("NEW") && entry.status === "NEW")
            matchesFilter = true;
          else if (
            filterValues.includes("DRAFT") &&
            ["NEW", "DRAFT"].includes(entry.status)
          )
            matchesFilter = true;
          else if (
            filterValues.includes("PENDING") &&
            entry.status === "PENDING"
          )
            matchesFilter = true;
          else if (
            filterValues.includes("APPROVED") &&
            entry.status === "APPROVED"
          )
            matchesFilter = true;
          else if (
            filterValues.includes("REJECTED") &&
            entry.status === "REJECTED"
          )
            matchesFilter = true;
          else if (
            filterValues.includes("CANCELLED") &&
            entry.status === "CANCELLED"
          )
            matchesFilter = true;
          else if (
            filterValues.includes("COMPLETED") &&
            entry.status === "COMPLETED"
          )
            matchesFilter = true;
          else matchesFilter = false;
        }

        if (
          matchesFilter &&
          (filterValues.includes("WEDDING") ||
            filterValues.includes("FUNERAL") ||
            filterValues.includes("PARTY"))
        ) {
          if (filterValues.includes("WEDDING") && entry.type === "WEDDING")
            matchesFilter = true;
          else if (filterValues.includes("FUNERAL") && entry.type === "FUNERAL")
            matchesFilter = true;
          else if (filterValues.includes("PARTY") && entry.type === "PARTY")
            matchesFilter = true;
          else matchesFilter = false;
        }
      }
      return matchesFilter;
    });
    //console.log("res -", resp);
    return resp;
  };

  let result = cloneDeep(data);

  // toLower(entry.lastChars).includes(toLower(search)) ||
  // toLower(entry.recipientEmail).includes(toLower(search)) ||
  // toLower(entry.recipientName).includes(toLower(search))

  result = Object.keys(result).reduce((accum, section) => {
    accum[section] = orderBy(
      applyControls(result[section]),
      (e) => {
        return sortField === "deliveryDate"
          ? e["locations"][0][sortField]
          : toLower(e[sortField]);
      },
      sortOrder
    );
    return accum;
  }, {});

  return result;
};
