import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "react-native";
import { Divider, Text } from "react-native-elements";
import { Picker } from "components/elements";

import I18NContext from "library/contexts/i18N";
import UserProfileContext from "library/contexts/userProfile";
import UserProfileStorage from "library/storage/userProfile";

import { fonts } from "styles/theme";
import tw from "tailwind-rn";

const ShopCodePicker = ({
  shopCodeSelector,
  setShopCodeAction,
  shopCodeOptions,
  containerStyle = {},
}) => {
  const dispatch = useDispatch();
  const currentShopCode = useSelector(shopCodeSelector);
  const { memberCodes = [] } = React.useContext(UserProfileContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const shopNames = UserProfileStorage.getAllShopNames();
  const displayOptions = shopCodeOptions ? shopCodeOptions : memberCodes;
  const pickerOptions = ["all", ...displayOptions];

  useEffect(() => {
    if (!currentShopCode) dispatch(setShopCodeAction(pickerOptions[0]));
  }, []);

  return (
    <>
      <View style={tw(`flex ${"flex-row items-center"}`)}>
        {displayOptions.length > 1 ? (
          <>
            <Text
              style={{
                ...fonts.heading4,
                height: 20,
                width: 100,
              }}
            >
              {Localise(messages, "Select Shop:")}
            </Text>
            <Picker
              containerStyle={{
                paddingRight: 0,
                paddingBottom: 0,
                ...containerStyle,
              }}
              items={pickerOptions.map((code) => ({
                label:
                  code === "all"
                    ? Localise(messages, "All Shops")
                    : `${code} ${shopNames[code]}`,
                value: code,
              }))}
              placeholder={{}}
              value={currentShopCode}
              onValueChange={(shop) => dispatch(setShopCodeAction(shop))}
            ></Picker>

            <Divider style={tw("mb-3")} />
          </>
        ) : (
          <Text
            style={{
              ...fonts.heading4,
              height: 30,
              width: 200,
              marginTop: 10,
            }}
          >
            {`${Localise(messages, "Shop")}: `}
            {currentShopCode === "all" ? displayOptions[0] : currentShopCode}
          </Text>
        )}
      </View>
    </>
  );
};

export default ShopCodePicker;
