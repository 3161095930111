import React from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";
import moment from "moment";

import { generateDetailContent } from "../helper";
import { selectRecordData } from "library/sagas/ongoing/current-orders/selector";

const FloristPickupInfo = ({ index, doNotFill }) => {
  const orderDetailResponse = useSelector(selectRecordData);

  const orderDetails = orderDetailResponse.orderItems[index];

  const {
    deliveryInfo: { deliveryDate, deliveryInstructions = "" },
    orderSource = "",
    recipientInfo: { firstName = "", lastName = "" },
    pickupInfo,
    hasPendingPayLaterPayment = false,
  } = orderDetails;

  const { storePickupDateTime = "" } = pickupInfo || {};
  let molDelInstructions = deliveryInstructions;

  if (orderSource === "MOL" && !!deliveryInstructions) {
    const [molPickupValue, ...otherDelInstructions] =
      deliveryInstructions.split(",");

    if (!!molPickupValue && !molPickupValue.includes("\\n")) {
      molDelInstructions = otherDelInstructions.join(",");
    } else {
      const [molPickupValue, ...otherDelInstructions] =
        deliveryInstructions.split("\\n");
      if (
        !!molPickupValue &&
        moment(molPickupValue).format("h:mm A") !== "Invalid date"
      ) {
        molDelInstructions = otherDelInstructions;
      }
    }
  }

  const pickupData = {
    PickupDate: {
      label: "Pickup Date",
      value: moment(storePickupDateTime || deliveryDate).format("MM/DD/YY"),
    },
    DeliveryType: {
      label: "Delivery Type",
      value: hasPendingPayLaterPayment ? "Pickup - Unpaid" : "Pickup",
    },
    PickupTime: {
      label: "Pickup Time",
      value: moment(storePickupDateTime || deliveryDate).format("h:mm A"),
    },
    PickupBy: {
      label: "Pickup By",
      value: `${firstName}${firstName !== "" ? " " : ""}${lastName}`,
    },
    DeliveryInstructions: {
      label: "Delivery Instructions",
      value: molDelInstructions,
    },
  };

  return (
    <View
      style={
        {
          // opacity: doNotFill ? 0.4 : 1,
        }
      }
      pointerEvents={doNotFill ? "none" : "auto"}
    >
      <View>{generateDetailContent(pickupData, true)}</View>
    </View>
  );
};

export default FloristPickupInfo;
