import { request } from "library/utils/request";

const debug = (message, ...args) => {
  log("debug", message, args);
};

const info = (message, ...args) => {
  log("info", message, args);
};

const notice = (message, ...args) => {
  log("notice", message, args);
};

const warn = (message, ...args) => {
  log("warning", message, args);
};

const error = (message, ...args) => {
  log("error", message, args);
};

const critical = (message, ...args) => {
  log("critical", message, args);
};

const alert = (message, ...args) => {
  log("alert", message, args);
};

const emergency = (message, ...args) => {
  log("emergency", message, args);
};

const log = (level = "info", ...message) => {
  // eslint-disable-next-line no-undef
  if (__DEV__) return;

  const MessagePrefix = "mercury-os-app-service";
  const loggerName = "mercury-os-app-service-interceptor";
  const logMessage = typeof message === "string" ? message : message.join(" ");

  const logArguments = {
    timestamp: new Date().toISOString(),
    message: `${MessagePrefix} - ${logMessage}`,
    severity: level.toUpperCase(),
    logger_name: loggerName,
  };

  request("log", { ...logArguments }).catch((error) => {
    console.log("Failed to send log to stackdriver", error);
  });
};

export default {
  debug,
  info,
  notice,
  warn,
  error,
  critical,
  alert,
  emergency,
};
