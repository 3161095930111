import { getLocationsWithConvertedTime } from "./config";
import { request } from "library/utils/request";
import moment from "moment";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";

export const handleSave = (values) => {
  const {
    startAndEndDate,
    actualCustomerInfo,
    customerInfo,
    hasCustomerInfo,
    currentTabIndex,
    _id,
    __v,
    createdOn,
    updatedOn,
    startTime,
    recipient,
    cc,
    subject,
    email,
    proposalInfo,
    paymentDetails,
    showPayment,
    isPaymentRequired,
    amountPaid,
    refunded,
    unpaidBalance,
    completedOrders,
    orderIds,
    actionItem,
    addressVerificationInfo,
    ...other
  } = values;
  const updatedLocations = getLocationsWithConvertedTime(
    other.locations,
    addressVerificationInfo,
    true
  );
  return request(values.eventId ? "update-event" : "save-event", {
    reqObj: {
      ...omit(other, ["showError"]),
      locations: updatedLocations,
      status: isPaymentRequired ? "APPROVED" : "NEW",
      proposal: isPaymentRequired
        ? other.proposal.status === "DECLINED"
          ? {
              ...other.proposal,
              fullLegalName: "",
              status: "APPROVED",
              date: moment().format("MM/DD/YYYY"),
            }
          : {
              ...other.proposal,
              status: "APPROVED",
              date: other.proposal.date || moment().format("MM/DD/YYYY"),
            }
        : { status: "DRAFT" },
      customer: {
        details: values.customerInfo,
      },
    },
  });
};

export const getIsEventModified = (initialValues, values) => {
  const {
    paymentDetails: initialPaymentDetails,
    showPayment: initialShowPayment,
    customerInfo: initialCustomerInfo,
    orderIds: initialOrderIds,
    ...otherInitialVals
  } = initialValues;

  const {
    actualCustomerInfo,
    paymentDetails,
    customerInfo,
    showPayment,
    isPaymentRequired,
    orderItems,
    orderAmounts,
    orderIds,
    ...other
  } = values;

  //console.log("initial -", JSON.stringify(otherInitialVals));
  //console.log("after -", JSON.stringify(other));

  return !isEqual(
    otherInitialVals,
    omit(other, ["showError", "merchantReferenceId"])
  );
};
