export const UIConfig = {
  routes: {
    status: [
      "Not Started",
      "In Design",
      "Designed",
      "Out for Delivery",
      "Completed",
    ],
    additional: [["rushOrders"]],
  },
  zones: {
    status: [
      "Not Started",
      "In Design",
      "Designed",
      "Out for Delivery",
      "Completed",
    ],
    additional: [["nonRoutedOrders", "routedOrders"], ["rushOrders"]],
  },
};

export const tabNames = [
  { key: "dashboard-zones-tab", title: "Zones" },
  { key: "dashboard-routes-tab", title: "Routes" },
];

export const initViewMoreCounts = {
  routes: 50,
  zones: 50,
};

export const orderStatusColor = {
  "Not Started": "#aadec6",
  "In Design": "#31aa92",
  Designed: "#037c77",
  "Out for Delivery": "#11838e",
  Completed: "#114258",
};

export const orderStatusIcon = {
  "Not Started": "delivery-not-started",
  "In Design": "design",
  Designed: "designed",
  "Out for Delivery": "delivery",
  Completed: "completed",
  rushOrders: "timed-delivery-progress",
  returnedOrders: "delivery-returned",
  routedOrders: "delivery",
  nonRoutedOrders: "incomplete",
};

export const displayStatus = {
  rushOrders: "Timed",
  returnedOrders: "Returned",
  routedOrders: "Routed",
  nonRoutedOrders: "Non Routed",
};
