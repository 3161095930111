export const PermissionsForPageAccess = {
  FTD_ADMIN: true,
  FTD_POWER_USER: true,
  FTD_VIEW_ONLY: true,
  FTD_CSR: true,
};

export const PermissionsForCreditCardSettings = {
  FTD_ADMIN: true,
  FTD_POWER_USER: true,
  FTD_VIEW_ONLY: false,
  FTD_CSR: false,
};

export const PermissionsToShowCreditCardSettings = {
  FTD_ADMIN: true,
  FTD_POWER_USER: true,
  FTD_VIEW_ONLY: true,
  FTD_CSR: false,
};

export const PermissionsForPaymentTerminalSettings = {
  FTD_ADMIN: true,
  FTD_POWER_USER: true,
  FTD_VIEW_ONLY: false,
};
