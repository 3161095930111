export const RestrictedCatalogPageFilters = (Localise, messages) => [
  {
    label: Localise(messages, "Core"),
    value: "core",
  },
  {
    label: Localise(messages, "Standard"),
    value: "standard",
  },
  {
    label: Localise(messages, "Essentials"),
    value: "essentials",
  },
  {
    label: Localise(messages, "Premium"),
    value: "premium",
  },
  {
    label: Localise(messages, "Enabled"),
    value: "enabled",
  },
  {
    label: Localise(messages, "Not Enabled"),
    value: "notEnabled",
  },
  // {
  //   label: Localise(messages, "Paid Content"),
  //   value: "paidContent",
  // },
];
