import React, { useEffect, useState, useRef } from "react";
import { ScrollView, View, ActivityIndicator, Platform } from "react-native";
import { Text } from "react-native-elements";
const { useMemo } = require("react");
import { request } from "library/utils/request";
import ProposalInfo from "../drawer/create-event/proposal-and-payment/proposal";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import HeaderBar from "components/containers/header";
import { colors, fonts } from "styles/theme";
import tw from "tailwind-rn";
import { useReactToPrint } from "react-to-print";

const Error = ({ Localise, messages, message }) => (
  <View
    style={{
      marginTop: 20,
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    }}
  >
    <Text
      style={{
        textAlign: "center",
        fontSize: 20,
        fontWeight: "400",
        marginTop: 50,
      }}
    >
      {Localise(messages, message)}
    </Text>
  </View>
);

const EventApprovalConfirmation = () => {
  const ref = useRef();
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const [values, setValues] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const handleProposalPrint = useReactToPrint({
    content: () => ref.current,
  });

  useEffect(() => {
    let eventId;
    if (Platform.OS === "web" && window) {
      eventId =
        window.location?.href?.split("event-approval-confirmation/")[1] || "";
    }
    if (!eventId) {
      setLoading(false);
      setError(
        "This page no longer exists. Please check the URL or try a different link."
      );
      return;
    }
    request("get-public-event", {
      eventId,
    })
      .then((response) => {
        setValues({
          ...response,
          customerInfo: response.customer.details,
          eventId,
        });
      })
      .catch((error) => {
        console.log("error: " + error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setValues, Localise, messages]);

  return useMemo(() => {
    const maxScrollViewHeight = `calc(100vh - 180px)`; //Header and Title height

    return (
      <React.Fragment>
        <HeaderBar showWelcomeSection={false} />
        <View style={{ padding: isDesktop ? 30 : 10 }}>
          <View style={[tw("flex flex-row justify-between items-center pb-3")]}>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={fonts.heading1}>
                {Localise(messages, "Proposal Review")}
              </Text>
            </View>
          </View>
          <View
            style={{
              borderBottomColor: colors.light,
              borderBottomWidth: 1,
              marginBottom: 20,
              shadowOffset: { width: 0, height: 2 },
              shadowOpacity: 0.4,
              shadowRadius: 7,
            }}
          />
          {error ? (
            <Error
              Localise={Localise}
              messages={messages}
              message="This page no longer exists. Please check the URL or try a different link."
            />
          ) : (
            <ScrollView
              style={{
                width: isDesktop ? "60%" : "100%",
                maxHeight: maxScrollViewHeight,
              }}
            >
              {values ? (
                <ProposalInfo
                  values={values}
                  isCustomerPage={true}
                  componentRef={ref}
                  handleProposalPrint={handleProposalPrint}
                />
              ) : (
                <Text>
                  {Localise(
                    messages,
                    loading ? (
                      <ActivityIndicator
                        style={{ marginLeft: 10 }}
                        color={colors.activityIndicator}
                        testID={"loader"}
                      />
                    ) : (
                      "Event Not Found!"
                    )
                  )}
                </Text>
              )}
            </ScrollView>
          )}
        </View>
      </React.Fragment>
    );
  }, [
    isDesktop,
    values,
    Localise,
    messages,
    loading,
    error,
    handleProposalPrint,
  ]);
};

export default EventApprovalConfirmation;
