import React from "react";
import { View } from "react-native";

import Filters from "./filters";
import Shops from "./shops";
import CreateNew from "./create-new";
import Search from "components/containers/search";
import Sort from "components/containers/sort";
import {
  SearchResults,
  ShopResults,
  FilterResults,
  SortResults,
} from "./results";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import get from "lodash/get";
import isEqual from "lodash/isEqual";
import tw from "tailwind-rn";

const ControlMap = {
  search: Search,
  shops: Shops,
  filters: Filters,
  sort: Sort,
  "create-new": CreateNew,
};

const Controls = ({ data, position = "header" }) => {
  return data.map(
    (
      {
        name,
        isHidden = false,
        component,
        props: { width, zIndex, ...controlProps },
      },
      index
    ) => {
      if (isHidden) return null;
      const controlPosition = get(controlProps, "controlPosition", "header");
      const Component = component || ControlMap[name];
      return (
        isEqual(controlPosition, position) && (
          <View
            key={index}
            style={[
              width && { width },
              zIndex && { zIndex },
              tw("flex flex-row justify-center"),
            ]}
          >
            <Component {...controlProps} />
          </View>
        )
      );
    }
  );
};

const ControlResultsMap = {
  search: SearchResults,
  shops: ShopResults,
  filters: FilterResults,
  sort: SortResults,
};

export const SelectedControls = ({ data }) => {
  const { isDesktop } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);
  const isSmallScreen = !isDesktop;

  return (
    <View
      style={[
        tw("flex flex-row justify-start items-baseline"),
        { paddingHorizontal: 7 },
        isSmallScreen ? tw("pr-4") : { width: "85%" },
      ]}
    >
      {data.map(({ name, props }, index) => {
        const Component = ControlResultsMap[name];
        const {
          selectors: { selectValue: valueSelector } = {},
          actions: { setAction } = {},
        } = props;

        return Component ? (
          <Component
            key={index}
            input={valueSelector}
            action={setAction}
            Localise={Localise}
            messages={messages}
          />
        ) : null;
      })}
    </View>
  );
};

export default Controls;
