import React, { useEffect, useMemo } from "react";
import { View } from "react-native";
import { Text, Divider } from "react-native-elements";
import { fonts, backgroundColors } from "styles/theme";
import { request } from "library/utils/request";
import moment from "moment";
import { DeliveryTypes } from "../../config";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import { connect } from "react-redux";
import UserProfileStorage from "library/storage/userProfile";
import { getAddressForTax } from "library/utils/createOrder";

const LocationInfo = ({
  index,
  locationInfo,
  Localise,
  messages,
  memberCode,
  setFieldValue,
  isProposalPage,
  selectedproducts,
  taxExemptCode,
  taxOnDeliveryFee = false,
  taxOnRetailDeliveryFee = false,
  eventId = "",
  initialLocationInfo = {},
  customerId,
  initailCustomerId,
}) => {
  const {
    title,
    name,
    address,
    address: { addressLine1, suite, city, state, zip, country } = {},
    products = [],
    locationTimes = [],
    locationNotes,
    deliveryType,
    deliveryInstructions,
    deliveryDate,
    deliveryFee,
  } = locationInfo;

  const floristAddress = UserProfileStorage.getShopLocation(memberCode) || "";
  const selectedShopPreferences =
    UserProfileStorage.getShopPreferences(memberCode);
  const {
    local_order_tax_settings,
    retail_delivery_fee = "0.00",
    tax_delivery_fee = "false",
  } = selectedShopPreferences;

  const addressLine = suite ? `${addressLine1}, ${suite}` : addressLine1;

  const getTax = (deliveryFee) => {
    const currentProductInfo = selectedproducts.find(
      (product) => product.productId === products[0]?.productId
    );
    const {
      productType = "",
      productSubType = "",
      categoryName = "",
    } = currentProductInfo || {};
    const productAmount = products.reduce(
      (a, b) => a + b.quantity * b.amount,
      0
    );

    // Calculating tax for fees, only when that particular fee is set to be taxable in shop settings.
    let applyTaxOnLocalDF =
      selectedShopPreferences?.tax_on_local_delivery_fee || tax_delivery_fee;
    applyTaxOnLocalDF = applyTaxOnLocalDF.toLowerCase() === "true";

    let applyTaxOnOutsideLocalDF =
      selectedShopPreferences?.tax_on_outside_local_delivery_fee ||
      tax_delivery_fee;
    applyTaxOnOutsideLocalDF =
      applyTaxOnOutsideLocalDF.toLowerCase() === "true";

    const apply_taxOnDeliveryFee = ["FLORIST_DELIVERED"].includes(deliveryType)
      ? applyTaxOnLocalDF
      : ["FLORIST_PARTNER", "PHONE_OUT"].includes(deliveryType)
      ? applyTaxOnOutsideLocalDF
      : false;

    const taxableAmount =
      productAmount +
      (apply_taxOnDeliveryFee ? parseFloat(deliveryFee) : 0) +
      (taxOnRetailDeliveryFee ? parseFloat(retail_delivery_fee) : 0);

    const req = {
      requests: [
        {
          siteId: "ftd",
          storeId: null,
          deliveryType: "domestic",
          amount: taxableAmount,
          requestId: "Item" + Math.random() * 10000,
          feeType: "Base Fee",
          address: getAddressForTax(
            address,
            floristAddress,
            local_order_tax_settings,
            deliveryType
          ),
          product: {
            subType: productSubType,
            taxCategoryId: null,
            type: productType,
          },
          productCategoryName: categoryName,
          productId: products[0]?.productId,
        },
      ],
    };
    if (!taxExemptCode) {
      request("get-tax", req).then((res) => {
        const { amount = 0.0, rate = 0.0 } = get(
          res,
          "responses.0.totalTax",
          0
        );
        const taxSplits = get(res, "responses.0.taxSplits", []);
        const taxInfo = {
          taxAmount: amount,
          taxRate: rate,
          taxSplits,
        };
        setFieldValue(`locations.${index}.taxInfo`, taxInfo);
      });
    } else {
      setFieldValue(`locations.${index}.taxInfo`, {
        taxAmount: 0,
        taxRate: 0,
        taxSplits: [],
      });
    }
  };

  useEffect(() => {
    if (
      !zip ||
      !city ||
      !state ||
      !country ||
      !deliveryDate ||
      !memberCode ||
      !addressLine1 ||
      !isProposalPage ||
      !products.length
    )
      return;

    if (eventId) {
      const {
        address: {
          addressLine1: initialAddressLine1,
          city: initialCity,
          state: initialState,
          zip: initialZip,
          country: initialCountry,
        } = {},
        deliveryDate: initialDateTime,
        products: initialProducts,
        deliveryType: initialDeliveryType,
      } = initialLocationInfo;

      //If address Or deliveryDate Or Products changes trigger deliver-fee and tax other-wise use the initial info
      if (
        isEqual(
          {
            customerId: initailCustomerId,
            addressLine1: initialAddressLine1,
            city: initialCity,
            state: initialState,
            zip: initialZip,
            country: initialCountry,
            deliveryDate: initialDateTime,
            products: initialProducts,
            deliveryType: initialDeliveryType,
          },
          {
            customerId,
            addressLine1,
            city,
            state,
            zip,
            country,
            deliveryDate,
            products,
            deliveryType,
          }
        )
      ) {
        setFieldValue(
          `locations.${index}.deliveryFee`,
          parseFloat(initialLocationInfo.deliveryFee).toFixed(2)
        );
        setFieldValue(
          `locations.${index}.retailDeliveryFee`,
          parseFloat(initialLocationInfo.retailDeliveryFee).toFixed(2)
        );
        setFieldValue(
          `locations.${index}.taxInfo`,
          initialLocationInfo.taxInfo
        );
        return;
      }
    }

    const requestParams = {
      zip,
      city: encodeURIComponent(city),
      state,
      country,
      deliveryDate: moment(deliveryDate).utc().format("YYYY-MM-DD"),
      selectedMemberCode: memberCode,
      streetAddress: addressLine1,
    };
    if (deliveryType === "FLORIST_DELIVERED") {
      request("get-delivery-fee", requestParams).then((resp) => {
        const deliveryFee = resp?.deliveryFee?.localFee;
        getTax(deliveryFee);
        setFieldValue(
          `locations.${index}.deliveryFee`,
          parseFloat(deliveryFee).toFixed(2)
        );
        setFieldValue(
          `locations.${index}.retailDeliveryFee`,
          state === floristAddress.state
            ? parseFloat(retail_delivery_fee || 0).toFixed(2)
            : "0.00"
        );
      });
    } else {
      setFieldValue(`locations.${index}.deliveryFee`, "0.00");
      setFieldValue(`locations.${index}.reailDeliveryFee`, "0.00");
      getTax(0);
    }
  }, [
    zip,
    city,
    state,
    country,
    deliveryDate,
    memberCode,
    addressLine1,
    isProposalPage,
    setFieldValue,
    index,
    deliveryType,
    address,
    products,
    selectedproducts,
    taxExemptCode,
    taxOnDeliveryFee,
  ]);

  useEffect(() => {
    if (
      isProposalPage &&
      parseFloat(initialLocationInfo.deliveryFee).toFixed(2) !==
        parseFloat(deliveryFee).toFixed(2)
    ) {
      getTax(parseFloat(deliveryFee));
    }
  }, [deliveryFee]);

  return useMemo(() => {
    if (!deliveryDate) return null;

    return (
      <React.Fragment key={index}>
        <Divider style={{ width: "100%", margin: 0 }} />
        <View
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <View style={{ width: "50%", padding: 18 }}>
            <View>
              <Text
                style={{
                  ...fonts.heading4,
                  color: backgroundColors.primary,
                  marginBottom: 6,
                }}
              >
                {title}
              </Text>
            </View>
            <View>
              <Text>{`${name} / ${addressLine}, ${city}, ${state} ${zip}`}</Text>
            </View>
            {!!locationNotes && (
              <View style={{ marginTop: 8, marginBottom: 6 }}>
                <Text style={{ ...fonts.heading5, marginBottom: 4 }}>
                  {Localise(messages, "Location Notes")}
                </Text>
                <Text>{locationNotes}</Text>
              </View>
            )}
            <View style={{ marginTop: 8, marginBottom: 6 }}>
              <Text style={{ ...fonts.heading5, marginBottom: 4 }}>
                {Localise(messages, "Location Times")}
              </Text>
              {locationTimes.map((time, idx) => {
                const { label, startTime, endTime, notes } = time;
                const start = moment(startTime).format("hh:mm a");
                const end = moment(endTime).format("hh:mm a");
                const notesText = notes ? `/ ${notes}` : "";
                return (
                  <Text
                    key={idx}
                  >{`${label} / ${start} - ${end} ${notesText}`}</Text>
                );
              })}
            </View>
            <View style={{ marginTop: 8, marginBottom: 6 }}>
              <Text style={{ ...fonts.heading5, marginBottom: 6 }}>
                {Localise(messages, "Products")}
              </Text>
              {products.map((product, idx) => {
                const { quantity, productName } = product;
                return (
                  <View
                    key={idx}
                    style={{
                      marginBottom: 2,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text style={{ width: 20 }}>{quantity}</Text>
                    <Text style={{ width: "calc(100% - 20px)" }}>
                      {productName}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>
          <View style={{ width: "50%", padding: 18 }}>
            <View style={{ marginBottom: 6 }}>
              <Text style={{ ...fonts.heading5, marginBottom: 6 }}>
                {Localise(messages, "Delivery Method")}
              </Text>
              <Text>
                {DeliveryTypes.find((x) => x.value === deliveryType)?.label}
              </Text>
            </View>
            {!!deliveryInstructions && (
              <View style={{ marginTop: 8, marginBottom: 6 }}>
                <Text style={{ ...fonts.heading5, marginBottom: 6 }}>
                  {Localise(messages, "Delivery Instructions")}
                </Text>
                <Text>{deliveryInstructions}</Text>
              </View>
            )}
          </View>
        </View>
      </React.Fragment>
    );
  }, [
    title,
    name,
    locationNotes,
    deliveryType,
    deliveryInstructions,
    addressLine,
    city,
    state,
    zip,
    Localise,
    index,
    messages,
    locationTimes,
    products,
    deliveryDate,
  ]);
};

const mapStateToProps = (state = {}) => {
  return {
    selectedproducts: state.mhq.ongoing.global.selectedproducts,
  };
};

export default connect(mapStateToProps, null)(LocationInfo);
