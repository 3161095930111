import React, { useContext, useEffect } from "react";
import {
  ActivityIndicator,
  View,
  Platform,
  TouchableOpacity,
  Image,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";

import tw from "tailwind-rn";
import isArray from "lodash/isArray";
import clone from "lodash/clone";
import { Text, Button } from "react-native-elements";

import { theme, colors } from "styles/theme";
import IMAGES from "static/assets/images";

import I18NContext from "library/contexts/i18N";
import useStateIfMounted from "library/utils/useStateIfMounted";

import { ToasterHandler, Tooltip } from "components/elements";
import { Form, FormField } from "components/elements/forms";
import { SaveCancelButtons } from "components/wrappers";

import { selectShopCode } from "library/sagas/views/home/drawer/shop-settings/common/selector";

import { getTerminalActivationCodes } from "library/sagas/views/home/drawer/shop-settings/common/slice";

import {
  getInitialValues,
  getValidationSchema,
  apiKeyMappings,
} from "./config";

import { getCurrentPOSSettings } from "../../helper";
import SetupInstructions from "./setup-instructions";

export const TerminalSettings = ({ shopSettings, saveSettings }) => {
  const dispatch = useDispatch();

  const shopCode = useSelector(selectShopCode);

  const { messages, Localise } = useContext(I18NContext);

  const [initialValues, setInitialValues] = useStateIfMounted({});
  const [currentPOSSettingsIndex, setCurrentPOSSettingsIndex] =
    useStateIfMounted(-1);
  const [activationCodeLoader, setActivationCodeLoader] =
    useStateIfMounted(false);

  const macAddress =
    Platform.OS === "web" && document.getElementById("macAddress").value;

  const savedTerminalSettings = shopSettings["terminal_settings"] || [];
  const hasMultiplePreferences = isArray(savedTerminalSettings);

  const extractCurrentPOSSettings = async () => {
    const { currentPOSSettings = {}, currentPOSSettingsIndex = -1 } =
      await getCurrentPOSSettings(savedTerminalSettings);

    setCurrentPOSSettingsIndex(currentPOSSettingsIndex);

    const initialFormValues = getInitialValues(currentPOSSettings, macAddress);

    setInitialValues(initialFormValues);
  };

  useEffect(() => {
    extractCurrentPOSSettings();
  }, []);

  const onSubmit = async (values, formikBag, action = "") => {
    const terminalSettings = {};

    Object.entries(values).map((entry) => {
      if (entry.length && entry[1] !== "") {
        terminalSettings[apiKeyMappings[entry[0]]] = entry[1].trim();
      }
    });

    let newTerminalSettings = [];

    const clonedTerminalSettings = clone(savedTerminalSettings);

    if (hasMultiplePreferences) {
      if (currentPOSSettingsIndex === -1 && action !== "DELETE") {
        clonedTerminalSettings.push(terminalSettings);
      } else {
        if (values?.serialNumber === "" || action === "DELETE") {
          const { currentPOSSettingsIndex = -1 } = await getCurrentPOSSettings(
            savedTerminalSettings
          );
          clonedTerminalSettings.splice(currentPOSSettingsIndex, 1);
        } else {
          clonedTerminalSettings.splice(
            currentPOSSettingsIndex,
            1,
            terminalSettings
          );
        }
      }
      newTerminalSettings = clonedTerminalSettings;
    } else {
      newTerminalSettings = [terminalSettings];
    }

    const preferences = [
      {
        id: "terminal_settings",
        values: [JSON.stringify(newTerminalSettings)],
      },
    ];

    saveSettings({ preferences }, true, false, () => {
      if (action === "DELETE") {
        // In case of delete, resetting the values
        const initialValues = getInitialValues({}, macAddress);
        formikBag.resetForm(initialValues);
        setInitialValues(initialValues);
      } else {
        // In case of save, reInitialising the values
        const initialValues = getInitialValues(
          {
            mac_address: values.macAddress,
            serial_number: values.serialNumber,
            name: values.terminalName,
          },
          macAddress
        );
        setInitialValues(initialValues);
      }
      formikBag.setSubmitting(false);
    });
  };

  const getActivationCodes = (payload, setFieldValue) => {
    setActivationCodeLoader(true);
    dispatch(
      getTerminalActivationCodes({
        payload,
        resolve: (response) => {
          setFieldValue &&
            setFieldValue("activationCode", response.tokens[0].token);

          setActivationCodeLoader(false);
        },
        reject: (error) => {
          setActivationCodeLoader(false);
          ToasterHandler("uh oh", Localise(messages, error));
        },
      })
    );
  };

  return (
    <View style={tw("w-full flex flex-row")}>
      <View style={{ width: "50%" }}>
        <Form
          initialValues={{ membercode: shopCode, size: "1" }}
          onSubmit={(values) => {
            getActivationCodes(values);
          }}
          render={({ setFieldValue, values }) => {
            return (
              <>
                <View style={[tw("flex pb-5"), { paddingLeft: 5 }]}>
                  <View style={tw("flex flex-row items-center")}>
                    <Text style={{ fontSize: 14 }}>
                      {Localise(
                        messages,
                        "Activation Code (active for 24 hours)"
                      )}
                    </Text>
                  </View>
                  <View style={tw("flex flex-row items-center mt-2")}>
                    <Button
                      key="generate_code"
                      titleStyle={theme.Button.secondaryTitleStyle}
                      buttonStyle={{
                        ...theme.Button.secondaryButtonStyle,
                        paddingVertical: 7,
                      }}
                      containerStyle={{
                        justifyContent: "center",
                        alignItems: "flex-start",
                        margin: 0,
                      }}
                      disabled={!!values.activationCode}
                      disabledStyle={{ borderWidth: 0 }}
                      onPress={() =>
                        getActivationCodes(
                          { membercode: shopCode, size: "1" },
                          setFieldValue
                        )
                      }
                      title={Localise(messages, "Generate Code")}
                      testID={"generate_code"}
                      accessibilityLabel={"generate_code"}
                    />
                    {activationCodeLoader && (
                      <ActivityIndicator
                        style={{ marginLeft: 5 }}
                        color={colors.activityIndicator}
                      />
                    )}
                    {values.activationCode && (
                      <View style={tw("flex flex-row items-center")}>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "600",
                            paddingLeft: 15,
                          }}
                          testID="activationCode"
                        >
                          {values.activationCode}
                        </Text>
                        <Tooltip
                          text={Localise(messages, "Copy Code")}
                          renderForWebOnly={true}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              navigator.clipboard.writeText(
                                values.activationCode
                              );
                            }}
                            style={{ paddingLeft: 10 }}
                            testID="close"
                            accessibilityLabel="close"
                          >
                            <Image
                              style={{ width: 20, height: 30 }}
                              resizeMode="cover"
                              source={IMAGES["copy-content"]}
                              testID="copyCodeImage"
                            />
                          </TouchableOpacity>
                        </Tooltip>
                      </View>
                    )}
                  </View>
                </View>
              </>
            );
          }}
        />
        <Form
          initialValues={initialValues}
          validationSchema={getValidationSchema(Localise, messages)}
          validateOnChange={true}
          validateOnBlur={false}
          onSubmit={(values, formikBag) => {
            onSubmit(values, formikBag);
          }}
          render={(formikBag) => {
            return (
              <>
                <View style={[tw("flex flex-row items-center")]}>
                  <FormField
                    name="terminalName"
                    placeholder={"Terminal Name"}
                    label={"Terminal Name"}
                    labelStyle={{ fontWeight: "normal" }}
                    autoCapitalize="none"
                    autoCorrect={false}
                    containerStyle={{
                      width: 300,
                      maxWidth: "50%",
                    }}
                    maxLength={30}
                    testID="terminal_name"
                    accessibilityLabel="terminal_name"
                  />
                </View>
                <View style={[tw("flex flex-row items-center")]}>
                  <FormField
                    name="serialNumber"
                    placeholder={"Serial Number"}
                    label={"Serial Number"}
                    labelStyle={{ fontWeight: "normal" }}
                    autoCapitalize="none"
                    autoCorrect={false}
                    containerStyle={{
                      width: 300,
                      maxWidth: "50%",
                    }}
                    maxLength={30}
                    keyboardType="numeric"
                    testID="serial_number"
                    accessibilityLabel="serial_number"
                  />
                </View>

                <View
                  testID="terminal_buttons"
                  style={tw("flex flex-row items-center justify-start")}
                >
                  <SaveCancelButtons
                    showCancel={false}
                    disableOnErrors={true}
                    buttonTitle={Localise(messages, "Save Terminal")}
                  />
                  <Button
                    key="delete_terminal"
                    titleStyle={theme.Button.secondaryTitleStyle}
                    buttonStyle={{
                      ...theme.Button.secondaryButtonStyle,
                      paddingVertical: 7,
                    }}
                    containerStyle={{
                      justifyContent: "center",
                      alignItems: "flex-start",
                      marginBottom: 10,
                      marginLeft: 0,
                      marginTop: 6,
                    }}
                    onPress={() =>
                      onSubmit(formikBag.values, formikBag, "DELETE")
                    }
                    title={Localise(messages, "Delete Terminal")}
                    testID={"delete_terminal"}
                    accessibilityLabel={"delete_terminal"}
                  />
                </View>
              </>
            );
          }}
        />
      </View>
      <View style={{ width: "50%", padding: 40 }}>
        <SetupInstructions />
      </View>
    </View>
  );
};

export default TerminalSettings;
