import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { View, Text, ScrollView } from "react-native";
import { CheckBox } from "react-native-elements";

import {
  SideCar,
  PrimaryButton,
  LabelField,
  SelectAll,
  FilterResults,
  SearchResults,
} from "components/wrappers";
import { ToasterHandler, Spinner } from "components/elements";
import Filters from "components/containers/listing-new/header/controls/filters";
import Search from "components/containers/search";

import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { selectSideCarTitle } from "library/sagas/views/home/drawer/ftd-admin/member-settings/common/selector";
import {
  selectPageError,
  selectPageStatus,
  selectHasChanges,
  selectCatalogLookup,
  selectCurrentCatalog,
  selectFilteredMemberMappings,
  selectFilters,
  selectSearchText,
  selectMembersSelected,
} from "library/sagas/views/home/drawer/ftd-admin/member-settings/shared-catalogs/selector";
import { setSideCar } from "library/sagas/views/home/drawer/ftd-admin/member-settings/common/slice";
import {
  setAction,
  setSelection,
  saveCatalogMemberMappings,
  setResetSelectedMembers,
} from "library/sagas/views/home/drawer/ftd-admin/member-settings/shared-catalogs/slice";

import { RestrictedCatalogPageFilters } from "./ui-config";
import { theme, fonts, backgroundColors, colors } from "styles/theme";
import tw from "tailwind-rn";

const SharedCatalogDetails = () => {
  const current = useSelector(selectCurrentCatalog);

  return (
    <SideCar titleSelector={selectSideCarTitle} onCloseAction={setSideCar}>
      <View style={tw("flex flex-col")}>
        <GroupBasicInfo />
        {current.isRestricted && <RestrictedCatalog />}
      </View>
    </SideCar>
  );
};

const GroupBasicInfo = () => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { name, isRestricted, isGroupActive } =
    useSelector(selectCurrentCatalog);

  const accessLabel = isRestricted ? "Restricted" : "Unrestricted";
  const accessValue = isRestricted
    ? "Limited Users have access"
    : "All users have access";
  const statusValue = isGroupActive ? "Active" : "Inactive";

  return (
    <View>
      <Text style={[fonts.heading3, tw("mb-1")]}>{name}</Text>
      <View style={{ flexDirection: "row" }}>
        <Text style={{ minWidth: 95 }}>{Localise(messages, accessLabel)}:</Text>
        <Text>{Localise(messages, accessValue)}</Text>
      </View>
      <View style={{ flexDirection: "row" }}>
        <Text style={{ minWidth: 95 }}>Status:</Text>
        <Text>{Localise(messages, statusValue)}</Text>
      </View>
      {/* <View style={{ flexDirection: "row" }}>
        <Text style={{ minWidth: 95 }}>Availability Dates:</Text>
        <Text>{availabilityDates[0]} - {availabilityDates[1]} </Text>
      </View> */}
    </View>
  );
};

const RestrictedCatalog = () => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const status = useSelector(selectPageStatus);
  const error = useSelector(selectPageError);

  const filterProps = {
    width: "5%",
    icon: "filter",
    options: {
      "Filter By": RestrictedCatalogPageFilters(Localise, messages),
    },
    selectors: {
      selectValue: selectFilters,
    },
    actions: {
      setAction: setAction,
    },
  };

  const searchProps = {
    type: "fullWidth",
    containerStyle: {
      paddingHorizontal: 0,
      paddingRight: 10,
      maxWidth: 440,
    },
    icon: "search",
    placeholder: Localise(messages, "Search Shops"),
    selectors: {
      selectValue: selectSearchText,
    },
    actions: {
      setAction: setAction,
    },
  };

  if (status === "progress")
    return error ? (
      <View style={{ minHeight: 150, width: 300, marginTop: 20 }}>
        <Text style={{ ...fonts.error, textAlign: "left" }}>
          Something went wrong, please try after sometime
        </Text>
      </View>
    ) : (
      <View style={{ minHeight: 150, width: 300 }}>
        <Spinner size="large" />
      </View>
    );

  return (
    <>
      <View style={tw("flex flex-row items-center mt-5")}>
        <Search {...searchProps} />
        <Filters {...filterProps} />
      </View>
      {isDesktop ? (
        <View style={tw("flex flex-row items-center mt-3")}>
          <FilterResults input={selectFilters} action={setAction} />
        </View>
      ) : null}
      <SearchResults
        selectSearchText={selectSearchText}
        setSearchText={setAction}
      />
      <MemberMappings />
    </>
  );
};

const MemberMappings = () => {
  const dispatch = useDispatch();
  const { Localise, messages } = React.useContext(I18NContext);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);

  const catalogLookup = useSelector(selectCatalogLookup);
  const { name: selectedCatalog } = useSelector(selectCurrentCatalog);
  const memberMappings = useSelector(selectFilteredMemberMappings);
  const selectedMembers = useSelector(selectMembersSelected);
  const hasChanges = useSelector(selectHasChanges);

  const onReset = () => {
    setSelectAll(false);
    dispatch(setResetSelectedMembers());
  };

  const handleOnSave = () => {
    setLoading(true);

    dispatch(
      saveCatalogMemberMappings({
        resolve: () => {
          ToasterHandler(
            "looks good",
            Localise(messages, `${selectedCatalog} settings have been updated`)
          );
          setLoading(false);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(messages, `Failed to update ${selectedCatalog} settings`)
          );
          setLoading(false);
        },
      })
    );
  };

  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
        opacity: 1,
        marginVertical: 10,
        padding: 10,
        flex: 1,
        maxWidth: 460,
        maxHeight: 550,
      }}
    >
      <View>
        <LabelField
          heading={true}
          style={fonts.heading3}
          text={`Members (${memberMappings.length})`}
        />
      </View>
      <View style={tw("flex flex-row justify-between items-center pb-3")}>
        <SelectAll
          value={selectAll}
          onChange={(value) => {
            dispatch(setSelection(value ? "all" : ""));
            setSelectAll(value);
          }}
        />
        <View style={tw("flex flex-row items-center")}>
          {hasChanges && (
            <Text onPress={onReset} style={fonts.link1}>
              reset
            </Text>
          )}
          <PrimaryButton
            action={handleOnSave}
            loading={loading}
            style={{ width: 50, height: 50, marginLeft: 5 }}
            title="Save"
            disabled={!hasChanges}
          />
        </View>
      </View>
      <ScrollView>
        {memberMappings.length === 0 && (
          <View>
            <Text style={fonts.style4}>No members available</Text>
          </View>
        )}
        {memberMappings.map((item, index) => {
          const mapped = selectedMembers.includes(item.memberCode);
          const allowedCatalogs = [...item.allowedCatalogs]
            .map((e) => catalogLookup[e])
            .sort()
            .join(", ");

          return (
            <View
              key={index}
              style={{ flexDirection: "row", flex: 1, paddingRight: 20 }}
            >
              <View>
                <CheckBox
                  containerStyle={[
                    theme.CheckBox.inputContainerStyle,
                    { paddingRight: 3 },
                  ]}
                  size={18}
                  testID={"Member"}
                  accessibilityLabel={"Member"}
                  checked={mapped}
                  onPress={() => dispatch(setSelection(item.memberCode))}
                />
              </View>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: colors.grayScaleLight,
                  opacity: 1,
                  marginBottom: 10,
                  padding: 10,
                  flex: 1,
                  backgroundColor: mapped
                    ? backgroundColors.navBar
                    : colors.secondary,
                }}
              >
                <LabelField
                  heading={true}
                  text={`${item.memberCode} ${item.businessName}`}
                />
                <LabelField
                  style={{ ...fonts.style2, marginTop: 4 }}
                  text={
                    allowedCatalogs ||
                    Localise(messages, "Not subscribed to any catalogs")
                  }
                />
              </View>
            </View>
          );
        })}
      </ScrollView>
    </View>
  );
};

export default SharedCatalogDetails;
