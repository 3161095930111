export const PermissionsForInvalidateSessions = {
  FTD_ADMIN: true,
  FTD_POWER_USER: false,
  FTD_VIEW_ONLY: false,
  FTD_CSR: false,
};

export const Messages = {
  confirmationMessage:
    "Are you sure you want to invalidate all the user sessions?",
  successMessage: "All user sessions invalidated successfully.",
  failureMessage: "Unable to invalidate all user sessions, please try later.",
};
