import { select, put, call, delay } from "redux-saga/effects";
import moment from "moment";
import get from "lodash/get";

import { selectShopCode, selectActiveSuspension } from "../selector";
import { setApiResponse, setApiError, fetchWebsiteStatus } from "../slice";
import { WEBSITE_STATUS_POLL_INTERVAL } from "../constants";

import request from "../request";

export function* handleFetchWebsiteStatus() {
  const serviceRequest = (params) => request("get-suspensions", params);
  const startDate = moment(new Date())
    .subtract(365, "days")
    .format("YYYY-MM-DD");
  const shopCode = yield select(selectShopCode);

  try {
    const response = yield call(serviceRequest, { startDate, shopCode });
    const content = processWebsiteStatusResponse(response);

    yield put(
      setApiResponse({
        path: "websiteStatus",
        content,
      })
    );
  } catch (error) {
    yield put(
      setApiError({
        path: "websiteStatus",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleWebsiteActiveSuspension() {
  const { type, startTime, endTime, startDate, endDate } = yield select(
    selectActiveSuspension
  );

  if (type === "upcoming") {
    const isStartToday = moment(startDate).isSame(moment(), "day");

    if (isStartToday) {
      const suspendStartDiff = moment(startTime).diff(moment(), "milliseconds");

      if (suspendStartDiff <= WEBSITE_STATUS_POLL_INTERVAL) {
        try {
          yield delay(suspendStartDiff);
          yield put(fetchWebsiteStatus());
        } catch (error) {
          console.log("Active Suspensions Error :>> ", error);
        }
      }
    }
  } else if (type === "suspended") {
    const isEndToday = moment(endDate).isSame(moment(), "day");

    if (isEndToday) {
      const suspendEndDiff = moment(endTime).diff(moment(), "milliseconds");

      if (suspendEndDiff <= WEBSITE_STATUS_POLL_INTERVAL) {
        try {
          yield delay(suspendEndDiff);
          yield put(fetchWebsiteStatus());
        } catch (error) {
          console.log("Active Suspensions Error :>> ", error);
        }
      }
    }
  }
}

export function* handleSaveSuspension(action = {}) {
  const { item, resolve, reject } = get(action, "payload", {});
  const serviceRequest = (params) => request("save-suspension", params);
  const shopCode = yield select(selectShopCode);
  const payload = prepareSaveSuspensionPayload(item, shopCode);

  try {
    yield call(serviceRequest, payload);
    yield call(handleFetchWebsiteStatus);

    if (resolve) resolve();
  } catch (error) {
    yield put(
      setApiError({
        path: "websiteStatus",
        error: "Something went wrong, please try again",
      })
    );
    if (reject) reject();
  }
}

export function* handleDeleteSuspension({ payload: { id, resolve, reject } }) {
  const serviceRequest = (params) => request("delete-suspension", params);
  const shopCode = yield select(selectShopCode);

  try {
    yield call(serviceRequest, { exceptionId: id, shopCode });
    yield call(handleFetchWebsiteStatus);

    if (resolve) resolve();
  } catch (error) {
    yield put(
      setApiError({
        path: "websiteStatus",
        error: "Something went wrong, please try again",
      })
    );
    if (reject) reject();
  }
}

const processWebsiteStatusResponse = (response = {}) => {
  const apiResponse = get(response, "details", []);

  const suspensions = apiResponse
    .map((item) => {
      const localStartDateTime = moment(new Date(item.suspendStartDate));
      const localEndDateTime = moment(new Date(item.suspendEndDate));
      return {
        id: item.suspendId,
        type: moment().isAfter(moment(item.suspendEndDate), "day")
          ? "past"
          : moment().isSameOrAfter(moment(item.suspendStartDate), "day")
          ? "suspended"
          : "upcoming",
        startDate: localStartDateTime.format("YYYY-MM-DD"),
        startTime: localStartDateTime.format("YYYY-MM-DD").concat("T00:00:00"),
        endDate: localEndDateTime.format("YYYY-MM-DD"),
        endTime: localEndDateTime.format("YYYY-MM-DD").concat("T23:59:59"),
      };
    })
    .sort((a, b) => moment(b.startTime) - moment(a.startTime));

  const status = suspensions.find(
    (suspension) => suspension.type === "suspended"
  )
    ? "Suspended"
    : "Active";

  return {
    activeSuspensions: suspensions.filter((e) => e.type !== "past"),
    pastSuspensions: suspensions.filter((e) => e.type === "past"),
    status,
  };
};

const prepareSaveSuspensionPayload = (item = {}, shopCode) => {
  const { id: suspendId = "", startDate, endDate } = item;
  const isStartDateToday = moment(startDate).isSame(moment(), "day");
  const currentTime = moment().format("HH:mm:ss");

  const UTCStartDateTime = isStartDateToday
    ? moment(`${startDate}T${currentTime}`).utc()
    : moment(`${startDate}T00:00:00`).utc();
  const UTCEndDateTime = moment(`${endDate}T23:59:59`).utc();

  return {
    startDate: UTCStartDateTime,
    endDate: UTCEndDateTime,
    type: "MOL",
    shopCode,
    ...(suspendId && { suspendId }),
  };
};
