import React, { Component } from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import range from "lodash/range";
import { Text, Icon } from "react-native-elements";

export default class Calendar extends Component {
  renderWeekDays() {
    const weekdays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    return weekdays.map((day) => {
      return (
        <Text key={day} style={styles.calendar_weekdays_text}>
          {day}
        </Text>
      );
    });
  }

  getWeeksArray(days) {
    var weeks_r = [];
    var seven_days = [];
    var count = 0;
    days.forEach((day) => {
      count += 1;
      seven_days.push(day);
      if (count == 7) {
        weeks_r.push(seven_days);
        count = 0;
        seven_days = [];
      }
    });
    console.log(weeks_r);
    return weeks_r;
  }

  renderDays(week_days) {
    return week_days.map((day, index) => {
      console.log("each day in the renderDays", day);
      return (
        <View key={index} style={styles.day} onPress={this.press.bind(this)}>
          <Text style={styles.day_text}>{day}</Text>
        </View>
      );
    });
  }

  renderWeeks() {
    let past_month_days = range(27, 31);
    let this_month_days = range(1, 30);

    let days = past_month_days.concat(past_month_days, this_month_days);
    let grouped_days = this.getWeeksArray(days);

    return grouped_days.map((week_days, index) => {
      return (
        <View key={index} style={styles.week_days}>
          {this.renderDays(week_days)}
        </View>
      );
    });
  }

  render() {
    return (
      <ScrollView style={styles.container}>
        <View style={styles.header}>
          <View style={styles.header_item}>
            <Text style={[styles.header_title, styles.bold_text]}>
              Calendar
            </Text>
          </View>
          <View style={styles.calendar_nav}>
            <Icon
              name="chevron-left"
              iconType="font-awesome"
              size={25}
              color="#333"
              onPress={this.press.bind(this)}
            />
            <Text style={styles.calendar_nav_text}>November</Text>
            <Icon
              name="chevron-right"
              iconType="font-awesome"
              size={25}
              color="#333"
              onPress={this.press.bind(this)}
            />
          </View>
          <View style={styles.header_item}>
            <Text style={[styles.header_text, styles.text_center]}>Month</Text>
          </View>
          <View style={styles.header_item}>
            <Text style={[styles.header_text, styles.text_center]}>Week</Text>
          </View>
          <View style={styles.header_item}>
            <Text style={[styles.header_text, styles.text_center]}>Day</Text>
          </View>
        </View>

        <View>
          <View style={styles.calendar_header}>
            <Text style={styles.calendar_header_text}>November 2020</Text>
          </View>
          <View style={styles.calendar_weekdays}>{this.renderWeekDays()}</View>
          <View style={styles.calendar_days}>{this.renderWeeks()}</View>
        </View>
      </ScrollView>
    );
  }

  press() {}
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#999",
  },
  header: {
    flexDirection: "row",
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#999",
  },
  header_item: {
    flex: 1,
  },
  header_button: {
    flexDirection: "row",
  },
  text_center: {
    textAlign: "center",
  },
  text_right: {
    textAlign: "right",
  },
  header_title: {
    fontSize: 20,
  },
  header_text: {
    fontSize: 18,
  },
  bold_text: {
    fontWeight: "bold",
  },
  calendar_nav: {
    flexDirection: "row",
  },
  calendar_header: {
    flexDirection: "row",
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#999",
  },
  calendar_nav_text: {
    fontWeight: "600",
    fontSize: 18,
    paddingRight: 8,
    paddingLeft: 8,
  },
  calendar_header_text: {
    fontWeight: "600",
    fontSize: 18,
    paddingRight: 20,
    paddingLeft: 20,
  },
  calendar_weekdays: {
    flexDirection: "row",
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#999",
  },
  calendar_weekdays_text: {
    flex: 1,
    textAlign: "center",
    textTransform: "capitalize",
  },
  week_days: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  day: {
    flex: 1,
    backgroundColor: "#F5F5F5",
    padding: 17,
    margin: 2,
  },
  day_text: {
    textAlign: "center",
    color: "#A9A9A9",
    fontSize: 25,
  },
  small_text: {
    fontSize: 15,
  },
  big_text: {
    fontSize: 50,
    fontWeight: "bold",
  },
  inline: {
    flexDirection: "row",
  },
});
