import React, { useEffect } from "react";
import {
  View,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { useSelector } from "react-redux";
import { Text, Image, Button } from "react-native-elements";
import tw from "tailwind-rn";
import { fonts, backgroundColors, colors } from "styles/theme";
import { Form } from "components/elements/forms";
import { request } from "library/utils/request";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import { ToasterHandler, Spinner } from "components/elements";
import UserProfileStorage from "library/storage/userProfile";
import IMAGES from "static/assets/images";
import I18NContext from "library/contexts/i18N";
import { Section } from "components/views/drawer/mercury-online/helper";
import { getValidationSchema } from "./yup";
import ProfileNotes from "./profile-notes";
import ProfileDetails from "./profile-details";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { getInitialValues } from "./ui-config";
import get from "lodash/get";
import toUpper from "lodash/toUpper";
import union from "lodash/union";
import isObject from "lodash/isObject";
import isEqual from "lodash/isEqual";
import { selectState } from "library/sagas/ongoing/staff-listing/selector";
import { customisePushNotificationsOptions } from "components/views/drawer/user-profile/config";
import { Entitlements } from "library/utils/entitlements";
import { formatPhoneForPayload } from "library/utils/formatter";
import { SaveCancelButtons } from "components/wrappers";
export default function CreateFloristStaffProfileScreen({
  onComplete,
  onAction,
  recordId,
  recordAction,
}) {
  const formRef = React.useRef();
  const {
    memberCodes = [],
    userProfile: { memberCode: myMemberCode } = {},
    userRole,
  } = React.useContext(UserProfileContext);

  let memberCode;
  if (UserProfileStorage.getProxyUser()) {
    memberCode = memberCodes[0];
  } else {
    memberCode = myMemberCode || memberCodes[0];
  }

  const isFloristAdmin = userRole === "ADMIN";

  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop, isTablet } = React.useContext(DeviceContext);
  const { userRolesPermissions } = useSelector(selectState);
  const navigation = useNavigation();

  const [profileData, setprofileData] = useStateIfMounted({});
  const [loading, setLoading] = useStateIfMounted(false);
  const [unlockLoading, setUnlockLoading] = useStateIfMounted(false);

  const recordIdArray = recordId && recordId.split(":");
  const primaryMemberCode =
    recordIdArray && recordIdArray.length && recordIdArray[0];
  const userEmailId =
    recordIdArray && recordIdArray.length > 1 && recordIdArray[1];

  const pullProfileDetails = () => {
    setLoading(true);

    setprofileData({});

    request("get-staff-profile", {
      emailId: userEmailId,
      memberCode: primaryMemberCode,
    })
      .then((response = {}) => {
        setprofileData(response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        ToasterHandler(
          "error",
          Localise(messages, "User not found, please try again")
        );
      });
  };

  useEffect(() => {
    recordId && pullProfileDetails();
  }, [recordId, recordAction]);

  const initialValues = getInitialValues(recordId ? profileData : {});
  const isCreateStaff = recordId ? false : true;

  const onFormSubmit = (values, formikBag) => {
    const {
      emailId,
      firstName,
      lastName,
      roles,
      shopCodes,
      status,
      employmentStatus,
      phone,
      profileNotes,
      passwordOptions,
      newPassword,
    } = values;

    let rolesInUpperCase = roles.map((roleName) => roleName.toUpperCase());
    let otherParams = {};

    if (isCreateStaff) {
      otherParams = {
        userId: emailId,
        memberCode,
        partnerGroup: "-",
        suppressSetPasswordEmail: passwordOptions === "MANUALLY_RESET",
      };
    } else {
      otherParams = {
        partnerGroup: profileData.partnerGroup,
        id: profileData.id,
        memberCode: profileData.memberCode,
      };
    }

    let optedOptions = {
      opted_order_push_notifications: [],
      opted_delivery_push_notifications: [],
    };

    let allSelectedPermissions = [];

    const rolesUpdated = !isEqual(
      get(profileData, "authGroups.0.roles", []),
      rolesInUpperCase
    );

    if (isCreateStaff || rolesUpdated) {
      roles.map((role) => {
        let selectedRolePermissions = get(
          userRolesPermissions.filter((per) => per.role === toUpper(role)),
          "0.permissions",
          []
        )?.map(toUpper);

        allSelectedPermissions = union(
          allSelectedPermissions,
          selectedRolePermissions
        );
      });

      customisePushNotificationsOptions.map((option) => {
        const { type, entitlement, name } = option;
        if (allSelectedPermissions.includes(Entitlements[entitlement])) {
          optedOptions[type].push(name);
        }
      });
    }

    const payload = {
      firstName,
      lastName,
      emailId,
      status: status ? "ACTIVE" : "IN-ACTIVE",
      phone: formatPhoneForPayload(phone),
      profileNotes,
      employmentStatus,
      authGroups: [
        {
          name: `${rolesInUpperCase}_ROLE`,
          roles: rolesInUpperCase,
          memberCodes:
            shopCodes.length > 1 ? shopCodes : [shopCodes[0].split(" ")[0]],
        },
      ],
      preferences: [
        {
          name: "Notification Sound",
          value: isCreateStaff
            ? "YES"
            : !isObject(
                get(profileData, "preferences", []).find(
                  (e) => e.name === "Notification Sound" && e.value === "NO"
                )
              )
            ? "YES"
            : "NO",
        },
        {
          name: "Push Notifications",
          value: isCreateStaff
            ? "YES"
            : !isObject(
                get(profileData, "preferences", []).find(
                  (e) => e.name === "Push Notifications" && e.value === "NO"
                )
              )
            ? "YES"
            : "NO",
        },
        {
          name: "opted_order_push_notifications",
          value:
            isCreateStaff || rolesUpdated
              ? optedOptions["opted_order_push_notifications"].join(",")
              : get(profileData, "preferences", []).find(
                  (e) => e.name === "opted_order_push_notifications"
                )?.value || "",
        },
        {
          name: "opted_delivery_push_notifications",
          value:
            isCreateStaff || rolesUpdated
              ? optedOptions["opted_delivery_push_notifications"].join(",")
              : get(profileData, "preferences", []).find(
                  (e) => e.name === "opted_delivery_push_notifications"
                )?.value || "",
        },
      ],
      ...otherParams,
    };

    const profileCreated = () => {
      formikBag.setSubmitting(false);
      formikBag.resetForm();

      ToasterHandler("success", Localise(messages, "New User created"));

      if (isDesktop) {
        onComplete();
        onAction("refresh", new Date().toString()); // To Refresh the listing screen every time on creating a new user
      } else {
        navigation.dispatch(
          CommonActions.navigate({
            name: "staff-management",
          })
        );
      }
    };

    const profileUpdated = (res) => {
      formikBag.setSubmitting(false);

      // While editing staff details, if we modify email id then we are setting recordId with new email id as mentioned below in else
      // o/w simply we are reloading staff details in side car by making get staff details api with same email id.
      if (userEmailId === emailId) {
        onAction(JSON.stringify(payload), recordId);
      } else {
        // Updating recordId with new email id in order to refresh staff deatils in side car by making get staff details api with new email id.
        recordId = `${primaryMemberCode}:${emailId}`;

        onComplete(recordId);
        onAction("refresh", recordId);
      }

      formikBag.setFieldValue("showPasswordField", false);

      res && get(res, "0.statusCode", 200) === 400
        ? ToasterHandler(
            "uh oh",
            Localise(messages, "Please try to update the password again")
          )
        : ToasterHandler(
            "nice",
            Localise(messages, "This profile has been updated")
          );
    };

    request(
      isCreateStaff ? "create-staff-profile" : "edit-staff-profile",
      payload
    )
      .then(() => {
        if (passwordOptions === "MANUALLY_RESET") {
          request("batch-update", [
            {
              email: emailId,
              unlock: true,
              newPassword,
            },
          ])
            .then((res) =>
              isCreateStaff ? profileCreated() : profileUpdated(res)
            )
            .catch(() => {
              formikBag.setSubmitting(false);

              ToasterHandler(
                "error",
                Localise(
                  messages,
                  isCreateStaff
                    ? "Unable to set password"
                    : "Unable to update password, please try again"
                )
              );
            });
        } else {
          isCreateStaff ? profileCreated() : profileUpdated();
        }
      })
      .catch((err) => {
        formikBag.setSubmitting(false);

        err === "USER_ALREADY_EXISTS"
          ? ToasterHandler(
              "oops",
              `${Localise(
                messages,
                "We’re seeing double – this user already has an account using"
              )} ${emailId}.`
            )
          : ToasterHandler(
              "uh oh",
              err.message ||
                Localise(
                  messages,
                  isCreateStaff
                    ? "Unable to create user, please try later."
                    : "Please try to update your profile again"
                )
            );
      });
  };

  const handlePasswordResetEmail = () => {
    request("initiate-reset-password", {
      email: profileData.emailId,
      type: "reset",
    })
      .then((res) => {
        ToasterHandler(
          "success",
          Localise(messages, "Password reset email has been sent")
        );
      })
      .catch((err) => {
        if (err === "INVALID_ACCOUNT")
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We couldn’t find that email – please try again or confirm the email"
            )
          );
        else
          ToasterHandler(
            "error",
            Localise(messages, "Something went wrong, please try again.")
          );
      });
  };

  const unlockProfile = () => {
    setUnlockLoading(true);

    request("batch-update", [
      {
        email: profileData.emailId,
        unlock: true,
      },
    ])
      .then(() => {
        setUnlockLoading(false);

        ToasterHandler(
          "they’re back",
          Localise(messages, "This profile has been unlocked")
        );

        onAction("refresh", recordId);
      })
      .catch(() => {
        setUnlockLoading(false);

        ToasterHandler(
          "error",
          Localise(messages, "Please try again to unlock this profile")
        );
      });
  };

  return (
    <>
      <ScrollView
        contentContainerStyle={{
          backgroundColor: backgroundColors.secondary,
          padding: isDesktop ? 5 : 10,
          ...(!isDesktop && { paddingBottom: 220 }),
        }}
        fsClass="fs-unmask"
      >
        <View
          style={{
            ...tw("px-3 py-3 flex flex-row items-center justify-between"),
            ...{ backgroundColor: backgroundColors.primary },
            ...(isTablet && { margin: 10 }),
          }}
          fsClass="fs-unmask"
        >
          <View style={tw("flex flex-row items-center")}>
            <Text style={[fonts.heading2, tw("text-white")]}>
              {Localise(messages, "Staff Profile")}
            </Text>
          </View>
          <View>
            <TouchableOpacity
              onPress={() => {
                formRef.current.resetForm();
                onComplete();
              }}
              testID="close"
            >
              <Image
                style={{ width: 20, height: 20 }}
                resizeMode="cover"
                source={IMAGES["close"]}
              />
            </TouchableOpacity>
          </View>
        </View>

        <View
          style={{
            borderBottomColor: colors.light,
            borderBottomWidth: 1,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.4,
            shadowRadius: 7,
            marginBottom: 20,
          }}
          fsClass="fs-unmask"
        />
        {loading ? (
          <View style={{ minHeight: 350 }}>
            <Spinner size={"large"} />
          </View>
        ) : (
          <View
            style={{
              width: "100%",
            }}
          >
            <Form
              initialValues={initialValues}
              onSubmit={onFormSubmit}
              validationSchema={getValidationSchema(Localise, messages)}
              innerRef={formRef}
              render={({ dirty, resetForm, values }) => {
                return (
                  <View style={tw("flex flex-col")}>
                    <Section
                      title={Localise(messages, "Profile Details")}
                      defaultOpen={true}
                    >
                      <ProfileDetails
                        isCreateStaff={isCreateStaff}
                        handlePasswordResetEmail={handlePasswordResetEmail}
                        isFloristAdmin={isFloristAdmin}
                        values={values}
                      />
                    </Section>
                    <Section
                      title={Localise(messages, "Profile Notes")}
                      defaultOpen={true}
                    >
                      <ProfileNotes />
                    </Section>
                    <View
                      style={tw(
                        "flex flex-row items-center justify-between py-2"
                      )}
                    >
                      <View style={tw("justify-start flex-row")}>
                        {isFloristAdmin && profileData.isAccountLocked && (
                          <Button
                            containerStyle={{ margin: 0 }}
                            onPress={unlockProfile}
                            title={Localise(messages, "Unlock Profile")}
                            testID="unlock_profile"
                            accessibilityLabel="unlock_profile"
                          />
                        )}
                        {unlockLoading && (
                          <ActivityIndicator
                            color={colors.activityIndicator}
                            style={tw("ml-1")}
                          />
                        )}
                      </View>

                      <SaveCancelButtons 
                        cancelTestId = {"cancel"}
                        buttonTitle={Localise(messages, "Save Profile")} 
                        />
                    </View>
                  </View>
                );
              }}
            />
          </View>
        )}
      </ScrollView>
    </>
  );
}
