import React, { useEffect } from "react";
import { ScrollView } from "react-native";
import { DeviceContext } from "library/contexts/appSettings";
import SingleScreen from "components/containers/single-screen";
import Video from "components/elements/video";
import SubHeader from "components/containers/header/sub-header";
import I18NContext from "library/contexts/i18N";
import UserAuthContext from "library/contexts/userAuth";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { introVideos } from "../help/video-links/config";
import { useRoute, useNavigation } from "@react-navigation/native";

const Demo = () => {
  const { isDesktop } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const { authToken } = React.useContext(UserAuthContext);

  const navigation = useNavigation();
  const { params: { key = "" } = {} } = useRoute();
  const [config, setConfig] = useStateIfMounted("");

  useEffect(() => {
    if (key.length > 0) {
      setConfig(introVideos[key]);
    }
    navigation.setParams({ key: [] });
  }, []);

  return (
    <>
      {!isDesktop && !!authToken && <SubHeader />}
      <SingleScreen title={Localise(messages, config.linkText)}>
        <ScrollView contentContainerStyle={{ padding: isDesktop ? 5 : 0 }}>
          <Video sourceUrl={config.url} />
        </ScrollView>
      </SingleScreen>
    </>
  );
};

export default Demo;
