import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { View, Text } from "react-native";
import { SecondaryButton } from "components/wrappers";
import { Spinner } from "components/elements";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import { setViewMoreCatalog } from "library/sagas/views/home/drawer/product-catalog/dashboard/slice";
import {
  selectPageData,
  selectCatalogLimit,
} from "library/sagas/views/home/drawer/product-catalog/dashboard/selector";

import { Widget } from "../helper";

import { fonts } from "styles/theme";
import tw from "tailwind-rn";

const Catalogs = () => {
  const dispatch = useDispatch();
  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile } = React.useContext(DeviceContext);
  const {
    catalogs: { data = [], status },
  } = useSelector(selectPageData);

  const currentLimit = useSelector(selectCatalogLimit);
  const baseLimit = isMobile ? 6 : 12;
  const showViewMore = currentLimit < data.length;

  useEffect(() => {
    if (currentLimit === 0) dispatch(setViewMoreCatalog(baseLimit));
  }, [currentLimit]);

  const displayCatalog = data.slice(0, currentLimit);

  return (
    <View style={tw("flex flex-col my-3")}>
      <View>
        <Text style={{ ...fonts.heading3, paddingBottom: 12 }}>
          {Localise(messages, "Catalogs")}
        </Text>
      </View>
      {status === "progress" ? (
        <View style={{ minHeight: 150 }}>
          <Spinner size="large" />
        </View>
      ) : (
        <View style={tw("flex flex-row flex-wrap")}>
          {displayCatalog.map((item, index) => {
            return (
              <Widget
                key={index}
                type="catalog"
                style={{ marginRight: 6 }}
                {...item}
              />
            );
          })}
        </View>
      )}

      {showViewMore && (
        <View
          style={tw(`flex flex-row-reverse justify-between items-center py-4`)}
        >
          <SecondaryButton
            title={"View More"}
            action={() => dispatch(setViewMoreCatalog(currentLimit))}
          />
        </View>
      )}
    </View>
  );
};

export default Catalogs;
