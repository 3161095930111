// A wrapper for location manager
import { Platform, PermissionsAndroid } from "react-native";
import Geolocation from "react-native-geolocation-service";

export class LocationManager {
  static requestCount = 0;

  static async requestPermission(Localise, messages) {
    if (Platform.OS === "android") {
      const granted = await PermissionsAndroid.check(
        PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION
      );

      if (granted) {
        return true;
      } else {
        const result = await PermissionsAndroid.requestMultiple(
          [
            PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION,
            PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
          ],
          {
            title: Localise(messages, "Location Permission Required"),
            message: Localise(
              messages,
              "Mercury HQ app needs access to your location for tracking order delivery location."
            ),
          }
        );

        if (
          result["android.permission.ACCESS_COARSE_LOCATION"] &&
          result["android.permission.ACCESS_FINE_LOCATION"] ===
            PermissionsAndroid.RESULTS.GRANTED
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else if (Platform.OS === "ios") {
      const permissions = await Geolocation.requestAuthorization("whenInUse");

      if (permissions === "granted") {
        return true;
      } else {
        return false;
      }
    } else if (Platform.OS === "web") {
      const permissions = await navigator.permissions.query({
        name: "geolocation",
      });

      if (permissions.state === "granted") {
        return true;
      } else {
        try {
          await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });
          return true;
        } catch (err) {
          return false;
        }
      }
    }
  }

  static async getCurrentLocation() {
    return new Promise((resolve, reject) => {
      Geolocation.getCurrentPosition(
        (position) => {
          resolve({
            success: true,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          reject({
            success: false,
            error: error,
          });
        },
        { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
      );
    });
  }
}
