import React from "react";
import { View, Text, ScrollView, Platform } from "react-native";
import { useSelector } from "react-redux";

import { Currency } from "components/wrappers";
import { selectProductActivity } from "library/sagas/views/home/drawer/product-catalog/product/selector";
import {
  selectAllAddons,
  selectAllCollections,
  selectShopCode,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";

import { prepareProductActivity } from "./ui-config";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";

import { fonts, colors, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";

const ProductActivity = () => {
  const shopCode = useSelector(selectShopCode);
  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile } = React.useContext(DeviceContext);
  const { userProfile } = React.useContext(UserProfileContext);
  const activityData = useSelector(selectProductActivity);
  const allAddons = useSelector(selectAllAddons);
  const allCollections = useSelector(selectAllCollections);

  const currency = Currency(shopCode);
  const finalData = prepareProductActivity(
    activityData,
    Localise,
    messages,
    userProfile,
    allAddons,
    allCollections,
    currency
  );

  const boldify = (str) => {
    const parts = str.split(/('.*?')/g);
    return (
      <Text>
        {parts.map((part, index) => {
          if (part.startsWith("'") && part.endsWith("'")) {
            return (
              <Text key={index} style={{ fontWeight: "bold" }}>
                {part.substring(1, part.length - 1)}
              </Text>
            );
          } else {
            return <Text key={index}>{part}</Text>;
          }
        })}
      </Text>
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        style={{
          padding: 10,
          maxHeight: 300,
          flexGrow: 0,
        }}
        {...(Platform.OS === "android" && {
          nestedScrollEnabled: true,
        })}
        scrollEnabled={true}
      >
        <View
          style={[
            tw(`relative flex flex-row flex-wrap`),
            { width: !isMobile ? "100%" : "70%" },
          ]}
        >
          <View
            style={[
              tw("absolute ml-3 top-0 h-full  "),
              {
                left: 5,
                borderColor: colors.grayScaleLight,
                borderRightWidth: 1,
              },
            ]}
          ></View>
          <View style={tw("mt-2")}>
            <View>
              {finalData.map((items, index) => {
                return (
                  <View key={`${index}+1`}>
                    <View style={tw("ml-10 mb-5")}>
                      <Text style={[{ ...fonts.heading4 }]}>{items.date}</Text>
                    </View>
                    {items.description.map((val, index) => {
                      return (
                        <View
                          style={tw("flex flex-row items-center mb-5")}
                          key={`${index}+2`}
                        >
                          <View
                            style={[
                              tw("w-3 h-3 rounded-full border ml-3"),
                              {
                                backgroundColor: backgroundColors.navBar,
                                borderColor: colors.primary2,
                              },
                            ]}
                          ></View>
                          <Text style={[{ ...fonts.default }, tw("ml-3")]}>
                            {boldify(val)}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                );
              })}
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default ProductActivity;
