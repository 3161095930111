import React, { useContext } from "react";
import { TouchableOpacity, Image } from "react-native";
import I18NContext from "library/contexts/i18N";
import IMAGES from "static/assets/images";
import { Tooltip } from "components/elements";
import { DeviceContext } from "library/contexts/appSettings";

const OpenAdditionalInfoArea = ({ setSideCarOpen, sideCarKey }) => {
  const { isDesktop } = useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);

  return (
    <TouchableOpacity
      onPress={() => {
        setSideCarOpen(
          (isDesktop && sideCarKey === "additional_info") ||
            (!isDesktop && sideCarKey)
            ? ""
            : "additional_info"
        );
      }}
      key={`AdditionalInfoArea`}
      testID="additional_info_area"
      accessibilityLabel="additional_info_area"
    >
      <Tooltip
        text={Localise(
          messages,
          (isDesktop &&
            ["additional_info", "customer_insights"].includes(sideCarKey)) ||
            (!isDesktop && sideCarKey)
            ? "Collapse"
            : "Expand"
        )}
        renderForWebOnly={true}
      >
        <Image
          style={{ width: 29, height: 27 }}
          resizeMode="cover"
          source={
            IMAGES[
              (isDesktop &&
                ["additional_info", "customer_insights"].includes(
                  sideCarKey
                )) ||
              (!isDesktop && sideCarKey)
                ? "back_left_nav_arrow"
                : "back_right_nav_arrow"
            ]
          }
        />
      </Tooltip>
    </TouchableOpacity>
  );
};

export default React.memo(OpenAdditionalInfoArea);
