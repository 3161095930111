import React, { useEffect, useContext } from "react";
import { View, ActivityIndicator, Platform } from "react-native";
import { Divider as RNDivider, Text } from "react-native-elements";
import { useSelector } from "react-redux";

import tw from "tailwind-rn";
import isEmpty from "lodash/isEmpty";

import { AccordionWithBorder, ToasterHandler } from "components/elements";
import { Form, FormFieldSwitch } from "components/elements/forms";
import { SaveCancelButtons } from "components/wrappers";
import { fonts, colors, theme } from "styles/theme";
import { request } from "library/utils/request";

import useStateIfMounted from "library/utils/useStateIfMounted";
import { DeviceContext } from "library/contexts/appSettings";
import UserAuthStorage from "library/storage/userAuth";
import UserProfileUtils from "library/utils/userProfile";
import UserProfileStorage from "library/storage/userProfile";
import I18NContext from "library/contexts/i18N";
import { selectShopCode } from "library/sagas/views/home/drawer/shop-settings/common/selector";

import {
  getUIConfig,
  getInitialValues,
  getValidationSchema,
  keyMappings,
  credentialsFields,
} from "./config";

const IntegrationSettings = ({ shopSettings, saveSettings, loadingKey }) => {
  const shopCode = useSelector(selectShopCode);
  const { messages, Localise } = useContext(I18NContext);
  const { isDesktop } = useContext(DeviceContext);
  const isSmallScreen = !isDesktop;

  const [integrationSettings, setIntegrationSettings] = useStateIfMounted({});

  const validationSchema = getValidationSchema(Localise, messages);
  const initialValues = getInitialValues(shopSettings);

  const onSubmit = async (values, formikBag, integrationkey) => {
    const formInitialVals = initialValues[integrationkey];
    let needCredentialsCheck = false;
    credentialsFields[integrationkey].map((each) => {
      if (formInitialVals[each] !== values[each]) needCredentialsCheck = true;
    });
    let validateCredentialsResult = "";

    if (needCredentialsCheck) {
      if (integrationkey === "telOrders") {
        validateCredentialsResult = await validateTelCredentials(
          values,
          formikBag
        );
      } else {
        validateCredentialsResult = await validateWOICredentials(
          values,
          formikBag
        );
      }
    }

    if (
      (needCredentialsCheck && validateCredentialsResult === "Success") ||
      !needCredentialsCheck
    ) {
      const preferences = Object.entries(values)
        .map((entry) => {
          if (entry.length && entry[1] !== "") {
            return {
              id: keyMappings[integrationkey][entry[0]],
              values: [entry[1].toString() || ""],
            };
          }
        })
        .filter(Boolean);

      saveSettings({ preferences }, true, false, () =>
        formikBag.setSubmitting(false)
      );
    }
  };

  useEffect(() => {
    if (isEmpty(shopSettings)) return;

    setIntegrationSettings({
      bloomlink_enabled: shopSettings["bloomlink_enabled"],
    });
  }, [shopSettings]);

  const validateTelCredentials = async (values, formikBag) => {
    const { telUserName = "", telPassword = "" } = values;
    if (telUserName !== "" && telPassword !== "") {
      return await request("validate-teleflora-credentials", {
        user: telUserName,
        password: telPassword,
      })
        .then(() => {
          return "Success";
        })
        .catch((error) => {
          if (error === "INVALID_CREDENTIALS") {
            formikBag.setFieldError("telUserName", "  ");
            formikBag.setFieldError("telPassword", "  ");
            formikBag.setSubmitting(false);
          } else {
            ToasterHandler(
              "uh oh",
              Localise(messages, "Please try saving your settings again")
            );
          }
          return "Error";
        });
    }
  };

  const validateWOICredentials = async (values, formikBag) => {
    const {
      mailServer = "",
      email = "",
      port = "",
      password = "",
      protocolType = "",
    } = values;
    return await request("validate-WOI-credentials", {
      hostName: mailServer,
      port,
      protocol: protocolType,
      emailAddress: email,
      password,
    })
      .then(() => {
        return "Success";
      })
      .catch((error) => {
        if (error === "INVALID_CREDENTIALS") {
          formikBag.setFieldError("mailServer", "  ");
          formikBag.setFieldError("email", "  ");
          formikBag.setFieldError("password", "  ");
          formikBag.setSubmitting(false);
        } else {
          ToasterHandler(
            "uh oh",
            Localise(messages, "Please try saving your settings again")
          );
        }
        return "Error";
      });
  };

  const UIConfig = getUIConfig();

  return (
    <View>
      <View style={[tw("flex flex-row items-center")]}>
        <View
          style={{
            paddingRight: isSmallScreen ? 10 : 20,
            width: isSmallScreen ? 150 : 200,
          }}
        >
          <Text style={fonts.heading5}>
            {Localise(messages, "Auto-Import Bloomnet Orders")}
          </Text>
        </View>
        <FormFieldSwitch
          value={integrationSettings["bloomlink_enabled"] === "true"}
          onValueChange={(val) => {
            let payload = {
              preferences: [
                {
                  id: "bloomlink_enabled",
                  values: [val ? "true" : "false"],
                },
              ],
            };

            saveSettings(payload, true, false, () => {
              if (
                Platform.OS === "web" &&
                document.getElementById("isElectronApp").value === "true"
              ) {
                let bloomlink_enabled = "false";
                let bln_enabled_member_code = "";

                if (val) {
                  bln_enabled_member_code = shopCode;
                  bloomlink_enabled = "true";
                } else {
                  const allShopsPreferences =
                    UserProfileStorage.getAllShopPreferences();

                  const preferences = Object.keys(allShopsPreferences);

                  const blnEnabledMemberCode = preferences.filter(
                    (shopCode) =>
                      allShopsPreferences[shopCode]?.bloomlink_enabled ===
                        "true" && shopCode
                  );

                  if (blnEnabledMemberCode.length > 0) {
                    bln_enabled_member_code = blnEnabledMemberCode[0];
                    bloomlink_enabled = "true";
                  }
                }

                document.getElementById("blnOrderPrefs").value = JSON.stringify(
                  {
                    bloomlink_enabled,
                    bln_enabled_member_code,
                    accessToken: UserAuthStorage.getAccessToken(),
                    request_context: UserProfileUtils.getRequestContext(),
                  }
                );
              }
            });
          }}
          testID="bloomlink_enabled"
        />
        {loadingKey === "bloomlink_enabled" && (
          <View style={{ paddingHorizontal: 10 }}>
            <ActivityIndicator
              color={colors.activityIndicator}
              testID={"loader"}
            />
          </View>
        )}
      </View>

      <RNDivider style={tw("my-4")} />

      {Object.keys(UIConfig).map((key, id) => {
        const { title, fields } = UIConfig[key];
        return (
          <AccordionWithBorder title={title} key={id}>
            <Form
              initialValues={initialValues[key]}
              onSubmit={(values, formikBag) => onSubmit(values, formikBag, key)}
              validationSchema={validationSchema[key]}
              render={({ values, setFieldValue, errors }) => {
                return (
                  <>
                    {fields.map((field, index) => {
                      const { field: Comp, title, props } = field;
                      let toggleProps = {};
                      if (props.name === "isEnabled") {
                        toggleProps.onValueChange = () => {
                          setFieldValue(props.name, !values[props.name]);
                        };
                        toggleProps.value = values[props.name];
                      }
                      return (
                        <View
                          style={[
                            tw(
                              `flex flex-row items-center mb-${
                                props.name === "isEnabled" ? 4 : 2
                              }`
                            ),
                          ]}
                          key={index}
                        >
                          <View
                            style={{
                              width: isSmallScreen ? 150 : 200,
                              ...(props.name !== "isEnabled" && {
                                paddingLeft: isSmallScreen ? 10 : 20,
                              }),
                            }}
                          >
                            <Text style={fonts.heading5}>
                              {Localise(messages, title)}
                            </Text>
                          </View>
                          {Comp && (
                            <Comp
                              {...props}
                              {...toggleProps}
                              containerStyle={{
                                width: 250,
                                maxWidth: "50%",
                                alignSelf: "center",
                              }}
                            />
                          )}
                        </View>
                      );
                    })}
                    {!isEmpty(errors) && (
                      <Text
                        style={[
                          theme.SubmitButton.errorStyle,
                          tw("ml-2 mt-2 text-right"),
                        ]}
                      >
                        {Localise(
                          messages,
                          "Incorrect credentials, please try again."
                        )}
                      </Text>
                    )}
                    <SaveCancelButtons
                      buttonTitle={Localise(messages, "Save")}
                    />
                  </>
                );
              }}
            />
          </AccordionWithBorder>
        );
      })}
    </View>
  );
};

export default IntegrationSettings;
