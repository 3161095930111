import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text, View, Image } from "react-native";
import { Button } from "react-native-elements";

import { Form } from "components/elements/forms";
import { ToasterHandler, Spinner } from "components/elements";
import { Section } from "components/wrappers";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import { selectCutoffExceptions } from "library/sagas/views/home/drawer/shop-settings/delivery-cutoff-times/selector";
import {
  saveCutoffExceptions,
  deleteCutoffExceptions,
} from "library/sagas/views/home/drawer/shop-settings/delivery-cutoff-times/slice";

import IMAGES from "static/assets/images";
import { theme, colors } from "styles/theme";
import tw from "tailwind-rn";

import { newException } from "./helper";
import { RulesMapping } from "../ui-config";
import { getExceptionValidationSchema } from "../yup";
import ExceptionInput from "./input";

const CutoffExceptions = () => {
  const dispatch = useDispatch();
  const allExceptions = useSelector(selectCutoffExceptions);
  const { isMobile } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const [newExceptionData, setNewExceptionData] = useState([]);

  if (!allExceptions) {
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );
  }

  const criteriaChangeHandler = (val, setFieldValue) => {
    setFieldValue(
      "cityZipInfo",
      val === "CITY_ZIP_CODE"
        ? {
            city: "",
            zips: [],
          }
        : {}
    );
  };

  const addNewExceptionHandler = () => {
    setNewExceptionData(newException);
  };

  const deleteExceptionHandler = (i, values) => {
    if (values.exceptionId) {
      dispatch(
        deleteCutoffExceptions({
          params: {
            exceptionId: values.exceptionId,
            exceptionType: RulesMapping[values.rule],
          },
          resolve: () => {
            ToasterHandler(
              "success",
              `${Localise(messages, "Cutoff Exception")} (${
                values.label
              }) ${Localise(messages, "has been deleted")}.`
            );
          },
          reject: () => {
            ToasterHandler(
              "error",
              Localise(
                messages,
                "We were unable to process your request, please try again."
              )
            );
          },
        })
      );
    } else {
      setNewExceptionData([]);
    }
  };

  const onSubmit = (values, formikBag) => {
    dispatch(
      saveCutoffExceptions({
        params: values,
        resolve: () => {
          ToasterHandler(
            "success",
            Localise(messages, "Cutoff Exception Settings have been updated")
          );
          setNewExceptionData([]);
          formikBag.setSubmitting(false);
        },
        reject: () => {
          ToasterHandler(
            "error",
            Localise(
              messages,
              "Unable to create cutoff exception, might be a duplicate or invalid."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  };

  const ExceptionForm = ({ exceptionProps }) =>
    exceptionProps?.map((exception, i) => {
      const title = exception.exceptionId
        ? exception.label
        : Localise(messages, "New Exception");
      const subtitle = exception.status
        ? Localise(messages, "Active")
        : Localise(messages, "Inactive");

      return (
        <Section
          defaultOpen={!exception.exceptionId}
          title={title}
          key={exception.exceptionId || i}
          headerContent={
            subtitle &&
            ((openAccordion) => (
              <View
                style={[
                  tw("flex flex-row items-center justify-between w-full"),
                ]}
              >
                <View style={[tw("flex flex-row items-center")]}>
                  <Text
                    style={{
                      ...theme.Accordion.titleStyle,
                      color: colors.primary,
                      paddingLeft: 10,
                    }}
                  >
                    {Localise(messages, title)}{" "}
                  </Text>
                  <Text style={[tw("ml-4")]}>
                    {Localise(messages, subtitle)}
                  </Text>
                </View>
                <Image
                  style={{ width: 20, height: 20 }}
                  containerStyle={{ marginLeft: "auto" }}
                  source={
                    IMAGES[
                      openAccordion ? "expanded-section" : "collapsed-section"
                    ]
                  }
                />
              </View>
            ))
          }
        >
          <Form
            initialValues={exception}
            onSubmit={onSubmit}
            validationSchema={getExceptionValidationSchema(
              messages,
              Localise,
              allExceptions
            )}
            render={() => {
              return (
                <ExceptionInput
                  criteriaChangeHandler={criteriaChangeHandler}
                  deleteExceptionHandler={deleteExceptionHandler}
                  i={i}
                />
              );
            }}
          />
        </Section>
      );
    });

  return (
    <>
      <View>
        <View>
          <Text
            style={{
              ...theme.Accordion.titleStyle,
              color: colors.primary,
              paddingLeft: 10,
              paddingVertical: 20,
            }}
          >
            {Localise(messages, "Delivery Cutoff Exceptions")}
          </Text>
        </View>
        {!!allExceptions.length && (
          <ExceptionForm exceptionProps={allExceptions} />
        )}
        {!!newExceptionData.length && (
          <ExceptionForm exceptionProps={newExceptionData} />
        )}
        {!newExceptionData.length && (
          <View style={tw(`justify-between flex-${isMobile ? "col" : "row"}`)}>
            <Button
              testID={"new_exception"}
              accessibilityLabel="new_exception"
              titleStyle={{
                ...theme.Button.secondaryTitleStyle,
              }}
              buttonStyle={{
                ...theme.Button.secondaryButtonStyle,
              }}
              containerStyle={{ marginLeft: 0 }}
              title={Localise(messages, "Add Cutoff Exception")}
              onPress={() => addNewExceptionHandler()}
            />
          </View>
        )}
      </View>
    </>
  );
};

export default CutoffExceptions;
