import React, { useContext } from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import { useFormikContext } from "formik";
import { colors } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import { generateDetailContent } from "../helper";
import { phoneNumberFormatter } from "library/utils/formatter";

const CustomerProfileInfo = ({ doNotFill }) => {
  const { messages, Localise } = useContext(I18NContext);
  const { values } = useFormikContext();
  const {
    customerType,
    firstName,
    lastName,
    phone,
    email,
    businessName,
    customerContacts = [],
  } = values.customerInfo;
  const customerName =
    customerType === "Business" ? businessName : `${firstName} ${lastName}`;

  const selectedContact = customerContacts?.find(
    (contact) => contact.isSelected
  );

  const content = {
    Name: { label: "", value: customerName },
    ...(selectedContact
      ? {
          ContactName: {
            label: "",
            value: `${selectedContact.firstName}${
              selectedContact.lastName ? ` ${selectedContact.lastName}` : ""
            }`,
          },
        }
      : {}),
    Phone: {
      label: "",
      value: phoneNumberFormatter(selectedContact?.phone || phone) || "",
    },
    Email: { label: "", value: selectedContact?.email || email || "" },
  };

  return (
    <View
      style={{
        zIndex: 1,
        paddingBottom: 15,
        // opacity: doNotFill ? 0.4 : 1,
      }}
      pointerEvents={doNotFill ? "none" : "auto"}
    >
      <View>
        <Text
          style={{
            fontSize: 15,
            color: colors.primary,
            fontWeight: "bold",
            paddingVertical: 5,
          }}
        >
          {Localise(messages, "Customer")}
        </Text>
        {generateDetailContent(content, true)}
      </View>
    </View>
  );
};

export default CustomerProfileInfo;
