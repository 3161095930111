import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectOrderModifying = createSelector(
  selectState,
  ({ isOrderModifying }) => isOrderModifying
);

export const selectOrderLocked = createSelector(
  selectState,
  ({ isOrderLocked }) => isOrderLocked
);

export const selectRecordData = createSelector(
  selectState,
  ({ recordData }) => recordData
);

export const selectAddressVerificationStatus = createSelector(
  selectState,
  ({ addressVerificationStatus }) => addressVerificationStatus
);

export const selectLoadingOnDSrequested = createSelector(
  selectState,
  ({ loadingOnDSrequested }) => loadingOnDSrequested
);

export const selectOrdersToHighlight = createSelector(
  selectState,
  ({ ordersToHighlight }) => ordersToHighlight
);

export const selectAutoPrintOrdersInfo = createSelector(
  selectState,
  ({ autoPrintOrdersInfo }) => autoPrintOrdersInfo
);

export const selectOrderDetailTabs = createSelector(
  selectState,
  ({ orderDetailTabs }) => orderDetailTabs
);

export const selectActiveTab = createSelector(
  selectState,
  ({ activeTab }) => activeTab || ""
);

export const selectDSEligibilityFailureMessage = createSelector(
  selectState,
  ({ dsEligibilityFailureMessage }) => dsEligibilityFailureMessage || ""
);

export const selectIsDesignersAreaEnabled = createSelector(
  selectState,
  ({ isDesignersAreaEnabled }) => isDesignersAreaEnabled
);

export const selectData = createSelector(selectState, ({ data }) => data);

export const selectRecordById = (id) =>
  createSelector(selectData, (data) => {
    return Object.keys(data)
      .map((eachCatagory) => {
        if (!["assignedOrders", "unassignedOrders"].includes(eachCatagory)) {
          const { orders = [] } = data[eachCatagory] || {};
          const order = orders.find((order) => order.orderItemId === id);
          if (order) return order;
        }
      })
      .filter((val) => val !== undefined);
  });

export const selectDconFormType = createSelector(
  selectState,
  ({ dconStatus }) => dconStatus
);

export const selectOnDemandInvoice = createSelector(
  selectState,
  ({ onDemandInvoiceFields }) => onDemandInvoiceFields
);

export const selectOrderItemId = createSelector(
  selectState,
  ({ recordId }) => recordId
);

export const selectActualDeliveryMethod = createSelector(
  selectState,
  ({ deliveryMethod }) => deliveryMethod
);

export const selectedShopCodes = createSelector(
  selectState,
  ({ actions }) => actions.selectedShops
);

export const selectedActions = createSelector(
  selectState,
  ({ actions }) => actions
);

export const selectedLoader = createSelector(
  selectState,
  ({ loading }) => loading
);
