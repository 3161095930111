import React from "react";
import { Platform } from "react-native";
import FeesInputFields from "./input/fees";
import CoverageInputFields from "./input/coverage";
import DeliveryExceptions from "./delivery-exception";
import DeliveryMap from "./delivery-exception/zones-map";

const FeesCoverage = ({ accordionStatus = false }) => {
  if (!accordionStatus) return null;
  return (
    <>
      {Platform.OS === "web" && <DeliveryMap />}
      <FeesInputFields />
      <CoverageInputFields />
      <DeliveryExceptions />
    </>
  );
};

export default FeesCoverage;
