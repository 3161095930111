export const getInitialValues = (details) => {
  const initalValues = {
    referenceNumber: undefined,
    shopField: undefined,
    shopCode: undefined,
    shopName: undefined,
    floristTimeZone: undefined,
    injectTimings: [],
    timeWindow: [],
    pickupUpdatedTime: {},
    distanceThreshold: 15,
    //productDetails
    items: [
      {
        itemDescription: undefined,
        NoOfItems: "1",
        totalItemValue: undefined,
      },
    ],
    //deliveryDetails
    deliveryDetails: {
      assignment: "deliveryService",
      deliveryDate: undefined,
      pickUpDateTime: undefined,
      deliveryTime: {
        start: undefined,
        end: undefined,
      },
      undeliverableAction: undefined,
      specialDeliveryInstructions: undefined,
    },
    //recipientDetails
    recipientDetails: {
      firstName: undefined,
      lastName: undefined,
      phone: "",
      addressLine1: undefined,
      addressLine2: undefined,
      country: "US",
      city: undefined,
      state: undefined,
      zipcode: undefined,
      locationType: undefined,
      locationName: undefined,
      geoLocation: {
        latitude: undefined,
        longitude: undefined,
      },
    },
  };
  return {
    ...initalValues,
    ...details,
  };
};

export const DistanceLimitMapping = {
  //miles
  default: 15,
  roadie: 15,
  doordash: 15,
  "vida-doordash": 15,
  vida: 15,
  burq: 15,
};

export const DSJourneyMessages = {
  REDELIVERY_FOR: "Redelivery request created",
  REDELIVERY: "New redelivery request created",
  REROUTED:
    "Rerouted to another provider. New Delivery service request created",
  REROUTED_FOR: "Rerouted by FTD Delivery Service. Waiting to assign a driver.",
  REROUTE_CANCEL: "Rerouting canceled.", // user cancel when reroutInProgress
  REROUTE_FAIL: "Canceled by Delivery Service",
  COURIER_ASSIGNED: "Driver Assigned",
  COURIER_UNASSIGNED: "Driver Unassigned",
  PICKUP_IN_PROGRESS: "Driver Pick Up in Progress",
  // APPROACHING_PICKUP: "Driver is near the pickup location",  // out of scope
  AT_PICKUP: "Driver Arrived at Pick Up Location",
  PICKUP_COMPLETE: "Driver Picked Up Delivery",
  DELIVERY_IN_PROGRESS: "Delivery in Progress",
  // APPROACHING_DELIVERY: "Driver is near the delivery location",  // out of scope
  AT_DELIVERY: "Driver Reached Delivery Location",
  DELIVERED: "Successfully Delivered by Delivery Service",
  ATTEMPTED_DELIVERY: "Attempted Delivery",
  ENROUTE_TO_RETURN: "Driver Returning Order to Pick Up Location",
  ARRIVED_AT_RETURN: "Driver Arrived at Pick Up Location",
  RETURNED: "Returned by Delivery Service",
  REJECTED: "Request rejected",
};
