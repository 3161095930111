import React from "react";
import { TouchableOpacity, View } from "react-native";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";

import { fonts } from "styles/theme";
import { ToasterHandler } from "components/elements";
import { request } from "library/utils/request";
import I18NContext from "library/contexts/i18N";
import UserAuthContext from "library/contexts/userAuth";

const DownloadLinks = ({ subTitle, fileName = "", needEncoding }) => {
  const { authToken } = React.useContext(UserAuthContext);
  const { messages, Localise } = React.useContext(I18NContext);

  if (!authToken) return null; //disable for logged-out and mobile apps

  const downloadPDF = (fileName, needEncoding = false) => {
    request("download-image", {
      imageName: needEncoding ? encodeURIComponent(fileName) : fileName,
    })
      .then((res) => {
        var tempAnchorTag = document.createElement("a"); // creating temp anchor tag

        tempAnchorTag.href = "data:application/pdf;base64," + res.source;

        tempAnchorTag.download = fileName;

        tempAnchorTag.click();
      })

      .catch((err) => {
        ToasterHandler(
          "error",

          "Failed to download the file, please try again later."
        );
      });
  };

  return (
    <>
      <View style={tw("pt-4")}>
        {/* <View style={tw("flex flex-row")}>
          <Text
            style={{
              ...fonts.heading2,
              ...tw("pt-2 pb-2"),
            }}
          >
            {Localise(messages, config.title)}
          </Text>

          {loading && (
            <ActivityIndicator
              color={colors.activityIndicator}
              style={tw("ml-2")}
            />
          )}
        </View> */}

        {/* {config && config.desc && (
          <View>
            <Text
              style={{
                ...tw("pb-2"),

                fontStyle: "italic",
              }}
            >
              {Localise(messages, config.desc)}
            </Text>
          </View>
        )} */}

        {/* {Object.keys(config.data).map((key) => {
          const { title, fileName, needEncoding = false } = config.data[key];

          return (
            <View key={key}>
              <View style={tw("flex flex-row items-center pt-1 pb-1")}>
                <TouchableOpacity
                  onPress={() => {
                    downloadPDF(fileName, needEncoding);
                  }}
                >
                  <Text style={[fonts.link1]}>{Localise(messages, title)}</Text>
                </TouchableOpacity>
              </View>
            </View>
          );
        })} */}

        <View>
          <View style={tw("flex flex-row items-center pt-1 pb-1")}>
            <TouchableOpacity
              onPress={() => {
                downloadPDF(fileName, needEncoding);
              }}
            >
              <Text style={[fonts.link1]}>{Localise(messages, subTitle)}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </>
  );
};

export default DownloadLinks;
