import React from "react";
import { Divider } from "react-native-elements";
import { View } from "react-native";
import { useSelector } from "react-redux";

import tw from "tailwind-rn";
import last from "lodash/last";
import filter from "lodash/filter";

import { backgroundColors } from "styles/theme";

import { selectRecordData } from "library/sagas/ongoing/current-orders/selector";

import useStateIfMounted from "library/utils/useStateIfMounted";
import ReasonForm from "./reason-form";
import AdjustmentForm from "./adjustment-form";
import CancelDSModal from "./cancelDSModal";
import { request } from "library/utils/request";

const FormContainer = React.memo(
  ({
    index,
    triggerRequest,
    actionCallback,
    sourceMemberCode,
    setMenuAction,
    selectedAction,
    setSelectedAction,
    isFormActions,
    showPayment,
  }) => {
    const orderDetailResponse = useSelector(selectRecordData);

    const { orderAmounts = [], paymentDetails = {} } = orderDetailResponse;

    const totalRefundDetails = [];
    paymentDetails?.paymentMethod?.forEach((payment) => {
      const { paymentMethodDetails: { refundDetails = [] } = {} } = payment;
      totalRefundDetails.push(...refundDetails);
    });

    const orderDetails = {
      ...orderDetailResponse.orderItems[index],
      priceIncludingDelivery: orderDetailResponse.priceIncludingDelivery || 0,
      orderAmounts: orderDetailResponse.orderAmounts,
    };

    const {
      messages,
      deliveryInfo: { deliveryMethod = "" },
      hasDSFulfillmentError = false,
      paymentItemStatus = "",
    } = orderDetails;

    const [showReasonForm, setShowReasonForm] = useStateIfMounted(true);
    const [isCancelDSModal, setShowCancelDSModal] = useStateIfMounted(false);

    const relatedMessages = filter(
      messages,
      (message) => message.messageType === "Cancel"
    );
    const latestMessage = last(relatedMessages);
    const { id: askId } = latestMessage || {};

    const cancelApprovalInfo = { askId };

    const onActionSubmit = (reqObj) => {
      request("order-actions", {
        recordId: orderDetails.orderItemId,
        deliveryMethod,
        action: selectedAction,
        sourceMemberCode: sourceMemberCode,
        ...reqObj,
        ...(selectedAction === "cancel-deny" && {
          askId: cancelApprovalInfo.askId,
        }),
      })
        .then((res) => {
          setSelectedAction("");

          /**
           * Setting menu action as empty in order to hide Confirm DCON Form, Reject and Cancel Deny forms after submission.
           */
          if (isFormActions) setMenuAction("");

          if (selectedAction === "reject" && !!hasDSFulfillmentError) {
            triggerRequest({
              requestType: "reject",
            });
          }
          actionCallback(selectedAction, orderDetails.orderItemId);
        })
        .catch((err) => {
          console.log("error log", err);
        });
    };

    return (
      <View
        style={{
          ...tw("flex"),
          backgroundColor: backgroundColors.secondary,
        }}
      >
        <View>
          {["cancel", "reject", "cancel-deny", "adjustment"].includes(
            selectedAction
          ) &&
            showReasonForm && <Divider style={tw("my-3")} />}

          {(selectedAction === "cancel" ||
            selectedAction === "reject" ||
            selectedAction === "cancel-deny") && (
            <>
              {selectedAction === "reject" && (
                <CancelDSModal
                  isOrderReject={true}
                  isCancelDSModal={isCancelDSModal}
                  setShowCancelDSModal={setShowCancelDSModal}
                  setShowReasonForm={setShowReasonForm}
                  setSelectedAction={setSelectedAction}
                />
              )}

              {selectedAction === "cancel" && (
                <CancelDSModal
                  isOrderCancel={true}
                  isCancelDSModal={isCancelDSModal}
                  setShowCancelDSModal={setShowCancelDSModal}
                  setShowReasonForm={setShowReasonForm}
                  setSelectedAction={setSelectedAction}
                />
              )}

              {showReasonForm && (
                <ReasonForm
                  orderDetails={orderDetails}
                  selectedAction={selectedAction}
                  triggerRequest={triggerRequest}
                  onActionSubmit={onActionSubmit}
                  setSelectedAction={setSelectedAction}
                  refundDetails={totalRefundDetails}
                  orderAmounts={orderAmounts}
                  showPayment={showPayment}
                  paymentItemStatus={paymentItemStatus}
                />
              )}
            </>
          )}
          {selectedAction === "adjustment" && (
            <AdjustmentForm
              orderDetails={orderDetails}
              selectedAction={selectedAction}
              triggerRequest={triggerRequest}
              onActionSubmit={onActionSubmit}
              setSelectedAction={setSelectedAction}
            />
          )}
        </View>
      </View>
    );
  }
);

export default FormContainer;
