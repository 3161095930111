import React, { useEffect } from "react";
import {
  View,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Form, FormFieldDatePicker } from "components/elements/forms";
import * as Yup from "yup";
import { Button, Text, Image } from "react-native-elements";
import { request } from "library/utils/request";
import tw from "tailwind-rn";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import map from "lodash/map";
import IMAGES from "static/assets/images";
import styles from "../styles";
import { colors, fonts } from "styles/theme";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { ToasterHandler } from "components/elements";
import I18NContext from "library/contexts/i18N";
import { SaveCancelButtons } from "components/wrappers";

const MemberSuspends = ({
  isMobile,
  index = 0,
  operator,
  settings,
  setRefreshSuspends,
  shopCode,
  suspendProcessing,
  setSuspendProcessing,
  isGoToFlorist,
  GoToSuspendProcessing,
  setRefreshGoToSuspends,
  setGoToSuspendProcessing,
  setFloristTimeStamp,
  setGotoTimeStamp,
  showF2FError,
  showGOTOError,
  SetShowF2FError,
  SetShowGOTOError,
  floristSuspendTime,
  gotoSuspendTime,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);

  const [editSettings, setEditSettings] = useStateIfMounted(false);
  const [resumeLoading, setResumeLoading] = useStateIfMounted(false);
  const [FTDSettings, setFTDSettings] = useStateIfMounted(false);
  const [isGOTOAction, setGOTOAction] = useStateIfMounted(false);
  const {
    type = "F2F",
    suspendStartDate: suspendStartDateUTC,
    suspendEndDate: suspendEndDateUTC,
    partnersSubscribed = [],
  } = settings;
  const currentTimeZone = moment.tz(moment.tz.guess()).format("z");
  const suspendStartDateObj = suspendStartDateUTC
    ? moment(suspendStartDateUTC)
    : "";
  const suspendEndDateObj = suspendEndDateUTC ? moment(suspendEndDateUTC) : "";
  const isSettingTypeGOTO = type === "GOTO";

  const isDateInBetween =
    suspendStartDateObj && suspendEndDateObj
      ? moment().isSameOrAfter(suspendStartDateObj) &&
        moment().isSameOrBefore(suspendEndDateObj)
      : false;

  const status =
    isDateInBetween && !!suspendStartDateUTC ? "Suspended" : "Active";

  const isPastEndDate =
    suspendEndDateObj && moment().isSameOrBefore(suspendEndDateObj)
      ? false
      : true;

  const isUpcomingSuspend = !!suspendStartDateObj && status === "Active";

  const isCurrentSuspend = !!suspendStartDateObj && status === "Suspended";

  useEffect(() => {
    let endCheckTimeOut;
    if (suspendEndDateObj) {
      endCheckTimeOut = setTimeout(() => {
        if (
          suspendEndDateObj.diff(moment(), "seconds") < 0 &&
          status === "Suspended"
        ) {
          setRefreshSuspends(true);
        }
      }, parseInt(suspendEndDateObj.diff(moment(), "seconds") + 2) * 1000);
    }

    return () => {
      clearTimeout(endCheckTimeOut);
    };
  }, [suspendEndDateObj]);

  Yup.addMethod(Yup.string, "pastStartDateTime", function (errorMessage) {
    return this.test(`past-start-datetime`, errorMessage, function (value) {
      const { path, createError } = this;
      const { suspendStartDate, suspendStartDateTime } = this.parent;

      const startDateTime = `${moment(suspendStartDate).format("YYYY-MM-DD")}T${
        suspendStartDateTime.split("T")[1]
      }`;

      return (
        moment(startDateTime).isSameOrAfter(moment(), "minute") ||
        createError({ path, message: errorMessage })
      );
    });
  });

  Yup.addMethod(Yup.string, "invalidEndDateTime", function (errorMessage) {
    return this.test(`invalid-end-datetime`, errorMessage, function (value) {
      const { path, createError } = this;
      const {
        suspendStartDate,
        suspendEndDate,
        suspendStartDateTime,
        suspendEndDateTime,
      } = this.parent;

      const startDateTime = `${moment(suspendStartDate).format("YYYY-MM-DD")}T${
        suspendStartDateTime.split("T")[1]
      }`;
      const endDateTime = `${moment(suspendEndDate).format("YYYY-MM-DD")}T${
        suspendEndDateTime.split("T")[1]
      }`;
      return (
        moment(endDateTime).isAfter(moment(startDateTime)) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  const validationSchema = Yup.object().shape({
    suspendStartDate: Yup.date()
      .label("suspendStartDate")
      .required(Localise(messages, "Enter Start Date")),
    suspendEndDate: Yup.date()
      .label("suspendEndDate")
      .required(Localise(messages, "Enter End Date")),
    suspendStartDateTime: Yup.string().required(
      Localise(messages, "Enter Start time")
    ),
    suspendEndDateTime: Yup.string().required(
      Localise(messages, "Please add end time")
    ),
    form: Yup.string()
      .pastStartDateTime(
        Localise(
          messages,
          "Suspend start time needs to start after current time"
        )
      )
      .invalidEndDateTime(
        Localise(
          messages,
          "The end time must be at least one minute after the start time."
        )
      ),
  });

  const submitSettings = (values, formikBag) => {
    const {
      suspendStartDate,
      suspendStartDateTime,
      suspendEndDate,
      suspendEndDateTime,
    } = values;

    const startDateTime = `${suspendStartDate.split("T")[0]}T${
      suspendStartDateTime.split("T")[1]
    }`;
    const endDateTime = `${suspendEndDate.split("T")[0]}T${
      suspendEndDateTime.split("T")[1]
    }`;

    const suspendMemberObj = {
      memberCode: shopCode,
      operator,
      suspendStartDate: moment(startDateTime).toISOString(),
      suspendEndDate: moment(endDateTime).toISOString(),
    };

    request("suspend-member", {
      suspendMemberObj,
      sourceMemberCode: shopCode,
    })
      .then((response) => {
        // Removing the errors & adding the current timestamp
        SetShowGOTOError(false);
        SetShowF2FError(false);
        setFloristTimeStamp(moment());

        if (response) {
          formikBag.setSubmitting(false);
          formikBag.resetForm();
          setEditSettings((editSettings) => !editSettings);
          setRefreshSuspends(true);
          setSuspendProcessing(true);
          setFTDSettings(false);
        } else {
          formikBag.setSubmitting(false);
          setEditSettings((editSettings) => !editSettings);
          setRefreshSuspends(true);
          setFTDSettings(false);
          ToasterHandler(
            "error",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
        }
      })
      .catch((err) => {
        if (err && typeof err !== "string") {
          err
            .then((errResponse) => {
              const errorKey = map(get(errResponse, "errors", []), "key");
              setRefreshSuspends(true);
              ToasterHandler(
                "uh oh",
                `${
                  errorKey.includes("INVALID_MEMBER_CODE")
                    ? Localise(messages, "That member code isn’t in our system")
                    : errorKey.includes("INVALID_SUSPEND_START_DATE")
                    ? Localise(
                        messages,
                        "The date & time you’ve selected is in the past – please select a different time"
                      )
                    : errorKey.includes("INVALID_SUSPEND_END_DATE")
                    ? Localise(
                        messages,
                        " Please make sure your dates are in the right order"
                      )
                    : Localise(
                        messages,
                        "Something isn’t quite right - please try that again"
                      )
                }`
              );
            })
            .catch(() => {
              setEditSettings((editSettings) => !editSettings);
              setRefreshSuspends(true);
              ToasterHandler(
                "error",
                Localise(
                  messages,
                  "We were unable to process your request, please try again."
                )
              );
            });
        } else {
          setEditSettings((editSettings) => !editSettings);
          setRefreshSuspends(true);
          ToasterHandler(
            "error",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
        }
      });
  };

  const addGoToSuspend = () => {
    const suspendMemberObj = {
      memberCode: shopCode,
      operator,
      isGoTo: true,
    };
    request("suspend-member", {
      suspendMemberObj,
      sourceMemberCode: shopCode,
    })
      .then((response) => {
        // Removing the errors & adding the current timestamp
        SetShowGOTOError(false);
        SetShowF2FError(false);
        setGotoTimeStamp(moment());

        if (response) {
          setFTDSettings((FTDSettings) => !FTDSettings);
          setRefreshGoToSuspends(true);
          setGoToSuspendProcessing(true);
        } else {
          setFTDSettings((FTDSettings) => !FTDSettings);
          setRefreshGoToSuspends(true);
          ToasterHandler(
            "error",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
        }
      })
      .catch(() => {
        setFTDSettings((FTDSettings) => !FTDSettings);
        setRefreshGoToSuspends(true);
        ToasterHandler(
          "error",
          Localise(
            messages,
            "We were unable to process your request, please try again."
          )
        );
      });
  };

  const resumeSuspend = (isGoTo) => {
    setResumeLoading(true);
    request("resume-member", {
      memberCode: shopCode,
      operator,
      suspendEndDate: moment().toISOString(),
      isGoTo,
    })
      .then((response) => {
        // Removing the errors & adding the current timestamp
        SetShowGOTOError(false);
        SetShowF2FError(false);
        setGotoTimeStamp(moment());
        setFloristTimeStamp(moment());

        if (response) {
          setResumeLoading(false);
          if (isGoTo) {
            setFTDSettings((FTDSettings) => !FTDSettings);
            setRefreshGoToSuspends(true);
            setGoToSuspendProcessing(true);
          } else {
            setEditSettings((editSettings) => !editSettings);
            setFTDSettings(false);
            setRefreshSuspends(true);
            setSuspendProcessing(true);
          }
        } else {
          setResumeLoading(false);
          if (isGoTo) {
            setFTDSettings((FTDSettings) => !FTDSettings);
            setRefreshGoToSuspends(true);
          } else {
            setEditSettings((editSettings) => !editSettings);
            setFTDSettings(false);
            setRefreshSuspends(true);
          }
          ToasterHandler(
            "error",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
        }
      })
      .catch(() => {
        setResumeLoading(false);
        if (isGoTo) {
          setFTDSettings((FTDSettings) => !FTDSettings);
          setRefreshGoToSuspends(true);
        } else {
          setEditSettings((editSettings) => !editSettings);
          setRefreshSuspends(true);
        }
        ToasterHandler(
          "error",
          Localise(
            messages,
            "We were unable to process your request, please try again."
          )
        );
      });
  };
  const suspensionStartTime = moment().add(5, "minutes");
  const suspensionEndTime = suspensionStartTime.clone().add(24, "hours");

  return (
    <>
      {showF2FError && (
        <Text style={{ color: "red" }}>
          {Localise(
            messages,
            "System is taking too long to respond, please try again."
          )}
        </Text>
      )}
      <View style={[styles.recordContainer, { zIndex: 10 - index }]}>
        <View style={[styles.record, styles[status]]}>
          <View
            style={{ ...tw("flex flex-row") }}
            testID="edit"
            accessibilityLabel="edit"
          >
            <View style={tw("flex flex-row justify-between w-full")}>
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  maxWidth: "70%",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ ...fonts.heading4, width: 170 }}>
                  {Localise(messages, "Florist to Florist Network")}
                  {": "}
                </Text>
                <Text style={{ fontSize: 13 }}>
                  {Localise(messages, status)}
                </Text>
              </View>
              {!suspendProcessing && (
                <View>
                  {editSettings ? (
                    <TouchableOpacity
                      onPress={() =>
                        setEditSettings((editSettings) => !editSettings)
                      }
                    >
                      <Image
                        style={{
                          width: 20,
                          height: 20,
                        }}
                        resizeMode="cover"
                        source={IMAGES["close"]}
                      />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      testID="edit_suspension_schedule"
                      accessibilityLabel="edit_suspension_schedule"
                      onPress={() =>
                        setEditSettings((editSettings) => !editSettings)
                      }
                    >
                      <Image
                        style={{
                          width: 18,
                          height: 18,
                        }}
                        resizeMode="cover"
                        source={IMAGES["edit"]}
                      />
                    </TouchableOpacity>
                  )}
                </View>
              )}
            </View>
          </View>
          {suspendProcessing && (
            <View style={tw("py-4")}>
              <Text>
                {Localise(
                  messages,
                  "Please wait while we process your request. It may take up to"
                )}{" "}
                {floristSuspendTime} {Localise(messages, "minutes.")}
              </Text>
              <ActivityIndicator color={colors.activityIndicator} />
            </View>
          )}
          {!!suspendStartDateObj && !editSettings && !isPastEndDate && (
            <View style={tw("py-4")}>
              <Text style={tw("font-bold")}>
                {status === "Suspended"
                  ? Localise(messages, "Suspend Details")
                  : Localise(messages, "Upcoming Suspend Details")}
              </Text>
              <View style={tw("py-2")}>
                <Text style={tw("py-4")}>
                  {Localise(messages, "Start")}
                  {": "}
                  {moment(suspendStartDateObj)
                    .tz(moment.tz.guess())
                    .format("M/D/YY [at] h:mm A z")}
                </Text>
                <Text>
                  {Localise(messages, "End")}
                  {": "}
                  {moment(suspendEndDateObj)
                    .tz(moment.tz.guess())
                    .format("M/D/YY [at] h:mm A z")}
                </Text>
              </View>
            </View>
          )}
          {editSettings && (
            <View style={tw("pt-4")}>
              {isCurrentSuspend && (
                <View style={{ alignSelf: "flex-start", marginBottom: 10 }}>
                  <Button
                    accessibilityLabel="resume"
                    containerStyle={{ marginHorizontal: 0 }}
                    loading={!isGOTOAction ? resumeLoading : false}
                    onPress={() => {
                      setGOTOAction(false);
                      resumeSuspend(false);
                    }}
                    testID="resume"
                    title={Localise(messages, "Resume Now")}
                  />
                </View>
              )}
              <Text style={tw("font-bold")}>
                {isCurrentSuspend
                  ? Localise(messages, "Suspend Details")
                  : isUpcomingSuspend
                  ? Localise(messages, "Upcoming Suspend Details")
                  : Localise(messages, "Schedule Suspension")}
              </Text>
              <View style={tw("py-2")}>
                <Form
                  initialValues={{
                    suspendStartDate:
                      !!suspendStartDateObj && !isPastEndDate
                        ? suspendStartDateObj.format("YYYY-MM-DD")
                        : moment().format("YYYY-MM-DD"),
                    suspendStartDateTime:
                      !!suspendStartDateObj && !isPastEndDate
                        ? suspendStartDateObj.format("YYYY-MM-DDTHH:mm:ss")
                        : suspensionStartTime.format("YYYY-MM-DDTHH:mm:ss"),
                    suspendEndDate:
                      !!suspendEndDateObj && !isPastEndDate
                        ? suspendEndDateObj.format("YYYY-MM-DD")
                        : moment().add(1, "days").format("YYYY-MM-DD"),
                    suspendEndDateTime:
                      !!suspendEndDateObj && !isPastEndDate
                        ? suspendEndDateObj.format("YYYY-MM-DDTHH:mm:ss")
                        : suspensionEndTime.format("YYYY-MM-DDTHH:mm:ss"),
                  }}
                  onSubmit={(values, formikBag) =>
                    submitSettings(values, formikBag)
                  }
                  validationSchema={validationSchema}
                  validateOnChange={false}
                  validateOnBlur={false}
                  formReinitialize={false}
                  render={({ dirty = false, errors }) => (
                    <View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          zIndex: 20,
                          marginBottom: 10,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 10,
                            width: 60,
                          }}
                        >
                          <Text>
                            {Localise(messages, "Start")}
                            {": "}
                          </Text>
                        </View>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <FormFieldDatePicker
                            dateFormat={
                              Platform.OS === "web" ? "MM/dd/yy" : "MM/DD/YY"
                            }
                            dateValueFormat="YYYY-MM-DD"
                            name="suspendStartDate"
                            placeholder="Start Date"
                            containerStyle={{
                              ...{
                                zIndex: 10,
                                height: Platform.OS !== "web" ? 35 : "auto",
                                marginTop: 0,
                              },
                              width: 120,
                            }}
                            iconName="calendar"
                          />
                          <FormFieldDatePicker
                            dateFormat="h:mm a"
                            name="suspendStartDateTime"
                            placeholder="Start Time"
                            containerStyle={{
                              ...{
                                zIndex: 10,
                                height: Platform.OS !== "web" ? 35 : "auto",
                                marginTop: 0,
                              },
                              width: 88,
                            }}
                            iconName="clock"
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            timeIntervals={Platform.OS !== "web" ? 1 : 30} //This change is for edit minutes in mobile
                            timeCaption="Start Time"
                            inputContainerStyle={{
                              width: 110,
                            }}
                          />
                          <View style={{ marginBottom: 8, marginLeft: 3.5 }}>
                            <Text>{currentTimeZone}</Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          zIndex: 10,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 10,
                            width: 60,
                          }}
                        >
                          <Text>
                            {Localise(messages, "End")}
                            {": "}
                          </Text>
                        </View>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <FormFieldDatePicker
                            dateFormat={
                              Platform.OS === "web" ? "MM/dd/yy" : "MM/DD/YY"
                            }
                            dateValueFormat="YYYY-MM-DD"
                            name="suspendEndDate"
                            placeholder="End Date"
                            containerStyle={{
                              ...{
                                zIndex: 10,
                                height: Platform.OS !== "web" ? 35 : "auto",
                                marginTop: 0,
                              },
                              width: 120,
                            }}
                            iconName="calendar"
                          />
                          <FormFieldDatePicker
                            dateFormat="h:mm a"
                            name="suspendEndDateTime"
                            placeholder="End Time"
                            containerStyle={{
                              ...{
                                zIndex: 10,
                                height: Platform.OS !== "web" ? 35 : "auto",
                                marginTop: 0,
                              },
                              width: 88,
                            }}
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            timeIntervals={Platform.OS !== "web" ? 1 : 30} //This change is for edit minutes in mobile
                            timeCaption="End Time"
                            iconName="clock"
                            inputContainerStyle={{
                              width: 110,
                            }}
                          />
                          <View style={{ marginBottom: 8, marginLeft: 3.5 }}>
                            <Text>{currentTimeZone}</Text>
                          </View>
                        </View>
                      </View>
                      <View style={{ alignItems: "flex-start" }}>
                        {!isEmpty(errors) && (
                          <Text style={fonts.error}>{errors.form}</Text>
                        )}
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          zIndex: -1,
                          flexWrap: "wrap",
                          marginTop: 10,
                        }}
                      >
                        {isUpcomingSuspend && !dirty ? (
                          <Button
                            accessibilityLabel="resume"
                            containerStyle={{ marginHorizontal: 0 }}
                            loading={!isGOTOAction ? resumeLoading : false}
                            onPress={() => {
                              setGOTOAction(false);
                              resumeSuspend(false);
                            }}
                            testID="resume"
                            title={Localise(messages, "Cancel Suspend")}
                          />
                        ) : (
                          <>
                            <SaveCancelButtons
                              buttonTitle={Localise(
                                messages,
                                isCurrentSuspend
                                  ? "Save Changes"
                                  : "Schedule Suspension"
                              )}
                              disableOnDirty={!isCurrentSuspend}
                              customContainerStyles={{ width: 170, margin: 5 }}
                              error={""}
                              cancelTestId="cancel_member_suspension"
                              saveTestId="save_member_suspension"
                            />
                          </>
                        )}
                      </View>
                    </View>
                  )}
                />
              </View>
            </View>
          )}
        </View>
      </View>
      {isGoToFlorist && (
        <>
          {showGOTOError && status === "Suspended" && (
            <Text style={{ color: "red", marginTop: 15 }}>
              {Localise(
                messages,
                "System is taking too long to respond, please try again."
              )}
            </Text>
          )}
          <View
            style={[styles.recordContainer, { zIndex: 9 - index }]}
            key={"partner"}
          >
            <View
              style={[
                styles.record,
                styles[isSettingTypeGOTO ? status : "Active"],
              ]}
            >
              <TouchableOpacity
                style={{ ...tw("flex flex-row") }}
                onPress={() => {
                  status === "Suspended" &&
                    setFTDSettings((FTDSettings) => !FTDSettings);
                }}
                testID="edit"
                accessibilityLabel="edit"
              >
                <View style={tw("flex flex-row justify-between w-full")}>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      maxWidth: "70%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={{ ...fonts.heading4 }}>{"FTD.COM : "}</Text>
                    <Text style={{ fontSize: 13 }}>
                      {isSettingTypeGOTO
                        ? status
                        : Localise(messages, "Active")}
                    </Text>
                  </View>
                  {!GoToSuspendProcessing && status === "Suspended" && (
                    <View>
                      {FTDSettings ? (
                        <Text
                          style={{
                            ...tw("text-right"),
                            ...fonts.link1,
                            width: 70,
                          }}
                        >
                          {Localise(messages, "Close")}
                        </Text>
                      ) : (
                        <Image
                          style={{
                            width: 18,
                            height: 18,
                          }}
                          resizeMode="cover"
                          source={IMAGES["edit"]}
                        />
                      )}
                    </View>
                  )}
                </View>
              </TouchableOpacity>
              {GoToSuspendProcessing ? (
                <View style={tw("py-4")}>
                  <Text>
                    {Localise(
                      messages,
                      "Please wait while we process your request. It may take up to"
                    )}{" "}
                    {gotoSuspendTime} {Localise(messages, "minutes.")}
                  </Text>
                  <ActivityIndicator color={colors.activityIndicator} />
                </View>
              ) : (
                FTDSettings && (
                  <View style={{ alignSelf: "flex-start", marginBottom: 10 }}>
                    <Button
                      accessibilityLabel="gotosuspend"
                      containerStyle={{ marginHorizontal: 0 }}
                      loading={isGOTOAction ? resumeLoading : false}
                      onPress={() => {
                        setGOTOAction(true);
                        isSettingTypeGOTO
                          ? resumeSuspend(true)
                          : addGoToSuspend();
                      }}
                      testID={
                        isSettingTypeGOTO ? "resumeGoToSuspend" : "gotosuspend"
                      }
                      title={
                        isSettingTypeGOTO
                          ? Localise(messages, "Resume")
                          : Localise(messages, "Suspend")
                      }
                    />
                  </View>
                )
              )}
            </View>
          </View>
        </>
      )}
      {partnersSubscribed.map((partner, index) => {
        return (
          <View
            style={[styles.recordContainer, { zIndex: 9 - index }]}
            key={partner}
          >
            <View style={[styles.record, styles[status]]}>
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text style={{ ...fonts.heading4 }}>
                  {partner === "doordash_subscription"
                    ? Localise(messages, "DoorDash Marketplace") + " : "
                    : partner === "uber_eats_subscription"
                    ? Localise(messages, "Uber Eats Marketplace") + " : "
                    : ""}
                </Text>
                <Text style={{ fontSize: 13 }}>
                  {Localise(messages, status)}
                </Text>
              </View>
            </View>
          </View>
        );
      })}
    </>
  );
};

export default MemberSuspends;
