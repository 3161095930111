import React, { useEffect, useContext } from "react";
import {
  View,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Text, Image, Avatar, Button } from "react-native-elements";
import tw from "tailwind-rn";
import { fonts, backgroundColors, colors } from "styles/theme";
import {
  Form,
  FormField,
  SubmitButton,
  FormFieldCheckBox,
  FormFieldRadioButtons,
  FormFieldPicker,
  FormFieldSwitch,
} from "components/elements/forms";
import * as Yup from "yup";
import { request } from "library/utils/request";
import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import toUpper from "lodash/toUpper";
import {
  StatusOptions,
  PermissionsforCreateUser as FloristUserPermissions,
} from "../staff-listing/config";
import {
  TypeOfUsers,
  AllDepartments,
  PermissionsforCreateUser as FTDUserPermissions,
} from "../ftd-admin/user-settings/config";
import UserProfileContext from "library/contexts/userProfile";
import { ToasterHandler } from "components/elements";
import IMAGES from "static/assets/images";
import { DeviceContext } from "library/contexts/appSettings";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";

export default function StaffProfileScreen({
  listingType,
  recordId,
  onComplete,
  onAction,
  recordAction,
  menuAction,
  setMenuAction,
}) {
  const { memberCodes = [], userRole } = React.useContext(UserProfileContext);

  const { messages, Localise } = React.useContext(I18NContext);

  const { isTablet } = useContext(DeviceContext);

  const [disableForm, setDisableForm] = useStateIfMounted(true);
  const [profileData, setprofileData] = useStateIfMounted({});
  const [successMsg, setSuccessMsg] = useStateIfMounted("");
  const [errorMsg, setErrorMsg] = useStateIfMounted("");
  const [userRoles, setUserRoles] = useStateIfMounted([]);
  const [loading, setLoading] = useStateIfMounted(true);
  const [accountEnabled, setAccountEnabled] = useStateIfMounted(true);

  const { firstName = "", lastName = "" } = profileData;
  const profileName = `${firstName} ${lastName}`;
  const profileRole = get(profileData, "authGroups.0.roles.0", "");

  const isFTDUsersAccountsListing = listingType === "ftd-user-accounts";
  const isFloristAdmin = userRole === "ADMIN";

  const isEditEligible = isFTDUsersAccountsListing
    ? FTDUserPermissions[toUpper(userRole)]
    : FloristUserPermissions[toUpper(userRole)];

  let typeOfFTDUsers;
  if (userRole === "FTD_ADMIN") {
    typeOfFTDUsers = TypeOfUsers;
  } else if (userRole === "FTD_POWER_USER") {
    typeOfFTDUsers = TypeOfUsers.filter((typeOfUser) => {
      return typeOfUser.value !== "FTD_ADMIN";
    });
  }

  const statusMap = {
    FULLTIME: "Full Time",
    PARTTIME: "Part Time",
    SEASONAL: "Seasonal",
    TERMINATED: "Terminated",
  };

  const recordIdArray = recordId && recordId.split(":");
  const primaryMemberCode =
    recordIdArray && recordIdArray.length && recordIdArray[0];
  const userEmailId =
    recordIdArray && recordIdArray.length > 1 && recordIdArray[1];

  const pullProfileDetails = () => {
    setLoading(true);
    setprofileData({});
    request("get-staff-profile", {
      emailId: userEmailId,
      memberCode: primaryMemberCode,
      ...(isFTDUsersAccountsListing && { memberCodes: ["FTD"] }),
    })
      .then((response = {}) => {
        setprofileData(response);
        setLoading(false);

        if (menuAction) {
          if (menuAction.includes(recordId)) setDisableForm(false);

          if ([`disableAccount${recordId}`].includes(menuAction)) {
            setAccountEnabled(false);
          }

          if ([`enableAccount${recordId}`].includes(menuAction)) {
            setAccountEnabled(true);
          }
        } else {
          setAccountEnabled(get(response, "status") === "ACTIVE");
        }
      })
      .catch((err) => {
        setLoading(false);
        setErrorMsg("User not found, please try again");
      });
  };

  useEffect(() => {
    recordId && pullProfileDetails();
    setDisableForm(true);
    setSuccessMsg("");
    setErrorMsg("");

    menuAction && !menuAction.includes(recordId) && setMenuAction("");
  }, [recordId, recordAction]);

  useEffect(() => {
    request("get-user-roles", {}, undefined, true).then((res) => {
      let roles = res.map((role) => {
        return { label: role, value: role };
      });
      setUserRoles(roles);
    });
  }, []);

  const editStaffValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .label("firstName")
      .required(Localise(messages, "Please enter first name")),
    lastName: Yup.string()
      .label("lastName")
      .required(Localise(messages, "Please enter last name")),
    role: Yup.string().label("role").required("Please enter a role"),
    shopCodes: Yup.string()
      .label("shopCodes")
      .required(Localise(messages, "Select at least one shop code")),
    employmentStatus: Yup.string()
      .label("status")
      .required(Localise(messages, "Enter Status")),
    emailId: Yup.string()
      .label("emailId")
      .email(Localise(messages, "Enter a valid email"))
      .required(Localise(messages, "Enter Email")),
    phone: Yup.string()
      .label("phone")
      .required(Localise(messages, "Please enter phone number"))
      .matches(
        new RegExp(
          "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
        ),
        Localise(messages, "Please enter a valid phone number")
      ),
    password: Yup.string()
      .label("password")
      .when("showPasswordField", {
        is: (value) => value === true,
        then: Yup.string().required(Localise(messages, "Enter Password")),
      })
      .matches(
        /^(?=\S)(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*\S$)[A-Za-z0-9\s!@#$%^&*]{8,32}$/,
        Localise(messages, "Enter Password")
      ),
  });

  const editFTDUserValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .label("firstName")
      .required("Please enter first name"),
    lastName: Yup.string().label("lastName").required("Please enter last name"),
    emailId: Yup.string()
      .label("emailId")
      .required("Please enter Email")
      .matches(
        /^[a-z0-9]+(.|_)+[a-z0-9]+@ftdi.com$/i,
        "Please enter a valid FTD email address"
      ),
    typeOfUser: Yup.string()
      .label("typeOfUser")
      .required("Please select a valid user type"),
    department: Yup.string()
      .label("department")
      .required("Please select a valid department"),
    password: Yup.string()
      .label("password")
      .when("showPasswordField", {
        is: (value) => value === true,
        then: Yup.string().required("Enter Password"),
      })
      .matches(
        /^(?=\S)(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*\S$)[A-Za-z0-9\s!@#$%^&*]{8,32}$/,
        "Enter Password"
      ),
  });

  const onFormSubmit = (values, formikBag) => {
    const {
      firstName,
      lastName,
      emailId,
      phone,
      role,
      shopCodes,
      employmentStatus,
      profileNotes,
      accountEnabled,
      typeOfUser,
      department,
      password,
      showPasswordField,
    } = values;

    let otherParams = {};

    if (isFTDUsersAccountsListing) {
      otherParams = {
        phone: "",
        department,
        employmentStatus: accountEnabled ? "FULLTIME" : "TERMINATED",
        type: "FTD_USER",
        authGroups: [
          {
            name: `${typeOfUser}_ROLE`,
            shopGroups: ["FTD_ALL_SHOPS"],
            roles: [typeOfUser],
          },
          {
            name: `ADMIN_ROLE`,
            shopGroups: ["FTD_ALL_SHOPS"],
            roles: ["ADMIN"],
          },
        ],
      };
    } else {
      otherParams = {
        phone,
        employmentStatus,
        profileNotes,
        authGroups: [
          {
            name: `${role}_ROLE`,
            roles: [role],
            memberCodes: shopCodes,
          },
        ],
      };
    }

    const payload = {
      firstName,
      lastName,
      emailId,
      status: accountEnabled ? "ACTIVE" : "INACTIVE",
      partnerGroup: profileData.partnerGroup,
      id: profileData.id,
      memberCode: profileData.memberCode,
      ...otherParams,
    };

    const profileUpdated = (res) => {
      formikBag.setSubmitting(false);
      //setSuccessMsg("Profile updated successfully"); -- commenting this as per suggestion from PO as we already showing same success message in Toaster.
      setDisableForm(true);

      // Setting menuAction as empty in order show form in disable mode after user profile updated
      if (
        menuAction &&
        [`enableAccount${recordId}`, `disableAccount${recordId}`].includes(
          menuAction
        )
      ) {
        setMenuAction("");
      }

      // While editing staff details, if we modify email id then we are setting recordId with new email id as mentioned below in else
      // o/w simply we are reloading staff details in side car by making get staff details api with same email id.
      if (userEmailId === emailId) {
        onAction(JSON.stringify(payload), recordId);
      } else {
        // Updating recordId with new email id in order to refresh staff deatils in side car by making get staff details api with new email id.
        recordId = `${primaryMemberCode}:${emailId}`;

        onComplete(recordId);
        onAction("refresh", recordId);
      }
      formikBag.setFieldValue("showPasswordField", false);
      res && get(res, "0.statusCode", 200) === 400
        ? ToasterHandler(
            "uh oh",
            Localise(messages, "Please try to update the password again")
          )
        : ToasterHandler(
            "nice",
            Localise(messages, "This profile has been updated")
          );
    };

    request("edit-staff-profile", payload)
      .then(() => {
        if (showPasswordField) {
          request("batch-update", [
            {
              email: emailId,
              unlock: true,
              newPassword: password,
            },
          ])
            .then((res) => profileUpdated(res))
            .catch(() => {
              formikBag.setSubmitting(false);
              setErrorMsg("Unable to update password, please try again");
            });
        } else {
          profileUpdated();
        }
      })
      .catch((err) => {
        formikBag.setSubmitting(false);
        //setErrorMsg("Unable to update profile, please try again"); -- commenting this line as we are showing Toaster already below
        err === "USER_ALREADY_EXISTS"
          ? ToasterHandler(
              "oops",
              `${Localise(
                messages,
                "We’re seeing double – this user already has an account using"
              )} ${emailId}.`
            )
          : ToasterHandler(
              "uh oh",
              err.message ||
                Localise(messages, "Please try to update your profile again")
            );
      });
  };

  const handleReset = () => {
    setSuccessMsg("");
    setErrorMsg("");

    request("initiate-reset-password", {
      email: profileData.emailId,
      type: "reset",
    })
      .then((res) =>
        ToasterHandler(
          "success",
          Localise(messages, "Password reset email has been sent")
        )
      )
      .catch((err) => {
        if (err === "INVALID_ACCOUNT")
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We couldn’t find that email – please try again or confirm the email"
            )
          );
        else
          ToasterHandler(
            "error",
            Localise(messages, "Something went wrong, please try again.")
          );
      });
  };

  const onAccountChange = (setFieldValue, values) => {
    const isEnable = values.accountEnabled;
    setFieldValue("accountEnabled", !isEnable);

    if (!isFTDUsersAccountsListing) {
      isEnable
        ? setFieldValue("employmentStatus", "TERMINATED")
        : setFieldValue("employmentStatus", StatusOptions[0].value);
    }
  };

  const onStatusChange = (value, setFieldValue) => {
    value === "TERMINATED"
      ? setFieldValue("accountEnabled", false)
      : setFieldValue("accountEnabled", true);
  };

  const unlockProfile = () => {
    setSuccessMsg("");
    setErrorMsg("");
    setLoading(true);

    request("batch-update", [
      {
        email: profileData.emailId,
        unlock: true,
      },
    ])
      .then(() => {
        setLoading(false);
        ToasterHandler(
          "they’re back",
          Localise(messages, "This profile has been unlocked")
        );
        onAction("refresh", recordId);
      })
      .catch(() => {
        setLoading(false);
        setErrorMsg("Please try to save again");
      });
  };

  return (
    <ScrollView>
      <View style={{ ...(isTablet && { padding: 10 }) }} fsClass="fs-unmask">
        <View
          style={[
            tw("px-3 py-3 flex flex-col"),
            { backgroundColor: backgroundColors.primary, height: 140 },
          ]}
        >
          {!isTablet && (
            <View style={tw("flex items-end")}>
              <TouchableOpacity
                onPress={() => onComplete()}
                testID="close"
                accessibilityLabel="close"
              >
                <Image
                  style={{ width: 20, height: 20 }}
                  resizeMode="cover"
                  source={IMAGES["close"]}
                />
              </TouchableOpacity>
            </View>
          )}
          <View style={tw("flex flex-col items-center")}>
            <Text style={{ ...fonts.heading2, ...tw("text-white mb-3") }}>
              {loading ? Localise(messages, "Loading Profile...") : profileName}
            </Text>

            <Text style={{ ...fonts.heading4, ...tw("text-white") }}>
              {loading
                ? " "
                : isFTDUsersAccountsListing
                ? startCase(profileData.department || " ")
                : toUpper(
                    Localise(messages, startCase(camelCase(profileRole)))
                  ) || " "}
            </Text>

            <Avatar
              rounded
              size="large"
              title={firstName.charAt(0).toUpperCase()}
              avatarStyle={{
                borderWidth: 1,
                borderColor: backgroundColors.primary,
                borderRadius: 37.5,
                borderStyle: "solid",
              }}
              source={require("static/assets/ftd-white.png")}
              containerStyle={{ top: 65, position: "absolute" }}
            />
          </View>
        </View>

        <View
          style={{
            padding: 20,
            minHeight: 100,
          }}
        >
          {loading && (
            <ActivityIndicator
              size="large"
              color={colors.activityIndicator}
              style={{ marginTop: 30 }}
            />
          )}
          {!isEmpty(profileData) && (
            <>
              {isEditEligible && (
                <View style={tw("flex flex-row justify-end items-end")}>
                  <View style={tw("flex flex-row")}>
                    <TouchableOpacity
                      style={[tw("py-2")]}
                      onPress={() => {
                        setDisableForm(!disableForm);
                        setSuccessMsg("");
                        setErrorMsg("");
                      }}
                      testID="edit"
                      accessibilityLabel="edit"
                    >
                      {disableForm && (
                        <Image
                          style={{
                            width: 18,
                            height: 18,
                          }}
                          resizeMode="cover"
                          source={IMAGES["edit"]}
                        />
                      )}
                    </TouchableOpacity>
                  </View>
                </View>
              )}
              {!!successMsg && (
                <View>
                  <Text style={[fonts.success]}>{successMsg}</Text>
                </View>
              )}
              {!!errorMsg && (
                <View>
                  <Text style={[fonts.error]}>{errorMsg}</Text>
                </View>
              )}
              {disableForm && (
                <View>
                  <View style={tw("flex flex-row my-2")}>
                    <Text style={[fonts.heading5, tw("w-1/4")]}>
                      {Localise(messages, "Name")}
                    </Text>
                    <Text>{profileName}</Text>
                  </View>
                  <View style={tw("flex flex-row my-2")}>
                    <Text style={[fonts.heading5, tw("w-1/4")]}>
                      {Localise(messages, "Role")}
                    </Text>
                    <Text>{Localise(messages, profileRole)}</Text>
                    {/* <Text style={[fonts.link1, tw("pl-4")]} onPress={() => {}}>
                  Add Role
                </Text> */}
                  </View>
                  {!isFTDUsersAccountsListing && (
                    <View style={tw("flex flex-row my-2")} fsClass="fs-exclude">
                      <Text style={[fonts.heading5, tw("w-1/4")]}>
                        {Localise(messages, "Phone")}
                      </Text>
                      <Text>{profileData.phone} </Text>
                    </View>
                  )}
                  <View style={tw("flex flex-row my-2")}>
                    <Text style={[fonts.heading5, tw("w-1/4")]}>
                      {Localise(messages, "Status")}
                    </Text>
                    <Text>
                      {Localise(
                        messages,
                        statusMap[profileData.employmentStatus]
                      )}{" "}
                    </Text>
                  </View>
                  <View style={tw("flex flex-row my-2")}>
                    <Text style={[fonts.heading5, tw("w-1/4")]}>
                      {Localise(messages, "Email")}
                    </Text>
                    <Text>{profileData.emailId}</Text>
                  </View>
                  {!isFTDUsersAccountsListing ? (
                    <View style={tw("flex flex-row my-2")}>
                      <Text style={[fonts.heading5, tw("w-1/4")]}>
                        {Localise(messages, "Profile Notes")}
                      </Text>
                      <Text>{profileData.profileNotes}</Text>
                    </View>
                  ) : (
                    <View style={tw("flex flex-row my-2")}>
                      <Text style={[fonts.heading5, tw("w-1/4")]}>
                        {Localise(messages, "Account Enabled")}
                      </Text>
                      <FormFieldSwitch
                        value={profileData.status === "ACTIVE"}
                      />
                    </View>
                  )}
                  <View style={tw("flex flex-row my-2")}>
                    <Text style={[fonts.heading5, tw("w-1/4")]}>
                      {Localise(messages, "Password")}
                    </Text>
                    <Text>*********</Text>
                    <TouchableOpacity
                      style={[
                        tw("flex flex-1 flex-row items-center ml-4 "),
                        { minWidth: 100 },
                      ]}
                      onPress={handleReset}
                      testID="reset_pwd"
                      accessibilityLabel="reset_pwd"
                    >
                      <Text style={fonts.link1}>
                        {Localise(messages, "Generate Password Reset Email")}
                      </Text>
                    </TouchableOpacity>
                  </View>
                  {(isFTDUsersAccountsListing || isFloristAdmin) &&
                    profileData.isAccountLocked && (
                      <View style={tw("flex flex-row my-2")}>
                        <Button
                          containerStyle={{ margin: 0 }}
                          onPress={unlockProfile}
                          title={"Unlock Profile"}
                          testID="unlock_profile"
                          accessibilityLabel="unlock_profile"
                        />
                      </View>
                    )}
                </View>
              )}
              {!disableForm && (
                <Form
                  initialValues={{
                    firstName: get(profileData, "firstName", ""),
                    lastName: get(profileData, "lastName", ""),
                    employmentStatus: get(profileData, "employmentStatus", ""),
                    emailId: get(profileData, "emailId", ""),
                    role: get(profileData, "authGroups.0.roles.0", ""),
                    shopCodes: get(profileData, "authGroups.0.memberCodes", []),
                    isEmail: true,
                    pushNotifications: false,
                    profileNotes: get(profileData, "profileNotes", ""),
                    phone: get(profileData, "phone", ""),
                    accountEnabled: accountEnabled,
                    typeOfUser: get(profileData, "authGroups.0.roles.0", ""),
                    department: get(profileData, "department", ""),
                    password: "",
                    showPasswordField: false,
                  }}
                  onSubmit={onFormSubmit}
                  validationSchema={
                    isFTDUsersAccountsListing
                      ? editFTDUserValidationSchema
                      : editStaffValidationSchema
                  }
                  validateOnMount={true}
                  initialTouched={{
                    department: true,
                  }}
                  render={({ values, setFieldValue }) => (
                    <View style={{ marginLeft: -5 }}>
                      <FormField
                        autoCapitalize="none"
                        autoCorrect={false}
                        name="firstName"
                        placeholder="Enter First Name"
                        label="First Name"
                      />
                      <FormField
                        autoCapitalize="none"
                        autoCorrect={false}
                        name="lastName"
                        placeholder="Enter Last Name"
                        label="Last Name"
                      />
                      {!isFTDUsersAccountsListing ? (
                        <>
                          {!!userRoles.length && (
                            <FormFieldRadioButtons
                              label={"Role"}
                              options={userRoles}
                              name={"role"}
                            />
                          )}
                          <FormFieldCheckBox
                            label={"Shop Codes"}
                            iconRight={false}
                            name="shopCodes"
                            size={20}
                            containerStyle={{}}
                            title={memberCodes}
                            isGroup={true}
                          />
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              marginRight: 20,
                              height: 22,
                            }}
                          >
                            <Text style={{ ...fonts.heading5, paddingTop: 10 }}>
                              {Localise(messages, "Account Enabled")}{" "}
                            </Text>
                            <View
                              style={{ maxWidth: 35, padding: 5, height: 22 }}
                            >
                              <FormFieldSwitch
                                value={values.accountEnabled}
                                onValueChange={() =>
                                  onAccountChange(setFieldValue, values)
                                }
                              />
                            </View>
                          </View>
                          <FormFieldPicker
                            placeholder={{
                              label: "Select Status",
                              value: "",
                            }}
                            data={StatusOptions}
                            name="employmentStatus"
                            label="Status"
                            onChange={(e) => onStatusChange(e, setFieldValue)}
                          />
                        </>
                      ) : (
                        <>
                          <FormFieldPicker
                            placeholder={{
                              label: "Select Type Of User",
                              value: "",
                            }}
                            data={typeOfFTDUsers}
                            name="typeOfUser"
                            label="Type Of User"
                          />
                          <FormFieldPicker
                            placeholder={{
                              label: "Select Department",
                              value: "",
                            }}
                            data={AllDepartments}
                            name="department"
                            label="Department"
                          />
                        </>
                      )}
                      <FormField
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardType="email-address"
                        name="emailId"
                        placeholder="Email"
                        textContentType="emailAddress"
                        label="Email"
                      />
                      {isFTDUsersAccountsListing && (
                        <View
                          style={tw("flex flex-row px-2 mb-4 items-center")}
                        >
                          <Text style={fonts.heading5}>
                            {Localise(messages, "Account Enabled")}{" "}
                          </Text>
                          <View style={{ maxWidth: 35, marginLeft: 10 }}>
                            <FormFieldSwitch
                              value={values.accountEnabled}
                              onValueChange={() =>
                                onAccountChange(setFieldValue, values)
                              }
                            />
                          </View>
                        </View>
                      )}
                      <View
                        style={{
                          flexDirection: "row",
                          flexWrap: "wrap",
                          alignItems: "center",
                        }}
                      >
                        <TouchableOpacity
                          style={[
                            tw("flex flex-row ml-1 mb-4"),
                            { minWidth: 100, paddingRight: 10 },
                          ]}
                          onPress={handleReset}
                          testID="reset_pwd"
                          accessibilityLabel="reset_pwd"
                        >
                          <Text style={fonts.link1}>
                            {Localise(
                              messages,
                              "Generate Password Reset Email"
                            )}
                          </Text>
                        </TouchableOpacity>
                        {(isFTDUsersAccountsListing || isFloristAdmin) && (
                          <TouchableOpacity
                            style={[
                              tw("flex flex-row ml-1 mb-4"),
                              { minWidth: 100 },
                            ]}
                            onPress={() =>
                              setFieldValue(
                                "showPasswordField",
                                !values.showPasswordField
                              )
                            }
                            testID="manually_reset_pwd"
                            accessibilityLabel="manually_reset_pwd"
                          >
                            <Text style={fonts.link1}>
                              {Localise(messages, "Manually Reset Password")}
                            </Text>
                          </TouchableOpacity>
                        )}
                      </View>
                      {values.showPasswordField && (
                        <View style={tw("mb-2")}>
                          <FormField
                            autoComplete="new-password"
                            autoCapitalize="none"
                            autoCorrect={false}
                            secureTextEntry
                            textContentType="password"
                            name="password"
                            label="Password"
                            placeholder="Enter New Password"
                            keyboardType="phone-pad"
                            maxLength={32}
                            isUpdateOnChange={true}
                          />
                          <View style={tw("flex flex-col pl-1")}>
                            <Text style={tw("my-1 italic")}>
                              {Localise(
                                messages,
                                "Your password must be a min 8 and max 32 characters without spaces, and include 1 uppercase, 1 number, and 1 special character. The password cannot match your three previous passwords."
                              )}
                            </Text>
                          </View>
                        </View>
                      )}
                      {/*
                        <View style={tw("flex flex-row ml-1 my-2")}>
                          <Text style={[fonts.heading6, tw("w-1/4")]}>
                            Security Questions
                          </Text>
                          <View style={tw("flex flex-col")}>
                            <View>
                              <Text style={tw("mb-2")}>Question1</Text>
                              <Text>Answer1</Text>
                            </View>
                            <View style={tw("mt-4")}>
                              <Text style={tw("mb-2")}>Question2</Text>
                              <Text>Answer2</Text>
                            </View>
                          </View>
                        </View>
                        <View style={tw("flex flex-row ml-1 my-2")}>
                          <Text style={[fonts.heading6, tw("w-1/4")]}>
                            Notifications
                          </Text>
                          <View style={tw("flex flex-col")}>
                            <Text> 9am-5pm</Text>
                            <View style={tw("flex flex-row mb-1 ")}>
                              <FormFieldCheckBox
                                iconRight={false}
                                name="isEmail"
                                size={20}
                                title="Email"
                              />
                              <FormFieldCheckBox
                                iconRight={false}
                                name="pushNotifications"
                                size={20}
                                title="Push Notifications"
                              />
                            </View>
                          </View>
                        </View>
                        <View style={tw("flex flex-row ml-1 my-2")}>
                          <Text style={[fonts.heading6, tw("w-1/4")]}>
                            Upcoming Hours
                          </Text>
                          <View style={tw("flex flex-col flex-1")}>
                            <View style={tw("flex flex-row justify-between ")}>
                              <Text style={tw("w-1/4")}>Monday </Text>
                              <Text>1/1/20 </Text>
                              <Text>9am-5pm </Text>
                            </View>
                            <View style={tw("flex flex-row justify-between my-2")}>
                              <Text style={tw("w-1/4")}>Tuesday </Text>
                              <Text>1/1/20 </Text>
                              <Text>9am-5pm </Text>
                            </View>
                            <View style={tw("flex flex-row justify-between")}>
                              <Text style={tw("w-1/4")}>Wednesday </Text>
                              <Text>1/1/20 </Text>
                              <Text>9am-5pm </Text>
                            </View>
                          </View>
                        </View>*/}
                      {!isFTDUsersAccountsListing && (
                        <>
                          <FormField
                            name="phone"
                            label="Phone"
                            placeholder="123-456-7890"
                            keyboardType="phone-pad"
                            maxLength={18}
                            fsClass="fs-exclude"
                          />
                          <FormField
                            autoCapitalize="none"
                            autoCorrect={false}
                            name="profileNotes"
                            placeholder="Enter Profile Notes"
                            label="Profile Notes"
                            multiline={true}
                            numberOfLines={4}
                            containerStyle={{ marginLeft: 0 }}
                          />
                        </>
                      )}
                      {(isFTDUsersAccountsListing || isFloristAdmin) &&
                        profileData.isAccountLocked && (
                          <View style={tw("flex flex-row p-2 items-center")}>
                            <Button
                              containerStyle={{ margin: 0 }}
                              onPress={unlockProfile}
                              title={Localise(messages, "Unlock Profile")}
                              testID="unlock_profile"
                              accessibilityLabel="unlock_profile"
                            />
                          </View>
                        )}
                      <SubmitButton
                        containerStyle={{ margin: 5 }}
                        title="Submit"
                        disableOnDirty={true}
                      />
                    </View>
                  )}
                />
              )}
            </>
          )}
        </View>
      </View>
    </ScrollView>
  );
}
