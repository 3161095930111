import React, { useMemo, useState, useEffect } from "react";
import { View, ActivityIndicator } from "react-native";
import { Button } from "react-native-elements";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { Form, FormField } from "components/elements/forms";
import { theme, colors } from "styles/theme";
import {
  customerActionValidationSchema,
  CustomerEligibleActions,
  ProposalActions,
} from "../../config";
import { request } from "library/utils/request";
import { ToasterHandler } from "components/elements";
import moment from "moment";

const CustomerActions = (props) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationOnChange, setValidationOnChange] = useState(false);
  const { isDesktop, values: eventInfo, handlePrint } = props;
  const [showActions, setShowActions] = useState(
    eventInfo?.proposal?.status === "DRAFT"
  );

  return useMemo(() => {
    return !action ? (
      <View
        style={[
          tw("flex flex-row flex-wrap items-center justify-start w-full mt-2"),
        ]}
      >
        {showActions &&
          CustomerEligibleActions.map((action, index) => {
            return (
              <Button
                key={index}
                titleStyle={theme.Button.secondaryTitleStyle}
                buttonStyle={{
                  ...theme.Button.secondaryButtonStyle,
                  paddingVertical: 5,
                  paddingHorizontal: 10,
                  marginRight: 10,
                }}
                containerStyle={{
                  justifyContent: "center",
                  alignItems: "flex-start",
                  marginRight: 10,
                }}
                onPress={() => {
                  setAction(action);
                }}
                title={Localise(messages, action)}
                testID={action}
                accessibilityLabel={action}
              />
            );
          })}
        <Button
          titleStyle={theme.Button.secondaryTitleStyle}
          buttonStyle={{
            ...theme.Button.secondaryButtonStyle,
            paddingVertical: 5,
            paddingHorizontal: 10,
            marginRight: 10,
          }}
          containerStyle={{
            justifyContent: "center",
            alignItems: "flex-start",
            marginRight: 10,
          }}
          onPress={handlePrint}
          title={Localise(messages, "Print")}
          testID={"Print"}
          accessibilityLabel={"Print"}
        />
      </View>
    ) : (
      <Form
        initialValues={{ fullLegalName: "", customerNotes: "" }}
        onSubmit={(values, formikBag) => {
          setLoading(true);
          const { fullLegalName = "", customerNotes = "" } = values;
          const { eventId, memberCode } = eventInfo;
          request("update-public-event", {
            eventId: eventId,
            reqObj: {
              proposal: {
                status: action ? ProposalActions[action] : "DRAFT",
                changeRequestNotes: customerNotes,
                fullLegalName,
                date: moment().format("MM/DD/YYYY"),
                signature: fullLegalName,
                emailSent: true,
              },
            },
            shopCode: memberCode,
          })
            .then((response) => {
              ToasterHandler(
                "nice",
                Localise(messages, `Your proposal status updated successfully`)
              );
              formikBag.resetForm({ fullLegalName: "", customerNotes: "" });
              setAction("");
              setShowActions(false);
            })
            .catch((error) => {
              console.log("error: " + error);
              ToasterHandler(
                "uh oh",
                Localise(messages, "Failed to update proposal status.")
              );
            })
            .finally(() => {
              setLoading(false);
            });
        }}
        validationSchema={customerActionValidationSchema(
          messages,
          Localise,
          action?.toLowerCase()
        )}
        validateOnChange={validationOnChange}
        validateOnBlur={validationOnChange}
        render={(props) => {
          const { handleSubmit, submitCount } = props;
          if (!action) return null;

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (submitCount > 0) {
              setValidationOnChange(true);
            }
          }, [submitCount]);

          return (
            <View
              style={{
                marginTop: 15,
                width: "100%",
                display: "flex",
              }}
            >
              {action === "Request Changes" ? (
                <FormField
                  name="customerNotes"
                  containerStyle={{
                    width: isDesktop ? "50%" : "100%",
                    paddingLeft: 0,
                  }}
                  placeholder={Localise(messages, "Customer Notes")}
                  label={Localise(messages, "Customer Notes")}
                  labelStyle={{ fontWeight: "normal" }}
                  testID="customerNotes"
                  accessibilityLabel="customerNotes"
                  multiline={true}
                  numberOfLines={3}
                />
              ) : (
                <FormField
                  name="fullLegalName"
                  containerStyle={{
                    width: isDesktop ? "50%" : "100%",
                    paddingLeft: 0,
                  }}
                  placeholder={Localise(messages, "Full Legal Name")}
                  label={Localise(messages, "Legal Name")}
                  labelStyle={{ fontWeight: "normal" }}
                  testID="fullLegalName"
                  accessibilityLabel="fullLegalName"
                />
              )}
              <View style={tw("flex flex-row")}>
                <Button
                  titleStyle={theme.Button.secondaryTitleStyle}
                  buttonStyle={{
                    ...theme.Button.secondaryButtonStyle,
                    paddingVertical: 5,
                    paddingHorizontal: 10,
                    marginRight: 10,
                  }}
                  containerStyle={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    marginRight: 10,
                    marginLeft: 0,
                  }}
                  onPress={() => setAction("")}
                  title={Localise(messages, "Cancel")}
                  testID={action}
                  accessibilityLabel={action}
                  disabled={loading}
                />
                <Button
                  titleStyle={theme.Button.secondaryTitleStyle}
                  buttonStyle={{
                    ...theme.Button.secondaryButtonStyle,
                    paddingVertical: 5,
                    paddingHorizontal: 10,
                  }}
                  containerStyle={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    marginRight: 10,
                  }}
                  onPress={handleSubmit}
                  title={Localise(messages, "Submit")}
                  testID={action}
                  accessibilityLabel={action}
                  disabled={loading}
                />
                {loading && (
                  <ActivityIndicator
                    style={{ marginLeft: 5, marginTop: -5 }}
                    color={colors.activityIndicator}
                  />
                )}
              </View>
            </View>
          );
        }}
      />
    );
  }, [
    Localise,
    messages,
    action,
    isDesktop,
    loading,
    eventInfo,
    validationOnChange,
    showActions,
    handlePrint,
  ]);
};

export default CustomerActions;
