import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text, TouchableOpacity, Image } from "react-native";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { IMAGES } from "static/assets/images";
import { backgroundColors, colors, fonts } from "styles/theme";
import tw from "tailwind-rn";

const SideCar = ({ titleSelector, onCloseAction, children }) => {
  const dispatch = useDispatch();
  const title = useSelector(titleSelector);
  const { isDesktop, isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);

  const onClose = () => dispatch(onCloseAction());

  return (
    <View fsClass="fs-unmask" style={tw("flex flex-col")}>
      <View
        style={[
          tw("px-3 py-3 flex flex-row items-center justify-between mb-2"),
          { backgroundColor: backgroundColors.primary },
        ]}
      >
        <Text style={{ ...fonts.heading2, ...tw("text-white") }}>
          {Localise(messages, title)}
        </Text>
        {isDesktop && (
          <View>
            <TouchableOpacity onPress={onClose} testID="close">
              <Image
                style={{ width: 20, height: 20 }}
                resizeMode="cover"
                source={IMAGES["close"]}
              />
            </TouchableOpacity>
          </View>
        )}
      </View>
      <View
        style={{
          borderWidth: 1,
          borderColor: colors.light,
          paddingBottom: 20,
        }}
      >
        <View
          style={{
            padding: isMobile ? 10 : 20,
          }}
        >
          {children}
        </View>
      </View>
    </View>
  );
};

export default SideCar;
