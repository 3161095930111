import React from "react";
import { View, Text, Platform } from "react-native";

import { FormFieldDatePicker } from "components/elements/forms";
import I18NContext from "library/contexts/i18N";

import { fonts } from "styles/theme";
import tw from "tailwind-rn";
import get from "lodash/get";

const CutoffTimes = ({ shopHours = {} }) => {
  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const { Localise, messages } = React.useContext(I18NContext);

  return days.map((dayOfWeek, index) => {
    const openedFlag = get(shopHours, `${dayOfWeek}.openedFlag`, "T");
    const isShopClosed = openedFlag === "F";

    return (
      <View
        style={[
          tw("flex flex-row items-center mb-1"),
          { maxWidth: 400, zIndex: 20 - index },
        ]}
        key={dayOfWeek}
      >
        <Text
          style={{
            width: 80,
            height: 25,
            textTransform: "capitalize",
            ...fonts.heading5,
          }}
        >
          {Localise(messages, dayOfWeek)}
        </Text>
        <FormFieldDatePicker
          dateFormat="h:mm a"
          name={`deliveryCutoffTimes.${dayOfWeek}`}
          placeholder={Localise(messages, "Select Open Time")}
          iconName="clock"
          showTimeSelect={true}
          showTimeSelectOnly={true}
          timeIntervals={30}
          timeCaption=""
          alignIconRight={true}
          disabled={isShopClosed}
          errorStyle={{ paddingVertical: 0 }}
          containerStyle={{ width: 120 }}
          popperPlacement="bottom"
          dateValueFormat={"YYYY-MM-DDTHH:mm:ss"}
          {...(Platform.OS !== "web"
            ? {
                minDate: new Date(get(shopHours, `${dayOfWeek}.open`, "")),
                maxDate: new Date(get(shopHours, `${dayOfWeek}.close`, "")),
              }
            : {
                minTime: new Date(get(shopHours, `${dayOfWeek}.open`, "")),
                maxTime: new Date(get(shopHours, `${dayOfWeek}.close`, "")),
              })}
          editable={Platform.OS !== "web"}
        />
        {isShopClosed && (
          <Text style={[fonts.default, tw("italic pl-3"), { height: 25 }]}>
            {Localise(messages, "Closed")}
          </Text>
        )}
      </View>
    );
  });
};

export default CutoffTimes;
