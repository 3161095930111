import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import GiftCard from "./giftCard";
import { setSideCarInitialize } from "library/sagas/views/home/drawer/gift-cards/slice";
import {
  selectExtendedScreenData,
  selectStoreOrigin,
} from "library/sagas/views/home/drawer/gift-cards/selector";
import UserProfileStorage from "library/storage/userProfile";

import get from "lodash/get";

const UpsertScreen = ({ action = "update" }) => {
  const dispatch = useDispatch();
  const extendedScreenData = useSelector(selectExtendedScreenData);
  const storeOrigin = useSelector(selectStoreOrigin);

  const { memberCodes = [] } = UserProfileStorage.getAuthGroup();
  const giftCardId = get(extendedScreenData, "giftCardId", "");

  const isCreate = action === "create";

  useEffect(() => {
    giftCardId &&
      dispatch(
        setSideCarInitialize({
          storeOrigin: storeOrigin || memberCodes[0],
          isCreate,
        })
      );
  }, [giftCardId]);

  return <GiftCard id={giftCardId} isCreate={isCreate} />;
};

export default UpsertScreen;
