export const reArrangeArray = (finalActions) => {
  const resultArray = [];
  // Desired List Order
  const desiredList = [
    "Accepted",
    "Assign to Designer",
    "In Design",
    "Designed",
    "Delivery",
    "Completed",
    "Ready for Pickup",
    "Picked Up",
    "Show Recipe",
    "View Order",
    "Take Payment",
    "Reprint",
    "Optimize Route",
  ];

  desiredList.forEach((item) => {
    const index = finalActions.indexOf(item);
    if (index !== -1) {
      resultArray.push(finalActions[index]);
      finalActions.splice(index, 1);
    }
  });
  return resultArray;
};
