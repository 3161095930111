import React from "react";
import { View, TouchableOpacity, Image } from "react-native";
import { Text, Button } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";

import I18NContext from "library/contexts/i18N";
import {
  setExtendedScreen,
  fetchOrderDetails,
} from "library/sagas/views/home/drawer/mol-refunds/slice";

import {
  selectShowUpsert,
  selectExtendedScreenData,
} from "library/sagas/views/home/drawer/mol-refunds/selector";

import styles from "./styles";
import get from "lodash/get";
import IMAGES from "static/assets/images";

const Item = ({ id, index, data, columns, style }) => {
  const dispatch = useDispatch();
  const {
    [id]: itemId,
    creditCardType = "",
    giftCardType = "",
    shopifyOrderID = "",
  } = data;
  const { messages, Localise } = React.useContext(I18NContext);
  const status = get(data, "refundDetails.0.status", "");
  const mainIndex = index + 1;
  const isProcessed = status === "PROCESSED";
  const isGiftCardOrder = giftCardType === "DIGITAL_GIFT_CARDS";
  const showUpsert = useSelector(selectShowUpsert);
  const { activeRecord: selectedRecordId } = useSelector(
    selectExtendedScreenData
  );
  const isSelectedRecord =
    showUpsert && Number(selectedRecordId) === Number(data["deliveryGroupId"]);

  const onPressRefund = () => {
    if (data["deliveryGroupId"]) {
      dispatch(
        setExtendedScreen({
          showUpsert: true,
          screenTitle: `Refund Order ID - ${shopifyOrderID}`,
          activeRecord: data["deliveryGroupId"],
        })
      );

      dispatch(
        fetchOrderDetails({
          params: {
            orderId: data["shopifyOrderID"],
            memberCode: data["memberCode"],
          },
        })
      );
    }
  };

  return (
    <TouchableOpacity
      activeOpacity={0.6}
      onPress={() => {
        onPressRefund();
      }}
      style={{
        minWidth: 300,
        cursor: "pointer",
      }}
      testID={itemId}
      accessibilityLabel={itemId}
    >
      <View
        style={[
          styles.container,
          {
            opacity: isProcessed ? 0.6 : 1,
            ...(isSelectedRecord ? { ...styles.activeRowContainer } : {}),
          },
        ]}
        fsClass="fs-unmask"
      >
        <View
          style={[
            styles.row,
            style,
            { borderLeftColor: isProcessed ? "#C4C4C4" : "#E6F6EE" },
          ]}
          fsClass="fs-unmask"
        >
          {columns.map(({ column, style }, index) => {
            return (
              <React.Fragment key={index}>
                {["dateTime", "omsOrderID", "name", "area"].includes(column) ? (
                  <Text
                    numberOfLines={2}
                    style={[
                      styles.column,
                      {
                        ...style,
                        ...(isSelectedRecord ? { ...styles.activeRow } : {}),
                      },
                    ]}
                    key={index}
                  >
                    {column === "dateTime" &&
                      `${data["date"]}\n${data["time"]}`}
                    {column === "omsOrderID" &&
                      `${data[column]}\n${
                        mainIndex +
                        ` ${Localise(messages, "of")} ` +
                        data["orderCount"]
                      }\n#${data["shopifyOrderID"]}`}
                    {column === "name" && `${data[column]}\n${data["address"]}`}
                    {column === "area" &&
                      `${data["city"]}, ${data["state"]}\n${data["postalCode"]}`}
                  </Text>
                ) : (
                  <Text
                    numberOfLines={1}
                    style={[
                      styles.column,
                      {
                        ...style,
                        ...(isSelectedRecord ? { ...styles.activeRow } : {}),
                      },
                    ]}
                    key={index}
                  >
                    {Localise(messages, data[column])}
                  </Text>
                )}
                {index === 0 && (
                  <Image
                    style={{
                      width: 25,
                      height: 25,
                      marginRight: 10,
                    }}
                    resizeMode="cover"
                    source={
                      IMAGES[
                        isProcessed
                          ? "mercury-online-refunds-black"
                          : "processing-refund"
                      ]
                    }
                  />
                )}
              </React.Fragment>
            );
          })}
          {["ERROR"].includes(status) ? (
            <Text
              style={{
                color: "#E5175E",
                fontWeight: "600",
                width: "10%",
                textAlign: "center",
              }}
            >
              {Localise(messages, "Error")}
            </Text>
          ) : ["NEW", "PENDING", "RETRY"].includes(status) ? (
            <Text
              style={{
                color: "#1f4156",
                fontWeight: "600",
                width: "10%",
                textAlign: "center",
              }}
            >
              {Localise(messages, "Processing")}
            </Text>
          ) : !["PROCESSED"].includes(status) &&
            creditCardType !== "INHOUSE" &&
            !isGiftCardOrder ? (
            <Button
              testID={"refund"}
              accessibilityLabel="refund"
              buttonStyle={{
                height: 40,
                backgroundColor: "transparent",
                borderWidth: 1,
                borderColor: "#1f4156",
              }}
              containerStyle={{
                width: "6%",
              }}
              onPress={onPressRefund}
              title={Localise(messages, "Refund")}
              titleStyle={{ color: "#1f4156" }}
            />
          ) : (
            <Text style={{ width: "10%" }}></Text>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default Item;
