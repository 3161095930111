import React, { useEffect, useRef } from "react";
import tw from "tailwind-rn";
import { View, Image, TouchableOpacity } from "react-native";
import { Text, Button } from "react-native-elements";
import { fonts, backgroundColors } from "styles/theme";
import { ToasterHandler, Picker } from "components/elements";
import IMAGES from "static/assets/images";
import isEmpty from "lodash/isEmpty";

import { request } from "library/utils/request";
import { useDispatch, useSelector } from "react-redux";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { getUniqMembers, filterDesignerSuggestions } from "./helper";
import {
  selectScannedOrders,
  selectOrderActions,
} from "library/sagas/views/home/drawer/qr-scan/selector";
import { selectDesignerInfo } from "library/sagas/ongoing/global-data/selector";
import {
  setDesignersData,
  fetchDesignerSuggestions,
} from "library/sagas/ongoing/global-data/slice";
import { removeTileByIds } from "library/sagas/views/home/drawer/qr-scan/slice";

const AssignDesigner = ({ setDetailView, pullOrders }) => {
  const dispatch = useDispatch();
  const scannedOrders = useSelector(selectScannedOrders);
  const orderActionsList = useSelector(selectOrderActions);
  const designerSuggestions = useSelector(selectDesignerInfo);
  const [selectedDesigner, setSelectedDesigner] = useStateIfMounted("");

  const memberCodesRef = useRef([]);
  // Ref to track if the component has mounted
  const isInitialRender = useRef(true);
  const { Localise, messages } = React.useContext(I18NContext);

  useEffect(() => {
    const sortedOrders = getUniqMembers(scannedOrders, orderActionsList);
    memberCodesRef.current = Object.keys(sortedOrders);
    dispatch(
      fetchDesignerSuggestions({
        members: memberCodesRef.current.join(","),
        reject: (error) => {
          ToasterHandler("error", "unable to fetch designers");
        },
      })
    );
  }, []);

  useEffect(() => {
    // Skip the initial render
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    setDetailView("");
    !isEmpty(designerSuggestions) && dispatch(setDesignersData([]));
  }, [scannedOrders]);

  const filteredSuggestions = filterDesignerSuggestions(
    designerSuggestions,
    memberCodesRef.current
  );

  const handleOnPress = () => {
    const sortedOrders = getUniqMembers(scannedOrders, orderActionsList);
    const designerDetails = filteredSuggestions.find(
      (each) => each.id === selectedDesigner
    );
    const promises = Object.keys(sortedOrders).map((each) => {
      return request("assign-designer", {
        designer: {
          id: designerDetails.id,
          name: designerDetails.fullName,
        },
        orders: sortedOrders[each],
        memberCode: each,
      });
    });
    try {
      // Wait for all promises to resolve
      Promise.allSettled(promises).then((responses) => {
        if (!isEmpty(responses)) {
          const succeededOrders = [];
          responses.forEach((each) => {
            if (each.status === "fulfilled") {
              each.value.forEach((item) =>
                succeededOrders.push(item?.orderItemId)
              );
            }
          });
          // Removing the order tiles
          dispatch(removeTileByIds(succeededOrders));

          if (succeededOrders.length > 0) {
            pullOrders();
            ToasterHandler(
              "hooray",
              `${succeededOrders.length} ${Localise(
                messages,
                "order(s) updated."
              )} `
            );
          }
        }
      });
    } catch (error) {
      console.error("Error with one of the dispatches:", error);
    }
  };

  return (
    <View style={tw(`w-full`)}>
      <View
        style={[
          tw(`flex flex-row items-center justify-between w-full`),
          {
            backgroundColor: backgroundColors.secondary,
            minHeight: 30,
          },
        ]}
      >
        <Text style={{ ...fonts.heading2 }}>
          {Localise(messages, "Assign a Designer")}
        </Text>
        <TouchableOpacity
          onPress={() => {
            setDetailView("");
            dispatch(setDesignersData([]));
          }}
          testID="close"
        >
          <Image
            style={{ width: 20, height: 20 }}
            resizeMode="cover"
            source={IMAGES["close"]}
          />
        </TouchableOpacity>
      </View>
      <View style={[tw(`flex flex-row items-center mt-3`), { flexGrow: 1 }]}>
        {designerSuggestions.length ? (
          filteredSuggestions.length ? (
            <View style={tw("flex flex-row")}>
              <Picker
                containerStyle={{
                  height: 40,
                  width: 200,
                  paddingLeft: 0,
                }}
                items={filteredSuggestions.map((item) => {
                  return {
                    label: `${item.firstName} ${item.lastName}`,
                    value: item.id,
                  };
                })}
                placeholder={{ label: "Select Designer", value: "" }}
                value={selectedDesigner}
                onValueChange={(val) => {
                  setSelectedDesigner(val);
                }}
              ></Picker>
              <Button
                title={Localise(messages, "Apply")}
                onPress={handleOnPress}
                containerStyle={{ margin: 0 }}
                disabled={selectedDesigner.length === 0}
                testID={"Apply"}
                accessibilityLabel={"Apply"}
              />
            </View>
          ) : (
            <Text>
              {Localise(
                messages,
                "Designers not available for the selected membercode combination"
              )}
            </Text>
          )
        ) : (
          <View>
            <Text> {Localise(messages, "Fetching designers...")}</Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default React.memo(AssignDesigner);
