import React from "react";
import { useSelector } from "react-redux";
import { Platform, Linking, View, Text } from "react-native";

import { getUIConfig } from "./ui-config";
import { Spinner } from "components/elements";

import I18NContext from "library/contexts/i18N";
import UserProfileStorage from "library/storage/userProfile";
import UserProfileContext from "library/contexts/userProfile";
import {
  selectAnalytics,
  selectShopCode,
} from "library/sagas/views/home/drawer/mercury-online/selector";

import { fonts } from "styles/theme";
import tw from "tailwind-rn";

const Analytics = () => {
  const shopCode = useSelector(selectShopCode);
  const { content } = useSelector(selectAnalytics);
  const { messages, Localise } = React.useContext(I18NContext);
  const { memberCodes = [] } = React.useContext(UserProfileContext);
  const UIConfig = getUIConfig(Localise, messages);

  const onPress = (uri) => {
    const { shopify_store_url: shopifyAdmin } =
      UserProfileStorage.getShopPreferences(
        shopCode === "all" ? memberCodes[0] : shopCode
      );

    const link = `${shopifyAdmin}${uri}`;

    Platform.OS === "web" ? window.open(link) : Linking.openURL(link);
  };

  return content ? (
    UIConfig.map(({ uri, title }, index) => (
      <View key={index} style={tw("mb-2 flex-row")}>
        <Text onPress={() => onPress(uri)}>{"\u2022  "}</Text>
        <Text style={fonts.link1} onPress={() => onPress(uri)}>
          {title}
        </Text>
      </View>
    ))
  ) : (
    <View style={{ minHeight: 150 }}>
      <Spinner size="large" />
    </View>
  );
};

export default Analytics;
