import React, { memo } from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";
import moment from "moment";
import isEmpty from "lodash/isEmpty";

import { useSelector } from "react-redux";
import { fonts, shapes } from "styles/theme";

import I18NContext from "library/contexts/i18N";
import { selectSubscriptionData } from "library/sagas/views/home/drawer/create-order/selector";

const SubscriptionPauses = () => {
  const { messages, Localise } = React.useContext(I18NContext);
  const subscriptionDetails = useSelector(selectSubscriptionData);
  if (isEmpty(subscriptionDetails?.pause)) return null;

  return (
    <View
      style={{
        ...shapes.sectionBorder,
        marginRight: 10,
        marginTop: 15,
        width: "100%",
      }}
    >
      <View style={tw("flex")}>
        <View style={tw("flex justify-between")}>
          <Text style={[tw("mb-4"), fonts.sectionHeading]}>
            {Localise(messages, `Pause Details`)}
          </Text>
          <View>
            {subscriptionDetails.pause.map((each) => (
              <View
                key={each.pauseId}
                style={tw("flex flex-row flex-wrap justify-between")}
              >
                <Text>
                  {`Start Date: ${moment(each.startDate).format("MM/DD/YYYY")}`}
                </Text>
                <Text>
                  {`End Date: ${moment(each.endDate).format("MM/DD/YYYY")}`}
                </Text>
                <Text>{`Status: ${each.status}`}</Text>
              </View>
            ))}
          </View>
        </View>
      </View>
    </View>
  );
};

export default memo(SubscriptionPauses);
