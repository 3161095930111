import Storage from "library/utils/storage";

const FormReloadCache = new Storage("FormReloadCache", {
  throttleWait: 0,
});

const load = () => FormReloadCache.load();

const clear = () => {
    FormReloadCache.clear();
};

const getFormValues = (title) => {
  return FormReloadCache.get(`formValues${title}`) || {};
};

const setFormValues = (title,values) => {
    FormReloadCache.put(`formValues${title}`, values);
};

export default {
  clear,
  load,
  getFormValues,
  setFormValues,
};
