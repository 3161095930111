import React, { useState } from "react";
import { View, Platform, TouchableOpacity } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Text, Image } from "react-native-elements";
import { PopupMenu, ToasterHandler, CustomModal } from "components/elements";
import isArray from "lodash/isArray";
import I18NContext from "library/contexts/i18N";

import { IMAGES } from "static/assets/images";
import { colors } from "styles/theme";
import styles from "../styles";
import tw from "tailwind-rn";

const Item = ({
  id,
  data,
  columns,
  menu,
  style,
  border,
  selectors: { selectActiveItem },
  actions: { setActiveItem },
}) => {
  const dispatch = useDispatch();
  const { [id]: itemId } = data;
  const selectedItemId = useSelector(selectActiveItem);
  const activeItem = selectedItemId === itemId;
  const meatBallsMenu = menu.filter((e) => e.condition(data));
  const { Localise, messages } = React.useContext(I18NContext);

  const [currentMenu, setCurrentMenu] = useState();
  const [modalVisible, setModalVisibile] = useState(false);

  const onContinueAction = () => {
    setModalVisibile(false);
    onAction(currentMenu, true);
  };

  const onAbortAction = () => {
    setModalVisibile(false);
    setCurrentMenu();
  };

  const onAction = (key, approved) => {
    const { action, modal, success, error } = meatBallsMenu.find(
      (e) => e.key === key
    );

    if (!approved && modal) {
      setModalVisibile(true);
      setCurrentMenu(key);
      return true;
    }

    dispatch(
      action({
        type: "meat-ball",
        value: {
          data,
          todo: key,
        },
        resolve: () => {
          success
            ? ToasterHandler("looks good", Localise(messages, success(data)))
            : null;
        },
        reject: () => {
          error
            ? ToasterHandler("uh oh", Localise(messages, error(data)))
            : null;
        },
      })
    );
  };

  const onSelect = () => {
    dispatch(setActiveItem(itemId));
  };

  return (
    <TouchableOpacity
      activeOpacity={0.6}
      onPress={() => onSelect()}
      style={{
        minWidth: 300,
      }}
      testID={itemId}
      accessibilityLabel={itemId}
    >
      <View
        style={[styles.container, activeItem && styles.activeRowContainer]}
        fsClass="fs-unmask"
      >
        <View
          style={[
            styles.row,
            activeItem && styles.activeRow,
            {
              borderColor: colors[border[data.status]],
              ...style,
            },
          ]}
          fsClass="fs-unmask"
        >
          {columns.map(({ column, style, type }, index) => {
            return type === "icon" ? (
              <View style={styles.column} key={index}>
                {data[column] ? (
                  <Image source={IMAGES[data[column]]} style={{ ...style }} />
                ) : (
                  <Text style={{ ...style }}></Text>
                )}
              </View>
            ) : type === "image-text" ? (
              <View style={styles.column} key={index}>
                <Image
                  source={{ uri: data[column[0]] }}
                  style={{ ...style[0] }}
                />
                <Text
                  numberOfLines={1}
                  style={[styles.column, { ...style[1] }]}
                >
                  {data[column[1]]}
                </Text>
              </View>
            ) : type === "price-text" ? (
              <Text
                numberOfLines={1}
                style={[styles.column, { ...style }]}
                key={index}
              >
                {isArray(data[column])
                  ? data[column].map((e) => `$${e}`).join(" - ")
                  : `$${data[column]}`}
              </Text>
            ) : (
              <Text
                numberOfLines={1}
                style={[styles.column, { ...style }]}
                key={index}
              >
                {isArray(data[column]) ? data[column].join(", ") : data[column]}
              </Text>
            );
          })}
        </View>
        {meatBallsMenu.length > 0 ? (
          <>
            <PopupMenu
              containerStyle={styles.menuContainer}
              optionsContainerStyle={{
                width: 150,
                padding: 5,
              }}
              menuOptions={meatBallsMenu}
              onSelect={onAction}
              testID="list_item_menu"
              accessibilityLabel="list_item_menu"
            >
              <Image
                style={{
                  width: 20,
                  height: 20,
                }}
                resizeMode="contain"
                source={IMAGES[`three-dots`]}
              />
            </PopupMenu>
            {currentMenu ? (
              <ConfirmActionModal
                modalVisible={modalVisible}
                handleSecondary={onAbortAction}
                handlePrimary={onContinueAction}
                data={meatBallsMenu.find((e) => e.key === currentMenu)}
              />
            ) : null}
          </>
        ) : (
          <View style={{ width: 30 }}></View>
        )}
      </View>
    </TouchableOpacity>
  );
};

const ConfirmActionModal = ({
  modalVisible,
  handlePrimary,
  handleSecondary,
  data = {},
}) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const {
    modal: {
      primary: textPrimary = "Continue",
      secondary: textSecondary = "Nevermind",
      content: textContent = "Are you sure you want to proceed?",
    } = {},
  } = data;

  const modalContent = {
    content: (
      <Text style={{ ...tw("p-4"), fontSize: 15, color: colors.highlighter }}>
        {Localise(messages, textContent)}
      </Text>
    ),
    buttons: [
      { type: "secondary", title: Localise(messages, textSecondary) },
      { type: "primary", title: Localise(messages, textPrimary) },
    ],
  };

  return (
    <CustomModal
      modalVisible={modalVisible}
      modalContent={modalContent}
      primaryhandler={handlePrimary}
      secondaryhandler={handleSecondary}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: "white" },
      ]}
      modalStyle={
        Platform.OS !== "web" && {
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          backgroundColor: "#00000070",
          color: "#FFFFFF",
        }
      }
    ></CustomModal>
  );
};

export default Item;
