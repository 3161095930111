import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectPageStatus = createSelector(
  selectState,
  ({ status }) => status
);

export const selectApiError = createSelector(
  selectState,
  ({ apiError }) => apiError
);

export const selectIsPageLoading = createSelector(
  selectPageStatus,
  (status) => status === "progress"
);

export const selectIsPageFailed = createSelector(
  selectPageStatus,
  (status) => status === "fail"
);

export const selectIsPageLoaded = createSelector(
  selectPageStatus,
  (status) => status === "done"
);

export const selectApiResponse = createSelector(
  selectState,
  ({ apiResponse }) => apiResponse
);

export const selectPageData = createSelector(selectState, ({ data }) => data);

export const selectGiftCard = createSelector(
  selectPageData,
  ({ giftCards }) => giftCards
);

export const selectTotalNumberOfRecords = createSelector(
  selectPageData,
  ({ totalRecords }) => totalRecords
);

export const selectGiftCardDetails = createSelector(
  selectPageData,
  ({ giftCardDetails }) => giftCardDetails
);

export const selectGiftCardActivityInfo = createSelector(
  selectPageData,
  ({ activityInfo }) => activityInfo || { activities: [] }
);

export const selectExtendedScreenData = createSelector(
  selectState,
  ({ extendedScreen }) => extendedScreen
);

export const selectShowUpsert = createSelector(
  selectExtendedScreenData,
  ({ showUpsert }) => showUpsert
);

export const selectCurrentGiftCardId = createSelector(
  selectExtendedScreenData,
  ({ giftCardId }) => {
    return giftCardId;
  }
);

export const selectPageActions = createSelector(
  selectState,
  ({ actions }) => actions
);

export const selectAppliedLimits = createSelector(
  selectPageActions,
  ({ limit }) => limit.giftCards
);

export const selectSearchText = createSelector(
  selectPageActions,
  ({ search }) => search
);

export const selectAppliedShops = createSelector(
  selectPageActions,
  ({ shops }) => shops
);

export const selectNoop = createSelector(selectState, () => "");

export const selectStoreOrigin = createSelector(
  selectExtendedScreenData,
  ({ storeOrigin }) => storeOrigin
);
