import isArray from "lodash/isArray";
import * as Yup from "yup";
import moment from "moment";

export const getValidationSchema = (
  component,
  title,
  Localise,
  messages,
  activeTab = "default-content"
) => {
  const mandatoryError = `${Localise(
    messages,
    "Please enter content for"
  )} ${title}`;

  const genericError = Localise(
    messages,
    "We were unable to process your request, please try again."
  );

  Yup.addMethod(Yup.string, "emptyFieldsCheck", function (errorMessage) {
    return this.test(`empty-fields-check`, errorMessage, function () {
      const { path, createError, parent } = this;
      const { content, scheduledContent = "" } = parent;

      const isLandingPages = [
        "funeralSympathy",
        "funeralSympathyHome",
        "funeralSympathyService",
        "sympathyCollections",
        "wedding",
        "weddingReception",
        "weddingCeremony",
        "weddingCollections",
        "weddingEventsPortfolio",
      ].includes(component);

      if (isLandingPages || component === "homepageBannerMetadata") {
        let data = {};
        try {
          data = JSON.parse(path === "content" ? content : scheduledContent);
        } catch {
          //do nothing
        }

        const {
          bannerType = "",
          staticBanner = {},
          rotatingBanner = [],
        } = data["banner"] || data;

        let staticBannerEmptyValidation = Object.values(staticBanner).every(
          (value) => value !== ""
        );
        let rotatingBannerEmptyvalidation = rotatingBanner.every(
          (item) =>
            item.image &&
            item.title &&
            item.buttonLabel &&
            item.buttonLink &&
            item.subTitle &&
            item.altText
        );
        let isCollectionSetsExists = true;

        if (isLandingPages) {
          staticBannerEmptyValidation = Object.keys(staticBanner).every(
            () =>
              staticBanner["image"] &&
              staticBanner["title"] &&
              staticBanner["subTitle"] &&
              staticBanner["altText"]
          );
          rotatingBannerEmptyvalidation = rotatingBanner.every(
            (item) => item.image && item.title && item.subTitle && item.altText
          );
          if (component === "funeralSympathy" || component === "wedding") {
            const pageData =
              component === "wedding"
                ? data?.collectionSets.filter(
                    (val) =>
                      val.id !== "weddingScheduleConsultation" &&
                      val.id !== "weddingEventsPortfolio"
                  )
                : data?.collectionSets;

            isCollectionSetsExists = pageData.every(
              (item) =>
                item.title &&
                item.image &&
                item.buttonLink &&
                item.buttonText &&
                item.description
            );
          } else if (component !== "weddingEventsPortfolio") {
            isCollectionSetsExists = Object.keys(data?.collectionSets)
              .map((item) => {
                const itemValue = data?.collectionSets[item];
                if (isArray(itemValue)) {
                  return itemValue.every((val) => val !== "");
                } else {
                  return itemValue !== "";
                }
              })
              .every((e) => e);
          }
        }
        if (
          !bannerType ||
          (bannerType === "static" && !staticBannerEmptyValidation) ||
          (bannerType === "rotating" && !rotatingBannerEmptyvalidation) ||
          (isLandingPages && !isCollectionSetsExists)
        ) {
          return createError({
            path,
            message: errorMessage,
          });
        }
      } else if (component === "weddingScheduleConsultation") {
        let data = {};
        try {
          data = JSON.parse(content);
        } catch {
          //do nothing
        }
        const {
          consultationForm: { fields },
        } = data;
        const isQuestionFieldEmpty = fields.every(
          (value) =>
            value !== "" && value.question !== "" && value.inputType !== ""
        );

        if (!isQuestionFieldEmpty) {
          return createError({
            path,
            message: "Question field and Input type cannot be empty",
          });
        }
      } else if (component === "brandSelectorConfig") {
        let data = {};
        try {
          data = JSON.parse(content);
        } catch {
          //do nothing
        }
        const { brands = [], show_brand_selector = true } = data;
        const brandSelectorEmptyValidation = brands.every(
          (brand) => brand.brand_url && (brand.logo || brand.brand_name)
        );

        if (show_brand_selector && !brandSelectorEmptyValidation) {
          return createError({
            path,
            message: `Below fields are mandatory in all Brand sections \n 1. Brand URL \n 2. Either Brand Logo or Brand Name`,
          });
        }
      }

      if (activeTab === "scheduled-content" && scheduledContent !== "") {
        let scheduledContentData = {};
        try {
          scheduledContentData = JSON.parse(scheduledContent);
        } catch {
          //do nothing
        }

        const { startDate = "", endDate = "" } = scheduledContentData;

        const isEndDateAfterStart =
          !startDate || !moment(startDate).isAfter(endDate);

        if (!isEndDateAfterStart) {
          return createError({
            path: "scheduledContent",
            message: "End date cannot be before start date",
          });
        }
      }

      return true;
    });
  });

  return Yup.object().shape({
    ...(activeTab === "default-content"
      ? {
          content: Yup.string()
            .required(mandatoryError)
            .emptyFieldsCheck("All fields in the above sections are mandatory")
            .test("contentUpdateFailed", genericError, function (value) {
              const {
                parent: { error },
              } = this;
              return !error && !!value;
            }),
        }
      : {
          scheduledContent: Yup.string().emptyFieldsCheck(
            "All fields in the above sections are mandatory"
          ),
        }),
  });
};
