import React from "react";
import { ActivityIndicator, ScrollView, View } from "react-native";
import ScreenHeader from "components/containers/header/screen";
import Header from "./header";
import List from "./content";
import { DeviceContext } from "library/contexts/appSettings";
import Controls, { SelectedControls } from "./header/controls";
import tw from "tailwind-rn";
import { useSelector } from "react-redux";
import { selectNoop } from "library/sagas/views/home/drawer/customer-directory/selector";
import { colors } from "styles/theme";

const Listing = ({ UIConfig, onComplete }) => {
  const {
    heading,
    ListingHeader,
    controls,
    sections,
    selectors,
    showHeader = true,
    headerTitleWidth = 200,
    scrollContainerStyles = {},
    scrollStyle = {},
    showV2Header = false,
    showTitle = true,
    createRoute,
    controlsStyles = { padding: 20 },
    showCreate = false,
    showSelectedControls = true,
  } = UIConfig;
  const { isDesktop } = React.useContext(DeviceContext);
  const pageActions = useSelector(selectors?.selectPageActions || selectNoop);
  const Component = pageActions?.create;
  const HeaderSiblings = () => {
    const loading = useSelector(selectors?.showLoading);
    return loading ? (
      <ActivityIndicator
        color={colors.activityIndicator}
        style={{ height: 25, paddingLeft: 15 }}
      />
    ) : null;
  };

  return (
    <>
      <>
        {showHeader &&
          (showV2Header ? (
            <React.Fragment>
              {showTitle && (
                <ScreenHeader
                  title={heading}
                  settingsRoute={{
                    page: "shop-settings",
                    screen: "order-settings",
                  }}
                  createRoute={createRoute}
                  loader={HeaderSiblings}
                />
              )}
              <View
                style={[
                  tw("flex flex-row items-center"),
                  isDesktop && { maxWidth: "100%" },
                  controlsStyles,
                ]}
              >
                <Controls data={controls} position={"header"} />
              </View>
              {showSelectedControls && (
                <View style={{ paddingLeft: 20 }}>
                  {isDesktop && <SelectedControls data={controls} />}
                </View>
              )}
            </React.Fragment>
          ) : (
            <Header
              key={"header"}
              title={heading}
              controls={controls}
              selectors={selectors}
              headerTitleWidth={headerTitleWidth}
            />
          ))}
      </>

      <>{ListingHeader && ListingHeader({ selectors })}</>

      <ScrollView
        key={"content"}
        style={{ ...scrollStyle }}
        contentContainerStyle={{
          padding: isDesktop ? 5 : 0,
          paddingTop: 0,
          minHeight: 100,
          ...scrollContainerStyles,
        }}
      >
        {showCreate && typeof Component !== "undefined" && (
          <Component defaultOpen={true} onComplete={onComplete} />
        )}
        {sections.map(({ section, component: { props } }, index) => {
          return (
            <List
              {...props}
              key={index}
              sectionName={section}
              onComplete={onComplete}
            />
          );
        })}
      </ScrollView>
    </>
  );
};

export default Listing;
