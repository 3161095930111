import React from "react";
import { View, TouchableOpacity } from "react-native";
import { Text, Image } from "react-native-elements";
import { useNavigation, CommonActions } from "@react-navigation/native";
import IMAGES from "static/assets/images";
import pick from "lodash/pick";
import has from "lodash/has";
import styles from "./styles";
import I18NContext from "library/contexts/i18N";
import { Entitlements } from "library/utils/entitlements";
import { getMemberCodesWithFeature } from "library/utils/featureAvailability";
import { AppSettingsContext } from "library/contexts/appSettings";
import { Tooltip } from "components/elements";

const OrderTomorrowWidget = ({
  widgetResponse,
  orderCounts,
  shopCodes,
  shopNames,
  isSmallScreen,
  onPress,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { permissions } = React.useContext(AppSettingsContext);
  const navigation = useNavigation();

  // Displaying the 'Designed' action widget for members with Design Center feature
  const designCenterMembers = getMemberCodesWithFeature(
    permissions,
    Entitlements.ORDERS,
    "DESIGNER"
  );

  const showDesignedStatus =
    designCenterMembers?.length > 0 ? ["designed"] : [];

  const ordersTomorrow = pick(widgetResponse, [
    "count",
    "incoming",
    "accepted",
    "design",
    ...showDesignedStatus,
    "delivery",
    "completed",
  ]);
  const bottomSection = pick(widgetResponse, [
    "rejected",
    "forfeited",
    "cancelled",
  ]);

  const config = {
    count: {
      displayStatus: "Tomorrow’s Orders",
      iconExists: true,
      styleConfig: {
        styleName: "titleHeader",
        labelSize: isSmallScreen ? 16 : 20,
        labelColor: "#ffffff",
        stripColor: "#3a7d8d",
        blockBgColor: "#3a7d8d",
      },
    },
    incoming: {
      displayStatus: "Incoming",
      iconExists: true,
      iconName: "incoming",
      styleConfig: {
        labelSize: 14,
        labelColor: "#114258",
        stripColor: "#e2f7ee",
        blockBgColor: "#fff",
        iconWidth: 28,
        iconHeight: 28,
      },
    },
    accepted: {
      displayStatus: "Accepted",
      iconExists: true,
      iconName: "accepted",
      styleConfig: {
        labelSize: 14,
        labelColor: "#114258",
        stripColor: "#aadec6",
        blockBgColor: "#fff",
        iconWidth: 30,
        iconHeight: 30,
      },
    },
    design: {
      displayStatus: "In Design",
      iconExists: true,
      iconName: "design",
      styleConfig: {
        labelSize: 14,
        labelColor: "#114258",
        stripColor: "#31aa92",
        blockBgColor: "#fff",
        iconWidth: 34,
        iconHeight: 35,
      },
    },
    designed: {
      displayStatus: "Designed",
      iconExists: true,
      iconName: "designed",
      styleConfig: {
        labelSize: 14,
        labelColor: "#114258",
        stripColor: "#037c77",
        blockBgColor: "#fff",
        iconWidth: 34,
        iconHeight: 35,
      },
    },
    delivery: {
      displayStatus: "Delivery",
      iconExists: true,
      iconName: "delivery",
      styleConfig: {
        labelSize: 14,
        labelColor: "#114258",
        stripColor: "#11838e",
        blockBgColor: "#fff",
        iconWidth: 42,
        iconHeight: 27,
      },
    },
    completed: {
      displayStatus: "Completed",
      iconExists: true,
      iconName: "completed",
      styleConfig: {
        labelSize: 14,
        labelColor: "#114258",
        stripColor: "#114258",
        blockBgColor: "#fff",
        iconWidth: 36,
        iconHeight: 30,
      },
    },
    rejected: {
      displayStatus: "Rejected",
      iconExists: false,
      styleConfig: {
        labelSize: 14,
        labelColor: "#114258",
        stripColor: "#114258",
        blockBgColor: "#fff",
      },
    },
    forfeited: {
      displayStatus: "Forfeited",
      iconExists: false,
      styleConfig: {
        labelSize: 14,
        labelColor: "#114258",
        stripColor: "#114258",
        blockBgColor: "#fff",
      },
    },
    cancelled: {
      displayStatus: "Canceled",
      iconExists: false,
      styleConfig: {
        labelSize: 14,
        labelColor: "#114258",
        stripColor: "#114258",
        blockBgColor: "#fff",
      },
    },
  };

  const handleOnPress = (status) => {
    const isActiveOrder =
      status === "Tomorrow’s Orders"
        ? true
        : [
            "Incoming",
            "Accepted",
            "In Design",
            "Designed",
            "Delivery",
            "Completed",
          ].includes(status);

    const filters = [];
    const shops = [];

    if (isActiveOrder) {
      filters.push({
        section: "Categories",
        title: "Tomorrow's Orders",
        value: "tomorrowOrders",
      });
      filters.push({
        section: "Categories",
        title: "Needs Action",
        value: "needsAction",
      });

      status !== "Tomorrow’s Orders" &&
        filters.push({
          section: "Order Status",
          title: status,
          value: status?.replace(" ", "-")?.toLowerCase(),
        });
      filters.push({
        section: "Date Range",
        title: "Tomorrow",
        value: "1",
      });
      //Inactive Order
    } else {
      filters.push({
        section: "Categories",
        title: `${status} Orders`,
        value: `${
          status === "Canceled" ? "cancelled" : status.toLowerCase()
        }Orders`,
      });
      filters.push({
        section: "Date Range",
        title: "Tomorrow",
        value: "1",
      });
    }

    filters.push({
      section: "Categories",
      title: "Inbound Orders",
      value: "INBOUND",
    });

    shopCodes !== "all" &&
      shops.push({
        section: "Shop Codes",
        title: `${shopCodes} ${shopNames[shopCodes]}`,
        value: shopCodes,
      });

    const name = "orders";
    onPress(name);
    navigation.dispatch(
      CommonActions.navigate({
        name,
        params: {
          filters,
          shops,
        },
      })
    );
  };

  return (
    <View
      style={
        isSmallScreen && {
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.4,
          shadowRadius: 4,
          elevation: 3,
          borderWidth: 1,
          borderColor: "#FFFFFF44",
        }
      }
    >
      <View
        style={
          has(ordersTomorrow, "designed")
            ? styles.premiumOrderTodayContainer
            : styles.orderTodayContainer
        }
      >
        {Object.keys(ordersTomorrow).map((item, index) => {
          const {
            displayStatus,
            iconExists,
            iconName,
            styleConfig = {},
          } = config[item] || {};
          const isShopBagEligible =
            ["accepted", "design", "designed", "delivery"].includes(item) &&
            orderCounts[item] &&
            orderCounts[item].pickupOrders > 0;

          const isNewPickupOrder =
            ["incoming"].includes(item) &&
            orderCounts["new"] &&
            orderCounts["new"].pickupOrders > 0;

          const isPayLaterShopBagEligible =
            ["accepted", "design", "designed", "delivery"].includes(item) &&
            orderCounts[item] &&
            orderCounts[item].payLaterPickupOrders > 0;

          const isNewPayLaterPickupOrder =
            ["incoming"].includes(item) &&
            orderCounts["new"] &&
            orderCounts["new"].payLaterPickupOrders > 0;

          if (!displayStatus) return null;

          return (
            <View
              key={index}
              style={{
                ...(has(ordersTomorrow, "designed")
                  ? styles.eachBlockPremium
                  : styles.eachBlock),
                ...(styleConfig.styleName ? styles[styleConfig.styleName] : {}),
                backgroundColor: styleConfig.blockBgColor,
              }}
            >
              <TouchableOpacity
                style={{
                  ...styles.eachBlockContent,
                  ...(styleConfig.styleName
                    ? styles[`${styleConfig.styleName}Content`]
                    : {}),
                }}
                //containerStyle={{ flex: 1 }}
                onPress={() => handleOnPress(displayStatus)}
                testID={`tomorrow_${displayStatus}`}
                accessibilityLabel={`tomorrow_${displayStatus}`}
              >
                <Text
                  style={{
                    ...styles.countText,
                    color: styleConfig.labelColor,
                    ...(styleConfig.styleName
                      ? styles[`${styleConfig.styleName}Text`]
                      : {}),
                  }}
                >
                  {widgetResponse[item]}
                </Text>
                <Text
                  style={{
                    fontSize: styleConfig.labelSize,
                    color: styleConfig.labelColor,
                    fontWeight: isSmallScreen
                      ? styleConfig.styleName
                        ? "600"
                        : "400"
                      : "600",
                    ...styles.orderStatus,
                  }}
                >
                  {Localise(messages, displayStatus)}
                </Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  {iconExists && IMAGES[iconName] && (
                    <Tooltip
                      text={Localise(messages, displayStatus)}
                      renderForWebOnly={true}
                    >
                      <Image
                        style={{
                          width: styleConfig.iconWidth,
                          height: styleConfig.iconHeight,
                          ...styles.eachBlockIcon,
                        }}
                        source={IMAGES[iconName]}
                      />
                    </Tooltip>
                  )}
                  {!!isShopBagEligible && IMAGES[`${item}-pickup`] && (
                    <Tooltip
                      text={Localise(messages, "Pickup")}
                      renderForWebOnly={true}
                    >
                      <Image
                        style={{
                          width: 25,
                          height: 30,
                          marginLeft: 15,
                          ...styles.eachBlockIcon,
                        }}
                        source={IMAGES[`${item}-pickup`]}
                      />
                    </Tooltip>
                  )}
                  {!!isNewPickupOrder && IMAGES[`incoming-pickup`] && (
                    <Tooltip
                      text={Localise(messages, "Incoming")}
                      renderForWebOnly={true}
                    >
                      <Image
                        style={{
                          width: 25,
                          height: 30,
                          marginLeft: 15,
                          ...styles.eachBlockIcon,
                        }}
                        source={IMAGES[`incoming-pickup`]}
                      />
                    </Tooltip>
                  )}
                  {!!isPayLaterShopBagEligible &&
                    IMAGES[`pay-later-${item}-pickup`] && (
                      <Tooltip
                        text={Localise(messages, "Paylater")}
                        renderForWebOnly={true}
                      >
                        <Image
                          style={{
                            width: 25,
                            height: 30,
                            marginLeft: 15,
                            ...styles.eachBlockIcon,
                          }}
                          source={IMAGES[`pay-later-${item}-pickup`]}
                        />
                      </Tooltip>
                    )}
                  {!!isNewPayLaterPickupOrder &&
                    IMAGES[`pay-later-incoming-pickup`] && (
                      <Tooltip
                        text={Localise(messages, "Paylater")}
                        renderForWebOnly={true}
                      >
                        <Image
                          style={{
                            width: 25,
                            height: 30,
                            marginLeft: 15,
                            ...styles.eachBlockIcon,
                          }}
                          source={IMAGES[`pay-later-incoming-pickup`]}
                        />
                      </Tooltip>
                    )}
                </View>
              </TouchableOpacity>
              <View
                style={{
                  ...styles.stripContainer,
                  ...(styleConfig.styleName
                    ? styles[`${styleConfig.styleName}Strip`]
                    : {}),
                  backgroundColor: styleConfig.stripColor,
                }}
              />
            </View>
          );
        })}
      </View>
      <View style={styles.orderTodayBottomStrip}>
        <View
          style={
            has(ordersTomorrow, "designed")
              ? styles.premiumBottomStripHeader
              : styles.bottomStripHeader
          }
        >
          <Text style={{ fontSize: 14, color: "#6a6a6a", fontWeight: "600" }}>
            {Localise(messages, "Inactive Orders")}
          </Text>
        </View>
        {Object.keys(bottomSection).map((item, index) => {
          const { displayStatus = "", styleConfig = {} } = config[item] || {};

          return (
            displayStatus && (
              <TouchableOpacity
                key={index}
                style={{
                  ...styles.bottomStripContent,
                  backgroundColor: styleConfig.blockBgColor,
                }}
                onPress={() => handleOnPress(displayStatus)}
                testID={`tomorrow_inactive_${displayStatus}`}
                accessibilityLabel={`tomorrow_inactive_${displayStatus}`}
              >
                <View
                  style={{
                    paddingVertical: 10,
                    paddingHorizontal: 20,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#6a6a6a",
                      paddingRight: 10,
                      fontWeight: "600",
                    }}
                  >
                    {widgetResponse[item]}
                  </Text>
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#6a6a6a",
                      fontWeight: isSmallScreen ? "400" : "600",
                    }}
                  >
                    {Localise(messages, displayStatus)}
                  </Text>
                </View>
              </TouchableOpacity>
            )
          );
        })}
      </View>
    </View>
  );
};

export default OrderTomorrowWidget;
