export const DeleteButtonNameMap = {
  suspended: "Resume",
  upcoming: "Delete",
  new: "Cancel",
  past: "Delete",
};

export const IndicatorMapping = {
  suspended: "red",
  upcoming: "highlighter",
  new: "active",
  past: "grayScaleLight",
};
