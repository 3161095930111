import React from "react";
import { Image, ScrollView, View } from "react-native";
import { Divider, Text } from "react-native-elements";

import tw from "tailwind-rn";
import moment from "moment";

import { colors, fonts, shapes } from "styles/theme";
import IMAGES from "static/assets/images";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { generateDetailContent } from "components/views/drawer/order-details/helper";
import { phoneNumberFormatter } from "library/utils/formatter";
import {
  extractTimeFromDI,
  DELIVER_BY_REGEX,
} from "components/views/drawer/create-order/helper";

const OrderDetails = ({ trackingDetails }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);

  const orderItemDetails = trackingDetails?.order?.orderItems?.[0] || {};

  const {
    recipientInfo: {
      firstName = "",
      lastName = "",
      addressLine1 = "",
      addressLine2 = "",
      city = "",
      state = "",
      zip = "",
      phone = "",
    } = {},
    deliveryInfo: {
      deliveryDate = "",
      deliveryInstructions = "",
      deliveryMethod = "",
      orderDeliveryTime = "",
    } = {},
    lineItems = [],
    pickupInfo: { storePickupDateTime = "" } = {},
    isRushOrder = false,
  } = orderItemDetails;

  const hasRecipientInfo = firstName.length > 0 || lastName.length > 0;

  const recipientContent = {
    Name: { label: "", value: `${firstName} ${lastName}` },
    AddressLine1: { label: "", value: addressLine1 },
    AddressLine2: { label: "", value: addressLine2 },
    CityStateZip: { label: "", value: `${city}, ${state} ${zip}` },
    Phone: { label: "", value: phoneNumberFormatter(phone) },
  };

  const isPickupOrder = [
    "STORE_PICKUP",
    "MOL_CUSTOMER_PICKUP",
    "PARTNER_STORE_PICKUP",
  ].includes(deliveryMethod);

  const deliverByTime =
    orderDeliveryTime ||
    (isRushOrder && extractTimeFromDI(deliveryInstructions)); //For old rush orders before timed delivery feature

  const displayTime = isPickupOrder || !!deliverByTime;

  const pickOrDeliverByTime = isPickupOrder
    ? moment(storePickupDateTime).format("h:mm A")
    : deliverByTime || "";

  let formattedDeliveryInstructions = deliveryInstructions;
  if (deliverByTime) {
    formattedDeliveryInstructions = formattedDeliveryInstructions.replace(
      DELIVER_BY_REGEX,
      ""
    );
  }

  return (
    <View
      style={[
        tw("flex flex-col"),
        {
          ...shapes.sectionBorder,
          width: isDesktop ? 328 : "100%",
          height: 540,
          padding: 0,
          marginHorizontal: isDesktop ? 11 : 0,
          marginVertical: 8,
        },
      ]}
    >
      <View
        style={{
          padding: 15,
        }}
      >
        <Text
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: colors.highlighter,
            marginLeft: 15,
          }}
        >
          {Localise(messages, "Order Details")}
        </Text>
      </View>

      <Divider style={{ marginVertical: 0 }} />

      <ScrollView
        style={{ padding: 15, marginLeft: 15 }}
        nativeID="order_status_scrollbar"
      >
        <View style={{ marginTop: 0 }}>
          <Text
            style={{
              ...fonts.default,
              fontSize: 15,
              fontWeight: "bold",
            }}
          >
            {Localise(
              messages,
              isPickupOrder ? "Pickup Date" : "Delivery Date"
            )}
          </Text>

          <Text style={{ ...fonts.default, fontSize: 14 }}>
            {moment(deliveryDate).format("dddd MMM DD, YYYY")}
          </Text>
        </View>

        {displayTime && (
          <View style={{ marginTop: 25 }}>
            <Text
              style={{
                ...fonts.default,
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              {Localise(messages, isPickupOrder ? "Pickup Time" : "Deliver By")}
            </Text>

            <Text style={{ ...fonts.default, fontSize: 14 }}>
              {pickOrDeliverByTime}
            </Text>
          </View>
        )}

        <View style={{ marginTop: 25 }}>
          <Text
            style={{
              ...fonts.default,
              fontSize: 15,
              fontWeight: "bold",
            }}
          >
            {Localise(messages, isPickupOrder ? "Pickup By" : "Deliver To")}
          </Text>

          {hasRecipientInfo && generateDetailContent(recipientContent, true)}
        </View>

        <View style={{ marginTop: 15 }}>
          <Text
            style={{
              ...fonts.default,
              fontSize: 15,
              fontWeight: "bold",
            }}
          >
            {Localise(
              messages,
              isPickupOrder ? "Pickup Notes" : "Delivery Notes"
            )}
          </Text>

          <Text style={{ ...fonts.default, fontSize: 14 }}>
            {formattedDeliveryInstructions}
          </Text>
        </View>

        <View style={{ marginTop: 25 }}>
          <Text
            style={{
              ...fonts.default,
              fontSize: 15,
              fontWeight: "bold",
            }}
          >
            {Localise(messages, "Product Name")}
          </Text>

          {lineItems.map((lineItem, index) => {
            const { productFirstChoiceDescription = "", productImageUrl = "" } =
              lineItem;

            return (
              <View key={`lineItem_${index}`} style={{ marginVertical: 10 }}>
                <Text style={{ ...fonts.default, fontSize: 14 }}>
                  {productFirstChoiceDescription}
                </Text>

                <Image
                  style={{
                    width: 150,
                    height: 150,
                    borderWidth: 1,
                    borderColor: colors.light,
                    marginTop: 5,
                  }}
                  resizeMode="cover"
                  source={
                    productImageUrl
                      ? { uri: productImageUrl }
                      : IMAGES[`quick-pick`]
                  }
                />
              </View>
            );
          })}
        </View>
      </ScrollView>
    </View>
  );
};

export default OrderDetails;
