import { createStyles, minWidth } from "react-native-media-queries";
import { colors, backgroundColors } from "styles/theme";

export default createStyles(
  {
    recordContainer: {
      flexDirection: "row",
      borderWidth: 1,
      borderColor: colors.grayScaleLight,
      borderRadius: 3,
      minHeight: 50,
      marginBottom: 5,
      padding: 5,
      justifyContent: "space-between",
      //flex: 1,
    },
    shop: {
      zIndex: 40,
      maxWidth: 800,
      marginTop: 5,
      padding: 0,
      paddingLeft: 0,
      paddingRight: 10,
    },
    activeRecordContainer: {
      borderWidth: 1,
      borderColor: backgroundColors.primary,
      backgroundColor: "#FAFAFA",
    },
    activeRecord: {
      color: backgroundColors.primary,
      fontWeight: "600",
    },
    menuContainer: {
      width: "10%",
      paddingRight: 10,
      alignItems: "center",
    },
    record: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "baseline",
      flexWrap: "wrap",
      borderLeftWidth: 8,
      width: "90%",
      paddingVertical: 6,
      paddingRight: 10,
      paddingLeft: 5,
    },
    addStopRecord: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "baseline",
      flexWrap: "wrap",
      borderLeftWidth: 8,
      paddingVertical: 6,
      paddingRight: 10,
      paddingLeft: 5,
      width: "100%",
    },
    recordItem: {
      textTransform: "capitalize",
    },
    subRecordItem: {
      width: 0,
      height: 0,
      opacity: 0,
    },
    recordItemView: {
      paddingRight: 5,
      paddingVertical: 4,
      overflow: "hidden",
    },
    statusText: {
      width: "auto",
    },
    statusIcon: {
      width: "auto",
    },
    /* Order Listing page widths */
    productFirstChoiceDescription: {
      width: "70%",
    },
    recipientCity: {
      width: "45%",
    },
    zip: {
      width: "55%",
    },
    recipientName: {
      width: "60%",
    },
    locationType: {
      width: "40%",
    },
    messageNumber: {
      width: "100%",
    },
    displayPrice: {
      width: "30%",
    },
    status: {
      width: "70%",
    },
    displayDeliveryDate: {
      width: "35%",
    },
    deliveryInstructions: {
      width: "65%",
    },
    items: {
      width: "30%",
    },
    labelTextContainer: {
      width: "40%",
    },
    labelText: {
      fontWeight: "500",
      lineHeight: 25,
    },
    valueText: {
      width: "60%",
      textTransform: "capitalize",
    },
    zoneName: {
      width: "18%",
    },
  },
  minWidth(767, {
    record: {
      paddingVertical: 3,
      flexWrap: "nowrap",
      width: "87%",
      alignItems: "baseline",
    },
    menuContainer: {
      width: "13%",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    recordItemView: {
      paddingVertical: 7,
    },
    subRecordItem: {
      fontSize: 10,
      textTransform: "capitalize",
      //textAlign: "center",
      width: "100%",
      height: "100%",
      opacity: 1,
    },
    statusText: {
      width: "65%",
    },
    statusIcon: {
      width: "35%",
    },
    productFirstChoiceDescription: {
      width: "15%",
    },
    recipientCity: {
      width: "15%",
    },
    recipientName: {
      width: "13%",
    },
    messageNumber: {
      width: "18%",
    },
    displayPrice: {
      width: "10%",
    },
    status: {
      width: "18%",
    },
    displayDeliveryDate: {
      width: "20%",
    },
  }),
  minWidth(1100, {
    record: {
      paddingVertical: 3,
      flexWrap: "nowrap",
      width: "90%",
      alignItems: "baseline",
    },
    menuContainer: {
      width: "10%",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    recordItemView: {
      paddingVertical: 7,
    },
    subRecordItem: {
      fontSize: 10,
      textTransform: "capitalize",
      //textAlign: "center",
      width: "100%",
      height: "100%",
      opacity: 1,
    },
    productFirstChoiceDescription: {
      width: "15%",
    },
    recipientCity: {
      width: "10%",
    },
    recipientName: {
      width: "15%",
    },
    messageNumber: {
      width: "18%",
    },
    displayPrice: {
      width: "9%",
    },
    status: {
      width: "19%",
    },
    displayDeliveryDate: {
      width: "20%",
    },
    statusText: {
      width: "auto",
    },
    statusIcon: {
      width: "auto",
    },
    labelTextContainer: {
      width: "25%",
      paddingLeft: 5,
    },
    valueText: {
      width: "75%",
      textTransform: "capitalize",
    },
    zoneName: {
      width: "15%",
    },
  })
);
