import React, { useContext } from "react";
import { View, TouchableOpacity } from "react-native";
import { Text, Image } from "react-native-elements";
import tw from "tailwind-rn";
import { fonts, shapes, colors } from "styles/theme";
import IMAGES from "static/assets/images";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

export const AbbreviationCodes = ({ onComplete, sideCarInfo }) => {
  const { isTablet, isDesktop } = useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);

  return (
    <View
      style={{
        ...shapes.sectionBorder,
        padding: isDesktop ? 20 : 10,
        marginVertical: 15,
      }}
    >
      <View style={[tw("flex flex-row justify-between")]}>
        <Text
          style={{
            ...fonts.sectionHeading,
            color: colors.highlighter,
          }}
        >
          {Localise(messages, "Abbreviation Codes")}
        </Text>
        {!isTablet && (
          <TouchableOpacity
            onPress={() => onComplete("")}
            testID="close"
            accessibilityLabel="close"
          >
            <Image
              style={{ width: 20, height: 20 }}
              resizeMode="cover"
              source={IMAGES["close"]}
            />
          </TouchableOpacity>
        )}
      </View>

      <View style={[tw("mt-5")]}>
        <View style={tw("flex flex-row")}>
          <Text style={[fonts.heading4, { width: "35%" }]}>
            {Localise(messages, "Code")}
          </Text>
          <Text style={[fonts.heading4, { width: "65%" }]}>
            {Localise(messages, "Card Message")}
          </Text>
        </View>
        {sideCarInfo.map((abbreviation, key) => {
          return (
            <View style={tw("flex flex-row mt-3")} key={key}>
              <Text style={[fonts.heading5, { width: "35%" }]}>
                {abbreviation.code}
              </Text>
              <Text style={([fonts.heading5], { width: "65%" })}>
                {abbreviation.cardMessage}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default AbbreviationCodes;
