export const InitData = [];

export const ListOfColumns = [
  "name",
  "role",
  "department",
  "emailId",
  "status",
];

export const ListOfFilters = {
  FTD_ADMIN: {
    "Type of User": [
      {
        title: "FTD ADMIN",
        value: "FTD_ADMIN",
      },
      {
        title: "FTD POWER USER",
        value: "FTD_POWER_USER",
      },
      {
        title: "FTD VIEW ONLY",
        value: "FTD_VIEW_ONLY",
      },
      {
        title: "FTD CSR",
        value: "FTD_CSR",
      },
    ],
    Status: [
      {
        title: "ACTIVE",
        value: "ACTIVE",
      },
      {
        title: "INACTIVE",
        value: "IN-ACTIVE",
      },
    ],
  },
  FTD_POWER_USER: {
    "Type of User": [
      {
        title: "FTD POWER USER",
        value: "FTD_POWER_USER",
      },
      {
        title: "FTD VIEW ONLY",
        value: "FTD_VIEW_ONLY",
      },
      {
        title: "FTD CSR",
        value: "FTD_CSR",
      },
    ],
  },
};

export const ListOfSortOptions = [
  { key: "Name: Ascending", label: "Name: Ascending" },
  { key: "Name: Descending", label: "Name: Descending" },
  { key: "Role: Ascending", label: "Role: Ascending" },
  { key: "Role: Descending", label: "Role: Descending" },
];

export const TypeOfUsers = [
  { label: "FTD Admin", value: "FTD_ADMIN" },
  { label: "FTD Power User", value: "FTD_POWER_USER" },
  { label: "FTD View Only", value: "FTD_VIEW_ONLY" },
  { label: "FTD CSR", value: "FTD_CSR" },
];

export const AllDepartments = [
  { label: "MTAC", value: "MTAC" },
  { label: "Development", value: "DEVELOPMENT" },
  { label: "Grocery", value: "GROCERY" },
  { label: "MSA", value: "MSA" },
  { label: "Member Services", value: "MEMBER_SERVICES" },
  { label: "Operations", value: "OPERATIONS" },
];

export const PermissionsforCreateUser = {
  FTD_ADMIN: true,
  FTD_POWER_USER: false,
  FTD_VIEW_ONLY: false,
  FTD_CSR: false,
};
