export const TaxRulesFields = () => [
  {
    name: "localOrderTaxSettings",
    title: "Tax Rules",
    formFieldType: "RadioButton",
    formFieldProps: {
      name: "localOrderTaxSettings",
      title: "Local Orders",
      options: [
        {
          label: "point_of_origin",
          value: "Point Of Origin",
        },
        {
          label: "destination",
          value: "Destination",
        },
      ],
    },
  },
  {
    name: "wireOrderTaxSettings",
    title: " ",
    formFieldType: "RadioButton",
    formFieldProps: {
      title: "Wire Orders",
      options: [
        {
          label: "point_of_origin",
          value: "Point Of Origin",
        },
        {
          label: "destination",
          value: "Destination",
        },
      ],
    },
  },
];
