import * as Yup from "yup";
import get from "lodash/get";
import moment from "moment";

export const getExceptionValidationSchema = (
  messages,
  Localise,
  allExceptions
) => {
  return Yup.object().shape({
    label: Yup.string()
      .required(Localise(messages, "Please enter Exception Label"))
      .test(
        "labelTest",
        Localise(messages, "Exception Label must be unique"),
        function (value) {
          const exceptionId = get(this, "parent.exceptionId", "");
          const isDuplicatelabel = allExceptions.some(
            (exception) =>
              get(exception, "label", "") === value &&
              get(exception, "exceptionId", "") !== exceptionId
          );
          return !isDuplicatelabel;
        }
      ),
    criteria: Yup.string().required(
      Localise(messages, "Please select Exception Criteria")
    ),
    cityZipInfo: Yup.object().shape({
      city: Yup.string().test(
        "city",
        Localise(messages, "Select City"),
        function (value) {
          if (
            get(this.options, "from.1.value.criteria", "") ===
              "CITY_ZIP_CODE" &&
            !value
          ) {
            return false;
          }
          return true;
        }
      ),
    }),
    rule: Yup.string().required(
      Localise(messages, "Please select Exception Rule")
    ),
    additionalFee: Yup.string().test(
      "additionalFee",
      Localise(messages, "Please Enter Additional Fee"),
      function (value) {
        const rule = get(this, "parent.rule", "");
        if (rule === "ADDITIONAL_FEE" && !value) {
          return false;
        }
        return true;
      }
    ),
    startDate: Yup.string().test(
      "startDate",
      Localise(messages, "End date is less than start date."),
      function (value) {
        const { endDate } = this.parent;
        const isEndDateAfterStart = !value || !moment(value).isAfter(endDate);
        if (isEndDateAfterStart) {
          return true;
        }
        return false;
      }
    ),
    endDate: Yup.string().test(
      "endDate",
      Localise(messages, "End date is less than start date."),
      function (value) {
        const { startDate } = this.parent;
        const isEndDateAfterStart = !value || !moment(startDate).isAfter(value);
        if (isEndDateAfterStart) {
          return true;
        }
        return false;
      }
    ),
  });
};

export const getGlobalFeesValidationSchema = (messages, Localise) => {
  return Yup.object().shape({
    localFee: Yup.string().inValidFees(Localise(messages, "Invalid Fee"), true),
    outsideLocalFee: Yup.string().inValidFees(
      Localise(messages, "Invalid Fee"),
      true
    ),
    serviceFee: Yup.string().inValidFees(
      Localise(messages, "Invalid Fee"),
      true
    ),
    relayFee: Yup.string().required(Localise(messages, "Invalid Fee")),
    rushDeliveryFee: Yup.string().inValidFees(
      Localise(messages, "Invalid Fee"),
      true
    ),
    rush_submitted_by: Yup.string().inValidFees(
      Localise(messages, "Rush Submitted By"),
      true
    ),
    rush_delivered_by: Yup.string().inValidFees(
      Localise(messages, "Rush Delivery By"),
      true
    ),
  });
};

export const getCitiesFeesValidationSchema = (messages, Localise) => {
  return Yup.array().of(
    Yup.object().shape({
      fee: Yup.string().inValidFees(Localise(messages, "Cities Fee"), true),
    })
  );
};

Yup.addMethod(
  Yup.string,
  "inValidFees",
  function (errorMessage, skipStatusCheck) {
    return this.test("invalid-fees", errorMessage, function (value) {
      const { path, createError, parent } = this;

      let {
        status,
        serviceFee,
        isActive,
        enableRushFee,
        rushDeliveryFee,
        rush_submitted_by,
        rush_delivered_by,
      } = parent;

      if (path === "serviceFee") {
        return createError({
          path,
          message: isActive === "true" && !serviceFee ? errorMessage : "",
        });
      }

      if (path === "rushDeliveryFee") {
        return createError({
          path,
          message:
            (!rushDeliveryFee || rushDeliveryFee < 0) && enableRushFee
              ? "Invalid Fee"
              : "",
        });
      }

      if (["rush_submitted_by", "rush_delivered_by"].includes(path)) {
        const submitVal =
          path === "rush_submitted_by" ? value : rush_submitted_by;
        const deliverVal =
          path === "rush_delivered_by" ? value : rush_delivered_by;
        const isError = moment(submitVal).isSameOrAfter(deliverVal);

        return createError({
          path,
          message: enableRushFee
            ? !value
              ? errorMessage
              : isError && path === "rush_delivered_by"
              ? "Invalid time"
              : ""
            : "",
        });
      }

      status = skipStatusCheck || status;
      if (
        status &&
        (value === "" || value === undefined || (value !== "" && isNaN(value)))
      )
        return createError({
          path,
          message: errorMessage,
        });
      return createError({ path, message: "" });
    });
  }
);

export const getNewCoverageAreaValidationSchema = (messages, Localise) => {
  return Yup.object().shape({
    cityZipcodesCoverage: Yup.array().of(
      Yup.object().shape({
        fee: Yup.string().inValidFees(Localise(messages, "Zip Code Fee")),
      })
    ),
  });
};

export const getCityZipcodesValidationSchema = (messages, Localise) => {
  return Yup.array().of(
    Yup.object().shape({
      fee: Yup.string().inValidFees(Localise(messages, "Zip Code Fee")),
    })
  );
};
