/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useContext, useRef, useMemo } from "react";
import { View, Platform, ActivityIndicator, Dimensions } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Text, Image, Button, CheckBox } from "react-native-elements";
import { bindActionCreators } from "redux";
import { connect, useSelector, useDispatch } from "react-redux";
import { CommonActions } from "@react-navigation/native";
import tw from "tailwind-rn";
import moment from "moment";

import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import toLower from "lodash/toLower";

import IMAGES from "static/assets/images";

import {
  setIsRouteSave,
  setIsRouteEditing,
  setIsAdhocStopEditing,
  setIsRouteSaveSuccess,
  setCurrentPage,
  setIsRouteOptimised,
  setOptimiseRoute,
  setIsRouteOptimiseDone,
  setQrScanVariant,
  setOpenScanner,
} from "library/sagas/ongoing/global-data/slice";
import {
  setCompleteRouteModal,
  setRemoveStopAction,
  getOrderForRoute,
  setFocusField,
  setQRScanReprintWarning,
  resetQRScanReprintWarning,
  setCanProgrammaticallyZoom,
} from "library/sagas/views/home/drawer/delivery/slice";
import {
  setRecordId,
  setOrdersToHighlight,
} from "library/sagas/ongoing/current-orders/slice";
import {
  setActions,
  resetBasicInfo,
} from "library/sagas/ongoing/current-delivery/slice";
import { generateQRCode } from "library/sagas/ongoing/order-details/slice";
import { selectOrdersToHighlight } from "library/sagas/ongoing/current-orders/selector";
import {
  selectRemoveStopAction,
  selectQRScanReprintWarning,
  selectFocusField,
} from "library/sagas/views/home/drawer/delivery/selector";
import {
  selectCurrentPage,
  selectQrScanVariant,
  selectAddressVerificationInfo,
} from "library/sagas/ongoing/global-data/selector";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import I18NContext from "library/contexts/i18N";

import RouteBreadcrumb from "../route-breadcrumb";
import TakeAction from "./take-action";
import ListItem from "../route-order";
import RouteDetailForm from "./route-details-form";
import RouteStopInfo from "./route-stop-info";
import RouteQRScan from "./qr-scan";
import Filters from "./filters";

import SplitScreen from "components/containers/split-screen";
import SubHeader from "components/containers/header/sub-header";
import { QRScanIcon } from "components/containers/header/screen";

import {
  Accordion,
  DirectionsMap,
  ToasterHandler,
  PrintIframeAction,
  PrintIframeRenderer,
  CustomModal,
  Tooltip,
  DraggableFlatList,
} from "components/elements";
import { ConfirmModal } from "components/wrappers";
import {
  InitOrdersData,
  ListOfColumns,
  ListOfMobileColumns,
  ListOfSubColumns,
  ListOfOrderStatus,
} from "../config";
import { fonts, colors, backgroundColors, theme } from "styles/theme";
import styles from "./styles";
import {
  getShopDetails,
  getRouteName,
  getDrivers,
  array_move,
  assignOrdersToRoutes,
  getPrintHTML,
  getLocalPermissions,
  setOrderSequences,
  saveUserPreferences,
  splitArray,
  areShopLocationsEqual,
} from "./helper";
import { isAdhocStop, isAdhocStopById } from "../helper";
import { getSingleOrderPrintHTML as getOrderDetailPrintHTML } from "components/views/drawer/order-details/order-details-print-helper";
import { ListOfFilters, InitViewMoreCounts } from "./config";
import {
  CompleteRouteModal,
  hasNonDeliveredOrdersInRoute,
} from "components/views/drawer/delivery/routes-dashboard/CompleteRouteModal";
import AddStopByOrderId from "components/views/drawer/delivery/routes-dashboard/upsert-route/take-action/add-order/addStopByOrderId";
import isPointInPolygon from "geolib/es/isPointInPolygon";
import { hashString } from "react-hash-string";

import { adhocStopObject } from "components/views/drawer/delivery/routes-dashboard/upsert-route/route-stop-info/helper";
import { advOptimiseFee } from "components/views/drawer/shop-settings/addons/ui-config";
import { extractTimeFromDI } from "components/views/drawer/create-order/helper";

//storage
import UserProfileStorage from "library/storage/userProfile";
import FormReloadCache from "library/storage/formReloadStorage";
import AppSettingsStorage from "library/storage/appSettings";

//utils
import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";
import Environment from "library/utils/environment";
import { processOrderResponse } from "library/utils/orderListing";
import {
  Entitlements,
  isMHQNonCoreMember,
  isPremiumMember,
} from "library/utils/entitlements";
import { handleError, convertToTimeZone } from "library/utils/deliveryService";
import { dateTimeFormat } from "library/utils/datetime";
import { processSelectedProductResponse } from "library/utils/productDetails";
import { hasDesignCenterFeature } from "library/utils/featureAvailability";

import { DELIVERY } from "library/constants";
import {
  hasRemotePrintEnabled,
  showPrintToaster,
  getRemotePrinterName,
} from "components/views/drawer/order-details/order-head/take-action/helper";

let deliveryOrdersController;
let routeDataController;

const UpsertRouteScreen = ({
  navigation,
  route,
  global: {
    shopCode: globalShopCode,
    isRouteSave,
    isRouteEditing,
    isAdhocStopEditing,
    optimiseRoute,
  },
  currentOrdersInfo = {},
  setRecordId,
  setIsRouteEditing,
  setIsAdhocStopEditing,
  setIsRouteSaveSuccess,
  setIsRouteSave,
  setCurrentPage,
  setOrdersToHighlight,
  setCompleteRouteModal,
  setRemoveStopAction,
  setActions,
  setIsRouteOptimised,
  setOptimiseRoute,
  setIsRouteOptimiseDone,
  appliedFilters = [],
  selectedShops = [],
  search = {},
}) => {
  const { setParams } = navigation;
  const {
    params: {
      routeId: paramsRouteId = "",
      shopCode: paramsShopCode = "",
      memberCodes: paramsMemberCodes = [],
      routeDate: paramsRouteDate = "",
      accessByDriver = "false",
      driverDashboardShopCode,
    } = {},
  } = route;

  const { width: windowWidth } = Dimensions.get("window");

  const shopCodeInStorage = UserProfileStorage.getSelectedShopCode();
  const shopListInStorage = UserProfileStorage.getRouteShopList();
  const getSelectedRouteIdFromStorage =
    UserProfileStorage.getSelectedRouteId() || "";
  const autoRouteId = UserProfileStorage.getAutoRouteId();
  const selectedRouteId = paramsRouteId.length
    ? paramsRouteId
    : getSelectedRouteIdFromStorage;
  const shopNames = UserProfileStorage.getAllShopNames();
  const shopLocations = UserProfileStorage.getAllShopLocation();
  UserProfileStorage;

  const { set1 = {} } = ListOfOrderStatus;

  const { userType, proxyUser, userProfile, setUserProfile } =
    useContext(UserProfileContext);
  const { messages, Localise } = useContext(I18NContext);
  const { permissions } = useContext(AppSettingsContext);
  const { isDesktop, isMobile, isTablet } = useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const isFTDUser = userType === "FTD";
  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const macAddress =
    isElectronApp && document.getElementById("macAddress").value;

  let selectedShopCode = paramsShopCode.length
    ? paramsShopCode
    : shopCodeInStorage;

  const filteredShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (
      Entitlements.DELIVERY in permissions[memberCode] ||
      Entitlements.ROUTES in permissions[memberCode]
    )
      filteredShopCodes.push(memberCode);
  });

  const {
    firstName: userFirstName,
    lastName: userLastName,
    email: userEmailId,
    phone: userPhone = "",
    preferences: userPreferences = [],
    memberCodes: userMemberCodes = [],
  } = userProfile;
  const routeMemberPreferences =
    userPreferences
      .find((e) => e.name === "routeMemberPreferences")
      ?.value?.split(",")
      .filter(Boolean) || [];
  const filteredRouteMemberPreferences = routeMemberPreferences?.filter(
    (shopCode) => filteredShopCodes.includes(shopCode)
  );

  //state Hooks
  const [eventAfterSaveRoute, setEventAfterSaveRoute] = useStateIfMounted({
    action: "",
    actionType: "",
  });
  const [routeDeliveryDate, setRouteDeliveryDate] = useStateIfMounted(
    paramsRouteDate
      ? paramsRouteDate
      : UserProfileStorage.getRouteDeliveryDate() ||
          moment().format("YYYY-MM-DD")
  );

  const hasPermission = (permisssionName) => {
    return filteredShopCodes.some(
      (memberCode) =>
        permissions[memberCode][Entitlements.DELIVERY]?.includes(
          Entitlements.DELIVERY_PAGE[permisssionName]
        ) ||
        permissions[memberCode][Entitlements.ROUTES]?.includes(
          Entitlements.ROUTES_PAGE[permisssionName]
        )
    );
  };

  const isMultiRoute = hasPermission("ROUTE_MULTISHOP_OWNERSHIP");

  if (
    (!selectedShopCode || selectedShopCode?.toLowerCase()?.includes("all")) &&
    !isMultiRoute
  ) {
    selectedShopCode = filteredShopCodes[0];
  }

  const [shopCode, setShopCode] = useStateIfMounted(
    selectedShopCode || (shopCodeInStorage ?? globalShopCode)
  );
  const [routeData, setRouteData] = useStateIfMounted({});
  const selectedMemberCode =
    filteredShopCodes.length > 1 && !shopCode?.toLowerCase().includes("all")
      ? shopCode || filteredShopCodes[0]
      : filteredShopCodes[0];

  const selectedShopPermissions = get(permissions, selectedMemberCode, {});
  const isEditRoute = !isEmpty(routeData);
  const localPermissions = getLocalPermissions(
    { ...permissions, ...AppSettingsStorage.getAllPermissions() },
    selectedShopPermissions,
    filteredShopCodes,
    paramsMemberCodes?.length
      ? [...paramsMemberCodes?.split(",")]
      : shopListInStorage?.length
      ? shopListInStorage
      : selectedShopCode && !selectedShopCode.includes("all")
      ? [selectedShopCode]
      : []
  );
  const needToPrefillMemberPreference =
    !selectedRouteId.length &&
    !isEditRoute &&
    localPermissions.multiShopRoute && //Premium
    !isFTDUser &&
    !proxyUser &&
    filteredShopCodes?.length > 1 &&
    filteredRouteMemberPreferences?.length;
  const [multiSelectedShopCodes, setMultiSelectedShopCodes] = useStateIfMounted(
    needToPrefillMemberPreference
      ? filteredRouteMemberPreferences
      : paramsMemberCodes?.length
      ? [...paramsMemberCodes?.split(",")]
      : shopListInStorage?.length
      ? shopListInStorage
      : selectedShopCode && !selectedShopCode.includes("all")
      ? [selectedShopCode]
      : []
  );
  const [startLocationCode, setStartLocationCode] = useStateIfMounted(
    needToPrefillMemberPreference
      ? filteredRouteMemberPreferences[0]
      : selectedShopCode
      ? selectedShopCode
      : ""
  );
  const [endLocationCode, setEndLocationCode] = useStateIfMounted(
    needToPrefillMemberPreference
      ? filteredRouteMemberPreferences[0]
      : selectedShopCode
      ? selectedShopCode
      : ""
  );
  const [memberQuery, setMemberQuery] = useStateIfMounted("");
  const [driverQuery, setDriverQuery] = useStateIfMounted("");
  const [locationFieldQuery, setLocationFieldQuery] = useStateIfMounted("");
  const [driver, setDriver] = useStateIfMounted({ name: "", id: "" });
  const [routeMoveAction, setRouteMoveAction] = useStateIfMounted("");
  const [isAddStopFormOpen, setIsAddStopFormOpen] = useStateIfMounted(false);
  const [ordersList, setOrdersList] = useStateIfMounted({
    ordersInRoute: [],
    nonRoutedOrders: [],
  });
  const [filteredOrdersList, setFilteredOrdersList] = useStateIfMounted({
    ordersInRoute: [],
    nonRoutedOrders: [],
  });
  const [unsavedMoveActionOrdersList, setUnsavedMoveActionOrdersList] =
    useStateIfMounted({
      ordersInRoute: [],
      nonRoutedOrders: [],
    });
  const [autoRouteData, setAutoRouteData] = useStateIfMounted({});
  const [autoRoutesData, setAutoRoutesData] = useStateIfMounted([]);
  const [autoRoutesFilters, setAutoRoutesFilters] = useStateIfMounted({
    "Zone Names": [],
  });
  const [driversData, setDriversData] = useStateIfMounted([]);
  const [routeName, setRouteName] = useStateIfMounted("");
  const [savedRoutes, setSavedRoutes] = useStateIfMounted({});
  const [disableSave, setDisableSave] = useStateIfMounted(true);
  const [mapKey, setMapKey] = useStateIfMounted("");
  const [isPastRoute, setIsPastRoute] = useStateIfMounted(false);
  const [loading, setLoading] = useStateIfMounted(false);
  const [isRouteSaving, setRouteSaving] = useStateIfMounted(false);
  const [showShopRemovedModal, setShowShopRemovedModal] =
    useStateIfMounted(false);
  const [showLargeRouteModal, setShowLargeRouteModal] =
    useStateIfMounted(false);
  const [show60StopsCFRModal, setShow60StopsCFRModal] =
    useStateIfMounted(false);
  const [showCFRConfirmModal, setShowCFRConfirmModal] =
    useStateIfMounted(false);
  const [forceOptimize, setForceOptimize] = useStateIfMounted({
    showforceOptimizeModal: false,
    delayedDeliveries: [],
  });
  const [qrScanWarning, setQRScanWarning] = useStateIfMounted({
    showWarningModal: false,
    action: {},
  });
  const [shopRemoved, setShopRemoved] = useStateIfMounted([]);
  const [showOptimizeModal, setShowOptimizeModal] = useStateIfMounted(false); //modal shown when users with multishop permission Optimise to confirm Expected Start Time
  const [driverAssignment, setDriverAssignment] = useStateIfMounted(true);
  const [eraseMap, setEraseMap] = useStateIfMounted(false);
  const [actionType, setActionType] = useStateIfMounted("");
  const [modalVisible, setModalVisible] = useStateIfMounted(false);
  const pollInterval = 2 * 60 * 1000; // 2 mins
  const [timeStamp, setTimestamp] = useStateIfMounted(false);
  const [saveCount, setSaveCount] = useStateIfMounted(0);
  const [scrollEnabled, setScrollEnabled] = useStateIfMounted(true);
  const [mapYCoordinate, setMapYCoordinate] = useStateIfMounted(null);
  const [addStopYCoordinate, setAddStopYCoordinate] = useStateIfMounted(0);
  const [autoScroll, setAutoScroll] = useStateIfMounted(false);
  const [expectedStartTime, setExpectedStartTime] = useStateIfMounted(null);
  const [selectAllChecked, setSelectAllChecked] = useStateIfMounted(false);
  const [checkedNonRouted, setCheckedNonRouted] = useStateIfMounted([]);
  const [scannedOrder, setScannedOrder] = useStateIfMounted();
  const ordersInRouteVMLimit = parseInt(
    Environment.get("OIR_VIEW_MORE_LIMIT", 30)
  );
  const ordersNotInRouteVMLimit = parseInt(
    Environment.get("ONIR_VIEW_MORE_LIMIT", 30)
  );
  const [viewMoreCounts, setViewMoreCounts] = useStateIfMounted(
    InitViewMoreCounts(ordersInRouteVMLimit, ordersNotInRouteVMLimit)
  );
  const [selectedAction, setSelectedAction] = useStateIfMounted("");

  //selectors
  const ordersToHighlight = useSelector(selectOrdersToHighlight);
  const removeStopAction = useSelector(selectRemoveStopAction);
  const currentPage = useSelector(selectCurrentPage);
  const qrScanVariant = useSelector(selectQrScanVariant);
  const focusFieldName = useSelector(selectFocusField);
  const qrScanReprintWarning = useSelector(selectQRScanReprintWarning);
  const AVSInfo = useSelector(selectAddressVerificationInfo);

  //References
  const RouteDetailsFormRef = useRef();
  const RouteStopFormRef = useRef();
  const routeDetailstimerToClear = useRef(null);
  const addStopViewRef = useRef();

  // const deliveryFlexDates = Environment.get(
  //   "DELIVERY_FLEX_DATES",
  //   "2024-03-29,2024-03-30,2024-03-31"
  // );

  const startRange = parseInt(
    Environment.get("ROUTES_DEFAULT_START_RANGE", "7")
  );
  const endRange = parseInt(Environment.get("ROUTES_DEFAULT_END_RANGE", "3"));
  const enableDND = Environment.get("ENABLE_ROUTE_DND", false);

  const isShowZonesInInteractiveMapVisible = Environment.get(
    "SHOW_ZONES_IN_INTERACTIVE_MAP",
    false
  );
  const dispatch = useDispatch();
  const { recordId } = currentOrdersInfo;

  const isEditStopOpen = recordId && recordId.includes("ADHOC");
  const isStopFormOpen = isEditStopOpen || isAddStopFormOpen;
  const hasLimitedRouteAccess =
    filteredShopCodes?.length < routeData?.memberCodes?.length;
  const floristTimeZone =
    UserProfileStorage.getShopTimeZone(multiSelectedShopCodes[0] || shopCode) ||
    "America/Chicago";

  const { showQRScan } = localPermissions;

  const fetchShopCode = localPermissions.multiShopRoute
    ? isEditRoute
      ? routeData?.memberCodes.filter((each) =>
          multiSelectedShopCodes.includes(each)
        )[0]
      : multiSelectedShopCodes[0]
    : shopCode;

  const hasShopForFetch = localPermissions.multiShopRoute
    ? isEditRoute
      ? routeData?.memberCodes.filter((each) =>
          multiSelectedShopCodes.includes(each)
        )?.length
      : multiSelectedShopCodes?.length
    : shopCode;

  const showFilters = true;
  // const showFilters = localPermissions.multiShopRoute
  //   ? multiSelectedShopCodes?.filter((each) => isMHQNonCoreMember(each)).length
  //   : isMHQNonCoreMember(selectedShopCode);

  useEffect(() => {
    // If not latitude or longitude for shopLocation
    if ((!latitude || !longitude) && !localPermissions.multiShopRoute) {
      setModalVisible(true);
    }

    // Fetch RouteData if routeId exists.
    if (selectedRouteId.length) {
      UserProfileStorage.setSelectedRouteId(selectedRouteId);
    }

    if (needToPrefillMemberPreference) {
      UserProfileStorage.setRouteShopList(filteredRouteMemberPreferences);
    }

    // Setting shopCode from storage incase of reload
    if (shopCode?.toLowerCase().includes("all")) {
      shopCodeInStorage.length && setShopCode(shopCodeInStorage);
    }

    isEmpty(shopNames) && getShopDetails();
    dispatch(setQrScanVariant(""));
    dispatch(setOpenScanner(false));
    // Clear RouteParams after receiving.
    setParams({ routeId: undefined, memberCodes: undefined });

    return () => {
      routeDataController && routeDataController.abort();
      deliveryOrdersController && deliveryOrdersController.abort();
      setParams({
        routeId: undefined,
        routeDate: undefined,
        shopCode: undefined,
        memberCodes: undefined,
        driverDashboardShopCode: undefined,
        accessByDriver: undefined,
      });
      setModalVisible(false);
      setOrdersToHighlight([]);
      UserProfileStorage.setRouteShopList([]);
      dispatch(setFocusField(""));
      setIsRouteOptimised(true);
      dispatch(setQrScanVariant(""));
      setIsRouteEditing(false);
      setIsAdhocStopEditing(false);
      setRecordId(""); //close Adhoc stop Form
    };
  }, []);

  useEffect(() => {
    if (Platform.OS !== "web") return;

    if (FormReloadCache.getFormValues("CreateRoute")) {
      const savedValues = FormReloadCache.getFormValues("CreateRoute");
      if (savedValues && Object.keys(savedValues).length > 0) {
        RouteDetailsFormRef.current.setValues(savedValues);
      }
      if (FormReloadCache.getFormValues("CreateRoute_AddStop")) {
        const savedValues = FormReloadCache.getFormValues(
          "CreateRoute_AddStop"
        );
        if (savedValues && Object.keys(savedValues).length > 0) {
          if (isStopFormOpen && RouteStopFormRef?.current) {
            RouteStopFormRef.current.setValues(savedValues);
          }
        }
        FormReloadCache.clear();
      }
    }
    const channel = new BroadcastChannel("reload-channel-SaveDraft");

    channel.onmessage = (event) => {
      if (event.data === "SaveDraft") {
        // Get the current form values from the form reference
        const updatedInitialValues = RouteDetailsFormRef.current.values;

        // Check if the item exists in local storage before attempting to remove it
        if (FormReloadCache.getFormValues("CreateRoute")) {
          FormReloadCache.clear();
        }
        if (FormReloadCache.getFormValues("CreateRoute_AddStop")) {
          FormReloadCache.clear();
        }
        // Save the updated form values into local storage
        FormReloadCache.setFormValues("CreateRoute", updatedInitialValues);
        if (isStopFormOpen && RouteStopFormRef?.current) {
          const updatedInitialValues_AddStop = RouteStopFormRef.current.values;
          FormReloadCache.setFormValues(
            "CreateRoute_AddStop",
            updatedInitialValues_AddStop
          );
        }
      }
    };

    return () => {
      channel.close();
    };
  }, []);

  useEffect(() => {
    if (isRouteSave) {
      RouteDetailsFormRef.current.handleSubmit();
      setOrdersToHighlight([]);
    }
  }, [isRouteSave]);

  useEffect(() => {
    if (optimiseRoute) {
      OptimizeAction();
      setOrdersToHighlight([]);
    }
  }, [optimiseRoute]);

  useEffect(() => {
    if (isEditRoute) {
      const { ordersInRoute = [] } = ordersList;

      const hasTimedOrders =
        ordersInRoute.filter(
          (ord) => ord.isRushOrder || !!ord?.orderDeliveryTime
        ).length > 0;

      const { isOptimized = false, status } = routeData;

      //show suggestion to Optimise only if route has timed Order and Optimise is enabled
      if (
        hasTimedOrders &&
        localPermissions.isOptimizeEnabled &&
        ["DRAFT", "PLANNED", "INPROGRESS"].includes(status)
      ) {
        setIsRouteOptimised(isOptimized);
      } else setIsRouteOptimised(true);
    }
  }, [ordersList, routeData]);

  useEffect(() => {
    if (isAddStopFormOpen) {
      setDisableSave(false);
      setIsAdhocStopEditing(true);
    }
    if (isStopFormOpen) {
      clearTimeout(routeDetailstimerToClear.current);
    } else {
      setTimestamp(moment().utc().format());
    }
  }, [recordId, isAddStopFormOpen]);

  const isOrderWithinZone = (ord, autoRoute, list) => {
    const {
      recipientLocation: { latitude = "", longitude = "" },
    } = ord;
    const isInside = isPointInPolygon(
      { latitude, longitude },
      autoRoute?.wayPoints
    );
    return (
      isInside && !list.some((order) => order.orderItemId == ord.orderItemId)
    );
  };

  const filterOrderData = (
    filteredAutoRoutes,
    ordersData,
    filterBasedOnZones
  ) => {
    let list = [];
    if (filterBasedOnZones) {
      filteredAutoRoutes.map((autoRoute) => {
        ordersData.map((ord, index) => {
          if (isOrderWithinZone(ord, autoRoute, list))
            list.push({
              ...ord,
              zoneName: autoRoute?.name,
              orderIndex: index,
            });
        });
      });
    } else {
      ordersData.map((ord, index) => {
        filteredAutoRoutes.map((autoRoute) => {
          if (isOrderWithinZone(ord, autoRoute, list))
            list.push({
              ...ord,
              zoneName: autoRoute?.name,
              orderIndex: index,
            });
        });
      });
    }
    return list;
  };

  useEffect(() => {
    let updatedList = {
      ordersInRoute: [],
      nonRoutedOrders: [],
    };

    const filters = cloneDeep(appliedFilters);
    const shops = cloneDeep(selectedShops);
    let hasDateFilter;
    const filterCategoryMap = {};
    if (filters.length) {
      filters.map((e) => {
        let [type, { value }] = Object.entries(e).pop();
        if (type !== "Zone Names") {
          filterCategoryMap[type] = filterCategoryMap[type]
            ? filterCategoryMap[type].concat(":" + value)
            : value;
        }
        if (type === "Date Range") {
          hasDateFilter = true;
        }
      });
    }

    Object.keys(updatedList).map((orders, index) => {
      // filter Orders based on Zones
      const selectedZones = appliedFilters
        ?.map((data) => data?.["Zone Names"]?.title)
        .filter((data) => data);

      const selectedAutoRoutesData = autoRoutesData.filter((data) => {
        return localPermissions.multiShopRoute
          ? selectedZones.includes(`${data.name} - ${data.siteId}`)
          : selectedZones.includes(data.name);
      });

      if (selectedZones.length) {
        updatedList[orders] = filterOrderData(
          selectedAutoRoutesData,
          ordersList[orders],
          index
        );
      } else {
        updatedList[orders] = ordersList[orders].map((eachOrder, i) => {
          return { ...eachOrder, orderIndex: i };
        });
      }

      //filter Orders based on shop codes and other filters
      updatedList[orders] = updatedList[orders].filter((order) => {
        let matchesFilter = true;

        // Filtering the orders based on the search query
        if (!isEmpty(search) && matchesFilter) {
          matchesFilter =
            toLower(order.messageNumber).includes(toLower(search.value)) ||
            toLower(order.orderItemId).includes(toLower(search.value)) ||
            toLower(order.recipientName).includes(toLower(search.value)) ||
            toLower(order.recipientCity).includes(toLower(search.value)) ||
            toLower(order.recipientZip).includes(toLower(search.value))
              ? true
              : false;
        }

        // Filter orders by shop codes
        if (!isEmpty(shops) && matchesFilter) {
          const results = shops.filter((shop) => {
            const memberCode = shop["Shop Codes"].value;
            return get(order, "receivingMember.memberCode") === memberCode;
          });
          matchesFilter = !!results.length;
        }

        // Filtering list by filters
        if (filters.length && matchesFilter) {
          matchesFilter = Object.keys(filterCategoryMap).reduce(
            (matches, category) => {
              const value = filterCategoryMap[category];

              // filter Timed Orders
              if (matches && category === "Categories") {
                const multiValues = value.split(":");
                const result = multiValues.filter((value) => {
                  if (value === "timedOrders") {
                    return order.isRushOrder || !!order?.orderDeliveryTime;
                  } else return false;
                });

                matches = result.length ? true : false;
              }

              // filter Orders by status
              if (matches && category === "Order Status") {
                const multiValues = value.split(":");
                const results = multiValues.filter((value) => {
                  if (
                    order.internalStatus === value ||
                    order?.internalSubStatus?.includes(value)
                  ) {
                    return true;
                  }
                });

                matches = results.length ? true : false;
              }

              // filter Orders by date
              if (matches && category === "Date Range") {
                const { startDate, endDate } = value;
                matches = moment(order?.displayDeliveryDate).isBetween(
                  moment(startDate),
                  moment(endDate),
                  null,
                  "[]"
                );
              }
              return matches;
            },
            true
          );
        }
        if (orders === "nonRoutedOrders" && matchesFilter && !hasDateFilter) {
          matchesFilter = moment(order?.displayDeliveryDate).isBetween(
            moment(routeDeliveryDate),
            moment(routeDeliveryDate),
            null,
            "[]"
          );
        }

        if (matchesFilter) return order;
      });
    });

    setFilteredOrdersList({ ...updatedList });
  }, [appliedFilters, selectedShops, search, ordersList, autoRoutesData]);

  useEffect(() => {
    //clear checked NonRouted Orders and SelectAll checkbox when filters applied
    setCheckedNonRouted([]);
    selectAllChecked && setSelectAllChecked(!selectAllChecked);

    dispatch(setCanProgrammaticallyZoom(true));
  }, [appliedFilters, selectedShops, search]);

  const refreshOrders = (startDate, endDate) => {
    setLoading(true);
    getOrdersRequest(startDate, endDate)
      .then((res) => {
        const shopCodeFilter =
          res &&
          res.length &&
          res.filter(
            (ord) =>
              ord?.direction === "INBOUND" &&
              (localPermissions.multiShopRoute
                ? multiSelectedShopCodes?.includes(
                    ord?.receivingMember?.memberCode
                  )
                : ord?.receivingMember?.memberCode === shopCode)
          );
        const updatedResp = processOrderResponse(
          shopCodeFilter,
          InitOrdersData,
          "delivery-details"
        );
        const { orderCollection = {} } = updatedResp;

        const ordersResponse = orderCollection?.nonRouted?.orders || [];
        let actualNonRouted = ordersResponse.filter(
          (ord) =>
            (!ord.routeId || ord.routeId === "null") &&
            !["DELIVERED", "CANCELLED"].includes(ord.status)
        );
        if (!localPermissions.considerLocalOrders) {
          actualNonRouted = actualNonRouted.filter(
            (ord) => ord.deliveryInfo?.deliveryMethod !== "FLORIST_DELIVERED" // filter local orders
          );
        }
        if (ordersList.ordersInRoute?.length) {
          actualNonRouted = actualNonRouted?.filter((ord) => {
            return (
              ordersList.ordersInRoute?.findIndex(
                (item) => item.orderItemId == ord.orderItemId
              ) == -1
            );
          });
        }
        setOrdersList({ ...ordersList, nonRoutedOrders: actualNonRouted });
        setLoading(false);
      })
      .catch(() => {});
  };
  const dateFilter = (appliedFilters) => {
    const filters = cloneDeep(appliedFilters);
    let hasDateFilter, startDate, endDate;
    if (filters.length) {
      filters.map((e) => {
        let [type, { value }] = Object.entries(e).pop();
        if (type === "Date Range") {
          hasDateFilter = true;
          startDate = moment(get(value, "startDate", routeDeliveryDate)).format(
            "YYYY-MM-DD"
          );
          endDate = moment(get(value, "endDate", routeDeliveryDate)).format(
            "YYYY-MM-DD"
          );
        }
      });
    }
    return { hasDateFilter, startDate, endDate };
  };
  useEffect(() => {
    const { startDate, endDate } = dateFilter(appliedFilters);
    refreshOrders(startDate, endDate);
  }, [appliedFilters]);

  const hasFilters =
    appliedFilters.length || selectedShops.length || !isEmpty(search);

  const filteredNonRoutedOrders = (
    hasFilters ? filteredOrdersList : ordersList
  ).nonRoutedOrders?.map((eachOrder, i) => {
    return { ...eachOrder, ...(!hasFilters && { orderIndex: i }) };
  });

  const filteredOrdersInRoute = (
    hasFilters ? filteredOrdersList : ordersList
  )?.ordersInRoute?.map((eachOrder, i) => {
    return {
      ...eachOrder,
      index: eachOrder.orderIndex || i,
      ...(!hasFilters && { orderIndex: i }),
    };
  });

  const { latitude = "", longitude = "" } = shopLocations[shopCode] || {};

  const {
    addressLine1: startAddressLine1 = "",
    addressLine2: startAddressLine2 = "",
    city: startCity = "",
    state: startState = "",
    zip: startZip = "",
    countryCode: startCountryCode = "",
    latitude: startLatitude = "",
    longitude: startLongitude = "",
  } = shopLocations[startLocationCode] || {};

  const {
    addressLine1: endAddressLine1 = "",
    addressLine2: endAddressLine2 = "",
    city: endCity = "",
    state: endState = "",
    zip: endZip = "",
    countryCode: endCountryCode = "",
    latitude: endLatitude = "",
    longitude: endLongitude = "",
  } = shopLocations[endLocationCode] || {};

  const getOrdersRequest = (startDate, endDate) => {
    deliveryOrdersController && deliveryOrdersController.abort();
    deliveryOrdersController = new AbortController();

    // const { startDate, endDate } = getDateRangeFromFlexDates(
    //   deliveryFlexDates,
    //   routeDeliveryDate
    // );
    return request(
      "delivery-listing",
      {
        startDate: startDate || moment(routeDeliveryDate).format("YYYY-MM-DD"),
        endDate: endDate || moment(routeDeliveryDate).format("YYYY-MM-DD"),
        fetchStatuses: set1.status.join(","),
      },
      deliveryOrdersController
    );
  };
  const getRouteDataRequest = () => {
    routeDataController && routeDataController.abort();
    routeDataController = new AbortController();

    return request(
      "get-route-data",
      {
        routeId: selectedRouteId,
        shopCode: fetchShopCode,
      },
      routeDataController
    );
  };
  const getRouteAndOrdersData = () => {
    const requestsList = [];
    const { startDate, endDate } = dateFilter(appliedFilters);
    if (multiSelectedShopCodes?.length > 0 || selectedRouteId.length)
      requestsList.push(getOrdersRequest(startDate, endDate));
    if (selectedRouteId.length) requestsList.push(getRouteDataRequest());

    if (requestsList.length > 0) {
      setLoading(true);
      Promise.all(requestsList)
        .then((responses) => {
          let selectedRoute = {};
          // handling Route response
          if (selectedRouteId.length && responses?.length > 1) {
            const { route = {} } = responses[1] || {};
            const {
              deliveryDate,
              driver = {},
              startLocation: {
                addressLine1,
                city,
                country,
                geoLocation: { latitude, longitude } = {},
                state,
                zip,
              } = {},
              endLocation: {
                addressLine1: endAddressLine1,
                city: endCity,
                country: endCountry,
                geoLocation: {
                  latitude: endLatitude,
                  longitude: endLongitude,
                } = {},
                state: endState,
                zip: endZip,
              } = {},
              expectedStartTime,
            } = route;
            selectedRoute = route;
            const startLocationIndex = Object.values(shopLocations).findIndex(
              (each) => {
                return (
                  addressLine1 === each.addressLine1 &&
                  city === each.city &&
                  country === each.countryCode &&
                  state === each.state &&
                  zip === each.zip &&
                  latitude == each.latitude &&
                  longitude == each.longitude
                );
              }
            );
            const routeStartLocationCode =
              Object.keys(shopLocations)[startLocationIndex];

            const endLocationIndex = Object.values(shopLocations).findIndex(
              (each) => {
                return (
                  endAddressLine1 === each.addressLine1 &&
                  endCity === each.city &&
                  endCountry === each.countryCode &&
                  endState === each.state &&
                  endZip === each.zip &&
                  endLatitude == each.latitude &&
                  endLongitude == each.longitude
                );
              }
            );
            const routeEndLocationCode =
              Object.keys(shopLocations)[endLocationIndex];

            setStartLocationCode(routeStartLocationCode);
            setEndLocationCode(routeEndLocationCode);

            setExpectedStartTime(
              moment(
                convertToTimeZone(expectedStartTime, floristTimeZone)
              ).format(dateTimeFormat)
            );

            getAutoRouteData(route);
            setRouteData(route);
            setSavedRoutes(route);
            setRouteDeliveryDate(deliveryDate);
            setIsPastRoute(
              moment(deliveryDate).isBefore(moment().add(-1, "days"))
            );
            setDriver(driver || { name: "", id: "" });
            RouteDetailsFormRef?.current?.setFieldValue(
              "driver.name",
              driver?.name || ""
            );
            RouteDetailsFormRef?.current?.setFieldValue(
              "driver.id",
              driver?.id || ""
            );
          }

          // handling Orders response
          const res = responses[0];
          const shopCodeFilter =
            res &&
            res.length &&
            res.filter(
              (ord) =>
                ord?.direction === "INBOUND" &&
                (localPermissions.multiShopRoute
                  ? multiSelectedShopCodes?.includes(
                      ord?.receivingMember?.memberCode
                    )
                  : ord?.receivingMember?.memberCode === shopCode)
            );
          const updatedResp = processOrderResponse(
            shopCodeFilter,
            InitOrdersData,
            "delivery-details"
          );
          const { orderCollection = {} } = updatedResp;
          let updatedOrdersList = { nonRouted: [], routeOrder: [] };

          const ordersResponse = orderCollection?.nonRouted?.orders || [];

          if (selectedRouteId.length) {
            assignOrdersToRoutes(
              selectedRouteId,
              ordersResponse,
              setOrdersList,
              localPermissions,
              selectedRoute,
              setLoading,
              (props) => {
                dispatch(getOrderForRoute({ ...props }));
              }
            );
          } else {
            let actualNonRouted = ordersResponse.filter(
              (ord) =>
                (!ord.routeId || ord.routeId === "null") &&
                !["DELIVERED", "CANCELLED"].includes(ord.status)
            );
            if (!localPermissions.considerLocalOrders) {
              actualNonRouted = actualNonRouted.filter(
                (ord) =>
                  ord.deliveryInfo?.deliveryMethod !== "FLORIST_DELIVERED" // filter local orders
              );
            }

            if (autoRouteId) {
              request("get-autoroutes-data", {
                shopCode: fetchShopCode,
                autoRouteId: autoRouteId,
              })
                .then((res) => {
                  setAutoRouteData(res);
                  RouteDetailsFormRef.current.setFieldValue(
                    "driver.name",
                    res.driverName || ""
                  );
                  RouteDetailsFormRef.current.setFieldValue(
                    "driver.id",
                    res.driverId || ""
                  );
                  actualNonRouted.map((ord) => {
                    const {
                      recipientLocation: { latitude = "", longitude = "" },
                    } = ord;
                    const isInside = isPointInPolygon(
                      { latitude, longitude },
                      res?.wayPoints
                    );
                    if (isInside) {
                      if (updatedOrdersList.routeOrder.indexOf(ord) === -1) {
                        updatedOrdersList.routeOrder.push(ord);
                      }
                    } else {
                      if (updatedOrdersList.nonRouted.indexOf(ord) === -1) {
                        updatedOrdersList.nonRouted.push(ord);
                      }
                    }
                  });
                  if (updatedOrdersList.routeOrder.length > 0) {
                    setDisableSave(false);
                  }
                  setOrdersList({
                    ordersInRoute: updatedOrdersList.routeOrder,
                    nonRoutedOrders: updatedOrdersList.nonRouted,
                  });
                })
                .catch((err) => {});
            } else {
              setOrdersList({
                ordersInRoute: [],
                nonRoutedOrders: actualNonRouted,
              });
            }
            setLoading(false);
          }
          setTimestamp(moment().utc().format());
        })
        .catch((err) => {
          if (err !== "AbortError") {
            setLoading(false);
            handleError(err, ToasterHandler, Localise, messages);
            console.log("error", err);
          }
        });
    } else {
      setOrdersList({
        ordersInRoute: [],
        nonRoutedOrders: [],
      });
      setFilteredOrdersList({
        ordersInRoute: [],
        nonRoutedOrders: [],
      });
      setDriversData([]);
      setDriver({ name: "", id: "" });
      setAutoRoutesData([]);
      setAutoRoutesFilters({});
    }
  };
  const getAllAutoRoutesData = () => {
    if (localPermissions.multiShopRoute && multiSelectedShopCodes?.length > 0) {
      const promises = multiSelectedShopCodes?.map((shopCode) =>
        request("get-autoroutes-data", { shopCode })
      );
      Promise.all(promises).then((responses) => {
        let filterOption = [];
        let autoRoutesDetails = [];
        responses.map((eachShopDetails = []) => {
          autoRoutesDetails.push(...eachShopDetails);
          eachShopDetails.map((data) => {
            filterOption.push({
              title: `${data.name} - ${data.siteId}`,
              value: data.name,
            });
          });
        });

        setAutoRoutesFilters({ "Zone Names": filterOption });
        setAutoRoutesData(autoRoutesDetails);
      });
    } else if (shopCode && !shopCode?.toLowerCase().includes("all")) {
      request("get-autoroutes-data", { shopCode })
        .then((res) => {
          let filterOption = [];
          res.map((data) => {
            filterOption.push({ title: data.name, value: data.name });
          });
          setAutoRoutesFilters({ "Zone Names": filterOption });
          setAutoRoutesData(res);
        })
        .catch((err) => {});
    }
  };
  const getAutoRouteData = (route) => {
    if (route?.autoRouteId) {
      request("get-autoroutes-data", {
        shopCode: fetchShopCode,
        autoRouteId: route?.autoRouteId,
      })
        .then((res) => {
          setAutoRouteData(res);
        })
        .catch((err) => {});
    }
  };

  const shopDependency = isEditRoute
    ? null
    : localPermissions.multiShopRoute
    ? JSON.stringify(multiSelectedShopCodes)
    : shopCode;

  const routeEditAllowed = useMemo(
    () =>
      !isEditRoute ||
      ["DRAFT", "PLANNED", "INPROGRESS"].includes(routeData.status),
    [routeData.status]
  );

  useEffect(() => {
    if (routeDeliveryDate && shopDependency?.length) getRouteAndOrdersData();
  }, [routeDeliveryDate, shopDependency]);

  useEffect(() => {
    clearTimeout(routeDetailstimerToClear.current);
    setMapKey(`${shopCode}:${moment(routeDeliveryDate).format("YYYY-MM-DD")}`);
    if (!selectedRouteId.length) {
      setOrdersList({ ...ordersList, ordersInRoute: [] });
      isRouteEditing && setIsRouteEditing(false);
      isAdhocStopEditing && setIsAdhocStopEditing(false);
      const isPastDate = moment(routeDeliveryDate).isBefore(
        moment().add(-1, "days")
      );
      isPastDate && setRouteDeliveryDate(moment().format("YYYY-MM-DD"));
    }
  }, [routeDeliveryDate, shopDependency]);

  useEffect(() => {
    clearTimeout(routeDetailstimerToClear.current);
    if (timeStamp && pollInterval && !isStopFormOpen) {
      routeDetailstimerToClear.current = setTimeout(() => {
        getRouteAndOrdersData();
        isDesktop && getAllAutoRoutesData();
      }, pollInterval);
    }
    return () => {
      clearTimeout(routeDetailstimerToClear.current);
    };
  }, [timeStamp]);

  useEffect(() => {
    if (shopDependency?.length) {
      // Fetch RouteName
      !selectedRouteId.length &&
        !routeName &&
        hasShopForFetch &&
        getRouteName(fetchShopCode, setRouteName);
      // Fetch drivers
      hasShopForFetch &&
        getDrivers(
          fetchShopCode,
          setLoading,
          setDriversData,
          multiSelectedShopCodes,
          true,
          (driverList) => {
            if (accessByDriver === "true" && !isEditRoute) {
              const driver = driverList.find(
                (each) => each?.emailId === userEmailId
              );
              const { fullName = "", id = "" } = driver || {};
              setDriver({ name: fullName, id: id });
            }
          }
        );
      isDesktop && getAllAutoRoutesData();
    }

    const { latitude: newLatitude = "", longitude: newLongitude = "" } =
      shopLocations[shopCode] || {};
    if ((!newLatitude || !newLongitude) && !localPermissions.multiShopRoute) {
      setModalVisible(true);
    }
    if (!startLocationCode && multiSelectedShopCodes?.length > 0) {
      setStartLocationCode(multiSelectedShopCodes[0]);
    }
    if (!endLocationCode && multiSelectedShopCodes?.length > 0) {
      setEndLocationCode(multiSelectedShopCodes[0]);
    }
  }, [shopDependency]);

  useEffect(() => {
    if (!scannedOrder) return;

    if (
      isEmpty(routeData) ||
      (!loading && !isPastRoute && isEditRoute && routeEditAllowed)
    ) {
      const {
        //displayDeliveryDate: orderDeliveryDate = "",
        receivingMember: { memberCode: siteId = "" } = {},
        isPrinted = false,
        orderLastModifiedDate,
        orderDetailsLastPrintedDate,
      } = scannedOrder?.scannedOrder;

      if (siteId && !multiSelectedShopCodes.includes(siteId)) {
        setMultiSelectedShopCodes([...multiSelectedShopCodes, siteId]);
      }

      const showReprintWarning =
        isPrinted &&
        orderLastModifiedDate &&
        orderDetailsLastPrintedDate &&
        moment(orderLastModifiedDate).isAfter(orderDetailsLastPrintedDate);

      if (showReprintWarning) {
        dispatch(
          setQRScanReprintWarning({
            ...qrScanReprintWarning,
            needReprint: true,
            order: scannedOrder?.orderDetails,
          })
        );
      }

      // Removing Date warning as we can add any date order to route
      // const showDateWarning =
      //   moment(orderDeliveryDate).format(
      //     "YYYY-MM-DD"
      //   ) !==
      //   moment(routeDeliveryDate).format("YYYY-MM-DD");

      // const warningMessage = `${Localise(
      //   messages,
      //   `Order delivery date does not match Route date. Do you still want to add this Order to the route?`
      // )}`;

      // if (showDateWarning) {
      //   setQRScanWarning({
      //     showWarningModal: true,
      //     message: warningMessage,
      //     action: { ...data },
      //   });
      // } else {
      //   setRouteMoveActionWrapper(data);
      // }
      setRouteMoveActionWrapper(scannedOrder);
    }
  }, [scannedOrder]);

  const buildPickupStop = (shopId) => {
    const {
      addressLine1: pickupAddressLine1 = "",
      addressLine2: pickupAddressLine2 = "",
      city: pickupCity = "",
      state: pickupState = "",
      zip: pickupZip = "",
      countryCode: pickupCountryCode = "",
      latitude: pickupLatitude = "",
      longitude: pickupLongitude = "",
    } = shopLocations[shopId] || {};
    const pickupShopName = shopNames[shopId];
    const pickupStopName = `${shopId} ${pickupShopName}`;
    const pickupStopDescription = `Pick up Orders from ${shopId} ${pickupShopName}`;
    const stopType = "PICKUP";
    const pickupStopId =
      stopType +
      hashString(
        `${pickupStopName}_${pickupStopDescription}_${moment().utc().format()}`
      );
    const pickupStopData = {
      stopType,
      stopAdditionMethod: "AUTOMATIC",
      receivingMember: { memberCode: shopId },
      orderItemId: pickupStopId,
      messageNumber: pickupStopName,
      stopName: pickupStopName,
      recipientAddressLine: pickupAddressLine1,
      recipientAddressLine2: pickupAddressLine2,
      recipientCity: pickupCity + "," + pickupState,
      recipientCityOnly: pickupCity,
      recipientState: pickupState,
      recipientZip: pickupZip,
      zip: pickupZip,
      recipientCountry: pickupCountryCode,
      recipientLocation: {
        latitude: pickupLatitude,
        longitude: pickupLongitude,
      },
      status: "NEW",
      routeId: routeData.routeId,
      imageName: "map-pin",
      statusColor: backgroundColors.primary,
      displayOrderStatus: "Added Stop",
      displayDeliveryDate: moment(routeDeliveryDate, "YYYY-MM-DD").format(
        "MM/DD/YYYY"
      ),
      stopDescription: pickupStopDescription,
      productFirstChoiceDescription: pickupStopDescription,
      addressVerificationInfo: {
        // keeping default as AVS verified
        avsConfidence: "HIGH",
        isAvsPerformed: "Y",
        isAvsSuggestedAddress: "Y",
      },
    };
    return pickupStopData;
  };

  const hasPickupStopWithSameAddress = (checkSiteId) => {
    return ordersList.ordersInRoute.find((ord) => {
      const { receivingMember: { memberCode: siteId = "" } = {} } = ord;
      return (
        ord.stopType === "PICKUP" &&
        siteId &&
        areShopLocationsEqual(siteId, checkSiteId)
      );
    });
  };

  const handleUnsavedMoveActionOrdersList = (updatedData) => {
    setOrdersList(updatedData);
    if (isStopFormOpen) {
      setUnsavedMoveActionOrdersList(updatedData);
    }
  };

  useEffect(() => {
    if (routeMoveAction?.action) {
      setDisableSave(false);
      setIsRouteEditing(true);
      if (routeMoveAction.action === "add") {
        let updatedList = {
          ordersInRoute: [],
          nonRoutedOrders: [],
        };
        const orderId =
          routeMoveAction.stopAdditionMethod === "MOBILE_APP_QR_SCAN"
            ? routeMoveAction.scannedOrder?.orderItemId
            : routeMoveAction.id;

        const { matchedList, unmatchedList } = splitArray(
          ordersList.nonRoutedOrders,
          (obj) => obj.orderItemId === orderId
        );

        const orderInfo = matchedList[0] || routeMoveAction?.scannedOrder;
        updatedList = {
          ordersInRoute:
            orderInfo && !isEmpty(orderInfo)
              ? [
                  ...ordersList.ordersInRoute,
                  {
                    ...orderInfo,
                    stopAdditionMethod: routeMoveAction.stopAdditionMethod,
                  },
                ]
              : [...ordersList.ordersInRoute],
          nonRoutedOrders: unmatchedList,
        };
        const { receivingMember: { memberCode: addedSiteId = "" } = {} } =
          orderInfo || {};

        const isFirstOrderForShop =
          localPermissions.multiShopRoute &&
          multiSelectedShopCodes?.length > 1 &&
          (
            ordersList.ordersInRoute.filter((ord) => {
              const { receivingMember: { memberCode: siteId = "" } = {} } = ord;
              return siteId && areShopLocationsEqual(siteId, addedSiteId);
            }) || []
          )?.length === 0;

        const isShopStartLocation = areShopLocationsEqual(
          startLocationCode,
          addedSiteId
        );

        const needToAddPickupStop =
          addedSiteId &&
          !isShopStartLocation &&
          isFirstOrderForShop &&
          !hasPickupStopWithSameAddress(addedSiteId);

        let stopsToHighlight = [];
        if (needToAddPickupStop) {
          const pickupStop = buildPickupStop(addedSiteId);
          updatedList = {
            ...updatedList,
            ordersInRoute: [...updatedList.ordersInRoute, pickupStop],
          };
          stopsToHighlight.push(pickupStop.orderItemId);
          ToasterHandler(
            "success",
            Localise(
              messages,
              `${Localise(
                messages,
                `Order from a different shop has been added.`
              )} ${Localise(messages, `Added Pickup stop for`)} ${
                pickupStop.stopName
              }. ${Localise(messages, `Please review.`)}`
            )
          );
        }

        handleUnsavedMoveActionOrdersList({ ...updatedList });

        if (routeMoveAction.mapAction) {
          stopsToHighlight.push(orderInfo.orderItemId);
        }
        if (stopsToHighlight?.length > 0) {
          setOrdersToHighlight(stopsToHighlight);
        }
        //After each scan turn on scanner..
        if (routeMoveAction.stopAdditionMethod === "MOBILE_APP_QR_SCAN") {
          dispatch(setOpenScanner(true));
        }
      } else if (routeMoveAction.action === "remove") {
        const filterOut = !isAdhocStopById(routeMoveAction.id)
          ? ordersList.ordersInRoute.filter(
              (obj) => obj.orderItemId === routeMoveAction.id
            )
          : [];
        let removedSiteId;
        let updatedOrderInRoute = ordersList.ordersInRoute.filter((obj) => {
          if (obj.orderItemId === routeMoveAction.id) {
            removedSiteId = get(obj, "receivingMember.memberCode");
          }
          return obj.orderItemId !== routeMoveAction.id;
        });
        const isOnlyOrderForShopRemoved =
          updatedOrderInRoute.filter((ord) => {
            return (
              areShopLocationsEqual(
                get(ord, "receivingMember.memberCode", ""),
                removedSiteId
              ) && !isAdhocStop(ord.stopType)
            );
          })?.length === 0;

        const needToRemovePickupStop =
          hasPickupStopWithSameAddress(removedSiteId) &&
          isOnlyOrderForShopRemoved;

        if (needToRemovePickupStop) {
          updatedOrderInRoute = updatedOrderInRoute.filter(
            (ord) =>
              !(
                ord.stopType === "PICKUP" &&
                get(ord, "receivingMember.memberCode", "") === removedSiteId
              )
          );
          ToasterHandler(
            "success",
            `${Localise(
              messages,
              `Removed Pickup stop for`
            )} ${removedSiteId} ${shopNames[removedSiteId]} ${Localise(
              messages,
              `Please review.`
            )}`
          );
        }

        handleUnsavedMoveActionOrdersList({
          ordersInRoute: updatedOrderInRoute,
          nonRoutedOrders: [...ordersList.nonRoutedOrders, ...filterOut],
        });

        if (isEditRoute && updatedOrderInRoute?.length === 0) {
          setModalVisible(true);
        }
        setOrdersToHighlight([]);
      } else if (routeMoveAction.action === "moveUp") {
        const updatedData = array_move(
          cloneDeep(ordersList.ordersInRoute),
          routeMoveAction.index,
          routeMoveAction.index - 1
        );
        handleUnsavedMoveActionOrdersList({
          ...ordersList,
          ordersInRoute: updatedData,
        });
        setOrdersToHighlight([]);
      } else if (routeMoveAction.action === "moveDown") {
        const updatedData = array_move(
          cloneDeep(ordersList.ordersInRoute),
          routeMoveAction.index,
          routeMoveAction.index + 1
        );
        handleUnsavedMoveActionOrdersList({
          ...ordersList,
          ordersInRoute: updatedData,
        });
        setOrdersToHighlight([]);
      } else if (routeMoveAction.action === "addAll") {
        let siteIdSet = new Set();
        const addOrders = filteredNonRoutedOrders?.map((ord) => {
          const siteId = get(ord, "receivingMember.memberCode");
          const isShopStartLocation = areShopLocationsEqual(
            startLocationCode,
            siteId
          );

          if (!hasPickupStopWithSameAddress(siteId) && !isShopStartLocation) {
            siteIdSet.add(siteId);
          }
          return {
            ...ord,
            stopAdditionMethod: routeMoveAction.stopAdditionMethod,
          };
        });
        let updatedOrderInRoute = [...ordersList.ordersInRoute, ...addOrders];
        let siteIdList = [...siteIdSet];
        siteIdList?.map((siteId) => {
          const pickupStop = buildPickupStop(siteId);
          updatedOrderInRoute.push(pickupStop);
          ToasterHandler(
            "success",
            `${Localise(messages, `Added Pickup stop for`)} ${
              pickupStop.stopName
            }. ${Localise(messages, `Please review.`)}`
          );
        });
        handleUnsavedMoveActionOrdersList({
          ordersInRoute: [...updatedOrderInRoute],
          nonRoutedOrders: [],
        });
        setOrdersToHighlight([]);
      } else if (["drawOnMap", "addChecked"].includes(routeMoveAction.action)) {
        let updatedList = { ...routeMoveAction.ordersList };
        let siteIdSet = new Set();

        routeMoveAction.ordersList?.ordersInRoute?.map((ord) => {
          if (isAdhocStop(ord.stopType)) return;
          const siteId = get(ord, "receivingMember.memberCode", "");
          const isShopStartLocation = areShopLocationsEqual(
            startLocationCode,
            siteId
          );

          if (!isShopStartLocation && !hasPickupStopWithSameAddress(siteId)) {
            siteIdSet.add(siteId);
          }
        });
        let siteIdList = [...siteIdSet];
        siteIdList?.map((siteId) => {
          const pickupStop = buildPickupStop(siteId);
          updatedList.ordersInRoute.push(pickupStop);
          ToasterHandler(
            "success",
            `${Localise(messages, `Added Pickup stop for`)} ${
              pickupStop.stopName
            }. ${Localise(messages, `Please review.`)}`
          );
        });
        setOrdersList(updatedList);
      } else if (routeMoveAction.action === "saveStop") {
        setIsAddStopFormOpen(false);
        setRecordId("");
        setOrdersToHighlight([]);
      }

      if (
        ["add", "remove", "addAll", "drawOnMap"].includes(
          routeMoveAction.action
        )
      ) {
        setCheckedNonRouted([]);
        selectAllChecked && setSelectAllChecked(!selectAllChecked);
      }

      if (
        [
          "add",
          "remove",
          "moveUp",
          "moveDown",
          "addAll",
          "drawOnMap",
          "saveStop",
          "addChecked",
        ].includes(routeMoveAction.action)
      ) {
        setEraseMap(true);
      }
    }
  }, [routeMoveAction]);

  useEffect(() => {
    setDisableSave(!(isRouteEditing || isAdhocStopEditing));
    if (
      isRouteEditing ||
      isAdhocStopEditing ||
      qrScanVariant === "order-route-scan"
    ) {
      clearTimeout(routeDetailstimerToClear.current);
    } else {
      setTimestamp(moment().utc().format());
    }
  }, [isRouteEditing, isAdhocStopEditing, qrScanVariant]);

  useEffect(() => {
    setAutoScroll(!scrollEnabled ? "mapScroll" : "");
  }, [scrollEnabled]);

  const hasPickupStopInRoute = !!ordersList?.ordersInRoute?.find((ord) => {
    const { receivingMember: { memberCode: siteId = "" } = {} } = ord;
    return ord.stopType === "PICKUP" && siteId;
  });

  const hasTimedDeliveriesInRoute = !!ordersList?.ordersInRoute?.find((ord) => {
    const {
      isRushOrder = false,
      orderDeliveryTime = "",
      deliveryInstructions = "",
    } = ord;
    return !!(
      (
        orderDeliveryTime ||
        (isRushOrder && extractTimeFromDI(deliveryInstructions))
      ) //For old rush orders before timed delivery feature
    );
  });

  const getDriversAndSaveRoute = (shopCodes) => {
    hasShopForFetch &&
      getDrivers(
        fetchShopCode,
        setLoading,
        setDriversData,
        shopCodes,
        true,
        (driverList) => {
          if (
            driverList.filter((eachDriver) => {
              const { id } = eachDriver;
              return driver.id === id;
            })?.length == 0
          ) {
            setDriver({ name: "", id: "" });
          }
          RouteDetailsFormRef.current.handleSubmit();
        }
      );
  };

  //Formik save
  const triggerSaveRoute = async (values, formikBag) => {
    if (formikBag.isSubmitting) return;

    let stopsData = [];
    setLoading(true);
    setRouteSaving(true);

    // fetch routeName incase if previous call fails.
    isEmpty(routeData) && !routeName && getRouteName(shopCode, setRouteName);

    const ordersInRouteList = cloneDeep(ordersList?.ordersInRoute);

    // If the stop form(Add/edit) is open, save the route on clicking "Save Draft" / "Show On Map"
    if (isStopFormOpen && RouteStopFormRef?.current) {
      const adhocStopFormValid = await RouteStopFormRef.current
        .validateForm()
        .then(() => {
          const isValid = RouteStopFormRef.current.isValid;
          if (isValid) {
            const adhocStopValues = RouteStopFormRef.current.values;
            const { stopName, stopDescription } = adhocStopValues;
            let stopStatus = "NEW";
            let stopType = "CUSTOM";
            let stopDeliveryMethod = "";
            let stopShopCode = "";
            if (isEditStopOpen) {
              const selectedStopInfo =
                ordersInRouteList?.find((ord) => ord.orderItemId == recordId) ||
                {};
              stopStatus = selectedStopInfo.status;
              stopType = selectedStopInfo.stopType;
              stopDeliveryMethod = selectedStopInfo.deliveryMethod;
              stopShopCode = get(
                selectedStopInfo,
                "receivingMember.memberCode",
                ""
              );
            }
            const selectedStopSequenceNumber = ordersInRouteList?.findIndex(
              (ord) => ord.orderItemId == recordId
            );
            const stopId = isEditStopOpen
              ? recordId
              : "ADHOC" +
                hashString(
                  `${stopName}_${stopDescription}_${moment().utc().format()}`
                );
            const adhocStop = adhocStopObject({
              values: adhocStopValues,
              stopId,
              addressVerificationInfo: AVSInfo[0],
              displayDeliveryDate: moment(
                routeDeliveryDate,
                "YYYY-MM-DD"
              ).format("MM/DD/YYYY"),
              stopStatus,
              routeId: routeData.routeId,
              stopType,
              deliveryMethod: stopDeliveryMethod,
              siteId: stopShopCode,
            });

            if (isEditStopOpen) {
              ordersInRouteList[selectedStopSequenceNumber] = adhocStop;
            } else {
              ordersInRouteList.push(adhocStop);
            }
            setOrdersList({ ...ordersList, ordersInRoute: ordersInRouteList });
            setIsAddStopFormOpen(false);
            setRecordId("");
            setOrdersToHighlight([]);
          } else {
            formikBag.setSubmitting(false);
            setLoading(false);
            setRouteSaving(false);
          }
          return isValid;
        })
        .catch(() => {
          formikBag.setSubmitting(false);
          setLoading(false);
          setRouteSaving(false);
          return false;
        });

      //abort saving route if adhocStopForm throws validation error
      if (!adhocStopFormValid) {
        RouteStopFormRef.current.handleSubmit();
        return;
      }
    }

    const {
      selectedMemberCodes = [],
      expectedStartTime: fieldValExpectedStartTime,
    } = values;

    ordersInRouteList.map((ord, stopIndex) => {
      const {
        deliveryInstructions = "",
        isRushOrder = false,
        recipientLocation: stopLocation = {},
        addressVerificationInfo = {},
        receivingMember: { memberCode: siteId = "" } = {},
        stopAdditionMethod = "MANUAL",
        actualArrivalTime,
        orderDeliveryTime = "",
        deliveryMethod,
      } = ord;
      const { latitude: stopLatitude = "", longitude: stopLongitude = "" } =
        stopLocation;

      const deliverByTime = moment(
        orderDeliveryTime ||
          (isRushOrder && extractTimeFromDI(deliveryInstructions)), //For old rush orders before timed delivery feature
        "h:mm A"
      ).format("hh:mmA");

      // const rushTime = moment(
      //   deliveryInstructions.replace("Deliver by ", "").split("\n")[0],
      //   "HH:mma"
      // ).format("hh:mmA");

      const deliverByDateTime = moment(
        moment(routeDeliveryDate).format("YYYY-MM-DD") + " " + deliverByTime,
        "YYYY-MM-DD hh:mmA"
      ).format(dateTimeFormat);
      const shopTimeZone = UserProfileStorage.getShopTimeZone(shopCode);
      const floristTimeZone = shopTimeZone || "America/Chicago";
      const deliverByTimeUTC = moment
        .tz(deliverByDateTime, dateTimeFormat, floristTimeZone)
        .utc()
        .format(dateTimeFormat);

      const isValidDeliverBy = moment(deliverByTime, "hh:mmA", true).isValid();
      const stopStatus = ["DELIVERED", "COMPLETED"].includes(ord.status)
        ? "COMPLETED"
        : ord.status === "INCOMPLETE"
        ? "INCOMPLETE"
        : "NEW";
      const stopType = ord.stopType || "ORDER";
      stopsData.push({
        type: stopType,
        orderId: ord.orderItemId,
        ...(["ORDER", "PICKUP"].includes(stopType) && {
          siteId,
        }),
        status: stopStatus,
        stopAdditionMethod,
        ...(actualArrivalTime && { actualArrivalTime }),
        stopName: ord.stopName,
        address: {
          addressLine1: ord.recipientAddressLine,
          addressLine2: ord.recipientAddressLine2,
          city: ord.recipientCityOnly,
          state: ord.recipientState,
          zip: ord.recipientZip,
          country: ord.recipientCountry,
          geoLocation: {
            latitude: stopLatitude,
            longitude: stopLongitude,
          },
          addressVerificationInfo,
        },
        instructions: ord.stopDescription,
        orderSequence: stopIndex + 1,
        deliveryMethod,
        deliverByTime:
          (isRushOrder || !!orderDeliveryTime) &&
          deliveryInstructions
            .toLowerCase()
            .includes("Deliver by ".toLowerCase()) &&
          isValidDeliverBy
            ? deliverByTimeUTC
            : null,
      });
    });

    const startLocationDetails = {
      addressLine1: startAddressLine1,
      addressLine2: startAddressLine2,
      city: startCity,
      state: startState,
      zip: startZip,
      country: startCountryCode,
      timeZone:
        UserProfileStorage.getShopTimeZone(startLocationCode) ||
        "America/Chicago",
      geoLocation: {
        latitude: startLatitude,
        longitude: startLongitude,
      },
    };

    const endLocationDetails = {
      addressLine1: endAddressLine1,
      addressLine2: endAddressLine2,
      city: endCity,
      state: endState,
      zip: endZip,
      country: endCountryCode,
      geoLocation: {
        latitude: endLatitude,
        longitude: endLongitude,
      },
    };

    const reqPayload = {
      routeId: isEmpty(routeData) ? "" : routeData.routeId,
      ...(autoRouteId && { autoRouteId }),
      routeName: isEmpty(routeData) ? routeName : routeData.routeName,
      memberCodes: selectedMemberCodes?.length
        ? selectedMemberCodes
        : shopCode?.toLowerCase().includes("all")
        ? [selectedShopCode]
        : [shopCode],
      status:
        isEmpty(routeData) || (!isEmpty(actionType) && actionType === "save")
          ? "DRAFT"
          : routeData.status,
      deliveryDate: moment(routeDeliveryDate).format("YYYY-MM-DD"),
      ...(isEditRoute && routeData.departureTime
        ? { departureTime: routeData.departureTime }
        : { departureTime: "" }),
      ...(isEditRoute &&
        routeData.estimatedReturnTime && {
          estimatedReturnTime: routeData.estimatedReturnTime,
        }),
      returnTime: "",
      startLocation: hasLimitedRouteAccess
        ? routeData?.startLocation
        : startLocationDetails,
      endLocation: hasLimitedRouteAccess
        ? routeData?.endLocation
        : endLocationDetails,
      ordersCount: ordersInRouteList.length,
      completed: isEmpty(routeData) ? 0 : routeData.completed,
      ...(isEditRoute &&
        routeData.createdOn && {
          createdOn: routeData.createdOn,
        }),
      ...(isEditRoute &&
        routeData.createdBy && {
          createdBy: routeData.createdBy,
        }),
      driver: { name: driver.name, id: driver.id },
      stops: stopsData,
      optimize: values.optimize || false,
      ...(localPermissions.isOptimizeEnabled &&
        localPermissions.isCFROptimiseEnabled && {
          expectedStartTime: moment
            .tz(
              expectedStartTime || fieldValExpectedStartTime,
              dateTimeFormat,
              floristTimeZone
            )
            .utc()
            .format(dateTimeFormat),
        }),
    };
    setEraseMap(true);
    dispatch(setCanProgrammaticallyZoom(true));

    request(!isEditRoute ? "create-route" : "update-route", {
      body: reqPayload,
      shopCode: isEditRoute
        ? routeData?.memberCodes.filter((each) =>
            multiSelectedShopCodes.includes(each)
          )[0]
        : reqPayload.memberCodes[0],
    })
      .then((res) => {
        setSavedRoutes(res);
        setRouteData(res);
        setDisableSave(true);
        setEraseMap(false);
        setRouteSaving(false);
        ToasterHandler(
          "success",
          `${Localise(messages, `Your`)} ${
            isEmpty(routeData) ? routeName : routeData.routeName
          } ${Localise(messages, `has been saved successfully`)}`
        );
        isRouteEditing && setIsRouteEditing(false);
        isAdhocStopEditing && setIsAdhocStopEditing(false);
        if (
          localPermissions.multiShopRoute &&
          !isEditRoute &&
          !isFTDUser &&
          !proxyUser &&
          filteredShopCodes?.length > 1 &&
          !isEqual(reqPayload.memberCodes, routeMemberPreferences)
        ) {
          const prefReqPayload = {
            firstName: userFirstName,
            lastName: userLastName,
            phone: userPhone,
            email: userEmailId,
            preferences: [
              ...userPreferences.filter(
                (pref) => pref.name !== "routeMemberPreferences"
              ),
              {
                name: "routeMemberPreferences",
                value: reqPayload.memberCodes.join(","),
              },
            ],
          };
          saveUserPreferences({
            reqPayload: prefReqPayload,
            resolve: () => {
              setUserProfile({ ...userProfile, ...prefReqPayload });
            },
          });
        }
        if (qrScanReprintWarning.needReprint) {
          dispatch(
            setQRScanReprintWarning({
              ...qrScanReprintWarning,
              showWarningModal: true,
            })
          );
        }
        isRouteSave && setIsRouteSave(false);
        navigation.dispatch(
          CommonActions.navigate({
            name: "edit-route",
            params: {
              routeId: (res && res.routeId) || "",
              accessByDriver,
              ...(selectedMemberCodes?.length && {
                memberCodes: selectedMemberCodes.join(","),
              }),
            },
          })
        );
        isRouteSave && setIsRouteSaveSuccess(true);
        setCurrentPage("edit-route");
        setLoading(false);
        setSaveCount(0);
        const openDriverRoute = () => {
          redirectTo("lmds-routes", {
            routeId: (res && res.routeId) || "",
            shopCode: [...selectedMemberCodes].includes(driverDashboardShopCode)
              ? driverDashboardShopCode || shopCode
              : selectedMemberCodes[0] || shopCode,
          });
        };
        if (eventAfterSaveRoute?.action === "optimize") {
          setEventAfterSaveRoute({ action: "", actionType: "" });
          const { resolveCallBack = () => {} } = eventAfterSaveRoute;
          OptimizeAction({
            onlyOptimize: true,
            routeId: res.routeId,
            resolveCallBack: () => {
              resolveCallBack();
              isEditRoute && accessByDriver === "true" && openDriverRoute();
            },
          });
        } else if (
          eventAfterSaveRoute?.actionType === "update" &&
          ["PLANNED", "INPROGRESS"].includes(eventAfterSaveRoute?.action)
        ) {
          setEventAfterSaveRoute({ action: "", actionType: "" });
          actionHandler({
            action: eventAfterSaveRoute?.action,
            routeId: selectedRouteId,
            actionType: eventAfterSaveRoute?.actionType,
          });
        } else if (isEditRoute && accessByDriver === "true") {
          openDriverRoute();
        } else {
          setSelectedAction("");
        }

        if (isEditRoute && accessByDriver !== "true") {
          getRouteAndOrdersData();
          isDesktop && getAllAutoRoutesData();
          // Fetch drivers
          hasShopForFetch &&
            getDrivers(
              fetchShopCode,
              setLoading,
              setDriversData,
              multiSelectedShopCodes,
              true
            );
        }
      })
      .catch((err) => {
        setLoading(false);
        if (saveCount < 3 && routeMoveAction.mapAction) {
          setSaveCount(saveCount + 1);
          RouteDetailsFormRef?.current?.handleSubmit();
        } else {
          console.log("error on save route", err);

          const defaultMessage = "Unable to save the Route. Please try again.";
          handleError(
            err,
            ToasterHandler,
            Localise,
            messages,
            () => {},
            defaultMessage
          );

          if (isRouteSave) {
            setIsRouteSave(false);
          }
          setSaveCount(0);
          setRouteSaving(false);
          if (routeMoveAction.stopAdditionMethod === "MOBILE_APP_QR_SCAN") {
            if (qrScanReprintWarning.needReprint) {
              dispatch(resetQRScanReprintWarning());
            }
            getRouteAndOrdersData();
            // Fetch drivers
            hasShopForFetch &&
              getDrivers(
                fetchShopCode,
                setLoading,
                setDriversData,
                multiSelectedShopCodes,
                true
              );
          } else {
            setDisableSave(false);
          }
        }
      })
      .finally(() => {
        routeMoveAction && setRouteMoveAction("");
      });
  };

  const redirectTo = (page, params = {}) => {
    setCurrentPage(page);
    navigation.dispatch(
      CommonActions.navigate({
        name: page,
        params,
      })
    );
  };

  const saveAndOptimize = ({ resolveCallBack = () => {} } = {}) => {
    setEventAfterSaveRoute({ action: "optimize", resolveCallBack });
    RouteDetailsFormRef.current.handleSubmit();
  };

  const OptimizeAction = ({
    onlyOptimize = false,
    forceOptimization = false,
    skipCFRConfirm = false,
    optmiseWithCFR = true,
    resolveCallBack = () => {},
    routeId = "",
  } = {}) => {
    if (
      (isStopFormOpen || isRouteEditing || isAdhocStopEditing) &&
      !onlyOptimize
    ) {
      saveAndOptimize({ resolveCallBack });
      return;
    } else {
      setSelectedAction("");
      if (
        !localPermissions.isCFROptimiseEnabled &&
        ordersList.ordersInRoute?.length > 25
      ) {
        setShowLargeRouteModal(true);
      } else if (
        localPermissions.isCFROptimiseEnabled &&
        ordersList.ordersInRoute?.length > 60
      ) {
        setShow60StopsCFRModal(true);
      } else if (
        localPermissions.isCFROptimiseEnabled &&
        !forceOptimization &&
        !skipCFRConfirm &&
        !hasPickupStopInRoute &&
        ordersList.ordersInRoute?.length < 25 &&
        hasTimedDeliveriesInRoute
      ) {
        setShowCFRConfirmModal(true);
      } else {
        setOrdersToHighlight([]);
        setLoading(true);
        request("route-optimize", {
          shopCode: fetchShopCode,
          routeId: routeId || routeData.routeId,
          isCFROptimise:
            localPermissions.isCFROptimiseEnabled && !!optmiseWithCFR,
          body: {
            optimizationAccuracy: "MEDIUM",
            ...(forceOptimization && { forceOptimization }),
          },
        })
          .then((res) => {
            setEraseMap(true);
            setSavedRoutes(res);
            setRouteData(res);
            setDisableSave(true);
            setEraseMap(false);
            ToasterHandler(
              "success",
              Localise(messages, "Your Route has been optimized")
            );
            setIsRouteOptimised(true);
            optimiseRoute && setOptimiseRoute(false);
            optimiseRoute && setIsRouteOptimiseDone(true);
            resolveCallBack();
          })
          .catch((error) => {
            console.log("error on Optimize:>> ", error);
            if (
              error.includes(
                "Do you still want to optimize the route with these orders?"
              ) &&
              error.includes("We suggest starting this route at")
            ) {
              const recommendedStartTime = error
                .split("at")
                .pop()
                .split("to")[0]
                .trim();
              const localRecommendedStartTime = moment(
                convertToTimeZone(recommendedStartTime, floristTimeZone)
              ).format("MM/DD/YYYY hh:mm A");

              setForceOptimize({
                showforceOptimizeModal: true,
                recommendedStartTime: localRecommendedStartTime,
                delayedDeliveries: [],
              });
            } else {
              let errorMessage =
                "Unable to optimize the Route. Please try again.";
              if (
                !["ERROR", "CATCH ALL ERROR", "null"].includes(error) &&
                error.length > 0
              ) {
                if (
                  error.includes(
                    "Do you still want to optimize the route with these orders?"
                  )
                ) {
                  errorMessage =
                    "Cannot optimize route due to some orders being too far to deliver on the route's delivery date.";
                } else {
                  errorMessage = error;
                }
              }
              ToasterHandler("uh oh", Localise(messages, errorMessage));
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const getRouteManifest = (routeId) => {
    setLoading(true);
    request("get-route-manifest", {
      shopCode: fetchShopCode,
      routeId: routeId,
    })
      .then((res) => {
        PrintIframeAction(getPrintHTML(res, Localise, messages), () => {});
        setSelectedAction("");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    request("update-route-manifest-print-status", {
      shopCode: fetchShopCode,
      routeId: routeId,
    })
      .then((res) => {
        res && setRouteData(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const updateStatus = (newStatus, routeId, { resolve, reject }) => {
    setLoading(true);
    request("update-route-status", {
      shopCode: fetchShopCode,
      newStatus,
      routeId,
    })
      .then((res) => {
        setLoading(false);
        resolve && resolve(res);
      })
      .catch(() => {
        setLoading(false);
        reject && reject();
        console.log("error");
      })
      .finally(() => {
        setSelectedAction("");
      });
  };

  const actionHandler = ({ action, routeId, actionType }) => {
    if (actionType === "update") {
      if (
        action === "INPROGRESS" &&
        RouteDetailsFormRef.current.values.driver.name === ""
      ) {
        setDriverAssignment(false);
        RouteDetailsFormRef.current.setFieldError(
          "driver.name",
          Localise(messages, "Select driver name")
        );
        setSelectedAction("");
        return;
      }

      if (action === "COMPLETED") {
        if (hasNonDeliveredOrdersInRoute(routeData?.stops)) {
          setCompleteRouteModal({
            modalVisible: true,
            routeStops: routeData?.stops,
          });
          return;
        }
      }
      if (["PLANNED", "INPROGRESS"].includes(action)) {
        if (isStopFormOpen) {
          setEventAfterSaveRoute({ action, actionType });
          RouteStopFormRef.current.handleSubmit();
          return;
        }
      }
      setLoading(true);
      updateStatus(action, routeId, {
        resolve: (res) => {
          res && setRouteData(res);
          setLoading(false);
          if (action === "COMPLETED") {
            getRouteAndOrdersData();
          }
        },
      });
    } else if (actionType?.includes("save")) {
      !driverAssignment && setDriverAssignment(true);
      setActionType(actionType);
      setOrdersToHighlight([]);
      RouteDetailsFormRef.current.handleSubmit();
    } else if (actionType === "optimize") {
      OptimizeAction();
    } else if (actionType === "print") {
      getRouteManifest(routeId);
    }
    setSelectedAction("");
  };

  const handleSaveRoute = () => {
    setOrdersToHighlight([]);
    RouteDetailsFormRef.current.handleSubmit();
    setModalVisible(false);
  };
  const handleDeleteRoute = () => {
    updateStatus("DELETED", selectedRouteId, {
      resolve: () => {
        redirectTo("lmds-delivery");
      },
      reject: () => {
        ToasterHandler(
          "error",
          Localise(messages, "Unable to delete the Route. Please try again.")
        );
        getRouteAndOrdersData();
      },
    });
    setModalVisible(false);
    isRouteEditing && setIsRouteEditing(false);
    isAdhocStopEditing && setIsAdhocStopEditing(false);
    isRouteSave && setIsRouteSave(false);
  };
  const handleCompleteRoute = () => {
    updateStatus("COMPLETED", selectedRouteId, {
      resolve: (res) => {
        res && setRouteData(res);
        getRouteAndOrdersData();
      },
    });
    setCompleteRouteModal({ modalVisible: false });
  };
  const handleNavigateBack = () => {
    setModalVisible(false);
    redirectTo("lmds-delivery");
  };
  const rePrintOrder = (orderDetails) => {
    const {
      orderItemId = "",
      deliveryInfo: { deliveryMethod },
      receivingMember: { memberCode: sourceMemberCode },
      orderCustomerInfo,
      lineItems,
      multiProductEligible = false,
      orderSource,
      direction = "",
    } = orderDetails;

    let qrImageData = "";
    const {
      order_details_print_settings = [],
      order_details_outgoing_print_settings = [],
      qr_code_global_enabled = "",
      qr_code_order_details_enabled = "",
    } = UserProfileStorage.getShopPreferences(sourceMemberCode);
    const showQrCode = qr_code_global_enabled === "true";
    const enableOrderDetailQrCode = qr_code_order_details_enabled === "true";
    const showOrderDetailQrCode = showQrCode ? enableOrderDetailQrCode : false;

    // QR Data Payload for generating the QR code
    const qrData = [
      {
        oid: orderItemId || "", // orderItemId
        sm: sourceMemberCode || "", // receivingMemberCode as only INBOUND orders are eligible for Route
        dm: deliveryMethod || "", // order deliveryMethod
      },
    ];

    const isInBoundOrder = direction === "INBOUND";
    const isOutBoundOrder = direction === "OUTBOUND";

    const printPreferencesArray = isInBoundOrder
      ? order_details_print_settings
      : isOutBoundOrder
      ? order_details_outgoing_print_settings
      : [];

    const isRemotePrintEnabled = hasRemotePrintEnabled(printPreferencesArray);

    const remotePrinterName = isRemotePrintEnabled
      ? getRemotePrinterName(printPreferencesArray)
      : "";

    // order detail print trigger
    const orderDetailPrint = () => {
      const printPreferences =
        (isElectronApp &&
          (printPreferencesArray || []).find(
            (each) => each.macAddress === macAddress
          )) ||
        {};
      const products = multiProductEligible
        ? lineItems.filter((lineItem) => lineItem.type === "Product")
        : lineItems;

      //printAction after reprint
      const orderPrintAction = (remotePrint = false) => {
        request("order-actions", {
          recordId: orderItemId,
          deliveryMethod,
          action: "print",
          askId: "",
          operator: userFirstName,
          isMessage: false,
          sourceMemberCode: sourceMemberCode,
          transferMemberCode: "",
          ...(remotePrint && { remotePrintRequest: true }),
        })
          .then(() => {
            remotePrint &&
              showPrintToaster({
                remotePrint,
                printerName: remotePrinterName,
                artifactName: "Order Details",
                Localise,
                messages,
              });
          })
          .catch((err) => {
            console.log("error", err);
          });
      };

      //PrintIframeAction for order details reprint
      const iframePrintAction = (productsInfo = []) => {
        PrintIframeAction(
          getOrderDetailPrintHTML({
            orderDetails,
            memberCodes: userMemberCodes,
            operatorName: userFirstName, //operator
            messages,
            Localise,
            selectedproducts: productsInfo,
            customerInfo: orderCustomerInfo,
            qrImageData,
          }),
          { printType: "orderDetails", preferences: printPreferences },
          () => {
            dispatch(setOpenScanner(true));
            orderPrintAction(false);
          }
        );
      };

      if (products.length) {
        let promises = [];
        products.forEach((product) => {
          const productId = product?.productFirstChoiceCode || "";
          if (productId) {
            const productResp = request("get-product-details", {
              productId: productId.toUpperCase(),
              siteId: sourceMemberCode,
              orderSource:
                orderSource === "FLORIST" &&
                deliveryMethod !== "FLORIST_PARTNER"
                  ? "LOCAL"
                  : orderSource,
            });
            promises.push(productResp);
          }
        });

        Promise.all(promises).then((responses = []) => {
          let productsInfo = [];
          responses.map((productResp) => {
            if (productResp?.products?.length > 0) {
              const productInfo = processSelectedProductResponse(
                (productResp && productResp.products) || []
              );
              productsInfo.push(productInfo);
            }
          });
          if (isRemotePrintEnabled) {
            orderPrintAction(true);
            return;
          }
          iframePrintAction(productsInfo);
        });
      } else {
        if (isRemotePrintEnabled) {
          orderPrintAction(true);
          return;
        }
        iframePrintAction([]);
      }
    };

    showOrderDetailQrCode
      ? dispatch(
          generateQRCode({
            params: { qrPayload: qrData },
            type: "SINGLE",
            resolve: (resp) => {
              qrImageData = resp[orderItemId] || "";
              orderDetailPrint();
            },
            reject: () => {
              orderDetailPrint();
            },
          })
        )
      : orderDetailPrint();
  };
  const setRouteMoveActionWrapper = (actionPayload) => {
    const { action, id } = actionPayload;
    action === "remove" && isAdhocStopById(id)
      ? setRemoveStopAction(actionPayload)
      : setRouteMoveAction(actionPayload);
  };

  useEffect(() => {
    if (isEditRoute && routeData?.stops?.length) {
      setOrderSequences(routeData, ordersList, setOrdersList, setLoading);
    }
  }, [JSON.stringify(routeData?.stops)]);

  const modalContent = {
    content: (
      <Text style={{ ...tw("p-4"), fontSize: 15, color: colors.highlighter }}>
        {Localise(
          messages,
          `${
            (!latitude || !longitude) && !localPermissions.multiShopRoute
              ? "Unable to determine store location. Please contact support to add your location to Mercury HQ."
              : DELIVERY.DELETE_ROUTE
          }`
        )}
      </Text>
    ),
    buttons:
      (!latitude || !longitude) && !localPermissions.multiShopRoute
        ? [{ type: "primary", title: Localise(messages, "Go Back") }]
        : [
            { type: "secondary", title: Localise(messages, "Cancel") },
            { type: "primary", title: Localise(messages, "Continue") },
          ],
  };

  const modal = (
    <>
      <CustomModal
        modalVisible={modalVisible}
        modalContent={modalContent}
        primaryhandler={
          (!latitude || !longitude) && !localPermissions.multiShopRoute
            ? handleNavigateBack
            : handleDeleteRoute
        }
        secondaryhandler={handleSaveRoute}
        contentStyle={[
          tw("border border-black p-2"),
          {
            backgroundColor: backgroundColors.secondary,
            textAlign: "center",
          },
        ]}
        modalStyle={
          Platform.OS !== "web"
            ? {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#00000070",
                color: colors.secondary,
              }
            : { width: "35%" }
        }
      />
      <CompleteRouteModal
        Localise={Localise}
        messages={messages}
        primaryhandler={handleCompleteRoute}
      />
      <ConfirmModal
        modalVisible={!!removeStopAction}
        handlePrimary={() => {
          setRouteMoveAction(removeStopAction);
          setRemoveStopAction(null);
        }}
        handleSecondary={() => {
          setRemoveStopAction(null);
        }}
        data={{
          modal: {
            primary: "Remove",
            content: Localise(
              messages,
              "Are you sure you want to remove this Stop?"
            ),
          },
        }}
        contentStyle={{ textAlign: "center" }}
      />
      {isRouteSaving && (
        <CustomModal
          modalVisible={isRouteSaving}
          modalContent={{
            content: (
              <View style={tw("flex flex-row items-center")}>
                <ActivityIndicator
                  style={{ marginLeft: 5, marginTop: isMobile ? -2 : -5 }}
                  color={colors.activityIndicator}
                />
                <Text style={{ marginLeft: 10 }}>
                  {`${Localise(messages, `Saving your route. Just a moment.`)}`}
                </Text>
              </View>
            ),
          }}
          contentStyle={[
            tw(`${isMobile ? `pt-4 pb-2 px-4` : `p-4`}`),
            { backgroundColor: "white" },
          ]}
          modalStyle={
            Platform.OS !== "web" && {
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor: "#00000070",
              color: "#FFFFFF",
            }
          }
        />
      )}
      {showShopRemovedModal && (
        <CustomModal
          modalVisible={showShopRemovedModal}
          modalContent={{
            content: (
              <Text
                style={{
                  ...tw("p-4"),
                  fontSize: 15,
                  color: colors.highlighter,
                }}
              >
                {Localise(
                  messages,
                  "Are you sure you want to remove the shop selection? This will also remove its orders from this route."
                )}
              </Text>
            ),
            buttons: [
              { type: "secondary", title: Localise(messages, "Nevermind") },
              { type: "primary", title: Localise(messages, "Continue") },
            ],
          }}
          primaryhandler={() => {
            const filteredCodes =
              multiSelectedShopCodes?.filter(
                (eachCode) => !shopRemoved?.includes(eachCode)
              ) || [];
            setMultiSelectedShopCodes([...filteredCodes]);
            let updatedList = {
              ordersInRoute: [],
              nonRoutedOrders: [],
            };
            updatedList.ordersInRoute = ordersList.ordersInRoute.filter(
              (ord) => {
                const { receivingMember: { memberCode: siteId = "" } = {} } =
                  ord;
                const stopType = ord.stopType || "ORDER";
                const includeStop = ["PICKUP"].includes(stopType)
                  ? filteredCodes?.includes(siteId) &&
                    !(
                      filteredCodes[0] === siteId &&
                      (shopRemoved?.some((removedShopCode) =>
                        areShopLocationsEqual(
                          removedShopCode,
                          startLocationCode
                        )
                      ) ||
                        !startLocationCode)
                    )
                  : ["ORDER"].includes(stopType)
                  ? filteredCodes?.includes(siteId)
                  : ["CUSTOM"].includes(stopType);
                return includeStop;
              }
            );
            const filteredOut = ordersList.nonRoutedOrders.filter((ord) => {
              const { receivingMember: { memberCode: siteId = "" } = {} } = ord;
              return shopRemoved?.includes(siteId);
            });
            updatedList.nonRoutedOrders = [
              ...ordersList.nonRoutedOrders,
              ...filteredOut,
            ];
            setEraseMap(true);
            setOrdersList({ ...updatedList });
            RouteDetailsFormRef.current.handleSubmit();

            setShowShopRemovedModal(false);
          }}
          secondaryhandler={() => {
            setShowShopRemovedModal(false);
          }}
          contentStyle={[
            tw("border border-black p-2"),
            {
              backgroundColor: backgroundColors.secondary,
              textAlign: "center",
            },
          ]}
          modalStyle={
            Platform.OS !== "web"
              ? {
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  backgroundColor: "#00000070",
                  color: "#FFFFFF",
                }
              : { width: "35%" }
          }
        />
      )}
      {showLargeRouteModal && (
        <CustomModal
          modalVisible={showLargeRouteModal}
          modalContent={{
            content: (
              <Text
                style={{
                  ...tw("p-4"),
                  fontSize: 15,
                  color: colors.highlighter,
                }}
              >
                {Localise(
                  messages,
                  "Cannot optimize more than 25 stops. Please contact support for additional optimization needs exceeding this limit"
                )}
              </Text>
            ),
            buttons: [{ type: "primary", title: Localise(messages, "OK") }],
          }}
          primaryhandler={() => {
            setShowLargeRouteModal(false);
          }}
          contentStyle={[
            tw("border border-black p-2"),
            {
              backgroundColor: backgroundColors.secondary,
              textAlign: "center",
            },
          ]}
          modalStyle={
            Platform.OS !== "web"
              ? {
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  backgroundColor: "#00000070",
                  color: "#FFFFFF",
                }
              : { width: "35%" }
          }
        />
      )}
      {show60StopsCFRModal && (
        <CustomModal
          modalVisible={show60StopsCFRModal}
          modalContent={{
            content: (
              <Text
                style={{
                  ...tw("p-4"),
                  fontSize: 15,
                  color: colors.highlighter,
                }}
              >
                {Localise(
                  messages,
                  "Cannot optimize more than 60 stops. Please contact support for additional optimization needs exceeding this limit"
                )}
              </Text>
            ),
            buttons: [{ type: "primary", title: Localise(messages, "OK") }],
          }}
          primaryhandler={() => {
            setShow60StopsCFRModal(false);
          }}
          contentStyle={[
            tw("border border-black p-2"),
            {
              backgroundColor: backgroundColors.secondary,
              textAlign: "center",
            },
          ]}
          modalStyle={
            Platform.OS !== "web"
              ? {
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  backgroundColor: "#00000070",
                  color: "#FFFFFF",
                }
              : { width: "35%" }
          }
        />
      )}
      {forceOptimize.showforceOptimizeModal && (
        <CustomModal
          modalVisible={forceOptimize.showforceOptimizeModal}
          modalContent={{
            content: (
              <Text
                style={{
                  ...tw("p-4"),
                  fontSize: 15,
                  color: colors.highlighter,
                }}
              >
                {Localise(messages, "We suggest starting this route at")}{" "}
                {forceOptimize.recommendedStartTime}{" "}
                {Localise(messages, "for on-time delivery.")}{" "}
                {Localise(
                  messages,
                  "However, some timed deliveries might not reach their destination on time if you proceed with this schedule. Do you want to optimize the route to include these orders?"
                )}{" "}
              </Text>
            ),
            buttons: [
              { type: "secondary", title: Localise(messages, "Cancel") },
              {
                type: "primary",
                title: `${Localise(messages, "Optimize")} $`,
              },
            ],
          }}
          primaryhandler={() => {
            OptimizeAction({ forceOptimization: true });
            setForceOptimize({
              showforceOptimizeModal: false,
              delayedDeliveries: [],
            });
          }}
          secondaryhandler={() => {
            setForceOptimize({
              showforceOptimizeModal: false,
              delayedDeliveries: [],
            });
            getRouteAndOrdersData();
          }}
          contentStyle={[
            tw("border border-black p-2"),
            {
              backgroundColor: backgroundColors.secondary,
              textAlign: "center",
            },
          ]}
          modalStyle={
            Platform.OS !== "web"
              ? {
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  backgroundColor: "#00000070",
                  color: "#FFFFFF",
                }
              : { width: "35%" }
          }
        />
      )}
      {qrScanWarning.showWarningModal && (
        <CustomModal
          modalVisible={qrScanWarning.showWarningModal}
          modalContent={{
            content: (
              <Text
                style={{
                  ...tw("p-4"),
                  fontSize: 15,
                  color: colors.highlighter,
                }}
              >
                {Localise(messages, qrScanWarning.message)}
              </Text>
            ),
            buttons: [
              { type: "secondary", title: Localise(messages, "Nevermind") },
              { type: "primary", title: Localise(messages, "Continue") },
            ],
          }}
          primaryhandler={() => {
            setRouteMoveActionWrapper(qrScanWarning?.action);
            setQRScanWarning({
              showWarningModal: false,
              action: {},
            });
          }}
          secondaryhandler={() => {
            setQRScanWarning({
              showWarningModal: false,
              action: {},
            });
          }}
          contentStyle={[
            tw("border border-black p-2"),
            {
              backgroundColor: backgroundColors.secondary,
              textAlign: "center",
            },
          ]}
          modalStyle={
            Platform.OS !== "web"
              ? {
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  backgroundColor: "#00000070",
                  color: "#FFFFFF",
                }
              : { width: "35%" }
          }
        />
      )}
      {qrScanReprintWarning?.showWarningModal && (
        <CustomModal
          modalVisible={qrScanReprintWarning?.showWarningModal}
          modalContent={{
            content: (
              <Text
                style={{
                  ...tw("p-4"),
                  fontSize: 15,
                  color: colors.highlighter,
                }}
              >
                {Localise(messages, qrScanReprintWarning.message)}
              </Text>
            ),
            buttons: [
              { type: "secondary", title: Localise(messages, "Nevermind") },
              { type: "primary", title: Localise(messages, "Reprint") },
            ],
          }}
          primaryhandler={() => {
            qrScanReprintWarning?.orders.forEach((order, index) => {
              setTimeout(() => {
                rePrintOrder(order);
              }, [index * 5000]);
              dispatch(resetQRScanReprintWarning());
            });
          }}
          secondaryhandler={() => {
            dispatch(resetQRScanReprintWarning());
          }}
          contentStyle={[
            tw("border border-black p-2"),
            {
              backgroundColor: backgroundColors.secondary,
              textAlign: "center",
            },
          ]}
          modalStyle={
            Platform.OS !== "web"
              ? {
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  backgroundColor: "#00000070",
                  color: "#FFFFFF",
                }
              : { width: "35%" }
          }
        />
      )}
      {showOptimizeModal && (
        <CustomModal
          modalVisible={showOptimizeModal}
          modalContent={{
            content: (
              <Text
                style={{
                  ...tw("p-4"),
                  fontSize: 15,
                  color: colors.highlighter,
                }}
              >
                {Localise(
                  messages,
                  "The suggested start time for this optimized route is"
                )}{" "}
                {moment
                  .parseZone(expectedStartTime)
                  .format("MM/DD/YYYY hh:mm A")}
                {"."}
                {Localise(messages, "Would you like to proceed?")}
              </Text>
            ),
            buttons: [
              { type: "secondary", title: Localise(messages, "Nevermind") },
              { type: "primary", title: Localise(messages, "Continue") },
            ],
          }}
          primaryhandler={() => {
            OptimizeAction();
            setShowOptimizeModal(false);
          }}
          secondaryhandler={() => {
            dispatch(setFocusField("expectedStartTime"));
            optimiseRoute && setOptimiseRoute(false);
            setShowOptimizeModal(false);
          }}
          contentStyle={[
            tw("border border-black p-2"),
            {
              backgroundColor: backgroundColors.secondary,
              textAlign: "center",
            },
          ]}
          modalStyle={
            Platform.OS !== "web"
              ? {
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  backgroundColor: "#00000070",
                  color: "#FFFFFF",
                }
              : { width: "35%" }
          }
        />
      )}
      {showCFRConfirmModal && (
        <CustomModal
          modalVisible={showCFRConfirmModal}
          modalContent={{
            content: (
              <Text
                style={{
                  ...tw("p-4"),
                  fontSize: 15,
                  color: colors.highlighter,
                }}
              >
                {Localise(
                  messages,
                  `This route contains time-sensitive deliveries. Would you like to optimize this route for $${advOptimiseFee} to ensure on-time deliveries?`
                )}
              </Text>
            ),
            buttons: [
              {
                type: "primary",
                title: Localise(
                  messages,
                  `Optimize Time for $${advOptimiseFee}`
                ),
              },
              {
                type: "secondary",
                title: Localise(messages, `Distance only`),
              },
            ],
          }}
          primaryhandler={() => {
            OptimizeAction({ skipCFRConfirm: true, optmiseWithCFR: true });
            setShowCFRConfirmModal(false);
          }}
          secondaryhandler={() => {
            OptimizeAction({ skipCFRConfirm: true, optmiseWithCFR: false });
            setShowCFRConfirmModal(false);
          }}
          contentStyle={[
            tw("border border-black p-2"),
            {
              backgroundColor: backgroundColors.secondary,
              textAlign: "center",
            },
          ]}
          customButtonStyles={[
            Platform.OS !== "web" ? tw("flex-col items-center") : {},
          ]}
          modalStyle={
            Platform.OS !== "web"
              ? {
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  backgroundColor: "#00000070",
                  color: "#FFFFFF",
                }
              : { width: "35%" }
          }
        />
      )}
      <AddStopByOrderId
        {...{
          ordersList,
          setRouteMoveAction: setRouteMoveActionWrapper,
        }}
      />
    </>
  );

  const filtersList = ListOfFilters(startRange, endRange, routeDeliveryDate);
  const hasDesignCenter = hasDesignCenterFeature(permissions, "all");

  const filterOptions = {
    ...cloneDeep(
      isPremiumMember("all")
        ? filtersList
        : isMHQNonCoreMember(selectedMemberCode)
        ? hasDesignCenter
          ? filtersList
          : { ...omit(filtersList, ["Order Status"]) } // hiding Designed filter["Order Status"] if no shop is premium in group
        : hasDesignCenter
        ? { ...omit(filtersList, ["Categories"]) }
        : { ...omit(filtersList, ["Order Status", "Categories"]) }
    ),
    ...(get(autoRoutesFilters, "Zone Names", [])?.length
      ? { ...autoRoutesFilters }
      : {}),
  };
  const showCFROptimiseIcon =
    ordersList.ordersInRoute?.length > 25 ||
    hasPickupStopInRoute ||
    (hasTimedDeliveriesInRoute && ordersList.ordersInRoute?.length > 25);

  const ShowDirectionsButton = () => {
    return (
      <TouchableOpacity
        testID="showdirections"
        accessibilityLabel="showdirections"
        style={{
          ...theme.Button.secondaryButtonStyle,
          paddingVertical: 10,
          paddingHorizontal: 10,
          margin: 5,
          ...((isEmpty(ordersList.ordersInRoute) || disableSave) && {
            backgroundColor: "#d3d3d380",
          }),
        }}
        onPress={() => {
          if (!(isEmpty(ordersList.ordersInRoute) || disableSave)) {
            RouteDetailsFormRef.current.handleSubmit();
            setOrdersToHighlight([]);
          }
        }}
      >
        <Text
          style={{
            ...theme.Button.secondaryTitleStyle,
            ...((isEmpty(ordersList.ordersInRoute) || disableSave) && {
              color: "#808080",
            }),
          }}
        >
          {Localise(messages, "Show Directions")}
        </Text>
      </TouchableOpacity>
    );
  };

  const MapComponent = () => {
    return (
      <DirectionsMap
        page={"routeDetail"}
        key={mapKey}
        mapKey={mapKey}
        mapsContainerHeight={
          Platform.OS === "web" ? "calc(100vh - 280px)" : 500
        }
        mapsAction={OptimizeAction}
        loading={loading}
        markerAction={
          isEmpty(routeData) ||
          (!loading && !isPastRoute && isEditRoute && routeEditAllowed)
            ? setRouteMoveActionWrapper
            : () => {}
        }
        routes={
          isEmpty(savedRoutes) || eraseMap ? [] : [{ route: savedRoutes }]
        }
        routeData={routeData}
        autoRouteData={autoRouteData}
        autoRoutesData={
          isShowZonesInInteractiveMapVisible ? autoRoutesData : []
        }
        isPastRoute={isPastRoute}
        ordersInRoute={cloneDeep(filteredOrdersInRoute)}
        nonRoutedOrders={
          localPermissions.showNonRoutedOrders
            ? cloneDeep(filteredNonRoutedOrders)
            : []
        }
        showDirections={true}
        isEditRoute={isEditRoute}
        shopLocation={{
          ...(startLocationCode
            ? { latitude: startLatitude, longitude: startLongitude }
            : !!latitude && { latitude, longitude }),
        }}
        shopLocations={[
          ...(localPermissions.multiShopRoute
            ? (multiSelectedShopCodes?.length > 0 &&
                multiSelectedShopCodes.map((shopCode) => {
                  const { latitude, longitude } = shopLocations[shopCode] || {};
                  return { latitude, longitude };
                })) ||
              []
            : !!latitude && !!longitude
            ? [{ latitude, longitude }]
            : []),
        ]}
        multiSelectedShopCodes={multiSelectedShopCodes}
        selectedShopList={
          localPermissions.multiShopRoute
            ? multiSelectedShopCodes?.length > 1
              ? [
                  {
                    label: Localise(messages, "All"),
                    value: [...multiSelectedShopCodes],
                  },
                  ...multiSelectedShopCodes.map((each) => {
                    const shopName = shopNames[each] || "";
                    return {
                      label: `${each + " " + shopName}`,
                      value: each,
                      shopName: shopName,
                    };
                  }),
                ]
              : [multiSelectedShopCodes[0]]
            : [shopCode]
        }
        shopNames={shopNames}
        zoomLevel={8}
        localPermissions={localPermissions}
        setScrollEnabled={setScrollEnabled}
        isSmallScreen={isSmallScreen}
        hasLimitedRouteAccess={hasLimitedRouteAccess}
        CFROptimise={showCFROptimiseIcon}
        routeHasEdits={isRouteEditing || isAdhocStopEditing}
        ShowDirectionsButton={() => {
          return <>{ShowDirectionsButton()}</>;
        }}
      />
    );
  };

  const OrdersInRouteRender = (order, index, dragOverlay = false) => (
    <React.Fragment key={index}>
      {["CUSTOM"].includes(order?.stopType) &&
      recordId === order?.orderItemId ? (
        <RouteStopInfo
          dragOverlay={dragOverlay}
          selectedStopId={recordId}
          loading={loading}
          setLoading={setLoading}
          routeData={routeData}
          routeDeliveryDate={routeDeliveryDate}
          ordersList={ordersList}
          setOrdersList={setOrdersList}
          setRouteMoveAction={setRouteMoveAction}
          setIsAddStopFormOpen={setIsAddStopFormOpen}
          setStopId={setRecordId}
          disableForm={!routeEditAllowed}
          isEditStop={true}
          innerRef={RouteStopFormRef}
          setEventAfterSaveRoute={setEventAfterSaveRoute}
          isRouteEditing={isRouteEditing}
          setIsAdhocStopEditing={setIsAdhocStopEditing}
          optimiseRoute={optimiseRoute}
          setOptimiseRoute={setOptimiseRoute}
          setIsRouteSave={setIsRouteSave}
          unsavedMoveActionOrdersList={unsavedMoveActionOrdersList}
          setUnsavedMoveActionOrdersList={setUnsavedMoveActionOrdersList}
        />
      ) : (
        <ListItem
          enableDND={enableDND}
          dragOverlay={dragOverlay}
          index={order?.orderIndex >= 0 ? order?.orderIndex : index}
          item={cloneDeep(order)}
          itemKey={"orderItemId"}
          listType={"routed"}
          page={"routeDetails"}
          routeData={routeData}
          isSmallScreen={isSmallScreen}
          isMobile={isMobile}
          setRecordId={setRecordId}
          columnsList={isMobile ? ListOfMobileColumns : ListOfColumns}
          subColumnsList={ListOfSubColumns}
          setRouteMoveAction={setRouteMoveActionWrapper}
          totalOrdersInRoute={ordersList.ordersInRoute.length}
          enableMoves={true}
          enableMoveStop={routeEditAllowed && localPermissions.enableStopsMoves}
          enableRemoveStop={routeEditAllowed && localPermissions.removeStop}
          localPermissions={localPermissions}
          styles={styles}
          ordersToHighlight={ordersToHighlight}
          loading={loading}
          isClickable={false}
        />
      )}
    </React.Fragment>
  );

  const ViewMore = ({ label }) => {
    return (
      <Button
        titleStyle={theme.Button.secondaryTitleStyle}
        buttonStyle={{
          ...theme.Button.secondaryButtonStyle,
          paddingHorizontal: 10,
          paddingVertical: 6,
          width: 95,
          height: 35,
        }}
        containerStyle={{ alignItems: "flex-end", marginHorizontal: 0 }}
        title={Localise(messages, "View More")}
        onPress={() => {
          setViewMoreCounts({
            ...viewMoreCounts,
            [label]:
              viewMoreCounts[label] +
              (label === "nonRoutedOrders"
                ? ordersNotInRouteVMLimit
                : ordersInRouteVMLimit),
          });
        }}
        testID={`${label}_view_more`}
        accessibilityLabel={`${label}_view_more`}
      />
    );
  };

  const addStopAction = () => {
    setRecordId(""); //Close the existing adhoc stop form
    setIsAddStopFormOpen(true);
  };

  return (
    <SplitScreen
      showTwoScreens={isDesktop}
      subHeaderExists={isSmallScreen}
      proxyHeaderExists={proxyUser ? true : false}
      scrollEnabled={scrollEnabled}
      autoScreenScroll={["addStopScroll", "mapScroll"].includes(autoScroll)}
      ScrollYCoordinate={
        autoScroll === "mapScroll" ? mapYCoordinate : addStopYCoordinate
      }
      screenToScroll={1}
      maxWidthForPrimary={windowWidth * 0.6}
      defaultPrimaryWidth={isTablet || isMobile ? "0%" : "55vw"}
    >
      <View fsClass="fs-unmask">
        <SubHeader showTwoScreens={true} />
        <View>
          {modal}
          <PrintIframeRenderer />
          <View style={[tw("flex flex-row justify-between")]}>
            <View style={tw("flex-row")}>
              <Text
                style={[
                  fonts.heading1,
                  tw("pt-5 pb-3"),
                  { ...(isTablet && { fontSize: 16, fontWeight: "400" }) },
                ]}
              >
                {Localise(
                  messages,
                  selectedRouteId.length ? "Route Details" : "Create Route"
                )}
              </Text>
              {loading && (
                <ActivityIndicator
                  style={{ marginLeft: 10, paddingTop: 10 }}
                  color={colors.activityIndicator}
                  testID={"loader"}
                />
              )}
            </View>
            {showQRScan && !loading ? (
              <View style={[tw("pt-5 pb-3")]}>
                <Tooltip
                  text={
                    Platform.OS === "web" &&
                    qrScanVariant === "order-route-scan"
                      ? Localise(
                          messages,
                          "Scanner mode enabled. Please do the scan now."
                        )
                      : ""
                  }
                >
                  <QRScanIcon
                    currentPage={currentPage}
                    imageName={
                      qrScanVariant === "order-route-scan" &&
                      Platform.OS === "web"
                        ? "active_scanner"
                        : "qr_code_scan"
                    }
                  />
                </Tooltip>
              </View>
            ) : null}
          </View>

          {!isMobile && (
            <View
              style={{
                borderBottomColor: colors.light,
                borderBottomWidth: 1,
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.4,
                shadowRadius: 7,
                marginBottom: 20,
              }}
            />
          )}
        </View>

        <View>
          {/* QR Scan */}
          <RouteQRScan
            {...{
              ordersList,
              setScannedOrder,
              disableSave,
              showCFROptimiseIcon,
              isPastRoute,
              eraseMap,
              routeData,
              loading,
              localPermissions,
              OptimizeAction,
              redirectTo,
              saveAndOptimize,
            }}
          />

          {/* Route Details - start */}
          <View
            style={[
              qrScanVariant === "order-route-scan" && Platform.OS !== "web"
                ? tw("p-0 w-0 h-0 opacity-0")
                : tw("w-full"),
            ]}
          >
            <View
              style={{
                position: Platform.OS === "web" ? "sticky" : "relative",
                top: 0,
                zIndex: 99,
                backgroundColor: colors.secondary,
              }}
            >
              <RouteBreadcrumb routeData={routeData} />
              <TakeAction
                loading={loading}
                routeData={routeData}
                setRouteData={setRouteData}
                actionHandler={actionHandler}
                addStopAction={addStopAction}
                isAddStopFormOpen={isAddStopFormOpen}
                setIsAddStopFormOpen={setIsAddStopFormOpen}
                {...{ setAutoScroll, addStopViewRef, setAddStopYCoordinate }}
                isPastRoute={isPastRoute}
                isEditRoute={isEditRoute}
                disableSave={isEmpty(ordersList.ordersInRoute) || disableSave}
                driverAssignment={driverAssignment}
                localPermissions={localPermissions}
                selectedAction={selectedAction}
                setSelectedAction={setSelectedAction}
                routeHasEdits={isRouteEditing || isAdhocStopEditing}
                routes={
                  isEmpty(savedRoutes) || eraseMap
                    ? []
                    : [{ route: savedRoutes }]
                }
                CFROptimise={showCFROptimiseIcon}
                routeEditAllowed={routeEditAllowed}
              />
            </View>

            {/* Route Details */}
            <View
              style={{
                borderWidth: 1,
                borderColor: colors.grayScaleLight,
                zIndex: 20,
              }}
            >
              <Accordion
                title={Localise(messages, "Route Details")}
                defaultOpen={true}
                handleOnPress={() => {}}
                labelStyle={{
                  marginTop: 0,
                  height: 50,
                  backgroundColor: backgroundColors.navBar,
                  borderBottomWidth: 1,
                  borderColor: colors.grayScaleLight,
                  paddingHorizontal: 12,
                }}
                titleStyle={{
                  color: colors.primary,
                  paddingLeft: 10,
                }}
                iconStyle={{
                  color: colors.primary,
                }}
                imageProps={{
                  width: 25,
                  height: 25,
                  source: "route-pin",
                }}
                testID="routeDetails"
              >
                <View style={tw("p-4")}>
                  <RouteDetailForm
                    shopCode={shopCode}
                    setShopCode={setShopCode}
                    routeName={routeName}
                    driver={driver}
                    setDriver={setDriver}
                    routeDeliveryDate={routeDeliveryDate}
                    setRouteDeliveryDate={setRouteDeliveryDate}
                    filteredShopCodes={filteredShopCodes}
                    multiSelectedShopCodes={multiSelectedShopCodes}
                    setMultiSelectedShopCodes={setMultiSelectedShopCodes}
                    driversData={driversData}
                    startLocationCode={startLocationCode}
                    setStartLocationCode={setStartLocationCode}
                    endLocationCode={endLocationCode}
                    setEndLocationCode={setEndLocationCode}
                    RouteDetailsFormRef={RouteDetailsFormRef}
                    triggerSaveRoute={triggerSaveRoute}
                    localPermissions={localPermissions}
                    routeData={routeData}
                    isPastRoute={isPastRoute}
                    autoRouteData={autoRouteData}
                    ordersList={ordersList}
                    setOrdersList={setOrdersList}
                    shopLocations={shopLocations}
                    isEditRoute={isEditRoute}
                    disableSave={disableSave}
                    setDisableSave={setDisableSave}
                    memberQuery={memberQuery}
                    driverQuery={driverQuery}
                    setDriverQuery={setDriverQuery}
                    setMemberQuery={setMemberQuery}
                    locationFieldQuery={locationFieldQuery}
                    setLocationFieldQuery={setLocationFieldQuery}
                    setShowShopRemovedModal={setShowShopRemovedModal}
                    setShopRemoved={setShopRemoved}
                    loading={loading}
                    hasLimitedRouteAccess={hasLimitedRouteAccess}
                    setEraseMap={setEraseMap}
                    getDriversAndSaveRoute={getDriversAndSaveRoute}
                    buildPickupStop={buildPickupStop}
                    expectedStartTime={expectedStartTime}
                    setExpectedStartTime={setExpectedStartTime}
                    focusFieldName={focusFieldName}
                    setIsRouteEditing={setIsRouteEditing}
                    routeHasEdits={isRouteEditing || isAdhocStopEditing}
                    hasPickupStopWithSameAddress={hasPickupStopWithSameAddress}
                  />
                </View>
              </Accordion>
            </View>

            {showFilters ? (
              <Filters
                filterOptions={filterOptions}
                multiSelectedShopCodes={multiSelectedShopCodes}
                setActions={setActions}
              />
            ) : null}

            {/* Orders in Route */}
            <View
              style={{
                borderWidth: 1,
                borderColor: colors.grayScaleLight,
                marginTop: 10,
                marginBottom: 20,
              }}
            >
              <Accordion
                title={`${Localise(messages, "Orders in Route")} (${
                  hasFilters
                    ? `${filteredOrdersList?.ordersInRoute?.length || 0}/${
                        ordersList.ordersInRoute?.length || 0
                      }`
                    : ordersList.ordersInRoute?.length || 0
                })`}
                defaultOpen={true}
                handleOnPress={() => {}}
                labelStyle={{
                  marginTop: 0,
                  height: 50,
                  backgroundColor: backgroundColors.navBar,
                  borderBottomWidth: 1,
                  borderColor: colors.grayScaleLight,
                  paddingHorizontal: 12,
                }}
                titleStyle={{
                  color: colors.primary,
                  paddingLeft: 10,
                }}
                testID="ordersInRoute"
              >
                <View
                  style={{
                    ...tw("p-4"),
                    ...(isPastRoute && {
                      pointerEvents: "none",
                      opacity: 0.5,
                    }),
                  }}
                >
                  {enableDND && !isSmallScreen ? (
                    <DraggableFlatList
                      data={
                        filteredOrdersInRoute?.length > 0
                          ? filteredOrdersInRoute.slice(
                              0,
                              viewMoreCounts["ordersInRoute"]
                            )
                          : []
                      }
                      onDragEnd={(updatedOrdersInRoute) => {
                        setOrdersList({
                          ...ordersList,
                          ordersInRoute: [...updatedOrdersInRoute],
                        });
                        setDisableSave(false);
                        setIsRouteEditing(true);
                        setEraseMap(true);
                      }}
                      renderItem={OrdersInRouteRender}
                    />
                  ) : (
                    <>
                      {filteredOrdersInRoute?.length > 0 &&
                        filteredOrdersInRoute
                          .slice(0, viewMoreCounts["ordersInRoute"])
                          .map((order, index) => {
                            return (
                              <React.Fragment key={index}>
                                {OrdersInRouteRender(order, index)}
                              </React.Fragment>
                            );
                          })}
                    </>
                  )}
                  <View ref={addStopViewRef}>
                    {isAddStopFormOpen && (
                      <RouteStopInfo
                        setRouteMoveAction={setRouteMoveAction}
                        setIsAddStopFormOpen={setIsAddStopFormOpen}
                        loading={loading}
                        setLoading={setLoading}
                        ordersList={ordersList}
                        setOrdersList={setOrdersList}
                        routeData={routeData}
                        routeDeliveryDate={routeDeliveryDate}
                        innerRef={RouteStopFormRef}
                        setEventAfterSaveRoute={setEventAfterSaveRoute}
                        isRouteEditing={isRouteEditing}
                        setIsAdhocStopEditing={setIsAdhocStopEditing}
                        optimiseRoute={optimiseRoute}
                        setOptimiseRoute={setOptimiseRoute}
                        setIsRouteSave={setIsRouteSave}
                        unsavedMoveActionOrdersList={
                          unsavedMoveActionOrdersList
                        }
                        setUnsavedMoveActionOrdersList={
                          setUnsavedMoveActionOrdersList
                        }
                        multiSelectedShopCodes={multiSelectedShopCodes}
                        shopLocations={shopLocations}
                      />
                    )}
                  </View>

                  <View
                    style={
                      localPermissions.adhocStops &&
                      multiSelectedShopCodes.length > 0 &&
                      routeEditAllowed &&
                      !isAddStopFormOpen
                        ? [tw("flex flex-row justify-between")]
                        : [tw("flex flex-row justify-end")]
                    }
                  >
                    {localPermissions.adhocStops &&
                      multiSelectedShopCodes.length > 0 &&
                      routeEditAllowed &&
                      !isAddStopFormOpen && (
                        <TouchableOpacity
                          testID="addStop"
                          accessibilityLabel="addStop"
                          onPress={addStopAction}
                        >
                          <Button
                            title={Localise(messages, "Add Stop")}
                            titleStyle={theme.Button.secondaryTitleStyle}
                            buttonStyle={{
                              ...theme.Button.secondaryButtonStyle,
                              paddingBottom: 9,
                              paddingTop: isMobile ? 7 : 9,
                              paddingHorizontal: 10,
                              margin: 5,
                              height: 40,
                            }}
                            containerStyle={{
                              margin: 0,
                              marginBottom: 10,
                              justifyContent: "center",
                            }}
                          />
                        </TouchableOpacity>
                      )}
                    {filteredOrdersInRoute?.length >
                      viewMoreCounts["ordersInRoute"] && (
                      <ViewMore label={"ordersInRoute"} />
                    )}
                  </View>
                </View>
              </Accordion>
            </View>

            {/* Non-routed orders */}
            {localPermissions.showNonRoutedOrders ? (
              <View
                style={{
                  borderWidth: 1,
                  borderColor: colors.grayScaleLight,
                  marginBottom: 20,
                }}
              >
                <Accordion
                  title={`${Localise(messages, "Orders not in Route")} (${
                    filteredNonRoutedOrders?.length || 0
                  })`}
                  defaultOpen={true}
                  handleOnPress={() => {}}
                  labelStyle={{
                    marginTop: 0,
                    height: 50,
                    backgroundColor: backgroundColors.navBar,
                    borderBottomWidth: 1,
                    borderColor: colors.grayScaleLight,
                    paddingHorizontal: 12,
                  }}
                  titleStyle={{
                    color: colors.primary,
                    paddingLeft: 10,
                  }}
                  testID="nonRoutedOrders"
                >
                  <View
                    style={{
                      ...tw("p-4"),
                      ...(isPastRoute && {
                        pointerEvents: "none",
                        opacity: 0.5,
                      }),
                    }}
                  >
                    {routeEditAllowed ? (
                      <View
                        style={[
                          tw("flex flex-row items-center justify-between mb-4"),
                        ]}
                      >
                        {/* Select All Non-routed orders CheckBox */}
                        {filteredNonRoutedOrders?.length > 0 &&
                        routeEditAllowed ? (
                          <TouchableOpacity
                            style={[
                              tw("flex flex-row items-center"),
                              { height: 35 },
                            ]}
                            onPress={() => {
                              setSelectAllChecked(!selectAllChecked);
                              setCheckedNonRouted(
                                cloneDeep(
                                  selectAllChecked
                                    ? []
                                    : filteredNonRoutedOrders.map(
                                        (each) => each.messageNumber
                                      )
                                )
                              );
                            }}
                          >
                            <CheckBox
                              containerStyle={[
                                theme.CheckBox.inputContainerStyle,
                                { paddingRight: 3 },
                              ]}
                              size={18}
                              checked={selectAllChecked}
                              checkedIcon={
                                <Image
                                  source={IMAGES["checkbox-checked"]}
                                  style={{ width: 20, height: 20 }}
                                />
                              }
                              uncheckedIcon={
                                <Image
                                  source={IMAGES["checkbox-unchecked-dark"]}
                                  style={{ width: 20, height: 20 }}
                                />
                              }
                              disabled={false}
                              testID={"select_all_checkbox"}
                              accessibilityLabel={"select_all_checkbox"}
                            />
                            <Text style={{ paddingTop: 5 }}>
                              {Localise(messages, "Select All")}
                            </Text>
                          </TouchableOpacity>
                        ) : null}

                        {/* Add Selected - Add all selected/checked NonRoutes orders to route */}
                        {checkedNonRouted?.length > 0 ? (
                          <View style={[tw("flex flex-row justify-end")]}>
                            <TouchableOpacity
                              testID="addSelected"
                              accessibilityLabel="addSelected"
                              style={{
                                ...theme.Button.secondaryButtonStyle,
                                paddingVertical: 10,
                                paddingHorizontal: 10,
                                margin: 0,
                              }}
                              onPress={() => {
                                if (checkedNonRouted?.length > 0) {
                                  const { matchedList, unmatchedList } =
                                    splitArray(
                                      ordersList.nonRoutedOrders,
                                      (order) =>
                                        checkedNonRouted.includes(
                                          order?.messageNumber
                                        )
                                    );

                                  setRouteMoveAction({
                                    action: "addChecked",
                                    ordersList: {
                                      ordersInRoute: [
                                        ...ordersList.ordersInRoute,
                                        ...matchedList,
                                      ],
                                      nonRoutedOrders: unmatchedList,
                                    },
                                    stopAdditionMethod: "MANUAL",
                                  });
                                  setCheckedNonRouted([]);
                                  selectAllChecked &&
                                    setSelectAllChecked(!selectAllChecked);
                                }
                              }}
                            >
                              <Text
                                style={{
                                  ...theme.Button.secondaryTitleStyle,
                                }}
                              >
                                {Localise(messages, "Add Selected")} (
                                {checkedNonRouted?.length})
                              </Text>
                            </TouchableOpacity>
                          </View>
                        ) : null}
                      </View>
                    ) : null}

                    {filteredNonRoutedOrders?.length
                      ? filteredNonRoutedOrders
                          .slice(0, viewMoreCounts["nonRoutedOrders"])
                          .map((order, index) => (
                            <View
                              key={index}
                              style={[tw("flex flex-row items-center")]}
                            >
                              {routeEditAllowed ? (
                                <TouchableOpacity
                                  style={[
                                    tw("flex flex-row items-center"),
                                    {
                                      paddingBottom: 10,
                                      ...(!isSmallScreen
                                        ? { width: "3.5%" }
                                        : {}),
                                    },
                                  ]}
                                  onPress={() => {
                                    const checkedndex =
                                      checkedNonRouted.indexOf(
                                        order?.messageNumber
                                      );

                                    if (checkedndex > -1) {
                                      let selectedNonRoutedOrders =
                                        cloneDeep(checkedNonRouted);
                                      selectedNonRoutedOrders.splice(
                                        checkedndex,
                                        1
                                      );
                                      setCheckedNonRouted(
                                        selectedNonRoutedOrders
                                      );
                                      selectAllChecked &&
                                        setSelectAllChecked(!selectAllChecked);
                                    } else {
                                      let selectedNonRoutedOrders =
                                        cloneDeep(checkedNonRouted);
                                      selectedNonRoutedOrders.push(
                                        order?.messageNumber
                                      );
                                      setCheckedNonRouted(
                                        selectedNonRoutedOrders
                                      );
                                    }
                                  }}
                                >
                                  <CheckBox
                                    containerStyle={[
                                      theme.CheckBox.inputContainerStyle,
                                      { paddingRight: 3 },
                                    ]}
                                    size={18}
                                    checked={checkedNonRouted?.includes(
                                      order?.messageNumber
                                    )}
                                    checkedIcon={
                                      <Image
                                        source={IMAGES["checkbox-checked"]}
                                        style={{ width: 20, height: 20 }}
                                      />
                                    }
                                    uncheckedIcon={
                                      <Image
                                        source={
                                          IMAGES["checkbox-unchecked-dark"]
                                        }
                                        style={{ width: 20, height: 20 }}
                                      />
                                    }
                                    disabled={false}
                                    testID={`checkbox_${order?.messageNumber}`}
                                    accessibilityLabel={`checkbox_${order?.messageNumber}`}
                                  />
                                </TouchableOpacity>
                              ) : null}
                              <View
                                style={{
                                  width: routeEditAllowed ? "96%" : "100%",
                                }}
                              >
                                <ListItem
                                  index={
                                    order?.orderIndex >= 0
                                      ? order?.orderIndex
                                      : index
                                  }
                                  item={cloneDeep(order)}
                                  itemKey={"orderItemId"}
                                  listType={"nonRouted"}
                                  page={"routeDetails"}
                                  routeData={routeData}
                                  setRecordId={setRecordId}
                                  isSmallScreen={isSmallScreen}
                                  isMobile={isMobile}
                                  columnsList={
                                    isMobile
                                      ? ListOfMobileColumns
                                      : ListOfColumns
                                  }
                                  subColumnsList={ListOfSubColumns}
                                  setRouteMoveAction={setRouteMoveAction}
                                  totalOrdersInRoute={
                                    ordersList.ordersInRoute.length
                                  }
                                  enableMoves={routeEditAllowed}
                                  enableAddOrder={routeEditAllowed}
                                  localPermissions={localPermissions}
                                  styles={styles}
                                  isClickable={false}
                                  routeEditAllowed={routeEditAllowed}
                                />
                              </View>
                            </View>
                          ))
                      : null}

                    {filteredNonRoutedOrders?.length >
                      viewMoreCounts["nonRoutedOrders"] && (
                      <ViewMore label={"nonRoutedOrders"} />
                    )}
                  </View>
                </Accordion>
              </View>
            ) : null}

            {/* MapComponent */}
            {isSmallScreen &&
            !!mapKey &&
            qrScanVariant !== "order-route-scan" ? (
              <View
                style={{ marginVertical: 20, zIndex: 12 }}
                onLayout={(event) => {
                  setMapYCoordinate(event.nativeEvent.layout.y - 5);
                }}
              >
                {MapComponent()}
              </View>
            ) : null}
          </View>
        </View>
      </View>
      <View>{isDesktop && !!mapKey && <> {MapComponent()}</>}</View>
    </SplitScreen>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    global: state.mhq.ongoing.global,
    appliedFilters:
      state.mhq.ongoing.currentDelivery.actions.appliedFilters || [],
    selectedShops:
      state.mhq.ongoing.currentDelivery.actions.selectedShops || [],
    search: state.mhq.ongoing.currentDelivery.actions.search || {},
    currentOrdersInfo: state.mhq.ongoing.currentOrders,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRecordId,
      setIsRouteSave,
      setIsRouteEditing,
      setIsAdhocStopEditing,
      setIsRouteSaveSuccess,
      setCurrentPage,
      setOrdersToHighlight,
      setCompleteRouteModal,
      setRemoveStopAction,
      setActions,
      resetBasicInfo,
      setIsRouteOptimised,
      setOptimiseRoute,
      setIsRouteOptimiseDone,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UpsertRouteScreen);
