import Environment from "library/utils/environment";
import { Linking, Alert, BackHandler } from "react-native";
import AppSettingsStorage from "library/storage/appSettings";

export default function AppUpdateAlert() {
  const updateType = Environment.get("update_type");

  const openAppInStore = () => {
    AppSettingsStorage.setAppUpdateAlertDialogShown(false);

    const storeURL = Environment.get("store_url");

    Linking.canOpenURL(storeURL)
      .then(() => {
        //BackHandler.exitApp();

        Linking.openURL(storeURL);
      })
      .catch(() => console.log("Unable to open app in store."));
  };

  if (!AppSettingsStorage.getAppUpdateAlertDialogShown()) {
    if (updateType === "force") {
      Alert.alert(
        "App Update Available",
        "Please update the app to continue",
        [
          {
            text: "Update Now",
            onPress: () => {
              openAppInStore();
            },
          },
        ],
        {
          cancelable: false,
          onDismiss: () => {
            AppSettingsStorage.setAppUpdateAlertDialogShown(false);

            console.log(
              "This alert was dismissed by tapping outside of the alert dialog."
            );
          },
        }
      );
    } else if (updateType === "normal") {
      Alert.alert(
        "App Update Available",
        "Please update the app to continue",
        [
          {
            text: "Update Later",
            onPress: () => {
              AppSettingsStorage.setAppUpdateAlertDialogShown(false);
            },
            style: "cancel",
          },
          {
            text: "Update Now",
            onPress: () => {
              openAppInStore();
            },
          },
        ],
        {
          cancelable: true,
          onDismiss: () => {
            AppSettingsStorage.setAppUpdateAlertDialogShown(false);

            console.log(
              "This alert was dismissed by tapping outside of the alert dialog."
            );
          },
        }
      );
    }

    AppSettingsStorage.setAppUpdateAlertDialogShown(true);
  }

  return null;
}
