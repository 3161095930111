import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { CheckBox } from "react-native-elements";
import { DeviceContext } from "library/contexts/appSettings";

import { theme, fonts } from "styles/theme";
import tw from "tailwind-rn";

const SelectAll = ({ count = -1, value, onChange }) => {
  const { isMobile } = React.useContext(DeviceContext);

  return (
    <View style={tw("flex flex-row items-center")}>
      <CheckBox
        Component={TouchableOpacity}
        containerStyle={[
          theme.CheckBox.inputContainerStyle,
          { paddingRight: isMobile ? 0 : 3, marginRight: !isMobile ? 3 : 0 },
        ]}
        size={18}
        checked={value}
        onPress={() => {
          onChange(!value);
        }}
      />
      <Text
        style={{
          ...fonts.default,
          height: 14,
          marginRight: !isMobile ? 10 : 0,
        }}
      >{`Select All ${value && count > 0 ? `(${count})` : ""}`}</Text>
    </View>
  );
};

export default SelectAll;
