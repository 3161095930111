import React from "react";
import { View, ActivityIndicator } from "react-native";
import { Text, Button } from "react-native-elements";
import { fonts, backgroundColors, colors, theme } from "styles/theme";
import tw from "tailwind-rn";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { CustomModal } from "components/elements";
import { invalidateAllUserSessions } from "./helper";
import { Messages } from "./config";

const InvalidateSessions = () => {
  const [modalVisible, setModalVisibile] = useStateIfMounted(false);
  const [showLoading, setShowLoading] = useStateIfMounted("");

  // eslint-disable-next-line no-unused-vars
  const [errorMsg, setErrorMsg] = useStateIfMounted("");
  // eslint-disable-next-line no-unused-vars
  const [successMsg, setSuccessMsg] = useStateIfMounted("");

  const modalContent = {
    content: (
      <Text style={[tw("p-4"), fonts.heading3]}>
        {Messages.confirmationMessage}
      </Text>
    ),
    buttons: [
      { type: "secondary", title: "Cancel" },
      { type: "primary", title: "Invalidate" },
    ],
  };

  const hideModal = () => {
    setModalVisibile(false);
  };

  const invalidateSessions = () => {
    invalidateAllUserSessions(hideModal, setShowLoading);
  };

  return (
    <>
      {modalVisible && (
        <CustomModal
          modalVisible={modalVisible}
          modalContent={modalContent}
          primaryhandler={invalidateSessions}
          secondaryhandler={hideModal}
          contentStyle={tw("border border-black")}
        />
      )}
      <View style={[tw("mt-5"), { zIndex: -2 }]}>
        <View
          style={{
            borderColor: colors.light,
            borderWidth: 1,
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.1,
            shadowRadius: 5,
            borderRadius: 5,
          }}
        >
          <View
            style={[
              {
                backgroundColor: backgroundColors.navBar,
              },
              tw("flex justify-center px-4 py-4"),
            ]}
          >
            <Text style={[fonts.heading3, tw("mb-2")]}>
              Invalidate User Sessions
            </Text>
          </View>
          <View style={tw("px-4 pb-5")}>
            <View style={tw("flex flex-row items-center")}>
              <Button
                containerStyle={{
                  margin: 2,
                }}
                onPress={() => {
                  setModalVisibile(true);
                }}
                title={"Submit"}
                disabled={false}
              />

              <Text style={[theme.SubmitButton.errorStyle, tw("ml-2 mt-2")]}>
                {errorMsg}
              </Text>

              <Text
                style={[fonts.success, tw("ml-2 mt-2"), { fontWeight: "bold" }]}
              >
                {successMsg}
              </Text>

              {showLoading === "load" && (
                <ActivityIndicator color={colors.activityIndicator} />
              )}
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

export default InvalidateSessions;
