import React from "react";
import { View, Text, Platform } from "react-native";

import {
  FormFieldDatePicker,
  FormFieldSwitch,
} from "components/elements/forms";

import I18NContext from "library/contexts/i18N";

import { useFormikContext } from "formik";
import { fonts } from "styles/theme";
import tw from "tailwind-rn";

const ShopHoursInput = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { Localise, messages } = React.useContext(I18NContext);

  return [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ].map((dayOfWeek, index) => {
    const isShopOpen = values && values[dayOfWeek]?.openedFlag === "T";
    return (
      <View
        style={[
          tw(
            `flex flex-row items-${
              Platform.OS !== "web" ? "center" : "baseline"
            } justify-between w-full pb-4`
          ),
          { zIndex: 20 - index, maxWidth: 420 },
        ]}
        key={dayOfWeek}
      >
        <Text
          style={{
            width: 70,
            height: 25,
            textTransform: "capitalize",
            ...fonts.heading5,
          }}
        >
          {Localise(messages, dayOfWeek)}
        </Text>
        <View style={tw("flex-row flex-wrap flex-1")}>
          <FormFieldDatePicker
            dateFormat="h:mm a"
            name={`${dayOfWeek}.open`}
            placeholder={Localise(messages, "Start Time")}
            iconName="clock"
            showTimeSelect={true}
            showTimeSelectOnly={true}
            timeIntervals={30}
            timeCaption=""
            alignIconRight={true}
            disabled={!isShopOpen}
            errorStyle={{ paddingVertical: 0 }}
            containerStyle={{ width: 120 }}
            dateValueFormat={"YYYY-MM-DDTHH:mm:ss"}
            onValueChange={() => setFieldTouched(`${dayOfWeek}.close`)}
            editable={Platform.OS !== "web"}
          />
          <FormFieldDatePicker
            dateFormat="h:mm a"
            name={`${dayOfWeek}.close`}
            placeholder={Localise(messages, "End Time")}
            iconName="clock"
            showTimeSelect={true}
            showTimeSelectOnly={true}
            timeIntervals={30}
            timeCaption=""
            alignIconRight={true}
            disabled={!isShopOpen}
            errorStyle={{ paddingVertical: 0 }}
            containerStyle={{ width: 120 }}
            dateValueFormat={"YYYY-MM-DDTHH:mm:ss"}
            editable={Platform.OS !== "web"}
          />
        </View>
        <View style={tw("flex flex-row items-center pl-1")}>
          <FormFieldSwitch
            value={isShopOpen}
            onValueChange={() =>
              setFieldValue(`${dayOfWeek}.openedFlag`, isShopOpen ? "F" : "T")
            }
          />
          <Text style={{ ...fonts.default, paddingLeft: 4, width: 50 }}>
            {isShopOpen
              ? Localise(messages, "Opened")
              : Localise(messages, "Closed")}
          </Text>
        </View>
      </View>
    );
  });
};

export default ShopHoursInput;
