import React, { useContext } from "react";
import { Image, TouchableOpacity } from "react-native";
import { useDispatch } from "react-redux";
import { useNavigation } from "@react-navigation/native";

import { Tooltip } from "components/elements";
import I18NContext from "library/contexts/i18N";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";

import IMAGES from "static/assets/images";

const ImageLink = ({
  imageName,
  customImageStyle = {},
  tooltipMsg = "",
  navigationRoute = {},
  onPress = () => {},
  testID = "imageLink",
  accessibilityLabel,
}) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { Localise, messages } = useContext(I18NContext);

  const { page, params = {} } = navigationRoute;

  return (
    <Tooltip text={`${Localise(messages, tooltipMsg)}`}>
      <TouchableOpacity
        style={{ paddingHorizontal: 5 }}
        onPress={() => {
          onPress();
          if (page) {
            dispatch(setCurrentPage(page));
            navigation.navigate(page, { ...params });
          }
        }}
        testID={testID}
        accessibilityLabel={accessibilityLabel || testID}
      >
        <Image
          style={{ width: 25, height: 25, ...customImageStyle }}
          resizeMode="contain"
          source={IMAGES[imageName]}
        />
      </TouchableOpacity>
    </Tooltip>
  );
};

export default ImageLink;
