import { createSlice } from "@reduxjs/toolkit";

import set from "lodash/set";

import { SLICE_NAME as sliceName } from "./constants";
import { SLICE_NAME as parentSlice } from "../constants";

export const initialState = {
  deliveryCutoffTimes: {
    cutoffExceptions: {},
  },
};

const slice = createSlice({
  name: `view::${parentSlice}::${sliceName}`,
  initialState,
  reducers: {
    setApiResponse: (state, action) => {
      const { path, content } = action.payload;
      set(state, `${path}.content`, content);
    },
    setApiError: (state, action) => {
      const { path, error } = action.payload;
      set(state, `${path}.error`, error);
    },

    fetchDeliveryCutoffTimes: () => {},
    saveDeliveryCutoffTimes: (state) => {
      state.deliveryCutoffTimes.error = "";
    },
    fetchCutoffExceptions: () => {},
    saveCutoffExceptions: (state) => {
      state.deliveryCutoffTimes.cutoffExceptions.error = "";
    },
    deleteCutoffExceptions: () => {},
  },
});

export const {
  setApiResponse,
  setApiError,
  fetchDeliveryCutoffTimes,
  saveDeliveryCutoffTimes,
  fetchCutoffExceptions,
  saveCutoffExceptions,
  deleteCutoffExceptions,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
