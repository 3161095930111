import React from "react";
import { View, ImageBackground } from "react-native";
import { Button } from "react-native-elements";
import { TermsandConditionsContent } from "../login/terms-conditions";
import IMAGES from "static/assets/images";
import { backgroundColors } from "styles/theme";
import tw from "tailwind-rn";
import { DeviceContext } from "library/contexts/appSettings";

const TermsAndConditionsPage = () => {
  const { isDesktop } = React.useContext(DeviceContext);
  return (
    <ImageBackground
      source={IMAGES[`ftdBg`]}
      style={{ width: "100%", height: "100%" }}
    >
      <View style={tw("flex flex-1 justify-center items-center")}>
        <View
          style={{
            backgroundColor: "#FFF",
            height: "80%",
            width: isDesktop ? "50%" : "80%",
            paddingHorizontal: 20,
            paddingVertical: 20,
            shadowColor: backgroundColors.black,
            borderColor: "#e2e2e2",
            borderWidth: 1,
            shadowOffset: {
              width: 2,
              height: 3,
            },
            shadowRadius: 10,
            shadowOpacity: 0.2,
            elevation: 3,
            ...tw("items-center justify-between"),
          }}
        >
          <TermsandConditionsContent />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              testID={"accept"}
              accessibilityLabel="accept"
              title={"I Accept"}
              containerStyle={{ alignItems: "flex-end" }}
              onPress={() =>
                window && window.document.referrer
                  ? window.close()
                  : window.open("/login", "_self")
              }
            />
            <Button
              testID={"not_accept"}
              accessibilityLabel="not_accept"
              title={"I Do Not Accept"}
              containerStyle={{ alignItems: "flex-end" }}
              onPress={() =>
                window && window.document.referrer
                  ? window.close()
                  : window.open("/login", "_self")
              }
            />
          </View>
        </View>
      </View>
    </ImageBackground>
  );
};

export default TermsAndConditionsPage;
