import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";
import { selectState as selectGlobalState } from "../../../../../ongoing/global-data/selector";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectPageStatus = createSelector(
  selectState,
  ({ status }) => status
);

export const selectIsPageLoading = createSelector(
  selectPageStatus,
  (status) => status === "progress"
);

export const selectIsPageFailed = createSelector(
  selectPageStatus,
  (status) => status === "fail"
);

export const selectIsPageLoaded = createSelector(
  selectPageStatus,
  (status) => status === "done"
);

export const selectTabNames = createSelector(
  selectState,
  ({ tabs }) => tabs || []
);

export const selectActiveTab = createSelector(
  selectState,
  ({ activeTab }) => activeTab || ""
);

export const selectTaxAccordianStatus = createSelector(
  selectState,
  ({ openTaxAccordian }) => openTaxAccordian || ""
);

export const selectActiveAccordion = createSelector(
  selectState,
  ({ activeAccordian }) => activeAccordian || ""
);

export const selectMarketingEmail = createSelector(
  selectState,
  ({ marketingEmail }) => marketingEmail || ""
);

export const selectReplyEmailVerificationStatus = createSelector(
  selectState,
  ({ replyEmailVerificationStatus }) => replyEmailVerificationStatus || ""
);

export const selectFromEmailVerificationStatus = createSelector(
  selectState,
  ({ fromEmailVerificationStatus }) => fromEmailVerificationStatus || ""
);

export const selectActiveNotificationTab = createSelector(
  selectState,
  ({ activeNotificationTab }) => activeNotificationTab || ""
);

export const selectNotificationTabNames = createSelector(
  selectState,
  ({ notificationTabs }) => notificationTabs || []
);

export const selectSideCar = createSelector(
  selectState,
  ({ sideCar }) => sideCar || ""
);

export const selectSideCarTitle = createSelector(
  selectState,
  ({ sideCarTitle }) => sideCarTitle || ""
);

export const selectShopCode = createSelector(
  selectState,
  selectGlobalState,
  ({ shopCode }, { shopCode: dashboardSelectedShopCode }) => {
    const memberCode = shopCode || dashboardSelectedShopCode;
    return memberCode !== "all" ? memberCode : "";
  }
);

export const selectShopGroupMembers = createSelector(
  selectState,
  ({ shopGroupMembers = {} }) => shopGroupMembers
);

export const selectActiveShopGroupMembers = createSelector(
  selectState,
  ({ activeShopGroupMembers = {} }) => activeShopGroupMembers
);

export const selectShopDetails = createSelector(
  selectState,
  ({ shopDetails }) => shopDetails.content || {}
);

export const selectShopPreferences = createSelector(
  selectState,
  ({ shopPreferences }) => shopPreferences.content || {}
);

export const selectShopLogoData = createSelector(
  selectState,
  ({ shopLogo }) => shopLogo || {}
);

export const selectShopHours = createSelector(
  selectState,
  ({ shopHours }) => shopHours || {}
);

export const selectQuickBooksAuthorizationStatus = createSelector(
  selectState,
  ({ quickBooksAuthorizationStatus }) => quickBooksAuthorizationStatus
);

export const selectNotificationSettings = createSelector(
  selectState,
  ({ notificationSettings }) => notificationSettings || []
);

export const selectDefaultOLCNotifications = createSelector(
  selectState,
  ({ defaultOLCNotifications }) => defaultOLCNotifications || []
);

export const selectAutoScroll = createSelector(
  selectState,
  ({ autoScroll }) => autoScroll || {}
);

export const selectPromoCodeslist = (memberCode = "") =>
  createSelector(selectState, ({ promoCodesList }) => {
    if (isEmpty(memberCode) || memberCode === "all") {
      return Object.values(promoCodesList).flat(); // Return all orders across memberCodes
    }
    return get(promoCodesList, memberCode, []);
  });
