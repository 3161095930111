import { request } from "library/utils/request";
import React, { useContext, useState } from "react";
import { View, ActivityIndicator } from "react-native";
import tw from "tailwind-rn";
import { fonts, colors } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import { Text } from "react-native-elements";
import { FormFieldSwitch } from "components/elements/forms";
import { selectQuickBooksAuthorizationStatus } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { useDispatch, useSelector } from "react-redux";
import { setQuickBooksAuthorizationStatus } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { ToasterHandler } from "components/elements";
import { setIsQuickBooksSessionExpired } from "library/sagas/ongoing/global-data/slice";

const QuickbooksIntegration = (shopCode) => {
  const quickBooksAuthStatus = useSelector(selectQuickBooksAuthorizationStatus);
  const [loadingKey, setLoadingKey] = useStateIfMounted("");
  const dispatch = useDispatch();
  const [
    memberQuickBooksAuthorizationDetails,
    setMemberQuickBooksAuthorizationDetails,
  ] = useState({ isAuthorized: quickBooksAuthStatus });

  const checkChildWindowStatus = (childWindow) => {
    return new Promise((resolve, reject) => {
      try {
        const intervalId = setInterval(() => {
          if (childWindow && childWindow.closed) {
            clearInterval(intervalId);
            resolve(true);
          }
        }, 1000);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleValueChange = async (value) => {
    try {
      setLoadingKey("connect-quick-books");
      if (value) {
        const connectToQuickBooks = await request("connect-to-quickBooks", {
          shopCode,
        });
        const childWindow = window.open(
          connectToQuickBooks.authorizationUrl,
          "_blank",
          "location=1,width=800,height=650"
        );
        const isChildWindowClosed = await checkChildWindowStatus(childWindow);
        if (isChildWindowClosed === true) {
          const authorizationStatus = await request(
            "get-quickBooks-authorization-status",
            { shopCode }
          );
          if (authorizationStatus.isAuthorized === true) {
            dispatch(
              setQuickBooksAuthorizationStatus(authorizationStatus.isAuthorized)
            );
            dispatch(
              setIsQuickBooksSessionExpired(authorizationStatus.isExpired)
            );
            setMemberQuickBooksAuthorizationDetails(authorizationStatus);
            ToasterHandler(
              "success",
              Localise(messages, "QuickBooks account connected")
            );
          } else {
            ToasterHandler(
              "uh oh",
              Localise(
                messages,
                "Please try connecting to your QuickBooks account again"
              )
            );
          }
          setLoadingKey("");
        }
      } else {
        await request("disconnect-from-quickBooks", { shopCode });
        // setting authorized value to false based on 200 response from revoke api
        dispatch(setQuickBooksAuthorizationStatus(false));
        setMemberQuickBooksAuthorizationDetails({ isAuthorized: false });
        ToasterHandler(
          "success",
          Localise(messages, "Disconnected from QuickBooks account")
        );
        setLoadingKey("");
      }
    } catch (error) {
      ToasterHandler(
        "uh oh",
        Localise(
          messages,
          "Please try connecting to your QuickBooks account again"
        )
      );
      setLoadingKey("");
    }
  };
  const { messages, Localise } = useContext(I18NContext);
  return (
    <>
      <View style={[tw("flex flex-row items-center")]}>
        <View style={{ paddingRight: 25, width: 200 }}>
          <Text style={fonts.heading4}>
            {Localise(messages, "QuickBooks Account")}
          </Text>
        </View>
        <FormFieldSwitch
          value={memberQuickBooksAuthorizationDetails?.isAuthorized}
          onValueChange={handleValueChange}
          testID={"quick-books-integration"}
        ></FormFieldSwitch>
        <View style={{ paddingHorizontal: 15 }}>
          <Text>
            {memberQuickBooksAuthorizationDetails?.isAuthorized
              ? Localise(messages, "Connected")
              : Localise(messages, "Not Connected")}
          </Text>
        </View>
        {loadingKey === "connect-quick-books" && (
          <ActivityIndicator
            color={colors.activityIndicator}
            testID={"loader"}
          />
        )}
      </View>
    </>
  );
};

export default QuickbooksIntegration;
