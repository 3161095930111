import React, { useEffect, useContext } from "react";
import { View, Text, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";

import UserProfileStorage from "library/storage/userProfile";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import {
  fetchDeliveryDashboardData,
  fetchZones,
  setDashboardCurrentTabKey,
} from "library/sagas/views/home/drawer/delivery/slice";
import { selectDashboardCurrentTabKey } from "library/sagas/views/home/drawer/delivery/selector";

import tw from "tailwind-rn";
import moment from "moment";
import isNil from "lodash/isNil";

import { tabNames } from "./config";

import { TabBar } from "components/elements";
import {
  FormFieldDatePicker,
  FormFieldAutoComplete,
} from "components/elements/forms";

let deliveryDashboardZonesController;
let deliveryDashboardRoutesController;

const DeliveryDashboardHeader = ({
  sendingOriginData,
  allShopCodes,
  setLoading,
  params,
}) => {
  const range = 6; // days -> 1 week range from "startDate" to "endDate"
  const { messages, Localise } = useContext(I18NContext);
  const { isDesktop, isMobile } = useContext(DeviceContext);
  const { setFieldValue, values } = useFormikContext();
  const [selectShopQuery, setSelectShopQuery] = useStateIfMounted("");
  const currentDashboardKey = useSelector(selectDashboardCurrentTabKey);
  const isSmallScreen = !isDesktop;
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { setParams } = navigation;
  const minDate = moment().subtract(30, "days");
  const { startDate, endDate, shopCode } = values;

  useEffect(() => {
    return () => {
      deliveryDashboardZonesController &&
        deliveryDashboardZonesController.abort();
      deliveryDashboardRoutesController &&
        deliveryDashboardRoutesController.abort();
    };
  }, []);

  useEffect(() => {
    if (
      startDate &&
      endDate &&
      shopCode &&
      !moment(endDate).isBefore(startDate) &&
      moment(endDate).diff(startDate, "days") <= range
    ) {
      setLoading(true);

      const fetchMemberCodes =
        shopCode === "All Shops"
          ? sendingOriginData
              .filter((each) => each.code !== "All Shops")
              .map((each) => each.code)
          : new Array(shopCode);
      const params = {
        startDate,
        endDate,
        fetchMemberCodes,
        dashboardType: "delivery-details",
      };
      deliveryDashboardZonesController &&
        deliveryDashboardZonesController.abort();
      deliveryDashboardRoutesController &&
        deliveryDashboardRoutesController.abort();

      deliveryDashboardZonesController = new AbortController();
      deliveryDashboardRoutesController = new AbortController();

      dispatch(
        fetchDeliveryDashboardData({
          params,
          setLoading,
          deliveryDashboardRoutesController,
        })
      );

      fetchMemberCodes.map((eachShopCode, i) => {
        dispatch(
          fetchZones({
            shopCode: eachShopCode,
            isSingleShop: fetchMemberCodes.length === 1,
            setLoading:
              i + 1 === fetchMemberCodes.length ? setLoading : () => {},
            deliveryDashboardZonesController,
          })
        );
      });
    }
  }, [startDate, endDate, shopCode]);

  return (
    <View fsClass="fs-unmask">
      <View
        style={[tw("flex flex-row flex-wrap justify-between items-center")]}
      >
        <View style={[tw("flex flex-row flex-wrap"), { width: "85%" }]}>
          {/**Shop filter */}
          {allShopCodes.length !== 1 && (
            <View
              style={[
                tw("flex flex-row items-center"),
                {
                  zIndex: 2,
                  paddingBottom: isSmallScreen ? 0 : 4,
                  minWidth: isMobile ? "100%" : "30%",
                  maxWidth: isMobile ? "100%" : "45%",
                  marginRight: 10,
                },
              ]}
            >
              <View>
                <Text style={{ marginBottom: 10, paddingRight: 15 }}>
                  {Localise(messages, "Select Shop")}:
                </Text>
              </View>

              <FormFieldAutoComplete
                name="shopField"
                placeholder={`${Localise(messages, "Select Shop")}`}
                data={sendingOriginData.filter((each) => {
                  const { code = "", shopName = "" } = each;
                  return (
                    code
                      .toLowerCase()
                      .includes(selectShopQuery.toLowerCase()) ||
                    shopName
                      .toLowerCase()
                      .includes(selectShopQuery.toLowerCase())
                  );
                })}
                outerContainerStyle={{
                  width: 250,
                  padding: 0,
                  paddingRight: isSmallScreen ? 5 : 0,
                }}
                containerStyle={{
                  marginLeft: -5,
                  padding: 0,
                  margin: 0,
                }}
                autoCapitalize="none"
                showOnFocus={true}
                clearTextOnBackTab={true}
                isMultiSelect={false}
                listDisplayValues={["code", "shopName"]}
                popperPlacement={"bottom"}
                onChangeText={(text) => {
                  setSelectShopQuery(text);
                }}
                onSelect={(selectedValue) => {
                  if (!selectedValue) return;
                  const { code, shopName = "" } = selectedValue;
                  if (code === "All Shops") {
                    setFieldValue("shopField", code);
                    setFieldValue("shopName", shopName);
                    UserProfileStorage.setSelectedShopCode(undefined);
                  } else {
                    setFieldValue("shopField", `${code} ${shopName}`);
                    setFieldValue("shopName", shopName);
                    const shopTimeZone =
                      UserProfileStorage.getShopTimeZone(code);
                    const floristTimeZone = shopTimeZone || "America/Chicago";
                    setFieldValue("floristTimeZone", floristTimeZone);
                    UserProfileStorage.setSelectedShopCode(code);
                  }
                  setFieldValue("shopCode", code);

                  setSelectShopQuery("");
                }}
                onClear={() => {
                  setFieldValue("shopField", "");
                  setFieldValue("shopCode", undefined);
                  setFieldValue("shopName", undefined);
                }}
              />
            </View>
          )}

          {/**Date Range filter */}
          <View
            style={[
              tw(`flex-row ${isMobile ? `items-start` : `items-center`} pb-1`),
              { maxWidth: isMobile ? "100%" : "55%" },
            ]}
          >
            <View>
              <Text style={{ marginBottom: 10, paddingRight: 15 }}>
                {Localise(messages, "Select Date")}:
              </Text>
            </View>

            <View style={[tw("flex flex-row flex-wrap items-center")]}>
              <FormFieldDatePicker
                dateValueFormat="YYYY-MM-DD"
                name="startDate"
                placeholder="MM/DD/YYYY"
                minDate={minDate.toDate()}
                containerStyle={{
                  zIndex: 20,
                  paddingLeft: 0,
                  marginTop: 0,
                  width: isMobile ? "55%" : 154,
                  height: Platform.OS !== "web" ? 40 : "auto",
                }}
                alignIconRight={true}
                onValueChange={(val) => {
                  if (!isNil(val)) {
                    const startDateVal = moment(val).format("YYYY-MM-DD");
                    const endDateVal = moment(startDateVal)
                      .add(range, "days")
                      .format("YYYY-MM-DD");

                    const isOutOfRange =
                      moment(endDate).isAfter(endDateVal) ||
                      moment(endDate).isBefore(startDateVal);

                    setParams({
                      ...params,
                      dashboardStartDate: startDateVal,
                      ...(isOutOfRange && { dashboardEndDate: endDateVal }),
                    });
                  }
                }}
                iconName="calendar"
                iconType="font-awesome"
              />
              <Text
                style={{ marginBottom: 10, paddingRight: 15, paddingLeft: 10 }}
              >
                {Localise(messages, "to")}
              </Text>
              <FormFieldDatePicker
                dateValueFormat="YYYY-MM-DD"
                name="endDate"
                placeholder="MM/DD/YYYY"
                minDate={moment(startDate).toDate()}
                containerStyle={{
                  zIndex: 20,
                  paddingLeft: 0,
                  marginTop: 0,
                  width: isMobile ? "55%" : 154,
                  height: Platform.OS !== "web" ? 40 : "auto",
                }}
                alignIconRight={true}
                onValueChange={(val) => {
                  if (!isNil(val)) {
                    const endDateVal = moment(val).format("YYYY-MM-DD");

                    const startRangeDate = moment(endDateVal).subtract(
                      range,
                      "days"
                    );
                    const startDateVal = (
                      startRangeDate.isBefore(minDate)
                        ? minDate
                        : startRangeDate
                    ).format("YYYY-MM-DD");
                    const isOutOfRange =
                      moment(startDate).isBefore(startDateVal) ||
                      moment(startDate).isAfter(endDateVal);

                    setParams({
                      ...params,
                      ...(isOutOfRange && { dashboardStartDate: startDateVal }),
                      dashboardEndDate: endDateVal,
                    });
                  }
                }}
                iconName="calendar"
                iconType="font-awesome"
              />
            </View>
          </View>
        </View>
        <View>
          <TabBar
            tabNames={tabNames}
            currentTab={currentDashboardKey}
            setCurrentTab={(key) => {
              dispatch(setDashboardCurrentTabKey(key));
            }}
            scrollTabBar={true}
            showScrollBar={false}
            showsScrollIndicators={true}
            showBorder={false}
            showDivider={true}
            style={{
              padding: 0,
              marginTop: 5,
              marginBottom: 18,
            }}
            tabStyle={{
              paddingVertical: 0,
              paddingTop: 0,
              paddingBottom: 0,
            }}
          />
        </View>
      </View>
    </View>
  );
};

export default DeliveryDashboardHeader;
