import get from "lodash/get";
import omit from "lodash/omit";
import startCase from "lodash/startCase";
import isEmpty from "lodash/isEmpty";
import UserProfileStorage from "library/storage/userProfile";
import { phoneNumberFormatter } from "library/utils/formatter";

export const getInitialValues = (details = {}) => {
  const defaultValues = {
    firstName: "",
    lastName: "",
    roles: [],
    employmentStatus: "",
    shopCodes: [],
    phone: "",
    emailId: "",
    isEmail: true,
    pushNotifications: false,
    profileNotes: "",
    status: true,
    passwordOptions: "",
    newPassword: "",
  };

  if (!isEmpty(details)) {
    let rolesInStartCase = [];

    let roles = get(details, "authGroups.0.roles", []);
    rolesInStartCase = roles.map((roleName) =>
      startCase(roleName.toLowerCase())
    );

    const shopNames = UserProfileStorage.getAllShopNames();

    let memberCodes = get(details, "authGroups.0.memberCodes", []);
    if (memberCodes.length === 1) {
      memberCodes = [`${memberCodes[0]} ${shopNames[memberCodes[0]] || ""}`];
    }

    return {
      ...defaultValues,
      ...omit(details, ["authGroups"]),
      ...{
        roles: rolesInStartCase,
        shopCodes: memberCodes,
        status: get(details, "status", "") === "ACTIVE",
        phone: phoneNumberFormatter(get(details, "phone", "")),
      },
    };
  } else {
    return {
      ...defaultValues,
    };
  }
};
