import React from "react";
import { ScrollView, TouchableOpacity, View } from "react-native";
import { Divider, Image, Text } from "react-native-elements";

import tw from "tailwind-rn";
import moment from "moment";
import isEmpty from "lodash/isEmpty";

import { colors, fonts, shapes } from "styles/theme";
import IMAGES from "static/assets/images";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { generateDetailContent } from "components/views/drawer/order-details/helper";
import {
  filterMessages,
  olcMessagesFilterKeywords,
  fetchAllDeliveryAttempts,
} from "../helper";
import { phoneNumberFormatter } from "library/utils/formatter";

const DeliveryDetails = ({
  trackingDetails,
  setModalName,
  deliveryStatus = "",
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);

  const isDelivered = deliveryStatus.includes("DELIVERED");

  const orderItemDetails = trackingDetails?.order?.orderItems?.[0] || {};

  const {
    receivingMember: {
      businessName = "",
      addressLine1 = "",
      city = "",
      state = "",
      zip = "",
      phone = "",
    } = {},
    deliveryInfo: { driver = "", deliveryMethod = "", deliveryTime = "" } = {},
    messages: olcMessages = [],
    latestDSRequestedStatus = "",
  } = orderItemDetails;

  const isPickupOrder = [
    "STORE_PICKUP",
    "MOL_CUSTOMER_PICKUP",
    "PARTNER_STORE_PICKUP",
  ].includes(deliveryMethod);

  const deliveryAttempts =
    fetchAllDeliveryAttempts(trackingDetails?.events, olcMessages) || [];

  const {
    recipientInfo: { latitude = "", longitude = "" } = {},
    photo = "",
    signature = "",
    deliveredAtDoor = false,
    createdOn = "",
    notes = "",
  } = isPickupOrder || isDelivered
    ? trackingDetails?.events?.[0] || {}
    : deliveryAttempts[deliveryAttempts?.length - 1] || {};

  const isDSAttempted =
    fetchAllDeliveryAttempts(trackingDetails?.events, olcMessages, true) ===
    "DS";
  const deliveryDetailsContent = {
    ShopName: { label: "", value: businessName },
    DriverName: {
      label: "",
      value:
        isPickupOrder || isDelivered
          ? driver
          : isDSAttempted
          ? "Delivery Service"
          : driver,
    },
    AddressLine1: { label: "", value: addressLine1 },
    CityStateZip: { label: "", value: `${city}, ${state} ${zip}` },
    Phone: { label: "", value: phoneNumberFormatter(phone) },
  };

  let deliveredTime = "";
  if (isPickupOrder || latestDSRequestedStatus === "SUCCESS") {
    const filteredMessages = filterMessages(
      olcMessages,
      olcMessagesFilterKeywords["Delivered"],
      "OLC"
    );

    deliveredTime = filteredMessages?.[0]?.createdOn || "";
  }

  return (
    <View
      style={[
        tw("flex flex-col"),
        {
          ...shapes.sectionBorder,
          width: isDesktop ? 372 : "100%",
          height: 540,
          padding: 0,
          marginHorizontal: isDesktop ? 11 : 0,
          marginVertical: 8,
        },
      ]}
    >
      <View
        style={{
          padding: 15,
        }}
      >
        <Text
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: colors.highlighter,
            marginLeft: 15,
          }}
        >
          {Localise(
            messages,
            isPickupOrder
              ? "Pickup Details"
              : isDelivered
              ? "Delivery Details"
              : "Attempted Delivery Details"
          )}
        </Text>
      </View>

      <Divider style={{ marginVertical: 0 }} />

      <ScrollView
        style={{ padding: 15, marginLeft: 15 }}
        nativeID="order_status_scrollbar"
      >
        <View style={{ marginTop: 0 }}>
          <Text
            style={{
              ...fonts.default,
              fontSize: 15,
              fontWeight: "bold",
            }}
          >
            {Localise(
              messages,
              isPickupOrder
                ? "Picked up Time"
                : isDelivered
                ? "Delivered Time"
                : "Attempted Time"
            )}
          </Text>

          <Text style={{ ...fonts.default, fontSize: 14 }}>
            {deliveryTime
              ? deliveryTime
              : moment(deliveredTime || createdOn).format("hh:mma")}
          </Text>
        </View>

        <View style={{ marginTop: 25 }}>
          <Text style={{ ...fonts.default, fontSize: 15, fontWeight: "bold" }}>
            {Localise(
              messages,
              isPickupOrder
                ? "Pickup From"
                : isDelivered
                ? "Delivered By"
                : "Attempted By"
            )}
          </Text>

          {generateDetailContent(deliveryDetailsContent, true)}

          {!isPickupOrder && !!(latitude && longitude) && (
            <TouchableOpacity
              onPress={() => setModalName("map")}
              testID="delivered_location_on_map"
              accessibilityLabel="delivered_location_on_map"
              style={{ marginTop: 5 }}
            >
              <Image
                style={{ width: 30, height: 30 }}
                resizeMode="cover"
                source={IMAGES["map-pin-circle"]}
              />
            </TouchableOpacity>
          )}
        </View>

        {!isPickupOrder && (
          <>
            <View style={{ marginTop: 15 }}>
              <Text
                style={{
                  ...fonts.default,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                {Localise(messages, "Driver Notes")}
              </Text>

              <Text style={{ ...fonts.default, fontSize: 14 }}>{notes}</Text>
            </View>

            {deliveredAtDoor && (
              <View style={{ marginTop: 15 }}>
                <Text
                  style={{
                    ...fonts.default,
                    fontSize: 15,
                    fontWeight: "bold",
                  }}
                >
                  {Localise(messages, "Delivered at Door")}
                </Text>

                <Text style={{ ...fonts.default, fontSize: 14 }}>
                  {Localise(messages, "Yes")}
                </Text>
              </View>
            )}

            <View
              style={[
                tw(`flex flex-${isDesktop ? "row" : "col"}`),
                { marginTop: 15 },
              ]}
            >
              {!isEmpty(photo) && (
                <View>
                  <Text
                    style={{
                      ...fonts.default,
                      fontSize: 15,
                      fontWeight: "bold",
                    }}
                  >
                    {Localise(messages, "Delivery Photo")}
                  </Text>

                  <View style={{ width: 104 }}>
                    <Image
                      style={{
                        width: 104,
                        height: 104,
                        marginTop: 10,
                      }}
                      source={{
                        uri: `data:image/png;base64,${photo}`,
                      }}
                    />

                    <TouchableOpacity
                      onPress={() => setModalName("delivery_photo")}
                      testID="zoom_delivery_photo"
                      accessibilityLabel="zoom_delivery_photo"
                      style={{
                        position: "absolute",
                        top: 81,
                        right: 3,
                      }}
                    >
                      <Image
                        style={{
                          width: 30,
                          height: 30,
                        }}
                        resizeMode="cover"
                        source={IMAGES["zoom-in"]}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              )}

              {!isEmpty(signature) && (
                <View
                  style={{
                    marginLeft: isDesktop && !isEmpty(photo) ? 20 : 0,
                    marginTop: isDesktop || isEmpty(photo) ? 0 : 15,
                  }}
                >
                  <Text
                    style={{
                      ...fonts.default,
                      fontSize: 15,
                      fontWeight: "bold",
                    }}
                  >
                    {Localise(messages, "Delivery Signature")}
                  </Text>

                  <View style={{ width: 170 }}>
                    <Image
                      style={{
                        width: 170,
                        height: 70,
                        marginTop: 10,
                        borderWidth: 1,
                        borderColor: "#707070",
                      }}
                      source={{
                        uri: `data:image/png;base64,${signature}`,
                      }}
                    />

                    <TouchableOpacity
                      onPress={() => setModalName("delivery_signature")}
                      testID="zoom_delivery_signature"
                      accessibilityLabel="zoom_delivery_signature"
                      style={{
                        position: "absolute",
                        top: 47,
                        right: 3,
                      }}
                    >
                      <Image
                        style={{
                          width: 30,
                          height: 30,
                        }}
                        resizeMode="cover"
                        source={IMAGES["zoom-in"]}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              )}
            </View>
          </>
        )}
      </ScrollView>
    </View>
  );
};

export default DeliveryDetails;
