const CUSTOMER_DIRECTORY = Object.freeze({
  CONTACT_SUBSCRIPTION:
    "There’s an active subscription linked to this contact. Please cancel the subscription before deleting the contact.",
  CONTACT_DELETED: "Contact has been deleted",
  FAILURE_ERROR: "We were unable to process your request, please try again.",
  CONTACT_UPDATED: "Contact has been updated",
  CONTACT_CREATED: "Contact has been created",
  CONFIRM_DELETE_CONTACT: "Are you sure you want to delete this contact?",
  CONFIRM_MERGE_START: "Merge started and will be completed in a few minutes.",
  ADD_CONTACT: "Add Contact",
  MERGE_CONTACT: "Merge Contact",
  MERGE_RECORDS: "Merge Records",
  MERGE_SELECTED: "Merge Selected",
  SELECT_RECORD: "Search and select record to merge",
  CONFIRM_MERGE:
    "Would you like to merge these profiles? This action cannot be reversed",
  INVOICE_PREFERENCE_ENABLED:
    "Invoices will be sent automatically for this customer and override the global settings in Shop Settings.",
  INVOICE_PREFERENCE_DISABLED:
    "Invoices will not be sent automatically for this customer and override the global settings in Shop Settings.",
  STATEMENT_PREFERENCE_ENABLED:
    "House Account Statements will be sent automatically via email for this customer and override the global settings in Shop Settings.",
  STATEMENT_PREFERENCE_DISABLED:
    "House Account Statements will not be sent automatically via email for this customer and override the global settings in Shop Settings.",
  ERROR_INVOICE_PREFERENCE_ENABLED:
    "It looks like your global setting are currently disabled in shop setting for sending invoices automatically. Please enable to update for this customer",
  ERROR_STATEMENT_PREFERENCE_ENABLED:
    "It looks like your global setting are currently disabled in shop setting for sending statements automatically. Please enable to update for this customer",
});

export default CUSTOMER_DIRECTORY;
