import { select, put, call } from "redux-saga/effects";
import get from "lodash/get";

import { setApiResponse, setApiError } from "../slice";
import { selectShopCode } from "../selector";

import request from "../request";

export function* handleFetchWebsiteSettings() {
  const serviceRequest = (params) => request("get-website-settings", params);
  const shopCode = yield select(selectShopCode);

  try {
    const response = yield call(serviceRequest, { shopCode });

    const content = processWebsiteSettingsResponse(response);

    yield put(
      setApiResponse({
        path: "websiteSettings",
        content,
      })
    );
  } catch (error) {
    yield put(
      setApiError({
        path: "websiteSettings",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleSaveWebsiteSettings(action) {
  const serviceRequest = (params) => request("save-website-settings", params);

  const { params, resolve, reject } = get(action, "payload", {});
  const shopCode = yield select(selectShopCode);

  try {
    const requestPayload = prepareSaveWebsiteSettingsPayload({
      ...params,
    });

    yield call(serviceRequest, { shopCode, ...requestPayload });

    yield call(handleFetchWebsiteSettings);

    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        path: "websiteSettings",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

const processWebsiteSettingsResponse = (response) => {
  const {
    shopSettings: {
      shopName = "",
      address: {
        address1: addressLine1 = "",
        city = "",
        postalCode: zip = "",
        province: state = "",
      } = {},
      shopPhone = "",
      tollFree = "",
      sites: [
        {
          website = "",
          redirectURL = [],
          websitePhoneNumber = "",
          websiteEmail = "",
          orderEmail = "",
          bccEmail = "",
          googleAnalytics = "",
          googleGTMTag = "",
          additionalScripts = "",
          siteVerificationCode = "",
          displayInfo: {
            displayShopAddress = "Y",
            displayWebsitePhoneNumber = "Y",
            displayShopTollFree = "Y",
            displayWebsiteEmail = "Y",
          } = {},
        },
      ] = [{}],
    } = {},
  } = response;
  return {
    shopName,
    shopAddress: {
      addressLine1,
      city,
      zip,
      state,
      streetAddress: addressLine1,
    },
    shopPhone,
    shopTollFree: tollFree,
    websitePhoneNumber: websitePhoneNumber || shopPhone,
    website,
    websiteEmail,
    orderEmail,
    bccEmails: bccEmail,
    redirectUrls: redirectURL,
    googleAnalyticsId: googleAnalytics,
    googleTagManagerId: googleGTMTag,
    additionalScripts: additionalScripts,
    siteVerificationCode,
    displayShopAddress: displayShopAddress === "Y",
    displayShopTollFree: displayShopTollFree === "Y",
    displayWebsitePhoneNumber: displayWebsitePhoneNumber === "Y",
    displayWebsiteEmail: displayWebsiteEmail === "Y",
  };
};

const prepareSaveWebsiteSettingsPayload = (params) => {
  const {
    shopName,
    shopAddress: {
      addressLine1: address1,
      city,
      zip: postalCode,
      state: province,
    } = {},
    shopPhone,
    shopTollFree: tollFree,
    websitePhoneNumber,
    website,
    websiteEmail,
    orderEmail: orderEmail,
    bccEmails: bccEmail,
    redirectUrls: redirectURL,
    googleAnalyticsId,
    googleTagManagerId,
    additionalScripts,
    siteVerificationCode,
    displayShopAddress,
    displayShopTollFree,
    displayWebsitePhoneNumber,
    displayWebsiteEmail,
  } = params;

  return {
    shopSettings: {
      shopName,
      address: {
        address1,
        city,
        postalCode,
        province,
      },
      shopPhone,
      tollFree,
      sites: [
        {
          website,
          bccEmail,
          orderEmail,
          websiteEmail,
          redirectURL,
          websitePhoneNumber,
          googleAnalytics: googleAnalyticsId,
          googleGTMTag: googleTagManagerId,
          additionalScripts: additionalScripts,
          siteVerificationCode,
          displayInfo: {
            displayShopAddress: displayShopAddress ? "Y" : "N",
            displayShopTollFree: displayShopTollFree ? "Y" : "N",
            displayWebsiteEmail: displayWebsiteEmail ? "Y" : "N",
            displayWebsitePhoneNumber: displayWebsitePhoneNumber ? "Y" : "N",
          },
        },
      ],
    },
  };
};
