import React, { useEffect } from "react";
import ReCaptcha from "react-google-recaptcha";
import Environment from "library/utils/environment";

const Captcha = ({ handleChange, resetCaptcha }) => {
  const recaptchaRef = React.useRef();

  useEffect(() => {
    if (resetCaptcha) {
      recaptchaRef.current.reset();
    }
  }, [resetCaptcha]);

  const onCaptchaChange = () => {
    handleChange(recaptchaRef.current.getValue());
  };
  const onExpired = () => {
    recaptchaRef.current.reset();
    handleChange("");
  };

  const siteKey = Environment.get(
    "MHQ_GOOGLE_CAPTCHA_SITE_KEY",
    "6LeAwyMbAAAAAHDuJuFPb6Uo4bcAUrXwLUYQpufy"
  );

  return (
    <ReCaptcha
      sitekey={siteKey}
      ref={recaptchaRef}
      size="normal"
      onChange={onCaptchaChange}
      onExpired={onExpired}
    />
  );
};

export default Captcha;
