import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectPageStatus = createSelector(
  selectState,
  ({ status }) => status
);

export const selectApiError = createSelector(
  selectState,
  ({ apiError }) => apiError
);

export const selectIsPageLoading = createSelector(
  selectPageStatus,
  (status) => status === "progress"
);

export const selectIsPageFailed = createSelector(
  selectPageStatus,
  (status) => status === "fail"
);

export const selectIsPageLoaded = createSelector(
  selectPageStatus,
  (status) => status === "done"
);

export const selectApiResponse = createSelector(
  selectState,
  ({ apiResponse }) => apiResponse
);

export const selectOrderLookup = (deliveryId) =>
  createSelector(
    selectApiResponse,
    ({ orderLookup }) => orderLookup[deliveryId]
  );

export const selectEntireRefunds = createSelector(
  selectApiResponse,
  ({ refunds }) => refunds
);

export const selectPageData = createSelector(selectState, ({ data }) => data);

export const selectRefunds = createSelector(
  selectPageData,
  ({ refunds }) => refunds
);

export const selectExtendedScreenData = createSelector(
  selectState,
  ({ extendedScreen }) => extendedScreen
);

export const selectSideCarTitle = createSelector(
  selectExtendedScreenData,
  ({ screenTitle }) => screenTitle || ""
);
export const selectShowUpsert = createSelector(
  selectExtendedScreenData,
  ({ showUpsert }) => showUpsert
);

export const selectTabNames = createSelector(
  selectExtendedScreenData,
  ({ tabs = [] }) => tabs
);

export const selectPageActions = createSelector(
  selectState,
  ({ actions }) => actions
);

export const selectSearchText = createSelector(
  selectPageActions,
  ({ search }) => search
);

export const selectAppliedLimits = createSelector(
  selectPageActions,
  ({ limit }) => limit.refunds
);

export const selectNoRecordsMessage = createSelector(
  selectPageActions,
  ({ noRecordsMessage }) => noRecordsMessage
);

export const selectShopCode = createSelector(
  selectPageActions,
  ({ shopCode }) => shopCode
);

export const selectNoop = createSelector(selectState, () => "");
