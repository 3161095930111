import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as parentSlice } from "../constants";
import { SLICE_NAME as sliceName } from "./constants";

export const initialState = {
  status: "",
  api: {},
  data: {},
  bulkActionType: "",
  error: false,
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: `view::${parentSlice}::${sliceName}`,
  initialState,
  reducers: {
    setReset: (state) => {
      Object.assign(state, initialState);
    },
    setApiResponse: (state, action) => {
      state.api = action.payload;
    },
    setData: (state, action) => {
      const { content, patch } = action.payload;
      if (patch) {
        state.data = { ...state.data, ...patch };
      } else {
        state.data = content;
      }
      state.status = "done";
      state.error = false;
    },
    setAPIError: (state, action) => {
      state.status = "done";
      state.error = action.payload;
    },
    setQuickAction: () => {},
    fetchProduct: (state) => {
      state.status = "progress";
    },
    setBulkAction: (state, action) => {
      state.bulkActionType = action.payload;
    },
    createProduct: () => {},
    updateProduct: () => {},
    revertToGlobal: () => {},
    patchProduct: () => {},
    deleteProduct: () => {},
    restoreProduct: () => {},
    fetchActivity: () => {},
  },
});

export const {
  setReset,
  setApiResponse,
  setData,
  setAPIError,
  setQuickAction,
  fetchProduct,
  createProduct,
  updateProduct,
  patchProduct,
  deleteProduct,
  restoreProduct,
  fetchActivity,
  revertToGlobal,
  setBulkAction,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
