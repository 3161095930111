import React from "react";
import { View, Platform, TouchableOpacity, Image } from "react-native";
import { Text } from "react-native-elements";
import { fonts } from "styles/theme";
import IMAGES from "static/assets/images";
import tw from "tailwind-rn";

const closeWindow = () => {
  Platform.OS === "web" && window && window.close();
};

const ProxyHeader = ({
  color,
  content,
  image,
  showClose = true,
  handleClick,
}) => {
  const Component = handleClick ? TouchableOpacity : View;
  return (
    <Component
      style={[
        tw("flex flex-row justify-center items-center"),
        { backgroundColor: color, height: 50 },
      ]}
      onPress={handleClick}
    >
      <View style={tw("flex flex-row justify-center items-center flex-1")}>
        <Image
          style={{ width: 50, height: 50, marginRight: 5 }}
          resizeMode="cover"
          source={IMAGES[image]}
        />
        <Text style={{ ...fonts.heading3, fontSize: 15 }}>{content}</Text>
      </View>
      {showClose && (
        <TouchableOpacity
          style={tw("items-end")}
          onPress={closeWindow}
          testID="close"
          accessibilityLabel="close"
        >
          <Image
            style={{ width: 20, height: 20, marginRight: 10 }}
            resizeMode="cover"
            source={require("static/assets/cancelled.png")}
          />
        </TouchableOpacity>
      )}
    </Component>
  );
};
export default ProxyHeader;
