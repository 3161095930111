import { combineReducers } from "redux";
import { SLICE_NAME } from "./constants";

import currentOrders from "./current-orders/slice";
import currentDelivery from "./current-delivery/slice";
import globalData from "./global-data/slice";
import staffListing from "./staff-listing/slice";
import orderDetails from "./order-details/slice";

const reducer = {
  [SLICE_NAME]: combineReducers({
    ...currentOrders,
    ...globalData,
    ...staffListing,
    ...currentDelivery,
    ...orderDetails,
  }),
};

export default reducer;
