import React from "react";
import { View } from "react-native";
import HeaderBar from "components/containers/header";
import Demo from "components/views/drawer/demo";

const DemoPage = () => {
  return (
    <View>
      <HeaderBar />
      <Demo />
    </View>
  );
};

export default DemoPage;
