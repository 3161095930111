import { takeLatest, put, call } from "redux-saga/effects";
import {
  createOrEndSession,
  createTransaction,
  setSessionInfo,
  setReset,
  fetchAvailableSessions,
} from "./slice";
import { request } from "library/utils/request";

import get from "lodash/get";
import { setCreateOrderShopCode } from "library/sagas/views/home/drawer/create-order/slice";

const getAvailableSessions = (params) =>
  request("get-available-sessions", params);
const openOrEndRequest = (params) => request("open-end-session", params);
const createTransactionRequest = (params) =>
  request("create-session-transaction", params);

/**
 * WORKER: Creates Or Ends Session and publishes the successful result, or an error
 * @param {action} action the create data action definition
 */
function* handleCreateOrEndSession(action = {}) {
  const { params, resolve, reject } = get(action, "payload", {});
  try {
    const response = yield call(openOrEndRequest, params);
    if (params.id) {
      yield put(setReset());
      yield put(setCreateOrderShopCode(""));
    } else {
      yield put(setSessionInfo(response));
    }
    resolve && resolve(response);
  } catch (error) {
    console.log(error);
    reject && reject(error);
  }
}

/**
 * WORKER: Creates data and publishes the successful result, or an error
 * @param {action} action the create data action definition
 */
function* handleCreateTransaction(action = {}) {
  const { params, resolve, reject } = get(action, "payload", {});
  try {
    const response = yield call(createTransactionRequest, params);
    if (response) {
      yield put(setSessionInfo(response));
      resolve && resolve(response);
    } else {
      reject && reject("error");
    }
  } catch (error) {
    console.log(error);
    reject && reject(error);
  }
}

/**
 * WORKER: Fetches data and publishes the successful result, or an error
 * @param {action} action the fetch data action definition
 */
function* handleFetchAvailableSessions(action = {}) {
  try {
    const { params } = get(action, "payload", {});
    const response = yield call(getAvailableSessions, params);
    yield put(setSessionInfo(response[0]));
  } catch (error) {
    console.log(error);
  }
}

/**
 * WATCHER: Subscribe to Page actions
 */
export default function* watchSaga() {
  yield takeLatest(createOrEndSession.type, handleCreateOrEndSession);
  yield takeLatest(createTransaction.type, handleCreateTransaction);
  yield takeLatest(fetchAvailableSessions.type, handleFetchAvailableSessions);
}
