import common from "./common";
import catalog from "./catalog";
import dashboard from "./dashboard";
import product from "./product";
import collection from "./collection";
import listCollections from "./list-collections";

const sagas = [
  common,
  catalog,
  dashboard,
  product,
  collection,
  listCollections,
];

export default sagas;
