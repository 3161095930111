import React, { useEffect } from "react";
import {
  View,
  ActivityIndicator,
  Platform,
  TouchableOpacity,
} from "react-native";
import { Text } from "react-native-elements";
import { ToasterHandler } from "components/elements";

import tw from "tailwind-rn";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import { request } from "library/utils/request";

import {
  Form,
  FormFieldPicker,
  FormFieldSwitch,
  SubmitButton,
} from "components/elements/forms";
import { getValidationSchema } from "./config";
import { fonts, colors } from "styles/theme";

import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import Environment from "library/utils/environment";

const SettingItem = React.memo(
  ({
    title,
    settingId,
    initialValues: initailPreferences,
    loadingKey,
    printers,
    saveSettings,
    shopSettings,
    printersInfo,
    shopCode,
    macAddress,
    setRemotePrintModalVisibile,
    remotePrintArtifactValuesRef,
  }) => {
    const hasMultiplePreferences = isArray(initailPreferences);
    let initialValues = { printer: "", tray: "" };
    let initialValuesIndex = -1;

    if (hasMultiplePreferences) {
      initialValuesIndex = initailPreferences.findIndex(
        (each) => each.macAddress === macAddress
      );
      if (initialValuesIndex > -1)
        initialValues = initailPreferences[initialValuesIndex];
    } else {
      initialValues = initailPreferences;
    }

    const { messages, Localise } = React.useContext(I18NContext);
    const [selectedPrinter, setSelectedPrinter] = useStateIfMounted(
      initialValues?.printer || ""
    );
    const [onChangeValidation, setOnChangeValidation] =
      useStateIfMounted(false);

    const validationSchema = getValidationSchema(Localise, messages);

    const specificSettingInfo = get(shopSettings, settingId, {});

    const specificSetting = isArray(specificSettingInfo)
      ? specificSettingInfo.find((each) => each.macAddress === macAddress)
      : specificSettingInfo;

    const isSettingsExists = !isEmpty(specificSetting);

    const isRemotePrintGloballyEnabled = Environment.get(
      "ENABLE_REMOTE_PRINT",
      true
    );

    const getPrinterTrays = () => {
      if (selectedPrinter === "") return [];
      const selectedPrinterDetails =
        printersInfo.filter((each) => each.printerName === selectedPrinter) ||
        [];

      const traysInfo = [];
      selectedPrinterDetails[0]?.trays.map((each) =>
        traysInfo.push({ label: each.name, value: each.code })
      );
      return traysInfo;
    };

    const onSubmit = async (values, settingId) => {
      const clonedInitialVals = cloneDeep(initailPreferences);
      let newValues = "";
      if (hasMultiplePreferences) {
        if (initialValues?.macAddress !== values.macAddress) {
          clonedInitialVals.push(values);
        } else {
          clonedInitialVals.splice(initialValuesIndex, 1, values);
        }
        newValues = clonedInitialVals;
      } else {
        newValues = [values];
      }

      const preferences = [
        {
          id: settingId,
          values: [JSON.stringify(newValues)],
        },
      ];
      saveSettings({ preferences });
    };

    const onClear = async (settingId, resetForm, setSelectedPrinter) => {
      const clonedInitialVals = cloneDeep(initailPreferences);
      const initialValuesIndex = initailPreferences.findIndex(
        (each) => each.macAddress === macAddress
      );
      if (initialValuesIndex > -1) {
        clonedInitialVals.splice(initialValuesIndex, 1);
      }

      const preferences = [
        {
          id: settingId,
          values: [JSON.stringify(clonedInitialVals)],
        },
      ];
      setSelectedPrinter("");
      saveSettings({ preferences }, true, false, () => {
        resetForm();
      });
    };

    return (
      <View
        style={[
          tw(`flex flex-row items-center w-full`),
          {
            borderWidth: 1,
            borderColor: colors.grayScaleLight,
            justifyContent: "space-evenly",
          },
        ]}
        testID={settingId}
      >
        <Form
          initialValues={{ ...initialValues, macAddress }}
          validationSchema={validationSchema[settingId]}
          onSubmit={(values) => onSubmit(values, settingId)}
          validateOnChange={onChangeValidation}
          validateOnBlur={onChangeValidation}
          render={({ values, resetForm, setFieldValue, submitCount }) => {
            //eslint-disable-next-line
            useEffect(() => {
              if (submitCount > 0) {
                setOnChangeValidation(true);
              }
            }, [submitCount]);

            return (
              <View
                style={[
                  tw("flex flex-row"),
                  {
                    width: "70%",
                    alignItems: Platform.OS !== "web" ? "center" : "baseline",
                    justifyContent: "space-evenly",
                  },
                ]}
              >
                <View
                  style={{
                    width: "20%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={fonts.heading5}>
                    {Localise(messages, title)}
                  </Text>
                </View>
                <FormFieldPicker
                  fsClass="fs-exclude"
                  containerStyle={{
                    width: "25%",
                    paddingBottom: 5,
                  }}
                  placeholder={{ label: "Select Printer", value: "" }}
                  data={printers}
                  name="printer"
                  onChange={(e) => {
                    setSelectedPrinter(e);
                    values.printer !== e && setFieldValue("tray", "");
                  }}
                  testID="printer_selection"
                />
                <FormFieldPicker
                  fsClass="fs-exclude"
                  containerStyle={{
                    width: "25%",
                    paddingBottom: 5,
                  }}
                  placeholder={{ label: "Select Tray", value: "" }}
                  data={getPrinterTrays()}
                  name="tray"
                  testID="tray_selection"
                />

                <View
                  style={[
                    tw("flex flex-row items-center justify-center"),
                    { width: "20%" },
                  ]}
                >
                  <SubmitButton disableOnDirty={true} title="Save" />
                  <View style={{ minWidth: 45 }}>
                    {!!initialValues?.printer && (
                      <TouchableOpacity
                        onPress={() => {
                          setOnChangeValidation(false);
                          onClear(settingId, resetForm, setSelectedPrinter);
                        }}
                        disabled={loadingKey === settingId}
                        testID={"clear"}
                        accessibilityLabel={"clear"}
                      >
                        <View>
                          <Text style={fonts.link1}>
                            {Localise(messages, "Clear")}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              </View>
            );
          }}
        />
        <View
          style={[
            tw(`flex flex-row items-center justify-evenly`),
            { width: "30%" },
          ]}
        >
          <View
            style={{
              ...tw("flex flex-row items-center w-1/2"),
              width: isRemotePrintGloballyEnabled ? "40%" : "80%",
            }}
          >
            <View
              style={{
                width: 75,
              }}
            >
              <Text style={fonts.heading5}>
                {Localise(messages, "Auto Print")}
              </Text>
            </View>
            <FormFieldSwitch
              name="auto_print"
              value={isSettingsExists ? specificSetting["auto_print"] : false}
              onValueChange={(val) => {
                const autoPrintAcceptedOrders = get(
                  shopSettings,
                  "auto_print_accept_orders",
                  ""
                );
                if (
                  val &&
                  [
                    "order_messages_incoming_print_settings",
                    "order_messages_outgoing_print_settings",
                  ].includes(settingId)
                ) {
                  request("mark-messages-printed", [
                    {
                      memberCode: shopCode,
                      direction: settingId.includes("incoming")
                        ? "INBOUND"
                        : "OUTBOUND",
                      messageType: "RELATED_MESSAGE",
                    },
                  ]);
                }

                const handleSwitch = () => {
                  const clonedInitialVals = cloneDeep(initailPreferences);
                  let newValues = "";
                  if (hasMultiplePreferences) {
                    clonedInitialVals.splice(initialValuesIndex, 1, {
                      ...initialValues,
                      auto_print: val,
                    });
                    newValues = clonedInitialVals;
                  } else {
                    newValues = [
                      {
                        ...initialValues,
                        auto_print: val,
                      },
                    ];
                  }

                  const preferences = [
                    {
                      id: settingId,
                      values: [JSON.stringify(newValues)],
                    },
                  ];
                  saveSettings(
                    {
                      preferences,
                      isAutoPrintToggle:
                        settingId !== "pickup_manifest_print_settings",
                    },
                    true,
                    false,
                    () => {},
                    `Auto Print has been ${val ? "enabled" : "disabled"}`
                  );
                };
                if (settingId === "pickup_manifest_print_settings") {
                  handleSwitch();
                } else {
                  if (autoPrintAcceptedOrders === "true") {
                    handleSwitch();
                  } else {
                    ToasterHandler(
                      "error",
                      Localise(
                        messages,
                        "Activate Auto Print in Shop Settings to use this feature"
                      )
                    );
                  }
                }
              }}
              disabled={!isSettingsExists}
              testID="auto_print"
            />
          </View>
          {isRemotePrintGloballyEnabled && (
            <View style={{ ...tw("flex flex-row items-center"), width: "40%" }}>
              {settingId !== "pickup_manifest_print_settings" && (
                <>
                  <View
                    style={{
                      width: 90,
                    }}
                  >
                    <Text style={fonts.heading5}>
                      {Localise(messages, "Remote Print")}
                    </Text>
                  </View>
                  <FormFieldSwitch
                    name="remote_print"
                    value={
                      isSettingsExists ? specificSetting["remote_print"] : false
                    }
                    onValueChange={(val) => {
                      const handleSwitch = () => {
                        const clonedInitialVals = cloneDeep(initailPreferences);
                        let newValues = "";
                        // Check if remote_print exists in any of the objects in initialPreferences
                        const isRemotePrintEnabled = clonedInitialVals.some(
                          (preference) => preference?.remote_print === true
                        );

                        // REMOTE Print case
                        if (isRemotePrintEnabled && val) {
                          // Show an alert if remote_print is true in any object
                          setRemotePrintModalVisibile(true);
                          remotePrintArtifactValuesRef.current = {
                            settingId,
                            initailPreferences,
                            hasMultiplePreferences,
                            initialValuesIndex,
                          };
                        } else {
                          remotePrintArtifactValuesRef.current = {};
                          // NON-REMOTE Print case
                          if (hasMultiplePreferences) {
                            clonedInitialVals.splice(initialValuesIndex, 1, {
                              ...initialValues,
                              remote_print: val,
                            });
                            newValues = clonedInitialVals;
                          } else {
                            newValues = [
                              {
                                ...initialValues,
                                remote_print: val,
                              },
                            ];
                          }
                          const preferences = [
                            {
                              id: settingId,
                              values: [JSON.stringify(newValues)],
                            },
                          ];
                          saveSettings(
                            {
                              preferences,
                              isAutoPrintToggle: true,
                            },
                            true,
                            false,
                            () => {},
                            `Remote Print has been ${
                              val ? "enabled" : "disabled"
                            }`
                          );
                        }
                      };
                      handleSwitch();
                    }}
                    disabled={!isSettingsExists}
                    testID="remote_print"
                  />
                </>
              )}
            </View>
          )}
          {loadingKey === settingId && (
            <ActivityIndicator
              color={colors.activityIndicator}
              testID={"setting_loader"}
            />
          )}
        </View>
      </View>
    );
  }
);

export default SettingItem;
