import React, { useEffect, useRef } from "react";
import { Text, Button } from "react-native-elements";
import { Platform, View, TouchableOpacity, Image } from "react-native";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { useSelector, useDispatch } from "react-redux";
import { useFormikContext } from "formik";

import tw from "tailwind-rn";
import filter from "lodash/filter";
import last from "lodash/last";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import get from "lodash/get";
import moment from "moment";

import { Tooltip } from "components/elements";
import { Form, FormField, SubmitButton } from "components/elements/forms";

import { fonts, colors, theme } from "styles/theme";

import {
  fetchAddons,
  fetchlocalAddons,
  fetchMOLAddons,
  fetchProduct,
  resetCurrentProduct,
  setCurrentPage,
} from "library/sagas/ongoing/global-data/slice";

import { setEditProducts } from "library/sagas/views/home/drawer/create-order/slice";
import {
  selectRecordData,
  selectOrderLocked,
} from "library/sagas/ongoing/current-orders/selector";

import {
  selectImages,
  selectSelectedProducts,
  selectCurrentProduct,
  selectState as selectGlobalState,
} from "library/sagas/ongoing/global-data/selector";

import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";
import { formatPrice, trimSpecialChars } from "library/utils/formatter";
import IMAGES from "static/assets/images";
import { isMolOrder } from "library/utils/payment-options";

import { getRequestObj } from "../helper";
import { getOccasionLabel, fetchAddonInfo } from "./helper";
import SelectedProductDetails from "./order-details/product-details";
import EnclosureCard from "./order-details/enclosure-card";
import { getValidationSchema } from "./order-details/yup";

const ProductDetails = ({
  triggerRequest,
  triggerAction,
  cardInfo,
  doNotFill,
  index,
  isPickUpOrder,
  supportPriceChange,
  showProductInfo,
}) => {
  const { messages: locMessages, Localise } = React.useContext(I18NContext);
  const { isMobile, isDesktop } = React.useContext(DeviceContext);
  const orderDetailResponse = useSelector(selectRecordData);
  const imagesData = useSelector(selectImages);
  const selectedproducts = useSelector(selectSelectedProducts);
  const currentproduct = useSelector(selectCurrentProduct);
  const global = useSelector(selectGlobalState);
  const isOrderLocked = useSelector(selectOrderLocked);

  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { values, setFieldValue } = useFormikContext();

  const orderDetails = orderDetailResponse.orderItems[index];

  const parentPath = `orderItems.${index}`;
  const formattedPrice = get(values, `${parentPath}.orderPrice`, 0);
  const addonsTotalPrice = get(values, `${parentPath}.addonsTotalPrice`, 0);
  const isSmallScreen = !isDesktop;

  const {
    direction: orderDirection,
    deliveryInfo: {
      occasion = "",
      cardMessage = "",
      deliveryDate = "",
      deliveryMethod,
      pickUpBy = "",
    },
    designer: designerInfo = { name: "" },
    lineItems = [],
    multiProductEligible = false,
    messages,
    recipientInfo,
    receivingMember,
    sendingMember,
    status,
    orderSource = "",
    orderItemId,
    hasFulfillmentError,
    hasDSFulfillmentError = false,
  } = orderDetails;

  const deliveryInfoPath = `${parentPath}.deliveryInfo`;
  const isLocalOrder = deliveryMethod !== "FLORIST_PARTNER";
  const specialInstructions = get(
    values,
    `${parentPath}.productInfo.specialInstructions`,
    ""
  );

  const sourceMemberCode =
    orderDirection === "INBOUND"
      ? receivingMember?.memberCode
      : sendingMember?.memberCode;

  const isOrderMOL = isMolOrder(orderSource);

  const isActiveOrder = ![
    "CANCELLED",
    "REJECTED",
    "FORFEITED",
    "ERROR",
  ].includes(status);

  // Hide modify order action button in case order delivery date is older than 90 days
  const hideModifyOrderAction = moment(deliveryDate).isBefore(
    moment().subtract(90, "days")
  );

  const showEditProductLink =
    !isOrderMOL &&
    !isOrderLocked.isLocked &&
    !hideModifyOrderAction &&
    !["PARTNER_STORE_PICKUP", "MOL_CUSTOMER_PICKUP"].includes(deliveryMethod) &&
    (isActiveOrder ||
      (!isActiveOrder && (hasDSFulfillmentError || hasFulfillmentError)));

  const [lineItemInfo = {}, setLineItemInfo] = useStateIfMounted({});
  const [updatedCardMessage, setUpdatedCardMessage] =
    useStateIfMounted(cardMessage);

  const prevDeps = useRef([]);
  const [occasionsList, setOccasionsList] = useStateIfMounted([]);

  useEffect(() => {
    designerInfo.name?.length > 0 &&
      setFieldValue(`designerName`, designerInfo.name);

    request("get-occasions", {}, undefined, true).then((res) => {
      setOccasionsList((res && res.occasions) || []);
    });

    if (lineItems.length) {
      const { accessories = [] } = lineItems[0];
      accessories.length && !global.addons.length && dispatch(fetchAddons());
      accessories.length &&
        isOrderMOL &&
        accessories.map(({ accessoryId }) =>
          dispatch(
            fetchMOLAddons({
              accessoryId,
              sourceMemberCode: isOrderMOL
                ? sendingMember?.originalSendingMemberCode
                : sourceMemberCode,
            })
          )
        );
    }

    !global.addons.length && dispatch(fetchAddons());

    return () => {
      dispatch(resetCurrentProduct());
    };
  }, []);

  useEffect(() => {
    if (isEmpty(currentproduct) || lineItems.length === 0) return;

    const lineItemIds = lineItems.map((each) =>
      trimSpecialChars(each.productFirstChoiceCode?.toLowerCase(), ".")
    );

    const isProductAvailable =
      lineItemIds.includes(
        trimSpecialChars(currentproduct.refNumberId?.toLowerCase())
      ) || lineItemIds.includes(currentproduct.productId?.toLowerCase());
    if (isProductAvailable) {
      let product = cloneDeep(currentproduct);
      setLineItemInfo({
        ...lineItemInfo,
        [product.productId?.toLowerCase()]: product,
      });
    }
  }, [currentproduct]);

  useEffect(() => {
    if (isEmpty(currentproduct) || lineItems.length === 0) return;
    let productsInfo = {};

    lineItems.map((each) => {
      const productId = each.productFirstChoiceCode?.toLowerCase() || "";
      const isProductAvailable = selectedproducts.filter((prod) =>
        [
          prod.refNumberId?.toLowerCase(),
          prod.productId?.toLowerCase(),
        ].includes(productId)
      );
      if (isProductAvailable.length > 0) {
        let product = cloneDeep(isProductAvailable[0]);
        productsInfo = {
          ...productsInfo,
          [product.productId?.toLowerCase()]: product,
        };
      }
    });
    setLineItemInfo(productsInfo);
  }, [selectedproducts]);

  useEffect(() => {
    if (lineItems.length) {
      const products = multiProductEligible
        ? lineItems.filter((lineItem) => lineItem.type === "Product")
        : lineItems;

      const addons = multiProductEligible
        ? lineItems.filter((lineItem) => lineItem.type === "Addon")
        : lineItems;

      products.map((each) => {
        const isSame = isEqual(each, prevDeps.current[0]);
        if (!prevDeps.current.length || !isSame) {
          const productId = each.productFirstChoiceCode;
          productId
            ? setTimeout(() => {
                dispatch(
                  fetchProduct({
                    productId: productId,
                    orderSource:
                      orderSource === "FLORIST" && isLocalOrder
                        ? "LOCAL"
                        : orderSource,
                    siteId: isOrderMOL
                      ? sendingMember?.originalSendingMemberCode
                      : sourceMemberCode,
                  })
                );
              }, 100)
            : setLineItemInfo({});
        }
        prevDeps.current = lineItems;
      });

      addons.map((each) => {
        const addonId = each.productFirstChoiceCode;
        const addOnInfo = fetchAddonInfo(addonId, global) || {};

        isEmpty(addOnInfo) &&
          dispatch(
            fetchlocalAddons({
              addonId: addonId,
              orderSource: "LOCAL",
              siteId: sourceMemberCode,
            })
          );
      });
    } else {
      setLineItemInfo({});
    }
  }, [lineItems]);

  const { cardSettings: cardSettingsText = "{}" } = cardInfo;
  const cardSettings = JSON.parse(cardSettingsText);

  const { isLogoOpted, isBannerOpted, logoImageURL, bannerImageURL } =
    cardSettings;
  let imageUrl = "";

  if (isLogoOpted) {
    imageUrl = logoImageURL;
  } else if (isBannerOpted) {
    imageUrl = bannerImageURL;
  }

  const imageData = imagesData[imageUrl];
  const fileInfo = {
    uri: imageData ? `data:image/png;base64,${imageData}` : "",
    name: imageUrl,
  };

  const isFolMolOrder = orderSource === "FOL" || isOrderMOL;

  const relatedMessages = filter(
    messages,
    (message) =>
      message.messageType === "Price" ||
      (message.messageType === "PriceApproval" &&
        message?.requestStatus !== "DENIED")
  );

  const latestMessage = last(relatedMessages);
  const {
    direction,
    requestStatus,
    price: newPrice,
    id: askId,
  } = latestMessage || {};
  const priceChangeInfo = { value: newPrice, askId };

  if (direction === "INBOUND" && requestStatus === "WAITING")
    priceChangeInfo.status = "APPROVE";
  else if (direction === "OUTBOUND" && requestStatus === "WAITING")
    priceChangeInfo.status = "PENDING APPROVAL";
  else priceChangeInfo.status = requestStatus;

  const showRequestNewPriceLink =
    priceChangeInfo.status !== "APPROVE" &&
    !["FORFEITED", "CANCELLED", "REJECTED"].includes(status) &&
    !isPickUpOrder &&
    !isFolMolOrder &&
    supportPriceChange;

  const [showRequestNewPriceForm, setShowRequestNewPriceForm] =
    useStateIfMounted(false);

  const requestNewPrice = ({ itemPrice }) => {
    const requestType = "price";

    const orderReq = getRequestObj(
      orderDetails,
      requestType,
      itemPrice,
      deliveryDate,
      `Please approve for $${itemPrice}`,
      "Price Adjustment"
    );

    triggerRequest({ requestType, orderReq });
  };

  const requestNewPriceAction = (actionType, askId, newPrice, formikBag) => {
    triggerAction({
      action: actionType,
      price: newPrice,
      askId: askId,
      formikBag,
    });
  };

  const occasionLabel = getOccasionLabel(occasionsList, occasion);

  if (!showProductInfo) return null;

  return (
    <View
      style={{
        zIndex: 5,
      }}
      pointerEvents={doNotFill ? "none" : "auto"}
    >
      <View>
        <View>
          <View style={{ zIndex: -1 }}>
            <View style={[tw("flex flex-row")]}>
              <Text
                style={[
                  tw("pt-3 pb-2"),
                  {
                    fontSize: 15,
                    fontWeight: "bold",
                    fontFamily: fonts.fontFamily.default,
                    color: colors.primary,
                    paddingVertical: 2,
                  },
                ]}
                testID={"order_details_selected_products"}
              >
                {Localise(messages, "Products")}
              </Text>
              {showEditProductLink && (
                <TouchableOpacity
                  style={{
                    marginLeft: "auto",
                    paddingTop: 12,
                    opacity: 1,
                    pointerEvents: "auto",
                  }}
                  onPress={() => {
                    dispatch(setEditProducts(true));

                    navigation.dispatch(
                      CommonActions.navigate({
                        name: "create-order",
                        params: {
                          id: orderItemId,
                          action: "edit",
                          smc: sourceMemberCode,
                          dm: deliveryMethod,
                        },
                      })
                    );

                    dispatch(setCurrentPage("create-order"));
                  }}
                  testID="edit_product"
                  accessibilityLabel="edit_product"
                >
                  <Tooltip
                    text={Localise(messages, "Edit Product, Add on")}
                    renderForWebOnly={true}
                  >
                    <Text style={{ ...fonts.link1, fontWeight: "400" }}>
                      {Localise(messages, "Edit Product")}
                    </Text>
                  </Tooltip>
                </TouchableOpacity>
              )}
            </View>

            <SelectedProductDetails
              index={index}
              lineItemInfo={lineItemInfo}
              global={global}
              occasion={occasionLabel}
            />

            <View>
              <View style={tw("flex flex-row flex-wrap items-center my-2")}>
                <Text
                  style={{
                    width: isMobile ? "40%" : "25%",
                    fontSize: 15,
                    color: colors.primary,
                    fontWeight: "bold",
                    paddingVertical: 2,
                  }}
                >
                  {Localise(locMessages, "Order Price")}
                </Text>
                <Text
                  style={{
                    paddingLeft: 0,
                    fontSize: 15,
                    color: colors.primary,
                    fontWeight: "bold",
                    paddingVertical: 2,
                  }}
                >
                  ${formattedPrice}
                </Text>
                {showRequestNewPriceLink && !showRequestNewPriceForm && (
                  <>
                    <TouchableOpacity
                      style={tw("pl-2")}
                      onPress={() => setShowRequestNewPriceForm(true)}
                      testID="new_price"
                      accessibilityLabel="new_price"
                    >
                      <Tooltip
                        text={Localise(messages, "Request new price")}
                        renderForWebOnly={true}
                      >
                        <Image
                          style={{ width: 25, height: 25 }}
                          resizeMode="cover"
                          source={IMAGES["dollar-rounded"]}
                        />
                      </Tooltip>
                    </TouchableOpacity>
                  </>
                )}
              </View>

              {showRequestNewPriceForm && (
                <>
                  <View
                    style={[
                      tw(
                        `flex flex-${
                          isMobile ? "col my-5" : "row my-1"
                        } flex-wrap items-${
                          Platform.OS === "ios" ? "start" : "baseline"
                        }`
                      ),
                      isMobile ? { width: "100%" } : { zIndex: 1 },
                    ]}
                  >
                    <Form
                      initialValues={{
                        itemPrice: formattedPrice,
                        addonsTotalPrice: formatPrice(addonsTotalPrice),
                      }}
                      validationSchema={getValidationSchema(
                        Localise,
                        locMessages
                      )}
                      onSubmit={(values, formikBag) => {
                        if (formikBag.isSubmitting) return;
                        requestNewPrice(values);
                      }}
                      render={({ isSubmitting }) => {
                        return (
                          <>
                            <Text
                              style={{
                                ...fonts.style1,
                                width: isMobile ? "40%" : "25%",
                                fontSize: 15,
                                color: colors.primary,
                                fontWeight: "bold",
                              }}
                            >
                              {Localise(locMessages, "New Price")}
                            </Text>

                            <FormField
                              autoCapitalize="none"
                              autoCorrect={false}
                              name="itemPrice"
                              placeholder="New Price"
                              containerStyle={{
                                width: 85,
                                paddingLeft: 0,
                                marginTop: 5,
                              }}
                              isPrice={true}
                              keyboardType="numeric"
                              iconName="usd"
                              iconSize={12}
                              iconType="font-awesome"
                              isUpdateOnChange={true}
                              maxLength={10}
                              editable={true}
                              grayedOutOnDisable={false}
                            />

                            <View style={[tw(`flex flex-1 flex-row`)]}>
                              <SubmitButton
                                title={"Request"}
                                containerStyle={{ margin: 5 }}
                                buttonStyle={{
                                  paddingVertical: 8,
                                  paddingHorizontal: 20,
                                  marginBottom: 3,
                                }}
                                isLoading={isSubmitting}
                                testID={"request_price_change_request"}
                                accessibilityLabel={
                                  "request_price_change_request"
                                }
                              />

                              {!isSubmitting && (
                                <Button
                                  title={Localise(locMessages, "Cancel")}
                                  titleStyle={{
                                    ...theme.Button.secondaryTitleStyle,
                                    fontSize: 12,
                                  }}
                                  buttonStyle={{
                                    ...theme.Button.secondaryButtonStyle,
                                    paddingVertical: 8,
                                    paddingHorizontal: 20,
                                    marginBottom: 3,
                                  }}
                                  containerStyle={{
                                    margin: 5,
                                    justifyContent: "center",
                                  }}
                                  onPress={() => {
                                    setShowRequestNewPriceForm(false);
                                  }}
                                  testID={"cancel_price_change_request"}
                                  accessibilityLabel={
                                    "cancel_price_change_request"
                                  }
                                />
                              )}
                            </View>
                          </>
                        );
                      }}
                    />
                  </View>
                </>
              )}

              {priceChangeInfo.status && (
                <View
                  style={tw(
                    `flex ${
                      isMobile
                        ? "flex-col items-start my-5"
                        : "flex-row items-center my-2"
                    } flex-wrap`
                  )}
                >
                  <Form
                    initialValues={{
                      requestedPrice: formatPrice(priceChangeInfo.value),
                      isDenyClicked: false,
                    }}
                    onSubmit={(_, formikBag) => {
                      if (formikBag.isSubmitting) return;
                      setShowRequestNewPriceForm(false);

                      requestNewPriceAction(
                        "price-approval",
                        priceChangeInfo.askId,
                        priceChangeInfo.value,
                        formikBag
                      );
                    }}
                    render={({
                      isSubmitting,
                      setFieldValue,
                      values,
                      ...formikBag
                    }) => (
                      <>
                        <Text
                          style={{
                            ...fonts.style1,
                            width: isMobile ? "40%" : "25%",
                            fontSize: 15,
                            color: colors.primary,
                            fontWeight: "bold",
                          }}
                          testID="newPriceApproveTitle"
                        >
                          {Localise(locMessages, "New Price")}
                        </Text>
                        <Text
                          style={{
                            paddingLeft: 0,
                            fontSize: 15,
                            color: colors.primary,
                            fontWeight: "bold",
                            paddingVertical: 2,
                          }}
                          testID="requestedPrice"
                        >
                          ${formatPrice(priceChangeInfo.value)}
                        </Text>
                        {priceChangeInfo.status === "APPROVE" ? (
                          <>
                            <View style={[tw(`flex flex-1 flex-row pl-2`)]}>
                              {!(values?.isDenyClicked ?? false) && (
                                <SubmitButton
                                  title={"Approve"}
                                  isLoading={isSubmitting}
                                  containerStyle={{ margin: 5 }}
                                  buttonStyle={{
                                    paddingVertical: 8,
                                    paddingHorizontal: 20,
                                    marginBottom: 3,
                                  }}
                                  testID={"approve_price_change_request"}
                                  accessibilityLabel={
                                    "approve_price_change_request"
                                  }
                                />
                              )}

                              {!isSubmitting && (
                                <Button
                                  title={Localise(locMessages, "Deny")}
                                  titleStyle={{
                                    ...theme.Button.secondaryTitleStyle,
                                    fontSize: 12,
                                  }}
                                  buttonStyle={{
                                    ...theme.Button.secondaryButtonStyle,
                                    paddingVertical: 8,
                                    paddingHorizontal: 20,
                                    marginBottom: 3,
                                  }}
                                  containerStyle={{
                                    margin: 5,
                                    justifyContent: "center",
                                  }}
                                  loading={values?.isDenyClicked ?? false}
                                  loadingProps={{
                                    color: colors.highlighter,
                                  }}
                                  onPress={() => {
                                    setFieldValue("isDenyClicked", true);
                                    setShowRequestNewPriceForm(false);

                                    requestNewPriceAction(
                                      "price-deny",
                                      priceChangeInfo.askId,
                                      priceChangeInfo.value,
                                      { ...formikBag, setFieldValue }
                                    );
                                  }}
                                  testID={"deny_price_change_request"}
                                  accessibilityLabel={
                                    "deny_price_change_request"
                                  }
                                />
                              )}
                            </View>
                          </>
                        ) : (
                          <Text
                            style={{ paddingLeft: 10, fontStyle: "italic" }}
                          >
                            {Localise(
                              locMessages,
                              startCase(toLower(priceChangeInfo.status))
                            )}
                          </Text>
                        )}
                      </>
                    )}
                  />
                </View>
              )}
            </View>
          </View>
        </View>
        {specialInstructions !== "" && (
          <View style={[tw("flex my-2"), { zIndex: -10 }]}>
            <FormField
              autoCapitalize="none"
              autoCorrect={false}
              name={`specialInstructions`}
              value={specialInstructions}
              placeholder=""
              label={Localise(locMessages, "Special Instructions")}
              labelStyle={{
                fontSize: 15,
                fontWeight: "bold",
                color: colors.primary,
                paddingBottom: 8,
              }}
              containerStyle={{
                width: "100%",
                paddingHorizontal: 0,
                paddingVertical: 5,
              }}
              multiline={true}
              errorStyle={{ paddingBottom: 0 }}
              editable={false}
              numberOfLines={3}
              maxNumberOfLines={5}
              isUpdateOnChange={true}
            />
          </View>
        )}
        <View>
          <Text
            style={{
              fontSize: 15,
              color: colors.primary,
              fontWeight: "bold",
              paddingTop: 10,
            }}
            testID="enclosure_card_title"
          >
            {Localise(locMessages, "Enclosure Card")}
          </Text>
          <View style={tw("w-full")}>
            <EnclosureCard
              updatedCardMessage={updatedCardMessage}
              setUpdatedCardMessage={(message = "") => {
                setFieldValue(`${deliveryInfoPath}.cardMessage`, message);
                setUpdatedCardMessage(message);
              }}
              fileInfo={fileInfo}
              cardSettings={cardSettings}
              isSmallScreen={isSmallScreen}
              isPickUpOrder={isPickUpOrder}
              orderSource={orderSource}
              receivingMember={receivingMember}
              recipientInfo={recipientInfo}
              sourceMemberCode={sourceMemberCode}
              pickUpBy={pickUpBy}
              occasion={occasion}
              recordId={orderItemId}
              deliveryMethod={deliveryMethod}
              values={values}
              triggerAction={triggerAction}
              imageData={imageData}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default ProductDetails;
