import React, { useEffect, useCallback, useContext } from "react";
import { ActivityIndicator, View } from "react-native";
import { Image, Input, Text } from "react-native-elements";
import { useSelector } from "react-redux";

import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import debounce from "lodash/debounce";

import {
  Form,
  FormField,
  FormFieldPicker,
  SubmitButton,
} from "components/elements/forms";
import { Accordion, FieldSet, Maps } from "components/elements";

import useStateIfMounted from "library/utils/useStateIfMounted";
import {
  selectShopCode,
  selectSideCar,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import I18NContext from "library/contexts/i18N";
import { formatData, getMembersInfo } from "library/utils/createOrder";
import { request } from "library/utils/request";
import { DeviceContext } from "library/contexts/appSettings";

import { fonts, backgroundColors, colors, theme } from "styles/theme";
import tw from "tailwind-rn";
import IMAGES from "static/assets/images";

import { PreferencesList } from "./config";

const generateContent = (data) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { messages, Localise } = useContext(I18NContext);

  return Object.keys(data).map((key) => {
    const value = data[key];
    const dataTestId = key?.toLowerCase()?.trim()?.replace(/ /g, "_");
    return (
      <View
        style={tw("flex flex-row py-1")}
        key={key}
        fsClass={
          key === "Contact Phone" || key === "Phone"
            ? "fs-exclude"
            : "fs-unmask"
        }
      >
        <Text style={{ ...fonts.heading6, width: "40%" }}>
          {Localise(messages, key)}
        </Text>
        {typeof value !== "string" ? (
          <View
            style={{ width: "60%" }}
            testID={dataTestId}
            accessibilityLabel={dataTestId}
          >
            {!!value &&
              Object.keys(value).map((key) => (
                <Text style={[fonts.style1, tw("pb-1")]} key={`${key}-value`}>
                  {value[key]}
                </Text>
              ))}
          </View>
        ) : (
          <Text
            style={{ ...fonts.style1, width: "60%" }}
            testID={dataTestId}
            accessibilityLabel={dataTestId}
          >
            {value}
          </Text>
        )}
      </View>
    );
  });
};

// eslint-disable-next-line react/display-name
const SavePreferences = React.memo(
  ({
    floristNotes,
    memberDisplayData,
    partnerPreference,
    shopCode,
    submitPreferences,
    targetMemberCode,
  }) => {
    const { messages, Localise } = useContext(I18NContext);

    return (
      <Form
        initialValues={{
          preference: partnerPreference.toLocaleLowerCase(),
          floristNotes: floristNotes || "",
          memberCode: shopCode,
          targetMemberCode: targetMemberCode,
        }}
        onSubmit={(values, formikBag) => {
          submitPreferences(values, formikBag);
        }}
        render={() => (
          <>
            <View style={tw("pt-3 pr-3 pl-3")}>
              <View style={tw("flex flex-row py-1 items-center")}>
                <Text style={{ ...fonts.heading6, width: "40%" }}>
                  {Localise(messages, "Partner Preference")}
                </Text>
                <View style={{ width: "60%" }}>
                  <FormFieldPicker
                    placeholder={{ label: "Select a preference..." }}
                    containerStyle={{
                      width: "100%",
                    }}
                    data={PreferencesList}
                    name="preference"
                    label=""
                  />
                </View>
              </View>
            </View>
            <View style={tw("pt-1 pr-3 pb-3 pl-3")}>
              {generateContent(memberDisplayData)}
            </View>
            <View style={tw("p-3")}>
              <FormField
                inputStyle={{ height: 80 }}
                containerStyle={{
                  width: "100%",
                  marginLeft: -5,
                  padding: 0,
                  margin: 0,
                }}
                placeholder="Add florist to address book"
                label="Florist Notes"
                name="floristNotes"
                multiline={true}
                numberOfLines={3}
              />
            </View>
            <SubmitButton
              containerStyle={{ flexDirection: "row-reverse" }}
              title="Submit"
            />
          </>
        )}
      />
    );
  }
);

// eslint-disable-next-line react/display-name
const FloristHeaderContent = React.memo(
  ({
    index,
    title,
    isDigitalAdAvailable,
    isMasterFlorist,
    isPremierFlorist,
    openAccordion,
    partnerPreference,
    unavailabilityReason,
    memberCode,
  }) => {
    const { messages, Localise } = useContext(I18NContext);

    return (
      <View
        style={[
          theme.Accordion.titleContainer,
          {
            flex: 1,
            justifyContent: "space-between",
          },
        ]}
        testID={`floristPartner_${memberCode}`}
      >
        <View
          style={{
            flexDirection: "row",
            flex: 1,
            flexWrap: "wrap",
            alignItems: "center",
            marginRight: 10,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
            }}
          >
            {!!index && (
              <View
                style={{
                  height: 25,
                  width: 25,
                  backgroundColor: "#3A7D8D",
                  borderRadius: 15,
                  marginRight: 10,
                }}
              >
                <Text
                  style={{
                    color: "white",
                    textAlign: "center",
                    lineHeight: 25,
                  }}
                >
                  {index}
                </Text>
              </View>
            )}
            <View
              style={{
                flexDirection: "row",
                flexShrink: 1,
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  ...theme.Accordion.titleStyle,
                  ...theme.Accordion.secondaryTitleStyle,
                  ...{ ...fonts.heading6, color: colors.primary },
                  ...{ flex: 1, flexWrap: "wrap" },
                }}
                numberOfLines={4}
              >
                {title}
              </Text>
            </View>
            {isMasterFlorist && (
              <Image
                style={{
                  width: 35,
                  height: 35,
                  marginLeft: 20,
                }}
                source={IMAGES["master-florist"]}
              />
            )}
            {isPremierFlorist && (
              <Image
                style={{
                  width: 35,
                  height: 35,
                  marginLeft: 20,
                }}
                source={IMAGES["premier-florist"]}
              />
            )}
            {isDigitalAdAvailable && (
              <Image
                style={{
                  width: 35,
                  height: 35,
                  marginLeft: 20,
                }}
                source={IMAGES["florist-ad"]}
              />
            )}
          </View>
        </View>
        <View style={{ flexDirection: "row", height: 18 }}>
          {!!unavailabilityReason && partnerPreference !== "DO_NOT_USE" && (
            <Text style={theme.Accordion.errorStyle}>
              {unavailabilityReason}
            </Text>
          )}
          {partnerPreference === "DO_NOT_USE" && (
            <Text style={theme.Accordion.errorStyle}>
              {Localise(messages, "Do Not Use")}
            </Text>
          )}
          <Image
            style={{
              width: 20,
              height: 20,
            }}
            source={
              IMAGES[openAccordion ? "expanded-section" : "collapsed-section"]
            }
          />
        </View>
      </View>
    );
  }
);

// eslint-disable-next-line react/display-name
export const FloristListItem = React.memo(
  ({
    florist = {},
    index,
    isShopSelected,
    isSmallScreen,
    shopCode,
    submitPreferences,
  }) => {
    const { basicInfo, memberDisplayData, memberInfoData } = florist;
    const listDisplayValues = ["name", "memberCode", "minPrice", "phone"];

    const { phone, ...other } = basicInfo;
    const { memberCode: targetMemberCode } = other;
    const {
      fieldSetLabel,
      floristNotes,
      imageData,
      isAvailable,
      isDigitalAdAvailable,
      isMasterFlorist,
      isPremierFlorist,
      partnerPreference,
      unavailabilityReason,
    } = memberInfoData;

    const { messages, Localise } = useContext(I18NContext);

    return (
      <FieldSet label={Localise(messages, fieldSetLabel)}>
        <Accordion
          labelStyle={{
            backgroundColor: "white",
            marginTop: 0,
            paddingHorizontal: 5,
            marginHorizontal: 5,
            height: "auto",
          }}
          closeStyle={{ borderBottomWidth: 0 }}
          headerContent={(openAccordion) => (
            <FloristHeaderContent
              isAvailable={isAvailable}
              isDigitalAdAvailable={isDigitalAdAvailable}
              isMasterFlorist={isMasterFlorist}
              isPremierFlorist={isPremierFlorist}
              index={index + 1}
              floristNotes={floristNotes}
              openAccordion={openAccordion}
              partnerPreference={partnerPreference}
              title={formatData(other, listDisplayValues, "space")}
              unavailabilityReason={unavailabilityReason}
              memberCode={targetMemberCode}
            />
          )}
          iconStyle={{ fontSize: 12, color: colors.primary }}
          defaultOpen={isShopSelected ? true : false}
          handleOnPress={() => {}}
          contentStyle={{
            backgroundColor: "white",
            borderWidth: 0,
            paddingVertical: 10,
            paddingHorizontal: 10,
          }}
          isAccessible={true}
        >
          {isMasterFlorist && (
            <View
              style={{
                flexDirection: "row",
                marginVertical: 15,
              }}
            >
              <Image
                style={{
                  width: 75,
                  height: 75,
                  marginLeft: 20,
                }}
                source={IMAGES["master-florist"]}
              />
            </View>
          )}
          {isPremierFlorist && (
            <View
              style={{
                flexDirection: "row",
                marginVertical: 15,
              }}
            >
              <Image
                style={{
                  width: 75,
                  height: 75,
                  marginLeft: 20,
                }}
                source={IMAGES["premier-florist"]}
              />
            </View>
          )}
          {isDigitalAdAvailable && (
            <Image
              containerStyle={{ flex: 1 }}
              style={{ height: 260 }}
              resizeMode="contain"
              source={{
                uri: `data:image/png;base64,${imageData}`,
              }}
            />
          )}
          <SavePreferences
            floristNotes={floristNotes}
            isSmallScreen={isSmallScreen}
            memberDisplayData={memberDisplayData}
            partnerPreference={partnerPreference}
            shopCode={shopCode}
            submitPreferences={submitPreferences}
            targetMemberCode={targetMemberCode}
          />
        </Accordion>
      </FieldSet>
    );
  }
);

const PreferencesSearch = ({ setRefreshPreferences }) => {
  const shopCode = useSelector(selectShopCode);
  const { type: showSideCar, data: selectedShop } = useSelector(selectSideCar);
  const { messages, Localise } = useContext(I18NContext);
  const { isDesktop } = useContext(DeviceContext);

  const [loading, setLoading] = useStateIfMounted(false);
  const [searchQuery, setSearchQuery] = useStateIfMounted("");
  const [florists, setFlorists] = useStateIfMounted(
    !isEmpty(selectedShop) ? [selectedShop] : ""
  );
  const [locations, setLocations] = useStateIfMounted();
  const [searchQueryText, setSearchQueryText] = useStateIfMounted("");
  const [totalRecords, setTotalRecords] = useStateIfMounted(0);
  const isSmallScreen = !isDesktop;
  const isShopSelected = !isEmpty(selectedShop);

  const getFloristsBySearch = ({ userQuery }) => {
    setLoading(true);
    request("get-preferences-search", {
      shopCode: shopCode,
      userQuery: userQuery,
    })
      .then((res) => {
        const { membersInfo, shopLocations, searchResultsCount } =
          getMembersInfo((res && res) || [], false, true);
        setFlorists(membersInfo);
        setLocations(shopLocations);
        setLoading(false);
        setTotalRecords(searchResultsCount);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const userQuery = searchQuery.trim();
    if (userQuery.trim().length >= 3) {
      getFloristsBySearch({ userQuery: userQuery });
      setSearchQueryText(
        `${Localise(messages, "Search results for")} : ${userQuery}`
      );
    } else {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!userQuery) {
        setSearchQueryText(
          `${Localise(messages, "Search results for")} : ${userQuery}`
        );
      } else {
        setSearchQueryText("");
      }
    }
    showSideCar === "search_florist_partners" && setFlorists([]);
  }, [searchQuery, showSideCar]);

  useEffect(() => {
    if (isShopSelected) {
      const { memberInfoData } = selectedShop;
      const { latitude = "", longitude = "" } = memberInfoData;
      const locations = [
        {
          latitude: parseFloat(latitude || 0),
          longitude: parseFloat(longitude || 0),
        },
      ];
      setSearchQuery("");
      setSearchQueryText("");
      setLocations({ locations: locations });
    }
    !searchQuery &&
      showSideCar === "florist_partner_details" &&
      setFlorists([selectedShop]);
  }, [selectedShop]);

  const submitPreferences = useCallback((values, formikBag) => {
    request("save-florist-preferences", values)
      .then((res) => {
        formikBag.setSubmitting(false);
        setRefreshPreferences(true);
      })
      .catch(() => {
        formikBag.setSubmitting(false);
      });
  });

  const { isTablet } = useContext(DeviceContext);

  const isZeroLocation =
    !isEmpty(locations) &&
    get(locations, "locations", []).length === 1 &&
    get(locations, "locations.0.latitude", "") === 0;

  return (
    <View style={{ ...(isTablet && { padding: 10 }) }}>
      <View style={!isSmallScreen ? tw("p-3") : tw("p-1")}>
        {!isShopSelected && !!showSideCar && (
          <View style={{ zIndex: 1 }}>
            <SearchBox
              label={Localise(messages, "Florist Partner Search")}
              onSearch={setSearchQuery}
              placeholder={Localise(
                messages,
                "Search by Shop Code, Shop Name, City, Zip"
              )}
            />
            {!!searchQueryText && (
              <>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 5,
                    zIndex: -1,
                  }}
                >
                  <Text>{`${searchQueryText}`}</Text>
                  {!loading && !!totalRecords && (
                    <Text>{`${Localise(
                      messages,
                      "Total partners found:"
                    )} ${totalRecords}`}</Text>
                  )}
                </View>
                {!loading && !!totalRecords && totalRecords > 10 && (
                  <View
                    style={{
                      paddingVertical: 2,
                      paddingHorizontal: 5,
                    }}
                  >
                    <Text style={tw("italic")}>
                      {Localise(
                        messages,
                        "Enter more keywords to narrow search"
                      )}
                    </Text>
                  </View>
                )}
                {loading && (
                  <ActivityIndicator color={colors.activityIndicator} />
                )}
              </>
            )}
          </View>
        )}
        {(!loading || isShopSelected) && (
          <View>
            {florists.length > 0 &&
              florists.map((florist, index) => {
                return (
                  <FloristListItem
                    key={florist.basicInfo.memberCode}
                    florist={florist}
                    index={index}
                    isSmallScreen={isSmallScreen}
                    shopCode={shopCode}
                    submitPreferences={submitPreferences}
                    isShopSelected={isShopSelected}
                  />
                );
              })}
            {florists.length < 1 &&
              !!searchQueryText &&
              searchQuery.length > 2 && (
                <Text style={fonts.error}>
                  {`${Localise(messages, "No florists available for")} "${
                    searchQueryText?.split(
                      Localise(messages, "Search results for") + " : "
                    )[1]
                  }"`}
                </Text>
              )}
          </View>
        )}
      </View>
      {!!(
        !!showSideCar &&
        florists?.length > 0 &&
        !isEmpty(locations) &&
        !isZeroLocation &&
        (!loading || isShopSelected)
      ) && (
        <View
          style={[
            tw("my-5"),
            {
              height: 400,
              borderColor: backgroundColors.medium,
              borderWidth: 1,
              borderStyle: "solid",
              marginHorizontal: 5,
              zIndex: -1,
            },
          ]}
        >
          <Maps mapMarkers={locations} zoomLevel={8} />
        </View>
      )}
    </View>
  );
};

const SearchBox = ({ label, onSearch, placeholder }) => {
  const [userQuery, setUserQuery] = useStateIfMounted("");
  const queryFieldRef = React.createRef();

  const updateQuery = () => {
    onSearch(userQuery);
  };

  const delayedQuery = useCallback(debounce(updateQuery, 2000), [userQuery]); //adding 2 second delay to trigger search action

  const onChange = (val) => {
    setUserQuery(val);
  };

  useEffect(() => {
    delayedQuery();
    // Cancel the debounce on useEffect cleanup.

    return delayedQuery.cancel;
  }, [userQuery, delayedQuery]);

  useEffect(() => {
    queryFieldRef.current.focus();
  }, []);

  return (
    <Input
      label={label}
      onChangeText={onChange}
      value={userQuery || ""}
      placeholder={placeholder}
      ref={queryFieldRef}
      testID="search_preferences"
      accessibilityLabel="search_preferences"
    />
  );
};

export default React.memo(PreferencesSearch);
