import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as parentSlice } from "../constants";
import { SLICE_NAME as sliceName } from "./constants";
import isNumber from "lodash/isNumber";

export const initialState = {
  status: "",
  api: {},
  error: false,
  products: {
    current: {
      data: [],
      actions: {
        searchText: "",
        sortOrder: "",
        filters: [],
        manualSortOrder: "",
        bulkActionType: "",
        selectedProducts: [],
        productLimit: 0,
      },
      controls: {},
    },
    new: {
      data: [],
      actions: {
        searchText: "",
        selectedProducts: [],
        productLimit: 0,
      },
      controls: {},
    },
  },
  sideCar: "",
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: `view::${parentSlice}::${sliceName}`,
  initialState,
  reducers: {
    setInit: (state) => {
      state.status = "progress";
    },
    setReset: (state) => {
      Object.assign(state, initialState);
    },
    setAPIResponse: (state, action) => {
      const { content, patch } = action.payload;
      if (patch) {
        state.api = { ...state.api, ...patch };
      } else {
        const { MHQSortValue, ...data } = content;
        state.api = data;
        state.products.current.actions.sortOrder = MHQSortValue;
      }
      state.status = "done";
      state.error = false;
    },
    setAPIError: (state, action) => {
      state.status = "done";
      state.error = action.payload;
    },
    setProductsData: (state, action) => {
      const { section, data } = action.payload;
      state.products[section].data = data;
    },
    setProductsViewMore: (state, action) => {
      const { section, limit, actual } = action.payload;
      if (isNumber(actual))
        state.products[section].actions.productLimit = actual;
      else if (isNumber(limit))
        state.products[section].actions.productLimit =
          state.products[section].actions.productLimit + limit;
    },
    setProductsControl: (state, action) => {
      const { section, type, value } = action.payload;
      state.products[section].controls[type] = value;
    },
    setProductsAction: (state, action) => {
      const { section, type, value, extraValue } = action.payload;
      if (type === "search") state.products[section].actions.searchText = value;
      else if (type === "bulkActionType")
        state.products[section].actions.bulkActionType = value;
      else if (type === "sortOrder") {
        state.products[section].actions.sortOrder = value;
        state.products[section].actions.manualSortOrder = extraValue;
      } else if (type === "filters") {
        state.products[section].actions.filters = [
          ...new Set([].concat(...value)),
        ];
      }
    },
    resetProductsAction: (state, action) => {
      const { section, type, value = "" } = action.payload;

      if (type === "search") state.products[section].actions.searchText = value;
      else if (type === "bulkActionType")
        state.products[section].actions.bulkActionType = value;
      else if (type === "sortOrder") {
        state.products[section].actions.sortOrder = value;
        state.products[section].actions.manualSortOrder = "";
      }
    },
    setProductsSelection: (state, action) => {
      const { section, productId, forceAdd } = action.payload;

      if (productId === "all") {
        state.products[section].actions.selectedProducts =
          state.products[section].data;
      } else if (productId) {
        if (forceAdd) {
          state.products[section].actions.selectedProducts = [productId];
        } else if (
          state.products[section].actions.selectedProducts.includes(productId)
        ) {
          const index = state.products[
            section
          ].actions.selectedProducts.findIndex((pid) => pid === productId);
          state.products[section].actions.selectedProducts.splice(index, 1);
        } else {
          state.products[section].actions.selectedProducts = [
            ...state.products[section].actions.selectedProducts,
            productId,
          ];
        }
      } else {
        state.products[section].actions.selectedProducts = [];
      }
    },
    setSideCar: (state, action) => {
      state.sideCar = action.payload;
    },

    setQuickAction: () => {}, // from catalog dashboard
    fetchCollection: (state, action) => {
      const handle = action.payload;
      if (state.api.handle !== handle) {
        Object.assign(state, initialState);
        state.status = "progress";
      }
      state.error = false;
    },
    createCollection: () => {},
    updateCollection: () => {},
    patchCollection: () => {},
    revertCollectionToGlobal: () => {},
    deleteCollection: () => {},
  },
});

export const {
  setReset,
  setAPIResponse,
  setAPIError,
  setProductsData,
  setProductsAction,
  resetProductsAction,
  setProductsControl,
  setProductsViewMore,
  setProductsSelection,
  setQuickAction,
  setSideCar,
  fetchCollection,
  createCollection,
  updateCollection,
  patchCollection,
  deleteCollection,
  revertCollectionToGlobal,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
