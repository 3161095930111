import React from "react";
import { Section } from "../../helper";

const Statements = ({ UIConfig }) => {
  const { sections } = UIConfig;

  return sections.map(
    ({ section, component: { name: Component, props } }, index) => {
      return (
        <Section title={section} key={index}>
          <Component UIConfig={props} sectionName={section} />
        </Section>
      );
    }
  );
};

export default Statements;
