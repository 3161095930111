/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import { Input, Icon, Image } from "react-native-elements";
import get from "lodash/get";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { theme, colors, backgroundColors, fonts } from "styles/theme";
import { Platform, TouchableOpacity, Text, View } from "react-native";
import I18NContext from "library/contexts/i18N";

const FormField = ({
  name,
  containerStyle = {},
  width,
  iconName,
  iconType = "simple-line-icon",
  iconPosition = true,
  onIconPress = () => {},
  iconSize = 20,
  label,
  handleOnFocus,
  handleOnBlur,
  path,
  isUpdateOnChange = false,
  isPrice = false,
  transformText, // we are using this prop to update form field data dynamically when user click on enter key or spacebar
  editable = true,
  grayedOutOnDisable = false,
  multiline = false,
  numberOfLines = 1,
  maxNumberOfLines = numberOfLines,
  secureTextEntry = false,
  placeholder,
  image,
  useFormikValue = false,
  inputContainerStyle = {},
  inputStyle = {},
  isRequired,
  // eslint-disable-next-line
  inputRef = React.useRef(),
  currency,
  showRemainingChars = false,
  maxLength,
  onChange,
  ...otherProps
}) => {
  const { setFieldTouched, errors, touched, values, setFieldValue } =
    useFormikContext();
  const { messages, Localise } = React.useContext(I18NContext);

  const key = (path && `${path}.${name}`) || name;
  const touchedVal = get(touched, key);
  const errorMsg = get(errors, key);
  const fieldVal = get(values, key) || "";
  const [text, setText] = useStateIfMounted(fieldVal);
  const getNoOfLines = (str) => {
    let newLinesCount = numberOfLines;

    if (multiline) {
      const linesCount = str.split(/\r\n|\r|\n/).length;

      if (linesCount >= numberOfLines && linesCount <= maxNumberOfLines) {
        newLinesCount = linesCount;
      } else if (linesCount < numberOfLines) {
        newLinesCount = numberOfLines;
      } else if (linesCount > maxNumberOfLines) {
        newLinesCount = maxNumberOfLines;
      }
    }
    return newLinesCount;
  };

  const [currentNumberOfLines, setCurrentNumberOfLines] = useStateIfMounted(
    getNoOfLines(fieldVal)
  );
  const [characterLimit, setCharacterLimit] = useStateIfMounted(
    maxLength - fieldVal?.length
  );

  useEffect(() => {
    if (text !== fieldVal) {
      setText(fieldVal);
      setCharacterLimit(maxLength - fieldVal?.length);
    }

    // setting default number of lines in order to adjust height after resetting form
    if (multiline) setCurrentNumberOfLines(getNoOfLines(fieldVal));
  }, [fieldVal]);

  const updateState = (val) => {
    /* If we pass transformText then we are comparing previous & current value to identify difference and if difference is starts with space
      then we are calling transformText callback. Usage: we are using this in create order screen to replace card message codes / shortcuts */

    const updatedText = transformText ? transformText(val, text, true) : val;

    !useFormikValue && setText(updatedText);

    if (isUpdateOnChange) {
      //Need to add price specific code if necessary. Currently its only for login screen.
      setFieldTouched(key);
      setFieldValue(key, updatedText);
    }

    if (multiline) setCurrentNumberOfLines(getNoOfLines(updatedText));
  };

  const updateFormik = () => {
    setFieldTouched(key);
    if (!isUpdateOnChange) {
      const updatedText = text
        ? transformText
          ? transformText(text)
          : isPrice
          ? !isNaN(text)
            ? parseFloat(text).toFixed(2)
            : text
          : text
        : text;
      setText(updatedText);
      setFieldValue(key, updatedText);
    }
    handleOnBlur && handleOnBlur(text);
  };

  const basicInputContainerStyle = {
    ...theme.Input.inputContainerStyle,
    ...inputContainerStyle,
    ...(errorMsg &&
      errorMsg.length &&
      touchedVal && { borderColor: colors.error }),
  };

  const multilineStyle = {
    ...basicInputContainerStyle,
    minHeight: "auto",
    height: "auto",
  };

  const { value: defaultValue } = otherProps;

  return useMemo(() => {
    return (
      <>
        {image && (
          <Image
            style={{ width: 45, height: 45, paddingHorizontal: 0 }}
            resizeMode="cover"
            source={image}
          />
        )}
        <Input
          name={name}
          inlineImageLeft={image}
          accessibilityLabel={name}
          testID={name}
          onBlur={updateFormik} // this event will tigger when user click on Enter or Tab key
          onChangeText={(val) => {
            updateState(val);
            if (showRemainingChars) {
              setCharacterLimit(maxLength - (val?.length || 0));
            }
          }}
          onChange={(e) => {
            onChange && onChange(e);
          }}
          maxLength={maxLength}
          containerStyle={containerStyle}
          inputStyle={
            multiline
              ? { ...theme.Input.inputStyle, minHeight: "auto", height: "auto" }
              : { ...theme.Input.inputStyle, width: "100%", ...inputStyle }
          }
          inputContainerStyle={
            multiline
              ? editable || !grayedOutOnDisable
                ? multilineStyle
                : {
                    ...multilineStyle,
                    backgroundColor: backgroundColors.greyColor,
                  }
              : editable || !grayedOutOnDisable
              ? basicInputContainerStyle
              : {
                  ...theme.Input.inputContainerStyle,
                  backgroundColor: backgroundColors.greyColor,
                  ...inputContainerStyle,
                }
          }
          width={width}
          label={
            isRequired
              ? `${Localise(messages, label)}*`
              : Localise(messages, label)
          }
          errorMessage={(touchedVal && errorMsg) || ""}
          onFocus={handleOnFocus}
          leftIcon={
            iconName && iconPosition ? (
              <Icon
                name={iconName}
                type={iconType}
                color="rgba(110, 120, 170, 1)"
                size={iconSize}
                onPress={onIconPress}
              />
            ) : undefined
          }
          rightIcon={
            currency ? (
              <Text style={fonts.default}> {currency}</Text>
            ) : iconName && !iconPosition ? (
              <TouchableOpacity
                onPressIn={() => (secureTextEntry ? onIconPress() : {})} // Only for password field in login page to handle long press
                onPress={onIconPress}
              >
                <Icon
                  name={iconName}
                  type={iconType}
                  color="rgba(110, 120, 170, 1)"
                  size={iconSize}
                />
              </TouchableOpacity>
            ) : undefined
          }
          value={!useFormikValue ? text : fieldVal}
          editable={editable}
          multiline={multiline}
          numberOfLines={Platform.OS === "ios" ? null : currentNumberOfLines}
          maxHeight={
            Platform.OS === "ios" && currentNumberOfLines
              ? 20 * currentNumberOfLines
              : null
          }
          secureTextEntry={secureTextEntry}
          placeholder={Localise(messages, placeholder)}
          ref={inputRef}
          {...otherProps}
        />
        {showRemainingChars && (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              paddingRight: 5,
              marginLeft: "auto",
            }}
            testID={`remaining_${name}_chars`}
            accessibilityLabel={`remaining_${name}_chars`}
          >
            <Text style={{ ...fonts.default }}>
              {Localise(
                messages,
                `${characterLimit} Characters / ${maxLength} Remaining`
              )}
            </Text>
          </View>
        )}
      </>
    );
  }, [
    text,
    touchedVal,
    errorMsg,
    editable,
    iconName,
    secureTextEntry,
    messages,
    label,
    placeholder,
    defaultValue,
    isRequired,
    maxLength,
    characterLimit,
  ]);
};

export default React.memo(FormField);
