import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserProfileStorage from "library/storage/userProfile";
import Environment from "library/utils/environment";
import { selectNestedScreenKey } from "library/sagas/views/home/drawer/delivery/selector";
import { setNestedScreenKey } from "library/sagas/views/home/drawer/delivery/slice";
import { screenList } from "./config";

//components
import TabNavigationScreen from "components/containers/tabs";

const CreateDSRequestData = ({ route }) => {
  const dispatch = useDispatch();
  const screenKey = useSelector(selectNestedScreenKey);
  const isBulkRequestVisible = Environment.get("SHOW_BULK_DS_REQUEST", false);
  let updatedScreenList = [...screenList];
  if (!isBulkRequestVisible) {
    updatedScreenList = screenList.filter((screen) => screen.key !== "bulk");
  }
  useEffect(() => {
    return () => {
      UserProfileStorage.setCurrentTab("");
      dispatch(setNestedScreenKey("single"));
    };
  }, []);
  return (
    <TabNavigationScreen
      title={"Delivery Service Request"}
      screenList={updatedScreenList}
      screenKey={screenKey}
      route={route}
      setSelectScreenKey={setNestedScreenKey}
    />
  );
};
export default CreateDSRequestData;
