import React, { useRef, useEffect } from "react";
import { Button } from "react-native-elements";
import * as DocumentPicker from "expo-document-picker";

import I18NContext from "library/contexts/i18N";
import { theme, fonts } from "styles/theme";

const FileSelector = ({
  accessibilityLabel,
  image,
  disabled,
  testID,
  onSelect,
  titleStyle,
  buttonStyle,
  containerStyle,
}) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const fileSelectRef = useRef(null);

  useEffect(() => {
    document.addEventListener("onMockFileSelect", handleMockSelect);

    return () => {
      document.removeEventListener("onMockFileSelect", handleMockSelect);
    };
  }, []);

  const handleMockSelect = (event) => {
    const { id, url } = event.detail;
    const { disabled, onPress } = fileSelectRef.current.props;
    id === testID && !disabled && onPress(url);
  };

  const imageUrlToBase64 = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();

    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  };

  const pickDocument = async (uri) => {
    let result = { type: "success" };
  
    if (uri) {
      const base64Image = await imageUrlToBase64(uri);
      result.uri = base64Image.startsWith("data:image")
        ? base64Image
        : `data:image/png;base64,${base64Image}`;
    } else {
      result = await DocumentPicker.getDocumentAsync({});
    }

    if (result.type === "success") {
      onSelect(result.uri);
    }
  };

  return (
    <Button
      testID={testID}
      ref={fileSelectRef}
      accessibilityLabel={accessibilityLabel}
      titleStyle={{
        ...fonts.heading5,
        ...theme.Button.secondaryTitleStyle,
        ...titleStyle,
      }}
      buttonStyle={{
        ...theme.Button.secondaryButtonStyle,
        ...buttonStyle,
      }}
      containerStyle={{ margin: 5, ...containerStyle }}
      title={
        image ? Localise(messages, "Replace") : Localise(messages, "Upload")
      }
      onPress={(url) => pickDocument(url)}
      disabled={disabled}
    />
  );
};

export default FileSelector;
