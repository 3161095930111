import React, { useMemo } from "react";
import { View } from "react-native";
import get from "lodash/get";
import { useSelector } from "react-redux";

import { getEligibleDeliveryMethods } from "../helper";

import { AppSettingsContext } from "library/contexts/appSettings";
import { Entitlements } from "library/utils/entitlements";
import { getUIConfig, quickSaleUIConfig } from "./helper";
import { selectApiResponse } from "library/sagas/ongoing/order-details/selector";
import {
  selectIsEditOrder,
  selectIsSubscription,
} from "library/sagas/views/home/drawer/create-order/selector";

const OrderItem = (props) => {
  const {
    index,
    isSmallScreen,
    orderInfo,
    setOrderPositions,
    orderPositions,
    setSideCarOpen,
    setScrollEnabled,
    setSelectedFlorist,
    addons,
    isAutoFill,
    sideCarKey,
    memberCodes,
    sendingMemberCode,
    isQuickSaleEnabled,
    canModifyOrder,
    isMultiOrderEligible,
    actualDeliveryMethod = "",
    submitCount = 0,
  } = props;
  const { permissions = {} } = React.useContext(AppSettingsContext);
  const orderDetailsResponse = useSelector(selectApiResponse);
  const isEditOrder = useSelector(selectIsEditOrder);
  const isSubscription = useSelector(selectIsSubscription);

  const orderDetails = get(orderDetailsResponse, `orderItems.0`, {});

  const selectedShopPermissions = get(permissions, sendingMemberCode, {});
  const isPaymentEntitlementEnabled = selectedShopPermissions[
    Entitlements.CREATE_ORDER
  ]?.includes(Entitlements.CREATE_ORDER_PAGE.PAYMENT_SECTION);

  const { deliveryInfo: { deliveryMethod = "" } = {} } = orderInfo;
  const { direction = {} } = orderDetails;
  const eligibleDeliveryMethods = useMemo(() => {
    let methods = getEligibleDeliveryMethods(
      permissions,
      sendingMemberCode,
      isEditOrder
    );

    if (isEditOrder) {
      methods = methods.map((option) => {
        if (
          option.value === "FLORIST_PARTNER" &&
          deliveryMethod === "FLORIST_PARTNER" &&
          direction === "INBOUND"
        ) {
          return { ...option, label: "Florist Delivered" };
        }
        return option;
      });
    }

    return methods;
  }, [permissions, sendingMemberCode, isEditOrder, deliveryMethod, direction]);

  const UIConfig = getUIConfig(isEditOrder, isSubscription);
  const UIConfigValues = isQuickSaleEnabled ? quickSaleUIConfig : UIConfig;

  return (
    <>
      <View
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          orderPositions[index] = layout.y;
          setOrderPositions([...orderPositions]);
        }}
      >
        <View>
          {Object.keys(UIConfigValues).map((key) => {
            const {
              title,
              component: Content,
              overrideZindex = 0,
            } = UIConfigValues[key];

            const contentComponent = (
              <Content
                isSmallScreen={isSmallScreen}
                key={key}
                index={index}
                title={title}
                setSideCarOpen={setSideCarOpen}
                orderInfo={orderInfo}
                setSelectedFlorist={setSelectedFlorist}
                setScrollEnabled={setScrollEnabled}
                setOrderPositions={setOrderPositions}
                addons={addons}
                isAutoFill={isAutoFill}
                sideCarKey={sideCarKey}
                memberCodes={memberCodes}
                eligibleDeliveryMethods={eligibleDeliveryMethods}
                sendingMemberCode={sendingMemberCode}
                isPaymentEntitlementEnabled={isPaymentEntitlementEnabled}
                canUpdateDeliveryMethod={!isEditOrder}
                canModifyOrder={canModifyOrder}
                isMultiOrderEligible={isMultiOrderEligible}
                actualDeliveryMethod={actualDeliveryMethod}
                submitCount={submitCount}
              />
            );

            return (
              <View
                style={{
                  zIndex: overrideZindex ? overrideZindex : -1,
                }}
                key={key}
              >
                {contentComponent}
              </View>
            );
          })}
        </View>
      </View>
    </>
  );
};

export default React.memo(OrderItem);
