import React from "react";
import { Tooltip } from "react-native-elements";
import { Text } from "react-native";
import Modal from "modal-enhanced-react-native-web";
import { backgroundColors } from "styles/theme";

export const CustomTooltip = ({
  children,
  text,
  isSmallScreen,
  height = 40,
  otherPropsForWeb = {},
}) => {
  if (!text) return children;

  return isSmallScreen ? (
    <Tooltip
      popover={<Text>{text}</Text>}
      ModalComponent={Modal}
      withOverlay={false}
      withPointer={false}
      containerStyle={{
        borderWidth: 1,
        borderRadius: 0,
        backgroundColor: backgroundColors.secondary,
      }}
      skipAndroidStatusBar={true}
      height={height}
    >
      {children}
    </Tooltip>
  ) : (
    <div title={text} {...otherPropsForWeb}>
      {children}
    </div>
  );
};

export default CustomTooltip;
