/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import { View, Platform } from "react-native";

import { useDispatch, useSelector } from "react-redux";

import { getValidationSchema } from "./yup";
import { SaveCancelButtons } from "../../helper";
import { getInitialValues } from "./ui-config";
import useStateIfMounted from "library/utils/useStateIfMounted";

import { Spinner, ToasterHandler } from "components/elements";
import { backgroundColors } from "styles/theme";

import { Form, FormField } from "components/elements/forms";

import tw from "tailwind-rn";

import I18NContext from "library/contexts/i18N";
import { selectGiftCardDetails } from "library/sagas/views/home/drawer/gift-cards/selector";
import {
  resendGiftCard,
  fetchGiftCardActivityDetails,
} from "library/sagas/views/home/drawer/gift-cards/slice";

const GiftCardInfo = ({ UIConfig }) => {
  const dispatch = useDispatch();
  const giftCardDetails = useSelector(selectGiftCardDetails);
  const initialValues = getInitialValues(giftCardDetails);
  const { messages, Localise } = React.useContext(I18NContext);
  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);

  const { InputFields } = UIConfig;

  if (!initialValues) {
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );
  }

  function onResend(values, formikBag) {
    dispatch(
      resendGiftCard({
        params: {
          id: values?.id,
          recipientEmailId: values?.recipientEmail,
          updateInfo: initialValues?.recipientEmail !== values?.recipientEmail,
        },
        resolve: (res) => {
          ToasterHandler(
            "success",
            Localise(messages, "Gift Card sent successfully")
          );
          dispatch(fetchGiftCardActivityDetails());
          formikBag.setSubmitting(false);
        },
        reject: (message = "") => {
          ToasterHandler(
            "error",
            Localise(
              messages,
              message !== ""
                ? message
                : "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values, formikBag) => onResend(values, formikBag)}
      validationSchema={getValidationSchema(Localise, messages)}
      validateOnBlur={validationOnChange}
      validateOnChange={validationOnChange}
      render={(props) => {
        const { submitCount } = props;
        useEffect(() => {
          if (submitCount > 0) {
            setValidationOnChange(true);
          }
        }, [submitCount]);
        return (
          <>
            <View
              style={[
                tw(
                  `flex flex-row justify-between items-center items-${
                    Platform.OS !== "web" ? "center" : "baseline"
                  } flex-wrap`
                ),
                { zIndex: -1 },
              ]}
            >
              {InputFields.fields?.map((data, index) => {
                return data?.title === "Email" &&
                  giftCardDetails?.giftCardType ===
                    "PHYSICAL_GIFT_CARDS" ? null : (
                  <View
                    key={index}
                    style={[
                      tw("flex justify-start items-center pb-2 flex-wrap"),
                      {
                        width: Platform.OS !== "web" ? "50%" : data?.fieldWidth,
                      },
                    ]}
                  >
                    <FormField
                      label={`${data?.title}`}
                      {...data?.formFieldProps}
                      inputContainerStyle={{
                        backgroundColor: data?.formFieldProps.editable
                          ? "auto"
                          : backgroundColors.greyColor,
                        ...data?.formFieldProps.inputContainerStyle,
                      }}
                    />
                  </View>
                );
              })}
            </View>
            {giftCardDetails?.giftCardType === "DIGITAL_GIFT_CARDS" && (
              <SaveCancelButtons giftCardDetails={giftCardDetails} />
            )}
          </>
        );
      }}
    />
  );
};

export default GiftCardInfo;
