import React, { useContext } from "react";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";
import { colors } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import { CustomModal } from "components/elements";
import { useSelector, useDispatch } from "react-redux";
import { selectMarketingEmail } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { setMarketingEmail } from "library/sagas/views/home/drawer/shop-settings/common/slice";

export const EmailVerification = () => {
  const { messages, Localise } = useContext(I18NContext);
  const currentMarketingEmail = useSelector(selectMarketingEmail);
  const dispatch = useDispatch();

  const modalContent = React.useCallback(() => {
    return {
      content: (
        <Text style={{ ...tw("p-4"), fontSize: 15, color: colors.highlighter }}>
          {Localise(
            messages,
            `Please check your inbox at ${currentMarketingEmail} to verify your email address.`
          )}
        </Text>
      ),
      buttons: [{ type: "primary", title: Localise(messages, "Close") }],
    };
  }, [Localise, messages, currentMarketingEmail]);

  return (
    <CustomModal
      modalVisible={!!currentMarketingEmail}
      modalContent={modalContent()}
      primaryhandler={() => dispatch(setMarketingEmail(""))}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: "white", maxWidth: 320 },
      ]}
    ></CustomModal>
  );
};
