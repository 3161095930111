import React from "react";
import { useSelector } from "react-redux";
import { View, Text, ScrollView, Platform } from "react-native";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { fonts, colors } from "styles/theme";
import { selectGiftCardActivityInfo } from "library/sagas/views/home/drawer/gift-cards/selector";
import { isMOLOnlyMember } from "library/utils/entitlements";
import UserProfileStorage from "library/storage/userProfile";

import moment from "moment";
import I18NContext from "library/contexts/i18N";

const WindowHeight = 300;

const Activity = () => {
  const navigation = useNavigation();
  const { messages, Localise } = React.useContext(I18NContext);
  const activitityInfo = useSelector(selectGiftCardActivityInfo);
  const { memberCodes = [] } = UserProfileStorage.getAuthGroup();
  const isMOLOnlyFlorist = isMOLOnlyMember(memberCodes[0]);

  const { activities = [] } = activitityInfo || {};
  let flag = 0;

  if (!activities.length) {
    return (
      <>
        <View>
          <Text
            style={{
              alignItems: "center",
            }}
          >
            {Localise(messages, "No Activity")}
          </Text>
        </View>
      </>
    );
  }
  const getLastUpdateDate = (latestDate) => {
    flag++;
    return (
      <View style={{ marginTop: 20 }}>
        <Text
          style={{
            ...fonts.heading5,
            marginLeft: 15,
            alignItems: "center",
          }}
        >
          {latestDate}
        </Text>
      </View>
    );
  };

  const getActivityInfoText = (data, orderItemIdInfo) => {
    const infoText = data.split(/([, ])/);
    let orderItemIdIndex = 0;
    return (
      <>
        {infoText?.map((each, idx) => {
          if (each === "ORDER_ITEM_ID") {
            const orderItemId = orderItemIdInfo[orderItemIdIndex];
            orderItemIdIndex++;
            return (
              <TouchableOpacity
                key={idx}
                activeOpacity={0.6}
                style={{ color: "#3A7D8D", textDecoration: "underline" }}
                onPress={() => {
                  navigation.dispatch(
                    CommonActions.navigate({
                      name: isMOLOnlyFlorist
                        ? "mercury-online-refunds"
                        : "orders",
                      params: {
                        search: {
                          title: orderItemId,
                          value: orderItemId,
                        },
                      },
                    })
                  );
                }}
                testID="order_item_activity"
                accessibilityLabel="order_item_activity"
              >
                <Text
                  style={{
                    textDecorationLine: "underline",
                    color: "#3A7D8D",
                    paddingRight: orderItemId ? 2 : 0,
                  }}
                >
                  {orderItemId}
                </Text>
              </TouchableOpacity>
            );
          } else {
            return <Text key={idx}>{`${each.trim()} `}</Text>;
          }
        })}
      </>
    );
  };

  return (
    <View>
      <ScrollView style={{ maxHeight: WindowHeight }}>
        {activitityInfo?.activities?.map((activity, index) => {
          const {
            createdOn,
            activityInfo = "",
            orderItemIds,
            orderItemId,
          } = activity;
          const currDate = moment(
            activitityInfo?.activities[index]?.createdOn
          ).format("MMM D, YYYY");
          const prevDate = moment(
            activitityInfo?.activities[index - 1]?.createdOn
          ).format("MMM D, YYYY");

          currDate === prevDate ? flag++ : (flag = 0);

          return (
            <View
              key={index}
              style={{
                borderStyle: "solid",
                borderLeftWidth: 1,
                borderLeftColor: colors.light,
                marginLeft: 5,
              }}
            >
              {(flag === 0 || index === 0) &&
                getLastUpdateDate(moment(createdOn)?.format("MMM D, YYYY"))}
              {activityInfo
                .trim("\n")
                .split("\n")
                .map((activityText, idx) => {
                  return (
                    <View
                      key={idx}
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <Text
                        style={{
                          borderRadius: 50,
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderColor: colors.dark,
                          width: 10,
                          height: 10,
                          position: "absolute",
                          left: -5,
                          top: Platform.OS === "web" ? "calc(50% - 5px)" : 5,
                          backgroundColor: colors.secondary,
                          marginBottom: 15,
                        }}
                      ></Text>
                      <Text
                        style={{
                          ...fonts.default,
                          lineHeight: 20,
                          marginLeft: 15,
                        }}
                      >
                        {/* Added logic with orderItemIdonly for backward compatibilty  */}
                        {getActivityInfoText(
                          activityText,
                          orderItemIds?.length > 0
                            ? orderItemIds
                            : Array.of(orderItemId)
                        )}
                      </Text>
                    </View>
                  );
                })}
            </View>
          );
        })}
      </ScrollView>
    </View>
  );
};

export default Activity;
