/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from "react";
import {
  TouchableOpacity,
  Platform,
  View,
  Switch,
  ActivityIndicator,
  Image,
} from "react-native";
import { Input, Text, Header, Badge } from "react-native-elements";
//import NetInfo from "@react-native-community/netinfo"; // Commented as per PAR-101
import { useNavigation, CommonActions } from "@react-navigation/native";
import UserAuthContext from "library/contexts/userAuth";
import UserProfileContext from "library/contexts/userProfile";
import AppSettingsContext from "library/contexts/appSettings";
import { shapes, fonts, colors, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";
import IMAGES from "static/assets/images";
import useStateIfMounted from "library/utils/useStateIfMounted";
import startCase from "lodash/startCase";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { CustomModal } from "components/elements";
import I18NContext from "library/contexts/i18N";

const TitleComponent = () => {
  return (
    <TouchableOpacity
      style={[tw("flex flex-row items-center"), { width: 250 }]}
      testID="logo"
    >
      <View
        style={{
          backgroundColor: "#FFF",
          borderRadius: 30,
          width: 50,
          height: 50,
        }}
      >
        <Image
          style={{
            width: 50,
            height: 50,
          }}
          resizeMode="cover"
          source={require("static/assets/logo.png")}
        />
      </View>
      <Text
        style={{
          fontSize: 24,
          fontWeight: "400",
          color: colors.secondary,
          marginLeft: 15,
        }}
      >
        Mercury HQ
      </Text>
    </TouchableOpacity>
  );
};

const WelcomeComponent = ({
  name = "",
  subTitle = "",
  userType,
  reset,
  currentPage,
  setCurrentPage,
  isPasswordAboutToExpire,
  hasMenus,
}) => {
  const navigation = useNavigation();
  const { signOut, authToken } = React.useContext(UserAuthContext);
  const [loading, setLoading] = React.useState(false);
  const { messages, Localise } = React.useContext(I18NContext);

  const submitAction = (action) => {
    if (action === "logout") {
      setLoading(true);
      signOut().then(() => {
        setLoading(false);
        reset();
      });
    }

    if (action === "profile") {
      const name = userType == "FTD" ? "ftd-profile" : "profile";
      setCurrentPage(name);
      navigation.dispatch(
        CommonActions.navigate({
          name,
        })
      );
    }

    if (action === "login") {
      setCurrentPage("login");
      if (window) window.location.href = "/login";
    }
  };

  const menuOptions = authToken
    ? hasMenus
      ? [
          { key: "profile", label: "Profile" },
          { key: "logout", label: "Logout" },
        ]
      : [{ key: "logout", label: "Logout" }]
    : [{ key: "login", label: "Login" }];
  const modalContent = {
    content: (
      <ActivityIndicator size="large" color={colors.activityIndicator} />
    ),
  };
  const modal = (
    <CustomModal
      modalVisible={loading}
      modalContent={modalContent}
      contentStyle={{ padding: 0, width: 0, height: 0 }}
      modalStyle={{
        padding: 0,
        ...(Platform.OS !== "web" && {
          flex: 1,
          backgroundColor: "rgb(0, 0, 0)",
          opacity: 0.7,
        }),
      }}
    ></CustomModal>
  );

  return (
    <View style={tw("flex flex-row justify-between items-center")}>
      {modal}
      {authToken && (
        <View style={tw("flex flex-col flex-1 items-end")}>
          <Text
            style={[
              { ...fonts.heading3, color: colors.secondary },
              tw("text-center"),
            ]}
          >
            {Localise(messages, "Welcome")}, {name}
          </Text>
          <Text
            style={[
              { ...fonts.heading5, paddingTop: 5, color: colors.secondary },
              tw("text-center"),
            ]}
          >
            {subTitle}
          </Text>
        </View>
      )}
      <Menu>
        <MenuTrigger testID="profile_icon" accessibilityLabel="profile_icon">
          <Image
            style={{ width: 50, height: 50 }}
            resizeMode="cover"
            source={IMAGES["user-profile"]}
          />
          <View
            style={{
              borderTopWidth: 7,
              borderTopColor: ["profile", "ftd-profile"].includes(currentPage)
                ? colors.navBarActive
                : backgroundColors.header,
            }}
          ></View>
          {isPasswordAboutToExpire && (
            <Badge
              containerStyle={[
                {
                  right: 0,
                  position: "absolute",
                },
              ]}
              badgeStyle={{ backgroundColor: "#e5175e" }}
              value={1}
            />
          )}
        </MenuTrigger>
        <MenuOptions
          optionsContainerStyle={{
            width: 150,
            padding: 5,
            marginTop: 40,
            marginLeft: -5,
          }}
          customStyles={{
            optionWrapper: {
              padding: 5,
              flexDirection: "row",
              justifyContent: "space-between",
            },
          }}
        >
          {menuOptions.map((option) => (
            <MenuOption
              key={option.key}
              onSelect={() => submitAction(option.key)}
              testID={option.key}
              accessibilityLabel={option.key}
            >
              <Text>
                {Localise(messages, option.label)}{" "}
                {isPasswordAboutToExpire && option.label === "Profile" && (
                  <Badge
                    badgeStyle={{ backgroundColor: "#e5175e" }}
                    value={1}
                  />
                )}
              </Text>
            </MenuOption>
          ))}
        </MenuOptions>
      </Menu>
    </View>
  );
};

const MemberComponent = () => {
  return (
    <View style={tw("flex flex-row justify-end items-center w-full")}>
      {/* commented as per PAR-1118 */}
      {/* <Input
        placeholder="Search Mercury..."
        containerStyle={{ width: 400 }}
        inputContainerStyle={{ backgroundColor: colors.secondary }}
      /> */}
    </View>
  );
};

// Commented as per PAR-101
const MemberComponentWithNetworkStatus = () => {
  const [isShopOpen, setShopOpen] = useStateIfMounted(true);
  const [isConnected, setIsConnected] = useStateIfMounted(false);

  React.useEffect(() => {
    let listener;
    if (Platform.OS === "web") {
      NetInfo.isConnected.fetch().then(setIsConnected);
      listener = NetInfo.isConnected.addEventListener(
        "connectionChange",
        setIsConnected
      );
    } else {
      NetInfo.fetch().then((status) => setIsConnected(status.isConnected));
      listener = NetInfo.addEventListener((status) =>
        setIsConnected(status.isConnected)
      );
    }

    // To unsubscribe
    return () => {
      listener.remove;
    };
  });

  return (
    <View style={tw("flex flex-row justify-end items-center w-full")}>
      <View style={tw("flex flex-row justify-start items-center")}>
        <View
          style={{
            ...shapes.round,
            ...(isConnected && { backgroundColor: "green" }),
            marginRight: 5,
          }}
        ></View>
        <Text style={fonts.heading4}>
          {isConnected ? "Connected" : "Disconnected"}
        </Text>
      </View>
      <View style={tw("flex flex-row justify-between items-center")}>
        <Text style={fonts.heading4}>Shop open 9am-5pm </Text>
        <Switch
          style={{ width: 35 }}
          trackColor={{ false: "#767577", true: "#81b0ff" }}
          thumbColor={isShopOpen ? "#f5dd4b" : "#f4f3f4"}
          ios_backgroundColor="#3e3e3e"
          onValueChange={() => setShopOpen(!isShopOpen)}
          value={isShopOpen}
        />
      </View>
      <View style={tw("flex flex-row items-center")}>
        <Input
          placeholder="Search Mercury..."
          containerStyle={{ width: 400 }}
          inputContainerStyle={{ backgroundColor: colors.secondary }}
          testID="search_mercury"
        />
      </View>
    </View>
  );
};

const HeaderBarLarge = ({
  subHeader,
  reset,
  currentPage,
  setCurrentPage,
  showWelcomeSection,
}) => {
  const {
    userProfile: { firstName } = {},
    memberCodes = [],
    shopGroups = [],
    userRole = "",
    userType = "",
    isPasswordAboutToExpire,
  } = React.useContext(UserProfileContext);
  const { allowedMenus } = React.useContext(AppSettingsContext);
  const { messages, Localise } = React.useContext(I18NContext);

  const subTitle =
    userType === "FTD"
      ? userRole !== ""
        ? startCase(userRole, "_", " ")
        : userRole
      : memberCodes.length === 1
      ? `${Localise(messages, "Member")} #${memberCodes[0]}`
      : shopGroups.length === 1
      ? shopGroups[0].replace("-VSG", "") // Temporary to support virtual shop group
      : Localise(messages, userRole);

  return (
    <>
      <Header
        placement="center"
        leftContainerStyle={{ paddingLeft: 5 }}
        rightContainerStyle={{ paddingRight: 5 }}
        centerContainerStyle={{ paddingRight: 5 }}
        containerStyle={{ borderBottomWidth: 0 }}
      >
        <TitleComponent />
        <MemberComponent />
        {showWelcomeSection && (
          <WelcomeComponent
            name={firstName}
            subTitle={subTitle}
            userType={userType}
            isPasswordAboutToExpire={isPasswordAboutToExpire}
            reset={reset}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            hasMenus={allowedMenus.length > 0}
          />
        )}
      </Header>
      {subHeader && subHeader()}
    </>
  );
};

export default HeaderBarLarge;
