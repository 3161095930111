import React from "react";
import {
  View,
  Text,
  ImageBackground,
  Image,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import Expired from "../common/expired";
import ChangePassword from "components/views/drawer/user-profile/change-password";
import tw from "tailwind-rn";
import { backgroundColors, fonts } from "styles/theme";
import IMAGES from "static/assets/images";
import { TermsAndConditionsLink } from "../../login/terms-conditions";
import { colors } from "styles/theme";

const ForgotPassword = ({
  nonce,
  expiry,
  isNonceValid,
  onSuccess,
  onCancel,
  isSmallScreen,
}) => {
  const hasLinkExpired = expiry < Math.round(new Date().getTime() / 1000);

  return (
    <ImageBackground
      source={IMAGES[`ftdBg`]}
      style={{ width: "100%", height: "100%" }}
    >
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View style={tw("flex-1 justify-center items-center w-full")}>
          {isNonceValid === "loading" ? (
            <ActivityIndicator color={colors.activityIndicator} />
          ) : hasLinkExpired ||
            ["IN-ACTIVE", "error"].includes(isNonceValid) ? (
            <Expired
              redirect={onSuccess}
              hasLinkExpired={hasLinkExpired}
              isNonceValid={isNonceValid}
            />
          ) : (
            <View
              style={{
                ...tw("items-center justify-between"),
                minHeight: 600,
                minWidth: isSmallScreen ? 350 : 500,
                paddingHorizontal: isSmallScreen ? 10 : 50,
                paddingVertical: isSmallScreen ? 10 : 20,
                shadowColor: backgroundColors.black,
                borderColor: "#e2e2e2",
                borderWidth: 1,
                shadowOffset: {
                  width: 2,
                  height: 3,
                },
                shadowRadius: 10,
                shadowOpacity: 0.2,
                elevation: 3,
              }}
            >
              <View style={tw("items-center justify-center p-6 mb-6")}>
                <Image
                  source={IMAGES["ftdLogo"]}
                  style={{
                    width: isSmallScreen ? 88 : 115,
                    height: isSmallScreen ? 88 : 115,
                    marginBottom: 20,
                  }}
                />
                <Text
                  style={[
                    tw("text-center"),
                    {
                      fontFamily: fonts.fontFamily.bold,
                      fontSize: 28,
                      color: backgroundColors.primary,
                    },
                  ]}
                >
                  {"Forgot Password"}
                </Text>
                <Text
                  style={[
                    tw("text-center"),
                    {
                      fontSize: 18,
                      marginTop: 10,
                    },
                  ]}
                >
                  {"Enter New Password below"}
                </Text>
              </View>
              <View style={{ ...tw("px-3 pt-2"), maxWidth: 375 }}>
                <ChangePassword
                  nonce={nonce}
                  showOldPassword={false}
                  actionType={"forgot-password"}
                  buttonTitle={"Reset My Password"}
                  onSuccess={onSuccess}
                  onCancel={onCancel}
                />
              </View>
              <View style={tw("items-center justify-center pt-20")}>
                <Text
                  style={{
                    ...fonts.heading2,
                    color: backgroundColors.primary,
                    fontWeight: "600",
                  }}
                >
                  Mercury HQ
                </Text>
                <TermsAndConditionsLink />
              </View>
            </View>
          )}
        </View>
      </ScrollView>
    </ImageBackground>
  );
};

export default ForgotPassword;
