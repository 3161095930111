import React, { useEffect, useContext } from "react";
import { View, Platform, Text } from "react-native";
import {
  FormField,
  FormFieldDatePicker,
  FormFieldPicker,
} from "components/elements/forms";
import moment from "moment";
import tw from "tailwind-rn";
import UserProfileStorage from "library/storage/userProfile";
import {
  getPickupTime,
  handlePickerTimings,
  handlePickupTimeWindow,
} from "../../helper";
import { backgroundColors, colors } from "styles/theme";
import {
  convertToTimeZone,
  getDisplayStatus,
  getErrorMessage,
  getPickupTimeOptions,
  getMinPickupDate,
  DS_OPENING_HOUR,
  DS_CLOSING_HOUR,
  cutOffTimeInterval,
  getDSRequestRerouteStatus,
} from "library/utils/deliveryService";
import { DeviceContext } from "library/contexts/appSettings";
import { useSelector } from "react-redux";
import { selectDSRequestDetails } from "library/sagas/views/home/drawer/delivery-tasks/selector";

const DeliveryInfo = ({
  Localise,
  messages,
  isSmallScreen,
  values,
  modify,
  setFieldValue,
}) => {
  let hasDSFulfillmentError = false;
  const { isMobile } = useContext(DeviceContext);
  const deliveryPath = "deliveryDetails";
  const productPath = `items.${0}`;

  const {
    shopCode,
    timeWindow: pickUpTimeWindow,
    pickupUpdatedTime,
    deliveryProvider,
    pickupTimeOptions,
    deliveryDetails: { pickUpDateTime, deliveryDate: pickUpDate } = {},
  } = values;

  const dsRequestDetails = useSelector(selectDSRequestDetails);
  const displayState = getDisplayStatus(dsRequestDetails);
  if (
    displayState === "Fulfillment Error" ||
    getDSRequestRerouteStatus(dsRequestDetails, "rerouting")
  )
    hasDSFulfillmentError = true;
  let errorMessage = "";
  if (dsRequestDetails?.state) {
    errorMessage = Localise(messages, getErrorMessage(dsRequestDetails));
  }

  let shopTimeZone,
    floristTimeZone = "";
  let updatedDate = "";
  if (shopCode) {
    shopTimeZone = UserProfileStorage.getShopTimeZone(shopCode);
    floristTimeZone = moment.tz(shopTimeZone).format("z");
    if (shopTimeZone)
      updatedDate = moment(
        convertToTimeZone(moment.utc().format(), shopTimeZone)
      ).add(cutOffTimeInterval[deliveryProvider?.toLowerCase()], "minutes");
  }

  const assignmentOptions = [
    { label: "Delivery Service", value: "deliveryService" },
  ];
  const getShopHoursForSelectedDate = (date) => {
    let dayOfWeek = moment(date).format("dddd").toUpperCase();
    let shopHours = shopOpenHours.filter(
      (dayHours) => dayHours.day.toUpperCase() === dayOfWeek
    );
    shopHours = {
      openTime: DS_OPENING_HOUR,
      closeTime: DS_CLOSING_HOUR,
    };
    return shopHours;
    //Commenting this for now as we use shop open and close hours as 7 am to 8pm.
    // return shopHours?.length ?? 0 > 0
    //   ? shopHours[0]
    //   : {
    //       openTime: "00:00:00",
    //       closeTime: "24:00:00",
    //     };
    // return shopHours?.length ?? 0 > 0
    //   ? shopHours[0]
    //   : {
    //       openTime: "00:00:00",
    //       closeTime: "24:00:00",
    //     };
  };
  const shopOpenHours = UserProfileStorage.getShopHours(values.shopCode);
  let shopHours = getShopHoursForSelectedDate(
    pickUpDate ? pickUpDate : updatedDate
  );

  useEffect(() => {
    let dsMinDate = convertToTimeZone(
      moment
        .utc()
        .add(cutOffTimeInterval[deliveryProvider?.toLowerCase()], "minutes")
        .format(),
      shopTimeZone
    );
    let currentTime = moment(moment(dsMinDate).format("HH:mm:ss"), "HH:mm:ss");
    let isSameDay = moment(pickUpDate).isSame(dsMinDate, "day");

    //handle pickupTime Options if delivery provider is Doordash
    const handleDDPickupTime = () => {
      if (shopCode && deliveryProvider && pickUpTimeWindow && pickUpDateTime) {
        let presentFloristTime = moment(
          convertToTimeZone(
            moment
              .utc()
              .add(
                cutOffTimeInterval[deliveryProvider?.toLowerCase()],
                "minutes"
              )
              .format(),
            shopTimeZone
          )
        );
        let isSameDay = moment(pickUpDate).isSame(presentFloristTime, "day");
        let pickupTimeOptions = getPickupTimeOptions(
          pickUpTimeWindow,
          isSameDay || !pickUpDate,
          updatedDate,
          presentFloristTime,
          pickUpDateTime,
          true
        );

        setFieldValue("pickupTimeOptions", pickupTimeOptions);
      }
    };

    if (Platform.OS !== "web") {
      if (deliveryProvider?.toLowerCase() === "doordash") {
        handleDDPickupTime();
      } else {
        if (pickUpTimeWindow.length && pickUpDateTime) {
          handlePickupTimeWindow(
            pickUpTimeWindow,
            pickUpDate,
            dsMinDate,
            currentTime,
            setFieldValue,
            pickUpDateTime,
            shopTimeZone
          );
        } else if (pickUpTimeWindow.length === 0) {
          handlePickerTimings(
            pickUpDate,
            setFieldValue,
            pickUpDateTime,
            shopTimeZone,
            shopHours,
            currentTime,
            dsMinDate,
            isSameDay
          );
        }
      }
    } else if (Platform.OS === "web") {
      if (deliveryProvider?.toLowerCase() === "doordash") {
        handleDDPickupTime();
      } else {
        if (pickUpTimeWindow.length) {
          let injectTiming = pickUpTimeWindow?.map((timeWindow) => {
            return moment(pickUpDate)
              .set({ hour: timeWindow, minute: 0, second: 0 })
              .toDate();
          });
          setFieldValue("injectTimings", injectTiming);
          let timeMorning = moment(pickUpTimeWindow[0] + ":00:00", "HH:mm:ss");
          let timeAfternoon = moment(
            pickUpTimeWindow[1] + ":00:00",
            "HH:mm:ss"
          );
          if (currentTime.isAfter(timeMorning)) {
            if (currentTime.isSameOrBefore(timeAfternoon)) {
              let newTiming = [
                moment(updatedDate)
                  .set({ hour: pickUpTimeWindow[1], minute: 0, second: 0 })
                  .toDate(),
              ];
              setFieldValue("injectTimings", newTiming);
            }
          }
          setFieldValue("pickupUpdatedTime", {});
        } else if (pickUpTimeWindow.length === 0) {
          let pickingTime = getPickupTime(
            pickUpDate,
            shopTimeZone,
            shopHours,
            updatedDate,
            dsMinDate
          );
          setFieldValue("pickupUpdatedTime", pickingTime);
        }
      }
    }
  }, [pickUpDate, pickUpTimeWindow, pickUpDateTime]);

  if (shopCode && shopTimeZone)
    updatedDate = getMinPickupDate(
      shopCode,
      shopTimeZone,
      updatedDate,
      pickUpTimeWindow
    );

  return (
    <>
      <View style={[tw("justify-start flex-row flex-wrap"), { zIndex: 5 }]}>
        {hasDSFulfillmentError ? (
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              width: "104%",
              alignItems: "center",
              paddingRight: isSmallScreen ? 5 : 0,
            }}
          >
            <FormFieldPicker
              name={"assignment"}
              path={deliveryPath}
              label={Localise(messages, "Assignment")}
              placeholder={{
                label: "Select Assignment",
                value: "",
              }}
              data={assignmentOptions}
              isRequired
              disabled
              innerContainerStyle={{
                backgroundColor: backgroundColors.greyColor,
              }}
              containerStyle={{
                width: isSmallScreen
                  ? "100%"
                  : hasDSFulfillmentError
                  ? "40%"
                  : "60%",
                marginLeft: -5,
                padding: 0,
                paddingTop: isSmallScreen ? 0 : 2,
                marginRight: isSmallScreen ? 0 : 6,
                margin: 0,
                zIndex: 10,
              }}
            />
            {hasDSFulfillmentError && (
              <View
                style={{
                  ...(isMobile
                    ? {
                        flex: 0,
                      }
                    : {
                        flex: 1,
                      }),
                  textTransform: "none",
                }}
              >
                <Text style={{ color: colors.error }}>
                  {errorMessage || Localise(messages, "Unknown error occured")}
                </Text>
              </View>
            )}
          </View>
        ) : (
          <FormFieldPicker
            name={"assignment"}
            path={deliveryPath}
            label={Localise(messages, "Assignment")}
            placeholder={{
              label: "Select Assignment",
              value: "",
            }}
            data={assignmentOptions}
            isRequired
            disabled
            innerContainerStyle={{
              backgroundColor: backgroundColors.greyColor,
            }}
            containerStyle={{
              width: isSmallScreen ? "100%" : "60%",
              marginLeft: -5,
              padding: 0,
              paddingTop: isSmallScreen ? 0 : 2,
              marginRight: isSmallScreen ? 0 : 6,
              margin: 0,
              zIndex: 10,
            }}
          />
        )}

        <FormFieldDatePicker
          name={"deliveryDate"}
          isRequired
          label={Localise(messages, "Delivery Date")}
          placeholder={"MM/DD/YYYY"}
          dateValueFormat={"YYYY-MM-DD"}
          alignIconRight={true}
          iconName={"calendar"}
          iconType={"font-awesome"}
          containerStyle={{
            width: isSmallScreen
              ? "100%"
              : hasDSFulfillmentError
              ? "42%"
              : "30%",
            marginLeft: -5,
            padding: 0,
            paddingTop: isSmallScreen ? 10 : 0,
            margin: 0,
            zIndex: 10,
          }}
          popperModifiersOverride={{
            offset: {
              enabled: true,
              offset: "-50px, 0px",
            },
          }}
          minDate={moment(updatedDate).toDate()}
          path={deliveryPath}
          editable={modify}
          disabled={!modify}
        />
      </View>
      <View
        style={[
          tw("justify-start flex-row flex-wrap"),
          { zIndex: 4, marginLeft: -5 },
        ]}
      >
        {deliveryProvider?.toLowerCase() === "doordash" ? (
          <FormFieldPicker
            name={"pickUpDateTime"}
            path={deliveryPath}
            label={`${Localise(messages, "Pickup Time")}${
              floristTimeZone ? ` (${floristTimeZone})` : ""
            }`}
            placeholder={{
              label: "Pickup Time",
              value: null,
            }}
            data={(pickupTimeOptions?.length
              ? pickupTimeOptions
              : getPickupTimeOptions(pickUpTimeWindow)
            ).map((item) => {
              const {
                value: itemValue,
                label,
                enabled = true,
                secondaryLabel = "",
              } = item;

              return {
                value: itemValue,
                label:
                  pickUpDateTime === itemValue && secondaryLabel
                    ? `${Localise(messages, label)} ${Localise(
                        messages,
                        secondaryLabel
                      )}`
                    : Localise(messages, label),
                enabled: enabled,
              };
            })}
            isRequired
            disabled={!modify}
            innerContainerStyle={{
              backgroundColor: modify
                ? "transparent"
                : backgroundColors.greyColor,
            }}
            containerStyle={{
              width: isSmallScreen ? "100%" : "35%",
              padding: 0,
              margin: 0,
              zIndex: 9,
            }}
          />
        ) : deliveryProvider ? (
          <FormFieldDatePicker
            dateFormat="h:mm a"
            name="pickUpDateTime"
            placeholder="Enter Time"
            label={`${Localise(messages, "Pickup Time")}${
              floristTimeZone ? ` (${floristTimeZone})` : ""
            }`}
            isRequired
            containerStyle={{
              width: isSmallScreen ? "100%" : "35%",
            }}
            inputContainerStyle={{
              marginRight: isSmallScreen ? -5 : 0,
            }}
            showTimeSelect={true}
            showTimeSelectOnly={true}
            timeIntervals={30}
            timeCaption="PickUp Time"
            minTime={pickupUpdatedTime.open}
            maxTime={pickupUpdatedTime.close}
            {...(Platform.OS !== "web"
              ? {
                  dateValueFormat: "YYYY-MM-DDTHH:mm:ss",
                }
              : values?.injectTimings?.length
              ? {
                  includeTimes: values?.injectTimings,
                }
              : {})}
            path={deliveryPath}
            editable={Platform.OS !== "web" ? modify : false}
            disabled={!modify}
          />
        ) : (
          <></>
        )}

        <FormField
          name="NoOfItems"
          label={Localise(messages, "# of Items")}
          placeholder={Localise(messages, "Enter Number of Items")}
          path={productPath}
          isRequired
          autoCapitalize="none"
          autoCorrect={false}
          autoComplete="new-password" //hack for autoComplete off
          fsClass="fs-exclude"
          keyboardType="numeric"
          editable={false}
          grayedOutOnDisable={true}
          isUpdateOnChange={true}
          containerStyle={{
            width: isSmallScreen ? "100%" : "35%",
          }}
        />
        <FormField
          name="totalItemValue"
          label={Localise(messages, "Item(s) Value")}
          placeholder={parseFloat(0).toFixed(2)}
          path={productPath}
          isRequired
          editable={modify}
          grayedOutOnDisable={true}
          containerStyle={{
            width: isSmallScreen ? "100%" : "30%",
          }}
          keyboardType="numeric"
          iconType="material-community"
          iconName="currency-usd"
          iconSize={14}
          inputContainerStyle={{
            padding: 6,
          }}
          leftIconContainerStyle={{ paddingRight: 0 }}
          isPrice={true}
          iconPosition={true}
          isUpdateOnChange={true}
        />
      </View>
      <FormField
        name="itemDescription"
        isRequired
        label={Localise(messages, "Item Description")}
        placeholder={Localise(messages, "Enter Item Description")}
        path={productPath}
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="new-password" //hack for autoComplete off
        containerStyle={{
          width: "100%",
          marginLeft: -5,
          paddingRight: isSmallScreen ? 5 : 0,
          padding: 0,
          margin: 0,
        }}
        multiline={true}
        numberOfLines={1}
        maxNumberOfLines={5}
        spellCheck={true}
        editable={modify}
        grayedOutOnDisable={true}
        isUpdateOnChange={true}
      />
    </>
  );
};

export default DeliveryInfo;
