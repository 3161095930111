import React, { useEffect, useState } from "react";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import { Text } from "react-native-elements";
import { View } from "react-native";
import tw from "tailwind-rn";

import useStateIfMounted from "library/utils/useStateIfMounted";
import { fonts } from "styles/theme";
import I18NContext from "library/contexts/i18N";

import {
  Form,
  FormFieldAutoComplete,
  SubmitButton,
} from "components/elements/forms";
import { getTransferFormValidationSchema } from "../yup";

export const TransferForm = (props) => {
  const [query, setQuery] = useState("");
  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);
  const { styles, setShowTransferForm, isSmallScreen, florists, onSubmit } =
    props;

  const filteredFlorists = florists.filter(
    (florist) =>
      florist.name.toLowerCase().includes(query.toLowerCase()) ||
      florist.memberCode.toLowerCase().includes(query.toLowerCase())
  );

  const { messages, Localise } = React.useContext(I18NContext);

  const validationSchema = getTransferFormValidationSchema(Localise, messages);

  return (
    <Form
      initialValues={{
        transferTo: "",
      }}
      validationSchema={validationSchema}
      validateOnChange={validationOnChange}
      validateOnBlur={validationOnChange}
      onSubmit={(values, formikBag) => {
        const { transferTo = "" } = values;
        onSubmit({
          action: "transfer",
          transferTo: transferTo.split(" ")[0],
          formikBag,
        });
      }}
      render={(props) => {
        const { submitCount, values, setValues } = props;
        const finalValues = cloneDeep(values);
        useEffect(() => {
          if (submitCount > 0) {
            setValidationOnChange(true);
          }
        }, [submitCount]);
        return (
          <View style={tw("flex flex-row py-1")} fsClass="fs-unmask">
            <Text
              style={[
                styles.labelText,
                tw(isSmallScreen ? "mt-2" : "mt-2"),
                fonts.style1,
                { fontSize: 14, paddingVertical: 2 },
              ]}
            >
              {Localise(messages, "Transfer To")}
            </Text>
            <View style={[styles.valueText]}>
              <FormFieldAutoComplete
                testID="transferTo"
                accessibilityLabel="transferTo"
                autoCapitalize="none"
                autoCorrect={false}
                name="transferTo"
                label=""
                clearTextOnBackTab={true}
                showOnFocus={true}
                delimiter="space"
                textOverFlowAsEllipsisLength={isSmallScreen ? 15 : 20}
                placeholder="Select Transfer Store"
                data={filteredFlorists}
                onChangeText={(text) => {
                  setQuery(text);
                }}
                outerContainerStyle={{
                  width: isSmallScreen ? "100%" : "90%",
                  zIndex: 1,
                  paddingHorizontal: 0,
                }}
                listDisplayValues={["memberCode", "name"]}
                onSelect={(selectedValue) => {
                  const { name, memberCode } = selectedValue;
                  set(finalValues, "transferTo", `${memberCode}  ${name}`);
                  setValues(finalValues);
                }}
                onClear={() => {
                  set(finalValues, "transferTo", "");
                  setValues(finalValues);
                }}
              />

              <View style={[tw("flex flex-row items-center")]}>
                <SubmitButton
                  title={"Submit"}
                  containerStyle={{
                    paddingVertical: 4,
                    margin: 0,
                    maxWidth: 100,
                  }}
                  testID="Submit"
                  accessibilityLabel="Submit"
                />
                <Text
                  style={[fonts.link1, tw("ml-4")]}
                  onPress={() => setShowTransferForm(false)}
                  testID="cancel"
                  accessibilityLabel="cancel"
                >
                  {Localise(messages, "Cancel")}
                </Text>
              </View>
            </View>
          </View>
        );
      }}
    />
  );
};

export default TransferForm;
