import { Platform, PermissionsAndroid } from "react-native";

import { ToasterHandler } from "components/elements";
import UserProfileStorage from "library/storage/userProfile";
import moment from "moment";
import FileViewer from "react-native-file-viewer";
import { deliveryProviders } from "../config";
import get from "lodash/get";
import startCase from "lodash/startCase";
import upperCase from "lodash/upperCase";

let RNFS;
if (Platform.OS !== "web") {
  RNFS = require("react-native-fs");
}

const ListOfCSVColumns = {
  "Shop Code": "siteId",
  "Shop Name": "siteId",
  "Invoice#": "billing.invoiceNumber",
  "Order#": "referenceId",
  Status: "displayStatus",
  "Delivery Partner": "vendor.name",
  "Requested Date": "requestedDate",
  "Delivery Date": "deliveryDate",
  "Recipient Name": "displayName",
  Address: "",
  "Box Qty": "items.0.quantity",
  "Total Price": "displayPrice",
  "Type Request": "source",
  "Delivery Price": "deliveryPrice",
};

export const exportDSRequests = (
  DSRequestsCollection = {},
  minDeliveryDate,
  maxDeliveryDate,
  messages,
  Localise
) => {
  const DSRequests = Object.values(DSRequestsCollection)
    .map((section) => section.list)
    .flat();
  const replacer = function (key, value) {
    return value === null || value === undefined ? "" : value;
  };
  const headings = [];
  const shopNames = UserProfileStorage.getAllShopNames();

  let csv = DSRequests.map((row, index) => {
    return Object.keys(ListOfCSVColumns)
      .map((fieldName) => {
        if (index === 0) {
          headings.push(fieldName);
        }

        if (fieldName === "Shop Name") {
          return JSON.stringify(
            shopNames[row[ListOfCSVColumns[fieldName]]] ?? ""
          );
        } else if (["Requested Date", "Delivery Date"].includes(fieldName)) {
          return `${moment(get(row, ListOfCSVColumns[fieldName], "")).format(
            "MM/DD/YYYY hh:mm A"
          )} ${moment.tz(row.floristTimeZone).format("z")}`;
        } else if (fieldName === "Type Request") {
          return JSON.stringify(
            ["MHQ_DELIVERY_TASKS", "MHQ_BULK_IMPORT_DELIVERY_TASKS"].includes(
              row[ListOfCSVColumns[fieldName]]
            )
              ? "Delivery Service Request"
              : row[ListOfCSVColumns[fieldName]] === "MHQ_ORDERS"
              ? "MHQ Order"
              : "",
            replacer
          );
        } else if (fieldName === "Delivery Partner") {
          return JSON.stringify(
            deliveryProviders[
              get(row, ListOfCSVColumns[fieldName], "").toLowerCase()
            ] ?? "",
            replacer
          );
        } else if (fieldName === "Address") {
          return JSON.stringify(
            ["addressLine1", "addressLine2", "city", "state", "zip"]
              .map((field) => {
                const fieldVal = get(
                  row,
                  `delivery.address.${field}`,
                  ""
                ).toLowerCase();

                return field !== "state"
                  ? startCase(fieldVal)
                  : upperCase(fieldVal);
              })
              .filter((fieldVal) => !!fieldVal.length)
              .join(", "),
            replacer
          );
        }
        return JSON.stringify(
          get(row, ListOfCSVColumns[fieldName], ""),
          replacer
        );
      })
      .join(",");
  });

  csv.unshift(headings.join(",")); // add header column
  csv = csv.join("\r\n");

  const startDateStr = moment(minDeliveryDate).format("M.D.YY").toString();
  const endDateStr = moment(maxDeliveryDate).format("M.D.YY").toString();
  const fileName = `${startDateStr}_${endDateStr} Delivery Service Detail Summary.csv`;

  if (Platform.OS === "web") {
    const url = window.URL.createObjectURL(new Blob([csv]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // Force download
    link.click();
  } else if (Platform.OS === "ios") {
    saveFileOnDevice(csv, fileName, messages, Localise);
  } else if (Platform.OS === "android") {
    checkPermissionsAndDownloadFile(csv, fileName, messages, Localise);
  }
};

const saveFileOnDevice = (data, fileName, messages, Localise) => {
  var directoryType =
    Platform.OS === "android"
      ? RNFS.DownloadDirectoryPath
      : RNFS.DocumentDirectoryPath;
  var fileDirPath = `${directoryType}/Mercury HQ Delivery Service Requests`;

  RNFS.mkdir(fileDirPath)
    .then(() => {
      var filePath = `${fileDirPath}/${fileName}`;
      RNFS.writeFile(filePath, data, "utf8")
        .then(async () => {
          FileViewer.open(filePath)
            .then(() => {})
            .catch(() => {
              ToasterHandler(
                "success",
                Localise(messages, "Download complete")
              );
            });
        })
        .catch(() => {
          ToasterHandler(
            "error",
            Localise(messages, "Download failed, please try again")
          );
        });
    })
    .catch(() => {
      ToasterHandler(
        "error",
        Localise(messages, "Download failed, please try again")
      );
    });
};

const checkPermissionsAndDownloadFile = async (
  res,
  fileName,
  messages,
  Localise
) => {
  try {
    const granted = await PermissionsAndroid.check(
      PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
    );

    // Asking for storage permissions on Android 13 is no longer needed
    if (granted || Platform.Version >= 33) {
      saveFileOnDevice(res, fileName, messages, Localise);
    } else {
      const result = await PermissionsAndroid.requestMultiple(
        [
          PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
          PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
        ],
        {
          title: Localise(messages, ""),
          message: Localise(
            messages,
            "Please approve Mercury HQ access to download"
          ),
        }
      );

      if (
        result["android.permission.READ_EXTERNAL_STORAGE"] &&
        result["android.permission.WRITE_EXTERNAL_STORAGE"] ===
          PermissionsAndroid.RESULTS.GRANTED
      ) {
        saveFileOnDevice(res, fileName, messages, Localise);
      } else {
        ToasterHandler(
          "error",
          Localise(messages, "Please approve Mercury HQ access to download")
        );
      }
    }
  } catch (err) {
    ToasterHandler(
      "error",
      Localise(messages, "Download failed, please try again")
    );
  }
};

export const shipmentDriverPickupManifestHTML = (
  Localise = () => {},
  messages = {},
  shipments,
  driverName,
  pickupTime,
  iframeId = "iframe"
) => {
  const { start: deliveryDate = "" } = shipments[0]?.pickup?.timeWindow;
  return `<!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml">
    <head>
      <title>Mercury HQ</title>
    </head>
    <body onload="window.parent.postMessage({ action: '${iframeId}-loaded' }, '*');">
      <div style="display: none">
        <style type="text/css" media="print,screen">
          .page-header,
          .page-header-space {
            height:${
              driverName.length >= 20
                ? Platform.OS === "ios"
                  ? "125px"
                  : "110px"
                : Platform.OS === "ios"
                ? "125px"
                : "90px"
            } ;
          }
          .page-footer,
          .page-footer-space {
            height:${Platform.OS === "android" ? "40px" : "50px"} ;
          }
          .page-footer {
            position: fixed;
            bottom: 0;
            width: 100%;
          }
          .page-header {
            position: fixed;
            top: 0;
            width: 100%;
          }
          .head-container {
            display: flex;
            flex:1;
            justify-content: space-between;
            height:${
              driverName.length >= 20
                ? Platform.OS === "ios"
                  ? "95"
                  : "70px"
                : Platform.OS === "ios"
                ? "85px"
                : "50px"
            } ;
            border-bottom: 1px solid black;
            width: 100%;
            font-weight: bold
          }
          .head-container div:first-child{
            text-align: left;
            width: 50%;
            font-weight: bold
          }
          .head-container div:nth-child(2) {
            text-align: left;
            width: 50%;
          }
          .foot-container {
            display: flex;
            justify-content: space-between;
            align-items:center;
            height: 50px;
          }
          .header-title {
            font-size: 20px;

          }
          .footer-title {
            font-size: 18px;
          }
          .table-responsive td:first-child {
            text-align: left;
            width: 25%;
            word-break: break-word;
            padding-right: 50px;
          }
          .table-responsive td:nth-child(2) {
            text-align: left;
            width: 25%;
            word-break: break-word;
          }
          .table-responsive td:nth-child(3) {
            text-align: left;
            width: 25%;
            word-break: break-word;
          }
          .blank {
            padding-left:60px;
            padding-right:60px;
            border-bottom: 1px solid black;
          }
          .tableHeader {
            text-align: left;
            border: 1px solid black;
          }
          @page {           
            margin: 20px;
            size: A4 landscape;
            -webkit-margin-before: 30px;
            -webkit-margin-after: 50px;
          }
          @media print {
            thead {
              display: table-header-group;
            }
            tfoot {
              display: table-footer-group;
            }
            button {
              display: none;
            }
            body {
              margin: 0;
              font-family: Helvetica, sans-serif;
            }
            .table tbody > tr > td,
            .table > tbody > tr > th,
            .table > tfoot > tr > td,
            .table > tfoot > tr > th,
            .table > thead > tr > td,
            .table > thead > tr > th {
              padding: 8px;
              line-height: 1.3;
              vertical-align: top;
            }
            .table {
              border-collapse: collapse;
              width: 100%;
              max-width: 100%;
              margin-bottom: 10px;
              border-spacing: 0px;
            }
          }
        </style>
      </div>
      <div 
      class=${Platform.OS == "ios" ? "" : "page-header"} 
      style="text-align: center;width: 100%; font-weight: bold; margin-bottom:100px !important; ">
        <div class="head-container">
          <div><p class="header-title"> ${Localise(
            messages,
            "Driver Is Approaching - Prepare Manifest"
          )}</p></div>
          <div><p class="header-title">${Localise(
            messages,
            "Delivery Service Driver "
          )}${driverName} ${Localise(messages, "ETA")}: ${pickupTime}</p></div>
        </div>
        <div class="table-responsive" style="margin-top: 10px;width: 100%;">
          <table class="table">
            <tbody>
              <tr
                style="font-size: 14px !important; font-weight: bold !important"
              >
                <td class="tableHeader">
                  <label>${Localise(messages, "Order")} #</label>
                </td>
                <td class="tableHeader">
                  <label>${Localise(messages, "Deliver To")}</label>
                </td>
                <td class="tableHeader">
                  <label>${Localise(messages, "Product(s)")}</label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      ${
        Platform.OS == "ios"
          ? ""
          : `<div class="page-footer">
        <div class="foot-container">
        <div class="footer-title" style="display:flex">
        ${Localise(messages, "Pulled By")} : &nbsp;<span class="blank"></span>
        </div>
        <div class="footer-title">
        ${Localise(messages, "Delivery Date")} : <span>${moment(
              deliveryDate
            ).format("MM/DD/YYYY")}</span>
        </div>
      </div>
      </div>`
      }
      <div style="margin-top: 0px !important" class="page-break-flow-root">
        <div class="table-responsive">
          <table class="table">
          ${
            Platform.OS == "ios"
              ? ""
              : `<thead>
              <tr>
                <th>
                  <!--place holder for the fixed-position header-->
                  <div class="page-header-space">&nbsp;</div>
                </th>
              </tr>
            </thead>`
          }
            <tbody>
              ${shipments
                .map((shipment, index) => {
                  const { referenceId: orderNumber } = shipment;
                  const {
                    addressLine1: recAddressLine1 = "",
                    firstName: recFirstName = "",
                    lastName: recLastName = "",
                    city: deliveryCity = "",
                    state: deliveryState = "",
                    zip: deliveryZip = "",
                  } = shipment?.delivery?.address;
                  const cityStateAndZip = `${
                    deliveryCity && deliveryState
                      ? `${deliveryCity}, ${deliveryState}`
                      : deliveryCity || deliveryState
                  } ${deliveryZip}`;
                  const recipientName = [recFirstName, recLastName].join(
                    "&nbsp;"
                  );
                  return `<tr style="border-bottom:1px solid black;border-top:1px solid black;page-break-inside:avoid !important; ${
                    Platform.OS == "ios" &&
                    "display:inline-block;vertical-align:top;width:100%"
                  }">
                <td>
                  <span>${index + 1}</span><br/ >${orderNumber}
                </td>
                <td>
                  <div class="">
                    <span>${recipientName}</span><br />
                    <span>${recAddressLine1}</span><br/>
                    <span>${cityStateAndZip}</span><br/>
                  </div>
                </td>
                <td>
                  <div>
                    ${shipment?.items
                      .map((item) => {
                        return `<span>
                      <span>Total Items: ${shipment?.items?.length}</span><br />
                      <span>${item?.description}</span><br />`;
                      })
                      .join("<br />")}
                  </div>
                </td>
              </tr>`;
                })
                .join("")}
            </tbody>
            ${
              Platform.OS === "android"
                ? ""
                : `<tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space">&nbsp;</div>
              </td>
            </tr>
          </tfoot>`
            }
          </table>
        </div>
        
        ${
          Platform.OS === "ios"
            ? `<div class="foot-container">
          <div class="footer-title" style="display:flex">
          ${Localise(messages, "Pulled By")} : &nbsp;<span class="blank"></span>
          </div>
          <div class="footer-title">
          ${Localise(messages, "Delivery Date")} : <span>${moment(
                deliveryDate
              ).format("MM/DD/YYYY")}</span>
          </div>
        </div>`
            : ""
        }
      </div>
    </body>
  </html>  
     `;
};
