import React, { useState, useEffect } from "react";
import { Text, View } from "react-native";
import SortableTree, {
  removeNodeAtPath,
  toggleExpandedForAll,
} from "react-sortable-tree";
import "react-sortable-tree/style.css"; // This only needs to be imported once in your app
import { Button } from "react-native-elements";
import "./style.css";
import I18NContext from "library/contexts/i18N";
import { theme, fonts } from "styles/theme";

const DraggableTreeView = ({
  sortableData = [],
  setValue,
  addMenuComponent,
  maxDepth,
}) => {
  const [treeData, setTreeData] = useState(
    toggleExpandedForAll({
      treeData: sortableData,
    })
  );
  const { messages, Localise } = React.useContext(I18NContext);
  const EmptyComponent = () => null;
  const AddItems = addMenuComponent || EmptyComponent;

  useEffect(() => {
    if (sortableData.length > 0) {
      setTreeData(sortableData);
    }
  }, [sortableData]);

  const canDrag = ({ node }) => {
    if (node.title === "Customer Reviews") return false;
    else return true;
  };

  return (
    <View>
      <SortableTree
        treeData={treeData}
        onChange={(treeData) => {
          setValue(treeData);
        }}
        isVirtualized={false}
        maxDepth={maxDepth}
        canDrag={canDrag}
        generateNodeProps={({ node, path }) => ({
          title: (
            <View>
              <Text style={[fonts.default, { fontSize: 13 }]}>
                {node?.title}
              </Text>
              <Text style={[fonts.default, { fontSize: 10 }]}>{node?.url}</Text>
            </View>
          ),
          buttons: [
            <>
              {node?.title !== "Customer Reviews" ? (
                <Button
                  key="delete"
                  titleStyle={theme.Button.secondaryTitleStyle}
                  buttonStyle={{
                    ...theme.Button.secondaryButtonStyle,
                    paddingVertical: 5,
                    paddingHorizontal: 5,
                  }}
                  containerStyle={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    margin: 0,
                  }}
                  onPress={() => {
                    const newTree = removeNodeAtPath({
                      treeData: sortableData,
                      path,
                      getNodeKey: ({ treeIndex }) => treeIndex,
                    });
                    setValue(newTree);
                  }}
                  title={Localise(messages, "Delete")}
                  testID={"Delete"}
                  accessibilityLabel={"Delete"}
                />
              ) : null}
            </>,
          ],
        })}
      />
      <AddItems
        treeData={sortableData}
        setValue={setValue}
        maxDepth={maxDepth}
      />
    </View>
  );
};

export default DraggableTreeView;
