import { createStyles, minWidth } from "react-native-media-queries";

export default createStyles(
  {
    recordContainer: {
      flexDirection: "row",
      padding: 5,
    },
    record: {
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
      paddingVertical: 6,
      paddingLeft: 5,
    },
  },
  minWidth(1100, {
    record: {
      justifyContent: "center",
      paddingVertical: 3,
      width: "100%",
      alignItems: "stretch",
    },
  })
);
