import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, TouchableOpacity } from "react-native";
import { LabelField, ImageField } from "components/wrappers";
import {
  selectCatalogs,
  selectCurrentCatalog,
} from "library/sagas/views/home/drawer/ftd-admin/member-settings/shared-catalogs/selector";
import { setCurrentCatalog } from "library/sagas/views/home/drawer/ftd-admin/member-settings/shared-catalogs/slice";
import { DeviceContext } from "library/contexts/appSettings";

import { colors, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";

const getColorCodes = (active, restricted) => {
  let bgColor = "#3A7D8D",
    textColor = colors.secondary;
  let selectedColor = backgroundColors.primary;

  if (active) {
    if (!restricted) {
      bgColor = colors.ftd;
    }
  } else if (!active) {
    bgColor = "#F2F2F2";
    textColor = colors.primary;
  }

  return {
    bgColor,
    textColor,
    selectedColor,
  };
};

const SharedCatalogs = () => {
  const dispatch = useDispatch();
  const catalogs = useSelector(selectCatalogs);
  const selected = useSelector(selectCurrentCatalog);
  const { isDesktop, isMobile } = React.useContext(DeviceContext);

  const width = 160;

  useEffect(() => {
    if (catalogs[0] && isDesktop) {
      dispatch(setCurrentCatalog(catalogs[0]));
    }
  }, [catalogs.length > 0]);

  const handleOnPress = (data) => {
    dispatch(setCurrentCatalog(data));
  };

  return (
    <View style={tw("flex flex-row flex-wrap")}>
      {catalogs.map((catalog, index) => {
        const { bgColor, textColor, selectedColor } = getColorCodes(
          catalog.isGroupActive,
          catalog.isRestricted
        );

        const active = selected && selected.groupId === catalog.groupId;

        return (
          <TouchableOpacity
            key={index}
            onPress={() => handleOnPress(catalog)}
            style={[
              tw("flex flex-col mb-3"),
              {
                width: width + (active ? 2 : 0),
                marginRight: isMobile ? 6 : 8,
                borderWidth: active ? 1 : 0,
                borderColor: selectedColor,
              },
            ]}
          >
            <View style={{ backgroundColor: bgColor }}>
              <View style={tw("p-3")}>
                <LabelField
                  text={catalog.name}
                  heading={true}
                  textColor={textColor}
                />
                <LabelField
                  text={`${catalog.products.length} Products`}
                  textColor={textColor}
                />
              </View>
              <ImageField
                image={catalog.imageUrl}
                style={{ width, height: width }}
                bgColor={bgColor}
              />
            </View>
            <View
              style={[
                tw("mt-2 flex flex-row justify-center items-center"),
                { backgroundColor: bgColor },
              ]}
            >
              <LabelField
                text={catalog.isGroupActive ? "Active" : "Inactive"}
                textColor={textColor}
                style={{ paddingTop: 6, paddingBottom: 6 }}
              />
            </View>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default SharedCatalogs;
