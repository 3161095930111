import isEmpty from "lodash/isEmpty";

export const getPrintHTML = (
  dateAndTime,
  messageTitle,
  message,
  sendingMemberCode,
  memberInfo
) => {
  return `<!DOCTYPE html>
        <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
           <title>Mercury HQ</title>
        </head>
        <body onload="window.parent.postMessage({ action: 'iframe-loaded' }, '*');">
           <div style="display: none">
              <style type="text/css" media="print,screen">
                 @page {
                 size: auto;
                 margin: 3px;
                 /* this affects the margin in the printer settings */
                 }            
                 .notificationContainer{
                    align-items: center;   
                    border: 1px solid #dddddd;  
                    margin: 40px 20px;                             
                 }
                 .messageHeader {
                    padding: 20px;               
                    display: flex;
                    flex-direction: row; 
                    justify-content: space-between;                    
                    align-items: center;
                    font-size: 16px;
                    border-bottom: 1px solid #dddddd;                    
                 }
                 .message {
                    display: flex;
                    flex-direction: column;               
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    padding: 20px;                    
                 }
                 .flex {
                    display: flex;
                    flex-direction: row;
                 }
                 .w-100{
                    width: 100px;
                    text-align: left;
                 }
                 .ml-10{
                    margin-left: 20px;
                  }
                  .mb-2{
                     margin-bottom: 8px;
                  }                 

                 @media print {
                 html,
                 body {
                    height: auto;
                    -ms-overflow-style: none;
                 }
                 
                 }
              </style>
           </div>
           <div class="notificationContainer">              
               <div class="messageHeader">                  
                  <div>${dateAndTime[0]}</div>
                  <div>${dateAndTime[1]}</div>
                  <div>${messageTitle}</div>
                  <div>${"Test Message"}</div>
               </div>
               <div class="message">
                  <div>${message}</div>                                         
               </div>              
               ${
                 sendingMemberCode
                   ? `<div class="ml-10">
                     <div class="mb-2">
                       <b>Sending Florist: </b>
                     </div>
                     <div class="flex mb-2">
                       <div class="w-100">Member Code</div>
                       <div>${sendingMemberCode}</div>
                     </div>
                     <div>
                       ${
                         !isEmpty(memberInfo)
                           ? `<div>
                           <div class="flex mb-2">
                             <div class="w-100">Shop Name</div>
                             <div>${memberInfo.businessName}</div>
                           </div>
                           <div class="flex mb-2">
                             <div class="w-100">Address</div>
                             <div>
                               <div>${memberInfo.addressLine1}</div>
                               <div>
                                 ${`${memberInfo.city}, ${memberInfo.state} ${memberInfo.zip}`}
                               </div>
                             </div>
                           </div>
                         </div>`
                           : ""
                       }
                     </div>
                   </div>
                 `
                   : ""
               }
            </div>            
        </body>
        </html>
     `;
};

export default getPrintHTML;
