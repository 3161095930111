import { put, takeLatest, delay } from "redux-saga/effects";

import {
  setPageInitialize,
  setPageInitFailure,
  setPageInitSuccess,
  setApiResponse,
  setPageAction,
  setSideCarInitialize,
  setSideCarInitSuccess,
  setSideCarInitFailure,
  fetchAllGiftCards,
  resendGiftCard,
  fetchGiftCardDetails,
  fetchGiftCardActivityDetails,
} from "./slice";

import { handleFetchAllGiftCards, handleUIRefresh } from "./listing-screen";
import {
  handleFetchGiftCardDetails,
  handleFetchGiftCardActivityDetails,
  handleResendGiftCard,
} from "./upsert-screen";

import get from "lodash/get";

function* handlePageIntialize() {
  try {
    yield put(fetchAllGiftCards());
    yield put(setPageInitSuccess());
  } catch (error) {
    yield put(setPageInitFailure());
  }
}

function* handleSideCarIntialize(action = {}) {
  const { storeOrigin } = get(action, "payload", {});
  try {
    yield put(fetchGiftCardDetails({ storeOrigin }));

    yield delay(1000);
    yield put(setSideCarInitSuccess());
  } catch (error) {
    yield put(setSideCarInitFailure());
  }
}

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeLatest(setPageInitialize.type, handlePageIntialize);
  yield takeLatest(setSideCarInitialize.type, handleSideCarIntialize);
  yield takeLatest(setApiResponse.type, handleUIRefresh);
  yield takeLatest(setPageAction.type, handleUIRefresh);
  yield takeLatest(fetchGiftCardDetails.type, handleFetchGiftCardDetails);
  yield takeLatest(
    fetchGiftCardActivityDetails.type,
    handleFetchGiftCardActivityDetails
  );
  yield takeLatest(fetchAllGiftCards.type, handleFetchAllGiftCards);
  yield takeLatest(resendGiftCard.type, handleResendGiftCard);
}

export default watchSaga;
