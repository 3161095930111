import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text } from "react-native";
import { useIsFocused } from "@react-navigation/native";

import { Spinner } from "components/elements";
import { SecondaryButton } from "components/wrappers";
import { Settings, PriceMinimumProducts } from "../common/settings";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import {
  setInit,
  setReset,
  setAction,
  setViewMore,
} from "library/sagas/views/home/drawer/product-catalog/list-collections/slice";
import {
  selectPageStatus,
  selectPageError,
  selectPageData,
  selectCollectionSortValue,
  selectCollectionLimit,
  selectSearchText,
} from "library/sagas/views/home/drawer/product-catalog/list-collections/selector";
import {
  selectScreenParam,
  selectShopCode,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";
import { navigateScreen } from "library/sagas/views/home/drawer/product-catalog/common/slice";

import { Widget, SortPicker, ScreenComponent } from "../helper";
import { PageHeader } from "../header";

import { fonts } from "styles/theme";
import tw from "tailwind-rn";

const ListCollections = ({ route: { name, params = {} }, rootNavigation }) => {
  const dispatch = useDispatch();
  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile, isDesktop, isTablet } = React.useContext(DeviceContext);

  const data = useSelector(selectPageData);
  const status = useSelector(selectPageStatus);
  const error = useSelector(selectPageError);
  const shopCode = useSelector(selectShopCode);
  const currentLimit = useSelector(selectCollectionLimit);

  const baseLimit = isMobile ? 4 : 24;
  const showViewMore = currentLimit < data.length;
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused) return;
    dispatch(setInit());
    dispatch(navigateScreen({ name, params, reduxOnly: true }));
    return () => {
      dispatch(setReset());
    };
  }, [isFocused, shopCode]);

  useEffect(() => {
    if (currentLimit === 0) dispatch(setViewMore(baseLimit));
  }, [currentLimit]);

  const collectionsSlice = data
    .filter((collection) => collection.handle !== "addons")
    .slice(0, currentLimit);

  const displayCollections = collectionsSlice
    .concat(Array.from(Array(baseLimit)).map(() => ({})))
    .slice(0, currentLimit);

  const MainScreen = () => {
    return (
      <View style={tw("flex flex-col my-3")}>
        <View style={tw("flex flex-row justify-between items-center pb-3")}>
          <Text style={fonts.heading3}>
            {Localise(messages, "All Collections")} ({data.length})
          </Text>

          <View style={tw(`flex flex-row items-center`)}>
            <SortPicker
              type={"collection"}
              selectSort={selectCollectionSortValue}
              setSort={({ value }) =>
                setAction({ type: "collectionSortBy", value })
              }
              style={{ width: 115 }}
            />
          </View>
        </View>
        {collectionsSlice.length == 0 ? (
          error ? (
            <Text style={{ ...fonts.error, textAlign: "left" }}>
              {Localise(messages, "Unable to pull collections, try again")}
            </Text>
          ) : status === "progress" ? (
            <View style={{ minHeight: 150 }}>
              <Spinner size="large" />
            </View>
          ) : (
            <Text style={{ ...fonts.default, textAlign: "left" }}>
              {Localise(messages, "No matching Collections found")}
            </Text>
          )
        ) : (
          <View style={tw("flex flex-row flex-wrap")}>
            {displayCollections.map((collection, index) => (
              <Widget
                key={index}
                style={{ marginRight: 6 }}
                type="collection"
                {...collection}
              />
            ))}
          </View>
        )}
        {showViewMore && (
          <View
            style={tw(
              `flex flex-row-reverse justify-between items-center py-4`
            )}
          >
            <SecondaryButton
              title={"View More"}
              action={() => dispatch(setViewMore(currentLimit))}
            />
          </View>
        )}
      </View>
    );
  };

  const SideCar = () => {
    const sideCar = useSelector(selectScreenParam("sideCar"));
    return sideCar === "catalogSettings" ? (
      <Settings />
    ) : sideCar === "priceMinimumProducts" ? (
      <PriceMinimumProducts />
    ) : null;
  };

  return (
    <ScreenComponent rootNavigation={rootNavigation}>
      <View
        fsClass="fs-unmask"
        style={{ maxWidth: isDesktop ? 996 : isTablet ? 676 : 332 }}
      >
        <PageHeader
          selectSearchText={selectSearchText}
          setSearchText={setAction}
        />
        <MainScreen />
      </View>
      <SideCar />
    </ScreenComponent>
  );
};

export default ListCollections;
