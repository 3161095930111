import React from "react";
import { ScrollView, View } from "react-native";
import { Text } from "react-native-elements";
import isEmpty from "lodash/isEmpty";
import { getUIConfig } from "./ui-config";
import { useSelector } from "react-redux";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import { selectScannedOrders } from "library/sagas/views/home/drawer/qr-scan/selector";
import { colors, fonts } from "styles/theme";
import tw from "tailwind-rn";

const BulkScan = () => {
  const { isDesktop, isTablet, isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);
  const scannedOrders = useSelector(selectScannedOrders);

  const UIConfig = getUIConfig(
    Localise,
    messages,
    isDesktop,
    isTablet,
    isMobile
  );

  return (
    <ScrollView style={{ width: "98%", paddingRight: 15 }}>
      {!isEmpty(scannedOrders) ? (
        UIConfig.sections.map(
          ({ section, component: { name: MainComponent, props } }, index) => {
            return <MainComponent UIConfig={props} key={index} />;
          }
        )
      ) : (
        <View
          style={{
            borderStyle: "dotted",
            borderWidth: 3,
            borderColor: colors.disabled,
            minHeight: 300,
            width: "80%",
            ...tw("flex items-center justify-center"),
          }}
        >
          <Text style={{ ...tw("font-bold"), ...fonts.heading4 }}>
            {Localise(messages, "Scan to add orders")}
          </Text>
        </View>
      )}
    </ScrollView>
  );
};

export default BulkScan;
