import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";
import cloneDeep from "lodash/cloneDeep";
import { isCustomDateRangeToggle } from "library/utils/formatter";

export const initialState = {
  originalData: [],
  data: [],
  AutoPrintData: [],
  recordId: "",
  recordAction: "",
  shopCode: "",
  minDeliveryDate: "",
  maxDeliveryDate: "",
  addressVerificationStatus: "",
  addressVerificationInfo: {},
  timeStamp: null,
  pickupManifestTimeStamp: null,
  loading: true,
  isDSRequestModifying: false,
  isTaskLocked: { isLocked: false, lockedBy: "" },
  actions: {
    appliedFilters: [],
    filters: [],
    selectedShops: [],
    shops: [],
    search: {},
    sort: {},
  },
  DSRequestLoading: true,
  DSRequestActionProcessing: false,
  DSRequestDetails: {},
  showApproveCreditForm: false,
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setActions: (state, action) => {
      const currentFilters = action.payload.appliedFilters;
      const prevFilters = state.actions.appliedFilters;
      const changeInDateRange = currentFilters
        ? isCustomDateRangeToggle(prevFilters, currentFilters)
        : false;
      if (changeInDateRange) {
        state.timeStamp = null;
        state.data = [];
        state.originalData = [];
      }
      state.actions = { ...state.actions, ...action.payload };
    },
    setData: (state, action) => {
      const {
        data,
        AutoPrintData,
        originalData,
        timeStamp,
        pickupManifestTimeStamp,
        minDeliveryDate,
        maxDeliveryDate,
      } = action.payload;
      if (data) state.data = cloneDeep(data);
      state.loading = false;
      state.minDeliveryDate = minDeliveryDate;
      state.maxDeliveryDate = maxDeliveryDate;
      if (originalData) state.originalData = cloneDeep(originalData);
      if (timeStamp) state.timeStamp = timeStamp;
      if (pickupManifestTimeStamp)
        state.pickupManifestTimeStamp = pickupManifestTimeStamp;
      if (AutoPrintData) state.AutoPrintData = cloneDeep(AutoPrintData);
    },
    setRecordId: (state, action) => {
      state.recordId = action.payload;
    },
    setShopCode: (state, action) => {
      state.shopCode = action.payload;
    },
    setRecordAction: (state, action) => {
      state.recordAction = action.payload;
    },
    updateAvsInfo: (state, action) => {
      const { ...avsInfo } = action.payload;
      state.addressVerificationInfo = avsInfo;
      state.loading = false;
    },
    resetBasicInfo: (state) => {
      Object.assign(state, {
        ...state,
        recordId: "",
        recordAction: "",
        data: [],
        actions: {
          appliedFilters: [],
          filters: [],
          selectedShops: [],
          shops: [],
          search: {},
          sort: {},
        },
        isDSRequestModifying: false,
      });
    },
    reset: (state) => {
      Object.assign(state, initialState);
    },
    setDSRequestModifying: (state, action) => {
      state.isDSRequestModifying = action.payload;
    },
    setTaskLocked: (state, action) => {
      state.isTaskLocked = action.payload;
    },
    setAddressVerificationStatus: (state, action) => {
      state.addressVerificationStatus = action.payload;
    },
    setDSRequestLoading: (state, action) => {
      state.DSRequestLoading = action.payload;
    },
    setDSRequestActionProcessing: (state, action) => {
      state.DSRequestActionProcessing = action.payload;
    },
    setDSRequestDetails: (state, action) => {
      state.DSRequestDetails = action.payload;
    },
    setShowApproveCreditForm: (state, action) => {
      state.showApproveCreditForm = action.payload;
    },

    // noop - only actions
    fetchData: () => {},
    fetchDSRequestDetails: () => {},
  },
});

export const {
  setLoading,
  setActions,
  setData,
  setRecordId,
  setShopCode,
  setRecordAction,
  resetBasicInfo,
  reset,
  fetchData,
  setAddressVerificationStatus,
  setDSRequestLoading,
  setDSRequestModifying,
  setDSRequestActionProcessing,
  setDSRequestDetails,
  setShowApproveCreditForm,
  fetchDSRequestDetails,
  updateAvsInfo,
} = slice.actions;

export default { [sliceName]: slice.reducer };
