import isObject from "lodash/isObject";
import moment from "moment";

export const getDatesDateRange = (filters) => {
  let filterCategoryMap = [];
  if (filters.length) {
    filterCategoryMap = filters
      .filter((e) => !!e["Date Range"])
      .map((e) => {
        return e["Date Range"].value;
      });
  }

  let startDate = moment();
  let endDate = moment();

  if (filterCategoryMap?.length === 1 && isObject(filterCategoryMap[0])) {
    startDate = filterCategoryMap[0].startDate;
    endDate = filterCategoryMap[0].endDate;
  } else {
    if (filterCategoryMap.includes("30")) {
      endDate = moment(startDate).add(30, "days");
    } else if (filterCategoryMap.includes("7")) {
      endDate = moment(startDate).add(7, "days");
    } else if (["0", "1"].every((value) => filterCategoryMap.includes(value))) {
      endDate = moment(startDate).add(1, "days");
    } else if (filterCategoryMap.includes("1")) {
      endDate = moment(startDate).add(1, "days");
      startDate = endDate;
    }
  }

  return {
    startDate,
    endDate,
  };
};
