/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useContext, useRef, useMemo } from "react";
import { View, Text } from "react-native";
import { Button } from "react-native-elements";
import { TouchableOpacity } from "react-native-gesture-handler";
import { hashString } from "react-hash-string";
import { useSelector } from "react-redux";

import { backgroundColors, theme, fontWeights } from "styles/theme";
import moment from "moment";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";
import { selectAddressVerificationInfo } from "library/sagas/ongoing/global-data/selector";

import { getInitialValues } from "./config";
import { adhocStopObject } from "./helper";
import { getValidationSchema } from "./yup";
import styles from "./styles";
import tw from "tailwind-rn";
import get from "lodash/get";
import { Form, FormField, SubmitButton } from "components/elements/forms";
import Address from "components/elements/address";

const RouteStopInfo = ({
  selectedStopId,
  setLoading,
  routeData,
  routeDeliveryDate,
  ordersList,
  setOrdersList,
  setRouteMoveAction,
  setIsAddStopFormOpen,
  setStopId,
  disableForm = false,
  isEditStop = false,
  innerRef,
  setEventAfterSaveRoute,
  optimiseRoute,
  setOptimiseRoute,
  setIsRouteSave,
  isRouteEditing,
  setIsAdhocStopEditing,
  unsavedMoveActionOrdersList,
  setUnsavedMoveActionOrdersList,
  multiSelectedShopCodes = [],
  shopLocations = {},
}) => {
  //context
  const { messages, Localise } = useContext(I18NContext);
  const { isDesktop, isMobile } = useContext(DeviceContext);

  //selectors
  const addressVerificationInfo = useSelector(selectAddressVerificationInfo);

  //state hooks
  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);

  //Refs
  const submitButtonRef = useRef();
  const screenRef = useRef(null);

  const isSmallScreen = !isDesktop;

  const { countryCode: defaultCountryCode = "US" } =
    shopLocations[multiSelectedShopCodes[0]] || {};

  let initialValues;
  let stopStatus = "NEW";
  let stopType = "CUSTOM";
  let stopDeliveryMethod = "";
  let stopShopCode = "";
  const ordersInRoute = unsavedMoveActionOrdersList.ordersInRoute.length
    ? unsavedMoveActionOrdersList.ordersInRoute
    : ordersList.ordersInRoute || [];
  const nonRoutedOrders = unsavedMoveActionOrdersList.nonRoutedOrders.length
    ? unsavedMoveActionOrdersList.nonRoutedOrders
    : ordersList.nonRoutedOrders || [];
  const selectedStopSequenceNumber = ordersInRoute?.findIndex(
    (ord) => ord.orderItemId == selectedStopId
  );
  if (isEditStop) {
    setIsAddStopFormOpen(false); // Close the new adhoc stop if the user tries to edit the other stop

    const selectedStopInfo =
      ordersInRoute?.find((ord) => ord.orderItemId == selectedStopId) || {};

    stopStatus = selectedStopInfo.status;
    stopType = selectedStopInfo.stopType;
    stopDeliveryMethod = selectedStopInfo.deliveryMethod;
    stopShopCode = get(selectedStopInfo, "receivingMember.memberCode", "");
    const {
      stopName: stopName,
      recipientAddressLine: stopAddressLine1,
      recipientAddressLine2: stopAddressLine2,
      recipientCityOnly: stopCity,
      recipientState: stopState,
      recipientZip: stopZip,
      recipientCountry: stopCountry,
      recipientLocation: {
        latitude: stopLatitude = "",
        longitude: stopLongitude = "",
      } = {},
      stopDescription: stopDescription,
    } = selectedStopInfo;
    const initialValuesForEditStop = {
      stopName,
      stopAddress: {
        addressLine1: stopAddressLine1,
        suite: stopAddressLine2,
        city: stopCity,
        state: stopState,
        zip: stopZip,
        country: stopCountry,
        county: "",
      },
      stopDescription,
      stopLatitude: JSON.stringify(stopLatitude),
      stopLongitude: JSON.stringify(stopLongitude),
    };
    initialValues = getInitialValues(initialValuesForEditStop);
  } else {
    initialValues = getInitialValues({
      stopAddress: { country: defaultCountryCode || "US", county: "", zip: "" },
    });
  }
  const formTitle = isEditStop
    ? stopStatus === "COMPLETED"
      ? "Completed Stop"
      : stopStatus === "INCOMPLETE"
      ? "Non Completed Stop"
      : stopStatus === "NEW" && disableForm
      ? "Added Stop"
      : "Edit Stop"
    : "Add a Stop";

  const disabled =
    disableForm || ["COMPLETED", "INCOMPLETE"].includes(stopStatus);

  const disableStopName = useMemo(() => disabled, [disabled]);

  useEffect(() => {
    submitButtonRef.current.disableButton(disabled);
  }, [disableForm]);

  const onSubmit = (values, formikBag) => {
    if (formikBag.isSubmitting) return;

    const { stopName = "", stopDescription = "" } = values;

    const stopId = isEditStop
      ? selectedStopId
      : "ADHOC" +
        hashString(`${stopName}_${stopDescription}_${moment().utc().format()}`);

    const adhocStop = adhocStopObject({
      values,
      stopId,
      stopType,
      addressVerificationInfo: addressVerificationInfo[0],
      displayDeliveryDate: moment(routeDeliveryDate, "YYYY-MM-DD").format(
        "MM/DD/YYYY"
      ),
      stopStatus,
      routeId: routeData.routeId,
      deliveryMethod: stopDeliveryMethod,
      siteId: stopShopCode,
    });

    if (isEditStop) {
      ordersInRoute[selectedStopSequenceNumber] = adhocStop;
    } else {
      ordersInRoute.push(adhocStop);
    }
    setOrdersList({ ordersInRoute, nonRoutedOrders });
    setUnsavedMoveActionOrdersList({
      ordersInRoute: [],
      nonRoutedOrders: [],
    });
    formikBag.setSubmitting(false);
    setRouteMoveAction({ action: "saveStop" });
  };

  return (
    <View activeOpacity={0.6} onPress={() => {}} style={{ minWidth: 300 }}>
      <View
        style={[styles.recordContainer, !isMobile && { flex: 1 }]}
        fsClass="fs-unmask"
      >
        <View
          style={[
            styles.addStopRecord,
            {
              borderColor: backgroundColors.primary || "transparent",
              backgroundColor: backgroundColors.secondary,
              alignItems: "center",
            },
          ]}
          fsClass="fs-unmask"
        >
          <Form
            initialValues={initialValues}
            innerRef={innerRef}
            onSubmit={(values, formikBag, initialValues) => {
              onSubmit(values, formikBag, initialValues);
            }}
            focusError={Localise(
              messages,
              "Please review and correct the following issues"
            )}
            screenRef={screenRef}
            validationSchema={getValidationSchema(Localise, messages)}
            validateOnBlur={validationOnChange}
            validateOnChange={validationOnChange}
            render={({
              values,
              submitCount,
              setValues,
              setFieldValue,
              errors,
            }) => {
              // If form has validation errors
              if (Object.keys(errors).length > 0) {
                // Do not show loading symbol
                setLoading(false);
                // Reset setEventAfterSaveRoute so that the optimize or route status update action doesn't happen in consecutive step
                setEventAfterSaveRoute();
                // Do not optimize the route until user selects optimize button again.
                optimiseRoute && setOptimiseRoute(false);
                // Reset isRouteSave to false so that the route can be saved when navigating away from route again
                setIsRouteSave(false);
              }

              const {
                stopAddress: {
                  addressLine1,
                  suite = "",
                  city,
                  state,
                  zip,
                  country,
                } = {},
              } = values;

              useEffect(() => {
                if (submitCount > 0) {
                  setValidationOnChange(true);
                }
              }, [submitCount]);

              useEffect(() => {
                if (
                  zip &&
                  zip.length >= 3 &&
                  addressLine1 &&
                  city &&
                  state &&
                  country
                ) {
                  request("get-lat-long", {
                    zipCode: zip,
                    addressLine1: encodeURIComponent(addressLine1),
                    suite: encodeURIComponent(suite),
                    city: encodeURIComponent(city),
                    state,
                    country,
                  })
                    .then((res) => {
                      const {
                        latitude: stopLatitude = "",
                        longitude: stopLongitude = "",
                      } = res || {};
                      setFieldValue("stopLatitude", stopLatitude);
                      setFieldValue("stopLongitude", stopLongitude);
                    })
                    .catch(() => {});
                }
              }, [zip, addressLine1, suite, city, state, country]);

              const isModified =
                JSON.stringify(initialValues) !== JSON.stringify(values);

              if (!isRouteEditing) {
                setIsAdhocStopEditing(isModified);
              }

              return (
                <View
                  style={{
                    ...tw("flex-row items-center flex-wrap"),
                    width: "100%",
                  }}
                >
                  <View
                    style={{
                      ...tw("flex-row items-center justify-between"),
                      width: "100%",
                    }}
                  >
                    <Text
                      style={{
                        color: backgroundColors.primary,
                        fontSize: 16,
                        fontWeight: fontWeights.bold,
                        marginBottom: 20,
                        paddingLeft: 5,
                      }}
                    >
                      {Localise(messages, formTitle)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                    }}
                  >
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      autoComplete="new-password" //hack for autoComplete off
                      name="stopName"
                      containerStyle={{
                        width: "100%",
                      }}
                      placeholder="Enter Stop Name"
                      label="Stop Name"
                      labelStyle={{ fontWeight: "normal" }}
                      editable={!disableStopName}
                      disabled={disableStopName}
                      isUpdateOnChange={true}
                    />
                  </View>
                  <Address
                    values={values}
                    setValues={setValues}
                    isSmallScreen={isSmallScreen}
                    formIKPath={"stopAddress"}
                    editable={!disabled}
                    disabled={disabled}
                    stateLabel={country === "CA" ? "Province" : "State"}
                  />
                  <View
                    style={{
                      width: "100%",
                      marginBottom: 15,
                      zIndex: -1,
                    }}
                  >
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      autoComplete="new-password" //hack for autoComplete off
                      name="stopDescription"
                      containerStyle={{
                        width: "100%",
                      }}
                      placeholder="Description"
                      label="Description"
                      labelStyle={{ fontWeight: "normal" }}
                      editable={!disabled}
                      disabled={disabled}
                      isUpdateOnChange={true}
                    />
                  </View>
                  <View
                    style={{
                      ...tw("flex flex-row justify-end"),
                      width: "100%",
                    }}
                  >
                    <TouchableOpacity
                      testID="cancel_add_stop"
                      accessibilityLabel="cancel_add_stop"
                      onPress={() => {
                        isEditStop
                          ? setStopId("")
                          : setIsAddStopFormOpen(false);
                        setIsAdhocStopEditing(false);
                      }}
                    >
                      <Button
                        title={Localise(messages, "Cancel")}
                        titleStyle={theme.Button.secondaryTitleStyle}
                        buttonStyle={{
                          ...theme.Button.secondaryButtonStyle,
                          paddingBottom: 9,
                          paddingTop: isMobile ? 7 : 9,
                          paddingHorizontal: 10,
                          margin: 5,
                          height: 35,
                        }}
                        containerStyle={{
                          margin: 0,
                          marginBottom: 10,
                          justifyContent: "center",
                        }}
                      />
                    </TouchableOpacity>
                    <SubmitButton
                      disableOnDirty={true}
                      disableOnErrors={true}
                      containerStyle={{
                        margin: 5,
                      }}
                      title={"Save"}
                      refFromProp={submitButtonRef}
                    />
                  </View>
                </View>
              );
            }}
          />
        </View>
      </View>
    </View>
  );
};

export default React.memo(RouteStopInfo);
