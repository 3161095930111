import React, { useMemo } from "react";
import { View, ActivityIndicator } from "react-native";
import { Text } from "react-native-elements";
import moment from "moment";
import tw from "tailwind-rn";
import { fonts, colors } from "styles/theme";
import { ToasterHandler } from "components/elements";
import I18NContext from "library/contexts/i18N";
import { formatPrice } from "library/utils/formatter";
import { FormField } from "components/elements/forms";
import { DeviceContext } from "library/contexts/appSettings";

const RefundPreview = ({
  transactions = [],
  refundEditable = false,
  variant = "orderDetails",
  formikPath = "refundTransactions",
  isLoading = false,
  setFieldValue,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { isMobile } = React.useContext(DeviceContext);

  return useMemo(() => {
    return (
      <View style={tw("my-2")}>
        <View
          style={{
            ...tw("flex flex-row"),
            ...(variant !== "orderDetails" && {
              paddingVertical: 2,
              paddingHorizontal: 5,
            }),
          }}
        >
          <View
            style={{
              width: variant === "orderDetails" ? "45%" : "75%",
              ...tw("flex flex-row items-center"),
            }}
          >
            <Text
              style={{
                color: colors.primary,
                ...fonts.heading5,
                ...(variant !== "orderDetails" && {
                  ...fonts.heading4,
                  fontSize: 15,
                }),
              }}
            >
              {Localise(messages, "Charges")}
            </Text>
            {isLoading && (
              <ActivityIndicator
                style={{ marginLeft: 10 }}
                color={colors.activityIndicator}
                testID={"refund_preview_loader"}
              />
            )}
          </View>
          <View
            style={{
              ...tw("flex flex-row"),
              ...(variant !== "orderDetails"
                ? {
                    width: "25%",
                    ...tw("justify-end"),
                  }
                : { width: "55%", ...tw("justify-start") }),
            }}
          >
            <Text
              style={{
                width: variant === "orderDetails" ? "25%" : "auto",
                color: colors.primary,
                marginHorizontal: 5,
              }}
            />
            <Text
              style={{
                ...fonts.heading5,
                paddingBottom: 5,
                ...(variant !== "orderDetails"
                  ? {
                      ...fonts.heading4,
                      fontSize: 15,
                      width: 80,
                    }
                  : {
                      width: "40%",
                    }),
              }}
            >
              {Localise(messages, "Refund")}
            </Text>
          </View>
        </View>

        <View>
          {transactions?.map((transaction, index) => {
            const {
              paymentMethodType,
              creditCardType,
              creditCardNumber,
              availableAmount,
              authorizationDate,
              originalRefundAmount,
            } = transaction;
            const isCashOrCheck = paymentMethodType === "CASH_OR_CHECK";
            const isCreditCard = paymentMethodType === "CREDIT_CARD";
            const isPaidElsewhere = paymentMethodType === "PAID_ELSEWHERE";
            const isSavedCard = paymentMethodType === "SAVED_CARD";
            const isInvoice = paymentMethodType === "INVOICE";
            const isGiftCard = paymentMethodType === "GIFT_CARD";
            let paymentVal = "";
            if (isSavedCard) {
              paymentVal = creditCardType + " " + creditCardNumber;
            } else if (isCreditCard) {
              if (creditCardType)
                paymentVal = creditCardType + " ***" + creditCardNumber;
            } else if (isCashOrCheck) {
              paymentVal = Localise(messages, "Cash or Check");
            } else if (isPaidElsewhere) {
              paymentVal = Localise(messages, "Paid Elsewhere");
            } else if (isInvoice) {
              paymentVal = Localise(messages, "Invoice");
            } else if (isGiftCard) {
              paymentVal = Localise(messages, "Gift Card");
            }
            return (
              <View
                key={index}
                style={{
                  ...tw("flex flex-row flex-wrap items-center"),
                  ...(variant !== "orderDetails"
                    ? {
                        paddingVertical: 2,
                        paddingHorizontal: 5,
                      }
                    : {
                        paddingBottom: 4,
                      }),
                }}
              >
                <View
                  style={{
                    width: variant === "orderDetails" ? "45%" : "75%",
                    ...tw(`flex ${isMobile ? "flex-col" : "flex-row"}`),
                  }}
                >
                  <Text
                    style={{
                      ...tw("flex items-center"),
                      ...fonts.default,
                      height: isMobile ? 15 : 30,
                    }}
                  >
                    {moment(authorizationDate).format("MM/DD/YY")}
                  </Text>
                  <Text
                    style={{
                      ...tw("flex items-center"),
                      ...fonts.default,
                      height: isMobile ? 15 : 30,
                      paddingLeft: isMobile ? 0 : 10,
                    }}
                  >
                    {paymentVal}
                  </Text>
                </View>
                <View
                  style={{
                    ...tw("flex flex-row items-center"),
                    ...(variant !== "orderDetails"
                      ? {
                          width: "25%",
                          justifyContent: "flex-end",
                        }
                      : { width: "55%", justifyContent: "flex-start" }),
                  }}
                >
                  <Text
                    style={{
                      width: variant === "orderDetails" ? "25%" : "auto",
                      paddingLeft: 0,
                      marginHorizontal: 5,
                      height: 30,
                      ...tw("flex items-center"),
                    }}
                  >
                    ${formatPrice(availableAmount)}
                  </Text>

                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    name={"refundAmount"}
                    placeholder=""
                    containerStyle={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      ...(variant !== "orderDetails"
                        ? {
                            marginLeft: 15,
                            width: 80,
                          }
                        : { width: "40%" }),
                    }}
                    isPrice={true}
                    keyboardType="numeric"
                    iconType="material-community"
                    iconName="currency-usd"
                    iconSize={14}
                    inputContainerStyle={{
                      padding: 6,
                    }}
                    leftIconContainerStyle={{ paddingRight: 0 }}
                    path={`${formikPath}.${index}`}
                    editable={refundEditable}
                    grayedOutOnDisable={true}
                    testID={`refund_${index}`}
                    errorStyle={{ paddingBottom: 0 }}
                    handleOnBlur={(refundAmountLocal = "") => {
                      if (variant === "orderDetails") {
                        if (
                          Number(availableAmount) < Number(refundAmountLocal)
                        ) {
                          setTimeout(() => {
                            setFieldValue(
                              `${formikPath}.${index}.refundAmount`,
                              formatPrice(originalRefundAmount)
                            );
                          }, 10);
                          ToasterHandler(
                            "error",
                            Localise(
                              messages,
                              `Refund amount is greater than $${formatPrice(
                                availableAmount
                              )}`
                            )
                          );
                        }
                      }
                    }}
                  />
                </View>
              </View>
            );
          })}
        </View>
      </View>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    variant,
    Localise,
    messages,
    isLoading,
    transactions,
    formikPath,
    refundEditable,
    setFieldValue,
  ]);
};

export default RefundPreview;
