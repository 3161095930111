import actionTypes from "library/actions/userProfile";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";

const initState = {
  userProfile: {},
  userRole: null,
  userType: null,
  suspendedMembers: [],
  floristTypes: [],
};

const UserProfileReducer = (prevState = initState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROFILE:
      return {
        ...prevState,
        userProfile: action.profile,
        userRole: action.role,
        userType: action.userType,
      };
    case actionTypes.SET_ROLE:
      return {
        ...prevState,
        userRole: action.role,
        userType: action.userType,
      };
    case actionTypes.RESET_PROFILE:
      return {
        ...prevState,
        ...initState,
      };
    case actionTypes.SET_SUSPENDED_MEMBERS:
      if (
        isEqual(
          sortBy(prevState.suspendedMembers),
          sortBy(action.suspendedMembers)
        )
      ) {
        return prevState;
      }
      return {
        ...prevState,
        suspendedMembers: action.suspendedMembers,
      };
    case actionTypes.SET_FLORIST_TYPES:
      return {
        ...prevState,
        floristTypes: action.floristTypes,
      };
    case actionTypes.SET_PROFILE_MEMBER_CODES:
      return {
        ...prevState,
        memberCodes: action.memberCodes,
      };
    case actionTypes.SET_ACCOUNT_MEMBER_CODES:
      return {
        ...prevState,
        memberCodes: action.memberCodes,
      };
  }
};

export default UserProfileReducer;
