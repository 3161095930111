import * as Yup from "yup";
import moment from "moment";
import get from "lodash/get";

export const getExceptionValidationSchema = (
  messages,
  Localise,
  cutoffExceptions
) => {
  return Yup.object().shape({
    label: Yup.string()
      .required(Localise(messages, "Please enter Exception Label"))
      .test(
        "labelTest",
        Localise(messages, "Exception Label already exists"),
        function (value) {
          const exceptionId = get(this, "parent.exceptionId", "");
          const isDuplicatelabel = cutoffExceptions.some(
            (exception) =>
              get(exception, "label", "") === value &&
              get(exception, "exceptionId", "") !== exceptionId
          );
          return !isDuplicatelabel;
        }
      ),
    criteria: Yup.string().required(
      Localise(messages, "Please select Exception Criteria")
    ),
    cityZipInfo: Yup.object().shape({
      city: Yup.string().test(
        "city",
        Localise(messages, "Select City"),
        function (value) {
          if (
            get(this.options, "from.1.value.criteria", "") ===
              "CITY_ZIP_CODE" &&
            !value
          ) {
            return false;
          }
          return true;
        }
      ),
    }),
    day: Yup.string().test(
      "Day Validation",
      `${Localise(messages, "Please select day(s)")}.`,
      function (value) {
        if (value?.length) {
          return true;
        }
        return false;
      }
    ),
    cutOffTime: Yup.string().required(
      Localise(messages, "Please select cutoff Time")
    ),
    startDate: Yup.string().test(
      "startDate",
      Localise(messages, "End date is less than start date."),
      function (value) {
        const { endDate } = this.parent;
        const isEndDateAfterStart = !value || !moment(value).isAfter(endDate);
        if (isEndDateAfterStart) {
          return true;
        }
        return false;
      }
    ),
    endDate: Yup.string().test(
      "endDate",
      Localise(messages, "End date is less than start date."),
      function (value) {
        const { startDate } = this.parent;
        const isEndDateAfterStart = !value || !moment(startDate).isAfter(value);
        if (isEndDateAfterStart) {
          return true;
        }
        return false;
      }
    ),
  });
};

export const getValidationSchema = (Localise, messages) => {
  const cutOffTimeValidation = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ].reduce((accum, day) => {
    accum[day] = Yup.string().required(
      Localise(messages, "Select cutoff time")
    );

    return accum;
  }, {});

  return Yup.object().shape({
    deliveryCutoffTimes: Yup.object().shape(cutOffTimeValidation),
  });
};
