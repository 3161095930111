import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";

export const initialState = {
  status: "progress",
  apiResponse: {
    giftCards: [],
  },
  apiError: {
    giftCards: "",
    giftCardDetails: "",
    orders: "",
  },
  data: {
    giftCards: [],
    totalRecords: 0,
    giftCardDetails: {},
    orders: [],
  },
  extendedScreen: {
    showUpsert: false,
    status: "",
    giftCardId: "",
    storeOrigin: "",
    orderId: "",
    limit: 0,
    menuAction: "",
    recordAction: "",
  },
  actions: {
    search: "",
    filters: [],
    sort: "",
    shops: [],
    limit: {
      giftCards: 0,
    },
  },
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: `view::${sliceName}`,
  initialState,
  reducers: {
    setPageInitialize: (state) => {
      state.status = "progress";
      state.extendedScreen = initialState.extendedScreen;
    },
    setPageInitSuccess: (state) => {
      state.status = "done";
    },
    setPageInitFailure: (state) => {
      state.status = "fail";
    },
    setPageReset: (state) => {
      Object.assign(state, initialState);
    },
    setApiError: (state, action) => {
      const { section, error } = action.payload;
      state.apiError[section] = error;
    },
    setApiResponse: (state, action) => {
      const { section, giftCards } = action.payload;
      state.apiResponse[section] = giftCards;
      state.apiError[section] = "";
    },
    setPageData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },

    setPageAction: (state, action) => {
      const { type, value } = action.payload;
      if (["shop"].includes(type))
        state.actions[type] = [...new Set([].concat(...value))];
      else if (type === "limit") {
        const { section } = action.payload;
        state.actions[type][section] = value;
      } else state.actions[type] = value;
    },
    setCurrentGiftCard: (state, action) => {
      if (action.payload) {
        const { type, value } = action.payload;
        if (type === "giftCardId") {
          state.extendedScreen = {
            ...initialState.extendedScreen,
            showUpsert: true,
            giftCardId: value,
          };
        } else {
          state.extendedScreen[type] = value;
        }
      } else {
        state.data = {
          ...state.data,
          giftCardDetails: {},
          orders: [],
        };
        state.extendedScreen = initialState.extendedScreen;
      }
    },

    setSideCarInitialize: (state) => {
      state.extendedScreen.status = "progress";
    },
    setSideCarInitSuccess: (state) => {
      state.extendedScreen.status = "done";
    },
    setSideCarInitFailure: (state) => {
      state.extendedScreen.status = "fail";
    },

    setStoreOrigin: (state, action) => {
      const { storeOrigin } = action.payload;
      state.extendedScreen.storeOrigin = storeOrigin;
    },

    fetchGiftCardDetails: () => {},
    fetchGiftCardActivityDetails: () => {},
    fetchAllGiftCards: () => {},
    resendGiftCard: () => {},
  },
});

export const {
  setPageInitialize,
  setPageInitSuccess,
  setPageInitFailure,
  setPageReset,
  setPageData,
  setApiResponse,
  setApiError,
  setPageAction,
  setCurrentGiftCard,
  setSideCarInitialize,
  setSideCarInitSuccess,
  setSideCarInitFailure,
  setStoreOrigin,

  fetchGiftCardDetails,
  fetchGiftCardActivityDetails,
  fetchAllGiftCards,
  resendGiftCard,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
