export const CornerPlacementPositions = [
  { label: "None", value: "None" },
  {
    label: "Top Left",
    value: "Top Left",
  },
  {
    label: "Top Right",
    value: "Top Right",
  },
  {
    label: "Bottom Left",
    value: "Bottom Left",
  },
  {
    label: "Bottom Right",
    value: "Bottom Right",
  },
];

export const bannerOrientationPositions = [
  { label: "None", value: "None" },
  {
    label: "Top",
    value: "Top",
  },
  {
    label: "Bottom",
    value: "Bottom",
  },
];

export const logoOrientationOptions = [
  {
    label: "Top Left",
    value: "Top Left",
  },
  {
    label: "Top Right",
    value: "Top Right",
  },
  {
    label: "Bottom Left",
    value: "Bottom Left",
  },
  {
    label: "Bottom Right",
    value: "Bottom Right",
  },
  { label: "None", value: "None" },
];

export const BannerPlacementPositions = [
  {
    label: "Top",
    value: "Top",
  },
  {
    label: "Bottom",
    value: "Bottom",
  },
  { label: "None", value: "None" },
];

export const logoPlacementOptions = [
  { label: "None", value: "None" },
  {
    label: "Corner Placement (130 x 130px)",
    value: "Corner",
  },
  {
    label: "Banner Placement (380 x 120 px)",
    value: "Banner",
  },
];

export const PlacementPositionsMapping = {
  None: [],
  Corner: CornerPlacementPositions,
  Banner: BannerPlacementPositions,
};

export const PermissionsForDeliveryProviderSettings = {
  ADMIN: true,
};

export const PermissionsForNoServiceZoneSettings = {
  ADMIN: true,
};

/* MSOL-18079: Removed Legacy Providers(vida) */
/* MSOL-18080: Removed Roadie */
export const deliveryProviders = [
  { label: "FTD Delivery Service", value: "burq" },
  // { label: "Roadie Direct", value: "roadie" },
  { label: "DoorDash Direct", value: "doordash" },
  // { label: "Legacy Doordash", value: "vida-doordash" },
  // { label: "Legacy Roadie", value: "vida" },
];

export const deliveryPickupTime = [
  { label: "Morning", value: "Morning" },
  { label: "Afternoon", value: "Afternoon" },
  { label: "Morning & Afternoon", value: "Morning & Afternoon" },
  { label: "Every 30 minutes", value: "Every 30 minutes" },
];
