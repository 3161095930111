import React from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import styles from "components/containers/listing-new/styles";

const Item = ({ index, data, columns }) => {
  return (
    <View
      style={{
        minWidth: 300,
      }}
      // testID={index}
      // accessibilityLabel={index}
    >
      <View style={styles.container} fsClass="fs-unmask">
        <View style={[styles.row]} fsClass="fs-unmask">
          {columns?.map(({ column, columnWidth }, index) => (
            <Text
              numberOfLines={2}
              style={[styles.column, { width: columnWidth }]}
              key={index}
            >
              {column === "amountPaid" ? `$${data[column]}` : data[column]}
            </Text>
          ))}
        </View>
      </View>
    </View>
  );
};

export default Item;
