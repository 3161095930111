import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, TouchableOpacity, Image } from "react-native";
import { Text } from "react-native-elements";

import { TabBar, Spinner, ToasterHandler } from "components/elements";
import {
  LabelField,
  Section,
  CollectionSearch,
  ConfirmModal,
} from "components/wrappers";
import { Form, FormFieldSwitch } from "components/elements/forms";

import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import {
  selectApiResponse,
  selectExtendedScreen,
  selectFuneralAccordions,
  selectFuneralTabs,
  selectAllCollections,
} from "library/sagas/views/home/drawer/ftd-admin/mol-settings/selector";
import {
  setApiResponse,
  fetchMiddlewareData,
  saveMiddlewareData,
  setComponentStatus,
} from "library/sagas/views/home/drawer/ftd-admin/mol-settings/slice";
import { selectAllowSyndicate } from "library/sagas/views/home/drawer/mercury-online/selector";

import { fonts, theme } from "styles/theme";
import tw from "tailwind-rn";
import isArray from "lodash/isArray";
import { useFormikContext } from "formik";
import IMAGES from "static/assets/images";

import { getValidationSchema } from "../yup";
import {
  BannerMetaData,
  ImageUploadField,
  CustomRTE,
  CustomInput,
  SaveCancelButtons,
} from "../../helper";

const FuneralPageAccordions = ({
  page,
  defaultContext,
  defaultAssetId,
  overrideHeader,
  funeralPageContent,
}) => {
  const dispatch = useDispatch();
  const allowSyndicate = useSelector(selectAllowSyndicate);
  const apiResponse = useSelector(selectApiResponse);
  const { context = defaultContext, assetId = defaultAssetId } =
    useSelector(selectExtendedScreen);
  const tabNames = useSelector(selectFuneralTabs);
  const { isMobile } = useContext(DeviceContext);
  const { Localise, messages } = useContext(I18NContext);
  const sectionRef = React.useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  const {
    fetching,
    component,
    content,
    error,
    title,
    activeTab,
    actualContext,
  } = page;
  const displayTabNames = tabNames.map((t) => ({
    ...t,
    title:
      component === "funeralSympathy" && t.title === "Collection Sets"
        ? "Collection Tiles"
        : t.title,
  }));

  const validationSchema = getValidationSchema(
    component,
    title,
    Localise,
    messages
  );

  const onSubmit = (values, formikBag) => {
    dispatch(
      saveMiddlewareData({
        params: { values },
        resolve: () => {
          ToasterHandler(
            "looks good",
            Localise(messages, `${title} content has been updated.`)
          );
          formikBag.setSubmitting(false);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  };

  useEffect(() => {
    dispatch(fetchMiddlewareData({ component, context, assetId }));
  }, []);

  if (fetching || apiResponse?.component !== "landingPageControls")
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );

  return (
    <Form
      initialValues={{
        component,
        context,
        assetId,
        content,
        error,
        pageControls: apiResponse?.content ?? "",
      }}
      onSubmit={allowSyndicate ? () => setShowPopup(!showPopup) : onSubmit}
      validationSchema={validationSchema}
      render={({ values, ...formikBag }) => {
        const pageControls =
          values?.pageControls && JSON.parse(values.pageControls);
        const { funeralSympathy } = pageControls;
        const pageStatus =
          component !== "funeralSympathy"
            ? pageControls?.funeralSympathy?.[component]?.status ?? false
            : true;
        const apiContent =
          apiResponse?.content && JSON.parse(apiResponse?.content);
        const showSection =
          component !== "funeralSympathy"
            ? apiContent?.funeralSympathy?.[component]?.status ?? false
            : true;

        const setValue = (field, value) => {
          formikBag.setFieldValue(field, JSON.stringify(value));
        };
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          dispatch(setComponentStatus({ component, dirty: formikBag.dirty }));
        }, [formikBag.dirty]);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          !showSection && sectionRef.current.setAccordion(false);
        }, [showSection]);

        return (
          <Section
            title={title}
            defaultOpen={false}
            disableAccordion={!showSection}
            parentRef={sectionRef}
          >
            {overrideHeader &&
              overrideHeader({
                component,
                actualContext,
                context,
                assetId,
                title,
              })}
            {component !== "funeralSympathy" && (
              <View style={tw("flex flex-row items-center px-2 pt-1 pb-2")}>
                <Text
                  style={{
                    ...fonts.heading4,
                    marginRight: 10,
                  }}
                >
                  {Localise(messages, `Enable ${title} Landing Page`)}
                </Text>
                <FormFieldSwitch
                  value={pageStatus}
                  onValueChange={(val) => {
                    const collections = funeralPageContent.collectionSets.map(
                      (set) =>
                        component === set.id ? { ...set, show: val } : set
                    );
                    dispatch(
                      setApiResponse({
                        component: "funeralSympathy",
                        content: JSON.stringify({
                          ...funeralPageContent,
                          collectionSets: collections,
                        }),
                      })
                    );
                    setValue("pageControls", {
                      ...pageControls,
                      funeralSympathy: {
                        ...funeralSympathy,
                        [component]: { status: val },
                      },
                    });
                  }}
                />
                {!isMobile && (
                  <LabelField
                    text={
                      pageStatus
                        ? Localise(messages, "Active")
                        : Localise(messages, "Inactive")
                    }
                    style={{
                      ...fonts.heading5,
                      paddingHorizontal: 10,
                      marginTop: 3,
                    }}
                  />
                )}
              </View>
            )}
            <View style={tw("flex flex-col px-2 py-3")}>
              <TabBar
                containerStyle={tw("w-full")}
                tabNames={displayTabNames}
                currentTab={activeTab}
                style={{
                  marginTop: -10,
                  justifyContent: "space-between",
                }}
                scrollTabBar
                setCurrentTab={(activeTab) =>
                  dispatch(
                    setApiResponse({
                      component,
                      activeTab,
                    })
                  )
                }
              />
              {activeTab === "banner" ? (
                <BannerMetaData field={"content"} group={"banner"} />
              ) : activeTab === "collection_list" ? (
                component === "funeralSympathy" ? (
                  <SympathyCollectionSets />
                ) : (
                  <FuneralCollectionSets />
                )
              ) : activeTab === "featured_collection" ? (
                <FeaturedCollection />
              ) : (
                <SEOContent />
              )}
            </View>
            <SaveCancelButtons />
            <ConfirmModal
              modalVisible={!!showPopup}
              handlePrimary={() => {
                onSubmit(values, formikBag);
                setShowPopup(!showPopup);
              }}
              handleSecondary={() => {
                setShowPopup(!showPopup);
                formikBag.setSubmitting(false);
              }}
              data={{
                modal: {
                  primary: "Update",
                  content: Localise(
                    messages,
                    "This change will apply to All shops and will override any local shop changes."
                  ),
                },
              }}
            />
          </Section>
        );
      }}
    />
  );
};

const SympathyCollectionSets = () => {
  const { Localise, messages } = useContext(I18NContext);
  const { isMobile } = useContext(DeviceContext);
  const { setFieldValue, values, errors } = useFormikContext();

  const actualContent = JSON.parse(values.content);
  const { collectionSets } = actualContent;
  const pageControls = values?.pageControls && JSON.parse(values.pageControls);
  const { funeralSympathy } = pageControls;
  const collectionFields = {
    image: "",
    title: "",
    subTitle: "",
    altText: "",
    aspectRatio: "",
    buttonLabel: "",
    buttonLink: "",
    alignment: "center center",
    show: true,
  };
  const collectionsList = isArray(collectionSets)
    ? collectionSets
    : [collectionFields, collectionFields, collectionFields];

  const setValue = (field, value) => {
    setFieldValue(field, JSON.stringify(value));
  };

  return (
    <>
      {collectionsList.map((collection, index) => {
        const pageStatus =
          funeralSympathy?.[collection?.id ?? ""]?.status ?? false;

        return (
          <React.Fragment key={index}>
            <View style={tw("pb-4")}>
              <View style={tw("flex flex-row pb-2")}>
                <Text
                  style={{
                    ...fonts.heading4,
                    paddingLeft: 5,
                    paddingRight: 10,
                  }}
                >
                  {Localise(messages, "Enable")}{" "}
                  {Localise(messages, `${collection?.title ?? ""}`)}
                </Text>
                <FormFieldSwitch
                  value={pageStatus}
                  onValueChange={(val) => {
                    const collections = collectionsList.map((set, i) =>
                      i === index ? { ...set, show: val } : set
                    );
                    setValue("content", {
                      ...actualContent,
                      collectionSets: collections,
                    });
                    setValue("pageControls", {
                      ...pageControls,
                      funeralSympathy: {
                        ...funeralSympathy,
                        [collection?.id ?? ""]: { status: val },
                      },
                    });
                  }}
                />
                {!isMobile && (
                  <LabelField
                    text={
                      pageStatus
                        ? Localise(messages, "Active")
                        : Localise(messages, "Inactive")
                    }
                    style={{
                      ...fonts.heading5,
                      paddingHorizontal: 10,
                      marginTop: 3,
                    }}
                  />
                )}
              </View>
              <Text
                style={{
                  ...fonts.heading4,
                  padding: 5,
                  paddingLeft: 5,
                }}
              >
                {Localise(messages, "Title")}
              </Text>
              <CustomInput
                input={collection?.title ?? ""}
                onChangeText={(_, val) => {
                  const collections = collectionsList.map((set, i) =>
                    i === index ? { ...set, title: val } : set
                  );
                  setValue("content", {
                    ...actualContent,
                    collectionSets: collections,
                  });
                }}
                placeholder={Localise(messages, "Enter Title")}
              />
              <ImageUploadField
                field={"image"}
                title={"Image"}
                imageType={"image"}
                input={collection?.image ?? ""}
                setImageValue={(name, val) => {
                  const collections = collectionsList.map((set, i) =>
                    i === index ? { ...set, [name]: val } : set
                  );
                  setValue("content", {
                    ...actualContent,
                    collectionSets: collections,
                  });
                }}
                dimensions={"1024x1024"}
                dimensionsInfo={
                  "Recommended Square Image size is 1024px x 1024px"
                }
                imageResizeMode={"contain"}
              />
              <View style={tw("my-2")}>
                <Text
                  style={{
                    ...fonts.heading4,
                    padding: 5,
                    paddingLeft: 5,
                  }}
                >
                  {Localise(messages, "SEO Text")}
                </Text>
                <CustomInput
                  input={collection?.description ?? ""}
                  onChangeText={(_, val) => {
                    const collections = collectionsList.map((set, i) =>
                      i === index ? { ...set, description: val } : set
                    );
                    setValue("content", {
                      ...actualContent,
                      collectionSets: collections,
                    });
                  }}
                  placeholder={Localise(messages, "Enter Text")}
                />
              </View>
              <CustomInput
                field={"buttonLabel"}
                title={"Button Label"}
                input={collection?.buttonText}
                onChangeText={(_, value) => {
                  const collections = collectionsList.map((set, i) =>
                    i === index ? { ...set, buttonText: value } : set
                  );
                  setValue("content", {
                    ...actualContent,
                    collectionSets: collections,
                  });
                }}
              />
              <CustomInput
                field={"buttonLink"}
                title={"Button Link"}
                input={collection?.buttonLink}
                onChangeText={(_, value) => {
                  const collections = collectionsList.map((set, i) =>
                    i === index ? { ...set, buttonLink: value } : set
                  );
                  setValue("content", {
                    ...actualContent,
                    collectionSets: collections,
                  });
                }}
              />
              {index < collectionsList.length - 1 && (
                <View
                  style={{
                    borderBottomColor: "#bbbbbb",
                    borderBottomWidth: 1,
                    padding: 10,
                  }}
                />
              )}
            </View>
          </React.Fragment>
        );
      })}
      {errors ? (
        <Text style={theme.CheckBox.errorStyle}>{errors.content}</Text>
      ) : null}
    </>
  );
};

const FuneralCollectionSets = () => {
  const { data: collectionData, error } = useSelector(selectAllCollections);
  const { setFieldValue, values, errors, submitCount } = useFormikContext();
  const { Localise, messages } = useContext(I18NContext);

  const actualContent = JSON.parse(values.content);
  const { collectionSets } = actualContent;
  const { collections = [] } = collectionSets;

  const setValue = (field, value) => {
    setFieldValue(field, JSON.stringify(value));
  };

  return (
    <>
      <Text
        style={{
          ...fonts.heading4,
          padding: 5,
          paddingLeft: 5,
        }}
      >
        {Localise(messages, "Header Text")}
      </Text>
      <CustomInput
        input={collectionSets?.title ?? ""}
        onChangeText={(_, val) => {
          setValue("content", {
            ...actualContent,
            collectionSets: { ...collectionSets, title: val },
          });
        }}
        placeholder={Localise(messages, "Enter Title")}
      />
      {collections.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Text
              style={{
                ...fonts.heading4,
                padding: 5,
                paddingLeft: 5,
              }}
            >
              {Localise(messages, `Collection ${index + 1}`)}
            </Text>
            <View style={tw("flex flex-row items-center")}>
              <CollectionSearch
                setInputData={(val) => {
                  collections[index] = val;
                  setValue("content", {
                    ...actualContent,
                    collectionSets: {
                      ...collectionSets,
                      collections,
                    },
                  });
                }}
                selectorData={collectionData}
                error={error}
                inputValue={item ?? ""}
                type={"handle"}
                referenceData={collections.map((handle) => {
                  return {
                    handle,
                  };
                })}
                popperPlacement={"top"}
              />
              <TouchableOpacity
                onPress={() => {
                  collections.splice(index, 1);
                  setValue("content", {
                    ...actualContent,
                    collectionSets: {
                      ...collectionSets,
                      collections,
                    },
                  });
                }}
                testID={"Remove_Collection_Type"}
                style={tw("pl-1 mb-3")}
              >
                <Image
                  style={{
                    width: 17,
                    height: 17,
                    transform: [{ rotate: "90deg" }],
                  }}
                  resizeMode={"cover"}
                  source={IMAGES["close"]}
                />
              </TouchableOpacity>
            </View>
          </React.Fragment>
        );
      })}
      <TouchableOpacity
        testID={"AddNewCollection"}
        accessibilityLabel={"AddNewCollection"}
        style={{ padding: 5 }}
      >
        <Text
          style={fonts.link1}
          onPress={() => {
            collections.push("");
            setValue("content", {
              ...actualContent,
              collectionSets: {
                ...collectionSets,
                collections,
              },
            });
          }}
        >
          {"[+] "}
          {Localise(messages, "Add New Collection")}
        </Text>
      </TouchableOpacity>
      {errors && submitCount > 0 ? (
        <Text style={theme.CheckBox.errorStyle}>{errors.content}</Text>
      ) : null}
    </>
  );
};

const FeaturedCollection = () => {
  const { data: collectionData, error } = useSelector(selectAllCollections);
  const { Localise, messages } = useContext(I18NContext);
  const { setFieldValue, values } = useFormikContext();

  const actualContent = JSON.parse(values.content);
  const { featuredCollection } = actualContent;

  const setValue = (field, value) => {
    setFieldValue(field, JSON.stringify(value));
  };

  return (
    <>
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Header Text")}
      </Text>
      <CustomInput
        input={featuredCollection?.title ?? ""}
        onChangeText={(_, val) => {
          setValue("content", {
            ...actualContent,
            featuredCollection: { ...featuredCollection, title: val },
          });
        }}
        placeholder={Localise(messages, "Enter Header Text")}
      />
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Collection")}
      </Text>
      <CollectionSearch
        setInputData={(val) =>
          setValue("content", {
            ...actualContent,
            featuredCollection: {
              ...featuredCollection,
              collectionHandle: val,
            },
          })
        }
        selectorData={collectionData}
        error={error}
        type={"handle"}
        inputValue={featuredCollection?.collectionHandle ?? ""}
        referenceData={[
          {
            handle: featuredCollection?.collectionHandle,
          },
        ]}
        popperPlacement={"top"}
      />
    </>
  );
};

const SEOContent = () => {
  const { Localise, messages } = useContext(I18NContext);
  const { setFieldValue, values } = useFormikContext();
  const { contentStatus } = values;
  const actualContent = JSON.parse(values.content);
  const { seo } = actualContent;

  const setValue = (field, value) => {
    setFieldValue(field, JSON.stringify(value));
  };

  return (
    <>
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Title")}
      </Text>
      <CustomInput
        input={seo?.footer?.title ?? ""}
        onChangeText={(_, val) => {
          setValue("content", {
            ...actualContent,
            seo: {
              ...seo,
              footer: {
                ...seo.footer,
                title: val,
              },
            },
          });
        }}
        placeholder={Localise(messages, "Enter SEO Title")}
      />
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Description")}
      </Text>
      <CustomRTE
        value={seo?.footer?.entry ?? ""}
        placeholder={Localise(messages, "Enter SEO Description")}
        setValue={(val) => {
          setValue("content", {
            ...actualContent,
            seo: {
              ...seo,
              footer: {
                ...seo.footer,
                entry: val,
              },
            },
          });
        }}
        onEditorStateChange={() => {
          if (contentStatus) return;
          setFieldValue("contentStatus", true);
        }}
      />
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Page Title")}
      </Text>
      <CustomInput
        input={seo?.pageTitle ?? ""}
        onChangeText={(_, val) =>
          setValue("content", {
            ...actualContent,
            seo: {
              ...seo,
              pageTitle: val,
            },
          })
        }
        placeholder={Localise(messages, "Enter Page Title")}
      />
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Page Meta Description")}
      </Text>
      <CustomInput
        input={seo?.pageDescription ?? ""}
        onChangeText={(_, val) =>
          setValue("content", {
            ...actualContent,
            seo: {
              ...seo,
              pageDescription: val,
            },
          })
        }
        placeholder={Localise(messages, "Enter Page Meta Description")}
        multiline={true}
        numberOfLines={4}
        inputStyle={{
          ...theme.Input.inputStyle,
          minHeight: "auto",
          height: "auto",
        }}
        inputContainerStyle={{
          minHeight: "auto",
          height: "auto",
        }}
      />
    </>
  );
};

const FuneralSympathy = ({
  defaultContext,
  defaultAssetId,
  overrideHeader,
}) => {
  const dispatch = useDispatch();
  const { context = defaultContext, assetId = defaultAssetId } =
    useSelector(selectExtendedScreen);
  const accordions = useSelector(selectFuneralAccordions);
  const funeralPageData =
    accordions.length > 0 &&
    accordions
      .map((item) => {
        return item.component === "funeralSympathy" && item.content;
      })
      .filter(Boolean)[0];
  const funeralPageContent = funeralPageData && JSON.parse(funeralPageData);

  useEffect(() => {
    dispatch(
      fetchMiddlewareData({
        component: "landingPageControls",
        context,
        assetId,
      })
    );
  }, []);

  return accordions.map((page, index) => (
    <FuneralPageAccordions
      key={index}
      page={page}
      defaultContext={defaultContext}
      defaultAssetId={defaultAssetId}
      overrideHeader={overrideHeader}
      funeralPageContent={funeralPageContent}
    />
  ));
};

export default FuneralSympathy;
