import React, { useEffect } from "react";
import { View } from "react-native";
import { Form, FormField, SubmitButton } from "components/elements/forms";
import { Text, Button } from "react-native-elements";
import { request } from "library/utils/request";
import tw from "tailwind-rn";
import { fonts, theme } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import * as Yup from "yup";
import useStateIfMounted from "library/utils/useStateIfMounted";

const redirectionTime = 10000;

const ForgotPassword = ({ userId, showLogin }) => {
  const [error, setError] = useStateIfMounted("");
  const [success, setSuccess] = useStateIfMounted("");

  const { messages, Localise } = React.useContext(I18NContext);

  useEffect(() => {}, [error]);

  useEffect(() => {
    if (success)
      setTimeout(() => {
        showLogin(success);
      }, redirectionTime);
  }, [success]);

  const validateForgotPassword = (values, formikBag) => {
    setError("");
    setSuccess("");
    request("non-loggedin-reset-password", {
      email: values.userId,
      type: "forgot",
    })
      .then((res) => {
        setSuccess(Localise(messages, "Password reset email has been sent"));
        formikBag.setSubmitting(false);
      })
      .catch((err) => {
        if (err === "INVALID_ACCOUNT")
          setError(
            Localise(
              messages,
              "We couldn’t find that email – please try again or confirm the email"
            )
          );
        else
          setError(
            Localise(
              messages,
              "Something went wrong, please try again or contact admin."
            )
          );
        formikBag.setSubmitting(false);
      });
  };

  const validationSchema = Yup.object().shape({
    userId: Yup.string()
      .label("userId")
      .email(Localise(messages, "Enter a valid User ID"))
      .required(Localise(messages, "Enter User ID")),
  });

  const emailInfo = Localise(
    messages,
    "Check your email to confirm your account, then reset your password"
  );

  return (
    <Form
      initialValues={{ userId }}
      onSubmit={validateForgotPassword}
      validationSchema={validationSchema}
      render={() => (
        <View>
          {!!error && <Text style={fonts.error}>{error}</Text>}
          {!!success && <Text style={fonts.success}>{success}</Text>}
          <Text
            style={{ ...tw("my-4 ml-2"), maxWidth: 360, textAlign: "justify" }}
          >
            {emailInfo}
          </Text>
          <FormField
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="email-address"
            name="userId"
            placeholder={"Email"}
            textContentType="emailAddress"
            iconName="user"
          />

          <View style={tw("flex flex-row justify-center items-center mx-16")}>
            <Button
              testID={"cancel"}
              accessibilityLabel="cancel"
              title={Localise(messages, "Cancel")}
              titleStyle={theme.Button.secondaryTitleStyle}
              buttonStyle={theme.Button.secondaryButtonStyle}
              onPress={() => showLogin(success)}
            />
            <SubmitButton
              containerStyle={{ marginLeft: 24 }}
              title={"Submit"}
            />
          </View>
        </View>
      )}
    />
  );
};

export default ForgotPassword;
