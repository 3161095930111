export const introVideos = {
  introToMhq: {
    key: "introToMhq",
    linkText: "Introduction to Mercury HQ",
    linkDesc:
      "A basic introduction to the FTD Mercury HQ platform. Learn how to log in and request a password reset.",
    url: "https://www.youtube.com/embed/nGRvz2C3grM",
  },
  homeDashboard: {
    key: "homeDashboard",
    linkText: "Home Dashboard",
    linkDesc:
      "A detailed walk through of the Mercury HQ dashboard options as well as the left hand navigation bar.",
    url: "https://www.youtube.com/embed/GgVF8vmxCzk",
  },
  orderDetails: {
    key: "orderDetails",
    linkText: "Order Details",
    linkDesc:
      "A detailed review of the information included on every order and what that means for your shop.",
    url: "https://www.youtube.com/embed/zk0CWp17T6s",
  },
  acceptingAndRejectingOrders: {
    key: "acceptingAndRejectingOrders",
    linkText: "Accepting and Rejecting Orders",
    linkDesc:
      "In this video, we discuss what to look for on incoming orders before making a decision on whether to accept or reject an order as well as best practices.",
    url: "https://www.youtube.com/embed/Lu6DXMxK1w4",
  },
  chatsRequestsandResponses: {
    key: "chatsRequestsandResponses",
    linkText: "Chats Requests and Responses",
    linkDesc:
      "A review of the different items a florist may see regarding sending messages on orders. Learn how to send date and price changes as well as cancellation requests. View what those messages look like when received by your shop.",
    url: "https://www.youtube.com/embed/9eNhf1SCANM",
  },
  workingOrdersTodayandFuture: {
    key: "workingOrdersTodayandFuture",
    linkText: "Working Orders: Today's and Future",
    linkDesc:
      "This video will walk through a florist’s daily routine while moving their orders through the funnel from received to a completed delivery.",
    url: "https://www.youtube.com/embed/Qtexcm0L7wY",
  },
  sendingAndReceivingDeliveryServices: {
    key: "sendingAndReceivingDeliveryServices",
    linkText: "Sending and Receiving from Delivery Services",
    linkDesc:
      "In this video, we review how to properly schedule an order for delivery with the FTD Delivery Service and what to look for when receiving an order from one of our delivery partners.",
    url: "https://www.youtube.com/embed/gSjMP31pMes",
  },
  outgoingOrders: {
    key: "outgoingOrders",
    linkText: "Outgoing Orders",
    linkDesc:
      "Learn how to send an outgoing order from your Mercury HQ system into the FTD network.",
    url: "https://www.youtube.com/embed/UnDTEKhWmNI",
  },
  adminSettings: {
    key: "adminSettings",
    linkText: "Admin Settings",
    linkDesc:
      "A review of all the settings an admin can adjust in the Mercury HQ system. Also includes how to adjust enclosure card settings.",
    url: "https://www.youtube.com/embed/iqQv-u6tJCA",
  },
};
