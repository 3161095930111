import { Platform } from "react-native";
import {
  IsCanceledByServiceProvider,
  getDSRequestRerouteStatus,
} from "library/utils/deliveryService";

export const DSFetchInterval = 2 * 60 * 1000; // 2 mins
export const AutoPrintDSFetchInterval = 2 * 60 * 1000; // 2 mins

export const UIConfig = [
  {
    key: "approachingDrivers",
    title: "Approaching Drivers",
    displayStatus: "open",
  },
  { key: "needsAction", title: "Needs Action", displayStatus: "open" },
  { key: "activeRequests", title: "Active Requests", displayStatus: "open" },
  {
    key: "completedRequests",
    title: "Completed Requests",
    displayStatus: "open",
  },
  {
    key: "cancelledRequests",
    title: "Canceled Requests",
    displayStatus: "open",
  },
];

export const InitDSRequestsData = {
  approachingDrivers: { list: [] },
  needsAction: { list: [] },
  activeRequests: { list: [] },
  completedRequests: { list: [] },
  cancelledRequests: { list: [] },
};

export const ListOfDeliveryServiceSources = {
  sources: [
    "MHQ_ORDERS",
    "MHQ_DELIVERY_TASKS",
    "MHQ_BULK_IMPORT_DELIVERY_TASKS",
  ],
};

export const ListOfDeliveryTaskStates = {
  set1: {
    start: "1w",
    status: [
      "SCHEDULED",
      "COURIER_ASSIGNED",
      "PICKUP_IN_PROGRESS",
      "AT_PICKUP",
      "PICKUP_COMPLETE",
      "DELIVERY_IN_PROGRESS",
      "AT_DELIVERY",
      "DELIVERED",
      "ATTEMPTED_DELIVERY",
      "ENROUTE_TO_RETURN",
      "ARRIVED_AT_RETURN",
      "CANCELLED",
      "RETURNED",
      "REJECTED",
      "REJECTED_ACK",
      "CANCELLED_ACK",
      "RETURNED_ACK",
      "STAGED",
      "CREATED",
    ],
  },
};

export const ListOfApproachingDriversStates = [
  "COURIER_ASSIGNED",
  "PICKUP_IN_PROGRESS",
  "AT_PICKUP",
  "DELIVERY_IN_PROGRESS",
  "AT_DELIVERY",
  "RETURNED",
  "REJECTED",
  "ATTEMPTED_DELIVERY",
  "ENROUTE_TO_RETURN",
  "ARRIVED_AT_RETURN",
  "CANCELLED",
  "CANCELLED_ACK",
  "REJECTED_ACK",
  "RETURNED_ACK",
  "DELIVERED",
];

export const InitViewMoreCounts = {
  needsAction: 10,
  activeRequests: 10,
  completedRequests: 10,
  cancelledRequests: 10,
};

export const ListOfColumns = [
  { key: "displayDeliveryDate", path: "displayDate" },
  { key: "referenceId", path: "referenceId" },
  { key: "recipientCity", path: "recipientCity" },
  { key: "recipientName", path: "displayName" },
  { key: "productFirstChoiceDescription", path: "items.0.description" },
  { key: "displayPrice", path: "displayPrice" },
];

export const ListOfMobileColumns = [
  { key: "displayPrice", path: "displayPrice" },
  { key: "displayDeliveryDate", path: "displayDate" },
  { key: "referenceId", path: "referenceId" },
  { key: "recipientCity", path: "recipientCity" },
  { key: "recipientZip", path: "delivery.address.zip" },
  { key: "recipientName", path: "displayName" },
  { key: "locationType", path: "delivery.address.locationType" },
  { key: "productFirstChoiceDescription", path: "items.0.description" },
  { key: "item", path: "item" },
];

export const ListOfSubColumns = {
  recipientCity: "delivery.address.zip",
  recipientName: "delivery.address.locationType",
  productFirstChoiceDescription: "item",
};

const IsCopyActionAllowed = (state) =>
  [
    "SCHEDULED",
    "COURIER_ASSIGNED",
    "PICKUP_IN_PROGRESS",
    "AT_PICKUP",
    "PICKUP_COMPLETE",
    "DELIVERY_IN_PROGRESS",
    "AT_DELIVERY",
    "DELIVERED",
    "ATTEMPTED_DELIVERY",
    "ENROUTE_TO_RETURN",
    "ARRIVED_AT_RETURN",
    "REJECTED_ACK",
    "CANCELLED_ACK",
    "RETURNED_ACK",
    "CANCELLED",
    "RETURNED",
    "REJECTED",
    "STAGED",
    "CREATED",
  ].includes(state);

const IsModifyActionAllowed = (state) =>
  ["SCHEDULED", "STAGED", "CREATED"].includes(state);

const IsCancelActionAllowed = (state, notes = []) => {
  return (
    ["SCHEDULED", "RETURNED", "REJECTED", "STAGED", "CREATED"].includes(
      state
    ) || IsCanceledByServiceProvider(state, notes)
  );
};

const IsOrderCancelActionAllowed = (state, notes = []) => {
  return (
    ["RETURNED", "REJECTED"].includes(state) ||
    IsCanceledByServiceProvider(state, notes)
  );
};

const IsCreditRequestAllowed = (state) =>
  [
    "RETURNED",
    "CANCELLED",
    "DELIVERED",
    "CANCELLED_ACK",
    "RETURNED_ACK",
  ].includes(state);

const showReDeliver = (state, source) => {
  const redeliverStates =
    source === "MHQ_ORDERS"
      ? ["DELIVERED"]
      : ["DELIVERED", "RETURNED", "RETURNED_ACK"];
  return redeliverStates.includes(state);
};

const CopyAction = { key: "copy-request", label: "Copy" };
const CancelAction = { key: "cancel", label: "Cancel" };
const ModifyAction = { key: "modify-request", label: "Modify Request" };
const CreditRequectAction = {
  key: "credit-request",
  label: "Credit Request",
};
const ApproveCreditAction = {
  key: "approve-credit",
  label: "Approve Credit",
};
const RedeliverAction = { key: "re-deliver", label: "Redeliver" };

export const ListOfActions = (
  state,
  notes = [],
  dsRequestDetails,
  localPermissions
) => {
  const { events: DSRequestEvents = [], source } = dsRequestDetails;
  const hasRedeliveryForEvent = DSRequestEvents.some(
    (event) => "REDELIVERY_FOR" === event.eventType
  );
  const { showUserActions = false, showCSRActions = false } = localPermissions;

  let listOfActions = [];
  if (showUserActions) {
    if (source === "MHQ_ORDERS") {
      listOfActions.push({ key: "go-to-orders", label: "View Order" });
      const showCancelForOrder =
        ((IsCancelActionAllowed(state, notes) && hasRedeliveryForEvent) ||
          IsOrderCancelActionAllowed(state, notes)) &&
        !getDSRequestRerouteStatus(dsRequestDetails, "rerouting");
      if (showCancelForOrder) {
        listOfActions.push(CancelAction);
      }
    } else {
      if (!["DOORDASH", "BURQ"].includes(dsRequestDetails?.vendor?.name)) {
        if (IsModifyActionAllowed(state)) {
          listOfActions.push(ModifyAction);
        }
      }
      if (IsCancelActionAllowed(state, notes)) {
        listOfActions.push(CancelAction);
      }
      if (IsCopyActionAllowed(state)) {
        listOfActions.push(CopyAction);
      }
    }
    if (showReDeliver(state, source)) {
      listOfActions.push(RedeliverAction);
    }
    if (
      IsCreditRequestAllowed(state) &&
      Platform.OS === "web" &&
      !getDSRequestRerouteStatus(dsRequestDetails, "rerouting")
    ) {
      listOfActions.push(CreditRequectAction);
    }
  }
  if (
    showCSRActions &&
    IsCreditRequestAllowed(state) &&
    !getDSRequestRerouteStatus(dsRequestDetails, "rerouting")
  ) {
    listOfActions.push(ApproveCreditAction);
  }

  return listOfActions;
};

export const ListOfSubActions = {};

export const ListOfFilters = {
  "Date Range": [
    {
      title: "Today",
      value: "0",
    },
    {
      title: "Tomorrow",
      value: "1",
    },
    {
      title: "This Week",
      value: "7",
    },
    {
      title: "This Month",
      value: "this-month",
    },
    { title: "Custom Range", value: "custom-date-range" },
  ],
  "Delivery Service Request Status": [
    {
      title: "Scheduled",
      value: "Scheduled",
    },
    {
      title: "Delivery",
      value: "Delivery",
    },
    {
      title: "Completed",
      value: "Completed",
    },
    {
      title: "Canceled",
      value: "Cancelled",
    },
    {
      title: "Fulfillment Error",
      value: "Fulfillment Error",
    },
  ],
  Type: [
    {
      title: "Orders",
      value: "MHQ_ORDERS",
    },
    { title: "Delivery Service Requests", value: "MHQ_DELIVERY_TASKS" },
    {
      title: "Bulk Requests",
      value: "MHQ_BULK_IMPORT_DELIVERY_TASKS",
    },
  ],
};

export const ListOfSortOptions = [
  { key: "Delivery Date: New to Old", label: "Delivery Date: New to Old" },
  { key: "Delivery Date: Old to New", label: "Delivery Date: Old to New" },
];

export const PermissionsforCreateTask = {
  ADMIN: true,
  MANAGER: true,
  SALES: false,
  DESIGNER: false,
  DRIVER: false,
};

export const deliveryProviders = {
  roadie: "Roadie Direct",
  doordash: "DoorDash Direct",
  burq: "FTD Delivery Service",
};
