import { createSlice } from "@reduxjs/toolkit";

import set from "lodash/set";
import get from "lodash/get";

import { SLICE_NAME as sliceName } from "./constants";
import { SLICE_NAME as parentSlice } from "../constants";

export const initialState = {
  feesCoverage: {
    siteFees: {},
    citiesFees: {},
    citiesZipcodes: [],
    citiesplaceIDs: [],
    cityZipcodesFees: {},
    cities: {},
    deliveryExceptions: {},
    deliveryExceptionCities: {},
    deliveryExceptionZips: {},
    reloadDeliveryZoneMap: true,
  },
  storePickup: {},
  actions: {
    searchCity: { type: "search", value: "" },
    searchZipcode: { type: "search", value: "" },
  },
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: `view::${parentSlice}::${sliceName}`,
  initialState,
  reducers: {
    setApiResponse: (state, action) => {
      const { path, content, isPatch, contentType } = action.payload;
      if (isPatch) {
        if (contentType === "object") {
          const prevContent = get(state, `${path}.content`, {});
          set(state, `${path}.content`, {
            ...prevContent,
            ...content,
          });
        } else {
          const prevContent = get(state, `${path}.content`, []);
          set(state, `${path}.content`, [...prevContent, ...content]);
        }
      } else {
        set(state, `${path}.content`, content);
      }
    },
    setApiError: (state, action) => {
      const { path, error } = action.payload;
      set(state, `${path}.error`, error);
    },
    setSearchCity: (state, action) => {
      state.actions.searchCity = action.payload;
    },
    setSearchZipcode: (state, action) => {
      state.actions.searchZipcode = action.payload;
    },
    setDeliveryZoneMapReload: (state, action) => {
      state.feesCoverage.reloadDeliveryZoneMap = action.payload;
    },

    saveGlobalFees: (state) => {
      state.feesCoverage.siteFees.error = "";
    },
    saveCitiesFees: (state) => {
      state.feesCoverage.citiesFees.error = "";
    },
    saveCitiesZipcodes: (state, action) => {
      const { content } = action.payload;
      const prevContent = get(state, `feesCoverage.citiesZipcodes`, []);
      set(state, `feesCoverage.citiesZipcodes`, [...prevContent, ...content]);
    },
    savecitiesplaceIDs: (state, action) => {
      const prevContent = get(state, `feesCoverage.citiesplaceIDs`, []);
      set(state, `feesCoverage.citiesplaceIDs`, [
        ...prevContent,
        ...action.payload,
      ]);
    },
    saveCityZipcodesFees: (state) => {
      state.feesCoverage.cityZipcodesFees.error = "";
    },
    saveDeliveryExceptions: (state) => {
      state.feesCoverage.deliveryExceptions.error = "";
    },
    saveStorePickup: (state) => {
      state.storePickup.error = "";
    },

    fetchGlobalFees: () => {},
    fetchCitiesZipcodes: () => {},
    fetchCitiesFees: () => {},
    fetchCityZipcodesFees: () => {},
    fetchCities: () => {},
    fetchDeliveryExceptions: () => {},
    fetchCitiesForExceptions: () => {},
    fetchZipCodesForExceptions: () => {},
    fetchStorePickup: () => {},
    fetchStorePickupStatus: () => {},
    fetchBasicInfo: () => {},

    clearCitiesZipcodes: (state) => {
      state.feesCoverage.citiesZipcodes = [];
      state.feesCoverage.citiesplaceIDs = [];
    },
    clearCityZipcodesFees: () => {},

    deleteDeliveryExceptions: () => {},
  },
});

export const {
  setApiResponse,
  setApiError,
  setSearchCity,
  setSearchZipcode,
  setDeliveryZoneMapReload,

  saveGlobalFees,
  saveCitiesFees,
  saveCitiesZipcodes,
  savecitiesplaceIDs,
  saveCityZipcodesFees,
  saveDeliveryExceptions,
  saveStorePickup,

  fetchGlobalFees,
  fetchCitiesZipcodes,
  fetchCitiesFees,
  fetchCityZipcodesFees,
  fetchCities,
  fetchDeliveryExceptions,
  fetchCitiesForExceptions,
  fetchZipCodesForExceptions,
  fetchStorePickup,
  fetchStorePickupStatus,
  fetchBasicInfo,

  clearCitiesZipcodes,
  clearCityZipcodesFees,

  deleteDeliveryExceptions,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
