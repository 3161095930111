import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "react-native";
import { SaveCancelButtons } from "components/wrappers";
import {
  ToasterHandler,
  Spinner,
  AccordionWithBorder,
} from "components/elements";
import { Form } from "components/elements/forms";

import I18NContext from "library/contexts/i18N";
import { selectShopHours } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { selectDeliveryCutoffTimes } from "library/sagas/views/home/drawer/shop-settings/delivery-cutoff-times/selector";
import { saveDeliveryCutoffTimes } from "library/sagas/views/home/drawer/shop-settings/delivery-cutoff-times/slice";

import CutoffTimes from "./input";
import CutoffExceptions from "./cutoff-exceptions";
import { getValidationSchema } from "./yup";

const DeliveryCutoffTimes = () => {
  const dispatch = useDispatch();
  const { content: initialValues } = useSelector(selectDeliveryCutoffTimes);
  const { content: shopHours } = useSelector(selectShopHours);
  const { messages, Localise } = React.useContext(I18NContext);

  const validationSchema = getValidationSchema(Localise, messages);

  if (!initialValues && !shopHours) {
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );
  }

  function onSubmit(values, formikBag) {
    try {
      dispatch(
        saveDeliveryCutoffTimes({
          params: values,
          resolve: () => {
            ToasterHandler(
              "nice",
              Localise(messages, "Delivery cutoff times have been updated")
            );
            formikBag.setSubmitting(false);
          },
          reject: () => {
            ToasterHandler(
              "uh oh",
              Localise(
                messages,
                "We were unable to process your request, please try again."
              )
            );
            formikBag.setSubmitting(false);
          },
        })
      );
    } catch (error) {
      console.log("error >>", error);
    }
  }

  return (
    <AccordionWithBorder title={Localise(messages, "Delivery Cutoff Times")}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        render={() => (
          <>
            <CutoffTimes shopHours={shopHours} />
            <SaveCancelButtons
              buttonTitle={Localise(messages, "Save")}
              customContainerStyles={{
                width: 100,
                marginLeft: 0,
              }}
            />
            <CutoffExceptions />
          </>
        )}
      />
    </AccordionWithBorder>
  );
};

export default DeliveryCutoffTimes;
