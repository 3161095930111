import React from "react";
import { View, Text } from "react-native";
import I18NContext from "library/contexts/i18N";

const SessionInfo = (props) => {
  const {
    sessionInfo: { sessionId = "", status = "Inactive", memberId = "" } = {},
    isMultimemberAccount = false,
  } = props;
  const { messages, Localise } = React.useContext(I18NContext);

  return (
    <View style={{ paddingHorizontal: 5 }}>
      <Text style={{ fontWeight: "bold", paddingBottom: 5 }}>
        {`${Localise(messages, `Session `)} #${sessionId}`}
      </Text>
      <Text style={{ paddingBottom: 5, textTransform: "capitalize" }}>
        {Localise(messages, status)}
      </Text>
      {isMultimemberAccount && (
        <Text style={{ paddingBottom: 5 }}>{`${Localise(
          messages,
          "Shop:"
        )} ${memberId}`}</Text>
      )}
    </View>
  );
};

export default SessionInfo;
