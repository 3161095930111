import React from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import WebsiteInput from "./input";
import { getValidationSchema } from "./yup";
import { SaveCancelButtons } from "../helper";
import { InputFields } from "./ui-config";
import { ToasterHandler, Spinner } from "components/elements";
import { Form } from "components/elements/forms";
import UserProfileContext from "library/contexts/userProfile";

import I18NContext from "library/contexts/i18N";
import { selectWebsiteSettings } from "library/sagas/views/home/drawer/mercury-online/selector";
import { saveWebsiteSettings } from "library/sagas/views/home/drawer/mercury-online/slice";

const WebsiteSettings = () => {
  const dispatch = useDispatch();
  const { content: initialValues } = useSelector(selectWebsiteSettings);
  const { messages, Localise } = React.useContext(I18NContext);
  const {
    userProfile: { type: userType },
  } = React.useContext(UserProfileContext);

  const isFTDUser = userType === "FTD_USER";

  const mtacFields = [
    "additionalScripts",
    "googleTagManagerId",
    "googleAnalyticsId",
  ];
  const FTDUserOnlyInputFields = isFTDUser
    ? InputFields
    : InputFields.filter((item) => !mtacFields.includes(item.name));

  if (!initialValues) {
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );
  }

  function onSubmit(values, formikBag) {
    dispatch(
      saveWebsiteSettings({
        params: values,
        resolve: () => {
          ToasterHandler(
            "nice",
            Localise(messages, "Your Website Settings have been updated.")
          );

          formikBag.setSubmitting(false);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      validationSchema={getValidationSchema(Localise, messages)}
      validateOnBlur={true}
      render={() => {
        return (
          <>
            {FTDUserOnlyInputFields.map((data, index) => (
              <WebsiteInput data={data} key={index} />
            ))}

            <SaveCancelButtons />
          </>
        );
      }}
    />
  );
};

export default WebsiteSettings;
