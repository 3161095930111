import React from "react";
import { View, TouchableOpacity, Image } from "react-native";
import { Text } from "react-native-elements";
import { connect, useDispatch } from "react-redux";
import { useNavigation, CommonActions } from "@react-navigation/native";

import I18NContext from "library/contexts/i18N";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";

import { fonts } from "styles/theme";
import tw from "tailwind-rn";

const SuspendHeader = ({
  suspendedMembers = [],
  memberCodes: allShopCodes = [],
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const suspendCount = suspendedMembers.length;
  if (!suspendCount || !allShopCodes.length) return null;
  const { messages, Localise } = React.useContext(I18NContext);

  return (
    <View
      style={[
        tw("flex flex-row justify-center items-center"),
        { backgroundColor: "#e5175e1a", height: 50 },
      ]}
    >
      <View style={tw("flex flex-row justify-center items-center w-11/12")}>
        <Image
          style={{ width: 30, height: 30, marginRight: 15 }}
          resizeMode="cover"
          source={require("static/assets/suspend-alert.png")}
        />
        <Text style={{ ...fonts.heading3, fontSize: 15, color: "#414141" }}>
          {allShopCodes.length === 1 &&
            suspendCount === 1 &&
            Localise(messages, "You are currently suspended")}
          {allShopCodes.length > 1 &&
            suspendCount === 1 &&
            Localise(messages, "One of your shops is suspended")}
          {suspendCount > 1 &&
            `${suspendCount} ${Localise(
              messages,
              "shops are currently suspended"
            )}`}
        </Text>
        <TouchableOpacity
          style={{ marginLeft: 10 }}
          onPress={() => {
            dispatch(setCurrentPage("shop-settings"));
            navigation.dispatch(
              CommonActions.navigate({
                name: "shop-settings",
                params: {
                  selectedSuspendedCode: suspendedMembers,
                },
              })
            );
          }}
          testID="suspend_resume"
          accessibilityLabel="suspend_resume"
        >
          <Text style={{ ...fonts.link1, fontSize: 15, fontWeight: "600" }}>
            {Localise(messages, "Resume")}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    selectedShopCode: state.mhq.ongoing.global.shopCode,
  };
};

export default connect(mapStateToProps, null)(SuspendHeader);
