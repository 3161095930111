/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef } from "react";
import { Text, Platform } from "react-native";
import { request } from "library/utils/request";
import UtilStorage from "library/storage/utilStorage";
import { CustomModal } from "components/elements";
import I18NContext from "library/contexts/i18N";
import { colors, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";
import ReloadTabs from "./reload-Tabs";
import Environment from "library/utils/environment";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { UpdatePopupRequiredTabs } from "components/containers/tabs/constants";
import { compareVersions } from "library/utils/formatter";

const LoadNewBundle = () => {
  // This functionality is only for the web so otherthan web return it.
  if (Platform.OS !== "web") return null;
  const [modalVisible, setModalVisibile] = useStateIfMounted(false);
  const { messages, Localise } = React.useContext(I18NContext);
  const pollIntervalForPullingConfigMap = Environment.get(
    "POLL_INTERVAL_FOR_PULLING_CONFIG_MAP",
    30 * 60 * 1000
  );
  let pollTimer = useRef(null);
  const latestVersion = useRef(null);
  
  const pullBuildInfo = () => {
     request("get-build-info", { platform: Platform.OS }).then((buildInfo) => {
       const currentRelease = UtilStorage.getReleaseVersion("CurrentVersion");
       const { config: { version = "" } = {} } = buildInfo;
       if (compareVersions(currentRelease, version) !== 0) {
         latestVersion.current = version;
        if (checkUpdatePopupisRequired(document.title)) {
          setModalVisibile(true);
        } else {
          // Automatically update to the new version if not focused
          window.location.reload();
          handleRefresh();
        }
      }
     });
   };

   const checkUpdatePopupisRequired = (title) => {
    if (title) {
      const formattedTitle = title.toLowerCase().replace(/\s+/g, ''); // Convert to lowercase and remove spaces
      return Object.values(UpdatePopupRequiredTabs)
        .map(value => value.toLowerCase().replace(/\s+/g, '')) // Convert values to lowercase and remove spaces
        .includes(formattedTitle);
    } else {
      return false;
    }
  };

 
  const handleRefresh = () => {
    //Do the refresh
    const version = latestVersion.current || "";
    UtilStorage.setReleaseVersion(version);
    const channel = new BroadcastChannel('reload-channel');
    channel.postMessage(JSON.stringify({ text: 'NewReleaseVersion', version: version }));
    channel.close();
  };

  const handleClose = () => {
    const normalizedTitle = document?.title?.toLowerCase()?.replace(/\s+/g, '')
    const isCreateOrEditOrder = normalizedTitle === 'createorder' || normalizedTitle === 'editorder';
    if (isCreateOrEditOrder) {
      handleRefresh();
      window.location.reload();
    }
    else {
      setModalVisibile(false);
    }
   
  };

  const handleSave = () => {
  const normalizedTitle = document?.title?.toLowerCase()?.replace(/\s+/g, '')
  const isCreateOrEditOrder = normalizedTitle === 'createorder' || normalizedTitle === 'editorder';
    if (isCreateOrEditOrder) {
      const channel = new BroadcastChannel('reload-channel-SaveDraft');
      channel.postMessage('SaveDraft');
      channel.close();
    } 
     handleRefresh();
     window.location.reload();
  };
  const normalizedTitle = document?.title?.toLowerCase()?.replace(/\s+/g, '')
  const isCreateOrEditOrder = normalizedTitle === 'createorder' || normalizedTitle === 'editorder';
  const modalContent = {
    content: (
      isCreateOrEditOrder? 
      <Text
        style={{
          ...tw("p-4"),
          fontSize: 15,
          color: colors.highlighter,
        }}
      >
        {Localise(messages, "New Update Available.")}
        {"\n"}
        {Localise(messages, "Screen will refresh, save your work.")}
      </Text>
      :
      <Text
        style={{
          ...tw("p-4"),
          fontSize: 15,
          color: colors.highlighter,
        }}
      >
        {Localise(messages, "New Update Available.")}
        {"\n"}
        {Localise(messages, "Would you like to refresh the screen?")}
      </Text>
    ),
    buttons: isCreateOrEditOrder ? [
      { type: "secondary", title: Localise(messages, "Cancel Draft") },
      { type: "primary", title: Localise(messages, "Save Draft") },
    ] : [
      { type: "secondary", title: Localise(messages, "Nevermind") },
      { type: "primary", title: Localise(messages, "Continue") },
    ],
  };

  useEffect(() => {
    if (pollTimer) clearInterval(pollTimer.current);
    pollTimer.current = setInterval(pullBuildInfo, pollIntervalForPullingConfigMap);
    return () => {
      clearInterval(pollTimer.current);
    };
  }, []);

  return (
    <>
    <CustomModal
      modalVisible={modalVisible}
      modalContent={modalContent}
      primaryhandler={handleSave}
      secondaryhandler={handleClose}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: backgroundColors.secondary, textAlign: "center" },
      ]}
    >
    </CustomModal>
     <ReloadTabs />
     </>
  );
};

export default LoadNewBundle;