import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";

export const initialState = {
  activeTab: "training-materials",
  activeSubTab: "mhq-sub-tab",
  tabs: [
    { key: "training-materials", title: "Training Materials" },
    { key: "release-notes", title: "Release Notes" },
    { key: "contact-us", title: "Contact Us" },
  ],
  subTabs: [
    { key: "mhq-sub-tab", title: "Mercury HQ" },
    { key: "mol-sub-tab", title: "Mercury Online" },
  ],
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: `view::${sliceName}`,
  initialState,
  reducers: {
    setActiveHelpTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setActiveHelpSubTab: (state, action) => {
      state.activeSubTab = action.payload;
    },
  },
});

export const { setActiveHelpTab, setActiveHelpSubTab } = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
