import ProductInfo from "../product-info";
import RecipientInfo from "../recipient-info";
import DeliveryInfo from "../delivery-info";
import FloristPartner from "../florist-partner";
import FloristPartnerDisplay from "../florist-partner/florist-display";
import ExternalPartner from "../external-partner";
import OrderSummary from "../order-summary";

export const getUIConfig = (isEditOrder = false, isSubscription = false) => {
  return {
    product: {
      title: "Product",
      component: ProductInfo,
      overrideZindex: 4,
    },
    recipient: {
      title: "Recipient",
      component: RecipientInfo,
      overrideZindex: 3,
    },
    deliveryDetails: {
      title: !isSubscription ? "Delivery" : "Subscription",
      component: DeliveryInfo,
      overrideZindex: 2,
    },
    floristPartner: {
      title: "Florist Partner",
      component: !isEditOrder ? FloristPartner : FloristPartnerDisplay,
      overrideZindex: 1,
    },
    externalPartner: {
      title: "Florist Partner",
      component: ExternalPartner,
      overrideZindex: 1,
    },
    orderSummary: {
      title: "Order Summary",
      component: OrderSummary,
      overrideZindex: 0,
    },
  };
};

export const quickSaleUIConfig = {
  product: {
    title: "Product",
    component: ProductInfo,
    overrideZindex: 4,
  },
  orderSummary: {
    title: "Order Summary",
    component: OrderSummary,
    overrideZindex: 3,
  },
};
