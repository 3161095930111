import React, { useContext } from "react";

import { View, Text, TouchableOpacity, ScrollView, Image } from "react-native";
import { Badge } from "react-native-elements";
import IMAGES from "static/assets/images";

import { backgroundColors, colors } from "styles/theme";

import tw from "tailwind-rn";

import I18NContext from "library/contexts/i18N";

import "./scrollbar";

const TabBar = ({
  scrollTabBar = false,
  scrollContainerStyle = {},
  showsScrollIndicators = false,
  showsScrollButtons = false,
  ...props
}) => {
  return scrollTabBar ? (
    <View style={scrollContainerStyle}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        horizontal={true}
        showsHorizontalScrollIndicator={showsScrollIndicators}
        nativeID={showsScrollButtons ? "scrollButtons" : ""}
      >
        <TabBarContent {...props} />
      </ScrollView>
    </View>
  ) : (
    <TabBarContent {...props} />
  );
};

const TabBarContent = ({
  tabNames,
  subTabNames,
  subTabImages,
  style,
  tabStyle,
  containerStyle = {},
  currentTab,
  disabled = false,
  setCurrentTab,
  showBorder = true,
  showDivider = false,
  siblings,
  removeTab = false,
  setTabsCount,
  isColumnView = false,
  activeTabStyle = {},
  inactiveTabStyle = {},
  activeSubTabStyle = {},
  inactiveSubTabStyle = {},
  customBorder,
  disableTabs,
  notificationTabs = false,
  notificationTabsWidth = { width: 200 },
  notificationSettings = [],
}) => {
  const { Localise, messages } = useContext(I18NContext);
  const Component = !disableTabs ? TouchableOpacity : View;

  return (
    <View style={containerStyle}>
      <View
        style={[
          tw(`flex flex-${isColumnView ? `col` : `row`} bg-white flex-wrap`),
          style,
        ]}
      >
        {tabNames.map((item, index) => {
          const isActiveTab = item.key === currentTab;
          const borderColorKey = isColumnView
            ? "borderLeftColor"
            : "borderBottomColor";
          const borderStyle = isColumnView
            ? "border-l-4 pl-2"
            : "border-b-2 pb-1";

          return (
            <Component
              key={item.key}
              disabled={disabled}
              style={[
                tw(`flex flex-row py-4 px-2`),
                tabStyle,
                showDivider &&
                  index !== tabNames.length - 1 && {
                    borderRightWidth: 2,
                  },
              ]}
              onPress={() => {
                setCurrentTab(item.key);
              }}
            >
              <View
                style={[
                  tw(
                    `mr-1 flex-row ${
                      isActiveTab ? (!customBorder ? borderStyle : "") : ""
                    }`
                  ),
                  {
                    [`${borderColorKey}`]: isActiveTab
                      ? backgroundColors.primary
                      : backgroundColors.secondary,
                  },
                ]}
              >
                {!!customBorder &&
                  (isActiveTab ? (
                    customBorder()
                  ) : (
                    <View style={{ width: activeTabStyle.margin }}></View>
                  ))}
                <Text
                  style={[
                    tw(
                      `flex flex-row items-center ${
                        isActiveTab
                          ? activeTabStyle.size || "font-semibold"
                          : inactiveTabStyle.size || "font-normal"
                      }`
                    ),
                    {
                      color: isActiveTab
                        ? activeTabStyle.color || backgroundColors.primary
                        : disabled
                        ? colors.grayScaleLight
                        : inactiveTabStyle.color || backgroundColors.black,
                    },
                    notificationTabs && notificationTabsWidth,
                  ]}
                  testID={`${item.key}`}
                >
                  {Localise(messages, item.title)}
                </Text>
                {item.error && (
                  <View style={{ marginLeft: 8 }}>
                    <Badge
                      containerStyle={[
                        {
                          right: 0,
                          position: "absolute",
                        },
                      ]}
                      badgeStyle={{ backgroundColor: "#e5175e" }}
                    />
                  </View>
                )}
                {notificationTabs && (
                  <>
                    {subTabNames.map((subTabItem, index) => {
                      const currentTabDefaultValues =
                        notificationSettings?.find(
                          (obj) => obj?.id === item.key
                        )?.values || {};

                      const subTabsActiveStatus = [
                        currentTabDefaultValues?.smsToggle,
                        currentTabDefaultValues?.emailToggle,
                      ];

                      return (
                        <Text
                          key={subTabItem?.key}
                          style={tw("flex flex-row justify-around")}
                        >
                          <Image
                            style={{
                              width: 18,
                              height: 18,
                              marginTop: 8,
                              marginRight: 8,
                            }}
                            source={
                              IMAGES[
                                subTabsActiveStatus[index]
                                  ? subTabImages?.enabled
                                  : subTabImages?.disabled
                              ]
                            }
                          />

                          <Text
                            style={[
                              tw(`flex flex-row items-center font-normal`),
                              {
                                color: subTabsActiveStatus[index]
                                  ? activeSubTabStyle.color
                                  : inactiveSubTabStyle.color,

                                fontSize: 12,
                                marginRight: 15,
                              },
                            ]}
                            testID={`${subTabItem.key}`}
                          >
                            {Localise(messages, `${subTabItem.title}`)}
                          </Text>
                        </Text>
                      );
                    })}
                  </>
                )}
              </View>

              {removeTab && item.isRemovable && (
                <TouchableOpacity
                  onPress={() => {
                    setTabsCount && setTabsCount(item.key);
                  }}
                  testID={"removeTab"}
                  style={{ marginTop: -10 }}
                >
                  <Image
                    style={{
                      width: 13,
                      height: 13,
                      transform: [{ rotate: "90deg" }],
                    }}
                    resizeMode="cover"
                    source={IMAGES["close"]}
                  />
                </TouchableOpacity>
              )}
            </Component>
          );
        })}
        {siblings && siblings()}
      </View>
      {showBorder && (
        <View
          style={{
            borderBottomColor: colors.light,
            borderBottomWidth: 1,
            marginBottom: currentTab === "notifications" ? 0 : 20,
            shadowOffset: { width: 0, height: 0.5 },
            shadowOpacity: 0.4,
            shadowRadius: 7,
          }}
        />
      )}
    </View>
  );
};

export default TabBar;
