import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";

export const getValidationSchema = (Localise, messages) =>
  Yup.object().shape({
    customerType: Yup.string().required(
      Localise(messages, "Please select Customer Type")
    ),
    firstName: Yup.string().when("customerType", {
      is: (val) => val === "false",
      then: Yup.string().required(
        Localise(messages, "Please enter first name")
      ),
    }),
    lastName: Yup.string().when("customerType", {
      is: (val) => val === "false",
      then: Yup.string().required(Localise(messages, "Please enter last name")),
    }),
    businessName: Yup.string().when("customerType", {
      is: (val) => val === "true",
      then: Yup.string().required(
        Localise(messages, "Please enter business name")
      ),
    }),
    phone: Yup.string().when(["email"], {
      is: (val) => isEmpty(val),
      then: Yup.string()
        .matches(
          new RegExp(
            "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
          ),
          Localise(messages, "Enter valid Phone")
        )
        .required(Localise(messages, "Please enter phone number")),
      otherwise: Yup.string().when(["createHouseAccount"], {
        is: (val) => val === true,
        then: Yup.string().required(
          Localise(messages, "Please enter phone number")
        ),
        otherwise: Yup.string().matches(
          new RegExp(
            "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
          ),
          Localise(messages, "Enter valid Phone")
        ),
      }),
    }),
    email: Yup.string()
      .email("Please enter valid email")
      .matches(
        /^\w+[+-.\w]*@(?!(?:donotsend)\.com$)\w[-.\w]*\.\w{2,4}$/,
        "Invalid Email address"
      )
      .when(["isEmailRequired"], {
        is: (req) => req,
        then: Yup.string().required(
          Localise(messages, "Please enter email to save credit card details")
        ),
      }),
    address: Yup.object().when("isAddressRequired", {
      is: true,
      then: Yup.object().shape({
        addressLine1: Yup.string().required(
          Localise(messages, "Please enter Street Address")
        ),
        country: Yup.string().required(
          Localise(messages, "Please enter Country")
        ),
        city: Yup.string().required(Localise(messages, "Please enter City")),
        state: Yup.string().required(Localise(messages, "Please enter State")),
        zipcode: Yup.string()
          .label("zipcode")
          .when("country", {
            is: (value) => value && value === "US",
            then: Yup.string()
              .required(Localise(messages, "Please enter Zip Code"))
              .matches(
                new RegExp("^[0-9]{5}$"),
                Localise(messages, "Invalid Zip Code")
              ),
            otherwise: Yup.string().when("country", {
              is: (value) => value && value === "CA",
              then: Yup.string()
                .required(Localise(messages, "Please enter Zip Code"))
                .matches(
                  new RegExp("^[A-Za-z]\\d[A-Za-z][ -]?(\\d[A-Za-z]\\d)?\\s*$"),
                  Localise(messages, "Invalid Zip Code")
                ),
              otherwise: Yup.string().required(
                Localise(messages, "Please enter Zip Code")
              ),
            }),
          }),
      }),
    }),
    discountPercentage: Yup.string().matches(
      /^\d+\.?\d*$/,
      Localise(messages, "Invalid Discount")
    ),
  });
