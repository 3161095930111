import { createStyles, minWidth } from "react-native-media-queries";
import { colors, backgroundColors } from "styles/theme";

export default createStyles(
  {
    recordContainer: {
      flexDirection: "row",
      borderWidth: 1,
      borderColor: colors.grayScaleLight,
      borderRadius: 3,
      minHeight: 50,
      marginBottom: 5,
      padding: 5,
      //flex: 1,
    },
    activeRecordContainer: {
      borderWidth: 1,
      borderColor: backgroundColors.primary,
      backgroundColor: "#FAFAFA",
    },
    activeRecord: {
      color: backgroundColors.primary,
      fontWeight: "600",
    },
    menuContainer: {
      width: "10%",
      paddingRight: 10,
      alignItems: "flex-end",
      justifyContent: "center",
    },
    record: {
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap",
      borderLeftWidth: 8,
      width: "90%",
      paddingVertical: 6,
      paddingRight: 10,
      paddingLeft: 5,
    },
    recordItem: {
      textTransform: "capitalize",
    },
    subRecordItem: {
      width: 0,
      height: 0,
      opacity: 0,
    },
    recordItemView: {
      paddingRight: 5,
      paddingVertical: 4,
      overflow: "hidden",
    },
    status: {
      width: 135,
    },
    statusText: {
      fontSize: 15,
      fontWeight: "600",
      width: 75,
      wordBreak: "break-word",
    },
    name: {
      width: 200,
    },
    memberCode: {
      width: 90,
    },
    rating: {
      width: 75,
    },
    minPrice: {
      width: 90,
    },
    subscription: {
      width: 135,
    },
    preferencesContainer: {
      borderWidth: 1,
      borderColor: colors.grayScaleLight,
    },
    preferencesHeader: {
      backgroundColor: backgroundColors.navBar,
      flexDirection: "row",
    },
    preferencesList: {
      padding: 8,
    },
    searchPreferences: {
      flex: 1,
    },
  },
  minWidth(767, {
    menuContainer: {
      width: "7%",
    },
    record: {
      width: "93%",
      justifyContent: "space-around",
    },
    status: {
      width: "15%",
    },
    name: {
      width: "20%",
    },
    memberCode: {
      width: "10%",
    },
    rating: {
      width: "3%",
    },
    minPrice: {
      width: "10%",
    },
    subscription: {
      width: "20%",
    },
  }),
  minWidth(1100, {
    record: {
      justifyContent: "space-around",
      paddingVertical: 3,
      flexWrap: "nowrap",
      width: "97%",
      alignItems: "center",
    },
    menuContainer: {
      width: "3%",
    },
    preferencesList: {
      padding: 16,
    },
    recordItemView: {
      paddingVertical: 7,
      alignItems: "center",
    },
    subRecordItem: {
      fontSize: 10,
      textTransform: "capitalize",
      textAlign: "center",
      width: "100%",
      height: "100%",
      opacity: 1,
    },
    searchPreferences: {
      flexDirection: "row",
    },
    status: {
      width: "15%",
    },
    name: { width: "20%" },
    memberCode: {
      width: "10%",
    },
    rating: {
      width: "3%",
    },
    minPrice: {
      width: "10%",
    },
    subscription: {
      width: "20%",
    },
  })
);
