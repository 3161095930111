import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";
import set from "lodash/set";
import get from "lodash/get";

export const initialState = {
  status: "progress",
  isDrawable: false,
  isCreateZoneEnabled: true,
  newZoneCreationFailed: false,
  createNewZone: false,
  isLoading: false,
  isDeleteModalVisible: false,
  deleteZoneData: {},
  isEditCancelled: false,
  currentlyCreatedRouteZone: {
    wayPoints: [],
    name: "",
    colorCode: "",
    capacity: "",
    isNewZone: true,
  },
  isCoverageAreaCircleVisible: false,
  isEditableZone: false,
  editZoneIndex: "",
  editedWaypoints: [],
  zoneCapacity: [],
  apiResponse: {
    feesCoverage: {
      citiesFees: {},
      citiesZipcodes: [],
      reloadDeliveryZoneMap: true,
    },
    autoRoutes: [],
    nonRoutedOrders: [],
  },
  zipApiResponse: {
    loading: false,
    feesCoverage: {
      citiesFees: {},
      citiesZipcodes: [],
      citiesplaceIDs: [],
    },
  },
  actions: {
    searchCity: { type: "search", value: "" },
    searchZipcode: { type: "search", value: "" },
    shopCode: "",
  },
  deliveryDashboard: { routes: [], zones: [] },
  zonesList: {},
  dashboardCurrentTabKey: "dashboard-zones-tab",
  activeRouteId: "",
  completeRouteWarning: { modalVisible: false, routeStops: [] },
  removeStopAction: null,
  screenKey: "",
  nestedScreenKey: "single",
  focusField: "",
  qrScanReprintWarning: {
    showWarningModal: false,
    needReprint: false,
    orders: [],
    message:
      "A few changes were made to few orders since the last print. Would you like to print them again?",
  },
  enableLassoTool: false,
  canProgrammaticallyZoom: true,
  addToRoute: {
    showOrderNumberModal: false, // order Number Add To Route
  },
};

const slice = createSlice({
  name: `view::${sliceName}`,
  initialState,
  reducers: {
    setPageInitialize: (state, action) => {
      const {
        payload: { shopCode = "" },
      } = action;
      state.status = "progress";
      state.apiResponse = initialState.apiResponse;
      state.extendedScreen = initialState.extendedScreen;
      state.actions.shopCode = shopCode;
    },
    setPageInitSuccess: (state) => {
      state.status = "done";
    },
    setPageInitFailure: (state) => {
      state.status = "fail";
    },
    setPageReset: (state) => {
      Object.assign(state, initialState);
    },
    setApiResponse: (state, action) => {
      const { path, content, isPatch, contentType } = action.payload;
      if (isPatch) {
        if (contentType === "object") {
          const prevContent = get(state, `apiResponse.${path}.content`, {});
          set(state.apiResponse, `${path}.content`, {
            ...prevContent,
            ...content,
          });
        } else {
          const prevContent = get(state, `apiResponse.${path}.content`, []);
          set(state.apiResponse, `${path}.content`, [
            ...prevContent,
            ...content,
          ]);
        }
      } else {
        set(state.apiResponse, `${path}.content`, content);
      }
    },
    setApiError: (state, action) => {
      const { path, error } = action.payload;
      set(state.apiResponse, `${path}.error`, error);
    },
    fetchCitiesZipcodes: () => {},
    setDeliveryZoneMapReload: (state, action) => {
      state.apiResponse.feesCoverage.reloadDeliveryZoneMap = action.payload;
    },
    saveCitiesZipcodes: (state, action) => {
      const { content } = action.payload;
      const prevContent = get(
        state,
        `apiResponse.feesCoverage.citiesZipcodes`,
        []
      );
      set(state.apiResponse, `feesCoverage.citiesZipcodes`, [
        ...prevContent,
        ...content,
      ]);
    },
    fetchCitiesFees: () => {},
    saveCitiesFees: (state) => {
      state.apiResponse.feesCoverage.citiesFees.error = "";
    },
    fetchShopSettings: () => {},
    clearCitiesZipcodes: (state) => {
      state.apiResponse.feesCoverage.citiesZipcodes = [];
    },
    setShopCodeSelected: (state, action) => {
      state.actions.shopCode = action.payload;
    },
    fetchAutoRoutes: () => {},
    setAutoRoutes: (state, action) => {
      const content = action?.payload;
      set(state.apiResponse, `autoRoutes`, [...content]);
    },
    saveAutoRoutes: (state, action) => {
      const { content } = action?.payload;
      const prevContent = get(state, `apiResponse.autoRoutes`, []);
      set(state.apiResponse, `autoRoutes`, [...prevContent, ...content]);
    },
    setIsDrawable: (state, action) => {
      state.isDrawable = action.payload;
    },
    setEnableLassoTool: (state, action) => {
      state.enableLassoTool = action.payload;
    },
    setCreatedRouteZone: (state, action) => {
      state.currentlyCreatedRouteZone = action.payload;
    },
    resetCreatedRouteZone: (state) => {
      state.currentlyCreatedRouteZone = initialState.currentlyCreatedRouteZone;
    },
    setIsCreateZoneEnabled: (state, action) => {
      state.isCreateZoneEnabled = action.payload;
    },
    resetIsCreateZoneEnabled: (state, action) => {
      state.isCreateZoneEnabled = initialState.isCreateZoneEnabled;
    },
    setNewZoneCreationFailed: (state, action) => {
      state.newZoneCreationFailed = action.payload;
    },
    resetNewZoneCreationFailed: (state, action) => {
      state.newZoneCreationFailed = initialState.newZoneCreationFailed;
    },
    deleteAutoRoute: () => {},
    saveUpdatedAutoRoute: (state, action) => {
      const content = action?.payload;
      if (content.length) {
        set(state.apiResponse, `autoRoutes`, [...content]);
      } else {
        state.apiResponse.autoRoutes = [];
      }
    },
    setIsLoadingAutoRoutes: (state, action) => {
      state.isLoading = action?.payload;
    },
    setDeleteZoneData: (state, action) => {
      state.deleteZoneData = action?.payload;
    },
    setIsDeleteModalVisible: (state, action) => {
      state.isDeleteModalVisible = action?.payload;
    },
    setEditZoneIndex: (state, action) => {
      state.editZoneIndex = action?.payload;
    },
    setIsEditableZone: (state, action) => {
      state.isEditableZone = action?.payload;
    },
    setEditedWaypoints: (state, action) => {
      state.editedWaypoints = action?.payload;
    },
    setIsEditCancelled: (state, action) => {
      state.isEditCancelled = action?.payload;
    },
    setNonRoutedOrders: (state, action) => {
      state.apiResponse.nonRoutedOrders = action?.payload || [];
    },
    setZoneCapacity: (state, action) => {
      state.zoneCapacity = action?.payload?.length ? action?.payload : [];
    },
    setCoverageAreaCircleVisibilty: (state, action) => {
      state.isCoverageAreaCircleVisible = action?.payload;
    },
    setactiveRouteId: (state, action) => {
      state.activeRouteId = action?.payload;
    },
    setCompleteRouteModal: (state, action) => {
      state.completeRouteWarning = { ...action?.payload };
    },
    setRemoveStopAction: (state, action) => {
      state.removeStopAction = action?.payload;
    },
    setSelectScreenKey: (state, action) => {
      state.screenKey = action?.payload;
    },
    setNestedScreenKey: (state, action) => {
      state.nestedScreenKey = action?.payload;
    },
    setDeliveryDashboardData: (state, action) => {
      const routesData = get(action, "payload.routes", []);
      const zonesData = get(action, "payload.zones", []);
      set(state.deliveryDashboard, "routes", routesData);
      set(state.deliveryDashboard, "zones", zonesData);
    },
    setDashboardCurrentTabKey: (state, action) => {
      state.dashboardCurrentTabKey = action?.payload;
    },
    setZones: (state, action) => {
      const content = action?.payload;
      state.zonesList = { ...content };
    },
    setFocusField: (state, action) => {
      state.focusField = action?.payload;
    },
    setQRScanReprintWarning: (state, action) => {
      const { order, ...other } = action.payload;
      if (order) {
        state.qrScanReprintWarning = {
          ...state.qrScanReprintWarning,
          ...other,
          orders: [...state.qrScanReprintWarning.orders, action.payload.order],
        };
      } else {
        state.qrScanReprintWarning = { ...action?.payload };
      }
    },
    resetQRScanReprintWarning: (state) => {
      state.qrScanReprintWarning = initialState.qrScanReprintWarning;
    },
    setZipApiResponse: (state, action) => {
      const { path, content } = action.payload;
      set(state.zipApiResponse, `${path}.content`, content);
    },
    saveCitiesZipcodesforShop: (state, action) => {
      const { content, shopCode } = action.payload;
      const prevContent = get(
        state,
        `zipApiResponse.feesCoverage.citiesZipcodes.${shopCode}`,
        []
      );
      set(state.zipApiResponse, `feesCoverage.citiesZipcodes.${shopCode}`, [
        ...prevContent,
        ...content,
      ]);
    },
    savecitiesplaceIDs: (state, action) => {
      const prevContent = get(
        state,
        `zipApiResponse.feesCoverage.citiesplaceIDs`,
        []
      );
      set(state, `zipApiResponse.feesCoverage.citiesplaceIDs`, [
        ...prevContent,
        ...action.payload,
      ]);
    },
    clearRouteCitiesZipcodes: (state) => {
      state.zipApiResponse.feesCoverage.citiesZipcodes = {};
      state.zipApiResponse.feesCoverage.citiesplaceIDs = [];
    },
    setZipCoverageLoading: (state, action) => {
      state.zipApiResponse.loading = action.payload;
    },
    setCanProgrammaticallyZoom: (state, action) => {
      state.canProgrammaticallyZoom = action.payload;
    },
    setShowONAddtoRouteModal: (state, action) => {
      state.addToRoute.showOrderNumberModal = action.payload;
    },

    fetchNonRoutedOrders: () => {},
    fetchDeliveryDashboardData: () => {},
    fetchRouteDetail: () => {},
    fetchZones: () => {},
    getOrderFromScan: () => {},
    getOrderForRoute: () => {},
    setZipInitialize: () => {},
    fetchCitiesFeesForShop: () => {},
    fetchCitiesZipcodesForShop: () => {},
  },
});

export const {
  setPageInitialize,
  setPageInitSuccess,
  setPageInitFailure,
  setApiResponse,
  setApiError,
  saveCitiesZipcodes,
  fetchCitiesZipcodes,
  fetchCitiesFees,
  saveCitiesFees,
  fetchShopSettings,
  setDeliveryZoneMapReload,
  clearCitiesZipcodes,
  setShopCodeSelected,
  setPageReset,
  fetchAutoRoutes,
  saveAutoRoutes,
  setIsDrawable,
  setCreatedRouteZone,
  setIsCreateZoneEnabled,
  resetCreatedRouteZone,
  setNewZoneCreationFailed,
  resetNewZoneCreationFailed,
  resetIsCreateZoneEnabled,
  deleteAutoRoute,
  saveUpdatedAutoRoute,
  setIsLoadingAutoRoutes,
  setIsDeleteModalVisible,
  setDeleteZoneData,
  setAutoRoutes,
  setEditZoneIndex,
  setEditedWaypoints,
  setIsEditableZone,
  setIsEditCancelled,
  fetchNonRoutedOrders,
  setNonRoutedOrders,
  setZoneCapacity,
  setCoverageAreaCircleVisibilty,
  setactiveRouteId,
  setCompleteRouteModal,
  setRemoveStopAction,
  setSelectScreenKey,
  setNestedScreenKey,
  fetchDeliveryDashboardData,
  fetchRouteDetail,
  setDeliveryDashboardData,
  setDashboardCurrentTabKey,
  fetchZones,
  setZones,
  getOrderFromScan,
  getOrderForRoute,
  setFocusField,
  setQRScanReprintWarning,
  resetQRScanReprintWarning,
  setEnableLassoTool,
  setCanProgrammaticallyZoom,
  setShowONAddtoRouteModal,

  //route Zip coverage
  setZipInitialize,
  setZipCoverageLoading,
  clearRouteCitiesZipcodes,
  fetchCitiesFeesForShop,
  fetchCitiesZipcodesForShop,
  setZipApiResponse,
  saveCitiesZipcodesforShop,
  savecitiesplaceIDs,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
