import React, { useEffect } from "react";
import { View } from "react-native";
import { useFormikContext } from "formik";

import {
  getCountyForGivenAddress,
  verifyAddress,
} from "library/utils/createOrder";

//Adding a separator line to differentiate libraries to custom components
import {
  FormField,
  FormFieldPicker,
  FormFieldAutoComplete,
} from "components/elements/forms";
import { request } from "library/utils/request";
import AddressVerificationModal from "components/views/drawer/create-order/address-verification-modal";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { updateAvsInfo } from "library/sagas/ongoing/global-data/slice";

import states from "static/data/states.json";
import countryList from "static/data/countries.json";
import moment from "moment";
import tw from "tailwind-rn";

import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";

import { setUpdateCounty } from "library/sagas/views/home/drawer/create-order/slice";
import { isAdddressChangedFn } from "components/views/drawer/order-details/helper";

let addressSuggestionsController;

const createAvsInfo = (
  index,
  isAvsSuggestedAddresses,
  confidence,
  isAvsPerformed = "Y"
) => {
  return updateAvsInfo({
    index,
    isAvsPerformed,
    avsConfidence: confidence,
    isAvsSuggestedAddress: isAvsSuggestedAddresses ? "Y" : "N",
    timeStamp: moment().toDate(),
  });
};

const Address = ({
  values,
  setValues,
  ignoreAVS = false,
  isSmallScreen,
  index = 0,
  focusTo,
  editable = true,
  disabled = false,
  formIKPath = "",
  stateLabel = "State",
  cityLabel = "City",
  updateCounty = false,
  canModifyOrder = false,
  zipLabel = "Postal Code",
  popperPlacement = "bottom",
  suiteName = "suite",
  fieldWidths: {
    cityWidth = "33.5%",
    stateWidth = "18.5%",
    zipWidth = "15%",
    countryWidth = "33%",
  } = {},
  ignoreZipValidation = false,
}) => {
  const { setFieldValue, initialValues } = useFormikContext();

  const [loading, setLoading] = useStateIfMounted(false);
  const [addressSuggestions, setAddressSuggestions] = useStateIfMounted([]);
  const [invalidAddress, setInvalidAddress] = useStateIfMounted();
  const [verifiedAddress, setVerifiedAddress] = useStateIfMounted();
  const dispatch = useDispatch();

  const { addressLine1, city, state, zip, country } = formIKPath
    ? get(values, formIKPath, {})
    : values;

  const getAddressSuggestions = (text) => {
    if (country !== "US" && country !== "CA") return;
    if (text.trim().length < 3) {
      setAddressSuggestions([]);
      return;
    }

    const payload = {
      addressLine1: text,
      aptCode: "",
      cityLocality: city,
      stateProvince: state,
      postalCode: zip,
      maxRecords: "10",
      resultDetails: [],
      countryRegion: country,
    };

    addressSuggestionsController && addressSuggestionsController.abort();
    addressSuggestionsController = new AbortController();

    setLoading(true);
    request("get-address-suggestions", payload, addressSuggestionsController)
      .then((res) => {
        //ToDo- Filter Unique data
        if (res?.addresses?.length) {
          setAddressSuggestions(res.addresses);
        } else setAddressSuggestions([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (updateCounty && addressLine1 && city && state && zip) {
      if (canModifyOrder) {
        //setFieldValue(`${formIKPath}.county`, "");
        //dispatch(setUpdateCounty(true));
      } else {
        const addressChanged = isAdddressChangedFn(
          values,
          initialValues,
          formIKPath
        );

        if (addressChanged) {
          getCountyForGivenAddress(
            {
              addressLine1,
              city,
              state,
              zipCode: zip,
              country,
            },
            index
          )
            .then((res) => {
              setFieldValue(`${formIKPath}.county`, res.county);
            })
            .catch((err) => {
              setFieldValue(`${formIKPath}.county`, "");

              console.log(
                "Getting error while getting county for given address :>> ",
                err
              );
            });
        } else {
          const initialCounty = get(initialValues, `${formIKPath}.county`, "");
          setFieldValue(`${formIKPath}.county`, initialCounty);
        }
      }
    }

    if (
      ["US", "CA"].includes(country) &&
      addressLine1 &&
      city &&
      state &&
      zip
    ) {
      if (ignoreAVS) return;

      verifyAddress({ addressLine1, city, state, zip, country }, index).then(
        (result) => {
          if (!result.isValid) {
            setInvalidAddress(result);
          } else {
            dispatch(createAvsInfo(index, true, result.confidence));
          }
        }
      );
    } else {
      dispatch(createAvsInfo(index, true, "Low", "N"));
    }
  }, [addressLine1, city, state, zip]);

  useEffect(() => {
    const updatedValues = cloneDeep(values);
    const data = get(updatedValues, formIKPath);
    const isStateExists =
      states[country] && state
        ? !!states[country].find((obj) => obj.value === state)
        : false;
    set(updatedValues, formIKPath, {
      ...data,
      state: ["US", "CA"].includes(country)
        ? isStateExists
          ? state
          : ""
        : country,
      county: "",
    });
    setValues(updatedValues);

    dispatch(setUpdateCounty(true));
  }, [country]);

  useEffect(() => {
    if (!verifiedAddress) return;
    const updatedValues = cloneDeep(values);
    //update formik where suggested addresses are selected.
    const isAvsSuggestedAddress = verifiedAddress
      ? verifiedAddress.selectedIndex === 1
      : false;

    if (isAvsSuggestedAddress) {
      const data = get(updatedValues, formIKPath);
      const { addressLine1, city, state, zip, country } =
        verifiedAddress.suggestedAddress;
      set(updatedValues, formIKPath, {
        ...data,
        addressLine1,
        city,
        state,
        zip: country === "US" ? zip?.substring(0, 5) : zip,
        country,
        county: "",
      });

      dispatch(setUpdateCounty(true));
    } else {
      dispatch(
        createAvsInfo(index, isAvsSuggestedAddress, verifiedAddress.confidence)
      );
    }
    setValues(updatedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifiedAddress]);

  const other =
    country === "US" ? { maxLength: ignoreZipValidation ? 10 : 5 } : {};

  return (
    <View
      pointerEvents={disabled ? "none" : "auto"}
      style={[{ width: "100%" }, tw("flex flex-row flex-wrap")]}
    >
      <FormFieldAutoComplete
        autoCapitalize="none"
        autoCorrect={false}
        name="addressLine1"
        fsClass="fs-exclude"
        loading={loading}
        placeholder={"Street Address"}
        label={"Address"}
        labelStyle={{ fontWeight: "normal" }}
        path={formIKPath}
        data={addressSuggestions}
        onChangeText={(text) => {
          getAddressSuggestions(text);
        }}
        outerContainerStyle={{
          width: isSmallScreen ? "100%" : "67%",
          zIndex: 2,
        }}
        containerStyle={{ opacity: disabled ? 0.7 : 1 }}
        listDisplayValues={["addressLine1", "cityLocality", "stateProvince"]}
        updateOnBlur={true}
        focusTo={focusTo}
        popperPlacement={popperPlacement}
        onSelect={(selectedValue) => {
          const updatedValues = cloneDeep(values);
          set(updatedValues, formIKPath, {
            ...get(updatedValues, formIKPath),
            addressLine1: selectedValue.addressLine1,
            city: selectedValue.cityLocality,
            zip: selectedValue.postalCode,
            state: selectedValue.stateProvince,
            country: selectedValue.countryRegion,
            county: "",
          });
          setValues(updatedValues);

          dispatch(setUpdateCounty(true));
        }}
        showErrorOnTouched={true}
      />
      <FormField
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="new-password"
        name={suiteName}
        fsClass="fs-exclude"
        placeholder={"Apt / Suite"}
        label={"Apt / Suite"}
        labelStyle={{ fontWeight: "normal" }}
        inputStyle={{ width: "100%" }}
        containerStyle={{
          width: isSmallScreen ? "100%" : "33%",
        }}
        path={formIKPath}
        editable={editable}
        disabled={disabled}
      />
      <FormField
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="new-password"
        name="city"
        fsClass="fs-exclude"
        placeholder={cityLabel}
        label={cityLabel}
        labelStyle={{ fontWeight: "normal" }}
        containerStyle={{
          width: isSmallScreen ? "60%" : cityWidth,
        }}
        path={formIKPath}
        editable={editable}
        disabled={disabled}
        testID="city"
        accessibilityLabel="city"
      />
      <FormFieldPicker
        containerStyle={{
          width: isSmallScreen ? "40%" : stateWidth,
        }}
        placeholder={{ label: stateLabel }}
        label={stateLabel}
        labelStyle={{ fontWeight: "normal" }}
        data={states[country] || []}
        name="state"
        fsClass="fs-exclude"
        path={formIKPath}
        disabled={disabled}
        disabledFieldTouch={true}
      />
      <FormField
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="new-password"
        name="zip"
        fsClass="fs-exclude"
        placeholder={zipLabel}
        label={zipLabel}
        labelStyle={{ fontWeight: "normal" }}
        containerStyle={{
          width: isSmallScreen ? "40%" : zipWidth,
        }}
        path={formIKPath}
        testID="zip"
        accessibilityLabel="zip"
        disabled={disabled}
        {...other}
      />
      <FormFieldPicker
        containerStyle={{
          width: isSmallScreen ? "60%" : countryWidth,
        }}
        placeholder={{}}
        data={countryList}
        name="country"
        fsClass="fs-exclude"
        label={"Country"}
        labelStyle={{ fontWeight: "normal" }}
        path={formIKPath}
        onChange={(value) => {
          value !== "US" && value !== "CA" && setAddressSuggestions([]);
        }}
        disabled={disabled}
        disabledFieldTouch={true}
      />
      <AddressVerificationModal
        showModal={!!invalidAddress}
        onClose={() => {
          setInvalidAddress();
        }}
        invalidAddresses={invalidAddress ? [invalidAddress] : []}
        onContinue={(addresses) => {
          setVerifiedAddress(addresses[0]);
          setInvalidAddress();
        }}
        isSmallScreen={isSmallScreen}
      />
    </View>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateAvsInfo,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Address);
