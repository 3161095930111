import { select, call, put, takeLatest, takeEvery } from "redux-saga/effects";
import * as Navigation from "library/utils/navigation.js";
import Logger from "library/utils/logger";
import { Currency } from "components/wrappers";

import {
  setData,
  setAction,
  setProductSelection,
  setInit,
  setQuickAction,
  setAPIError,
  setAPIResponse,
  updateCatalog,
  fetchCatalog,
} from "./slice";
import request from "../request";
import {
  selectActions,
  selectSortValue,
  selectProductsSelected,
  selectAPIResponse,
} from "./selector";
import {
  fetchSharedCatalogs,
  setAPIResponse as setCommonAPIResponse,
  setBulkUpdates,
} from "../common/slice";
import {
  selectProductLookup,
  selectScreen,
  selectSharedCatalogs,
  selectShopCode,
} from "../common/selector";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import toLower from "lodash/toLower";
import orderBy from "lodash/orderBy";

function* handleUIRefresh(action = {}) {
  const {
    type: actionType,
    value: actionValue,
    resolve,
    reject,
  } = get(action, "payload", {});

  if (actionType === "bulkActionType") {
    const selectedProducts = yield select(selectProductsSelected);
    yield call(
      handleBulkUpdates,
      actionValue,
      selectedProducts,
      resolve,
      reject
    );
    return;
  }

  const catalog = yield select(selectAPIResponse);
  const productLookup = yield select(selectProductLookup);
  const actions = yield select(selectActions);

  if (isEmpty(catalog) || isEmpty(productLookup)) return;

  const currentProducts = yield call(
    processData,
    applyPageActions(catalog, productLookup, actions)
  );

  yield put(setData({ data: currentProducts }));
}

function* handleDataRefresh(action = {}) {
  const { section = "local" } = get(action, "payload", {});
  const { name: screen } = yield Navigation.getCurrentRoute(true);

  if (screen === "catalog") {
    if (section === "local" || section === "global" || section === "addons") {
      yield call(handleUIRefresh);
    } else if (section === "sharedCatalogs") {
      const { params: { handle: groupId } = {} } = yield select(selectScreen);
      yield call(handleFetchCatalog, { payload: groupId });
    }
  }
}

function* handleBulkUpdates(actionValue, selectedProducts, resolve, reject) {
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (params) => request("bulk-products-update", params);

  try {
    if (!actionValue) return;

    const [property, value, operation, type] = actionValue.split(/::/);
    const currencyCode = Currency(shopCode, "CODE");

    const final =
      property === "prices"
        ? parseFloat(value)
        : property === "addons" || property === "collections"
        ? value.split(/,/)
        : value === "true";

    yield call(serviceRequest, {
      ...(property === "addons"
        ? {
            ...(final.includes("[*]")
              ? { productIds: ["*"] }
              : { collectionIds: final }),
            value: selectedProducts,
          }
        : {
            productIds: selectedProducts,
            value: final,
            type,
          }),
      property,
      operation,
      shopCode,
      currency: currencyCode,
    });

    yield put(
      setBulkUpdates({
        productIds: selectedProducts,
        property,
        operation,
        value: final,
        type,
      })
    );

    yield put(setProductSelection({ productId: "" }));

    resolve && resolve();

    yield put(
      setAction({
        type: "bulkActionType",
        value: "",
      })
    );
  } catch {
    reject && reject();
  }
}

const applyPageActions = (
  catalog,
  productLookup,
  { searchText, catalogType, filters = [] }
) => {
  const productIds = Object.keys(productLookup).filter((pid) =>
    catalog.products.includes(pid)
  );

  let catalogData = [];
  productIds.forEach((p) => {
    const entry = productLookup[p];
    if (!entry) return false;

    const isCollection = !!entry.productCount;
    let matchesFilter = true;

    if (searchText) {
      if (isCollection && toLower(entry.name).includes(toLower(searchText)))
        matchesFilter = true;
      else if (
        !isCollection &&
        (toLower(entry.name).includes(toLower(searchText)) ||
          toLower(entry.description).includes(toLower(searchText)) ||
          toLower(entry.productId).includes(toLower(searchText)) ||
          toLower(entry.variationDescription).includes(toLower(searchText)) ||
          toLower(entry.color).includes(toLower(searchText)) ||
          toLower(entry.flowerType).includes(toLower(searchText)))
      )
        matchesFilter = true;
      else matchesFilter = false;
    }

    if (filters.length) {
      const filterValues = filters.map((e) => e.value);

      if (
        matchesFilter &&
        (filterValues.includes("local") || filterValues.includes("global"))
      ) {
        if (filterValues.includes("local") && entry.catalogType === "local")
          matchesFilter = true;
        else if (
          filterValues.includes("global") &&
          entry.catalogType === "global"
        )
          matchesFilter = true;
        else matchesFilter = false;
      }

      if (
        matchesFilter &&
        catalogType === "addons" &&
        (filterValues.includes("myAddons") ||
          filterValues.includes("globalAddons"))
      ) {
        if (filterValues.includes("myAddons") && entry.group === "florist")
          matchesFilter = true;
        else if (filterValues.includes("globalAddons") && entry.group === "mol")
          matchesFilter = true;
        else matchesFilter = false;
      }

      if (
        matchesFilter &&
        (filterValues.includes("active") || filterValues.includes("inactive"))
      ) {
        if (filterValues.includes("active") && entry.status === "active")
          matchesFilter = true;
        else if (
          filterValues.includes("inactive") &&
          entry.status === "inactive"
        )
          matchesFilter = true;
        else matchesFilter = false;
      }

      if (matchesFilter && filterValues.includes("soldOut")) {
        if (entry.soldOut) matchesFilter = true;
        else matchesFilter = false;
      }

      if (
        matchesFilter &&
        (filterValues.includes("callForPrice") ||
          filterValues.includes("instore") ||
          filterValues.includes("rushDelivery") ||
          filterValues.includes("localDelOnly") ||
          filterValues.includes("dropship"))
      ) {
        if (
          (filterValues.includes("callForPrice") && entry.callForPrice) ||
          (filterValues.includes("instore") && entry.inStorePickUp) ||
          (filterValues.includes("rushDelivery") &&
            !entry.excludeFromRushDelivery) ||
          (filterValues.includes("localDelOnly") && entry.localDelOnly) ||
          (filterValues.includes("dropship") && entry.dropShippingProduct)
        )
          matchesFilter = true;
        else matchesFilter = false;
      }

      // if (matchesFilter && filterValues.includes("codified")) {
      //   if (entry.codified) matchesFilter = true;
      //   else matchesFilter = false;
      // }
    }

    if (matchesFilter) catalogData.push(entry);
  });

  return catalogData;
};

function* processData(data) {
  const sortBy = yield select(selectSortValue);
  const [sortField, sortOrder] = sortBy.split(/::/);

  return orderBy(
    data,
    (e) => {
      const value = e[sortField];

      return sortField === "price" ? parseFloat(value) : toLower(value.trim());
    },
    sortOrder
  ).map((product) => product.productId);
}

function* handleFetchCatalog(action = {}) {
  const groupId = get(action, "payload", "");
  const shopCode = yield select(selectShopCode);

  try {
    const sharedCatalogs = yield select(selectSharedCatalogs);
    const content = sharedCatalogs.find((el) => el.groupId === groupId);

    if (sharedCatalogs.length && content && !content.name) throw "NOT_FOUND";
    if (content) yield put(setAPIResponse({ content }));

    return content;
  } catch (error) {
    Logger.error("Unable to fetch catalog details.", groupId);
    const catalogError =
      error == "NOT_FOUND"
        ? `There is no catalog "${groupId}" available for the member "${shopCode}"`
        : "Something went wrong, please try again";
    yield put(setAPIError(catalogError));
  }
}

function* handleUpdateCatalog(action = {}) {
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (params) => request("update-shared-catalog", params);

  const {
    resolve,
    reject,
    params = {},
    currentGroupId,
  } = get(action, "payload", {});

  try {
    const { groupId, status, name } = params;
    const current = yield call(handleFetchCatalog, { payload: groupId });
    const fullPayload = current.groupScope === "local";
    const isGroupActive = status === "active";

    const payload = fullPayload
      ? {
          ...current,
          ...params,
          name: {
            "en-US": name || current.name,
          },
          isGroupActive,
        }
      : {
          groupId: groupId,
          siteId: current.siteId ? current.siteId : shopCode,
          isGroupActive,
        };

    const response = yield call(serviceRequest, {
      ...payload,
      currentGroupId,
      shopCode,
    });

    if (!response) throw "error";

    yield put(
      setAPIResponse({
        patch: { ...payload, status, ...(name && { name }) },
      })
    );

    yield put(fetchSharedCatalogs());

    const actionValue = `active::${isGroupActive}::::`;
    yield call(handleBulkUpdates, actionValue, current.products);

    resolve && resolve();
  } catch (error) {
    reject && reject();
  }
}

function* handleQuickAction(action = {}) {
  const {
    resolve,
    reject,
    params: {
      id,
      patch: { todo, data },
    },
  } = get(action, "payload", {});

  if (!id) return;

  // status update
  if (todo === "status") {
    yield put(
      updateCatalog({
        params: { groupId: id, ...data },
        resolve,
        reject,
      })
    );
  }
}

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeLatest(
    [setInit.type, setAPIResponse.type, setAction.type],
    handleUIRefresh
  );
  yield takeLatest(setCommonAPIResponse.type, handleDataRefresh);
  yield takeLatest(setQuickAction, handleQuickAction);
  yield takeEvery(updateCatalog.type, handleUpdateCatalog);
  yield takeLatest(fetchCatalog.type, handleFetchCatalog);
}

export default watchSaga;
