import React, { useContext, useRef } from "react";
import { ScrollView, View } from "react-native";
import { DeviceContext } from "library/contexts/appSettings";
import NavHeader from "components/containers/header/sub-header";
import ScreenHeader from "../split-screen-new/header";
import tw from "tailwind-rn";

const SingleScreen = ({
  Screen,
  CustomHeader,
  headerProps,
  rootNavigation,
  navHeaderProps,
  screenRef = useRef(),
}) => {
  const { isDesktop } = useContext(DeviceContext);

  return (
    <View style={tw("flex flex-col w-full h-full")}>
      <NavHeader {...navHeaderProps} rootNavigation={rootNavigation} />
      <View
        style={[
          tw("flex flex-col w-full h-full"),
          {
            paddingHorizontal: isDesktop ? 20 : 10,
            paddingBottom: 50,
          },
        ]}
        fsClass="fs-unmask"
      >
        {CustomHeader ? <CustomHeader /> : <ScreenHeader {...headerProps} />}
        <ScrollView ref={screenRef}>
          <View style={tw("flex w-full")}>{Screen}</View>
        </ScrollView>
      </View>
    </View>
  );
};

export default SingleScreen;
