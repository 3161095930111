import Environment from "library/utils/environment";

export const getRefundInitialValues = ({
  deliveryGroupId = "",
  orderLookup = {},
}) => {
  const {
    lineItems = [],
    orderId = "",
    memberCode = "",
    omsOrderId = "",
    customerOrderId = "",
    retailDeliveryFee = 0,
  } = orderLookup || {};

  const lineItemRefundAmounts = lineItems.map((lineItem) => {
    return {
      productId: lineItem.productId,
      productType: lineItem.type === "addon" ? "Addon" : "Product",
      refundAmount: 0,
    };
  });

  const initialValues = {
    isPartial: true,
    deliveryGroupId,
    refundReason: "Quality",
    memberCode,
    refundInitiatedAmount: 0,
    partialAmount: 0,
    taxAmount: 0,
    feeAmount: 0,
    lineItems: lineItemRefundAmounts,
    serviceFeeAmount: 0,
    rushFeeAmount: 0,
    retailDeliveryFeeAmount: retailDeliveryFee,
    shopifyOrderId: omsOrderId,
    operator: orderId,
    orderID: orderId,
    deliveryTip: 0,
    orderItemId: "mpos",
    customerOrderId: customerOrderId,
  };
  return initialValues;
};

export const refundReasonsPickerData = () => {
  const refundReasons = Environment.get("REFUND_REASONS", "").split(",");
  const refundPickerData = refundReasons.map((reason) => {
    return {
      label: reason,
      value: reason,
    };
  });
  return refundPickerData;
};
