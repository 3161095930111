import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../root-selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectActivities = createSelector(
  selectState,
  ({ events = [] }) => events || []
);

export const selectCurrentBalance = createSelector(
  selectState,
  ({ currentBalance }) => currentBalance
);

export const selectSessionId = createSelector(
  selectState,
  ({ sessionId }) => sessionId
);

export const selectMemberId = createSelector(
  selectState,
  ({ memberId }) => memberId
);

export const selectOpeningBalance = createSelector(
  selectState,
  ({ openingBalance }) => openingBalance
);
