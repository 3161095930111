/* Options for Add to Route in Create/Edit Route screen */
export const addToRouteOptions = ({
  localPermissions,
  isAddStopFormOpen,
  qrScanVariant,
  showModal,
}) => {
  const optionsList = [
    { key: "byOrderNumber", label: "By Order Number", selected: showModal },
  ];
  if (localPermissions.showQRScan) {
    optionsList.push({
      key: "qrScan",
      label: "By QR Scan",
      selected: qrScanVariant == "order-route-scan",
    });
  }
  if (localPermissions.adhocStops) {
    optionsList.push({
      key: "addStopAction",
      label: "Add Stop",
      selected: isAddStopFormOpen,
    });
  }
  return optionsList;
};
