import { createStyles } from "react-native-media-queries";
import { colors } from "styles/theme";

export default createStyles({
  recordContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    borderWidth: 1,
    borderColor: colors.grayScaleLight,
    borderRadius: 3,
    minHeight: 50,
    marginBottom: 5,
    padding: 10,
    paddingLeft: 5,
    paddingRight: 0,
  },
  addStopRecord: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "baseline",
    flexWrap: "wrap",
    borderLeftWidth: 8,
    paddingVertical: 6,
    paddingRight: 10,
    paddingLeft: 8,
    width: "100%",
  },
  labelTextContainer: {
    width: "10%",
    paddingLeft: 0,
  },
  labelText: {
    lineHeight: 25,
    marginTop: 8,
  },
});
