import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "react-native";
import ListingScreen from "./listing-screen";
import {
  setPageInitialize,
  setPageReset,
  setPageAction as setEventsActions,
  setPageActionsReset,
} from "library/sagas/views/home/drawer/events/slice";
import { setLogoURLs } from "library/sagas/ongoing/global-data/slice";
import { selectDashboardShopCode } from "library/sagas/ongoing/global-data/selector";
import UserProfileStorage from "library/storage/userProfile";
import isObject from "lodash/isObject";
import { downloadImage } from "../create-event/config";

const Events = (props) => {
  const { isFuneralLog, onComplete } = props;
  const dispatch = useDispatch();
  const dashboardShopCode = useSelector(selectDashboardShopCode);
  const shopNames = UserProfileStorage.getAllShopNames();

  useEffect(() => {
    if (dashboardShopCode !== "all") {
      // Updating selected shop in events redux slices in order show it as pre selected in shops filter.
      const selectedShop = [
        {
          label: `${dashboardShopCode} ${shopNames[dashboardShopCode]}`,
          value: dashboardShopCode,
        },
      ];

      dispatch(setEventsActions({ type: "shops", value: selectedShop }));
    }

    Object.keys(shopNames).forEach((shop) => {
      const { card_settings } = UserProfileStorage.getShopPreferences(shop);
      const settings = !isObject(card_settings)
        ? JSON.parse(card_settings || "{}")
        : card_settings;

      const { logoImageURL } = settings;
      if (logoImageURL) {
        downloadImage(shop, logoImageURL).then((resp) => {
          dispatch(
            setLogoURLs({ shop, url: `data:image/png;base64,${resp.source}` })
          );
        });
      }
    });

    dispatch(
      setPageInitialize({
        eventType: isFuneralLog ? "FUNERAL_LOG" : "",
      })
    );

    return () => {
      if (!isFuneralLog) {
        dispatch(setPageReset());
      } else {
        dispatch(setPageActionsReset());
      }
    };
  }, [dashboardShopCode, dispatch, shopNames]);

  return (
    <View fsClass="fs-unmask" style={{ height: "100%" }}>
      {ListingScreen({ isFuneralLog, onComplete })}
    </View>
  );
};

export default Events;
