import {
  IsCanceledBySender,
  IsCanceledByServiceProvider,
  getDSRequestRerouteStatus,
} from "library/utils/deliveryService";

export const getDisplayEligible = (dsRequestDetails) => {
  const { state, notes = [] } = dsRequestDetails;

  return [
    "SCHEDULED",
    "COURIER_ASSIGNED",
    "PICKUP_IN_PROGRESS",
    "AT_PICKUP",
    "STAGED",
    "CREATED",
  ].includes(state)
    ? ["created", "scheduled"]
    : ["PICKUP_COMPLETE", "DELIVERY_IN_PROGRESS", "AT_DELIVERY"].includes(state)
    ? ["created", "scheduled", "delivery"]
    : [
        "ATTEMPTED_DELIVERY",
        "ENROUTE_TO_RETURN",
        "ARRIVED_AT_RETURN",
        "RETURNED",
        "REJECTED",
      ].includes(state) ||
      IsCanceledByServiceProvider(state, notes) ||
      getDSRequestRerouteStatus(dsRequestDetails, "rerouting") // show DS REquest as Created when Rerouting
    ? ["created"]
    : ["REJECTED_ACK", "CANCELLED_ACK", "RETURNED_ACK"].includes(state) ||
      IsCanceledBySender(state, notes)
    ? ["created", "cancelled"]
    : state === "DELIVERED"
    ? ["created", "scheduled", "delivery", "completed"]
    : ["created"];
};

export const getConfig = (status, isSmallScreen, notes = []) => {
  return {
    created: {
      displayStatus: "Created",
      iconExists: true,
      iconName: "delivery-service-accepted",
      styleConfig: {
        labelSize: isSmallScreen ? 12 : 13,
        labelColor: "#114258",
        stripColor: "#aadec6",
        blockBgColor: "#fff",
        iconWidth: isSmallScreen ? 21 : 25,
        iconHeight: isSmallScreen ? 21 : 25,
      },
    },
    ...(["REJECTED_ACK", "CANCELLED_ACK", "RETURNED_ACK"].includes(
      status.toUpperCase()
    ) || IsCanceledBySender(status.toUpperCase(), notes)
      ? {
          cancelled: {
            displayStatus: "Canceled",
            iconExists: true,
            iconName: "cancelled",
            styleConfig: {
              labelSize: isSmallScreen ? 12 : 13,
              labelColor: "#6a6a6a",
              stripColor: "#c4c4c4",
              blockBgColor: "#fff",
              iconWidth: isSmallScreen ? 19 : 23,
              iconHeight: isSmallScreen ? 19 : 23,
            },
          },
        }
      : {
          scheduled: {
            displayStatus: "Scheduled",
            iconExists: true,
            iconName: "delivery-service-design",
            styleConfig: {
              labelSize: isSmallScreen ? 12 : 13,
              labelColor: "#114258",
              stripColor: "#31aa92",
              blockBgColor: "#fff",
              iconWidth: isSmallScreen ? 21 : 26,
              iconHeight: isSmallScreen ? 21 : 26,
            },
          },
        }),
    delivery: {
      displayStatus: "Delivery",
      iconExists: true,
      iconName: "delivery-service-delivery",
      styleConfig: {
        labelSize: isSmallScreen ? 12 : 13,
        labelColor: "#114258",
        stripColor: "#11838e",
        blockBgColor: "#fff",
        iconWidth: isSmallScreen ? 21 : 26,
        iconHeight: isSmallScreen ? 21 : 26,
      },
    },
    completed: {
      displayStatus: "Completed",
      iconExists: true,
      iconName: "completed",
      styleConfig: {
        labelSize: isSmallScreen ? 12 : 13,
        labelColor: "#114258",
        stripColor: "#114258",
        blockBgColor: "#fff",
        iconWidth: isSmallScreen ? 20 : 24,
        iconHeight: isSmallScreen ? 16 : 20,
      },
    },
  };
};
