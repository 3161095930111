const TextFieldProps = {
  autoCapitalize: "none",
  autoCorrect: false,
  editable: false,
  multiline: false,
  numberOfLines: 1,
};

export const InputFields = [
  {
    name: "shopName",
    title: "Shop Name",
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: "Shop Name",
    },
  },
  {
    name: "shopAddress",
    title: "Shop Address",
    formFieldType: "CustomAddress",
    formInputFields: [
      {
        name: "streetAddress",
        formFieldType: "Text",
        formFieldProps: {
          ...TextFieldProps,
          placeholder: "Street Address",
        },
      },
      {
        name: "aptNumber",
        formFieldType: "Text",
        formFieldProps: {
          ...TextFieldProps,
          placeholder: "Apt/Suite/Unit",
        },
      },
      {
        name: "city",
        formFieldType: "Text",
        formFieldProps: {
          ...TextFieldProps,
          placeholder: "City",
        },
      },
      {
        name: "state",
        formFieldType: "Text",
        formFieldProps: {
          ...TextFieldProps,
          placeholder: "State",
        },
      },
      {
        name: "zip",
        formFieldType: "Text",
        formFieldProps: {
          ...TextFieldProps,
          placeholder: "Zip",
        },
      },
    ],
    checkBoxAddIn: "displayShopAddress",
  },
  {
    name: "shopPhone",
    title: "Shop Phone",
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: "Shop Phone",
    },
  },
  {
    name: "shopTollFree",
    title: "Shop Toll Free",
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: "Shop Toll Free",
    },
    checkBoxAddIn: "displayShopTollFree",
  },
  {
    name: "websitePhoneNumber",
    title: "Website Phone Number",
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: "Website Phone Number",
      editable: true,
      isUpdateOnChange: true,
    },
    checkBoxAddIn: "displayWebsitePhoneNumber",
  },
  {
    name: "website",
    title: "Website",
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: "List websites with comma between each",
    },
  },
  {
    name: "websiteEmail",
    title: "Website Email",
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: "Website Email",
      editable: true,
      isUpdateOnChange: true,
    },
    checkBoxAddIn: "displayWebsiteEmail",
  },
  {
    name: "orderEmail",
    title: "Order Email",
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: "What email do you use for orders?",
      editable: true,
      isUpdateOnChange: true,
    },
  },
  {
    name: "bccEmails",
    title: "BCC Email(s)",
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: "List bcc emails with comma between each",
      editable: true,
      isUpdateOnChange: true,
    },
  },
  {
    name: "redirectUrls",
    title: "Redirect URL(s)",
    formFieldType: "CustomUrls",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: "Redirect URL",
    },
  },
  {
    name: "googleAnalyticsId",
    title: "Google Analytics ID",
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: "Marketing Concierge Program - GA4 Google Analytics ID",
      editable: true,
      isUpdateOnChange: true,
    },
  },
  {
    name: "googleTagManagerId",
    title: "Google Tag Manager ID",
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder:
        "Marketing Concierge Program - Google Tag Manager Container ID",
      editable: true,
      isUpdateOnChange: true,
    },
  },
  {
    name: "additionalScripts",
    title: "Additional Scripts",
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: "Google Site Verification and Additional Scripts",
      editable: true,
      numberOfLines: 20,
      multiline: true,
      isUpdateOnChange: true,
    },
  },
];
