import React from "react";
import {
  Platform,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Image,
} from "react-native";
import { Text, Header, Badge } from "react-native-elements";
import {
  useNavigation,
  CommonActions,
  useNavigationState,
} from "@react-navigation/native";
import { getStatusBarHeight } from "react-native-status-bar-height";
import UserProfileContext from "library/contexts/userProfile";
import UserAuthContext from "library/contexts/userAuth";
import { colors, theme } from "styles/theme";
import tw from "tailwind-rn";
import { fonts, backgroundColors } from "styles/theme";
import startCase from "lodash/startCase";
import get from "lodash/get";
import IMAGES from "static/assets/images";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { CustomModal } from "components/elements";
import I18NContext from "library/contexts/i18N";

const TitleComponent = () => {
  return (
    <TouchableOpacity
      style={[tw("flex flex-row items-center"), { width: 155 }]}
      fsClass="fs-unmask"
      testID="logo"
    >
      <View
        style={{
          backgroundColor: "#FFF",
          borderRadius: 30,
          width: 45,
          height: 45,
        }}
      >
        <Image
          style={{ width: 45, height: 45, paddingHorizontal: 0 }}
          resizeMode="cover"
          source={require("static/assets/logo.png")}
        />
      </View>

      <Text
        style={{
          fontSize: 18,
          fontWeight: "400",
          color: colors.secondary,
          paddingLeft: 10,
        }}
      >
        Mercury HQ
      </Text>
    </TouchableOpacity>
  );
};

const WelcomeComponent = ({
  name = "",
  subTitle = "",
  userType = "",
  isTablet,
  reset,
  isPasswordAboutToExpire,
}) => {
  const navigation = useNavigation();
  const { routes } = useNavigationState((state) => state || {});
  const routeNames = get(routes, "0.state.routeNames", []);
  const index = get(routes, "0.state.index", 0);
  const { signOut, authToken } = React.useContext(UserAuthContext);
  const [loading, setLoading] = React.useState(false);
  const { messages, Localise } = React.useContext(I18NContext);

  const submitAction = (action) => {
    if (action === "logout") {
      setLoading(true);
      signOut().then(() => {
        setLoading(false);
        reset();
      });
    }

    if (action === "profile") {
      navigation.dispatch(
        CommonActions.navigate({
          name: userType == "FTD" ? "ftd-profile" : "profile",
        })
      );
    }
    if (action === "login") {
      if (window) window.location.href = "/login";
    }
  };

  const modalContent = {
    content: (
      <ActivityIndicator size="large" color={colors.activityIndicator} />
    ),
  };
  const modal = (
    <CustomModal
      modalVisible={loading}
      modalContent={modalContent}
      contentStyle={{ padding: 0, width: 0, height: 0 }}
      modalStyle={{
        padding: 0,
        ...(Platform.OS !== "web" && {
          flex: 1,
          backgroundColor: "rgb(0, 0, 0)",
          opacity: 0.7,
        }),
      }}
    ></CustomModal>
  );

  const menuOptions = authToken
    ? [
        { key: "profile", label: "Profile" },
        { key: "logout", label: "Logout" },
      ]
    : [{ key: "login", label: "Login" }];

  return (
    <View style={tw("flex flex-row justify-between items-center")}>
      {modal}
      {authToken && (
        <View style={tw("flex flex-col items-end mr-1")}>
          <Text
            style={{
              ...fonts.heading5,
              fontWeight: "400",
              color: colors.secondary,
            }}
          >
            {Localise(messages, "Welcome")}, {name}
          </Text>
          <Text
            style={{
              ...fonts.heading5,
              paddingTop: 5,
              color: colors.secondary,
              fontWeight: "400",
            }}
          >
            {subTitle}
          </Text>
        </View>
      )}

      {isTablet && (
        <Menu>
          <MenuTrigger testID="profile_icon" accessibilityLabel="profile_icon">
            <Image
              style={{ width: 50, height: 50 }}
              resizeMode="cover"
              source={IMAGES["user-profile"]}
            />
            <View
              style={{
                borderTopWidth: 7,
                borderTopColor:
                  routeNames.length > 0 &&
                  (routeNames[index] === "profile" ||
                    routeNames[index] === "ftd-profile")
                    ? colors.navBarActive
                    : backgroundColors.header,
              }}
            ></View>
            {isPasswordAboutToExpire && (
              <Badge
                containerStyle={[
                  {
                    right: 0,
                    position: "absolute",
                  },
                ]}
                badgeStyle={{ backgroundColor: "#e5175e" }}
                value={1}
              />
            )}
          </MenuTrigger>
          <MenuOptions
            optionsContainerStyle={{
              width: 150,
              padding: 5,
              marginTop: 40,
              marginLeft: -5,
            }}
            customStyles={{
              optionWrapper: {
                padding: 5,
                flexDirection: "row",
                justifyContent: "space-between",
              },
            }}
          >
            {menuOptions.map((option) => (
              <MenuOption
                key={option.key}
                onSelect={() => submitAction(option.key)}
                testID={option.key}
                accessibilityLabel={option.key}
              >
                <Text>
                  {Localise(messages, option.label)}{" "}
                  {isPasswordAboutToExpire && option.label === "Profile" && (
                    <Badge
                      badgeStyle={{ backgroundColor: "#e5175e" }}
                      value={1}
                    />
                  )}
                </Text>
              </MenuOption>
            ))}
          </MenuOptions>
        </Menu>
      )}
    </View>
  );
};

const HeaderBar = ({ isTablet, subHeader, reset, showWelcomeSection }) => {
  const {
    userProfile: { firstName } = {},
    memberCodes = [],
    shopGroups = [],
    userRole = "",
    userType = "",
    isPasswordAboutToExpire,
  } = React.useContext(UserProfileContext);
  const { messages, Localise } = React.useContext(I18NContext);

  const subTitle =
    userType === "FTD"
      ? userRole !== ""
        ? startCase(userRole, "_", " ")
        : userRole
      : memberCodes.length === 1
      ? `${Localise(messages, "Member")} #${memberCodes[0]}`
      : shopGroups.length === 1
      ? shopGroups[0].replace("-VSG", "") // Temporary to support virtual shop group
      : Localise(messages, userRole);

  return (
    <>
      <Header
        placement="right"
        containerStyle={{
          ...(Platform.OS !== "ios" && {
            height: theme.Header.containerStyle.height + getStatusBarHeight(),
          }),
          ...(Platform.OS === "ios" && {
            paddingTop: 0,
          }),
          paddingHorizontal: 5,
          borderBottomWidth: 0,
        }}
      >
        <TitleComponent />
        {showWelcomeSection && (
          <View
            style={[
              tw("flex flex-row justify-between items-center"),
              { marginHorizontal: -15 },
            ]}
            fsClass="fs-unmask"
          >
            <WelcomeComponent
              name={firstName}
              subTitle={subTitle}
              userType={userType}
              isTablet={isTablet}
              reset={reset}
              isPasswordAboutToExpire={isPasswordAboutToExpire}
            />
          </View>
        )}
      </Header>
      {subHeader && subHeader()}
    </>
  );
};

export default HeaderBar;
