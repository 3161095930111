/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { View, Platform } from "react-native";
import moment from "moment";
import { AppSettingsContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import UserProfileStorage from "library/storage/userProfile";
import { PrintIframeRenderer, PrintIframeAction } from "components/elements";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import { getMultiWorksheetPrintHTML } from "components/views/drawer/order-details/worksheet-print-helper";
import { getMultiOrderPrintHTML } from "components/views/drawer/order-details/order-details-print-helper";
import { getMultiCardPrintHTML } from "components/views/drawer/order-details/trifold-print-helper";
import { getMultiInvoicePrintHTML } from "components/views/drawer/order-details/invoice-print-helper";
import {
  fetchAutoPrintOrders,
  fetchAutoPrintOrdersLastSyncTime,
  updateAutoPrintOrders,
} from "library/sagas/ongoing/current-orders/slice";
import { selectImages } from "library/sagas/ongoing/global-data/selector";
import { selectAutoPrintOrdersInfo } from "library/sagas/ongoing/current-orders/selector";
import I18NContext from "library/contexts/i18N";
import { Entitlements } from "library/utils/entitlements";
import Environment from "library/utils/environment";
import { generateQRCode } from "library/sagas/ongoing/order-details/slice";

const orderDetailsPrintIneligibleStatusList = [
  "CANCELLED",
  "REJECTED",
  "ERROR",
  "ERROR_ACK",
  "EAPI_REJECT",
];

const AutoPrint = () => {
  const imagesData = useSelector(selectImages);
  const autoPrintOrdersInfo = useSelector(selectAutoPrintOrdersInfo);
  const dispatch = useDispatch();

  const ORDERS_LIMIT_PER_PDF = 25;

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const isPrintManagementOn = Environment.get(
    "PRINT_MANAGEMENT_ENABLED",
    false
  );

  const pollIntervalForAutoPrint = Environment.get(
    "AUTO_PRINT_POLL_INTERVAL",
    2 * 60 * 1000
  );

  const autoPrintStartEndInterval = Environment.get(
    "AUTO_PRINT_START_END_INTERVAL",
    720 * 60 * 60 * 1000
  );

  const autoPrintMessagesStartEndInterval = Environment.get(
    "AUTO_PRINT_MESSAGES_START_END_INTERVAL",
    7 * 24 * 60 * 60 * 1000
  );

  const { memberCodes = [], userProfile } =
    React.useContext(UserProfileContext);

  const {
    orders = [],
    timeStamp = "",
    initialTimeStamp = "",
  } = autoPrintOrdersInfo || {};

  const { permissions } = React.useContext(AppSettingsContext);

  const allShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.DASHBOARD in permissions[memberCode])
      allShopCodes.push(memberCode);
  });

  const shopNames = UserProfileStorage.getAllShopNames();

  const getPrintType = (preferences) => {
    const { auto_print = false, remote_print = false } = preferences || {};
    if (auto_print && remote_print) return "REMOTE";
    if (auto_print) return "AUTO";
    if (remote_print) return "REMOTE";
    return false;
  };

  const isAutoPrint = (preferences = {}) => preferences?.auto_print || false;

  const isRemotePrint = (preferences = {}) =>
    preferences?.remote_print || false;

  const isEligibleToPrint = (preferences = {}) =>
    isAutoPrint(preferences) || isRemotePrint(preferences);

  const processOrderMessages = (
    messages,
    preferences,
    direction,
    commonParams
  ) => {
    if (!isEligibleToPrint(preferences)) return [];

    return messages.reduce((acc, message) => {
      if (
        message.messageType !== "OLC" &&
        (!message.isPrinted || message.isPrintRequested) &&
        message.direction === direction
      ) {
        acc.push({
          ...commonParams,
          relatedMessageId: message.id,
          direction,
          messageType: "RELATED_MESSAGE",
          isPrinted: true,
          isAutoPrint: isAutoPrint(preferences),
          printType: getPrintType(preferences),
        });
      }
      return acc;
    }, []);
  };

  const getPrintStatusOfOrders = (
    memberCode,
    autoPrintOrder,
    preferencesArray = []
  ) => {
    const [
      orderPreferences = {},
      outboundOrderPreferences = {},
      trifoldPreferences = {},
      invoicePreferences = {},
      worksheetPreferences = {},
      orderMessagesInboundPreferences = {},
      orderMessagesOutboundPreferences = {},
    ] = preferencesArray;

    const isEligible = preferencesArray.some(isEligibleToPrint);

    if (!isEligible) return null;

    let printedOrdersMessagesList = [];
    const printedOrdersList = autoPrintOrder.map((order) => {
      const orderInfo = get(order, "orderDetails.orderItems.0", {});
      const {
        deliveryInfo: { deliveryMethod },
        isInvoiceDetailsPrinted,
        isTrifoldDetailsPrinted,
        isDesignerWorkSheetPrinted,
        isPrinted,
        //Remote Print flags
        isOrderDetailsPrintRequested,
        isTrifoldPrintRequested,
        isInvoicePrintRequested,
        isDesignerWorksheetPrintRequested,
        orderItemId,
        erosOrderNumber,
        sendingMember,
        receivingMember,
        direction,
        messages = [],
        status,
      } = orderInfo;

      const isInBoundOrder = direction === "INBOUND";

      const commonParams = {
        orderNumber: erosOrderNumber,
        orderId: order.orderID || orderItemId,
        orderItemId,
        deliveryMethod,
        sendingMemberCode: sendingMember?.memberCode,
        fillingMemberCode: receivingMember?.memberCode,
      };

      let payload = {};

      //To update the print status for order-related messages.
      if (deliveryMethod === "FLORIST_PARTNER") {
        const inboundMessages = processOrderMessages(
          messages,
          orderMessagesInboundPreferences,
          "INBOUND",
          commonParams
        );

        const outboundMessages = processOrderMessages(
          messages,
          orderMessagesOutboundPreferences,
          "OUTBOUND",
          commonParams
        );

        printedOrdersMessagesList.push(...inboundMessages, ...outboundMessages);
      }

      const orderDetailPreferences = isInBoundOrder
        ? orderPreferences
        : outboundOrderPreferences;

      // Check for order details auto-print eligibility or remote print request
      if (
        (!isPrinted &&
          !orderDetailsPrintIneligibleStatusList.includes(status) &&
          orderDetailPreferences?.auto_print) ||
        (isOrderDetailsPrintRequested && orderDetailPreferences?.remote_print)
      ) {
        payload.isPrinted = true;
      }

      // Check for trifold details auto-print eligibility or remote print request
      if (
        (isInBoundOrder &&
          !isTrifoldDetailsPrinted &&
          trifoldPreferences?.auto_print) ||
        (isTrifoldPrintRequested && trifoldPreferences?.remote_print)
      ) {
        payload.isTrifoldDetailsPrinted = true;
      }

      // Check for invoice details auto-print eligibility or remote print request
      if (
        (!isInvoiceDetailsPrinted && invoicePreferences?.auto_print) ||
        (isInvoicePrintRequested && invoicePreferences?.remote_print)
      ) {
        payload.isInvoiceDetailsPrinted = true;
      }

      // Check for designer worksheet auto-print eligibility or remote print request
      if (
        (isInBoundOrder &&
          !isDesignerWorkSheetPrinted &&
          worksheetPreferences?.auto_print) ||
        (isDesignerWorksheetPrintRequested &&
          worksheetPreferences?.remote_print)
      ) {
        payload.isDesignerWorkSheetPrinted = true;
      }

      const preferences = [
        orderPreferences,
        trifoldPreferences,
        invoicePreferences,
        worksheetPreferences,
        outboundOrderPreferences,
      ];

      const anyRemotePrintEnabled = preferences.some(isRemotePrint);
      const anyAutoPrintEnabled = preferences.some(isAutoPrint);

      return !isEmpty(payload)
        ? {
            ...commonParams,
            ...payload,
            isAutoPrint: anyAutoPrintEnabled,
            printType: anyRemotePrintEnabled ? "REMOTE" : "AUTO",
          }
        : {};
    });

    return {
      memberCode,
      autoPrintOrders: [...printedOrdersList, ...printedOrdersMessagesList],
    };
  };

  const triggerOrderDetailPrints = (
    inboundList,
    preferences = {},
    memberCode,
    qrImagesData,
    direction = "Inbound"
  ) => {
    const iframeId =
      direction === "Inbound"
        ? `iframeForOrder_${memberCode}`
        : `iframeForOrder_Outbound_${memberCode}`;
    const customerInfoList = inboundList.map(
      (each) => each.orderDetails.customerInfo
    );
    const ordersData = inboundList.map(
      (each) => each.orderDetails.orderItems[0]
    );

    let splitOrdersDataIntoChunks = [];
    for (let i = 0; i < ordersData.length; i += ORDERS_LIMIT_PER_PDF) {
      splitOrdersDataIntoChunks.push(
        ordersData.slice(i, i + ORDERS_LIMIT_PER_PDF)
      );
    }

    splitOrdersDataIntoChunks.forEach((eachSubArr, index) => {
      setTimeout(() => {
        const orderDetailsHtml = getMultiOrderPrintHTML({
          data: eachSubArr,
          memberCodes,
          operatorName: userProfile.firstName,
          messages,
          Localise,
          customerInfoList,
          iframeId,
          qrImagesData,
        });
        PrintIframeAction(
          orderDetailsHtml,
          {
            printType:
              direction === "Inbound" ? "orderDetails" : "outboundOrderDetails",
            preferences,
          },
          () => {},
          iframeId
        );
      }, index * 500);
    });
  };

  const triggerOrderMessagePrints = (
    inboundList,
    preferences = {},
    memberCode,
    qrImagesData,
    direction = "Inbound"
  ) => {
    const iframeId = `iframeForOrderMessage_${direction}_${memberCode}`;
    const customerInfoList = inboundList.map(
      (each) => each.orderDetails.customerInfo
    );
    const ordersData = inboundList.map(
      (each) => each.orderDetails.orderItems[0]
    );
    let splitOrdersDataIntoChunks = [];
    for (let i = 0; i < ordersData.length; i += ORDERS_LIMIT_PER_PDF) {
      splitOrdersDataIntoChunks.push(
        ordersData.slice(i, i + ORDERS_LIMIT_PER_PDF)
      );
    }
    splitOrdersDataIntoChunks.forEach((eachSubArr, index) => {
      setTimeout(() => {
        const printType = `order${direction}Messages`;
        const orderDetailsHtml = getMultiOrderPrintHTML({
          data: eachSubArr,
          memberCodes,
          operatorName: userProfile.firstName,
          messages,
          Localise,
          customerInfoList,
          iframeId,
          qrImagesData,
          printType,
        });

        PrintIframeAction(
          orderDetailsHtml,
          {
            printType,
            preferences,
          },
          () => {},
          iframeId
        );
      }, index * 500);
    });
  };

  const triggerTrifoldPrints = (
    inboundList,
    preferences = {},
    memberCode,
    qrImagesData,
    additional_sympathy_card = ""
  ) => {
    const iframeId = `iframeForTrifold_${memberCode}`;

    let splitInboundListIntoChunks = [];
    for (let i = 0; i < inboundList.length; i += ORDERS_LIMIT_PER_PDF) {
      splitInboundListIntoChunks.push(
        inboundList.slice(i, i + ORDERS_LIMIT_PER_PDF)
      );
    }

    splitInboundListIntoChunks.forEach((eachSubArr, index) => {
      setTimeout(() => {
        const printCardHtml = getMultiCardPrintHTML(
          eachSubArr,
          imagesData,
          iframeId,
          qrImagesData,
          additional_sympathy_card
        );
        PrintIframeAction(
          printCardHtml,
          {
            printType: "trifold",
            preferences,
          },
          () => {},
          iframeId
        );
      }, index * 500);
    });
  };

  const triggerInvoicePrints = (
    outboundList,
    preferences = {},
    memberCode,
    qrImagesData,
    additional_sympathy_invoice,
    direction = "Inbound"
  ) => {
    const iframeId = `iframeForInvoice_${direction}_${memberCode}`;

    let splitOutboundListIntoChunks = [];
    for (let i = 0; i < outboundList.length; i += ORDERS_LIMIT_PER_PDF) {
      splitOutboundListIntoChunks.push(
        outboundList.slice(i, i + ORDERS_LIMIT_PER_PDF)
      );
    }

    splitOutboundListIntoChunks.forEach((eachSubArr, index) => {
      setTimeout(() => {
        const printInvoiceHtml = getMultiInvoicePrintHTML({
          data: eachSubArr,
          memberCodes,
          messages,
          Localise,
          iframeId,
          qrImagesData,
          additional_sympathy_invoice,
        });
        PrintIframeAction(
          printInvoiceHtml,
          {
            printType: "invoice",
            preferences,
          },
          () => {},
          iframeId
        );
      }, index * 500);
    });
  };

  const triggerWorksheetPrints = (
    inboundList,
    preferences = {},
    memberCode,
    qrImagesData
  ) => {
    const iframeId = `iframeForWorksheet_${memberCode}`;
    inboundList.forEach((eachOrder, index) => {
      setTimeout(() => {
        const worksheetHtml = getMultiWorksheetPrintHTML({
          eachOrder,
          operatorName: userProfile.firstName,
          messages,
          Localise,
          iframeId,
          qrImagesData,
        });
        PrintIframeAction(
          worksheetHtml,
          {
            printType: "designerWorksheet",
            preferences,
          },
          () => {},
          iframeId
        );
      }, index * 500);
    });
  };

  let pollTimerForAutoPrint = useRef(null);

  //Using this ref to store Last failure call start time so that we can send this time as startTime to next call.
  const printLastCallStartTime = useRef("");

  //Iterate through each member and print orders sequentially.
  const printOrders = (orders) => {
    if (!orders.length) return;
    let updatePayload = [];

    const macAddress =
      isElectronApp && document.getElementById("macAddress").value;

    if (!macAddress) return;

    orders.forEach((orderInfo, idx) => {
      const index = idx + 1;
      // const memberInfo = orders.shift();
      const shopsPreferences = UserProfileStorage.getAllShopPreferences();
      const { memberCode = "", autoPrintOrder = [] } = orderInfo;
      const inboundList = autoPrintOrder.filter(
        (orderInfo) =>
          orderInfo.orderDetails.orderItems[0].direction === "INBOUND"
      );
      const outboundList = autoPrintOrder.filter(
        (orderInfo) =>
          orderInfo.orderDetails.orderItems[0].direction === "OUTBOUND"
      );

      const preferences = shopsPreferences[memberCode];

      const orderPreferencesArray =
        preferences["order_details_print_settings"] || [];
      const outboundOrderPreferencesArray =
        preferences["order_details_outgoing_print_settings"] || [];
      const trifoldPreferencesArray =
        preferences["trifold_card_print_settings"] || [];
      const invoicePreferencesArray =
        preferences["legal_size_invoice_print_settings"] || [];
      const worksheetPreferencesArray =
        preferences["designer_worksheet_print_settings"] || [];
      const orderMessagesInboundPreferencesArray =
        preferences["order_messages_incoming_print_settings"] || [];
      const orderMessagesOutboundPreferencesArray =
        preferences["order_messages_outgoing_print_settings"] || [];

      const orderPreferences = orderPreferencesArray.find(
        (each) => each.macAddress === macAddress
      );
      const outboundOrderPreferences = outboundOrderPreferencesArray.find(
        (each) => each.macAddress === macAddress
      );
      const trifoldPreferences = trifoldPreferencesArray.find(
        (each) => each.macAddress === macAddress
      );
      const invoicePreferences = invoicePreferencesArray.find(
        (each) => each.macAddress === macAddress
      );
      const worksheetPreferences = worksheetPreferencesArray.find(
        (each) => each.macAddress === macAddress
      );
      const orderMessagesInboundPreferences =
        orderMessagesInboundPreferencesArray.find(
          (each) => each.macAddress === macAddress
        );
      const orderMessagesOutboundPreferences =
        orderMessagesOutboundPreferencesArray.find(
          (each) => each.macAddress === macAddress
        );

      const qrCodeEnableValue = preferences["qr_code_global_enabled"] || "";
      const showQrCode = qrCodeEnableValue === "true";

      const qrCodeInvoiceEnableValue =
        preferences["qr_code_invoice_enabled"] || "";
      const showInvoiceQrCode = qrCodeInvoiceEnableValue === "true";

      const qrCodeWorksheetEnableValue =
        preferences["qr_code_worksheet_enabled"] || "";
      const showWorksheetQrCode = qrCodeWorksheetEnableValue === "true";

      const qrCodeOrderDetailsEnableValue =
        preferences["qr_code_order_details_enabled"] || "";
      const showOrderDetailsQrCode = qrCodeOrderDetailsEnableValue === "true";

      // We are showing the triFold QR code only with the combination of Recipient Top Half / Delivery Information Bottom Half (section3 placement)
      // Incase of autoPrint we are generating the QR code in bulkway depending on the Tri-Fold QR switch.
      const qrCodeTriFoldEnableValue =
        preferences["qr_code_trifold_enabled"] || "";
      const showTriFoldQrCode = qrCodeTriFoldEnableValue === "true";

      const qrPayload = [];

      if (
        showQrCode &&
        (showInvoiceQrCode ||
          showWorksheetQrCode ||
          showOrderDetailsQrCode ||
          showTriFoldQrCode)
      ) {
        autoPrintOrder.forEach((each, index) => {
          qrPayload.push({
            oid: each.orderDetails.orderItems[0].orderItemId || "", // orderItemId
            sm: memberCode || "", // sourceMemberCode based on orderDirection
            dm:
              each.orderDetails.orderItems[0].deliveryInfo.deliveryMethod || "", // order deliveryMethod
          });
        });
      }

      const readyToPrint = (
        qrImagesData = [],
        showInvoiceQrCode,
        showWorksheetQrCode,
        showOrderDetailsQrCode,
        showTriFoldQrCode
      ) => {
        if (
          isEligibleToPrint(orderPreferences) ||
          isEligibleToPrint(outboundOrderPreferences)
        ) {
          const printOrdersByDirection = (
            orderList,
            preferences,
            direction
          ) => {
            const unPrintedOrders = orderList.filter((order) => {
              const { isPrinted, status, isOrderDetailsPrintRequested } =
                order?.orderDetails?.orderItems[0] || {};

              // Determine if the order is eligible for auto-print
              const isAutoPrintEligible =
                preferences?.auto_print &&
                !orderDetailsPrintIneligibleStatusList.includes(status) &&
                !isPrinted;

              // Determine if the order is eligible for remote print
              const isRemotePrintEligible =
                preferences?.remote_print && isOrderDetailsPrintRequested;

              // Include the order if either auto-print or remote-print condition is satisfied
              return isAutoPrintEligible || isRemotePrintEligible;
            });

            unPrintedOrders?.length &&
              setTimeout(() => {
                triggerOrderDetailPrints(
                  unPrintedOrders,
                  preferences,
                  memberCode,
                  showOrderDetailsQrCode ? qrImagesData : [],
                  direction
                );
              }, index * 500);
          };

          inboundList.length &&
            printOrdersByDirection(inboundList, orderPreferences, "Inbound");

          outboundList.length &&
            printOrdersByDirection(
              outboundList,
              outboundOrderPreferences,
              "Outbound"
            );
        }

        if (isEligibleToPrint(trifoldPreferences)) {
          const printTrifoldCard = (orderList, preferences) => {
            const unPrintedOrders = orderList.filter((order) => {
              const {
                isTrifoldDetailsPrinted,
                isTrifoldPrintRequested,
                direction,
              } = order?.orderDetails?.orderItems[0] || {};

              // Determine if the order is eligible for auto-print
              const isAutoPrintEligible =
                direction === "INBOUND"
                  ? preferences?.auto_print && !isTrifoldDetailsPrinted
                  : false; // Not supporting autoprint for trifold card for outbound order

              // Determine if the order is eligible for remote print
              const isRemotePrintEligible =
                preferences?.remote_print && isTrifoldPrintRequested;

              // Include the order if either auto-print or remote-print condition is satisfied
              return isAutoPrintEligible || isRemotePrintEligible;
            });

            unPrintedOrders?.length &&
              setTimeout(() => {
                triggerTrifoldPrints(
                  unPrintedOrders,
                  preferences,
                  memberCode,
                  showTriFoldQrCode ? qrImagesData : [],
                  preferences["additional_sympathy_card"]
                );
              }, index * 1000);
          };

          inboundList.length &&
            printTrifoldCard(inboundList, trifoldPreferences);

          outboundList.length &&
            printTrifoldCard(outboundList, trifoldPreferences);
        }

        if (isEligibleToPrint(invoicePreferences)) {
          const printInvoice = (orderList, preferences) => {
            const unPrintedOrders = orderList.filter((order) => {
              const { isInvoiceDetailsPrinted, isInvoicePrintRequested } =
                order?.orderDetails?.orderItems[0] || {};

              // Determine if the order is eligible for auto-print
              const isAutoPrintEligible =
                preferences?.auto_print && !isInvoiceDetailsPrinted;

              // Determine if the order is eligible for remote print
              const isRemotePrintEligible =
                preferences?.remote_print && isInvoicePrintRequested;

              // Include the order if either auto-print or remote-print condition is satisfied
              return isAutoPrintEligible || isRemotePrintEligible;
            });

            unPrintedOrders?.length &&
              setTimeout(() => {
                triggerInvoicePrints(
                  unPrintedOrders,
                  preferences,
                  memberCode,
                  showInvoiceQrCode ? qrImagesData : [],
                  preferences["additional_sympathy_invoice"]
                );
              }, index * 1500);
          };

          inboundList.length && printInvoice(inboundList, invoicePreferences);
          outboundList.length && printInvoice(outboundList, invoicePreferences);
        }

        if (isEligibleToPrint(worksheetPreferences)) {
          const printWorksheets = (orderList, preferences) => {
            const unPrintedOrders = orderList.filter((order) => {
              const {
                isDesignerWorkSheetPrinted,
                isDesignerWorksheetPrintRequested,
                direction,
              } = order?.orderDetails?.orderItems[0] || {};

              // Determine if the order is eligible for auto-print
              const isAutoPrintEligible =
                direction === "INBOUND"
                  ? worksheetPreferences?.auto_print &&
                    !isDesignerWorkSheetPrinted
                  : false; // Not supporting autoprint for worksheet for outbound order

              // Determine if the order is eligible for remote print
              const isRemotePrintEligible =
                worksheetPreferences?.remote_print &&
                isDesignerWorksheetPrintRequested;

              return isAutoPrintEligible || isRemotePrintEligible;
            });

            unPrintedOrders?.length &&
              setTimeout(() => {
                triggerWorksheetPrints(
                  unPrintedOrders,
                  preferences,
                  memberCode,
                  showWorksheetQrCode ? qrImagesData : []
                );
              }, index * 2000);
          };
          inboundList.length &&
            printWorksheets(inboundList, worksheetPreferences);
          outboundList.length &&
            printWorksheets(outboundList, worksheetPreferences);
        }

        if (
          orderMessagesInboundPreferences?.auto_print ||
          orderMessagesInboundPreferences?.remote_print
        ) {
          setTimeout(() => {
            const unPrintedOrders = autoPrintOrder.filter((order) => {
              const {
                messages = [],
                deliveryInfo: { deliveryMethod },
              } = order?.orderDetails?.orderItems[0] || {};
              return (
                deliveryMethod === "FLORIST_PARTNER" &&
                messages.find((message) => {
                  // Check if message is eligible for auto print
                  const isAutoPrintEligible =
                    orderMessagesInboundPreferences?.auto_print &&
                    message.messageType !== "OLC" &&
                    !message.isPrinted &&
                    message.direction === "INBOUND";

                  // Check if message is eligible for remote print based on isPrintRequested flag
                  const isRemotePrintEligible =
                    orderMessagesInboundPreferences?.remote_print &&
                    message.messageType !== "OLC" &&
                    message.direction === "INBOUND" &&
                    message.isPrintRequested;

                  // Include message if either auto print or remote print condition is met
                  return isAutoPrintEligible || isRemotePrintEligible;
                })
              );
            });
            unPrintedOrders?.length &&
              triggerOrderMessagePrints(
                unPrintedOrders,
                orderMessagesInboundPreferences,
                memberCode,
                qrImagesData,
                "Inbound"
              );
          }, index * 2000);
        }
        if (
          orderMessagesOutboundPreferences?.auto_print ||
          orderMessagesOutboundPreferences?.remote_print
        ) {
          setTimeout(() => {
            const unPrintedOrders = autoPrintOrder.filter((order) => {
              const {
                messages = [],
                deliveryInfo: { deliveryMethod },
              } = order?.orderDetails?.orderItems[0] || {};
              return (
                deliveryMethod === "FLORIST_PARTNER" &&
                messages.find((message) => {
                  // Check if message is eligible for auto print
                  const isAutoPrintEligible =
                    orderMessagesOutboundPreferences?.auto_print &&
                    message.messageType !== "OLC" &&
                    !message.isPrinted &&
                    message.direction === "OUTBOUND";

                  // Check if message is eligible for remote print based on isPrintRequested flag
                  const isRemotePrintEligible =
                    orderMessagesOutboundPreferences?.remote_print &&
                    message.messageType !== "OLC" &&
                    message.direction === "OUTBOUND" &&
                    message.isPrintRequested;

                  // Include message if either auto print or remote print condition is met
                  return isAutoPrintEligible || isRemotePrintEligible;
                })
              );
            });
            unPrintedOrders?.length &&
              triggerOrderMessagePrints(
                unPrintedOrders,
                orderMessagesOutboundPreferences,
                memberCode,
                qrImagesData,
                "Outbound"
              );
          }, index * 2000);
        }
      };

      if (qrPayload.length > 0) {
        dispatch(
          generateQRCode({
            params: { qrPayload: qrPayload },
            type: "BULK",
            resolve: (resp) => {
              const qrImagesData = resp;
              readyToPrint(
                qrImagesData,
                showInvoiceQrCode,
                showWorksheetQrCode,
                showOrderDetailsQrCode,
                showTriFoldQrCode
              );
            },
            reject: () => {
              readyToPrint();
            },
          })
        );
      } else {
        readyToPrint();
      }

      const printStatus = getPrintStatusOfOrders(memberCode, autoPrintOrder, [
        orderPreferences,
        outboundOrderPreferences,
        trifoldPreferences,
        invoicePreferences,
        worksheetPreferences,
        orderMessagesInboundPreferences,
        orderMessagesOutboundPreferences,
      ]);
      !isEmpty(printStatus?.autoPrintOrders) && updatePayload.push(printStatus);
    });
    updatePayload.length &&
      dispatch(updateAutoPrintOrders({ payload: updatePayload }));
  };

  const getAutoPrintOrdersByMember = (startDate) => {
    const shopsPreferences = UserProfileStorage.getAllShopPreferences();
    const macAddress =
      isElectronApp && document.getElementById("macAddress").value;

    if (!macAddress) return;

    let autoprintMembers = [];
    let isOrderDetailPrintEligible = false;
    let isOutboundOrderDetailPrintEligible = false;
    let isTrifoldPrintEligible = false;
    let isInvoicePrintEligible = false;
    let isDesignerWorksheetPrintEligible = false;
    let isOutboundOrderMessagePrintEligible = false;
    let isInboundOrderMessagePrintEligible = false;
    let isOrderDetailRemotePrintEligible = false;
    let isTrifoldRemotePrintEligible = false;
    let isDesignerWorksheetRemotePrintEligible = false;
    let isInvoiceRemotePrintEligible = false;
    let isOutboundOrderDetailRemotePrintEligible = false;
    let isOutboundOrderMessageRemotePrintEligible = false;
    let isInboundOrderMessageRemotePrintEligible = false;

    memberCodes.forEach((memberCode) => {
      const preferences = shopsPreferences[memberCode];
      const orderPreferencesArray =
        preferences["order_details_print_settings"] || [];
      const outgoingOrderPreferencesArray =
        preferences["order_details_outgoing_print_settings"] || [];
      const trifoldPreferencesArray =
        preferences["trifold_card_print_settings"] || [];
      const invoicePreferencesArray =
        preferences["legal_size_invoice_print_settings"] || [];
      const worksheetPreferencesArray =
        preferences["designer_worksheet_print_settings"] || [];
      const orderMessagesInboundPreferencesArray =
        preferences["order_messages_incoming_print_settings"] || [];
      const orderMessagesOutboundPreferencesArray =
        preferences["order_messages_outgoing_print_settings"] || [];

      const orderPreferences = orderPreferencesArray.find(
        (each) => each.macAddress === macAddress
      );
      const outgoingOrderPreferences = outgoingOrderPreferencesArray.find(
        (each) => each.macAddress === macAddress
      );
      const trifoldPreferences = trifoldPreferencesArray.find(
        (each) => each.macAddress === macAddress
      );
      const invoicePreferences = invoicePreferencesArray.find(
        (each) => each.macAddress === macAddress
      );
      const worksheetPreferences = worksheetPreferencesArray.find(
        (each) => each.macAddress === macAddress
      );
      const orderMessagesInboundPreferences =
        orderMessagesInboundPreferencesArray.find(
          (each) => each.macAddress === macAddress
        );
      const orderMessagesOutboundPreferences =
        orderMessagesOutboundPreferencesArray.find(
          (each) => each.macAddress === macAddress
        );

      // Auto Print Flags
      isOrderDetailPrintEligible =
        isOrderDetailPrintEligible ||
        (orderPreferences ? orderPreferences?.auto_print : false);
      isOutboundOrderDetailPrintEligible =
        isOutboundOrderDetailPrintEligible ||
        (outgoingOrderPreferences
          ? outgoingOrderPreferences?.auto_print
          : false);
      isTrifoldPrintEligible =
        isTrifoldPrintEligible ||
        (trifoldPreferences ? trifoldPreferences?.auto_print : false);
      isInvoicePrintEligible =
        isInvoicePrintEligible ||
        (invoicePreferences ? invoicePreferences?.auto_print : false);
      isDesignerWorksheetPrintEligible =
        isDesignerWorksheetPrintEligible ||
        (worksheetPreferences ? worksheetPreferences?.auto_print : false);
      isOutboundOrderMessagePrintEligible =
        isOutboundOrderMessagePrintEligible ||
        (orderMessagesOutboundPreferences
          ? orderMessagesOutboundPreferences?.auto_print
          : false);
      isInboundOrderMessagePrintEligible =
        isInboundOrderMessagePrintEligible ||
        (orderMessagesInboundPreferences
          ? orderMessagesInboundPreferences?.auto_print
          : false);

      // Remote Print Flags
      isOrderDetailRemotePrintEligible =
        isOrderDetailRemotePrintEligible ||
        orderPreferences?.remote_print ||
        false;

      isOutboundOrderDetailRemotePrintEligible =
        isOutboundOrderDetailRemotePrintEligible ||
        outgoingOrderPreferences?.remote_print ||
        false;

      isTrifoldRemotePrintEligible =
        isTrifoldRemotePrintEligible ||
        trifoldPreferences?.remote_print ||
        false;

      isDesignerWorksheetRemotePrintEligible =
        isDesignerWorksheetRemotePrintEligible ||
        worksheetPreferences?.remote_print ||
        false;

      isInvoiceRemotePrintEligible =
        isInvoiceRemotePrintEligible ||
        invoicePreferences?.remote_print ||
        false;

      isInboundOrderMessageRemotePrintEligible =
        isInboundOrderMessageRemotePrintEligible ||
        orderMessagesInboundPreferences?.remote_print ||
        false;

      isOutboundOrderMessageRemotePrintEligible =
        isOutboundOrderMessageRemotePrintEligible ||
        orderMessagesOutboundPreferences?.remote_print ||
        false;

      const preferencesArray = [
        orderPreferences,
        outgoingOrderPreferences,
        trifoldPreferences,
        invoicePreferences,
        worksheetPreferences,
        orderMessagesOutboundPreferences,
        orderMessagesInboundPreferences,
      ];

      // Check if any preference has 'auto_print' or 'remote_print' enabled for the member.
      const isEnabled = preferencesArray.some(isEligibleToPrint);

      if (isEnabled) autoprintMembers.push(memberCode);
    });

    if (autoprintMembers.length) {
      dispatch(
        fetchAutoPrintOrders({
          data: {
            messagesStartDate: moment()
              .subtract(autoPrintMessagesStartEndInterval, "millisecond")
              .utc()
              .format(),
            startDate: printLastCallStartTime.current || startDate,
            endDate: moment().utc().format(),
            memberCodes: autoprintMembers,
            //AutoPrint Flags
            isOrderDetailPrintEligible,
            isOutboundOrderDetailPrintEligible,
            isTrifoldPrintEligible,
            isInvoicePrintEligible,
            isDesignerWorksheetPrintEligible,
            isInboundOrderMessagePrintEligible,
            isOutboundOrderMessagePrintEligible,
            //RemotePrint Flags
            isOrderDetailRemotePrintEligible,
            isOutboundOrderDetailRemotePrintEligible,
            isTrifoldRemotePrintEligible,
            isDesignerWorksheetRemotePrintEligible,
            isInvoiceRemotePrintEligible,
            isInboundOrderMessageRemotePrintEligible,
            isOutboundOrderMessageRemotePrintEligible,
            newAutoPrintContract: true,
          },
          resolve: () => {
            if (printLastCallStartTime.current !== "") {
              printLastCallStartTime.current = "";
            }
          },
          reject: () => {
            if (printLastCallStartTime.current === "") {
              printLastCallStartTime.current = startDate;
            }
          },
        })
      );
    }
  };

  useEffect(() => {
    if (!isPrintManagementOn || !isElectronApp) return;
    if (pollTimerForAutoPrint) clearInterval(pollTimerForAutoPrint);

    if (initialTimeStamp) {
      pollTimerForAutoPrint = setInterval(
        () =>
          getAutoPrintOrdersByMember(
            moment()
              .subtract(autoPrintStartEndInterval, "millisecond")
              .utc()
              .format()
          ),
        pollIntervalForAutoPrint
      );
      getAutoPrintOrdersByMember(initialTimeStamp);
    }

    return () => {
      clearInterval(pollTimerForAutoPrint);
    };
  }, [initialTimeStamp]);

  useEffect(() => {
    dispatch(fetchAutoPrintOrdersLastSyncTime({ memberCodes }));
  }, []);

  useEffect(() => {
    if (!isPrintManagementOn || !isElectronApp || !timeStamp) return;
    printOrders(orders);
  }, [timeStamp]);

  // Emulates `forceUpdate()`
  // eslint-disable-next-line no-unused-vars
  const { messages, Localise } = React.useContext(I18NContext);

  return (
    <>
      {isElectronApp &&
        Object.keys(shopNames).map((memberCode, index) => (
          <View key={index}>
            <PrintIframeRenderer id={`iframeForOrder_${memberCode}`} />
            <PrintIframeRenderer id={`iframeForOrder_Outbound_${memberCode}`} />
            <PrintIframeRenderer id={`iframeForTrifold_${memberCode}`} />
            <PrintIframeRenderer id={`iframeForWorksheet_${memberCode}`} />
            <PrintIframeRenderer
              id={`iframeForInvoice_Inbound_${memberCode}`}
            />
            <PrintIframeRenderer
              id={`iframeForInvoice_Outbound_${memberCode}`}
            />
            <PrintIframeRenderer
              id={`iframeForOrderMessage_Inbound_${memberCode}`}
            />
            <PrintIframeRenderer
              id={`iframeForOrderMessage_Outbound_${memberCode}`}
            />
          </View>
        ))}
    </>
  );
};

export default AutoPrint;
