import React, { useContext } from "react";
import { View, TouchableOpacity, Image } from "react-native";
import { Text } from "react-native-elements";
import { fonts, colors, shapes } from "styles/theme";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import IMAGES from "static/assets/images";
import Events from "../../events";
import { PrintIframeRenderer } from "components/elements";

const FuneralLog = (props) => {
  const { onComplete } = props;
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = useContext(DeviceContext);

  return (
    <View
      style={{
        ...shapes.sectionBorder,
        height: "100%",
      }}
    >
      <View style={[tw("flex flex-row justify-between mx-2")]}>
        {isDesktop && (
          <>
            <Text
              style={{
                ...fonts.sectionHeading,
                color: colors.highlighter,
              }}
            >
              {Localise(messages, "Funeral Log")}
            </Text>

            <TouchableOpacity
              onPress={() => onComplete()}
              testID="close"
              accessibilityLabel="close"
            >
              <Image
                style={{ width: 20, height: 20 }}
                resizeMode="cover"
                source={IMAGES["close"]}
              />
            </TouchableOpacity>
          </>
        )}
      </View>
      <PrintIframeRenderer testID={"funeral-manifest"} />
      <Events isFuneralLog={true} onComplete={onComplete} />
    </View>
  );
};

export default FuneralLog;
