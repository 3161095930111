export const getInitialValues = (details) => {
  const initalValues = {
    paymentInfo: {
      nameOnCard: undefined,
      cardNumber: undefined,
      cardType: undefined,
      cvvNumber: undefined,
      expiration: undefined,
      sameAsMailingAddress: false,
      billingAddress: {
        addressLine1: undefined,
        addressLine2: undefined,
        country: undefined,
        city: undefined,
        state: undefined,
        zipcode: undefined,
      },
    },
  };

  return {
    ...initalValues,
    ...details,
  };
};
