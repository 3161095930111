import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";

export const initialState = {
  status: "progress",
  apiResponse: {
    refunds: [],
    orderLookup: {},
  },
  apiError: {
    refunds: "",
  },
  data: {
    refunds: [],
  },
  extendedScreen: {
    showUpsert: false,
    screenTitle: "Refunds",
    activeRecord: "",
    tabs: [{ key: "payments", title: "Payments" }],
    activeTab: "payments",
  },
  actions: {
    search: "",
    shopCode: "",
    noRecordsMessage: "Search for an order to see details",
    limit: {
      refunds: 0,
    },
  },
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: `view::${sliceName}`,
  initialState,
  reducers: {
    setPageInitialize: (state) => {
      state.status = "progress";
      state.extendedScreen = initialState.extendedScreen;
    },
    setPageInitProgress: (state) => {
      state.status = "progress";
    },
    setPageInitSuccess: (state) => {
      state.status = "done";
    },
    setPageInitFailure: (state) => {
      state.status = "fail";
    },
    setPageReset: (state) => {
      Object.assign(state, initialState);
    },
    setApiError: (state, action) => {
      const { section, error } = action.payload;
      state.apiError[section] = error;
    },
    setApiResponse: (state, action) => {
      const { section, content } = action.payload;
      if (section === "refunds") {
        state.apiResponse[section] = content;
      }
      if (section === "order-details") {
        state.apiResponse.orderLookup = content;
      }
      state.apiError[section] = "";
    },
    setPageData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    setPageAction: (state, action) => {
      const { type, value } = action.payload;
      state.actions[type] = value;
    },
    setExtendedScreen: (state, action) => {
      state.extendedScreen = { ...state.extendedScreen, ...action.payload };
    },
    setPageSearch: (state, action) => {
      state.status = "progress";
      if (action.payload) {
        const { type, value } = action.payload;
        state.actions[type] = value;
      }
    },

    handleRefund: () => {},
    fetchOrderDetails: () => {},
  },
});

export const {
  setPageInitialize,
  setPageInitProgress,
  setPageInitSuccess,
  setPageInitFailure,
  setPageReset,
  setApiError,
  setApiResponse,
  setPageData,
  setPageAction,
  setPageSearch,
  handleRefund,
  setExtendedScreen,
  fetchOrderDetails,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
