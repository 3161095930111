import React from "react";
import { Text } from "react-native-elements";
import { View, Image, TouchableOpacity, Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "components/elements";

import tw from "tailwind-rn";
import moment from "moment";
import { useFormikContext } from "formik";

import IMAGES from "static/assets/images";
import { backgroundColors, colors } from "styles/theme";

import I18NContext from "library/contexts/i18N";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import { selectRecordData } from "library/sagas/ongoing/current-orders/selector";
import {
  selectOrderDetailsFromScan,
  selectOrderActions,
} from "library/sagas/ongoing/order-details/selector";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { setShowOrderUnpaidModal } from "library/sagas/ongoing/order-details/slice";
import { hasDesignCenterFeature } from "library/utils/featureAvailability";

import {
  getConfig,
  getDisplayEligible,
  getOrderStatus,
  actionMap,
} from "./helper";
import QRScanModal from "./qr-scan-action-modal";

const OrderBreadcrumb = React.memo(
  ({
    index,
    listingType,
    isSmallScreen,
    doNotFill,
    isPickUpOrder = false,
    orderRefundStatus,
    sourceMemberCode,
    setSelectedAction,
    triggerAction,
  }) => {
    const dispatch = useDispatch();
    const { messages: locMessages, Localise } = React.useContext(I18NContext);
    const { permissions } = React.useContext(AppSettingsContext);
    const { isMobile } = React.useContext(DeviceContext);
    const orderDetailResponse = useSelector(selectRecordData);
    const orderActions = useSelector(selectOrderActions);
    const { setFieldValue } = useFormikContext();
    const isOrderDetailsFromScan = useSelector(selectOrderDetailsFromScan);
    const [modalVisible, setModalVisibile] = useStateIfMounted(
      isOrderDetailsFromScan
    );

    const orderDetails = {
      ...orderDetailResponse.orderItems[index],
      priceIncludingDelivery: orderDetailResponse.priceIncludingDelivery || 0,
      orderAmounts: orderDetailResponse.orderAmounts,
      paymentStatus:
        orderDetailResponse.paymentDetails?.paymentMethod[0]?.paymentStatus ||
        "",
    };

    const {
      status,
      direction,
      deliveryInfo: { deliveryMethod },
      hasRefundError,
      hasSettlementError,
      paymentStatus,
    } = orderDetails;

    const { orderLastModifiedDate, orderDetailsLastPrintedDate } =
      orderDetailResponse;

    const hasDesignCenter = hasDesignCenterFeature(
      permissions,
      sourceMemberCode
    );

    const internalStatus = getOrderStatus(
      { status, isPickUpOrder },
      listingType
    );

    const config = getConfig(
      direction,
      orderRefundStatus === "processed" ? "refunded" : status,
      isSmallScreen,
      hasRefundError || hasSettlementError,
      internalStatus === "designed" || hasDesignCenter // Displaying the designed breadcrumb button if the order status is already in the designed state or if it has the Design Center feature.
    );

    const upComingStatuses = [];
    const showRePrint =
      orderLastModifiedDate &&
      orderDetailsLastPrintedDate &&
      moment(orderLastModifiedDate).isAfter(orderDetailsLastPrintedDate) &&
      !["cancelled", "error", "forfeited", "rejected"].includes(internalStatus);

    return (
      <View
        style={tw(
          `flex flex-row items-center py-1 ${isMobile ? "w-full" : ""}`
        )}
      >
        {Object.keys(config).map((item, index) => {
          const {
            displayStatus,
            iconExists,
            iconName,
            inActiveIcon,
            actionKey = "",
            styleConfig = {},
          } = config[item] || {};

          const displayEligible = getDisplayEligible(direction) || {};

          const eligibleStatuses =
            displayEligible[
              orderRefundStatus === "processed"
                ? "refunded"
                : hasRefundError || hasSettlementError
                ? "error"
                : internalStatus
            ] || [];

          // isTileActive means this particular tile action is already done so we will show in active state.
          const isTileActive = eligibleStatuses.includes(item);

          // current focused tile to show with text
          const currentStatusTile =
            eligibleStatuses[eligibleStatuses.length - 1] || "";

          // Is current tile focused or not
          const isFocusedTile = item === currentStatusTile;

          const eligibleTakeAction =
            orderActions.filter((each) => each.key === actionKey) || [];

          // Hiding only active statuses for the cancelled, error, forfeited, rejected & outgoing orders
          if (
            !isTileActive &&
            (["cancelled", "error", "forfeited", "rejected"].includes(
              currentStatusTile
            ) ||
              (direction !== "INBOUND" && deliveryMethod !== "PHONE_OUT") ||
              status === "FORWARDED" ||
              !eligibleTakeAction.length)
          )
            return null;

          const isEnabledOrderUnpaidModal =
            paymentStatus?.toLowerCase() === "pending" && item === "completed";

          const pickUpDisplayStatus = {
            delivery: "Ready for Pickup",
            completed: "Picked Up",
          };

          if (!isTileActive)
            upComingStatuses.push({
              name: item,
              displayText:
                isPickUpOrder && ["delivery", "completed"].includes(item)
                  ? pickUpDisplayStatus[item]
                  : displayStatus,
            });

          return (
            <>
              <TouchableOpacity
                key={index}
                activeOpacity={0.5}
                onPress={() => {
                  if (isEnabledOrderUnpaidModal) {
                    dispatch(setShowOrderUnpaidModal(true));
                  } else {
                    if (
                      isTileActive &&
                      direction !== "INBOUND" &&
                      deliveryMethod !== "PHONE_OUT"
                    )
                      return null;
                    setFieldValue("actionItem", actionMap[item]);
                  }
                }}
                testID={`breadcrumb-btn-${item}`}
                accessibilityLabel={`breadcrumb-btn-${item}`}
                style={{
                  ...tw("items-center justify-center flex-row"),
                  height: 32,
                  width: isFocusedTile ? "auto" : 32,
                  marginHorizontal: 2,
                  marginVertical: 4,
                  backgroundColor: isTileActive
                    ? styleConfig.blockBgColor
                    : backgroundColors.secondary,
                  borderWidth: 1,
                  borderColor: isTileActive
                    ? styleConfig.activeBorder
                    : colors.grayScaleLight || colors.grayScaleLight,
                  borderRadius: 2,
                  flexWrap: isSmallScreen ? "nowrap" : "wrap",
                  ...(isFocusedTile && {
                    paddingHorizontal: 7,
                  }),
                  ...((isTileActive || doNotFill) && {
                    pointerEvents: "none",
                  }),
                }}
              >
                <Tooltip
                  text={
                    Platform.OS === "web"
                      ? Localise(locMessages, (displayStatus === "Accepted" && currentStatusTile == "incoming") ? "Click Here to Accept the Order" : displayStatus )
                      : ""
                  }
                >
                  <View
                    style={{
                      ...tw("flex flex-row items-center justify-center"),
                    }}
                  >
                    {iconExists &&
                      IMAGES[isTileActive ? iconName : inActiveIcon] && (
                        <View>
                          <Image
                            style={{
                              width: styleConfig.iconWidth,
                              height: styleConfig.iconHeight,
                            }}
                            source={
                              IMAGES[isTileActive ? iconName : inActiveIcon]
                            }
                          />
                        </View>
                      )}
                    {isFocusedTile && (
                      <Text
                        style={{
                          fontSize: styleConfig.labelSize,
                          color: styleConfig.labelColor,
                          fontWeight: "700",
                          paddingLeft: isSmallScreen ? 0 : 5,
                          textAlign: "center",
                        }}
                      >
                        {Localise(
                          locMessages,
                          displayStatus.replace("Cancelled", "Canceled")
                        )}
                      </Text>
                    )}
                  </View>
                </Tooltip>
              </TouchableOpacity>
            </>
          );
        })}
        {/* In mobile - If order scanned with the QR code then we are showing the QR SCAN modal with all possible order actions */}
        {isOrderDetailsFromScan && Platform.OS !== "web" && (
          <QRScanModal
            modalVisible={modalVisible}
            setModalVisibile={setModalVisibile}
            upComingStatuses={upComingStatuses}
            setFieldValue={setFieldValue}
            showRePrint={showRePrint}
            orderDetails={orderDetails}
            setSelectedAction={setSelectedAction}
            triggerAction={triggerAction}
          />
        )}
      </View>
    );
  }
);

export default OrderBreadcrumb;
