import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";

export const initialState = {
  status: "progress",
  apiResponse: {
    customers: [],
    mergeRecordsSearchCustomers: [],
  },
  apiError: {
    customers: "",
    mergeRecordsSearchCustomers: "",
    customerDetails: "",
    orders: "",
    subscriptions: "",
  },
  data: {
    customers: [],
    totalRecords: 0,
    customerDetails: {},
    originalCustomerDetails: {},
    customersToMerge: [], //For the selected merge customers list
    orders: [],
    receivePayments: [],
    subscriptions: [],
  },
  extendedScreen: {
    showUpsert: false,
    status: "",
    customerId: "",
    storeOrigin: "",
    orderId: "",
    limit: 0,
    menuAction: "",
    recordAction: "",
    addressSuggestions: [],
    meetBallAction: {
      event: "",
      recordId: "",
      hasPendingPayLaterPayment: false,
      deliveryMethod: "",
      sourceMemberCode: "",
      messages: {},
      Localise: () => {},
    },
  },
  actions: {
    search: "",
    mergeRecordsSearch: "",
    filters: [],
    sort: "",
    shops: [],
    limit: {
      customers: 0,
    },
  },
  selectedAccountingType: "Balance_Forward",
  setOpenItemUnpaidOrdersData: {},
  scrolltoSubscriptions: false,
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: `view::${sliceName}`,
  initialState,
  reducers: {
    setPageInitialize: (state) => {
      state.status = "progress";
      state.extendedScreen = initialState.extendedScreen;
    },
    setPageInitSuccess: (state) => {
      state.status = "done";
    },
    setPageInitFailure: (state) => {
      state.status = "fail";
    },
    setPageReset: (state) => {
      Object.assign(state, initialState);
    },
    setApiError: (state, action) => {
      const { section, error } = action.payload;
      state.apiError[section] = error;
    },
    setApiResponse: (state, action) => {
      const { section, content } = action.payload;
      state.apiResponse[section] = content;
      state.apiError[section] = "";
    },
    setPageData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    setHouseAccount: (state, action) => {
      state.data = {
        ...state.data,
        customerDetails: {
          ...state.data.customerDetails,
          houseAccountInfo: action.payload,
        },
      };
    },
    setPageAction: (state, action) => {
      const { type, value } = action.payload;
      if (["filters", "shop"].includes(type))
        state.actions[type] = [...new Set([].concat(...value))];
      else if (type === "limit") {
        const { section } = action.payload;
        state.actions[type][section] = value;
      } else state.actions[type] = value;
    },
    setCurrentCustomer: (state, action) => {
      if (action.payload) {
        const { type, value, viewedAt } = action.payload;
        if (type === "customerId") {
          state.extendedScreen = {
            ...initialState.extendedScreen,
            showUpsert: true,
            customerId: value,
            viewedAt,
          };
        } else if (type === "meetBallAction") {
          state.extendedScreen = {
            ...state.extendedScreen,
            orderId: "",
            menuAction: "",
            recordAction: "",
            meetBallAction: initialState.extendedScreen.meetBallAction,
          };
        } else {
          state.extendedScreen[type] = value;
        }
      } else {
        state.data = {
          ...state.data,
          customerDetails: {},
          orders: [],
        };
        state.extendedScreen = initialState.extendedScreen;
      }
    },
    setCustomerOrders: (state, action) => {
      const { setEmpty } = action.payload;
      if (setEmpty) {
        state.data = {
          ...state.data,
          orders: [],
        };
      }
    },
    setCustomerReceivePayments: (state, action) => {
      const { setEmpty } = action.payload;
      if (setEmpty) {
        state.data = {
          ...state.data,
          receivePayments: [],
        };
      }
    },
    setSideCarInitialize: (state) => {
      state.extendedScreen.status = "progress";
    },
    setSideCarInitSuccess: (state) => {
      state.extendedScreen.status = "done";
      // state.extendedScreen = {
      //   showUpsert: true,
      // };
    },
    setSideCarInitFailure: (state) => {
      state.extendedScreen.status = "fail";
    },
    fetchAllCustomersBySearch: (state, action) => {
      const { type, value } = action.payload;
      state.actions[type] = value;
    },
    setOrderMeatBallAction: (state, action) => {
      const { type, value } = action.payload;
      value.forEach((action, index) => {
        state.extendedScreen.meetBallAction[type[index]] = action;
      });
    },
    setStoreOrigin: (state, action) => {
      const { storeOrigin } = action.payload;
      state.extendedScreen.storeOrigin = storeOrigin;
    },
    setCustomerSubscriptions: (state, action) => {
      const { setEmpty } = action.payload;
      if (setEmpty) {
        state.data = {
          ...state.data,
          subscriptions: [],
        };
      }
    },
    setScrolltoSubscriptions: (state, action) => {
      state.scrolltoSubscriptions = action.payload;
    },
    setAccountingType: (state, action) => {
      state.selectedAccountingType = action.payload;
    },
    setOpenItemUnpaidOrders: (state, action) => {
      state.setOpenItemUnpaidOrdersData = action.payload;
    },
    setCustomersToMerge: (state, action) => {
      const { setEmpty } = action.payload;
      if (setEmpty) {
        state.data = {
          ...state.data,
          customersToMerge: [],
        };
      }
    },
    setCurrentCustomerViewedAt: (state, action) => {
      state.extendedScreen.viewedAt = action.payload;
    },
    fetchCustomerDetails: () => {},
    fetchCustomerActivityDetails: () => {},
    fetchOpenItemUnpaidOrders: () => {},
    setOrderAction: () => {},
    fetchOrderDetails: () => {},
    fetchAllCustomers: () => {},
    fetchCustomerOrders: () => {},
    saveCustomerInfo: () => {},
    saveCustomerPayment: () => {},
    saveCreditDebitMemoHouseAccount: () => {},
    saveCustomerReceivePayment: () => {},
    deleteCustomerCard: () => {},
    fetchAddressSuggestions: () => {},
    saveCustomerHouseAccount: () => {},
    fetchCustomerReceivePayments: () => {},
    fetchCustomerStatement: () => {},
    fetchCustomerNotifications: () => {},
    deleteSubscriptionTemplate: () => {},
    pauseSubscriptionTemplate: () => {},
    resumeSubscriptionTemplate: () => {},
    mergeCustomerProfiles: () => {},
    fetchContactSubscriptions: () => {},
  },
});

export const {
  setPageInitialize,
  setPageInitSuccess,
  setPageInitFailure,
  setPageReset,
  setPageData,
  setApiResponse,
  setApiError,
  setPageAction,
  setCurrentCustomer,
  setOrderMeatBallAction,
  setOrderAction,
  setCustomerOrders,
  setSideCarInitialize,
  setSideCarInitSuccess,
  setSideCarInitFailure,
  setStoreOrigin,
  setHouseAccount,
  setAccountingType,
  setOpenItemUnpaidOrders,
  setCustomersToMerge,
  setCurrentCustomerViewedAt,

  fetchCustomerDetails,
  fetchCustomerActivityDetails,
  fetchOpenItemUnpaidOrders,
  fetchOrderDetails,
  fetchAllCustomers,
  fetchCustomerOrders,
  fetchAllCustomersBySearch,
  fetchAddressSuggestions,
  fetchCustomerReceivePayments,
  fetchCustomerStatement,
  fetchCustomerNotifications,
  fetchContactSubscriptions,

  saveCustomerInfo,
  saveCustomerPayment,
  saveCustomerReceivePayment,
  deleteCustomerCard,
  saveCustomerHouseAccount,
  saveCreditDebitMemoHouseAccount,
  mergeCustomerProfiles,

  setCustomerSubscriptions,
  setScrolltoSubscriptions,
  deleteSubscriptionTemplate,
  pauseSubscriptionTemplate,
  resumeSubscriptionTemplate,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
