// Actions for create/edit route
export const getListOfRouteActions = ({
  status = "DEFAULT",
  localPermissions = {},
  CFROptimise,
  isOptimized = false,
  isEditRoute = false,
}) => {
  const draftSave = { key: "DRAFT", label: "Save", actionType: "save" };
  const inProgressSave = {
    key: "INPROGRESS",
    label: "Save",
    actionType: "saveInProgress",
  };
  const optimize = {
    key: "OPTIMIZE",
    label: `Optimize ${
      localPermissions?.isCFROptimiseEnabled && CFROptimise ? `$` : ``
    }`,
    actionType: "optimize",
    enabled: !isOptimized,
  };
  const ready = { key: "PLANNED", label: "Ready", actionType: "update" };
  const start = { key: "INPROGRESS", label: "Start", actionType: "update" };
  const complete = {
    key: "COMPLETED",
    label: "Complete",
    actionType: "update",
  };
  const endRoute = {
    key: "COMPLETED",
    label: "End Route",
    actionType: "update",
  };
  const print = { key: "PRINTROUTE", label: "Print", actionType: "print" };

  const showOptimize = localPermissions?.isOptimizeEnabled && isEditRoute;

  if (status === "DEFAULT") {
    return [draftSave, ...(showOptimize ? [optimize] : [])];
  } else if (status === "DRAFT") {
    return [
      draftSave,
      ...(showOptimize ? [optimize] : []),
      ready,
      start,
      print,
    ];
  } else if (status === "PLANNED") {
    return [draftSave, ...(showOptimize ? [optimize] : []), start, print];
  } else if (status === "INPROGRESS") {
    return [
      inProgressSave,
      ...(showOptimize ? [optimize] : []),
      endRoute,
      print,
    ];
  } else if (status === "COMPLETED") {
    return [...(showOptimize ? [optimize] : []), print];
  } else if (status === "INCOMPLETE") {
    return [...(showOptimize ? [optimize] : []), complete, print];
  }
};
