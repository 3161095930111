import React from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";

import { selectExtendedScreen } from "library/sagas/views/home/drawer/ftd-admin/mol-settings/selector";

import { SyncStore, ManualSyncStore } from "../../helper";
import FuneralSympathy from "./funeral-sympathy";
import Wedding from "./wedding-pages";

const CustomComponent = () => {
  const { fieldType } = useSelector(selectExtendedScreen);

  return (
    <View>
      {fieldType === "sync-store" && <SyncStore />}
      {fieldType === "manual-sync" && <ManualSyncStore />}
      {fieldType === "funeralSympathy" && <FuneralSympathy />}
      {fieldType === "wedding" && <Wedding />}
    </View>
  );
};

export default CustomComponent;
