const ORDERS = Object.freeze({
  FLORIST_DELIVERED: "FLORIST_DELIVERED",
  FLORIST_PARTNER: "FLORIST_PARTNER",
  STORE_PICKUP: "STORE_PICKUP",
  PHONE_OUT: "PHONE_OUT",
  WALK_IN: "WALK_IN",
  PARTNER_STORE_PICKUP: "PARTNER_STORE_PICKUP",
  MOL_FLORIST_DELIVERED: "MOL_FLORIST_DELIVERED",
  FOL_FLORIST_DELIVERED: "FOL_FLORIST_DELIVERED",
  MOL_CUSTOMER_PICKUP: "MOL_CUSTOMER_PICKUP",
  DELIVERY_SERVICE: "DELIVERY_SERVICE",
  DRAFT_ALREADY_DELETED_MESSAGE: "Draft order was already deleted.",
  DRAFT_CREATE_FAILURE_MESSAGE:
    "Failed to save the draft order. Please try again!",
  DRAFT_CREATE_SUCCESS_MESSAGE: "Draft order has been saved successfully",
  DRAFT_CREATING_MESSAGE: "Creating the draft order. Just a moment.",
  DRAFT_DELETE_FAILURE_MESSAGE:
    "Failed to delete the draft order. Please try again!",
  DRAFT_DELETE_SUCCESS_MESSAGE: "Draft order has been deleted successfully",
  DRAFT_EMPTY_DESCRIPTION:
    "It looks like you don't have any draft orders at the moment. Create a new order to save it as a draft.",
  DRAFT_EMPTY_TEXT: "No Draft Orders Available.",
  DRAFT_CONFIRM_MODAL_CONTENT:
    "We cannot save payment information, such as credit card details, in a draft order. Do you wish to continue saving the draft without the payment information, or cancel to continue working on the order?",
  DRAFT_INFO_TEXT:
    "Click on any draft order tile to open, modify, or complete your order.",
  DRAFT_MODIFY_FAILURE_MESSAGE:
    "Failed to modify the draft order. Please try again!",
  DRAFT_MODIFY_SUCCESS_MESSAGE: "Draft order has been modified successfully",
  DRAFT_MODIFYING_MESSAGE: "Modifying the draft order. Just a moment.",
  DRAFT_TITLE: "Draft Orders",
});

export default ORDERS;
