import Storage from "library/utils/storage";

const UtilCache = new Storage("UtilCache", {
  throttleWait: 0,
});

const load = () => UtilCache.load();

const clear = () => {
  UtilCache.clear();
};

const setReleaseVersion = (version) => {
  return UtilCache.put("CurrentVersion", version);
};

const getReleaseVersion = () => {
  return UtilCache.get("CurrentVersion") || "";
};

const setMacAddressRetryCount = (version) => {
  return UtilCache.put("MacAddressRetryCount", version);
};

const getMacAddressRetryCount = () => {
  return UtilCache.get("MacAddressRetryCount") || 0;
};

export default {
  clear,
  load,
  setReleaseVersion,
  getReleaseVersion,
  setMacAddressRetryCount,
  getMacAddressRetryCount,
};
