import React, { useState } from "react";
import { View } from "react-native";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import I18NContext from "library/contexts/i18N";
import SessionInfo from "../session-info";
import BalanceCheck from "../balance-check";
import UserBalanceCheck from "../user-balance-check";
import tw from "tailwind-rn";
import PaidInOut from "../paid-in-out";
import { Button, Input } from "react-native-elements";
import { theme } from "styles/theme";
import {
  createOrEndSession,
  createTransaction,
} from "library/sagas/session/slice";
import { ToasterHandler, CustomModal } from "components/elements";
import { printTheRecipt } from "../paid-in-out";

const EndSesion = (props) => {
  const {
    sessionInfo,
    createTransaction,
    memberCodes,
    operator,
    isUpdateEligible,
  } = props;
  const { messages, Localise } = React.useContext(I18NContext);
  const [showBalanceCheck, setShowBalanceCheck] = useState(false);
  const [showUserBalanceCheck, setShowUserBalanceCheck] = useState(false);

  const modalContent = {
    content: (
      <BalanceCheck
        operator={operator}
        memberCodes={memberCodes}
        sessionInfo={sessionInfo}
        setShowBalanceCheck={setShowBalanceCheck}
      />
    ),
    buttons: [],
  };

  const userModalContent = {
    content: (
      <UserBalanceCheck
        operator={operator}
        memberCodes={memberCodes}
        sessionInfo={sessionInfo}
        setShowUserBalanceCheck={setShowUserBalanceCheck}
      />
    ),
    buttons: [],
  };

  return (
    <React.Fragment>
      <SessionInfo
        sessionInfo={sessionInfo}
        isMultimemberAccount={memberCodes.length > 1}
      />
      <View style={tw("flex-row items-center mt-8")}>
        <Input
          containerStyle={{
            width: 100,
            marginTop: 7,
          }}
          label="Opening Balance"
          leftIcon={{
            type: "material-community",
            name: "currency-usd",
            size: 13,
          }}
          inputContainerStyle={{
            padding: 6,
          }}
          leftIconContainerStyle={{ paddingRight: 0 }}
          value={parseFloat(sessionInfo.openingBalance).toFixed(2)}
          disabled={true}
        />
        <Input
          containerStyle={{
            width: 100,
            marginTop: 7,
          }}
          label="Current Balance"
          leftIcon={{
            type: "material-community",
            name: "currency-usd",
            size: 13,
          }}
          inputContainerStyle={{
            padding: 6,
          }}
          leftIconContainerStyle={{ paddingRight: 0 }}
          value={parseFloat(sessionInfo.currentBalance).toFixed(2)}
          disabled={true}
        />
      </View>
      {isUpdateEligible && (
        <React.Fragment>
          <PaidInOut sessionInfo={sessionInfo} operator={operator} />
          <View style={tw("flex-row")}>
            <Button
              title={Localise(messages, "No Sale")}
              onPress={() => {
                const { memberId, macAddress } = sessionInfo;
                createTransaction({
                  params: {
                    amount: "0",
                    type: "No-Sale",
                    memberId,
                    macAddress,
                    user: operator,
                    userNote: "",
                    orderId: "",
                  },
                  resolve: (resp) => {
                    printTheRecipt(resp);
                    ToasterHandler(
                      "success",
                      Localise(
                        messages,
                        `Successfully created No Sale transaction`
                      )
                    );
                  },
                  reject: () => {
                    ToasterHandler(
                      "uh oh",
                      Localise(
                        messages,
                        `Failed to create transaction. Please try again.`
                      )
                    );
                  },
                });
              }}
              testID={"No_Sale"}
              accessibilityLabel={"No_Sale"}
              containerStyle={{
                width: 100,
                margin: 5,
                marginTop: 10,
                backgroundColor: "white",
              }}
              titleStyle={theme.Button.secondaryTitleStyle}
              buttonStyle={{
                ...theme.Button.secondaryButtonStyle,
                paddingVertical: 7,
              }}
            />
            <Button
              title={Localise(messages, "Balance Check")}
              onPress={() => {
                const { memberId, macAddress } = sessionInfo;
                setShowUserBalanceCheck(!showUserBalanceCheck);
                createTransaction({
                  params: {
                    amount: "0",
                    type: "No-Sale",
                    memberId,
                    macAddress,
                    user: operator,
                    userNote: "$$BALANCE_CHECK$$",
                    orderId: "",
                  },
                  resolve: (resp) => {
                    printTheRecipt(resp);
                    console.log("No-Sale Transaction created");
                  },
                  reject: () => {
                    ToasterHandler(
                      "uh oh",
                      Localise(
                        messages,
                        `Failed to open the Cash Drawer. Please try again`
                      )
                    );
                  },
                });
              }}
              testID={"balance_check"}
              accessibilityLabel={"balance_check"}
              containerStyle={{
                width: 150,
                margin: 5,
                marginTop: 10,
                backgroundColor: "white",
              }}
              titleStyle={theme.Button.titleStyle}
              buttonStyle={{
                ...theme.Button.buttonStyle,
                padding: 0,
              }}
            />
            <Button
              title={Localise(messages, "End Session")}
              onPress={() => {
                const { memberId, macAddress } = sessionInfo;
                setShowBalanceCheck(!showBalanceCheck);
                //Open Cash Drawer by initiating No Sale Transaction.
                createTransaction({
                  params: {
                    amount: "0",
                    type: "No-Sale",
                    memberId: memberId,
                    macAddress,
                    user: operator,
                    userNote: "$$BALANCE_CHECK$$",
                    orderId: "",
                  },
                  resolve: (resp) => {
                    printTheRecipt(resp);
                    console.log("No-Sale Transaction created");
                  },
                  reject: () => {
                    ToasterHandler(
                      "uh oh",
                      Localise(
                        messages,
                        `Failed to open the Cash Drawer. Please try again`
                      )
                    );
                  },
                });
              }}
              testID={"End_Session"}
              accessibilityLabel={"End_Session"}
              containerStyle={{ width: 150, margin: 5, marginTop: 10 }}
            />
            <CustomModal
              modalVisible={showBalanceCheck}
              modalContent={modalContent}
            ></CustomModal>
            <CustomModal
              modalVisible={showUserBalanceCheck}
              modalContent={userModalContent}
            ></CustomModal>
          </View>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOrEndSession,
      createTransaction,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(EndSesion);
