import * as React from "react";
import UserAuthContext from "library/contexts/userAuth";
import UserAuthReducer from "library/reducers/userAuth";
import UserAuthStorage from "library/storage/userAuth";
import UserAuthUtils from "library/utils/userAuth";

export default function UserAuthProvider({ children }) {
  const [state, dispatch] = React.useReducer(UserAuthReducer, {
    authToken: UserAuthStorage.getAccessToken(),
    keepMeSignedIn: UserAuthStorage.getKeepMeSignedIn(),
  });

  const context = React.useMemo(
    () => ({
      authToken: null,
      keepMeSignedIn: false,

      signIn: (data) =>
        UserAuthUtils.logIn(data).then((res) => {
          if ("loginType" in res) {
            const validateSSO = res.loginType;
            if (validateSSO === "MANUAL") {
              dispatch({ type: "ENABLE_SSO_LOGIN", ssoUser: validateSSO });
            }
          } else {
            dispatch({
              type: "SIGN_IN",
              token: res,
              //keepMeSignedIn: data?.keepMeSignedIn,
            });
          }
        }),

      signOut: () =>
        UserAuthUtils.logOut().then(() => dispatch({ type: "SIGN_OUT" })),
    }),
    []
  );

  return (
    <UserAuthContext.Provider value={{ ...context, ...state }}>
      {children}
    </UserAuthContext.Provider>
  );
}
