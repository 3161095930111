import { Platform } from "react-native";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

export const isPlatformWeb = Platform.OS === "web";

export const isAnyFieldEmpty = (obj = {}) => {
  return Object.values(obj).some((value) => isEmpty(value));
};

export const promoParams = ({
  date = moment(),
  all = true,
  sortBy = "startDate",
  sortOrder = "ASC",
  memberCode,
} = {}) => ({
  memberCode,
  queryParams: new URLSearchParams({
    requestedDate: moment(date).format("YYYY-MM-DD"),
    showAll: all,
    sortBy,
    sortOrder,
  }).toString(),
});
