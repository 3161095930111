/* eslint-disable react/display-name */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, TouchableOpacity, ActivityIndicator } from "react-native";
import { Text, Image } from "react-native-elements";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

import { Accordion, ToasterHandler } from "components/elements";

import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";
import {
  selectMemberCode,
  selectGroupName,
  selectJobId,
} from "library/sagas/views/home/drawer/ftd-admin/member-settings/manage-groups/selector";
import { setSideCar } from "library/sagas/views/home/drawer/ftd-admin/member-settings/common/slice";

import IMAGES from "static/assets/images";
import { backgroundColors, fonts, colors } from "styles/theme";
import tw from "tailwind-rn";

const DataMigrationJobDetails = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useStateIfMounted(false);
  const [jobDetails, setJobDetails] = useStateIfMounted({});

  const memberCode = useSelector(selectMemberCode);
  const selectedGroupName = useSelector(selectGroupName);
  const selectedJobId = useSelector(selectJobId);

  const onComplete = () => dispatch(setSideCar(""));

  const processMHQReponse = (response) => {
    try {
      if (response.status !== "COMPLETED") {
        response.displayStatus = "In Progress";
      } else {
        response.displayStatus = "Completed";
      }
      setJobDetails(response);
      setLoading(false);
    } catch (err) {
      setJobDetails({});
      setLoading(false);
    }
  };

  const downloadExceptionReport = (jobId) => {
    request("get-exception-report", { jobId, memberCode: memberCode })
      .then((res) => {
        var tempAnchorTag = document.createElement("a"); // creating temp anchor tag
        tempAnchorTag.href =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          res.source;
        tempAnchorTag.download = "exception-report.xlsx";
        tempAnchorTag.click(); // triggering click event in order to download exception report automatically as we alreday clicked on it's link
      })
      .catch((err) => {
        ToasterHandler(
          "error",
          "Failed to download exception report, please try again later."
        );
      });
  };

  useEffect(() => {
    // call the job details api
    if (selectedJobId !== "") {
      setLoading(true);
      request("get-data-migration-job-details", {
        jobId: selectedJobId,
        memberCode: memberCode,
      })
        .then((res) => {
          processMHQReponse(res);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [selectedJobId]);

  return (
    <View
      style={[
        {
          backgroundColor: backgroundColors.sideCar,
        },
      ]}
    >
      <View
        style={[
          tw("px-3 py-3 flex flex-row items-center justify-between"),
          { backgroundColor: backgroundColors.primary },
        ]}
      >
        <View
          style={[
            tw("flex flex-row items-center justify-between"),
            { backgroundColor: backgroundColors.primary },
          ]}
        >
          <Text style={{ ...fonts.heading2, ...tw("text-white pr-4") }}>
            Group: {selectedGroupName}
          </Text>
        </View>

        <View>
          <TouchableOpacity
            onPress={() => {
              onComplete(false);
            }}
            testID="close"
          >
            <Image
              style={{ width: 20, height: 20 }}
              resizeMode="cover"
              source={IMAGES["close"]}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View
        style={[
          {
            borderWidth: 1,
            borderColor: colors.grayScaleLight,
          },
          tw("my-3"),
        ]}
        pointerEvents={"auto"}
      >
        <Accordion
          title={"Data Migration Status"}
          defaultOpen={true}
          handleOnPress={() => {}}
          labelStyle={{
            marginTop: 0,
            height: 50,
            backgroundColor: backgroundColors.navBar,
            borderBottomWidth: 1,
            borderColor: colors.grayScaleLight,
            paddingHorizontal: 12,
          }}
          titleStyle={{
            color: colors.primary,
            paddingLeft: 10,
            fontSize: 13,
          }}
          testID="page_access_info"
        >
          <View
            style={{
              backgroundColor: backgroundColors.secondary,
              padding: 10,
            }}
          >
            {loading && <ActivityIndicator color={colors.activityIndicator} />}

            {!loading && (
              <View style={{ padding: 10 }}>
                {!isEmpty(jobDetails) && (
                  <View
                    style={{
                      ...tw("flex flex-row items-center w-full mb-4"),
                    }}
                  >
                    <Text
                      style={{
                        ...fonts.heading5,
                        marginRight: 5,
                        width: "22%",
                      }}
                    >
                      File Name(s) -
                    </Text>
                    <Text style={{ width: "78%" }}>{jobDetails?.fileName}</Text>
                  </View>
                )}

                {jobDetails?.id && (
                  <View
                    style={{
                      ...tw("flex flex-row w-full mb-4"),
                    }}
                  >
                    <Text style={{ ...fonts.heading4, marginRight: 5 }}>
                      JobId -
                    </Text>
                    <Text>{jobDetails?.id}</Text>
                  </View>
                )}

                <View
                  style={{
                    ...tw("flex flex-row items-center justify-between"),
                  }}
                >
                  {jobDetails?.displayStatus && (
                    <View
                      style={{
                        ...tw("flex flex-row"),
                      }}
                    >
                      <Text style={{ ...fonts.heading4, marginRight: 5 }}>
                        Status -
                      </Text>
                      <Text>{jobDetails?.displayStatus}</Text>
                    </View>
                  )}

                  {jobDetails?.exceptionReportStatus === "COMPLETED" &&
                    jobDetails?.exceptionReportName !== "" && (
                      <TouchableOpacity
                        onPress={() => {
                          downloadExceptionReport(jobDetails.id);
                        }}
                      >
                        <Text style={[fonts.link1]}>
                          Download Exception Report
                        </Text>
                      </TouchableOpacity>
                    )}
                </View>
                <View style={tw("p-2")}>
                  <Text style={tw("p-1")}>
                    Started:{" "}
                    {`${
                      !isEmpty(jobDetails) &&
                      moment
                        .utc(jobDetails.startDate)
                        .tz("America/Chicago")
                        .format("MM/DD/YYYY hh:mm A")
                    }`}{" "}
                    CST
                  </Text>
                  {!isEmpty(jobDetails) &&
                    jobDetails.status === "COMPLETED" && (
                      <Text style={tw("p-1")}>
                        Finished:{" "}
                        {`${
                          !isEmpty(jobDetails) &&
                          moment
                            .utc(jobDetails.endDate)
                            .tz("America/Chicago")
                            .format("MM/DD/YYYY hh:mm A")
                        }`}{" "}
                        CST
                      </Text>
                    )}
                </View>
                {!isEmpty(jobDetails) && jobDetails.status === "COMPLETED" && (
                  <View style={tw("py-3")}>
                    <Text>
                      Status Report - {jobDetails?.successfulRecords?.customers}
                      /{jobDetails?.records?.customers} of customers{" & "}
                      {jobDetails?.successfulRecords?.orders}/
                      {jobDetails?.records?.orders} of orders
                    </Text>
                  </View>
                )}
              </View>
            )}
          </View>
        </Accordion>
      </View>
    </View>
  );
};

export default DataMigrationJobDetails;
