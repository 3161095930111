import React, { useEffect } from "react";
import { View } from "react-native";
import {
  FormField,
  FormFieldPicker,
  FormFieldDatePicker,
} from "components/elements/forms";
import { useFormikContext } from "formik";
import { frequency, subscriptionEnds } from "../helper";

/* eslint-disable react/display-name */
const SubscriptionDetails = React.memo(({ isAutoFill, values, index }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(
      "orderItems.0.deliveryInfo.deliveryDate",
      values.subscriptionInfo.firstDeliveryDate
    );
  }, [values.subscriptionInfo.firstDeliveryDate]);

  return (
    <>
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          marginLeft: -5,
          paddingVertical: 10,
          zIndex: 10,
        }}
        pointerEvents={isAutoFill ? "none" : "auto"}
      >
        <FormFieldDatePicker
          dateValueFormat="YYYY-MM-DD"
          name="firstDeliveryDate"
          label={"First Delivery Date"}
          containerStyle={{
            zIndex: 10,
            width: "30%",
          }}
          placeholder="MM / DD / YYYY"
          path={`subscriptionInfo`}
        />
        <FormFieldPicker
          placeholder={{
            label: "Select Frequency",
            value: null,
          }}
          containerStyle={{ width: "30%" }}
          data={frequency}
          name="frequency"
          label={"Frequency"}
          path={`subscriptionInfo`}
        />
        <FormFieldPicker
          placeholder={{
            label: "Never",
            value: "NEVER",
          }}
          containerStyle={{ width: "30%" }}
          data={subscriptionEnds}
          name="ends"
          label={"Ends"}
          path={`subscriptionInfo`}
        />
      </View>
      <View style={{ zIndex: 9 }}>
        <FormField
          autoCapitalize="none"
          autoCorrect={false}
          name="deliveryInstructions"
          label={"Delivery Instructions"}
          placeholder={"Enter time details or other delivery instructions"}
          containerStyle={{
            width: "90%",
          }}
          path={`orderItems.${index}.deliveryInfo`}
        />
      </View>
    </>
  );
});

export default SubscriptionDetails;
