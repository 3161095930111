import React from "react";
import { View, TouchableOpacity } from "react-native";
import { Text } from "react-native-elements";

import { useDispatch, useSelector } from "react-redux";
import I18NContext from "library/contexts/i18N";
import moment from "moment";

import styles from "./styles";
import tw from "tailwind-rn";

const Item = ({
  id,
  index,
  data,
  columns,
  style,
  selectors: { dataSelector, limitSelector, selectActiveItem },
  actions: { setActiveItem, setStoreOrigin },
}) => {
  const dispatch = useDispatch();
  const itemData = useSelector(dataSelector);
  const selectedItemId = useSelector(selectActiveItem);
  // const totalRecords = useSelector(selectTotalNumberOfRecords); Enable once MSOL-2395 is finalised
  const { [id]: itemId, storeOrigin } = data;
  const totalLimit = itemData?.length ?? 0;
  const activeItem = selectedItemId === itemId;

  const { messages, Localise } = React.useContext(I18NContext);

  const onSelect = () => {
    dispatch(
      setActiveItem({
        type: "customerId",
        value: itemId,
        viewedAt: moment().utc().toISOString(),
      })
    );
    dispatch(setStoreOrigin({ storeOrigin }));
  };

  return (
    <>
      <TouchableOpacity
        activeOpacity={0.6}
        onPress={onSelect}
        style={{
          minWidth: 300,
        }}
        testID={itemId}
        accessibilityLabel={itemId}
      >
        <View
          style={[styles.container, activeItem && styles.activeRowContainer]}
          fsClass="fs-unmask"
        >
          <View
            style={[styles.row, activeItem && styles.activeRow, style]}
            fsClass="fs-unmask"
          >
            {columns.map(({ column, style, type }, index) => {
              return (
                <Text
                  numberOfLines={1}
                  style={[styles.column, { ...style }]}
                  key={index}
                  fsClass={
                    column === "phone" ||
                    column === "email" ||
                    column === "address" ||
                    type === "area"
                      ? "fs-exclude"
                      : "fs-unmask"
                  }
                >
                  {type === "area"
                    ? `${data[column[0]]} ${data[column[1]]}`
                    : data[column]}
                </Text>
              );
            })}
          </View>
        </View>
      </TouchableOpacity>
      {index === totalLimit - 1 && totalLimit > 10 && (
        <View style={tw("flex flex-1 flex-row items-center justify-end p-2")}>
          <Text style={{ color: "red" }}>
            {Localise(
              messages,
              "Please refine your search criteria and try again"
            )}
          </Text>
        </View>
      )}
    </>
  );
};

export default Item;
