import { put, takeLatest, delay } from "redux-saga/effects";

import {
  setPageInitialize,
  setPageInitFailure,
  setPageInitSuccess,
  setApiResponse,
  setPageAction,
  setSideCarInitialize,
  setSideCarInitSuccess,
  setSideCarInitFailure,
  fetchAllCustomers,
  fetchCustomerDetails,
  fetchCustomerActivityDetails,
  fetchOpenItemUnpaidOrders,
  setCustomerOrders,
  setOrderMeatBallAction,
  setOrderAction,
  fetchOrderDetails,
  saveCustomerInfo,
  saveCustomerPayment,
  saveCreditDebitMemoHouseAccount,
  saveCustomerReceivePayment,
  deleteCustomerCard,
  fetchAllCustomersBySearch,
  fetchAddressSuggestions,
  saveCustomerHouseAccount,
  fetchCustomerReceivePayments,
  fetchCustomerStatement,
  fetchCustomerNotifications,
  setCustomerSubscriptions,
  deleteSubscriptionTemplate,
  pauseSubscriptionTemplate,
  resumeSubscriptionTemplate,
  mergeCustomerProfiles,
  setCustomersToMerge,
  fetchContactSubscriptions,
  setCurrentCustomer,
  setStoreOrigin,
} from "./slice";

import {
  handleFetchAllCustomers,
  handleFetchAllCustomersBySearch,
  handleUIRefresh,
} from "./listing-screen";
import {
  handleFetchCustomerDetails,
  handleFetchCustomerActivityDetails,
  handleFetchOpenItemUnpaidOrders,
  handleFetchCustomerOrders,
  handleOrderMeatBallAction,
  handleSetOrderActions,
  handleFetchOrderDetails,
  handleSaveCustomerInfo,
  handleSaveCustomerPayment,
  handleSaveCreditDebitMemoHouseAccount,
  handleSaveCustomerReceivePayment,
  handleDeleteCustomerCreditcard,
  handleFetchAddressSuggestions,
  handleSaveCustomerHouseAccount,
  handleFetchCustomerReceivePayments,
  handleFetchCustomerStatement,
  handleFetchCustomerSubscriptions,
  handleFetchCustomerNotifications,
  handleDeleteSubscriptionTemplate,
  handlePauseSubscriptionTemplate,
  handleResumeSubscriptionTemplate,
  handleMergeCustomerProfiles,
  handleFetchContactSubscriptions,
} from "./upsert-screen";

import get from "lodash/get";
import moment from "moment";

function* handlePageIntialize(action = {}) {
  const { params: { customerId, storeOrigin } = {} } = get(
    action,
    "payload",
    {}
  );

  try {
    yield put(fetchAllCustomers());
    yield put(setPageInitSuccess());
    if (customerId && customerId !== "")
      yield put(
        setCurrentCustomer({
          type: "customerId",
          value: customerId,
          viewedAt: moment().utc().toISOString(),
        })
      );
    if (storeOrigin !== "") yield put(setStoreOrigin({ storeOrigin }));
  } catch (error) {
    yield put(setPageInitFailure());
  }
}

function* handleSideCarIntialize(action = {}) {
  const { storeOrigin, isCreate } = get(action, "payload", {});
  try {
    yield put(fetchCustomerDetails({ storeOrigin }));
    yield put(setCustomersToMerge({ setEmpty: true })); //To clear the existing customersToMerge list if we select a different customer.
    if (!isCreate) {
      yield put(setCustomerOrders({ setEmpty: true }));
      yield put(setCustomerSubscriptions({ setEmpty: true }));
    }
    yield delay(1000);
    yield put(setSideCarInitSuccess());
  } catch (error) {
    yield put(setSideCarInitFailure());
  }
}

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeLatest(setPageInitialize.type, handlePageIntialize);
  yield takeLatest(setSideCarInitialize.type, handleSideCarIntialize);
  yield takeLatest(setApiResponse.type, handleUIRefresh);
  yield takeLatest(setPageAction.type, handleUIRefresh);
  yield takeLatest(fetchCustomerDetails.type, handleFetchCustomerDetails);
  yield takeLatest(
    fetchCustomerActivityDetails.type,
    handleFetchCustomerActivityDetails
  );
  yield takeLatest(
    fetchOpenItemUnpaidOrders.type,
    handleFetchOpenItemUnpaidOrders
  );
  yield takeLatest(
    fetchCustomerReceivePayments.type,
    handleFetchCustomerReceivePayments
  );
  yield takeLatest(setCustomerOrders.type, handleFetchCustomerOrders);
  yield takeLatest(setOrderMeatBallAction.type, handleOrderMeatBallAction);
  yield takeLatest(setOrderAction.type, handleSetOrderActions);

  yield takeLatest(fetchAllCustomers.type, handleFetchAllCustomers);
  yield takeLatest(
    fetchAllCustomersBySearch.type,
    handleFetchAllCustomersBySearch
  );
  yield takeLatest(fetchOrderDetails.type, handleFetchOrderDetails);
  yield takeLatest(saveCustomerInfo.type, handleSaveCustomerInfo);
  yield takeLatest(saveCustomerPayment.type, handleSaveCustomerPayment);
  yield takeLatest(
    saveCreditDebitMemoHouseAccount.type,
    handleSaveCreditDebitMemoHouseAccount
  );
  yield takeLatest(
    saveCustomerReceivePayment.type,
    handleSaveCustomerReceivePayment
  );
  yield takeLatest(deleteCustomerCard.type, handleDeleteCustomerCreditcard);
  yield takeLatest(fetchAddressSuggestions.type, handleFetchAddressSuggestions);
  yield takeLatest(
    saveCustomerHouseAccount.type,
    handleSaveCustomerHouseAccount
  );
  yield takeLatest(fetchCustomerStatement.type, handleFetchCustomerStatement);
  yield takeLatest(
    fetchCustomerNotifications.type,
    handleFetchCustomerNotifications
  );
  yield takeLatest(
    setCustomerSubscriptions.type,
    handleFetchCustomerSubscriptions
  );
  yield takeLatest(
    deleteSubscriptionTemplate.type,
    handleDeleteSubscriptionTemplate
  );
  yield takeLatest(
    pauseSubscriptionTemplate.type,
    handlePauseSubscriptionTemplate
  );
  yield takeLatest(
    resumeSubscriptionTemplate.type,
    handleResumeSubscriptionTemplate
  );
  yield takeLatest(mergeCustomerProfiles.type, handleMergeCustomerProfiles);
  yield takeLatest(
    fetchContactSubscriptions.type,
    handleFetchContactSubscriptions
  );
}

export default watchSaga;
