import React, { memo } from "react";
import { Text, Platform } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { createStyles, minWidth } from "react-native-media-queries";
import { backgroundColors, colors } from "styles/theme";
import tw from "tailwind-rn";

import { CustomModal } from "components/elements";
import { setCompleteRouteModal } from "library/sagas/views/home/drawer/delivery/slice";
import { selectCompleteRouteModalVisible } from "library/sagas/views/home/drawer/delivery/selector";
import { isAdhocStop } from "components/views/drawer/delivery/routes-dashboard/helper";

const completeRouteMessage = (stops = []) => {
  const incompleteStops =
    stops.filter(
      (stop) =>
        isAdhocStop(stop.type) &&
        !["COMPLETED", "INCOMPLETE"].includes(stop.status)
    )?.length || 0;
  const incompleteOrders =
    stops.filter((stop) => stop.type === "ORDER" && stop.status !== "COMPLETED")
      ?.length || 0;

  let message = "";
  message = `Ending this route will ${
    incompleteOrders
      ? `move ${incompleteOrders} undelivered ${
          incompleteOrders == 1 ? `order` : `orders`
        } to non-routed`
      : ``
  }${incompleteOrders && incompleteStops ? " and " : ""}${
    incompleteStops
      ? `mark ${incompleteStops} ${
          incompleteStops == 1 ? `stop` : `stops`
        } as incomplete`
      : ``
  }. Do you want to proceed?`;
  return message;
};

export const hasNonDeliveredOrdersInRoute = (stops = []) =>
  stops.some(
    (stop) =>
      (isAdhocStop(stop.type) &&
        !["COMPLETED", "INCOMPLETE"].includes(stop.status)) ||
      (stop.type === "ORDER" && stop.status !== "COMPLETED")
  );

export const CompleteRouteModal = memo(
  ({ Localise, messages, primaryhandler }) => {
    const dispatch = useDispatch();
    const { modalVisible, routeStops } =
      useSelector(selectCompleteRouteModalVisible) || {};

    const modalMessage = completeRouteMessage(routeStops);
    const modalContent = {
      content: (
        <Text
          style={{
            ...tw("p-4"),
            fontSize: 15,
            color: colors.highlighter,
            textAlign: "center",
          }}
        >
          {Localise(messages, modalMessage)}
        </Text>
      ),
      buttons: [
        { type: "secondary", title: Localise(messages, "Cancel") },
        {
          type: "primary",
          title: Localise(messages, "Continue"),
          testID: "complete_route_Continue",
        },
      ],
    };

    return (
      <CustomModal
        modalVisible={modalVisible}
        modalContent={modalContent}
        primaryhandler={primaryhandler}
        secondaryhandler={() =>
          dispatch(setCompleteRouteModal({ modalVisible: false }))
        }
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: backgroundColors.secondary, width: "100%" },
        ]}
        modalStyle={styles.modalStyle}
      />
    );
  }
);

const styles = createStyles(
  {
    modalStyle:
      Platform.OS !== "web"
        ? {
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            backgroundColor: "#00000070",
            color: "#FFFFFF",
          }
        : { width: "100%" },
  },
  minWidth(767, {
    modalStyle:
      Platform.OS !== "web"
        ? {
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            backgroundColor: "#00000070",
            color: "#FFFFFF",
          }
        : { width: "35%" },
  })
);
