import React from "react";
import { Button } from "react-native-elements";
import I18NContext from "library/contexts/i18N";
import { theme } from "styles/theme";

const PrimaryButton = ({ title, action, style, disabled, loading }) => {
  const { Localise, messages } = React.useContext(I18NContext);

  return (
    <Button
      titleStyle={{
        ...theme.Button.titleStyle,
        fontSize: 12,
      }}
      buttonStyle={{
        ...theme.Button.buttonStyle,
        paddingVertical: 6,
        paddingHorizontal: 6,
      }}
      containerStyle={{
        marginVertical: 0,
        marginHorizontal: 0,
        justifyContent: "center",
        ...style,
      }}
      onPress={action}
      title={Localise(messages, title)}
      testID={title}
      accessibilityLabel={title}
      disabled={disabled}
      loading={loading}
    />
  );
};

export default PrimaryButton;
