import React, { useEffect, useRef, useState } from "react";
import {
  View,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Image,
  Platform,
} from "react-native";
import { Text } from "react-native-elements";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { useFormikContext } from "formik";

import tw from "tailwind-rn";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import get from "lodash/get";

import { colors, fonts, theme, backgroundColors } from "styles/theme";
import IMAGES from "static/assets/images";

import {
  FormFieldPicker,
  FormFieldAutoComplete,
  FormField,
} from "components/elements/forms";
import { Accordion } from "components/elements";
import I18NContext from "library/contexts/i18N";
import { request } from "library/utils/request";
import { giftCardProducts } from "library/utils/giftCardProducts";
import {
  processProductResponse,
  processAddonsResponse,
  filterFromList,
} from "library/utils/createOrder";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { getProductObj } from "../../../create-order/helper";

import {
  fetchProduct,
  fetchMOLAddons,
} from "library/sagas/ongoing/global-data/slice";
import { selectSelectedProducts } from "library/sagas/ongoing/global-data/selector";
import { ITEM_MAX_QUANTITY, MAX_ITEMS_PER_ORDER } from "../../config";

const Quantity = ({ path, messages, Localise }) => {
  const quantityOptions = [];
  const maxQuantity = ITEM_MAX_QUANTITY;

  for (var i = 1; i <= maxQuantity; i++) {
    quantityOptions.push({ label: i.toString(), value: i.toString() });
  }
  return (
    <View style={[tw("flex"), { marginTop: 5 }]}>
      <Text style={{ marginBottom: 5 }}>{Localise(messages, "Qty")}</Text>
      <FormFieldPicker
        name={`quantity`}
        containerStyle={{
          paddingHorizontal: 0,
          width: 85,
        }}
        placeHolderExists={false}
        data={quantityOptions}
        path={path}
      />
    </View>
  );
};

const ProductsList = ({
  isSmallScreen,
  index,
  title,
  addons = [],
  quickProducts = [],
  values,
  setValues,
  sendingMemberCode,
  setSideCarInfo,
  setFieldTouched,
  setFieldValue,
  fetchProduct,
  locationInfo = {},
}) => {
  const prevProducts = useSelector(selectSelectedProducts);
  const { messages, Localise } = React.useContext(I18NContext);

  const [products, setProducts] = useStateIfMounted([]);
  const [localAddOns, setLocalAddOns] = useStateIfMounted([]);
  const [productQuery, setProductQuery] = useStateIfMounted("");

  const productsCount = locationInfo?.products?.length;
  const shopCode = values?.memberCode || "";
  const hasProducts = productsCount > 0;
  const productInfoPath = `locations.${index}`;
  const path = `locations.${index}.products`;

  const scrollViewRef = useRef();
  const finalValues = cloneDeep(values);
  const [loading, setLoading] = useStateIfMounted(false);
  const qtyWarning = get(values, `${productInfoPath}.qtyWarning`) || "";

  useEffect(() => {
    if (productQuery.trim().length >= 3) {
      setLoading(true);
      request("get-local-addons", {
        query: productQuery,
        memberCode: sendingMemberCode,
        siteId: `ftd,${sendingMemberCode}`,
        includeOverrides: true,
      })
        .then((response) => {
          const addons = [
            ...processAddonsResponse(response && response?.productSummaries),
          ];
          setLocalAddOns(addons);
        })
        .catch((error) => {
          console.log("error: " + error);
          setLoading(false);
        });

      request("get-products", {
        query: productQuery,
        memberCode: sendingMemberCode,
        siteId: `fsg,${sendingMemberCode}`,
        includeOverrides: true,
      })
        .then((res) => {
          const products = [
            ...filterFromList(quickProducts, productQuery),
            ...processProductResponse(res && res.records),
          ];

          const formattedProductsList = products.filter(
            (eachProduct) => !giftCardProducts.includes(eachProduct.pid)
          );

          setProducts(formattedProductsList);
        })
        .finally(() => setLoading(false));
    } else {
      setProducts([]);
      setLocalAddOns([]);
    }
  }, [productQuery]);

  useEffect(() => {
    setProductQuery("");
    const products = locationInfo?.products || [];
    if (products.length && values?.eventId?.length > 0) {
      // To load all products details like name, images etc. and MOL add-ons details (if any) when we did copy order

      products.map((product, index) => {
        const { productId = "", siteId = "" } = product;
        if (productId.length > 0) {
          fetchProduct({
            productId: productId,
            siteId: shopCode,
            orderSource: siteId === "fsg" ? "FLORIST" : "LOCAL",
          });
        }
      });
    }
  }, [locationInfo?.products?.length]);

  const data =
    products.length > 0 || productQuery.length >= 3 || localAddOns.length > 0
      ? filterFromList([...products, ...addons, ...localAddOns], productQuery)
      : filterFromList(quickProducts, productQuery);

  const getProductList = () => {
    return hasProducts ? (
      <View
        style={{
          zIndex: -1,
          opacity: 1,
        }}
        pointerEvents={"auto"}
      >
        <ScrollView
          contentContainerStyle={
            (!isSmallScreen && tw("flex flex-row flex-wrap justify-start")) ||
            {}
          }
          horizontal={false}
          ref={scrollViewRef}
        >
          {locationInfo?.products?.map((product, idx) => {
            const cartItems =
              locationInfo?.products.filter((each) => !each.isRemoved) || [];

            return (
              <ProductDetails
                key={idx}
                product={product}
                productPath={path}
                idx={idx}
                isLast={productsCount - 1 === idx}
                isSmallScreen={isSmallScreen}
                messages={messages}
                Localise={Localise}
                productsCount={cartItems.length}
                prevProducts={prevProducts}
                setSideCarInfo={setSideCarInfo}
                productInfoPath={productInfoPath}
              />
            );
          })}
        </ScrollView>
      </View>
    ) : null;
  };

  useEffect(() => {
    if (qtyWarning.length > 0) {
      const timeId = setTimeout(() => {
        setFieldValue(`${productInfoPath}.qtyWarning`, "");
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [qtyWarning, values, index, setFieldValue, productInfoPath]);

  return (
    <View>
      <View style={[tw("flex flex-row pb-3"), { paddingHorizontal: 5 }]}>
        <Text>{Localise(messages, title)}</Text>
        {loading && (
          <ActivityIndicator
            style={{ marginLeft: 5, marginTop: -5 }}
            color={colors.activityIndicator}
          />
        )}

        <TouchableOpacity
          style={{
            marginLeft: "auto",
            opacity: 1,
            pointerEvents: "auto",
          }}
          onPress={() => {
            setSideCarInfo({ key: "product_selection", index });
          }}
          testID="product_catalog"
          accessibilityLabel="product_catalog"
        >
          <Text style={{ ...fonts.link1, fontWeight: "400" }}>
            {Localise(messages, "Product Catalog")}
          </Text>
        </TouchableOpacity>
      </View>

      <View style={[tw("flex flex-row flex-wrap")]}>
        <FormFieldAutoComplete
          autoCapitalize="none"
          autoCorrect={false}
          name="productInfo"
          data={data}
          showOnFocus={true}
          onChangeText={(text) => {
            setProductQuery(text);
          }}
          placeholder={"Product Search"}
          listDisplayValues={["refNumberId", "name", "price"]}
          outerContainerStyle={{
            zIndex: 1,
            width: "100%",
          }}
          path={productInfoPath}
          popperPlacement={hasProducts ? "bottom" : "top"}
          onSelect={(selectedValue, reset) => {
            const {
              pid,
              name,
              price,
              img = "",
              refNumberId,
              siteId = "",
              quantity = "1",
              type = "Product",
            } = selectedValue;

            const products = get(finalValues, path, []);

            // const productIndex = products.findIndex(
            //   (prod) => prod.productFirstChoiceCode === pid
            // );

            // // If same product is already selected then increasing the quantity.
            // if (productIndex >= 0) {
            //   const selectedProduct = products[productIndex];

            //   if (selectedProduct.quantity === ITEM_MAX_QUANTITY) {
            //     const qtyMsg = Localise(
            //       messages,
            //       "You've reached the maximum quantity allowed for"
            //     );
            //     set(
            //       finalValues,
            //       `${productInfoPath}.qtyWarning`,
            //       `${qtyMsg} ${pid} - ${name}`
            //     );
            //   } else if (selectedProduct.isRemoved !== true) {
            //     products[productIndex].quantity = (
            //       parseInt(products[productIndex].quantity) + parseInt(quantity)
            //     ).toString();
            //   } else {
            //     products[productIndex] = {
            //       ...selectedProduct,
            //       isRemoved: false,
            //       quantity: "1",
            //       amount: price,
            //       itemPrice: price,
            //     };
            //   }
            //   set(finalValues, path, products);
            // } else

            if (products.length === MAX_ITEMS_PER_ORDER) {
              set(
                finalValues,
                `${productInfoPath}.qtyWarning`,
                `You've reached the maximum items allowed for this order.`
              );
            } else {
              const newProduct = getProductObj(
                pid,
                name,
                refNumberId,
                img,
                price,
                quantity,
                type,
                siteId
              );

              const updatedProducts = [
                ...products,
                {
                  ...newProduct,
                  amount: newProduct.actualPrice,
                  productId: newProduct.productFirstChoiceCode,
                  productName: newProduct.productFirstChoiceDescription,
                  productLabel: "",
                  placement: "Tables",
                },
              ];
              set(finalValues, path, updatedProducts);
              fetchProduct({
                productId: newProduct.productFirstChoiceCode,
                siteId: sendingMemberCode,
                orderSource: siteId === "fsg" ? "FLORIST" : "LOCAL",
              });
            }
            reset("");
            setValues(finalValues);
            setFieldTouched(`${path}.${products.length}.productLabel`);
          }}
        />
      </View>
      {qtyWarning.length > 0 && (
        <View style={{ paddingLeft: 5 }}>
          <Text style={{ ...theme.Input.errorStyle, zIndex: -1 }}>
            {qtyWarning}
          </Text>
        </View>
      )}
      {getProductList()}
    </View>
  );
};

const ProductDetails = ({
  product,
  idx,
  productPath,
  messages,
  Localise,
  prevProducts,
  isSmallScreen,
  productInfoPath,
}) => {
  const [expand, setExpand] = useState(true);
  if (!product) return null;

  // eslint-disable-next-line
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const {
    productFirstChoiceCode = "",
    productId = "",
    img = "",
    isRemoved = false,
  } = product;

  const currentProductImg =
    prevProducts?.find(
      (each) =>
        each?.productId === productFirstChoiceCode ||
        each?.productId === productId
    )?.primaryImg || img;

  if (isRemoved) return null;

  const imgUri = currentProductImg.includes("http")
    ? currentProductImg
    : `https:${currentProductImg}`;

  const getActions = (actions, handler) => {
    return actions.map((icon, idx) => (
      <TouchableOpacity
        key={idx}
        onPress={() => handler(icon)}
        style={idx === 2 && { paddingLeft: 4 }}
      >
        <Image
          style={{
            width: idx == 2 ? 20 : 24,
            height: idx == 2 ? 20 : 24,
          }}
          source={IMAGES[icon]}
        />
      </TouchableOpacity>
    ));
  };

  const actionHandler = (action, idx) => {
    const products = get(values, productPath);
    if (action === "cancel") {
      products.splice(idx, 1);
      setFieldValue(productPath, products);
    } else if (action === "copy-content") {
      if (products.length === MAX_ITEMS_PER_ORDER) {
        setFieldValue(
          `${productInfoPath}.qtyWarning`,
          `You've reached the maximum items allowed for this order.`
        );
      } else {
        const product = get(values, `${productPath}.${idx}`);
        setFieldValue(productPath, [...products, product]);
        setFieldTouched(`${productPath}.${idx + 1}.productLabel`);
      }
    } else {
      setExpand(!expand);
    }
  };

  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
        opacity: 1,
        marginBottom: 10,
        marginHorizontal: 5,
        width: Platform.OS === "web" ? "calc(100% - 10px)" : "100%",
      }}
    >
      <Accordion
        title={""}
        defaultOpen={expand}
        handleOnPress={setExpand}
        labelStyle={{
          marginTop: 0,
          height: 50,
          backgroundColor: backgroundColors.transparent,
          paddingHorizontal: 12,
        }}
        closeStyle={{
          borderBottomWidth: 1,
          borderColor: colors.grayScaleLight,
        }}
        titleStyle={{
          color: colors.primary,
          paddingLeft: 10,
        }}
        iconStyle={{
          color: colors.primary,
        }}
        containerStyle={{
          width: "100%",
        }}
        headerContent={(openAccordion) => (
          <View style={tw("flex flex-row items-center justify-between w-full")}>
            <View>
              <Text style={{ ...fonts.sectionHeading }}>{`${
                get(values, `${productPath}.${idx}.productLabel`) ||
                "Product Label"
              }`}</Text>
            </View>
            <View style={tw("flex flex-row items-center justify-between")}>
              {getActions(
                [
                  "copy-content",
                  "cancel",
                  openAccordion ? "collapse-circle" : "expand-circle",
                ],
                (action) => {
                  actionHandler(action, idx);
                }
              )}
            </View>
          </View>
        )}
      >
        <View style={{ padding: 10, paddingTop: 0 }}>
          <View style={[tw("flex mb-4")]}>
            <View style={[!isSmallScreen && tw("flex flex-row mt-2")]}>
              <View style={{ width: "30%", marginTop: 5 }}>
                <Image
                  style={{
                    width: 160,
                    height: 180,
                    borderWidth: 1,
                    borderColor: colors.light,
                  }}
                  resizeMode="cover"
                  source={
                    currentProductImg ? { uri: imgUri } : IMAGES[`quick-pick`]
                  }
                />
              </View>

              <View style={{ width: "70%" }}>
                <FormField
                  name="productLabel"
                  containerStyle={{
                    width: "100%",
                    paddingLeft: 30,
                  }}
                  path={`${productPath}.${idx}`}
                  placeholder={Localise(messages, "")}
                  label={Localise(messages, "Product Label")}
                  labelStyle={{ fontWeight: "normal" }}
                  testID="productLabel"
                  accessibilityLabel="productLabel"
                />
                <FormField
                  name="productName"
                  containerStyle={{
                    width: "100%",
                    paddingLeft: 30,
                  }}
                  disabled={true}
                  editable={false}
                  grayedOutOnDisable={true}
                  path={`${productPath}.${idx}`}
                  placeholder={Localise(messages, "")}
                  label={Localise(messages, "Product")}
                  labelStyle={{ fontWeight: "normal" }}
                  testID="productName"
                  accessibilityLabel="productName"
                />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 30,
                    marginBottom: 5,
                  }}
                >
                  <View style={{ marginRight: 20, marginTop: 5 }}>
                    <Text style={{ marginBottom: 5 }}>
                      {Localise(messages, "Amount")}
                    </Text>
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name={"amount"}
                      placeholder="Amount"
                      containerStyle={{
                        ...tw("mb-1"),
                        paddingHorizontal: 0,
                        width: 85,
                      }}
                      isPrice={true}
                      keyboardType="numeric"
                      iconName="usd"
                      iconSize={12}
                      iconType="font-awesome"
                      path={`${productPath}.${idx}`}
                      errorStyle={{ paddingBottom: 0 }}
                    />
                  </View>
                  <Quantity
                    path={`${productPath}.${idx}`}
                    messages={messages}
                    Localise={Localise}
                  />
                </View>
              </View>
            </View>
          </View>
          {values.type === "FUNERAL" && (
            <View>
              {/* <View
                style={[tw("flex flex-row pb-3"), { paddingHorizontal: 5 }]}
              >
                {
                  <TouchableOpacity
                    style={{
                      marginLeft: "auto",
                      opacity: 1,
                      pointerEvents: "auto",
                    }}
                    onPress={() => {
                      setSideCarInfo({
                        key: "abbreviation_codes",
                        index: idx,
                      });
                    }}
                    testID="product_catalog"
                    accessibilityLabel="product_catalog"
                  >
                    <Text
                      style={[tw("mb-1"), fonts.link1, { fontWeight: "400" }]}
                    >
                      {Localise(messages, "Abbreviation Codes")}
                    </Text>
                  </TouchableOpacity>
                }
              </View> */}

              <FormField
                label={"Card Message"}
                labelStyle={{ fontWeight: "normal" }}
                autoCapitalize="none"
                autoCorrect={false}
                name={`cardMessage`}
                placeholder="Enter Card Message"
                containerStyle={[tw("px-0 pb-2")]}
                errorStyle={[tw("pb-0 m-0")]}
                path={`${productPath}.${idx}`}
                multiline={true}
                numberOfLines={2}
              />
            </View>
          )}
          <FormField
            label={"Product Notes"}
            labelStyle={{ fontWeight: "normal" }}
            autoCapitalize="none"
            autoCorrect={false}
            name={`productNotes`}
            placeholder="Product Notes"
            containerStyle={[tw("px-0")]}
            errorStyle={[tw("pb-0 m-0")]}
            path={`${productPath}.${idx}`}
            multiline={true}
            numberOfLines={4}
          />
        </View>
      </Accordion>
    </View>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    currentproduct: state.mhq.ongoing.global.currentproduct,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchProduct, fetchMOLAddons }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductsList);
