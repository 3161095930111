import UserProfileStorage from "library/storage/userProfile";

const Currency = (memberCode, type = "SYMBOL") => {
  const memberCodes = UserProfileStorage.getProfileMemberCodes();
  const code = memberCode === "all" ? memberCodes[0] : memberCode;
  const currencyCode = UserProfileStorage.getShopCurrency(code);

  return type === "SYMBOL"
    ? currencyCode === "CAD"
      ? "CAD"
      : ""
    : type === "CODE"
    ? currencyCode
    : type === "COUNTRY"
    ? currencyCode === "CAD"
      ? "CA"
      : "US"
    : "";
};

export default Currency;
