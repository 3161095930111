import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { selectState as selectMHQState } from "../root-selector";

/**
 * Gets the state slice combined entity states
 * @param {object} state the store state
 * @returns {object} returns the entity state
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || {};

/**
 * A memoized selector function for the combined entity states
 */
export const selectState = createSelector(selectMHQState, getStateSlice);

export default selectState;
