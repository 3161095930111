import React from "react";
import { View } from "react-native";
import { colors } from "styles/theme";
import OpenSession from "./open-session";
import EndSession from "./end-sesion";
import { fetchSessionInfo } from "library/sagas/session/slice";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SplitScreen from "components/containers/split-screen";
import UserProfileContext from "library/contexts/userProfile";
import Activity from "./activity";

const TerminalSession = (props) => {
  const { memberCodes = [] } = React.useContext(UserProfileContext);
  const { operator, sessionInfo = {} } = props;
  const { sessionId, memberId } = sessionInfo;

  const isUpdateEligible = memberCodes.includes(memberId);

  return (
    <SplitScreen showTwoScreens={true}>
      <View
        style={{
          border: "1px solid",
          borderColor: colors.light,
          borderRadius: 5,
          padding: 20,
          display: "flex",
          justifyContent: "center",
          marginTop: 20,
        }}
      >
        {!sessionId ? (
          <OpenSession memberCodes={memberCodes} operator={operator} />
        ) : (
          <EndSession
            memberCodes={memberCodes}
            operator={operator}
            sessionInfo={sessionInfo}
            isUpdateEligible={isUpdateEligible}
          />
        )}
      </View>
      <View fsClass="fs-unmask">
        <Activity />
      </View>
    </SplitScreen>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    sessionInfo: state.mhq.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSessionInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TerminalSession);
