import get from "lodash/get";
import {
  initiateRefundThroughTerminal,
  sendCommandToPAXTerminalV1,
  TransactionTypes,
} from "library/utils/payment-options";
import { formatPrice } from "library/utils/formatter";
import { COMMON } from "library/constants";

export const getSavePayload = ({
  createOrderReqObj,
  values,
  paymentMethodDetails,
  merchantReferenceId,
}) => {
  const billingAddress = get(
    createOrderReqObj,
    "paymentDetails.paymentMethod.0.billingInformation",
    {}
  );

  const { addressLine1, suite, city, state, country, zip } = billingAddress;

  const { customerId, storeOrigin } = values?.customerInfo;

  const {
    nameOnCard,
    creditCardType,
    creditCardNumber,
    creditCardExpireMonth,
    creditCardExpireYear,
    tokenId,
  } = paymentMethodDetails;

  const requestPayload = {
    customerId,
    billingAddress: {
      addressLine1,
      addressLine2: suite || "",
      city,
      state,
      country,
      zipcode: zip,
    },
    storeOrigin,
    nameOnCard,
    cardType: creditCardType,
    expirationMonth: creditCardExpireMonth,
    expirationYear: creditCardExpireYear,
    lastFourDigits: creditCardNumber,
    merchantReferenceId,
    tokenId,
  };

  return requestPayload;
};

export const getCreditCardTypeFromCYBSTxnRes = (cardType) => {
  let creditCardType = cardType;

  if (creditCardType) {
    if (creditCardType.includes(COMMON.CC_AMERICAN_EXPRESS)) {
      creditCardType = COMMON.CC_AMEX;
    } else if (creditCardType.includes(COMMON.CC_DISCOVER)) {
      creditCardType = COMMON.CC_DISCOVER;
    } else if (
      creditCardType.includes(COMMON.CC_MASTERCARD) ||
      creditCardType.includes(COMMON.CC_MAESTRO)
    ) {
      creditCardType = COMMON.CC_MASTERCARD;
    } else if (creditCardType.includes(COMMON.CC_DINERS)) {
      creditCardType = COMMON.CC_DINERS;
    } else if (
      creditCardType.includes(COMMON.CC_VISA) ||
      creditCardType.includes(COMMON.CC_CYBERSOURCE_TOKEN) ||
      creditCardType.includes(COMMON.CC_UNKNOWN)
    ) {
      creditCardType = COMMON.CC_VISA;
    } else {
      console.log("Type of card used for payment :>>", creditCardType);
      creditCardType = COMMON.CC_VISA;
    }
  } else {
    console.log("Received cardType as empty, applying default value as VISA");
    creditCardType = COMMON.CC_VISA;
  }

  return creditCardType;
};

export const getPaymentMethodDetailsFromCYBSTxnRes = (
  serialNumber,
  sendingMember,
  merchantReferenceId,
  txnResponse = {}
) => {
  let cardHolderName = "Card Holder Name";

  if (txnResponse?.CardHolderName) {
    // removing special characters except space, dot and hyphen as we have form validation for saved card payment option
    cardHolderName = txnResponse?.CardHolderName.replace(
      /[^\\sa-zA-Z.-]/g,
      " "
    ).trim();
  }

  const {
    transactionDetails: {
      id: transactionId = "",
      amountDetails: { amount = "0.00" } = {},
    } = {},
    processingDetails: {
      entryMode = "",
      card: {
        expirationMonth = "",
        expirationYear = "",
        type: cardType = "",
        maskedPan: cardNumber = "",
      } = {},
    } = {},
    additionalInformation: { instrumentId: tokenId = "", requestId = "" } = {},
    receipts: {
      merchantReceipt: {
        receiptData: {
          lines: {
            PAYMENT_DETAILS_EMV_APPLICATION_ID: {
              value: applicationId = "",
            } = {},
            PAYMENT_DETAILS_SOURCE: { value: paymentEntryMode = "" } = {},
            CLEARING_DETAILS_AUTHORIZATION_CODE: {
              value: authorizationCode = "",
            } = {},
          } = {},
        } = {},
      } = {},
    } = {},
  } = txnResponse;

  const paymentMethodDetails = {
    nameOnCard: cardHolderName,
    creditCardType: getCreditCardTypeFromCYBSTxnRes(cardType),
    creditCardNumber: cardNumber.substring(cardNumber.length - 4),
    creditCardExpireMonth: expirationMonth,
    creditCardExpireYear: expirationYear,
    tokenId: tokenId || "",
    authorizationDetails: [
      {
        name: "currency",
        value: "usd",
      },
      {
        name: "authCode",
        value: authorizationCode || "",
      },
      {
        name: "authorizationTransactionId",
        value: requestId || "",
      },
      {
        name: "transactionDetailsId", // adding this transactionDetailsId in authorizationDetails in order to use it while initiating refund from terminal as it is different from requestId
        value: transactionId || "",
      },
      {
        name: "amount",
        value: parseFloat(amount) || 0.0,
      },

      {
        name: "status",
        value: "approved",
      },
      {
        name: "merchantReferenceId",
        value: merchantReferenceId,
      },
      {
        name: "partnerCode",
        value: sendingMember,
      },
      {
        name: "entryMode",
        value: entryMode || "",
      },
      {
        name: "terminalSerialNumber",
        value: serialNumber,
      },
    ],
    receiptsData: {
      transactionId,
      applicationId,
      paymentEntryMode,
      authorizationCode,
    },
  };

  return paymentMethodDetails;
};

export const createTerminalTxnRequest = ({
  transactionType,
  merchantReferenceId,
  amount,
  transactionId,
}) => {
  switch (transactionType) {
    case TransactionTypes.SALE:
      return {
        type: "PaymentRequest",
        merchantReferenceCode: merchantReferenceId,
        amountDetails: {
          amount: formatPrice(amount),
          currency: "USD",
        },
      };

    case TransactionTypes.AUTHORIZATION:
      return {
        type: "PaymentRequest",
        merchantReferenceCode: merchantReferenceId,
        amountDetails: {
          amount: formatPrice(amount),
          currency: "USD",
        },
        capture: false,
      };

    case TransactionTypes.TOKENIZATION:
      return {
        type: "AccountVerificationRequest",
        merchantReferenceCode: merchantReferenceId,
        amountDetails: {
          currency: "USD",
        },
      };

    case TransactionTypes.CANCEL:
      return { type: "CancelRequest" };

    case TransactionTypes.REFUND:
      // Here amountDetails is optional, we can send them in request to initiate partial refund otherwise full amount will be refunded
      return {
        type: "LinkedRefundRequest",
        transactionId: transactionId,
        // amountDetails: {
        //   amount: formatPrice(amount),
        //   currency: "USD",
        // },
      };

    case TransactionTypes.TRANSACTION_LOOKUP:
      return {
        type: "TransactionLookupRequest",
        idType: transactionId ? "TRANSACTION_ID" : "MERCHANT_REFERENCE_CODE",
        id: transactionId || merchantReferenceId,
      };
  }
};

export const handleTerminalTxnResponse = ({
  txnResponse,
  transactionId,
  merchantReferenceId,
  serialNumber,
  sendingMember,
  callback,
  cancelBtnHandler,
}) => {
  const responseType = txnResponse?.type || "";
  const responseMessage = txnResponse?.message || "";

  let terminalResponse = {};

  if (
    responseType === "TransactionStatusResponse" &&
    ["Processing payment", "Processing verification"].some((txnMessage) =>
      responseMessage.includes(txnMessage)
    )
  ) {
    cancelBtnHandler &&
      cancelBtnHandler({ txnStatus: "PAYMENT_TERMINAL_PROCESSING" });
  }

  const status = txnResponse?.processingDetails?.status;
  const isApproved = status === "APPROVED";
  const isAborted = status === "ABORTED";

  if (
    ["PaymentResponse", "AccountVerificationResponse"].includes(responseType)
  ) {
    if (isApproved) {
      const paymentMethodDetails = getPaymentMethodDetailsFromCYBSTxnRes(
        serialNumber,
        sendingMember,
        merchantReferenceId,
        txnResponse
      );
      terminalResponse = { ResponseCode: "00", ResponseText: txnResponse };
      return callback && callback({ terminalResponse, paymentMethodDetails });
    } else {
      terminalResponse = {
        ResponseCode: isAborted ? "200" : "500",
        ResponseText:
          responseMessage ||
          (isAborted ? COMMON.PAYMENT_ABORT : COMMON.PAYMENT_FAIL),
      };
    }
  } else if (responseType === "TransactionLookupResponse") {
    terminalResponse = {
      ResponseCode: isApproved ? "00" : "404",
      ResponseText: isApproved
        ? txnResponse
        : responseMessage ||
          `${COMMON.TXN_NOT_FOUND} ${transactionId || merchantReferenceId}.`,
    };
  } else if (responseType === "LinkedRefundResponse") {
    terminalResponse = {
      ResponseCode: isApproved ? "00" : "500",
      ResponseText: isApproved
        ? txnResponse
        : responseMessage || COMMON.REFUND_FAIL,
    };
  } else if (responseType === "ErrorResponse") {
    terminalResponse = {
      ResponseCode: "500",
      ResponseText: `${responseMessage ? `${responseMessage}. ` : ""}${
        COMMON.CONTACT_SUPPORT
      }`,
    };
  }

  callback && callback({ terminalResponse });
};

export const handleTerminalTxnErrors = async ({
  error,
  sendingMember,
  merchantReferenceId,
  callback,
}) => {
  let terminalResponse = {};
  if (error === "REQUEST_TIME_OUT") {
    await sendCommandToPAXTerminalV1({
      transactionType: TransactionTypes.CANCEL,
      sendingMember,
      callback: ({ terminalResponse }) => {
        console.log("Cancel Txn Response on Timeout :>> ", terminalResponse);

        // when there is a time-out or disconnect b/w. MHQ Desktop app and terminal in middle (with out completion of order creation/modification etc.),
        // we are initiating refund just to make sure customer is not charged.
        initiateRefundThroughTerminal({
          sendingMember,
          merchantReferenceId,
          doLookup: true,
          resolve: ({ message }) => {
            console.log("Initiate Refund Response on Timeout :>> ", message);
          },
        });
      },
    });

    terminalResponse = {
      ResponseCode: "408",
      ResponseText: COMMON.PT_CONNECTION_TIMEOUT,
    };
  } else if (error === "SERVER_ERROR") {
    terminalResponse = {
      ResponseCode: "500",
      ResponseText: COMMON.PT_SERVER_ERROR,
    };
  } else if (error === "SETUP_ERROR") {
    terminalResponse = {
      ResponseCode: "400",
      ResponseText: COMMON.PT_SETUP_MISSING(sendingMember),
    };
  } else {
    terminalResponse = {
      ResponseCode: "1004",
      ResponseText: COMMON.PT_CONNECTION_FAIL,
    };
  }
  callback && callback({ terminalResponse });
};
