import React from "react";
import { View, Platform } from "react-native";
import { Text } from "react-native-elements";

import { fonts, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";

import { CustomModal } from "components/elements";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

export const DeleteCardModal = ({
  modalVisible,
  onConfirm,
  closeModal,
  loading = false,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);

  const isSmallScreen = !isDesktop;

  const Content = () => {
    return (
      <View
        style={{
          maxWidth: isSmallScreen ? "100%" : 600,
          maxHeight: isSmallScreen ? "100%" : 400,
          margin: "auto",
          backgroundColor: backgroundColors.secondary,
          borderColor: "rgba(0, 0, 0, 0.1)",
        }}
      >
        <View
          style={[
            tw("p-3 flex flex-row items-center justify-between"),
            { backgroundColor: backgroundColors.primary },
          ]}
        >
          <Text style={{ ...fonts.heading1, ...tw("text-white") }}>
            {Localise(messages, "Delete Card")}
          </Text>
        </View>
        <View style={isSmallScreen ? {} : tw("mx-20")}>
          <Text style={[tw("p-5"), { fontSize: 14 }]}>
            {Localise(messages, "Do you want to delete this saved card?")}
          </Text>
        </View>
      </View>
    );
  };

  const modalContent = {
    content: <Content />,
    buttons: [
      {
        type: "primary",
        title: Localise(messages, "Yes"),
        loader: loading,
      },
      { type: "secondary", title: Localise(messages, "No") },
    ],
  };

  return (
    <React.Fragment>
      <CustomModal
        modalVisible={modalVisible}
        modalContent={modalContent}
        primaryhandler={() => onConfirm()}
        secondaryhandler={() => closeModal()}
        contentStyle={{ backgroundColor: "white" }}
        modalStyle={
          Platform.OS !== "web"
            ? {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#00000070",
                color: backgroundColors.secondary,
              }
            : { padding: 0 }
        }
        isReduceBtnSpacing={true}
      ></CustomModal>
    </React.Fragment>
  );
};
