import { request } from "library/utils/request";
import AppSettingsUtils from "library/utils/appSettings";

const reloadMemberEntitlements = async ({ shopCode }) => {
  const requestParams = {
    shopCode,
  };
  return await request("reload-member-entitlements", requestParams)
    .then((res) => {
      console.log("Cache cleared for memberCode ", shopCode);
    })
    .catch((err) => {
      console.log("Cache Flush Error Response ", JSON.stringify(err));
    });
};

const refreshPermissionGroups = (params = {}, resolve = () => {}) => {
  AppSettingsUtils.requestPermissionGroups(params).then(
    (permissionsGroups = {}) => {
      let permissions = {};

      Object.keys(permissionsGroups).map((memberCode) => {
        permissions = {
          ...permissions,
          [memberCode]: permissionsGroups[memberCode]?.permissions,
        };
      });

      resolve(permissions);
    }
  );
};

export default async function reloadUserPermissions(options) {
  const {
    refreshEntitlementsCache = true,
    cacheControl = "use",
    shopCodes = [],
    resolve = () => {}, // setPermissions from AppSettingsProvider
  } = options;
  if (refreshEntitlementsCache) {
    Promise.all(
      shopCodes.map((shopCode) => reloadMemberEntitlements({ shopCode }))
    ).then(() => {
      refreshPermissionGroups({ cacheControl }, resolve);
    });
  } else {
    refreshPermissionGroups({ cacheControl }, resolve);
  }
}
