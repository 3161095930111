import React, { useEffect } from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";
import moment from "moment";

import I18NContext from "library/contexts/i18N";
import { fonts } from "styles/theme";

import {
  Form,
  FormField,
  FormFieldPicker,
  SubmitButton,
  FormFieldDatePicker,
} from "components/elements/forms";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { DeviceContext } from "library/contexts/appSettings";

import { adjustmentReasons } from "../helper";
import { getAdjustmentFormValidationSchema } from "../yup";

const AdjustmentForm = ({
  orderDetails,
  selectedAction,
  triggerRequest,
  setSelectedAction,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);

  const {
    recipientInfo: { firstName = "", lastName = "" },
    sendingMember: { memberCode: sendingMemberCode },
    receivingMember: { memberCode: receivingMemberCode },
    deliveryInfo: { deliveryDate: deliveryDateInfo },
    price,
    operator,
    orderItemId,
    erosOrderNumber: rofNumber,
    lineItems = [],
  } = orderDetails;
  const recipientName = firstName + " " + lastName;

  const { accessories = [] } = lineItems[0];
  let orderTotal = price.find((ele) => ele.name === "orderTotal");

  let priceTotal = accessories.reduce(
    (total, acc) => total + acc.accessoryPrice,
    orderTotal.value
  );

  const defaultValues = {
    comments: "",
    deliveryDate: deliveryDateInfo,
    adjustmentReason: "",
    price: priceTotal,
    combinedReportNumber: moment(deliveryDateInfo).format("MM") + "-1",
    adjustedPrice: "",
    recipientName: recipientName,
    rofNumber: rofNumber.split("-")[0],
    receivingMemberCode: receivingMemberCode,
    sendingMemberCode: sendingMemberCode,
  };

  const validationSchema = getAdjustmentFormValidationSchema(
    Localise,
    messages
  );

  const [onChangeValidation, setOnChangeValidation] = useStateIfMounted(false);

  const formSubmitTrigger = (values) => {
    const orderReq = {
      recordId: orderItemId,
      requestType: "adjustment",
      operator,
      ...values,
    };

    triggerRequest({ requestType: selectedAction, orderReq });

    /**
     * When user submitted cancel form, we are setting selected action as empty in order to prevent showing cancel form in sidecar
     * before getting response from service
     * */
    setSelectedAction("");
  };

  const { isDesktop, isMobile } = React.useContext(DeviceContext);

  return (
    <View>
      <View style={tw("mb-3")}>
        <Text style={fonts.heading3}>{Localise(messages, "Adjustment")}</Text>
      </View>

      <Form
        initialValues={defaultValues}
        validationSchema={validationSchema}
        validateOnChange={onChangeValidation}
        validateOnBlur={onChangeValidation}
        onSubmit={formSubmitTrigger}
        render={({ submitCount }) => {
          useEffect(() => {
            if (submitCount > 0) {
              setOnChangeValidation(true);
            }
          }, [submitCount]);
          return (
            <>
              <View style={tw("m-1")}>
                <View
                  style={[tw("flex flex-row flex-wrap justify-between my-1")]}
                >
                  <View
                    style={[
                      tw("flex flex-row items-center"),
                      isMobile && { marginBottom: 20 },
                    ]}
                  >
                    <Text style={{ ...fonts.heading5, width: 100 }}>
                      {Localise(messages, "Sending Florist")}
                    </Text>
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name="sendingMemberCode"
                      editable={false}
                      containerStyle={
                        isDesktop
                          ? { flex: 1 }
                          : { width: isMobile ? "70%" : 300, height: 40 }
                      }
                    />
                  </View>
                  <View style={[tw("flex flex-row items-center")]}>
                    <Text style={{ ...fonts.heading5, width: 100 }}>
                      {Localise(messages, "Filling Florist")}
                    </Text>
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name="receivingMemberCode"
                      editable={false}
                      containerStyle={
                        isDesktop
                          ? { flex: 1 }
                          : { width: isMobile ? "70%" : 300, height: 40 }
                      }
                    />
                  </View>
                </View>
                <View
                  style={[tw("flex flex-row flex-wrap justify-between my-1")]}
                >
                  <View
                    style={[
                      tw("flex flex-row items-center"),
                      isMobile && { marginBottom: 20 },
                    ]}
                  >
                    <View>
                      <Text style={{ ...fonts.heading5, width: 100 }}>
                        {Localise(messages, "Report")} #
                      </Text>
                    </View>

                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name="combinedReportNumber"
                      containerStyle={
                        isDesktop
                          ? { flex: 1 }
                          : { width: isMobile ? "70%" : 300, height: 40 }
                      }
                      placeholder="NN-N"
                    />
                  </View>
                  <View style={[tw("flex flex-row items-center")]}>
                    <Text style={{ ...fonts.heading5, width: 100 }}>
                      {Localise(messages, "Mercury")} #
                    </Text>
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name="rofNumber"
                      containerStyle={
                        isDesktop
                          ? { flex: 1 }
                          : { width: isMobile ? "70%" : 300, height: 40 }
                      }
                      editable={false}
                    />
                  </View>
                </View>
                <View
                  style={[tw("flex flex-row flex-wrap justify-between my-1")]}
                >
                  <View
                    style={[
                      tw("flex flex-row items-center"),
                      isMobile && { marginBottom: 20 },
                    ]}
                  >
                    <Text style={{ ...fonts.heading5, width: 100 }}>
                      {Localise(messages, "Recipient")}
                    </Text>
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name="recipientName"
                      containerStyle={
                        isDesktop
                          ? { flex: 1 }
                          : { width: isMobile ? "70%" : 300, height: 40 }
                      }
                      editable={false}
                    />
                  </View>
                  <View style={[tw("flex flex-row items-center")]}>
                    <Text style={{ ...fonts.heading5, width: 100 }}>
                      {Localise(messages, "Delivery Date")}
                    </Text>
                    <FormFieldDatePicker
                      dateValueFormat="YYYY-MM-DD"
                      name="deliveryDate"
                      placeholder="MM / DD / YYYY"
                      containerStyle={
                        isDesktop
                          ? { flex: 1 }
                          : { width: isMobile ? "70%" : 300, height: 40 }
                      }
                      minDate={moment().subtract(2, "years").toDate()}
                      maxDate={moment().toDate()}
                    />
                  </View>
                </View>
                <View
                  style={[
                    tw("flex flex-row flex-wrap justify-between my-1"),
                    { zIndex: -1 },
                  ]}
                >
                  <View
                    style={[
                      tw("flex flex-row items-center"),
                      isMobile && { marginBottom: 20 },
                    ]}
                  >
                    <Text style={{ ...fonts.heading5, width: 100 }}>
                      {Localise(messages, "Amount billed")}
                    </Text>
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name="price"
                      containerStyle={
                        isDesktop
                          ? { flex: 1 }
                          : { width: isMobile ? "70%" : 300, height: 40 }
                      }
                    />
                  </View>
                  <View
                    style={[
                      tw("flex flex-row items-center"),
                      isMobile && { marginBottom: 20 },
                    ]}
                  >
                    <Text style={{ ...fonts.heading5, width: 100 }}>
                      {Localise(messages, "Correct amount")}
                    </Text>
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name="adjustedPrice"
                      containerStyle={
                        isDesktop
                          ? { flex: 1 }
                          : { width: isMobile ? "70%" : 300, height: 40 }
                      }
                    />
                  </View>
                </View>
                {!isMobile && <View style={{ marginBottom: 10 }} />}
                <View
                  style={[
                    tw("flex flex-row  items-center my-1"),
                    { zIndex: -1 },
                  ]}
                >
                  <Text style={{ ...fonts.heading5, width: 100 }}>
                    {Localise(messages, "Select Reason")}
                  </Text>
                  <FormFieldPicker
                    placeholder={{
                      label: "Select Reason for Adjustment",
                      value: "",
                    }}
                    containerStyle={
                      isDesktop
                        ? { flex: 1 }
                        : { width: isMobile ? "70%" : "89%", height: 40 }
                    }
                    data={adjustmentReasons}
                    name="adjustmentReason"
                    label=""
                    defaultValue={defaultValues.adjustmentReason}
                  />
                </View>
                <View style={{ marginBottom: 10 }} />
                <View
                  style={[
                    tw("flex flex-row items-center my-1"),
                    { zIndex: -1 },
                  ]}
                >
                  <Text style={{ ...fonts.heading5, width: 100 }}>
                    {Localise(messages, "Notes")}
                  </Text>
                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    name="comments"
                    placeholder="Add additional notes if needed"
                    multiline={true}
                    numberOfLines={2}
                    containerStyle={
                      isDesktop
                        ? { flex: 1 }
                        : { width: isMobile ? "70%" : "89%", height: 40 }
                    }
                  />
                </View>
                {!isMobile && <View style={{ marginBottom: 10 }} />}
                <View
                  style={{
                    flexDirection: isMobile ? "column-reverse" : "row",
                    justifyContent: isMobile ? "space-between" : "flex-end",
                    alignItems: "center",
                    zIndex: -1,
                  }}
                >
                  <View style={tw("mx-5")}>
                    <Text
                      style={{ ...fonts.heading4, marginVertical: 8 }}
                      onPress={() => {
                        setSelectedAction("");
                      }}
                    >
                      {Localise(messages, "Nevermind")}
                    </Text>
                  </View>
                  <SubmitButton
                    containerStyle={{ margin: 5 }}
                    title="Confirm"
                    error={""}
                  />
                </View>
              </View>
            </>
          );
        }}
      />
    </View>
  );
};

export default AdjustmentForm;
