import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { View, Text, Platform } from "react-native";
import { Input, Image, Badge } from "react-native-elements";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import isEmpty from "lodash/isEmpty";

import { DeviceContext } from "library/contexts/appSettings";

import IMAGES from "static/assets/images";
import { fonts } from "styles/theme";
import tw from "tailwind-rn";

const Search = ({
  icon,
  selectors: { selectValue },
  actions: { setAction },
  type = "flexible", //fullWidth
  ...props
}) => {
  const fullWidth = type === "fullWidth";
  const { isDesktop } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;

  const dispatch = useDispatch();
  const value = useSelector(selectValue);
  const searchMenuRef = React.useRef();

  const onChange = (e) =>
    dispatch(setAction({ type: "search", value: e.nativeEvent.text }));
  const onClear = () => dispatch(setAction({ type: "search", value: "" }));
  const onClose = () => searchMenuRef?.current?.close();

  return isSmallScreen && !fullWidth ? (
    <Menu ref={searchMenuRef}>
      <MenuTrigger>
        <View style={tw("flex-col justify-around items-center")}>
          <Image
            style={{ width: 20, height: 20 }}
            resizeMode="cover"
            source={IMAGES[icon]}
          />
          {!isEmpty(value) && (
            <Badge
              containerStyle={[
                {
                  right: !isSmallScreen ? -18 : -10,
                  ...tw("mb-4"),
                  position: "absolute",
                },
                Platform.OS !== "web" && { top: -10 },
              ]}
              badgeStyle={{ backgroundColor: "#e5175e" }}
              value={1}
            />
          )}
        </View>
      </MenuTrigger>
      <MenuOptions
        optionsContainerStyle={{
          width: "85%",
        }}
      >
        <MenuOption onSelect={() => false}>
          <SearchBox
            {...props}
            value={value}
            onChange={onChange}
            onClose={onClose}
            onClear={onClear}
          />
        </MenuOption>
      </MenuOptions>
    </Menu>
  ) : (
    <SearchBox
      {...props}
      value={value}
      onChange={onChange}
      onClose={onClose}
      onClear={onClear}
    />
  );
};

const SearchBox = ({
  placeholder,
  value,
  onChange,
  onClose,
  onClear,
  ...props
}) => {
  return (
    <Input
      style={{
        padding: 10,
        fontFamily: fonts.fontFamily.default,
        fontSize: 12,
        height: 35,
        ...props.inputStyle,
      }}
      {...props}
      onChange={onChange}
      onBlur={onClose}
      value={value}
      placeholder={placeholder}
      testID="search"
      containerStyle={{
        height: 35,
        ...props.containerStyle,
      }}
      inputContainerStyle={{
        height: 35,
        alignItems: "center",
        ...props.inputContainerStyle,
      }}
      rightIcon={
        value ? (
          <Text
            style={{
              paddingRight: 5,
              fontWeight: "bold",
            }}
            onPress={() => onClear()}
          >
            X
          </Text>
        ) : undefined
      }
      accessibilityLabel="search"
    />
  );
};

export default Search;
