import * as React from "react";
import {
  Text,
  View,
  StyleSheet,
  Platform,
  TouchableWithoutFeedback,
} from "react-native";
import { Link, useTheme } from "@react-navigation/native";
import Color from "color";
import { colors } from "styles/theme";
import useStateIfMounted from "library/utils/useStateIfMounted";

const Touchable = ({
  children,
  style,
  onPress,
  to,
  accessibilityRole,
  delayPressIn,
  onPressIn,
  onPressOut,
  onMouseEnter,
  onMouseLeave,
  ...rest
}) => {
  if (Platform.OS === "web" && to) {
    // React Native Web doesn't forward `onClick` if we use `TouchableWithoutFeedback`.
    // We need to use `onClick` to be able to prevent default browser handling of links.
    return (
      <Link
        {...rest}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        to={to}
        style={[styles.button, style]}
        onPress={(e) => {
          if (
            !(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) && // ignore clicks with modifier keys
            (e.button == null || e.button === 0) // ignore everything but left clicks
          ) {
            e.preventDefault();
            onPress?.(e);
          }
        }}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <TouchableWithoutFeedback
        {...rest}
        accessibilityRole={accessibilityRole}
        delayPressIn={delayPressIn}
        onPress={onPress}
        onPressIn={onPressIn}
        onPressOut={onPressOut}
      >
        <View style={style}>{children}</View>
      </TouchableWithoutFeedback>
    );
  }
};

/**
 * A component used to show an action item with an icon and a label in a navigation drawer.
 */
export default function DrawerItem(props) {
  const isMountedRef = React.useRef(null);
  const [hovered, setHover] = useStateIfMounted(false);

  const {
    icon,
    label,
    labelStyle,
    to,
    focused = false,
    activeTintColor = colors.primary,
    inactiveTintColor = Color(colors.text).alpha(0.68).rgb().string(),
    activeBackgroundColor = Color(activeTintColor).alpha(0.12).rgb().string(),
    inactiveBackgroundColor = "transparent",
    hoverBackgroundColor = colors.navBarActive,
    style,
    onPress,
    ...rest
  } = props;

  const hasTouchscreen = Platform.OS === "web" && "ontouchstart" in window;
  const { borderRadius = 4 } = StyleSheet.flatten(style || {});
  const color = focused ? activeTintColor : inactiveTintColor;
  const backgroundColor = hovered
    ? hoverBackgroundColor
    : inactiveBackgroundColor;

  const iconNode = icon ? icon({ size: 24, focused, color }) : null;

  React.useEffect(() => {
    isMountedRef.current = true;

    return () => (isMountedRef.current = false);
  }, []);

  const onPressItem = () => {
    hasTouchscreen && isMountedRef.current && setHover(true);
    onPress();
    hasTouchscreen &&
      setTimeout(() => {
        isMountedRef.current && setHover(false);
      }, 300);
  };

  return (
    <View
      collapsable={false}
      {...rest}
      style={[
        styles.container,
        {
          borderRadius,
          backgroundColor,
          borderLeftWidth: focused ? 8 : 0,
          borderLeftColor: colors.navBarActive,
          paddingLeft: 6,
          height: 50,
          justifyContent: "center",
        },
        style,
      ]}
      fsClass="fs-unmask"
    >
      <Touchable
        delayPressIn={0}
        onPress={onPressItem}
        style={[styles.wrapper, { borderRadius, paddingLeft: focused ? 0 : 8 }]}
        accessibilityTraits={focused ? ["button", "selected"] : "button"}
        accessibilityComponentType="button"
        accessibilityRole="button"
        accessibilityStates={focused ? ["selected"] : []}
        to={to}
        onMouseEnter={() => isMountedRef.current && setHover(true)}
        onMouseLeave={() => isMountedRef.current && setHover(false)}
        onPressIn={() => isMountedRef.current && setHover(true)}
        onPressOut={() => isMountedRef.current && setHover(false)}
      >
        <React.Fragment>
          {iconNode}
          <View
            style={[
              styles.label,
              { marginLeft: iconNode ? 32 : 0, marginVertical: 5 },
            ]}
            fsClass="fs-unmask"
          >
            {typeof label === "string" ? (
              <Text
                numberOfLines={1}
                style={[
                  {
                    color,
                    fontWeight: "500",
                  },
                  labelStyle,
                ]}
              >
                {label}
              </Text>
            ) : (
              label({ color, focused })
            )}
          </View>
        </React.Fragment>
      </Touchable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
    marginVertical: 4,
    overflow: "hidden",
  },
  wrapper: {
    flexDirection: "row",
    alignItems: "center",
    padding: 8,
  },
  label: {
    marginRight: 32,
    flex: 1,
  },
  button: {
    display: "flex",
  },
});
