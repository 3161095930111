import React from "react";
import { ActivityIndicator } from "react-native";
import WebView from "react-native-webview";
import SubHeader from "components/containers/header/sub-header";
import { colors } from "styles/theme";

const WebviewWrapper = ({ route }) => {
  const {
    params: { url },
  } = route;
  return (
    <>
      <SubHeader />
      <WebView
        source={{ uri: url }}
        startInLoadingState={true}
        renderLoading={() => (
          <ActivityIndicator
            size="large"
            color={colors.activityIndicator}
            style={{
              flex: 1,
              alignContent: "center",
              justifyContent: "center",
            }}
          />
        )}
      />
    </>
  );
};

export default WebviewWrapper;
