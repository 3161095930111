import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text } from "react-native";

import { Section, Header } from "../helper";
import { getUIConfig } from "../ui-config";

import { Spinner } from "components/elements";

import I18NContext from "library/contexts/i18N";
import { Entitlements } from "library/utils/entitlements";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import { setCurrentGiftCard } from "library/sagas/views/home/drawer/gift-cards/slice";
import { selectExtendedScreenData } from "library/sagas/views/home/drawer/gift-cards/selector";

import get from "lodash/get";
import tw from "tailwind-rn";

const GiftCard = ({ isCreate }) => {
  const dispatch = useDispatch();
  const extendedScreenData = useSelector(selectExtendedScreenData);

  const status = get(extendedScreenData, "status", "");
  const { permissions } = React.useContext(AppSettingsContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const { isTablet } = React.useContext(DeviceContext);

  const allShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.GIFT_CARD in permissions[memberCode])
      allShopCodes.push(memberCode);
  });

  const UIConfig = getUIConfig(Localise, messages);

  const onClose = () => {
    dispatch(setCurrentGiftCard());
  };

  return (
    <View style={[isTablet && { padding: 10 }]}>
      <Header
        title={Localise(messages, "Gift Card Details")}
        onClose={onClose}
      />
      {status === "progress" ? (
        <View style={{ minHeight: 150 }}>
          <Spinner size="large" />
        </View>
      ) : status === "fail" ? (
        <View
          style={[{ minHeight: 150 }, tw("items-center justify-center p-8")]}
        >
          <Text style={{ color: "red" }}>
            {Localise(messages, "Something went wrong, please try again")}
          </Text>
        </View>
      ) : (
        UIConfig.sections.map(
          ({ section, component: { name: MainComponent, props } }, index) => {
            return (
              <Section title={section} key={index}>
                <MainComponent UIConfig={props} isCreate={isCreate} />
              </Section>
            );
          }
        )
      )}
    </View>
  );
};

export default GiftCard;
