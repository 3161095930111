import React from "react";
import toNumber from "lodash/toNumber";
import { View } from "react-native";
import MultiSlider from "./multi-slider";

// Non Colliding Multi Slider
export default function MultiSliderComp({
  rangeValues,
  sliderLength,
  onValuesChange,
  onValuesChangeFinish,
  minValue,
  maxValue,
}) {
  return (
    <View>
      <MultiSlider
        values={[toNumber(rangeValues[0]), toNumber(rangeValues[1])]}
        sliderLength={sliderLength}
        onValuesChange={onValuesChange}
        onValuesChangeFinish={onValuesChangeFinish}
        min={minValue}
        max={maxValue}
        step={1}
        allowOverlap={false}
        snapped
        minMarkerOverlapDistance={10}
      />
    </View>
  );
}
