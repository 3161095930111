import { createStyles, minWidth } from "react-native-media-queries";

export default createStyles(
  {
    menuContainer: {
      maxWidth: 30,
      alignItems: "center",
      justifyContent: "flex-start",
      marginTop: 3,
      paddingBottom: 8,
    },
  },
  minWidth(767, {
    menuContainer: {
      justifyContent: "center",
      marginTop: 0,
    },
  })
);
