import React from "react";
import { View, Image } from "react-native";
import { Divider, Text } from "react-native-elements";

import tw from "tailwind-rn";
import moment from "moment";
import isEmpty from "lodash/isEmpty";

import { backgroundColors, colors } from "styles/theme";

import { Maps } from "components/elements";
import { generateContent } from "components/views/drawer/order-details/helper";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

const DeliveryStatusScreen = ({ trackingDetails }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop, isMobile } = React.useContext(DeviceContext);

  return (
    <View>
      <View style={tw("mb-3")}>
        {trackingDetails?.events.map((eve, index) => {
          const {
            createdOn = "",
            type = "",
            notes = "",
            deviceLatitude = "",
            deviceLongitude = "",
            recipientInfo: {
              name = "",
              addressLine1 = "",
              city = "",
              state = "",
              zip = "",
              latitude = "",
              longitude = "",
            } = {},
            photo = "",
            signature = "",
            deliveredAtDoor = false,
          } = eve;

          const dateObj = createdOn ? moment(createdOn) : "";

          const recipientAddress = {
            addressLine1: addressLine1,
            addressLine2: `${city}, ${state} ${zip}`,
          };

          const data = {
            Status: type,
            Recipient: name,
            "Delivery Location": {
              ...recipientAddress,
              latLongs: `${latitude}, ${longitude}`,
            },
            Date: moment(dateObj).format("MMMM DD, YYYY"),
            Time: moment(dateObj).format("hh:mm A"),
            "Delivery Notes": notes,
            ...(deliveredAtDoor && { "Delivered at Door": "Yes" }),
          };

          return (
            <View key={index}>
              <View>
                <View>
                  <Text
                    style={{
                      fontSize: 18,
                      fontWeight: "600",
                      paddingVertical: 10,
                    }}
                  >
                    {Localise(messages, "Order")} # {trackingDetails.orderId}
                  </Text>
                </View>
                <View
                  style={{
                    width: "60%",
                    paddingTop: 10,
                    paddingBottom: 20,
                  }}
                >
                  {generateContent(data)}
                </View>
                <View>
                  {!!(latitude && longitude) && (
                    <>
                      <Text style={{ fontWeight: "600" }}>
                        {Localise(messages, "Delivery Location")}
                      </Text>
                      <View
                        style={[
                          tw("my-2"),
                          {
                            height: 600,
                            width: !isMobile ? "80%" : "95%",
                            borderColor: backgroundColors.medium,
                            borderWidth: 1,
                            borderStyle: "solid",
                            marginHorizontal: 5,
                            zIndex: -1,
                          },
                        ]}
                      >
                        <Maps
                          mapMarkers={{
                            locations: [
                              {
                                latitude: "",
                                longitude: "",
                              },
                            ],
                            ...{
                              recipientLatLong: {
                                recipientLatitude: parseFloat(latitude),
                                recipientLongitude: parseFloat(longitude),
                              },
                            },
                            ...{
                              driverLatLong: {
                                driverLatitude:
                                  (deviceLatitude &&
                                    parseFloat(deviceLatitude)) ||
                                  "",
                                driverLongitude:
                                  (deviceLongitude &&
                                    parseFloat(deviceLongitude)) ||
                                  "",
                              },
                            },
                          }}
                          showMarkerPopup={true}
                          recipientAddress={recipientAddress}
                          zoomLevel={18}
                          mapHeight={600}
                        />
                      </View>
                    </>
                  )}
                </View>
                <View style={tw(!isMobile ? "flex-row w-full" : "w-full")}>
                  {!isEmpty(photo) && (
                    <View style={{ width: !isMobile ? "50%" : "100%" }}>
                      <Text
                        style={{
                          fontWeight: "600",
                          paddingVertical: 10,
                        }}
                      >
                        {Localise(messages, "Delivery Photo")}
                      </Text>
                      <View>
                        <Image
                          style={{
                            height: 550,
                            width: isDesktop ? 550 : "95%",
                            borderWidth: 0.5,
                            borderColor: colors.light,
                          }}
                          containerStyle={{ margin: "auto" }}
                          source={{
                            uri: `data:image/png;base64,${photo}`,
                          }}
                        />
                      </View>
                    </View>
                  )}
                  {!isEmpty(signature) && (
                    <View style={{ width: !isMobile ? "50%" : "100%" }}>
                      <Text
                        style={{
                          fontWeight: "600",
                          paddingVertical: 10,
                        }}
                      >
                        {Localise(messages, "Delivery Signature")}
                      </Text>
                      <View>
                        <Image
                          style={{
                            height: 550,
                            width: isDesktop ? 550 : "95%",
                            borderWidth: 0.5,
                            borderColor: colors.light,
                          }}
                          containerStyle={{ margin: "auto" }}
                          source={{
                            uri: `data:image/png;base64,${signature}`,
                          }}
                        />
                      </View>
                    </View>
                  )}
                </View>
              </View>
              <Divider style={{ marginVertical: 20 }} />
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default DeliveryStatusScreen;
