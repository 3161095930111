import React from "react";
import { View } from "react-native";
import { Text, Button } from "react-native-elements";
import moment from "moment";

import { fonts, theme } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import { ToasterHandler, PrintIframeRenderer } from "components/elements";
import Environment from "library/utils/environment";
import { request } from "library/utils/request";
import { Platform, PermissionsAndroid } from "react-native";
import FileViewer from "react-native-file-viewer";
import { useSelector } from "react-redux";
import { selectCustomerDetails } from "library/sagas/views/home/drawer/customer-directory/selector";

let RNFS;
if (Platform.OS !== "web") {
  RNFS = require("react-native-fs");
}
const getFileName = (data) => {
  return `${data?.fileName}.${data?.fileType}`;
};
export const fetchStatement = (
  requestURL,
  requestParams,
  Localise,
  messages
) => {
  request(requestURL, requestParams)
    .then((resp) => {
      const res = resp?.data;
      const fileName = getFileName(resp);

      if (Platform.OS === "web") {
        // Creating blob link to download
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        // Force download
        link.click();
      } else {
        var reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onloadend = function () {
          var base64String = reader.result?.split(",")[1] || "";
          if (Platform.OS === "ios") {
            saveReportFileOnDevice(
              base64String,
              fileName,
              messages,
              Localise,
              "base64"
            );
          } else if (Platform.OS === "android") {
            checkPermissionsAndDownloadReport(
              base64String,
              fileName,
              messages,
              Localise,
              "base64"
            );
          }
        };
      }
    })
    .catch(() => {
      ToasterHandler(
        "error",
        Localise(messages, "House Account statements are not available")
      );
    });
};

const checkPermissionsAndDownloadReport = async (
  res,
  fileName,
  messages,
  Localise,
  encoding
) => {
  try {
    const granted = await PermissionsAndroid.check(
      PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
    );

    // Asking for storage permissions on Android 13 is no longer needed
    if (granted || Platform.Version >= 33) {
      saveReportFileOnDevice(res, fileName, messages, Localise, encoding);
    } else {
      const result = await PermissionsAndroid.requestMultiple(
        [
          PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
          PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
        ],
        {
          title: Localise(messages, ""),
          message: Localise(
            messages,
            "Please approve Mercury HQ access to download"
          ),
        }
      );

      if (
        result["android.permission.READ_EXTERNAL_STORAGE"] &&
        result["android.permission.WRITE_EXTERNAL_STORAGE"] ===
          PermissionsAndroid.RESULTS.GRANTED
      ) {
        saveReportFileOnDevice(res, fileName, messages, Localise, encoding);
      } else {
        ToasterHandler(
          "error",
          Localise(messages, "Please approve Mercury HQ access to download")
        );
      }
    }
  } catch (err) {
    ToasterHandler(
      "error",
      Localise(messages, "Download failed, please try again")
    );
  }
};

const saveReportFileOnDevice = (
  data,
  fileName,
  messages,
  Localise,
  encoding = "base64"
) => {
  var directoryType =
    Platform.OS === "android"
      ? RNFS.DownloadDirectoryPath
      : RNFS.DocumentDirectoryPath;

  var reportsDirPath = `${directoryType}/Mercury HQ Reports`;

  RNFS.mkdir(reportsDirPath)
    .then(() => {
      var filePath = `${reportsDirPath}/${fileName}`;

      RNFS.writeFile(filePath, data, encoding)
        .then(async () => {
          console.log("Report generated and saved at " + filePath);

          FileViewer.open(filePath)
            .then(() => {
              console.log(
                "Generated report opened using selected application.."
              );
            })
            .catch((error) => {
              console.log("Unable to open generated report " + error.message);

              ToasterHandler(
                "success",
                Localise(messages, "Download complete")
              );
            });
        })
        .catch(() => {
          ToasterHandler(
            "error",
            Localise(messages, "Download failed, please try again")
          );
        });
    })
    .catch(() => {
      ToasterHandler(
        "error",
        Localise(messages, "Download failed, please try again")
      );
    });
};
const Statement = () => {
  const customerDetails = useSelector(selectCustomerDetails);
  const { houseAccountInfo = {} } = customerDetails;

  const { messages, Localise } = React.useContext(I18NContext);
  const currMonth = moment().add("month");
  const currYear = moment().year();
  const formattedcurrMonth = currMonth.format("MMMM");

  const createdOn = moment(
    houseAccountInfo?.createdOn,
    "YYYY-MM-DDTHH:mm:ss"
  ).format("MMMM YYYY");

  const previousMonthDate = moment().subtract(1, "month");
  const month =
    Environment.get("STATEMENT_MONTH", "") || previousMonthDate.format("MMMM");
  const year =
    Environment.get("STATEMENT_YEAR", "") || previousMonthDate.format("YYYY");

  let requestParams;
  requestParams = {
    month: month,
    year: year,
    houseAccountId: houseAccountInfo?.houseAccountId,
  };
  return (
    <View style={{ flex: 1 }}>
      {createdOn === `${formattedcurrMonth} ${currYear}` ? (
        <Text style={fonts.heading5}>
          {Localise(messages, "No Statements available")}
        </Text>
      ) : (
        <>
          <Text style={fonts.heading3}>
            {month}/{year}
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              marginVertical: 10,
            }}
          >
            <Button
              testID={"Download_Statement"}
              accessibilityLabel="Download_Statement"
              titleStyle={theme.Button.secondaryTitleStyle}
              buttonStyle={{
                ...theme.Button.secondaryButtonStyle,
                paddingVertical: 5,
                paddingHorizontal: 5,
              }}
              containerStyle={{
                marginVertical: 0,
                marginHorizontal: 0,
                justifyContent: "center",
                width: 200,
              }}
              title={Localise(messages, "Download Statement")}
              onPress={() =>
                fetchStatement(
                  "house-account-statements",
                  requestParams,
                  Localise,
                  messages
                )
              }
            />
            <PrintIframeRenderer testID="house-account-statements" />
          </View>
        </>
      )}
    </View>
  );
};

export default Statement;
