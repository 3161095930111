import React, { useEffect } from "react";
import { ActivityIndicator, View, TouchableOpacity } from "react-native";
import { Text, Image, Icon } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "components/elements";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useNavigation, CommonActions } from "@react-navigation/native";
import UserProfileContext from "library/contexts/userProfile";
import UserProfileStorage from "library/storage/userProfile";
import PageStorage from "library/storage/pageStorage";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import SortComponent from "./sort";
import SearchComponent from "./search";
import { FilterComponent, SelectedFilters } from "./filter";
import {
  setSearchFieldText,
  setCurrentPage,
} from "library/sagas/ongoing/global-data/slice";
import {
  setRecordData,
  setRecordId,
  setDeliveryMethod,
  setSourceMemberCode,
  setDesignersAreaEnabled,
} from "library/sagas/ongoing/current-orders/slice";
import {
  selectIsDesignersAreaEnabled,
  selectedActions,
} from "library/sagas/ongoing/current-orders/selector";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import tw from "tailwind-rn";
import { fonts, colors } from "styles/theme";
import IMAGES from "static/assets/images";
import moment from "moment";
import I18NContext from "library/contexts/i18N";
import { Breadcrumb } from "../header/bread-crumb";
import { hasDesignCenterFeature } from "library/utils/featureAvailability";

//eslint-disable-next-line
const OpenDesignersArea = React.memo(({ isDesktop }) => {
  const dispatch = useDispatch();
  const showDesignersSection = useSelector(selectIsDesignersAreaEnabled);
  const actions = useSelector(selectedActions);
  const { messages, Localise } = React.useContext(I18NContext);

  return (
    <Tooltip text={Localise(messages, "Design Center")} renderForWebOnly={true}>
      <TouchableOpacity
        onPress={() => {
          // Saving the Design Center related actions in session storage in order to retain them
          if (showDesignersSection) {
            const storageValues = PageStorage.getOrdersPageActions();
            PageStorage.setOrdersPageData({
              ...storageValues,
              isDesignCenterEnabled: false,
              actions: {},
              designCenterSelections: {},
              viewMoreCounts: {},
            });
          } else {
            PageStorage.setOrdersPageActions("isDesignCenterEnabled", true);
            PageStorage.setOrdersPageActions("actions", actions);
          }
          dispatch(setDesignersAreaEnabled(!showDesignersSection));
          dispatch(setRecordData({}));
          dispatch(setRecordId());
          dispatch(setDeliveryMethod());
          dispatch(setSourceMemberCode());
        }}
        key={`OpenDesignersArea`}
        testID="open_designers_area"
        accessibilityLabel="open_designers_area"
      >
        <Image
          style={{
            width: isDesktop ? 35 : 18,
            height: isDesktop ? 35 : 18,
            marginLeft: isDesktop ? 20 : 0,
          }}
          resizeMode="cover"
          source={IMAGES[showDesignersSection ? "assigned" : "unassigned"]}
        />
      </TouchableOpacity>
    </Tooltip>
  );
});

//eslint-disable-next-line
const SearchSortFilters = React.memo(
  ({
    loading,
    actions,
    setActions,
    sortOptions,
    filterOptions,
    params,
    title,
    placeholder,
    route,
    actionName,
    showPriceRange,
    showaction,
    currentPage,
    setCurrentPage,
    setSearchFieldText,
    setRecordId,
    enableShopSelection = true,
    enableSearch = true,
    enableFilters = true,
    enableSort = true,
    memberCodes = [],
    showLoading = true,
    showDivider = true,
    showDateType = true,
    customFiltersWidth = false,
    showTitle = true,
    showFiltersTitle = true,
    showBreadCrumb = false,
    range = 21,
    sortDefaultValue,
    customContainerStyles = {},
  }) => {
    const { suspendedMembers = [] } = React.useContext(UserProfileContext);
    const { permissions } = React.useContext(AppSettingsContext);
    const shopNames = UserProfileStorage.getAllShopNames();
    const isEnhancedDesignerEnabled = memberCodes.some((eachShop) =>
      hasDesignCenterFeature(permissions, eachShop)
    );

    const { isDesktop, isTablet, isMobile } = React.useContext(DeviceContext);
    const isSmallScreen = !isDesktop;
    const {
      appliedFilters,
      filters,
      search,
      selectedShops,
      shops,
      sort,
      orderIds,
    } = actions;

    const { messages, Localise } = React.useContext(I18NContext);

    useEffect(() => {
      const {
        filters: selectedFilters = [],
        shops: selectedShops = [],
        search = {},
        orderIds = "",
      } = params;

      // Storing the selected filters to redux when Dashboard widget is clicked
      if (
        !isEmpty(selectedFilters) ||
        !isEmpty(selectedShops) ||
        search?.title?.length ||
        orderIds?.length
      ) {
        const currentFilters = cloneDeep(filters);
        const currentShops = cloneDeep(shops);
        selectedFilters?.map((filter) => {
          const { section, title, value } = filter;
          currentFilters.push({ [section]: { title, value } });

          // Logic for adding the date filter to filterOptions when user clicked on the undelivered orders (truck) icon in the dashboard calender.
          if (section === "Date Range") {
            Object.keys(filterOptions).map((key) => {
              if (key === "Date Range") {
                const filterIndex = filterOptions[key].findIndex((date) => {
                  const isValidDate = moment(
                    date.value,
                    "YYYY-MM-DD",
                    true
                  ).isValid();
                  return isValidDate;
                });
                filterIndex > 0 && filterOptions[key].splice(filterIndex, 1);
                moment(value, "YYYY-MM-DD", true).isValid() &&
                  filterOptions[key].push({ title, value });
              }
              return filterOptions[key];
            });
          }
        });
        selectedShops?.map((shop) => {
          const { section, title, value } = shop;
          currentShops.push({ [section]: { title, value } });
        });
        const finalFilters = uniqWith(currentFilters, isEqual);
        const finalShops = uniqWith(currentShops, isEqual);
        setActions({
          filters: finalFilters,
          shops: finalShops,
          appliedFilters: finalFilters,
          selectedShops: finalShops,
          search: search,
          orderIds,
        });
      }
    }, [params]);

    // Filters Logic
    const unsetFilter = React.useCallback(
      (index, value = "", clearOnApplied = false) => {
        const currentFilters = cloneDeep(
          clearOnApplied ? appliedFilters : filters
        );

        // Logic for removing the date filter to filterOptions.
        if (moment(value, "YYYY-MM-DD", true).isValid()) {
          currentFilters.map((filter) => {
            if (filter["Date Range"]) {
              Object.keys(filterOptions).map((key) => {
                if (key === "Date Range") {
                  const filterIndex = filterOptions[key].findIndex((date) => {
                    const isValidDate = moment(
                      date.value,
                      "YYYY-MM-DD",
                      true
                    ).isValid();
                    return isValidDate;
                  });
                  filterIndex > 0 && filterOptions[key].splice(filterIndex, 1);
                }
              });
            }
          });
        }

        if (index < 0 || index >= currentFilters.length) return;
        currentFilters.splice(index, 1);
        const finalFilters = uniqWith(currentFilters, isEqual);

        clearOnApplied
          ? setActions({
              appliedFilters: finalFilters,
              filters: finalFilters,
            })
          : setActions({
              filters: finalFilters,
            });
      }
    );

    const setFilter = React.useCallback((section, title, value, index) => {
      let currentFilters = cloneDeep(filters);
      if (section === "Date Range") {
        if (title === "Custom Range" || title === "This Month") {
          currentFilters = currentFilters.filter((obj) => !obj["Date Range"]);
        } else {
          const customRange = "Custom Range";
          const customRangeIndex = get(
            selectedFiltersMap,
            `${section}.${customRange}`
          );

          if (customRangeIndex >= 0) currentFilters.splice(customRangeIndex, 1);

          const thisMonthTitle = "This Month";
          const thisMonthIndex = get(
            selectedFiltersMap,
            `${section}.${thisMonthTitle}`
          );

          if (thisMonthIndex >= 0) currentFilters.splice(thisMonthIndex, 1);
        }
      }
      // if index, then replace else add
      if (index >= 0) {
        currentFilters[index] = { [section]: { title, value } };
      } else currentFilters.push({ [section]: { title, value } });

      setActions({
        filters: uniqWith(currentFilters, isEqual),
      });
    });

    const setAppliedFilters = () => {
      setActions({
        appliedFilters: filters,
      });
    };

    const resetFilters = () => {
      setActions({ filters: appliedFilters });
    };

    const selectedFiltersMap = {};
    filters &&
      filters.map((e, index) => {
        const [type, value] = Object.entries(e).pop();
        selectedFiltersMap[type] = {
          ...selectedFiltersMap[type],
          [value.title]: index,
        };
      });

    // Search Logic
    const unsetSearch = React.useCallback(() => {
      setActions({
        search: {},
      });
      setSearchFieldText({ [currentPage]: "" });
    });

    const setSearch = React.useCallback((title, value) => {
      setActions({
        search: { title, value },
      });
    });

    // Sort Logic
    const unsetSort = React.useCallback(() => {
      setActions({
        sort: {},
      });
    });

    // Orders Logic
    const unsetOrders = React.useCallback((index) => {
      const currentOrders = orderIds.split(",");
      currentOrders.splice(index, 1);
      setActions({
        orderIds: currentOrders.join(),
      });
    });

    const setSort = React.useCallback((title, value) => {
      setActions({
        sort: { title, value },
      });
    });

    // Shops Logic
    const unsetShops = React.useCallback((index, clearOnSelected = false) => {
      const currentShops = cloneDeep(clearOnSelected ? selectedShops : shops);
      if (index < 0 || index >= shops.length) return;
      currentShops.splice(index, 1);
      const finalShops = uniqWith(currentShops, isEqual);
      clearOnSelected
        ? setActions({
            selectedShops: finalShops,
            shops: finalShops,
          })
        : setActions({
            shops: finalShops,
          });
    });

    const setShops = React.useCallback((section, title, value, index) => {
      const currentShops = cloneDeep(shops);
      // if index, then replace else add
      if (index >= 0) {
        currentShops[index] = { [section]: { title, value } };
      } else currentShops.push({ [section]: { title, value } });
      setActions({
        shops: uniqWith(currentShops, isEqual),
      });
    });

    const setSelectedShops = () => {
      setActions({
        selectedShops: shops,
      });
    };

    const resetShops = () => {
      setActions({ shops: selectedShops });
    };

    const selectedShopsMap = {};
    shops &&
      shops.map((e, index) => {
        const [type, value] = Object.entries(e).pop();
        selectedShopsMap[type] = {
          ...selectedShopsMap[type],
          [value.value]: index,
        };
      });

    const clearAllFilters = React.useCallback((title) => {
      if (title === "shops") {
        setActions({ filters, selectedShops: [], shops: [], search });
      } else if (title === "filters") {
        setActions({ filters: [], appliedFilters: [] });
      } else if (title === "orders") {
        setActions({ orderIds: "" });
      }
    });

    const isTitleOrders = title === "Orders";

    return React.useMemo(() => {
      return (
        <View
          style={tw(
            `flex flex-col bg-white ${isSmallScreen ? "pt-5" : "pt-4"}`
          )}
        >
          <View
            style={[
              tw(
                `flex-row ${
                  showFiltersTitle || isSmallScreen ? "justify-between" : ""
                } items-center flex-initial `
              ),
              customContainerStyles,
            ]}
          >
            <View style={tw("flex flex-row justify-between")}>
              {showTitle && (
                <View>
                  <Text
                    style={{
                      ...fonts.heading1,
                      ...(isTablet && { fontSize: 16, fontWeight: "400" }),
                      ...(showBreadCrumb && { ...fonts.heading3 }),
                    }}
                  >
                    {Localise(messages, title)}
                  </Text>
                  {showBreadCrumb && <Breadcrumb />}
                </View>
              )}
              {loading && showLoading && (
                <ActivityIndicator
                  style={{ marginLeft: 10 }}
                  color={colors.activityIndicator}
                  testID={"loader"}
                />
              )}
            </View>
            {isSmallScreen ? null : (
              <View
                style={{
                  ...(showFiltersTitle
                    ? {
                        display: "flex",
                        flex: 1,
                        ...(showTitle
                          ? {
                              alignItems: "center",
                            }
                          : { alignItems: "flex-start", marginLeft: 10 }),
                      }
                    : { width: 390, marginLeft: 10 }),
                }}
              >
                <SearchComponent
                  setSearch={setSearch}
                  unsetSearch={unsetSearch}
                  selectedFiltersMap={selectedFiltersMap}
                  placeholder={placeholder}
                  isSmallScreen={isSmallScreen}
                  search={search}
                  enableSearch={enableSearch}
                  showFiltersTitle={showFiltersTitle}
                />
              </View>
            )}
            <View
              style={[
                tw(
                  `flex-row ${
                    showFiltersTitle || isSmallScreen ? "justify-between" : ""
                  } flex-initial`
                ),
                {
                  width: isSmallScreen
                    ? memberCodes.length > 1
                      ? 100
                      : 75
                    : customFiltersWidth
                    ? customFiltersWidth
                    : memberCodes.length > 1
                    ? showaction
                      ? 380
                      : 225
                    : showaction
                    ? enableFilters && enableSort
                      ? 300
                      : enableFilters || enableSort
                      ? 200
                      : 100
                    : enableFilters && enableSort
                    ? 200
                    : enableFilters || enableSort
                    ? 100
                    : 0,
                },
              ]}
            >
              {isSmallScreen
                ? null
                : showaction && (
                    <CreateEvent
                      route={route}
                      actionName={actionName}
                      setRecordId={setRecordId}
                      setCurrentPage={setCurrentPage}
                    />
                  )}
              {enableShopSelection && memberCodes.length > 1 && (
                <FilterComponent
                  filterOptions={{
                    "Shop Codes": memberCodes.map((shopCode) => ({
                      title:
                        suspendedMembers.length > 0 &&
                        suspendedMembers.includes(shopCode)
                          ? `⚠️  ${shopCode} ${shopNames[shopCode]}`
                          : `${shopCode} ${shopNames[shopCode]}`,
                      value: shopCode,
                    })),
                  }}
                  onApply={setSelectedShops}
                  onCancel={resetShops}
                  selectedFiltersMap={selectedShopsMap}
                  setFilter={setShops}
                  unsetFilter={unsetShops}
                  clearAllFilters={clearAllFilters}
                  filters={shops}
                  title={Localise(messages, "Shops")}
                  filterIcon={
                    <Tooltip
                      text={Localise(messages, "Select Shop")}
                      renderForWebOnly={true}
                    >
                      <Icon
                        name="home"
                        type="font-awesome"
                        size={
                          isSmallScreen
                            ? 20
                            : showFiltersTitle
                            ? 13
                            : isTitleOrders
                            ? 35
                            : 22
                        }
                        iconStyle={{
                          paddingRight: 3,
                          color: colors.highlighter,
                        }}
                      />
                    </Tooltip>
                  }
                  menuOptionWidth={!isDesktop ? "100%" : 350}
                  showFiltersTitle={showFiltersTitle}
                />
              )}
              {enableFilters && filterOptions && (
                <FilterComponent
                  filterOptions={filterOptions}
                  selectedFiltersMap={selectedFiltersMap}
                  setFilter={setFilter}
                  onApply={setAppliedFilters}
                  onCancel={resetFilters}
                  unsetFilter={unsetFilter}
                  showPriceRange={showPriceRange}
                  clearAllFilters={clearAllFilters}
                  filters={filters}
                  title={"Filters"}
                  filterIcon={
                    <Tooltip
                      text={Localise(messages, "Filter")}
                      renderForWebOnly={true}
                    >
                      <Image
                        style={{
                          width:
                            isSmallScreen || showFiltersTitle
                              ? 18
                              : isTitleOrders
                              ? 35
                              : 22,
                          height:
                            isSmallScreen || showFiltersTitle
                              ? 18
                              : isTitleOrders
                              ? 35
                              : 22,
                        }}
                        resizeMode="cover"
                        source={IMAGES["filter"]}
                      />
                    </Tooltip>
                  }
                  menuOptionWidth={isDesktop ? 300 : 500}
                  showDateType={showDateType}
                  showFiltersTitle={showFiltersTitle}
                  range={range}
                  showDesignerFilters={isEnhancedDesignerEnabled}
                />
              )}
              {enableSort && (
                <SortComponent
                  sortOptions={sortOptions}
                  sort={sort}
                  setSort={setSort}
                  isSmallScreen={isSmallScreen}
                  showFiltersTitle={showFiltersTitle}
                  defaultValue={sortDefaultValue}
                  isTitleOrders={isTitleOrders}
                />
              )}
              {isTitleOrders && isEnhancedDesignerEnabled && (
                <OpenDesignersArea isDesktop={isDesktop} />
              )}
              {isSmallScreen ? (
                <SearchComponent
                  setSearch={setSearch}
                  unsetSearch={unsetSearch}
                  selectedFiltersMap={selectedFiltersMap}
                  placeholder={placeholder}
                  isSmallScreen={isSmallScreen}
                  search={search}
                  enableSearch={enableSearch}
                />
              ) : null}
            </View>
          </View>
          <View
            style={tw(
              `flex-row items-center justify-end ${isSmallScreen ? "mt-4" : ""}`
            )}
          >
            {isSmallScreen
              ? showaction && (
                  <CreateEvent
                    route={route}
                    actionName={actionName}
                    setRecordId={setRecordId}
                    setCurrentPage={setCurrentPage}
                  />
                )
              : null}
          </View>
          {!isMobile && (
            <View
              style={
                showDivider
                  ? {
                      borderBottomColor: colors.light,
                      borderBottomWidth: 1,
                      marginVertical: 10,
                      shadowOffset: { width: 0, height: 2 },
                      shadowOpacity: 0.4,
                      shadowRadius: 7,
                    }
                  : {
                      marginBottom: 5,
                    }
              }
            />
          )}
          <SelectedFilters
            filters={appliedFilters}
            unsetFilter={unsetFilter}
            search={search}
            unsetSearch={unsetSearch}
            shops={selectedShops}
            unsetShops={unsetShops}
            sort={sort}
            unsetSort={unsetSort}
            orderIds={orderIds}
            unsetOrders={unsetOrders}
            isSmallScreen={isSmallScreen}
            clearAllFilters={clearAllFilters}
          />
        </View>
      );
    }, [
      loading,
      filters,
      shops,
      sort,
      search,
      showaction,
      appliedFilters,
      filterOptions,
    ]);
  }
);

const CreateEvent = ({ route, actionName, setRecordId, setCurrentPage }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const navigation = useNavigation();
  const { isDesktop } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  if (
    isSmallScreen &&
    ![
      "create-staff-profile",
      "ftd-create-user",
      "create-delivery-service-request",
    ].includes(route)
  )
    return null;

  return (
    <TouchableOpacity
      style={tw("flex-row justify-around items-center")}
      onPress={() => {
        if (["create-staff-profile", "ftd-create-user"].includes(route)) {
          // showing create staff profile in side car
          setRecordId("createStaffProfile");
        } else if (
          isSmallScreen ||
          ["create-delivery-service-request"].includes(route)
        ) {
          setCurrentPage(route);
          UserProfileStorage.setCurrentTab("");
          navigation.dispatch(
            CommonActions.navigate({
              name: route,
            })
          );
        }
      }}
      testID="create_order"
      accessibilityLabel="create_order"
    >
      <Image
        style={{
          width: 15,
          height: 15,
        }}
        source={IMAGES["create-order"]}
      />
      <Text style={{ paddingLeft: 2 }}>{Localise(messages, actionName)} </Text>
    </TouchableOpacity>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    currentPage: state.mhq.ongoing.global.currentPage,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setSearchFieldText, setCurrentPage }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchSortFilters);
