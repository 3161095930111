import * as Yup from "yup";
import { orderStatusSpecificTokenMenu } from "library/utils/notificationTokens";

// To apply validation for invalid tokens
Yup.addMethod(
  Yup.string,
  "emailSubjTokenRules",
  function (errorMessage, currentNotificationTab) {
    return this.test(
      `email-subj-token-rules`,
      errorMessage,
      function (value = "") {
        const tokensList =
          orderStatusSpecificTokenMenu[0]?.[currentNotificationTab]
            ?.emailSubjectTokenMenu;

        let emailSubjTokens = [];
        tokensList.forEach((eachToken) => {
          const token = eachToken?.label?.toLowerCase()?.replace(/ /g, "_");
          emailSubjTokens.push(token);
        });

        const tokens = value
          ?.toLowerCase()
          ?.replace(/ /g, "_")
          ?.match(/\[([^[\]]+)]/g);

        let isInvertedBracesPresent = [];

        for (let char of value) {
          if (char === "[") {
            // If an opening bracket is found, push it onto the isInvertedBracesPresent
            isInvertedBracesPresent.push(char);
          } else if (char === "]") {
            // If a closing bracket is found, check if there is a matching opening bracket
            if (isInvertedBracesPresent.length === 0) {
              return false; // Unmatched closing bracket
            }
            isInvertedBracesPresent.pop(); // Matching opening bracket found
          }
        }

        const openSquareBracketsCount = value?.match(/\[/g)?.length;
        const closeSquareBracketsCount = value?.match(/\]/g)?.length;

        const isTokenValid =
          tokens?.length > 0
            ? tokens?.every((value) => emailSubjTokens.includes(value))
            : true;
        if (
          isTokenValid &&
          openSquareBracketsCount === closeSquareBracketsCount
        ) {
          return true;
        } else {
          return false;
        }
      }
    );
  }
);

export const emailSettingsSchema = (
  messages,
  Localise,
  currentNotificationTab
) => {
  return Yup.object({
    emailSubject: Yup.string().emailSubjTokenRules(
      Localise(messages, "Invalid Token Format"),
      currentNotificationTab
    ),
  });
};
