import React, { useEffect } from "react";
import { View, Platform } from "react-native";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";
import moment from "moment";
import {
  Form,
  FormFieldDatePicker,
  FormFieldPicker,
} from "components/elements/forms";
import styles from "./styles";
import I18NContext from "library/contexts/i18N";

const DateRangeFilter = ({
  onSelect,
  checked,
  startDate,
  endDate,
  feedType = "",
  hideType = false,
  minDate = moment().subtract(30, "days"),
  maxDate = moment(),
  popperPlacement = "bottom-end",
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const feedTypesList = [
    { label: "Customer", value: "CUSTOMER_PROFILE" },
    { label: "Email", value: "TRANSACTIONAL_EMAIL" },
    { label: "Order", value: "ORDER_STATUS" },
    { label: "SMS", value: "TRANSACTIONAL_SMS" },
  ];

  return (
    <View style={[styles.recordContainer]}>
      <View style={[styles.record]}>
        <View>
          <View style={tw("py-2")}>
            <Form
              initialValues={{
                startDate,
                endDate,
                feedType,
              }}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
              render={({
                initialValues,
                values,
                setFieldValue,
                isSubmitting,
              }) => {
                let { startDate, endDate, feedType } = values;

                // eslint-disable-next-line react-hooks/rules-of-hooks
                useEffect(() => {
                  if (!isSubmitting && initialValues.startDate !== startDate) {
                    const isOutOfRange = moment(endDate).isBefore(startDate);
                    isOutOfRange && setFieldValue("endDate", startDate);

                    onSelect({
                      startDate,
                      endDate: isOutOfRange ? startDate : endDate,
                      feedType,
                    });
                  }
                }, [startDate]);

                // eslint-disable-next-line react-hooks/rules-of-hooks
                useEffect(() => {
                  if (!isSubmitting && initialValues.endDate !== endDate) {
                    const isOutOfRange = moment(startDate).isAfter(endDate);
                    isOutOfRange && setFieldValue("startDate", endDate);
                    onSelect({
                      startDate: isOutOfRange ? endDate : startDate,
                      endDate,
                      feedType,
                    });
                  }
                }, [endDate]);

                // eslint-disable-next-line react-hooks/rules-of-hooks
                useEffect(() => {
                  if (!isSubmitting && initialValues.feedType !== feedType) {
                    onSelect({
                      startDate,
                      endDate,
                      feedType: feedType,
                    });
                  }
                }, [feedType]);

                return (
                  <View>
                    {checked && (
                      <>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            zIndex: 20,
                            marginBottom: 10,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: 10,
                            }}
                          >
                            <Text>{Localise(messages, "Start Date")}</Text>
                          </View>
                          <View
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              alignItems: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <FormFieldDatePicker
                              dateFormat={
                                Platform.OS === "web"
                                  ? "MM/dd/yyyy"
                                  : "MM/DD/YYYY"
                              }
                              dateValueFormat="YYYY-MM-DD"
                              name="startDate"
                              placeholder={Localise(messages, "Start Date")}
                              containerStyle={{
                                ...{
                                  zIndex: 30,
                                  height: Platform.OS !== "web" ? 40 : "auto",
                                  marginTop: 0,
                                  flex: 1,
                                },
                              }}
                              alignIconRight={true}
                              iconName="calendar"
                              iconType="font-awesome"
                              minDate={minDate.toDate()}
                              maxDate={maxDate.toDate()}
                              overrideValue={startDate}
                              popperPlacement={popperPlacement}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            zIndex: Platform.OS === "web" ? 10 : 20,
                            marginBottom: 10,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: 10,
                            }}
                          >
                            <Text>{Localise(messages, "End Date")}</Text>
                          </View>
                          <View
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              alignItems: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <FormFieldDatePicker
                              dateFormat={
                                Platform.OS === "web"
                                  ? "MM/dd/yyyy"
                                  : "MM/DD/YYYY"
                              }
                              dateValueFormat="YYYY-MM-DD"
                              name="endDate"
                              placeholder={Localise(messages, "End Date")}
                              containerStyle={{
                                ...{
                                  zIndex: 30,
                                  height: Platform.OS !== "web" ? 40 : "auto",
                                  marginTop: 0,
                                  flex: 1,
                                },
                              }}
                              alignIconRight={true}
                              iconName="calendar"
                              iconType="font-awesome"
                              minDate={minDate.toDate()}
                              maxDate={maxDate.toDate()}
                              overrideValue={endDate}
                              popperPlacement={popperPlacement}
                            />
                          </View>
                        </View>
                      </>
                    )}
                    {!hideType && (
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          zIndex: Platform.OS === "web" ? 0 : 20,
                          marginBottom: 10,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 10,
                          }}
                        >
                          <Text>{Localise(messages, "Type")} </Text>
                        </View>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <FormFieldPicker
                            placeholder={{
                              label: Localise(messages, "Select type"),
                            }}
                            containerStyle={{
                              zIndex: 30,
                              flex: 1,
                              marginTop: 0,
                              width: "100%",
                              height: Platform.OS !== "web" ? 40 : "auto",
                            }}
                            data={feedTypesList}
                            name="feedType"
                            label=""
                            overrideValue={feedType}
                          />
                        </View>
                      </View>
                    )}
                  </View>
                );
              }}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default DateRangeFilter;
