import React from "react";
import { View, Text } from "react-native";
import { Button } from "react-native-elements";
import { useFormikContext, FieldArray } from "formik";

import { ProductVariant } from "./helper";
import { FormFieldPicker } from "components/elements/forms";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { theme, fonts } from "styles/theme";
import tw from "tailwind-rn";
import findLastIndex from "lodash/findLastIndex";

const Variations = ({ isCreateProduct }) => {
  const {
    values: { group, variants = [], defaultVariantName, standalone },
    setFieldValue,
    errors,
  } = useFormikContext();

  const isMOLProduct = group === "mol";
  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile } = React.useContext(DeviceContext);

  const activeVariants =
    variants
      ?.filter((e) => e.active)
      .map((variant) => ({
        label: variant.variantType,
        value: variant.variantType,
      })) || [];
  const isVariantNameExists =
    !isMOLProduct &&
    findLastIndex(variants, (v) => v.variantType === "") === -1;

  return (
    <>
      {standalone ? (
        <>
          <ProductVariant isCreateProduct={isCreateProduct} />
        </>
      ) : (
        <>
          <FieldArray
            name="variants"
            render={() => {
              return (
                <>
                  {variants.map((variant, index) => {
                    return (
                      <React.Fragment key={index}>
                        <ProductVariant
                          index={index}
                          data={variant}
                          isCreateProduct={isCreateProduct}
                        />
                      </React.Fragment>
                    );
                  })}
                  {typeof errors.variants === "string" ? (
                    <Text
                      style={[theme.Picker.errorStyle, { margin: 5 }]}
                      testID={"custom_formik_error"}
                    >
                      {Localise(messages, errors.variants)}
                    </Text>
                  ) : null}
                </>
              );
            }}
          />
          {!isMOLProduct && variants.length < 8 ? (
            <View style={tw("flex flex-row justify-between items-center")}>
              <Button
                titleStyle={theme.Button.secondaryTitleStyle}
                buttonStyle={{
                  ...theme.Button.secondaryButtonStyle,
                  paddingVertical: 5,
                  paddingHorizontal: 5,
                }}
                containerStyle={{
                  marginVertical: 15,
                  marginHorizontal: 0,
                  justifyContent: "center",
                }}
                disabled={!isVariantNameExists}
                onPress={() => {
                  if (!variants.find((v) => v.variantType === "")) {
                    setFieldValue("addNewVariant", variants.length + 1);
                  }
                }}
                title={Localise(messages, "Add Variation")}
                testID={"Add Variation"}
                accessibilityLabel={"Add Variation"}
              />
            </View>
          ) : null}
        </>
      )}

      {activeVariants.length > 0 && (
        <FormFieldPicker
          name={"defaultVariantName"}
          label={"Website Default Variation"}
          labelStyle={fonts.heading4}
          containerStyle={{
            width: isMobile ? "100%" : 200,
            paddingBottom: 5,
          }}
          placeholder={{
            label: "Choose variation",
            value: "",
          }}
          data={activeVariants}
          defaultValue={defaultVariantName}
        />
      )}
    </>
  );
};

export default Variations;
