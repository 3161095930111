import unionBy from "lodash/unionBy";
import intersection from "lodash/intersection";
import isArray from "lodash/isArray";
import get from "lodash/get";

const variantFields = {
  active: false,
  call_for_price: false,
  inStorePickUp: false,
  status: "inactive",
  dimensions: "",
  price: "",
  srpMinPrice: "",
  adjustedPrice: "",
  adjustedCompareAtPrice: "",
  flowerType: [],
  productColor: [],
  customFlowerType: "",
  customProductColor: "",
  statusAsOnDate: true,
  compareAtPrice: "",
  recipe: [],
  image: "",
  originalImage: "",
  originalColor: [],
  seoUrl: "",
};

const variantSequence = ["S", "D", "P", "E"];

const variantSuffix = {
  S: "Standard",
  D: "Deluxe",
  P: "Premium",
  E: "Exquisite",
};

export const getUIConfig = (
  productData,
  isCreateProduct,
  shopCode,
  addonCatalog
) => {
  const initialValues = {
    name: "",
    productId: "",
    productType: "",
    collections: [],
    productGroup: [],
    seoUrl: "",
    seoDisplayUrl: "",
    description: "",
    defaultVariantId: "",
    defaultVariantName: "",
    variants: [],
    addons: [],
    flowerType: [],
    productColor: [],
    customFlowerType: "",
    customProductColor: "",
    recipe: [],
    availability: false,
    showOnWebsite: true,
    activeDateAvailability: false,
    activeStartDate: "",
    activeEndDate: "",
    localDelivery: false,
    promoEligible: true,
    statusAsOnDate: true,
    soldOut: false,
    inStorePickUp: false,
    call_for_price: false,
    excludeFromPriceMinimum: false,
    excludeFromRushDelivery: false,
    isAddonSorted: false,
    fulfillmentDateAvailability: false,
    fulfillmentStartDate: "",
    fulfillmentEndDate: "",
    classification: {},
    availabilityObject: {},
    memberModifiedCollections: [],
    shopCode,
    ...productData,
  };
  const isAddon = initialValues.productType === "addon";

  if (isCreateProduct) {
    initialValues.productId = "";
    initialValues.name = "";
    initialValues.seoUrl = "";
    initialValues.seoDisplayUrl = "";
    initialValues.group = "florist";
    initialValues.siteSellable = [];
    initialValues.siteId = undefined;
    initialValues.srpMinPrice = "";
    initialValues.addons = productData.productId
      ? productData.addons
      : addonCatalog.filter((addon) => addon.group === "mol");

    if (initialValues.standalone) {
      initialValues.taxonomy = "Variation Florist";
      initialValues.variants = [
        {
          variantType: isAddon ? "Variant 1" : variantSuffix["S"],
          upsellProductName: isAddon ? "Variant 1" : variantSuffix["S"],
          variationDisplaySequence:
            variantSequence.findIndex((e) => e == "S") + 1,
          productId: isAddon ? "A" : "S",
          active: true,
          price: initialValues.price,
          srpMinPrice: "",
          adjustedPrice: initialValues.adjustedPrice,
          adjustedCompareAtPrice: initialValues.adjustedCompareAtPrice,
          classification: initialValues.classification,
          compareAtPrice: initialValues.compareAtPrice,
          flowerType: initialValues.flowerType,
          productColor: initialValues.productColor,
          customFlowerType: "",
          customProductColor: "",
          recipe: [],
          image: initialValues.image,
          originalImage: initialValues.originalImage,
          originalColor: initialValues.originalColor,
          description: initialValues.description,
          seoUrl: "",
          ...(isAddon ? { addonType: "Other" } : {}),
        },
      ];
      initialValues.defaultVariantName = isAddon
        ? "Variant 1"
        : variantSuffix["S"];
    }
    initialValues.standalone = false;
    initialValues.productGroup = ["my_catalog"];
  }

  const activeVariants = initialValues.variants?.filter((e) => e.active);

  const defaultVariantName = get(
    activeVariants.find(
      (e) => e.variantType === initialValues.defaultVariantName
    ),
    "variantType",
    activeVariants.length ? activeVariants[0].variantType : ""
  );
  const defaultVariantId = get(
    activeVariants.find(
      (e) => e.variantType === initialValues.defaultVariantName
    ),
    "productId",
    activeVariants.length ? activeVariants[0].variantType : ""
  );

  initialValues.defaultVariantId = defaultVariantId;
  initialValues.defaultVariantName = defaultVariantName;

  initialValues.addons =
    intersection(
      initialValues.addons,
      addonCatalog.map((e) => e.productId)
    ) || [];

  if (initialValues.productType) {
    if (isAddon) {
      initialValues.addNewVariant = initialValues.variants.length || 1;
      initialValues.variants = getAddonVariants(initialValues, isCreateProduct);
      initialValues.seoDisplayUrl = "";
      initialValues.addons = [];
    } else {
      initialValues.variants = getProductVariants(
        initialValues,
        isCreateProduct
      );
    }
  }

  return initialValues;
};

export const getProductVariants = (initialValues, isCreateProduct) => {
  const {
    standalone = false,
    group = "",
    productId = "",
    productType = "",
    variants = [],
    addNewVariant = 0,
  } = initialValues;
  if (productType && productType === "addon") {
    return [];
  }

  if (standalone) return [];

  const isMOLProduct = group === "mol";

  if (isMOLProduct) return variants;

  const sourceVariants = variants
    .filter((v) => !v.addonType)
    .map((v) => {
      const key = v.productId.charAt(v.productId.length - 1);
      return {
        ...v,
        productId: `${productId}${key}`,
        seoUrl: "",
        srpMinPrice: isCreateProduct ? "" : v.srpMinPrice,
      };
    });
  const alphabet = "ABCFGHIJKLMNOQRTUVWXYZ".split("");
  const newVariationChar = sourceVariants.filter(
    (v) => !variantSequence.includes(v.productId.charAt(v.productId.length - 1))
  );
  const nextChar = alphabet[newVariationChar.length];

  const addNextVariant =
    sourceVariants.length < 8 &&
    addNewVariant > sourceVariants.length &&
    !sourceVariants.find((v) => v.variantType === "");

  const defaultVariants = Object.keys(variantSuffix).map((key) => ({
    ...variantFields,
    variantType: variantSuffix[key],
    upsellProductName: variantSuffix[key],
    productId: `${productId}${key}`,
    variationDisplaySequence: variantSequence.findIndex((e) => e === key) + 1,
  }));

  if (addNextVariant)
    sourceVariants.push({
      ...variantFields,
      variantType: "",
      upsellProductName: "",
      productId: `${productId}${nextChar}`,
      variationDisplaySequence: sourceVariants.length + 1,
    });

  const productvariations = sourceVariants.length
    ? unionBy(sourceVariants, defaultVariants, "productId").sort((v1, v2) => {
        return parseInt(v1.variationDisplaySequence) <
          parseInt(v2.variationDisplaySequence)
          ? -1
          : 1;
      })
    : defaultVariants;
  return productvariations;
};

export const getAddonVariants = (initialValues, isCreateProduct) => {
  const {
    group = "",
    productId = "",
    productType = "",
    standalone = false,
    variants = [],
    addNewVariant = 1,
  } = initialValues;

  if (productType && productType !== "addon") return [];

  if (standalone) return [];

  const isMOLProduct = group === "mol";

  if (isMOLProduct) return variants;

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  const sourceVariants = variants
    .filter((v) => v.addonType)
    .map((v) => {
      const key = v.productId.charAt(v.productId.length - 1);
      return {
        ...v,
        productId: `${productId}${key}`,
        seoUrl: "",
        srpMinPrice: isCreateProduct ? "" : v.srpMinPrice,
      };
    });

  const nextChar = alphabet[sourceVariants.length];

  const addNextVariant =
    sourceVariants.length < 10 &&
    addNewVariant > sourceVariants.length &&
    !sourceVariants.find((v) => v.variantType === "");

  if (addNextVariant)
    sourceVariants.push({
      ...variantFields,
      variantType: "",
      upsellProductName: "",
      productId: `${productId}${nextChar}`,
      addonType: "Other",
      variationDisplaySequence: sourceVariants.length + 1,
    });

  return sourceVariants;
};

export const getAdjustedPrice = (
  initialPrice = 0,
  currentPrice = 0,
  adjustedPriceValue = 0
) => {
  const initial = initialPrice ? initialPrice : 0;
  const current = currentPrice ? currentPrice : 0;
  const adjustedPrice = adjustedPriceValue ? adjustedPriceValue : 0;
  const difference = parseFloat(current) - parseFloat(initial);

  return difference
    ? (parseFloat(adjustedPrice) + parseFloat(difference)).toFixed(2)
    : parseFloat(adjustedPrice).toFixed(2);
};

export const OPERATIONS = {
  add: "added",
  remove: "removed",
  replace: "updated",
  addonReplace: "removed",
};

export const COLLECTIONOPS = {
  add: "added to",
  remove: "removed from",
  replace: "added to",
};

export const BOOL_TYPE = (val) => (val ? "Active" : "Inactive");

export const KEY_MAPPINGS = {
  availabilityStartDate: "availability.activeStartDate",
  availabilityEndDate: "availability.activeEndDate",
  availabilityActive: "masterAvailability",
  defaultVariantId: "variations.defaultVariationId",
  availabilityIsDeleted: "availability.isDeleted",
  availabilitySoldOut: "availability.soldOut",
  availabilityExcludePriceMin: "availability.excludeFromPriceMinimum",
  regionalPrices: "regionalPrices",
  callForPrice: "callForPrice",
  fulfillmentInstore: "fulfillment.inStore",
  fulfillmentLocalDelivery: "fulfillment.localDelOnly",
  regionalPricesSalePrice: "regionalPrices.1.salePrice",
  regionalPricesPrice: "regionalPrices.1.price",
  addonRegionalPricesSalePrice: "regionalPrices.0.salePrice",
  addonRegionalPricesPrice: "regionalPrices.0.price",
  descriptions: "descriptions.long.en-US",
  addons: "addons",
  media: "media",
  variations: "variations",
  color: "color",
  collections: "collections.floristCollections",
  name: "name.en-US",
  classification: "classification",
  variationActive: "availability.active",
  productGroup: "productGroup",
  addonName: "addonName",
};

export const VALUE_MAPPINGS = {
  author: "{{AUTHOR}}",
  operation: "{{OPERATION}}",
  activeDate: "{{ACTIVEDATE}}",
  date: "{{DATE}}",
  time: "{{TIME}}",
  bool: "{{BOOL}}",
  price: "{{PRICE}}",
  collections: "{{COLLECTION}}",
  flowerType: "{{FLOWERTYPE}}",
  color: "{{COLOR}}",
  name: "{{NAME}}",
  addons: "{{ADDONS}}",
  variation: "{{VARIATION}}",
  fromValue: "{{FROMVALUE}}",
  delete: "{{DELETE}}",
  type: "{{PRODUCT}}",
};

export const FULL_PATH = {
  [KEY_MAPPINGS.availabilityStartDate]: `Availability 'Start' Date '{{OPERATION}}' to '{{ACTIVEDATE}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.availabilityEndDate]: `Availability 'End' Date '{{OPERATION}}' to '{{ACTIVEDATE}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.availabilityActive]: `'Availability' status is '{{OPERATION}}' to '{{BOOL}}' by {{AUTHOR}} at {{TIME}}`,
  [KEY_MAPPINGS.availabilityIsDeleted]: `{{PRODUCT}} is '{{DELETE}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.availabilitySoldOut]: `'Sold Out' status set to '{{OPERATION}}' '{{BOOL}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.availabilityExcludePriceMin]: `Catalog Minimum '{{OPERATION}}' as '{{BOOL}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.regionalPrices]: `'{{VARIATION}}' Price '{{OPERATION}}' '{{PRICE}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.callForPrice]: `'Call For Price' '{{OPERATION}}' as '{{BOOL}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.fulfillmentInstore]: `'In-Store Pickup' '{{OPERATION}}' as '{{BOOL}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.fulfillmentLocalDelivery]: `Local Delivery '{{OPERATION}}' as '{{BOOL}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.regionalPricesSalePrice]: `'{{VARIATION}}' Sale Price '{{OPERATION}}' to '{{PRICE}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.regionalPricesPrice]: `'{{VARIATION}}' Price '{{OPERATION}}' to '{{PRICE}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.media]: `'{{VARIATION}}' Image '{{OPERATION}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.variations]: `Variations {{OPERATION}} by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.collections]: `'{{COLLECTION}}' collection '{{OPERATION}}' the Product by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.classification]: `'{{VARIATION}}' '{{FLOWERTYPE}}' '{{OPERATION}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.name]: `'{{PRODUCT}} Name' is '{{OPERATION}}' by {{AUTHOR}} at {{TIME}}`,
  [KEY_MAPPINGS.addonName]: `'{{VARIATION}}' Name is '{{OPERATION}}'  by {{AUTHOR}} at {{TIME}}`,
  [KEY_MAPPINGS.descriptions]: `{{PRODUCT}} 'Description' '{{OPERATION}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.variationActive]: `'{{VARIATION}}' turned '{{BOOL}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.productGroup]: `Product is {{OPERATION}} '{{FROMVALUE}}' Product Group  by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  [KEY_MAPPINGS.defaultVariantId]: `'Default Variation' is 'updated' to '{{FROMVALUE}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
};

export const SMALL_PATH = {
  classification: `'{{VARIATION}}' Flower Type '{{FLOWERTYPE}}' is '{{OPERATION}}' by {{AUTHOR}} at {{TIME}}`,
  triggerType: `{{PRODUCT}} 'created' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  variationAdd: `'{{VARIATION}}' is 'added' to the {{PRODUCT}} by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  addons: `Add-On '{{ADDONS}}' is '{{OPERATION}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  addonRemove: `Add-On '{{ADDONS}}' is '{{OPERATION}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
  color: `'{{VARIATION}}' Color type '{{OPERATION}}' to '{{COLOR}}' by {{AUTHOR}} on {{DATE}} at {{TIME}}`,
};

const getProductNameById = (data, id, collection = false) => {
  const property = collection ? "handle" : "productId";
  if (id.includes("addons")) {
    return "Addons";
  }
  const productName = data.find((item) => id.includes(item[property]))?.name;
  return productName;
};

export const prepareProductActivity = (
  response = [],
  Localise,
  messages,
  userProfile,
  allAddons,
  allCollections,
  currency
) => {
  const activity = [];

  response.forEach(
    ({
      diff,
      trigger_type,
      authorName,
      lastUpdatedDate,
      productId,
      isVariationProduct,
      isAddon,
      variationIds,
      modifiedProperty,
    }) => {
      const activityObject = {};
      const updatedAuthorName =
        `${userProfile.firstName} ${userProfile.lastName}` || authorName;
      activityObject["date"] = lastUpdatedDate?.format("MMM D, YYYY") ?? "";
      activityObject["description"] = [];
      diff.forEach(({ fullPath, operation, value, path, fromValue }) => {
        const finalPath = path.includes("collections")
          ? "collections.floristCollections"
          : path.includes("productGroup")
          ? "productGroup"
          : fullPath;
        const finalPrice = path.toLowerCase().includes("prices")
          ? isArray(value)
            ? value[0]?.price
            : value
          : "";

        const collections =
          path.toLowerCase() === "collections"
            ? value?.floristCollections?.length
              ? value?.floristCollections.toString()
              : value
            : "";
        const timeZone = lastUpdatedDate._d
          .toTimeString()
          .split("(")[1]
          .match(/\b(\w)/g)
          .join("");

        activityObject["description"].push(
          getActivity({
            fullPath: finalPath,
            path,
            authorName: updatedAuthorName,
            operation,
            date: activityObject["date"],
            time: lastUpdatedDate.format("LT"),
            timeZone,
            value,
            diff,
            modifiedProperty,
            allAddons,
            allCollections,
            isAddon,
            isVariationProduct,
            variationIds,
            response,
            productId,
            price: `$${parseFloat(`${finalPrice}`).toFixed(2)} ${currency}`,
            trigger_type,
            collections,
            fromValue,
            Localise,
            messages,
          })
        );
      });
      activity.push(activityObject);
    }
  );

  let finalResponse = Object.values(
    activity.reduce((acc, cur) => {
      if (!acc[cur.date]) acc[cur.date] = { date: cur.date, description: [] };
      cur.description.forEach((val) => {
        if (val && !acc[cur.date].description.includes(val)) {
          acc[cur.date].description.push(val);
        }
      });

      return acc;
    }, {})
  )
    .filter((element) => element.description.length > 0)
    .sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });
  return finalResponse;
};

const getActivity = ({
  path = "",
  fullPath = "",
  authorName = "",
  operation = "",
  time = "",
  date,
  productId,
  diff,
  timeZone = "",
  isVariationProduct,
  isAddon,
  allAddons,
  allCollections,
  variationIds,
  value = false,
  price = "",
  fromValue = "",
  trigger_type = "",
  collections = [],
  Localise,
  messages,
}) => {
  const LocalisedWord = (params) => Localise(messages, params);
  const variantName = productId.charAt(productId.length - 1);

  if (trigger_type !== "add") {
    const masterData = isVariationProduct
      ? !variationIds.includes(productId)
      : !isVariationProduct;
    const isAuditCreated =
      diff.filter((val) => val.path === "audit").length > 0;

    if (masterData && !isAuditCreated) {
      //Master Product Activity
      const finalPath =
        fullPath === "availability.active"
          ? "masterAvailability"
          : fullPath === "variations" && value.defaultVariationId
          ? "variations.defaultVariationId"
          : fullPath;
      const isMemberModifiedCollectionsExists = diff.filter((val) =>
        val.path.includes("memberModifiedCollections")
      );
      if (
        [
          KEY_MAPPINGS.availabilityActive,
          KEY_MAPPINGS.availabilitySoldOut,
          KEY_MAPPINGS.availabilityExcludePriceMin,
          KEY_MAPPINGS.callForPrice,
          KEY_MAPPINGS.fulfillmentInstore,
          KEY_MAPPINGS.fulfillmentLocalDelivery,
          KEY_MAPPINGS.descriptions,
          KEY_MAPPINGS.name,
          KEY_MAPPINGS.availabilityIsDeleted,
          KEY_MAPPINGS.productGroup,
          KEY_MAPPINGS.defaultVariantId,
        ].includes(finalPath)
      ) {
        const MODIFIEDOPS =
          finalPath === "productGroup" ? COLLECTIONOPS : OPERATIONS;
        const boolValue =
          finalPath === "availability.excludeFromPriceMinimum" ? !value : value;
        return LocalisedWord(FULL_PATH[finalPath])
          .replace(VALUE_MAPPINGS.author, authorName)
          .replace(
            VALUE_MAPPINGS.operation,
            Localise(messages, Localise(messages, MODIFIEDOPS[operation]))
          )
          .replace(
            VALUE_MAPPINGS.bool,
            Localise(messages, BOOL_TYPE(boolValue))
          )
          .replace(
            VALUE_MAPPINGS.fromValue,
            Localise(messages, value?.defaultVariationId || value)
          )
          .replace(VALUE_MAPPINGS.date, date)
          .replace(VALUE_MAPPINGS.type, isAddon ? "Addon" : "Product")
          .replace(
            VALUE_MAPPINGS.delete,
            Localise(messages, value ? "deleted" : "restored")
          )
          .replace(VALUE_MAPPINGS.time, `${time} ${timeZone}`);
      } else if ([KEY_MAPPINGS.collections].includes(finalPath)) {
        let collectionValue = "";
        if (isMemberModifiedCollectionsExists.length > 0) {
          const memberModifiedValue =
            isMemberModifiedCollectionsExists[0].value;

          if (isArray(collections)) {
            if (isArray(memberModifiedValue)) {
              collectionValue = memberModifiedValue.find((element) =>
                collections.includes(element)
              );
            } else if (collections.includes(memberModifiedValue)) {
              collectionValue = memberModifiedValue;
            }
          }
        } else {
          collectionValue = collections;
        }
        if (collectionValue) {
          return LocalisedWord(FULL_PATH[finalPath])
            .replace(VALUE_MAPPINGS.author, authorName)
            .replace(
              VALUE_MAPPINGS.operation,
              Localise(messages, COLLECTIONOPS[operation])
            )
            .replace(
              VALUE_MAPPINGS.collections,
              `${getProductNameById(allCollections, collectionValue, true)}`
            )
            .replace(VALUE_MAPPINGS.date, date)
            .replace(VALUE_MAPPINGS.time, `${time} ${timeZone}`);
        }
      } else if (
        [
          KEY_MAPPINGS.availabilityStartDate,
          KEY_MAPPINGS.availabilityEndDate,
        ].includes(finalPath)
      ) {
        const finalOperation =
          value === "2100-01-01" || value === "1900-01-01"
            ? "remove"
            : operation;
        const finalValue = finalOperation === "remove" ? fromValue : value;
        return LocalisedWord(FULL_PATH[finalPath])
          .replace(VALUE_MAPPINGS.author, authorName)
          .replace(
            VALUE_MAPPINGS.operation,
            Localise(messages, OPERATIONS[finalOperation])
          )
          .replace(VALUE_MAPPINGS.activeDate, finalValue)
          .replace(VALUE_MAPPINGS.date, date)
          .replace(VALUE_MAPPINGS.time, `${time} ${timeZone}`);
      } else if (!isVariationProduct) {
        const isAuditCreated =
          diff.filter((val) => val.path === "audit").length > 0;

        if (
          ([KEY_MAPPINGS.addons].includes(path) &&
            !isAuditCreated &&
            fullPath.split(".").length === 2) ||
          fullPath.includes(".id.value")
        ) {
          // Standalone Addons activity
          const valueMapping = operation === "remove" || operation === "add";
          const firstAddonValue = diff.find(
            (val) =>
              val.path === "addons" &&
              val.operation === "replace" &&
              val.fromValue
          );

          const findRemove =
            diff.filter((val) => val.operation === "remove").length === 1;
          const lastAddonValue =
            diff.length > 1 &&
            findRemove &&
            diff[diff.length - 1].operation === "remove";

          if (
            operation === "replace" &&
            fromValue === firstAddonValue.fromValue
          ) {
            return LocalisedWord(SMALL_PATH[path])
              .replace(VALUE_MAPPINGS.author, authorName)
              .replace(
                VALUE_MAPPINGS.operation,
                Localise(messages, OPERATIONS["addonReplace"])
              )
              .replace(VALUE_MAPPINGS.time, `${time} ${timeZone}`)
              .replace(VALUE_MAPPINGS.date, date)
              .replace(
                VALUE_MAPPINGS.addons,
                getProductNameById(allAddons, fromValue)
              );
          } else if (valueMapping && !lastAddonValue) {
            return LocalisedWord(SMALL_PATH["addonRemove"])
              .replace(VALUE_MAPPINGS.author, authorName)
              .replace(
                VALUE_MAPPINGS.operation,
                Localise(messages, OPERATIONS[operation])
              )
              .replace(VALUE_MAPPINGS.time, `${time} ${timeZone}`)
              .replace(VALUE_MAPPINGS.date, date)
              .replace(
                VALUE_MAPPINGS.fromValue,
                getProductNameById(allAddons, fromValue)
              )
              .replace(
                VALUE_MAPPINGS.addons,
                getProductNameById(allAddons, value?.id?.value)
              );
          }
        } else if (
          [
            KEY_MAPPINGS.regionalPrices,
            KEY_MAPPINGS.regionalPricesSalePrice,
            KEY_MAPPINGS.regionalPricesPrice,
            KEY_MAPPINGS.media,
          ].includes(finalPath) &&
          !isAuditCreated &&
          !variationIds.length
        ) {
          return LocalisedWord(FULL_PATH[finalPath])
            .replace(VALUE_MAPPINGS.author, authorName)
            .replace(
              VALUE_MAPPINGS.operation,
              Localise(messages, OPERATIONS[operation])
            )
            .replace(VALUE_MAPPINGS.price, price)
            .replace(VALUE_MAPPINGS.date, date)
            .replace(VALUE_MAPPINGS.variation, isAddon ? "Addon" : "Product")
            .replace(VALUE_MAPPINGS.time, `${time} ${timeZone}`)
            .replace(VALUE_MAPPINGS.bool, Localise(messages, BOOL_TYPE(value)))
            .replace(VALUE_MAPPINGS.color, value.toString());
        }
      }
    } else if (
      variantSequence.includes(variantName) ||
      variationIds.includes(productId)
    ) {
      //Variation Activity
      const isAuditCreated =
        diff.filter((val) => val.path === "audit").length > 0;
      const finalPath =
        path === "media"
          ? "media"
          : fullPath === "name.en-US"
          ? "addonName"
          : fullPath;
      if (
        [
          // KEY_MAPPINGS.regionalPrices,
          KEY_MAPPINGS.regionalPricesSalePrice,
          KEY_MAPPINGS.regionalPricesPrice,
          KEY_MAPPINGS.media,
          KEY_MAPPINGS.addonName,
          KEY_MAPPINGS.variations,
          KEY_MAPPINGS.variationActive,
        ].includes(finalPath) &&
        !isAuditCreated
      ) {
        return LocalisedWord(FULL_PATH[finalPath])
          .replace(VALUE_MAPPINGS.author, authorName)
          .replace(
            VALUE_MAPPINGS.operation,
            Localise(messages, OPERATIONS[operation])
          )
          .replace(VALUE_MAPPINGS.price, price)
          .replace(VALUE_MAPPINGS.date, date)
          .replace(
            VALUE_MAPPINGS.variation,
            isAddon
              ? `${productId} Variation`
              : `${variantSuffix[variantName]} Variation`
          )
          .replace(VALUE_MAPPINGS.type, isAddon ? "Addon" : "Product")
          .replace(VALUE_MAPPINGS.time, `${time} ${timeZone}`)
          .replace(VALUE_MAPPINGS.bool, Localise(messages, BOOL_TYPE(value)))
          .replace(VALUE_MAPPINGS.color, value.toString());
      } else if (
        [KEY_MAPPINGS.classification, KEY_MAPPINGS.color].includes(path) &&
        !isAuditCreated
      ) {
        return LocalisedWord(SMALL_PATH[path])
          .replace(VALUE_MAPPINGS.author, authorName)
          .replace(
            VALUE_MAPPINGS.operation,
            Localise(messages, OPERATIONS[operation])
          )
          .replace(VALUE_MAPPINGS.time, `${time} ${timeZone}`)
          .replace(VALUE_MAPPINGS.date, date)
          .replace(
            VALUE_MAPPINGS.variation,
            `${variantSuffix[variantName]} Variation`
          )
          .replace(VALUE_MAPPINGS.color, value)
          .replace(VALUE_MAPPINGS.name, value)
          .replace(VALUE_MAPPINGS.flowerType, value);
      }
      if (
        [KEY_MAPPINGS.addons].includes(path) &&
        !isAuditCreated &&
        (fullPath.split(".").length === 2 || fullPath.includes(".id.value"))
      ) {
        //Addons activity

        const valueMapping = operation === "remove" || operation === "add";
        const firstAddonValue = diff.find(
          (val) => val.operation === "replace" && val.fromValue
        );
        const findRemove =
          diff.filter((val) => val.operation === "remove" && path === "addons")
            .length === 1;
        const lastAddonValue =
          diff.length > 1 &&
          findRemove &&
          diff[diff.length - 1].operation === "remove" &&
          diff[diff.length - 1].path === "addons";

        if (
          operation === "replace" &&
          fromValue === firstAddonValue.fromValue
        ) {
          return LocalisedWord(SMALL_PATH[path])
            .replace(VALUE_MAPPINGS.author, authorName)
            .replace(
              VALUE_MAPPINGS.operation,
              Localise(messages, OPERATIONS["addonReplace"])
            )
            .replace(VALUE_MAPPINGS.time, `${time} ${timeZone}`)
            .replace(VALUE_MAPPINGS.date, date)
            .replace(
              VALUE_MAPPINGS.addons,
              getProductNameById(allAddons, fromValue)
            );
        } else if (valueMapping && !lastAddonValue) {
          return LocalisedWord(SMALL_PATH["addonRemove"])
            .replace(VALUE_MAPPINGS.author, authorName)
            .replace(
              VALUE_MAPPINGS.operation,
              Localise(messages, OPERATIONS[operation])
            )
            .replace(VALUE_MAPPINGS.time, `${time} ${timeZone}`)
            .replace(VALUE_MAPPINGS.date, date)
            .replace(
              VALUE_MAPPINGS.fromValue,
              getProductNameById(allAddons, fromValue)
            )
            .replace(
              VALUE_MAPPINGS.addons,
              getProductNameById(allAddons, value?.id?.value)
            );
        }
      }
    }
  } else if (trigger_type === "add") {
    //Variation creation, Product creation
    const variantName = productId.charAt(productId.length - 1);
    const definePath =
      isVariationProduct &&
      (variantSequence.includes(variantName) ||
        variationIds.includes(productId))
        ? SMALL_PATH.variationAdd
        : SMALL_PATH.triggerType;

    return Localise(messages, definePath)
      .replace(VALUE_MAPPINGS.author, authorName)
      .replace(VALUE_MAPPINGS.date, date)
      .replace(
        VALUE_MAPPINGS.variation,
        isAddon
          ? `${productId} Variation`
          : `${variantSuffix[variantName]} Variation`
      )
      .replace(VALUE_MAPPINGS.type, isAddon ? "Addon" : "Product")
      .replace(VALUE_MAPPINGS.time, `${time} ${timeZone}`);
  } else {
    return "";
  }
};
