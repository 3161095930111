const getDesignTimeOptions = (Localise, messages) =>
  [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5].map((hour) => ({
    value: hour.toString().includes(".")
      ? `0${hour.toString().split(".")[0]}:30`
      : `0${hour}:00`,
    label: Localise(messages, `${hour} hour${hour === 1 ? "" : "s"}`),
  }));

export const getUIConfig = (Localise, messages) => [
  {
    name: "localPickup",
    type: "switch",
    title: Localise(messages, "Store Pickup"),
  },
  // {
  //   name: "curbsidePickup",
  //   type: "switch",
  //   title: Localise(messages, "Curbside Pickup"),
  // },
  {
    name: "designTime",
    type: "picker",
    title: Localise(messages, "Design Time"),
    options: getDesignTimeOptions(Localise, messages),
  },
  {
    name: "stores",
    title: Localise(messages, "Stores"),
  },
];
