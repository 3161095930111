import React from "react";
import { ScrollView } from "react-native";
import HeaderBar from "components/containers/header";
import Feedback from "components/views/drawer/feedback";

const FeedbackPage = () => {
  return (
    <ScrollView>
      <HeaderBar />
      <Feedback />
    </ScrollView>
  );
};

export default FeedbackPage;
