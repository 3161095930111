import { createContext } from "react";
import noop from "lodash/noop";

/**
 * The context used for storing user auth information
 *
 * @see 'providers/userProfile'
 * @see 'reducers/userProfile'
 */
const UserProfileContext = createContext({
  setUserRole: noop,
  userProfile: {},
  userRole: null,
});

export default UserProfileContext;
