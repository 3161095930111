import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCollections,
  setAPIError as setCommonAPIError,
} from "../common/slice";
import { SLICE_NAME as parentSlice } from "../constants";
import { SLICE_NAME as sliceName } from "./constants";

export const initialState = {
  status: "",
  error: false,
  data: [],
  actions: {
    searchText: "",
    filters: [],
    collectionSortBy: "name::asc",
    collectionLimit: 0,
  },
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: `view::${parentSlice}::${sliceName}`,
  initialState,
  reducers: {
    setInit: (state) => {
      state.status = "progress";
    },
    setReset: (state) => {
      Object.assign(state, initialState);
    },
    setData: (state, action) => {
      state.status = "done";
      state.data = action.payload;
    },
    setViewMore: (state, action) => {
      state.actions.collectionLimit =
        state.actions.collectionLimit + action.payload;
    },
    setAction: (state, action) => {
      const { type, value } = action.payload;
      if (type === "search") state.actions.searchText = value;
      else if (type === "collectionSortBy") {
        state.actions.collectionSortBy = value;
      } else if (type === "filters") {
        state.actions.filters = [...new Set([].concat(...value))];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCommonAPIError, (state, action) => {
        const { section } = action.payload;
        if (section === "collections") {
          state.error = true;
        }
      })
      .addCase(fetchCollections, (state) => {
        state.error = false;
        state.status = "progress";
      });
  },
});

export const { setInit, setReset, setData, setViewMore, setAction } =
  slice.actions;

export default {
  [sliceName]: slice.reducer,
};
