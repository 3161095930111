import React, { Component } from "react";
import { View, Text, StyleSheet } from "react-native";

import { theme } from "styles/theme";
import tw from "tailwind-rn";

const FieldSet = ({
  label,
  children,
  labelStyle,
  containerStyle,
  labelPosition = "start", //"start", "center" or "end"
}) => {
  return (
    <View style={[theme.FieldSet.containerStyle, containerStyle]}>
      <View
        style={[
          tw(`flex justify-center items-${labelPosition}`),
          theme.FieldSet.labelContainerStyle,
        ]}
      >
        <Text
          style={[
            theme.FieldSet.labelStyle,
            labelStyle,
            {
              elevation: 1000,
            },
          ]}
        >
          {label}
        </Text>
      </View>
      <View style={{ flex: 1, justifyContent: "center", paddingVertical: 10 }}>
        <View style={theme.FieldSet.contentStyle}>{children}</View>
      </View>
    </View>
  );
};

export default FieldSet;
