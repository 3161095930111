import React from "react";
import { View, Linking, Platform, TouchableOpacity } from "react-native";
import { Text, Image } from "react-native-elements";
import tw from "tailwind-rn";
import { fonts, colors } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import { IMAGES } from "static/assets/images";
import { DeviceContext } from "library/contexts/appSettings";
import UserAuthContext from "library/contexts/userAuth";

export const callNumber = (phone) => {
  let phoneNumber = phone;
  if (Platform.OS !== "android") {
    phoneNumber = `telprompt:${phone}`;
  } else {
    phoneNumber = `tel:${phone}`;
  }
  return Linking.openURL(phoneNumber);
};

export default function InactiveUser(props) {
  const { setLogoutModal } = props;
  const { isDesktop } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const { signOut } = React.useContext(UserAuthContext);

  const handleLogout = () => {
    setLogoutModal(true);
    signOut().then(() => {
      setLogoutModal(false);
    });
  };

  return (
    <>
      {!isDesktop && (
        <View
          style={{
            justifyContent: "space-between",
            height: 50,
            borderBottomColor: colors.medium,
            borderBottomWidth: 1,
            padding: 10,
            shadowColor: colors.medium,
            shadowOffset: {
              width: 0,
              height: 3,
            },
            shadowRadius: 5,
            shadowOpacity: 1.0,
            zIndex: 1,
            backgroundColor: "#fff",
          }}
          fsClass="fs-unmask"
        >
          <TouchableOpacity onPress={handleLogout}>
            <Image
              style={{
                width: 20,
                height: 20,
              }}
              source={IMAGES["logout"]}
            />
          </TouchableOpacity>
        </View>
      )}
      <View style={[tw("flex justify-center items-center"), { height: "75%" }]}>
        <Text style={fonts.heading3}>{Localise(messages, "Oops!")}</Text>
        <Image
          style={{
            width: 200,
            height: 200,
          }}
          source={IMAGES["inactive-user"]}
        />

        <Text style={[fonts.heading5, { marginVertical: 10 }]}>
          {Localise(messages, "Your account is inactive.")}
        </Text>
        <Text style={fonts.heading5} onPress={() => callNumber("8882057225")}>
          {Localise(
            messages,
            `Please call 888.205.7225 to reactivate your account.`
          )}
        </Text>
      </View>
    </>
  );
}
