import { StyleSheet } from "react-native";
import { colors, backgroundColors, fontWeights } from "styles/theme";
import tw from "tailwind-rn";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: backgroundColors.sideCar,
    ...tw("flex-1 justify-center"),
  },
  content: {
    marginHorizontal: 16,
    alignItems: "center",
  },
  title: {
    fontSize: 48,
    fontWeight: "300",
    paddingBottom: 16,
    color: colors.dark,
  },
  subtitle: {
    fontSize: 32,
    fontWeight: "800",
    color: colors.dark,
  },
  error: {
    paddingVertical: 16,
  },
  button: {
    backgroundColor: colors.button,
    borderRadius: 50,
    padding: 16,
  },
  buttonText: {
    color: colors.secondary,
    fontWeight: fontWeights.semiBold,
    textAlign: "center",
  },
  bottomView: { marginBottom: 20 },
});
