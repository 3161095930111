import React, { useContext } from "react";
import { ActivityIndicator, View } from "react-native";
import { Text } from "react-native-elements";
import { Accordion, ToasterHandler } from "components/elements";
import { FormFieldSwitch } from "components/elements/forms";
import { backgroundColors, fonts, colors } from "styles/theme";
import tw from "tailwind-rn";
import moment from "moment";
import UserProfileContext from "library/contexts/userProfile";
import { request } from "library/utils/request";
import { PermissionsForCreditCardSettings } from "./config";
import toUpper from "lodash/toUpper";

const CreditCardSettings = ({
  memberCode,
  loading,
  setLoading,
  creditCardAccess,
  setCreditCardAccess,
  auditMsg,
  getShopSettings,
  processResponse,
}) => {
  const { userProfile: { firstName: User } = {}, userRole } =
    useContext(UserProfileContext);

  const hasEditAccess = PermissionsForCreditCardSettings[toUpper(userRole)];

  let date = new Date();

  const onAcceptCardChange = () => {
    setLoading("update-card-settings");

    request("save-card-settings", {
      preferences: [
        {
          id: "credit_card",
          values: [`${!creditCardAccess}`],
          auditInfo: `Credit Card payments ${
            creditCardAccess ? "enabled" : "disabled"
          } ${moment(date).utc().format("MM/DD/YYYY")} at ${moment(date)
            .utc()
            .format("h:mm A")} by ${User}`,
        },
      ],
      memberCode: memberCode,
    })
      .then((res) => {
        if (res && res.preferences && res.preferences.length) {
          let result = processResponse(res);
          setCreditCardAccess(result.credit_card === "true" ? true : false);

          getShopSettings(true);

          ToasterHandler("hooray", "Your settings have been saved");
        }
      })
      .catch((error) => {
        setLoading("");

        ToasterHandler("uh oh", "Please try saving your settings again");
      });
  };

  return (
    <View
      style={[
        {
          borderWidth: 1,
          borderColor: colors.grayScaleLight,
        },
        tw("my-3"),
      ]}
      pointerEvents={"auto"}
    >
      <Accordion
        title={"Credit Card Settings"}
        defaultOpen={true}
        handleOnPress={() => {}}
        labelStyle={{
          marginTop: 0,
          height: 50,
          backgroundColor: backgroundColors.navBar,
          borderBottomWidth: 1,
          borderColor: colors.grayScaleLight,
          paddingHorizontal: 12,
        }}
        titleStyle={{
          color: colors.primary,
          paddingLeft: 10,
        }}
        testID="credit_card_settings"
      >
        <View
          style={{
            backgroundColor: backgroundColors.secondary,
            padding: 10,
            paddingHorizontal: 15,
          }}
        >
          <View style={[tw("flex flex-row items-center p-2")]}>
            <Text
              style={{
                ...tw("items-center p-2"),
                ...fonts.default,
                fontSize: 15,
              }}
            >
              Accept Credit Card Payments via FTD
            </Text>
            <View
              style={{
                marginHorizontal: 20,
              }}
            >
              {loading === "get-shop-settings" ? (
                <ActivityIndicator color={colors.activityIndicator} />
              ) : (
                <FormFieldSwitch
                  value={creditCardAccess}
                  onValueChange={() => {
                    if (hasEditAccess && loading === "") {
                      onAcceptCardChange();
                    }
                  }}
                />
              )}
            </View>
            {loading === "update-card-settings" && (
              <ActivityIndicator color={colors.activityIndicator} />
            )}
          </View>

          {auditMsg && auditMsg.length > 0 ? (
            <View
              style={{
                paddingHorizontal: 16,
                paddingBottom: 14,
              }}
            >
              <Text
                style={{
                  ...tw("items-center"),
                  ...fonts.style2,
                  fontSize: 15,
                }}
              >
                {auditMsg}
              </Text>
            </View>
          ) : null}
        </View>
      </Accordion>
    </View>
  );
};

export default CreditCardSettings;
