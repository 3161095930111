import React from "react";
import { Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-native-elements";

import PaymentInput from "./input";
import { getValidationSchema } from "./yup";
import { SaveCancelButtons } from "../../helper";
import { getInitialValues } from "./ui-config";
import { theme } from "styles/theme";

import { Spinner, ToasterHandler } from "components/elements";
import { Form } from "components/elements/forms";

import tw from "tailwind-rn";
import get from "lodash/get";

import I18NContext from "library/contexts/i18N";
import { selectCustomerDetails } from "library/sagas/views/home/drawer/customer-directory/selector";
import {
  saveCustomerPayment,
  deleteCustomerCard,
} from "library/sagas/views/home/drawer/customer-directory/slice";
import { selectShopPreferences } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { DeleteCardModal } from "library/utils/delete-card";
import { setDeleteCardModal } from "library/sagas/ongoing/global-data/slice";
import { selectDeletCardModal } from "library/sagas/ongoing/global-data/selector";

const Payment = ({ UIConfig, isCreate }) => {
  const dispatch = useDispatch();
  const customerDetails = useSelector(selectCustomerDetails);
  const shopPreferences = useSelector(selectShopPreferences);
  const { showDeleteCardModal, isLoading } = useSelector(selectDeletCardModal);

  const initialValues = getInitialValues(customerDetails);
  const { messages, Localise } = React.useContext(I18NContext);

  const {
    InputFields: { section, fields, path },
  } = UIConfig;

  if (!initialValues) {
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );
  }

  function onSubmit(values, formikBag) {
    if (get(initialValues, "customerId", "") !== "") {
      const supportCreditCardPayment =
        shopPreferences["credit_card"] === "true";

      if (supportCreditCardPayment) {
        dispatch(
          saveCustomerPayment({
            params: { ...values, isCreate },
            resolve: () => {
              ToasterHandler(
                "success",
                Localise(
                  messages,
                  "Payment details have been updated successfully"
                )
              );
              formikBag.setSubmitting(false);
            },
            reject: (message = "") => {
              ToasterHandler(
                "error",
                Localise(
                  messages,
                  message !== ""
                    ? message
                    : "We were unable to process your request, please try again."
                )
              );
              formikBag.setSubmitting(false);
            },
          })
        );
      } else {
        ToasterHandler(
          "error",
          Localise(
            messages,
            "Unable to save card as credit card payment type is disabled. Please contact administrator"
          )
        );
        formikBag.setSubmitting(false);
      }
    } else {
      ToasterHandler(
        "error",
        Localise(messages, "Please save Customer Info before saving Payment")
      );
      formikBag.setSubmitting(false);
    }
  }

  const deleteCard = (values) => {
    const {
      customerId,
      storeOrigin,
      paymentInfo: { creditCardId },
    } = values;
    dispatch(
      deleteCustomerCard({
        params: { customerId, storeOrigin, creditCardId },
        resolve: () => {
          ToasterHandler(
            "success",
            Localise(messages, "Credit Card deleted successfully")
          );
          dispatch(
            setDeleteCardModal({ showDeleteCardModal: false, isLoading: false })
          );
        },
        reject: () => {
          ToasterHandler(
            "error",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          dispatch(
            setDeleteCardModal({ showDeleteCardModal: false, isLoading: false })
          );
        },
      })
    );
  };

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
        validationSchema={getValidationSchema(Localise, messages)}
        validateOnBlur={false}
        validateOnChange={false}
        render={({ values }) => {
          return (
            <>
              <Text style={tw("pb-4")}>{section}</Text>
              {fields.map((data, index) => {
                const { name } = data;
                const {
                  paymentInfo: { tokenId = "" },
                } = values;
                const hideCVV = name === "cvvNumber" && tokenId !== "";
                return (
                  !hideCVV && (
                    <PaymentInput data={data} key={index} path={path} />
                  )
                );
              })}
              {values.paymentInfo.tokenId !== "" ? (
                <View style={tw("flex flex-row justify-end mt-1")}>
                  <Button
                    title={Localise(messages, "Delete Card")}
                    titleStyle={theme.Button.secondaryTitleStyle}
                    buttonStyle={{
                      ...theme.Button.secondaryButtonStyle,
                      paddingVertical: 8,
                      paddingHorizontal: 10,
                    }}
                    containerStyle={{ margin: 5, justifyContent: "center" }}
                    onPress={() =>
                      dispatch(
                        setDeleteCardModal({ showDeleteCardModal: true })
                      )
                    }
                  />
                </View>
              ) : (
                <SaveCancelButtons />
              )}

              {showDeleteCardModal && (
                <DeleteCardModal
                  modalVisible={showDeleteCardModal}
                  onConfirm={() => {
                    deleteCard(values);
                    dispatch(
                      setDeleteCardModal({
                        showDeleteCardModal: true,
                        isLoading: true,
                      })
                    );
                  }}
                  closeModal={() =>
                    dispatch(setDeleteCardModal({ showDeleteCardModal: false }))
                  }
                  loading={isLoading}
                />
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default Payment;
