import React from "react";
import { View, TouchableOpacity } from "react-native";
import { Text } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";

import styles from "./styles";
import moment from "moment";
import { formatPrice } from "library/utils/formatter";

const Item = ({
  id,
  // index,
  data,
  columns,
  style,
  selectors: {
    // dataSelector, limitSelector,
    selectActiveItem,
  },
  actions: { setActiveItem, setStoreOrigin },
}) => {
  const dispatch = useDispatch();
  const selectedItemId = useSelector(selectActiveItem);
  const { [id]: itemId, storeOrigin, status = "" } = data;
  const activeItem = selectedItemId === itemId;

  const onSelect = () => {
    dispatch(setActiveItem({ type: "giftCardId", value: itemId }));
    dispatch(setStoreOrigin({ storeOrigin }));
  };

  const statusKeyMap = {
    ACTIVE: "Active",
    REFUND: "Refund",
    REINSTATE: "Reinstate",
    REDEEMED: "Redeemed",
    EXPIRED: "Expired",
    CANCELLED: "Cancelled",
    ISSUEDINACTIVE: "Inactive",
  };

  return (
    <>
      <TouchableOpacity
        activeOpacity={0.6}
        onPress={onSelect}
        style={{
          minWidth: 300,
        }}
        testID={itemId}
        accessibilityLabel={itemId}
      >
        <View
          style={[styles.container, activeItem && styles.activeRowContainer]}
          fsClass="fs-unmask"
        >
          <View
            style={[styles.row, activeItem && styles.activeRow, style]}
            fsClass="fs-unmask"
          >
            {columns.map(({ column, style }, index) => {
              return (
                <Text
                  numberOfLines={1}
                  style={[styles.column, { ...style }]}
                  key={index}
                  fsClass={
                    column === "giftCardId" ||
                    column === "remainingAmount" ||
                    column === "recipientName" ||
                    column === "recipientEmail" ||
                    column === "expirationDate"
                      ? "fs-exclude"
                      : "fs-unmask"
                  }
                >
                  {column === "giftCardId"
                    ? `${`Gift Card`} **${data["lastChars"]}`
                    : column === "remainingAmount"
                    ? `${`Balance`} $${formatPrice(data[column])}`
                    : column === "expirationDate"
                    ? status !== "ACTIVE"
                      ? statusKeyMap[status]
                      : `${`Expiration:`} ${moment(
                          data[column],
                          "YYYY-MM-DDTHH:mm:ss"
                        ).format("MM/DD/YYYY")}`
                    : data[column]}
                </Text>
              );
            })}
          </View>
        </View>
      </TouchableOpacity>
    </>
  );
};

export default Item;
