import { put } from "redux-saga/effects";

import { setApiResponse } from "../slice";

export function* handleFetchAnalytics() {
  yield put(
    setApiResponse({
      path: "analytics",
      content: "Analytics",
    })
  );
}
