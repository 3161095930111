import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectActiveTab = createSelector(
  selectState,
  ({ activeTab }) => activeTab || 0
);

export const selectEnableSharedCatalogs = createSelector(
  selectState,
  ({ enableSharedCatalogs }) => enableSharedCatalogs
);

export const selectTabNames = createSelector(
  selectState,
  ({ tabs, enableSharedCatalogs }) =>
    tabs.filter((tab) =>
      tab.key === "shared-catalogs" ? enableSharedCatalogs : true
    ) || []
);

export const selectSideCar = createSelector(
  selectState,
  ({ sideCar }) => sideCar || false
);

export const selectSideCarTitle = createSelector(
  selectState,
  ({ sideCarTitle }) => sideCarTitle
);
