import React from "react";
import { View, Text, Platform } from "react-native";

import { CustomModal } from "components/elements";

import { fonts, colors, backgroundColors } from "styles/theme";

import I18NContext from "library/contexts/i18N";

const FeesCoverageModal = ({ title, primaryAction, secondaryAction }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  return (
    <CustomModal
      modalVisible={!!title}
      modalContent={{
        content: (
          <View
            style={{
              borderColor: backgroundColors.greyColor,
              borderWidth: 1,
              padding: 20,
            }}
          >
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <Text
                style={{
                  ...fonts.heading4,
                  paddingBottom: 15,
                  color: colors.highlighter,
                }}
              >
                {`${Localise(
                  messages,
                  "This fee change will apply to all"
                )} ${title}`}
              </Text>
              <Text
                style={{
                  ...fonts.heading5,
                  paddingBottom: 15,
                  color: colors.highlighter,
                }}
              >
                {Localise(messages, "Do you want to continue?")}
              </Text>
            </View>
          </View>
        ),
        buttons: [
          {
            type: "secondary",
            title: Localise(messages, "Nevermind"),
          },
          {
            type: "primary",
            title: Localise(messages, "Update"),
          },
        ],
      }}
      secondaryhandler={() => {
        secondaryAction && secondaryAction();
      }}
      primaryhandler={() => {
        primaryAction && primaryAction();
      }}
      contentStyle={{
        backgroundColor: "white",
      }}
      modalStyle={{
        padding: 0,
        ...(Platform.OS !== "web" && {
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          backgroundColor: "#00000070",
          color: "#FFFFFF",
        }),
      }}
    />
  );
};

export default FeesCoverageModal;
