export const InitData = [];

export const ListOfColumns = [
  "name",
  "emailId",
  "role",
  "phone",
  "employmentStatus",
];

export const ListOfFilters = {
  Role: [
    {
      title: "Designer",
      value: "DESIGNER",
    },
    {
      title: "Driver",
      value: "DRIVER",
    },
    {
      title: "Sales",
      value: "SALES",
    },
    {
      title: "Manager",
      value: "MANAGER",
    },
  ],
  Status: [
    {
      title: "Full Time",
      value: "FULLTIME",
    },
    {
      title: "Part Time",
      value: "PARTTIME",
    },
    {
      title: "Seasonal",
      value: "SEASONAL",
    },
    {
      title: "Terminated",
      value: "TERMINATED",
    },
  ],
};

export const ListOfSortOptions = [
  { key: "Name: Ascending", label: "Name: Ascending" },
  { key: "Name: Descending", label: "Name: Descending" },
  { key: "Role: Ascending", label: "Role: Ascending" },
  { key: "Role: Descending", label: "Role: Descending" },
];

export const StatusOptions = [
  { label: "Full Time", value: "FULLTIME" },
  { label: "Part Time", value: "PARTTIME" },
  { label: "Seasonal", value: "SEASONAL" },
  { label: "Terminated", value: "TERMINATED" },
];

export const PermissionsforCreateUser = {
  ADMIN: true,
  MANAGER: true,
  SALES: false,
  DESIGNER: false,
  DRIVER: false,
};
