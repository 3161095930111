import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";
import { selectState as selectGlobalState } from "../../../../../ongoing/global-data/selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectPageStatus = createSelector(
  selectState,
  ({ status }) => status
);

export const selectIsPageLoading = createSelector(
  selectPageStatus,
  (status) => status === "progress"
);

export const selectIsPageFailed = createSelector(
  selectPageStatus,
  (status) => status === "fail"
);

export const selectIsPageLoaded = createSelector(
  selectPageStatus,
  (status) => status === "done"
);

export const selectData = createSelector(selectState, ({ data }) => data);

export const selectProductExtraFiters = createSelector(
  selectData,
  ({ productExtraFilters }) => productExtraFilters
);

export const selectFlowerTypes = createSelector(
  selectProductExtraFiters,
  ({ flowerTypes }) => flowerTypes
);

export const selectProductColors = createSelector(
  selectProductExtraFiters,
  ({ productColors }) => productColors
);

export const selectAPIResponse = createSelector(selectState, ({ api }) => api);

export const selectAPIError = createSelector(selectState, ({ error }) => error);

export const selectActions = createSelector(
  selectState,
  ({ actions }) => actions
);

export const selectAllCollections = createSelector(
  selectAPIResponse,
  ({ collections = {} }) => Object.values(collections)
);

export const selectAllAddons = createSelector(
  selectAPIResponse,
  ({ addons = {} }) => Object.values(addons)
);

export const selectSwitches = createSelector(
  selectState,
  ({ switches }) => switches
);

export const selectEnableSharedCatalogs = createSelector(
  selectSwitches,
  ({ sharedCatalogs }) => sharedCatalogs
);

export const selectEnableProductActivity = createSelector(
  selectSwitches,
  ({ productActivity }) => productActivity
);

export const selectEnableFulfillmentDates = createSelector(
  selectSwitches,
  ({ enableFulfillmentDates }) => enableFulfillmentDates
);

export const selectSharedCatalogs = createSelector(
  selectAPIResponse,
  ({ sharedCatalogs }) => sharedCatalogs || []
);

export const selectSharedCatalogIds = createSelector(
  selectAPIResponse,
  ({ sharedCatalogs = [] }) =>
    sharedCatalogs.filter((c) => c.groupId).map(({ groupId }) => groupId) || []
);

export const selectSharedCatalogTitles = createSelector(
  selectAPIResponse,
  ({ sharedCatalogs }) =>
    sharedCatalogs
      .filter((c) => c.groupId)
      .reduce((accum, { groupId, name }) => {
        accum[groupId] = name;
        return accum;
      }, {})
);

export const selectScreenTitles = createSelector(
  selectState,
  ({ screenTitles = {} }) => screenTitles
);

export const selectScreenTitle = (name) =>
  createSelector(
    selectState,
    selectSharedCatalogTitles,
    ({ screenTitles = {} }, sharedCatalogTitles = {}) => {
      return sharedCatalogTitles[name] || screenTitles[name];
    }
  );

export const selectScreen = createSelector(
  selectState,
  ({ screen = {} }) => screen
);

export const selectScreenParam = (name) =>
  createSelector(selectScreen, ({ params = {} }) => params[name]);

export const selectShopCode = createSelector(
  selectState,
  selectGlobalState,
  ({ shopCode }, { shopCode: dashboardSelectedShopCode }) =>
    shopCode || dashboardSelectedShopCode
);

export const selectCatalogSettings = createSelector(
  selectData,
  ({ catalogSettings }) => catalogSettings
);

export const selectProductLookup = createSelector(
  selectAPIResponse,
  ({ local = {}, global = {}, addons = {} }) => ({
    ...local,
    ...global,
    ...addons,
  })
);

export const selectProductLookupById = (productId) =>
  createSelector(
    selectProductLookup,
    (productLookup) => productLookup[productId] || {}
  );

export const selectCollectionLookup = createSelector(
  selectAPIResponse,
  ({ collections = {} }) => collections
);

export const selectCollectionLookupByHandle = (handle) =>
  createSelector(
    selectCollectionLookup,
    (collectionLookup) => collectionLookup[handle]
  );

export const selectAllowNewProducts = createSelector(
  selectCatalogSettings,
  ({ allowNewProducts = {} }) => allowNewProducts
);

export const selectIsRulesFetched = createSelector(
  selectCatalogSettings,
  ({ isRulesFetched = false }) => isRulesFetched
);
export const selectCatalogPriceMinimum = createSelector(
  selectCatalogSettings,
  ({ catalogPriceMinimum = {} }) => catalogPriceMinimum
);

export const selectScheduledPriceAdjustment = createSelector(
  selectCatalogSettings,
  ({ scheduledPriceAdjustment = [] }) => scheduledPriceAdjustment
);

export const selectCatalogPriceMinimumValue = createSelector(
  selectCatalogPriceMinimum,
  ({ price = "0.00" }) => price
);

const selectPriceMinimumActions = createSelector(
  selectActions,
  ({ catalogPriceMinimum = {} }) => catalogPriceMinimum
);

export const selectPriceMinimumBulkActionType = createSelector(
  selectPriceMinimumActions,
  ({ bulkActionType }) => bulkActionType
);

export const selectPriceMinimumProductsSelected = createSelector(
  selectPriceMinimumActions,
  ({ selectedProducts = [] }) => selectedProducts
);

export const selectPriceMinimumCurrentLimit = createSelector(
  selectPriceMinimumActions,
  ({ productsLimit = 0 }) => productsLimit
);

export const selectPriceMinimumSearchText = createSelector(
  selectPriceMinimumActions,
  ({ searchText }) => searchText || ""
);

export const selectPriceMinimumFilter = createSelector(
  selectPriceMinimumActions,
  ({ filters }) => filters
);

export const selectAllowSyndicate = createSelector(
  selectState,
  ({ allowSyndicate }) => allowSyndicate
);

export const selectIsCatalogManager = createSelector(
  selectState,
  ({ catalogManager }) => catalogManager
);
