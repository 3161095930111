import React from "react";
import { View, Text, Platform } from "react-native";

import {
  FormField,
  FormFieldCheckBox,
  FormFieldPicker,
} from "components/elements/forms";
import { getCardType } from "library/utils/payment-options";
import { ToasterHandler } from "components/elements";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { useFormikContext } from "formik";
import { fonts, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";
import upperCase from "lodash/upperCase";
import get from "lodash/get";
import states from "static/data/states.json";

const PaymentInput = ({ data, path }) => {
  const { formFieldType, ...inputProps } = data;
  return formFieldType === "Text" ? (
    <TextField {...inputProps} path={path} />
  ) : formFieldType === "BillingAddress" ? (
    <AddressField {...inputProps} path={path} />
  ) : null;
};

const TextField = ({ name, title, formFieldProps, path }) => {
  const { isMobile } = React.useContext(DeviceContext);
  const {
    values: {
      paymentInfo: { tokenId = "" },
    },
    setFieldValue,
  } = useFormikContext();
  const isEditDisable = tokenId !== "";
  formFieldProps = { ...formFieldProps, editable: !isEditDisable };

  const handleOnBlur = (text) => {
    name === "cardNumber" &&
      text !== "" &&
      setFieldValue("paymentInfo.cardType", upperCase(getCardType(text)));
  };

  return (
    <>
      <View
        style={tw(
          `flex flex-row justify-start items-center pb-2 items-${
            Platform.OS !== "web" ? "center" : "baseline"
          }`
        )}
      >
        <Text
          style={{
            height: 40,
            width: isMobile ? "25%" : "30%",
            ...fonts.heading5,
          }}
        >
          {title}
        </Text>
        <View
          style={[
            tw("flex flex-row justify-start items-center pb-2 flex-wrap"),
            { width: isMobile ? "75%" : "70%" },
          ]}
        >
          <FormField
            name={`${path}.${name}`}
            {...formFieldProps}
            inputContainerStyle={{
              backgroundColor: formFieldProps.editable
                ? "auto"
                : backgroundColors.greyColor,
              ...formFieldProps.inputContainerStyle,
            }}
            handleOnBlur={handleOnBlur}
          />
        </View>
      </View>
    </>
  );
};

const AddressField = ({ name, title, formInputFields, formCheckbox, path }) => {
  const { isMobile } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const { values, setValues } = useFormikContext();
  const customerId = values.customerId;
  const address = values.address;
  const isEditDisable =
    values.paymentInfo.sameAsMailingAddress ||
    values.paymentInfo.tokenId !== "";

  const onChangehandler = () => {
    customerId !== "" && address.addressId !== ""
      ? setValues({
          ...values,
          paymentInfo: {
            ...values.paymentInfo,
            sameAsMailingAddress: !isEditDisable,
            billingAddress: {
              ...values.billingAddress,
              addressLine1: !isEditDisable ? address.addressLine1 : "",
              addressLine2: !isEditDisable ? address.addressLine2 : "",
              country: !isEditDisable ? address.country : "",
              city: !isEditDisable ? address.city : "",
              state: !isEditDisable ? address.state : "",
              zipcode: !isEditDisable ? address.zipcode : "",
            },
          },
        })
      : ToasterHandler(
          "error",
          Localise(messages, "Please save an address in the customer info")
        );
  };

  return (
    <View
      style={tw(
        `flex flex-row justify-start items-${
          Platform.OS !== "web" ? "center" : "baseline"
        } pb-2`
      )}
    >
      <Text
        style={{
          height: 40,
          width: isMobile ? "25%" : "30%",
          ...fonts.heading5,
        }}
      >
        {title}
      </Text>
      <View
        style={[
          tw("flex items-start justify-center"),
          { width: isMobile ? "75%" : "70%" },
        ]}
      >
        {values.paymentInfo.tokenId === "" && (
          <FormFieldCheckBox
            name={`${path}.${formCheckbox.name}`}
            containerStyle={{}}
            iconRight={false}
            size={20}
            title={formCheckbox.title}
            onChangehandler={onChangehandler}
          />
        )}
        <View
          style={tw(
            `flex flex-row justify-start items-${
              Platform.OS !== "web" ? "center" : "baseline"
            } pb-2 flex-wrap`
          )}
        >
          <View style={[tw("flex flex-row flex-wrap"), { width: "100%" }]}>
            {formInputFields.map(({ name: field, formFieldProps }, index) => {
              const isPicker = field === "country" || field === "state";
              formFieldProps = { ...formFieldProps, editable: !isEditDisable };
              const width = isMobile
                ? {
                    addressLine1: "100%",
                    addressLine2: "55%",
                    country: "45%",
                    city: "50%",
                    state: "50%",
                    zipcode: "50%",
                  }
                : {
                    addressLine1: "60%",
                    addressLine2: "40%",
                    country: "40%",
                    city: "25%",
                    state: "35%",
                    zipcode: "50%",
                  };
              if (field === "state") {
                const country = get(
                  values,
                  "paymentInfo.billingAddress.country",
                  ""
                );
                formFieldProps.options = states[country] || [];
              }
              return !isPicker ? (
                <FormField
                  name={`${path}.${name}.${field}`}
                  containerStyle={{
                    width: width[field],
                  }}
                  inputContainerStyle={{
                    backgroundColor: formFieldProps.editable
                      ? "auto"
                      : backgroundColors.greyColor,
                  }}
                  {...formFieldProps}
                  key={index}
                />
              ) : (
                <FormFieldPicker
                  name={`${path}.${name}.${field}`}
                  containerStyle={{ width: width[field] }}
                  placeholder={formFieldProps.placeholder}
                  data={formFieldProps.options}
                  disabledFieldTouch={true}
                  disabled={isEditDisable}
                />
              );
            })}
          </View>
        </View>
      </View>
    </View>
  );
};

export default PaymentInput;
