import UserAuthStorage from "library/storage/userAuth";
import Environment from "library/utils/environment";
import Cookies from "js-cookie";

export const saveAuthToken = () => {
  const accessToken = Cookies.get("accessToken");
  const refreshToken = Cookies.get("refreshToken");

  if (accessToken && refreshToken) {
    const publicUiDomain = Environment.get(
      "PUBLIC_UI_DOMAIN",
      "mercury-os-dev1.gcp.ftdi.com"
    );

    let domain = "";
    if (publicUiDomain.includes(".ftdi.com")) {
      domain = ".ftdi.com";
    } else if (publicUiDomain.includes(".ftd.com")) {
      domain = ".ftd.com";
    } else {
      domain = publicUiDomain;
    }

    UserAuthStorage.setAuthToken({
      accessToken,
      refreshToken,
      keepMeSignedIn: false,
    });

    // clear cookies once saved
    Cookies.remove("accessToken", { path: "/", domain: `${domain}` });
    Cookies.remove("refreshToken", {
      path: "/",
      domain: `${domain}`,
    });
  }
};
