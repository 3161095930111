import React, { useEffect } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { useFormikContext } from "formik";
import get from "lodash/get";
import {
  FormField,
  FormFieldCheckBox,
  FormFieldPicker,
  FormFieldAutoComplete,
  FormFieldSwitch,
} from "components/elements/forms";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { fonts, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";
import useStateIfMounted from "library/utils/useStateIfMounted";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import toUpper from "lodash/toUpper";
import union from "lodash/union";
import { Entitlements } from "library/utils/entitlements";
import Environment from "library/utils/environment";
import UserProfileStorage from "library/storage/userProfile";
import { phoneNumberFormatter } from "library/utils/formatter";
import isEmpty from "lodash/isEmpty";

const ProfileDetailsInput = ({ data }) => {
  const { formFieldType, ...inputProps } = data;
  return formFieldType === "Text" ? (
    <TextField {...inputProps} />
  ) : formFieldType === "Picker" ? (
    <PickerField {...inputProps} />
  ) : formFieldType === "ShopPicker" ? (
    <ShopPickerField {...inputProps} />
  ) : formFieldType === "toggle" ? (
    <ToggleField {...inputProps} />
  ) : formFieldType === "Label" ? (
    <LabelField {...inputProps} />
  ) : formFieldType === "StaffRoles" ? (
    <StaffRoles {...inputProps} />
  ) : formFieldType === "Permissions" ? (
    <PermissionsView {...inputProps} />
  ) : formFieldType === "newPassword" ? (
    <PasswordResetField {...inputProps} />
  ) : null;
};

const TextField = ({ name, title, formFieldProps }) => {
  const { isMobile } = React.useContext(DeviceContext);

  return (
    <>
      <View style={[tw(`flex flex-row justify-start items-center pb-2`)]}>
        <Text
          style={{
            width: isMobile ? "25%" : "25%",
            ...fonts.heading5,
          }}
        >
          {title}
        </Text>
        <View
          style={[
            tw("flex flex-row justify-start flex-wrap"),
            { width: isMobile ? "75%" : "75%" },
          ]}
        >
          <FormField
            name={name}
            {...formFieldProps}
            containerStyle={{
              width: isMobile ? "100%" : "100%",
              marginRight: 20,
            }}
            inputContainerStyle={{
              backgroundColor: formFieldProps.editable
                ? "auto"
                : backgroundColors.greyColor,
              ...formFieldProps.inputContainerStyle,
            }}
            transformText={(text = "") => {
              return name === "phone" ? phoneNumberFormatter(text) : text;
            }}
          />
        </View>
      </View>
    </>
  );
};

const PasswordResetField = ({ name, title, formFieldProps }) => {
  const { values } = useFormikContext();
  const { isMobile } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const [isPasswordSecure, setIsPasswordSecure] = useStateIfMounted(true);
  const option = get(values, "passwordOptions", "");

  return option !== "" ? (
    <View style={[tw(`flex flex-row justify-start items-center pb-2`)]}>
      <Text
        style={{
          width: "25%",
          ...fonts.heading5,
        }}
      >
        {title}
      </Text>
      <View
        style={[tw("flex flex-row justify-start flex-wrap"), { width: "75%" }]}
      >
        {option === "MANUALLY_RESET" ? (
          <>
            <FormField
              name={name}
              {...formFieldProps}
              containerStyle={{
                width: isMobile ? "100%" : "100%",
                marginRight: 20,
              }}
              inputContainerStyle={{
                backgroundColor: formFieldProps.editable
                  ? "auto"
                  : backgroundColors.greyColor,
                height: 50,
                ...formFieldProps.inputContainerStyle,
              }}
              textContentType="password"
              isUpdateOnChange={true}
              iconPosition={false}
              secureTextEntry={isPasswordSecure}
              iconType="ionicon"
              iconName={
                !isEmpty(values.newPassword)
                  ? isPasswordSecure
                    ? "eye"
                    : "eye-off"
                  : ""
              }
              onIconPress={() => {
                setIsPasswordSecure(!isPasswordSecure);
              }}
            />
            <Text style={tw("my-1 pl-1 italic")}>
              {Localise(
                messages,
                "Your password must be a min 8 and max 32 characters without spaces, and include 1 uppercase, 1 number, and 1 special character. The password cannot match your three previous passwords."
              )}
            </Text>
          </>
        ) : (
          <>
            <TouchableOpacity
              style={{
                borderColor: "#224056",
                borderWidth: 1,
                paddingVertical: 10,
                paddingHorizontal: 20,
                marginLeft: 7,
              }}
              onPress={formFieldProps.handler}
            >
              <Text style={{ color: "#224056", ...fonts.heading5 }}>
                {Localise(messages, "Send Password Reset Email")}
              </Text>
            </TouchableOpacity>
          </>
        )}
      </View>
    </View>
  ) : null;
};

const PickerField = ({ name, title, formFieldProps }) => {
  const { isMobile } = React.useContext(DeviceContext);

  return formFieldProps.options.length > 0 ? (
    <>
      <View style={[tw(`flex flex-row justify-start items-center pb-2`)]}>
        <Text
          style={{
            width: isMobile ? "25%" : "25%",
            ...fonts.heading5,
          }}
        >
          {title}
        </Text>
        <View
          style={[
            tw("flex flex-row justify-start flex-wrap"),
            { width: isMobile ? "75%" : "75%" },
          ]}
        >
          <FormFieldPicker
            name={name}
            containerStyle={{ width: "100%" }}
            placeholder={formFieldProps.placeholder}
            data={formFieldProps.options}
            disabledFieldTouch={true}
          />
        </View>
      </View>
    </>
  ) : null;
};

const ShopPickerField = ({ name, title, formFieldProps }) => {
  const { isMobile } = React.useContext(DeviceContext);
  const [memberQuery, setMemberQuery] = useStateIfMounted("");
  const { permissions } = React.useContext(AppSettingsContext);
  const shopNames = UserProfileStorage.getAllShopNames();

  const filteredShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.STAFF_MANAGEMENT in permissions[memberCode])
      filteredShopCodes.push(memberCode);
  });

  let shopsList = filteredShopCodes.map((code) => {
    return { label: `${code} ${shopNames[code] || ""}`, value: code };
  });

  if (shopsList.length > 1) {
    shopsList = [
      { label: formFieldProps.allLabel, value: filteredShopCodes },
      ...shopsList,
    ];
  } else {
    shopsList = [
      `${filteredShopCodes[0]} ${shopNames[filteredShopCodes[0]] || ""}`,
    ];
  }

  const { values, setValues } = useFormikContext();

  const finalValues = cloneDeep(values);
  const selectedCount = values.shopCodes.length;

  useEffect(() => {
    setMemberQuery("");
  }, [values.shopCodes]);

  return (
    <>
      <View
        style={[
          tw(`flex flex-row justify-start items-center pb-2`),
          { zIndex: 10 },
        ]}
      >
        <Text
          style={{
            width: isMobile ? "25%" : "25%",
            ...fonts.heading5,
          }}
        >
          {title}
        </Text>
        <View
          style={[
            tw("flex flex-row justify-start flex-wrap"),
            { width: isMobile ? "75%" : "75%" },
          ]}
        >
          {filteredShopCodes.length > 1 ? (
            <FormFieldAutoComplete
              data={shopsList.filter((code) => {
                return code.label
                  .toLowerCase()
                  .includes(memberQuery.toLowerCase());
              })}
              initialDataLength={shopsList.length}
              showOnFocus={true}
              setFocusBack={true}
              onChangeText={(text) => {
                const arr = text.split(",");
                setMemberQuery(arr[arr.length - 1]);
              }}
              placeholder={
                selectedCount
                  ? `${selectedCount} ${formFieldProps.selectedLabel}`
                  : formFieldProps.placeholder
              }
              listDisplayValues={["label"]}
              outerContainerStyle={{
                zIndex: 1,
                width: "100%",
              }}
              name={name}
              isMultiSelect={true}
              onSelect={(selectedValue) => {
                const { shopCodes } = finalValues;
                if (selectedValue.label === "All") {
                  if (shopCodes.length === selectedValue.value.length) {
                    set(finalValues, name, []);
                  } else {
                    set(finalValues, name, [...selectedValue.value]);
                  }
                } else {
                  const index = shopCodes.indexOf(selectedValue.value);
                  const isSelected = index >= 0;
                  isSelected
                    ? shopCodes.splice(index, 1)
                    : shopCodes.push(selectedValue.value);
                }
                setValues(finalValues);
              }}
            />
          ) : (
            <FormFieldCheckBox
              iconRight={false}
              name={name}
              size={20}
              containerStyle={{ paddingTop: 10 }}
              title={shopsList}
              isGroup={true}
            />
          )}
        </View>
      </View>
    </>
  );
};

const ToggleField = ({ name, title, formFieldProps }) => {
  const { values, setFieldValue } = useFormikContext();
  const isChecked = get(values, name, false);

  return (
    <>
      <View style={[tw(`flex flex-row justify-start items-center pb-4`)]}>
        <Text
          style={{
            width: "25%",
            ...fonts.heading5,
          }}
        >
          {title}
        </Text>
        <View
          style={[
            tw("flex flex-row justify-start flex-wrap items-center "),
            { width: "75%" },
          ]}
        >
          <FormFieldSwitch
            name={name}
            value={isChecked}
            onValueChange={() => setFieldValue(name, !isChecked)}
          />
          <Text style={{ ...fonts.default, paddingLeft: 10 }}>
            {isChecked
              ? formFieldProps.activeLabel
              : formFieldProps.inactiveLabel}
          </Text>
        </View>
      </View>
    </>
  );
};

const LabelField = ({ title, formFieldProps }) => {
  return (
    <View style={formFieldProps.viewStyle}>
      <Text style={formFieldProps.textStyle}>{title}</Text>
    </View>
  );
};

const StaffRoles = ({ name, title, formFieldProps }) => {
  const { isMobile } = React.useContext(DeviceContext);
  const { values, setFieldValue } = useFormikContext();

  const roleTitles = [];

  formFieldProps.options.map(({ label, value }) => {
    roleTitles.push(label);
  });

  const finalValues = cloneDeep(values);

  return (
    <>
      <View style={[tw(`flex flex-row justify-start pb-2`)]}>
        <Text
          style={{
            ...fonts.heading5,
            width: isMobile ? "25%" : "25%",
            paddingTop: 20,
          }}
        >
          {title}
        </Text>
        <View
          style={[
            tw("flex flex-row justify-start flex-wrap"),
            { width: isMobile ? "75%" : "75%" },
          ]}
        >
          <FormFieldCheckBox
            name={name}
            size={20}
            title={roleTitles}
            inputContainerStyle={{
              width: isMobile ? "100%" : "45%",
              paddingVertical: 10,
            }}
            iconRight={false}
            isGroup={true}
            onChangehandler={(selectedRole) => {
              let { roles } = finalValues;

              const index = roles.indexOf(selectedRole);
              const isSelected = index >= 0;

              if (isSelected) {
                roles.splice(index, 1);
              } else {
                if (selectedRole === "Manager") roles = roleTitles;
                else roles.push(selectedRole);
              }

              setFieldValue(name, roles);
            }}
          />
        </View>
      </View>
    </>
  );
};

const PermissionsView = ({ title, formFieldProps }) => {
  const { isMobile } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);

  const { values } = useFormikContext();
  const { roles } = values;

  const isGiftCardsEnabled = Environment.get("GIFT_CARDS_ENABLED", false);

  let allSelectedPermissions = [];

  roles.map((role) => {
    let selectedRolePermissions = formFieldProps.options.filter(
      (per) => per.role === toUpper(role)
    );
    selectedRolePermissions = get(
      formFieldProps.options.filter((per) => per.role === toUpper(role)),
      "0.permissions",
      []
    );

    allSelectedPermissions = union(
      allSelectedPermissions,
      selectedRolePermissions
    );

    if (!isGiftCardsEnabled)
      allSelectedPermissions = allSelectedPermissions.filter(
        (each) => each !== "Gift Cards"
      );
  });

  return (
    <>
      <View style={[tw("flex flex-row justify-start pb-2")]}>
        <Text
          style={{
            width: isMobile ? "25%" : "25%",
            paddingTop: 5,
            ...fonts.heading5,
          }}
        >
          {title}
        </Text>
        <View
          style={[
            tw(`flex flex-${isMobile ? "col" : "row"} justify-start flex-wrap`),
            {
              width: isMobile ? "75%" : "75%",
              //height: isMobile ? 100 : 150,
            },
          ]}
        >
          {allSelectedPermissions.map((permission, index) => {
            return (
              <Text
                key={index}
                style={{
                  ...formFieldProps.textStyle,
                  width: isMobile ? "100%" : "41%",
                }}
              >
                {Localise(messages, permission)}
              </Text>
            );
          })}
        </View>
      </View>
    </>
  );
};

export default ProfileDetailsInput;
