export const getSubscriptionItemMenuOptions = (
  Localise,
  messages,
  status,
  activePause
) => {
  const allOptions = [
    {
      label: Localise(messages, "Edit"),
      key: "Modify",
    },
    {
      label: Localise(messages, "Copy"),
      key: "Copy",
    },
    {
      label: Localise(messages, "Cancel"),
      key: "Cancel",
    },
    {
      label: Localise(messages, "Pause"),
      key: "Pause",
    },
    {
      label: Localise(messages, "Resume"),
      key: "Resume",
    },
  ];

  let options = [];

  if (status === "ACTIVE" || status === "PAUSED") {
    options = allOptions.filter(
      (option) => option.key !== "Resume" && option.key !== "Pause"
    );
    if (activePause) {
      options.push(allOptions.find((option) => option.key === "Resume"));
    }
    options.push(allOptions.find((option) => option.key === "Pause")); // Always add Pause at the end
  } else if (status === "INACTIVE") {
    options = allOptions.filter((option) => option.key === "Copy");
  }

  return options;
};

// Helper function to get the suffix for the day (1st, 2nd, 3rd, etc.)
export const getDaySuffix = (day) => {
  if (day > 3 && day < 21) return "th";
  const suffixes = { 1: "st", 2: "nd", 3: "rd" };
  return suffixes[day % 10] || "th";
};