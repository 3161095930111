import * as Yup from "yup";

export const InitialValues = {
  showGroupsLoading: "",
  showSyncLoading: "",
  inputMemberCode: "",
  apiMemberCode: "",
  floristAccountRes: {},
  profileRes: {},
  shopGroupRes: {},
  oldUserDetails: {},
  showAllShopsExportLoading: false,
  showSingleShopExportLoading: false,
  clientIp: "",
};

export const ErrorMessages = {
  groupNameRequired: "Please enter a group name",
  invalidGroupName: "Invalid group name",
  adminFirstNameRequired: "Please enter admin first name",
  adminLastNameRequired: "Please enter admin last name",
  adminEmailRequired: "Please enter admin email address",
  memberCodeNotExists:
    "Member Code does not exist. Please create the account in Mercury Cloud first then add and edit here.",
  errorWhileFetchingDetails:
    "Unable to fetch account details for given Member Code from Mercury Cloud.",
  accountStatusInactive:
    "Account is inactive in Mercury Cloud. Please change the account status in Mercury Cloud first then add and edit here.",
  invalidIP: "Please enter valid IP",
};

// Regex for a digit from 0 to 255 and
// followed by a dot, repeat 4 times.
// this is the regex to validate an IP address.
const regexForIP = new RegExp(
  "^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
);

Yup.addMethod(Yup.string, "isValidIP", function (errorMessage) {
  return this.test(`is-valid-ip`, errorMessage, function (value = "") {
    if (!value) return true;
    const ips = value.split("\n").join(",").split(",,").join(",").split(",");
    const hasInvalidIP = ips
      .map((ip) => regexForIP.test(ip.trim()))
      .some((isValid) => !isValid);
    return !hasInvalidIP;
  });
});

export const ValidationSchema = Yup.object().shape({
  profileRes: Yup.object().shape({
    accountName: Yup.string()
      .label("accountName")
      .required(ErrorMessages.groupNameRequired)
      .matches(
        new RegExp("^[a-zA-Z0-9_# &-]*$"), // we are allowing space, ampersand (&), pound (#), underscore (_) and hyphen (-) along with alpha numeric characters for group name.
        ErrorMessages.invalidGroupName
      ),
    firstName: Yup.string()
      .label("firstName")
      .required(ErrorMessages.adminFirstNameRequired),
    lastName: Yup.string()
      .label("lastName")
      .required(ErrorMessages.adminLastNameRequired),
    emailId: Yup.string()
      .label("emailId")
      .email("Enter a valid email")
      .required(ErrorMessages.adminEmailRequired),
  }),
  clientIp: Yup.string().isValidIP(ErrorMessages.invalidIP),
});

export const PermissionsForEditAccess = {
  FTD_ADMIN: true,
  FTD_POWER_USER: true,
  FTD_VIEW_ONLY: false,
  FTD_CSR: false,
};

export const PermissionsForShopAccess = {
  FTD_ADMIN: true,
  FTD_POWER_USER: true,
  FTD_VIEW_ONLY: false,
  FTD_CSR: true,
};

export const PermissionsForShopsExport = {
  FTD_ADMIN: true,
  FTD_POWER_USER: false,
  FTD_VIEW_ONLY: false,
  FTD_CSR: false,
};

export const PermissionsForDataMigration = {
  FTD_ADMIN: true,
  FTD_POWER_USER: true,
  FTD_VIEW_ONLY: true,
  FTD_CSR: false,
};
