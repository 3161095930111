import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { Platform } from "react-native";
import isNil from "lodash/isNil";
import isObject from "lodash/isObject";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { AutoComplete } from "components/elements";
import { formatData } from "library/utils/createOrder";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { colors } from "styles/theme";

const FormFieldAutoComplete = ({
  label,
  loading,
  data: initialData,
  initialDataLength,
  onChangeText,
  listDisplayValues,
  name = "",
  path,
  delimiter,
  clearTextOnBackTab = false,
  updateOnBlur = false,
  showErrorMessage = "",
  handleOnBlur,
  focusTo,
  setFocusBack = false,
  isMultiSelect,
  textOverFlowAsEllipsisLength = 0,
  onSelect,
  isFormatData = false,
  onClear,
  placeholder,
  popperPlacement,
  showErrorOnTouched = false,
  showOnSelect = false,
  submitCount = 0,
  ...otherProps
}) => {
  const {
    initialValues,
    setFieldTouched,
    errors,
    touched,
    values,
    setFieldValue,
  } = useFormikContext();
  const { messages, Localise } = React.useContext(I18NContext);

  // Avoiding scroll for Android in Autocomplete.
  const dataLimit = name === "floristInfo" ? 10 : 5;
  const data =
    Platform.OS === "android" ? initialData?.slice(0, dataLimit) : initialData;

  const key =
    (path && name && `${path}.${name}`) || (path && `${path}`) || name;
  const touchedVal = get(touched, key);
  let fieldVal = get(values, key);
  const finalValues = cloneDeep(values);
  let error =
    (!isEmpty(showErrorMessage) && showErrorMessage) || get(errors, key);
  const [value, setValue] = useStateIfMounted(get(finalValues, key, ""));

  if (isMultiSelect || showErrorOnTouched || submitCount > 0) {
    error = (touchedVal || submitCount > 0) && get(errors, key);
  }

  if (isFormatData && typeof fieldVal === "object") {
    fieldVal = formatData(fieldVal, listDisplayValues, delimiter);
  }

  useEffect(() => {
    if (isEqual(initialValues, values) && isEmpty(touched)) {
      if (isMultiSelect) {
        setValue("");
      } else {
        setValue(fieldVal);
      }
    }
  }, [initialValues, values, touched]);

  useEffect(() => {
    if (value !== fieldVal && !isNil(name)) {
      if (isMultiSelect) {
        setValue("");
      } else {
        setValue(fieldVal);
      }
    }
  }, [fieldVal]);

  useEffect(() => {
    //clear form value on initial field clear
    value === "" && onClear && onClear();
  }, [value]);

  return (
    <>
      <AutoComplete
        label={Localise(messages, label)}
        placeholder={Localise(messages, placeholder)}
        placeholderTextColor={colors.medium}
        name={name}
        loading={loading}
        onBlur={() => {
          setFieldTouched(key);
          updateOnBlur && setFieldValue(key, value);
          handleOnBlur && handleOnBlur(value);
          if (value && focusTo && focusTo.current && Platform.OS === "web") {
            focusTo.current.focus();
          }
        }}
        onChangeText={(val) => {
          setValue(val);
          onChangeText(val);
        }}
        value={value || ""}
        data={data}
        initialDataLength={initialDataLength}
        isMultiSelect={isMultiSelect}
        selectedValues={get(finalValues, key, [])}
        delimiter={delimiter}
        clearTextOnBackTab={clearTextOnBackTab}
        onSelect={(val) => {
          onSelect && onSelect(val, setValue);
          if (val && focusTo && focusTo.current && Platform.OS === "web") {
            (!document.activeElement.dataset.testid ||
              document.activeElement.dataset.testid === "addressLine1") &&
              focusTo.current.focus();
          }
        }}
        innerContainerStyle={{
          ...(error ? { borderColor: colors.error } : {}),
        }}
        onOptionChange={() => {}}
        listDisplayValues={listDisplayValues}
        error={(!isObject(error) && error) || ""}
        setFocusBack={setFocusBack}
        dataLimit={dataLimit}
        textOverFlowAsEllipsisLength={textOverFlowAsEllipsisLength}
        popperPlacement={popperPlacement}
        showOnSelect={showOnSelect}
        {...otherProps}
      />
    </>
  );
};

export default React.memo(FormFieldAutoComplete);
