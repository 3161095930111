/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import { View, ActivityIndicator, Linking } from "react-native";
import { CommonActions, useNavigation } from "@react-navigation/native";
import { Button, Text } from "react-native-elements";
import { backgroundColors, theme, fonts, colors } from "styles/theme";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import replace from "lodash/replace";
import get from "lodash/get";
import moment from "moment";
import {
  InitOrdersData,
  ListOfSubColumns,
  ListOfOrderStatus,
  ListOfSubActions,
  ListOfDriverMobileColumns,
} from "../delivery/routes-dashboard/config";
//import { getDateRangeFromFlexDates } from "library/utils/deliveryService";
import { processOrderResponse } from "library/utils/orderListing";

import useStateIfMounted from "library/utils/useStateIfMounted";
import OrderDetails from "components/views/drawer/order-details";
import {
  setRecordId,
  setRecordAction,
  setDconStatus,
} from "library/sagas/ongoing/current-orders/slice";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import { DeviceContext } from "library/contexts/appSettings";
import { request } from "library/utils/request";
import { DirectionsMap } from "components/elements";
import UserProfileStorage from "library/storage/userProfile";
import ListItem from "components/views/drawer/delivery/routes-dashboard/route-order";
import styles from "components/views/drawer/route-details/styles";
import { ListOfRouteActions } from "./config";
import {
  setCompleteRouteModal,
  getOrderForRoute,
} from "library/sagas/views/home/drawer/delivery/slice";
import {
  CompleteRouteModal,
  hasNonDeliveredOrdersInRoute,
} from "../delivery/routes-dashboard/CompleteRouteModal";
import { isAdhocStop } from "components/views/drawer/delivery/routes-dashboard/helper";
import {
  getOrdersInRoute,
  getOrderStopsDetails,
} from "components/views/drawer/delivery/routes-dashboard/upsert-route/helper";
import RouteAdhocStopDetails from "./route-adhoc-stop-details";

const ActionRow = ({
  actionHandler,
  routeData,
  phoneNumber = "",
  localPermissions,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { status = "", routeId, driver } = routeData || {};
  const { name: driverName = "" } = driver || { name: "", id: "" };
  let routeActions = get(ListOfRouteActions, status, []) || [];

  if (!localPermissions.editRoute) {
    routeActions = routeActions = routeActions.filter(
      (action) => action.key !== "EDIT"
    );
  }

  const dialCall = () => {
    let phone = replace(phoneNumber, "-", "");
    phone = "+1" + phone;
    Linking.canOpenURL(`tel:${phone}`)
      .then(() => {
        Linking.openURL(`tel:${phone}`);
      })
      .catch(() => console.log("Unable to open dialer app."));
  };

  return (
    <>
      {routeActions.map((eachAction, index) => {
        return (
          <Button
            key={index}
            title={Localise(messages, eachAction.label)}
            titleStyle={theme.Button.secondaryTitleStyle}
            buttonStyle={{
              ...theme.Button.secondaryButtonStyle,
              paddingVertical: 8,
              paddingHorizontal: 10,
            }}
            containerStyle={{ margin: 5, justifyContent: "center" }}
            disabled={
              !driverName
                ? ["INPROGRESS", "COMPLETED"].includes(eachAction.key)
                : false
            }
            onPress={() => {
              actionHandler({
                action: eachAction.key,
                routeId: routeId,
              });
            }}
          />
        );
      })}
      {phoneNumber !== "" && (
        <Button
          title={Localise(messages, "Call Shop")}
          titleStyle={theme.Button.secondaryTitleStyle}
          buttonStyle={{
            ...theme.Button.secondaryButtonStyle,
            paddingVertical: 8,
            paddingHorizontal: 10,
          }}
          containerStyle={{ margin: 5, justifyContent: "center" }}
          onPress={dialCall}
        />
      )}
    </>
  );
};

// eslint-disable-next-line react/display-name
const RouteDetails = React.memo(
  ({
    selectedRecordId, //routeId
    shopCode,
    currentOrdersInfo,
    triggerAction,
    showSignatureModal,
    getRoutes,
    localPermissions,
    ...userActions
  }) => {
    if (selectedRecordId === "") return;

    const dispatch = useDispatch();
    const navigation = useNavigation();

    const shopLocations = UserProfileStorage.getAllShopLocation();
    const { latitude = "", longitude = "" } = shopLocations[shopCode] || {};
    const shopPhoneNumbers = UserProfileStorage.getAllShopPhoneNumbers();
    const routeDeliveryDate = UserProfileStorage.getRouteDeliveryDate();
    const phoneNumber = shopPhoneNumbers[shopCode] || "";
    const { set1 = {} } = ListOfOrderStatus;
    const [ordersInRoute, setOrdersInRoute] = useStateIfMounted([]);
    const [menuAction, setMenuAction] = useStateIfMounted("");
    const [routeData, setRouteData] = useStateIfMounted({});

    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useStateIfMounted(false);
    const { recordId, recordAction } = currentOrdersInfo;
    const {
      setRecordId,
      setRecordAction,
      setCompleteRouteModal,
      setCurrentPage,
    } = userActions;
    const { isDesktop, isMobile } = React.useContext(DeviceContext);
    const isSmallScreen = !isDesktop;
    const { messages, Localise } = React.useContext(I18NContext);
    // const deliveryFlexDates = Environment.get(
    //   "DELIVERY_FLEX_DATES",
    //   "2024-03-29,2024-03-30,2024-03-31"
    // );

    useEffect(() => {
      if (selectedRecordId) {
        setRecordId("");
        getRouteData();
      }
    }, [selectedRecordId]);

    useEffect(() => {
      if (!recordId) {
        !isEmpty(routeData) &&
          routeData.routeId === selectedRecordId &&
          getData(routeData);
      }
    }, [recordId]);

    const onCompleteAction = React.useCallback((action) => {
      if (
        action.includes("delivery-exception") ||
        action.includes("delivery-confirmation")
      ) {
        setRecordId("");
        getRoutes();
        getRouteData();
        //reset delivery status prefill for form
        dispatch(setDconStatus("delivery-confirmation"));
      }
      setRecordAction(action);
    });

    const getRouteData = () => {
      setLoading(true);
      request("get-route-data", {
        routeId: selectedRecordId,
        shopCode: shopCode,
      })
        .then((res) => {
          const { route } = res;
          setRouteData(route);
          setOrdersInRoute([]);
          getData(route);
          setLoading(false);
        })
        .catch((err) => {
          console.log("error", err);
        });
    };

    const getData = (routeData) => {
      setLoading(true);
      //  const { startDate, endDate } =
      //    getDateRangeFromFlexDates(deliveryFlexDates);
      request("delivery-listing", {
        startDate: moment(routeDeliveryDate).format("YYYY-MM-DD"),
        endDate: moment(routeDeliveryDate).format("YYYY-MM-DD"),
        fetchStatuses: set1.status.join(","),
      })
        .then((res) => {
          const { memberCodes = [] } = routeData;
          const shopCodeFilter =
            res &&
            res.length &&
            res.filter(
              (ord) =>
                ord?.direction === "INBOUND" &&
                memberCodes?.includes(ord?.receivingMember?.memberCode)
            );
          const updatedResp = processOrderResponse(
            shopCodeFilter,
            InitOrdersData,
            "delivery-details"
          );
          const { orderCollection = {} } = updatedResp;

          const ordersResponse = orderCollection?.nonRouted?.orders || [];
          const ordersBelongsToRoute = getOrdersInRoute(
            selectedRecordId,
            routeData,
            ordersResponse
          );
          const ordersNeedFetching = ordersBelongsToRoute.filter(
            (ord) => ord.fetchOrder
          );

          if (ordersNeedFetching?.length > 0) {
            getOrderStopsDetails(
              ordersBelongsToRoute,
              ordersNeedFetching,
              (props) => {
                dispatch(getOrderForRoute({ ...props }));
              },
              (processedOrders) => {
                setOrdersInRoute([...processedOrders]);
                setLoading(false);
              }
            );
          } else {
            setOrdersInRoute(ordersBelongsToRoute);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    };

    const updateRouteStatus = (newStatus, routeId) => {
      request("update-route-status", {
        shopCode,
        newStatus,
        routeId,
      })
        .then((res) => {
          res && setRouteData(res);
          res && getData(res);
          triggerAction(`${newStatus}${routeId}`);
          setLoading(false);
        })
        .catch(() => {
          console.log("error");
        });
    };

    const openEditRoute = (params = {}) => {
      setCurrentPage("edit-route");
      if (routeData.autoRouteId) {
        UserProfileStorage.setAutoRouteId(routeData.autoRouteId);
      } else {
        UserProfileStorage.setAutoRouteId(undefined);
      }
      UserProfileStorage.setRouteShopList([...routeData.memberCodes]);
      navigation.dispatch(
        CommonActions.navigate({
          name: "edit-route",
          params: {
            routeId: routeData.routeId,
            memberCodes: routeData.memberCodes.join(","),
            accessByDriver: "true",
            driverDashboardShopCode: shopCode,
            ...params,
          },
        })
      );
    };

    const actionHandler = ({ action, routeId }) => {
      if (action === "COMPLETED") {
        if (hasNonDeliveredOrdersInRoute(routeData?.stops)) {
          setCompleteRouteModal({
            modalVisible: true,
            routeStops: routeData?.stops,
          });
          return;
        }
      } else if (action === "EDIT") {
        openEditRoute();
        return;
      }
      setLoading(true);
      updateRouteStatus(action, routeId);
    };

    const handleCompleteRoute = () => {
      updateRouteStatus("COMPLETED", selectedRecordId);
      setCompleteRouteModal({ modalVisible: false });
    };

    if (loading) {
      return (
        <View>
          <View
            style={{
              ...tw("px-3 py-3 flex flex-row items-center justify-between"),
              ...{ backgroundColor: backgroundColors.primary },
            }}
          >
            <View style={tw("flex flex-row items-center justify-start")}>
              <Text
                style={{
                  ...fonts.heading2,
                  ...tw("text-white"),
                }}
              >
                {Localise(messages, "Loading Route Details")}
              </Text>
              <ActivityIndicator
                style={{ marginLeft: 10 }}
                color={colors.activityIndicator}
              />
            </View>
          </View>
        </View>
      );
    }

    if (recordId) {
      const stopData =
        ordersInRoute.length > 0 &&
        ordersInRoute.find((item) => item.orderItemId === recordId);
      const {
        deliveryMethod = "",
        sourceMemberCode = "",
        stopType = "ORDER",
      } = stopData;

      return (
        <>
          {isAdhocStop(stopType) ? (
            <RouteAdhocStopDetails
              shopCode={shopCode}
              recordId={recordId}
              selectedRecordId={selectedRecordId}
              stopData={stopData}
              onClose={setRecordId}
              onAction={onCompleteAction}
              isSmallScreen={isSmallScreen}
            />
          ) : (
            <OrderDetails
              listingType={"delivery"}
              isSmallScreen={isSmallScreen}
              recordId={recordId}
              onComplete={setRecordId}
              onAction={onCompleteAction}
              actionsList={{}}
              deliveryMethod={deliveryMethod}
              sourceMemberCode={sourceMemberCode}
              subActionsList={ListOfSubActions}
              menuAction={menuAction}
              setMenuAction={setMenuAction}
              recordAction={recordAction}
              showDriverAction={true}
            />
          )}
        </>
      );
    }

    return (
      <View
        style={{
          backgroundColor: backgroundColors.sideCar,
          ...(showSignatureModal && { display: "none" }),
        }}
      >
        {!recordId && (
          <>
            <View
              style={[
                tw("px-3 py-3 flex flex-row items-center justify-between "),
                { backgroundColor: backgroundColors.primary },
              ]}
            >
              <Text style={{ ...fonts.heading2, ...tw("text-white") }}>
                {routeData && routeData.routeName}{" "}
                {Localise(messages, "Details")}
              </Text>
            </View>
            <View
              style={{
                backgroundColor: backgroundColors.secondary,
                padding: 10,
              }}
            >
              <View style={tw("flex flex-row flex-wrap mt-2 mb-4")}>
                <ActionRow
                  actionHandler={actionHandler}
                  routeData={routeData}
                  phoneNumber={phoneNumber}
                  localPermissions={localPermissions}
                />
              </View>
              {!isEmpty(routeData) && (
                <DirectionsMap
                  page={"driverDashboard"}
                  key={selectedRecordId}
                  mapKey={`${shopCode}:${moment(routeData.deliveryDate).format(
                    "YYYY-MM-DD"
                  )}`}
                  mapsContainerHeight={300}
                  routes={isEmpty(routeData) ? [] : [{ route: routeData }]}
                  ordersInRoute={cloneDeep(ordersInRoute)}
                  shopLocation={{
                    ...(routeData?.startLocation?.geoLocation?.latitude
                      ? routeData?.startLocation?.geoLocation
                      : !!latitude && { latitude, longitude }),
                  }}
                  shopLocations={[
                    ...(localPermissions.multiShopRoute
                      ? (routeData?.memberCodes?.length > 0 &&
                          routeData?.memberCodes?.map((shopCode) => {
                            const { latitude, longitude } =
                              shopLocations[shopCode] || {};
                            return { latitude, longitude };
                          })) ||
                        []
                      : !!latitude && !!longitude
                      ? [{ latitude, longitude }]
                      : []),
                  ]}
                  zoomLevel={8}
                  accordionWrap={false}
                  localPermissions={localPermissions}
                />
              )}
              {ordersInRoute.length > 0 && (
                <View style={{ padding: 15 }}>
                  <Text style={{ ...fonts.heading2, paddingBottom: 20 }}>
                    {Localise(messages, "Deliveries")}
                  </Text>
                  {ordersInRoute.map((order, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        index={index}
                        item={cloneDeep(order)}
                        itemKey={"orderItemId"}
                        listType={"routed"}
                        page={"driverDashboard"}
                        routeData={routeData}
                        isSmallScreen={isSmallScreen}
                        isMobile={isMobile}
                        setRecordId={setRecordId}
                        columnsList={ListOfDriverMobileColumns}
                        subColumnsList={ListOfSubColumns}
                        routeMoveAction={() => {}}
                        totalOrdersInRoute={ordersInRoute.length}
                        enableMoves={false}
                        styles={styles}
                      />
                    </React.Fragment>
                  ))}
                </View>
              )}
            </View>
          </>
        )}

        <CompleteRouteModal
          Localise={Localise}
          messages={messages}
          primaryhandler={handleCompleteRoute}
        />
      </View>
    );
  }
);

const mapStateToProps = (state = {}) => {
  return {
    currentOrdersInfo: state.mhq.ongoing.currentOrders,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRecordId,
      setRecordAction,
      setCompleteRouteModal,
      setCurrentPage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RouteDetails);
