import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text, View } from "react-native";

import I18NContext from "library/contexts/i18N";
import { fonts } from "styles/theme";
import tw from "tailwind-rn";

const SearchResults = ({ selectSearchText, setSearchText }) => {
  const dispatch = useDispatch();
  const search = useSelector(selectSearchText);
  const { Localise, messages } = React.useContext(I18NContext);

  return (
    search != "" && (
      <View
        style={[
          tw("flex flex-row items-center"),
          {
            paddingRight: 30,
            paddingVertical: 10,
          },
        ]}
      >
        <Text style={fonts.heading4}>
          {Localise(messages, "Search Results")}:
        </Text>
        <Text style={fonts.default}>{` "${search}" `} </Text>
        <Text
          style={fonts.link1}
          onPress={() => dispatch(setSearchText({ type: "search", value: "" }))}
        >
          {Localise(messages, "Clear")}
        </Text>
      </View>
    )
  );
};

export default SearchResults;
