import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScrollView, View, TouchableOpacity, Platform } from "react-native";
import { Text, Button, CheckBox } from "react-native-elements";
import { useIsFocused } from "@react-navigation/native";

import { useFormikContext } from "formik";
import get from "lodash/get";
import toLower from "lodash/toLower";

import {
  FormField,
  FormFieldSwitch,
  FormFieldPicker,
  FormFieldDatePicker,
  FormFieldAutoComplete,
} from "components/elements/forms";
import AddressVerificationModal from "components/views/drawer/create-order/address-verification-modal";
import { SaveCancelButtons, Currency } from "components/wrappers";

import { selectShopCode } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import {
  selectExceptionCities,
  selectExceptionZips,
} from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/selector";
import { fetchZipCodesForExceptions } from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/slice";
import I18NContext from "library/contexts/i18N";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";
import { verifyAddress } from "library/utils/createOrder";
import { DeviceContext } from "library/contexts/appSettings";

import { getExceptionsConfig, FormFieldProps } from "../ui-config";
import { getNewDatesTimesObj, daysOfWeek } from "./helper";
import AddressVerificationIndicator from "./address-indicator";
import styles from "./styles";

import { theme, fonts } from "styles/theme";
import countryList from "static/data/countries.json";
import stateList from "static/data/states.json";
import tw from "tailwind-rn";

let addressSuggestionsController;

function ExceptionInput({
  criteriaChangeHandler,
  ruleChangeHandler,
  deleteExceptionHandler,
  i,
}) {
  const shopCode = useSelector(selectShopCode);
  const exceptionCities = useSelector(selectExceptionCities);
  const exceptionZips = useSelector(selectExceptionZips);
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    values: exception,
    resetForm,
  } = useFormikContext();
  const { Localise, messages } = React.useContext(I18NContext);
  const [addressSuggestions, setAddressSuggestions] = useStateIfMounted([]);
  const [invalidAddress, setInvalidAddress] = useStateIfMounted();
  const [zipQuery, setZipQuery] = useStateIfMounted("");
  const [daysAndDatesSection, setDaysAndDatesSection] =
    useStateIfMounted(false);

  const currency = Currency(shopCode);

  const {
    status,
    criteria,
    rule,
    startDate,
    day = [],
    productType = "",
    cityZipInfo = { city: "" },
    addressInfo = {},
  } = exception;
  const {
    streetAddress: AVSAddressLine1 = "",
    country: AVSCountry = "",
    state: AVSState = "",
    cityZipInfo: { city: AVSCity = "", zips: AVSZip } = {},
    addressVerificationInfo: {
      isAvsPerformed = "",
      avsConfidence = "",
      isAvsSuggestedAddress = "",
    } = {},
  } = addressInfo || {};
  const isAvsVerified =
    isAvsPerformed === "Y" &&
    isAvsSuggestedAddress === "Y" &&
    avsConfidence === "HIGH";
  const { isDesktop, isMobile } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const zipCodes = exceptionZips[cityZipInfo.city] || [];
  const zIndex = 100;
  const postalCode = get(AVSZip, "0", "");

  const ExceptionsConfig = getExceptionsConfig(Localise, messages, productType);
  const showDaysCheckbox =
    productType === "dropship" ||
    ["IN_STORE_PICKUP_ONLY", "OVERWRITE_DELIVERY_FEE"].includes(rule);

  const cityChangeHandler = (val, setFieldValue) => {
    setFieldValue("cityZipInfo.zips", []);

    if (val && !Object.keys(exceptionZips).includes(val)) {
      dispatch(fetchZipCodesForExceptions(val));
    }
  };

  const FieldTitle = ({ title, required = false }) => (
    <View style={{ width: 100, marginTop: 16 }}>
      <Text style={fonts.heading4}>
        {Localise(messages, title)}
        {required ? " * " : ""}
      </Text>
    </View>
  );

  // Mark: Get the address suggestions when user is typing in the autocomplete
  const getAddressSuggestions = (text) => {
    if (AVSCountry !== "US" && AVSCountry !== "CA") return;
    if (text.trim().length < 3) {
      setAddressSuggestions([]);
      return;
    }

    const payload = {
      addressLine1: text,
      aptCode: "",
      countryRegion: AVSCountry,
      cityLocality: AVSCity,
      stateProvince: AVSState,
      postalCode,
      maxRecords: "10",
      resultDetails: [],
    };

    addressSuggestionsController && addressSuggestionsController.abort();
    addressSuggestionsController = new AbortController();

    request(
      "get-address-suggestions",
      payload,
      addressSuggestionsController
    ).then((res) => {
      if (res?.addresses?.length) {
        setAddressSuggestions(res.addresses);
      } else setAddressSuggestions([]);
    });
  };

  // MARK: Verify the address user has selected
  useEffect(() => {
    if (!isFocused) return;
    if (
      ["US", "CA"].includes(AVSCountry) &&
      AVSAddressLine1 &&
      AVSCity &&
      AVSState &&
      postalCode
    ) {
      const verifyAddressPayload = {
        addressLine1: addressInfo.streetAddress,
        country: AVSCountry,
        city: AVSCity,
        state: AVSState,
        zip: postalCode,
      };
      verifyAddress(verifyAddressPayload, null).then((result) => {
        if (!result.isValid) {
          setInvalidAddress(result);
        } else {
          setFieldValue(`addressInfo`, {
            ...addressInfo,
            addressVerificationInfo: {
              isAvsPerformed: "Y",
              avsConfidence: result.confidence,
              isAvsSuggestedAddress: result.confidence === "HIGH" ? "Y" : "N",
            },
          });
        }
      });
    } else {
      setFieldValue(`addressInfo`, {
        ...addressInfo,
        addressVerificationInfo: {
          isAvsPerformed: "N",
          avsConfidence: "Low",
          isAvsSuggestedAddress: "N",
        },
      });
    }
  }, [isFocused, AVSAddressLine1, AVSCity, AVSState, postalCode]);

  useEffect(() => {
    setFieldValue(`addressInfo`, {
      ...addressInfo,
      state: "",
      cityZipInfo: { city: "", zips: "" },
    });
  }, [AVSCountry]);

  useEffect(() => {
    if (rule === "IN_STORE_PICKUP_ONLY") {
      setFieldValue("criteria", "ALL_AREAS");
      criteriaChangeHandler &&
        criteriaChangeHandler("ALL_AREAS", setFieldValue, exception);
    }
  }, [rule]);

  useEffect(() => {
    if (productType === "dropship") {
      setFieldValue("rule", "DO_NOT_SHIP");
      setFieldValue("criteria", "ALL_AREAS");
      ruleChangeHandler &&
        ruleChangeHandler("DO_NOT_SHIP", setFieldValue, exception);
    }
  }, [productType]);

  useEffect(() => {
    if (day?.length || startDate || rule === "OVERWRITE_DELIVERY_FEE") {
      setDaysAndDatesSection(true);
    } else setDaysAndDatesSection(false);

    if (rule === "OVERWRITE_DELIVERY_FEE" && !daysAndDatesSection) {
      getNewDatesTimesObj(startDate, day, setFieldValue, rule);
    }
  }, [daysAndDatesSection, rule]);

  useEffect(() => {
    resetForm();
  }, []);

  const DateField = ({ name, disabled, endDatePath }) => {
    const errorName =
      get(errors, "endDate", "") || get(errors, "startDate", "");
    const showError =
      name === "endDate" &&
      (get(touched, "endDate", "") || get(errors, "startDate", "")) &&
      errorName;

    return (
      <View>
        <FormFieldDatePicker
          dateFormat={Platform.OS === "web" ? "MM/dd/yy" : "MM/DD/YY"}
          dateValueFormat="YYYY-MM-DD"
          name={name}
          placeholder={`${
            name === "endDate"
              ? Localise(messages, "End Date")
              : Localise(messages, "Start Date")
          }`}
          iconName="calendar"
          alignIconRight={true}
          disabled={disabled}
          errorStyle={{ display: "none" }}
          containerStyle={{
            width: Platform.OS === "web" && !isSmallScreen ? 115 : 130,
            height: showError ? "auto" : 50,
          }}
          onValueChange={() => {
            endDatePath && setFieldTouched && setFieldTouched(endDatePath);
          }}
          popperPlacement={"top"}
          popperModifiersOverride={{
            offset: {
              enabled: true,
              offset: "0px, -40px",
            },
          }}
        />
        {!!showError && (
          <Text style={styles.dateFieldsError}>
            {Localise(messages, errorName)}
          </Text>
        )}
      </View>
    );
  };

  const DaysField = () => {
    const onPress = (val, _index) => {
      if (!day.includes(val)) {
        const sortedDays = [...day, val].sort((a, b) => {
          a = daysOfWeek.indexOf(a);
          b = daysOfWeek.indexOf(b);
          return a - b;
        });
        setFieldValue("day", sortedDays);
      } else {
        const valIndex = day.indexOf(val);
        const updatedArray = [...day];
        updatedArray.splice(valIndex, 1);
        setFieldValue("day", updatedArray);
      }
    };

    return (
      <View>
        {daysOfWeek.map((val, index) => {
          return (
            <View style={tw("flex flex-col pl-1")} key={val}>
              <View style={tw("flex flex-row items-center")}>
                <CheckBox
                  containerStyle={[
                    theme.CheckBox.inputContainerStyle,
                    { paddingRight: 3 },
                  ]}
                  size={18}
                  checked={day?.includes(val)}
                  disabled={day?.includes(val) && day?.length === 1}
                  onPress={() => {
                    onPress(val, index);
                  }}
                  testID={val}
                  accessibilityLabel={val}
                />
                <Text>{Localise(messages, val)}</Text>
              </View>
            </View>
          );
        })}
      </View>
    );
  };

  return (
    <>
      <View>
        <ScrollView
          contentContainerStyle={tw("flex flex-row flex-wrap items-start")}
          {...(Platform.OS === "android" && {
            nestedScrollEnabled: true,
          })}
        >
          <View style={{ width: "100%" }}>
            <View style={[tw("flex-row items-start"), { marginBottom: 5 }]}>
              <FieldTitle title={"Label"} required={true} />
              <View style={{ width: 200 }}>
                <FormField
                  name={`label`}
                  placeholder={Localise(messages, "Add Label")}
                />
              </View>
            </View>
            <View
              style={[
                tw("flex-row items-start"),
                { marginBottom: 5, zIndex: zIndex + 45 },
              ]}
            >
              <FieldTitle title={"Product Type"} required={true} />
              <View style={{ width: 200 }}>
                <FormFieldPicker
                  placeholder={ExceptionsConfig.productTypeDropdown.placeholder}
                  containerStyle={{
                    paddingBottom: 10,
                  }}
                  data={ExceptionsConfig.productTypeDropdown.data}
                  name={"productType"}
                  defaultValue={""}
                  onChange={(val) =>
                    setTimeout(() => setFieldValue("productType", val), 0)
                  }
                />
              </View>
            </View>
            <View
              style={[
                tw("flex-row items-start"),
                { marginBottom: 5, zIndex: zIndex + 45 },
              ]}
            >
              <FieldTitle title={"Rule"} required={true} />
              <View style={{ width: 200 }}>
                <FormFieldPicker
                  placeholder={ExceptionsConfig.ruleDropdown.placeholder}
                  containerStyle={{
                    paddingBottom: 10,
                  }}
                  data={ExceptionsConfig.ruleDropdown.data}
                  name={"rule"}
                  defaultValue={""}
                  disabled={productType === "dropship"}
                  onChange={(val) =>
                    setTimeout(
                      () =>
                        ruleChangeHandler &&
                        ruleChangeHandler(val, setFieldValue, exception),
                      0
                    )
                  }
                />
                {(rule === "ADDITIONAL_FEE" ||
                  rule === "OVERWRITE_DELIVERY_FEE") && (
                  <FormField
                    name={
                      rule === "ADDITIONAL_FEE"
                        ? `additionalFee`
                        : `overWriteDeliveryFee`
                    }
                    {...FormFieldProps}
                    inputContainerStyle={{
                      ...FormFieldProps.inputContainerStyle,
                      height: 35,
                    }}
                    inputStyle={{
                      fontSize: 13,
                      maxWidth: "100%",
                    }}
                    containerStyle={{ width: 200 }}
                    errorStyle={{}}
                    iconType={"material-community"}
                    iconName={"currency-usd"}
                    iconSize={14}
                    currency={currency}
                  />
                )}
              </View>
            </View>
            <View
              style={[
                tw("flex-row items-start"),
                { marginBottom: 5, zIndex: zIndex + 50 },
              ]}
            >
              <FieldTitle title={"Criteria"} required={true} />
              <View style={{ width: 200 }}>
                <FormFieldPicker
                  placeholder={ExceptionsConfig.criteriaDropdown.placeholder}
                  containerStyle={{
                    paddingBottom: 10,
                  }}
                  data={ExceptionsConfig.criteriaDropdown.data}
                  name={"criteria"}
                  defaultValue={""}
                  disabled={
                    rule === "IN_STORE_PICKUP_ONLY" ||
                    productType === "dropship"
                  }
                  onChange={(val) => {
                    setTimeout(
                      () =>
                        criteriaChangeHandler &&
                        criteriaChangeHandler(val, setFieldValue, exception),
                      0
                    );
                  }}
                />
                {criteria === "CITY_ZIP_CODE" && (
                  <>
                    <FormFieldPicker
                      placeholder={{
                        label: Localise(messages, "Select City"),
                        value: "",
                      }}
                      containerStyle={{
                        paddingBottom: 10,
                      }}
                      data={exceptionCities}
                      name="cityZipInfo.city"
                      defaultValue={""}
                      onChange={(val) =>
                        setTimeout(
                          () => cityChangeHandler(val, setFieldValue),
                          0
                        )
                      }
                    />
                    <FormFieldAutoComplete
                      name={"cityZipInfo.zips"}
                      labelStyle={{
                        ...fonts.heading4,
                      }}
                      listStyle={{
                        paddingVertical: 5,
                      }}
                      data={zipCodes.filter((e) =>
                        toLower(e.label).includes(toLower(zipQuery))
                      )}
                      initialDataLength={zipCodes.length}
                      showOnFocus={true}
                      setFocusBack={true}
                      placeholder={
                        cityZipInfo?.zips?.length
                          ? cityZipInfo?.zips?.length > 1
                            ? Localise(messages, "Multi")
                            : cityZipInfo?.zips?.join("")
                          : Localise(messages, "All Zips")
                      }
                      listDisplayValues={["label"]}
                      outerContainerStyle={{
                        zIndex: 1,
                      }}
                      isMultiSelect={true}
                      onSelect={(selectedValue) => {
                        const newZips = [...(cityZipInfo?.zips || [])];
                        const index = newZips?.indexOf(selectedValue.value);
                        const unSelected = index >= 0;
                        unSelected
                          ? newZips?.splice(index, 1)
                          : newZips?.push(selectedValue.value);
                        setFieldValue("cityZipInfo.zips", newZips);
                        setZipQuery("");
                      }}
                      onChangeText={(val) => setZipQuery(val)}
                    />
                  </>
                )}
              </View>
            </View>
            {criteria === "ADDRESS" && (
              <View
                style={[
                  tw("flex flex-row flex-wrap"),
                  { zIndex: zIndex + 100 },
                ]}
              >
                <FieldTitle title={""} />
                <View
                  style={[
                    tw("flex flex-row flex-wrap"),
                    { width: isMobile ? "60%" : "40%" },
                  ]}
                >
                  <FormFieldAutoComplete
                    name={"addressInfo.streetAddress"}
                    placeholder={Localise(messages, "Street Address")}
                    autoCapitalize={"none"}
                    autoCorrect={false}
                    fsClass={"fs-exclude"}
                    loading={false}
                    data={addressSuggestions}
                    outerContainerStyle={{
                      width: isMobile ? "100%" : "60%",
                      zIndex: zIndex + 100,
                    }}
                    listDisplayValues={[
                      "addressLine1",
                      "cityLocality",
                      "stateProvince",
                    ]}
                    updateOnBlur={true}
                    showErrorOnTouched={true}
                    popperPlacement={"bottom"}
                    onChangeText={(text) => {
                      getAddressSuggestions(text);
                    }}
                    onSelect={(selectedValue) =>
                      setFieldValue(`addressInfo`, {
                        ...addressInfo,
                        streetAddress: selectedValue.addressLine1,
                        state: selectedValue.stateProvince,
                        country: selectedValue.countryRegion,
                        cityZipInfo: {
                          city: selectedValue.cityLocality,
                          zips: [selectedValue.postalCode],
                        },
                      })
                    }
                  />
                  {!isSmallScreen && addressInfo && (
                    <AddressVerificationIndicator
                      isVerified={isAvsVerified}
                      isSmallScreen={isSmallScreen}
                    />
                  )}
                  <FormFieldPicker
                    name={"addressInfo.country"}
                    placeholder={{
                      label: Localise(messages, "Select Country"),
                    }}
                    containerStyle={{
                      width: isMobile ? "100%" : "40%",
                    }}
                    data={countryList}
                    fsClass={"fs-exclude"}
                  />
                  <FormFieldPicker
                    name={"addressInfo.state"}
                    placeholder={{
                      label: Localise(messages, "Select State"),
                    }}
                    containerStyle={{
                      width: isMobile ? "100%" : "40%",
                    }}
                    data={stateList[AVSCountry] || []}
                    fsClass={"fs-exclude"}
                  />
                  <FormField
                    name={"addressInfo.cityZipInfo.city"}
                    placeholder={Localise(messages, "City")}
                    autoCapitalize="none"
                    autoCorrect={false}
                    containerStyle={{
                      width: isMobile ? "100%" : "40%",
                    }}
                    labelStyle={fonts.style1}
                  />
                  <FormField
                    name={"addressInfo.cityZipInfo.zips.0"}
                    placeholder={"Postal Code"}
                    autoCapitalize="none"
                    autoCorrect={false}
                    containerStyle={{
                      width: isMobile ? "100%" : "40%",
                    }}
                    labelStyle={fonts.style1}
                  />
                  <AddressVerificationModal
                    showModal={!!invalidAddress}
                    onClose={() => setInvalidAddress()}
                    invalidAddresses={invalidAddress ? [invalidAddress] : []}
                    onContinue={(addresses) => {
                      const verifiedAddress =
                        addresses[0].selectedIndex === 0
                          ? addresses[0].originalAddress
                          : addresses[0].suggestedAddress;
                      const isAvsSuggestedAddress = verifiedAddress
                        ? verifiedAddress.selectedIndex === 1
                        : false;
                      setFieldValue(`addressInfo`, {
                        ...addressInfo,
                        streetAddress: verifiedAddress.addressLine1,
                        country: verifiedAddress.country,
                        state: verifiedAddress.state,
                        cityZipInfo: {
                          city: verifiedAddress.city,
                          zips: [verifiedAddress.postalCode],
                        },
                        addressVerificationInfo: {
                          isAvsPerformed: "Y",
                          avsConfidence: verifiedAddress.confidence,
                          isAvsSuggestedAddress: isAvsSuggestedAddress
                            ? "Y"
                            : "N",
                        },
                      });
                      setInvalidAddress();
                    }}
                    isSmallScreen={isSmallScreen}
                  />
                </View>
              </View>
            )}

            <View
              style={[
                tw(`flex-row ${isMobile ? "items-center" : "items-start"}`),
                { marginBottom: 5, zIndex: zIndex + 40 },
              ]}
            >
              <View style={{ width: 100, marginTop: 16 }}>
                <Text style={fonts.heading4}>
                  {Localise(messages, "Status")} {` * `}
                </Text>
              </View>
              <View style={{ width: isMobile ? 200 : "100%" }}>
                <View
                  style={[
                    {
                      paddingLeft: 5,
                      paddingBottom: 10,
                      paddingTop: 15,
                    },
                    tw("flex flex-row items-start"),
                  ]}
                >
                  <FormFieldSwitch
                    value={status}
                    name="status"
                    onValueChange={() => {
                      setFieldValue("status", !status);
                    }}
                  />
                  <View
                    style={{
                      marginLeft: 10,
                      justifyContent: "center",
                    }}
                  >
                    <TouchableOpacity
                      onPress={(e) => {
                        getNewDatesTimesObj(
                          startDate,
                          day,
                          setFieldValue,
                          rule
                        );
                        setDaysAndDatesSection(!daysAndDatesSection);
                      }}
                      testID={"add_dates_times"}
                      accessibilityLabel={"add_dates_times"}
                    >
                      <View>
                        <Text
                          style={{
                            ...fonts.link1,
                            width: isMobile ? "70%" : "100%",
                          }}
                        >
                          {startDate || day?.length
                            ? Localise(
                                messages,
                                showDaysCheckbox
                                  ? "Reset Days of the Week & Dates"
                                  : "Reset Dates"
                              )
                            : Localise(
                                messages,
                                rule !== "OVERWRITE_DELIVERY_FEE"
                                  ? showDaysCheckbox
                                    ? `Days of the Week & Dates (Optional)`
                                    : "Dates (Optional)"
                                  : showDaysCheckbox
                                  ? `Days of the Week & Dates`
                                  : "Dates"
                              )}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
            {daysAndDatesSection ? (
              <>
                {showDaysCheckbox ? (
                  <View
                    style={[
                      tw("flex-row items-start"),
                      { marginBottom: 5, zIndex: zIndex + 125 },
                    ]}
                  >
                    <View style={{ width: 100, marginTop: 16 }}>
                      <Text style={fonts.heading4}>
                        {Localise(messages, "Days")}
                        {rule === "OVERWRITE_DELIVERY_FEE" ? " * " : ""}
                      </Text>
                    </View>
                    <View>
                      <View
                        style={[
                          tw(
                            `flex flex-row justify-between items-${
                              Platform.OS !== "web" ? "start" : "baseline"
                            } pl-3 py-2`
                          ),
                          styles.datesSection,
                          { zIndex: zIndex + 20 },
                        ]}
                      >
                        <DaysField />
                      </View>
                    </View>
                  </View>
                ) : null}
                <View
                  style={[
                    tw("flex-row items-start"),
                    { marginBottom: 5, zIndex: zIndex + 125 },
                  ]}
                >
                  <View style={{ width: 100, marginTop: 16 }}>
                    <Text style={fonts.heading4}>
                      {Localise(messages, "Date")}{" "}
                      {rule === "OVERWRITE_DELIVERY_FEE" ? "(Optional)" : ""}
                    </Text>
                  </View>
                  <View>
                    <View
                      style={[
                        tw(
                          `flex flex-row justify-between items-${
                            Platform.OS !== "web" ? "center" : "baseline"
                          } pl-3 py-2`
                        ),
                        styles.datesSection,
                        { zIndex: zIndex + 20 },
                      ]}
                    >
                      <Text style={{ ...fonts.heading5, width: 30 }}>
                        {Localise(messages, "Start")}
                      </Text>
                      <DateField name={`startDate`} endDatePath=" endDate" />
                    </View>
                    <View
                      style={[
                        tw(
                          `flex flex-row justify-between items-${
                            Platform.OS !== "web" ? "center" : "baseline"
                          } pl-3 py-2`
                        ),
                        styles.datesSection,
                        { zIndex: zIndex + 25 },
                      ]}
                    >
                      <Text style={{ ...fonts.heading5, width: 30 }}>
                        {Localise(messages, "End")}
                      </Text>
                      <DateField name={`endDate`} />
                    </View>
                  </View>
                </View>
              </>
            ) : null}
            <View
              style={[
                tw(
                  `${
                    exception.exceptionId ? "justify-between" : "justify-end"
                  } flex-${isMobile ? "col" : "row"}`
                ),
                { zIndex: zIndex - 2 },
              ]}
            >
              {exception.exceptionId && (
                <View
                  style={tw(`justify-between flex-${isMobile ? "col" : "row"}`)}
                >
                  <Button
                    testID={"delivery_exception"}
                    accessibilityLabel="delivery_exception"
                    titleStyle={{
                      ...theme.Button.secondaryTitleStyle,
                    }}
                    buttonStyle={{
                      ...theme.Button.secondaryButtonStyle,
                    }}
                    containerStyle={{ marginLeft: 0 }}
                    title={Localise(messages, "Delete")}
                    onPress={(e) => deleteExceptionHandler(i, exception)}
                  />
                </View>
              )}
              <SaveCancelButtons
                onCancel={() =>
                  !exception.exceptionId && deleteExceptionHandler(i, exception)
                }
                customContainerStyles={{
                  width: 100,
                  marginLeft: 0,
                }}
                showCancelAlways={!exception.exceptionId && true}
                buttonTitle={Localise(messages, "Save")}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    </>
  );
}

export default ExceptionInput;
