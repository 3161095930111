import * as Yup from "yup";
import {
  getCardType,
  ccdRegex,
  ccdErrors,
} from "library/utils/payment-options";

export const getValidationSchema = (Localise, messages) => {
  Yup.addMethod(Yup.string, "isValidCard", function (errorMessage) {
    return this.test(`is-valid-card`, errorMessage, function (value = "") {
      const { actualCardNumber = "" } = this.parent;
      if (!actualCardNumber) return true;
      const trimmedValue = actualCardNumber.split(" ").join("");
      if (trimmedValue.substring(0, 3) === "***") return true;
      if (isNaN(trimmedValue)) return false;
      var cardType = getCardType(trimmedValue);
      if (!cardType) return false;
      const { path, createError } = this;
      var regEx = new RegExp(ccdRegex[cardType]);

      return (
        regEx.test(trimmedValue) ||
        createError({
          path,
          message:
            ccdErrors[cardType] || Localise(messages, "Invalid Card Number"),
        })
      );
    });
  });

  return Yup.object().shape({
    paymentInfo: Yup.object().shape({
      nameOnCard: Yup.string()
        .matches(
          new RegExp("^[-\\sa-zA-Z.]+$"),
          Localise(messages, "Invalid Name")
        )
        .required(Localise(messages, "Please enter the Name on card")),
      cardNumber: Yup.string()
        .required(Localise(messages, "Please enter Card Number"))
        .isValidCard(Localise(messages, "Invalid Card Number")),
      cvvNumber: Yup.string()
        .matches(new RegExp("^[0-9]{3,4}$"), Localise(messages, "Invalid CVV"))
        .required(Localise(messages, "Enter CVV")),
      expiration: Yup.string()
        .matches(
          /([0-9]{2})\/([0-9]{4})/,
          Localise(messages, "Please enter Valid Expiration")
        )
        .required(Localise(messages, "Please enter Expiration")),
      billingAddress: Yup.object().when("sameAsMailingAddress", {
        is: false,
        then: Yup.object().shape({
          addressLine1: Yup.string()
            .matches(
              new RegExp("^[-\\sa-zA-Z0-9./]+$"),
              Localise(messages, "Invalid Billing Address")
            )
            .required(Localise(messages, "Please enter Street Address")),
          country: Yup.string().required(
            Localise(messages, "Please enter Country")
          ),
          city: Yup.string().required(Localise(messages, "Please enter City")),
          state: Yup.string().required(
            Localise(messages, "Please enter State")
          ),
          zipcode: Yup.string().required(
            Localise(messages, "Please enter Zip Code")
          ),
        }),
      }),
    }),
  });
};
