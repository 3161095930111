import React, { useEffect, useContext, useRef } from "react";
import { View, Text, ScrollView } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { fonts } from "styles/theme";
import CreateTask from "../../task-info/index";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { AppSettingsContext } from "library/contexts/appSettings";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { Form, FormFieldAutoComplete } from "components/elements/forms";
import { Spinner, ToasterHandler } from "components/elements";
import { request } from "library/utils/request";
import tw from "tailwind-rn";
import { SaveCancelButtons } from "./helper";
import { selectDashboardShopCode } from "library/sagas/ongoing/global-data/selector";
import { Entitlements } from "library/utils/entitlements";
import UserProfileStorage from "library/storage/userProfile";
import { getReqObj, getShopDetails, checkIfPastDate } from "../../helper";
import { getInitialValues } from "../../config";
import {
  convertToTimeZone,
  DSEstimatedFees,
  isBurqProvider,
  defaultDeliveryProvider,
} from "library/utils/deliveryService";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { deliveryPickupTimeWindow } from "components/views/drawer/delivery/config";
import moment from "moment";
import { getValidationSchema } from "../../yup";
import {
  useNavigation,
  CommonActions,
  useRoute,
} from "@react-navigation/native";
import { setSelectScreenKey } from "library/sagas/views/home/drawer/delivery/slice";
import { processShopSettingsResponse } from "library/utils/shopSettings";
import Environment from "library/utils/environment";

const CreateDSRequest = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { setParams } = navigation;
  const {
    params: {
      redeliver = false,
      formValues = {},
      previousShipmentId = "",
    } = {},
  } = useRoute();

  const { messages, Localise } = useContext(I18NContext);
  const dashboardSelectedShopCode = useSelector(selectDashboardShopCode);
  const { permissions } = useContext(AppSettingsContext);
  const { isDesktop } = useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);
  const [loading, setLoading] = useStateIfMounted(true);
  const [preloadInitialValues, setPreloadInitialValues] = useStateIfMounted(
    formValues?.shopCode ? formValues : {}
  );
  const createTaskRetryCount = useRef(0);
  const distanceMatrixRetryCount = useRef(0);

  const DSRequestCreateFormRef = useRef();
  const shopNames = UserProfileStorage.getAllShopNames();
  let shopTimeZone = "";
  let deliveryDate = undefined;
  const [selectShopQuery, setSelectShopQuery] = useStateIfMounted("");
  const [eligibleShops, setEligibleShops] = useStateIfMounted([]);
  const eligibleProvidersList = Environment.get(
    "ELIGIBLE_DELIVERY_PROVIDERS",
    "roadie,doordash"
  ).split(",");

  const filteredShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.DELIVERY_TASKS in permissions[memberCode])
      filteredShopCodes.push(memberCode);
  });

  const isShopEligible = (memberCode) => {
    return eligibleShops.includes(memberCode);
  };

  const sendingOriginData = eligibleShops?.map((each) => {
    const shopName = shopNames[each] || "";
    return { code: each, shopName: shopName };
  });

  const selectedShopCode =
    dashboardSelectedShopCode !== "all"
      ? dashboardSelectedShopCode
      : filteredShopCodes[0];

  if (preloadInitialValues) {
    shopTimeZone = UserProfileStorage.getShopTimeZone(
      preloadInitialValues?.shopCode
    );
    if (shopTimeZone)
      deliveryDate = moment(convertToTimeZone(new Date(), shopTimeZone)).format(
        "YYYY-MM-DD"
      );
  }

  const initialValues = getInitialValues(
    preloadInitialValues?.shopCode
      ? checkIfPastDate(
          get(preloadInitialValues, "deliveryDetails.deliveryDate"),
          preloadInitialValues?.shopCode
        )
        ? preloadInitialValues?.shopCode &&
          !isShopEligible(preloadInitialValues?.shopCode)
          ? {
              ...preloadInitialValues,
              shopCode: undefined,
              shopField: undefined,
              shopName: undefined,
              floristTimeZone: undefined,
              deliveryDetails: {
                ...preloadInitialValues?.deliveryDetails,
                deliveryDate: deliveryDate,
                pickUpDateTime: undefined,
              },
            }
          : {
              ...preloadInitialValues,
              deliveryDetails: {
                ...preloadInitialValues?.deliveryDetails,
                deliveryDate: deliveryDate,
                pickUpDateTime: undefined,
              },
            }
        : preloadInitialValues?.shopCode &&
          [
            preloadInitialValues?.deliveryProvider?.toLowerCase(),
            UserProfileStorage.getShopPreferences(
              preloadInitialValues?.shopCode
            )?.delivery_provider.toLowerCase(),
          ].includes("doordash")
        ? preloadInitialValues?.shopCode &&
          !isShopEligible(preloadInitialValues?.shopCode)
          ? {
              ...preloadInitialValues,
              shopCode: undefined,
              shopField: undefined,
              shopName: undefined,
              floristTimeZone: undefined,
              deliveryDetails: {
                ...preloadInitialValues?.deliveryDetails,
                pickUpDateTime: undefined,
              },
            }
          : {
              ...preloadInitialValues,
              deliveryDetails: {
                ...preloadInitialValues?.deliveryDetails,
                pickUpDateTime: undefined,
              },
            }
        : preloadInitialValues?.shopCode &&
          !isShopEligible(preloadInitialValues?.shopCode)
        ? {
            ...preloadInitialValues,
            shopCode: undefined,
            shopField: undefined,
            shopName: undefined,
            floristTimeZone: undefined,
          }
        : preloadInitialValues
      : (dashboardSelectedShopCode !== "all" &&
          isShopEligible(dashboardSelectedShopCode)) ||
        filteredShopCodes.length == 1
      ? getShopDetails(selectedShopCode)
      : {}
  );

  useEffect(() => {
    setLoading(true);
    request("get-shop-details", { memberCodes: filteredShopCodes })
      .then((res) => {
        setEligibleShops(
          res
            .filter(
              (each) =>
                (eligibleProvidersList.includes(
                  each.preferences.find(
                    (preference) => preference.name === "delivery_provider"
                  )?.value[0] || defaultDeliveryProvider
                ) &&
                  each.preferences.find(
                    (preference) => preference.name === "delivery_service"
                  )?.value[0] === "true") ||
                ""
            )
            .map((each) => each.memberCode)
        );
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      setParams({
        formValues: undefined,
        redeliver: undefined,
        previousShipmentId: undefined,
      });
    };
  }, []);

  //To reset the form values from copy action when cancel click
  function setCancelAction() {
    setPreloadInitialValues({});
  }

  if (!initialValues || loading) {
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );
  }

  const onSubmit = (values, formikBag) => {
    const createTaskReqObj = getReqObj({
      values,
      redeliver,
      previousShipmentId,
    });

    if (values?.estimatedDistance) {
      createTask(values, formikBag, createTaskReqObj);
    } else {
      const {
        pickup: {
          address: {
            addressLine1: shopAddressLine1,
            city: shopCity,
            state: shopState,
            zip: shopZip,
          } = {},
        } = {},
        delivery: {
          address: {
            addressLine1: addressLine1,
            city: city,
            state: state,
            zip: zipcode,
          } = {},
        } = {},
      } = createTaskReqObj?.body || {};
      const reqPayload = {
        shopCode: values.shopCode,
        origins: encodeURIComponent(
          [shopAddressLine1, shopCity, shopState, shopZip].join(", ")
        ),
        destinations: encodeURIComponent(
          [addressLine1, city, state, zipcode].join(", ")
        ),
      };
      request("distance-matrix", reqPayload)
        .then((res) => {
          let distance = get(res, "rows.0.elements.0.distance.value", 0);
          distance = distance / 1609;
          formikBag.setFieldValue(
            "estimatedDistance",
            parseFloat(distance).toFixed(5)
          );
          if (
            get(res, "rows.0.elements.0.distance.value", 0) >
              values.distanceThreshold * 1609.344 ||
            res.rows[0].elements[0].status !== "OK"
          ) {
            formikBag.setFieldError(
              `recipientDetails.addressLine1`,
              Localise(messages, `Delivery is beyond`) +
                " " +
                values.distanceThreshold +
                " " +
                Localise(messages, `miles`)
            );
            formikBag.setSubmitting(false);
          } else {
            createTask(values, formikBag, createTaskReqObj);
          }
        })
        .catch((err) => {
          if (distanceMatrixRetryCount.current < 3) {
            distanceMatrixRetryCount.current =
              distanceMatrixRetryCount.current + 1;
            console.error(
              "distance matrix request failed. Retrying attempt: " +
                distanceMatrixRetryCount.current
            );
            DSRequestCreateFormRef?.current?.handleSubmit();
          } else {
            console.log(
              "distance matrix request failed",
              get(err, "errors.0.message", "")
            );
            const errorMessage =
              "Unable to submit the delivery service request. Please try again.";
            ToasterHandler("uh oh", Localise(messages, errorMessage));
            formikBag.setSubmitting(false);
            formikBag.setErrors({});
            distanceMatrixRetryCount.current = 0;
          }
        });
    }
  };

  const createTask = (values, formikBag, createTaskReqObj) => {
    request(
      redeliver ? "redelivery-ds-request" : "create-task",
      createTaskReqObj
    )
      .then((res) => {
        ToasterHandler(
          "success",
          Localise(messages, "Your delivery service request has been submitted")
        );
        setValidationOnChange(false);
        setPreloadInitialValues({});
        formikBag.resetForm(initialValues);
        if (
          (dashboardSelectedShopCode !== "all" &&
            isShopEligible(dashboardSelectedShopCode)) ||
          filteredShopCodes.length == 1
        ) {
          //resetting shopCode for Standalone shops after create DS Request submission
          formikBag.setFieldValue("shopCode", "");
          formikBag.setFieldValue("shopCode", selectedShopCode);
        }
        formikBag.setErrors({});
        setTimeout(() => {
          formikBag.setErrors({});
        }, 10);
        formikBag.setSubmitting(false);
        createTaskRetryCount.current = 0;
        distanceMatrixRetryCount.current = 0;
      })
      .catch((err) => {
        if (err?.errorCode >= 500 && createTaskRetryCount.current < 3) {
          createTaskRetryCount.current = createTaskRetryCount.current + 1;
          console.error(
            "create task request failed. Retrying attempt: " +
              createTaskRetryCount.current
          );
          DSRequestCreateFormRef?.current?.handleSubmit();
        } else {
          let errorMessage = get(err, "errors.0.message", "");
          const defaultErrorMessage =
            "Unable to submit the delivery service request. Please try again.";
          if (
            errorMessage.length === 0 ||
            ["ERROR", "CATCH ALL ERROR", "null"].includes(errorMessage)
          ) {
            errorMessage = defaultErrorMessage;
          }
          ToasterHandler("uh oh", Localise(messages, errorMessage));
          let referenceNumber = values?.referenceNumber;
          let shopCode = values?.shopCode;
          dispatch(setSelectScreenKey("DS"));
          UserProfileStorage.setCurrentTab("DS");
          navigation.dispatch(
            CommonActions.navigate({
              name: "lmds-delivery",
              params: {
                referenceNumber: referenceNumber,
                shopCode: shopCode,
                search: {
                  title: referenceNumber,
                  value: referenceNumber,
                },
              },
            })
          );
          createTaskRetryCount.current = 0;
          formikBag.setSubmitting(false);
          formikBag.setErrors({});
        }
      });
  };

  return (
    <>
      <ScrollView>
        <View
          style={[
            tw(`mt-2  ${isDesktop ? "pl-6 " : "pl-3"} `),
            { maxWidth: 768 },
          ]}
        >
          <Form
            initialValues={initialValues}
            onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
            validationSchema={getValidationSchema(Localise, messages)}
            validateOnBlur={validationOnChange}
            validateOnChange={validationOnChange}
            innerRef={DSRequestCreateFormRef}
            enableReinitialize={true}
            render={(props) => {
              const { submitCount, setFieldValue, values, setValues } = props;

              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                if (submitCount > 0) {
                  setValidationOnChange(true);
                }
              }, [submitCount]);

              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                setFieldValue("deliveryProvider", "");
                setFieldValue("deliveryDetails.pickUpDateTime", "");
                if (!values.shopCode) return;
                request("get-shop-settings", {
                  ids: ["delivery_pickup_time", "delivery_provider"],
                  memberCode: values.shopCode,
                })
                  .then((res) => {
                    if (res && res.preferences && res.preferences.length) {
                      let result = processShopSettingsResponse(res);
                      let deliveryPickupTime = result.delivery_pickup_time;
                      let targetPickUpTimeWindow = deliveryPickupTimeWindow;
                      setFieldValue(
                        "deliveryProvider",
                        result.delivery_provider || defaultDeliveryProvider
                      );
                      if (deliveryPickupTime !== "Every 30 minutes") {
                        let deliveryMatchingTime = [];
                        targetPickUpTimeWindow?.map((timeWindow) => {
                          if (timeWindow.key === deliveryPickupTime) {
                            deliveryMatchingTime = timeWindow.value;
                          }
                        });
                        if (deliveryMatchingTime.length) {
                          const injectTiming = deliveryMatchingTime?.map(
                            (timeWindow) => {
                              return moment(new Date())
                                .set({
                                  hour: timeWindow,
                                  minute: 0,
                                  second: 0,
                                })
                                .toDate();
                            }
                          );
                          setFieldValue("injectTimings", injectTiming);
                          setFieldValue("timeWindow", deliveryMatchingTime);
                        }
                      } else {
                        setFieldValue("injectTimings", []);
                        setFieldValue("timeWindow", []);
                      }
                    }
                  })
                  .catch((err) => {
                    console.log(
                      "Error occurred while getting shop preferences :>> ",
                      err
                    );
                  });
              }, [values.shopCode]);

              return (
                <View style={[tw("mb-8")]}>
                  <View style={{ zIndex: 11, paddingLeft: 5 }}>
                    {filteredShopCodes.length > 1 && !!eligibleShops.length && (
                      <View
                        style={[
                          tw("flex w-full"),
                          { zIndex: 2, paddingBottom: isSmallScreen ? 0 : 4 },
                        ]}
                      >
                        <Text
                          style={[
                            fonts.heading4,
                            { fontSize: 11, paddingBottom: 5 },
                          ]}
                        >
                          {Localise(messages, "Select Shop")} *
                        </Text>
                        <FormFieldAutoComplete
                          name="shopField"
                          placeholder={`${Localise(messages, "Select Shop")}`}
                          data={sendingOriginData.filter((each) => {
                            const { code = "", shopName = "" } = each;
                            return (
                              code
                                .toLowerCase()
                                .includes(selectShopQuery.toLowerCase()) ||
                              shopName
                                .toLowerCase()
                                .includes(selectShopQuery.toLowerCase())
                            );
                          })}
                          outerContainerStyle={{
                            width: "100%",
                            padding: 0,
                            paddingRight: isSmallScreen ? 5 : 0,
                          }}
                          containerStyle={{
                            marginLeft: -5,
                            padding: 0,
                            margin: 0,
                          }}
                          autoCapitalize="none"
                          showOnFocus={true}
                          clearTextOnBackTab={true}
                          isMultiSelect={false}
                          listDisplayValues={["code", "shopName"]}
                          popperPlacement={"bottom"}
                          onChangeText={(text) => {
                            setSelectShopQuery(text);
                          }}
                          onSelect={(selectedValue) => {
                            if (!selectedValue) return;

                            const { code, shopName } = selectedValue;
                            setFieldValue("shopField", `${code} ${shopName}`);
                            setFieldValue("shopCode", code);
                            setFieldValue("shopName", shopName);
                            const shopTimeZone =
                              UserProfileStorage.getShopTimeZone(code);
                            const floristTimeZone =
                              shopTimeZone || "America/Chicago";
                            setFieldValue("floristTimeZone", floristTimeZone);

                            setSelectShopQuery("");
                          }}
                          onClear={() => {
                            setFieldValue("shopField", undefined);
                            setFieldValue("shopCode", undefined);
                            setFieldValue("shopName", undefined);
                          }}
                        />
                      </View>
                    )}
                  </View>
                  <View
                    style={{
                      marginBottom: 10,
                      paddingLeft: 5,
                    }}
                  >
                    <CreateTask
                      screen={"create"}
                      copy={!isEmpty(preloadInitialValues)}
                      {...props}
                    />
                  </View>
                  <View
                    style={[tw("flex flex-row items-center justify-between")]}
                  >
                    <View
                      style={{
                        marginBottom: 10,
                        marginTop: isSmallScreen ? 0 : 10,
                      }}
                    >
                      {values?.estimatedDistance ? (
                        <Text
                          style={{
                            ...fonts.default,
                            marginBottom: 5,
                            fontSize: 12,
                          }}
                        >
                          {Localise(messages, "Estimated Distance")}:{" "}
                          {parseFloat(values?.estimatedDistance).toFixed(2)}{" "}
                          {Localise(messages, "miles")}
                        </Text>
                      ) : null}
                      {isBurqProvider(values?.deliveryProvider) ? (
                        <DSEstimatedFees {...{ Localise, messages }} />
                      ) : null}
                    </View>
                    <SaveCancelButtons
                      isSmallScreen={isSmallScreen}
                      values={values}
                      setValues={setValues}
                      setValidationOnChange={setValidationOnChange}
                      selectedShopCode={
                        (dashboardSelectedShopCode !== "all" &&
                          isShopEligible(dashboardSelectedShopCode)) ||
                        filteredShopCodes.length == 1
                          ? selectedShopCode
                          : ""
                      }
                      setCancelAction={setCancelAction}
                    />
                  </View>
                </View>
              );
            }}
          />
        </View>
      </ScrollView>
    </>
  );
};

export default CreateDSRequest;
