import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ScrollView,
  View,
  Platform,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import {
  Button,
  CheckBox,
  Text,
  Badge,
  Image,
  Divider,
} from "react-native-elements";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import { Tooltip } from "components/elements";
import IMAGES from "static/assets/images";
import { fonts, theme } from "styles/theme";
import tw from "tailwind-rn";

const Filters = ({
  icon,
  text,
  hideText = false,
  selectors: { selectValue },
  actions: { setAction },
  options,
}) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;

  const dispatch = useDispatch();
  const appliedFilters = useSelector(selectValue);
  const [currentFilters, setFilters] = useState(appliedFilters);

  const filtersMenuRef = React.useRef();

  useEffect(() => {
    setFilters(appliedFilters);
  }, [appliedFilters]);

  const onClose = () => filtersMenuRef?.current?.close();
  const onCancel = () => {
    setFilters(appliedFilters);
    onClose();
  };
  const onReset = () => dispatch(setAction({ type: "filters", value: [] }));
  const onSelect = (entry) => {
    const finding = currentFilters.find((e) => e.value === entry.value);
    const result = finding
      ? currentFilters.filter((e) => e.value !== entry.value)
      : currentFilters.concat([entry]);
    setFilters([...result]);
    return false;
  };
  const onApply = () => {
    dispatch(setAction({ type: "filters", value: currentFilters }));
    onClose();
  };

  return (
    <Menu ref={filtersMenuRef} onClose={onCancel}>
      <MenuTrigger testID={text} accessibilityLabel={text}>
        <View style={tw("flex-row justify-around items-center")}>
          <Tooltip text={Localise(messages, "Filter")} renderForWebOnly = {true}>
            <Image
              style={{ width: 20, height: 20 }}
              resizeMode="cover"
              source={IMAGES[icon]}
            />
          </Tooltip>
          {!isSmallScreen && text && !hideText && (
            <Text style={{ paddingLeft: 2 }}>{text}</Text>
          )}
          {appliedFilters.length > 0 && (
            <Badge
              containerStyle={[
                {
                  right: isDesktop ? -18 : -10,
                  ...tw("mb-4"),
                  position: "absolute",
                },
                Platform.OS !== "web" && { top: -10 },
              ]}
              badgeStyle={{ backgroundColor: "#e5175e" }}
              value={appliedFilters.length}
            />
          )}
        </View>
      </MenuTrigger>
      <MenuOptions
        optionsContainerStyle={{
          padding: 5,
          maxHeight: !isDesktop ? Dimensions.get("window").height : "80%",
          marginTop: 20,
        }}
        customStyles={{
          optionsWrapper: {
            flex: 1,
          },
          optionWrapper: {
            padding: 5,
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          },
        }}>
        <View style={{ flex: 1 }}>
          <ScrollView
            style={{
              flexGrow: 0,
              maxHeight: isDesktop ? "80%" : "60%",
            }}>
            {currentFilters.length > 0 && (
              <MenuOption onSelect={() => onReset()}>
                <View style={tw("flex flex-col w-full")}>
                  <Text style={{ ...fonts.heading4, marginVertical: 3 }}>
                    {Localise(messages, "Clear")} {Localise(messages, text)}
                  </Text>
                  <Divider />
                </View>
              </MenuOption>
            )}
            {Object.keys(options).map((section) => {
              return (
                <React.Fragment key={section}>
                  <MenuOption>
                    <View style={tw("flex flex-col w-full")}>
                      <Text
                        style={{
                          ...fonts.heading4,
                          marginVertical: 3,
                        }}>
                        {Localise(messages, section)}
                      </Text>
                      <Divider />
                    </View>
                  </MenuOption>
                  {options[section].map((option) => {
                    const isSelected = currentFilters.find(
                      (e) => e.value === option.value,
                    );
                    return (
                      <MenuOption key={`${section}_${option.value}`}>
                        <CheckBoxFilter
                          option={option}
                          selected={!!isSelected}
                          onPress={onSelect}
                        />
                      </MenuOption>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </ScrollView>
          <View
            style={[
              tw("flex flex-row items-baseline justify-between m-2"),
              Platform.OS !== "web" && tw("pb-12"),
            ]}>
            <View>
              <Text style={[fonts.link1, tw("m-2")]} onPress={() => onCancel()}>
                {Localise(messages, "Close")}
              </Text>
            </View>
            <Button
              title={Localise(messages, "Apply")}
              onPress={() => onApply()}
              testID={"Apply"}
              accessibilityLabel={"Apply"}
            />
          </View>
        </View>
      </MenuOptions>
    </Menu>
  );
};

const CheckBoxFilter = ({ option: { label, value }, selected, onPress }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  return (
    <TouchableOpacity
      style={tw("flex flex-row items-center")}
      onPress={() => onPress({ label, value })}
    >
      <CheckBox
        containerStyle={[
          theme.CheckBox.inputContainerStyle,
          { paddingRight: 3 },
        ]}
        size={18}
        checked={selected}
        testID={label}
        accessibilityLabel={label}
        onPress={() => onPress({ label, value })}
      />
      <Text onPress={() => onPress({ label, value })}>
        {Localise(messages, label)}
      </Text>
    </TouchableOpacity>
  );
};

export default Filters;
