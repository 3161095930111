import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUIConfig } from "./ui-config";
import Listing from "components/containers/listing-new";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import { fetchShopPaymentSettings } from "library/sagas/views/home/drawer/shop-settings/common/slice";

const ListingScreen = () => {
  const { isDesktop, isTablet, isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchShopPaymentSettings());
  }, []);

  const UIConfig = getUIConfig(
    Localise,
    messages,
    isDesktop,
    isTablet,
    isMobile
  );

  const ListingComponent = Listing({ UIConfig });

  return ListingComponent?.props?.children;
};

export default ListingScreen;
