import React from "react";
import { TouchableOpacity } from "react-native";

const HoverableOpacity = ({ outerStyle, hoverStyle, children, onPress }) => {
  const [hoverState, sethoverState] = React.useState(false);
  return (
    <TouchableOpacity
      activeOpacity={1}
      style={[{ cursor: "inherit" }, outerStyle, hoverState ? hoverStyle : {}]}
      onMouseEnter={() => sethoverState(true)}
      onMouseLeave={() => sethoverState(false)}
      onPress={onPress}
    >
      {children}
    </TouchableOpacity>
  );
};

export default HoverableOpacity;
