import * as Yup from "yup";
import get from "lodash/get";
import moment from "moment";

export const getValidationSchema = (Localise, messages, currency) => {
  Yup.addMethod(Yup.array, "isUniqueVariantName", function (message) {
    return this.test("isUniqueVariantName", message, function (list) {
      const set = [
        ...new Set(list.map((val) => val.variantType?.toLowerCase())),
      ];
      const isUnique = list.length === set.length;
      if (isUnique) {
        return true;
      }
      const index = list.findIndex(
        (val, index) => val.variantType.toLowerCase() !== set[index]
      );
      return this.createError({
        path: `variants[${index}].variantType`,
        message: message,
      });
    });
  });
  return Yup.object().shape({
    name: Yup.string().required(Localise(messages, "Please enter Name")),
    productId: Yup.string().required(Localise(messages, "Please enter ID")),
    productType: Yup.string().required(
      Localise(messages, "Please select Type")
    ),
    description: Yup.string().when(["group", "availability"], {
      is: (group) => group !== "mol",
      then: Yup.string().test(
        "description",
        Localise(messages, Localise(messages, "Please enter Description")),
        function (value) {
          const regex = /(&nbsp;|<([^>]+)>)/gi;
          const result = value?.replace(regex, "").trim();

          return !!result;
        }
      ),
    }),
    defaultVariantName: Yup.string().when(["standalone", "productType"], {
      is: (standalone, productType) => !standalone && productType !== "addon",
      then: Yup.string().required(
        Localise(messages, "Please select default variation")
      ),
    }),
    price: Yup.string().when("standalone", {
      is: true,
      then: Yup.string()
        .required(Localise(messages, "Please enter Price"))
        .test(
          "price",
          Localise(messages, "Price cannot be greater than Compare at Price"),
          function (value) {
            const { compareAtPrice } = this.parent;
            return compareAtPrice
              ? parseFloat(compareAtPrice) >= parseFloat(value)
              : true;
          }
        )
        .test("price", function (value) {
          const { srpMinPrice } = this.parent;
          if (!srpMinPrice || parseFloat(value) >= parseFloat(srpMinPrice)) {
            return true;
          }

          return this.createError({
            message: `Price must be above $${parseFloat(srpMinPrice).toFixed(
              2
            )} ${currency}`,
          });
        })
        .matches(
          /^[1-9]\d*(\.\d+)?$/,
          Localise(messages, "Please enter non-zero price in decimals")
        ),
    }),
    compareAtPrice: Yup.string().when("standalone", {
      is: true,
      then: Yup.string()
        .matches(
          /^[1-9]\d*(\.\d+)?$/,
          Localise(messages, "Please enter non-zero price in decimals")
        )
        .test(
          "compareAtPrice",
          Localise(messages, "Compare At Price should not be less than Price"),
          function (value) {
            const { price } = this.parent;
            if (price && value && parseFloat(value) < parseFloat(price)) {
              return false;
            }
            return true;
          }
        ),
    }),
    image: Yup.string().when("standalone", {
      is: true,
      then: Yup.string().test(
        "imageNotFound",
        Localise(messages, "Image not found, please try again later"),
        function (value) {
          if (this.parent.group === "mol" && !get(this.parent, "image")) {
            return !!value;
          } else {
            return true;
          }
        }
      ),
    }),
    activeEndDate: Yup.string()
      .test(
        "activeEndDate",
        Localise(messages, "End date must come after the Start date"),
        function (value) {
          const { activeStartDate, availability } = this.parent;
          const isEndDateAfterStart =
            !value || !moment(activeStartDate).isSameOrAfter(value);

          if (availability && !isEndDateAfterStart) {
            return false;
          }
          return true;
        }
      )
      .test(
        "activeEndDate",
        Localise(messages, "Please select a current or future date"),
        function (value) {
          const todayDate = moment().format("YYYY-MM-DD");
          const isEndDatePast = moment(todayDate).isAfter(value);
          const { availability } = this.parent;
          if (availability && isEndDatePast) {
            return false;
          }
          return true;
        }
      ),
    fulfillmentEndDate: Yup.string()
      .test(
        "fulfillmentEndDate",
        Localise(messages, "End date must come after the Start date"),
        function (value) {
          const { fulfillmentStartDate, availability } = this.parent;
          const isEndDateAfterStart =
            !value || !moment(fulfillmentStartDate).isSameOrAfter(value);

          if (availability && !isEndDateAfterStart) {
            return false;
          }
          return true;
        }
      )
      .test(
        "fulfillmentEndDate",
        Localise(messages, "Please select a current or future date"),
        function (value) {
          const todayDate = moment().format("YYYY-MM-DD");
          const isEndDatePast = moment(todayDate).isAfter(value);
          const { availability } = this.parent;
          if (availability && isEndDatePast) {
            return false;
          }
          return true;
        }
      ),

    variants: Yup.array().when("standalone", {
      is: false,
      then: Yup.array()
        .of(
          Yup.object().shape({
            variantType: Yup.string().when(["price"], {
              is: (price) => parseFloat(price) > 0,
              then: Yup.string().required(
                Localise(messages, "Please enter Variation Name")
              ),
            }),
            price: Yup.string().when(["isExists", "active"], {
              is: (isExists, active) => isExists || active,
              then: Yup.string()
                .required(Localise(messages, "Please enter Price"))
                .test(
                  "price",
                  Localise(
                    messages,
                    "Price should not be greater than compare at price"
                  ),
                  function (value) {
                    const { compareAtPrice } = this.parent;
                    return compareAtPrice
                      ? parseFloat(compareAtPrice) >= parseFloat(value)
                      : true;
                  }
                )
                .test("price", function (value) {
                  const { srpMinPrice, productId } = this.parent;

                  if (
                    !srpMinPrice ||
                    parseFloat(value) >= parseFloat(srpMinPrice) ||
                    productId.includes("GIFT_CARD")
                  ) {
                    return true;
                  }

                  return this.createError({
                    message: `Price must be above $${parseFloat(
                      srpMinPrice
                    ).toFixed(2)} ${currency}`,
                  });
                })
                .matches(
                  /^[1-9]\d*(\.\d+)?$/,
                  Localise(messages, "Please enter non-zero price in decimals")
                ),
            }),

            compareAtPrice: Yup.string().when(["isExists", "active"], {
              is: (isExists, active) => isExists || active,
              then: Yup.string()
                .test(
                  "compareAtPrice",
                  Localise(
                    messages,
                    "Compare at Price should not be less than Price"
                  ),
                  function (value) {
                    const { price } = this.parent;
                    if (
                      price &&
                      value &&
                      parseFloat(value) < parseFloat(price)
                    ) {
                      return false;
                    }
                    return true;
                  }
                )
                .matches(
                  /^[1-9]\d*(\.\d+)?$/,
                  Localise(messages, "Please enter non-zero price in decimals")
                ),
            }),
            customFlowerType: Yup.string().when(["isExists", "active"], {
              is: (isExists, active) => isExists || active,
              then: Yup.string().test(
                "flowerType",
                Localise(messages, "Filter type already exists"),

                function (value) {
                  const { flowerType } = this.parent;
                  const isExists =
                    flowerType.findIndex(
                      (val) => val.toLowerCase() === value?.toLowerCase().trim()
                    ) > -1;
                  return !isExists;
                }
              ),
            }),
            customProductColor: Yup.string().when(["isExists", "active"], {
              is: (isExists, active) => isExists || active,
              then: Yup.string().test(
                "productColor",
                Localise(messages, "Filter type already exists"),

                function (value) {
                  const { productColor } = this.parent;
                  const isExists =
                    productColor.findIndex(
                      (val) => val.toLowerCase() === value?.toLowerCase().trim()
                    ) > -1;
                  return !isExists;
                }
              ),
            }),
            image: Yup.string().when("active", {
              is: true,
              then: Yup.string()
                .test(
                  "imageIsRequired",
                  Localise(messages, "Please upload an image"),
                  function (value) {
                    if (
                      get(this.options.from[1], "value.showOnWebsite") &&
                      get(this.options.from[1], "value.group") !== "mol"
                    ) {
                      return !!value;
                    } else {
                      return true;
                    }
                  }
                )
                .test(
                  "imageNotFound",
                  Localise(messages, "Image not found, please try again later"),
                  function (value) {
                    if (
                      get(this.options.from[1], "value.group") === "mol" &&
                      !get(this.options.from[1], "value.image")
                    ) {
                      return !!value;
                    } else {
                      return true;
                    }
                  }
                ),
            }),
          })
        )
        .isUniqueVariantName(Localise(messages, "Variant name already exists"))
        .test(
          "isInvalidMOLProduct",
          Localise(
            messages,
            "Oops! There is an issue with this product. We're working on fixing it."
          ),
          function (value) {
            return (
              (get(this.options.from[1], "value.standalone") &&
                value.length === 0) ||
              (!get(this.options.from[1], "value.standalone") &&
                value.length > 0)
            );
          }
        )
        .test(
          "hasOneVariantAtleast",
          Localise(messages, "Please select at least one variation"),
          (values) => {
            return (
              values.filter(
                (object) => object.active && object.productId && object.price
              ).length > 0
            );
          }
        ),
    }),
  });
};
