import RoutesDashboard from "./routes-dashboard";
import DeliveryTasks from "./delivery-service-dashboard";
import DeliveryDashboard from "./delivery-dashboard";

export const screenList = [
  {
    key: "deliveryDashboard",
    label: "Dashboard",
    component: DeliveryDashboard,
    entitlement: "DELIVERY_DASHBOARD",
    pageTitle: "Delivery Dashboard",
  },
  {
    key: "routes",
    label: "Routes",
    component: RoutesDashboard,
    entitlement: "ROUTES_TAB",
  },
  {
    key: "DS",
    label: "Delivery Service",
    component: DeliveryTasks,
    entitlement: "DELIVERY_TASKS",
  },
];

export const deliveryPickupTimeWindow = [
  { key: "Morning", value: ["09"] },
  { key: "Afternoon", value: [15] },
  { key: "Morning & Afternoon", value: ["09", 15] },
  { key: "Every 30 minutes", value: [] },
];

export const RouteStatusDisplayNames = {
  DRAFT: "Draft",
  PLANNED: "Ready To Go",
  INPROGRESS: "Out for Delivery",
  COMPLETED: "Completed",
  INCOMPLETE: "Incomplete",
  DELETED: "Deleted",
};
