import React, { useRef, useEffect, useContext } from "react";
import {
  SafeAreaView,
  Text,
  View,
  ActivityIndicator,
  Platform,
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import I18NContext from "library/contexts/i18N";
import useStateIfMounted from "library/utils/useStateIfMounted";
import isEmpty from "lodash/isEmpty";
import { colors, fonts } from "styles/theme";
import tw from "tailwind-rn";
import AutomationStorage from "library/storage/automationStorage";

import ScreenHeader from "components/containers/header/screen";
import BulkScan from "components/views/drawer/bulk-scan";
import BulkScanActions from "components/views/drawer/bulk-scan/bulk-scan-actions";
import fetchOrders from "library/utils/fetchOrders";
import ScannerListener from "./scanner-listener";
import { CustomModal, ToasterHandler } from "components/elements";
import {
  ListOfOrderStatus,
  InitOrdersData,
} from "components/views/drawer/order-listing/current-orders/config";

import {
  setActionsQR,
  setScannedOrders,
  getOrderDetailsFromScan,
  updateActionsForOrder,
} from "library/sagas/views/home/drawer/qr-scan/slice";
import { fetchData } from "library/sagas/ongoing/current-orders/slice";
import { getOrderFromScan } from "library/sagas/views/home/drawer/delivery/slice";
import {
  selectQrScanVariant,
  selectCurrentPage,
} from "library/sagas/ongoing/global-data/selector";
import {
  selectScannedOrders,
  selectOrdersCount,
} from "library/sagas/views/home/drawer/qr-scan/selector";
import { selectState as selectCurrentOrdersInfo } from "library/sagas/ongoing/current-orders/selector";

const QrCodeScanner = ({ setScannedOrder = () => {}, ordersList = {} }) => {
  const dispatch = useDispatch();
  const qrScanVariant = useSelector(selectQrScanVariant);
  const currentOrdersInfo = useSelector(selectCurrentOrdersInfo);
  const scannedOrders = useSelector(selectScannedOrders);
  const currentPage = useSelector(selectCurrentPage);
  const ordersCount = useSelector(selectOrdersCount);

  const { messages, Localise } = useContext(I18NContext);
  const [qrValue, setQrValue] = useStateIfMounted("");
  const [isConnected, setIsConnected] = useStateIfMounted(true);
  const [modalVisible, setModalVisibile] = useStateIfMounted(false);
  const [showProcessing, setShowProcessing] = useStateIfMounted(false);

  const [errorText, setErrorText] = useStateIfMounted("");

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const getScannerStatus = () => {
    if (isElectronApp) {
      const HID = window.require("node-hid");

      try {
        const devices = HID.devices() || [];
        const list =
          devices.filter((each) =>
            each?.product?.includes("Bar Code Scanner")
          ) || [];
        setIsConnected(list.length > 0);
      } catch (error) {
        console.log("Failed to get devices: ", error);
      }
    }
  };

  let pollTimer = useRef(null);
  let pollingTimer = useRef(null);
  const countRef = useRef(null);

  const pollingInterval = 1 * 30 * 1000; // 30 secs pollInterval
  const pollInterval = 2 * 60 * 1000; // 2 mins pollInterval
  const { timeStamp } = currentOrdersInfo || {};

  // pulling latest orders - using existing fetchOrders function
  const pullOrders = () => {
    currentPage === "qr-code-scan" &&
      fetchOrders(
        "current",
        { ...currentOrdersInfo },
        ListOfOrderStatus,
        InitOrdersData,
        (data) => dispatch(fetchData(data)),
        () => {
          updateScannedOrders();
        }
      );
  };

  const updateScannedOrders = () => {
    // update the scanned orders here, FUTURE SCOPE.
  };

  useEffect(() => {
    if (Platform.OS === "web") {
      const qrCodesList = AutomationStorage.getAutomationQrCode() || [];
      if (!isEmpty(qrCodesList)) {
        qrCodesList.forEach((qrCode) => {
          setTimeout(() => {
            setQrValue(qrCode);
          }, 1500);
        });
      }

      // pullOrders on load of the page.
      pullOrders();
    }

    if (isElectronApp) {
      if (pollingTimer) clearInterval(pollingTimer);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      pollingTimer = setInterval(getScannerStatus, pollingInterval);
      getScannerStatus();
    }
    return () => {
      clearInterval(pollTimer);
    };
  }, []);

  useEffect(() => {
    clearTimeout(pollTimer.current);

    if (timeStamp && pollInterval) {
      pollTimer.current = setTimeout(() => {
        // pullOrders for every 2 mins interval to maintain fresh list of orders.
        pullOrders();
      }, pollInterval);
    }
    return () => {
      clearTimeout(pollTimer.current);
    };
  }, [timeStamp]);

  useEffect(() => {
    if (!isEmpty(qrValue)) {
      // identifying order scan - orders have oid as mandatory attribute
      if (Object.keys(qrValue).includes("oid")) {
        if (qrScanVariant === "order-route-scan") {
          // when order scanned from create-route/edit-route page
          dispatch(
            getOrderFromScan({
              params: { ...qrValue, ordersList },
              resolve: (data) => {
                setScannedOrder(data);
                setShowProcessing(false);
              },
              reject: (errorMessage) => {
                setShowProcessing(false);
                ToasterHandler(
                  "uh oh",
                  `${qrValue.oid}: ${Localise(messages, errorMessage)}`,
                  false,
                  120 * 1000
                );
              },
            })
          );
        } else {
          !IsDuplicateAndLimitCheck(qrValue) &&
            dispatch(
              getOrderDetailsFromScan({
                params: qrValue,
                resolve: (data) => {
                  const { tileRecord, foundInListing, orderDetails } = data;

                  // If response returned from the order details
                  if (!foundInListing) {
                    const {
                      orderLastModifiedDate,
                      orderDetailsLastPrintedDate,
                    } = orderDetails;

                    dispatch(
                      setScannedOrders({
                        ...tileRecord,
                        orderLastModifiedDate,
                        orderDetailsLastPrintedDate,
                        isDetailResponse: true,
                      })
                    );
                  }
                  setShowProcessing(false);
                },
                reject: (res) => {
                  setShowProcessing(false);
                  ToasterHandler("uh oh", Localise(messages, res));
                },
              })
            );
        }
      } else if (Object.keys(qrValue).includes("id")) {
        // actions QR
        dispatch(setActionsQR(qrValue));
      } else {
        setShowProcessing(false);
        ToasterHandler(
          "uh oh",
          Localise(messages, "QR code is not from MercuryHQ")
        );
      }
    }
  }, [qrValue]);

  useEffect(() => {
    countRef.current = ordersCount;
  }, [ordersCount]);

  // Incase of first order - to show reprint we are calling the order details api in the background.
  useEffect(() => {
    if (scannedOrders.length === 1) {
      const {
        orderItemId,
        receivingMember,
        sendingMember,
        direction,
        deliveryMethod,
        isDetailResponse = false,
      } = scannedOrders[0];
      const sourceMemberCode =
        direction === "INBOUND"
          ? receivingMember?.memberCode
          : sendingMember?.memberCode;

      const qrPayload = {
        oid: orderItemId,
        sm: sourceMemberCode,
        dm: deliveryMethod,
        fetchFromDetails: true,
      };
      !isDetailResponse &&
        dispatch(
          getOrderDetailsFromScan({
            params: qrPayload,
            resolve: (data) => {
              const { tileRecord, orderDetails } = data;
              const { orderLastModifiedDate, orderDetailsLastPrintedDate } =
                orderDetails;

              // If that order is not already deleted.
              if (countRef.current !== 0) {
                dispatch(
                  setScannedOrders({
                    ...tileRecord,
                    orderLastModifiedDate,
                    orderDetailsLastPrintedDate,
                    isDetailResponse: true,
                  })
                );
                // update actions for this order to retrigger the actions calculation - dummy trigger to update
                dispatch(
                  updateActionsForOrder({ recordId: tileRecord.orderItemId })
                );
              }

              setShowProcessing(false);
            },
            reject: (res) => {
              setShowProcessing(false);
              ToasterHandler("uh oh", Localise(messages, res));
            },
          })
        );
    }
  }, [scannedOrders]);

  const IsDuplicateAndLimitCheck = (qrValue) => {
    const { oid: orderItemId = "" } = qrValue;
    const IsDuplicate = scannedOrders.length
      ? scannedOrders.filter((each) => each.orderItemId === orderItemId)
          .length > 0
      : false;
    const isLimitExceeded = scannedOrders.length >= 30;
    if (IsDuplicate) {
      setShowProcessing(false);
      ToasterHandler(
        "uh oh",
        Localise(messages, "This order has already been scanned")
      );
    }
    if (isLimitExceeded) {
      setShowProcessing(false);
      ToasterHandler(
        "uh oh",
        Localise(messages, "You can bulk scan up to 30 orders at a time")
      );
    }
    return IsDuplicate || isLimitExceeded;
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {qrScanVariant !== "order-route-scan" ? (
        <>
          <ScreenHeader
            title={Localise(messages, "QR Scanning")}
            settingsRoute={{
              page: "shop-settings",
              screen: "shop-details",
            }}
          />
        </>
      ) : null}
      <ScannerListener
        setQrValue={setQrValue}
        modalVisible={modalVisible}
        setModalVisibile={setModalVisibile}
        errorText={errorText}
        setErrorText={setErrorText}
        setShowProcessing={setShowProcessing}
      />

      {currentPage === "qr-code-scan" ? (
        <>
          <View style={tw("flex-1 flex flex-row mt-2")}>
            <View
              style={{
                width: "60%",
                paddingVertical: 10,
                paddingLeft: 20,
                paddingRight: 5,
              }}
            >
              <View style={{ paddingBottom: 20 }}>
                <Text style={{ ...fonts.heading2 }}>
                  {Localise(
                    messages,
                    isConnected
                      ? "MercuryHQ is now connected to your scanner"
                      : "Please connect your scanner to start scanning"
                  )}
                </Text>
                <Text>
                  {Localise(
                    messages,
                    "Scan QR codes to add and manage your orders."
                  )}
                </Text>
              </View>
              <BulkScan />
            </View>
            <View
              style={{
                width: "40%",
              }}
            >
              <BulkScanActions
                setShowProcessing={setShowProcessing}
                pullOrders={pullOrders}
              />
            </View>
          </View>
        </>
      ) : null}
      {showProcessing && (
        <CustomModal
          modalVisible={showProcessing}
          modalContent={{
            content: (
              <View style={tw("flex flex-row items-center")}>
                <ActivityIndicator
                  style={{ marginLeft: 5, marginTop: -5 }}
                  color={colors.activityIndicator}
                />
                <Text style={{ marginLeft: 10 }}>
                  {`${Localise(messages, `Processing...`)}`}
                </Text>
              </View>
            ),
          }}
          contentStyle={[{ backgroundColor: "white" }, tw(`p-4`)]}
        />
      )}
    </SafeAreaView>
  );
};

export default QrCodeScanner;
