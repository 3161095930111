import * as React from "react";
import { View } from "react-native";
import { Text, Badge, Image } from "react-native-elements";
import { PopupMenu, Tooltip } from "components/elements";
import tw from "tailwind-rn";
import IMAGES from "static/assets/images";
import { Platform } from "react-native";
import isEmpty from "lodash/isEmpty";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

// eslint-disable-next-line react/display-name
const SortComponent = React.memo(
  ({
    sortOptions,
    sort,
    setSort,
    isSmallScreen,
    showFiltersTitle = true,
    defaultValue = "Delivery Date: Old to New",
    isTitleOrders = false,
  }) => {
    const onSort = (e) => {
      if (e) setSort(e, e);
    };

    const { messages, Localise } = React.useContext(I18NContext);
    const { isDesktop } = React.useContext(DeviceContext);

    return (
      <PopupMenu
        menuOptions={sortOptions}
        optionsContainerStyle={{
          width: 250,
          padding: 5,
        }}
        onSelect={onSort}
        selectedValue={(sort && sort.value) || defaultValue}
        testID="sort"
        accessibilityLabel="sort"
      >
        <View
          style={tw(
            `${
              isSmallScreen ? "flex-col" : "flex-row"
            } justify-around items-center ${
              isDesktop && !showFiltersTitle ? "ml-5" : ""
            }`
          )}
        >
          <Tooltip text={Localise(messages, "Sort")} renderForWebOnly={true}>
            <Image
              style={{
                width:
                  isSmallScreen || showFiltersTitle
                    ? 18
                    : isTitleOrders
                    ? 35
                    : 22,
                height:
                  isSmallScreen || showFiltersTitle
                    ? 18
                    : isTitleOrders
                    ? 35
                    : 22,
              }}
              resizeMode="cover"
              source={IMAGES["sort"]}
            />
          </Tooltip>

          {!isSmallScreen && showFiltersTitle && (
            <Text>{Localise(messages, "Sort")}</Text>
          )}
          {!isEmpty(sort) && (
            <Badge
              containerStyle={[
                {
                  right: !isSmallScreen ? -18 : -10,
                  ...tw("mb-4"),
                  position: "absolute",
                },
                Platform.OS !== "web" && { top: -10 },
              ]}
              badgeStyle={{ backgroundColor: "#e5175e" }}
              value={1}
            />
          )}
        </View>
      </PopupMenu>
    );
  }
);

export default SortComponent;
