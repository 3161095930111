import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TouchableOpacity, View } from "react-native";
import { Text } from "react-native-elements";

import { fonts, backgroundColors, colors } from "styles/theme";
import tw from "tailwind-rn";

export const SearchResults = ({ input, action, Localise, messages }) => {
  const dispatch = useDispatch();
  const search = useSelector(input);

  const onClear = () => {
    dispatch(action({ type: "search", value: "" }));
  };

  return search ? (
    <View
      style={{
        ...tw("flex-row items-center"),
        paddingRight: 30,
        paddingVertical: 10,
        width: "15%",
        flexGrow: 1,
      }}
    >
      <Text style={fonts.heading4}>
        {Localise(messages, "Search Results")}:
      </Text>
      <Text>{` "${search}" `} </Text>
      <Text style={fonts.link1} onPress={onClear}>
        {Localise(messages, "Clear")}
      </Text>
    </View>
  ) : null;
};

export const FilterResults = ({ input, action, Localise, messages }) => {
  const dispatch = useDispatch();
  const filters = useSelector(input);

  const onClear = (index) => {
    let result = [];
    if (index >= 0) {
      result = [...filters];
      result.splice(index, 1);
    }
    dispatch(action({ type: "filters", value: result }));
  };

  return filters.length > 0 ? (
    <View
      style={{
        ...tw("flex-row flex-wrap items-center"),
        width: "40%",
        paddingRight: 10,
        flexGrow: 1,
      }}
    >
      <Text style={{ ...fonts.heading4 }}>
        {Localise(messages, "Filters")}:
      </Text>
      {filters.map(({ label, value }, index) => {
        return (
          <TouchableOpacity
            key={index}
            style={{
              ...tw("flex-row justify-between items-center m-2"),
              backgroundColor: backgroundColors.navBar,
              borderColor: "#a9a9a9",
              borderWidth: 0.5,
              borderRadius: 3,
              padding: 10,
              shadowColor: colors.medium,
              shadowOffset: {
                width: 0,
                height: 0.5,
              },
              shadowRadius: 1,
              shadowOpacity: 0.5,
            }}
            onPress={() => onClear(index)}
            testID={value}
            accessibilityLabel={value}
          >
            <Text
              style={{
                paddingRight: 5,
                color: colors.highlighter,
                fontWeight: "bold",
              }}
            >
              X
            </Text>
            <Text style={{ color: colors.highlighter, fontWeight: "bold" }}>
              {label}
            </Text>
          </TouchableOpacity>
        );
      })}
      <Text
        style={fonts.link1}
        onPress={onClear}
        testID={"clear_all"}
        accessibilityLabel={"clear_all"}
      >
        {Localise(messages, "Clear")}
      </Text>
    </View>
  ) : null;
};

export const ShopResults = ({ input, action, Localise, messages }) => {
  const dispatch = useDispatch();
  const shops = useSelector(input);

  const onClear = (index) => {
    let result = [];
    if (index >= 0) {
      result = [...shops];
      result.splice(index, 1);
    }
    dispatch(action({ type: "shops", value: result }));
  };

  return shops.length ? (
    <View
      style={{
        ...tw("flex-row flex-wrap items-center"),
        width: "30%",
        paddingRight: 10,
        flexGrow: 1,
      }}
    >
      <Text style={{ ...fonts.heading4 }}>
        {Localise(messages, "Showing Shops")}:
      </Text>
      {shops.map(({ label, value }, index) => {
        return (
          <TouchableOpacity
            key={index}
            style={{
              ...tw("flex-row justify-between items-center m-2"),
              backgroundColor: backgroundColors.navBar,
              borderColor: "#a9a9a9",
              borderWidth: 0.5,
              borderRadius: 3,
              padding: 10,
              shadowColor: colors.medium,
              shadowOffset: {
                width: 0,
                height: 0.5,
              },
              shadowRadius: 1,
              shadowOpacity: 0.5,
            }}
            onPress={() => onClear(index)}
            testID={value}
            accessibilityLabel={value}
          >
            <Text
              style={{
                paddingRight: 5,
                color: colors.highlighter,
                fontWeight: "bold",
              }}
            >
              X
            </Text>
            <Text style={{ color: colors.highlighter, fontWeight: "bold" }}>
              {label}
            </Text>
          </TouchableOpacity>
        );
      })}
      <Text
        style={fonts.link1}
        onPress={onClear}
        testID={"clear_all"}
        accessibilityLabel={"clear_all"}
      >
        {Localise(messages, "Clear")}
      </Text>
    </View>
  ) : null;
};

export const SortResults = ({ input, action, Localise, messages }) => {
  const dispatch = useDispatch();
  const sort = useSelector(input);

  const onClear = () => {
    dispatch(action({ type: "sort", value: "" }));
  };

  return sort != "" ? (
    <View
      style={{
        ...tw("flex-row items-center"),
        paddingRight: 10,
        width: "15%",
        flexGrow: 1,
      }}
    >
      <Text style={{ ...fonts.heading4 }}>{Localise(messages, "Sort")}:</Text>
      <TouchableOpacity
        style={{
          ...tw("flex-row justify-between items-center m-2"),
          backgroundColor: backgroundColors.navBar,
          borderColor: "#a9a9a9",
          borderWidth: 0.5,
          borderRadius: 3,
          padding: 10,
          shadowColor: colors.medium,
          shadowOffset: {
            width: 0,
            height: 0.5,
          },
          shadowRadius: 1,
          shadowOpacity: 0.5,
        }}
        onPress={onClear}
        testID={sort.value}
        accessibilityLabel={sort.value}
      >
        <Text
          style={{
            paddingRight: 5,
            color: colors.highlighter,
            fontWeight: "bold",
          }}
        >
          X
        </Text>
        <Text style={{ color: colors.highlighter, fontWeight: "bold" }}>
          {Localise(messages, sort.label)}
        </Text>
      </TouchableOpacity>
      <Text
        style={fonts.link1}
        onPress={onClear}
        testID={"clear_all"}
        accessibilityLabel={"clear_all"}
      >
        {Localise(messages, "Clear")}
      </Text>
    </View>
  ) : null;
};
