import React from "react";
import { Platform, Text } from "react-native";
import { CustomModal } from "components/elements";
import I18NContext from "library/contexts/i18N";
import { colors } from "styles/theme";
import tw from "tailwind-rn";

const ConfirmActionModal = ({
  modalVisible,
  handlePrimary,
  handleSecondary,
  data = {},
  modalStyle: customModalStyle = {},
  contentStyle = {},
  isReduceBtnSpacing = false,
}) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const {
    modal: {
      primary: textPrimary = "Continue",
      secondary: textSecondary = "Nevermind",
      content: textContent = "Are you sure you want to proceed?",
    } = {},
  } = data;

  const modalContent = {
    content: (
      <Text style={{ ...tw("p-4"), fontSize: 15, color: colors.highlighter }}>
        {Localise(messages, textContent)}
      </Text>
    ),
    buttons: [
      { type: "secondary", title: Localise(messages, textSecondary) },
      { type: "primary", title: Localise(messages, textPrimary) },
    ],
  };

  const modalStyle =
    Platform.OS !== "web"
      ? {
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          backgroundColor: "#00000070",
          color: "#FFFFFF",
        }
      : {};
  return (
    <CustomModal
      modalVisible={modalVisible}
      modalContent={modalContent}
      primaryhandler={handlePrimary}
      secondaryhandler={handleSecondary}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: "white", maxWidth: 320 },
        { ...contentStyle },
      ]}
      customSecondaryButtonStyle={{
        paddingVertical: 7,
        paddingHorizontal: 20,
      }}
      modalStyle={{ ...modalStyle, ...customModalStyle }}
      isReduceBtnSpacing={isReduceBtnSpacing}
    ></CustomModal>
  );
};

export default ConfirmActionModal;
