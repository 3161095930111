/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Platform,
  Text,
  TouchableOpacity,
  View,
  Linking,
  useWindowDimensions,
} from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Image, CheckBox } from "react-native-elements";
import { useFormikContext } from "formik";

import ShopCodePicker from "components/containers/shop-code-picker";
import {
  Picker,
  PopupMenu,
  ToasterHandler,
  FileSelector,
  Tooltip,
} from "components/elements";
import {
  SideCar,
  LabelField,
  ImageField,
  ConfirmModal,
  SecondaryButton,
} from "components/wrappers";
import SingleScreen from "components/containers/single-screen-new";
import SplitScreen from "components/containers/split-screen-new";

import {
  getColorCodes,
  ProductSortProps,
  CollectionSortProps,
  CollectionMeatBallsMenu,
  ProductMeatBallsMenu,
  CollectionProductMeatBallsMenu,
  AddonBulkActions,
  CatalogBulkActions,
  CollectionBulkActions,
  CatalogPriceMinimumBulkActions,
  SettingsMenuOptions,
  CreateMenuOptions,
  CatalogMeatBallsMenu,
} from "./ui-config";

import {
  setSideCar,
  navigateScreen,
  setShopCode,
} from "library/sagas/views/home/drawer/product-catalog/common/slice";
import {
  selectScreenParam,
  selectScreenTitle,
  selectProductLookupById,
  selectShopCode,
  selectAllowSyndicate,
  selectIsCatalogManager,
  selectScreen,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import { getRouteDetails } from "library/utils/url";
import { Entitlements } from "library/utils/entitlements";
import UserProfileStorage from "library/storage/userProfile";
import AppSettingsContext from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";

import { IMAGES } from "static/assets/images";
import { theme, backgroundColors, colors, fonts } from "styles/theme";
import styles from "./styles";
import tw from "tailwind-rn";

/** Functional Logic Components */

export const ScreenComponent = ({ screenRef, rootNavigation, children }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const sideCar = useSelector(selectScreenParam("sideCar"));
  const shopCode = useSelector(selectShopCode);

  const shopNames = UserProfileStorage.getAllShopNames();
  const { memberCodes = [] } = React.useContext(UserProfileContext);
  const { isDesktop, isMobile, isTablet } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const { routes = [] } = navigation?.dangerouslyGetState();
  const { width: dWidth } = useWindowDimensions();
  const currentRoute = routes[routes.length - 1] || {};
  const prevRoute = routes[routes.length - 2] || {};
  const selectedCode = shopCode === "all" ? memberCodes[0] : shopCode;
  const shopName =
    shopCode === "all" && memberCodes.length > 1
      ? "All Shops"
      : `${shopNames[selectedCode]}, ${selectedCode}`;

  const {
    name: currentRouteName,
    params: { handle: currentRouteHandle } = {},
  } = currentRoute;
  const currentScreenName = useSelector(selectScreenTitle(currentRouteName));
  const currentScreenHandle = useSelector(
    selectScreenTitle(currentRouteHandle)
  );
  const currentScreenTitle = currentScreenHandle || currentScreenName;
  const { name: prevRouteName, params: { handle: prevRouteHandle } = {} } =
    prevRoute;
  const prevScreenName = useSelector(selectScreenTitle(prevRouteName));
  const prevScreenHandle = useSelector(selectScreenTitle(prevRouteHandle));
  const prevScreenTitle = `${prevScreenName}${
    prevScreenHandle ? ` / ${prevScreenHandle}` : ""
  }`;

  const CustomHeader = () => {
    return (
      <View>
        <View
          style={[
            tw("flex flex-row w-full justify-between items-center pb-3"),
            {
              paddingTop: isDesktop ? 30 : 16,
            },
          ]}
        >
          <View
            style={[
              {
                display: "flex",
                justifyContent: isDesktop ? "flex-start" : "space-between",
                alignItems: "flex-start",
                flexWrap: isMobile ? "wrap" : "nowrap",
                width: isMobile ? dWidth - 20 : "100%",
                flexDirection: "column",
              },
            ]}
          >
            <View
              style={[
                tw(`flex flex-row items-center justify-between flex-wrap`),
                {
                  width: isMobile ? dWidth - 50 : "100%",
                },
              ]}
            >
              <Text
                style={{
                  paddingRight: 12,
                  ...fonts.heading1,
                  ...(isTablet && { fontSize: 16, fontWeight: "400" }),
                }}
              >
                {Localise(messages, currentScreenTitle)}
              </Text>
              <Text style={fonts.heading4} key={"middle"}>
                {Localise(messages, "Showing")}{" "}
                {`${Localise(messages, "Member")} : ${shopName}`}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isDesktop && (
                  <>
                    <ShopCodePickerComponent />
                    <HeaderMenusControl />
                  </>
                )}
              </View>
            </View>
            {Breadcrumb({ currentRoute, prevRoute })}

            {!isDesktop && (
              <View style={tw("flex flex-col items-start justify-center")}>
                <ShopCodePickerComponent />
                <HeaderMenusControl />
              </View>
            )}
          </View>
        </View>
        {isMobile ? (
          <View
            style={{
              marginBottom: 10,
            }}
          />
        ) : (
          <View
            style={{
              borderBottomColor: colors.light,
              borderBottomWidth: 1,
              marginBottom: 20,
              shadowOffset: { width: 0, height: 2 },
              shadowOpacity: 0.4,
              shadowRadius: 7,
            }}
          />
        )}
      </View>
    );
  };
  return children && children.length ? (
    <SplitScreen
      ScreenOne={children[0]}
      screenOneRef={screenRef}
      ScreenTwo={
        <SideCar
          titleSelector={selectScreenTitle(sideCar)}
          onCloseAction={setSideCar}
        >
          {children[1]}
        </SideCar>
      }
      showSecondScreen={!!sideCar}
      CustomHeader={CustomHeader}
      rootNavigation={rootNavigation}
      navHeaderProps={{
        title: currentScreenTitle,
        onTrigger: () => dispatch(setSideCar("")),
      }}
    />
  ) : (
    <SingleScreen
      Screen={<View style={{ maxWidth: 1024 }}>{children}</View>}
      headerProps={{
        title: currentScreenTitle,
        siblings: () => Breadcrumb({ currentRoute, prevRoute }),
        style: { flexDirection: isDesktop ? "row" : "column" },
      }}
      rootNavigation={rootNavigation}
      navHeaderProps={{
        title: prevScreenTitle,
        onTrigger: () => dispatch(navigateScreen(prevRoute)),
      }}
    />
  );
};

const MeatBallsMenu = ({ menuOptions, data }) => {
  const allowSyndicate = useSelector(selectAllowSyndicate);
  const { Localise, messages } = useContext(I18NContext);
  const [showPopup, setShowPopup] = useState(false);
  const [currentMenu, setCurrentMenu] = useState();

  const dispatch = useDispatch();

  const onAction = (key) => {
    const { action, success, error } = menuOptions.find((e) => e.key === key);

    const [todo, value] = key.split(/::/);

    dispatch(
      action({
        params: data.groupId
          ? {
              id: data.groupId,
              patch: {
                todo,
                data: {
                  ...(todo === "status" && value && { status: value }),
                },
              },
            }
          : {
              id: data.productId || data.id,
              name: data.name,
              handle: data.handle,
              group: data.group,
              section: data.catalogType,
              patch: {
                todo,
                data: {
                  ...(todo === "status" && value && { status: value }),
                  ...(todo === "removeFromCollection" &&
                    value && { collection: value }),
                },
              },
            },
        resolve: () => {
          success
            ? ToasterHandler("looks good", Localise(messages, success(data)))
            : null;
        },
        reject: () => {
          error
            ? ToasterHandler("uh oh", Localise(messages, error(data)))
            : null;
        },
      })
    );
  };

  if (!menuOptions.length) return null;

  return (
    <>
      <PopupMenu
        containerStyle={styles.menuContainer}
        optionsContainerStyle={{
          width: 150,
          padding: 5,
        }}
        menuOptions={menuOptions}
        onSelect={(menu) => {
          if (
            (allowSyndicate && !["duplicate"].includes(menu)) ||
            menu === "delete"
          ) {
            setShowPopup(!showPopup);
            setCurrentMenu(menu);
          } else {
            onAction(menu);
          }
        }}
        testID="list_item_menu"
        accessibilityLabel="list_item_menu"
      >
        <Image
          style={{
            width: 20,
            height: 20,
          }}
          resizeMode="contain"
          source={
            IMAGES[
              data.status === "inactive" ? "three-dots" : "three-white-dots"
            ]
          }
        />
      </PopupMenu>
      <ConfirmModal
        modalVisible={!!showPopup}
        handlePrimary={() => {
          onAction(currentMenu);
          setShowPopup(!showPopup);
        }}
        handleSecondary={() => {
          setCurrentMenu();
          setShowPopup(!showPopup);
        }}
        data={{
          modal: {
            content: `${
              allowSyndicate
                ? "This change will apply to All shops and will override any local shop changes." +
                  "\n\n"
                : ""
            }${
              currentMenu === "delete"
                ? "Are you sure you want to delete this product?"
                : ""
            }`,
          },
        }}
      />
    </>
  );
};

const DashboardWidget = ({
  groupId,
  name,
  image,
  count,
  status,
  style,
  colorKey,
  groupScope,
}) => {
  if (!name) return null;

  const { Localise, messages } = React.useContext(I18NContext);
  const { bgColor, textColor } = getColorCodes(colorKey);
  const width = 160;

  const dispatch = useDispatch();
  const handleNavigation = () => {
    dispatch(
      navigateScreen({
        name: "catalog",
        params: {
          handle: groupId,
        },
      })
    );
  };

  const menuOptions = CatalogMeatBallsMenu(Localise, messages).filter((e) =>
    e.condition({
      name,
      status,
      groupScope,
    })
  );

  return (
    <TouchableOpacity
      onPress={handleNavigation}
      style={[tw("flex flex-col mb-3"), { width, ...style }]}
    >
      {name && (
        <View style={{ backgroundColor: bgColor }}>
          <View style={tw("p-3")}>
            <View style={tw("flex flex-row justify-between items-center")}>
              <LabelField
                text={name}
                heading={true}
                textColor={textColor}
                style={{
                  width: 100,
                  height: 28,
                }}
                processing={status === "progress"}
              />
              {menuOptions.length > 0 && (
                <MeatBallsMenu
                  menuOptions={menuOptions}
                  data={{
                    groupId,
                    name,
                    status,
                  }}
                />
              )}
            </View>
            <LabelField text={count} textColor={textColor} />
          </View>
          <ImageField
            image={image}
            bgColor={bgColor}
            style={{ width, height: width }}
            containerStyle={{ flexDirection: "column" }}
          />
        </View>
      )}
    </TouchableOpacity>
  );
};

const CollectionWidget = ({
  id,
  handle,
  name,
  image,
  count,
  status,
  style,
  colorKey,
  catalogType,
  displayCollection,
  totalActiveProductsCount,
}) => {
  const catalogManager = useSelector(selectIsCatalogManager);
  const { Localise, messages } = React.useContext(I18NContext);
  const { bgColor, textColor } = getColorCodes(colorKey);
  const group = displayCollection?.includes("Global") ? "mol" : "florist";
  const width = 160;

  const dispatch = useDispatch();

  const handleNavigation = () => {
    dispatch(
      navigateScreen({
        name: "collection",
        params: {
          handle,
        },
      })
    );
  };

  const menuOptions = CollectionMeatBallsMenu(Localise, messages).filter((e) =>
    e.condition({
      name,
      handle,
      status,
      group,
      totalActiveProductsCount,
      catalogManager,
    })
  );

  return (
    <View style={[tw("flex flex-col mb-3"), { width, ...style }]}>
      {name && (
        <TouchableOpacity onPress={handleNavigation}>
          <View style={{ backgroundColor: bgColor }}>
            <View style={tw("p-3")}>
              <View style={tw("flex flex-row justify-between items-center")}>
                <LabelField
                  text={name}
                  heading={true}
                  textColor={textColor}
                  style={{
                    width: 100,
                    height: 28,
                    textTransform: "capitalize",
                  }}
                />
                <MeatBallsMenu
                  menuOptions={menuOptions}
                  data={{ id, handle, name, catalogType, status }}
                />
              </View>
              <LabelField text={count} textColor={textColor} />
              {status && (
                <LabelField
                  text={status}
                  style={{ textTransform: "capitalize" }}
                  textColor={textColor}
                />
              )}
              {displayCollection && (
                <LabelField text={displayCollection} textColor={textColor} />
              )}
            </View>
            <ImageField
              image={image}
              bgColor={bgColor}
              style={{ width, height: width }}
            />
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};

const ProductWidget = ({
  productId,
  style,
  showPosition,
  fromCollection,
  selected,
  onSelect,
  position,
  overlayText,
}) => {
  if (!productId) return null;

  const {
    name,
    image,
    count,
    group,
    displayPrice,
    catalogType,
    status,
    colorKey,
    codified,
    onLoadSideCar,
    dropShippingProduct = false,
  } = useSelector(selectProductLookupById(productId));
  const catalogManager = useSelector(selectIsCatalogManager);

  if (!name) return null;

  const { Localise, messages } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);

  const { bgColor, textColor } = getColorCodes(colorKey);
  const width = 160;

  const navigation = useNavigation();
  const dispatch = useDispatch();
  const meatBallsMenu = ProductMeatBallsMenu(Localise, messages).concat(
    fromCollection && fromCollection !== "addons"
      ? CollectionProductMeatBallsMenu(Localise, messages, fromCollection)
      : []
  );

  const menuOptions = meatBallsMenu.filter((e) =>
    e.condition({
      name,
      productId,
      status,
      group,
      catalogManager,
    })
  );

  const handleNavigation = () => {
    const { routes } = navigation.dangerouslyGetState();
    const isCatalogSettingsScreen = routes.find(
      (val) => val.name === "catalog-settings"
    );

    if (isCatalogSettingsScreen) {
      navigation.navigate("product-catalog", {
        screen: "product",
        params: {
          handle: productId,
          ...(onLoadSideCar && { sideCar: onLoadSideCar }),
        },
      });
    } else {
      dispatch(
        navigateScreen({
          name: "product",
          params: {
            handle: productId,
            ...(onLoadSideCar && { sideCar: onLoadSideCar }),
          },
        })
      );
    }
  };

  const ViewComponent = showPosition ? View : TouchableOpacity;

  return (
    <View style={[tw("flex flex-col mb-3"), { width, ...style }]}>
      {name && (
        <View style={{ backgroundColor: bgColor }}>
          <ViewComponent
            onPress={handleNavigation}
            style={[tw("p-3"), { cursor: showPosition ? "move" : "pointer" }]}
          >
            <View style={tw("flex flex-row justify-between items-center")}>
              <TouchableOpacity onPress={handleNavigation}>
                <LabelField
                  text={name}
                  heading={true}
                  textColor={textColor}
                  style={{ width: 100 }}
                />
              </TouchableOpacity>
              <MeatBallsMenu
                menuOptions={menuOptions}
                data={{ productId, name, catalogType, status }}
              />
            </View>
            <View style={tw("flex flex-row justify-between items-center")}>
              {status && (
                <View style={tw("flex flex-col")}>
                  <LabelField
                    text={status}
                    style={{ textTransform: "capitalize" }}
                    textColor={textColor}
                  />

                  {productId && (
                    <LabelField
                      text={productId}
                      textColor={textColor}
                      style={{ width: 100 }}
                    />
                  )}
                </View>
              )}
              {showPosition && (
                <View
                  style={[
                    {
                      height: 28,
                      width: 40,
                      backgroundColor: colors.secondary,
                      border: 1,
                    },
                    tw("flex flex-row items-center mb-2"),
                  ]}
                >
                  <Image
                    style={{ width: 25, height: 25 }}
                    source={IMAGES["drag-drop-icon"]}
                  />
                  <LabelField text={position} style={{ paddingBottom: 0 }} />
                </View>
              )}
            </View>

            {displayPrice && (
              <LabelField text={displayPrice} textColor={textColor} />
            )}

            <View
              style={[
                tw("flex flex-row items-center justify-between"),
                { height: 25 },
              ]}
            >
              <LabelField
                text={
                  catalogType !== "addons"
                    ? count
                    : `${group === "mol" ? "Global" : "My"} Add-on`
                }
                textColor={textColor}
                style={{ height: !isDesktop ? 17 : 14 }}
              />
              {codified && (
                <Image
                  source={
                    IMAGES[
                      colorKey === "inactive"
                        ? "product-codified"
                        : "product-codified-white"
                    ]
                  }
                  style={{ width: 25, height: 25 }}
                />
              )}
              {dropShippingProduct && (
                <Image
                  source={
                    IMAGES[
                      colorKey === "inactive"
                        ? "product-drop-ship-grey"
                        : "product-drop-ship-white"
                    ]
                  }
                  style={{ width: 25, height: 25 }}
                />
              )}
              <CheckBox
                containerStyle={[
                  theme.CheckBox.inputContainerStyle,
                  {
                    padding: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 0,
                  },
                ]}
                size={18}
                checked={!!selected}
                onPress={() => onSelect(productId)}
                checkedIcon={
                  <Image
                    source={IMAGES["checkbox-checked"]}
                    style={{ width: 18, height: 18 }}
                  />
                }
                uncheckedIcon={
                  <Image
                    source={IMAGES["checkbox-unchecked"]}
                    style={{ width: 18, height: 18 }}
                  />
                }
              />
            </View>
          </ViewComponent>
          <TouchableOpacity onPress={handleNavigation}>
            <ImageField
              image={image}
              bgColor={bgColor}
              style={{ width, height: width }}
            >
              {overlayText ? (
                <View
                  style={[
                    tw("flex flex-row justify-center items-center w-full"),
                    {
                      backgroundColor: backgroundColors.primary,
                      position: "absolute",
                      bottom: 0,
                      height: 20,
                    },
                  ]}
                >
                  <Text style={{ ...fonts.heading6, color: colors.secondary }}>
                    {overlayText}
                  </Text>
                </View>
              ) : null}
            </ImageField>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

export const Widget = ({ type, ...props }) => {
  return type === "product" ? (
    <ProductWidget {...props} order={["name"]} />
  ) : type === "collection" ? (
    <CollectionWidget {...props} />
  ) : (
    <DashboardWidget {...props} />
  );
};

export const SortPicker = ({ type, style = {}, selectSort, setSort }) => {
  const dispatch = useDispatch();
  const sortBy = useSelector(selectSort);
  const { Localise, messages } = React.useContext(I18NContext);
  const getOptions =
    type === "collection" ? CollectionSortProps : ProductSortProps;

  return (
    <View style={tw("flex flex-row items-center")}>
      <Text style={[tw("pr-2"), fonts.default]}>Sort</Text>

      <Picker
        containerStyle={{ paddingHorizontal: 0, paddingBottom: 0, ...style }}
        items={getOptions(Localise, messages)}
        placeholder={{}}
        value={sortBy}
        onValueChange={(e) => {
          dispatch(setSort({ type: "sort", value: e }));
        }}
      ></Picker>
    </View>
  );
};

export const Breadcrumb = ({ currentRoute, prevRoute }) => {
  const { Localise, messages } = React.useContext(I18NContext);

  const dispatch = useDispatch();
  const {
    name: screenName,
    params: { handle: currentScreenHandle = "all_catalogs" } = {},
  } = currentRoute;

  const { name: prevScreenName, params: { handle: prevScreenHandle } = {} } =
    prevRoute;

  const entityName = useSelector(selectScreenTitle(currentScreenHandle));
  const prevEntityName = useSelector(selectScreenTitle(prevScreenHandle));

  const { isMobile } = React.useContext(DeviceContext);

  let links = [];

  if (screenName === "dashboard") return <View></View>;

  links.push({
    title: "Catalog Dashboard",
    linkTo: "dashboard",
  });
  if (screenName === "listCollections") {
    links.push({
      title: "All Collections",
    });
  } else if (screenName === "catalog" && currentScreenHandle === "create") {
    links.push({
      title: "Create Catalog",
    });

    links.push({
      title: currentScreenHandle === "create" ? "Create " : entityName || "",
    });
  } else if (screenName === "catalog") {
    links.push({
      title: "Products",
      linkTo: "catalog",
      linkParams: {
        handle: "all_catalogs",
      },
    });
    links.push({ title: entityName });
  } else if (screenName === "collection") {
    links.push({
      title: "All Collections",
      linkTo: "listCollections",
      linkParams: {
        handle: "listCollections",
      },
    });
    links.push({
      title:
        currentScreenHandle === "create_collection"
          ? "Create "
          : entityName || "",
    });
  } else if (screenName === "product") {
    const isCreateProduct = currentScreenHandle.includes("create_");
    if (
      !isCreateProduct &&
      prevScreenName === "collection" &&
      prevScreenHandle
    ) {
      links.push({
        title: prevEntityName,
        linkTo: prevScreenName,
        linkParams: {
          handle: prevScreenHandle,
        },
      });
    } else if (
      !isCreateProduct &&
      prevScreenName === "catalog" &&
      prevScreenHandle
    ) {
      links.push({
        title: prevEntityName,
        linkTo: prevScreenName,
        linkParams: {
          handle: prevScreenHandle,
        },
      });
    } else {
      links.push({
        title: "All Catalogs",
        linkTo: "catalog",
        linkParams: {
          handle: "all_catalogs",
        },
      });
    }

    links.push({
      title: currentScreenHandle.includes("create_")
        ? "Create "
        : entityName || "",
    });
  }

  return links.length ? (
    <View
      style={[
        tw(`flex flex-row flex-wrap items-center`),
        {
          paddingTop: isMobile ? 8 : 1,
          width: isMobile ? "100%" : "auto",
        },
      ]}
    >
      {links.map((link, index) => (
        <View
          style={tw("flex flex-row justify-center items-center mt-1")}
          key={index}
        >
          {link.linkTo ? (
            <TouchableOpacity
              onPress={() =>
                dispatch(
                  navigateScreen({
                    name: link.linkTo || "",
                    params: link.linkParams || {},
                  })
                )
              }
            >
              <Text style={fonts.link2}>{Localise(messages, link.title)}</Text>
            </TouchableOpacity>
          ) : (
            <Text style={fonts.heading5}>{Localise(messages, link.title)}</Text>
          )}
          {index !== links.length - 1 && <Text> / </Text>}
        </View>
      ))}
    </View>
  ) : null;
};

export const PreviewUrl = ({ heading = "URL: ", url = "" }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile } = React.useContext(DeviceContext);

  const result = url?.replace(/(^\w+:|^)\/\//, "");
  const slashIndex = result.indexOf("/");
  const seoUrl = slashIndex > -1 ? result.slice(slashIndex) : result;

  const openURL = (url) => {
    if (Platform.OS == "web") {
      window.open(url, "_blank");
    } else {
      Linking.openURL(url);
    }
  };

  if (!url) return null;

  return (
    <View style={[tw("flex flex-row flex-1 pl-1 my-3 items-center")]}>
      <Text style={{ ...fonts.heading4, marginRight: 4 }}>
        {Localise(messages, heading)}
      </Text>
      {url.startsWith("http") ? (
        <Text
          style={[fonts.link1, { width: isMobile ? "80%" : "auto" }]}
          onPress={() => openURL(url)}
          testID={"PreviewUrl"}
          accessibilityLabel={"PreviewUrl"}
          numberOfLines={1}
          ellipsizeMode={"tail"}
        >
          {seoUrl}
        </Text>
      ) : (
        <Text
          style={{
            ...fonts.default,
            maxWidth: "80%",
          }}
          numberOfLines={1}
          ellipsizeMode={"tail"}
        >
          {url}
        </Text>
      )}
    </View>
  );
};

export const BannerImageField = ({ name, title, dimensions }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile, isDesktop } = React.useContext(DeviceContext);
  const {
    values: { [name]: image = "" },
    setFieldValue,
  } = useFormikContext();

  return (
    <View style={tw("flex flex-col w-full pl-1 my-3")}>
      <View
        style={tw(`flex ${isMobile ? "flex-col" : "flex-row items-center"}`)}
      >
        <Text style={{ ...fonts.heading4, paddingRight: 6 }}>
          {Localise(messages, title)}
        </Text>
        {!!dimensions && (
          <Text
            style={{ ...fonts.style2 }}
          >{`Recommended Size ${dimensions}`}</Text>
        )}
      </View>

      <View style={tw(`flex flex-col pt-1 w-full`)}>
        <View style={tw("w-full")}>
          {!!image && (
            <Tooltip text={image}>
              <Image
                style={{
                  height: isMobile ? 75 : 150,
                  width: isMobile ? 300 : 600,
                  borderWidth: 0.5,
                  borderColor: colors.light,
                }}
                containerStyle={{ margin: "auto" }}
                source={{ uri: image }}
              />
            </Tooltip>
          )}
        </View>

        <View style={tw("flex flex-row items-center")}>
          <FileSelector
            testID={"choose_image"}
            accessibilityLabel="choose_image"
            image={image}
            containerStyle={{
              width: !isDesktop ? 100 : 70,
              marginLeft: 0,
            }}
            buttonStyle={{ paddingVertical: 5 }}
            titleStyle={{ fontSize: 12 }}
            onSelect={(uri) => setFieldValue(name, uri)}
          />

          {!!image && (
            <Image
              onPress={() => setFieldValue("bannerImage", "")}
              style={{ width: 20, height: 20, marginLeft: 10 }}
              source={require("static/assets/trash-can.png")}
            />
          )}
        </View>
      </View>
    </View>
  );
};

export const BulkActionsPicker = ({
  type,
  entity,
  bulkActionType,
  setBulkActionType,
  isProductPage = false,
}) => {
  const allowSyndicate = useSelector(selectAllowSyndicate);
  const catalogManager = useSelector(selectIsCatalogManager);
  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile, isDesktop } = React.useContext(DeviceContext);

  const actions =
    type === "addons"
      ? AddonBulkActions(
          Localise,
          messages,
          allowSyndicate,
          catalogManager,
          isProductPage
        )
      : type === "catalog"
      ? CatalogBulkActions(Localise, messages, allowSyndicate, catalogManager)
      : type === "collection"
      ? CollectionBulkActions(
          Localise,
          messages,
          allowSyndicate,
          catalogManager,
          entity
        )
      : type === "priceMinimum"
      ? CatalogPriceMinimumBulkActions(Localise, messages, allowSyndicate)
      : [];

  return (
    <Picker
      containerStyle={{
        paddingHorizontal: 0,
        paddingBottom: 0,
        width:
          type === "catalog"
            ? !isDesktop
              ? 210
              : 170
            : type === "priceMinimum"
            ? isMobile
              ? 175
              : 250
            : isMobile
            ? 210
            : 185,
      }}
      items={actions}
      placeholder={{}}
      value={bulkActionType.value}
      onValueChange={(e) => {
        setBulkActionType(actions.find((a) => a.value === e));
      }}
    />
  );
};

export const getInitialRoute = (navigation) => {
  const isWeb = Platform.OS === "web";
  let route = "dashboard",
    handle = "",
    member_code = "";

  const memberCodes = UserProfileStorage.getProfileMemberCodes();

  if (isWeb) {
    ({
      pathArray: [, , route = "dashboard", handle = "", member_code = ""] = [],
    } = getRouteDetails());
  } else {
    const { routes = [] } = navigation.dangerouslyGetState();
    const current = routes.find((route) => route.name === "product-catalog");
    const {
      params: { params: { handle: id = "" } = {}, screen = "dashboard" } = {},
    } = current;

    route = screen;
    handle = id;
  }

  if (route === "catalog" && !handle) handle = "all_catalogs";
  else if (route === "product" && !handle) {
    route = "catalog";
    handle = "all_catalogs";
  } else if (route === "collection" && !handle) {
    route = "collections";
    handle = "listCollections";
  } else if (route === "dashboard" || route === "collections") {
    member_code = handle;
    handle = "";
  }

  if (!memberCodes.includes(member_code)) member_code = "";

  return {
    screen: route,
    params: {
      handle,
      member_code: member_code || "all",
    },
  };
};

export const ShopCodePickerComponent = () => {
  const { permissions } = React.useContext(AppSettingsContext);
  const { isDesktop } = React.useContext(DeviceContext);

  const shopCodeOptions = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.PRODUCT_CATALOG in permissions[memberCode])
      shopCodeOptions.push(memberCode);
  });

  return (
    <View
      style={[
        tw(` ${isDesktop ? "text-right" : "text-left"} `),
        {
          width: !isDesktop ? "80%" : "auto",
          marginTop: !isDesktop && 8,
          justifyContent: "center",
        },
      ]}
    >
      <ShopCodePicker
        shopCodeSelector={selectShopCode}
        setShopCodeAction={setShopCode}
        shopCodeOptions={shopCodeOptions}
        containerStyle={{ width: isDesktop ? "auto" : 250 }}
      />
    </View>
  );
};

export const HeaderMenusControl = () => {
  const { isMobile } = React.useContext(DeviceContext);
  const dispatch = useDispatch();

  const onCreateAction = (key) => {
    const {
      action,
      route,
      handle = "",
    } = CreateMenuOptions().find((e) => e.key === key);

    dispatch(
      action({
        name: route,
        params: {
          handle,
        },
      })
    );
  };

  const onSettingAction = (key) => {
    const { sideCarName, action } = SettingsMenuOptions.find(
      (e) => e.key === key
    );
    dispatch(action(sideCarName));
  };

  return (
    <View style={tw(`flex-row ${isMobile ? "ml-0" : "mx-2"} items-center`)}>
      <CreateMenu onSelect={onCreateAction} />
      <SettingsMenu onSelect={onSettingAction} />
    </View>
  );
};

export const CreateMenu = ({ onSelect }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const catalogManager = useSelector(selectIsCatalogManager);
  if (!catalogManager) return null;

  return (
    <PopupMenu
      containerStyle={tw("mx-3 items-end")}
      optionsContainerStyle={tw("p-1 m-8")}
      menuOptions={CreateMenuOptions()}
      onSelect={onSelect}
      testID="list_create_menu"
      accessibilityLabel="list_create_menu"
    >
      <Tooltip
        text={Localise(messages, "Create Product, Addon, Collection")}
        renderForWebOnly={true}
      >
        <Image
          style={{
            width: 26,
            height: 26,
          }}
          resizeMode="contain"
          source={IMAGES[`create-order`]}
        />
      </Tooltip>
    </PopupMenu>
  );
};

export const SettingsMenu = ({ onSelect }) => {
  const { name: screen, params: { handle } = {} } = useRoute();
  const excludeMenu = (arr) =>
    SettingsMenuOptions.filter((el) => arr.every((f) => f.key !== el.key));
  const { Localise, messages } = React.useContext(I18NContext);
  const excludeCollectionSettings =
    screen !== "collection" || handle === "create_collection";
  const options = excludeCollectionSettings
    ? excludeMenu([{ key: "collectionSettings" }])
    : SettingsMenuOptions;

  return (
    <PopupMenu
      containerStyle={tw("mx-3 items-end")}
      optionsContainerStyle={tw("p-1 m-8")}
      menuOptions={options}
      onSelect={onSelect}
      testID="list_settings_menu"
      accessibilityLabel="list_settings_menu"
    >
      <Tooltip
        text={Localise(messages, "Catalog Settings")}
        renderForWebOnly={true}
      >
        <Image
          style={{
            width: 26,
            height: 26,
          }}
          resizeMode="contain"
          source={IMAGES[`settings`]}
        />
      </Tooltip>
    </PopupMenu>
  );
};

export const OverrideHeader = ({ isOverrideHeader, id = "", action }) => {
  const dispatch = useDispatch();
  const { Localise, messages } = React.useContext(I18NContext);
  const { name: screenName } = useSelector(selectScreen);
  const [showPopup, setShowPopup] = React.useState(false);
  const allowSyndicate = useSelector(selectAllowSyndicate);

  const onRevert = () => {
    dispatch(
      action({
        params: {
          ...(screenName === "product" ? { productId: id } : { handle: id }),
        },
        resolve: () => {
          ToasterHandler(
            "looks good",
            Localise(messages, `Content is set to FTD default.`)
          );
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
        },
      })
    );
  };

  return (
    <View style={tw("flex flex-row flex-wrap mb-3 items-center justify-start")}>
      {isOverrideHeader ? (
        <>
          <Text
            style={{ ...fonts.heading3, fontWeight: "normal", marginRight: 5 }}
          >
            {Localise(messages, "You have customized this content.")}
          </Text>
          <SecondaryButton
            title={Localise(messages, "Revert to Original")}
            action={allowSyndicate ? () => setShowPopup(!showPopup) : onRevert}
          />
          <ConfirmModal
            modalVisible={!!showPopup}
            handlePrimary={() => {
              onRevert();
              setShowPopup(!showPopup);
            }}
            handleSecondary={() => {
              setShowPopup(!showPopup);
            }}
            data={{
              modal: {
                content: `This change will apply to All shops and will override any local shop changes.`,
              },
            }}
          />
        </>
      ) : (
        <>
          <Text
            style={{ ...fonts.heading3, fontWeight: "normal", marginRight: 5 }}
          >
            FTD generates this content for you, but you can customize it as you
            like.
          </Text>
        </>
      )}
    </View>
  );
};
