import React, { useEffect } from "react";
import { View, ScrollView, TouchableOpacity } from "react-native";
import { Text, Image } from "react-native-elements";
import tw from "tailwind-rn";
import { fonts, backgroundColors, colors } from "styles/theme";
import {
  Form,
  FormField,
  SubmitButton,
  FormFieldCheckBox,
  FormFieldPicker,
  FormFieldRadioButtons,
  FormFieldSwitch,
  FormFieldAutoComplete,
} from "components/elements/forms";
import * as Yup from "yup";
import { request } from "library/utils/request";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import { ToasterHandler } from "components/elements";
import { StatusOptions } from "components/views/drawer/staff-listing/config";
import { TypeOfUsers, AllDepartments } from "../ftd-admin/user-settings/config";
import SubHeader from "components/containers/header/sub-header";
import UserProfileStorage from "library/storage/userProfile";
import useStateIfMounted from "library/utils/useStateIfMounted";
import IMAGES from "static/assets/images";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import I18NContext from "library/contexts/i18N";

export default function CreateStaffProfileScreen({ onComplete, onAction }) {
  const {
    memberCodes = [],
    userProfile: { memberCode: myMemberCode } = {},
    userRole: myRole,
    userType,
  } = React.useContext(UserProfileContext);

  const { messages, Localise } = React.useContext(I18NContext);

  const isFTDUser = userType === "FTD";
  const isFloristAdmin = myRole === "ADMIN";

  let typeOfFTDUsers;
  if (myRole === "FTD_ADMIN") {
    typeOfFTDUsers = TypeOfUsers;
  } else {
    typeOfFTDUsers = TypeOfUsers.filter((typeOfUser) => {
      return typeOfUser.value !== "FTD_ADMIN";
    });
  }

  let memberCode;
  if (UserProfileStorage.getProxyUser()) {
    memberCode = memberCodes[0];
  } else {
    memberCode = myMemberCode || memberCodes[0];
  }

  const { isDesktop } = React.useContext(DeviceContext);
  const navigation = useNavigation();
  const [userRoles, setUserRoles] = useStateIfMounted([]);
  const [memberQuery, setMemberQuery] = useStateIfMounted("");

  useEffect(() => {
    request("get-user-roles", {}, undefined, true).then((res) => {
      const roles = res.map((role) => {
        return { label: role, value: role };
      });
      setUserRoles(roles);
    });
  }, []);

  const addStaffValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .label("firstName")
      .required(Localise(messages, "Please enter first name"))
      .test(
        "ftdsubstringcheck",
        Localise(messages, "FTD cannot be part of the users name"),
        (val) => val && !val.toLowerCase().includes("ftd")
      ),
    lastName: Yup.string()
      .label("lastName")
      .required(Localise(messages, "Please enter last name"))
      .test(
        "ftdsubstringcheck",
        Localise(messages, "FTD cannot be part of the users name"),
        (val) => val && !val.toLowerCase().includes("ftd")
      ),
    roles: Yup.string()
      .label("roles")
      .required(Localise(messages, "Select Role")),
    employmentStatus: Yup.string()
      .label("employmentStatus")
      .required(Localise(messages, "Select a Status")),
    shopCodes: Yup.string()
      .label("shopCodes")
      .required(Localise(messages, "Select at least one shop code")),
    emailId: Yup.string()
      .label("emailId")
      .email(Localise(messages, "Enter a valid email"))
      .required(Localise(messages, "Enter Email")),
    phone: Yup.string()
      .label("phone")
      .required(Localise(messages, "Please enter phone number"))
      .matches(
        new RegExp(
          "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
        ),
        Localise(messages, "Please enter a valid phone number")
      ),
    password: Yup.string()
      .label("password")
      .when("showPasswordField", {
        is: (value) => value === true,
        then: Yup.string().required(Localise(messages, "Enter Password")),
      })
      .matches(
        /^(?=\S)(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*\S$)[A-Za-z0-9\s!@#$%^&*]{8,32}$/,
        Localise(messages, "Enter Password")
      ),
  });

  const addFTDUserValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .label("firstName")
      .required("Please enter first name"),
    lastName: Yup.string().label("lastName").required("Please enter last name"),
    typeOfUser: Yup.string()
      .label("typeOfUser")
      .required("Please select a valid user type"),
    department: Yup.string()
      .label("department")
      .required("Please select a valid department"),
    emailId: Yup.string()
      .label("emailId")
      .required("Please enter Email")
      .matches(
        /^[a-z0-9]+(.|_)+[a-z0-9]+@ftdi.com$/i,
        "Please enter a valid FTD email address"
      ),
    password: Yup.string()
      .label("password")
      .when("showPasswordField", {
        is: (value) => value === true,
        then: Yup.string().required(Localise(messages, "Enter Password")),
      })
      .matches(
        /^(?=\S)(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*\S$)[A-Za-z0-9\s!@#$%^&*]{8,32}$/,
        "Enter Password"
      ),
  });

  const onFormSubmit = (values, formikBag) => {
    const {
      emailId,
      firstName,
      lastName,
      roles,
      shopCodes,
      accountEnabled,
      employmentStatus,
      phone,
      profileNotes,
      typeOfUser,
      department,
      password,
      showPasswordField,
    } = values;

    let otherParams = {};

    if (isFTDUser) {
      otherParams = {
        phone: "",
        profileNotes: "",
        employmentStatus: "FULLTIME",
        department,
        type: "FTD_USER",
        memberCode: "FTD",
        authGroups: [
          {
            name: `${typeOfUser}_ROLE`,
            shopGroups: ["FTD_ALL_SHOPS"],
            roles: [typeOfUser],
          },
          {
            name: `ADMIN_ROLE`,
            shopGroups: ["FTD_ALL_SHOPS"],
            roles: ["ADMIN"],
          },
        ],
      };
    } else {
      otherParams = {
        phone,
        profileNotes,
        employmentStatus,
        memberCode,
        authGroups: [
          {
            name: `${roles}_ROLE`,
            roles: [roles],
            memberCodes: shopCodes,
          },
        ],
      };
    }

    const payload = {
      firstName,
      lastName,
      emailId,
      userId: emailId,
      partnerGroup: "-",
      status: accountEnabled ? "ACTIVE" : "INACTIVE",
      suppressSetPasswordEmail: showPasswordField,
      ...otherParams,
    };

    const profileCreated = () => {
      formikBag.setSubmitting(false);
      ToasterHandler(
        "your team is growing",
        Localise(messages, "You added a new user to the bunch")
      );
      if (isDesktop) {
        onComplete();
        onAction("refresh", new Date().toString()); // To Refresh the listing screen every time on creating a new user
      } else {
        navigation.dispatch(
          CommonActions.navigate({
            name: isFTDUser ? "ftd-user-settings" : "staff-management",
          })
        );
      }
    };

    request("create-staff-profile", payload)
      .then(() => {
        if (showPasswordField) {
          request("batch-update", [
            {
              email: emailId,
              unlock: true,
              newPassword: password,
            },
          ])
            .then(() => profileCreated())
            .catch(() => {
              formikBag.setSubmitting(false);
              ToasterHandler(
                "error",
                Localise(messages, "Unable to set password")
              );
            });
        } else {
          profileCreated();
        }
      })
      .catch((err) => {
        formikBag.setSubmitting(false);
        err === "USER_ALREADY_EXISTS"
          ? ToasterHandler(
              "oops",
              Localise(
                messages,
                "We’re seeing double – this person already has a profile"
              )
            )
          : ToasterHandler(
              "uh oh",
              err.message ||
                Localise(
                  messages,
                  "User account failed to save – please try again"
                )
            );
      });
  };

  const onAccountChange = (setFieldValue, values) => {
    const isEnable = values.accountEnabled;
    setFieldValue("accountEnabled", !isEnable);
    if (!isFTDUser) {
      isEnable
        ? setFieldValue("employmentStatus", "TERMINATED")
        : setFieldValue("employmentStatus", StatusOptions[0].value);
    }
  };

  const onStatusChange = (value, setFieldValue) => {
    value === "TERMINATED"
      ? setFieldValue("accountEnabled", false)
      : setFieldValue("accountEnabled", true);
  };

  const defaultValues = {
    firstName: "",
    lastName: "",
    roles: [],
    employmentStatus: "",
    shopCodes: [],
    phone: "",
    emailId: "",
    isEmail: true,
    pushNotifications: false,
    profileNotes: "",
    accountEnabled: true,
    typeOfUser: "",
    department: "",
    password: "",
    showPasswordField: false,
  };

  let shopsList = memberCodes.map((code) => {
    return { label: code, value: code };
  });

  shopsList = shopsList.length > 1 && [
    { label: Localise(messages, "All"), value: memberCodes },
    ...shopsList,
  ];

  return (
    <>
      {!isDesktop && <SubHeader />}
      <ScrollView
        contentContainerStyle={{
          backgroundColor: backgroundColors.secondary,
          padding: isDesktop ? 5 : 10,
          ...(!isDesktop && { paddingBottom: 220 }),
        }}
        fsClass="fs-unmask"
      >
        {isDesktop ? (
          <View
            style={[
              tw("px-3 py-3 flex flex-col"),
              { backgroundColor: backgroundColors.primary, height: 140 },
            ]}
            fsClass="fs-unmask"
          >
            <View style={tw("flex items-end")}>
              <TouchableOpacity onPress={() => onComplete()} testID="close">
                <Image
                  style={{ width: 20, height: 20 }}
                  resizeMode="cover"
                  source={IMAGES["close"]}
                />
              </TouchableOpacity>
            </View>

            <View style={tw("flex flex-col items-center")}>
              <Text style={[fonts.heading2, tw("text-white mb-3")]}>
                {isFTDUser
                  ? "Create FTD User"
                  : Localise(messages, "Create Staff Profile")}
              </Text>
            </View>
          </View>
        ) : (
          <View style={tw("mb-3")} fsClass="fs-unmask">
            <Text style={[fonts.heading1]}>
              {isFTDUser
                ? "Create FTD User"
                : Localise(messages, "Create Staff Profile")}
            </Text>
          </View>
        )}
        <View
          style={{
            borderBottomColor: colors.light,
            borderBottomWidth: 1,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.4,
            shadowRadius: 7,
            marginBottom: 20,
          }}
          fsClass="fs-unmask"
        />
        <View
          style={{
            width: "100%",
          }}
        >
          <View style={tw("flex flex-row mb-5")}>
            {/* <TouchableOpacity
            style={[tw("flex flex-col  mb-5 "), { width: 200 }]}
          > */}
            <Image
              style={{
                width: 100,
                height: 100,
                borderWidth: 1,
                borderColor: colors.medium,
              }}
              resizeMode="cover"
              source={require("static/assets/ftd-white.png")}
            />
            {/* <View style={tw("mt-2")}>
              <Text style={[fonts.link1]} onPress={() => {}}>
                Edit Photo
              </Text>
            </View>
          </TouchableOpacity> */}
          </View>
          <Form
            initialValues={defaultValues}
            onSubmit={onFormSubmit}
            validationSchema={
              isFTDUser ? addFTDUserValidationSchema : addStaffValidationSchema
            }
            render={({ values, setValues, setFieldValue }) => {
              const finalValues = cloneDeep(values);
              const selectedCount = values.shopCodes.length;

              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                setMemberQuery("");
              }, [values.shopCodes]);

              return (
                <View style={{ marginLeft: -5 }}>
                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    name="firstName"
                    placeholder={"Enter First Name"}
                    label="First Name"
                  />

                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    name="lastName"
                    placeholder={"Enter Last Name"}
                    label="Last Name"
                  />

                  {!isFTDUser ? (
                    <>
                      {!!userRoles.length && (
                        <FormFieldRadioButtons
                          label={"Role"}
                          options={userRoles}
                          name={"roles"}
                        />
                      )}
                      {memberCodes.length > 1 ? (
                        <View style={{ zIndex: 1, padding: 5 }}>
                          <Text style={{ marginBottom: 8 }}>
                            {Localise(messages, "Shop Codes")}
                          </Text>

                          <FormFieldAutoComplete
                            data={shopsList.filter((code) =>
                              code.label.includes(memberQuery)
                            )}
                            initialDataLength={shopsList.length}
                            showOnFocus={true}
                            setFocusBack={true}
                            onChangeText={(text) => {
                              const arr = text.split(",");
                              setMemberQuery(arr[arr.length - 1]);
                            }}
                            placeholder={
                              selectedCount
                                ? `${selectedCount} ${Localise(
                                    messages,
                                    "Selected"
                                  )}`
                                : "Select Shop Codes"
                            }
                            listDisplayValues={["label"]}
                            outerContainerStyle={{
                              zIndex: 1,
                              marginLeft: -5,
                            }}
                            name="shopCodes"
                            isMultiSelect={true}
                            onSelect={(selectedValue) => {
                              const { shopCodes } = finalValues;
                              if (selectedValue.label === "All") {
                                if (
                                  shopCodes.length ===
                                  selectedValue.value.length
                                ) {
                                  set(finalValues, "shopCodes", []);
                                } else {
                                  set(finalValues, "shopCodes", [
                                    ...selectedValue.value,
                                  ]);
                                }
                              } else {
                                const index = shopCodes.indexOf(
                                  selectedValue.label
                                );
                                const isSelected = index >= 0;
                                isSelected
                                  ? shopCodes.splice(index, 1)
                                  : shopCodes.push(selectedValue.label);
                              }
                              setValues(finalValues);
                            }}
                          />
                        </View>
                      ) : (
                        <FormFieldCheckBox
                          label={"Shop Codes"}
                          iconRight={false}
                          name="shopCodes"
                          size={20}
                          containerStyle={{}}
                          title={memberCodes}
                          isGroup={true}
                        />
                      )}
                      <FormFieldPicker
                        placeholder={{ label: "Select Status", value: "" }}
                        containerStyle={{ width: isDesktop ? "40%" : "100%" }}
                        data={StatusOptions}
                        name="employmentStatus"
                        label="Status"
                        defaultValue={defaultValues.employmentStatus}
                        onChange={(e) => onStatusChange(e, setFieldValue)}
                      />

                      <FormField
                        name="phone"
                        label="Phone"
                        placeholder="123-456-7890"
                        containerStyle={{ width: isDesktop ? "45%" : "100%" }}
                        keyboardType="phone-pad"
                        maxLength={18}
                        fsClass="fs-exclude"
                      />
                    </>
                  ) : (
                    <>
                      <FormFieldPicker
                        placeholder={{
                          label: "Select Type Of User",
                          value: "",
                        }}
                        containerStyle={{ width: isDesktop ? "40%" : "100%" }}
                        data={typeOfFTDUsers}
                        name="typeOfUser"
                        label="Type Of User"
                        defaultValue={defaultValues.typeOfUser}
                      />

                      <FormFieldPicker
                        placeholder={{ label: "Select Department", value: "" }}
                        containerStyle={{ width: isDesktop ? "40%" : "100%" }}
                        data={AllDepartments}
                        name="department"
                        label="Department"
                        defaultValue={defaultValues.department}
                      />
                    </>
                  )}
                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="email-address"
                    name="emailId"
                    placeholder="Email"
                    textContentType="emailAddress"
                    label="Email"
                  />
                  {(isFTDUser || isFloristAdmin) && (
                    <TouchableOpacity
                      style={[tw("flex flex-row ml-1 mb-4"), { minWidth: 100 }]}
                      onPress={() =>
                        setFieldValue(
                          "showPasswordField",
                          !values.showPasswordField
                        )
                      }
                      testID="manually_reset_pwd"
                      accessibilityLabel="manually_reset_pwd"
                    >
                      <Text style={fonts.link1}>
                        {" "}
                        {Localise(messages, "Manually Set Password")}
                      </Text>
                    </TouchableOpacity>
                  )}
                  {values.showPasswordField && (
                    <View style={tw("mb-2")}>
                      <FormField
                        autoComplete="new-password"
                        autoCapitalize="none"
                        autoCorrect={false}
                        secureTextEntry
                        textContentType="password"
                        name="password"
                        label="Password"
                        placeholder="Enter Password"
                        keyboardType="phone-pad"
                        maxLength={32}
                        isUpdateOnChange={true}
                      />
                      <View style={tw("flex flex-col pl-1")}>
                        <Text style={tw("my-1 italic")}>
                          {Localise(
                            messages,
                            "Your password must be a min 8 and max 32 characters without spaces, and include 1 uppercase, 1 number, and 1 special character. The password cannot match your three previous passwords."
                          )}
                        </Text>
                      </View>
                    </View>
                  )}
                  {/*

              <Divider
                style={{
                  backgroundColor: colors.medium,
                  marginVertical: 20,
                }}
              />

              
              <View>
                <Text style={[fonts.heading3]}>Notifications</Text>
              </View>
              <View style={tw("flex flex-row py-2 justify-start")}>
                <Text> 9am-5pm</Text>
                <Text
                  style={[fonts.link1, { marginLeft: 10 }]}
                  onPress={() => {}}
                >
                  Edit Hours
                </Text>
              </View>

              <View style={tw("flex flex-row ")}>
                <FormFieldCheckBox
                  iconRight={false}
                  name="isEmail"
                  size={20}
                  title="Email"
                />
                <FormFieldCheckBox
                  iconRight={false}
                  name="pushNotifications"
                  size={20}
                  title="Push Notifications"
                  label="Push Notifications"
                />
              </View>

              <Divider
                style={{
                  backgroundColor: colors.medium,
                  marginVertical: 20,
                }}
              />
              <View style={tw("flex flex-col px-1 mb-5")}>
                <Text style={[fonts.link1, tw("py-2")]} onPress={() => {}}>
                  Hours
                </Text>
                <Text
                  style={[fonts.link1, tw("py-2")]}
                  onPress={() => {
                    navigation.dispatch(
                      CommonActions.navigate({
                        name: "dashboard",
                      })
                    );
                  }}
                >
                  Dashboard Settings
                </Text>
              </View>
              */}
                  {!isFTDUser && (
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name="profileNotes"
                      placeholder="Enter Profile Notes"
                      label="Profile Notes"
                      multiline={true}
                      numberOfLines={4}
                    />
                  )}
                  <View style={tw("flex flex-row px-2 py-2 items-center")}>
                    <Text style={fonts.heading5}>
                      {Localise(messages, "Account Enabled")}{" "}
                    </Text>
                    <View style={{ maxWidth: 35, marginLeft: 10 }}>
                      <FormFieldSwitch
                        value={values.accountEnabled}
                        onValueChange={() =>
                          onAccountChange(setFieldValue, values)
                        }
                      />
                    </View>
                  </View>
                  <SubmitButton
                    containerStyle={{ margin: 5, width: 150 }}
                    title="Create"
                  />
                </View>
              );
            }}
          />
        </View>
      </ScrollView>
    </>
  );
}
