import {
  selectNoop,
  selectShowUpsert,
  selectIsPageLoading,
  selectGiftCard,
  selectAppliedLimits,
  selectCurrentGiftCardId,
  selectSearchText,
  // selectAppliedShops,
  selectTotalNumberOfRecords,
  selectPageActions,
} from "library/sagas/views/home/drawer/gift-cards/selector";

import {
  setPageAction,
  setCurrentGiftCard,
  setStoreOrigin,
} from "library/sagas/views/home/drawer/gift-cards/slice";

import Item from "./list-item";
import ListingHeader from "./listing-header";

export const getUIConfig = (
  Localise,
  messages,
  isDesktop,
  isTablet,
  isMobile
) => ({
  heading: Localise(messages, "Gift Cards"),
  ListingHeader,
  selectors: {
    showSecondScreen: selectShowUpsert,
    showLoading: selectIsPageLoading,
    dataSelector: selectGiftCard,
    limitSelector: selectAppliedLimits,
    selectTotalNumberOfRecords,
    selectPageActions,
  },
  sections: [
    {
      section: "giftCards",
      component: {
        props: {
          defaultOpen: () => {},
          showAccordion: false,
          viewMore: {
            label: Localise(messages, "View More"),
            value: 10,
            initial: 10,
          },
          selectors: {
            dataSelector: selectGiftCard,
            limitSelector: selectAppliedLimits,
            accordionSelector: selectNoop,
            selectTotalNumberOfRecords,
          },
          actions: { setPageAction },
          itemComponent: {
            name: Item,
            props: {
              id: "id",
              columns: getColumns(isMobile, isTablet),
              style: {},
              selectors: {
                dataSelector: selectGiftCard,
                selectActiveItem: selectCurrentGiftCardId,
                accordionSelector: selectNoop,
              },
              actions: {
                setActiveItem: setCurrentGiftCard,
                setStoreOrigin,
              },
            },
          },
        },
      },
    },
  ],
  controls: [
    {
      name: "search",
      props: {
        width: isDesktop ? "33%" : "",
        icon: "search",
        placeholder: Localise(messages, "Search Gift Cards"),
        inputStyle: {
          width: "100%",
        },
        selectors: {
          selectValue: selectSearchText,
        },
        actions: {
          setAction: setPageAction,
        },
      },
    },
    // {
    //   name: "shops",
    //   props: {
    //     width: isDesktop ? "5%" : "",
    //     icon: "home",
    //     text: Localise(messages, "Shops"),
    //     selectors: {
    //       selectValue: selectAppliedShops,
    //     },
    //     actions: {
    //       setAction: setPageAction,
    //     },
    //   },
    // },
    {
      name: "create-new",
      isHidden: false,
      props: {
        icon: "create-order",
        text: Localise(messages, "Create Gift Card"),
        route: "create-order",
        params: {},
      },
    },
  ],
  // Providing Static height Temporarily to fix height issue if we have banners upto 3. In future when listing screen ui changes similar to Orders listing screen, issue will resolve and we can delete this css
  scrollContainerStyles: { minHeight: 700 },
});

const getColumns = (isMobile, isTablet) => {
  return [
    {
      column: "giftCardId",
      style: {
        width: isMobile ? "50%" : isTablet ? 65 : "25%",
        textTransform: "capitalize",
      },
    },
    {
      column: "remainingAmount",
      style: {
        width: isMobile ? "50%" : isTablet ? 65 : "25%",
        textTransform: "capitalize",
      },
    },
    {
      column: "recipientName",
      style: {
        width: isMobile ? "50%" : isTablet ? 65 : "25%",
      },
    },
    {
      column: "recipientEmail",
      style: {
        width: isMobile ? "50%" : isTablet ? 65 : "25%",
      },
    },
    {
      column: "expirationDate",
      style: {
        width: isMobile ? "50%" : isTablet ? 65 : "25%",
      },
    },
  ];
};
