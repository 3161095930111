import React from "react";
import { ScrollView } from "react-native";
import HeaderBar from "components/containers/header";
import Help from "components/views/drawer/help";

const HelpPage = () => {
  return (
    <ScrollView>
      <HeaderBar />
      <Help />
    </ScrollView>
  );
};

export default HelpPage;
