import React from "react";
import { View } from "react-native";

import OrderDetailsPaymentDetails from "./order-details";

const PaymentDetails = ({
  orderRefundStatus,
  setOrderRefundStatus,
  setActionProcessing,
  triggerAction,
}) => {
  return (
    <View>
      <OrderDetailsPaymentDetails
        orderRefundStatus={orderRefundStatus}
        setOrderRefundStatus={setOrderRefundStatus}
        setActionProcessing={setActionProcessing}
        triggerAction={triggerAction}
      />
    </View>
  );
};

export default PaymentDetails;
