import * as Yup from "yup";

//validationSchema for Pickup Instructions shop preference
export const validationSchema = (Localise, messages) =>
  Yup.object().shape({
    pickupInstructions: Yup.string().max(
      500,
      Localise(messages, "Pickup Instructions cannot exceed 500 characters")
    ),
  });
