/**
 * react-native-draggable-gridview
 */

import React, { memo, useRef, useState, useCallback } from "react";
import {
  Dimensions,
  View,
  TouchableOpacity,
  Animated,
  Easing,
  ScrollView,
  PanResponder,
} from "react-native";

import findIndex from "lodash/findIndex";
import head from "lodash/head";
import differenceWith from "lodash/differenceWith";

const { width: screenWidth } = Dimensions.get("window");

const DraggableGridView = memo(
  ({
    data,
    keyExtractor,
    renderItem,
    renderLockedItem,
    locked,
    onBeginDragging,
    onPressCell,
    onReleaseCell,
    onEndAddAnimation,
    onEndDeleteAnimation,
    numColumns = 1,
    cellWidth,
    cellHeight,
    containerWidth,
    ...rest
  }) => {
    const width = containerWidth || screenWidth;
    const activeOpacity = rest.activeOpacity || 0.5;
    const delayLongPress = rest.delayLongPress || 0;
    const selectedStyle = rest.selectedStyle || {
      shadowColor: "#000",
      shadowRadius: 8,
      shadowOpacity: 0.2,
      elevation: 10,
    };

    const [selectedItem, setSelectedItem] = useState(null);

    const self = useRef({
      contentOffset: 0,
      grid: [],
      items: [],
      cellHeight: cellHeight,
      numRows: 1,
      startPointOffset: 0,
    }).current;

    //-------------------------------------------------- Preparing
    const prepare = useCallback(() => {
      if (!data) return;
      const diff = data.length - self.grid.length;
      if (Math.abs(diff) == 1) {
        prepareAnimations(diff);
      } else if (diff != 0) {
        onUpdateGrid();
      } else if (findIndex(self.items, (v, i) => v.item != data[i]) >= 0) {
        onUpdateData();
      }
    }, [data, selectedItem]);

    const onUpdateGrid = useCallback(() => {
      const cellSize = width / numColumns;
      self.cellSize = cellSize;

      self.numRows = Math.ceil(data.length / numColumns);
      self.cellWidth = cellWidth;
      self.cellHeight = cellHeight;

      const grid = [];
      for (let i = 0; i < data.length; i++) {
        const x = (i % numColumns) * cellSize;
        const y = Math.floor(i / numColumns) * cellHeight;

        grid.push({ x, y });
      }
      self.grid = grid;
      onUpdateData();
    }, [data, selectedItem]);

    const onUpdateData = useCallback(() => {
      // Stop animation
      stopAnimation();

      const { grid } = self;
      self.items = data.map((item, i) => {
        const pos = new Animated.ValueXY(grid[i]);
        const opacity = new Animated.Value(1);
        const item0 = { item, pos, opacity };
        // While dragging
        if (selectedItem && selectedItem.item == item) {
          const { x: x0, y: y0 } = selectedItem.pos;
          const x = x0["_value"];
          const y = y0["_value"];
          if (!self.animation) pos.setValue({ x, y });
          selectedItem.item = item;
          selectedItem.pos = pos;
          selectedItem.opacity = opacity;
          self.startPoint = { x, y };
        }
        return item0;
      });
    }, [data, selectedItem]);

    const prepareAnimations = useCallback(
      (diff) => {
        const config = rest.animationConfig || {
          easing: Easing.ease,
          duration: 300,
          useNativeDriver: true,
        };

        const grid0 = self.grid;
        const items0 = self.items;
        onUpdateGrid();
        const { grid, items } = self;

        const diffItem = head(
          differenceWith(
            diff < 0 ? items0 : items,
            diff < 0 ? items : items0,
            (v1, v2) => v1.item == v2.item
          )
        );
        // console.log('[GridView] diffItem', diffItem)

        const animations = (diff < 0 ? items0 : items).reduce(
          (prev, curr, i) => {
            // Ignore while dragging
            if (selectedItem && curr.item == selectedItem.item) return prev;

            let toValue;

            if (diff < 0) {
              // Delete
              const index = findIndex(items, { item: curr.item });
              toValue = index < 0 ? grid0[i] : grid[index];
              if (index < 0) {
                prev.push(
                  Animated.timing(curr.opacity, { toValue: 0, ...config })
                );
              }
            } else {
              // Add
              const index = findIndex(items0, { item: curr.item });
              if (index >= 0) curr.pos.setValue(grid0[index]);
              toValue = grid[i];
              if (diffItem.item == curr.item) {
                curr.opacity.setValue(0);
                prev.push(
                  Animated.timing(curr.opacity, { toValue: 1, ...config })
                );
              }
            }

            // Animation for position
            prev.push(Animated.timing(curr.pos, { toValue, ...config }));
            return prev;
          },
          []
        );

        if (diff < 0) {
          self.items = items0;
          self.grid = grid0;
        }

        // Stop animation
        stopAnimation();

        self.animation = Animated.parallel(animations);
        self.animation.start(() => {
          // console.log('[Gird] end animation')
          self.animation = undefined;
          if (diff < 0) {
            self.items = items;
            self.grid = grid;
            onEndDeleteAnimation && onEndDeleteAnimation(diffItem.item);
          } else {
            onEndAddAnimation && onEndAddAnimation(diffItem.item);
          }
        });
      },
      [data, selectedItem]
    );

    const stopAnimation = useCallback(() => {
      if (self.animation) {
        self.animation.stop();
        self.animation = undefined;
      }
    }, []);

    prepare();

    //-------------------------------------------------- Handller
    const onLayout = useCallback(
      ({ nativeEvent: { layout } }) => (self.frame = layout),
      []
    );

    const animate = useCallback(() => {
      if (!selectedItem) return;

      const { move, frame, cellSize } = self;
      const s = cellSize / 2;
      let a = 0;
      if (move < s) {
        a = Math.max(-s, move - s); // above
      } else if (move > frame.height - s) {
        a = Math.min(s, move - (frame.height - s)); // below
      }
      a && scroll((a / s) * 10); // scrolling

      self.animationId = requestAnimationFrame(animate);
    }, [selectedItem]);

    const scroll = useCallback(
      (offset) => {
        const { scrollView, cellHeight, numRows, frame, contentOffset } = self;
        const max = cellHeight * numRows - frame.height;
        const offY = Math.max(0, Math.min(max, contentOffset + offset));
        const diff = offY - contentOffset;
        if (Math.abs(diff) > 0.2) {
          // Set offset for the starting point of dragging
          self.startPointOffset += diff;
          // Move the dragging cell
          const { x: x0, y: y0 } = selectedItem.pos;
          const x = x0["_value"];
          const y = y0["_value"] + diff;
          selectedItem.pos.setValue({ x, y });
          reorder(x, y);
          scrollView.scrollTo({ y: offY, animated: false });
        }
      },
      [selectedItem]
    );

    const onScroll = useCallback(
      ({
        nativeEvent: {
          contentOffset: { y },
        },
      }) => (self.contentOffset = y),
      []
    );

    const onLongPress = useCallback(
      (item, index, position) => {
        if (self.animation) return;

        // console.log('[GridView] onLongPress', item, index)
        self.startPoint = position;
        self.startPointOffset = 0;
        setSelectedItem(self.items[index]);
        onBeginDragging && onBeginDragging();
      },
      [onBeginDragging]
    );

    const reorder = useCallback(
      (x, y) => {
        if (self.animation) return;

        const { numRows, cellWidth, cellHeight, grid, items } = self;

        let colum = Math.floor((x + cellWidth / 2) / cellWidth);
        colum = Math.max(0, Math.min(numColumns, colum));

        let row = Math.floor((y + cellHeight / 2) / cellHeight);
        row = Math.max(0, Math.min(numRows, row));

        const index = Math.min(items.length - 1, colum + row * numColumns);
        const isLocked = locked && locked(items[index].item, index);
        const itemIndex = findIndex(items, (v) => v.item == selectedItem.item);

        if (isLocked || itemIndex == index) return;

        move(items, index, itemIndex);

        const animations = items.reduce((prev, curr, i) => {
          index != i &&
            prev.push(
              Animated.timing(curr.pos, {
                toValue: grid[i],
                easing: Easing.ease,
                duration: 200,
                useNativeDriver: true,
              })
            );
          return prev;
        }, []);

        self.animation = Animated.parallel(animations);
        self.animation.start(() => (self.animation = undefined));
      },
      [selectedItem]
    );

    //-------------------------------------------------- PanResponder
    const onMoveShouldSetPanResponder = useCallback(() => {
      if (!self.startPoint) return false;
      const shoudSet = selectedItem != null;
      if (shoudSet) {
        // console.log('[GridView] onMoveShouldSetPanResponder animate')
        animate();
      }
      return shoudSet;
    }, [selectedItem]);

    const onMove = useCallback(
      (event, { moveY, dx, dy }) => {
        const { startPoint, startPointOffset, frame } = self;
        self.move = moveY - frame.y;
        let { x, y } = startPoint;
        // console.log('[GridView] onMove', dx, dy, moveY, x, y)
        x += dx;
        y += dy + startPointOffset;
        selectedItem.pos.setValue({ x, y });
        reorder(x, y);
      },
      [selectedItem]
    );

    const onRelease = useCallback(() => {
      if (!self.startPoint) return;
      // console.log('[GridView] onRelease')
      cancelAnimationFrame(self.animationId);
      self.animationId = undefined;
      self.startPoint = undefined;
      const { grid, items } = self;
      const itemIndex = findIndex(items, (v) => v.item == selectedItem.item);
      itemIndex >= 0 &&
        Animated.timing(selectedItem.pos, {
          toValue: grid[itemIndex],
          easing: Easing.out(Easing.quad),
          duration: 200,
          useNativeDriver: true,
        }).start(onEndRelease);
    }, [selectedItem]);

    const onEndRelease = useCallback(() => {
      onReleaseCell &&
        onReleaseCell({
          newOrder: self.items.map((entry) => entry.item),
        });
      setSelectedItem(undefined);
    }, [onReleaseCell, selectedItem]);

    //-------------------------------------------------- Render
    const _renderItem = useCallback(
      (value, index) => {
        // Update pan responder
        if (index == 0) {
          self.panResponder = PanResponder.create({
            onStartShouldSetPanResponder: () => true,
            onStartShouldSetPanResponderCapture: () => false,
            onMoveShouldSetPanResponder: onMoveShouldSetPanResponder,
            onMoveShouldSetPanResponderCapture: onMoveShouldSetPanResponder,
            onShouldBlockNativeResponder: () => false,
            onPanResponderTerminationRequest: () => false,
            onPanResponderMove: onMove,
            onPanResponderRelease: onRelease,
            onPanResponderEnd: onRelease,
          });
        }

        const { item, pos, opacity } = value;
        const { cellWidth, cellHeight, grid } = self;
        const p = grid[index];
        const isLocked = locked && locked(item, index);
        const key =
          (keyExtractor && keyExtractor(item)) ||
          (typeof item == "string" ? item : `${index}`);

        let style = {
          position: "absolute",
          width: cellWidth,
          height: cellHeight,
        };

        if (!isLocked && selectedItem && value.item == selectedItem.item)
          style = { zIndex: 1, ...style, ...selectedStyle };

        return isLocked ? (
          <View key={key} style={[style, { left: p.x, top: p.y }]}>
            {renderLockedItem(item, index)}
          </View>
        ) : (
          <Animated.View
            {...self.panResponder.panHandlers}
            key={key}
            style={[
              style,
              {
                transform: pos.getTranslateTransform(),
                opacity,
              },
            ]}
          >
            <TouchableOpacity
              style={{ flex: 1 }}
              activeOpacity={activeOpacity}
              delayLongPress={delayLongPress}
              onLongPress={() => onLongPress(item, index, p)}
              onPress={() => onLongPress(item, index, p)} //onPressCell && onPressCell(item, index)}
            >
              {renderItem(item, index)}
            </TouchableOpacity>
          </Animated.View>
        );
      },
      [selectedItem, renderLockedItem, renderItem]
    );

    return (
      <ScrollView
        {...rest}
        ref={(ref) => (self.scrollView = ref)}
        onLayout={onLayout}
        onScroll={onScroll}
        scrollEnabled={!selectedItem}
        scrollEventThrottle={16}
      >
        <View
          style={{
            height: self.numRows * self.cellHeight,
          }}
        />
        {self.items.map((v, i) => _renderItem(v, i))}
      </ScrollView>
    );
  }
);

/**
 * move
 * @param array
 * @param i
 * @param j
 */
const move = (array, i, j) => array.splice(i, 0, array.splice(j, 1)[0]);

export default DraggableGridView;
