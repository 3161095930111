import React from "react";
import { View } from "react-native";
import { useFormikContext } from "formik";

import { FormField, FormFieldPicker } from "components/elements/forms";

import states from "static/data/states.json";
import countryList from "static/data/countries.json";

import I18NContext from "library/contexts/i18N";

const BillingInfo = (props) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { values } = useFormikContext();

  const {
    paymentInfo: {
      CREDIT_CARD: { billingInfo: { country = "" } = {} } = {},
    } = {},
  } = values;

  const { isSmallScreen, isSavedCard } = props;
  const path = `paymentInfo.CREDIT_CARD.billingInfo`;

  return (
    <View
      style={{
        width: "100%",
      }}
      testID="billing-info"
    >
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          marginTop: 10,
        }}
      >
        <FormField
          fsClass="fs-exclude"
          name="addressLine1"
          label={Localise(messages, "Billing Address")}
          placeholder={Localise(messages, "Billing Address")}
          inputStyle={{ width: "100%" }}
          containerStyle={{ width: isSmallScreen ? "100%" : "80%" }}
          path={path}
          editable={!isSavedCard}
          grayedOutOnDisable={true}
        />
        <FormField
          autoCapitalize="none"
          autoCorrect={false}
          autoComplete="new-password"
          name="suite"
          fsClass="fs-exclude"
          label={"Apt/Suite/Unit"}
          placeholder={"Apt/Suite/Unit"}
          inputStyle={{ width: "100%" }}
          containerStyle={{ width: isSmallScreen ? "50%" : "20%" }}
          path={path}
          editable={!isSavedCard}
          grayedOutOnDisable={true}
        />
        <FormField
          name="city"
          label={"City"}
          placeholder={"City"}
          fsClass="fs-exclude"
          containerStyle={{ width: isSmallScreen ? "50%" : "30%" }}
          path={path}
          testID="city"
          accessibilityLabel="city"
          editable={!isSavedCard}
          grayedOutOnDisable={true}
        />
        <FormFieldPicker
          containerStyle={{
            width: isSmallScreen ? "100%" : "30%",
          }}
          fsClass="fs-exclude"
          placeholder={{ label: "Select State" }}
          data={states[country] || []}
          name="state"
          label={"State"}
          path={path}
          disabled={isSavedCard}
        />
        <FormFieldPicker
          containerStyle={{
            width: isSmallScreen ? "100%" : "40%",
          }}
          placeholder={{}}
          data={countryList}
          fsClass="fs-exclude"
          name="country"
          label={"Country"}
          path={path}
          disabled={isSavedCard}
        />
      </View>
    </View>
  );
};

export default BillingInfo;
