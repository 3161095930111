export const SLICE_NAME = "customerDirectory";

export const InitOrdersData = {
  customerOrders: { orders: [] },
};

export const meatBallActions = [
  "accept",
  "design",
  "designed",
  "out-for-delivery",
  "transfer",
  "assign",
  "accept-print",
  "printOrder",
  "copy-order",
];

export const menuActions = [
  "delivery-confirmation",
  "delivery-exception",
  "cancel",
  "reject",
  "markAsUnread",
  "adjustment",
  "assign-to-designer",
];
