import { useSelector } from "react-redux";
import { generateContent } from "components/views/drawer/order-details/helper";
import {
  selectCustomerCurrentBalance,
  selectCustomerDetails,
} from "library/sagas/views/home/drawer/customer-directory/selector";
import { selectShopCode } from "library/sagas/views/home/drawer/shop-settings/common/selector";

const CurrentBalance = () => {
  const {
    previousBalance = 0,
    newCharges = 0,
    paymentsReceived = 0,
    totalBalance = 0,
    creditBalance = 0,
  } = useSelector(selectCustomerCurrentBalance);

  const customerDetails = useSelector(selectCustomerDetails);
  const shopCode = useSelector(selectShopCode);

  const { memberCodeBalance, accountType } =
    customerDetails?.houseAccountInfo || {};
  const memberCodeBalanceDetails = memberCodeBalance?.find(
    (item) => item.memberCode === shopCode
  );
  const isEmpty =
    !memberCodeBalanceDetails ||
    Object.keys(memberCodeBalanceDetails).length === 0;

  let content = {
    "Previous Balance, Debits": isEmpty
      ? 0
      : memberCodeBalanceDetails?.previousBalance ?? previousBalance ?? 0,
    "Charges, Debits": isEmpty
      ? 0
      : memberCodeBalanceDetails?.newCharges ?? newCharges ?? 0,
    "Payments, Credits ": isEmpty
      ? 0
      : memberCodeBalanceDetails?.paymentsReceived ?? paymentsReceived ?? 0,
    "Balance Due": isEmpty
      ? 0
      : memberCodeBalanceDetails?.totalBalance ?? totalBalance ?? 0,
  };
  let contentStyles = {
    "Previous Balance, Debits": { isPrice: true },
    "Charges, Debits": { isPrice: true },
    "Payments, Credits ": { isPrice: true, isNegative: true },
    "Balance Due": { isPrice: true },
  };

  if (accountType === "Open_Item") {
    content = {
      "Credit Balance": isEmpty
        ? 0
        : memberCodeBalanceDetails?.creditBalance ?? creditBalance ?? 0,
      ...content,
    };
    contentStyles = {
      "Credit Balance": { isPrice: true, isNegative: true },
      ...contentStyles,
    };
  }
  return generateContent(content, false, true, contentStyles, {
    paddingLeft: 10,
  });
};

export default CurrentBalance;
