import React from "react";
import { Button } from "react-native-elements";
import I18NContext from "library/contexts/i18N";
import { theme } from "styles/theme";

const SecondaryButton = ({
  title,
  action,
  style,
  disabled,
  loading = false,
  loadingProps = {},
}) => {
  const { Localise, messages } = React.useContext(I18NContext);

  return (
    <Button
      titleStyle={{
        ...theme.Button.secondaryTitleStyle,
        fontSize: 12,
      }}
      buttonStyle={{
        ...theme.Button.secondaryButtonStyle,
        paddingVertical: 5,
        paddingHorizontal: 5,
      }}
      containerStyle={{
        marginVertical: 0,
        marginHorizontal: 0,
        justifyContent: "center",
        ...style,
      }}
      onPress={action}
      title={Localise(messages, title)}
      testID={title}
      loading={loading}
      loadingProps={loadingProps}
      accessibilityLabel={title}
      disabled={disabled}
    />
  );
};

export default SecondaryButton;
