import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray } from "formik";
import { View, Text } from "react-native";

import {
  CreateNewSuspension,
  ActiveSuspensions,
  NoSuspensions,
  PastSuspensions,
} from "./suspensions";
import I18NContext from "library/contexts/i18N";
import { Form } from "components/elements/forms";
import { Spinner } from "components/elements";

import {
  selectWebsiteStatus,
  selectPastSuspensionsLimit,
} from "library/sagas/views/home/drawer/mercury-online/selector";
import {
  setWebsiteStatusPollStart,
  setWebsiteStatusPollStop,
  setPastSuspensionsLimit,
} from "library/sagas/views/home/drawer/mercury-online/slice";

import { getValidationSchema } from "./yup";
import { fonts } from "styles/theme";
import moment from "moment";

const WebsiteStatus = () => {
  const dispatch = useDispatch();
  const { messages, Localise } = React.useContext(I18NContext);
  const { content: suspensions, error: apiError } =
    useSelector(selectWebsiteStatus);
  const limit = useSelector(selectPastSuspensionsLimit);
  const validationSchema = getValidationSchema(Localise, messages);
  const currentTimeZone = moment.tz(moment.tz.guess()).format("z");

  if (!suspensions) {
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );
  }

  return (
    <Form
      initialValues={suspensions}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={false}
      render={({
        values: { activeSuspensions = [], pastSuspensions = [] } = {},
        dirty,
      }) => {
        useEffect(() => {
          dispatch(setWebsiteStatusPollStart());
          return () => dispatch(setWebsiteStatusPollStop());
        }, [activeSuspensions]);

        return (
          <>
            <Text style={fonts.heading4}>
              {Localise(messages, "Time Zone")}: {currentTimeZone}
            </Text>
            <FieldArray
              name="activeSuspensions"
              render={(formikArrayHelper) => {
                return (
                  <>
                    {activeSuspensions.length ? (
                      <ActiveSuspensions data={activeSuspensions} />
                    ) : (
                      <NoSuspensions formikArrayHelper={formikArrayHelper} />
                    )}
                    {!activeSuspensions.find((s) => s.type === "new") ? (
                      <CreateNewSuspension
                        formikArrayHelper={formikArrayHelper}
                      />
                    ) : null}
                  </>
                );
              }}
            />
            {!dirty && apiError !== "" && (
              <Text style={{ ...fonts.error, marginBottom: 0 }}>
                {Localise(messages, apiError)}
              </Text>
            )}
            {pastSuspensions.length > 0 && (
              <PastSuspensions
                data={pastSuspensions}
                limit={limit}
                onViewMore={() => dispatch(setPastSuspensionsLimit())}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default WebsiteStatus;
