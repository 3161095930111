import React from "react";
import { View, TouchableOpacity, Image } from "react-native";
import { Text } from "react-native-elements";
import { useSelector } from "react-redux";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { Tooltip } from "components/elements";

import tw from "tailwind-rn";

import IMAGES from "static/assets/images";
import Listing from "components/containers/listing-new";
import I18NContext from "library/contexts/i18N";
import { selectCustomerDetails } from "library/sagas/views/home/drawer/customer-directory/selector";

const Subscriptions = ({ UIConfig }) => {
  const navigation = useNavigation();
  const { messages, Localise } = React.useContext(I18NContext);

  const customerDetails = useSelector(selectCustomerDetails);

  return (
    <>
      <View style={tw("flex-row pb-4")}>
        <TouchableOpacity
          style={tw("flex-row justify-around items-center")}
          onPress={() => {
            navigation.dispatch(
              CommonActions.navigate({
                name: "create-order",
                params: {
                  customerDetails,
                  type: "subscription",
                  action: "create",
                },
              })
            );
          }}
          testID="create_new_subscription"
          accessibilityLabel="create_new_subscription"
        >
          <Tooltip
            text={Localise(messages, "Create Subscription")}
            renderForWebOnly={true}
          >
            <Image
              style={{
                width: 28,
                height: 28,
              }}
              resizeMode="cover"
              source={IMAGES["create-order"]}
            />
          </Tooltip>
          <Text style={{ paddingLeft: 8, fontSize: 14 }}>
            {Localise(messages, "Create New Subscription")}
          </Text>
        </TouchableOpacity>
      </View>

      <Listing UIConfig={UIConfig} />
    </>
  );
};

export default Subscriptions;
