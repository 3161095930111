import { combineReducers } from "redux";
import { SLICE_NAME } from "./constants";

import home from "./home/reducer";

const reducer = {
  [SLICE_NAME]: combineReducers({
    ...home,
  }),
};

export default reducer;
