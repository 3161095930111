import React from "react";
import { ActivityIndicator, View } from "react-native";
import { Text } from "react-native-elements";
import { Accordion } from "components/elements";
import { backgroundColors, fonts, colors } from "styles/theme";
import tw from "tailwind-rn";

// eslint-disable-next-line react/display-name
const PageAccess = React.memo(({ entitlements, loading }) => {
  return (
    <View
      style={[
        {
          borderWidth: 1,
          borderColor: colors.grayScaleLight,
        },
        tw("my-3"),
      ]}
      pointerEvents={"auto"}
    >
      <Accordion
        title={"Paid Features Access"}
        defaultOpen={true}
        handleOnPress={() => {}}
        labelStyle={{
          marginTop: 0,
          height: 50,
          backgroundColor: backgroundColors.navBar,
          borderBottomWidth: 1,
          borderColor: colors.grayScaleLight,
          paddingHorizontal: 12,
        }}
        titleStyle={{
          color: colors.primary,
          paddingLeft: 10,
        }}
        testID="page_access_info"
      >
        <View
          style={{
            backgroundColor: backgroundColors.secondary,
            padding: 10,
          }}
        >
          {loading && <ActivityIndicator color={colors.activityIndicator} />}
          {entitlements.map((entitlement, index) => (
            <View key={index} style={[tw("flex flex-row items-center p-2")]}>
              <Text
                style={{
                  ...fonts.default,
                  fontSize: 15,
                }}
              >
                - {entitlement}
              </Text>
            </View>
          ))}
        </View>
      </Accordion>
    </View>
  );
});

export default PageAccess;
