import React, { useEffect } from "react";
import { isValidJSON } from "library/utils/formatter";
import QRScanErrorModal from "./error-modal";
import isEmpty from "lodash/isEmpty";

const ScannerListener = ({
  setQrValue,
  modalVisible,
  setModalVisibile,
  errorText,
  setErrorText,
  setShowProcessing,
}) => {
  let barcode = "";
  let interval;

  // This is to format the object in the required format - keys in lowerCase and values in upperCase.
  const formatObject = (obj) =>
    Object.keys(obj).reduce((acc, k) => {
      acc[k.toLowerCase()] = obj[k].toUpperCase();
      return acc;
    }, {});

  const handleBarcodeScan = (evt) => {
    if (interval) clearInterval(interval);
    if (evt.code == "BracketRight") {
      barcode += evt.key;
      if (barcode) handleBarcode(barcode);
      barcode = "";
      return;
    }
    // exclude these keys from reading
    if (
      ![
        "Shift",
        "Control",
        "CapsLock",
        "PageUp",
        "PageDown",
        "Delete",
        "ContextMenu",
        "Alt",
        "Meta",
        "Escape"
      ].includes(evt.key)
    )
      barcode += evt.key;

    interval = setInterval(() => {
      // Expecting min 10 digit barcode / QR code to show the error
      if (barcode && barcode.length > 10 && !isValidJSON(barcode)) {
        setModalVisibile(true);
        setErrorText("QR code is not from MercuryHQ");
      }
      barcode = "";
    }, 100);
  };

  function handleBarcode(scanned_barcode) {
    console.log("scanned_barcode :>> ", scanned_barcode);
    if (scanned_barcode.length > 1) {
      // To verify valid json structure & empty object
      if (
        isValidJSON(scanned_barcode) &&
        !isEmpty(JSON.parse(scanned_barcode))
      ) {
        const parsedQRValue = JSON.parse(scanned_barcode);
        setQrValue(formatObject(parsedQRValue));
        setShowProcessing && setShowProcessing(true);
      } else {
        setModalVisibile(true);
        setErrorText("QR code is not from MercuryHQ");
      }
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleBarcodeScan);

    return () => {
      document.removeEventListener("keydown", handleBarcodeScan);
    };
  }, []);

  return (
    <QRScanErrorModal
      modalVisible={modalVisible}
      handleSecondary={() => {
        setErrorText("");
        setModalVisibile(false);
      }}
      errorText={errorText}
    />
  );
};

export default ScannerListener;
