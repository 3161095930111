import audio from "../../../../../static/assets/sounds/notification.mp3";

const AudibleNotification = () => {
  try {
    new Audio(audio).play();
  } catch (e) {
    console.log("*** error while playing the audio" + e);
  }
};

export default AudibleNotification;
