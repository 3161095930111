import * as Yup from "yup";

export const getAdjustmentFormValidationSchema = (Localise, messages) =>
  Yup.object().shape({
    deliveryDate: Yup.date()
      .label("deliveryDate")
      .required(Localise(messages, "Enter Delivery Date")),
    combinedReportNumber: Yup.string()
      .matches(
        /^\d{2}[-]\d{1}$/,
        Localise(messages, "Please enter a correct Report #")
      )
      .required(Localise(messages, "Enter Report Number")),
    recipientName: Yup.string()
      .label("recipientName")
      .required(Localise(messages, "Enter Recipient Name")),
    rofNumber: Yup.string()
      .label("rofNumber")
      .required(Localise(messages, "Enter Mercury Number")),
    receivingMemberCode: Yup.string()
      .label("receivingMemberCode")
      .required(Localise(messages, "Enter Receiving Member Code")),
    sendingMemberCode: Yup.string()
      .label("sendingMemberCode")
      .required(Localise(messages, "Enter Sending Member Code")),
    adjustedPrice: Yup.string()
      .label("correctAmount")
      .required(Localise(messages, "Enter correct amount"))
      .test(
        "correctAmount",
        Localise(messages, "Invalid correct amount"),
        (value) => value && !isNaN(value)
      ),
    price: Yup.string()
      .label("Amount Billed")
      .required(Localise(messages, "Enter Amount Billed"))
      .test(
        "Amount Billed",
        Localise(messages, "Invalid amount billed"),
        (value) => value && !isNaN(value)
      ),
    comments: Yup.string().label("notes"),
    adjustmentReason: Yup.string()
      .label("reason")
      .required(Localise(messages, "Select reason")),
  });

export const getReasonFormValidationSchema = (Localise, messages) =>
  Yup.object().shape({
    reason: Yup.string()
      .label("reason")
      .required(Localise(messages, "Select reason")),
  });

export const getTransferFormValidationSchema = (Localise, messages) =>
  Yup.object().shape({
    transferTo: Yup.string()
      .label("tranferTo")
      .required(Localise(messages, "Select Transfer Store")),
  });

export const getOnDemandInvoiceFormValidationSchema = (Localise, messages) =>
  Yup.object({
    recipientEmail: Yup.array().of(
      Yup.string()
        .matches(
          /^\w+[+-.\w]*@(?!(?:donotsend)\.com$)\w[-.\w]*\.\w{2,4}$/,
          Localise(messages, "Invalid Email address")
        )
        .test("first-email-required", "Please enter Email", function (value) {
          // Only apply the 'required' validation for the zeroth index
          if (this.options.index === 0) {
            return !!value?.trim();
          }
          return true; // No validation for other indices
        })
    ),
  });
