import React, { forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { View, TouchableOpacity, Platform } from "react-native";
import { Image, Text } from "react-native-elements";
import moment from "moment";

import { Accordion, Divider, TabBar } from "components/elements";
import { SubmitButton } from "components/elements/forms";
import ShopCodePicker from "components/containers/shop-code-picker";

import UserProfileContext from "library/contexts/userProfile";
import UserProfileStorage from "library/storage/userProfile";
import { Entitlements } from "library/utils/entitlements";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { selectShopCode } from "library/sagas/views/home/drawer/mercury-online/selector";
import { setShopCode } from "library/sagas/views/home/drawer/mercury-online/slice";
import {
  selectApiResponse,
  selectScheduledContentScreen,
} from "library/sagas/views/home/drawer/ftd-admin/mol-settings/selector";
import { setScheduledContentScreenTabs } from "library/sagas/views/home/drawer/ftd-admin/mol-settings/slice";
import { LabelField } from "components/wrappers";
import { theme, backgroundColors, fonts, colors } from "styles/theme";
import IMAGES from "static/assets/images";
import tw from "tailwind-rn";
import { useFormikContext } from "formik";

export const Header = ({ title }) => {
  const shopCode = useSelector(selectShopCode);
  const { isMobile, isTablet } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);
  const { permissions } = React.useContext(AppSettingsContext);
  const { memberCodes = [] } = React.useContext(UserProfileContext);
  const shopNames = UserProfileStorage.getAllShopNames();
  const selectedCode = shopCode === "all" ? memberCodes[0] : shopCode;
  const shopName =
    shopCode === "all" && memberCodes.length > 1
      ? "All Shops"
      : `${shopNames[selectedCode]}, ${selectedCode}`;
  const shopCodeOptions = [];

  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.MERCURY_ONLINE in permissions[memberCode])
      shopCodeOptions.push(memberCode);
  });

  return (
    <>
      <View
        style={tw(
          `flex flex-row flex-wrap justify-between items-${
            Platform.OS !== "web" ? "center" : "baseline"
          } text-${isMobile ? "left" : "right"}`
        )}
      >
        <Text
          style={[
            fonts.heading1,
            tw("pb-3"),
            isMobile ? tw("pt-5") : tw("pt-4"),
            isTablet && { fontSize: 16, fontWeight: "400" },
          ]}
        >
          {Localise(messages, title)}
        </Text>
        <Text style={fonts.heading4} key={"middle"}>
          {Localise(messages, "Showing")}{" "}
          {`${Localise(messages, "Member")} : ${shopName}`}
        </Text>
        <ShopCodePicker
          shopCodeSelector={selectShopCode}
          setShopCodeAction={setShopCode}
          shopCodeOptions={shopCodeOptions}
        />
      </View>
      {!isMobile && <Divider />}
    </>
  );
};

export const ExtendedScreenHeader = ({ title, onClose, component }) => {
  const { isDesktop } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);

  const {
    components: scheduledContentComponents,
    activeTab,
    tabs = [],
  } = useSelector(selectScheduledContentScreen);

  const { scheduledContent = "" } = useSelector(selectApiResponse);
  const dispatch = useDispatch();
  const defaultTabs = tabs.filter((val) => val.key !== "select-shops");

  let parsedScheduledContent = {};

  try {
    parsedScheduledContent = JSON.parse(scheduledContent);
  } catch {
    //Do nothing
  }

  const isScheduledContentActive = moment(
    parsedScheduledContent?.startDate
  ).isSameOrBefore(moment().format().split("T")[0]);

  return (
    <View>
      <View
        style={[
          tw("flex flex-row items-start justify-between"),
          {
            backgroundColor: colors.navBarActive,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
        ]}
      >
        <View style={[tw("flex flex-col flex-wrap m-5")]}>
          <LabelField
            heading={true}
            style={fonts.heading3}
            text={Localise(messages, title)}
          />
          <Text style={{ ...fonts.style2, paddingTop: 4, paddingBottom: 8 }}>
            {Localise(
              messages,
              "Changing the details below will change the settings on the site"
            )}
          </Text>
        </View>
        {isDesktop && (
          <View style={tw("m-4")}>
            <TouchableOpacity onPress={onClose} testID="close">
              <Image
                style={{ width: 20, height: 20 }}
                resizeMode="cover"
                source={IMAGES["close"]}
              />
            </TouchableOpacity>
          </View>
        )}
      </View>
      {scheduledContentComponents.includes(component) &&
        scheduledContent !== "" && (
          <View
            style={[
              tw("flex flex-row items-center justify-center"),
              {
                backgroundColor: colors.priceBanner,
                height: 35,
              },
            ]}
          >
            <Text
              style={[
                tw("flex flex-row items-center justify-center"),
                {
                  ...fonts.heading4,
                  fontSize: 14,
                },
              ]}
            >
              {Localise(
                messages,
                `${
                  isScheduledContentActive ? "Temporary" : "Default"
                } content is active `
              )}{" "}
            </Text>
          </View>
        )}
      {scheduledContentComponents.includes(component) && (
        <View style={tw("mx-5 mt-5")}>
          <TabBar
            containerStyle={tw("w-full")}
            tabNames={defaultTabs}
            currentTab={activeTab}
            setCurrentTab={(activeTab) => {
              dispatch(setScheduledContentScreenTabs({ value: activeTab }));
            }}
            style={{
              marginTop: -10,
              zIndex: 0,
            }}
          />
        </View>
      )}
    </View>
  );
};

export const EmptyScreen = () => null;

export const Section = ({
  title,
  children,
  subTitleSelector,
  subtitle,
  defaultOpen = false,
  overrideZindex = 0,
}) => {
  const { isMobile } = React.useContext(DeviceContext);
  const subTitle =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    subtitle || (subTitleSelector ? useSelector(subTitleSelector) : "");
  const { messages, Localise } = React.useContext(I18NContext);
  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
        opacity: 1,
        marginBottom: 10,
        zIndex: overrideZindex,
      }}
    >
      <Accordion
        headerContent={
          subTitle &&
          ((openAccordion) => (
            <View
              style={[tw("flex flex-row items-center justify-between w-full")]}
            >
              <View style={[tw("flex flex-row items-center")]}>
                <Text
                  style={{
                    ...theme.Accordion.titleStyle,
                    color: colors.primary,
                    paddingLeft: 10,
                  }}
                >
                  {Localise(messages, title)}{" "}
                </Text>
                <Text style={[tw("ml-4")]}>{Localise(messages, subTitle)}</Text>
              </View>
              <Image
                style={{ width: 20, height: 20 }}
                containerStyle={{ marginLeft: "auto" }}
                source={
                  IMAGES[
                    openAccordion ? "expanded-section" : "collapsed-section"
                  ]
                }
              />
            </View>
          ))
        }
        title={Localise(messages, title)}
        defaultOpen={defaultOpen}
        handleOnPress={() => {}}
        labelStyle={{
          marginTop: 0,
          height: 50,
          backgroundColor: backgroundColors.navBar,
          borderBottomWidth: 1,
          borderColor: colors.grayScaleLight,
        }}
        closeStyle={{
          borderBottomWidth: 0,
        }}
        titleStyle={{
          color: colors.primary,
          paddingLeft: 10,
        }}
        iconStyle={{
          color: colors.primary,
        }}
      >
        <View
          style={{
            backgroundColor: backgroundColors.secondary,
            padding: isMobile ? 10 : 20,
          }}
        >
          {children}
        </View>
      </Accordion>
    </View>
  );
};

function saveCancelButtons({ disableOnDirty = true, onCancel }, ref) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { dirty, resetForm } = useFormikContext();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { Localise, messages } = React.useContext(I18NContext);

  return (
    <View style={tw("justify-end flex-row")}>
      {dirty && (
        <View style={{ marginRight: 10, justifyContent: "center" }}>
          <TouchableOpacity
            onPress={() => {
              onCancel && onCancel();
              resetForm();
            }}
            onResponderStart={() =>
              setTimeout(() => {
                onCancel && onCancel();
                resetForm();
              }, 0)
            }
            testID={"cancel"}
            accessibilityLabel={"cancel"}
          >
            <View>
              <Text style={fonts.link1}>{Localise(messages, "Cancel")}</Text>
            </View>
          </TouchableOpacity>
        </View>
      )}
      <SubmitButton
        containerStyle={{ width: 100, marginLeft: 0 }}
        title={Localise(messages, "Save")}
        disableOnDirty={disableOnDirty}
        refFromProp={ref}
      />
    </View>
  );
}

export const SaveCancelButtons = forwardRef(saveCancelButtons);
