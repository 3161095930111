import { request } from "library/utils/request";
import { ToasterHandler } from "components/elements";
import { Messages } from "./config";

export const invalidateAllUserSessions = (hideModal, setShowLoading) => {
  setShowLoading("load");

  hideModal();

  request("invalidate-sessions", {
    clearAll: true,
    deviceType: "",
    memberCodes: "",
    email: "",
    mobileTokenId: "",
  })
    .then((res) => {
      setShowLoading("");
      ToasterHandler("success", Messages.successMessage);
    })
    .catch((err) => {
      setShowLoading("");

      ToasterHandler("error", err.message || Messages.failureMessage);
    });
};
