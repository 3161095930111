import React, { useContext, useEffect, useRef } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { setSelectScreenKey } from "library/sagas/views/home/drawer/delivery/slice";
import {
  setCurrentPage,
  setIsBulkDSRequestUploadInProgress,
} from "library/sagas/ongoing/global-data/slice";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { theme, colors, fonts } from "styles/theme";
import useStateIfMounted from "library/utils/useStateIfMounted";
import tw from "tailwind-rn";
import { Button } from "react-native-elements";
import { Entitlements } from "library/utils/entitlements";
import { ToasterHandler, CustomModal } from "components/elements";
import { AppSettingsContext } from "library/contexts/appSettings";
import { handleSavingReport } from "library/utils/download";
import { request } from "library/utils/request";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import * as DocumentPicker from "expo-document-picker";
import UserProfileStorage from "library/storage/userProfile";
import moment from "moment";
import { selectIsBulkDSRequestUploadInProgress } from "library/sagas/ongoing/global-data/selector";
import { selectDashboardShopCode } from "library/sagas/ongoing/global-data/selector";

const CreateBulkRequest = () => {
  const { messages, Localise } = useContext(I18NContext);
  const dashboardSelectedShopCode = useSelector(selectDashboardShopCode);
  const isBulkDSInProgress = useSelector(selectIsBulkDSRequestUploadInProgress);
  const { permissions } = useContext(AppSettingsContext);
  const filteredShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.DELIVERY_TASKS in permissions[memberCode])
      filteredShopCodes.push(memberCode);
  });
  const selectedShopCode =
    dashboardSelectedShopCode !== "all"
      ? dashboardSelectedShopCode
      : filteredShopCodes[0];
  const [isModalVisible, setIsModalVisible] = useStateIfMounted(false);
  const pollInterval = 2 * 60 * 1000; // 2 mins
  const { isDesktop } = useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const [showSampleFileLoading, setShowSampleFileLoading] =
    useStateIfMounted(false);
  const [isRepotFileLoading, setisRepotFileLoading] = useStateIfMounted(false);
  const [bulkDsFile, setBulkDsFile] = useStateIfMounted({});
  const [bulkDsError, setBulkDsError] = useStateIfMounted();
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] =
    useStateIfMounted(false);
  const [isFileUploadButtonEnabled, setIsFileUploadButtonEnabled] =
    useStateIfMounted(false);
  const [bulkDsResponse, setBulkDsResponse] = useStateIfMounted({});
  const [uploadInProgress, setUploadInProgress] = useStateIfMounted(false);
  const [timeStamp, setTimestamp] = useStateIfMounted(false);
  const bulkDsTimerToClear = useRef(null);
  const navigation = useNavigation();

  const dispatch = useDispatch();
  useEffect(() => {
    const jobList = UserProfileStorage.getJobsList();
    jobList?.[selectedShopCode] &&
      getBulkDsRequestStatus(jobList[selectedShopCode]);
  }, []);

  useEffect(() => {
    clearTimeout(bulkDsTimerToClear.current);
    if (selectedShopCode !== "all" && timeStamp && pollInterval) {
      bulkDsTimerToClear.current = setTimeout(() => {
        const jobList = UserProfileStorage.getJobsList();
        jobList?.[selectedShopCode] &&
          getBulkDsRequestStatus(jobList[selectedShopCode]);
      }, pollInterval);
    }
    return () => {
      clearTimeout(bulkDsTimerToClear.current);
    };
  }, [timeStamp]);

  const getBulkDsRequestStatus = (bulkImportJobId) => {
    request("get-bulk-ds-request-status", {
      shopCode: selectedShopCode,
      bulkImportJobId,
    })
      .then((res) => {
        if (["YET_TO_START", "IN_PROGRESS"].includes(res?.status)) {
          setUploadInProgress(true);
          setIsFileUploadButtonEnabled(true);
          setTimestamp(moment().utc().format());
          dispatch(setIsBulkDSRequestUploadInProgress(true));
        } else {
          const jobList = UserProfileStorage.getJobsList();
          var key = selectedShopCode;
          jobList && delete jobList[key];
          UserProfileStorage.setJobsList(jobList);
          dispatch(setIsBulkDSRequestUploadInProgress(false));
          setUploadInProgress(false);
          setIsFileUploadButtonEnabled(false);
        }
        setBulkDsResponse(res);
      })
      .catch((err) => {
        setUploadInProgress(false);
      });
  };

  const downloadFile = (fileType, serviceName, params, fileName, filePath) => {
    request(serviceName, params)
      .then((res) => {
        handleSavingReport(messages, Localise, res, fileName, filePath);
        fileType === "sample-file"
          ? setShowSampleFileLoading(false)
          : setisRepotFileLoading(false);
      })
      .catch(() => {
        fileType === "sample-file"
          ? setShowSampleFileLoading(false)
          : setisRepotFileLoading(false);
        ToasterHandler(
          "error",
          Localise(
            messages,
            `${
              fileType === "sample-file" ? "Sample" : "Report"
            } file download failed, please try again.`
          )
        );
      });
  };

  const chooseFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "text/csv",
    });

    if (result.type === "success") {
      setBulkDsFile(result);
      setIsSubmitButtonEnabled(true);
      setBulkDsError("");
    }
  };

  const uploadFile = () => {
    const bulkDsRequest = new FormData();
    bulkDsRequest.append("files", bulkDsFile?.file);
    setUploadInProgress(true);
    setIsFileUploadButtonEnabled(true);
    dispatch(setIsBulkDSRequestUploadInProgress(true));
    request("create-bulk-ds-request", {
      shopCode: selectedShopCode,
      body: bulkDsRequest,
    })
      .then((res) => {
        setBulkDsResponse(res);
        UserProfileStorage.setJobsList({
          ...UserProfileStorage.getJobsList(),
          ...{ [selectedShopCode]: res.jobId },
        });
        if (["YET_TO_START", "IN_PROGRESS"].includes(res?.status)) {
          setTimestamp(moment().utc().format());
        }
        setBulkDsError("");
      })
      .catch((err) => {
        setUploadInProgress(false);
        setBulkDsFile({});
        setIsFileUploadButtonEnabled(false);
        dispatch(setIsBulkDSRequestUploadInProgress(false));
        let errorMessage = Localise(
          messages,
          "Delivery Service request failed, please try again."
        );
        if (
          !["ERROR", "CATCH ALL ERROR", "null"].includes(err) &&
          err.length > 0
        ) {
          errorMessage = err;
        }
        setBulkDsError(errorMessage);
      });
  };
  const modalContent = {
    content: (
      <Text
        style={{
          ...tw("p-4"),
          fontSize: 15,
          color: colors.highlighter,
          textAlign: "center",
        }}
      >
        {Localise(messages, "File upload in progress")}
      </Text>
    ),
    buttons: [
      {
        type: "primary",
        title: Localise(messages, "OK"),
      },
    ],
  };

  const handleContinue = () => {
    setIsModalVisible(false);
    dispatch(setIsBulkDSRequestUploadInProgress(false));
    navigation.dispatch(
      CommonActions.navigate({
        name: "lmds-delivery",
      })
    );
    dispatch(setCurrentPage("DeliveryDashboard"));
    dispatch(setSelectScreenKey("DS"));
    UserProfileStorage.setCurrentTab("DS");
  };

  const modal = (
    <CustomModal
      modalVisible={isModalVisible}
      modalContent={modalContent}
      primaryhandler={handleContinue}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: "white" },
      ]}
      modalStyle={
        Platform.OS !== "web" && {
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          backgroundColor: "#00000070",
          color: "#FFFFFF",
        }
      }
    ></CustomModal>
  );
  return (
    <>
      <View
        style={{
          borderColor: colors.light,
          borderWidth: 1,
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.1,
          shadowRadius: 5,
          borderRadius: 5,
          margin: 20,
          padding: 20,
          gap: 10,
          ...(isDesktop && { maxWidth: "60%" }),
        }}
      >
        <View>
          <Text
            style={{
              ...fonts.Text,
              ...(isSmallScreen && { fontSize: 14 }),
              ...tw("text-black"),
              fontStyle: "italic",
              display: "flex",
            }}
          >
            {Localise(
              messages,
              "Upload multiple Delivery Service requests at one time via a spreadsheet"
            )}{" "}
            (
            <TouchableOpacity
              onPress={() => {
                // downloadSampleFile();
                downloadFile(
                  "sample-file",
                  "download-image",
                  {
                    imageName: "bulk-ds-request-sample-file.csv",
                  },
                  "bulk-ds-request-sample-file.csv",
                  "Mercury HQ Bulk Imports"
                );
                setShowSampleFileLoading(true);
              }}
            >
              <Text
                style={[
                  fonts.link2,
                  {
                    ...(isDesktop
                      ? { marginTop: 0, fontSize: 14 }
                      : { bottom: -3, ...tw("pt-2") }),
                  },
                ]}
              >
                {Localise(messages, "sample file")}
              </Text>
            </TouchableOpacity>
            )
            <ActivityIndicator
              style={{ marginLeft: 3 }}
              color={colors.activityIndicator}
              animating={showSampleFileLoading}
            />
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <Button
            disabled={isFileUploadButtonEnabled}
            title={Localise(messages, "Upload Your File")}
            titleStyle={theme.Button.secondaryTitleStyle}
            buttonStyle={{
              ...theme.Button.secondaryButtonStyle,
              paddingVertical: 8,
              paddingHorizontal: 20,
            }}
            containerStyle={{
              margin: 0,
              justifyContent: "center",
            }}
            onPress={() => {
              isDesktop && chooseFile();
              isDesktop && setBulkDsResponse({});
            }}
          />
          <Button
            title={Localise(messages, "Submit")}
            disabled={
              !isSubmitButtonEnabled ||
              !(
                bulkDsResponse ??
                !["COMPLETED", "COMPLETED_WITH_ERROR"].includes(
                  bulkDsResponse?.status
                )
              )
            }
            containerStyle={{
              margin: 10,
            }}
            onPress={() => {
              isDesktop && setIsSubmitButtonEnabled(false);
              isDesktop && uploadFile();
            }}
          />
          <ActivityIndicator
            color={colors.activityIndicator}
            animating={uploadInProgress}
          ></ActivityIndicator>
        </View>
        {bulkDsError && (
          <Text style={{ color: "red" }}>
            {Localise(messages, bulkDsError)}
          </Text>
        )}
        {bulkDsFile?.name || bulkDsResponse?.jobId ? (
          <Text>
            {bulkDsResponse?.jobId
              ? bulkDsResponse?.files?.[0]?.fileName
                  ?.split("-")
                  .splice(1)
                  .join("-")
              : bulkDsFile?.name || ""}
          </Text>
        ) : (
          <></>
        )}
        {uploadInProgress && (
          <Text
            style={{
              color: colors.black,
            }}
          >
            {Localise(messages, "Processing delivery service requests")}
          </Text>
        )}
        {bulkDsResponse?.jobId &&
          !["YET_TO_START", "IN_PROGRESS"].includes(bulkDsResponse?.status) && (
            <Text
              style={{ marginTop: 2, fontStyle: "italic", display: "flex" }}
            >
              <Text
                style={{
                  ...(["COMPLETED_WITH_ERROR"].includes(bulkDsResponse?.status)
                    ? { color: "red" }
                    : { color: "green" }),
                }}
              >
                {bulkDsResponse?.successfulRecordCount
                  ? bulkDsResponse?.successfulRecordCount
                  : bulkDsResponse?.totalRecordCount -
                      bulkDsResponse?.erroredRecordCount || 0}{" "}
                {Localise(messages, "of")} {bulkDsResponse?.totalRecordCount}{" "}
                {Localise(messages, "Requests uploaded.")}{" "}
              </Text>
              {["COMPLETED_WITH_ERROR"].includes(bulkDsResponse?.status) && (
                <Text style={{ display: "flex" }}>
                  <Text>{Localise(messages, "Download")} </Text>
                  <TouchableOpacity
                    onPress={() => {
                      downloadFile(
                        "report-file",
                        "get-bulk-ds-request-report",
                        {
                          shopCode: selectedShopCode,
                          bulkImportJobId: bulkDsResponse?.jobId,
                        },
                        "Exception_Report" +
                          bulkDsResponse.files?.[0]?.fileName
                            ?.split("-")
                            .splice(1)
                            .join("-"),
                        "Mercury HQ Bulk Imports/Reports"
                      );
                      setisRepotFileLoading(true);
                    }}
                  >
                    <Text
                      style={[
                        fonts.link2,
                        {
                          ...(isDesktop
                            ? { marginTop: 0, fontSize: 14 }
                            : { bottom: -3, ...tw("pt-2") }),
                        },
                      ]}
                    >
                      {Localise(messages, "Exception Report")}
                    </Text>
                  </TouchableOpacity>
                  <ActivityIndicator
                    color={colors.activityIndicator}
                    style={{ marginLeft: 3, marginTop: -3 }}
                    animating={isRepotFileLoading}
                  ></ActivityIndicator>
                </Text>
              )}
            </Text>
          )}
      </View>
      <Button
        title={Localise(messages, "View Requests")}
        disabled={false}
        containerStyle={{
          marginLeft: 20,
          flexDirection: "row",
        }}
        onPress={() => {
          if (isBulkDSInProgress) {
            setIsModalVisible(true);
          } else {
            navigation.dispatch(
              CommonActions.navigate({
                name: "lmds-delivery",
              })
            );
            dispatch(setCurrentPage("DeliveryDashboard"));
            dispatch(setSelectScreenKey("DS"));
            UserProfileStorage.setCurrentTab("DS");
          }
        }}
      />
      {modal}
    </>
  );
};

export default CreateBulkRequest;
