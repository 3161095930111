import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */

export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectHelpPageTabNames = createSelector(
  selectState,
  ({ tabs }) => tabs || []
);

export const selectHelpPageSubTabNames = createSelector(
  selectState,
  ({ subTabs }) => subTabs || []
);

export const selectActiveHelpTab = createSelector(
  selectState,
  ({ activeTab }) => activeTab || ""
);

export const selectActiveHelpSubTab = createSelector(
  selectState,
  ({ activeSubTab }) => activeSubTab || ""
);
