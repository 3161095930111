import React from "react";
import { Platform, Linking, TouchableOpacity, View, Image } from "react-native";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";
import IMAGES from "static/assets/images";

import I18NContext from "library/contexts/i18N";
import { fonts, backgroundColors, colors } from "styles/theme";
import Feedback from "components/views/drawer/feedback";

import { CustomModal } from "components/elements";

const ContactUs = () => {
  const contactNumber = "800-788-9000";
  const { messages, Localise } = React.useContext(I18NContext);
  const [showModal, setShowModal] = React.useState(false);
  const handleCalling = () => {
    setShowModal(false);
    Linking.openURL(`tel:${contactNumber}`);
  };

  const modalContent = {
    content: (
      <>
        <View
          style={[
            tw("px-3 py-3 flex flex-row items-center justify-between "),
            { backgroundColor: backgroundColors.primary },
          ]}
        >
          <Text style={{ ...fonts.heading1, ...tw("text-white") }}>
            {Localise(messages, "Contact Us")}
          </Text>
          <View>
            <TouchableOpacity
              onPress={() => {
                setShowModal(false);
              }}
              testID="close"
            >
              <Image
                style={{ width: 20, height: 20 }}
                resizeMode="cover"
                source={IMAGES["close"]}
              />
            </TouchableOpacity>
          </View>
        </View>
        <View>
          <Text
            style={{
              ...fonts.default,
              fontSize: 16,
              textAlign: "center",
              color: "black",
              ...tw("pt-4"),
            }}
          >
            {Localise(messages, "Are you sure want to call?")}
          </Text>

          <View
            style={{
              ...tw("pt-6 flex flex-row items-center justify-around "),
            }}
          >
            <Text
              onPress={() => {
                setShowModal(false);
              }}
              style={{
                ...fonts.default,
                fontSize: 18,
                color: "red",
              }}
            >
              {Localise(messages, "Cancel")}
            </Text>
            <Text
              onPress={() => {
                handleCalling();
              }}
              style={{
                ...fonts.default,
                fontSize: 18,
                color: "green",
              }}
            >
              {Localise(messages, "Confirm")}
            </Text>
          </View>
        </View>
      </>
    ),
    buttons: [],
  };

  return (
    <View
      style={{
        justifyContent: "flex-start",
      }}
    >
      <View
        style={{
          paddingHorizontal: 20,
        }}
      >
        <Text
          style={{
            ...fonts.heading2,
            fontSize: 20,
            ...tw("pt-2 pb-2"),
          }}
        >
          {Localise(messages, "Contact Us")}
        </Text>
        <Text
          style={{
            ...fonts.default,
            fontSize: 15,
            ...tw("pt-1"),
          }}
        >
          {Localise(
            messages,
            "For immediate support, please call us. For other issues, general feedback, or suggestions please use the form below."
          )}
        </Text>
        <View
          style={{
            borderBottomColor: colors.light,
            borderBottomWidth: 1,
            paddingVertical: 10,
          }}
        />

        <Text
          style={{
            ...fonts.heading2,
            fontSize: 20,
            ...tw("pt-2 pb-2"),
          }}
        >
          {Localise(messages, "Call")}
        </Text>
        <Text
          style={{
            ...fonts.default,
            fontSize: 15,
            paddingVertical: 3,
          }}
        >
          {Localise(messages, "Please call FTD Member Support at")}{" "}
          <Text
            style={{
              ...fonts.link1,
              fontSize: 14,
              cursor: "pointer",
            }}
            onPress={() => {
              if (Platform.OS !== "web") {
                setShowModal(!showModal);
              }
            }}
          >
            {contactNumber}
          </Text>
        </Text>

        <Text
          style={{
            ...fonts.default,
            ...tw("pt-1"),
          }}
        >
          {Localise(
            messages,
            "Follow prompts to reach the following departments:"
          )}
        </Text>
        <Text
          style={{
            ...fonts.default,
            ...tw("pt-1"),
          }}
        >
          {`\u25CF`}{" "}
          <Text>
            {Localise(messages, "Question regarding an FTD.com Order")}
          </Text>
        </Text>
        <Text
          style={{
            ...fonts.default,
            ...tw("pt-1"),
          }}
        >
          {`\u25CF`}{" "}
          <Text>
            {Localise(messages, "Member Services - Statements and Billing")}
          </Text>
        </Text>
        <Text
          style={{
            ...fonts.default,
            ...tw("pt-1"),
          }}
        >
          {`\u25CF`} <Text>{Localise(messages, "MHQ Technical Support")}</Text>
        </Text>
        <Text
          style={{
            ...fonts.default,
            ...tw("pt-1"),
          }}
        >
          {`\u25CF`}{" "}
          <Text>
            {Localise(
              messages,
              "Mercury Online and Florists Online Website Support"
            )}
          </Text>
        </Text>
        <Text
          style={{
            ...fonts.default,
            ...tw("pt-1"),
          }}
        >
          {`\u25CF`}{" "}
          <Text>{Localise(messages, "Containers and other supplies")}</Text>
        </Text>
        <Text
          style={{
            ...fonts.default,
            ...tw("pt-1"),
          }}
        >
          {`\u25CF`} <Text>{Localise(messages, "Wholesale flowers")}</Text>
        </Text>
        <Text
          style={{
            ...fonts.default,
            ...tw("pt-1"),
          }}
        >
          {`\u25CF`}{" "}
          <Text>{Localise(messages, "Credit Card Processing inquiries")}</Text>
        </Text>
        <Text
          style={{
            ...fonts.default,
            ...tw("pt-1"),
          }}
        >
          {`\u25CF`} <Text>{Localise(messages, "International Orders")}</Text>
        </Text>
        <Text
          style={{
            ...fonts.default,
            ...tw("pt-1"),
          }}
        >
          {`\u25CF`}{" "}
          <Text>{Localise(messages, "Mercury HQ technical support")}</Text>
        </Text>

        <View
          style={{
            borderBottomColor: colors.light,
            borderBottomWidth: 1,
            paddingVertical: 10,
          }}
        />
      </View>
      <Feedback />
      <CustomModal
        modalVisible={showModal}
        modalContent={modalContent}
        contentStyle={{
          backgroundColor: "white",
          height: "25%",
          width: "90%",
        }}
        modalStyle={
          Platform.OS !== "web"
            ? {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#00000070",
                color: "#FFFFFF",
              }
            : { padding: 0 }
        }
      ></CustomModal>
    </View>
  );
};

export default ContactUs;
