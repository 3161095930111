import * as Yup from "yup";

export const getValidationSchema = (Localise, messages) =>
  Yup.object().shape({
    recipientEmail: Yup.string()
      .email("Please enter valid email")
      .matches(
        /^\w+[+-.\w]*@(?!(?:donotsend)\.com$)\w[-.\w]*\.\w{2,4}$/,
        "Invalid Email address"
      )
      .when(["isEmailRequired"], {
        is: (req) => req,
        then: Yup.string().required(
          Localise(messages, "Please enter email to save credit card details")
        ),
      }),
  });
