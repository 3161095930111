import * as Yup from "yup";
import moment from "moment";

export const getValidationSchema = (Localise, messages) => {
  Yup.addMethod(Yup.string, "invalidTime", function (type, errorMessage) {
    return this.test(type, errorMessage, function (value) {
      const { path, createError, parent } = this;

      const isShopOpen = parent.openedFlag === "T";
      if (!isShopOpen) return true;

      const [day] = path.split(".");

      const openTime = type === "invalid-open-time" ? value : parent.open;
      const closeTime = type === "invalid-close-time" ? value : parent.close;
      const closePath = type === "invalid-close-time" ? path : `${day}.close`;

      return (
        moment(closeTime, "YYYY-MM-DDTHH:mm").isAfter(
          moment(openTime, "YYYY-MM-DDTHH:mm")
        ) || createError({ path: closePath, message: errorMessage })
      );
    });
  });

  const hoursValidation = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ].reduce((accum, day) => {
    accum[day] = Yup.object().shape({
      open: Yup.string()
        .required(Localise(messages, "Select a Open time"))
        .invalidTime(
          `invalid-open-time`,
          Localise(messages, "Shop close time must be after open time")
        ),
      close: Yup.string()
        .required(Localise(messages, "Select Close time"))
        .invalidTime(
          `invalid-close-time`,
          Localise(messages, "Shop close time must be after open time")
        ),
    });
    return accum;
  }, {});

  return Yup.object().shape({
    ...hoursValidation,
  });
};
