import React from "react";
import { View, ActivityIndicator, Text } from "react-native";
import { useSelector } from "react-redux";

import { Divider } from "components/elements";

import Controls, { SelectedControls } from "./controls";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { colors, fonts } from "styles/theme";
import tw from "tailwind-rn";

const Header = ({
  title,
  controls,
  selectors: { showLoading },
  headerTitleWidth,
}) => {
  const loading = useSelector(showLoading);

  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop, isTablet } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;

  return (
    <View
      style={[
        tw(`flex flex-col bg-white ${isSmallScreen ? "pt-5" : "pt-6"}`),
        { zIndex: 1 },
      ]}
    >
      <View style={tw("flex-row justify-between flex-wrap items-center")}>
        <View style={[tw("flex flex-row pb-3"), { width: headerTitleWidth }]}>
          <Text
            style={{
              ...fonts.heading1,
              ...(isTablet && { fontSize: 16, fontWeight: "400" }),
            }}
          >
            {Localise(messages, title)}
          </Text>
          {loading && (
            <ActivityIndicator
              style={{ marginLeft: 5 }}
              color={colors.activityIndicator}
              testID={"loader"}
            />
          )}
        </View>
        <View
          style={[
            tw("flex flex-row justify-between items-center flex-grow"),
            isSmallScreen && { maxWidth: 250 },
          ]}
        >
          <Controls data={controls} position={"header"} />
        </View>
      </View>
      {isDesktop && <Divider />}
      <View
        style={[
          tw("flex-row pb-3"),
          { paddingHorizontal: 15, flexWrap: "wrap" },
        ]}
      >
        <Controls data={controls} position={"subHeader"} />
        {isDesktop && <SelectedControls data={controls} />}
      </View>
    </View>
  );
};

export default Header;
