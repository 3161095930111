import { put, select, call, all } from "redux-saga/effects";
import { request } from "library/utils/request";
import moment from "moment";
import { processOrderResponse } from "library/utils/orderListing";
import { fetchStatuses } from "./config";
import {
  setPageInitSuccess,
  setPageInitFailure,
  setApiResponse,
  setApiError,
  saveCitiesZipcodes,
  fetchCitiesZipcodes,
  setDeliveryZoneMapReload,
  clearCitiesZipcodes,
  setAutoRoutes,
  saveUpdatedAutoRoute,
  setNonRoutedOrders,
  setDashboardCurrentTabKey,
} from "./slice";

import { selectShopCode, selectFeesCoverage } from "./selector";
import get from "lodash/get";

const processCityZipcodesFeesResponse = (response = [], localFee) => {
  let cityFeeData = {};
  const cityFeeDataIndex = response?.findIndex((row) => !row.zipCode);

  if (cityFeeDataIndex < 0) {
    cityFeeData = {
      city: get(response, "0.city", ""),
      countryId: get(response, "0.countryId", ""),
      state: get(response, "0.state", ""),
      fee: localFee,
      status: "N",
      cityFeeAddedManually: true,
    };
  } else {
    cityFeeData = response.splice(cityFeeDataIndex, 1)[0];
  }
  const sortedZipcodes = response.sort((a, b) =>
    b.status?.localeCompare(a.status)
  );
  sortedZipcodes.unshift(cityFeeData);
  return sortedZipcodes.map((data) => ({
    ...data,
    status: data.status || "N",
    fee: roundTheFees(data.fee || localFee),
  }));
};

const processCitiesFeesResponse = (response) => {
  return response
    .filter((cityData) => cityData.city)
    .map((data) => ({
      ...data,
      fee: roundTheFees(data.fee),
    }));
};

export const roundTheFees = (fee, prevText, isOnChange) => {
  const isValidFee = isValidFeeValue(fee);
  const roundedFee = isValidFee
    ? isOnChange
      ? isMaxDecimalsExceeded(fee)
        ? prevText
        : fee
      : fee % 1 !== 0
      ? (+fee).toFixed(2)
      : parseInt(fee).toString()
    : fee.replace(/[^0-9.]/g, "");
  return roundedFee.toString();
};
export const isMaxDecimalsExceeded = (fee) => {
  const maxDecimalsReached = fee.split(".")[1]?.length > 2;
  return maxDecimalsReached;
};
export const isValidFeeValue = (fee) => {
  const isValid = (fee || fee === 0 || fee === "0") && !isNaN(fee);
  return isValid;
};

export function* handleFetchAutoRoutes(action = {}) {
  const stateShopCode = yield select(selectShopCode);

  const { shopCode = stateShopCode } = get(action, "payload", {});

  if (shopCode) {
    const serviceRequest = (params) => request("get-autoroutes-data", params);
    try {
      const response = yield call(serviceRequest, {
        shopCode,
      });
      if (response.length) {
        yield put(setAutoRoutes(response));
        yield put(setPageInitSuccess());
      } else {
        yield put(setAutoRoutes([]));
        yield put(setDashboardCurrentTabKey("dashboard-routes-tab"));
      }
    } catch (error) {
      yield put(setPageInitFailure());
      yield put(setDashboardCurrentTabKey("dashboard-routes-tab"));
    }
  }
}

export function* handleFetchCitiesZipcodes(action = {}) {
  const { payload: { city, countryId, state } = {} } = action;
  const { siteFees: { content: { localFee = "" } = {} } = {} } = yield select(
    selectFeesCoverage
  );
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (params) => request("get-cityzipcodes-fees", params);

  try {
    const response = yield call(serviceRequest, {
      shopCode,
      city,
      countryId,
      state,
    });
    const content = processCityZipcodesFeesResponse(response, localFee);
    yield put(saveCitiesZipcodes({ content }));
  } catch (error) {
    yield put(
      setApiError({
        path: "feesCoverage.citiesZipcodes",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleFetchCitiesFees() {
  const serviceRequest = (params) => request("get-cities-fees", params);
  const shopCode = yield select(selectShopCode);
  try {
    const response = yield call(serviceRequest, { shopCode });
    const content = processCitiesFeesResponse(response);
    if (content && content.length > 0) {
      yield put(clearCitiesZipcodes());
      yield all(
        content.map((_, i) =>
          put(
            fetchCitiesZipcodes({
              city: content[i].city,
              countryId: content[i].countryId,
              state: content[i].state,
            })
          )
        )
      );
      yield put(setDeliveryZoneMapReload(true));
    }

    yield put(
      setApiResponse({
        path: "feesCoverage.citiesFees",
        content,
      })
    );
  } catch (error) {
    yield put(
      setApiError({
        path: "feesCoverage.citiesFees",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleDeleteAutoRoute(action = {}) {
  const { autoRoutes, autoRouteId } = action?.payload;
  if (autoRoutes?.length && autoRouteId) {
    let content = autoRoutes?.filter(function (el) {
      return el.autoRouteId != autoRouteId;
    });
    yield put(saveUpdatedAutoRoute(content));
  }
}

export function* handleFetchNonRoutedOrders(action = {}) {
  const {
    shopCode,
    startDate = "",
    endDate = "",
    deliveryDate,
    considerLocalOrders,
  } = action.payload;
  const serviceRequest = (params) => request("delivery-listing", params);
  try {
    const res = yield call(serviceRequest, {
      startDate: moment(startDate ? startDate : deliveryDate).format(
        "YYYY-MM-DD"
      ),
      endDate: moment(endDate ? endDate : deliveryDate).format("YYYY-MM-DD"),
      fetchStatuses: fetchStatuses.join(","),
    });
    const InitOrdersData = {
      nonRouted: { orders: [] },
    };
    const shopCodeFilter =
      res &&
      res.length &&
      res.filter(
        (ord) =>
          ord?.direction === "INBOUND" &&
          (shopCode?.toLowerCase().includes("all")
            ? true
            : ord?.receivingMember?.memberCode === shopCode)
      );
    const updatedResp = processOrderResponse(
      shopCodeFilter,
      InitOrdersData,
      "delivery-details"
    );
    const { orderCollection = {} } = updatedResp;
    let ordersResponse = orderCollection?.nonRouted?.orders || [];

    if (!considerLocalOrders) {
      ordersResponse = ordersResponse.filter(
        (ord) => ord.deliveryInfo?.deliveryMethod !== "FLORIST_DELIVERED" // filter local orders
      );
    }
    yield put(setNonRoutedOrders(ordersResponse));
  } catch (error) {
    console.log("Error", error);
    yield put(setPageInitFailure());
  }
}
