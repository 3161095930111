import React, { useCallback, useState, useMemo, memo } from "react";
import { useDispatch } from "react-redux";
import { View } from "react-native";
import { Icon, Text } from "react-native-elements";
import {
  Menu,
  MenuOptions,
  MenuTrigger,
  MenuOption,
} from "react-native-popup-menu";
import { ToasterHandler } from "components/elements";
import I18NContext from "library/contexts/i18N";
import tw from "tailwind-rn";
import PauseForm from "./pause-form";
import {
  pauseSubscriptionTemplate,
  resumeSubscriptionTemplate,
} from "library/sagas/views/home/drawer/customer-directory/slice";
import { getSubscriptionItemMenuOptions } from "./helper";

const ItemActions = ({ data: itemData, onSelect }) => {
  const dispatch = useDispatch();

  const { Localise, messages } = React.useContext(I18NContext);
  const [showDateRange, setShowDateRange] = useState(false);
  const {
    pause = [],
    customerId,
    id: subscriptionId,
    memberCode,
  } = itemData || {};
  const activePause = pause.find(({ status }) => status === "ACTIVE");
  const menuOptions = getSubscriptionItemMenuOptions(
    Localise,
    messages,
    itemData.status,
    activePause
  );

  const filtersMenuRef = React.useRef();
  const formInitialValues = useMemo(
    () => ({
      startDate: activePause?.startDate || "",
      endDate: activePause?.endDate || "",
    }),
    [activePause]
  );

  const onClose = useCallback(() => {
    filtersMenuRef.current?.close();
    setShowDateRange(false);
  }, []);

  // Pause Submit Handler
  const pauseSubmitHandler = useCallback(
    (values) => {
      dispatch(
        pauseSubscriptionTemplate({
          params: {
            ...values,
            customerId,
            subscriptionId,
            memberCode,
            ...(activePause && { pauseId: activePause.pauseId }),
          },
          resolve: (res) => {
            console.log("res :>> ", res);
            setShowDateRange(false);
            onClose();
            ToasterHandler(
              "nice",
              Localise(
                messages,
                activePause
                  ? "Your pause details have been updated!"
                  : "Your subscription has been paused."
              )
            );
          },
          reject: (err) => {
            console.log("err :>> ", err);
            ToasterHandler(
              "error",
              Localise(
                messages,
                "We were unable to process your request, please try again."
              )
            );
          },
        })
      );
    },
    [
      dispatch,
      customerId,
      subscriptionId,
      memberCode,
      activePause,
      onClose,
      Localise,
      messages,
    ]
  );
  // Resume Submit Handler
  const resumeHandler = useCallback(() => {
    dispatch(
      resumeSubscriptionTemplate({
        params: {
          pauseId: activePause.pauseId,
          memberCode,
        },
        resolve: (res) => {
          setShowDateRange(false);
          onClose();
          ToasterHandler(
            "nice",
            Localise(
              messages,
              "Your subscription has been successfully resumed."
            )
          );
        },
        reject: (err) => {
          console.log("err :>> ", err);
          ToasterHandler(
            "error",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
        },
      })
    );
  }, [Localise, activePause, dispatch, memberCode, messages, onClose]);

  const optionClickHandler = useCallback(
    (key) => {
      if (["Copy", "Modify", "Cancel"].includes(key)) {
        onSelect(key);
      } else if (key === "Resume") {
        resumeHandler();
      } else if (key === "Pause") {
        setShowDateRange(!showDateRange);
        return false;
      }
    },
    [onSelect, resumeHandler, showDateRange]
  );

  return (
    <Menu
      ref={filtersMenuRef}
      onClose={onClose}
      style={tw("flex items-center justify-center")}
    >
      <MenuTrigger
        testID="subscription_list_item_menu"
        accessibilityLabel="subscription_list_item_menu"
      >
        <Icon name={"more-vert"} size={20} color={"#000"} />
      </MenuTrigger>
      <MenuOptions
        optionsContainerStyle={{
          width: 180,
          padding: 15,
        }}
      >
        <View style={{ flex: 1 }}>
          {menuOptions.map((option) => (
            <MenuOption
              key={option.key}
              onSelect={() => optionClickHandler(option.key)}
              testID={option.key}
              accessibilityLabel={option.key}
            >
              <Text
                style={{
                  ...(option.key === "Pause"
                    ? { fontWeight: showDateRange ? "bold" : "normal" }
                    : {}),
                }}
              >
                {Localise(messages, option.label)}
              </Text>
            </MenuOption>
          ))}
          {showDateRange && (
            <PauseForm
              initialValues={formInitialValues}
              onSubmit={pauseSubmitHandler}
              onCancel={onClose}
            />
          )}
        </View>
      </MenuOptions>
    </Menu>
  );
};

export default memo(ItemActions);
