import React, { useContext } from "react";
import { AccordionWithBorder } from "components/elements";
import CreatePromoCode from "./promoCodes/createPromoCode";
import PromoCodesList from "./promoCodes/promoCodesList";
import I18NContext from "library/contexts/i18N";
import { SHOP_SETTINGS } from "library/constants";

const Marketing = () => {
  const { messages, Localise } = useContext(I18NContext);

  return (
    <>
      <AccordionWithBorder
        title={Localise(messages, SHOP_SETTINGS.PROMO_CODES)}
      >
        <CreatePromoCode />
        <AccordionWithBorder
          title={Localise(messages, SHOP_SETTINGS.CODES)}
          overrideZindex={-1}
          defaultOpen={true}
        >
          <PromoCodesList />
        </AccordionWithBorder>
      </AccordionWithBorder>
    </>
  );
};

export default Marketing;
