import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "react-native";

import FuneralSympathy from "components/views/drawer/ftd-admin/mol-settings/extended/custom/funeral-sympathy";
import Wedding from "components/views/drawer/ftd-admin/mol-settings/extended/custom/wedding-pages";
import { Languages } from "components/views/drawer/ftd-admin/mol-settings/helper";
import { DeviceContext } from "library/contexts/appSettings";
import { selectExtendedScreen } from "library/sagas/views/home/drawer/mercury-online/selector";
import { setExtendedScreen } from "library/sagas/views/home/drawer/mercury-online/slice";
import { setScheduledContentScreenTabs } from "library/sagas/views/home/drawer/ftd-admin/mol-settings/slice";
import SiteContent from "./site-content";
import { ExtendedScreenHeader } from "../helper";
import OverrideHeader from "./override-header";

import { colors } from "styles/theme";

const ExtendedScreen = () => {
  const dispatch = useDispatch();
  const { type, data } = useSelector(selectExtendedScreen);
  const { isMobile, isTablet } = React.useContext(DeviceContext);
  const { title, component, context, assetId, fieldType, fieldProps } = data;

  useEffect(() => {
    return () => {
      dispatch(setScheduledContentScreenTabs({ value: "default-content" }));
    };
  }, [component]);

  if (!type) return null;

  const onClose = () => dispatch(setExtendedScreen());
  return (
    <View
      style={{
        backgroundColor: colors.secondary,
        borderColor: colors.grayScaleLight,
        borderWidth: 1,
        borderRadius: 10,
        ...(isTablet && { padding: 10 }),
      }}
    >
      <ExtendedScreenHeader
        title={title}
        onClose={onClose}
        component={component}
      />
      <View
        style={{
          paddingBottom: 20,
        }}
      >
        <View
          style={{
            paddingHorizontal: isMobile ? 10 : 20,
          }}
        >
          {fieldType === "funeralSympathy" ? (
            <FuneralSympathy
              defaultContext={context}
              defaultAssetId={assetId}
              overrideHeader={(props) => OverrideHeader(props)}
            />
          ) : fieldType === "wedding" ? (
            <Wedding
              defaultContext={context}
              defaultAssetId={assetId}
              overrideHeader={(props) => OverrideHeader(props)}
            />
          ) : fieldType === "Languages" ? (
            <Languages fieldProps={fieldProps} />
          ) : type === "siteContent" ? (
            <SiteContent
              component={component}
              context={context}
              assetId={assetId}
              title={title}
              fieldType={fieldType}
              fieldProps={fieldProps}
              overrideHeader={(props) => OverrideHeader(props)}
            />
          ) : (
            () => {}
          )}
        </View>
      </View>
    </View>
  );
};

export default ExtendedScreen;
