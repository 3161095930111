import React from "react";
import { View, Image } from "react-native";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";

import { Tooltip } from "components/elements";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

const AddressVerificationIndicator = ({ containerStyle, isVerified }) => {
  const { isDesktop } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const isSmallScreen = !isDesktop;

  return isVerified ? (
    <View style={[tw("flex flex-row items-center"), containerStyle]}>
      <Tooltip
        text={"FTD has verified this address"}
        isSmallScreen={isSmallScreen}
      >
        <View style={tw("flex justify-center flex-row px-1")}>
          <Image
            containerStyle={tw("flex")}
            style={{ width: 17, height: 17 }}
            resizeMode="cover"
            source={require("static/assets/address-verification-check-mark-fill.png")}
          />
          <Text style={tw("ml-1")}>
            {Localise(messages, "Address FTD Verified")}
          </Text>
        </View>
      </Tooltip>
    </View>
  ) : (
    <View style={[tw("flex flex-row items-center"), containerStyle]}>
      <Tooltip
        text={Localise(
          messages,
          "FTD was not able to verify this address. Please confirm address with the florist partner, customer, or recipient"
        )}
        isSmallScreen={isSmallScreen}
        height={160}
      >
        <View style={tw("flex flex-row items-center")}>
          <Image
            containerStyle={tw("absolute")}
            style={{ width: 17, height: 17 }}
            resizeMode="cover"
            source={require("static/assets/address-not-verified-exclamation.png")}
          />
          <Text style={tw("ml-1")}>
            {Localise(messages, "Confirm address")}
          </Text>
        </View>
      </Tooltip>
    </View>
  );
};

export default AddressVerificationIndicator;
