import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";
import { selectState as selectScreenState } from "../common/selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectPageStatus = createSelector(
  selectState,
  selectScreenState,
  ({ status }, { status: screenStatus }) =>
    screenStatus === "done" ? status : "progress"
);

export const selectIsPageLoading = createSelector(
  selectPageStatus,
  (status) => status === "progress"
);

export const selectIsPageFailed = createSelector(
  selectPageStatus,
  (status) => status === "fail"
);

export const selectIsPageLoaded = createSelector(
  selectPageStatus,
  (status) => status === "done"
);

export const selectActions = createSelector(
  selectState,
  ({ actions }) => actions
);

export const selectPageData = createSelector(selectState, ({ data }) => data);

export const selectAPIResponse = createSelector(selectState, ({ api }) => api);

export const selectAPIError = createSelector(selectState, ({ error }) => error);

export const selectSearchText = createSelector(
  selectActions,
  ({ searchText }) => searchText
);

export const selectAppliedFilters = createSelector(
  selectActions,
  ({ filters = [] }) => filters || []
);

export const selectSortValue = createSelector(
  selectActions,
  ({ sortBy }) => sortBy || "name::asc"
);

export const selectCatalogType = createSelector(
  selectActions,
  ({ catalogType }) => catalogType
);

export const selectCatalogLimit = createSelector(
  selectActions,
  ({ catalogLimit }) => catalogLimit
);

export const selectBulkActionType = createSelector(
  selectActions,
  ({ bulkActionType }) => bulkActionType
);

export const selectProductsSelected = createSelector(
  selectActions,
  ({ selectedProducts }) => selectedProducts || []
);
