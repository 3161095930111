import React, { useContext } from "react";
import { View, TouchableOpacity, ScrollView } from "react-native";

import { Text } from "react-native-elements";
import tw from "tailwind-rn";
import { theme, backgroundColors, fonts } from "styles/theme";
import { CustomModal } from "components/elements";
import { Platform } from "react-native";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

export const CustomerVerificationModal = ({
  showModal,
  customerSuggestions = [],
  onContinue,
  onCancel,
  isSmallScreen,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop, isTablet } = useContext(DeviceContext);
  const [selectedIndex, setSelectedIndex] = useStateIfMounted(0);

  const Content = () => {
    return (
      <View
        style={{
          maxWidth: isSmallScreen ? "100%" : 400,
          maxHeight: isSmallScreen ? 200 : 400,
          margin: "auto",
          backgroundColor: backgroundColors.secondary,
          borderColor: "rgba(0, 0, 0, 0.1)",
        }}
      >
        <View
          style={[
            tw("p-3 flex flex-row items-center justify-between"),
            { backgroundColor: backgroundColors.primary },
          ]}
        >
          <Text style={{ ...fonts.heading1, ...tw("text-white") }}>
            {Localise(messages, "Customer Verification")}
          </Text>
        </View>
        <View>
          <Text style={[tw("p-5"), { paddingBottom: 5 }]}>
            {Localise(
              messages,
              "Customer already exists. Please select the customer from below suggestions."
            )}
          </Text>
        </View>
        <ScrollView
          contentContainerStyle={{
            padding: isDesktop ? 15 : isTablet ? 0 : 10,
          }}
        >
          <View style={tw("px-5")}>
            {customerSuggestions?.map((customer, idx) => {
              const {
                firstName = "",
                lastName = "",
                phone = "",
                email = "",
              } = customer;
              return (
                <View style={tw("my-2")} key={idx}>
                  <RadioField
                    index={idx}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={() => {
                      setSelectedIndex(idx);
                    }}
                    data={`${firstName} ${lastName} ${phone} ${email}`}
                  />
                </View>
              );
            })}
          </View>
        </ScrollView>
      </View>
    );
  };

  const modalContent = {
    content: <Content />,
    buttons: [
      { type: "primary", title: Localise(messages, "Confirm") },
      { type: "secondary", title: Localise(messages, "Cancel") },
    ],
  };

  return (
    <CustomModal
      modalVisible={showModal}
      modalContent={modalContent}
      primaryhandler={() => onContinue(customerSuggestions[selectedIndex])}
      secondaryhandler={onCancel}
      contentStyle={{ backgroundColor: "white" }}
      modalStyle={
        Platform.OS !== "web"
          ? {
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor: "#00000070",
              color: backgroundColors.secondary,
            }
          : { padding: 0 }
      }
    ></CustomModal>
  );
};

const RadioField = ({ index, selectedIndex, setSelectedIndex, data }) => {
  const id = `select_${index}`;
  return (
    <TouchableOpacity
      key={index}
      style={{
        ...tw("flex flex-row items-start mr-10"),
      }}
      onPress={setSelectedIndex}
      testID={id}
      accessibilityLabel={id}
    >
      <View style={theme.Radio.inputStyle}>
        {selectedIndex === index && <View style={theme.Radio.selectedStyle} />}
      </View>
      <View style={tw("ml-2 mt-1 w-full")}>
        <Text>{data}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default CustomerVerificationModal;
