import { fonts } from "styles/theme";
import toUpper from "lodash/toUpper";
import toLower from "lodash/toLower";
import startCase from "lodash/startCase";
import Environment from "library/utils/environment";

const TextFieldProps = {
  autoCapitalize: "none",
  autoCorrect: false,
  editable: true,
  multiline: false,
  numberOfLines: 1,
};

const getOptions = (userRolesPermissions = [], selectedShopCodes = []) => {
  const eligibleMemberCodes = Environment.get(
    "ENABLE_EVENTS_PLANNER",
    ""
  ).split(",");

  const showEventsPlanner =
    eligibleMemberCodes.includes("ALL") ||
    selectedShopCodes.some((member) => eligibleMemberCodes.includes(member));

  if (showEventsPlanner) return userRolesPermissions;
  return userRolesPermissions.map((rolesPermissions) => {
    const permissions = [...rolesPermissions.permissions];
    ["events", "create event"].forEach((name) => {
      const permissonIdx = permissions.findIndex(
        (permission) => permission?.toLowerCase() === name
      );
      permissonIdx > -1 && permissions.splice(permissonIdx, 1);
    });
    return {
      ...rolesPermissions,
      permissions,
    };
  });
};

const staffRolesOptions = (userRolesPermissions = []) => {
  let staffRoles = [];

  userRolesPermissions.map((rolesPermissions) => {
    staffRoles.push({
      label: startCase(toLower(rolesPermissions.role)),
      value: toUpper(rolesPermissions.role),
    });
  });

  return staffRoles;
};

const staffEmploymentOptions = (Localise, messages) => {
  return [
    { label: Localise(messages, "Full Time"), value: "FULLTIME" },
    { label: Localise(messages, "Part Time"), value: "PARTTIME" },
    { label: Localise(messages, "Seasonal"), value: "SEASONAL" },
  ];
};

const passwordUpdateOptions = (
  isCreateStaff,
  Localise,
  messages,
  isFloristAdmin
) => {
  let options = [];

  if (isFloristAdmin) {
    options.push({
      label: Localise(messages, "Manually Reset"),
      value: "MANUALLY_RESET",
    });
  }

  if (!isCreateStaff) {
    options.push({
      label: Localise(messages, "Send Password Reset Email"),
      value: "SEND_RESET_EMAIL",
    });
  }

  return options;
};

export const InputFields = (
  isCreateStaff,
  Localise,
  messages,
  userRolesPermissions,
  memberCodes,
  handlePasswordResetEmail,
  isFloristAdmin,
  selectedShopCodes
) => [
  {
    name: "firstName",
    title: Localise(messages, "First Name"),
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: Localise(messages, "Enter First Name"),
    },
  },
  {
    name: "lastName",
    title: Localise(messages, "Last Name"),
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: Localise(messages, "Enter Last Name"),
    },
  },
  {
    name: "employmentStatus",
    title: Localise(messages, "Employment"),
    formFieldType: "Picker",
    formFieldProps: {
      placeholder: {
        label: Localise(messages, "Select Employment Type"),
        value: "",
      },
      options: staffEmploymentOptions(Localise, messages),
    },
  },
  {
    name: "shopCodes",
    title: Localise(messages, "Shops"),
    formFieldType: "ShopPicker",
    formFieldProps: {
      placeholder: Localise(messages, "Select Shops"),
      memberCodes,
      allLabel: Localise(messages, "All"),
      selectedLabel: Localise(messages, "Selected"),
    },
  },
  {
    name: "status",
    title: Localise(messages, "Status"),
    formFieldType: "toggle",
    formFieldProps: {
      activeLabel: Localise(messages, "Active"),
      inactiveLabel: Localise(messages, "Terminated"),
    },
  },
  {
    name: "phone",
    title: Localise(messages, "Phone"),
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      isCreateStaff,
      placeholder: "123-456-7890",
      maxLength: 18,
    },
  },
  {
    name: "emailId",
    title: Localise(messages, "Email"),
    formFieldType: "Text",
    formFieldProps: {
      ...TextFieldProps,
      placeholder: Localise(messages, "Enter Email"),
    },
  },
  {
    name: "passwordOptions",
    title: Localise(messages, "Password"),
    formFieldType: "Picker",
    formFieldProps: {
      placeholder: {
        label: Localise(messages, "Password Update"),
        value: "",
      },
      options: passwordUpdateOptions(
        isCreateStaff,
        Localise,
        messages,
        isFloristAdmin
      ),
    },
  },
  {
    name: "newPassword",
    title: "",
    formFieldType: "newPassword",
    formFieldProps: {
      placeholder: Localise(messages, "Enter New Password"),
      handler: handlePasswordResetEmail,
    },
  },
  {
    title: Localise(messages, "Mercury HQ Access"),
    formFieldType: "Label",
    formFieldProps: {
      viewStyle: {
        paddingTop: 20,
        paddingBottom: 10,
      },
      textStyle: fonts.heading3,
    },
  },
  {
    title: Localise(
      messages,
      "Employee permissions change based on Role(s) selected"
    ),
    formFieldType: "Label",
    formFieldProps: {
      viewStyle: {
        paddingTop: 5,
        paddingBottom: 10,
      },
      textStyle: { ...fonts.default, fontSize: 14 },
    },
  },
  {
    name: "roles",
    title: Localise(messages, "Roles"),
    formFieldType: "StaffRoles",
    formFieldProps: {
      options: staffRolesOptions(userRolesPermissions),
    },
  },
  {
    name: "permissions",
    title: Localise(messages, "Permissions"),
    formFieldType: "Permissions",
    formFieldProps: {
      options: getOptions(userRolesPermissions, selectedShopCodes),
      textStyle: { ...fonts.default, fontSize: 14, padding: 5 },
    },
  },
];
