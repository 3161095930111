import { combineReducers } from "redux";

import ongoingReducers from "./ongoing/reducer";
import viewReducers from "./views/reducer";
import session from "./session/slice";

import { ROOT_SLICE_NAME, ROOT_RESET } from "./root-actions";

const mhqReducer = combineReducers({
  [ROOT_SLICE_NAME]: combineReducers({
    ...ongoingReducers,
    ...viewReducers,
    ...session,
  }),
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === ROOT_RESET) {
    state = {
      ...state,
      [ROOT_SLICE_NAME]: {
        ...state[ROOT_SLICE_NAME],
        apis: {},
        views: {},
        containers: {},
      },
    };
  }
  return mhqReducer(state, action);
};

export default rootReducer;
