import React from "react";
import { Text, View, Platform } from "react-native";
import { useFormikContext } from "formik";
import { FormFieldDatePicker } from "components/elements/forms";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { theme, fonts } from "styles/theme";
import moment from "moment";

import get from "lodash/get";

const DateField = ({
  name = "",
  disabled,
  endDatePath,
  dateValueFormat,
  defaultValue = "",
  label = "",
  popperPlacement = "bottom",
  customDateField = false,
  field = "",
}) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);

  const {
    setFieldTouched,
    values: { scheduledContent: content = "" },
    touched,
    errors,
    setFieldValue,
  } = useFormikContext();

  let parsedValues = {};
  try {
    parsedValues = JSON.parse(content);
  } catch {
    //do nothing
  }

  const errorName = get(errors, "endDate", "") || get(errors, "startDate", "");
  const showError =
    name === "endDate" &&
    (get(touched, "endDate", "") || get(errors, "startDate", "")) &&
    errorName;

  return (
    <View>
      <Text style={{ ...fonts.heading6, padding: 5 }}>{label}</Text>
      <FormFieldDatePicker
        dateFormat={Platform.OS === "web" ? "MM/dd/yy" : "MM/DD/YY"}
        dateValueFormat={dateValueFormat || "MM/DD/YYYY"}
        name={!customDateField ? name : ""}
        placeholder={Localise(messages, "Date")}
        iconName="calendar"
        alignIconRight={true}
        defaultValue={defaultValue}
        disabled={disabled}
        errorStyle={{ display: "none" }}
        containerStyle={{
          width: Platform.OS === "web" && isDesktop ? 115 : 135,
          height: showError ? "auto" : 40,
          padding: 3,
        }}
        onValueChange={(val) => {
          endDatePath && setFieldTouched(endDatePath);
          if (customDateField) {
            setFieldValue(
              "scheduledContent",
              JSON.stringify({
                ...parsedValues,
                [field]: moment(val).format().split("T")[0],
                ...(field === "startDate" && !parsedValues.endDate
                  ? { endDate: moment(val).format().split("T")[0] }
                  : {}),
              })
            );
          }
        }}
        popperPlacement={popperPlacement}
        popperModifiersOverride={{
          offset: {
            enabled: true,
            offset: "50px, 0px",
          },
        }}
      />
      {!!showError && (
        <Text style={{ ...theme.Input.errorStyle, width: 135 }}>
          {Localise(messages, errorName)}
        </Text>
      )}
    </View>
  );
};

export default DateField;
