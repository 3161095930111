import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text } from "react-native";

import { getUIConfig } from "./ui-config";
import I18NContext from "library/contexts/i18N";
import { setExtendedScreen } from "library/sagas/views/home/drawer/ftd-admin/mol-settings/slice";
import { LabelField, ConfirmModal } from "components/wrappers";
import { DeviceContext } from "library/contexts/appSettings";
import { selectComponentStatus } from "library/sagas/views/home/drawer/ftd-admin/mol-settings/selector";
import { fonts, colors } from "styles/theme";
import tw from "tailwind-rn";

const MainScreen = () => {
  const dispatch = useDispatch();
  const { messages, Localise } = React.useContext(I18NContext);
  const UIConfig = getUIConfig(Localise, messages);
  const { isDesktop } = React.useContext(DeviceContext);
  const inputRef = useRef(null);
  const [isTouched, setTouched] = useState(false);
  const [capturedLink, setLink] = useState("");
  const onPress = (link) => {
    dispatch(setExtendedScreen(link));
  };
  const status = useSelector(selectComponentStatus);
  const links = UIConfig.reduce((accum, data) => {
    if (!accum[data.category]) accum[data.category] = [];
    accum[data.category].push(data);
    return accum;
  }, {});

  return (
    <>
      <View
        style={[
          tw("flex flex-col my-4"),
          {
            borderColor: colors.grayScaleLight,
            borderWidth: 1,
            borderRadius: 10,
            width: isDesktop ? "70%" : "100%",
          },
        ]}
      >
        <View
          style={[
            {
              backgroundColor: "#B8DBE3",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            },
          ]}
        >
          <View style={[tw("flex flex-col m-5")]}>
            <LabelField
              heading={true}
              style={fonts.heading4}
              text={"Global Settings"}
            />
            <View style={tw("flex flex-row flex-wrap")}>
              <Text
                style={{ ...fonts.style2, paddingTop: 4, paddingBottom: 8 }}
              >
                {Localise(
                  messages,
                  `Click the links below to edit global content on sites.`
                )}
              </Text>
            </View>
          </View>
        </View>
        {Object.keys(links).map((category, index, array) => {
          return (
            <View key={category}>
              {category !== "MOL Operations Support" ? (
                <>
                  <View style={[tw("flex flex-col my-4 mx-5")]}>
                    <View
                      key={`${category}-${index}`}
                      style={tw("mb-2 flex-col")}
                    >
                      <Text style={fonts.heading4}>
                        {Localise(messages, category)}
                      </Text>
                      <Text
                        style={{
                          ...fonts.style2,
                          paddingTop: 4,
                          paddingBottom: 8,
                        }}
                      >
                        {links[category][0].description}
                      </Text>
                    </View>
                    {links[category].map((link, index) => (
                      <View
                        key={`${link.component}-${index}`}
                        style={tw("mb-2 flex-row items-center")}
                      >
                        <Text
                          style={fonts.link2}
                          ref={inputRef}
                          onPress={() => {
                            if (status.dirty) {
                              setTouched(!isTouched);
                              setLink(link);
                            } else onPress(link);
                          }}
                        >
                          {link?.title}
                        </Text>
                      </View>
                    ))}
                  </View>
                  <View
                    style={{
                      borderBottomColor: colors.grayScaleLight,
                      borderBottomWidth: index !== array.length - 1 ? 1 : 0,
                      marginHorizontal: 20,
                    }}
                  ></View>
                </>
              ) : null}
            </View>
          );
        })}
        <ConfirmModal
          modalVisible={isTouched}
          handlePrimary={() => {
            setTouched(false);
          }}
          handleSecondary={() => {
            setTouched(!isTouched);
            onPress(capturedLink);
          }}
          data={{
            modal: {
              primary: "Save",
              content: `Please save the content before you leave to next section`,
            },
          }}
        />
      </View>
      <View
        style={[
          tw("flex flex-col mt-2 mb-5"),
          {
            borderColor: colors.grayScaleLight,
            borderWidth: 1,
            borderRadius: 10,
            width: isDesktop ? "70%" : "100%",
          },
        ]}
      >
        <View
          style={[
            {
              backgroundColor: "#B8DBE3",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            },
          ]}
        >
          <View style={[tw("flex flex-col m-5")]}>
            <LabelField
              heading={true}
              style={fonts.heading4}
              text={"MOL Operations and Support"}
            />
          </View>
        </View>
        {Object.keys(links).map((category, index, array) => {
          return (
            <View key={category}>
              {category === "MOL Operations Support" ? (
                <>
                  <View style={[tw("flex flex-col my-4 mx-5")]}>
                    <View
                      key={`${category}-${index}`}
                      style={tw("mb-2 flex-col")}
                    >
                      <Text
                        style={{
                          ...fonts.style2,
                          paddingTop: 4,
                          paddingBottom: 8,
                        }}
                      >
                        {links[category][0].description}
                      </Text>
                    </View>
                    {links[category].map((link, index) => (
                      <View
                        key={`${link.component}-${index}`}
                        style={tw("mb-2 flex-row items-center")}
                      >
                        <Text
                          style={fonts.link2}
                          ref={inputRef}
                          onPress={() => {
                            if (status.dirty) {
                              setTouched(!isTouched);
                              setLink(link);
                            } else onPress(link);
                          }}
                        >
                          {link?.title}
                        </Text>
                      </View>
                    ))}
                  </View>
                  <View
                    style={{
                      borderBottomColor: colors.grayScaleLight,
                      borderBottomWidth: index !== array.length - 1 ? 1 : 0,
                      marginHorizontal: 20,
                    }}
                  ></View>
                </>
              ) : null}
            </View>
          );
        })}
        <ConfirmModal
          modalVisible={isTouched}
          handlePrimary={() => {
            setTouched(false);
          }}
          handleSecondary={() => {
            setTouched(!isTouched);
            onPress(capturedLink);
          }}
          data={{
            modal: {
              primary: "Save",
              content: `Please save the content before you leave to next section`,
            },
          }}
        />
      </View>
    </>
  );
};

export default MainScreen;
