import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text, Platform } from "react-native";

import { Input, Image, Icon } from "react-native-elements";
import { useFormikContext } from "formik";

import { FormFieldSwitch } from "components/elements/forms";
import { Tooltip } from "components/elements";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import {
  selectCatalogPriceMinimumValue,
  selectEnableFulfillmentDates,
  selectShopCode,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";
import { setSideCar } from "library/sagas/views/home/drawer/product-catalog/common/slice";
import { SecondaryButton, DateField, Currency } from "components/wrappers";
import styles from "../common/styles";
import { fonts, theme } from "styles/theme";
import tw from "tailwind-rn";

const Availability = () => {
  const shopCode = useSelector(selectShopCode);
  const currency = Currency(shopCode);

  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile, isDesktop } = React.useContext(DeviceContext);
  const dispatch = useDispatch();
  const {
    values: {
      availability = false,
      showOnWebsite = true,
      localDelivery = false,
      soldOut = false,
      group,
      call_for_price = false,
      inStorePickUp = false,
      productType,
      productId,
      excludeFromPriceMinimum = true,
      excludeFromRushDelivery = true,
    },
    setFieldValue,
  } = useFormikContext();
  const isMOLProduct = group === "mol";
  const isProduct = productType !== "addon" && productId !== "GIFT_CARD";
  const isDropShip = productType === "dropship";
  const catalogPriceMinimum = useSelector(selectCatalogPriceMinimumValue);
  const enableFulfillmentDates = useSelector(selectEnableFulfillmentDates);

  return (
    <View style={tw("flex flex-col")}>
      <View style={[tw("flex flex-row mb-2")]}>
        <Text style={{ width: 250, ...fonts.heading4 }}>
          {Localise(messages, "Active")}
        </Text>
        <FormFieldSwitch
          name="availability"
          value={availability}
          onValueChange={() => {
            setFieldValue("availability", !availability);
          }}
        />
      </View>

      <ActiveDateFields component={"active"} />
      {enableFulfillmentDates && isProduct && productId !== "GIFT_CARD" && (
        <ActiveDateFields component={"fulfillment"} />
      )}

      {!isMOLProduct && isProduct && (
        <View style={[tw("flex flex-row mb-2")]}>
          <Text style={{ width: 250, ...fonts.heading4 }}>
            {Localise(messages, "Show On Website")}
          </Text>
          <FormFieldSwitch
            name={"showOnWebsite"}
            value={showOnWebsite}
            onValueChange={() => setFieldValue("showOnWebsite", !showOnWebsite)}
          />
        </View>
      )}

      {isProduct && (
        <>
          {!isMOLProduct && !isDropShip && (
            <View style={[tw("flex flex-row mb-2")]}>
              <Text style={{ width: 250, ...fonts.heading4 }}>
                {Localise(messages, "Local Delivery Only")}
              </Text>
              <FormFieldSwitch
                name="localDelivery"
                value={localDelivery}
                onValueChange={() =>
                  setFieldValue("localDelivery", !localDelivery)
                }
              />
            </View>
          )}

          {!isDropShip && (
            <View style={[tw("flex flex-row mb-2")]}>
              <Text style={{ width: 250, ...fonts.heading4 }}>
                {Localise(messages, "Call For Price")}
              </Text>
              <FormFieldSwitch
                name="call_for_price"
                value={call_for_price}
                onValueChange={() =>
                  setFieldValue("call_for_price", !call_for_price)
                }
              />
            </View>
          )}

          {!isDropShip && (
            <View style={[tw("flex flex-row mb-2")]}>
              <Text style={{ width: 250, ...fonts.heading4 }}>
                {Localise(messages, "In-Store Pickup Only")}
              </Text>
              <FormFieldSwitch
                name="inStorePickUp"
                value={inStorePickUp}
                onValueChange={() =>
                  setFieldValue("inStorePickUp", !inStorePickUp)
                }
              />
            </View>
          )}
        </>
      )}

      <View style={[tw("flex flex-row mb-2")]}>
        <Text style={{ width: 250, ...fonts.heading4 }}>
          {Localise(messages, "Sold Out")}
        </Text>
        <FormFieldSwitch
          name="soldOut"
          value={soldOut}
          onValueChange={() => setFieldValue("soldOut", !soldOut)}
        />
      </View>
      {isProduct && !isDropShip && parseFloat(catalogPriceMinimum) > 0 && (
        <View style={[tw(`flex ${isMobile ? "flex-col" : "flex-row"}  mb-2`)]}>
          <View style={tw("flex flex-row")}>
            <Text style={{ width: 250, ...fonts.heading4 }}>
              {Localise(messages, "Apply Catalog Minimum")}
            </Text>
            <FormFieldSwitch
              name="excludeFromPriceMinimum"
              value={!excludeFromPriceMinimum}
              onValueChange={() =>
                setFieldValue(
                  "excludeFromPriceMinimum",
                  !excludeFromPriceMinimum
                )
              }
            />
          </View>
          <View style={tw("flex flex-row")}>
            <Input
              disabled={true}
              leftIconContainerStyle={{
                paddingRight: 0,
              }}
              leftIcon={
                <Icon
                  name={"currency-usd"}
                  type={"material-community"}
                  size={14}
                  iconStyle={{ color: "rgba(170, 170, 170, 1)" }}
                />
              }
              rightIcon={
                <Text
                  style={[fonts.default, { color: "rgba(170, 170, 170, 1)" }]}
                >
                  {" "}
                  {currency}
                </Text>
              }
              containerStyle={{ width: 90, paddingLeft: isMobile ? 0 : 5 }}
              inputContainerStyle={{ height: !isDesktop ? 35 : 20, padding: 6 }}
              inputStyle={{ height: !isDesktop ? 35 : 20 }}
              value={catalogPriceMinimum}
            />
            <Text style={{ ...fonts.default, lineHeight: 25 }}>
              {Localise(messages, "Edit Price in")}{" "}
              <Text
                style={{ ...fonts.link1, paddingLeft: 4 }}
                onPress={() => dispatch(setSideCar("catalogSettings"))}
              >
                {Localise(messages, "Catalog Settings")}
              </Text>
            </Text>
          </View>
        </View>
      )}
      {isProduct && !isDropShip && (
        <View style={[tw(`flex ${isMobile ? "flex-col" : "flex-row"}  mb-2`)]}>
          <View style={tw("flex flex-row")}>
            <Text style={{ width: 250, ...fonts.heading4 }}>
              {Localise(messages, "Allow Rush Delivery")}
            </Text>
            <FormFieldSwitch
              name="excludeFromRushDelivery"
              value={!excludeFromRushDelivery}
              onValueChange={() =>
                setFieldValue(
                  "excludeFromRushDelivery",
                  !excludeFromRushDelivery
                )
              }
            />
          </View>
          <View style={tw("flex flex-row ml-3")}>
            <Text style={{ ...fonts.default, lineHeight: 25 }}>
              {Localise(messages, "Edit Rush Delivery in")}{" "}
              <Text
                style={{ ...fonts.link1, paddingLeft: 4 }}
                onPress={() => dispatch(setSideCar("rushDeliverySettings"))}
              >
                {Localise(messages, "Shop Settings")}
              </Text>
            </Text>
          </View>
        </View>
      )}
    </View>
  );
};

const ActiveDateFields = ({ component = "" }) => {
  const {
    values: {
      availability = false,
      activeDateAvailability = false,
      fulfillmentDateAvailability = false,
    },
    setFieldValue,
    errors,
  } = useFormikContext();

  const enableFulfillmentDates = useSelector(selectEnableFulfillmentDates);
  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile, isDesktop } = React.useContext(DeviceContext);
  const zIndex = 100;
  const fieldErrors =
    component === "active" ? "activeEndDate" : "fulfillmentEndDate";
  const availabilitySwitch =
    component === "active"
      ? activeDateAvailability
      : fulfillmentDateAvailability;
  const activeDatesToolTip =
    "These are the dates this product is available on your site and Mercury HQ";
  const fulfillmentDatesToolTip =
    "These are the dates you will fulfill and allow for delivery and pickup of this product";

  useEffect(() => {
    if (!fulfillmentDateAvailability) {
      setFieldValue("fulfillmentStartDate", "");
      setFieldValue("fulfillmentEndDate", "");
    }
    if (!activeDateAvailability) {
      setFieldValue("activeStartDate", "");
      setFieldValue("activeEndDate", "");
    }
  }, [fulfillmentDateAvailability, activeDateAvailability]);

  return (
    <View
      style={[
        tw(`flex ${isMobile ? "flex-col mb-2" : "flex-row items-end mb-2"} `),
        { zIndex: component === "active" ? 20 : 15 },
      ]}
    >
      <View style={tw("flex flex-row mb-2")}>
        <View style={[tw("flex flex-row"), { width: 250 }]}>
          <Text style={{ ...fonts.heading4, marginRight: 10 }}>
            {Localise(
              messages,
              !enableFulfillmentDates
                ? `Availability Dates`
                : `${
                    component === "active" ? "Active" : "Fulfillment"
                  } Dates (Optional)`
            )}
          </Text>
          <Tooltip
            text={
              component === "active"
                ? activeDatesToolTip
                : fulfillmentDatesToolTip
            }
            height={isMobile ? null : 40}
          >
            <Image
              style={{
                width: 15,
                height: 15,
              }}
              source={require("static/assets/help.png")}
            />
          </Tooltip>
        </View>
        {enableFulfillmentDates && (
          <FormFieldSwitch
            name={`${
              component === "active"
                ? "activeDateAvailability"
                : `fulfillmentDateAvailability`
            }`}
            value={
              component === "active"
                ? activeDateAvailability
                : fulfillmentDateAvailability
            }
            onValueChange={() =>
              setFieldValue(
                `${
                  component === "active"
                    ? "activeDateAvailability"
                    : `fulfillmentDateAvailability`
                }`,
                component === "active"
                  ? !activeDateAvailability
                  : !fulfillmentDateAvailability
              )
            }
          />
        )}
      </View>
      <View style={tw("flex flex-col")}>
        <View
          style={tw(
            `flex ${
              !isDesktop
                ? "flex-col mt-2"
                : "flex-row items-center justify-center"
            }`
          )}
        >
          {(availabilitySwitch || !enableFulfillmentDates) && (
            <>
              <View
                style={[
                  tw(
                    `flex flex-row ${
                      !isDesktop ? "justify-start" : "ml-2 justify-between "
                    } items-center`
                  ),
                  styles.datesSection,
                  { zIndex: zIndex + 20 },
                ]}
              >
                <Text
                  style={{
                    ...fonts.heading5,
                    marginHorizontal: 5,
                    width: 30,
                  }}
                >
                  {Localise(messages, "Start")}
                </Text>
                <DateField
                  name={`${
                    component === "active"
                      ? "activeStartDate"
                      : `fulfillmentStartDate`
                  }`}
                  endDatePath={`${
                    component === "active"
                      ? "activeEndDate"
                      : `fulfillmentEndDate`
                  }`}
                  startDatePath={`${
                    component === "active"
                      ? "activeStartDate"
                      : `fulfillmentStartDate`
                  }`}
                  dateValueFormat="YYYY-MM-DD"
                />
              </View>
              <View
                style={[
                  tw(
                    `flex flex-row ${
                      !isDesktop ? "justify-start" : "justify-between"
                    } items-${Platform.OS !== "web" ? "center" : "center"} `
                  ),
                  styles.datesSection,
                  { zIndex: zIndex + 20 },
                ]}
              >
                <Text
                  style={{
                    ...fonts.heading5,
                    marginHorizontal: 5,
                    width: 30,
                  }}
                >
                  {Localise(messages, "End")}
                </Text>
                <DateField
                  name={`${
                    component === "active"
                      ? "activeEndDate"
                      : `fulfillmentEndDate`
                  }`}
                  endDatePath={`${
                    component === "active"
                      ? "activeEndDate"
                      : `fulfillmentEndDate`
                  }`}
                  startDatePath={`${
                    component === "active"
                      ? "activeStartDate"
                      : `fulfillmentStartDate`
                  }`}
                  dateValueFormat="YYYY-MM-DD"
                />
                <SecondaryButton
                  title={"Clear"}
                  action={() => {
                    setFieldValue(
                      `${
                        component === "active"
                          ? "activeStartDate"
                          : "fulfillmentStartDate"
                      }`,
                      ""
                    );
                    setFieldValue(
                      `${
                        component === "active"
                          ? "activeEndDate"
                          : "fulfillmentEndDate"
                      }`,
                      ""
                    );
                  }}
                  style={tw("flex flex-row items-start")}
                />
              </View>
            </>
          )}
        </View>
      </View>
      {availability && errors[fieldErrors] && (
        <Text
          style={{ ...theme.Input.errorStyle, width: "100%", marginLeft: 10 }}
        >
          {Localise(messages, errors[fieldErrors])}
        </Text>
      )}
    </View>
  );
};

export default Availability;
