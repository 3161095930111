import React, { useEffect } from "react";
import { View, Text } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { Form, FormField, SubmitButton } from "components/elements/forms";
import { ToasterHandler } from "components/elements";

import I18NContext from "library/contexts/i18N";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";
import UserProfileStorage from "library/storage/userProfile";

import { backgroundColors, colors, fonts } from "styles/theme";
import tw from "tailwind-rn";
import moment from "moment";
import get from "lodash/get";

import { selectDSRequestActionProcessing } from "library/sagas/views/home/drawer/delivery-tasks/selector";
import { setShowApproveCreditForm } from "library/sagas/views/home/drawer/delivery-tasks/slice";
import { getApproveCreditFormValidationSchema } from "./yup";

// eslint-disable-next-line react/display-name
const ApproveCreditForm = React.memo(
  ({ dsRequestDetails, onAction, handleOnAction, handleError }) => {
    const dispatch = useDispatch();
    const { messages: locMessages, Localise } = React.useContext(I18NContext);
    const [onChangeValidation, setOnChangeValidation] =
      useStateIfMounted(false);
    const isActionProcessing = useSelector(selectDSRequestActionProcessing);
    const ApproveCreditRef = React.useRef();
    const {
      firstName: operatorFirstName = "",
      lastName: operatorLastName = "",
    } = UserProfileStorage.getUser();
    const handledBy = operatorFirstName + " " + operatorLastName;
    const {
      referenceId,
      shipmentId,
      siteId: shopCode,
      items,
      billing = {},
      vendor: { name: deliveryProvider = "" } = {},
      createdDate,
      delivery,
    } = dsRequestDetails;
    const { deliveryFee: { value: serviceCharge = 0 } = {} } = billing;

    //calculating maxCreditAmount (total of all items cost + billing deliveryFee)
    const orderItemAmount = items.reduce(
      (accumulator, item) => accumulator + item.cost.value,
      0
    );
    const maxCreditAmount = parseFloat(orderItemAmount + serviceCharge).toFixed(
      2
    );

    const formSubmitTrigger = (values, formikBag) => {
      if (formikBag.isSubmitting) return;
      const { approvedCreditAmount, csrNotes = "" } = values;
      const body = {
        referenceId,
        status: "APPROVED",
        csrNotes,
        approvedAmount: {
          value: parseFloat(approvedCreditAmount).toFixed(2),
          currency: "USD",
        },
        serviceProvider: deliveryProvider,
        orderItemAmount: {
          value: orderItemAmount,
          currency: "USD",
        },
        deliveryFee: {
          value: serviceCharge,
          currency: "USD",
        },
        contact: {
          firstName: get(delivery, "contact.firstName", ""),
          lastName: get(delivery, "contact.lastName", ""),
        },
        handledBy: handledBy.trim(),
        shipmentCreatedDate: moment(createdDate).format("YYYY-MM-DD"),
        shipmentDeliveryDate: moment(
          get(delivery, "timeWindow.start", "")
        ).format("YYYY-MM-DD"),
      };

      request("approve-delivery-credit", {
        shopCode,
        shipmentId,
        body,
      })
        .then((res) => {
          formikBag.resetForm();
          onAction("approve-credit", shipmentId, true);
          handleOnAction("approve-credit", shipmentId, true);
          ToasterHandler("success", Localise(locMessages, "Credit approved"));
        })
        .catch((err) => {
          handleError(err);
        })
        .finally(() => {
          formikBag.setSubmitting(false);
          dispatch(setShowApproveCreditForm(false));
        });
    };

    return (
      <View
        style={{
          zIndex: 1,
          borderWidth: 1,
          borderColor: colors.grayScaleLight,
        }}
      >
        <View
          style={{
            backgroundColor: backgroundColors.secondary,
            paddingHorizontal: 20,
            paddingVertical: 20,
          }}
        >
          <View style={tw("mb-3")}>
            <Text style={fonts.heading3}>
              {Localise(locMessages, "Approve Credit")}
            </Text>
          </View>

          <Form
            initialValues={{
              maxCreditAmount: maxCreditAmount,
              approvedCreditAmount: "",
              csrNotes: "",
            }}
            innerRef={ApproveCreditRef}
            validationSchema={getApproveCreditFormValidationSchema(
              Localise,
              locMessages,
              maxCreditAmount
            )}
            validateOnChange={onChangeValidation}
            validateOnBlur={onChangeValidation}
            onSubmit={(values, formikBag) =>
              formSubmitTrigger(values, formikBag)
            }
            render={({ submitCount }) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                if (submitCount > 0) {
                  setOnChangeValidation(true);
                }
              }, [submitCount]);

              return (
                <View style={tw("flex flex-col")}>
                  <View style={tw("flex flex-row my-1")}>
                    <Text
                      style={{
                        ...fonts.heading5,
                        width: 125,
                      }}
                    >
                      {Localise(locMessages, "Enter Amount")}
                    </Text>
                    <View style={{ width: 195 }}>
                      <FormField
                        name="approvedCreditAmount"
                        placeholder={parseFloat(0).toFixed(2)}
                        grayedOutOnDisable={true}
                        containerStyle={{
                          width: "100%",
                          padding: 0,
                          margin: 0,
                        }}
                        keyboardType="numeric"
                        iconType="material-community"
                        iconName="currency-usd"
                        iconSize={14}
                        inputContainerStyle={{ padding: 6 }}
                        leftIconContainerStyle={{ paddingRight: 0 }}
                        isPrice={true}
                        iconPosition={true}
                        isUpdateOnChange={false}
                      />
                    </View>
                  </View>

                  <View style={tw("flex flex-row my-1")}>
                    <Text style={{ ...fonts.heading5, width: 125 }}>
                      {Localise(locMessages, "Notes")}
                    </Text>
                    <View style={{ width: 195 }}>
                      <FormField
                        autoCapitalize="none"
                        autoCorrect={false}
                        name="csrNotes"
                        placeholder="Add additional notes if needed"
                        multiline={true}
                        numberOfLines={2}
                      />
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <View style={tw("mx-5")}>
                      <Text
                        style={{ ...fonts.heading4, marginVertical: 8 }}
                        onPress={() => {
                          dispatch(setShowApproveCreditForm(false));
                        }}
                      >
                        {Localise(locMessages, "Nevermind")}
                      </Text>
                    </View>
                    <SubmitButton
                      disableOnDirty={isActionProcessing}
                      disableOnErrors={true}
                      containerStyle={{
                        flexDirection: "row-reverse",
                        margin: 5,
                      }}
                      title={"Confirm"}
                    />
                  </View>
                </View>
              );
            }}
          />
        </View>
      </View>
    );
  }
);

export default ApproveCreditForm;
