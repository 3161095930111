import React, { useContext } from "react";
import { View } from "react-native";
import { Accordion } from "components/elements";
import I18NContext from "library/contexts/i18N";
import { useSelector } from "react-redux";
import moment from "moment";
import { backgroundColors, colors } from "styles/theme";
import { selectDSRequestDetails } from "library/sagas/views/home/drawer/delivery-tasks/selector";
import { generateContent } from ".././helper";
import UserProfileStorage from "library/storage/userProfile";
import { convertToTimeZone } from "library/utils/deliveryService";
import { phoneNumberFormatter } from "library/utils/formatter";

const FulfillmentInfo = () => {
  const { messages: locMessages, Localise } = useContext(I18NContext);
  const dsRequestDetails = useSelector(selectDSRequestDetails);
  const shopLocations = UserProfileStorage.getAllShopLocation();
  const shopNames = UserProfileStorage.getAllShopNames();

  const { createdDate, siteId: shopCode } = dsRequestDetails;
  const shopTimeZone =
    UserProfileStorage.getShopTimeZone(shopCode) || "America/Chicago";
  const floristTimeZone = moment.tz(shopTimeZone).format("z");

  const {
    addressLine1: shopAddressLine1 = "",
    city: shopCity = "",
    state: shopState = "",
    zip: shopZip = "",
  } = shopLocations[shopCode] || {};

  const fulfillmentInfo = {
    "Date & Time Received": `${moment(
      convertToTimeZone(createdDate, shopTimeZone)
    ).format("MM/DD/YY [at] hh:mm A")} (${floristTimeZone.toUpperCase()})`,
    "Member Code": shopCode,
    "Shop Name": shopNames[shopCode],
    Phone: phoneNumberFormatter(
      UserProfileStorage.getShopPhoneNumber(shopCode)
    ),
    Address: {
      line1: shopAddressLine1,
      line2: `${shopCity},${shopState} ${shopZip}`,
    },
    Assignment: "Delivery Service",
  };

  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
        backgroundColor: backgroundColors.secondary,
        marginBottom: 10,
      }}
    >
      <Accordion
        title={Localise(locMessages, "Fulfillment")}
        defaultOpen={true}
        handleOnPress={() => {}}
        labelStyle={{
          marginTop: 0,
          paddingHorizontal: 12,
          height: 50,
          borderBottomWidth: 1,
          backgroundColor: backgroundColors.navBar,
          borderColor: colors.grayScaleLight,
        }}
        contentStyle={{
          paddingHorizontal: 20,
          paddingVertical: 20,
        }}
        titleStyle={{
          color: colors.primary,
          paddingLeft: 10,
        }}
        iconStyle={{
          color: colors.primary,
        }}
        imageProps={{
          width: 27,
          height: 18,
          source: "fulfillment",
        }}
        testID={"dsRequestFulfillment"}
        accessibilityLabel={"dsRequestFulfillment"}
      >
        {generateContent(fulfillmentInfo, true)}
      </Accordion>
    </View>
  );
};

export default FulfillmentInfo;
