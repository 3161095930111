import React from "react";
import { View, StyleSheet, Linking, Platform } from "react-native";
import { Image, Text } from "react-native-elements";
import { useSelector } from "react-redux";
import tw from "tailwind-rn";
import moment from "moment";
import { fonts, colors, backgroundColors } from "styles/theme";
import IMAGES from "static/assets/images";
import I18NContext from "library/contexts/i18N";
import { selectDSRequestDetails } from "library/sagas/views/home/drawer/delivery-tasks/selector";
import UserProfileStorage from "library/storage/userProfile";
import { MessageText } from "react-native-gifted-chat";

import { Accordion, Chat } from "components/elements";
import {
  isBurqProvider,
  IsCanceledBySender,
  convertToTimeZone,
  getErrorMessageForRejectedDSRequest,
} from "library/utils/deliveryService";
import { DSJourneyMessages } from "../config";

const DSRequestJourney = ({ hideSendInput = true }) => {
  const { messages: locMessages, Localise } = React.useContext(I18NContext);
  const dsRequestDetails = useSelector(selectDSRequestDetails);
  let deliveryInvoiceMessage = "";
  let deliveryRejectMessage = "";
  const {
    createdDate,
    siteId: shopCode = "",
    state,
    notes,
    pickup: {
      address: { locationName: shopName = "" },
      timeWindow: { start: estimatedPickupTime = "" },
    } = {},
    delivery: {
      notes: deliveryInstructions = "",
      timeWindow: { start: estimatedDeliveryTime = "" },
    } = {},
    lastModifiedDate,
    events: DSRequestEvents = [],
    imageDetails = {},
    billing = {},
    vendor: {
      responseMessage: vendorResponseMessage = "",
      name: DsProviderName = "",
    } = {},
    correlatedShipments: { redeliveryFor = [], reroutedFor = [] } = {},
  } = dsRequestDetails;

  const {
    signature = "",
    pickup: pickupProof = "",
    delivery: deliveryProof = "",
  } = imageDetails;

  const {
    invoiceNumber = "",
    deliveryFee: { value: serviceCharge = "" } = {},
    estimatedDeliveryFee: { value: estimatedServiceCharge = 0 } = {},
  } = billing;

  const userId = shopCode;
  const userName = shopName;

  const shopTimeZone =
    UserProfileStorage.getShopTimeZone(shopCode) || "America/Chicago";
  const floristTimeZone = moment.tz(shopTimeZone).format("z");

  const DSHeaderText = `${
    Localise(locMessages, "Delivery Service") +
    " at " +
    shopCode +
    " " +
    shopName
  }`;
  const CSRHeaderText = `${
    Localise(locMessages, "FTD Customer Service") +
    " at " +
    shopCode +
    " " +
    shopName
  }`;
  const hasReroutingEvent = DSRequestEvents.some(
    (event) => "REROUTING" === event.eventType
  );
  const hasReroutedForEvent = DSRequestEvents.some(
    (event) => "REROUTED_FOR" === event.eventType
  );
  const hasRedeliveryForEvent = DSRequestEvents.some(
    (event) => "REDELIVERY_FOR" === event.eventType
  );
  const hasRejectEvent = DSRequestEvents.some(
    (event) => "REJECTED" === event.eventType
  );

  if (["REJECTED", "REJECTED_ACK"].includes(state)) {
    const errorMessage = getErrorMessageForRejectedDSRequest(
      vendorResponseMessage,
      state,
      notes
    );
    deliveryRejectMessage = `${
      errorMessage
        ? `\n${Localise(locMessages, `Reason`)}: ${errorMessage}`
        : ``
    }`;
  }
  const chatMessages = DSRequestEvents.filter(
    (event) =>
      Object.keys(DSJourneyMessages)
        .concat(["CANCELLED", "CREDIT"])
        .includes(event.eventType) && !event.eventSubType
  ).map((event, index) => {
    const { eventType, time, notes: journeyNotes = "", operator } = event;

    const isLmds = operator === "last-mile-delivery-service";
    const headerText = `${
      operator
        ? isLmds
          ? DSHeaderText
          : eventType === "CREDIT"
          ? CSRHeaderText
          : shopName
        : eventType === "CANCELLED"
        ? IsCanceledBySender(state, notes)
          ? hasReroutedForEvent
            ? DSHeaderText
            : shopName
          : DSHeaderText
        : eventType === "REROUTE_CANCEL"
        ? shopName
        : eventType === "CREDIT"
        ? CSRHeaderText
        : DSHeaderText
    }`;
    const direction = operator
      ? isLmds || eventType === "CREDIT"
        ? "INBOUND"
        : "OUTBOUND"
      : eventType === "CANCELLED"
      ? IsCanceledBySender(state, notes)
        ? hasReroutedForEvent
          ? "INBOUND"
          : "OUTBOUND"
        : "INBOUND"
      : eventType === "REROUTE_CANCEL"
      ? "OUTBOUND"
      : "INBOUND";

    if (billing) {
      deliveryInvoiceMessage = `${
        invoiceNumber
          ? `\n${Localise(
              locMessages,
              `Delivery Service Invoice`
            )}: ${invoiceNumber}`
          : ``
      }${
        serviceCharge
          ? `\n${Localise(locMessages, `Service Charge`)}: $${
              isNaN(serviceCharge)
                ? serviceCharge
                : parseFloat(serviceCharge).toFixed(2)
            }`
          : ``
      }`;
    }

    const messageText = `${
      eventType === "CREDIT"
        ? ""
        : `${Localise(locMessages, `Delivery Service Update`)}: `
    }${
      eventType === "CANCELLED"
        ? IsCanceledBySender(state, notes)
          ? `${Localise(
              locMessages,
              `${
                hasReroutedForEvent
                  ? "Rerouted request canceled"
                  : "Request canceled"
              }`
            )}${deliveryInvoiceMessage}`
          : `${Localise(
              locMessages,
              `${
                isBurqProvider(DsProviderName) && hasReroutingEvent
                  ? "Request canceled by delivery service. Rerouting to another provider"
                  : "Request canceled by delivery service."
              }`
            )}${deliveryInvoiceMessage}`
        : eventType === "RETURNED"
        ? `${Localise(
            locMessages,
            DSJourneyMessages[eventType]
          )}${deliveryInvoiceMessage}`
        : hasRejectEvent && eventType === "REJECTED"
        ? `${Localise(
            locMessages,
            DSJourneyMessages[eventType]
          )}${deliveryRejectMessage}`
        : eventType === "CREDIT"
        ? `${Localise(locMessages, journeyNotes)}`
        : Localise(locMessages, DSJourneyMessages[eventType])
    }`;

    return {
      _id: index + 2,
      user: {
        _id: shopName,
        name: headerText,
        avatar: true,
      },
      text: messageText,
      createdAt: time,
      direction,
      timezone: shopTimeZone,
      eventType,
    };
  });

  const dsUpdateMsgTemplate = (text) => {
    return {
      _id: 1,
      user: {
        _id: shopName,
        name: DSHeaderText,
        avatar: true,
      },
      text,
      createdAt: createdDate,
      direction: "INBOUND",
      timezone: shopTimeZone,
    };
  };

  const defaultMessage = dsUpdateMsgTemplate(
    `${Localise(locMessages, `Delivery Service Update`)}:\n${Localise(
      locMessages,
      `Delivery Instructions`
    )}: ${deliveryInstructions}\n${Localise(
      locMessages,
      `Estimated Delivery Time`
    )}: ${moment(convertToTimeZone(estimatedDeliveryTime, shopTimeZone)).format(
      "h:mm A MM/DD/YYYY"
    )} - ${floristTimeZone}\n${Localise(
      locMessages,
      `Estimated Pickup Time`
    )}: ${moment(convertToTimeZone(estimatedPickupTime, shopTimeZone)).format(
      "h:mm A MM/DD/YYYY"
    )} - ${floristTimeZone}${
      estimatedServiceCharge
        ? `\n${Localise(
            locMessages,
            `Estimated Delivery Service Charge`
          )}: $${parseFloat(estimatedServiceCharge).toFixed(2)}`
        : ""
    }`
  );

  if (
    redeliveryFor?.length > 0 ||
    hasRedeliveryForEvent ||
    reroutedFor?.length > 0 ||
    hasReroutedForEvent
  ) {
    chatMessages.splice(1, 0, defaultMessage);
  } else {
    chatMessages.unshift(defaultMessage);
  }

  if (!hasRejectEvent && ["REJECTED", "REJECTED_ACK"].includes(state)) {
    chatMessages.push({
      _id: chatMessages.length + 1,
      user: {
        _id: shopName,
        name: DSHeaderText,
        avatar: true,
      },
      text: `${Localise(locMessages, `Delivery Service Update`)}: ${Localise(
        locMessages,
        `Order rejected`
      )}${deliveryRejectMessage}`,
      createdAt: lastModifiedDate,
      direction: "INBOUND",
      timezone: shopTimeZone,
    });
  }

  const CustomMessageText = (props) => {
    const { currentMessage } = props;
    const { eventType } = currentMessage;

    const onClickLink = (link) => {
      Platform.OS === "web" ? window.open(link) : Linking.openURL(link);
    };

    const linkComponent = (text, link, breakLine = false) => {
      return (
        <Text>
          <Text
            style={{
              ...styles.messageCustomTextStyle,
              color: colors.dark,
              lineHeight: 20,
            }}
          >
            <Text
              style={{ ...fonts.link1, fontSize: 12 }}
              onPress={() => onClickLink(link)}
            >
              {Localise(locMessages, text)}
            </Text>
          </Text>
          {breakLine && "\n"}
        </Text>
      );
    };
    const textComponent = (text, value, breakLine = false) => {
      return (
        <Text>
          <Text
            style={{
              ...styles.messageCustomTextStyle,
              color: colors.dark,
              lineHeight: 20,
            }}
          >
            {Localise(locMessages, text)}: {value}
          </Text>
          {breakLine && "\n"}
        </Text>
      );
    };

    return (
      <View>
        <Text>
          <MessageText
            {...props}
            currentMessage={{ ...currentMessage }}
            customTextStyle={[
              styles.messageCustomTextStyle,
              Platform.OS === "web" && { marginVertical: 0 },
            ]}
          />
          {eventType === "PICKUP_COMPLETE"
            ? pickupProof && (
                <Text>
                  {"\n"}
                  {linkComponent("Proof of Pickup", pickupProof)}
                </Text>
              )
            : eventType === "DELIVERED"
            ? (deliveryProof ||
                signature ||
                invoiceNumber ||
                serviceCharge) && (
                <Text>
                  {"\n"}
                  {deliveryProof &&
                    linkComponent(
                      "Proof of Delivery",
                      deliveryProof,
                      signature || invoiceNumber || serviceCharge
                    )}
                  {signature &&
                    linkComponent(
                      "Signature",
                      signature,
                      invoiceNumber || serviceCharge
                    )}
                  {invoiceNumber &&
                    textComponent(
                      "Delivery Service Invoice",
                      invoiceNumber,
                      serviceCharge
                    )}
                  {serviceCharge &&
                    textComponent(
                      "Service Charge",
                      `$${
                        isNaN(serviceCharge)
                          ? serviceCharge
                          : parseFloat(serviceCharge).toFixed(2)
                      }`
                    )}
                </Text>
              )
            : ``}
        </Text>
      </View>
    );
  };

  const renderTime = (props) => {
    const {
      currentMessage: { createdAt },
    } = props;

    return (
      <Text style={{ fontStyle: "italic", marginTop: 3, fontSize: 11 }}>
        {moment(convertToTimeZone(createdAt, shopTimeZone)).format(
          "M/D/YY [at] h:mm A"
        )}
      </Text>
    );
  };

  const renderAvatar = (props) => {
    const {
      currentMessage: { direction = "" },
    } = props;
    const OutgoingMessage = direction === "OUTBOUND";

    return (
      <>
        {OutgoingMessage ? (
          <Image style={styles.chatAvatar} source={IMAGES["chat-profile1"]} />
        ) : (
          <Image style={styles.chatAvatar} source={IMAGES["chat-profile2"]} />
        )}
      </>
    );
  };

  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
      }}
    >
      <Accordion
        title={Localise(locMessages, "Order Journey")}
        defaultOpen={true}
        handleOnPress={() => {}}
        labelStyle={{
          marginTop: 0,
          height: 50,
          backgroundColor: backgroundColors.navBar,
          borderBottomWidth: 1,
          borderColor: colors.grayScaleLight,
          paddingHorizontal: 12,
        }}
        titleStyle={{
          color: colors.primary,
          paddingLeft: 10,
        }}
        iconStyle={{
          color: colors.primary,
        }}
        imageProps={{
          width: 24,
          height: 22,
          source: "chat-grey",
        }}
        testID="dsRequestJourney"
      >
        <View style={[tw("px-2"), { height: 500 }]}>
          <Chat
            messages={chatMessages}
            userId={userId}
            userName={userName}
            customMessageText={CustomMessageText}
            renderAvatar={renderAvatar}
            renderTime={renderTime}
            containerStyle={styles.mainContainerStyle}
            hideSendInput={hideSendInput}
          />
        </View>
      </Accordion>
    </View>
  );
};

const styles = StyleSheet.create({
  butterCupAvatar: {
    width: 35,
    height: 35,
  },
  chatAvatar: {
    width: 30,
    height: 30,
  },
  mainContainerStyle: {
    flex: 1,
    maxHeight: 500,
  },
  messageCustomTextStyle: {
    fontSize: 12,
    marginLeft: 0,
    fontFamily: fonts.fontFamily.default,
  },
});

export default DSRequestJourney;
