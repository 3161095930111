import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";
import isEmpty from "lodash/isEmpty";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectPageStatus = createSelector(
  selectState,
  ({ status }) => status
);

export const selectIsPageLoading = createSelector(
  selectPageStatus,
  (status) => status === "progress"
);

export const selectIsPageFailed = createSelector(
  selectPageStatus,
  (status) => status === "fail"
);

export const selectIsPageLoaded = createSelector(
  selectPageStatus,
  (status) => status === "done"
);

export const selectActions = createSelector(
  selectState,
  ({ actions }) => actions
);

export const selectIsEditOrder = createSelector(
  selectState,
  ({ action, type = "" }) => action === "edit" && type === ""
);

export const selectRushDeliveryFee = createSelector(
  selectState,
  ({ rushDeliveryFee = 0 }) => rushDeliveryFee
);

export const selectIsCopyOrder = createSelector(
  selectState,
  ({ action }) => action === "copy"
);

export const selectIsCreateSubscription = createSelector(
  selectState,
  ({ action, type }) => action === "create" && type === "subscription"
);

export const selectIsEditSubscription = createSelector(
  selectState,
  ({ action, type }) => action === "edit" && type === "subscription"
);

export const selectIsCopySubscription = createSelector(
  selectState,
  ({ action, type }) => action === "copy" && type === "subscription"
);

export const selectIsSubscription = createSelector(
  selectState,
  ({ type }) => type === "subscription"
);

export const selectOrderItemId = createSelector(
  selectState,
  ({ orderItemId }) => orderItemId
);

export const selectActualDeliveryMethod = createSelector(
  selectState,
  ({ deliveryMethod }) => deliveryMethod
);

export const selectCustomerOrders = createSelector(
  selectState,
  ({ customerOrders = [] }) => customerOrders
);

export const selectCreateOrderShopCode = createSelector(
  selectState,
  ({ selectedShopCode }) => selectedShopCode
);

export const selectOrderItemTabs = createSelector(
  selectState,
  ({ orderItemTabs }) => orderItemTabs || []
);

export const selectActiveItemTab = createSelector(
  selectState,
  ({ activeItemTab }) => activeItemTab || 0
);

export const selectGiftCardsData = createSelector(
  selectState,
  ({ giftCardsData }) => giftCardsData
);

export const selectRedeemedGiftCardsData = createSelector(
  selectState,
  ({ redeemedGcData }) => redeemedGcData
);

export const selectUpdateCounty = createSelector(
  selectState,
  ({ updateCounty }) => updateCounty || false
);

export const selectCalculatingTax = createSelector(
  selectState,
  ({ calculateTax }) => calculateTax || false
);

export const selectPaymentDetails = createSelector(
  selectState,
  ({ paymentDetails }) => paymentDetails || {}
);

export const selectOrderSummary = createSelector(
  selectState,
  ({ orderSummary }) => orderSummary || {}
);

export const selectRefundSummary = createSelector(
  selectOrderSummary,
  ({ summary }) => summary || {}
);

export const selectIsOrderSummaryLoading = createSelector(
  selectState,
  ({ orderSummaryStatus }) => orderSummaryStatus === "progress"
);

export const selectDraftOrders = createSelector(
  selectState,
  ({ draftOrders }) => draftOrders
);

export const selectShopCodeForDraftOrder = createSelector(
  selectState,
  ({ selectedMemberCode }) => selectedMemberCode
);

export const selectDraftOrdersByMember = (memberCodes) =>
  createSelector(selectState, ({ draftOrders }) => {
    // Handle case where memberCodes is "all", empty string, or empty array
    if (isEmpty(memberCodes) || memberCodes === "all") {
      return Object.values(draftOrders).flat(); // Return all orders across memberCodes
    }

    // Convert single member code string into an array for consistency
    const memberCodesArray = Array.isArray(memberCodes)
      ? memberCodes
      : [memberCodes];

    // Aggregate orders for all selected memberCodes
    return memberCodesArray.reduce((acc, memberCode) => {
      if (draftOrders[memberCode]) {
        acc.push(...draftOrders[memberCode]);
      }
      return acc;
    }, []);
  });

export const selectDraftsToDelete = createSelector(
  selectState,
  ({ draftsToDelete }) => draftsToDelete
);

export const selectIsEditProducts = createSelector(
  selectState,
  ({ editProducts }) => editProducts || false
);

export const selectZoneDetails = createSelector(
  selectState,
  ({ zoneDetails }) => zoneDetails
);

export const selectGeoCodeDetails = createSelector(
  selectState,
  ({ geoCodeDetails }) => geoCodeDetails
);

export const selectSubscriptionData = createSelector(
  selectState,
  ({ subscription }) => subscription?.subscriptionData
);

export const selectSubscriptionCustomer = createSelector(
  selectState,
  ({ subscription }) => subscription?.customer
);
