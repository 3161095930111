import { request as fetch } from "library/utils/request";
import UserProfileStorage from "library/storage/userProfile";

const request = (service = "", params = {}) => {
  const allowSyndicate = [
    "create-product",
    "update-product",
    "delete-product",
    "restore-product",
    "update-shared-catalog",
    "bulk-products-update",
    "create-collection",
    "update-collection",
    "delete-collection",
    "save-card-settings",
    "product-revert-to-global",
    "collection-revert-to-global",
  ].includes(service);
  const memberCodes = UserProfileStorage.getProfileMemberCodes();
  const shopCode = params?.shopCode ?? "";

  if (shopCode === "all") {
    delete params.shopCode;
    /* Below check is to make sure request context has only single membercode for all other api calls */
    if (!allowSyndicate) params.memberCode = memberCodes[0];
  } else {
    params.memberCode = shopCode;
  }

  if (params?.siteId === "all") {
    params.siteId = memberCodes[0];
  }

  if (shopCode === "all" && params?.media) {
    params.media = { [memberCodes[0]]: params?.media[shopCode] };
  }

  if (shopCode === "all" && params?.siteSellable?.includes(shopCode)) {
    const filter = params.siteSellable.filter((e) => e !== shopCode);
    params.siteSellable = [...filter, memberCodes[0]];
  }

  return fetch(service, params);
};

export default request;
