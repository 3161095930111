import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";

export const initialState = {
  createCardShopCode: "",
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCreateCardShopCode: (state, action) => {
      state.createCardShopCode = action.payload;
    },
  },
});

export const { setCreateCardShopCode } = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
