import React from "react";
import { View } from "react-native";
import { Accordion } from "components/elements";
import { DeviceContext } from "library/contexts/appSettings";
import { colors, fonts, backgroundColors } from "styles/theme";

export const Section = ({
  title,
  overrideZindex,
  modify = true,
  key,
  children,
}) => {
  const { isMobile } = React.useContext(DeviceContext);
  return (
    <View
      key={key}
      pointerEvents={modify ? "auto" : "none"}
      style={{ zIndex: overrideZindex ? overrideZindex : -1 }}
    >
      <Accordion
        title={title}
        defaultOpen={true}
        handleOnPress={() => {}}
        separatorAlways={true}
        labelStyle={{
          marginVertical: 5,
          backgroundColor: backgroundColors.secondary,
          paddingHorizontal: 0,
        }}
        titleStyle={{
          ...fonts.heading4,
          fontWeight: "600",
        }}
        closeStyle={{
          borderBottomWidth: 0,
        }}
        iconStyle={{
          color: colors.primary,
        }}
        testID={title}
      >
        <View
          style={{
            backgroundColor: backgroundColors.secondary,
            padding: isMobile ? 10 : 0,
            paddingTop: 15,
          }}
        >
          {children}
        </View>
      </Accordion>
    </View>
  );
};
