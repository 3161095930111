import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text, Platform, TouchableOpacity } from "react-native";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { Image, Input, Icon } from "react-native-elements";
import { useFormikContext, FieldArray, getIn } from "formik";
import isArray from "lodash/isArray";

import { FormFieldCheckBox, FormField } from "components/elements/forms";
import {
  ImageModal,
  FileSelector,
  Tooltip,
  Picker,
  PrintIframeAction,
  PrintIframeRenderer,
} from "components/elements";

import { LabelField, Currency } from "components/wrappers";
import {
  setSideCar,
  setProductExtraFilters,
} from "library/sagas/views/home/drawer/product-catalog/common/slice";
import {
  selectScheduledPriceAdjustment,
  selectProductColors,
  selectFlowerTypes,
  selectShopCode,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";

import { getAdjustedPrice } from "./ui-config";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import IMAGES from "static/assets/images";
import orderBy from "lodash/orderBy";
import { getRecipeInfo } from "components/views/drawer/order-details/product-details/helper";
import { fonts, colors, theme, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";
import Environment from "library/utils/environment";

export const ProductVariant = ({ index, isCreateProduct }) => {
  const shopCode = useSelector(selectShopCode);
  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile, isTablet, isDesktop } = React.useContext(DeviceContext);

  const {
    initialValues,
    values: {
      name: productName,
      image: standaloneImage,
      group,
      productType,
      standalone,
      variants,
      flowerType: selectedFlowers = [],
      productColor: selectedColors = [],
      price: masterPrice,
      srpMinPrice: masterSrpMinPrice,
      originalSrpMin: masterPrsPrice,
      compareAtPrice: masterCompareAtPrice,
      adjustedPrice: masterAdjustedPrice,
      adjustedCompareAtPrice: masterAdjustedCompareAtPrice,
      productId,
    },
    touched,
    errors,
    setFieldValue,
  } = useFormikContext();

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const priceAdjustmentRule = useSelector(selectScheduledPriceAdjustment);
  const productColors = useSelector(selectProductColors);
  const flowers = useSelector(selectFlowerTypes);

  const isPriceRuleActive =
    priceAdjustmentRule.filter((rule) => rule.active).length > 0;

  const path = `variants.${index}`;
  const variantData = variants[index] || {};
  const {
    variantType = "",
    image = "",
    active = false,
    price,
    compareAtPrice: variantCompareAtPrice,
    flowerType: selectedVariantFlowerType = [],
    productColor: selectedVariantColors = [],
    srpMinPrice: variantSrpMinPrice,
    originalSrpMin: variantPrsPrice,
    adjustedPrice: variantAdjustedPrice,
    adjustedCompareAtPrice: variantAdjustedCompareAtPrice,
  } = variantData;

  const selectedFlowersType = standalone
    ? selectedFlowers
    : selectedVariantFlowerType;
  const selectedColorsType = standalone
    ? selectedColors
    : selectedVariantColors;

  const adjustedPrice = standalone ? masterAdjustedPrice : variantAdjustedPrice;
  const adjustedCompareAtPrice = standalone
    ? masterAdjustedCompareAtPrice
    : variantAdjustedCompareAtPrice;

  const srpMinPrice = standalone ? masterSrpMinPrice : variantSrpMinPrice;
  const originalPrsPrice = standalone ? masterPrsPrice : variantPrsPrice;
  const initialPrices = standalone
    ? getIn(initialValues, "price")
    : getIn(initialValues, `variants.${index}.price`);

  const initialCompareAtPrice = standalone
    ? getIn(initialValues, "compareAtPrice")
    : getIn(initialValues, `variants.${index}.compareAtPrice`);

  const currentPrices = standalone ? masterPrice : variants[index]?.price;
  const currentCompareAtPrice = standalone
    ? masterCompareAtPrice
    : variantCompareAtPrice;

  const finalAdjustedPrice = getAdjustedPrice(
    initialPrices,
    currentPrices,
    adjustedPrice
  );

  const finalAdjustedCompareAtPrice = getAdjustedPrice(
    initialCompareAtPrice,
    currentCompareAtPrice,
    adjustedCompareAtPrice
  );

  const isMOLProduct = group === "mol";
  const isAddon = productType === "addon";

  const maxDepreciationAllowed = Environment.get(
    "MAXIMUM_SRP_DEPRECIATION_ALLOWED",
    10
  );
  const isSrpNegative = parseFloat(originalPrsPrice) <= 0;
  const allowSrpPrice = isSrpNegative
    ? parseFloat(srpMinPrice).toFixed(2)
    : (parseFloat(srpMinPrice) + parseFloat(maxDepreciationAllowed)).toFixed(2);

  const compareAtPriceToolTipText =
    "To show the sale price, move the variant's original price to Compare at Price, Enter a lower value into Price";

  const currency = Currency(shopCode);
  const srpPriceToolTipText = `FTD SRP is $${allowSrpPrice} ${currency}, Please enter a value at or above $${parseFloat(
    srpMinPrice
  ).toFixed(2)} ${currency}`;
  const imageSrc = standalone ? standaloneImage : image;
  const productImage = imageSrc ? imageSrc : IMAGES["quick-pick"];

  return (
    <View
      style={[
        tw("flex flex-col w-full mb-4"),
        {
          borderColor: colors.light,
          borderWidth: 1,
          height: "auto",
        },
      ]}
    >
      <View
        style={{
          backgroundColor:
            active || standalone
              ? backgroundColors.navBar
              : backgroundColors.sideCar,
        }}
      >
        <View style={[tw("flex flex-row items-center px-3 py-2")]}>
          {!standalone && (
            <FormFieldCheckBox
              name={`${path}.active`}
              isChecked={active}
              title={""}
              containerStyle={{ width: 40, height: 40 }}
            />
          )}

          {standalone ? (
            <FormField
              containerStyle={{
                width: 250,
                paddingLeft: 0,
              }}
              name={"name"}
              labelStyle={fonts.heading4}
              editable={!isMOLProduct}
              disabled={isMOLProduct}
              placeholder={Localise(messages, "Variation Name")}
              inputContainerStyle={{ backgroundColor: "#fff" }}
            />
          ) : !isMOLProduct ? (
            <View
              style={tw(
                `flex ${isDesktop ? `flex-row  items-center` : `flex-col`} `
              )}
            >
              <FormField
                containerStyle={{ width: 150, paddingLeft: 0 }}
                name={`${path}.variantType`}
                labelStyle={fonts.heading4}
                editable={!isMOLProduct}
                disabled={isMOLProduct}
                placeholder={Localise(messages, "Variation Name")}
                inputContainerStyle={{ backgroundColor: "#fff" }}
              />
              <View
                style={[
                  tw(`flex flex-row flex-wrap`),
                  { width: !isDesktop ? 250 : "100%" },
                ]}
              >
                <Text style={[fonts.heading4, tw("pl-1 ")]}>
                  {`${productName} ${variantType && `(${variantType})`}`}
                </Text>
              </View>
            </View>
          ) : (
            <Text
              style={[
                tw("pb-1 capitalize"),
                fonts.heading4,
                Platform.OS !== "web" && { maxWidth: "70%" },
              ]}
            >
              {Localise(messages, variantType)}
            </Text>
          )}
        </View>
      </View>
      <View style={tw(`flex flex-${isMobile ? "col" : "row"} m-3 mb-0`)}>
        <View style={[tw("flex flex-col")]}>
          <View>
            <FormField
              containerStyle={{ width: 150 }}
              name={standalone ? `productId` : `${path}.productId`}
              label={"ID"}
              labelStyle={[
                fonts.heading4,
                { fontWeight: "100", marginBottom: 4 },
              ]}
              editable={false}
            />
          </View>
          {srpMinPrice &&
          !isCreateProduct &&
          !productId.includes("GIFT_CARD") ? (
            <View style={tw("flex flex-row items-center")}>
              <LabelField
                text={`SRP: $${parseFloat(srpMinPrice).toFixed(2)} ${currency}`}
                style={{
                  ...fonts.heading4,
                  fontWeight: "100",
                  marginVertical: 10,
                  marginLeft: 7,
                  marginRight: 7,
                  paddingBottom: 0,
                }}
                numberOfLines={2}
              />
              <Tooltip text={srpPriceToolTipText} height={isMobile ? null : 40}>
                <Image
                  style={{
                    width: 15,
                    height: 15,
                  }}
                  source={require("static/assets/help.png")}
                />
              </Tooltip>
            </View>
          ) : null}
          <View style={tw(`flex ${isMobile ? "flex-col" : "flex-row"} mb-2`)}>
            <FormField
              containerStyle={{ width: 150 }}
              name={standalone ? "price" : `${path}.price`}
              iconType={"material-community"}
              iconSize={14}
              iconName={"currency-usd"}
              currency={currency}
              inputStyle={{
                fontSize: 13,
                maxWidth: 100,
              }}
              inputContainerStyle={{
                padding: 6,
              }}
              leftIconContainerStyle={{
                paddingRight: 0,
              }}
              label={"Price"}
              labelStyle={[
                fonts.heading4,
                { fontWeight: "100", marginBottom: 4 },
              ]}
              editable={true}
              disabled={false}
              isPrice={true}
            />
            <View>
              {!isAddon && isPriceRuleActive && adjustedPrice ? (
                <>
                  <Text
                    style={{ ...fonts.link2, marginLeft: 5 }}
                    onPress={() => dispatch(setSideCar("catalogSettings"))}
                  >
                    {Localise(messages, "Adjusted Price")}
                  </Text>
                  <Input
                    disabled={true}
                    leftIconContainerStyle={{
                      paddingRight: 0,
                    }}
                    leftIcon={
                      <Icon
                        name={"currency-usd"}
                        type={"material-community"}
                        size={14}
                        iconStyle={{ color: "rgba(170, 170, 170, 1)" }}
                      />
                    }
                    rightIcon={
                      <Text
                        style={[
                          fonts.default,
                          { color: "rgba(170, 170, 170, 1)" },
                        ]}
                      >{`${currency} `}</Text>
                    }
                    containerStyle={{ width: 150, marginTop: 5 }}
                    inputContainerStyle={{ height: 35, padding: 6 }}
                    inputStyle={{ height: 20 }}
                    value={finalAdjustedPrice}
                  />
                </>
              ) : null}
            </View>
          </View>
          <View style={tw(`flex ${isMobile ? "flex-col" : "flex-row"}`)}>
            <View style={tw("flex flex-col")}>
              <View style={tw("flex flex-row")}>
                <LabelField
                  text={"Compare at Price"}
                  style={{
                    ...fonts.heading4,
                    fontWeight: "100",
                    marginBottom: 4,
                    marginHorizontal: 7,
                  }}
                />
                <Tooltip
                  text={compareAtPriceToolTipText}
                  height={isMobile ? null : 40}
                >
                  <Image
                    style={{
                      width: 15,
                      height: 15,
                    }}
                    source={require("static/assets/help.png")}
                  />
                </Tooltip>
              </View>
              <FormField
                containerStyle={{ width: 150 }}
                name={standalone ? "compareAtPrice" : `${path}.compareAtPrice`}
                iconType={"material-community"}
                iconSize={14}
                iconName={"currency-usd"}
                currency={currency}
                inputStyle={{
                  fontSize: 13,
                  maxWidth: 100,
                }}
                inputContainerStyle={{
                  padding: 6,
                }}
                leftIconContainerStyle={{
                  paddingRight: 0,
                }}
                editable={true}
                disabled={false}
                isPrice={true}
              />
            </View>
            <View>
              {!isAddon && isPriceRuleActive && adjustedPrice ? (
                <>
                  <Text
                    style={{ ...fonts.link2, marginLeft: 5 }}
                    onPress={() => dispatch(setSideCar("catalogSettings"))}
                  >
                    {Localise(messages, "Adjusted Compare at Price")}
                  </Text>
                  <Input
                    disabled={true}
                    leftIconContainerStyle={{
                      paddingRight: 0,
                    }}
                    leftIcon={
                      <Icon
                        name={"currency-usd"}
                        type={"material-community"}
                        size={14}
                        iconStyle={{ color: "rgba(170, 170, 170, 1)" }}
                      />
                    }
                    rightIcon={
                      <Text
                        style={[
                          fonts.default,
                          { color: "rgba(170, 170, 170, 1)" },
                        ]}
                      >{`${currency} `}</Text>
                    }
                    containerStyle={{ width: 150, marginTop: 9 }}
                    inputContainerStyle={{ height: 35, padding: 6 }}
                    inputStyle={{ height: 20 }}
                    value={finalAdjustedCompareAtPrice}
                  />
                </>
              ) : null}
            </View>
          </View>
        </View>

        <View
          style={[
            tw(`flex flex-col ${isMobile ? "pl-1" : "pl-3"}`),
            { maxWidth: 400 },
          ]}
        >
          <LabelField text={"Photo"} style={{ fontSize: 13 }} />
          <View
            style={tw(
              `flex ${
                !isTablet ? "flex-row items-center flex-wrap" : "flex-col"
              }`
            )}
          >
            <View style={tw("mr-3 mb-2")}>
              <ImageModal
                style={{
                  width: 160,
                  height: 160,
                  borderWidth: 1,
                  borderColor: colors.light,
                }}
                resizeMode={"contain"}
                showImageModal={!!imageSrc}
                uri={productImage}
              />
            </View>

            <View style={tw("flex flex-col")}>
              {!isMOLProduct && (
                <>
                  <View style={tw("flex flex-row")}>
                    <FileSelector
                      testID={`product_image`}
                      accessibilityLabel="save"
                      disabled={isMOLProduct || !price}
                      image={productImage}
                      buttonStyle={{ paddingVertical: 7 }}
                      containerStyle={{ margin: 0, marginRight: 5 }}
                      onSelect={(uri) =>
                        standalone && variants.length === 0
                          ? setFieldValue(`image`, uri)
                          : setFieldValue(`${path}.image`, uri)
                      }
                    />
                    {image ? (
                      <Tooltip
                        text={Localise(messages, "Delete")}
                        renderForWebOnly={true}
                      >
                        <Image
                          onPress={() =>
                            standalone && variants?.length === 0
                              ? setFieldValue(`image`, "")
                              : setFieldValue(`${path}.image`, "")
                          }
                          style={{
                            width: 25,
                            height: 25,
                            marginLeft: 5,
                            marginTop: 5,
                          }}
                          source={require("static/assets/trash-can.png")}
                        />
                      </Tooltip>
                    ) : null}
                  </View>
                  <View
                    style={[
                      tw("flex flex-row flex-wrap"),
                      { width: isMobile ? 150 : 200, marginTop: 10 },
                    ]}
                  >
                    <LabelField
                      text={"Recommended Square Image size is 1024px x 1024px"}
                      style={fonts.style2}
                      numberOfLines={2}
                    />
                  </View>
                </>
              )}
              {errors?.image?.includes("Image not found") ||
              (isMOLProduct &&
                variants[index] &&
                touched?.variants &&
                isArray(errors?.variants) &&
                errors?.variants[index]?.image) ? (
                <Text
                  style={[theme.Picker.errorStyle, { marginHorizontal: 5 }]}
                >
                  Please
                  <Text
                    style={[
                      theme.Picker.errorStyle,
                      { textDecorationLine: "underline" },
                    ]}
                    onPress={() => {
                      navigation.dispatch(
                        CommonActions.navigate({
                          name: "feedback",
                        })
                      );
                    }}
                  >
                    {" "}
                    contact support
                  </Text>{" "}
                  to upload the missing photo
                </Text>
              ) : null}
              {!isMOLProduct &&
              variants[index] &&
              touched?.variants &&
              isArray(errors?.variants) &&
              errors?.variants[index] ? (
                <Text
                  testID={"custom_formik_error"}
                  style={[theme.Picker.errorStyle, { marginHorizontal: 5 }]}
                >
                  {Localise(messages, errors.variants[index].image)}
                </Text>
              ) : null}
            </View>
          </View>
        </View>
      </View>
      {productType !== "addon" ? (
        <View
          style={tw(
            `flex ${isMobile ? "flex-col" : "flex-row flex-wrap"}  m-3 mt-0`
          )}
        >
          <ProductExtraFields
            data={flowers}
            index={index}
            selectedData={selectedFlowersType}
            dataType="flowerType"
          />
          <ProductExtraFields
            data={productColors}
            index={index}
            selectedData={selectedColorsType}
            dataType="productColor"
          />
          <ProductRecipes index={index} />
        </View>
      ) : null}
    </View>
  );
};

export const ProductIDError = ({ style, error, onRestore }) => {
  const { Localise, messages } = React.useContext(I18NContext);

  const createProductError = Localise(messages, error);
  const restoreKeyWord = Localise(messages, "restore");
  const restoreKeyWordIndex = createProductError.indexOf(restoreKeyWord);

  return createProductError ? (
    <View
      style={[tw("flex flex-row flex-wrap items-baseline pb-2 px-1"), style]}
    >
      <Text>
        <Text style={theme.Picker.errorStyle}>
          {createProductError.substring(
            0,
            restoreKeyWordIndex < 0 ? undefined : restoreKeyWordIndex
          )}
        </Text>
        {restoreKeyWordIndex >= 0 ? (
          <>
            <Text
              style={{
                textDecorationLine: "underline",
                ...fonts.heading5,
              }}
              onPress={onRestore}
            >
              {restoreKeyWord}
            </Text>
            <Text style={theme.Picker.errorStyle}>
              {createProductError.substring(
                restoreKeyWordIndex + restoreKeyWord.length
              )}
            </Text>
          </>
        ) : null}
      </Text>
    </View>
  ) : null;
};

const ProductExtraFields = ({ data, index, dataType, selectedData = [] }) => {
  const { isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);
  const dispatch = useDispatch();

  const {
    values: { standalone, group, variants },
    setFieldValue,
  } = useFormikContext();
  const path = `variants.${index}`;
  const filterPath =
    dataType === "flowerType" ? `customFlowerType` : `customProductColor`;

  const customInputValue =
    variants.length > 0 ? variants[index][filterPath] : "";

  const toolTipText = Localise(
    messages,
    `Add the ${
      dataType === "flowerType" ? "Flower Type" : "Color"
    } tags using the custom input field or dropdown list`
  );

  const isFilterExists =
    selectedData.findIndex(
      (val) => val.toLowerCase() === customInputValue?.toLowerCase().trim()
    ) > -1;

  return (
    <View style={[tw("flex flex-col")]}>
      <FieldArray
        name={standalone ? dataType : `${path}.${dataType}`}
        render={(arrayHelpers) => {
          const remainingTypes = data?.filter(
            (type) =>
              !selectedData.filter(
                (val) => val.toLowerCase().trim() === type.toLowerCase().trim()
              ).length > 0
          );
          return (
            <>
              <View
                style={{
                  paddingHorizontal: 5,
                }}
              >
                {group !== "mol" || selectedData.length > 0 ? (
                  <>
                    <View style={tw("flex flex-row items-center")}>
                      <LabelField
                        text={
                          dataType === "flowerType" ? "Flower Type" : "Color"
                        }
                        style={{
                          ...fonts.heading4,
                          marginTop: 10,
                          marginBottom: 7,
                          marginRight: 8,
                        }}
                        numberOfLines={2}
                      />
                      {group !== "mol" && (
                        <Tooltip
                          text={toolTipText}
                          height={isMobile ? null : 40}
                        >
                          <Image
                            style={{
                              width: 15,
                              height: 15,
                            }}
                            source={require("static/assets/help.png")}
                          />
                        </Tooltip>
                      )}
                    </View>
                    {group !== "mol" ? (
                      <View style={tw("flex flex-row items-center ")}>
                        <FormField
                          name={
                            dataType === "flowerType"
                              ? `${path}.customFlowerType`
                              : `${path}.customProductColor`
                          }
                          placeholder={`Add a custom ${
                            dataType === "flowerType" ? "Flower Type" : "Color"
                          }`}
                          containerStyle={{
                            width: 180,
                            height: "auto",
                            paddingHorizontal: 0,
                          }}
                          labelStyle={fonts.heading4}
                          isUpdateOnChange={true}
                          editable={true}
                        />
                        {customInputValue && !isFilterExists ? (
                          <TouchableOpacity
                            onPress={() => {
                              if (customInputValue !== "" && !isFilterExists) {
                                arrayHelpers.push(customInputValue);
                                setFieldValue(
                                  `variants.${index}.${filterPath}`,
                                  ""
                                );
                              }
                              dispatch(
                                setProductExtraFilters({
                                  [dataType]: customInputValue,
                                })
                              );
                            }}
                            testID={"Remove Flower Type"}
                            style={tw("pl-1 mb-3")}
                          >
                            <Tooltip
                              text={Localise(messages, "Remove")}
                              renderForWebOnly={true}
                            >
                              <Image
                                style={{
                                  width: 17,
                                  height: 17,
                                  transform: [{ rotate: "45deg" }],
                                }}
                                resizeMode="cover"
                                source={IMAGES["close"]}
                              />
                            </Tooltip>
                          </TouchableOpacity>
                        ) : null}
                      </View>
                    ) : null}
                  </>
                ) : null}
                <View>
                  {group !== "mol" && remainingTypes.length > 0 ? (
                    <Picker
                      placeholder={{
                        label:
                          dataType === "flowerType"
                            ? "Select Flower Type"
                            : "Select Color",
                      }}
                      containerStyle={{
                        width: 180,
                        height: "auto",
                        paddingHorizontal: 0,
                        paddingBottom: 5,
                      }}
                      items={orderBy(
                        remainingTypes.map((e, index) => ({
                          label: e,
                          value: e,
                          key: `${e}-${index}`,
                        })),
                        "label",
                        "asc"
                      )}
                      name={"newTypes"}
                      value={""}
                      onValueChange={(val) => {
                        if (val) arrayHelpers.push(val);
                      }}
                    />
                  ) : null}
                </View>
                {selectedData
                  ? selectedData.map((type, index) => {
                      const listTypes =
                        group === "mol" ? type : data.filter((e) => type === e);
                      return (
                        <View
                          style={tw(
                            `flex flex-col ${group !== "mol" ? "mb-2" : ""}`
                          )}
                          key={index}
                        >
                          <View style={tw("flex flex-row items-center")}>
                            <View
                              style={{
                                width: isMobile ? "60%" : 180,
                                maxWidth: 400,
                                padding: 6,
                                paddingLeft: 3,
                              }}
                            >
                              <Text
                                style={{
                                  ...fonts.heading4,
                                  fontWeight: "100",
                                }}
                              >
                                {listTypes.length > 0 ? listTypes : type}
                              </Text>
                            </View>
                            {group !== "mol" ? (
                              <TouchableOpacity
                                onPress={() => arrayHelpers.remove(index)}
                                testID={"Remove Flower Type"}
                                style={tw("pl-1")}
                              >
                                <Tooltip
                                  text={Localise(messages, "Remove")}
                                  renderForWebOnly={true}
                                >
                                  <Image
                                    style={{ width: 17, height: 17 }}
                                    resizeMode="cover"
                                    source={IMAGES["close"]}
                                  />
                                </Tooltip>
                              </TouchableOpacity>
                            ) : null}
                          </View>
                        </View>
                      );
                    })
                  : null}
              </View>
            </>
          );
        }}
      />
    </View>
  );
};

const ProductRecipes = ({ index }) => {
  const { isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);
  const {
    values: {
      standalone,
      group,
      variants,
      price: masterPrice,
      recipe: masterRecipe = [],
      name: productName,
      productId: masterProductId,
      image: masterImage = "",
    },
    setFieldValue,
  } = useFormikContext();
  const {
    productId: variantProductId,
    price: variantPrice,
    image: variantImage,
    variantType,
  } = variants[index] || {};
  const productId = standalone ? masterProductId : variantProductId;
  const recipe = standalone ? masterRecipe : variants[index].recipe;
  const image = standalone ? masterImage : variantImage;
  const price = standalone ? masterPrice : variantPrice;
  const isMOLProduct = group === "mol";

  const recipeList = recipe.length > 0 ? recipe : [];

  const [recipeData, setRecipeData] = useState(recipeList);

  const setValue = (val, itemIndex, field) => {
    const updatedData = recipeData.map((recipe, i) => {
      if (i === itemIndex) {
        return {
          ...recipe,
          [field]: val,
        };
      } else return recipe;
    });
    setRecipeData(updatedData);
    setFieldValue(`variants.${index}.recipe`, updatedData);
  };

  const printRecipeDetails = {
    productFirstChoiceDescription: `${productName} ${
      standalone ? "" : `(${variantType})`
    }`,
    productFirstChoiceCode: productId,
    recipeList: [
      {
        quantity: "Qty",
        item: "Item",
        css: "font-bold",
      },
    ].concat(recipeList),
    formattedPrice: Number(price),
    img: image,
  };

  return (
    <View style={tw("ml-2")}>
      <View style={tw("flex flex-col")}>
        <View style={tw("flex flex-row items-center justify-start")}>
          <LabelField
            text={"Recipe"}
            style={{
              ...fonts.heading4,
              marginTop: 10,
              marginBottom: 5,
              marginRight: 8,
            }}
            numberOfLines={2}
          />
          {recipe?.length > 0 ? (
            <View style={{ width: isMobile ? 60 : 95 }}>
              <PrintIframeRenderer testID={"product-page"} />
              <TouchableOpacity
                activeOpacity={0.5}
                onPress={() =>
                  PrintIframeAction(
                    getRecipeInfo(printRecipeDetails, Localise, messages)
                  )
                }
                style={{ marginLeft: 5 }}
                testID="print"
                accessibilityLabel="print"
              >
                <Tooltip
                  text={Localise(messages, "Print Recipe")}
                  renderForWebOnly={true}
                >
                  <Image
                    style={{
                      width: 26,
                      height: 26,
                    }}
                    resizeMode="contain"
                    source={IMAGES["print"]}
                  />
                </Tooltip>
              </TouchableOpacity>
            </View>
          ) : null}
        </View>
        {recipeData.length === 0 ? (
          <View
            style={[
              tw(`flex flex-row items-center justify-start`),
              { width: 250 },
            ]}
          >
            {!isMOLProduct && (
              <TouchableOpacity testID={"addRecipe"} style={tw("pl-1 mb-1")}>
                <Text
                  style={fonts.link1}
                  onPress={() => {
                    const updatedList = recipeData.concat({
                      quantity: "",
                      item: "",
                    });
                    setRecipeData(updatedList);
                  }}
                >
                  {`[+] Add Recipe`}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        ) : null}
      </View>

      {recipeData.length > 0 ? (
        <View
          style={[
            {
              borderColor: colors.light,
              borderWidth: isMOLProduct ? 0 : 1,
              maxWidth: 400,
              marginTop: 2,
            },
          ]}
        >
          <View style={tw("flex flex-row mx-5")}>
            <View style={tw("flex flex-col")}>
              {recipeData.length > 0
                ? recipeData.map((recipe, index) => {
                    return (
                      <View
                        key={index}
                        style={tw("flex flex-row items-start justify-between")}
                      >
                        <View>
                          {index == 0 && (
                            <LabelField
                              text={"Qty"}
                              style={{
                                ...fonts.heading4,
                                marginTop: 10,
                                marginBottom: 7,
                              }}
                              numberOfLines={2}
                            />
                          )}
                          {isMOLProduct ? (
                            <LabelField
                              text={`${recipe.quantity}`}
                              style={{
                                ...fonts.heading4,
                                fontWeight: "100",
                                marginTop: 10,
                                marginBottom: 5,
                                marginRight: 8,
                              }}
                              numberOfLines={2}
                            />
                          ) : (
                            <Input
                              name="quantity"
                              containerStyle={{
                                width: isMobile ? 45 : 75,
                                paddingLeft: 0,
                              }}
                              inputContainerStyle={{
                                paddingVertical: 10,
                              }}
                              placeholder={"Qty"}
                              disabled={isMOLProduct}
                              leftIconContainerStyle={{ paddingRight: 0 }}
                              onChange={(e) => {
                                setValue(
                                  Platform.OS === "web"
                                    ? e.currentTarget.value
                                    : e.nativeEvent.text,
                                  index,
                                  "quantity"
                                );
                              }}
                              value={recipe.quantity}
                            />
                          )}
                        </View>
                        <View style={{ width: isMobile ? 200 : 300 }}>
                          {index == 0 && (
                            <LabelField
                              text={"Item"}
                              style={{
                                ...fonts.heading4,
                                marginTop: 10,
                                marginBottom: 7,
                                marginLeft: isMOLProduct ? 20 : 0,
                              }}
                              numberOfLines={2}
                            />
                          )}
                          {isMOLProduct ? (
                            <LabelField
                              text={`${recipe.item}`}
                              style={{
                                ...fonts.heading4,
                                fontWeight: "100",
                                marginTop: 10,
                                marginBottom: 5,
                                marginRight: 8,
                                marginLeft: 20,
                              }}
                              numberOfLines={3}
                            />
                          ) : (
                            <View style={tw("flex flex-row items-center")}>
                              <Input
                                name="item"
                                containerStyle={{
                                  width: isMobile ? 160 : 250,
                                  paddingLeft: 0,
                                }}
                                inputContainerStyle={{
                                  paddingVertical: 10,
                                }}
                                placeholder={"Item"}
                                disabled={isMOLProduct}
                                leftIconContainerStyle={{ paddingRight: 0 }}
                                onChange={(e) => {
                                  setValue(
                                    Platform.OS === "web"
                                      ? e.currentTarget.value
                                      : e.nativeEvent.text,
                                    index,
                                    "item"
                                  );
                                }}
                                value={recipe.item}
                              />
                              <TouchableOpacity
                                onPress={() => {
                                  const updatedData = recipeData.filter(
                                    (_item, itemIndex) => itemIndex !== index
                                  );
                                  setRecipeData(updatedData);
                                  setFieldValue(
                                    `variants.${index}.recipe`,
                                    updatedData
                                  );
                                }}
                                testID={"removeRecipe"}
                                style={tw("pl-1 mb-3")}
                              >
                                <Image
                                  style={{
                                    width: 17,
                                    height: 17,
                                  }}
                                  resizeMode="cover"
                                  source={IMAGES["close"]}
                                />
                              </TouchableOpacity>
                            </View>
                          )}
                        </View>
                      </View>
                    );
                  })
                : null}
            </View>
          </View>
          {recipeData.length > 0 ? (
            <View
              style={[
                tw(`flex flex-row items-center justify-start m-5`),
                { width: 250 },
              ]}
            >
              {!isMOLProduct && (
                <TouchableOpacity testID={"addRecipe"} style={tw("pl-1 mb-1")}>
                  <Text
                    style={fonts.link1}
                    onPress={() => {
                      const updatedList = recipeData.concat({
                        quantity: "",
                        item: "",
                      });
                      setRecipeData(updatedList);
                    }}
                  >
                    {`[+] Add More`}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          ) : null}
        </View>
      ) : null}
    </View>
  );
};
