import React, { useRef } from "react";
import { Platform, Text } from "react-native";

import isArray from "lodash/isArray";
import get from "lodash/get";

import { useFormikContext } from "formik";
import { theme } from "styles/theme";

const richTextEditior = ["description"];

const findElementID = (errorKey, errorValue) => {
  const objIndex = errorValue.findIndex((val) => typeof val === "object");
  const objKey = get(Object.keys(errorValue[objIndex]), "0", "");
  return `"${errorKey}.${objIndex}.${objKey}"`;
};

const ScrollToError = ({ focusError = "", screenRef = useRef() }) => {
  const { errors = {}, isSubmitting = false } = useFormikContext();
  const errorKeys = Object.keys(errors);

  if (Platform.OS === "web") {
    if (isSubmitting && errorKeys.length) {
      const errorKey = get(errorKeys, "0", "");
      const errorValue = errors[errorKey];
      const elementID = isArray(errorValue)
        ? findElementID(errorKey, errorValue)
        : richTextEditior.includes(errorKey)
        ? "RichTextEditor"
        : errorKey;

      const element =
        document.querySelector(`input[data-testid=${elementID}]`) ||
        document.querySelector(`select[name=${elementID}]`) ||
        document.querySelector(`div[datacomponent=${elementID}]`) ||
        document.querySelector(`div[data-testid=custom_formik_error]`);

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
        element.focus();
      }
    }

    return null;
  } else {
    isSubmitting &&
      errorKeys.length &&
      screenRef.current?.scrollTo({
        y: 0,
        animated: true,
      });
    return errorKeys.length ? (
      <Text style={theme.Picker.errorStyle}>{focusError}</Text>
    ) : null;
  }
};

export default ScrollToError;
