import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ScrollView, View, Platform, TouchableOpacity } from "react-native";
import {
  Button,
  CheckBox,
  Text,
  Badge,
  Image,
  Divider,
} from "react-native-elements";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";

import I18NContext from "library/contexts/i18N";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import UserProfile from "library/storage/userProfile";
import { Tooltip } from "components/elements";
import IMAGES from "static/assets/images";
import { fonts, theme } from "styles/theme";
import tw from "tailwind-rn";
import { Entitlements } from "library/utils/entitlements";
import { selectCurrentPage } from "library/sagas/ongoing/global-data/selector";

const Shops = ({
  icon,
  text,
  selectors: { selectValue },
  actions: { setAction },
}) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;

  const dispatch = useDispatch();
  const selectedShops = useSelector(selectValue);
  const [currentShops, setShops] = useState(selectedShops);

  const shopsMenuRef = React.useRef();
  const shops = UserProfile.getAllShopNames();

  const currentPage = useSelector(selectCurrentPage);

  const { permissions } = React.useContext(AppSettingsContext);

  let entitlementKeyword = "";
  if (currentPage === "customer-directory") {
    entitlementKeyword = Entitlements.CUSTOMER_DIRECTORY;
  } else if (currentPage === "events") {
    entitlementKeyword = Entitlements.CREATE_EVENT;
  } else {
    entitlementKeyword = Entitlements.CREATE_ORDER;
  }

  const shopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (entitlementKeyword in permissions[memberCode])
      shopCodes.push(memberCode);
  });

  const options = shopCodes.map((code) => ({
    label: `${code} ${shops[code]}`,
    value: code,
  }));

  // We need to define Hooks at the top level of your React function, before any early returns.
  useEffect(() => {
    setShops(selectedShops);
  }, [selectedShops]);

  if (options.length < 2) return null;

  const onClose = () => shopsMenuRef?.current?.close();
  const onCancel = () => {
    setShops(selectedShops);
    onClose();
  };
  const onReset = () => dispatch(setAction({ type: "shops", value: [] }));
  const onSelect = (entry) => {
    const finding = currentShops.find((e) => e.value === entry.value);
    const result = finding
      ? currentShops.filter((e) => e.value !== entry.value)
      : currentShops.concat([entry]);
    setShops([...result]);
    return false;
  };
  const onApply = () => {
    dispatch(setAction({ type: "shops", value: currentShops }));
    onClose();
  };

  return (
    <Menu ref={shopsMenuRef} onClose={onCancel}>
      <MenuTrigger testID={text} accessibilityLabel={text}>
        <View style={tw("flex-row justify-around items-center")}>
          <Tooltip text={Localise(messages, "Select Shop")} renderForWebOnly = {true}>
            <Image
              style={{ width: 20, height: 20 }}
              resizeMode="cover"
              source={IMAGES[icon]}
            />
          </Tooltip>
          {!isSmallScreen && text && (
            <Text style={{ paddingLeft: 2 }}>{text}</Text>
          )}
          {selectedShops.length > 0 && (
            <Badge
              containerStyle={[
                {
                  right: isDesktop ? -18 : -10,
                  ...tw("mb-4"),
                  position: "absolute",
                },
                Platform.OS !== "web" && { top: -10 },
              ]}
              badgeStyle={{ backgroundColor: "#e5175e" }}
              value={selectedShops.length}
            />
          )}
        </View>
      </MenuTrigger>
      <MenuOptions
        optionsContainerStyle={{
          width: isDesktop ? 350 : "100%",
          padding: 5,
          maxHeight: "80%",
          marginTop: 20,
        }}
        customStyles={{
          optionsWrapper: {
            flex: 1,
          },
          optionWrapper: {
            padding: 5,
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          },
        }}>
        <View style={{ flex: 1 }}>
          <ScrollView
            style={{
              flexGrow: 0,
              maxHeight: isDesktop ? "80%" : "50%",
            }}>
            {currentShops.length > 0 && (
              <MenuOption onSelect={() => onReset()}>
                <View style={tw("flex flex-col w-full")}>
                  <Text style={{ ...fonts.heading4, marginVertical: 3 }}>
                    {Localise(messages, "Clear")} {Localise(messages, text)}
                  </Text>
                  <Divider />
                </View>
              </MenuOption>
            )}
            <MenuOption>
              <View style={tw("flex flex-col w-full")}>
                <Text
                  style={{
                    ...fonts.heading4,
                    marginVertical: 3,
                  }}>
                  {Localise(messages, "Shop Codes")}
                </Text>
                <Divider />
              </View>
            </MenuOption>
            {options.map((option, index) => {
              const isSelected = currentShops.find(
                (e) => e.value === option.value,
              );
              return (
                <MenuOption key={index}>
                  <CheckBoxFilter
                    option={option}
                    selected={!!isSelected}
                    onPress={onSelect}
                  />
                </MenuOption>
              );
            })}
          </ScrollView>
          <View style={tw("flex flex-row items-baseline justify-between m-2")}>
            <View>
              <Text style={[fonts.link1, tw("m-2")]} onPress={() => onCancel()}>
                {Localise(messages, "Close")}
              </Text>
            </View>
            <Button
              title={Localise(messages, "Apply")}
              onPress={() => onApply()}
              testID={"Apply"}
              accessibilityLabel={"Apply"}
            />
          </View>
        </View>
      </MenuOptions>
    </Menu>
  );
};

const CheckBoxFilter = ({ option: { label, value }, selected, onPress }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  return (
    <TouchableOpacity
      style={tw("flex flex-row items-center")}
      onPress={() => onPress({ label, value })}
    >
      <CheckBox
        containerStyle={[
          theme.CheckBox.inputContainerStyle,
          { paddingRight: 3 },
        ]}
        size={18}
        checked={selected}
        testID={label}
        accessibilityLabel={label}
        onPress={() => onPress({ label, value })}
      />
      <Text onPress={() => onPress({ label, value })}>
        {Localise(messages, label)}
      </Text>
    </TouchableOpacity>
  );
};

export default Shops;
