import * as Yup from "yup";
import moment from "moment";
import get from "lodash/get";
import {
  convertToTimeZone,
  cutOffTimeInterval,
} from "library/utils/deliveryService";
import { roundUpTime } from "./helper";

export const getValidationSchema = (
  Localise,
  messages,
  skipList = [],
  includeList = [],
  customErrorMessages = {}
) => {
  Yup.addMethod(Yup.string, "invalidTime", function (type, errorMessages) {
    return this.test(type, errorMessages, function () {
      const [timeInPastError] = errorMessages;
      const { path, createError, parent } = this;
      const { deliveryDate, pickUpDateTime } = parent;
      const floristTimeZone = get(this.from[1], "value.floristTimeZone");
      const deliveryProvider = get(
        this.from[1],
        "value.deliveryProvider"
      )?.toLowerCase();

      // For doordash, the text Standard/Express needs to be truncated
      const truncatedPickupTime =
        deliveryProvider === "doordash"
          ? `${deliveryDate}T${pickUpDateTime.split(" ")[0]}`
          : pickUpDateTime;
      const isPickupDateTimeField = path.includes("pickUpDateTime");
      let isSameDay = moment(deliveryDate).isSame(
        convertToTimeZone(moment.utc().format(), floristTimeZone),
        "day"
      );
      if (isSameDay) {
        const dsBufferTime = moment(
          roundUpTime(
            moment(
              convertToTimeZone(
                moment
                  .utc()
                  .add(cutOffTimeInterval[deliveryProvider], "minutes")
                  .format(),
                floristTimeZone
              )
            ).toDate()
          )
        ).format("YYYY-MM-DD HH:mm:ss");

        if (isPickupDateTimeField) {
          const pickupDateTime = moment(truncatedPickupTime);
          if (
            type === "past-date-time" &&
            pickupDateTime.isBefore(dsBufferTime)
          ) {
            return createError({ path, message: timeInPastError });
          }
        }
      }
      return true;
    });
  });

  Yup.addMethod(Yup.string, "pastDate", function (type, errorMessages) {
    return this.test(type, errorMessages, function () {
      const [dateInPastError] = errorMessages;
      const { path, createError, parent } = this;
      const { deliveryDate } = parent;
      const floristTimeZone = get(this.from[1], "value.floristTimeZone");
      let isPastDay = moment(deliveryDate).isBefore(
        convertToTimeZone(moment.utc().format(), floristTimeZone),
        "day"
      );
      if (isPastDay) {
        if (type === "past-date") {
          return createError({ path, message: dateInPastError });
        }
      }
      return true;
    });
  });

  return Yup.object().shape({
    shopField: Yup.string().required(Localise(messages, "Select a Shop")),
    referenceNumber: Yup.string()
      .required(Localise(messages, "Enter Order Number"))
      .matches(
        new RegExp(/^[a-zA-Z0-9]/),
        Localise(messages, "Start with an letter or number")
      )
      .matches(
        new RegExp(/^[a-zA-Z0-9\-_]*$/),
        Localise(
          messages,
          "Letters, numbers, hyphen (-), underscore (_) characters allowed"
        )
      )
      .max(
        100,
        Localise(messages, "Order number cannot exceed 100 characters")
      ),
    deliveryDetails: Yup.object().shape({
      deliveryDate: Yup.string()
        .required(Localise(messages, "Select Delivery Date"))
        .pastDate(includeList.includes("pastDeliveryDate") ? "past-date" : "", [
          Localise(messages, "Delivery date is past"),
        ]),
      ...(!skipList.includes("pickUpDateTime") && {
        pickUpDateTime: Yup.string()
          .required(Localise(messages, "Select Pickup Time"))
          .invalidTime("past-date-time", [
            Localise(messages, "Choose next available pickup time slot"),
          ]),
      }),
      specialDeliveryInstructions: Yup.string().max(
        500,
        Localise(
          messages,
          "Special Delivery Instructions cannot exceed 500 characters"
        )
      ),
      ...(!skipList.includes("undeliverableAction") && {
        undeliverableAction: Yup.string().required(
          Localise(messages, "Please select Undeliverable Action")
        ),
      }),
    }),
    items: Yup.array().of(
      Yup.object().shape({
        totalItemValue: Yup.string()
          .required(Localise(messages, "Enter Item(s) value"))
          .matches(/^\d+\.?\d*$/, Localise(messages, "Invalid Price")),
        itemDescription: Yup.string()
          .required(Localise(messages, "Enter Item Description"))
          .min(
            2,
            Localise(
              messages,
              "Item description must have at least 2 characters"
            )
          )
          .max(
            200,
            Localise(
              messages,
              "Item description must not exceed 200 characters"
            )
          ),
      })
    ),
    recipientDetails: Yup.object().when("shopField", {
      is: (value) => !!value,
      then: Yup.object().shape({
        firstName: Yup.string()
          .required(Localise(messages, "Enter Recipient First Name"))
          .min(
            2,
            Localise(
              messages,
              "Recipient First Name must have at least 2 characters"
            )
          )
          .max(
            100,
            Localise(
              messages,
              "Recipient First Name must not exceed 100 characters"
            )
          )
          .matches(
            new RegExp(/^[a-zA-Z0-9\s\-_.]*$/),
            Localise(messages, "Enter valid First Name")
          ),
        lastName: Yup.string()
          .required(Localise(messages, "Enter Recipient Last Name"))
          .min(
            2,
            Localise(
              messages,
              "Recipient Last Name must have at least 2 characters"
            )
          )
          .max(
            100,
            Localise(
              messages,
              "Recipient Last Name must not exceed 100 characters"
            )
          )
          .matches(
            new RegExp(/^[a-zA-Z0-9\s\-_.]*$/),
            Localise(messages, "Enter valid Last Name")
          ),
        phone: Yup.string()
          .required(
            Localise(
              messages,
              customErrorMessages["phone"] || "Enter Recipient Phone"
            )
          )
          .matches(
            new RegExp(
              "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
            ),
            Localise(
              messages,
              customErrorMessages["invalidPhone"] || "Enter valid Phone Number"
            )
          ),
        addressLine1: Yup.string()
          .required(
            Localise(
              messages,
              customErrorMessages["addressLine1"] || "Enter Street Address"
            )
          )
          .min(
            2,
            Localise(messages, "Street Address must have at least 2 characters")
          )
          .max(
            200,
            Localise(messages, "Street Address must not exceed 200 characters")
          ),
        ...(!skipList.includes("addressLine2") && {
          addressLine2: Yup.string()
            .min(
              2,
              Localise(
                messages,
                "Apt/Suite/Unit must must have at least 2 characters"
              )
            )
            .max(
              200,
              Localise(
                messages,
                "Apt/Suite/Unit must not exceed 200 characters"
              )
            ),
        }),
        city: Yup.string()
          .required(
            Localise(messages, customErrorMessages["city"] || "Enter City name")
          )
          .min(
            2,
            Localise(messages, "City name must have at least 2 characters")
          )
          .max(
            200,
            Localise(messages, "City name must not exceed 200 characters")
          ),
        state: Yup.string().required(
          Localise(
            messages,
            customErrorMessages["recipientState"] || "State required"
          )
        ),
        country: Yup.string()
          .required(
            Localise(
              messages,
              customErrorMessages["recipientCountry"] || "Country required"
            )
          )
          .oneOf(
            ["US", "USA"],
            customErrorMessages["nonUSRecipientCountry"] ||
              "Country must be United States"
          ),
        zipcode: Yup.string()
          .label("zip")
          .when("country", {
            is: (value) => value && value === "US",
            then: Yup.string()
              .required(
                Localise(
                  messages,
                  customErrorMessages["recipientZipCode"] ||
                    "Postal Code required"
                )
              )
              .matches(
                new RegExp("^[0-9]{5}$"),
                Localise(messages, "Invalid Postal Code")
              ),
            otherwise: Yup.string().when("country", {
              is: (value) => value && value === "CA",
              then: Yup.string()
                .required(Localise(messages, "Postal Code required"))
                .matches(
                  new RegExp("^[A-Za-z]\\d[A-Za-z][ -]?(\\d[A-Za-z]\\d)?\\s*$"),
                  Localise(messages, "Invalid Postal Code")
                ),
              otherwise: Yup.string().required(
                Localise(messages, "Postal Code required")
              ),
            }),
          }),
        ...(!skipList.includes("recipientLocationType") && {
          locationType: Yup.string().required("Select Location Type"),
        }),
        locationName: Yup.string()
          .label("locationName")
          .when("locationType", {
            is: (locationType) =>
              !["residence", undefined].includes(locationType),
            then: Yup.string()
              .required(
                Localise(
                  messages,
                  customErrorMessages["locationName"] || "Enter Location Name"
                )
              )
              .min(
                3,
                Localise(
                  messages,
                  "Location Name must have at least 3 characters"
                )
              )
              .max(
                200,
                Localise(
                  messages,
                  "Location Name must not exceed 200 characters"
                )
              ),
          }),
      }),
    }),
    ...(includeList.includes("fillerDetails") && {
      fillerDetails: Yup.object().shape({
        phone: Yup.string()
          .required(
            Localise(
              messages,
              customErrorMessages["fillerPhone"] || "Phone number required"
            )
          )
          .matches(
            new RegExp(
              "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
            ),
            Localise(
              messages,
              customErrorMessages["invalidFillerPhone"] ||
                "Enter valid Phone Number"
            )
          ),
        city: Yup.string().required(
          Localise(
            messages,
            customErrorMessages["fillerCity"] || "Enter City name"
          )
        ),
        state: Yup.string().required(
          Localise(
            messages,
            customErrorMessages["fillerState"] || "State required"
          )
        ),
        country: Yup.string()
          .required(
            Localise(
              messages,
              customErrorMessages["fillerCountry"] || "Country required"
            )
          )
          .oneOf(
            ["US", "USA"],
            customErrorMessages["nonUSFillerCountry"] ||
              "Country must be United States"
          ),
        zipcode: Yup.string()
          .label("zip")
          .when("country", {
            is: (value) => value && value === "US",
            then: Yup.string()
              .required(
                Localise(
                  messages,
                  customErrorMessages["recipientZipCode"] ||
                    "Postal Code required"
                )
              )
              .matches(
                new RegExp("^[0-9]{5}$"),
                Localise(messages, "Invalid Postal Code")
              ),
            otherwise: Yup.string().when("country", {
              is: (value) => value && value === "CA",
              then: Yup.string()
                .required(Localise(messages, "Postal Code required"))
                .matches(
                  new RegExp("^[A-Za-z]\\d[A-Za-z][ -]?(\\d[A-Za-z]\\d)?\\s*$"),
                  Localise(messages, "Invalid Postal Code")
                ),
              otherwise: Yup.string().required(
                Localise(messages, "Postal Code required")
              ),
            }),
          }),
      }),
    }),
  });
};
