import { combineReducers } from "redux";
import { SLICE_NAME as sliceName } from "./constants";

import common from "./common/slice";
import deliveryPickup from "./delivery-pickup/slice";
import deliveryCutoff from "./delivery-cutoff-times/slice";
import addons from "./addons/slice";

export default {
  [sliceName]: combineReducers({
    ...common,
    ...deliveryPickup,
    ...deliveryCutoff,
    ...addons,
  }),
};
