import React from "react";
import { View } from "react-native";
import Svg, { Image } from "react-native-svg";
import tw from "tailwind-rn";

const LogoImage = ({
  img,
  isRight,
  isLogo,
  isSmallScreen = false,
  logoPlacement,
}) => {
  const classes = `flex ${isRight ? `flex-row-reverse` : `flex-row`} ${
    !isLogo ? `justify-center` : ``
  }`;

  if (!img) return null;

  let width = 0;
  let height = 0;

  if (!isSmallScreen) {
    width = isLogo ? 130 : 380;
    height = isLogo ? 130 : 120;
  } else {
    width = isLogo ? 90 : 263;
    height = isLogo ? 90 : 83;
  }

  // 130 ~ 90
  //   const width = isLogo ? 90 : isSmallScreen ? 220 : 263;
  //   const height = isLogo ? 90 : isSmallScreen ? 70 : 83;

  return (
    <View testID="logoImg" style={[tw(`${classes}`), { zIndex: -1 }]}>
      <Svg width={width} height={height}>
        <Image href={img} width={width} height={height} />
      </Svg>
    </View>
  );
};

export default LogoImage;
