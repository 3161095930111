import { put, call, select } from "redux-saga/effects";
import get from "lodash/get";
import moment from "moment";

import { selectShopCode } from "../common/selector";
import { setApiResponse, setApiError } from "./slice";

import { request } from "library/utils/request";

export function* handleFetchDeliveryCutoffTimes() {
  const serviceRequest = (params) =>
    request("get-delivery-cutoff-times", params);
  const shopCode = yield select(selectShopCode);

  try {
    const response = yield call(serviceRequest, { shopCode });
    const content = processDeliveryCutoffTimesResponse(response);
    yield put(
      setApiResponse({
        path: "deliveryCutoffTimes",
        content,
      })
    );
  } catch (error) {
    yield put(
      setApiError({
        path: "deliveryCutoffTimes",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleSaveDeliveryCutoffTimes(action = {}) {
  const {
    resolve,
    reject,
    params: { deliveryCutoffTimes },
  } = get(action, "payload", {});
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (params) =>
    request("update-delivery-cutoff-times", params);
  const updateParams = processDeliveryCutoffTimePayload(
    deliveryCutoffTimes,
    shopCode
  );

  try {
    yield call(serviceRequest, updateParams);
    yield call(handleFetchDeliveryCutoffTimes);
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        path: "deliveryCutoffTimes",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

const processDeliveryCutoffTimesResponse = (response = {}) => {
  const {
    monCutoffTime,
    tueCutoffTime,
    wedCutoffTime,
    thuCutoffTime,
    friCutoffTime,
    satCutoffTime,
    sunCutoffTime,
  } = response;

  const getTimeFormat = (time, defaultTime = "13:00") =>
    moment(time || defaultTime, "HH:mm").format("YYYY-MM-DDTHH:mm:ss");

  return {
    deliveryCutoffTimes: {
      monday: getTimeFormat(monCutoffTime),
      tuesday: getTimeFormat(tueCutoffTime),
      wednesday: getTimeFormat(wedCutoffTime),
      thursday: getTimeFormat(thuCutoffTime),
      friday: getTimeFormat(friCutoffTime),
      saturday: getTimeFormat(satCutoffTime, "10:00"),
      sunday: getTimeFormat(sunCutoffTime, "10:00"),
    },
  };
};

const processDeliveryCutoffTimePayload = (
  deliveryCutoffTimes = {},
  shopCode
) => {
  const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } =
    deliveryCutoffTimes;

  const getTimeFormat = (time) => {
    // eslint-disable-next-line no-unused-vars
    const [date, hours, minutes, seconds] = time?.split(/[T:]/);
    return `${hours}:${minutes}`;
  };

  return {
    monCutoffTime: getTimeFormat(monday),
    tueCutoffTime: getTimeFormat(tuesday),
    wedCutoffTime: getTimeFormat(wednesday),
    thuCutoffTime: getTimeFormat(thursday),
    friCutoffTime: getTimeFormat(friday),
    satCutoffTime: getTimeFormat(saturday),
    sunCutoffTime: getTimeFormat(sunday),
    shopCode,
  };
};
