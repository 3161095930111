import * as Yup from "yup";

export const PrinterSettingsKeys = [
  "legal_size_invoice_print_settings",
  "trifold_card_print_settings",
  "order_details_print_settings",
  "order_details_outgoing_print_settings",
  "pickup_manifest_print_settings",
  "designer_worksheet_print_settings",
  "order_messages_incoming_print_settings",
  "order_messages_outgoing_print_settings",
];

export const nonStandardEligiblePrinterSettings = [
  "order_details_print_settings",
  "order_details_outgoing_print_settings",
  "pickup_manifest_print_settings",
  "order_messages_incoming_print_settings",
  "order_messages_outgoing_print_settings",
];

export const UIConfig = {
  invoices: {
    title: "Legal Size Invoice",
    id: "legal_size_invoice_print_settings",
  },
  trifold: {
    title: "Tri-Fold Card",
    id: "trifold_card_print_settings",
  },
  orderDetails: {
    title: "Orders - Incoming",
    id: "order_details_print_settings",
  },
  orderDetailsOutgoing: {
    title: "Orders - Outgoing",
    id: "order_details_outgoing_print_settings",
  },
  designerWorkSheet: {
    title: "Designer Worksheet",
    id: "designer_worksheet_print_settings",
  },
  pickupManifest: {
    title: "Pickup Manifest",
    id: "pickup_manifest_print_settings",
  },
  messagesIncoming: {
    title: "Messages - Incoming",
    id: "order_messages_incoming_print_settings",
  },
  messagesOutgoing: {
    title: "Messages - Outgoing",
    id: "order_messages_outgoing_print_settings",
  },
};

export const getInitialValues = (shopSettings) => {
  return {
    legal_size_invoice_print_settings:
      shopSettings["legal_size_invoice_print_settings"] || [],
    trifold_card_print_settings:
      shopSettings["trifold_card_print_settings"] || [],
    order_details_print_settings:
      shopSettings["order_details_print_settings"] || [],
    order_details_outgoing_print_settings:
      shopSettings["order_details_outgoing_print_settings"] || [],
    designer_worksheet_print_settings:
      shopSettings["designer_worksheet_print_settings"] || [],
    pickup_manifest_print_settings:
      shopSettings["pickup_manifest_print_settings"] || [],
    order_messages_incoming_print_settings:
      shopSettings["order_messages_incoming_print_settings"] || [],
    order_messages_outgoing_print_settings:
      shopSettings["order_messages_outgoing_print_settings"] || [],
  };
};

export const getValidationSchema = (Localise, messages) => {
  const printSettingsValidationSchema = Yup.object().shape({
    printer: Yup.string().required(Localise(messages, "Please select printer")),
    // tray: Yup.string().when("printer", {
    //   is: (value) => value !== "",
    //   then: Yup.string().required(
    //     Localise(messages, "Please select paper tray")
    //   ),
    // }),
  });

  return {
    legal_size_invoice_print_settings: printSettingsValidationSchema,
    trifold_card_print_settings: printSettingsValidationSchema,
    order_details_print_settings: printSettingsValidationSchema,
    order_details_outgoing_print_settings: printSettingsValidationSchema,
    designer_worksheet_print_settings: printSettingsValidationSchema,
    pickup_manifest_print_settings: printSettingsValidationSchema,
    order_messages_incoming_print_settings: printSettingsValidationSchema,
    order_messages_outgoing_print_settings: printSettingsValidationSchema,
  };
};
