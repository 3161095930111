import React from "react";
import {
  SafeAreaView,
  View,
  Text,
  TouchableOpacity,
  Platform,
} from "react-native";
import { styles } from "./styles";
import { useSelector } from "react-redux";
import {
  selectCurrentPage,
  selectDashboardShopCode,
} from "library/sagas/ongoing/global-data/selector";
import Logger from "library/utils/logger";
import UserProfileContext from "library/contexts/userProfile";
import get from "lodash/get";

const FallBackWebComponent = ({ error, resetErrorBoundary }) => {
  const currentPage = useSelector(selectCurrentPage);

  const { proxyUser } = React.useContext(UserProfileContext);

  const proxyUserAccount =
    get(proxyUser, "shopGroups.0") ||
    get(proxyUser, "memberCodes.0") ||
    "Florist";

  const dashboardSelectedShopCode = useSelector(selectDashboardShopCode);

  const errorMessage = `Error occured in: ${Platform.OS},  Account Name: ${proxyUserAccount} Shopcode: ${dashboardSelectedShopCode} Screen Name: ${currentPage}`;
  console.log("Error message", errorMessage);
  Logger.error(errorMessage);
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.title}>Oops!</Text>
        <Text style={styles.subtitle}>{"Something went wrong"}</Text>
        <View style={styles.bottomView} />
        <TouchableOpacity style={styles.button} onPress={resetErrorBoundary}>
          <Text style={styles.buttonText}>Try again</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

export default FallBackWebComponent;
