/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import { Text } from "react-native-elements";
import {
  ActivityIndicator,
  View,
  TouchableOpacity,
  Platform,
  Image,
} from "react-native";
import isEmpty from "lodash/isEmpty";
import IMAGES from "static/assets/images";

import { CustomModal } from "components/elements";
import useStateIfMounted from "library/utils/useStateIfMounted";

import { useDispatch } from "react-redux";
import tw from "tailwind-rn";

import { fonts, colors, theme } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import { actionMap } from "./helper";
import {
  setShowRecipe,
  setOrderDetailsFromScan,
} from "library/sagas/ongoing/order-details/slice";
import { setActiveTab } from "library/sagas/ongoing/current-orders/slice";
import { setEditOrder } from "library/sagas/views/home/drawer/create-order/slice";

const QRScanModal = ({
  modalVisible,
  setModalVisibile,
  upComingStatuses,
  setFieldValue,
  showRePrint,
  orderDetails,
  setSelectedAction,
  triggerAction,
}) => {
  const dispatch = useDispatch();

  const { Localise, messages } = React.useContext(I18NContext);
  const [qrScanMenuConfig, setQrScanMenuConfig] = useStateIfMounted({});

  const { isPrinted = false, status = "", paymentStatus = "" } = orderDetails;

  const unPaidOrder =
    paymentStatus?.toLowerCase() === "pending" && status !== "CANCELLED";

  // update the menu options
  useEffect(() => {
    getMoveToNextConfig();
  }, [upComingStatuses]);

  const getMoveToNextConfig = () => {
    const moveToNextConfig =
      (!isEmpty(upComingStatuses) &&
        upComingStatuses
          .map((each) => {
            return {
              [each.name]: {
                buttonText: each.displayText,
                testId: `${each.name}`,
              },
            };
          })
          .reduce((acc, obj) => ({ ...acc, ...obj }))) ||
      {};

    const finalQrMenuConfig = {
      ...(unPaidOrder && {
        unpaid: {
          buttonText: "Take Payment",
          testId: "pay_later_order_payment_link",
        },
      }),
      ...moveToNextConfig,
      showrecipe: {
        buttonText: "Show Recipe",
        testId: "show_recipe",
      },
      ...(showRePrint && {
        showreprint: {
          buttonText: isPrinted ? "Reprint" : "Print",
          testId: "re_print",
        },
      }),
      vieworder: {
        buttonText: "View Order",
        testId: "view_order",
      },
    };

    setQrScanMenuConfig(finalQrMenuConfig);
  };

  const onPressHandler = (item) => {
    console.log("item - onPressHandler :>> ", item);
    if (["design", "designed", "delivery", "completed"].includes(item)) {
      setFieldValue("actionItem", actionMap[item]);
    } else if (item === "showrecipe") {
      dispatch(setShowRecipe(true));
      dispatch(setActiveTab("order-summary"));
    } else if (item === "showreprint") {
      setSelectedAction("print");
    } else if (unPaidOrder && item === "unpaid") {
      dispatch(setEditOrder());
      triggerAction({
        action: "edit-and-lock-order",
      });
    }
    dispatch(setOrderDetailsFromScan(false));
    setModalVisibile(false);
  };

  const modalContent = {
    content: (
      <>
        {isEmpty(qrScanMenuConfig) ? (
          <View testID="loading_view">
            <Text
              style={{
                ...fonts.heading2,
                ...tw("text-white"),
              }}
              testID="loading_text"
            >
              {Localise(messages, "Loading Actions")}
            </Text>
            <ActivityIndicator
              style={{ marginLeft: 10 }}
              color={colors.activityIndicator}
            />
          </View>
        ) : (
          <View testID="qr_scan_action_modal">
            <View style={tw("flex flex-row items-center justify-between pb-3")}>
              <Text
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  color: colors.highlighter,
                  textAlign: "center",
                }}
              >
                {Localise(messages, "What would you like to do?")}
              </Text>
              <TouchableOpacity
                onPress={() => {
                  dispatch(setOrderDetailsFromScan(false));
                  setModalVisibile(false);
                }}
                style={{ paddingLeft: 10 }}
                testID="qr_scan_action_popup_close"
              >
                <Image
                  style={{ width: 20, height: 20 }}
                  resizeMode="cover"
                  source={IMAGES["close"]}
                />
              </TouchableOpacity>
            </View>
            <View style={{ padding: 10 }}>
              {Object.keys(qrScanMenuConfig).map((item, index) => {
                const { buttonText, testId } = qrScanMenuConfig[item] || {};
                return (
                  <TouchableOpacity
                    onPress={() => onPressHandler(item)}
                    style={{
                      ...theme.Button.secondaryButtonStyle,
                      margin: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      borderColor: colors.grayScaleLight,
                    }}
                    testID={testId}
                    accessibilityLabel={testId}
                    key={testId}
                  >
                    <>
                      <Text
                        style={{
                          ...theme.Button.secondaryTitleStyle,
                          color: colors.highlighter,
                          fontSize: 12,
                        }}
                      >
                        {Localise(messages, buttonText)}
                      </Text>
                    </>
                  </TouchableOpacity>
                );
              })}
            </View>
          </View>
        )}
      </>
    ),
  };

  return (
    <>
      <CustomModal
        modalVisible={modalVisible}
        modalContent={modalContent}
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: "white" },
        ]}
        modalStyle={
          Platform.OS !== "web"
            ? {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#00000070",
                color: "#FFFFFF",
              }
            : {
                borderColor: "transparent",
                padding: 0,
              }
        }
      ></CustomModal>
    </>
  );
};

export default QRScanModal;
