import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import {
  View,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Text, Button } from "react-native-elements";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import { FormFieldDatePicker } from "components/elements/forms";
import { ToasterHandler } from "components/elements";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import {
  deleteSuspension,
  saveSuspension,
} from "library/sagas/views/home/drawer/mercury-online/slice";

import { DeleteButtonNameMap, IndicatorMapping } from "./ui-config";
import { fonts, theme, colors, backgroundColors } from "styles/theme";
import styles from "./styles";
import tw from "tailwind-rn";

const SuspensionItem = ({ item, index }) => {
  const dispatch = useDispatch();
  const { isDesktop, isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const {
    errors = {},
    setFieldTouched,
    dirty,
    touched,
    resetForm,
  } = useFormikContext();
  const isValidItem = isEmpty(errors) && dirty;

  const isPastSuspension = item.type === "past";
  const rowBackground = isPastSuspension ? backgroundColors.greyColor : "";
  const deleteButtonName = DeleteButtonNameMap[item.type];
  const rootPath = `${
    isPastSuspension ? "pastSuspensions" : "activeSuspensions"
  }.${index}`;
  const zIndex = 100;

  const onDelete = (deleteAction) => {
    if (deleteAction === "Cancel") {
      return resetForm();
    }
    setDeleteLoading(true);
    dispatch(
      deleteSuspension({
        id: item.id,
        resolve: () => {
          ToasterHandler("done", Localise(messages, "Suspension deleted"));
          setDeleteLoading(false);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(messages, "Unable to delete suspension, please try again.")
          );

          setDeleteLoading(false);
        },
      })
    );
  };

  const onSave = () => {
    if (!isValidItem) return;

    setSaveLoading(true);
    dispatch(
      saveSuspension({
        item,
        resolve: () => {
          setSaveLoading(false);
          ToasterHandler(
            "done",
            Localise(
              messages,
              `${item.id ? "Suspension updated" : "Suspension created"}`
            )
          );
        },
        reject: () => {
          setSaveLoading(false);
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              `Unable to ${
                item.id ? "update" : "create"
              } suspension – please try again.`
            )
          );
        },
      })
    );
  };

  const DateField = ({ name, disabled, endDatePath }) => {
    const errorName = get(errors, name, "");
    const showError = get(touched, name, "") && errorName;

    return (
      <>
        <FormFieldDatePicker
          dateFormat={Platform.OS === "web" ? "MM/dd/yy" : "MM/DD/YY"}
          dateValueFormat="YYYY-MM-DD"
          name={name}
          placeholder={Localise(messages, "Start Date")}
          iconName="calendar"
          alignIconRight={true}
          disabled={disabled}
          errorStyle={{ display: "none" }}
          containerStyle={{
            width: Platform.OS === "web" && isDesktop ? 115 : 130,
            height: showError ? "auto" : 50,
          }}
          onValueChange={() => {
            endDatePath && setFieldTouched(endDatePath);
          }}
          popperPlacement={"bottom"}
        />
        {!!showError && (
          <Text style={styles.dateFieldsError}>
            {Localise(messages, errorName)}
          </Text>
        )}
      </>
    );
  };

  const SuspensionLabel = ({ type }) => (
    <View
      style={[
        {
          height: 45,
          width: 80,
        },
        tw("flex-row items-center"),
        styles.labelContainer,
      ]}
    >
      <Text
        style={[
          {
            textTransform: "capitalize",
            ...fonts.heading5,
          },
          Platform.OS === "web" && { wordBreak: "break-all" },
        ]}
      >
        {Localise(messages, type)}
      </Text>
    </View>
  );

  const SuspensionDates = ({ path, disabled }) => (
    <>
      <View
        style={[
          tw(
            `flex flex-row justify-between items-${
              Platform.OS !== "web" ? "start" : "baseline"
            } pl-3 py-2`
          ),
          styles.datesSection,
          { zIndex: zIndex + 4 },
        ]}
      >
        <Text style={{ ...fonts.heading5, width: 30 }}>
          {Localise(messages, "Start")}
        </Text>
        <View style={tw("flex flex-1 flex-row flex-wrap")}>
          <View style={{ zIndex: zIndex + 8 }}>
            <DateField
              name={`${path}.startDate`}
              disabled={disabled}
              endDatePath={`${path}.endDate`}
            />
          </View>
        </View>
      </View>

      <View
        style={[
          tw(
            `flex flex-row justify-between items-${
              Platform.OS !== "web" ? "start" : "baseline"
            } pl-3 py-2`
          ),
          { zIndex: zIndex + 3 },
        ]}
      >
        <Text style={{ ...fonts.heading5, width: 30 }}>
          {Localise(messages, "End")}
        </Text>
        <View style={tw("flex flex-1 flex-row flex-wrap")}>
          <View style={{ zIndex: zIndex + 6 }}>
            <DateField name={`${path}.endDate`} disabled={disabled} />
          </View>
        </View>
      </View>
    </>
  );

  const SuspensionActions = ({ showSave }) => (
    <View
      style={[
        tw("flex-row justify-end items-center"),
        {
          height: 45,
          minWidth: 100,
        },
      ]}
    >
      {deleteLoading ? (
        <ActivityIndicator
          style={{ marginRight: 25 }}
          color={colors.activityIndicator}
        />
      ) : (
        <View style={{ marginHorizontal: 10, justifyContent: "center" }}>
          <TouchableOpacity
            onPress={(e) => onDelete(deleteButtonName)}
            testID={deleteButtonName}
            accessibilityLabel={deleteButtonName}
          >
            <View>
              <Text style={fonts.link1}>
                {Localise(messages, deleteButtonName)}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      )}
      {showSave && (
        <Button
          testID={"save"}
          accessibilityLabel="save"
          titleStyle={{
            ...theme.Button.secondaryTitleStyle,
            ...fonts.heading5,
          }}
          buttonStyle={{
            ...theme.Button.secondaryButtonStyle,
            paddingTop: 8,
            paddingBottom: 6,
            height: 30,
            borderColor: !isValidItem && colors.secondaryButton,
          }}
          containerStyle={{ marginLeft: 0 }}
          title={Localise(messages, "Save")}
          onPress={onSave}
          disabled={!isValidItem}
          loading={saveLoading}
          loadingProps={{
            color: theme.Button.secondaryTitleStyle.color,
          }}
        />
      )}
    </View>
  );

  return (
    <View
      style={[
        {
          backgroundColor: rowBackground,
          zIndex: zIndex - parseInt(index),
        },
        tw("flex justify-between"),
        styles.container,
      ]}
    >
      {!isMobile ? (
        <View
          style={{
            ...tw("flex-row w-full justify-between items-center"),
            borderLeftWidth: 10,
            borderColor: colors[IndicatorMapping[item.type]],
          }}
        >
          <SuspensionLabel type={item.type} />
          <View
            style={[tw("flex-row flex-1 flex-wrap"), { zIndex: zIndex + 2 }]}
          >
            <SuspensionDates disabled={isPastSuspension} path={rootPath} />
          </View>
          <View
            style={[tw("flex-row justify-between"), { zIndex: zIndex + 1 }]}
          >
            <SuspensionActions showSave={!isPastSuspension} />
          </View>
        </View>
      ) : (
        <>
          <View style={tw("flex flex-row w-full justify-between")}>
            <View
              style={{
                borderLeftWidth: 10,
                borderColor: colors[IndicatorMapping[item.type]],
              }}
            >
              <SuspensionLabel type={item.type} />
            </View>
            <SuspensionActions showSave={!isPastSuspension} />
          </View>
          <View style={{ ...styles.datesContainer, ...tw("pl-0") }}>
            <SuspensionDates disabled={isPastSuspension} path={rootPath} />
          </View>
        </>
      )}
    </View>
  );
};

export default SuspensionItem;
