import * as Yup from "yup";

export const getValidationSchema = (Localise, messages) =>
  Yup.object().shape({
    stopName: Yup.string()
      .required(Localise(messages, "Enter Stop Name"))
      .min(3, Localise(messages, "Stop Name must have at least 3 characters"))
      .max(200, Localise(messages, "Stop Name must not exceed 200 characters")),
    stopAddress: Yup.object().shape({
      addressLine1: Yup.string()
        .required(Localise(messages, "Enter Street Address"))
        .min(
          2,
          Localise(messages, "Street Address must have at least 2 characters")
        )
        .max(
          200,
          Localise(messages, "Street Address must not exceed 200 characters")
        ),
      suite: Yup.string()
        .min(
          2,
          Localise(
            messages,
            "Apt/Suite/Unit must must have at least 2 characters"
          )
        )
        .max(
          200,
          Localise(messages, "Apt/Suite/Unit must not exceed 200 characters")
        ),
      city: Yup.string()
        .required(Localise(messages, "Enter City name"))
        .min(2, Localise(messages, "City name must have at least 2 characters"))
        .max(
          200,
          Localise(messages, "City name must not exceed 200 characters")
        ),
      state: Yup.string().when("country", {
        is: (value) => (value && value === "US") || value === "CA",
        then: Yup.string()
          .required(Localise(messages, "State required"))
          .matches(
            new RegExp("^[a-zA-Z]{2}$"),
            Localise(messages, "Invalid State")
          ),
      }),
      zip: Yup.string()
        .label("zip")
        .when("country", {
          is: (value) => value && value === "US",
          then: Yup.string()
            .required(Localise(messages, "Postal Code required"))
            .matches(
              new RegExp("^[0-9]{5}$"),
              Localise(messages, "Invalid Postal Code")
            ),
          otherwise: Yup.string().when("country", {
            is: (value) => value && value === "CA",
            then: Yup.string()
              .required(Localise(messages, "Postal Code required"))
              .matches(
                new RegExp("^[A-Za-z]\\d[A-Za-z][ -]?(\\d[A-Za-z]\\d)?\\s*$"),
                Localise(messages, "Invalid Postal Code")
              ),
            otherwise: Yup.string().required(
              Localise(messages, "Postal Code required")
            ),
          }),
        }),
      country: Yup.string().required("Country required"),
    }),
    stopDescription: Yup.string()
      .required(Localise(messages, "Enter Description"))
      .min(3, Localise(messages, "Description must have at least 3 characters"))
      .max(
        200,
        Localise(messages, "Description must not exceed 200 characters")
      ),
  });
