import React from "react";
import { View } from "react-native";
import { Image } from "react-native-elements";
import { IMAGES } from "static/assets/images";
import { colors } from "styles/theme";
import tw from "tailwind-rn";

const ImageField = ({
  image,
  source,
  bgColor,
  children,
  style = {},
  innerStyle = {},
  containerStyle = {},
}) => {
  const imageProps = {
    width: 160,
    height: 160,
    ...style,
  };

  return (
    <View
      style={[
        tw("flex flex-row justify-center items-center"),
        {
          ...imageProps,
          ...(bgColor && { borderColor: bgColor }),
          backgroundColor: colors.secondary,
          borderWidth: image ? 0 : 1,
          ...containerStyle,
        },
      ]}
    >
      {source ? (
        <Image style={imageProps} resizeMode="contain" source={source} />
      ) : image ? (
        <Image
          style={imageProps}
          resizeMode="contain"
          source={{ uri: image }}
        />
      ) : (
        <Image
          style={{
            ...imageProps,
            width: 100,
            height: 100,
            ...innerStyle,
          }}
          source={IMAGES["quick-pick"]}
        />
      )}
      {children}
    </View>
  );
};

export default ImageField;
