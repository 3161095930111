export const getUIConfig = (Localise, messages) => [
  {
    category: "Navigation",
    component: "brandSelectorConfig",
    assetId: "json",
    title: Localise(messages, "Brand Selector"),
    fieldType: "BrandSelector",
    fieldProps: {
      dimensions: "50x50",
      link: "/",
    },
  },
  {
    category: "Navigation",
    component: "mainNavigation",
    assetId: "json",
    title: Localise(messages, "Main Menu"),
    fieldType: "Navigation",
    fieldProps: {
      menuDepth: 3,
      link: "/",
      showCollectionsField: true,
    },
  },
  {
    category: "Navigation",
    component: "footerNavigation",
    assetId: "json",
    title: Localise(messages, "Footer Menu"),
    fieldType: "Navigation",
    fieldProps: {
      menuDepth: 1,
      link: "/",
    },
  },
  {
    category: "Theme Settings",
    component: "homepageBannerMetadata",
    assetId: "json",
    title: Localise(messages, "Homepage Banner"),
    fieldType: "BannerMetaData",
    fieldProps: {
      dimensions: "1920x1280",
      link: "/",
    },
  },
  {
    category: "Theme Settings",
    component: "homepageSEO",
    assetId: "json",
    title: Localise(messages, "Homepage SEO"),
    fieldType: "SEOText",
    fieldProps: {
      link: "/",
    },
  },
  {
    category: "Theme Settings",
    component: "announcementBannerText",
    assetId: "json",
    title: Localise(messages, "Announcement Banner"),
    fieldType: "AnnouncementBanner",
    fieldProps: {
      link: "/",
    },
  },
  {
    category: "Theme Settings",
    component: "shopperApprovedConfig",
    assetId: "json",
    title: Localise(messages, "Shopper Approved Reviews"),
    fieldType: "ShopperApprovedReviews",
    fieldProps: {
      link: "/pages/reviews",
      title: "Reviews",
      ignoreHeading: true,
      ftdAdminOnly: true,
    },
  },
  {
    category: "Theme Settings",
    component: "abTesting",
    assetId: "json",
    title: Localise(messages, "A/B Testing"),
    fieldType: "ABTesting",
    fieldProps: {
      link: "/",
      ignoreHeading: true,
      ftdAdminOnly: true,
    },
  },
  {
    category: "Theme Settings",
    component: "languages",
    assetId: "json",
    title: Localise(messages, "Languages"),
    fieldType: "Languages",
    fieldProps: {
      link: "/",
      ignoreHeading: true,
      ftdAdminOnly: true,
    },
  },
  {
    category: "Theme Settings",
    component: "designLayout",
    title: Localise(messages, "Design & Layout"),
    fieldType: "Link",
    uri: "/admin/themes",
  },
  {
    category: "Theme Settings",
    component: "checkoutCustomizations",
    assetId: "json",
    title: Localise(messages, "Checkout"),
    fieldType: "CheckoutCustomizations",
    fieldProps: {
      link: "/",
      ignoreHeading: true,
    },
  },
  {
    category: "Content Pages",
    component: "FuneralSympathy",
    assetId: "json",
    title: Localise(messages, "Funeral & Sympathy Landing pages"),
    fieldType: "funeralSympathy",
    fieldProps: {
      link: "/pages/funeral-sympathy",
    },
  },
  {
    category: "Content Pages",
    component: "wedding",
    assetId: "json",
    title: Localise(messages, "Wedding Landing pages"),
    fieldType: "wedding",
    fieldProps: {
      link: "/pages/wedding",
    },
  },
  {
    category: "Content Pages",
    component: "about-us",
    assetId: "html",
    title: Localise(messages, "About Us"),
    fieldType: "HTMLText",
    fieldProps: {
      link: "/pages/about-us",
    },
  },
  {
    category: "Content Pages",
    component: "contact",
    assetId: "html",
    title: Localise(messages, "Contact"),
    fieldType: "HTMLText",
    fieldProps: {
      link: "/pages/contact",
    },
  },
  {
    category: "Content Pages",
    component: "our-guarantee",
    assetId: "html",
    title: Localise(messages, "Our Guarantee"),
    fieldType: "HTMLText",
    fieldProps: {
      link: "/pages/our-guarantee",
    },
  },
  {
    category: "Content Pages",
    component: "privacy-policy",
    assetId: "html",
    title: Localise(messages, "Privacy Policy"),
    fieldType: "HTMLText",
    fieldProps: {
      link: "/pages/privacy-policy",
    },
  },
  {
    category: "Content Pages",
    component: "refund-policy",
    assetId: "html",
    title: Localise(messages, "Refund Policy"),
    fieldType: "HTMLText",
    fieldProps: {
      link: "/pages/refund-policy",
    },
  },
  {
    category: "Content Pages",
    component: "substitutionPolicy",
    assetId: "html",
    title: Localise(messages, "Substitution Policy"),
    fieldType: "HTMLText",
    fieldProps: {
      link: "",
    },
  },
  {
    category: "Content Pages",
    component: "terms-of-service",
    assetId: "html",
    title: Localise(messages, "Terms Of Service"),
    fieldType: "HTMLText",
    fieldProps: {
      link: "/pages/terms-of-service",
    },
  },
  {
    category: "Content Pages",
    component: "others",
    title: Localise(messages, "Other Pages"),
    fieldType: "Link",
    uri: "/admin/pages",
  },
  {
    category: "Blog Posts",
    component: "blogPosts",
    title: Localise(messages, "Articles"),
    fieldType: "Link",
    uri: "/admin/articles",
  },
];
