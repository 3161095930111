import React, { useEffect } from "react";
import {
  View,
  Platform,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { Button, CheckBox } from "react-native-elements";
import { useDispatch } from "react-redux";

import tw from "tailwind-rn";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

import { theme, fonts, colors, backgroundColors } from "styles/theme";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileStorage from "library/storage/userProfile";
import Environment from "library/utils/environment";
import IMAGES from "static/assets/images";
import { request } from "library/utils/request";
import { printPickupManifest } from "library/utils/deliveryService";
import { setActions } from "library/sagas/views/home/drawer/delivery-tasks/slice";

//Custom components
import ListItem from "./list-item";
import { Accordion, PrintIframeRenderer } from "components/elements";

//service requests
const getPickupManifestPrintStatus = (params) =>
  request("get-pickup-manifest-print-status", params);

// eslint-disable-next-line react/display-name
export const DeliveryTaskList = React.memo(
  ({
    data,
    config,
    columnsList,
    subColumnsList,
    actionsList,
    subActionsList,
    recordId,
    setRecordId,
    setShopCode,
    isSmallScreen,
    isMobile,
    filterActions,
    initViewMoreCounts,
    loading,
    getData,
    listingType,
    isCustomRangeApplied = false,
  }) => {
    const { isDesktop } = React.useContext(DeviceContext);
    const { messages, Localise } = React.useContext(I18NContext);

    const [searchFiltered, setSearchFiltered] = useStateIfMounted(false);
    const [viewMoreCounts, setViewMoreCounts] =
      useStateIfMounted(initViewMoreCounts);
    const [pickupManifestPrintStatus, setPickupManifestPrintStatus] =
      useStateIfMounted({});

    const isQuickFiltersVisible = Environment.get(
      "SHOW_DELIVERY_QUICK_FILTERS",
      false
    );

    const uiConfig = isQuickFiltersVisible
      ? config
      : config.filter((section) => section.key !== "approachingDrivers");

    useEffect(() => {
      const searchCategories = [];

      !isEmpty(filterActions?.search) && searchCategories.push("Search Text");
      if (searchCategories.length) setSearchFiltered(true);
      else setSearchFiltered(false);
    }, [filterActions]);

    useEffect(() => {
      if (data["approachingDrivers"]?.list.length && isQuickFiltersVisible) {
        let print = {};
        data["approachingDrivers"]?.list?.map((pickupDriverBatch, index) => {
          const { shipments = [], reqParams } = pickupDriverBatch;
          const { siteId: shopCode } = shipments[0];

          //getting print status for all ApproachingDrivers
          getPickupManifestPrintStatus({
            shopCode,
            ...reqParams,
          }).then((printStatusResp) => {
            if (printStatusResp) {
              const { printed: isPickupManifestPrinted = false } =
                printStatusResp;
              print[reqParams.printKindId] = isPickupManifestPrinted;
              setPickupManifestPrintStatus({
                ...print,
                [reqParams.printKindId]: isPickupManifestPrinted,
              });
            }
          });
        });
      }
    }, [data]);

    if (isEmpty(data)) {
      return (
        <View
          style={{
            flex: 1,
            marginTop: Platform.OS === "web" ? 50 : 20,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <>
              <Image
                style={{
                  width: Platform.OS === "web" ? 400 : 250,
                  height: Platform.OS === "web" ? 400 : 250,
                }}
                resizeMode="cover"
                source={IMAGES["preparing-order-vase"]}
              />
              <Text
                style={{
                  width: 200,
                  textAlign: "center",
                  fontSize: 20,
                  fontWeight: "400",
                }}
              >
                {Localise(messages, "Buttercup is prepping your orders")}
              </Text>
            </>
          ) : (
            <Text
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "400",
                marginTop: 50,
              }}
            >
              {Localise(
                messages,
                "This request is taking too long to respond. Please"
              )}{" "}
              <Text
                style={{ textDecorationLine: "underline" }}
                onPress={() => {
                  getData();
                }}
              >
                {Localise(messages, "refresh")}
              </Text>{" "}
              {Localise(messages, "the page.")}
            </Text>
          )}
        </View>
      );
    }

    return (
      <View style={{ marginBottom: isCustomRangeApplied ? 0 : 10 }}>
        <View
          style={{
            ...(isDesktop && Platform.OS === "web" && { minHeight: "80vh" }),
          }}
        >
          {uiConfig.map((section) => {
            const { key, title, displayStatus = "close" } = section;

            if (!data[key]) return null;
            const records = data[key].list;
            const openAccordion = searchFiltered
              ? searchFiltered
              : displayStatus === "open";

            return (
              <Accordion
                key={key}
                title={`${Localise(messages, title)} (${records.length})`}
                titleStyle={{
                  ...fonts.heading3,
                  fontWeight: "600",
                  color: colors.primary,
                }}
                labelStyle={{
                  marginVertical: 5,
                  backgroundColor: backgroundColors.secondary,
                  paddingHorizontal: 0,
                }}
                iconStyle={{
                  color: colors.primary,
                }}
                defaultOpen={records.length > 0 ? openAccordion : false}
                handleOnPress={() => {}}
                testID={title}
              >
                {title === "Approaching Drivers" ? (
                  records.length > 0 ? (
                    <ApproachingDrivers
                      driversList={records}
                      pickupManifestPrintStatus={pickupManifestPrintStatus}
                      isSmallScreen={isSmallScreen}
                      Localise={Localise}
                      messages={messages}
                      filterActions={filterActions}
                      getData={getData}
                    />
                  ) : null
                ) : (
                  <>
                    {records
                      ?.slice(0, viewMoreCounts[key])
                      .map((task, index) => (
                        <React.Fragment key={index}>
                          <DeliveryListItem
                            task={cloneDeep(task)}
                            index={index}
                            columnsList={columnsList}
                            subColumnsList={subColumnsList}
                            actionsList={actionsList}
                            subActionsList={subActionsList}
                            selectedTaskId={recordId}
                            setRecordId={setRecordId}
                            setShopCode={setShopCode}
                            isSmallScreen={isSmallScreen}
                            isMobile={isMobile}
                            listingType={listingType}
                          />
                        </React.Fragment>
                      ))}
                    {records.length > 10 &&
                      records.length > viewMoreCounts[key] && (
                        <Button
                          titleStyle={theme.Button.secondaryTitleStyle}
                          buttonStyle={{
                            ...theme.Button.secondaryButtonStyle,
                            paddingHorizontal: 10,
                            paddingVertical: 6,
                            width: 95,
                            height: 35,
                          }}
                          containerStyle={{
                            alignItems: "flex-end",
                            marginHorizontal: 0,
                          }}
                          title={Localise(messages, "View More")}
                          onPress={() => {
                            setViewMoreCounts({
                              ...viewMoreCounts,
                              [key]: viewMoreCounts[key] + 10,
                            });
                          }}
                          testID="view_more"
                          accessibilityLabel="view_more"
                        />
                      )}
                  </>
                )}
              </Accordion>
            );
          })}
        </View>
      </View>
    );
  }
);

/* eslint-disable react/display-name */
const ApproachingDrivers = React.memo(
  ({
    driversList,
    pickupManifestPrintStatus,
    isSmallScreen,
    Localise,
    messages,
    filterActions,
    getData,
  }) => {
    const [displayScroll, setDisplayScroll] = useStateIfMounted(false);

    const dispatch = useDispatch();
    const shopNames = UserProfileStorage.getAllShopNames();
    const isElectronApp =
      Platform.OS === "web" &&
      document.getElementById("isElectronApp").value === "true";
    const { appliedFilters } = filterActions;
    let quickFilters = [];

    const onQuickFilterPress = (value) => {
      let driverValues = {
        value: [
          {
            title: "Courier Name",
            value: value.courier.name,
          },
          {
            title: "Pickup Time",
            value: value.pickupTime,
          },
          {
            title: "Display Date",
            value: value.displayDate,
          },
          {
            title: "ShopCode",
            value: value.siteId,
          },
        ],
      };
      const driverFilters = appliedFilters?.length
        ? Object.values(appliedFilters).filter((datat) => {
            return !Object.keys(datat).includes("ApproachingDrivers");
          })
        : appliedFilters;

      driverValues.value.map((each) => {
        const filterExists = quickFilters.some((filter) => {
          return (
            filter.ApproachingDrivers &&
            filter.ApproachingDrivers.value[0].title === each.title &&
            filter.ApproachingDrivers.value[0].value === each.value
          );
        });

        if (!filterExists) {
          quickFilters.push({
            ["ApproachingDrivers"]: {
              value: [
                {
                  title: each.title,
                  value: each.value,
                },
              ],
            },
          });
        }
      });
      dispatch(
        setActions({
          appliedFilters: [...quickFilters, ...driverFilters],
          filters: [...quickFilters, ...driverFilters],
        })
      );
    };

    const scrollLayout = (
      <ScrollView
        contentContainerStyle={{
          flexDirection: "row",
          width: Platform.OS === "web" ? "100%" : "auto",
        }}
        style={{
          paddingBottom: 10,
        }}
        horizontal={true}
        showsHorizontalScrollIndicator={!isSmallScreen ? displayScroll : true}
      >
        {driversList.map((batch, index) => {
          const { name: driverName = "", shipments = [], reqParams } = batch;
          const { pickupTime, isHurryPickup, expectedTime } = shipments[0];
          const isPickupManifestPrinted =
            pickupManifestPrintStatus[reqParams.printKindId];

          return (
            <View
              key={index}
              style={{
                alignItems: "flex-start",
                height: 120,
              }}
            >
              <View
                style={[
                  tw("flex justify-between"),
                  {
                    height: 120,
                    width: 120,
                    marginLeft: 0,
                    marginBottom: 0,
                    paddingLeft: 10,
                    paddingVertical: 10,
                    borderRadius: 8,
                    borderWidth: 1,
                    marginHorizontal: 20,
                    borderColor: isHurryPickup ? "#b50d81" : "#aadec6",
                    backgroundColor: isHurryPickup ? "#B50D810C" : "#AADEC633",
                  },
                ]}
              >
                <View>
                  <TouchableOpacity
                    style={{}}
                    onPress={() => onQuickFilterPress(shipments[0])}
                    testID={`${driverName}_tile`}
                    accessibilityLabel={`${driverName}_tile`}
                  >
                    <Text
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      {driverName}
                    </Text>
                    <Text>{expectedTime ? expectedTime : pickupTime}</Text>
                    <Text>
                      {shipments.length}{" "}
                      {Localise(
                        messages,
                        shipments.length > 1 ? "Orders" : "Order"
                      )}
                    </Text>
                  </TouchableOpacity>
                </View>

                <View style={[tw("flex flex-row justify-between")]}>
                  <PrintIframeRenderer />
                  <TouchableOpacity
                    activeOpacity={0.5}
                    onPress={() => {
                      printPickupManifest(
                        batch,
                        "MANUAL",
                        ![true, false].includes(isPickupManifestPrinted),
                        getData,
                        Localise,
                        messages
                      );
                    }}
                    testID="pickupManifestPrint"
                    accessibilityLabel="pickupManifestPrint"
                  >
                    <Image
                      style={{
                        width: 20,
                        height: 20,
                      }}
                      source={
                        IMAGES[isPickupManifestPrinted ? "printAgain" : "print"]
                      }
                    />
                  </TouchableOpacity>
                  <CheckBox
                    testID={`Driver_${driverName}_checkbox`}
                    accessibilityLabel={`Driver_${driverName}_checkbox`}
                    Component={TouchableOpacity}
                    checked={false}
                    checkedIcon={
                      <Image
                        source={IMAGES["checkbox-checked"]}
                        style={{ width: 20, height: 20 }}
                      />
                    }
                    uncheckedIcon={
                      <Image
                        source={IMAGES["checkbox-unchecked"]}
                        style={{ width: 20, height: 20 }}
                      />
                    }
                    containerStyle={[
                      theme.CheckBox.inputContainerStyle,
                      { marginTop: 0 },
                    ]}
                    iconRight={false}
                    onPress={() => {}}
                    size={20}
                  />
                </View>
              </View>
              {isElectronApp &&
                Object.keys(shopNames).map((memberCode, index) => (
                  <View key={index}>
                    <PrintIframeRenderer
                      id={`iframeForPickupManifest_${memberCode}`}
                    />
                  </View>
                ))}
            </View>
          );
        })}
      </ScrollView>
    );

    return (
      <View
        onMouseEnter={() => !isSmallScreen && setDisplayScroll(true)}
        onMouseLeave={() => !isSmallScreen && setDisplayScroll(false)}
      >
        <View
          style={{
            paddingVertical: 0,
            paddingHorizontal: isSmallScreen ? 5 : 0,
            flexDirection: "column",
          }}
        >
          {scrollLayout}
        </View>
      </View>
    );
  }
);

// eslint-disable-next-line react/display-name
export const DeliveryListItem = React.memo(
  ({
    task,
    columnsList,
    subColumnsList,
    selectedTaskId,
    setRecordId,
    setShopCode,
    isSmallScreen,
    isMobile,
    listingType,
    index,
  }) => {
    const isSelectedRecord = task.shipmentId === selectedTaskId;

    return (
      <React.Fragment>
        <ListItem
          item={cloneDeep(task)}
          itemKey={"deliveryTaskId"}
          isSelectedRecord={isSelectedRecord}
          isSmallScreen={isSmallScreen}
          isMobile={isMobile}
          columnsList={columnsList}
          subColumnsList={subColumnsList}
          recordId={selectedTaskId}
          setRecordId={setRecordId}
          setShopCode={setShopCode}
          listingType={listingType}
          index={index}
        />
      </React.Fragment>
    );
  }
);

export default DeliveryTaskList;
