export const PermissionsForBulkUserCreation = {
  FTD_ADMIN: true,
  FTD_POWER_USER: true,
  FTD_VIEW_ONLY: false,
  FTD_CSR: false,
};

export const TYPE = {
  MHQ: "mhq",
  MCLOUD: "mcloud",
};

export const ErrorMessages = {
  fileRequired: "Please choose excel file to create user accounts.",
  errorOccured: "Failed to create user accounts, please try again later.",
  internalErrorOccured: "Some internal error occured, please try again later.",
};
