import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";

export const initialState = {
  status: "progress",
  apiResponse: {
    events: [],
  },
  apiError: {
    events: "",
    eventDetails: "",
    orders: "",
  },
  data: {
    events: [],
    needsAction: [],
    active: [],
    drafts: [],
    completed: [],
    cancelled: [],
    inactive: [],
    funeralLog: [],
    funeralLogActual: [],
    totalRecords: 0,
    eventDetails: {},
    orders: [],
    selectedFuneralLogs: [],
  },
  extendedScreen: {
    showUpsert: false,
    status: "",
    eventId: "",
    storeOrigin: "",
    orderId: "",
    limit: 0,
    menuAction: "",
    recordAction: "",
  },
  actions: {
    search: "",
    filters: [],
    sort: "",
    shops: [],
    limit: {
      needsAction: 0,
      active: 0,
      drafts: 0,
      completed: 0,
      cancelled: 0,
      inactive: 0,
      funeralLog: 0,
    },
  },
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: `view::${sliceName}`,
  initialState,
  reducers: {
    setPageInitialize: (state) => {
      state.status = "progress";
      state.extendedScreen = initialState.extendedScreen;
    },
    setPageInitSuccess: (state) => {
      state.status = "done";
    },
    setPageInitFailure: (state) => {
      state.status = "fail";
    },
    setPageStatus: (state, action) => {
      state.status = action.payload;
    },
    setPageReset: (state) => {
      Object.assign(state, {
        ...initialState,
        data: {
          ...initialState.data,
          eventDetails: state.data?.eventDetails,
          orders: [],
        },
      });
    },
    setPageActionsReset: (state) => {
      state.actions = { ...initialState.actions };
    },
    setApiError: (state, action) => {
      const { section, error } = action.payload;
      state.apiError[section] = error;
    },
    setApiResponse: (state, action) => {
      const { section, events } = action.payload;
      state.apiResponse[section] = events;
      state.apiError[section] = "";
    },
    setPageData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    setOrderInfo: (state, action) => {
      state.data = {
        ...state.data,
        orders: [...state.data.orders, action.payload],
      };
    },
    resetOrdersInfo: (state) => {
      state.data = {
        ...state.data,
        orders: [],
      };
    },

    setSearchText: (state, action) => {
      state.actions = {
        ...state.actions,
        search: action.payload,
      };
    },

    setSelectedFuneralLog: (state, action) => {
      state.data = {
        ...state.data,
        selectedFuneralLogs: [
          ...state.data.selectedFuneralLogs,
          action.payload,
        ],
      };
    },

    setPageAction: (state, action) => {
      const { type, value } = action.payload;
      if (["shop"].includes(type))
        state.actions[type] = [...new Set([].concat(...value))];
      else if (type === "limit") {
        const { section } = action.payload;
        state.actions[type][section] = value;
      } else state.actions[type] = value;
    },
    setCurrentEvent: (state, action) => {
      if (action.payload) {
        const { type, value } = action.payload;
        if (type === "eventId") {
          state.extendedScreen = {
            ...initialState.extendedScreen,
            showUpsert: true,
            eventId: value,
          };
        } else {
          state.extendedScreen[type] = value;
        }
      } else {
        state.data = {
          ...state.data,
          eventDetails: {},
          orders: [],
        };
        state.extendedScreen = initialState.extendedScreen;
      }
    },

    setSideCarInitialize: (state) => {
      state.extendedScreen.status = "progress";
    },
    setSideCarInitSuccess: (state) => {
      state.extendedScreen.status = "done";
    },
    setSideCarInitFailure: (state) => {
      state.extendedScreen.status = "fail";
    },
    setStoreOrigin: (state, action) => {
      const { storeOrigin } = action.payload;
      state.extendedScreen.storeOrigin = storeOrigin;
    },
    fetchEventDetails: () => {},
    fetchAllEventsBySearch: (state, action) => {
      const { type, value } = action.payload;
      state.actions[type] = value;
    },
    fetchAllFuneralEventsBySearch: (state, action) => {
      const { type, value } = action.payload;
      state.actions[type] = value;
    },
    fetchAllEvents: () => {},
    fetchEventOrder: () => {},
  },
});

export const {
  setPageInitialize,
  setPageInitSuccess,
  setPageInitFailure,
  setPageReset,
  setPageData,
  setOrderInfo,
  resetOrdersInfo,
  setApiResponse,
  setApiError,
  setPageAction,
  setCurrentEvent,
  setCurrentEventId,
  setSearchText,
  setSideCarInitialize,
  setSideCarInitSuccess,
  setSideCarInitFailure,
  setStoreOrigin,
  setPageStatus,
  fetchEventDetails,
  fetchAllEvents,
  fetchAllEventsBySearch,
  fetchAllFuneralEventsBySearch,
  fetchEventOrder,
  setPageActionsReset,
  setSelectedFuneralLog,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
