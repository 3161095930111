import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";
import set from "lodash/set";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import truncate from "lodash/truncate";

export const initialState = {
  status: "progress",
  orderItemId: "",
  action: "",
  deliveryMethod: "",
  sourceMemberCode: "",
  apiResponse: {},
  selectedShopCode: "",
  activeItemTab: 0,
  orderItemTabs: [{ key: 0, title: "Order 1", isRemovable: true }],
  giftCardsData: [],
  redeemedGcData: [],
  updateCounty: false,
  calculateTax: false,
  paymentDetails: {
    status: "",
    values: {},
  },
  orderSummary: {},
  orderSummaryStatus: "",
  draftOrders: {},
  draftsToDelete: [],
  /**
   * This slice(selectedMemberCode) was created to display draft orders
   * based on the selected shop code in the create order screen.
   *
   * We are not using the existing 'selectedShopCode' slice
   * because it is not being reset after order creation,
   * and if the user manually clears the shop code.
   */
  selectedMemberCode: "",
  editProducts: false,
  geoCodeDetails: {},
  zoneDetails: [],
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setPageInitialize: (state) => {
      state.status = "progress";
      state.apiResponse = initialState.apiResponse;
    },
    setPageInitSuccess: (state) => {
      state.status = "done";
    },
    setPageInitProgress: (state) => {
      state.status = "progress";
    },
    setPageInitFailure: (state) => {
      state.status = "fail";
    },
    setPageReset: (state) => {
      Object.assign(state, initialState);
    },
    setNavParams: (state, action) => {
      const { id, dm, smc, action: actionName, type } = action.payload;
      state.action = actionName;
      state.deliveryMethod = dm;
      state.sourceMemberCode = smc;
      state.orderItemId = id;
      state.type = type;
    },
    setEditOrder: (state) => {
      state.action = "edit";
    },
    setApiResponse: (state, action) => {
      state.apiResponse = action.payload;
    },
    setApiError: (state, action) => {
      const { path, error } = action.payload;
      set(state.apiResponse, `${path}.error`, error);
    },
    setCustomerOrders: (state, action) => {
      state.customerOrders = action.payload;
    },
    setCreateOrderShopCode: (state, action) => {
      state.selectedShopCode = action.payload;
    },
    setShopCodeForDraftOrder: (state, action) => {
      state.selectedMemberCode = action.payload;
    },
    setRushDeliveryFee: (state, action) => {
      const { rushDeliveryFee = 0 } = action.payload;
      state.rushDeliveryFee = rushDeliveryFee;
    },
    setActiveOrderItemTab: (state, action) => {
      state.activeItemTab = action.payload;
    },
    setOrderItemTabs: (state, action) => {
      state.orderItemTabs = action.payload;
    },
    resetOrderItemTabs: (state) => {
      state.orderItemTabs = initialState.orderItemTabs;
      state.activeItemTab = 0;
    },
    setOrderItemTitle: (state, action) => {
      const { activeItemTab } = state;
      const { firstName, Localise, messages } = get(action, "payload", {});
      state.orderItemTabs[activeItemTab] = {
        ...state.orderItemTabs[activeItemTab],
        title: `${Localise(messages, "Order for")} ${capitalize(
          truncate(firstName, { length: 8 })
        )}`,
        avsTitle: `${capitalize(firstName)}'s Order`,
        defaultTitle: false,
      };
    },
    setOrderItemTabDetails: (state, action) => {
      const {
        Localise,
        messages,
        orderItems = [],
      } = get(action, "payload", {});

      const updatedOrderItemTabs = orderItems.map((item, index) => {
        const { recipientInfo: { firstName = "" } = {} } = item;

        return {
          key: index,
          isRemovable: true,
          title: firstName
            ? `${Localise(messages, "Order for")} ${capitalize(
                truncate(firstName, { length: 8 })
              )}`
            : `Order ${index + 1}`,
          avsTitle: firstName
            ? `${capitalize(firstName)}'s Order`
            : `Order ${index + 1}`,
          defaultTitle: firstName ? false : true,
        };
      });
      state.orderItemTabs = updatedOrderItemTabs;
      state.activeItemTab = updatedOrderItemTabs.length - 1;
    },
    resetOrderItemTitle: (state) => {
      const { activeItemTab } = state;
      state.orderItemTabs[activeItemTab] = {
        ...state.orderItemTabs[activeItemTab],
        title: `Order ${activeItemTab + 1}`,
        avsTitle: `Order ${activeItemTab + 1}`,
        defaultTitle: true,
      };
    },
    setGiftCardsData: (state, action) => {
      const { response, actionName, index } = action.payload;
      actionName === "delete" && state.giftCardsData.splice(index, 1);
      state.giftCardsData =
        actionName === "clear"
          ? []
          : actionName === "delete"
          ? state.giftCardsData
          : [...state.giftCardsData.concat(...[response])];
    },
    setRedeemedGcData: (state, action) => {
      state.redeemedGcData = action.payload;
    },
    setUpdateCounty: (state, action) => {
      state.updateCounty = action.payload;
    },
    setCalculatingTax: (state, action) => {
      state.calculateTax = action.payload;
    },
    setPaymentDetails: (state, action) => {
      state.paymentDetails = { ...action.payload };
    },
    setOrderSummary: (state, action) => {
      state.orderSummary = { ...action.payload };
    },
    resetOrderSummary: (state) => {
      state.orderSummary = initialState.orderSummary;
    },
    setOrderSummaryProgress: (state) => {
      state.orderSummaryStatus = "progress";
    },
    setOrderSummarySuccess: (state) => {
      state.orderSummaryStatus = "";
    },
    setOrderSummaryFail: (state) => {
      state.orderSummaryStatus = "fail";
    },
    setDraftOrders: (state, action) => {
      state.draftOrders = action.payload;
    },
    setDraftsToDelete: (state, action) => {
      state.draftsToDelete = action.payload;
    },
    setEditProducts: (state, action) => {
      state.editProducts = action.payload;
      state.action = action.payload ? "edit" : "";
    },
    setGeoCodeDetails: (state, action) => {
      state.geoCodeDetails = action.payload;
    },
    setZoneDetails: (state, action) => {
      state.zoneDetails = action.payload;
    },
    setSubscriptionData: (state, action) => {
      state.subscription = {
        ...state.subscription, // Keep existing properties
        subscriptionData: action.payload, // Update subscriptionData
      };
    },
    setSubscriptionCustomer: (state, action) => {
      state.subscription = {
        ...state.subscription, // Keep existing properties
        customer: action.payload, // Update customer
      };
    },
    resetSubscriptionData: (state, action) => {
      state.subscription = {
        subscriptionData: {},
        customer: {},
      };
    },

    // noop - only actions
    fetchOrderData: () => {},
    fetchCustomerOrders: () => {},
    fetchGlobalFees: () => {},
    fetchGiftCardsData: () => {},
    fetchOrderSummary: () => {},
    saveDraftOrder: () => {},
    fetchDraftOrders: () => {},
    modifyDraftOrder: () => {},
    deleteDraftOrder: () => {},
    validateOrder: () => {},
    saveCustomerCreditCard: () => {},
    upsertSubscription: () => {},
    fetchZoneDetailsByLatLng: () => {},
    fetchLatLngAndZoneDetailsById: () => {},
  },
});

export const {
  setPageInitialize,
  setPageInitSuccess,
  setPageInitProgress,
  setPageInitFailure,
  setPageReset,
  setApiResponse,
  setApiError,
  fetchOrderData,
  setNavParams,
  setEditOrder,
  setCustomerOrders,
  fetchCustomerOrders,
  setCreateOrderShopCode,
  setShopCodeForDraftOrder,
  setRushDeliveryFee,
  fetchGlobalFees,
  setActiveOrderItemTab,
  setOrderItemTabs,
  resetOrderItemTabs,
  setOrderItemTitle,
  resetOrderItemTitle,
  setGiftCardsData,
  setRedeemedGcData,
  setCalculatingTax,
  fetchGiftCardsData,
  setUpdateCounty,
  setPaymentDetails,
  fetchOrderSummary,
  setOrderSummary,
  resetOrderSummary,
  setOrderSummaryProgress,
  setOrderSummarySuccess,
  setOrderSummaryFail,
  saveDraftOrder,
  fetchDraftOrders,
  modifyDraftOrder,
  deleteDraftOrder,
  setDraftOrders,
  setOrderItemTabDetails,
  setDraftsToDelete,
  validateOrder,
  saveCustomerCreditCard,
  upsertSubscription,
  setEditProducts,
  fetchZoneDetailsByLatLng,
  fetchLatLngAndZoneDetailsById,
  setZoneDetails,
  setGeoCodeDetails,
  setSubscriptionData,
  setSubscriptionCustomer,
  resetSubscriptionData,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
