import React, { useEffect } from "react";
import { Platform } from "react-native";
import {
  selectShopCode,
  selectScreenKey,
} from "library/sagas/views/home/drawer/delivery/selector";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import { setSelectScreenKey } from "library/sagas/views/home/drawer/delivery/slice";
import { resetBasicInfo as resetDeliveryBasicInfo } from "library/sagas/ongoing/current-delivery/slice";
import { useSelector, useDispatch } from "react-redux";
import { Entitlements, isMHQNonCoreMember } from "library/utils/entitlements";
import {
  AppSettingsContext,
  DeviceContext,
} from "library/contexts/appSettings";
import some from "lodash/some";
import { screenList } from "./config";
import UserProfileStorage from "library/storage/userProfile";
import Environment from "library/utils/environment";

//components
import TabNavigationScreen from "components/containers/tabs";

const DeliveryDashboard = ({ navigation: { setParams }, route }) => {
  const { params = {} } = route;

  const { permissions } = React.useContext(AppSettingsContext);
  const filteredShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.DELIVERY in permissions[memberCode])
      filteredShopCodes.push(memberCode);
  });
  let shopCode = useSelector(selectShopCode);
  if (!shopCode) {
    shopCode = UserProfileStorage.getSelectedShopCode();
  }
  const selectedMemberCode =
    filteredShopCodes.length > 1 && shopCode !== "all"
      ? shopCode || filteredShopCodes[0]
      : filteredShopCodes[0];

  const isMHQNonCoreUser = isMHQNonCoreMember(selectedMemberCode);
  const { isDesktop } = React.useContext(DeviceContext);
  const isHintsVisible = Environment.get("SHOW_ROUTE_HINTS", false);
  const dispatch = useDispatch();
  const screenKey = useSelector(selectScreenKey);
  const isRouteSettingsEnabled =
    !["all", ""].includes(shopCode) &&
    Platform.OS === "web" &&
    isHintsVisible &&
    isDesktop &&
    isMHQNonCoreUser;

  let updatedScreenList = [...screenList];

  //Filter tabs based on entitlements and device
  updatedScreenList = updatedScreenList.filter((screen) => {
    const hasPermission =
      some(permissions, Entitlements[screen.entitlement]) || false;
    return isDesktop
      ? hasPermission
      : screen?.key !== "deliveryDashboard" && hasPermission;
  });

  useEffect(() => {
    dispatch(setCurrentPage("lmds-delivery"));
    dispatch(resetDeliveryBasicInfo()); //To clear filters before entering create/edit-route
    return () => {
      setParams({
        dashboardStartDate: undefined,
        dashboardEndDate: undefined,
      });
      dispatch(setSelectScreenKey(""));
    };
  }, []);

  return (
    <TabNavigationScreen
      title={"Delivery"}
      screenList={updatedScreenList}
      setSelectScreenKey={setSelectScreenKey}
      screenKey={screenKey}
      params={params}
      setParams={setParams}
      isRouteSettingsEnabled={isRouteSettingsEnabled}
    />
  );
};
export default DeliveryDashboard;
