const actionTypes = {
  SET_ROLE: "SET_ROLE",
  SET_PROFILE: "SET_PROFILE",
  RESET_PROFILE: "RESET_PROFILE",
  SET_SUSPENDED_MEMBERS: "SET_SUSPENDED_MEMBERS",
  SET_FLORIST_TYPES: "SET_FLORIST_TYPES",
  SET_PROFILE_MEMBER_CODES: "SET_PROFILE_MEMBER_CODES",
  SET_ACCOUNT_MEMBER_CODES: "SET_ACCOUNT_MEMBER_CODES",
};

export default actionTypes;
