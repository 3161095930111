/* eslint-disable react-hooks/rules-of-hooks */
import React, { forwardRef } from "react";
import { View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Text } from "react-native-elements";
import I18NContext from "library/contexts/i18N";
import { AppSettingsContext } from "library/contexts/appSettings";
import { Entitlements } from "library/utils/entitlements";
import { theme } from "styles/theme";
import tw from "tailwind-rn";
import get from "lodash/get";
import { useFormikContext } from "formik";
import { SubmitButton } from "components/elements/forms";

function submitButtons(
  {
    disableOnDirty = true,
    title = "Submit",
    isSmallScreen,
    setValidationOnChange,
    setCancelAction,
    selectedShopCode,
  },
  ref
) {
  const { dirty, values, resetForm, setErrors, setFieldValue } =
    useFormikContext();
  const { shopCode: selectedMemberCode, deliveryProvider } = values;
  const { messages, Localise } = React.useContext(I18NContext);
  const { permissions } = React.useContext(AppSettingsContext);

  const selectedShopPermissions = get(
    permissions,
    selectedMemberCode || selectedShopCode,
    {}
  );

  const CreateDSEntitled = selectedShopPermissions[
    Entitlements.DELIVERY_TASKS
  ]?.includes(Entitlements.DELIVERY_TASKS_PAGE.CREATE_DS_REQUEST);

  return (
    <View style={[tw("flex-row"), { paddingRight: isSmallScreen ? 5 : 0 }]}>
      {dirty && (
        <View style={{ marginRight: 10, justifyContent: "center" }}>
          <TouchableOpacity
            onPress={() => {
              setValidationOnChange(false);
              setCancelAction();
              resetForm();
              setFieldValue("deliveryProvider", deliveryProvider);
              setErrors({});
            }}
            onResponderStart={() =>
              setTimeout(() => {
                setValidationOnChange(false);
                resetForm();
                setErrors({});
              }, 0)
            }
            style={{
              ...theme.Button.secondaryButtonStyle,
              minWidth: 100,
              paddingVertical: 8,
              paddingHorizontal: CreateDSEntitled ? 10 : 0,
            }}
            testID="create-task-cancel"
            accessibilityLabel="create-task-cancel"
          >
            <View>
              <Text style={{ ...theme.Button.secondaryTitleStyle }}>
                {Localise(messages, "Cancel")}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      )}
      {CreateDSEntitled && (
        <SubmitButton
          containerStyle={{ minWidth: 100, marginLeft: 0, marginRight: 5 }}
          mainContainerStyle={{ justifyContent: "center" }}
          title={Localise(messages, title)}
          disableOnDirty={disableOnDirty}
          disableOnErrors={true}
          refFromProp={ref}
        />
      )}
    </View>
  );
}

export const SaveCancelButtons = forwardRef(submitButtons);
