import React from "react";

import CustomerProfileInfo from "../../create-order/customer-profile-info";

const EventDetails = (props) => {
  const { isDesktop, values } = props;

  return (
    <CustomerProfileInfo
      isSmallScreen={!isDesktop}
      title={"Primary Contact"}
      values={values}
      sendingMemberCode={""}
      isEventPlanner={true}
    />
  );
};

export default EventDetails;
