import moment from "moment";
import toLower from "lodash/toLower";
import startCase from "lodash/startCase";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import isObject from "lodash/isObject";

import IMAGES from "static/assets/images";

import Environment from "library/utils/environment";

import {
  phoneNumberFormatter,
  formatCardMessage,
} from "library/utils/formatter";
import UserProfileStorage from "library/storage/userProfile";
import { formatProductSecondChoice } from "./product-details/helper";
import { relatedMessageInfo } from "../dashboard/helper";
import { qrPlaceHolder } from "components/views/drawer/order-details/helper.js";

const getCSS = ({ isSinglePrint }) => {
  return `@page {
      font-family: Helvetica, sans-serif;
      size: auto;
      margin: 3px;
      -webkit-margin-before: ${isSinglePrint ? "20px" : "30px"}
      -webkit-margin-after: 50px;
      /* this affects the margin in the printer settings */
    }
    .editorContent > p, ol, ul, pre, blockquote, h1, h2, h3, h4, h5, h6 {
      margin: 0px;
      padding: 0px;
    }
    .cardMessage {
      text-align: center;
      word-break: break-word;
      white-space: pre-wrap;
    }
    .ql-font-arial {
      font-family: "Arial";
    }
    .ql-font-georgia {
      font-family: "Georgia";
    }
    .ql-font-verdana {
      font-family: "Verdana";
    }
    .ql-font-impact {
      font-family: "Impact";
    }
    .ql-font-tahoma {
      font-family: "Tahoma";
    }
    .ql-font-times {
      font-family: "Times New Roman", Times, serif;
    }
    .ql-align-left {
      text-align: left;
    }
    .ql-align-right {
      text-align: right;
    }
    .ql-align-center {
      text-align: center;
    }
    .dynamic-content {
      white-space: pre-wrap;
    }
    .printOrderContainer td:first-child {
      text-align: left;
      width: 22%;
      font-weight: bold;
    }
    .printOrderContainer td:nth-child(2) {
      text-align: left;
      width: 64%;
      word-break: break-word;
    }
    .printOrderContainer td:nth-child(3) {
      text-align: center;
      justify-items: center;
      width: 14%;
      padding-right: 30px !important;
    }
    .printFtdLogo {
      position: absolute;
      top: 10px;
      left: 40px;
    }
    .orderTypeHeading {
      font-size: 20px;
    }
    .printOrderContainer .table tr > td,
    .printOrderContainer .table tr > th {
      border-top: 0px;
      padding: 1px 2px;
      border-bottom: 0px;
    }
    @media print {
      html,
      body {
        height: auto;
        -ms-overflow-style: none;
      }
      .noPrint {
        display: none;
      }
      .page-break {
        display: block;
        page-break-after: always;
      }
      .page-break-flow-root {
        display: flow-root;
        page-break-after: always;
      }
      .table-responsive {
        min-height: 0.01%;
        overflow-x: auto;
      }
      .table {
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        margin-bottom: 10px;
        border-spacing: 2px;
      }
      thead {
        display: table-header-group;
      }
      tr {
        text-align: left;
        display: table-row;
        vertical-align: left;
        border-color: inherit;
      }
      .table > thead > tr > th {
        text-align: left;
        display: table-cell;
        vertical-align: bottom;
      }
      .table > tbody > tr > td,
      .table > tbody > tr > th,
      .table > tfoot > tr > td,
      .table > tfoot > tr > th,
      .table > thead > tr > td,
      .table > thead > tr > th {
        padding: 8px;
        line-height: 1.3;
      }
      .table > tbody > tr > td,
      .table > tbody > tr > th,
      .table > tfoot > tr > td,
      .table > tfoot > tr > th,
      .table > thead > tr > td,
      .table > thead > tr > th {
        padding: 8px;
        line-height: 1.3;
        vertical-align: top;
        border-top: 1px solid gray;
      }
      tbody {
        display: table-row-group;
        border-color: inherit;
      }
    }`;
};

export const getSingleOrderPrintHTML = ({
  orderDetails,
  memberCodes,
  operatorName,
  messages = {},
  Localise = () => {},
  selectedproducts = [],
  customerInfo = {},
  qrImageData = "",
  printType = "orderDetails",
  isSinglePrint = true,
}) => {
  const printVersion = Environment.get("PRINT_VERSION", 1);
  const { messages: orderMessages = [] } = orderDetails;
  const messageDirectionToPrint = printType.includes("InboundMessages")
    ? "INBOUND"
    : printType.includes("OutboundMessages")
    ? "OUTBOUND"
    : "";
  const eligibleMessages =
    printType === "orderDetails"
      ? ["orderDetails"]
      : orderMessages.filter((message) => {
          const { messageType, direction } = message;
          return (
            messageType !== "OLC" &&
            (messageDirectionToPrint
              ? direction === messageDirectionToPrint
              : true)
          );
        });
  const {
    deliveryInfo = {},
    lineItems = [],
    multiProductEligible = false,
    messageNumber = "",
    price,
    recipientInfo,
    receivingMember = {},
    sendingMember = {},
    specialInstructions = "",
    orderSource = "",
    pickupInfo,
    priceIncludingDelivery,
    direction,
    hasPendingPayLaterPayment = false,
    externalPartnerBusinessName = "",
    giftCardType = "",
    partnerId = "",
  } = orderDetails;

  let floristShopContactSettings = "";

  const isSenderOrder =
    (direction === "OUTBOUND" &&
      memberCodes.includes(sendingMember.memberCode) &&
      sendingMember.memberCode !== receivingMember.memberCode) ||
    deliveryMethod === "PHONE_OUT";

  const shopPreferences = UserProfileStorage.getShopPreferences(
    isSenderOrder ? sendingMember.memberCode : receivingMember.memberCode
  );

  let isShopNameOverridden = false;
  let isPhoneOverridden = false;

  floristShopContactSettings = get(
    shopPreferences,
    "florist_contact_information_settings",
    ""
  );

  if (floristShopContactSettings == "JDE" || floristShopContactSettings == "") {
    floristShopContactSettings =
      '{"shopName":"","streetAddress":"","aptSuite":"","city":"","state":"","zip":"","phone":"","email":"","website":"","additionalMessage":"","contactName":"","printed":[],"web":[],"network":[]}';
  }
  let {
    shopName = "",
    phone = "",
    printed = [],
  } = JSON.parse(floristShopContactSettings);

  if (printed.includes("shopName") && shopName != "") {
    isShopNameOverridden = true;
  }
  if (printed.includes("phone") && phone != "") {
    isPhoneOverridden = true;
  }

  const {
    cardMessage,
    deliveryDate,
    deliveryInstructions,
    occasion,
    deliveryMethod = "",
    pickUpBy,
  } = deliveryInfo;
  const { accessories: oldAccessories } = lineItems[0];

  const accessories = multiProductEligible
    ? lineItems.filter((lineItem) => lineItem.type === "Addon")
    : oldAccessories;

  const products = multiProductEligible
    ? lineItems.filter((lineItem) => lineItem.type === "Product")
    : lineItems;
  let productsInfo = {};

  if (isSinglePrint) {
    products.map((each) => {
      const productId = each.productFirstChoiceCode?.toLowerCase() || "";
      const currentproduct = selectedproducts.find((prod) =>
        [
          prod.refNumberId?.toLowerCase(),
          prod.productId?.toLowerCase(),
        ].includes(productId)
      );
      if (currentproduct) {
        let product = cloneDeep(currentproduct);
        productsInfo = {
          ...productsInfo,
          [product.productId.toLowerCase()]: product,
        };
      }
    });
  }

  const {
    addressLine1: delAddress = "",
    addressLine2: delAddress2 = "",
    city: delCity = "",
    firstName = "",
    lastName = "",
    phone: delPhone = "",
    state: delState = "",
    zip: delZip = "",
    locationType: delLocationType = "",
    locationName: delLocationName = "",
  } = recipientInfo;

  const {
    firstName: custFirstName = "",
    lastName: custLastName = "",
    businessName = "",
    customerType = "",
    isBusinessProfile = false,
    phones = [],
  } = customerInfo;

  const isBusinessAccount = isBusinessProfile || customerType === "Business";
  const customerName = isBusinessAccount
    ? businessName
    : `${custFirstName} ${custLastName}`.trim();

  const delName = firstName + " " + lastName;

  let {
    businessName: recbusinessName = "",
    memberCode: recMemberCode,
    phone: recPhone = "",
  } = receivingMember;

  let {
    businessName: sendBusinessName = "",
    memberCode: sendMemberCode,
    phone: sendPhone = "",
  } = sendingMember;

  if (
    ((deliveryMethod === "FLORIST_DELIVERED" ||
      deliveryMethod === "STORE_PICKUP" ||
      deliveryMethod === "WALK_IN") &&
      sendMemberCode === recMemberCode) ||
    isSenderOrder === true
  ) {
    sendBusinessName = isShopNameOverridden ? shopName : sendBusinessName;
    sendPhone = isPhoneOverridden ? phone : sendPhone;
  }
  if (sendMemberCode === recMemberCode || isSenderOrder === false) {
    recbusinessName = isShopNameOverridden ? shopName : recbusinessName;
    recPhone = isPhoneOverridden ? phone : recPhone;
  }

  const { storePickupDateTime = "" } = pickupInfo || {};
  const isPickUpOrder =
    orderSource === "DoorDash" ||
    orderSource === "UberEats" ||
    (orderSource === "MOL" && deliveryMethod === "MOL_CUSTOMER_PICKUP") ||
    deliveryMethod === "STORE_PICKUP" ||
    deliveryMethod === "WALK_IN";

  const isMOLPickUpOrder =
    orderSource === "MOL" && deliveryMethod === "MOL_CUSTOMER_PICKUP";
  const isStorePickupOrder = deliveryMethod === "STORE_PICKUP";
  const isWalkInOrder = deliveryMethod === "WALK_IN";
  const isProductDigitalGC = giftCardType === "DIGITAL_GIFT_CARDS";
  const isFolMolOrder = orderSource === "FOL" || orderSource === "MOL";
  const isMigratedFolMolOrder = partnerId === "FOL";
  const orderOrigin = isPickUpOrder
    ? orderSource === "UberEats"
      ? "Uber Eats"
      : orderSource === "DoorDash"
      ? "DoorDash"
      : orderSource === "MOL" || isMigratedFolMolOrder
      ? "My Website"
      : ["Bloomnet", "Teleflora", "TEL eFlorist", "Email Order"].includes(
          orderSource
        )
      ? orderSource
      : "FTD"
    : orderSource === "MOL" || isMigratedFolMolOrder
    ? "My Website"
    : ["Bloomnet", "Teleflora", "TEL eFlorist", "Email Order"].includes(
        orderSource
      )
    ? orderSource
    : "FTD";
  let accessoriesDescription = "";
  accessories.map((accessory) => {
    accessoriesDescription = `${accessoriesDescription} PRICE INCLUDES 1 ${
      accessory?.accessoryId || ""
    }-${accessory?.accessoryDesc}($${accessory?.accessoryPrice}), ${
      accessory?.designNotes ? `Design notes: ${accessory.designNotes}` : ""
    }   `;
  });

  let deliveryInstructionsFormatted = deliveryInstructions;
  if (
    orderSource === "MOL" &&
    deliveryMethod === "MOL_CUSTOMER_PICKUP" &&
    !!deliveryInstructions
  ) {
    const [molPickupValue, ...otherDelInstructions] =
      deliveryInstructions.split(",");

    if (!!molPickupValue && !molPickupValue.includes("\\n")) {
      deliveryInstructionsFormatted = otherDelInstructions.join(",");
    } else {
      const [molPickupValue, ...otherDelInstructions] =
        deliveryInstructions.split("\\n");
      if (
        !!molPickupValue &&
        moment(molPickupValue).format("h:mm A") !== "Invalid date"
      ) {
        deliveryInstructionsFormatted = otherDelInstructions;
      }
    }
  }

  const cityStateAndZip = `${
    delCity && delState ? `${delCity}, ${delState}` : delCity || delState
  } ${delZip}`;

  const getSinglePrintProductLineItem = () => {
    let productContent = "";
    let isFirstRecipe = true;
    let recipeContent = "";

    products.map((product, index) => {
      const {
        productFirstChoiceCode = "",
        productFirstChoiceDescription = "",
        productSecondChoiceCode = "",
        productSecondChoiceDescription = "",
        designNotes = "",
        quantity = 1,
      } = product;

      const { recipeList = [], productName = "" } =
        productsInfo[productFirstChoiceCode?.toLowerCase()] || {};

      recipeContent =
        recipeContent +
        recipeList
          .map((recipeItem, idx) => {
            if (idx === 0) {
              const content = `<tr style="font-size:14px !important;">
                <td>
                  <label>${
                    isFirstRecipe ? Localise(messages, "Recipe") : ""
                  }</label>
                </td>
                <td style="display:flex;">
                ${`${productFirstChoiceCode ? productFirstChoiceCode : ""}${
                  productFirstChoiceDescription
                    ? " - " + productFirstChoiceDescription
                    : ""
                }`}
                </td>
                <td>${isFirstRecipe ? checkBoxPlaceholder() : ""}</td>
              </tr>`;
              isFirstRecipe = false;
              return content;
            } else {
              return `<tr style="font-size:14px !important;">
                <td>
                  <label></label>
                </td>
                <td style="display:flex;">
                  <span style="flex:0.5;">${recipeItem.quantity}</span>
                  <span style="flex:1.2;">${recipeItem.color || "-"}</span>
                  <span style="flex:4;">${recipeItem.item || "-"}</span>
                </td>
                <td></td>
              </tr>`;
            }
          })
          .join("");

      let productDescription = `${productFirstChoiceCode} ${
        productFirstChoiceDescription.trim() || productName
      }`;

      let productSecondChoice = formatProductSecondChoice(
        productSecondChoiceCode,
        productSecondChoiceDescription,
        true
      );

      if (productSecondChoice.length > 0)
        productDescription = `${productDescription}, ${Localise(
          messages,
          "Second Choice"
        )}: ${productSecondChoice}`;

      productContent =
        productContent +
        `${
          index ? ", " : ""
        }${productDescription} Qty ${quantity} ${designNotes}`;

      return productContent;
    });

    return `<tr style="font-size:14px !important;font-weight:bold !important">
        <td><label>${Localise(messages, "Product")}</label></td>
        <td>
        ${productContent || Localise(messages, "NONE")}
          </td>
          <td>${checkBoxPlaceholder()}</td>
      </tr>${recipeContent}`;
  };

  const getAutoPrintProductLineItem = () => {
    let recipeContent = "";
    let productContent = "";
    let isFirstRecipe = true;

    products.map((product, index) => {
      const {
        productFirstChoiceCode = "",
        productFirstChoiceDescription = "",
        productSecondChoiceCode = "",
        productSecondChoiceDescription = "",
        designNotes = "",
        quantity = 1,
        recipe = "",
      } = product;

      let recipeList = [];
      if (recipe.length) {
        try {
          recipeList = JSON.parse(recipe);
        } catch (e) {
          try {
            recipe.split(",").map((ele) => {
              const [f1, ...other] = ele.trim().split(" ");
              recipeList.push({
                quantity: f1,
                item: other.join(" "),
              });
            });
          } catch (e) {
            console.log("Failed to parse recipe info");
          }
        }
      }

      recipeContent =
        recipeContent +
        recipeList
          .map((recipeItem, idx) => {
            if (idx === 0) {
              const content = `<tr style="font-size:14px !important;">
            <td>
              <label>${
                isFirstRecipe ? Localise(messages, "Recipe") : ""
              }</label>
            </td>
            <td style="display:flex;">
            ${`${productFirstChoiceCode ? productFirstChoiceCode : ""}${
              productFirstChoiceDescription
                ? " - " + productFirstChoiceDescription
                : ""
            }`}
            </td>
            <td>${isFirstRecipe ? checkBoxPlaceholder() : ""}</td>
          </tr>
          <tr style="font-size:14px !important;">
            <td>
              <label></label>
            </td>
            <td style="display:flex;">
              <span style="flex:0.5;">${recipeItem.quantity}</span>
              <span style="flex:1.2;">${recipeItem.color || "-"}</span>
              <span style="flex:4;">${recipeItem.item || "-"}</span>
            </td>
            <td></td>
          </tr>`;
              isFirstRecipe = false;
              return content;
            } else {
              return `<tr style="font-size:14px !important;">
            <td>
              <label></label>
            </td>
            <td style="display:flex;">
              <span style="flex:0.5;">${recipeItem.quantity}</span>
              <span style="flex:1.2;">${recipeItem.color || "-"}</span>
              <span style="flex:4;">${recipeItem.item || "-"}</span>
            </td>
            <td></td>
          </tr>`;
            }
          })
          .join("");

      let productDescription = `${productFirstChoiceCode} ${productFirstChoiceDescription}`;

      let productSecondChoice = formatProductSecondChoice(
        productSecondChoiceCode,
        productSecondChoiceDescription,
        true
      );

      if (productSecondChoice.length > 0)
        productDescription = `${productDescription}, ${Localise(
          messages,
          "Second Choice"
        )}: ${productSecondChoice}`;

      productContent =
        productContent +
        `${
          index ? ", " : ""
        }${productDescription} Qty ${quantity} ${designNotes}`;

      return productContent;
    });

    return `<tr style="font-size:14px !important;font-weight:bold !important">
        <td><label>${Localise(messages, "Product")}</label></td>
        <td>
        ${productContent || Localise(messages, "NONE")}
          </td>
          <td>${checkBoxPlaceholder()}</td>
      </tr>${recipeContent}`;
  };

  const getAccessoriesContent = () => {
    return accessories?.length
      ? accessories
          .map((addOn, index) => {
            const {
              // accessoryPrice,
              // actualPrice,
              accessoryDesc,
              productFirstChoiceDescription,
              accessoryId,
              productFirstChoiceCode,
              designNotes,
              accessoryQty,
              quantity,
            } = addOn;
            const qty = accessoryQty || quantity;
            const id = accessoryId || productFirstChoiceCode;
            // const price = accessoryPrice || actualPrice;
            const desc = accessoryDesc || productFirstChoiceDescription;
            return `<tr style="font-size:14px !important;font-weight:bold !important">
                <td><label>${
                  index === 0 ? Localise(messages, "Add-ons") : ""
                }</label></td>
                <td>${id ? " " + id : ""}${desc ? " " + desc : ""}${
              qty ? " Qty " + qty : ""
            }${designNotes ? " " + designNotes : ""}</td>
                <td>${checkBoxPlaceholder()}</td>`;
          })
          .join("")
      : `<tr style="font-size:14px !important;font-weight:bold !important">
                <td><label>${Localise(messages, "Add-ons")}</label></td>
                <td></td>
              </tr>`;
  };

  const getSinglePrintProductLineItemV2 = () => {
    return products
      .map((product, index) => {
        const {
          productFirstChoiceCode = "",
          productFirstChoiceDescription = "",
          productSecondChoiceCode = "",
          productSecondChoiceDescription = "",
          designNotes = "",
          quantity = 1,
          lineItemAmounts = [],
        } = product;

        const pricePerUnit =
          lineItemAmounts?.find(
            (amount) => amount.name === "retailProductAmount"
          ).value / quantity;

        const { recipeList = [], productName = "" } =
          productsInfo[productFirstChoiceCode?.toLowerCase()] || {};

        let productDescription = "";

        if (productFirstChoiceCode.length > 0)
          productDescription = productFirstChoiceCode;

        if (productFirstChoiceDescription.length > 0)
          productDescription = productDescription.concat(
            ` ${productFirstChoiceDescription.trim() || productName}`
          );

        let productSecondChoice = formatProductSecondChoice(
          productSecondChoiceCode,
          productSecondChoiceDescription,
          true
        );

        if (productSecondChoice.length > 0)
          productDescription = `${productDescription}, ${Localise(
            messages,
            "Second Choice"
          )}: ${productSecondChoice}`;

        return `<tr style="font-size:14px !important;font-weight:bold !important">
                <td><label>${Localise(messages, "Product and Recipe")} #${
          index + 1
        }</label></td>
                <td>${productDescription}</td>
                <td>${checkBoxPlaceholder()}</td>
                <tr style="font-size:14px !important;font-weight:bold !important">
                <td><label>${""}</label></td>
                <td>${` ${
                  designNotes ? `Design Notes: ${designNotes}` : ""
                }`}</td></tr>
                <tr style="font-size:14px !important;font-weight:bold !important">
                <td><label>${""}</label></td>
                <td>
                  ${`  Qty: ${quantity ? " " + quantity : ""} 
                  ${
                    pricePerUnit
                      ? "@ $" + parseFloat(pricePerUnit).toFixed(2)
                      : ""
                  }`}
                </td>
                </tr>
                <td>
                ${recipeList
                  .map((recipeItem, index) => {
                    if (index > 0) {
                      return `<tr style="font-size:14px !important;">
                    <td><label>${""}</label></td>
                    <td style="display:flex;">
                      <span style="flex:1;">${recipeItem.quantity}</span>
                      <span style="flex:1.2;">${recipeItem.color || "-"}</span>
                      <span style="flex:4;">${recipeItem.item || "-"}</span>
                    </td>
                  </tr>`;
                    }
                  })
                  .join("")}
                </td>
              </tr>`;
      })
      .join("");
  };

  const getAutoPrintProductLineItemV2 = () => {
    return products
      .map((product, index) => {
        const {
          productFirstChoiceCode = "",
          productFirstChoiceDescription = "",
          productSecondChoiceCode = "",
          productSecondChoiceDescription = "",
          designNotes = "",
          quantity = 1,
          lineItemAmounts = [],
          recipe = "",
        } = product;

        let productDescription = "";

        if (productFirstChoiceCode.length > 0)
          productDescription = productFirstChoiceCode;

        if (productFirstChoiceDescription.length > 0)
          productDescription = productDescription.concat(
            ` ${productFirstChoiceDescription}`
          );

        let productSecondChoice = formatProductSecondChoice(
          productSecondChoiceCode,
          productSecondChoiceDescription,
          true
        );

        if (productSecondChoice.length > 0)
          productDescription = `${productDescription}, ${Localise(
            messages,
            "Second Choice"
          )}: ${productSecondChoice}`;

        const pricePerUnit =
          lineItemAmounts?.find(
            (amount) => amount.name === "retailProductAmount"
          ).value / quantity;

        let recipeList = [];
        if (recipe.length) {
          try {
            recipeList = JSON.parse(recipe);
          } catch (e) {
            try {
              recipe.split(",").map((ele) => {
                const [f1, ...other] = ele.trim().split(" ");
                recipeList.push({
                  quantity: f1,
                  item: other.join(" "),
                });
              });
            } catch (e) {
              console.log("Failed to parse recipe info");
            }
          }
        }

        return `<tr style="font-size:14px !important;font-weight:bold !important">
                <td><label>${Localise(messages, "Product and Recipe")} #${
          index + 1
        }</label></td>
                <td>${productDescription}</td>
                <td>${checkBoxPlaceholder()}</td>
                <tr style="font-size:14px !important;font-weight:bold !important">
                <td><label>${""}</label></td>
                <td>${` ${designNotes ? `Design Notes: ${designNotes}` : ""}
                `}</td></tr>
                <tr style="font-size:14px !important;font-weight:bold !important">
                <td><label>${""}</label></td>
                <td>
                  ${`  Qty: ${quantity ? " " + quantity : ""} 
                  ${
                    pricePerUnit
                      ? "@ $" + parseFloat(pricePerUnit).toFixed(2)
                      : ""
                  }`}
                </td>
                </tr>
                <td>
                ${recipeList
                  .map((recipeItem) => {
                    return `<tr style="font-size:14px !important;">
                    <td><label>${""}</label></td>
                    <td style="display:flex;">
                      <span style="flex:1;">${recipeItem.quantity}</span>
                      <span style="flex:1.2;">${recipeItem.color || "-"}</span>
                      <span style="flex:4;">${recipeItem.item || "-"}</span>
                    </td>
                  </tr>`;
                  })
                  .join("")}
                </td>
              </tr>`;
      })
      .join("");
  };

  const getAccessoriesContentV2 = () => {
    return accessories?.length
      ? accessories
          .map((addOn, index) => {
            const {
              accessoryPrice,
              // actualPrice,
              accessoryDesc,
              productFirstChoiceDescription,
              accessoryId,
              productFirstChoiceCode,
              designNotes,
              accessoryQty,
              quantity,
              lineItemAmounts,
            } = addOn;
            const qty = accessoryQty || quantity;
            const id = accessoryId || productFirstChoiceCode;

            const retailPrice = lineItemAmounts?.find(
              (amount) => amount.name === "retailProductAmount"
            ).value;

            const price = parseFloat(accessoryPrice || retailPrice);
            const pricePerUnit = price / qty;

            const desc = accessoryDesc || productFirstChoiceDescription;
            return `
                        <tr style="font-size:14px !important;font-weight:bold !important">
                <td><label>${Localise(messages, "Add-ons")} #${
              index + 1
            }</label></td>
                <td>${id ? " " + id : ""}${desc ? " " + desc : ""} 
                <td>${checkBoxPlaceholder()}</td>
                <tr style="font-size:14px !important;font-weight:bold !important">
                <td><label>${""}</label></td>
                <td>${` ${designNotes ? `Design Notes: ${designNotes}` : ""}
                `}</td></tr>
                <tr style="font-size:14px !important;font-weight:bold !important">
                <td><label>${""}</label></td>
                <td>${`  Qty: ${accessoryQty || quantity} ${
                  pricePerUnit
                    ? "@ $" + parseFloat(pricePerUnit).toFixed(2)
                    : ""
                }`}</td>              
                </tr>
              </tr>`;
          })
          .join("")
      : `<tr style="font-size:14px !important;font-weight:bold !important">
                <td><label>${Localise(messages, "Add-ons")}</label></td>
                <td></td>
              </tr>`;
  };

  const checkBoxPlaceholder = () =>
    `<img src="${IMAGES["checkbox-unchecked-dark"]}" width="13" height="13">`;

  const orderMessagesPrintContent = (message) => {
    const orderPrice =
      price.find((obj) => obj.name === "orderTotal")?.value || 0;
    return (
      orderTitle(
        message?.direction === "INBOUND" ? "Inbound" : "Outbound",
        "Chat"
      ) +
      orderInfoSection() +
      deliveryInfoSection() +
      relatedMessageInfo(Localise, messages, message, orderPrice)
    );
  };

  const orderDetailsPrintContent = () => {
    return (
      orderTitle(isSenderOrder ? "Outgoing" : "Incoming", "Order") +
      orderInfoSection() +
      deliveryInfoSection() +
      productInfoSection() +
      specialInstructionsSection()
    );
  };

  const orderTitle = (direction = "", type = "Order") => {
    return `<div class="table-responsive">
        <table class="table">
          <tbody>
            <tr>
              <td class="orderTypeHeading" style="width:82% !important">
              ${Localise(messages, direction)}${" - "}
              ${Localise(messages, `Mercury HQ ${type} Detail`)}${" - "}
              ${Localise(
                messages,
                `${
                  isWalkInOrder
                    ? isProductDigitalGC
                      ? "Digital"
                      : "Walk-In"
                    : isPickUpOrder
                    ? hasPendingPayLaterPayment
                      ? "Pickup - Unpaid"
                      : "Pickup"
                    : "Delivery"
                }`
              )}</td>
              <td style="font-size:18px;font-weight:bold !important;text-align: center;">
              ${Localise(
                messages,
                type === "Order" ? "Order Review" : "Review"
              )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>`;
  };

  const orderInfoSection = () => {
    return `<div class="table-responsive">
        <table class="table">
          <tbody>
            <tr style="font-size:14px !important;font-weight:bold !important">
            <td>
                <label>${Localise(messages, "Order Source")}</label>
              </td>
              <td>${orderOrigin}</td>
              <td rowspan="1">${checkBoxPlaceholder()}</td>
            </tr>
            <tr style="font-size:14px !important;">
              <td>
                <label>${Localise(messages, "Order Number")}</label>
              </td>
              <td>${messageNumber}</td>
              <td rowspan="6" style="padding-right: 0!important;">${qrPlaceHolder(
                qrImageData,
                { width: 100, height: 100 }
              )}</td>
            </tr>
            ${
              isSenderOrder
                ? `<tr style="font-size:14px !important;">
              <td>
                <label>${Localise(messages, "Receiving Shop Code")}</label>
              </td>
              <td>${
                deliveryMethod === "PHONE_OUT"
                  ? externalPartnerBusinessName
                  : `${recMemberCode} - ${recbusinessName}`
              }</td>
            </tr>
            <tr style="font-size:14px !important;">
              <td>
                <label>${Localise(messages, "Receiving Phone")} </label>
              </td>
              <td>${phoneNumberFormatter(recPhone)}</td>
            </tr>
            <tr style="font-size:14px !important;">
              <td>
                <label>${Localise(messages, "Sending Shop Code")}</label>
              </td>
              <td data-testid="sending_shop_details">${sendMemberCode} - ${sendBusinessName}</td>
            </tr>`
                : `<tr style="font-size:14px !important;">
                <td>
                  <label>${Localise(messages, "Sending Shop Code")}</label>
                </td>
                <td data-testid="sending_shop_details">${sendMemberCode} - ${sendBusinessName}</td>
              </tr>
              <tr style="font-size:14px !important;">
                <td>
                  <label>${Localise(messages, "Sending Phone")}</label>
                </td>
                <td data-testid="sendingShopPhone">${phoneNumberFormatter(
                  sendPhone
                )}</td>
              </tr>
              <tr style="font-size:14px !important;">
                <td>
                  <label>${Localise(messages, "Receiving Shop Code")}</label>
                </td>
                <td data-testid="receiving_shop_details">${recMemberCode} - ${recbusinessName}</td>
              </tr>`
            }
            <tr style="font-size:14px !important;">
              <td><label>${Localise(messages, "Operator")}</label></td>
              <td>${operatorName}</td>
            </tr>
            <tr style="font-size:14px !important;">
              <td><label>${Localise(messages, "Day & Time")}</label></td>
              <td>${moment().format("ddd MMM DD YYYY  hh:mm A ")}</td>
            </tr>
          </tbody>
        </table>
        <hr />
      </div>`;
  };

  const deliveryInfoSection = () => {
    return `<div class="table-responsive">
        <table class="table">
          <tbody>
              <tr style="font-size:14px !important;">
                <td style="font-weight:bold !important">
                  <label>${Localise(
                    messages,
                    `${isPickUpOrder ? "Pickup" : "Delivery"} Date`
                  )}*</label>
                </td>
                <td>
                  <span style="font-weight:bold !important">
                  ${moment(deliveryDate, "YYYY-MM-DD ").format(
                    "dddd, MMMM D, YYYY "
                  )}</span>
                </td>
                <td>${checkBoxPlaceholder()}</td>
              </tr>
              ${
                isPickUpOrder
                  ? `<tr style="font-size:14px !important; ">
                  <td><label>${Localise(messages, "Pickup By")}</label></td>
                  <td>${
                    isMOLPickUpOrder
                      ? delName
                      : isStorePickupOrder || isWalkInOrder
                      ? pickUpBy || customerName
                      : orderOrigin + " " + delName
                  }</td>
              </tr>
              <tr style="font-size:14px !important; ">
                <td><label>${Localise(messages, "Pickup Time")}</label></td>
                <td>${moment(storePickupDateTime || deliveryDate).format(
                  "h:mm A "
                )}</td>
                <td>${checkBoxPlaceholder()}</td>
              </tr>`
                  : `<tr style="font-size:14px !important;">
                <td><label>${Localise(messages, "Deliver To")}</label></td>
                <td>${delName}</td>
              </tr>
              <tr style="font-size:14px !important;">
                <td><label>${Localise(messages, "Location Type")}</label></td>
                <td>${delLocationType}</td>
                <td>${checkBoxPlaceholder()}</td>
              </tr>
              <tr style="font-size:14px !important;">
                <td><label>${Localise(messages, "Address")}</label></td>
                <td>${
                  delLocationName ? `<span>${delLocationName}</span><br/>` : ``
                }
                <span>${delAddress}${
                      delAddress2 ? `, ${delAddress2}` : ``
                    } </span><br/><span>${cityStateAndZip}</span></td>
              </tr>`
              }
              <tr style="font-size:14px !important;">
                <td><label>${Localise(messages, "Phone")}</label></td>
                <td><span>${
                  isStorePickupOrder || isWalkInOrder
                    ? phoneNumberFormatter(phones[0]) || ""
                    : phoneNumberFormatter(delPhone) || ""
                }</span></td>
              </tr>
              <tr style="font-size:14px !important;font-weight:bold !important">
                <td><label>${Localise(
                  messages,
                  "Delivery Instructions"
                )}</label></td>
                <td>${deliveryInstructionsFormatted || ``}</td>
                <td>${checkBoxPlaceholder()}</td>
              </tr>
          </tbody>
        </table>
        <hr />
      </div>`;
  };

  const productInfoSection = () => {
    return `<div class="table-responsive">
        <table class="table">
          <tbody>
            <tr style="font-size:14px !important;">
              <td>
                <label>${Localise(messages, "Total Order Price")}</label>
              </td>
              <td>$${parseFloat(
                priceIncludingDelivery ||
                  price.find((obj) => obj.name === "orderTotal")?.value ||
                  0
              ).toFixed(2)}</td>
            </tr>
            ${
              printVersion === 1
                ? isSinglePrint
                  ? getSinglePrintProductLineItem()
                  : getAutoPrintProductLineItem()
                : isSinglePrint
                ? getSinglePrintProductLineItemV2()
                : getAutoPrintProductLineItemV2()
            }
            ${
              printVersion === 1
                ? getAccessoriesContent()
                : getAccessoriesContentV2()
            }
            <tr style="font-size:14px !important;">
              <td><label>${Localise(messages, "Occasion")}</label></td>
              <td>${startCase(toLower(occasion))}</td>
            </tr>
            <tr style="font-size:14px !important;">
              <td><label>${Localise(messages, "Card Message")}</label></td>
              <td class="cardMessage editorContent">${formatCardMessage(
                cardMessage
              )}</td>
              <td>${checkBoxPlaceholder()}</td>
            </tr>
            ${
              !isFolMolOrder
                ? `<tr style="font-size:14px !important;">
              <td><label>${Localise(
                messages,
                "Special Instructions"
              )}</label></td>
              <td style="white-space: pre-wrap !important;">${specialInstructions
                .split("\\n")
                .join("\n")}</td>
              <td>${checkBoxPlaceholder()}</td>
            </tr>`
                : ``
            }
          </tbody>
        </table>
      </div>`;
  };

  const specialInstructionsSection = () => {
    return isFolMolOrder
      ? `<div style="break-after:page"></div>
          ${orderTitle(isSenderOrder ? "Outgoing" : "Incoming", "Order")}
          ${orderInfoSection()}
          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr style="font-size:14px !important;">
                  <td><label>${Localise(
                    messages,
                    "Special Instructions"
                  )}</label></td>
                  <td style="white-space: pre-wrap !important;">${specialInstructions
                    .split("\\n")
                    .join("\n")}</td>
                  <td>${checkBoxPlaceholder()}</td>
                </tr>
              </tbody>
            </table>
          </div>`
      : ``;
  };
  const getIframe = (message) => {
    let iframeId = "iframe";

    if (isSinglePrint) {
      if (isObject(message)) {
        iframeId = messageDirectionToPrint
          ? message?.direction === "INBOUND"
            ? "orderInboundMessages"
            : "orderOutboundMessages"
          : "orderMessages";
      }
    }
    return iframeId;
  };

  const htmlContent = eligibleMessages.map((message) => {
    return `${
      isSinglePrint
        ? `<!DOCTYPE html>
        <html xmlns="http://www.w3.org/1999/xhtml">
        <head><title>Mercury HQ</title></head>
        <body onload="window.parent.postMessage({ action: '${getIframe(
          message
        )}-loaded' }, '*');">
          <div style="display: none">
            <style type="text/css" media="print,screen">
              ${getCSS({ isSinglePrint })}
            </style>
          </div>`
        : ``
    }
          <div class="printOrderContainer">
            <div style="margin: 10px; background-color: white; padding:10px;" class="page-break-flow-root">
              <div style="margin-top: -10px !important"></div>
              ${
                !isObject(message)
                  ? orderDetailsPrintContent()
                  : orderMessagesPrintContent(message)
              }
            </div>
          </div>
          ${
            isSinglePrint
              ? `</body>
          </html>`
              : ``
          }
        `;
  });

  return htmlContent.join("");
};

export const getMultiOrderPrintHTML = ({
  data,
  memberCodes,
  operatorName,
  messages = {},
  Localise = () => {},
  customerInfoList = [],
  iframeId = "iframe",
  qrImagesData,
  printType = "orderDetails",
}) => {
  // const printVersion = Environment.get("PRINT_VERSION", 1);
  const displayOrders = data
    .map((order, index) => {
      const {
        // deliveryInfo = {},
        // lineItems = [],
        // multiProductEligible = false,
        // messageNumber = "",
        // price = [],
        // recipientInfo = {},
        // receivingMember = {},
        // sendingMember = {},
        // specialInstructions = "",
        // orderSource = "",
        // pickupInfo,
        // direction,
        // hasPendingPayLaterPayment = false,
        orderItemId,
        // externalPartnerBusinessName = "",
        // giftCardType = "",
        // messages: orderMessages,
      } = order || {};

      // let floristShopContactSettings = "";

      // const isSenderOrder =
      //   (direction === "OUTBOUND" &&
      //     memberCodes.includes(sendingMember.memberCode) &&
      //     sendingMember.memberCode !== receivingMember.memberCode) ||
      //   deliveryMethod === "PHONE_OUT";

      // const shopPreferences = UserProfileStorage.getShopPreferences(
      //   isSenderOrder ? sendingMember.memberCode : receivingMember.memberCode
      // );

      // let isShopNameOverridden = false;
      // let isPhoneOverridden = false;

      // floristShopContactSettings = get(
      //   shopPreferences,
      //   "florist_contact_information_settings",
      //   ""
      // );
      // if (
      //   floristShopContactSettings == "JDE" ||
      //   floristShopContactSettings == ""
      // ) {
      //   floristShopContactSettings =
      //     '{"shopName":"","streetAddress":"","aptSuite":"","city":"","state":"","zip":"","phone":"","email":"","website":"","additionalMessage":"","contactName":"","printed":[],"web":[],"network":[]}';
      // }

      // let {
      //   shopName = "",
      //   phone = "",
      //   printed = [],
      // } = JSON.parse(floristShopContactSettings);

      // if (printed.includes("shopName") && shopName != "") {
      //   isShopNameOverridden = true;
      // }
      // if (printed.includes("phone") && phone != "") {
      //   isPhoneOverridden = true;
      // }
      // const messageDirection = printType.includes("InboundMessages")
      //   ? "INBOUND"
      //   : printType.includes("OutboundMessages")
      //   ? "OUTBOUND"
      //   : "";

      // const eligibleMessages =
      //   printType === "orderDetails"
      //     ? ["orderDetails"]
      //     : orderMessages.filter((message) => {
      //         const { messageType, isPrinted, direction } = message;
      //         return (
      //           messageType !== "OLC" &&
      //           !isPrinted &&
      //           (messageDirection ? direction === messageDirection : true)
      //         );
      //       });

      // const checkBoxPlaceholder = () =>
      //   `<img src="${IMAGES["checkbox-unchecked-dark"]}" width="13" height="13">`;

      const customerInfo = customerInfoList[index] || {};

      const qrImageData = qrImagesData[orderItemId];
      // const {
      //   cardMessage,
      //   deliveryDate,
      //   deliveryInstructions,
      //   occasion,
      //   deliveryMethod = "",
      //   pickUpBy,
      // } = deliveryInfo;
      // const { accessories: oldAccessories = [] } = lineItems[0] || {};

      // const accessories = multiProductEligible
      //   ? lineItems.filter((lineItem) => lineItem.type === "Addon")
      //   : oldAccessories;

      // const products = multiProductEligible
      //   ? lineItems.filter((lineItem) => lineItem.type === "Product")
      //   : lineItems;

      // const {
      //   addressLine1: delAddress = "",
      //   addressLine2: delAddress2 = "",
      //   city: delCity = "",
      //   firstName = "",
      //   lastName = "",
      //   phone: delPhone = "",
      //   state: delState = "",
      //   zip: delZip = "",
      //   locationType: delLocationType = "",
      //   locationName: delLocationName = "",
      // } = recipientInfo;

      // const {
      //   firstName: custFirstName = "",
      //   lastName: custLastName = "",
      //   businessName = "",
      //   customerType = "",
      //   isBusinessProfile = false,
      //   phones = [],
      // } = customerInfo;

      // const isBusinessAccount =
      //   isBusinessProfile || customerType === "Business";
      // const customerName = isBusinessAccount
      //   ? businessName
      //   : `${custFirstName} ${custLastName}`.trim();

      // const delName = firstName + " " + lastName;

      // let {
      //   businessName: recbusinessName = "",
      //   memberCode: recMemberCode,
      //   phone: recPhone = "",
      // } = receivingMember;

      // let {
      //   businessName: sendBusinessName = "",
      //   memberCode: sendMemberCode,
      //   phone: sendPhone = "",
      // } = sendingMember;

      // if (
      //   ((deliveryMethod === "FLORIST_DELIVERED" ||
      //     deliveryMethod === "STORE_PICKUP" ||
      //     deliveryMethod === "WALK_IN") &&
      //     sendMemberCode === recMemberCode) ||
      //   isSenderOrder === true
      // ) {
      //   sendBusinessName = isShopNameOverridden ? shopName : sendBusinessName;
      //   sendPhone = isPhoneOverridden ? phone : sendPhone;
      // }
      // if (sendMemberCode === recMemberCode || isSenderOrder === false) {
      //   recbusinessName = isShopNameOverridden ? shopName : recbusinessName;
      //   recPhone = isPhoneOverridden ? phone : recPhone;
      // }

      // let orderPrice =
      //   price.find((obj) => obj.name === "orderTotal")?.value || 0;
      // const { storePickupDateTime = "" } = pickupInfo || {};
      // const isPickUpOrder =
      //   orderSource === "DoorDash" ||
      //   orderSource === "UberEats" ||
      //   (orderSource === "MOL" && deliveryMethod === "MOL_CUSTOMER_PICKUP") ||
      //   deliveryMethod === "STORE_PICKUP" ||
      //   deliveryMethod === "WALK_IN";

      // const isMOLPickUpOrder =
      //   orderSource === "MOL" && deliveryMethod === "MOL_CUSTOMER_PICKUP";
      // const isStorePickupOrder = deliveryMethod === "STORE_PICKUP";
      // const isWalkInOrder = deliveryMethod === "WALK_IN";
      // const isProductDigitalGC = giftCardType === "DIGITAL_GIFT_CARDS";
      // const isFolMolOrder = orderSource === "FOL" || orderSource === "MOL";
      // const orderOrigin = isPickUpOrder
      //   ? orderSource === "UberEats"
      //     ? "Uber Eats"
      //     : orderSource === "DoorDash"
      //     ? "DoorDash"
      //     : orderSource === "MOL"
      //     ? "My Website"
      //     : ["Bloomnet", "Teleflora", "TEL eFlorist", "Email Order"].includes(
      //         orderSource
      //       )
      //     ? orderSource
      //     : "FTD"
      //   : orderSource === "MOL"
      //   ? "My Website"
      //   : ["Bloomnet", "Teleflora", "TEL eFlorist", "Email Order"].includes(
      //       orderSource
      //     )
      //   ? orderSource
      //   : "FTD";
      // let accessoriesDescription = "";
      // accessories.map((accessory) => {
      //   accessoriesDescription = `${accessoriesDescription} PRICE INCLUDES 1 ${
      //     accessory?.accessoryId || ""
      //   }-${accessory?.accessoryDesc}($${accessory?.accessoryPrice}), ${
      //     accessory?.designNotes ? `Design notes: ${accessory.designNotes}` : ""
      //   }   `;
      // });

      // let deliveryInstructionsFormatted = deliveryInstructions;
      // if (
      //   orderSource === "MOL" &&
      //   deliveryMethod === "MOL_CUSTOMER_PICKUP" &&
      //   !!deliveryInstructions
      // ) {
      //   const [molPickupValue, ...otherDelInstructions] =
      //     deliveryInstructions.split(",");
      //   if (!!molPickupValue && !molPickupValue.includes("\\n")) {
      //     deliveryInstructionsFormatted = otherDelInstructions.join(",");
      //   } else {
      //     const [molPickupValue, ...otherDelInstructions] =
      //       deliveryInstructions.split("\\n");
      //     if (
      //       !!molPickupValue &&
      //       moment(molPickupValue).format("h:mm A") !== "Invalid date"
      //     ) {
      //       deliveryInstructionsFormatted = otherDelInstructions;
      //     }
      //   }
      // }

      // const cityStateAndZip = `${
      //   delCity && delState ? `${delCity}, ${delState}` : delCity || delState
      // } ${delZip}`;

      //   const getProductLineItem = () => {
      //     let recipeContent = "";
      //     let productContent = "";
      //     let isFirstRecipe = true;

      //     products.map((product, index) => {
      //       const {
      //         productFirstChoiceCode = "",
      //         productFirstChoiceDescription = "",
      //         productSecondChoiceCode = "",
      //         productSecondChoiceDescription = "",
      //         designNotes = "",
      //         quantity = 1,
      //         recipe = "",
      //       } = product;

      //       let recipeList = [];
      //       if (recipe.length) {
      //         try {
      //           recipeList = JSON.parse(recipe);
      //         } catch (e) {
      //           try {
      //             recipe.split(",").map((ele) => {
      //               const [f1, ...other] = ele.trim().split(" ");
      //               recipeList.push({
      //                 quantity: f1,
      //                 item: other.join(" "),
      //               });
      //             });
      //           } catch (e) {
      //             console.log("Failed to parse recipe info");
      //           }
      //         }
      //       }

      //       recipeContent =
      //         recipeContent +
      //         recipeList
      //           .map((recipeItem, idx) => {
      //             if (idx === 0) {
      //               const content = `<tr style="font-size:14px !important;">
      //             <td>
      //               <label>${
      //                 isFirstRecipe ? Localise(messages, "Recipe") : ""
      //               }</label>
      //             </td>
      //             <td style="display:flex;">
      //             ${`${productFirstChoiceCode ? productFirstChoiceCode : ""}${
      //               productFirstChoiceDescription
      //                 ? " - " + productFirstChoiceDescription
      //                 : ""
      //             }`}
      //             </td>
      //             <td>${isFirstRecipe ? checkBoxPlaceholder() : ""}</td>
      //           </tr>
      //           <tr style="font-size:14px !important;">
      //             <td>
      //               <label></label>
      //             </td>
      //             <td style="display:flex;">
      //               <span style="flex:0.5;">${recipeItem.quantity}</span>
      //               <span style="flex:1.2;">${recipeItem.color || "-"}</span>
      //               <span style="flex:4;">${recipeItem.item || "-"}</span>
      //             </td>
      //             <td></td>
      //           </tr>`;
      //               isFirstRecipe = false;
      //               return content;
      //             } else {
      //               return `<tr style="font-size:14px !important;">
      //             <td>
      //               <label></label>
      //             </td>
      //             <td style="display:flex;">
      //               <span style="flex:0.5;">${recipeItem.quantity}</span>
      //               <span style="flex:1.2;">${recipeItem.color || "-"}</span>
      //               <span style="flex:4;">${recipeItem.item || "-"}</span>
      //             </td>
      //             <td></td>
      //           </tr>`;
      //             }
      //           })
      //           .join("");

      //       let productDescription = `${productFirstChoiceCode} ${productFirstChoiceDescription}`;

      //       let productSecondChoice = formatProductSecondChoice(
      //         productSecondChoiceCode,
      //         productSecondChoiceDescription,
      //         true
      //       );

      //       if (productSecondChoice.length > 0)
      //         productDescription = `${productDescription}, ${Localise(
      //           messages,
      //           "Second Choice"
      //         )}: ${productSecondChoice}`;

      //       productContent =
      //         productContent +
      //         `${
      //           index ? ", " : ""
      //         }${productDescription} Qty ${quantity} ${designNotes}`;

      //       return productContent;
      //     });

      //     return `<tr style="font-size:14px !important;font-weight:bold !important">
      //         <td><label>${Localise(messages, "Product")}</label></td>
      //         <td>
      //         ${productContent || Localise(messages, "NONE")}
      //           </td>
      //           <td>${checkBoxPlaceholder()}</td>
      //       </tr>${recipeContent}`;
      //   };

      //   const getAccessoriesContent = () => {
      //     return accessories?.length
      //       ? accessories
      //           .map((addOn, index) => {
      //             const {
      //               // accessoryPrice,
      //               // actualPrice,
      //               accessoryDesc,
      //               productFirstChoiceDescription,
      //               accessoryId,
      //               productFirstChoiceCode,
      //               designNotes,
      //               accessoryQty,
      //               quantity,
      //             } = addOn;
      //             const qty = accessoryQty || quantity || 1;
      //             const id = accessoryId || productFirstChoiceCode;
      //             // const price = accessoryPrice || actualPrice;
      //             const desc = accessoryDesc || productFirstChoiceDescription;
      //             return `<tr style="font-size:14px !important;font-weight:bold !important">
      //             <td><label>${
      //               index === 0 ? Localise(messages, "Add-ons") : ""
      //             }</label></td>
      //             <td>${id ? " " + id : ""}${desc ? " " + desc : ""}${
      //               qty ? " Qty " + qty : ""
      //             }${designNotes ? " " + designNotes : ""}</td>
      //                     <td>${checkBoxPlaceholder()}</td>`;
      //           })
      //           .join("")
      //       : `<tr style="font-size:14px !important;font-weight:bold !important">
      //             <td><label>${Localise(messages, "Add-ons")}</label></td>
      //             <td></td>
      //           </tr>`;
      //   };

      //   const getProductLineItemV2 = () => {
      //     return products
      //       .map((product, index) => {
      //         const {
      //           productFirstChoiceCode = "",
      //           productFirstChoiceDescription = "",
      //           productSecondChoiceCode = "",
      //           productSecondChoiceDescription = "",
      //           designNotes = "",
      //           quantity = 1,
      //           lineItemAmounts = [],
      //           recipe = "",
      //         } = product;

      //         let productDescription = "";

      //         if (productFirstChoiceCode.length > 0)
      //           productDescription = productFirstChoiceCode;

      //         if (productFirstChoiceDescription.length > 0)
      //           productDescription = productDescription.concat(
      //             ` ${productFirstChoiceDescription}`
      //           );

      //         let productSecondChoice = formatProductSecondChoice(
      //           productSecondChoiceCode,
      //           productSecondChoiceDescription,
      //           true
      //         );

      //         if (productSecondChoice.length > 0)
      //           productDescription = `${productDescription}, ${Localise(
      //             messages,
      //             "Second Choice"
      //           )}: ${productSecondChoice}`;

      //         const pricePerUnit =
      //           lineItemAmounts?.find(
      //             (amount) => amount.name === "retailProductAmount"
      //           ).value / quantity;

      //         let recipeList = [];
      //         if (recipe.length) {
      //           try {
      //             recipeList = JSON.parse(recipe);
      //           } catch (e) {
      //             try {
      //               recipe.split(",").map((ele) => {
      //                 const [f1, ...other] = ele.trim().split(" ");
      //                 recipeList.push({
      //                   quantity: f1,
      //                   item: other.join(" "),
      //                 });
      //               });
      //             } catch (e) {
      //               console.log("Failed to parse recipe info");
      //             }
      //           }
      //         }

      //         return `<tr style="font-size:14px !important;font-weight:bold !important">
      //                 <td><label>${Localise(messages, "Product and Recipe")} #${
      //           index + 1
      //         }</label></td>
      //                 <td>${productDescription}</td>
      //                 <td>${checkBoxPlaceholder()}</td>
      //                 <tr style="font-size:14px !important;font-weight:bold !important">
      //                 <td><label>${""}</label></td>
      //                 <td>${` ${designNotes ? `Design Notes: ${designNotes}` : ""}
      //                 `}</td></tr>
      //                 <tr style="font-size:14px !important;font-weight:bold !important">
      //                 <td><label>${""}</label></td>
      //                 <td>
      //                   ${`  Qty: ${quantity ? " " + quantity : ""}
      //                   ${
      //                     pricePerUnit
      //                       ? "@ $" + parseFloat(pricePerUnit).toFixed(2)
      //                       : ""
      //                   }`}
      //                 </td>
      //                 </tr>
      //                 <td>
      //                 ${recipeList
      //                   .map((recipeItem) => {
      //                     return `<tr style="font-size:14px !important;">
      //                     <td><label>${""}</label></td>
      //                     <td style="display:flex;">
      //                       <span style="flex:1;">${recipeItem.quantity}</span>
      //                       <span style="flex:1.2;">${
      //                         recipeItem.color || "-"
      //                       }</span>
      //                       <span style="flex:4;">${recipeItem.item || "-"}</span>
      //                     </td>
      //                   </tr>`;
      //                   })
      //                   .join("")}
      //                 </td>
      //               </tr>`;
      //       })
      //       .join("");
      //   };

      //   const getAccessoriesContentV2 = () => {
      //     return accessories?.length
      //       ? accessories
      //           .map((addOn, index) => {
      //             const {
      //               accessoryPrice,
      //               // actualPrice,
      //               accessoryDesc,
      //               productFirstChoiceDescription,
      //               accessoryId,
      //               productFirstChoiceCode,
      //               designNotes,
      //               accessoryQty,
      //               quantity,
      //               lineItemAmounts,
      //             } = addOn;
      //             const qty = accessoryQty || quantity;
      //             const id = accessoryId || productFirstChoiceCode;

      //             const retailPrice = lineItemAmounts?.find(
      //               (amount) => amount.name === "retailProductAmount"
      //             ).value;

      //             const price = parseFloat(accessoryPrice || retailPrice);
      //             const pricePerUnit = price / qty;

      //             const desc = accessoryDesc || productFirstChoiceDescription;
      //             return `
      //                     <tr style="font-size:14px !important;font-weight:bold !important">
      //             <td><label>${Localise(messages, "Add-ons")} #${
      //               index + 1
      //             }</label></td>
      //             <td>${id ? " " + id : ""}${desc ? " " + desc : ""}
      //             <td>${checkBoxPlaceholder()}</td>
      //             <tr style="font-size:14px !important;font-weight:bold !important">
      //             <td><label>${""}</label></td>
      //             <td>${` ${designNotes ? `Design Notes: ${designNotes}` : ""}
      //             `}</td></tr>
      //             <tr style="font-size:14px !important;font-weight:bold !important">
      //             <td><label>${""}</label></td>
      //             <td>${`  Qty: ${accessoryQty || quantity} ${
      //               pricePerUnit
      //                 ? "@ $" + parseFloat(pricePerUnit).toFixed(2)
      //                 : ""
      //             }`}</td>
      //             </tr>
      //           </tr>`;
      //           })
      //           .join("")
      //       : `<tr style="font-size:14px !important;font-weight:bold !important">
      //             <td><label>${Localise(messages, "Add-ons")}</label></td>
      //             <td></td>
      //           </tr>`;
      //   };

      //   const orderTitle = (direction = "", type = "Order") => {
      //     return `<div class="table-responsive">
      //         <table class="table">
      //           <tbody>
      //             <tr>
      //               <td class="orderTypeHeading" style="width:82% !important">
      //               ${Localise(messages, direction)}${" - "}
      //               ${Localise(messages, `Mercury HQ ${type} Detail`)}${" - "}
      //               ${Localise(
      //                 messages,
      //                 `${
      //                   isWalkInOrder
      //                     ? isProductDigitalGC
      //                       ? "Digital"
      //                       : "Walk-In"
      //                     : isPickUpOrder
      //                     ? hasPendingPayLaterPayment
      //                       ? "Pickup - Unpaid"
      //                       : "Pickup"
      //                     : "Delivery"
      //                 }`
      //               )}</td>
      //               <td style="font-size:18px;font-weight:bold !important;text-align: center;">
      //               ${Localise(
      //                 messages,
      //                 type === "Order" ? "Order Review" : "Review"
      //               )}
      //               </td>
      //             </tr>
      //           </tbody>
      //         </table>
      //       </div>`;
      //   };

      //   const orderInfoSection = () => {
      //     return `<div class="table-responsive">
      //         <table class="table">
      //           <tbody>
      //             <tr style="font-size:14px !important;font-weight:bold !important">
      //               <td>
      //                 <label>${Localise(messages, "Order Source")}</label>
      //               </td>
      //               <td>${orderOrigin}</td>
      //               <td rowspan="1">${checkBoxPlaceholder()}</td>
      //             </tr>
      //             <tr style="font-size:14px !important;">
      //               <td>
      //                 <label>${Localise(messages, "Order Number")}</label>
      //               </td>
      //               <td>${messageNumber}</td>
      //                <td rowspan="6" style="padding-right: 0!important;">${qrPlaceHolder(
      //                  qrImageData,
      //                  { width: 100, height: 100 }
      //                )}</td>
      //             </tr>
      //             ${
      //               isSenderOrder
      //                 ? `<tr style="font-size:14px !important;">
      //               <td>
      //                 <label>${Localise(messages, "Receiving Shop Code")}</label>
      //               </td>
      //                <td data-testid="receiving_shop_details">${
      //                  deliveryMethod === "PHONE_OUT"
      //                    ? externalPartnerBusinessName
      //                    : `${recMemberCode} - ${recbusinessName}`
      //                }</td>
      //             </tr>
      //             <tr style="font-size:14px !important;">
      //               <td>
      //                 <label>${Localise(messages, "Receiving Phone")} </label>
      //               </td>
      //               <td>${phoneNumberFormatter(recPhone)}</td>
      //             </tr>
      //             <tr style="font-size:14px !important;">
      //               <td>
      //                 <label>${Localise(messages, "Sending Shop Code")}</label>
      //               </td>
      //               <td data-testid="sending_shop_details">${sendMemberCode} - ${sendBusinessName}</td>
      //             </tr>`
      //                 : `<tr style="font-size:14px !important;">
      //                 <td>
      //                   <label>${Localise(messages, "Sending Shop Code")}</label>
      //                 </td>
      //                 <td data-testid="sending_shop_details">${sendMemberCode} - ${sendBusinessName}</td>
      //               </tr>
      //               <tr style="font-size:14px !important;">
      //                 <td>
      //                   <label>${Localise(messages, "Sending Phone")}</label>
      //                 </td>
      //                 <td>${phoneNumberFormatter(sendPhone)}</td>
      //               </tr>
      //               <tr style="font-size:14px !important;">
      //                 <td>
      //                   <label>${Localise(
      //                     messages,
      //                     "Receiving Shop Code"
      //                   )}</label>
      //                 </td>
      //                  <td data-testid="receiving_shop_details">${recMemberCode} - ${recbusinessName}</td>
      //               </tr>`
      //             }
      //             <tr style="font-size:14px !important;">
      //               <td><label>${Localise(messages, "Operator")}</label></td>
      //               <td>${operatorName}</td>
      //             </tr>
      //             <tr style="font-size:14px !important;">
      //               <td><label>${Localise(messages, "Day & Time")}</label></td>
      //               <td>${moment().format("ddd MMM DD YYYY hh:mm A ")}</td>
      //             </tr>
      //           </tbody>
      //         </table>
      //         <hr />
      //       </div>`;
      //   };

      //   const deliveryInfoSection = () => {
      //     return `<div class="table-responsive">
      //         <table class="table">
      //           <tbody>
      //             <tr style="font-size:14px !important;">
      //               <td style="font-weight:bold !important">
      //                 <label>${Localise(
      //                   messages,
      //                   `${isPickUpOrder ? "Pickup" : "Delivery"} Date`
      //                 )}*</label>
      //               </td>
      //               <td>
      //                 <span style="font-weight:bold !important">
      //                 ${moment(deliveryDate, "YYYY-MM-DD ").format(
      //                   "dddd, MMMM D, YYYY "
      //                 )}</span>
      //               </td>
      //               <td>${checkBoxPlaceholder()}</td>
      //             </tr>
      //               ${
      //                 isPickUpOrder
      //                   ? `<tr style="font-size:14px !important; ">
      //                   <td><label>${Localise(messages, "Pickup By")}</label></td>
      //                   <td>${
      //                     isMOLPickUpOrder
      //                       ? delName
      //                       : isStorePickupOrder || isWalkInOrder
      //                       ? pickUpBy || customerName
      //                       : orderOrigin + " " + delName
      //                   }</td>
      //               </tr>
      //               <tr style="font-size:14px !important; ">
      //                 <td><label>${Localise(messages, "Pickup Time")}</label></td>
      //                 <td>${moment(storePickupDateTime || deliveryDate).format(
      //                   "h:mm A "
      //                 )}</td>
      //                 <td>${checkBoxPlaceholder()}</td>
      //               </tr>`
      //                   : `<tr style="font-size:14px !important;">
      //                 <td><label>${Localise(messages, "Deliver To")}</label></td>
      //                 <td>${delName}</td>
      //               </tr>
      //               <tr style="font-size:14px !important;">
      //                 <td><label>${Localise(
      //                   messages,
      //                   "Location Type"
      //                 )}</label></td>
      //                 <td>${delLocationType}</td>
      //                 <td>${checkBoxPlaceholder()}</td>
      //               </tr>
      //               <tr style="font-size:14px !important;">
      //                 <td><label>${Localise(messages, "Address")}</label></td>
      //                 <td>${
      //                   delLocationName
      //                     ? `<span>${delLocationName}</span><br/>`
      //                     : ``
      //                 }
      //                 <span>${delAddress}${
      //                       delAddress2 ? `, ${delAddress2}` : ``
      //                     } </span><br/><span>${cityStateAndZip}</span></td>
      //               </tr>`
      //               }
      //               <tr style="font-size:14px !important;">
      //                 <td><label>${Localise(messages, "Phone")}</label></td>
      //                 <td><span>${
      //                   isStorePickupOrder || isWalkInOrder
      //                     ? phoneNumberFormatter(phones[0]) || ""
      //                     : phoneNumberFormatter(delPhone) || ""
      //                 }</span></td>
      //               </tr>
      //               <tr style="font-size:14px !important;font-weight:bold !important">
      //                 <td><label>${Localise(
      //                   messages,
      //                   "Delivery Instructions"
      //                 )}</label></td>
      //                 <td>${deliveryInstructionsFormatted || ""}</td>
      //                 <td>${checkBoxPlaceholder()}</td>
      //               </tr>
      //           </tbody>
      //         </table>
      //         <hr />
      //       </div>`;
      //   };

      //   const productInfoSection = () => {
      //     return `<div class="table-responsive">
      //       <table class="table">
      //         <tbody>
      //           <tr style="font-size:14px !important;">
      //             <td>
      //               <label>${Localise(messages, "Total Order Price")}</label>
      //             </td>
      //             <td>$${parseFloat(orderPrice).toFixed(2)}</td>
      //           </tr>
      //           ${
      //             printVersion === 1
      //               ? getProductLineItem()
      //               : getProductLineItemV2()
      //           }
      //           ${
      //             printVersion === 1
      //               ? getAccessoriesContent()
      //               : getAccessoriesContentV2()
      //           }
      //           <tr style="font-size:14px !important;">
      //             <td><label>${Localise(messages, "Occasion")}</label></td>
      //             <td>${startCase(toLower(occasion))}</td>
      //           </tr>
      //           <tr style="font-size:14px !important;">
      //             <td><label>${Localise(messages, "Card Message")}</label></td>
      //             <td class="cardMessage editorContent">${formatCardMessage(
      //               cardMessage
      //             )}</td>
      //             <td>${checkBoxPlaceholder()}</td>
      //           </tr>
      //           ${
      //             !isFolMolOrder
      //               ? `<tr style="font-size:14px !important;">
      //             <td><label>${Localise(
      //               messages,
      //               "Special Instructions"
      //             )}</label></td>
      //             <td style="white-space: pre-wrap !important;">${specialInstructions
      //               .split("\\n")
      //               .join("\n")}</td>
      //             <td>${checkBoxPlaceholder()}</td>
      //           </tr>`
      //               : ``
      //           }
      //         </tbody>
      //       </table>
      //     </div>`;
      //   };

      //   const specialInstructionsSection = () => {
      //     return isFolMolOrder
      //       ? `<div style="break-after:page"></div>
      //         ${orderTitle(isSenderOrder ? "Outgoing" : "Incoming", "Order")}
      //         ${orderInfoSection()}
      //       <div class="table-responsive">
      //         <table class="table">
      //           <tbody>
      //             <tr style="font-size:14px !important;">
      //               <td><label>${Localise(
      //                 messages,
      //                 "Special Instructions"
      //               )}</label></td>
      //               <td style="white-space: pre-wrap !important;">${specialInstructions
      //                 .split("\\n")
      //                 .join("\n")}</td>
      //               <td>${checkBoxPlaceholder()}</td>
      //             </tr>
      //           </tbody>
      //         </table>
      //       </div>`
      //       : ``;
      //   };

      //   const orderDetailsPrintContent = () => {
      //     return (
      //       orderTitle(isSenderOrder ? "Outgoing" : "Incoming", "Order") +
      //       orderInfoSection() +
      //       deliveryInfoSection() +
      //       productInfoSection() +
      //       specialInstructionsSection()
      //     );
      //   };

      // const orderMessagesPrintContent = (message) => {
      //   return (
      //     orderTitle(
      //       message?.direction === "INBOUND" ? "Inbound" : "Outbound",
      //       "Chat"
      //     ) +
      //     orderInfoSection() +
      //     deliveryInfoSection() +
      //     relatedMessageInfo(Localise, messages, message, orderPrice)
      //   );
      // };

      // const htmlContent = eligibleMessages.map((message) => {
      //   return `<div class="printOrderContainer">
      //       <div  style="margin: 10px; background-color: white; padding:10px;" class="page-break-flow-root">
      //         <div style="margin-top: -25px !important"></div>
      //         ${
      //           !isObject(message)
      //             ? orderDetailsPrintContent()
      //             : orderMessagesPrintContent(message)
      //         }

      //       </div>
      //     </div>`;
      // });

      return getSingleOrderPrintHTML({
        orderDetails: order,
        memberCodes,
        operatorName,
        messages,
        Localise,
        customerInfo,
        qrImageData,
        printType,
        isSinglePrint: false,
      });
    })
    .join("");

  return `<!DOCTYPE html>
       <html xmlns="http://www.w3.org/1999/xhtml">
          <head>
             <title>Mercury HQ</title>
          </head>
          <body onload="window.parent.postMessage({ action: '${iframeId}-loaded' }, '*');">
             <div style="display: none">
                <style type="text/css" media="print,screen">
                ${getCSS({ isSinglePrint: false })}
                </style>
             </div>
             <div>
                ${displayOrders}
             </div>
          </body>
       </html>`;
};
