export const defaultColors = [
  "#cb63d6",
  "#db6451",
  "#d19a47",
  "#c9d130",
  "#6dd136",
  "#12cc9e",
  "#1b84cf",
  "#372f96",
  "#962cb0",
  "#c6e2ff",
  "#b0e0e6",
  "#faebd7",
  "#eeeeee",
  "#f08080",
  "#afeeee",
  "#66cccc",
  "#3399ff",
  "#ff80ed",
  "#e6e6fa",
  "#d3ffce",
  "#c470b3",
];
