export const getUIConfig = (Localise, messages) => [
  {
    name: "dashboard",
    title: Localise(messages, "Dashboard"),
    uri: "/admin/dashboards",
  },
  {
    name: "reports",
    title: Localise(messages, "Reports"),
    uri: "/admin/reports",
  },
  {
    name: "preview",
    title: Localise(messages, "Live View"),
    uri: "/admin/dashboards/live",
  },
];
