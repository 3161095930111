import { put, call, select, delay } from "redux-saga/effects";

import {
  setApiResponse,
  setApiError,
  setPageData,
  setPageInitSuccess,
  setPageInitProgress,
  setPageInitFailure,
  setPageAction,
  setPageSearch,
  fetchOrderDetails,
} from "../slice";
import {
  selectApiResponse,
  selectSearchText,
  selectShopCode,
} from "../selector";

import { request } from "library/utils/request";

import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import moment from "moment";

export function* handleFetchAllRefunds() {
  const searchText = yield select(selectSearchText);

  const serviceRequest = (selectedShopCode) =>
    request("mol-order-search", {
      operatorID: searchText,
      memberCodes: [selectedShopCode],
      isOrderDetails: false,
    });

  const section = "refunds";

  try {
    if (searchText.length > 3) {
      const selectedShopCode = yield select(selectShopCode);

      if (selectedShopCode) {
        yield delay(1000);

        const { molOrderSearchDetails } = yield call(
          serviceRequest,
          selectedShopCode
        );

        yield put(
          setApiResponse({
            section,
            content: molOrderSearchDetails,
          })
        );
      }
    } else {
      yield put(
        setPageAction({
          type: "noRecordsMessage",
          value: "Search for an order to see details",
        })
      );
      yield put(
        setApiResponse({
          section,
          content: [],
        })
      );
    }
    yield put(setPageInitSuccess());
  } catch (error) {
    yield put(
      setPageAction({
        type: "noRecordsMessage",
        value: "No Orders Found",
      })
    );
    yield put(
      setApiResponse({
        section,
        content: [],
      })
    );
    yield put(setPageInitFailure());
    yield put(
      setApiError({
        section,
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleUIRefresh() {
  const { refunds = [] } = yield select(selectApiResponse);
  const newData = applyPageActions({ refunds });
  yield put(setPageData(newData));
}

export function* handleFetchOrderDetails(action = {}) {
  const { params } = get(action, "payload", {});
  const { orderId, memberCode } = params;

  const serviceRequest = () =>
    request("mol-order-search", {
      operatorID: orderId,
      shopCode: memberCode,
      isOrderDetails: true,
    });

  try {
    const { molOrderSearchDetails, orderAmounts } = yield call(serviceRequest);

    const orderDetailsData = processMOLOrdersResponse({
      molOrderSearchDetails,
      orderAmounts,
    });

    yield put(
      setApiResponse({
        section: "order-details",
        content: orderDetailsData,
      })
    );
  } catch (error) {
    console.log("error:", error);
    //Do nothing
  }
}

export function* handleRefundPayment(action = {}) {
  yield put(setPageInitProgress());

  const { resolve, reject, params } = get(action, "payload", {});

  const { mappedOrderID = "", orderItemId = "" } = params;
  const [shopifyOrderId, deliveryGroupId] = mappedOrderID
    .replace("<", "")
    .split(">");

  const requestPayload =
    orderItemId === "mpos"
      ? params
      : {
          ...params,
          deliveryGroupId,
          customerOrderId: params.customerOrderId,
          shopifyOrderId,
          orderID: mappedOrderID,
          orderItemId,
          refundReason: params.reason,
        };

  const serviceRequest = (params) => request("refund-order", params);

  try {
    const { status } = yield call(serviceRequest, requestPayload);
    if (status === "success") {
      const searchText = yield select(selectSearchText);
      yield put(setPageSearch({ type: "search", value: searchText }));
      yield put(setPageInitSuccess());
      resolve("Refund has been initiated successfully");

      if (orderItemId === "mpos") {
        yield put(
          fetchOrderDetails({
            params: {
              orderId: params.customerOrderId,
              memberCode: params.memberCode,
            },
          })
        );
      }
    } else {
      yield put(setPageInitFailure());
      reject("Refund request failed, please try again");
    }
  } catch (error) {
    yield put(setPageInitFailure());
    yield put(
      setApiError({
        section: "refunds",
        error: "Refund request failed, please try again",
      })
    );
    reject("Refund request failed, please try again");
  }
}

const applyPageActions = (data) => {
  const applyControls = (data = []) =>
    data.filter((entry) => {
      let matchesFilter = true;
      const isProcessed =
        get(entry, "refundDetails.0.status", "") === "PROCESSED";

      // Modifying the data as required for listing
      entry.name = entry.firstName + " " + entry.lastName;
      entry.address = entry.address1;
      entry.variation = isProcessed ? "Refunded" : "MOL";
      entry.date = moment(entry.orderCreatedDateTime).format("DD/MM/YYYY");
      entry.time = moment(entry.orderCreatedDateTime).format("HH:MM A");
      entry.orderAmount = "$" + entry.orderAmount;
      entry.orderCount = data.length;

      delete entry.firstName;
      delete entry.lastName;
      delete entry.address1;
      delete entry.address2;
      delete entry.orderCreatedDateTime;

      return matchesFilter;
    });

  let result = cloneDeep(data);

  result = Object.keys(result).reduce((accum, section) => {
    accum[section] = applyControls(result[section]);
    return accum;
  }, {});

  return result;
};

export const processMOLOrdersResponse = ({
  molOrderSearchDetails = [],
  orderAmounts = {},
}) => {
  const updatedData = molOrderSearchDetails.reduce((acc, obj, index) => {
    const lineItemsData = get(obj, "lineItems.0", {});

    const { subTotal, discountAmount } = obj.lineItems.reduce(
      (acc, item) => {
        const lineItemRetailAmount =
          item.lineItemAmounts.retailProductAmount || 0;
        const lineItemDiscountAmount = item.lineItemAmounts.discountAmount || 0;

        acc.subTotal += lineItemRetailAmount;
        acc.discountAmount += lineItemDiscountAmount;

        return acc;
      },
      { subTotal: 0, discountAmount: 0 }
    );

    const refundData = get(obj, "paymentMethodDetails.refundDetails", []);
    const refundDetailsObject = refundData.map((data) => {
      return {
        refundAmount: data.amount,
        refundDate: data.refundInsertDate.split("T")[0],
        paymentCardType: get(obj, "paymentMethodDetails.creditCardType", ""),
        paymentCardNumber: get(
          obj,
          "paymentMethodDetails.creditCardNumber",
          ""
        ),
      };
    });

    let feeSummary = {};
    try {
      const feeSummaryJSON = orderAmounts.find(
        (obj) => obj.name === "feeSummary"
      ).value;
      feeSummary = JSON.parse(feeSummaryJSON);
    } catch {
      feeSummary = {};
    }
    //Show fee summary details for first lineItem only in case of multi product order
    const feeSummaryObject = index === 0 ? feeSummary : {};
    const totalFeeAmount = Object.keys(feeSummaryObject).reduce((acc, val) => {
      acc += Number(feeSummaryObject[val]);
      return acc;
    }, 0);
    const retailDeliveryFee = feeSummaryObject?.retailDeliveryFee || 0;
    const deliveryTipTotal =
      index === 0
        ? orderAmounts.find((obj) => obj.name === "deliveryTipTotal")?.value ||
          0
        : 0;
    const orderTotal = get(obj, "orderAmount", 0);
    const orderObject = {
      ...obj,
      lineItems: get(obj, "lineItems", []),
      productAmount: get(
        lineItemsData,
        "lineItemAmounts.retailProductAmount",
        0
      ),
      totalTaxAmount: get(lineItemsData, "totalTax.amount", 0),
      totalTaxRate: get(lineItemsData, "totalTax.rate", 0) * 100,
      subTotal,
      totalTaxTypes: get(lineItemsData, "taxAmounts", []),
      feeSummary: feeSummaryObject,
      totalFeeAmount,
      deliveryTipTotal,
      orderTotal: Number(orderTotal) + Number(deliveryTipTotal),
      discountAmount,
      retailDeliveryFee,
      omsOrderId: get(obj, "omsOrderID", ""),
      customerOrderId: get(obj, "shopifyOrderID", ""),
      orderId: `<${get(obj, "omsOrderID", "")}>${get(
        lineItemsData,
        "lineItemId",
        ""
      )}`,
      paymentMethodDetails: {
        paymentCardType: get(obj, "paymentMethodDetails.creditCardType", ""),
        paymentCardNumber: get(
          obj,
          "paymentMethodDetails.creditCardNumber",
          ""
        ),
        paymentDate: get(
          obj,
          "paymentMethodDetails.authorizationDetails.authorizationDate",
          ""
        ).split("T")[0],
        paymentAmount: get(
          obj,
          "paymentMethodDetails.authorizationDetails.approvalAmount",
          0
        ),
        paymentStatus: get(obj, "paymentMethodDetails.paymentStatus", ""),
      },
      refundDetails: refundDetailsObject,
      orderAmounts,
      refundedAmounts: {
        feeRefundedAmount:
          orderAmounts?.find((obj) => obj?.name === "feeRefundedAmount")
            ?.value || 0,
        serviceFeeRefundedAmount:
          orderAmounts?.find((obj) => obj?.name === "serviceFeeRefundedAmount")
            ?.value || 0,
        rushFeeRefundedAmount:
          orderAmounts?.find((obj) => obj?.name === "rushFeeRefundedAmount")
            ?.value || 0,
        taxRefundedAmount:
          orderAmounts?.find((obj) => obj.name === "taxRefundedAmount")
            ?.value || 0,
        deliveryTipRefundedAmount:
          orderAmounts?.find((obj) => obj.name === "deliveryTipRefundedAmount")
            ?.value || 0,
      },
    };
    acc[obj.deliveryGroupId] = orderObject;
    return acc;
  }, {});

  return updatedData;
};
