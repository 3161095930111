import React, { useEffect } from "react";
import { View, Platform } from "react-native";
import { Text, Button } from "react-native-elements";
import tw from "tailwind-rn";
import { connect } from "react-redux";
import { DeviceContext } from "library/contexts/appSettings";
import { fonts, theme } from "styles/theme";
import useStateIfMounted from "library/utils/useStateIfMounted";
import UserProfileContext from "library/contexts/userProfile";
import { SaveCancelButtons } from "components/wrappers";
import { Form, FormField, FormFieldPicker } from "components/elements/forms";
import * as DocumentPicker from "expo-document-picker";
import * as FileSystem from "expo-file-system";
import { request } from "library/utils/request";
import UserAuthContext from "library/contexts/userAuth";
import Captcha from "components/elements/recaptcha";
import UserProfileStorage from "library/storage/userProfile";
import { ToasterHandler } from "components/elements";
import I18NContext from "library/contexts/i18N";
import { getValidationSchema } from "./yup";

const FeedBack = ({ selectedShopCode = "" }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const {
    userProfile: { firstName = "", lastName = "", email: emailId = "" },
    memberCodes,
  } = React.useContext(UserProfileContext);
  const { isMobile } = React.useContext(DeviceContext);
  const { authToken } = React.useContext(UserAuthContext);
  const [fbImages, setFbImages] = useStateIfMounted([]);
  const [feedbackCategories, setFeedbackCategories] = useStateIfMounted([]);
  const [captchaUpdate, setCaptchaUpdate] = useStateIfMounted("");
  const [resetCaptcha, setResetCaptcha] = useStateIfMounted();
  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);
  const [characterLimit, setCharacterLimit] = useStateIfMounted(0);
  const [fbDetails, setFbDetails] = useStateIfMounted("");

  const shopNames = UserProfileStorage.getAllShopNames();

  const profileName = authToken && firstName + " " + lastName;

  const defaultValues = {
    customerName: profileName || "",
    customerEmail: emailId || "",
    storeName:
      selectedShopCode !== "all"
        ? selectedShopCode
        : memberCodes.length === 1
        ? `${memberCodes[0]} ${shopNames[memberCodes[0]]}`
        : "",
    feedbackCategory: feedbackCategories[0]?.value || "",
    feedbackNotes: "",
    feedbackImages: [],
    captchaResponse: "",
    hasMultipleShops: memberCodes.length > 1,
    storeCode: "",
    shopName: "",
  };

  const validationSchema = getValidationSchema(
    authToken,
    memberCodes,
    Localise,
    messages
  );

  useEffect(() => {
    request(
      authToken ? "get-feedback-categories" : "get-feedback-categories-noauth"
    )
      .then((res) => {
        constructCategories(res);
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  }, []);

  const constructCategories = (res) => {
    const finalRes =
      res &&
      res.map((category) => {
        return { label: category, value: category };
      });
    const filteredArray = finalRes?.filter(
      (item) => item.value === "Issue/Bug"
    );
    setFeedbackCategories(filteredArray);
  };

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/*",
    });
    if (Platform.OS !== "web") {
      const base64Image = await FileSystem.readAsStringAsync(result.uri, {
        encoding: "base64",
      });
      result.uri = base64Image;
    }
    setFbImages((fbImages) => [...fbImages, result]);
  };

  const formSubmitTrigger = (values, formikBag) => {
    let feedbackImages = [];
    fbImages.forEach((fbImage) =>
      feedbackImages.push(
        fbImage.uri.replace(/^data:image\/[a-z]+;base64,/, "")
      )
    );
    const params = {
      name: values.customerName,
      email: values.customerEmail,
      storeName:
        authToken && memberCodes.length > 1
          ? values.storeName + " " + shopNames[values.storeName]
          : authToken && memberCodes.length === 1
          ? values.storeName
          : values.storeCode + " " + values.shopName,
      feedbackCategory: values.feedbackCategory,
      feedbackDetails: values.feedbackNotes || fbDetails,
      feedbackImages: feedbackImages,
    };

    if (!authToken && captchaUpdate === "") {
      formikBag.setSubmitting(false);
      return;
    }

    if (authToken) {
      request("feedback-submit", {
        ...params,
      })
        .then((res) => {
          if (res) {
            ToasterHandler(
              "thanks",
              Localise(messages, "Your feedback has been submitted")
            );
          }
          setValidationOnChange(false);
          formikBag.resetForm();
          formikBag.setSubmitting(false);
          setFbImages([]);
          setFbDetails("");
          setCharacterLimit(0);
        })
        .catch((error) => {
          if (error) {
            ToasterHandler(
              "uh oh",
              Localise(
                messages,
                "Your feedback was not submitted – please try again"
              )
            );
          }
          formikBag.setSubmitting(false);
        });
    } else {
      request("feedback", {
        ...params,
        captcha: captchaUpdate,
      })
        .then((res) => {
          if (res && res.success) {
            ToasterHandler(
              "thanks",
              Localise(messages, "Your feedback has been submitted")
            );
            formikBag.resetForm();
            setValidationOnChange(false);
            setFbDetails("");
            setCharacterLimit(0);
            setFbImages([]);
          } else if (res && !res.success) {
            ToasterHandler(
              "error",
              Localise(messages, "Invalid captcha response, please try again.")
            );
          }
          formikBag.setSubmitting(false);
          setResetCaptcha(true);
        })
        .catch((error) => {
          if (error) {
            ToasterHandler(
              "uh oh",
              Localise(
                messages,
                "Your feedback was not submitted – please try again"
              )
            );
          }
          formikBag.setSubmitting(false);
        });
    }
  };

  const handleFbChange = (value) => {
    setFbDetails(value);
    setCharacterLimit(value?.length);
  };

  return (
    <View
      style={{
        paddingHorizontal: 20,
        paddingVertical: 20,
      }}
    >
      <Text
        style={{
          ...fonts.heading2,
          fontSize: 20,
          ...tw("pt-2 pb-2"),
        }}
      >
        {Localise(messages, "Email")}
      </Text>
      <Text
        style={{
          ...fonts.default,
          fontSize: 15,
          paddingVertical: 2,
        }}
      >
        {Localise(
          messages,
          "Please submit your issue, general feedback, or suggestions in the form."
        )}
      </Text>
      <Form
        initialValues={defaultValues}
        validationSchema={validationSchema}
        validateOnChange={validationOnChange}
        validateOnBlur={validationOnChange}
        onSubmit={formSubmitTrigger}
        render={({ values, setFieldValue, submitCount, resetForm }) => {
          const { captchaResponse } = values;

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            setFieldValue("captchaResponse", captchaUpdate);
          }, [captchaUpdate]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (submitCount > 0) {
              setValidationOnChange(true);
            }
          }, [submitCount]);
          return (
            <>
              <View style={{ marginBottom: 8, marginTop: 3 }}>
                <View style={tw("flex my-1")}>
                  {!authToken && (
                    <>
                      <Text
                        style={{
                          ...fonts.default,
                          paddingVertical: 2,
                        }}
                      >
                        {Localise(messages, "Shop Name")}
                      </Text>
                      <FormField
                        autoCapitalize="none"
                        autoCorrect={false}
                        autoComplete="new-password" //hack for autoComplete off
                        name="shopName"
                        containerStyle={{ width: "100%", paddingHorizontal: 0 }} //{{ width: isSmallScreen ? "100%" : "70%" }}
                        placeholder="Please type your shop name here" //"Start typing Customer Name to search records"
                      />
                      <Text
                        style={{
                          ...fonts.default,
                          paddingVertical: 2,
                        }}
                      >
                        {Localise(messages, "Shop Code")}
                      </Text>
                      <FormField
                        autoCapitalize="none"
                        autoCorrect={false}
                        autoComplete="new-password" //hack for autoComplete off
                        name="storeCode"
                        containerStyle={{ width: "100%", paddingHorizontal: 0 }} //{{ width: isSmallScreen ? "100%" : "70%" }}
                        placeholder="Please type your shop code here" //"Start typing Customer Name to search records"
                      />
                    </>
                  )}
                  <Text
                    style={{
                      ...fonts.default,
                      paddingVertical: 2,
                    }}
                  >
                    {Localise(messages, "Name")}
                  </Text>
                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    autoComplete="new-password" //hack for autoComplete off
                    name="customerName"
                    containerStyle={{ width: "100%", paddingHorizontal: 0 }} //{{ width: isSmallScreen ? "100%" : "70%" }}
                    placeholder="Please tell us your name, so we may contact you with any questions." //"Start typing Customer Name to search records"
                  />
                  <Text
                    style={{
                      ...fonts.default,
                      paddingVertical: 2,
                    }}
                  >
                    {Localise(messages, "Email")}
                  </Text>
                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    autoComplete="new-password"
                    name="customerEmail"
                    keyboardType="email-address"
                    containerStyle={{ width: "100%", paddingHorizontal: 0 }}
                    placeholder="Please tell us your email, so we may contact you with any questions."
                  />

                  {authToken && (
                    <Text
                      style={{
                        ...fonts.default,
                        paddingVertical: 2,
                      }}
                    >
                      {Localise(messages, "Shop")}
                    </Text>
                  )}

                  {authToken && memberCodes.length > 1 && (
                    <FormFieldPicker
                      placeholder={{ label: "Please select a shop" }}
                      containerStyle={{ width: "100%", paddingHorizontal: 0 }}
                      data={memberCodes.map((code) => {
                        return {
                          label: `${code} ${shopNames[code]}`,
                          value: code,
                        };
                      })}
                      name="storeName"
                    />
                  )}
                  {authToken && memberCodes.length == 1 && (
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      autoComplete="new-password" //hack for autoComplete off
                      name="storeName"
                      containerStyle={{ width: "100%", paddingHorizontal: 0 }} //{{ width: isSmallScreen ? "100%" : "70%" }}
                      placeholder="Enter Your Shop Code and Shop Name" //"Start typing Customer Name to search records"
                      editable={false}
                    />
                  )}
                  <Text
                    style={{
                      ...fonts.default,
                      paddingVertical: 2,
                    }}
                  >
                    {Localise(messages, "Category")}
                  </Text>
                  <FormFieldPicker
                    placeholder={{ label: "Select Reason", value: "" }}
                    containerStyle={{
                      width: "100%",
                      paddingBottom: 10,
                      paddingHorizontal: 0,
                    }}
                    data={feedbackCategories}
                    name="feedbackCategory"
                    defaultValue={defaultValues.feedbackCategory}
                  />
                  <Text
                    style={{
                      ...fonts.default,
                      paddingVertical: 2,
                    }}
                  >
                    {Localise(messages, "Details")}
                  </Text>
                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    name="feedbackNotes"
                    placeholder="Share your feedback here"
                    multiline={true}
                    numberOfLines={5}
                    containerStyle={{ width: "100%", paddingHorizontal: 0 }}
                    maxLength={1000}
                    value={fbDetails}
                    onChangeText={handleFbChange}
                    handleOnBlur={() => {
                      setFieldValue("feedbackNotes", fbDetails);
                    }}
                  />
                </View>
                <View
                  style={[
                    tw("flex flex-row-reverse"),
                    {
                      marginLeft: "auto",
                    },
                  ]}
                  testID="remaining_fb_chars"
                  accessibilityLabel="remaining_fb_chars"
                >
                  <Text style={{ ...fonts.default }}>
                    {Localise(
                      messages,
                      `${characterLimit} Characters / 1000 Remaining`
                    )}
                  </Text>
                </View>
                <View style={tw("flex flex-row justify-between items-center")}>
                  <Button
                    testID={"upload_photo"}
                    accessibilityLabel="upload_photo"
                    titleStyle={{
                      ...theme.Button.secondaryTitleStyle,
                      ...fonts.heading5,
                    }}
                    buttonStyle={{
                      ...theme.Button.secondaryButtonStyle,
                      paddingVertical: 8,
                    }}
                    containerStyle={{ marginLeft: 0 }}
                    title={Localise(messages, "Upload Photo")}
                    onPress={pickDocument}
                  />
                  <SaveCancelButtons
                    cancelTestId={"clear_fb_form"}
                    saveTestId={"submit_fb_form"}
                    buttonTitle={Localise(messages, "Send Email")}
                    onCancel={() => {
                      resetForm();
                      setFbDetails("");
                      setCharacterLimit(0);
                    }}
                  />
                </View>
                <View style={tw("flex")}>
                  {fbImages.length > 0 && (
                    <Text>{Localise(messages, "Uploaded Images")}</Text>
                  )}
                  {fbImages.map((image, index) => {
                    return (
                      <Text key={index}>
                        {image.name || Localise(messages, "no file chosen")}
                      </Text>
                    );
                  })}
                </View>

                {!authToken && (
                  <View
                    style={{
                      flexDirection: isMobile ? "column" : "row",
                      alignItems: "stretch",
                      paddingVertical: 10,
                      ...(isMobile && { marginBottom: 20 }),
                    }}
                  >
                    <Captcha
                      handleChange={setCaptchaUpdate}
                      resetCaptcha={resetCaptcha}
                    />
                    {submitCount > 0 && captchaResponse === "" && (
                      <Text
                        style={{
                          ...fonts.error,
                          fontWeight: "700",
                          textAlign: "left",
                        }}
                      >
                        {Localise(messages, "Select Captcha")}
                      </Text>
                    )}
                  </View>
                )}
              </View>
            </>
          );
        }}
      />
    </View>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    selectedShopCode: state.mhq.ongoing.global.shopCode,
  };
};

export default connect(mapStateToProps, null)(FeedBack);
