import React, { useContext } from "react";
import { View, TouchableOpacity, Platform } from "react-native";
import { Text } from "react-native-elements";
import startCase from "lodash/startCase";
import get from "lodash/get";
import * as Yup from "yup";
import {
  Form,
  FormField,
  SubmitButton,
  FormFieldSwitch,
} from "components/elements/forms";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import ChangePasswordScreen from "./change-password";
import { request } from "library/utils/request";
import { Entitlements } from "library/utils/entitlements";
import { fonts, colors, backgroundColors } from "styles/theme";
import { ToasterHandler, Picker } from "components/elements";
import tw from "tailwind-rn";
import SingleScreen from "components/containers/single-screen";
import SubHeader from "components/containers/header/sub-header";
import useStateIfMounted from "library/utils/useStateIfMounted";
import isObject from "lodash/isObject";
import some from "lodash/some";
import I18NContext from "library/contexts/i18N";
import AppSettingsStorage from "library/storage/appSettings";
import { Section } from "components/views/drawer/mercury-online/helper";
import { customisePushNotificationsOptions } from "./config";
import {
  phoneNumberFormatter,
  formatPhoneForPayload,
} from "library/utils/formatter";
export default function ProfileScreen() {
  const { isDesktop, isMobile } = useContext(DeviceContext);
  const {
    userRole,
    userProfile,
    setUserProfile,
    userType,
    proxyUser,
    isPasswordAboutToExpire,
    remainingPasswordExpDays,
  } = useContext(UserProfileContext);
  const { permissions } = useContext(AppSettingsContext);

  const [changePassword, setChangePassword] = useStateIfMounted(false);
  const {
    firstName,
    lastName,
    email: emailId,
    phone = "",
    department = " ",
    authGroups = [],
    preferences: userPreferences = [],
  } = userProfile;

  const isFTDUser = userType === "FTD";

  const {
    messages,
    AllowedLocales,
    currentLocale,
    setCurrentLocale,
    Localise,
  } = React.useContext(I18NContext);

  const changeLanguage = (value) => {
    AppSettingsStorage.setLanguage(value);
    setCurrentLocale(value);
  };

  let userRoles = get(authGroups, "0.roles", []);
  userRoles = userRoles.map((role) => Localise(messages, role));

  const hasCustomisePushNotificationPermission =
    some(permissions, Entitlements.CUSTOMIZE_PUSH_NOTIFICATIONS) || false;

  const customisePushNotificationsOptionsByRole =
    customisePushNotificationsOptions.filter(
      (option) => some(permissions, Entitlements[option.entitlement]) || false
    );

  const PreferenceToggle = ({
    label = "",
    value = false,
    onValueChange = () => {},
    style = {},
  }) => {
    return (
      <View
        style={[
          tw("flex flex-row my-1 mb-2 items-center justify-between"),
          style,
        ]}
      >
        <Text style={[isMobile && { height: 20 }]}>
          {Localise(messages, label)}
        </Text>
        <FormFieldSwitch
          value={value}
          onValueChange={() => {
            onValueChange();
          }}
          testID={label}
          accessibilityLabel={label}
        />
      </View>
    );
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .label("firstName")
      .required(Localise(messages, "Please enter first name")),
    lastName: Yup.string()
      .label("lastName")
      .required(Localise(messages, "Please enter last name")),
    emailId: Yup.string()
      .label("emailId")
      .email(Localise(messages, "Enter a valid email"))
      .required(Localise(messages, "Enter Email")),
    phone: Yup.string()
      .label("phone")
      .required(Localise(messages, "Please enter phone number"))
      .matches(
        new RegExp(
          "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
        ),
        Localise(messages, "Please enter a valid phone number")
      ),
  });

  const onFormSubmit = (values, formikBag) => {
    const {
      firstName,
      lastName,
      emailId,
      phone,
      notificationSounds,
      enableAppPushNotifications,
      opted_order_push_notifications = [],
      opted_delivery_push_notifications = [],
    } = values;

    let setProfileValues = {
      firstName,
      lastName,
      phone: formatPhoneForPayload(phone),
      email: emailId,
    };

    if (!isFTDUser) {
      setProfileValues = {
        ...setProfileValues,
        preferences: [
          {
            name: "Notification Sound",
            value: notificationSounds ? "YES" : "NO",
          },
          ...(!proxyUser
            ? [
                {
                  name: "Push Notifications",
                  value: enableAppPushNotifications ? "YES" : "NO",
                },
                {
                  name: "opted_order_push_notifications",
                  value: opted_order_push_notifications.join(","),
                },
                {
                  name: "opted_delivery_push_notifications",
                  value: opted_delivery_push_notifications.join(","),
                },
                ...(userPreferences.find(
                  (e) => e.name === "routeMemberPreferences"
                )?.name
                  ? [
                      userPreferences.find(
                        (e) => e.name === "routeMemberPreferences"
                      ),
                    ]
                  : []),
              ]
            : []),
        ],
      };
    }

    request("edit-user-profile", setProfileValues)
      .then((res) => {
        setUserProfile({ ...userProfile, ...setProfileValues });
        formikBag.setSubmitting(false);
        ToasterHandler(
          "nice",
          Localise(messages, "Your profile has been updated")
        );
      })
      .catch((err) => {
        formikBag.setSubmitting(false);
        ToasterHandler(
          "uh oh",
          Localise(messages, "Please try to update your profile again")
        );
        console.log("unable to update profile", JSON.stringify(err));
      });
  };

  return (
    <>
      {!isDesktop && <SubHeader />}
      <SingleScreen title={Localise(messages, "Profile")}>
        <View
          style={{
            ...tw(
              `w-full flex flex-col p-2 ${isDesktop ? "w-6/12" : "w-full"}`
            ),
            ...(!isDesktop && { paddingBottom: 220 }),
          }}
          fsClass="fs-unmask"
        >
          <Form
            initialValues={{
              firstName,
              lastName,
              emailId,
              phone: phoneNumberFormatter(phone),
              notificationSounds: !isObject(
                get(userProfile, "preferences", []).find(
                  (e) => e.name === "Notification Sound" && e.value === "NO"
                )
              ),
              enableAppPushNotifications: !isObject(
                get(userProfile, "preferences", []).find(
                  (e) => e.name === "Push Notifications" && e.value === "NO"
                )
              ),
              opted_order_push_notifications:
                get(userProfile, "preferences", [])
                  .find((e) => e.name === "opted_order_push_notifications")
                  ?.value?.split(",")
                  .filter(Boolean) || [],
              opted_delivery_push_notifications:
                get(userProfile, "preferences", [])
                  .find((e) => e.name === "opted_delivery_push_notifications")
                  ?.value?.split(",")
                  .filter(Boolean) || [],
            }}
            onSubmit={onFormSubmit}
            validationSchema={validationSchema}
            render={({ setFieldValue, values, dirty }) => {
              const {
                enableAppPushNotifications,
                notificationSounds: isAudibleTurnedOn,
              } = values;

              return (
                <View style={tw("flex flex-col")}>
                  <Section
                    title={Localise(messages, "Profile Details")}
                    defaultOpen={true}
                  >
                    <View
                      style={[
                        tw(`flex flex-row justify-start items-center pb-2`),
                      ]}
                    >
                      <Text
                        style={{
                          width: "20%",
                          ...fonts.heading5,
                        }}
                      >
                        {Localise(messages, "First Name")}
                      </Text>
                      <View
                        style={[
                          tw("flex flex-row justify-start flex-wrap"),
                          { width: "75%" },
                        ]}
                      >
                        <FormField
                          autoCapitalize="none"
                          autoCorrect={false}
                          name="firstName"
                          placeholder={"Enter First Name"}
                        />
                      </View>
                    </View>

                    <View
                      style={[
                        tw(`flex flex-row justify-start items-center pb-2`),
                      ]}
                    >
                      <Text
                        style={{
                          width: "20%",
                          ...fonts.heading5,
                        }}
                      >
                        {Localise(messages, "Last Name")}
                      </Text>
                      <View
                        style={[
                          tw("flex flex-row justify-start flex-wrap"),
                          { width: "75%" },
                        ]}
                      >
                        <FormField
                          autoCapitalize="none"
                          autoCorrect={false}
                          name="lastName"
                          placeholder={"Enter Last Name"}
                        />
                      </View>
                    </View>

                    <View
                      style={[
                        tw(`flex flex-row justify-start items-center pb-2`),
                      ]}
                    >
                      <Text
                        style={{
                          width: "21%",
                          ...fonts.heading5,
                        }}
                      >
                        {Localise(messages, "Role")}
                      </Text>
                      <View
                        style={[
                          tw("flex flex-row justify-start flex-wrap"),
                          { width: "75%" },
                        ]}
                      >
                        <Text style={tw("py-2")}>
                          {isFTDUser
                            ? Localise(messages, startCase(userRole, "_", " "))
                            : userRoles.join(", ")}
                        </Text>
                      </View>
                    </View>

                    {isFTDUser && (
                      <View
                        style={[
                          tw(`flex flex-row justify-start items-center pb-2`),
                        ]}
                      >
                        <Text
                          style={[fonts.heading6, tw("py-1"), { width: "21%" }]}
                        >
                          {Localise(messages, "Department")}
                        </Text>
                        <View
                          style={[
                            tw("flex flex-row justify-start flex-wrap"),
                            { width: "75%" },
                          ]}
                        >
                          <Text style={tw("py-2")}>
                            {department !== ""
                              ? startCase(department, "_", " ")
                              : department}
                          </Text>
                        </View>
                      </View>
                    )}

                    <View
                      style={[
                        tw(`flex flex-row justify-start items-center pb-2`),
                      ]}
                    >
                      <Text
                        style={{
                          width: "20%",
                          ...fonts.heading5,
                        }}
                      >
                        {Localise(messages, "Phone")}
                      </Text>
                      <View
                        style={[
                          tw("flex flex-row justify-start flex-wrap"),
                          { width: "75%" },
                        ]}
                      >
                        <FormField
                          autoCapitalize="none"
                          autoCorrect={false}
                          keyboardType="phone-pad"
                          name="phone"
                          placeholder="123-456-7890"
                          maxLength={18}
                          fsClass="fs-exclude"
                          transformText={(text = "") => {
                            return phoneNumberFormatter(text);
                          }}
                        />
                      </View>
                    </View>

                    <View
                      style={[
                        tw(`flex flex-row justify-start items-center pb-2`),
                      ]}
                    >
                      <Text
                        style={{
                          width: "20%",
                          ...fonts.heading5,
                        }}
                      >
                        {Localise(messages, "Email")}
                      </Text>
                      <View
                        style={[
                          tw("flex flex-row justify-start flex-wrap"),
                          { width: "75%" },
                        ]}
                      >
                        <FormField
                          autoCapitalize="none"
                          autoCorrect={false}
                          keyboardType="email-address"
                          autoComplete="new-email"
                          name="emailId"
                          placeholder={"Email"}
                          textContentType="emailAddress"
                        />
                      </View>
                    </View>

                    {changePassword ? (
                      <View style={tw("my-3")}>
                        <ChangePasswordScreen
                          onCancel={() => setChangePassword(false)}
                        />
                      </View>
                    ) : (
                      <View
                        style={[
                          tw(`flex flex-row justify-start items-center pb-2`),
                        ]}
                      >
                        <Text
                          style={{
                            width: "19%",
                            ...fonts.heading5,
                          }}
                        >
                          {Localise(messages, "Password")}
                        </Text>
                        <View
                          style={[
                            tw("flex flex-row justify-start flex-wrap"),
                            { width: "75%" },
                          ]}
                        >
                          <TouchableOpacity
                            style={[
                              tw("flex flex-row items-center my-2 ml-2"),
                              { minWidth: 100 },
                            ]}
                            onPress={() => setChangePassword(true)}
                            testID="change_pwd"
                            accessibilityLabel="change_pwd"
                          >
                            <View
                              style={{
                                borderColor: "#224056",
                                borderWidth: 1,
                                paddingVertical: 10,
                                paddingHorizontal: 20,
                              }}
                            >
                              <Text
                                style={{ color: "#224056", ...fonts.heading5 }}
                              >
                                {Localise(messages, "Reset Password")}
                              </Text>
                            </View>
                            {isPasswordAboutToExpire && (
                              <>
                                <View style={{ marginRight: 20 }} />
                                <Text style={{ color: colors.error }}>
                                  {Localise(
                                    messages,
                                    "Your password is going to expire in XX days. Please change your password"
                                  ).replace("XX", remainingPasswordExpDays)}
                                </Text>
                              </>
                            )}
                          </TouchableOpacity>
                        </View>
                      </View>
                    )}

                    {AllowedLocales.length > 1 && (
                      <View
                        style={[
                          tw(`flex flex-row justify-start items-center pb-2`),
                        ]}
                      >
                        <Text
                          style={{
                            width: "20%",
                            height: 45,
                            ...fonts.heading5,
                          }}
                        >
                          {Localise(messages, "Language")}
                        </Text>
                        <View
                          style={[tw("flex flex-row justify-start flex-wrap")]}
                        >
                          <Picker
                            containerStyle={{
                              marginLeft: isMobile ? 0 : -5,
                              width: Platform.OS !== "web" ? "70%" : "auto",
                            }}
                            items={AllowedLocales}
                            placeholder={{}}
                            value={currentLocale}
                            onValueChange={(value) => changeLanguage(value)}
                          ></Picker>
                        </View>
                      </View>
                    )}
                  </Section>

                  {!isFTDUser && (
                    <Section
                      title={Localise(messages, "Notifications")}
                      defaultOpen={true}
                    >
                      {!proxyUser ? (
                        <>
                          {/* App Push Notifications Preferences */}
                          <PreferenceToggle
                            label={"Enable App Push Notifications"}
                            value={enableAppPushNotifications}
                            style={{
                              width: isMobile ? "100%" : 300,
                              paddingRight: 10,
                              marginBottom: 12,
                            }}
                            onValueChange={() => {
                              setFieldValue(
                                "enableAppPushNotifications",
                                !enableAppPushNotifications
                              );
                            }}
                          />

                          {/* Customise App Push Notifications Preferences */}
                          {hasCustomisePushNotificationPermission &&
                          enableAppPushNotifications &&
                          customisePushNotificationsOptionsByRole?.length >
                            0 ? (
                            <View
                              style={{
                                ...tw("mb-2"),
                                backgroundColor: backgroundColors.greyColor,
                                borderTopWidth: 1,
                                borderTopColor: colors.lightBorder,
                                padding: 10,
                                width: isMobile ? "100%" : 300,
                              }}
                            >
                              {customisePushNotificationsOptionsByRole.map(
                                (pushNotificationPreference, i) => {
                                  const {
                                    title = "",
                                    name,
                                    type,
                                  } = pushNotificationPreference;

                                  const fieldValue = get(
                                    values,
                                    type,
                                    []
                                  )?.includes(name);

                                  return (
                                    <React.Fragment key={i}>
                                      <PreferenceToggle
                                        label={title}
                                        value={fieldValue}
                                        onValueChange={() => {
                                          let typeValues = get(
                                            values,
                                            type,
                                            []
                                          );
                                          let updatedFieldVal = [...typeValues];
                                          if (fieldValue) {
                                            updatedFieldVal = typeValues.filter(
                                              (pref) => pref !== name
                                            );
                                          } else {
                                            updatedFieldVal.push(name);
                                            updatedFieldVal.sort(
                                              (a, b) =>
                                                customisePushNotificationsOptionsByRole
                                                  .map((e) => e.name)
                                                  .indexOf(a) -
                                                customisePushNotificationsOptionsByRole
                                                  .map((e) => e.name)
                                                  .indexOf(b)
                                            );
                                          }
                                          setFieldValue(
                                            `${type}`,
                                            updatedFieldVal
                                          );
                                        }}
                                      />
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </View>
                          ) : null}
                        </>
                      ) : null}

                      {/* Notification Sounds Preference */}
                      <PreferenceToggle
                        label={"Notification Sounds"}
                        value={isAudibleTurnedOn}
                        style={{
                          width: isMobile ? "100%" : 300,
                          paddingRight: 10,
                        }}
                        onValueChange={() => {
                          setFieldValue(
                            "notificationSounds",
                            !values.notificationSounds
                          );
                        }}
                      />
                    </Section>
                  )}
                  <SubmitButton
                    mainContainerStyle={tw("items-end")}
                    containerStyle={[tw("mx-1 my-5"), { width: 150 }]}
                    title={Localise(messages, "Save Profile")}
                    disableOnDirty={!dirty}
                  />
                </View>
              );
            }}
          />
        </View>
      </SingleScreen>
    </>
  );
}
