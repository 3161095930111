import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUIConfig } from "./ui-config";
import Listing from "components/containers/listing-new";

import { Entitlements } from "library/utils/entitlements";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import AppSettingsContext from "library/contexts/appSettings";
import { fetchShopPaymentSettings } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import UserProfileStorage from "library/storage/userProfile";

import get from "lodash/get";

const ListingScreen = () => {
  const dispatch = useDispatch();
  const { memberCodes = [] } = UserProfileStorage.getAuthGroup();
  const { isDesktop, isTablet, isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);
  const { permissions = {} } = React.useContext(AppSettingsContext);
  const selectedShopPermissions = get(permissions, memberCodes[0], {});

  const enableCreateGiftCard = selectedShopPermissions[
    Entitlements.GIFT_CARDS
  ]?.includes(Entitlements.GIFT_CARDS_PAGE.CREATE_GIFT_CARD);

  useEffect(() => {
    dispatch(fetchShopPaymentSettings());
  }, []);

  const UIConfig = getUIConfig(
    Localise,
    messages,
    isDesktop,
    isTablet,
    isMobile
  );

  const ListingComponent = Listing({
    UIConfig: {
      ...UIConfig,
      controls: UIConfig.controls.map((e) => {
        return e.name === "create-new"
          ? {
              ...e,
              isHidden: !enableCreateGiftCard,
            }
          : e;
      }),
    },
  });

  return ListingComponent?.props?.children;
};

export default ListingScreen;
