import { put, call, select, delay } from "redux-saga/effects";

import {
  setApiResponse,
  setApiError,
  setPageData,
  fetchAllCustomers,
} from "../slice";
import { selectApiResponse, selectPageActions } from "../selector";

import { request } from "library/utils/request";

import cloneDeep from "lodash/cloneDeep";
import orderBy from "lodash/orderBy";
import toLower from "lodash/toLower";
import get from "lodash/get";
import { phoneNumberFormatter } from "library/utils/formatter";

export function* handleFetchAllCustomers({ payload = {} }) {
  const {
    resolve,
    reject,
    query: {
      offset = 0,
      limit = 50,
      includeTotalCount = true,
      section = "customers",
    } = {},
  } = payload;

  try {
    const { search = "", mergeRecordsSearch = "" } = yield select(
      selectPageActions
    );

    const isMergeRecordsSearch = payload.type === "mergeRecordsSearch";
    const serviceRequest = (params) => request("get-customers", params);

    const {
      content,
      pageable: { totalRecords = 0 },
    } = yield call(serviceRequest, {
      offset,
      limit,
      searchText: isMergeRecordsSearch ? mergeRecordsSearch : search,
      includeTotalCount,
    });

    yield put(
      setApiResponse({
        section,
        content,
      })
    );
    if (!isMergeRecordsSearch) {
      yield put(setPageData({ totalRecords }));
    }
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        section,
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleFetchAllCustomersBySearch({ payload = {} }) {
  const { section = "customers" } = get(payload, "query", {});
  try {
    const { search = "", mergeRecordsSearch = "" } = yield select(
      selectPageActions
    );
    const searchText =
      payload.type === "mergeRecordsSearch" ? mergeRecordsSearch : search;

    if (searchText.length > 1 || searchText === "") {
      yield delay(1000);
      yield put(fetchAllCustomers(payload));
    }
  } catch (error) {
    yield put(
      setApiError({
        section,
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleUIRefresh() {
  const origData = yield select(selectApiResponse);
  const actions = yield select(selectPageActions);
  const newData = applyPageActions(origData, actions);
  yield put(setPageData(newData));
}

const applyPageActions = (data, actions) => {
  const {
    shops = [],
    filters = [],
    sort: { value: sortBy = "name::asc" },
  } = actions;

  const [sortField, sortOrder] = sortBy.split(/::/);

  const applyControls = (data = []) => {
    const filterByShop = data.filter((entry) => {
      let matchesFilter = true;
      if (shops.length) {
        if (shops.filter((item) => item.value === entry.storeOrigin).length)
          matchesFilter = true;
        else matchesFilter = false;
      }
      return matchesFilter;
    });

    return filterByShop.filter((entry) => {
      let matchesFilter = true;

      // Modifying the data as required for listing
      entry.name = entry.isBusinessProfile
        ? entry.businessName
        : entry.firstName + " " + entry.lastName;
      entry.address =
        get(entry.addresses, "0.addressLine1", "") +
        " " +
        get(entry.addresses, "0.addressLine2", "");
      entry.state = get(entry.addresses, "0.state", "");
      entry.city = get(entry.addresses, "0.city", "");
      entry.phone = phoneNumberFormatter(get(entry?.phones, "0", ""));

      delete entry.firstName;
      delete entry.lastName;
      delete entry.businessName;
      delete entry.addresses;
      delete entry.phones;

      if (filters.length) {
        const filterValues = filters.map((e) => e.value);

        if (
          filterValues.includes("businessProfiles") &&
          entry.isBusinessProfile
        )
          matchesFilter = true;
        else if (
          filterValues.includes("individualProfiles") &&
          !entry.isBusinessProfile
        )
          matchesFilter = true;
        else if (
          filterValues.includes("subscriptionProfiles") &&
          entry.subscription
        )
          matchesFilter = true;
        else if (
          filterValues.includes("outstandingBalance") &&
          entry.houseAccountOutstandingBalance
        )
          matchesFilter = true;
        else matchesFilter = false;
      }
      return matchesFilter;
    });
  };

  let result = cloneDeep(data);

  result = Object.keys(result).reduce((accum, section) => {
    accum[section] = orderBy(
      applyControls(result[section]),
      (e) => toLower(e[sortField]),
      sortOrder
    );
    return accum;
  }, {});

  return result;
};
