import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { DraggableGridView, ToasterHandler } from "components/elements";

import { Widget } from "../helper";
import { SecondaryButton } from "components/wrappers";

import { AddProductToCollectionWidget } from "./helper";
import { ProductsInCollectionHeader, AddToCollectionsHeader } from "./header";
import I18NContext from "library/contexts/i18N";
import {
  selectAPIResponse,
  selectProductsData,
  selectProductsSortOrder,
  selectProductsSelected,
  selectProductsViewMore,
} from "library/sagas/views/home/drawer/product-catalog/collection/selector";
import {
  setProductsAction,
  resetProductsAction,
  setProductsSelection,
  setProductsViewMore,
} from "library/sagas/views/home/drawer/product-catalog/collection/slice";
import { selectProductLookup } from "library/sagas/views/home/drawer/product-catalog/common/selector";
import { DeviceContext } from "library/contexts/appSettings";

import tw from "tailwind-rn";

export const ProductsInCollection = ({ isCreateCollection }) => {
  const products = useSelector(selectProductsData("current"));
  const [loading, setLoading] = useState(false);

  return (
    <View style={[tw(`flex flex-col justify-between`), { minHeight: 55 }]}>
      <ProductsInCollectionHeader
        positionMaxLimit={products.length}
        isCreateCollection={isCreateCollection}
        loading={loading}
        setLoading={setLoading}
      />
      {products.length ? (
        <Products
          section={"current"}
          loading={loading}
          setLoading={setLoading}
        />
      ) : (
        <View>
          <AddProductToCollectionWidget />
        </View>
      )}
    </View>
  );
};

export const AddProductsToCollection = () => {
  return (
    <View style={[tw(`flex flex-col justify-between`), { minHeight: 55 }]}>
      <AddToCollectionsHeader />
      <Products section={"new"} />
    </View>
  );
};

const Products = ({ section, loading, setLoading }) => {
  const dispatch = useDispatch();
  const { isDesktop, isTablet, isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);

  const { handle } = useSelector(selectAPIResponse);
  const { sortOrder } = useSelector(selectProductsSortOrder);
  const products = useSelector(selectProductsData(section));
  const productLookup = useSelector(selectProductLookup);
  const selectedProducts = useSelector(selectProductsSelected(section));
  const currentLimit = useSelector(selectProductsViewMore(section));

  const draggableUI =
    !isMobile && section === "current" && sortOrder === "manually";
  const maxWidth = isDesktop
    ? section === "new"
      ? 504
      : 996
    : isTablet
    ? 676
    : 332;
  const showViewMore = currentLimit > 0 && currentLimit < products.length;

  const [baseLimit, setBaseLimit] = useState(0);
  const productsSlice = products
    .filter(
      (p) =>
        productLookup[p] && productLookup[p].productId && productLookup[p].name
    )
    .slice(0, currentLimit);
  const displayProducts = productsSlice
    .concat(Array.from(Array(baseLimit)).map(() => ""))
    .slice(0, currentLimit);

  useEffect(() => {
    if (sortOrder === "manually" && section === "current")
      setBaseLimit(products.length);
    else setBaseLimit(isMobile ? 8 : 24);
  }, [sortOrder, products.length]);

  useEffect(() => {
    dispatch(setProductsViewMore({ section, actual: baseLimit }));
  }, [baseLimit]);

  return (
    <View fsClass="fs-unmask" style={{ maxWidth }}>
      <View style={tw("flex flex-col")}>
        <View
          style={[
            tw(`flex flex-row flex-wrap`),
            { opacity: loading ? 0.5 : 1 },
          ]}
        >
          {draggableUI ? (
            <DraggableProductsContainer
              handle={handle}
              data={displayProducts}
              containerWidth={maxWidth}
              loading={loading}
              selected={selectedProducts}
              onSelect={(e) =>
                dispatch(setProductsSelection({ section, productId: e }))
              }
              onDragEnd={({ newOrder }) => {
                setLoading(true);
                dispatch(
                  setProductsAction({
                    section: "current",
                    type: "sortOrder",
                    value: "manually",
                    extraValue: `dragToPos::${newOrder}`,
                    resolve: (message) => {
                      ToasterHandler(
                        "nice",
                        Localise(
                          messages,
                          message ? message : "Sort order updated successfully"
                        )
                      );

                      setLoading(false);

                      dispatch(
                        resetProductsAction({
                          section: "current",
                          type: "sortOrder",
                          value: "manually",
                        })
                      );
                    },
                    reject: (message) => {
                      ToasterHandler(
                        "oh oh",
                        Localise(
                          messages,
                          message
                            ? message
                            : "We were unable to process your request, please try again."
                        )
                      );

                      setLoading(false);

                      dispatch(
                        resetProductsAction({
                          section: "current",
                          type: "sortOrder",
                          value: "manually",
                        })
                      );
                    },
                  })
                );
              }}
            />
          ) : (
            displayProducts.map((productId, index) => {
              const selected = selectedProducts.find((p) => p === productId);

              return (
                <Widget
                  key={index}
                  type="product"
                  productId={productId}
                  style={{ marginRight: 6 }}
                  showPosition={
                    isMobile &&
                    section === "current" &&
                    sortOrder === "manually"
                  }
                  fromCollection={section === "current" ? handle : ""}
                  selected={selected}
                  onSelect={(e) =>
                    dispatch(setProductsSelection({ section, productId: e }))
                  }
                />
              );
            })
          )}
        </View>
        {showViewMore && (
          <View
            style={tw(
              `flex flex-row-reverse w-full justify-between items-center py-4`
            )}
          >
            <SecondaryButton
              title={"View More"}
              action={() =>
                dispatch(setProductsViewMore({ section, limit: currentLimit }))
              }
            />
          </View>
        )}
      </View>
    </View>
  );
};

export const DraggableProductsContainer = ({
  data,
  handle,
  selected,
  onSelect,
  onDragEnd,
  containerWidth,
}) => {
  const cellWidth = 160;
  const cellHeight = 303;
  const numberOfColumns = Math.floor(containerWidth / cellWidth);

  return (
    <SafeAreaProvider>
      <DraggableGridView
        keyExtractor={(item) => item}
        data={[...data]}
        renderItem={(productId, index) => {
          const selectedVal = selected.find((p) => p === productId);

          return (
            <Widget
              productId={productId}
              type="product"
              showPosition={true}
              position={index + 1}
              fromCollection={handle}
              selected={selectedVal}
              onSelect={() => onSelect(productId)}
            />
          );
        }}
        onReleaseCell={onDragEnd}
        numColumns={numberOfColumns}
        cellHeight={cellHeight}
        cellWidth={cellWidth}
        containerWidth={containerWidth}
      />
    </SafeAreaProvider>
  );
};
