// List of all languages imports in the application.
export const Messages = {
  pt: require("./pt.json"),
  es: require("./es.json"),
  fr: require("./fr.json"),
};

// List of all languages allowed in the application.
export const AllowedLocales = [
  { label: "English", value: "en" },
  { label: "Español (Spanish)", value: "es" },
  { label: "Français (French)", value: "fr" },
  { label: "Português (Portuguese)", value: "pt" },
];
