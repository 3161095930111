import React, { useMemo } from "react";
import { View, ActivityIndicator } from "react-native";
import { Text, Image } from "react-native-elements";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { fonts, backgroundColors, colors } from "styles/theme";
import { Accordion } from "components/elements";
import { request } from "library/utils/request";
import moment from "moment";
import { PrintIframeAction, PrintIframeRenderer } from "components/elements";
import { getPrintHTML } from "./helper";
import styles from "../styles";
import IMAGES from "static/assets/images";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { ToasterHandler } from "components/elements";
import { fetchMember } from "library/sagas/ongoing/global-data/slice";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const Notifications = ({
  isSmallScreen,
  shopCodes,
  notifications,
  loading,
  setNotifications,
  fetchMember,
  members,
  currentPage,
}) => {
  // const defaultCount = 5;
  // const [showAll, setShowAll] = useStateIfMounted(false);

  /*const records = showAll
    ? notifications
    : notifications.slice(0, defaultCount);
  const showViewAllLink = notifications.length > defaultCount && !showAll;*/

  const {
    messagesCount = 0,
    messagesUnreadCount = 0,
    notificationMessages = [],
  } = notifications;

  const { messages, Localise } = React.useContext(I18NContext);

  return (
    <View
      style={[
        { borderWidth: 1, borderColor: colors.light },
        { ...styles.notifications },
      ]}
    >
      <View
        style={[
          tw("p-3 w-full"),
          { backgroundColor: backgroundColors.primary },
        ]}
      >
        <Text
          style={{
            ...fonts.heading1,
            ...tw("text-white"),
            ...(isSmallScreen && { fontSize: 20 }),
          }}
          testID="ftd_notifications"
          accessibilityLabel="ftd_notifications"
        >
          {messagesUnreadCount
            ? `${messagesUnreadCount} ${Localise(
                messages,
                "Unread FTD Messages"
              )}`
            : Localise(messages, "FTD Notifications")}
        </Text>
      </View>
      <View style={tw("p-4")}>
        {messagesCount > 0 ? (
          notificationMessages.map((message, index) => {
            const { sendingMemberCode } = message;
            const memberInfo =
              (sendingMemberCode && members[sendingMemberCode]) || {};
            return (
              <Notification
                record={message}
                memberCode={message.memberCode}
                index={index}
                setNotifications={setNotifications}
                key={`${message.memberCode}_${index}`}
                notifications={notifications}
                fetchMember={fetchMember}
                memberInfo={memberInfo}
                members={members}
                testID={`${message.messageType}_${message.memberCode}_${index}`}
              />
            );
          })
        ) : loading ? (
          <ActivityIndicator color={colors.activityIndicator} />
        ) : (
          <Text>{Localise(messages, "No Notifications")}</Text>
        )}
      </View>
      {/*
      {showViewAllLink && (
        <View style={tw("px-4 mb-4")}>
          <Text
            style={{ ...fonts.link1, marginLeft: "auto" }}
            onPress={() => {
              setShowAll(true);
            }}
          >
            View all FTD notifications
          </Text>
        </View>
      )}
          */}
      {currentPage === "dashboard" ? (
        <PrintIframeRenderer testID={"notifications-print"} />
      ) : null}
    </View>
  );
};

const Notification = ({
  record,
  memberCode,
  setNotifications,
  notifications,
  index,
  members,
  fetchMember,
  memberInfo,
  testID,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);

  const {
    businessName = "",
    addressLine1 = "",
    city = "",
    state = "",
    zip = "",
  } = memberInfo;

  const {
    messageRecievedDate,
    messageTitle,
    message,
    messageIdentifier,
    messageRead,
    messageType,
    sendingMemberCode,
  } = record;

  const { notificationMessages, messagesUnreadCount, messagesCount } =
    notifications;

  const [loading, setLoading] = useStateIfMounted(false);
  const [toggleAccordian, setToggleAccrodian] = useStateIfMounted(false);

  const dateAndTime = moment(messageRecievedDate)
    .format("MM/DD/YYYY hh:mma")
    .split(" ");
  const fontsStyle = messageRead ? fonts.default : fonts.heading5;

  const handleDelete = (index) => {
    if (loading) return;
    setLoading(true);
    request("delete-notification", {
      memberCode,
      messageIdentifier,
      messageType,
    })
      .then((res) => {
        if (!res) {
          ToasterHandler(
            "error",
            Localise(messages, "Notification is already deleted")
          );
          return;
        }
        const updatedMessages = cloneDeep(notificationMessages);
        updatedMessages.splice(index, 1);
        setNotifications({
          ...notifications,
          messagesCount: messagesCount - 1,
          notificationMessages: updatedMessages,
        });
        ToasterHandler(
          "done",
          Localise(messages, "Your notification was deleted")
        );
      })
      .catch((error) => {
        ToasterHandler(
          "oops",
          Localise(messages, "Notification didn’t delete – please try again")
        );
      })
      .finally(() => {
        setLoading(false);
        setToggleAccrodian(!toggleAccordian);
      });
  };

  const accordianTitle =
    messageType === "FTD_NOTIFICATION"
      ? messageTitle
      : message.length > 20
      ? message.substring(0, 20) + "..."
      : message;

  return useMemo(() => {
    return (
      <View
        style={[tw("py-2 my-1"), { borderWidth: 1, borderColor: colors.light }]}
        key={`${memberCode}_${index}`}
      >
        <Accordion
          headerContent={(openAccordion) => (
            <View style={[tw("flex flex-row items-center w-full")]}>
              <Text style={fontsStyle}>{dateAndTime[0]}</Text>
              <Text style={[tw("ml-2"), fontsStyle]}>{dateAndTime[1]}</Text>
              <Text style={[tw("ml-4"), fontsStyle]}>{accordianTitle}</Text>
              <Image
                style={{
                  width: 20,
                  height: 20,
                }}
                containerStyle={{ marginLeft: "auto" }}
                source={
                  IMAGES[
                    openAccordion ? "expanded-section" : "collapsed-section"
                  ]
                }
              />
            </View>
          )}
          labelStyle={{
            backgroundColor: "white",
            marginTop: 0,
            paddingHorizontal: 5,
            marginHorizontal: 5,
            height: "auto",
          }}
          closeStyle={{ borderBottomWidth: 0 }}
          iconStyle={{ fontSize: 12, color: colors.primary }}
          titleStyle={{ ...fonts.heading6, color: colors.primary }}
          defaultOpen={false}
          handleOnPress={(openAccordion) => {
            if (openAccordion && messageType === "GENERAL_MESSAGE") {
              sendingMemberCode &&
                !members[sendingMemberCode] &&
                fetchMember(sendingMemberCode);
            }
            if (openAccordion && !messageRead) {
              !messageRead &&
                request("notification-action", {
                  memberCode,
                  messageIdentifier,
                  messageRead: true,
                  messageType,
                })
                  .then((res = false) => {
                    if (!res) return;
                    const updateMessages = cloneDeep(notificationMessages);
                    updateMessages[index].messageRead = true;
                    setNotifications({
                      ...notifications,
                      messagesUnreadCount: messagesUnreadCount - 1,
                      notificationMessages: updateMessages,
                    });
                  })
                  .catch((error) => console.log("error", error));
            }
          }}
          iconType={"chevron"}
          contentStyle={{
            backgroundColor: "white",
            borderWidth: 0,
            paddingVertical: 10,
            paddingHorizontal: 10,
          }}
          toggleAccordian={toggleAccordian}
          testID={testID}
        >
          <View style={{ maxHeight: 120, overflowY: "auto", paddingRight: 10 }}>
            <Text>{message}</Text>
            {sendingMemberCode && (
              <View style={tw("my-2")}>
                <Text style={[tw("mb-1"), fonts.heading5]}>
                  {Localise(messages, "Sending Florist")}:{" "}
                </Text>
                <View style={tw("mb-1 flex flex-row")}>
                  <Text style={{ width: 100 }}>
                    {Localise(messages, "Member Code")}
                  </Text>
                  <Text>{sendingMemberCode}</Text>
                </View>
                {!isEmpty(memberInfo) && (
                  <>
                    <View style={tw("mb-1 flex flex-row")}>
                      <Text style={{ width: 100 }}>
                        {Localise(messages, "Shop Name")}
                      </Text>
                      <Text>{businessName}</Text>
                    </View>
                    <View style={tw("flex flex-row")}>
                      <Text style={{ width: 100 }}>
                        {Localise(messages, "Address")}
                      </Text>
                      <View>
                        <Text>{addressLine1}</Text>
                        <Text>{`${city}, ${state} ${zip}`}</Text>
                      </View>
                    </View>
                  </>
                )}
              </View>
            )}
          </View>
          <View style={[tw("flex flex-row px-2 mt-4"), { marginLeft: "auto" }]}>
            {messageType === "FTD_NOTIFICATION" && (
              <Text
                style={[fonts.link1, tw("mx-2")]}
                onPress={() => {
                  handleDelete(index);
                }}
              >
                {Localise(messages, "Delete")}
              </Text>
            )}
            <Text
              style={[fonts.link1, tw("mx-2")]}
              onPress={() => {
                PrintIframeAction(
                  getPrintHTML(
                    dateAndTime,
                    messageTitle,
                    message,
                    sendingMemberCode,
                    memberInfo
                  )
                );
              }}
            >
              {Localise(messages, "Print")}
            </Text>
            <Text
              style={[fonts.link1, tw("mx-2")]}
              onPress={() => {
                if (loading) return;
                setLoading(true);
                request("notification-action", {
                  memberCode,
                  messageIdentifier,
                  messageRead: false,
                  messageType,
                })
                  .then((res = false) => {
                    if (!res) return;
                    const updateMessages = cloneDeep(notificationMessages);
                    updateMessages[index].messageRead = false;
                    setNotifications({
                      ...notifications,
                      messagesUnreadCount: messagesUnreadCount + 1,
                      notificationMessages: updateMessages,
                    });

                    setToggleAccrodian(!toggleAccordian);
                  })
                  .catch((error) => console.log("error", error))
                  .finally(() => setLoading(false));
              }}
            >
              {Localise(messages, "Mark as unread")}
            </Text>
            {loading && <ActivityIndicator color={colors.activityIndicator} />}
          </View>
        </Accordion>
      </View>
    );
  });
};

const mapStateToProps = (state = {}) => {
  return {
    members: state.mhq.ongoing.global.members,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchMember }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
