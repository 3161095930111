import { useState, useEffect, useRef } from "react";

function useIsComponentMounted() {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);
  return isMounted;
}

export default function useStateIfMounted(initialValue) {
  const isComponentMounted = useIsComponentMounted();
  const [state, setState] = useState(initialValue);
  function newSetState(value) {
    if (isComponentMounted.current) {
      setState(value);
    }
  }
  return [state, newSetState];
}
