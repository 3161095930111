import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";

export const initialState = {
  id: 0,
  sessionId: 0,
  status: "",
  openingBalance: 0.0,
  currentBalance: 0.0,
  events: [],
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setReset: (state) => {
      return initialState;
    },
    setSessionInfo: (state, action) => {
      return { ...state, ...action.payload };
    },
    setActivities: (state, action) => {
      state.events = action.payload;
    },

    // noop - only actions
    createOrEndSession: () => {},
    createTransaction: () => {},
    fetchSessionActivities: () => {},
    fetchSessionInfo: () => {},
    fetchAvailableSessions: () => {},
  },
});

export const {
  setReset,
  setSessionInfo,
  setActivities,
  createOrEndSession,
  createTransaction,
  fetchSessionActivities,
  endSession,
  fetchSessionInfo,
  fetchAvailableSessions,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
