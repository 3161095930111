import React from "react";
import { colors } from "styles/theme";
import { TouchableOpacity } from "react-native";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";

const CrossIcon = ({
  onPress,
  containerStyle = {},
  contentStyle = {},
  testID = "remove",
  accessibilityLabel = "remove",
}) => {
  return (
    <TouchableOpacity
      style={{
        borderRadius: 15,
        borderWidth: 1,
        borderColor: colors.primary,
        ...tw("justify-center items-center w-5 h-5"),
        ...containerStyle,
      }}
      onPress={onPress}
      testID={testID}
      accessibilityLabel={accessibilityLabel}
    >
      <Text
        style={{
          color: colors.primary,
          fontSize: 10,
          ...contentStyle,
        }}
      >
        X
      </Text>
    </TouchableOpacity>
  );
};

export default CrossIcon;
