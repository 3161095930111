import React, { useState } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import { Switch } from "components/elements";
import I18NContext from "library/contexts/i18N";
import { fonts } from "styles/theme";
import tw from "tailwind-rn";

const ToggleButton = ({
  value,
  onValueChange,
  disabled = false,
  activeText = "Active",
  inactiveText = "Inactive",
  timeOut = 1000,
}) => {
  const { Localise, messages } = React.useContext(I18NContext);

  const circleSize = 16,
    backgroundActiveColor = "#164158",
    backgroundInactiveColor = "#C7C7C7",
    circleActiveColor = "#ffffff",
    circleInActiveColor = "#ffffff";

  const [loading, setLoading] = useState(false);
  const onToggle = (val) => {
    setLoading(true);
    onValueChange(val);

    setTimeout(() => {
      setLoading(false);
    }, timeOut);
  };

  return (
    <View style={tw("flex flex-row px-2 py-2 items-center")}>
      {loading && (
        <View style={{ paddingRight: 10 }}>
          <ActivityIndicator />
        </View>
      )}
      <Text style={fonts.heading5}>
        {Localise(messages, value ? activeText : inactiveText)}{" "}
      </Text>
      <View style={{ maxWidth: 35, marginLeft: 10 }}>
        <Switch
          value={value}
          onValueChange={onToggle}
          disabled={disabled}
          circleSize={circleSize}
          backgroundActive={backgroundActiveColor}
          backgroundInactive={backgroundInactiveColor}
          circleActiveColor={circleActiveColor}
          circleInActiveColor={circleInActiveColor}
          changeValueImmediately={true} // if rendering inside circle, change state immediately or wait for animation to complete
          renderActiveText={false}
          renderInActiveText={false}
          switchLeftPx={1.25} // denominator for logic when sliding to TRUE position. Higher number = more space from RIGHT of the circle to END of the slider
          switchRightPx={1.25} // denominator for logic when sliding to FALSE position. Higher number = more space from LEFT of the circle to BEGINNING of the slider
          switchWidthMultiplier={2.5} // multipled by the `circleSize` prop to calculate total width of the Switch
          switchBorderRadius={30} // Sets the border Radius of the switch slider. If unset, it remains the circleSize.
          testID={"toggle-button"}
          accessibilityLabel={"Toggle"}
        />
      </View>
    </View>
  );
};

export default ToggleButton;
