import React from "react";
import { View, ActivityIndicator, Platform } from "react-native";
import { Text } from "react-native-elements";
import { CustomModal, ToasterHandler } from "components/elements";
import { colors } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import tw from "tailwind-rn";
import {
  TransactionTypes,
  sendCommandToPAXTerminalV1,
  getCancelTerminalTxnMessage,
} from "library/utils/payment-options";

const Processing = (props) => {
  const {
    authorizeGcPaymentMethod = false,
    currentPaymentMethod = "",
    setCurrentPaymentMethod,
    isFormSubmitting = false,
    setFormSubmitting,
    isGcAdded = [],
    isGcAuthorizationSuccess = false,
    waitMessage = "Creating your order(s). Just a moment.",
    formRef = null,
    sendingMemberRef = null,
    terminalTxnCancellingRef = null,
    isSavingDraft = false,
  } = props;

  const { messages, Localise } = React.useContext(I18NContext);

  let paymentIndex = "";
  if (currentPaymentMethod.includes("PAYMENT_TERMINAL")) {
    const splitArray = currentPaymentMethod.split("*");
    paymentIndex = splitArray[1];
  }

  const cancelTerminalTxn = () => {
    if (terminalTxnCancellingRef) terminalTxnCancellingRef.current = true;

    setCurrentPaymentMethod("");

    sendCommandToPAXTerminalV1({
      transactionType: TransactionTypes.CANCEL,
      sendingMember: sendingMemberRef ? sendingMemberRef.current : "",
      callback: ({ terminalResponse }) => {
        console.log("Cancel Txn Response in Processing :>> ", terminalResponse);

        setFormSubmitting(false);
        if (formRef) formRef.current.setSubmitting(false);

        ToasterHandler(
          "uh oh",
          Localise(
            messages,
            getCancelTerminalTxnMessage(terminalResponse?.ResponseText)
          )
        );

        if (terminalTxnCancellingRef) terminalTxnCancellingRef.current = false;
      },
    });
  };

  return (
    <React.Fragment>
      {authorizeGcPaymentMethod && (
        <CustomModal
          modalVisible={authorizeGcPaymentMethod}
          modalContent={{
            content: (
              <View style={[tw("items-center")]}>
                <View style={tw("flex flex-row")}>
                  <ActivityIndicator
                    style={{ marginLeft: 5, marginTop: -5 }}
                    color={colors.activityIndicator}
                  />
                  <Text style={{ marginLeft: 10 }}>
                    {Localise(messages, "Processing Gift Card Payment")}
                  </Text>
                </View>
              </View>
            ),
          }}
          contentStyle={[tw("p-4"), { backgroundColor: "white" }]}
          modalStyle={
            Platform.OS !== "web" && {
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor: "#00000070",
              color: "#FFFFFF",
            }
          }
        ></CustomModal>
      )}

      {currentPaymentMethod.length > 0 &&
        (isGcAdded?.length > 0 ? isGcAuthorizationSuccess : true) && (
          <CustomModal
            modalVisible={currentPaymentMethod.length > 0}
            modalContent={{
              content: (
                <View style={[tw("items-center")]}>
                  <View style={tw("flex flex-row")}>
                    <ActivityIndicator
                      style={{ marginLeft: 5, marginTop: -5 }}
                      color={colors.activityIndicator}
                    />
                    <Text style={{ marginLeft: 10 }}>
                      {Localise(messages, "Processing Payment")}{" "}
                      {paymentIndex !== "" && !isNaN(paymentIndex)
                        ? Number(paymentIndex) + 1
                        : ""}
                    </Text>
                  </View>
                  {currentPaymentMethod.includes("PAYMENT_TERMINAL") && (
                    <View style={{ marginTop: 16 }}>
                      <Text>
                        {Localise(
                          messages,
                          "Cancel transaction from Terminal using [X] key or click Cancel button below."
                        )}
                      </Text>
                    </View>
                  )}
                </View>
              ),
              ...(currentPaymentMethod.includes("PAYMENT_TERMINAL") && {
                buttons: [
                  {
                    type: "primary",
                    title: Localise(messages, "Cancel"),
                  },
                ],
              }),
            }}
            primaryhandler={cancelTerminalTxn}
            contentStyle={[tw("p-4"), { backgroundColor: "white" }]}
            modalStyle={
              Platform.OS !== "web" && {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#00000070",
                color: "#FFFFFF",
              }
            }
          ></CustomModal>
        )}

      {(isSavingDraft || (isFormSubmitting && currentPaymentMethod === "")) && (
        <CustomModal
          modalVisible={isSavingDraft || isFormSubmitting}
          modalContent={{
            content: (
              <View style={[tw("items-center")]}>
                <View style={tw("flex flex-row")}>
                  <ActivityIndicator
                    style={{ marginLeft: 5, marginTop: -5 }}
                    color={colors.activityIndicator}
                  />
                  <Text style={{ marginLeft: 10 }}>
                    {`${Localise(messages, waitMessage)}`}
                  </Text>
                </View>
              </View>
            ),
          }}
          contentStyle={[tw("p-4"), { backgroundColor: "white" }]}
          modalStyle={
            Platform.OS !== "web" && {
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor: "#00000070",
              color: "#FFFFFF",
            }
          }
        ></CustomModal>
      )}
    </React.Fragment>
  );
};

export default Processing;
