import React, { useEffect, useMemo, useRef } from "react";
import { Text } from "react-native-elements";
import { View, Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";

import tw from "tailwind-rn";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import { fonts, shapes, colors } from "styles/theme";

import PaymentDetails from "../payment-details";
import RedeemGiftCards from "../redeem-gift-cards-section";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import Environment from "library/utils/environment";
import UserProfileStorage from "library/storage/userProfile";

import { getTotalPrice, getGrandTotalPrice } from "library/utils/createOrder";
import { formatPrice } from "library/utils/formatter";
import { giftCardProducts } from "library/utils/giftCardProducts";
import { isCoreMember, isCoreConnectMember } from "library/utils/entitlements";
import { setGiftCardsData } from "library/sagas/views/home/drawer/create-order/slice";
import {
  selectIsEditOrder,
  selectGiftCardsData,
  selectIsSubscription,
  selectIsEditSubscription,
} from "library/sagas/views/home/drawer/create-order/selector";
import { selectApiResponse } from "library/sagas/ongoing/order-details/selector";

const OrderPayment = (props) => {
  const dispatch = useDispatch();
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);

  const isEditOrder = useSelector(selectIsEditOrder);
  const orderDetailsResponse = useSelector(selectApiResponse);
  const giftCardsResponse = useSelector(selectGiftCardsData);
  const isSubscription = useSelector(selectIsSubscription);
  const isEditSubscription = useSelector(selectIsEditSubscription);
  const paymentNeededRef = useRef(false);

  const { paymentDetails = {} } = orderDetailsResponse;
  const orderSource = get(orderDetailsResponse, "orderItems.0.orderSource", "");
  const orderDirection = get(
    orderDetailsResponse,
    "orderItems.0.direction",
    ""
  );
  const deliveryMethod = get(
    orderDetailsResponse,
    "orderItems.0.deliveryInfo.deliveryMethod",
    ""
  );

  const { setFieldValue, values, setValues } = useFormikContext();

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const {
    sendingMemberCode,
    paymentOptions,
    amountDue,
    enableSplitPayment = false,
    setValidationOnChange = () => {},
    houseAccountFormRef,
    onHouseAccountUpdateSuccess,
    onHouseAccountUpdateReject,
    onHouseAccountCreditLimitChange,
    setInnerFormLoading,
    subscriptionDetails = {},
  } = props;
  const isCoreUser = isCoreMember(sendingMemberCode);
  const isCoreConnectUser = isCoreConnectMember(sendingMemberCode);

  const { gift_cards = "false" } =
    UserProfileStorage.getShopPreferences(sendingMemberCode);

  const showOrderSummary =
    (!isCoreUser && !isCoreConnectUser && !isEditOrder) ||
    (isEditOrder &&
      !isCoreConnectUser &&
      orderSource !== "MOL" &&
      !isEmpty(paymentDetails) &&
      (orderDirection !== "OUTBOUND" || deliveryMethod === "PHONE_OUT"));

  const { orderItems = [], refundAvailable = "0.00" } = values;

  const productFirstChoiceCode = get(
    values,
    `orderItems.0.lineItems.0.productFirstChoiceCode`,
    ""
  );

  const { paymentMethodType, enablePayment } =
    values.paymentDetails?.paymentMethod[0] || {};
  const { paymentStatus } = values.paymentDetails || {};

  const hasLineItems = orderItems?.some((each) => each?.lineItems?.length > 0);

  const { orderTotal } = getTotalPrice(orderItems); // orders total without gc
  const amountToPay = isEditOrder ? amountDue : orderTotal;
  const { grandTotal = 0 } = getGrandTotalPrice(amountToPay, giftCardsResponse); // orders total with gc

  const subscriptionOrderAmounts =
    get(subscriptionDetails, "orderTemplate.orderAmounts") || [];

  const amountChargedToCustomerInSubscription =
    subscriptionOrderAmounts.find(
      (obj) => obj.name === "amountChargedToCustomer"
    )?.value || 0;

  useMemo(() => {
    if (isEditSubscription) {
      const isPaymentNeeded =
        parseFloat(orderTotal).toFixed(2) !==
        parseFloat(amountChargedToCustomerInSubscription).toFixed(2);

      paymentNeededRef.current = isPaymentNeeded;
      setFieldValue("savePayment", isPaymentNeeded);
      setFieldValue(
        "paymentDetails.paymentMethod.0.enablePayment",
        isPaymentNeeded
      );
      setFieldValue("enablePayment", isPaymentNeeded);
    }
  }, [
    isEditSubscription,
    orderTotal,
    amountChargedToCustomerInSubscription,
    enablePayment,
  ]);

  const showPayment = isEditOrder
    ? paymentStatus?.toLowerCase() === "pending" ||
      (hasLineItems &&
        enablePayment &&
        showOrderSummary &&
        formatPrice(refundAvailable) !== formatPrice(orderTotal))
    : isEditSubscription
    ? enablePayment && paymentNeededRef?.current
    : enablePayment;

  const isGiftCardsEnabled = Environment.get("GIFT_CARDS_ENABLED", false);

  useEffect(() => {
    if (giftCardsResponse?.length > 0) {
      dispatch(
        setGiftCardsData({
          actionName: "clear",
        })
      );
      setFieldValue("giftCard", "");
      setFieldValue("paymentDetails.paymentMethod.0.paymentMethodType", "");
    }
  }, [amountToPay]);

  const isProductGiftCard =
    orderItems?.length === 1 &&
    giftCardProducts.includes(productFirstChoiceCode);

  const isRedeemGiftCardEnabled =
    isGiftCardsEnabled &&
    !isProductGiftCard &&
    gift_cards === "true" &&
    !isSubscription;

  //Keeping below logs to debug this issue https://ftdcorp.atlassian.net/browse/MSOL-15160

  // useEffect(() => {
  //   console.log(
  //     "Payment Permission for ",
  //     sendingMemberCode,
  //     "==>",
  //     isPaymentEntitlementEnabled,
  //     "enablePayment ==>",
  //     enablePayment
  //   );

  //   Logger.info(
  //     `Payment Permission for ${sendingMemberCode} ==>`,
  //     `${isPaymentEntitlementEnabled} ,  enablePayment => ${enablePayment}`
  //   );
  // }, [enablePayment, sendingMemberCode]);

  return (
    <>
      {showPayment ? (
        <View
          style={[
            {
              ...shapes.sectionBorder,
              marginTop: 15,
              zIndex: -1,
            },
          ]}
        >
          {isRedeemGiftCardEnabled && (
            <RedeemGiftCards values={values} amountToPay={amountToPay} />
          )}
          <View
            style={[
              tw("py-2"),
              {
                paddingHorizontal: 5,
                ...(showPayment && { marginBottom: 10 }),
              },
            ]}
          >
            <View style={tw("flex flex-row items-center")}>
              <View
                style={[
                  tw("flex flex-row"),
                  { width: isDesktop ? "75%" : "70%" },
                ]}
              >
                <Text
                  style={[
                    fonts.sectionHeading,
                    tw("pr-4"),
                    { color: colors.primary },
                  ]}
                >
                  {Localise(messages, "Grand Total")}
                </Text>
              </View>
              <View
                style={[
                  tw("flex flex-row items-center justify-end"),
                  { width: isDesktop ? "25%" : "30%" },
                ]}
              >
                <Text
                  style={[fonts.sectionHeading, { color: colors.primary }]}
                  testID="grandTotal_title"
                >
                  {`$${formatPrice(grandTotal)}`}
                </Text>
              </View>
            </View>
          </View>
          <PaymentDetails
            isSmallScreen={!isDesktop}
            values={values}
            setValues={setValues}
            setFieldValue={setFieldValue}
            messages={messages}
            Localise={Localise}
            subTotal={grandTotal}
            paymentOptions={paymentOptions}
            sendingMemberCode={sendingMemberCode}
            isEditOrder={isEditOrder}
            grandTotal={grandTotal}
            mandatorySave={isSubscription}
            enableSplitPayment={enableSplitPayment}
            setValidationOnChange={setValidationOnChange}
            houseAccountFormRef={houseAccountFormRef}
            onHouseAccountUpdateSuccess={onHouseAccountUpdateSuccess}
            onHouseAccountUpdateReject={onHouseAccountUpdateReject}
            onHouseAccountCreditLimitChange={onHouseAccountCreditLimitChange}
            setInnerFormLoading={setInnerFormLoading}
            showInvoiceOnInactive={true}
          />
        </View>
      ) : null}

      {isElectronApp &&
      showPayment &&
      paymentMethodType === "PAYMENT_TERMINAL" ? (
        <View
          style={[tw("flex flex-row flex-wrap"), { marginTop: 15, zIndex: -1 }]}
        >
          <Text
            style={[
              {
                ...fonts.heading6,
                fontWeight: "normal",
              },
            ]}
          >
            {`* ${Localise(
              messages,
              "Save Payment won't work with digital payments like Apple Pay, Google Pay, Samsung Pay, and PayPal."
            )}`}
          </Text>
        </View>
      ) : null}
    </>
  );
};

export default React.memo(OrderPayment);
