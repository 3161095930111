import * as React from "react";
import ListingScreen from "components/containers/listing";
import StaffList from "./staff-list";
import {
  InitData,
  ListOfColumns,
  ListOfFilters,
  ListOfSortOptions,
  PermissionsforCreateUser,
} from "./config";
import UserProfileContext from "library/contexts/userProfile";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  setRecordId,
  setActions,
  setRecordAction,
  fetchData,
  setLoading,
  resetBasicInfo,
} from "library/sagas/ongoing/staff-listing/slice";
import FloristStaffProfileScreen from "components/views/drawer/florist-staff-profile";
import get from "lodash/get";
import UserProfileStorage from "library/storage/userProfile";

const StaffListingScreen = ({
  navigation: { setParams },
  route,
  staffListingInfo,
  resetBasicInfo,
  dashboardShopCode,
  ...userActions
}) => {
  const { userProfile } = React.useContext(UserProfileContext);
  const { authGroups = [] } = userProfile;
  const userRoles = get(authGroups, "0.roles", []);
  let showaction = false;
  const shopNames = UserProfileStorage.getAllShopNames();
  const { setActions } = userActions;

  // Checking wether loggedIn user has create user permission or not by fetching authorized roles.
  Object.keys(PermissionsforCreateUser)
    .filter((role) => PermissionsforCreateUser[role])
    .map((item) => {
      if (userRoles.includes(item)) {
        showaction = true;
      }
    });

  const { params = {} } = route;

  React.useEffect(() => {
    if (dashboardShopCode !== "all") {
      const selectedShops = [
        {
          ["Shop Codes"]: {
            title: `${dashboardShopCode} ${shopNames[dashboardShopCode]}`,
            value: dashboardShopCode,
          },
        },
      ];
      setActions({
        shops: selectedShops,
        selectedShops,
      });
    }

    setParams({ filters: undefined, shops: undefined }); // To eliminate the unwanted data in the URL when the user is from dashboard

    return () => {
      resetBasicInfo();
    };
  }, []);

  return (
    <ListingScreen
      listingType="staff"
      initData={InitData}
      columnsList={ListOfColumns}
      sortOptions={ListOfSortOptions}
      filterOptions={ListOfFilters}
      title={"Staff Management"}
      placeholder={"Search Staff"}
      route={"create-staff-profile"}
      actionName={"Create New User"}
      showaction={showaction}
      List={StaffList}
      Details={FloristStaffProfileScreen}
      listingInfo={staffListingInfo}
      userActions={userActions}
      params={params}
      CreateUser={FloristStaffProfileScreen}
    />
  );
};

const mapStateToProps = (state = {}) => {
  return {
    staffListingInfo: state.mhq.ongoing.staffListing,
    dashboardShopCode: state.mhq.ongoing.global.shopCode,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRecordId,
      setRecordAction,
      setActions,
      fetchData,
      setLoading,
      resetBasicInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StaffListingScreen);
