import React, { useEffect, useState } from "react";
import { View } from "react-native";

import { AutoComplete } from "components/elements";

import I18NContext from "library/contexts/i18N";

import orderBy from "lodash/orderBy";
import toUpper from "lodash/toUpper";
import differenceBy from "lodash/differenceBy";

const CollectionSearch = ({
  setInputData,
  referenceData,
  inputValue,
  selectorData,
  error,
  type = "title",
  popperPlacement,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const [collectionQuery, setCollectionQuery] = useState("");

  const remainingCollections = differenceBy(
    selectorData,
    referenceData,
    `${type}`
  );

  useEffect(() => {
    inputValue === "" && setCollectionQuery(inputValue);
  }, [inputValue]);

  const fieldValue =
    type === "title" ? inputValue : inputValue ?? collectionQuery;

  return (
    <>
      <View style={{ zIndex: 10, width: 250 }}>
        <AutoComplete
          placeholder={Localise(messages, "Select from collections")}
          onBlur={() => {}}
          onChangeText={(val) => {
            if (type === "handle") {
              setInputData(val);
              setCollectionQuery(val);
            } else setInputData({ query: val });
          }}
          data={orderBy(
            remainingCollections?.filter((e) =>
              toUpper(e.title).includes(
                toUpper(type === "title" ? inputValue : collectionQuery)
              )
            ),
            "title",
            "asc"
          )}
          initialDataLength={remainingCollections?.length}
          listDisplayValues={["title"]}
          onSelect={(val) => {
            type === "handle" ? setInputData(val?.handle) : setInputData(val);
          }}
          value={fieldValue}
          onOptionChange={() => {}}
          testID="menu-collections-suggestions"
          showOnFocus={true}
          outerContainerStyle={{ paddingHorizontal: 5 }}
          error={error}
          popperPlacement={popperPlacement}
        />
      </View>
    </>
  );
};

export default CollectionSearch;
