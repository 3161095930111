import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useFormikContext } from "formik";
import { Text, Button } from "react-native-elements";
import { View, Platform, Keyboard } from "react-native";
import isEmpty from "lodash/isEmpty";
import { fonts, theme, colors } from "styles/theme";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";

const ResetButton = ({
  title,
  type,
  icon,
  error = "",
  mainContainerStyle = {},
  containerStyle = {},
  buttonStyle = {},
  titleStyle = {},
  disabledStyle = {},
  disableOnErrors = false,
  isSubmitOnEnter = false,
  disableOnDirty = false,
  buttonRef = null,
  refFromProp,
  isLoading = false,
  testID,
  onPressCallback, // Optional callback prop
  customDisable = false,
  showCancelAlways = false,
  ...otherProps
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const [isDisabled, setDisabled] = useStateIfMounted(false);

  const resetBtnRef = useRef();
  useImperativeHandle(refFromProp, () => ({
    triggerReset: () => {
      resetBtnRef.current?.props?.onPress();
    },
    disableButton: (disable = false) => {
      setDisabled(disable);
    },
  }));

  const { resetForm, dirty, isSubmitting, isValid, errors } =
    useFormikContext();
  const [loading, setLoading] = useStateIfMounted(false);

  useEffect(() => {
    if (error || !isSubmitting) {
      setLoading(false);
    }
  }, [error, isSubmitting]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const ResetAction = () => {
    if (Platform.OS === "web") {
      resetForm();
    } else {
      setTimeout(() => {
        resetForm();
      }, 100);
    }
  };

  const handlePress = () => {
    setLoading(true);
    Keyboard.dismiss();
    ResetAction();
    setLoading(false);
    if (onPressCallback) {
      onPressCallback();
    }
  };

  const resetOnEnter = (event) => {
    if (event.keyCode === 13) {
      setLoading(true);
      ResetAction();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Platform.OS === "web" && isSubmitOnEnter) {
      if (buttonRef) {
        buttonRef.current.addEventListener("keydown", resetOnEnter);
      } else {
        document.addEventListener("keydown", resetOnEnter);
        return () => document.removeEventListener("keydown", resetOnEnter);
      }
    }
  }, []);

  const themeTitleStyle = theme.Button.secondaryTitleStyle;

  const themeButtonStyle = theme.Button.cancelButtonStyle;

  const loadingProps =
    type === "secondary"
      ? { color: theme.Button.secondaryTitleStyle.color }
      : type === "regular"
      ? { color: theme.Button.regularTitleStyle.color }
      : {};

  const id = testID || title.replace(" ", "_");
  return type === "link" ? (
    <Text
      testID={id}
      accessibilityLabel={id}
      style={[fonts.link1, containerStyle]}
      onPress={handlePress}
    >
      {Localise(messages, title)}
    </Text>
  ) : (
    <View style={mainContainerStyle}>
      {!isEmpty(errors) && !isEmpty(error) && (
        <Text style={theme.SubmitButton.errorStyle}>{error}</Text>
      )}
      <Button
        testID={id}
        accessibilityLabel={id}
        titleStyle={{ ...themeTitleStyle, ...titleStyle }}
        icon={icon}
        buttonStyle={{ ...themeButtonStyle, ...buttonStyle }}
        containerStyle={containerStyle}
        disabled={
          isDisabled
            ? true
            : showCancelAlways
            ? false
            : disableOnErrors
            ? !(dirty && isValid)
            : disableOnDirty
            ? !dirty
            : customDisable
        }
        disabledTitleStyle={{ color: colors.grayScaleLight }}
        disabledStyle={{
          ...disabledStyle,
          borderColor: colors.grayScaleLight,
          backgroundColor: colors.secondary,
        }}
        loading={loading}
        loadingProps={loadingProps}
        onPress={handlePress}
        title={Localise(messages, title)}
        ref={resetBtnRef}
        {...otherProps}
      />
    </View>
  );
};

export default React.memo(forwardRef(ResetButton));
