import React, { useRef, useEffect } from "react";
import { Platform } from "react-native";

import qw from "query-string";

import UserProfileStorage from "library/storage/userProfile";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";

const OnDemandEmailContent = () => {
  const [emailContentId, setEmailContentId] = useStateIfMounted("");
  const iframeRef = useRef(null);

  useEffect(() => {
    if (Platform.OS === "web" && window) {
      const { id: urlEmailContentId } = qw.parse(window.location.search);
      urlEmailContentId &&
        UserProfileStorage.setEmailContentId(urlEmailContentId);
      urlEmailContentId
        ? setEmailContentId(urlEmailContentId)
        : setEmailContentId(UserProfileStorage.getEmailContentId());
    }
  }, []);

  useEffect(() => {
    getEmailContentDetails();
  }, [emailContentId]);

  const getEmailContentDetails = () => {
    emailContentId &&
      request("get-html-email-content", {
        emailContentId: emailContentId,
      })
        .then((res) => {
          const { html = "" } = res;

          if (iframeRef.current) {
            const iframe = iframeRef.current.contentWindow;
            iframe.document.open();
            iframe.document.write(html);

            iframe.document.close();
            iframe.focus();
            iframe.print();
          }
        })
        .catch((error) => {
          console.log("error: " + error);
        });
  };

  return (
    <iframe
      ref={iframeRef}
      title="print-invoice"
      style={{ display: "none" }}
      data-test-id="print-invoice"
    />
  );
};

export default OnDemandEmailContent;
