import React, { useEffect } from "react";
import { Platform, View, ActivityIndicator } from "react-native";
import { CheckBox } from "react-native-elements";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Text } from "react-native-elements";
import { setIsAutoFill } from "library/sagas/ongoing/global-data/slice";
import Autocomplete from "./auto-complete";
import { formatData } from "library/utils/createOrder";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { theme, colors } from "styles/theme";
import tw from "tailwind-rn";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { Keyboard } from "react-native";
import I18NContext from "library/contexts/i18N";

import { DeviceContext } from "library/contexts/appSettings";

const AutoComplete = ({
  label,
  name,
  loading,
  data,
  initialDataLength,
  value,
  onBlur,
  onChangeText,
  onOptionChange,
  placeholder,
  placeholderTextColor,
  labelStyle,
  containerStyle,
  innerContainerStyle,
  outerContainerStyle,
  inputStyle,
  onSelect,
  error,
  listDisplayValues,
  delimiter,
  clearTextOnBackTab,
  showOnFocus,
  setFocusBack,
  isMultiSelect,
  selectedValues,
  setIsAutoFill,
  dataLimit,
  textOverFlowAsEllipsisLength,
  popperPlacement,
  listStyle,
  listContainerStyle,
  showOnSelect,
  ...otherProps
}) => {
  const [showSuggestions, setShowSuggestions] = useStateIfMounted(false);
  const [activeOption, setActiveOption] = useStateIfMounted(-1);
  const [isFocus, setIsFocus] = useStateIfMounted(false);
  const { messages, Localise } = React.useContext(I18NContext);

  const { isDesktop } = React.useContext(DeviceContext);
  const height = isDesktop ? 33 : 35;

  useEffect(() => {
    if (activeOption > -1) onOptionChange(data[activeOption]);
  }, [activeOption]);

  // const TouchableComponent =
  //   Platform.OS === "web" ? TouchableWithoutFeedback : TouchableOpacity;
  let TouchableComponent =
    Platform.OS === "web"
      ? require("react-native").TouchableWithoutFeedback
      : (TouchableComponent =
          require("react-native-gesture-handler").TouchableOpacity);

  const onKeyPress = (e) => {
    const keyCode = e.which || e.keyCode;
    if (
      !data?.length ||
      (keyCode !== 13 &&
        keyCode !== 38 &&
        keyCode !== 40 &&
        keyCode !== 8 &&
        keyCode !== 9)
    )
      return;

    // Key Up
    if (keyCode === 38 && activeOption > 0) {
      setActiveOption(activeOption - 1);
    }

    // Key Down
    else if (keyCode === 40 && activeOption < data.length - 1) {
      setActiveOption(activeOption + 1);
    }

    // Key Enter
    else if (keyCode === 13 || keyCode === 9) {
      handleSelect(data[activeOption]);
    }

    // Backspace
    else if (keyCode === 8) {
      clearTextOnBackTab && onChangeText("");
    }
  };

  let timer = null;
  let isSelected = false;

  const handleSelect = (item) => {
    !isMultiSelect && setIsAutoFill(false);
    isSelected = true;
    clearTimeout(timer);
    onSelect(item);
    !isMultiSelect && setShowSuggestions(false);
    setActiveOption(-1);
    setFocusBack && setIsFocus(true);
    !isMultiSelect && Keyboard.dismiss();
  };

  return (
    <View style={[theme.AutoComplete.outerContainerStyle, outerContainerStyle]}>
      {!!(label || loading) && (
        <View style={tw("flex flex-row")}>
          {label && (
            <Text style={[theme.AutoComplete.labelStyle, labelStyle]}>
              {label}
            </Text>
          )}
          {loading && (
            <ActivityIndicator
              style={{ marginLeft: 5, marginTop: -5 }}
              color={colors.activityIndicator}
            />
          )}
        </View>
      )}
      <View style={{ height: height + 2 }}>
        <View style={[theme.AutoComplete.containerStyle, containerStyle]}>
          <Autocomplete
            autoComplete="new-password"
            popperPlacement={popperPlacement}
            name={name}
            onBlur={() => {
              if (!isSelected) {
                timer = setTimeout(() => {
                  !isMultiSelect && setIsAutoFill(false);
                  isMultiSelect && setIsFocus(false);
                  showSuggestions && setShowSuggestions(false);
                  isSelected = false;
                  onBlur();
                }, 500);
              }
            }}
            listStyle={listStyle}
            containerStyle={[
              theme.AutoComplete.innerContainerStyle,
              innerContainerStyle,
            ]}
            height={height}
            style={{ ...theme.AutoComplete.inputStyle, ...inputStyle }}
            listContainerStyle={[
              theme.AutoComplete.listContainerStyle,
              listContainerStyle,
            ]}
            data={(showSuggestions && data) || []}
            value={value}
            onChangeText={(val) => {
              onChangeText(val);
              setShowSuggestions(true);
            }}
            onFocus={(text) => {
              !isMultiSelect && setIsAutoFill(true);
              showOnFocus &&
                !isFocus &&
                data.length > 0 &&
                setShowSuggestions(true);
              isMultiSelect && setIsFocus(true);
              showOnSelect && text?.target?.select();
            }}
            dataLimit={dataLimit}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            placeholderTextColor={placeholderTextColor}
            isFocus={isFocus}
            isMultiSelect={isMultiSelect}
            showSuggestions={showSuggestions}
            activeOption={activeOption}
            renderItem={({ item, index }) => {
              const textStyle = {
                ...theme.AutoComplete.textStyle,
                ...(activeOption === index &&
                  theme.AutoComplete.activeOptionStyle),
                ...(index === 0 && theme.AutoComplete.renderSeparatorstyle),
              };

              return isMultiSelect ? (
                <CheckBox
                  key={index}
                  testID={item.label}
                  accessibilityLabel={item.label}
                  Component={TouchableOpacity}
                  checked={
                    selectedValues.length ===
                    initialDataLength -
                      data.filter((e) => e.label === Localise(messages, "All"))
                        .length
                      ? true
                      : selectedValues.includes(item.value)
                  }
                  checkedIcon="check-square"
                  uncheckedIcon="square"
                  containerStyle={[
                    theme.CheckBox.inputContainerStyle,
                    { marginLeft: 10 },
                  ]}
                  iconType="font-awesome-5"
                  onPress={() => handleSelect(item)}
                  size={20}
                  textStyle={theme.CheckBox.textStyle}
                  title={item.label}
                />
              ) : (
                <TouchableComponent
                  onPress={() => handleSelect(item)}
                  onPressIn={() => {
                    Platform.OS === "android" && handleSelect(item);
                  }} // only for android
                >
                  {delimiter === "space" ? (
                    <View
                      style={[
                        tw("flex flex-row"),
                        !textOverFlowAsEllipsisLength ? tw("flex-wrap") : {},
                      ]}
                    >
                      {Object.values(item).map((val, key) => (
                        <View key={key}>
                          <Text style={textStyle}>
                            {!!textOverFlowAsEllipsisLength &&
                            val.length > textOverFlowAsEllipsisLength
                              ? `${val.slice(
                                  0,
                                  textOverFlowAsEllipsisLength
                                )}...`
                              : val}
                          </Text>
                        </View>
                      ))}
                    </View>
                  ) : (
                    <Text
                      style={textStyle}
                      testID={`${index}`}
                      accessibilityLabel={`${index}`}
                    >
                      {formatData(item, listDisplayValues, delimiter)}
                    </Text>
                  )}
                </TouchableComponent>
              );
            }}
            {...otherProps}
          />
        </View>
      </View>
      <Text style={{ ...theme.Input.errorStyle, zIndex: -1 }}>{error}</Text>
    </View>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setIsAutoFill }, dispatch);
export default connect(null, mapDispatchToProps)(AutoComplete);
