import ProductDetails from "./product-details";
import DeliveryDetails from "./delivery-details";
import RecipientDetails from "./recipient-details";
import DeliveryActions from "./delivery-actions";
import DeliveryInfo from "./delivery-Info";

export const singleDSRequestCreateSections = [
  {
    name: "Delivery Details",
    Component: DeliveryDetails,
    accordionWrap: false,
    displayOpen: true,
    overrideZindex: 10,
  },
  {
    name: "Product Details",
    Component: ProductDetails,
    accordionWrap: false,
    displayOpen: true,
    overrideZindex: 5,
  },
  {
    name: "Recipient Details",
    Component: RecipientDetails,
    accordionWrap: false,
    displayOpen: true,
    overrideZindex: 9,
  },
  {
    name: "Delivery Actions",
    Component: DeliveryActions,
    accordionWrap: false,
    displayOpen: true,
    overrideZindex: 8,
  },
];

export const DSRequestDetailsSections = [
  {
    name: "Delivery Details",
    Component: DeliveryInfo,
    accordionWrap: false,
    displayOpen: true,
    overrideZindex: 10,
  },
  {
    name: "Delivery Actions",
    Component: DeliveryActions,
    accordionWrap: false,
    displayOpen: true,
    overrideZindex: 9,
  },
  {
    name: "Recipient Details",
    Component: RecipientDetails,
    accordionWrap: false,
    displayOpen: true,
    overrideZindex: 8,
  },
];
