import React, { useEffect } from "react";
import { TouchableOpacity, View, Dimensions, Platform } from "react-native";

import { Image } from "react-native-elements";
import { CustomModal } from "../modal";

import tw from "tailwind-rn";

import IMAGES from "static/assets/images";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { DeviceContext } from "library/contexts/appSettings";

const ImageModal = ({
  style = {},
  resizeMode = "cover",
  uri = "",
  showImageModal = true,
}) => {
  const [showModal, setShowModal] = useStateIfMounted(false);
  const [imageSize, setImageSize] = useStateIfMounted({});
  const { isDesktop } = React.useContext(DeviceContext);

  useEffect(() => {
    if (showImageModal) {
      uri !== "" &&
        Image.getSize(
          uri,
          (width, height) => {
            if (
              width > Dimensions.get("window").width ||
              height > Dimensions.get("window").height
            ) {
              setImageSize({
                width: Dimensions.get("window").width / (isDesktop ? 2 : 1.2),
                height: Dimensions.get("window").height / 2,
              });
            } else {
              setImageSize({ width, height });
            }
          },
          (error) => {
            console.error(`Couldn't get the image size: ${error}`);
          }
        );
    }
  }, [showModal]);

  const modalContent = {
    content: (
      <View style={[tw("flex items-center justify-center")]}>
        <Image style={{ ...imageSize }} resizeMode="contain" source={{ uri }} />
        <View
          style={[
            tw("flex-row items-center justify-end w-full top-0 absolute"),
            { zIndex: 1 },
          ]}
        >
          <Image
            style={{
              width: 20,
              height: 20,
            }}
            resizeMode="cover"
            source={IMAGES["close"]}
            onPress={() => setShowModal(!showModal)}
          />
        </View>
      </View>
    ),
    buttons: [],
  };

  return (
    <>
      <TouchableOpacity
        onPress={() => showImageModal && setShowModal(!showModal)}
      >
        <Image
          style={style}
          resizeMode={resizeMode}
          source={showImageModal ? { uri } : uri}
        />
      </TouchableOpacity>
      <CustomModal
        modalVisible={showModal}
        modalContent={modalContent}
        contentStyle={{
          backgroundColor: "white",
          ...imageSize,
        }}
        modalStyle={
          Platform.OS !== "web" && {
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            backgroundColor: "#00000070",
            color: "#FFFFFF",
          }
        }
      ></CustomModal>
    </>
  );
};

export default ImageModal;
