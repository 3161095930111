import React, { useContext } from "react";
import { View, TouchableOpacity, Image, Platform } from "react-native";
import { Text } from "react-native-elements";
import { IMAGES } from "static/assets/images";
import { PopupMenu, Tooltip } from "components/elements";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";

export const PrintMenusContainer = ({
  printMenuContainer,
  printContainer,
  isSmallScreen = false,
  submitAction,
  getExtendedMenuOptions,
  onPrintSelection,
  currentPage = "",
  isPrinted = false,
  printText,
  printOptionsContainer,
  action = "",
  disablePrint = false,
  showPrintOptions = true,
}) => {
  const { messages, Localise } = useContext(I18NContext);

  return (
    <View
      style={[
        printMenuContainer, // Base style
        tw("flex-row"), // Tailwind or other style utility
        { opacity: disablePrint ? 0.4 : 1 }, // Conditional opacity
        disablePrint && { pointerEvents: "none" }, // Conditional pointerEvents
      ]}
    >
      {showPrintOptions && (
        <>
          <View style={printContainer}>
            <TouchableOpacity
              activeOpacity={0.5}
              onPress={() => submitAction(action)}
              testID="print"
              accessibilityLabel="print"
              style={tw("items-center")}
            >
              <Tooltip
                text={Localise(messages, "Print Artifacts")}
                renderForWebOnly={true}
              >
                <Image
                  style={{
                    width: isSmallScreen ? 24 : 26,
                    height: isSmallScreen ? 24 : 26,
                  }}
                  resizeMode="contain"
                  source={IMAGES[isPrinted ? "printAgain" : "print"]}
                />
              </Tooltip>
              {Platform.OS === "web" && action === "printOrder" && (
                <Text
                  style={[printText, { textAlign: "center", marginTop: 4 }]}
                >
                  {isPrinted ? "Printed" : "Not Printed"}
                </Text>
              )}
            </TouchableOpacity>
          </View>
          {currentPage === "orders" && (
            <PopupMenu
              menuOptions={getExtendedMenuOptions}
              containerStyle={printOptionsContainer}
              onSelect={onPrintSelection}
              optionsContainerStyle={{
                minWidth: 110,
                maxWidth: 120,
                padding: 4,
              }}
              testID="print_dropdown"
              accessibilityLabel="print_dropdown"
            >
              <View
                style={tw(
                  `${
                    isSmallScreen ? "p-0" : "px-2"
                  } flex-row justify-end items-center`
                )}
              >
                <Tooltip
                  text={Localise(messages, "Expand")}
                  renderForWebOnly={true}
                >
                  <Image
                    style={{
                      width: 18,
                      height: 18,
                    }}
                    resizeMode="cover"
                    source={IMAGES["collapsed-section"]}
                  />
                </Tooltip>
              </View>
            </PopupMenu>
          )}
        </>
      )}
    </View>
  );
};
