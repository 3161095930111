import { request as fetch } from "library/utils/request";
import UserProfileStorage from "library/storage/userProfile";

const request = (service = "", params = {}) => {
  const allowSyndicate = ["save-mol-content", "delete-mol-content"].includes(
    service
  );
  const memberCodes = UserProfileStorage.getProfileMemberCodes();
  const shopCode = params?.shopCode ?? "";

  if (params?.shopCode === "all") {
    delete params.shopCode;
    if (!allowSyndicate) {
      params.memberCode = memberCodes[0];
    }
  } else {
    params.memberCode = shopCode;
  }

  return fetch(service, params);
};

export default request;
