import * as Yup from "yup";

export const getValidationSchema = (Localise, messages) => {
  return Yup.object().shape({
    name: Yup.string().required(
      Localise(messages, "Please enter Collection Name")
    ),

    seoPageTitle: Yup.string()
      .label("seoPageTitle")
      .max(70, Localise(messages, "Maximum Limit is 70 Characters")),

    seoMetaDescription: Yup.string()
      .label("seoMetaDescription")
      .max(320, Localise(messages, "Maximum Limit is 320 Characters")),
  });
};
