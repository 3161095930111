import Storage from "library/utils/storage";
import { Platform } from "react-native";

const EnvironmentContextCache = new Storage("EnvironmentContextCache", {
  enableLocal: false,
  enableSession: Platform.OS === "web" ? true : false,
  throttleWait: 0,
  //ttl: 24 * 60 * 60 * 1000, //PAR-2635 - issue when session is more than 24 hours
});

const load = () => EnvironmentContextCache.load();

const getName = () => {
  return EnvironmentContextCache.get("ENV");
};

const getConfig = () => {
  return EnvironmentContextCache.get("CONFIG");
};

const setName = (envName) => EnvironmentContextCache.put("ENV", envName);

const setConfig = (config) => EnvironmentContextCache.put("CONFIG", config);

const setFCMDeviceToken = (token) => {
  EnvironmentContextCache.put("fcmDeviceToken", token);
};

const getFCMDeviceToken = () => {
  return EnvironmentContextCache.get("fcmDeviceToken") || "";
};

export default {
  getName,
  setName,
  load,
  getConfig,
  setConfig,
  setFCMDeviceToken,
  getFCMDeviceToken,
};
