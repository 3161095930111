import React, { useState } from "react";
import RenderUI from "index";
import * as ReactNative from "react-native";
//import * as Font from "expo-font";
import AppLoading from "expo-app-loading";
import * as FullStoryBrowser from "@fullstory/browser";
import FullStory from "@fullstory/react-native";
import { request } from "library/utils/request";
import UserAuthStorage from "library/storage/userAuth";
import UserProfileStorage from "library/storage/userProfile";
import AppSettingsStorage from "library/storage/appSettings";
import EnvironmentStorage from "library/storage/environment";
import UtilStorage from "library/storage/utilStorage";
import AutomationStorage from "library/storage/automationStorage";
import PageStorage from "library/storage/pageStorage";
import DeviceInfo from "react-native-device-info";
import FormReloadCache from "library/storage/formReloadStorage";
import { compareVersions } from "library/utils/formatter";

const { Platform } = ReactNative;
const { LogBox } = Platform.OS !== "web" ? ReactNative : {};

export default function App() {
  const [loading, setLoading] = useState(true);

  // TODO MOBILE: MOS - always have force updates - make AppLoading wait for this
  // 1. Call to App Store / Google Play Store
  //    --> get the latest build
  //    --> if its not same as current build, simply force update.

  // 2. get-build-info: Call WEB API GATEWAY to know the current build's environment
  // "https://api-gateway.ftdi.com/getReleaseChannel?buildId=<current-build>";

  const getBuildInfo = () => {
    let captchaSiteKey = "";
    if (Platform.OS === "web") {
      const { env, MHQ_GOOGLE_CAPTCHA_SITE_KEY = "" } = window.__env;
      EnvironmentStorage.setName(env);
      captchaSiteKey = MHQ_GOOGLE_CAPTCHA_SITE_KEY;
    }

    return request("get-build-info", { platform: Platform.OS }).then(
      (buildInfo) => {
        // For backward compatibility we added this check buildInfo -> IOS_APP_INFO and need to clean this after Release 11
        if (buildInfo?.IOS_APP_INFO) {
          if (Platform.OS === "ios") {
            const { IOS_APP_INFO } = buildInfo;
            return Promise.resolve(IOS_APP_INFO);
          } else if (Platform.OS === "android") {
            const { ANDROID_APP_INFO } = buildInfo;
            return Promise.resolve(ANDROID_APP_INFO);
          } else {
            return Promise.resolve(window.__env);
          }
        } else {
          if (Platform.OS === "web") {
            buildInfo.config["MHQ_GOOGLE_CAPTCHA_SITE_KEY"] = captchaSiteKey;
            const { config: { version = "" } = {} } = buildInfo;
            const currentRelease = UtilStorage.getReleaseVersion("CurrentVersion");
            if (compareVersions(currentRelease, version) !== 0) {
              UtilStorage.setReleaseVersion(version);
              window.location.reload();
            }
          }
          return Promise.resolve(buildInfo);
        }
      }
    );
  };

  const loadStorage = (env) =>
    UserAuthStorage.load()
      .then(() => UserProfileStorage.load())
      .then(() => AppSettingsStorage.load())
      .then(() => EnvironmentStorage.load())
      .then(() => UtilStorage.load())
      .then(() => AutomationStorage.load())
      .then(() => FormReloadCache.load())
      .then(() => PageStorage.load());

  // const fontConfig = {
  //   "Montserrat-Regular": require("./src/static/assets/fonts/Montserrat-Regular.ttf"),
  //   "Montserrat-Bold": require("./src/static/assets/fonts/Montserrat-Bold.ttf"),
  //   "Montserrat-Light": require("./src/static/assets/fonts/Montserrat-Light.ttf"),
  //   "Montserrat-Medium": require("./src/static/assets/fonts/Montserrat-Medium.ttf"),
  // };

  //const loadFonts = () => Font.loadAsync(fontConfig);

  const initFullStory = () => {
    let isProd = EnvironmentStorage.getName() === "prod";

    // we can un-comment below line in order to see full story sessions in Non-Prod QA mobile builds to test MSOL-1632
    // isProd = true;

    if (Platform.OS === "web") {
      isProd && FullStoryBrowser.init({ orgId: "108N8X" });
    } else {
      isProd && FullStory.restart(); // For starting the native mobiles session
    }
  };

  const loadAppAsync = async () =>
    loadStorage()
      //.then(() => loadFonts())
      .then(() => getBuildInfo())
      .then(({ env, config }) => {
        if (Platform.OS === "web") {
          EnvironmentStorage.setConfig(config);
        } else {
          EnvironmentStorage.setName(env);

          // We are checking any force app update available first to show app update alert dialog in order to prevent user to use app
          if (DeviceInfo.getBuildNumber() < config.force_update_build_number) {
            EnvironmentStorage.setConfig({
              ...config,
              update_type: "force",
              force_update_build_number: config.force_update_build_number,
              update_needed: true,
            });
          }
          // Next we are checking any normal app update available to show app update alert dialog
          else if (
            DeviceInfo.getBuildNumber() < config.normal_update_build_number
          ) {
            EnvironmentStorage.setConfig({
              ...config,
              update_type: "normal",
              normal_update_build_number: config.normal_update_build_number,
              update_needed: true,
            });
          }
          // If user already has latest avaiable app version, we are not showing app update alert dialog
          else {
            EnvironmentStorage.setConfig({
              ...config,
              update_type: "",
              update_needed: JSON.stringify(false), // as Async Storage storing only string data and in order to store boolean value I am serializing it first.
            });
          }

          /**
           * On initial app launch, we are assuming app update alert dialog is not shown so that we can show it again (if any app update is available)
           * when user reopened app after user quit app forcefully.
           * */
          AppSettingsStorage.setAppUpdateAlertDialogShown(false);
        }

        // To see fullstory sessions in local when required, please move below initFullStory() to outside/finally (by adidng it) as we are getting
        // CORS error in local for getBuildInfo call. To avoid capturing fullstory sessions in local so we are not moving them now.
        initFullStory();
      });

  LogBox &&
    LogBox.ignoreLogs([
      "Failed prop type: Invalid props.style key",
      "Animated: `useNativeDriver`",
      "VirtualizedLists should never be nested",
    ]);

  return loading ? (
    <AppLoading
      startAsync={loadAppAsync}
      onFinish={() => setLoading(false)}
      onError={console.warn}
    />
  ) : (
    <RenderUI />
  );
}
