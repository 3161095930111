import moment from "moment";
import format from "date-fns/format";

export const dateTimeFormat = "YYYY-MM-DDTHH:mm:ss[Z]";

export const addLeadingZeros = (value) => {
  return value < 10 ? `0${value}` : value;
};

/**
 * Converts elapsed milliseconds to a timespan object
 * @param {Number} elapsedMilliseconds a date range in milliseconds
 * @returns {Object} timespan object { days, hours, minutes, seconds }
 */
export const toTimeSpan = (elapsedMilliseconds = defaultExpiredElapsed) => {
  const minToMilliSec = 1000 * 60;
  const hrToMilliSec = minToMilliSec * 60;
  const dayToMilliSec = hrToMilliSec * 24;

  // find the smallest absolute value
  const truncate = elapsedMilliseconds >= 0 ? Math.floor : Math.ceil;

  const hrs = truncate((elapsedMilliseconds % dayToMilliSec) / hrToMilliSec);
  const minutes = truncate(
    (elapsedMilliseconds % hrToMilliSec) / minToMilliSec
  );
  const seconds = truncate((elapsedMilliseconds % minToMilliSec) / 1000);

  const timespan = {
    hours: hrs,
    minutes: minutes,
    seconds: seconds,
  };

  return timespan;
};

export const isToday = (date) => {
  const dateObj = moment(date).startOf("day");
  const todayObj = moment().startOf("day");
  const isToday = todayObj.isSame(dateObj);

  return isToday;
};

export const getTimeLeftInMins = (
  targetTime,
  timeZone = "",
  sourceTime = moment()
) => {
  const timeDiff = moment(targetTime).diff(
    timeZone ? sourceTime.tz(timeZone) : sourceTime
  );
  const truncate = timeDiff >= 0 ? Math.floor : Math.ceil;
  const timeLeftInMins = truncate(timeDiff / (1000 * 60));
  return timeLeftInMins;
};

export const getPickupTimeLeftInMins = (targetTime = "") => {
  if (!targetTime) return 0;
  const timeDiff = moment(targetTime).diff(moment(moment().toISOString()));
  const truncate = timeDiff >= 0 ? Math.floor : Math.ceil;
  const timeLeftInMins = truncate(timeDiff / (1000 * 60));
  return timeLeftInMins;
};

export const getFormattedDate = (date = new Date()) => {
  return format(date, "MM/dd/yyyy HH:mm:ss");
};
