import { request } from "library/utils/request";
import startsWith from "lodash/startsWith";
import get from "lodash/get";
import kebabCase from "lodash/kebabCase";
import { Platform, Dimensions } from "react-native";
import { UpdatePopupRequiredTabs } from "components/containers/tabs/constants";

export const getDeviceInfo = () => {
  const { width: windowWidth, height: windowHeight } = Dimensions.get("window");
  const deviceOrientaion = windowWidth < windowHeight ? "potrait" : "landscape";
  const dimensionMetric =
    deviceOrientaion === "potrait" ? windowWidth : windowHeight;
  const hasTouchscreen = Platform.OS === "web" && "ontouchstart" in window;
  const deviceType =
    Platform.OS === "ios"
      ? windowWidth > 767
        ? "tablet"
        : "mobile"
      : Platform.OS === "android"
      ? windowWidth > 767
        ? "tablet"
        : "mobile"
      : hasTouchscreen
      ? windowWidth > 767
        ? windowWidth < 1024
          ? "tablet"
          : "desktop"
        : "mobile"
      : windowWidth >= 1024
      ? "desktop"
      : dimensionMetric < 640
      ? "mobile"
      : dimensionMetric > 960
      ? "desktop"
      : "tablet";

  return { deviceType, deviceOrientaion };
};

const requestPermissionGroups = (params = {}) => {
  return request("get-user-permission-groups", params).then(
    (resp) => resp?.permissionsGroups
  );
};

const requestMenus = (userRole, mock = false) => {
  return request("get-menus", undefined, undefined, mock).then((res) => {
    let menuItems = get(
      res,
      mock && startsWith(userRole, "FTD_") ? "FTD" : "submenu",
      []
    );

    const customMenuNames = {
      "Driver Dashboard Create Route": "create-route",
      "Driver Dashboard Edit Route": "edit-route",
    };

    menuItems.map((menu) => {
      const prefix = startsWith(userRole, "FTD_") ? "ftd-" : "";
      const name = `${prefix}${
        customMenuNames[menu.label] || kebabCase(menu.label)
      }`;
      menu.name = name;

      ["profile", "logout", "ftd-profile", "ftd-logout"].includes(name) &&
        (menu.mobileMenu = true);
      name === "create-staff-profile" && (menu.hidden = true);
      name === "ftd-create-user" && (menu.hidden = true);
      name === "demo" && (menu.hidden = true);
      name === "feedback" && (menu.hidden = true);
      name === "releases" && (menu.hidden = true);
      name === "create-route" && (menu.hidden = true);
      name === "edit-route" && (menu.hidden = true);
      name === "dashboard" && (menu.unmountOnBlur = false);
      name === "create-customer" && (menu.hidden = true);
      name === "order-delivery-status" && (menu.hidden = true);
      name === "create-delivery-service-request" && (menu.hidden = true);
      name === "create-subscription" && (menu.hidden = true);
      name === "delivery-settings" && (menu.hidden = true);
      name === "create-card" && (menu.hidden = true);
      name === "create-event" && (menu.hidden = true);
      name === "events" && (menu.hidden = Platform.OS !== "web");
      name === "qr-code-scan" && (menu.hidden = true);
    });

    menuItems = menuItems.reduce((acc, user) => {
      if (!acc.find((u) => u.name === user.name)) {
        acc.push(user);
      }
      return acc;
    }, []);

    return menuItems;
  });
};

// const requestMenus = (userRole, mock = false) => {
//   return Promise.reject("Error");
// };

const checkUpdatePopupisRequired = (title) => {
  if (title) {
    const formattedTitle = title.toLowerCase().replace(/\s+/g, ''); // Convert to lowercase and remove spaces
    return Object.values(UpdatePopupRequiredTabs)
      .map(value => value.toLowerCase().replace(/\s+/g, '')) // Convert values to lowercase and remove spaces
      .includes(formattedTitle);
  } else {
    return false;
  }
};

export default {
  requestMenus,
  requestPermissionGroups,
  checkUpdatePopupisRequired,
};
