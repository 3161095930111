import React, { useEffect } from "react";
import { View, Platform } from "react-native";
import {
  FormFieldDatePicker,
  FormFieldPicker,
} from "components/elements/forms";
import moment from "moment";
import tw from "tailwind-rn";
import UserProfileStorage from "library/storage/userProfile";
import { backgroundColors } from "styles/theme";
import {
  convertToTimeZone,
  getPickupTimeOptions,
  getMinPickupDate,
  DS_OPENING_HOUR,
  DS_CLOSING_HOUR,
  cutOffTimeInterval,
} from "library/utils/deliveryService";
import {
  handlePickupTimeWindow,
  getPickupTime,
  handlePickerTimings,
  roundUpTime,
} from "../../helper";

const DeliveryDetails = ({
  Localise,
  messages,
  isSmallScreen,
  values = {},
  setFieldValue,
  copy,
}) => {
  const path = "deliveryDetails";
  let shopTimeZone,
    floristTimeZone = "";

  const getShopHoursForSelectedDate = (date) => {
    let dayOfWeek = moment(date).format("dddd").toUpperCase();
    let shopHours = shopOpenHours.filter(
      (dayHours) => dayHours.day.toUpperCase() === dayOfWeek
    );
    shopHours = {
      openTime: DS_OPENING_HOUR,
      closeTime: DS_CLOSING_HOUR,
    };
    return shopHours;
    //Commenting this as for now we use shop open and close hours as 7 am to 8pm.
    // return shopHours?.length ?? 0 > 0
    //   ? shopHours[0]
    //   : {
    //       openTime: "00:00:00",
    //       closeTime: "24:00:00",
    //     };
  };

  const {
    shopCode,
    timeWindow: pickUpTimeWindow,
    pickupUpdatedTime,
    deliveryProvider,
    pickupTimeOptions,
    deliveryDetails: { pickUpDateTime, deliveryDate: pickUpDate } = {},
  } = values;

  const shopOpenHours = UserProfileStorage.getShopHours(shopCode);

  const assignmentOptions = [
    { label: "Delivery Service", value: "deliveryService" },
  ];

  let updatedDate = "";
  if (shopCode) {
    shopTimeZone = UserProfileStorage.getShopTimeZone(shopCode);
    floristTimeZone = moment.tz(shopTimeZone).format("z");
    if (shopTimeZone)
      updatedDate = moment(
        convertToTimeZone(moment.utc().format(), shopTimeZone)
      ).add(cutOffTimeInterval[deliveryProvider?.toLowerCase()], "minutes");
  }

  let shopHours = getShopHoursForSelectedDate(
    pickUpDate ? pickUpDate : updatedDate
  );

  useEffect(() => {
    if (
      shopCode &&
      shopTimeZone &&
      Platform.OS !== "web" &&
      deliveryProvider &&
      deliveryProvider?.toLowerCase() !== "doordash"
    ) {
      let dsMinDate = moment(
        convertToTimeZone(moment.utc().format(), shopTimeZone)
      ).add(cutOffTimeInterval[deliveryProvider?.toLowerCase()], "minutes");
      let currentTime = moment(
        moment(dsMinDate).format("HH:mm:ss"),
        "HH:mm:ss"
      );
      let isSameDay = moment(pickUpDate).isSame(dsMinDate, "day");

      if (pickUpTimeWindow.length) {
        handlePickupTimeWindow(
          pickUpTimeWindow,
          pickUpDate,
          dsMinDate,
          currentTime,
          setFieldValue,
          pickUpDateTime,
          shopTimeZone
        );
      } else if (pickUpTimeWindow.length === 0) {
        handlePickerTimings(
          pickUpDate,
          setFieldValue,
          pickUpDateTime,
          shopTimeZone,
          shopHours,
          currentTime,
          dsMinDate,
          isSameDay
        );
      }
    }
  }, [
    shopCode,
    pickUpDate,
    pickUpDateTime,
    JSON.stringify(pickUpTimeWindow),
    deliveryProvider,
  ]);

  useEffect(() => {
    if (
      shopCode &&
      Platform.OS === "web" &&
      deliveryProvider &&
      deliveryProvider?.toLowerCase() !== "doordash"
    ) {
      let dsMinDate = moment(
        convertToTimeZone(moment.utc().format(), shopTimeZone)
      ).add(cutOffTimeInterval[deliveryProvider?.toLowerCase()], "minutes");

      let currentTime = moment(
        moment(dsMinDate).format("HH:mm:ss"),
        "HH:mm:ss"
      );
      let isSameDay = moment(pickUpDate).isSame(dsMinDate, "day");

      if (pickUpTimeWindow.length) {
        let injectTiming = pickUpTimeWindow?.map((timeWindow) => {
          return moment(pickUpDate)
            .set({ hour: timeWindow, minute: 0, second: 0 })
            .toDate();
        });
        let updatedPickUpDateTime = moment(injectTiming[0]).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
        setFieldValue("injectTimings", injectTiming);
        let timeMorning = moment(pickUpTimeWindow[0] + ":00:00", "HH:mm:ss");
        let timeAfternoon = moment(pickUpTimeWindow[1] + ":00:00", "HH:mm:ss");
        if (isSameDay && currentTime.isAfter(timeMorning)) {
          if (currentTime.isSameOrBefore(timeAfternoon)) {
            let newTiming = [
              moment(updatedDate)
                .set({ hour: pickUpTimeWindow[1], minute: 0, second: 0 })
                .toDate(),
            ];
            updatedPickUpDateTime = moment(newTiming[0]).format(
              "YYYY-MM-DDTHH:mm:ss"
            );
            setFieldValue("injectTimings", newTiming);
          }
        }
        setFieldValue("deliveryDetails.pickUpDateTime", updatedPickUpDateTime);
        setFieldValue("pickupUpdatedTime", {});
      } else if (pickUpTimeWindow.length === 0) {
        let pickingTime = getPickupTime(
          pickUpDate,
          shopTimeZone,
          shopHours,
          updatedDate,
          dsMinDate,
          isSameDay
        );
        setFieldValue(
          "deliveryDetails.pickUpDateTime",
          moment(roundUpTime(moment(pickingTime.open).toDate())).format(
            "YYYY-MM-DDTHH:mm:ss"
          )
        );
        setFieldValue("pickupUpdatedTime", pickingTime);
      }
    } else if (
      shopCode &&
      deliveryProvider &&
      deliveryProvider?.toLowerCase() === "doordash" &&
      pickUpTimeWindow
    ) {
      let presentFloristTime = moment(
        convertToTimeZone(moment.utc().format(), shopTimeZone)
      ).add(cutOffTimeInterval[deliveryProvider?.toLowerCase()], "minutes");
      let isSameDay = moment(pickUpDate).isSame(presentFloristTime, "day");

      let pickupTimeOptions = getPickupTimeOptions(
        pickUpTimeWindow,
        isSameDay || !pickUpDate,
        updatedDate,
        presentFloristTime
      );

      setFieldValue("pickupTimeOptions", pickupTimeOptions);
      //default pickupTime for DoordashWeb
      setFieldValue(
        "deliveryDetails.pickUpDateTime",
        pickupTimeOptions.find((option) => option.enabled)?.value
      );
      setFieldValue(
        "fulfillmentType",
        pickupTimeOptions.find((option) => option.enabled)?.secondaryLabel
      );
    }
  }, [
    shopCode,
    pickUpDate,
    JSON.stringify(pickUpTimeWindow),
    deliveryProvider,
  ]);

  if (shopCode && shopTimeZone)
    updatedDate = getMinPickupDate(
      shopCode,
      shopTimeZone,
      updatedDate,
      pickUpTimeWindow
    );

  useEffect(() => {
    if ((pickUpDate || updatedDate) && !copy)
      setFieldValue(
        "deliveryDetails.deliveryDate",
        moment(updatedDate || pickUpDate).format("YYYY-MM-DD")
      );
  }, [moment(updatedDate).format("YYYY-MM-DD")]);

  return (
    <View
      style={[
        tw(
          `${
            deliveryProvider ? `justify-between` : `justify-start`
          } flex-row flex-wrap`
        ),
        { zIndex: 5 },
      ]}
    >
      <FormFieldPicker
        name={"assignment"}
        path={path}
        label={Localise(messages, "Assignment")}
        placeholder={{
          label: "Select Assignment",
          value: "",
        }}
        data={assignmentOptions}
        isRequired
        disabled
        innerContainerStyle={{
          backgroundColor: backgroundColors.greyColor,
        }}
        containerStyle={{
          width: isSmallScreen ? "100%" : "40%",
          marginLeft: -5,
          padding: 0,
          margin: 0,
          zIndex: 10,
        }}
      />
      <FormFieldDatePicker
        name={"deliveryDate"}
        isRequired
        label={Localise(messages, "Delivery Date")}
        placeholder={"MM/DD/YYYY"}
        dateValueFormat={"YYYY-MM-DD"}
        alignIconRight={true}
        iconName={"calendar"}
        iconType={"font-awesome"}
        containerStyle={{
          width: isSmallScreen ? "100%" : "30%",
          marginLeft: deliveryProvider || Platform.OS !== "web" ? -5 : 0,
          padding: 0,
          paddingTop: isSmallScreen ? 10 : 0,
          margin: 0,
          zIndex: 10,
        }}
        minDate={moment(updatedDate).toDate()}
        path={path}
      />
      {deliveryProvider?.toLowerCase() === "doordash" ? (
        <FormFieldPicker
          name={"pickUpDateTime"}
          path={path}
          label={`${Localise(messages, "Pickup Time")}${
            floristTimeZone ? ` (${floristTimeZone})` : ""
          }`}
          placeholder={{
            label: "Pickup Time",
            value: null,
          }}
          data={(pickupTimeOptions?.length
            ? pickupTimeOptions
            : getPickupTimeOptions(pickUpTimeWindow)
          ).map((item) => {
            const {
              value: itemValue,
              label,
              enabled = true,
              secondaryLabel = "",
            } = item;

            return {
              value: itemValue,
              label:
                pickUpDateTime === itemValue && secondaryLabel
                  ? `${Localise(messages, label)} ${Localise(
                      messages,
                      secondaryLabel
                    )}`
                  : Localise(messages, label),
              enabled: enabled,
            };
          })}
          isRequired
          innerContainerStyle={{
            backgroundColor: backgroundColors.greyColor,
          }}
          containerStyle={{
            width: isSmallScreen ? "100%" : "30%",
            marginLeft: -5,
            padding: 0,
            marginBottom: isSmallScreen ? 10 : 0,
            margin: 0,
            zIndex: 9,
          }}
        />
      ) : deliveryProvider && shopCode ? (
        <FormFieldDatePicker
          dateFormat="h:mm a"
          name="pickUpDateTime"
          placeholder="Select Pickup Time"
          label={`${Localise(messages, "Pickup Time")}${
            floristTimeZone ? ` (${floristTimeZone})` : ""
          }`}
          isRequired
          containerStyle={{
            width: isSmallScreen ? "100%" : "30%",
            marginLeft: -5,
            padding: 0,
            margin: 0,
            zIndex: 9,
          }}
          {...(!isSmallScreen && {
            iconName: "clock",
            alignIconRight: true,
          })}
          showTimeSelect={true}
          showTimeSelectOnly={true}
          timeIntervals={30}
          timeCaption="PickUp Time"
          minTime={pickupUpdatedTime.open}
          maxTime={pickupUpdatedTime.close}
          {...(Platform.OS !== "web"
            ? {
                dateValueFormat: "YYYY-MM-DDTHH:mm:ss",
              }
            : values.injectTimings?.length
            ? {
                includeTimes: values.injectTimings,
              }
            : {})}
          editable={Platform.OS !== "web" ? true : false}
          path={path}
        />
      ) : (
        <></>
      )}
    </View>
  );
};

export default DeliveryDetails;
