// Capture Digital Signature in React Native App for Android and iOS
// https://aboutreact.com/react-native-capture-signature/

// import React in our code
import React, { createRef } from "react";
import { useDispatch } from "react-redux";
import { View, StyleSheet, Text, Platform, Dimensions } from "react-native";
import I18NContext from "library/contexts/i18N";
import {
  setIsShowSignatureModal,
  setSignatureImage,
} from "library/sagas/ongoing/global-data/slice";
import { fonts, theme } from "styles/theme";
import { Button } from "react-native-elements";

let SignatureCapture;
if (Platform.OS !== "web") {
  SignatureCapture = require("react-native-signature-capture");
}

const windowHeight = Dimensions.get("window").height;

const SignCapture = () => {
  const dispatch = useDispatch();
  const { messages, Localise } = React.useContext(I18NContext);

  if (Platform.OS === "web") return null;
  const sign = createRef();

  const saveSign = () => {
    sign.current.saveImage();
  };

  const resetSign = () => {
    sign.current.resetImage();
  };

  const _onSaveEvent = (result) => {
    //result.encoded - for the base64 encoded png
    //result.pathName - for the file path name
    dispatch(setSignatureImage(result.encoded));
    dispatch(setIsShowSignatureModal(false));
  };

  return (
    <>
      <Text style={styles.titleStyle} numberOfLines={2}>
        Please sign your name to confirm you received your delivery.
      </Text>
      <View style={styles.container}>
        <SignatureCapture
          style={styles.signature}
          ref={sign}
          onSaveEvent={_onSaveEvent}
          showTitleLabel={false}
          showNativeButtons={false}
          strokeColor="#000000"
          minStrokeWidth={4}
          maxStrokeWidth={4}
          showBorder={false}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingHorizontal: 10,
          paddingVertical: 10,
        }}
      >
        <Text
          style={{
            ...fonts.default,
            textDecorationLine: "underline",
          }}
          onPress={resetSign}
        >
          {Localise(messages, "Clear")}
        </Text>
        <Button
          title={Localise(messages, "Submit Signature")}
          titleStyle={{ ...theme.Button.secondaryTitleStyle, color: "white" }}
          containerStyle={{ justifyContent: "center", marginRight: -1 }}
          onPress={saveSign}
        />
      </View>
    </>
  );
};
export default SignCapture;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    borderColor: "#000",
    borderWidth: 0.5,
    marginHorizontal: 10,
  },
  titleStyle: {
    fontSize: 12,
    textAlign: "center",
    margin: 10,
    paddingHorizontal: 30,
  },
  signature: {
    height: windowHeight / 2.5,
  },
});
