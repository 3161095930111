import React, { useEffect, useContext } from "react";
import { useNavigation } from "@react-navigation/native";
import {
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
} from "react-native";
import ScreenHeader from "components/containers/header/screen";

import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import UserProfileStorage from "library/storage/userProfile";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { CustomModal } from "components/elements";
import { fonts, colors } from "styles/theme";
import tw from "tailwind-rn";
import find from "lodash/find";
import { useDispatch, useSelector } from "react-redux";
import { setIsBulkDSRequestUploadInProgress } from "library/sagas/ongoing/global-data/slice";
import { selectIsBulkDSRequestUploadInProgress } from "library/sagas/ongoing/global-data/selector";

//components
import { Divider } from "components/elements";

const TabNavigationScreen = ({
  title = "",
  screenList = [],
  headerSiblings,
  screenKey,
  setSelectScreenKey,
  route,
  isRouteSettingsEnabled = false,
  ...props
}) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const { isDesktop, isMobile, isTablet } = useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const { messages, Localise } = useContext(I18NContext);
  const [loading, setLoading] = React.useState(false);
  const [routeDeatils, setRouteDetails] = React.useState(route);
  const [isModalVisible, setIsModalVisible] = useStateIfMounted(false);
  const [showCreateDSRequest, setShowCreateDSRequest] =
    useStateIfMounted(false);

  const currentTabKey = UserProfileStorage.getCurrentTab();
  const isBulkDSInProgress = useSelector(selectIsBulkDSRequestUploadInProgress);
  const [screenKeyValForBulkImportDS, setscreenKeyValForBulkImportDS] =
    useStateIfMounted();
  const selectedKey =
    currentTabKey?.length &&
    screenList?.map(({ key } = {}) => key)?.includes(currentTabKey)
      ? currentTabKey
      : screenKey || screenList[0]?.key;

  const ScreenComponent = find(
    screenList,
    (screen) => screen.key === selectedKey
  )?.component;

  const setPageOptions = (screen) => {
    Platform.OS === "web" &&
      navigation.setOptions({
        title: screen?.pageTitle || screen?.label,
      });
  };

  useEffect(() => {
    const key = selectedKey?.length
      ? selectedKey
      : screenList?.length
      ? screenList[0].key
      : "";
    const screen = find(screenList, (screen) => screen.key === selectedKey);
    dispatch(setSelectScreenKey(key));

    setPageOptions(screen);
  }, [screenKey]);

  const modalContent = {
    content: (
      <Text
        style={{
          ...tw("p-4"),
          fontSize: 15,
          color: colors.highlighter,
          textAlign: "center",
        }}
      >
        {Localise(messages, "File upload in progress")}
      </Text>
    ),
    buttons: [
      {
        type: "primary",
        title: Localise(messages, "OK"),
      },
    ],
  };

  const handleContinue = () => {
    screenKeyValForBulkImportDS &&
      dispatch(setSelectScreenKey(screenKeyValForBulkImportDS));
    setIsModalVisible(false);
    dispatch(setIsBulkDSRequestUploadInProgress(false));
    screenKeyValForBulkImportDS &&
      UserProfileStorage.setCurrentTab(screenKeyValForBulkImportDS);
  };

  const modal = (
    <CustomModal
      modalVisible={isModalVisible}
      modalContent={modalContent}
      primaryhandler={handleContinue}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: "white" },
      ]}
      modalStyle={
        Platform.OS !== "web" && {
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          backgroundColor: "#00000070",
          color: "#FFFFFF",
        }
      }
    ></CustomModal>
  );
  return (
    <>
      <ScreenHeader
        title={title}
        createRoute={
          screenKey === "routes"
            ? "create-route"
            : screenKey === "DS" && showCreateDSRequest
            ? "create-delivery-service-request"
            : ""
        }
        settingsRoute={
          isRouteSettingsEnabled && {
            page: "delivery-settings",
          }
        }
        setShowQRScan={false}
        showKebabMenu={false}
        listingType={"deliveryTasks"}
      />
      {isSmallScreen && (
        <View
          style={[
            tw("flex-row justify-between"),
            { paddingLeft: isDesktop ? 20 : 10 },
          ]}
        >
          <View style={{ flexDirection: "row" }}>
            <Text
              style={[
                fonts.heading1,
                tw("pb-3 pt-5"),
                isTablet && { fontSize: 16, fontWeight: "400" },
              ]}
            >
              {Localise(messages, title)}
            </Text>
            {loading && (
              <ActivityIndicator
                style={{
                  marginLeft: 10,
                  marginTop: 10,
                }}
                color={colors.activityIndicator}
                testID={"Delivery_Dashboard_loader"}
              />
            )}
          </View>
        </View>
      )}
      <View
        style={[
          tw("flex-row flex-wrap justify-start"),
          { paddingLeft: isDesktop ? 20 : 10 },
          { marginTop: isDesktop ? 6 : 0 },
        ]}
      >
        {screenList.map((screen, i) => {
          const activeTab = screen.key === selectedKey;
          return (
            <React.Fragment key={i}>
              <TouchableOpacity
                testID={`${screen.label}-tab`}
                accessibilityLabel={`${screen.label}-tab`}
                style={{
                  paddingVertical: 8,
                  margin: 5,
                  marginLeft: i !== 0 ? 50 : 0,
                  marginRight: 0,
                  borderBottomColor: colors.secondaryButtonText,
                  ...(activeTab
                    ? {
                        borderBottomWidth: 2,
                      }
                    : {}),
                }}
                onPress={() => {
                  if (isBulkDSInProgress) {
                    setIsModalVisible(true);
                    setscreenKeyValForBulkImportDS(screen.key);
                  } else {
                    setRouteDetails({});
                    dispatch(setSelectScreenKey(screen.key));
                    UserProfileStorage.setCurrentTab(screen.key);
                  }
                  setPageOptions(screen);
                }}
              >
                <Text
                  style={{
                    ...fonts.default,
                    fontSize: 16,
                    ...(activeTab
                      ? {
                          color: colors.secondaryButtonText,
                        }
                      : {}),
                  }}
                >
                  {Localise(messages, screen.label)}
                </Text>
              </TouchableOpacity>
            </React.Fragment>
          );
        })}
        {modal}
      </View>
      {!isMobile && <Divider />}
      <ScreenComponent
        loading={loading}
        setLoading={setLoading}
        route={routeDeatils}
        setShowCreateDSRequest={setShowCreateDSRequest}
        {...props}
      />
    </>
  );
};

export default TabNavigationScreen;
