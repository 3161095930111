import React, { useContext } from "react";
import { View, Text, useWindowDimensions } from "react-native";

import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

import { fonts, colors } from "styles/theme";
import tw from "tailwind-rn";

const ScreenHeader = ({ title, style, siblings }) => {
  const { isDesktop, isTablet, isMobile } = useContext(DeviceContext);
  const { width: dWidth } = useWindowDimensions();

  const { messages, Localise } = React.useContext(I18NContext);

  if (!title) return null;

  return (
    <View>
      <View
        style={[
          tw("flex flex-row w-full justify-between items-center pb-3"),
          {
            paddingTop: isDesktop ? 30 : 16,
          },
        ]}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: isDesktop ? "flex-start" : "space-between",
            alignItems: "flex-start",
            flexWrap: "nowrap",
            width: isMobile ? dWidth - 20 : "100%",
            ...style,
          }}
        >
          <Text
            style={{
              paddingRight: 12,
              ...fonts.heading1,
              ...(isTablet && { fontSize: 16, fontWeight: "400" }),
            }}
          >
            {Localise(messages, title)}
          </Text>
          {siblings && siblings()}
        </View>
      </View>
      {isMobile ? (
        <View
          style={{
            marginBottom: 10,
          }}
        />
      ) : (
        <View
          style={{
            borderBottomColor: colors.light,
            borderBottomWidth: 1,
            marginBottom: 20,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.4,
            shadowRadius: 7,
          }}
        />
      )}
    </View>
  );
};

export default ScreenHeader;
