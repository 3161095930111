import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, ActivityIndicator, TouchableOpacity } from "react-native";
import { Button, Text, Image } from "react-native-elements";
import tw from "tailwind-rn";
import isObject from "lodash/isObject";
import omit from "lodash/omit";
import I18NContext from "library/contexts/i18N";
import { FormField } from "components/elements/forms";
import { shapes, fonts, theme, colors, backgroundColors } from "styles/theme";
import IMAGES from "static/assets/images";
import { Form } from "components/elements/forms";
import {
  sendEventProposalValidationSchema,
  proposalInitialValues,
  getLocationsWithConvertedTime,
} from "../../config";
import { request } from "library/utils/request";
import { formatQuillEditorStyles } from "library/utils/formatter";
import { ToasterHandler } from "components/elements";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { handleSave } from "../../helper";
import { setSearchText } from "library/sagas/views/home/drawer/events/slice";
import {
  fetchDefaultNotifications,
  setShopCode,
} from "library/sagas/views/home/drawer/shop-settings/common/slice";
import { selectNotificationSettings } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { getIsEventModified } from "../../helper";

const SendProposal = (props) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const notificationSettings = useSelector(selectNotificationSettings);

  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const [emailBodyTemplate, setEmailBodyTemplate] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [loadingEmail, setLoadingEmail] = useState(false);

  const {
    eventInfo,
    eventInfo: {
      eventId,
      memberCode,
      proposal: { emailSent } = {},
      customerInfo: { email = "" } = {},
    },
    initialEventInfo,
    onClose,
  } = props;

  const isEventModified = getIsEventModified(initialEventInfo, eventInfo);

  useEffect(() => {
    if (!memberCode) return;
    const shopCode = memberCode;
    dispatch(setShopCode(shopCode));
    request("get-shop-settings", {
      ids: ["event_proposal_notification_settings"],
      memberCode: shopCode,
    })
      .then((res) => {
        if (res && res.preferences && res.preferences.length) {
          dispatch(fetchDefaultNotifications(shopCode));
        }
      })
      .catch((err) => {
        ToasterHandler("uh oh", Localise(messages, err));
        setLoadingEmail(false);
      });
  }, [memberCode]);

  useEffect(() => {
    if (!notificationSettings.length) return;
    const response =
      notificationSettings?.find(
        (obj) => obj?.id === "event_proposal_notification_settings"
      )?.values || "";
    setEmailBodyTemplate(response?.emailBody);
    setEmailSubject(response?.emailSubject);
    response?.emailBody?.length > 0
      ? setLoadingEmail(false)
      : setLoadingEmail(true);
  }, [notificationSettings?.length]);

  return useMemo(() => {
    const sendEmailAndUpdateStatus = (
      values,
      formikBag,
      eventInfo,
      memberCode
    ) => {
      const { recipient, cc } = values;
      const url = `${window.location.origin}/event-approval-confirmation/${eventInfo?.eventId}`;
      request("send-event-proposal", {
        reqObj: {
          memberCode: memberCode,
          recipientEmail: recipient,
          ccAddress: cc ? cc.split(",") : [],
          messageType: "EVENTPROPOSAL",
          eventProposalLink: url,
          customerId: eventInfo?.customerInfo?.customerId,

          // event: "MHQ_INVOICE",
          // siteId: "mhq",
          // mode: ["EMAIL"],
          // customer: {
          //   email: recipient,
          //   ccAddress: cc ? cc.split(",") : [],
          //   bccAddress: bcc ? bcc.split(",") : [],
          // },

          // data: {
          //   subject,
          //   body: emailBodyTemplate
          //     .replace("FLORIST_NAME", floristName)
          //     .replace("URL", url),
          // },
        },
      })
        .then((response) => {
          const {
            _id,
            createdOn,
            updatedOn,
            __v,
            startAndEndDate,
            startTime,
            customerInfo,
            actualCustomerInfo,
            proposalInfo,
            paymentDetails,
            showPayment,
            isPaymentRequired,
            amountPaid,
            refunded,
            unpaidBalance,
            completedOrders,
            orderIds,
            orderItems,
            orderAmounts,
            actionItem,
            ...other
          } = eventInfo;
          const updatedLocations = getLocationsWithConvertedTime(
            other.locations,
            true
          );
          request("update-event", {
            reqObj: {
              ...omit(other, ["showError"]),
              locations: updatedLocations,
              proposal: {
                status: "DRAFT",
                emailSent: "true",
              },
              status: "PENDING",
            },
          })
            .then((response) => {
              ToasterHandler(
                "nice",
                Localise(messages, `Your proposal sent successfully`)
              );
              formikBag.resetForm({
                ...proposalInitialValues,
              });
              dispatch(setSearchText(other.name));
              navigation.dispatch(
                CommonActions.navigate({
                  name: "events",
                })
              );
            })
            .catch((error) => {
              console.log("error: " + error);
              ToasterHandler(
                "uh oh",
                Localise(messages, "Failed to update status.")
              );
            });
        })
        .catch((error) => {
          console.log("error: " + error);
          ToasterHandler(
            "uh oh",
            Localise(messages, "Failed to send proposal.")
          );
        })
        .finally(() => {
          setLoading(false);
        });
    };
    const webStyles = {
      emailBodyContent: {
        fontFamily: fonts.fontFamily.default,
        lineHeight: 1,
        fontSize: 12,
        borderColor: colors.grayScaleLight,
        padding: 10,
        backgroundColor: backgroundColors.greyColor,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 2,
      },
    };

    return (
      <Form
        initialValues={{
          ...proposalInitialValues,
          recipient: email,
          subject: emailSubject,
        }}
        onSubmit={(values, formikBag) => {
          setLoading(true);
          !eventId
            ? handleSave(eventInfo)
                .then((resp) => {
                  sendEmailAndUpdateStatus(values, formikBag, resp, memberCode);
                })
                .catch((error) => {
                  formikBag.setSubmitting(false);
                  setLoading(false);
                  const message = isObject(error) ? error.error : error;
                  console.log("error: " + message);
                  ToasterHandler(
                    "uh oh",
                    Localise(
                      messages,
                      `Failed to ${
                        values.eventId ? `update` : `create`
                      } event. ${message}`
                    )
                  );
                })
            : sendEmailAndUpdateStatus(
                values,
                formikBag,
                eventInfo,
                memberCode
              );
        }}
        validationSchema={sendEventProposalValidationSchema(messages, Localise)}
        validateOnChange={true}
        validateOnBlur={true}
        render={(props) => {
          const { handleSubmit } = props;

          return (
            <View style={[tw("flex justify-center w-full"), { marginTop: 15 }]}>
              <View style={[shapes.sectionBorder]}>
                <View
                  style={tw(
                    "flex flex-row justify-between items-center mb-4 ml-1 mr-1"
                  )}
                >
                  <Text style={[fonts.sectionHeading]}>
                    {Localise(messages, "Send Proposal")}
                  </Text>
                  <TouchableOpacity
                    onPress={() => onClose()}
                    testID="close"
                    accessibilityLabel="close"
                  >
                    <Image
                      style={{ width: 20, height: 20 }}
                      resizeMode="cover"
                      source={IMAGES["close"]}
                    />
                  </TouchableOpacity>
                </View>
                <FormField
                  name="recipient"
                  containerStyle={{
                    width: "100%",
                  }}
                  placeholder={Localise(messages, "Enter Recipient Email")}
                  label={Localise(messages, "Recipient")}
                  labelStyle={{ fontWeight: "normal" }}
                  testID="recipient"
                  accessibilityLabel="recipient"
                />

                <FormField
                  name="cc"
                  containerStyle={{
                    width: "100%",
                  }}
                  placeholder={Localise(
                    messages,
                    "Add one or more emails including Florist Account Email separated by ,"
                  )}
                  label={Localise(messages, "cc")}
                  labelStyle={{ fontWeight: "normal" }}
                  testID="cc"
                  accessibilityLabel="cc"
                />
                <FormField
                  name="subject"
                  containerStyle={{
                    width: "100%",
                  }}
                  placeholder={Localise(messages, "Your Proposal")}
                  label={Localise(messages, "Subject")}
                  labelStyle={{ fontWeight: "normal" }}
                  testID="subject"
                  accessibilityLabel="subject"
                  editable={false}
                  grayedOutOnDisable={true}
                />
                <View style={{ padding: 5 }}>
                  {loadingEmail ? (
                    <ActivityIndicator
                      style={{ marginLeft: 5, marginTop: -5 }}
                      color={colors.activityIndicator}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: formatQuillEditorStyles(emailBodyTemplate),
                      }}
                      style={webStyles.emailBodyContent}
                    />
                  )}
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 20,
                  marginLeft: "auto",
                  alignItems: "center",
                }}
              >
                <Button
                  titleStyle={theme.Button.secondaryTitleStyle}
                  buttonStyle={{
                    ...theme.Button.secondaryButtonStyle,
                    paddingVertical: 5,
                    paddingHorizontal: 10,
                    marginRight: 0,
                  }}
                  containerStyle={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    marginLeft: "auto",
                    marginRight: 0,
                  }}
                  onPress={handleSubmit}
                  title={Localise(
                    messages,
                    emailSent === "true" && !isEventModified
                      ? "Resend Email"
                      : "Send Email"
                  )}
                  testID={"sendEmail"}
                  accessibilityLabel={"sendEmail"}
                  disabled={loading}
                />
                {loading && (
                  <ActivityIndicator
                    style={{ marginLeft: 5, marginTop: -5 }}
                    color={colors.activityIndicator}
                  />
                )}
              </View>
            </View>
          );
        }}
      />
    );
  }, [
    Localise,
    messages,
    loading,
    eventId,
    eventInfo,
    memberCode,
    navigation,
    emailSent,
    email,
    emailBodyTemplate,
    emailSubject,
    loadingEmail,
    notificationSettings,
  ]);
};

export default SendProposal;
