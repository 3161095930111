import React from "react";
import { View, Text, StyleSheet } from "react-native";

const sliderRadius = 3;
const width = 50;
export default class DefaultLabel extends React.Component {
  render() {
    const {
      oneMarkerValue,
      twoMarkerValue,
      oneMarkerLeftPosition,
      twoMarkerLeftPosition,
      oneMarkerPressed,
      twoMarkerPressed,
    } = this.props;

    return (
      <View style={{ position: "relative" }}>
        {Number.isFinite(oneMarkerLeftPosition) &&
          Number.isFinite(oneMarkerValue) && (
            <View
              style={{
                ...styles.sliderLabel,
                left: oneMarkerLeftPosition - width / 2 + sliderRadius,
                ...(oneMarkerPressed && styles.markerPressed),
              }}
            >
              <Text style={styles.sliderLabelText}>{oneMarkerValue}</Text>
            </View>
          )}

        {Number.isFinite(twoMarkerLeftPosition) &&
          Number.isFinite(twoMarkerValue) && (
            <View
              style={{
                ...styles.sliderLabel,
                left: twoMarkerLeftPosition - width / 2 + sliderRadius,
                ...(twoMarkerPressed && styles.markerPressed),
              }}
            >
              <Text style={styles.sliderLabelText}>{twoMarkerValue}</Text>
            </View>
          )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  sliderLabel: {
    position: "absolute",
    bottom: 0,
    minWidth: width,
    padding: 8,
    backgroundColor: "#f1f1f1",
  },
  sliderLabelText: {
    alignItems: "center",
    textAlign: "center",
    fontStyle: "normal",
    fontSize: 11,
  },
  markerPressed: {
    borderWidth: 2,
    borderColor: "#999",
  },
});
