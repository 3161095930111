import { put, call, select, fork } from "redux-saga/effects";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { request } from "library/utils/request";
import { setApiResponse, setApiError } from "./slice";
import { fetchZipCodesForExceptions } from "../delivery-pickup/slice";
import { selectShopCode } from "../common/selector";
import { selectExceptionZips } from "../delivery-pickup/selector";

export function* handleFetchCutoffExceptions() {
  const serviceRequest = (params) => request("get-delivery-exceptions", params);
  const shopCode = yield select(selectShopCode);

  try {
    const response = yield call(serviceRequest, { shopCode });
    const content = processDeliveryExceptionsResponse(response);

    yield fork(triggerGetZipcodesCall, content);

    yield put(
      setApiResponse({
        path: "deliveryCutoffTimes.cutoffExceptions",
        content,
      })
    );
  } catch (error) {
    yield put(
      setApiError({
        path: "deliveryCutoffTimes.cutoffExceptions",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleSaveCutoffExceptions(action = {}) {
  const { resolve, reject, params } = get(action, "payload", {});
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (payload) =>
    request("save-delivery-exceptions", payload);
  const requestPayload = preparePatchPayload(params);

  try {
    yield call(serviceRequest, {
      shopCode,
      requestPayload,
    });
    yield call(handleFetchCutoffExceptions);
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        path: "deliveryCutoffTimes.cutoffExceptions",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleDeleteCutoffExceptions(action = {}) {
  const {
    resolve,
    reject,
    params: { exceptionId, exceptionType },
  } = get(action, "payload", {});
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (payload) =>
    request("delete-delivery-exceptions", payload);

  try {
    yield call(serviceRequest, {
      shopCode,
      exceptionType,
      exceptionId,
    });
    yield call(handleFetchCutoffExceptions);
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        path: "deliveryCutoffTimes.cutoffExceptions",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* triggerGetZipcodesCall(content) {
  const existingZips = yield select(selectExceptionZips);
  const existingCities = Object.keys(existingZips);

  for (let i = 0; i < content.length; i++) {
    const city = get(content, `${i}.cityZipInfo.city`, "");
    const zipCodeDataExists = existingCities.includes(city);
    if (city && !zipCodeDataExists) {
      yield put(fetchZipCodesForExceptions(city));
    }
  }
}

const removeExpiredExceptions = (allExceptions) =>
  allExceptions.filter(
    ({ endDate }) =>
      !endDate ||
      !moment(moment().format("YYYY-MM-DD")).isAfter(moment(endDate))
  );

const processExceptionItem = (exception) => {
  const { startDate, endDate, cityZipInfo, cutOffTime } = exception;

  const getTimeFormat = (time, defaultTime = "13:00") =>
    moment(time || defaultTime, "HH:mm").format("YYYY-MM-DDTHH:mm:ss");

  return {
    ...exception,
    startDate: startDate
      ? moment(new Date(startDate)).format("YYYY-MM-DD")
      : "",
    endDate: endDate ? moment(new Date(endDate)).format("YYYY-MM-DD") : "",
    cityZipInfo: cityZipInfo ? cityZipInfo : {},
    cutOffTime: getTimeFormat(cutOffTime),
  };
};

const processDeliveryExceptionsResponse = (response = {}) => {
  const cutOffTimeInfo =
    get(response, "deliveryExceptions.cutOffTimeInfo", []) || [];
  const allCutoffExceptions = cutOffTimeInfo.map((exception) =>
    processExceptionItem(exception)
  );
  const updatedExpetions = removeExpiredExceptions(allCutoffExceptions);
  const sorted = updatedExpetions.sort(
    (a, b) => new Date(b.updatedOn) - new Date(a.updatedOn)
  );
  return sorted;
};

const preparePatchPayload = (params) => {
  const { cutOffTime, cityZipInfo, startDate, endDate } = params;

  const getTimeFormat = (time) => {
    // eslint-disable-next-line no-unused-vars
    const [date, hours, minutes, seconds] = time?.split(/[T:]/);
    return `${hours}:${minutes}`;
  };

  return {
    ...params,
    cutOffTime: getTimeFormat(cutOffTime),
    startDate: !startDate ? null : moment(startDate).utc(),
    endDate: !endDate ? null : moment(endDate).utc(),
    cityZipInfo: isEmpty(cityZipInfo) ? null : cityZipInfo,
  };
};
