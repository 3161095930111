import React, { useRef } from "react";
import { View, TouchableOpacity } from "react-native";
import { Text, Image } from "react-native-elements";
import { useSelector, useDispatch } from "react-redux";
import I18NContext from "library/contexts/i18N";
import styles from "./styles";
import tw from "tailwind-rn";
import moment from "moment";
import omit from "lodash/omit";
import { PopupMenu, ToasterHandler } from "components/elements";
import { request } from "library/utils/request";
import {
  setPageInitialize,
  setPageData,
  fetchEventOrder,
} from "library/sagas/views/home/drawer/events/slice";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import { IMAGES, DIMENSIONS } from "static/assets/images";
import { useNavigation, CommonActions } from "@react-navigation/native";
import {
  StatusIcons,
  EventStatusColorCodes,
  StatusLabel,
  EligibleStatusList,
  defaultActionsList,
  StatusSuggestions,
} from "../ui-config";
import ProposalInfo from "../../../create-event/proposal-and-payment/proposal";

import {
  downloadEventLabels,
  printActions,
} from "../../../create-event/config";
import { useReactToPrint } from "react-to-print";

const Item = ({
  id,
  index,
  data,
  columns,
  style,
  selectors: { dataSelector, selectActiveItem },
}) => {
  const ref = useRef();
  const navigation = useNavigation();
  const itemData = useSelector(dataSelector);
  const selectedItemId = useSelector(selectActiveItem);
  const dispatch = useDispatch();
  const { [id]: itemId, storeOrigin, name } = data;
  //const totalLimit = itemData?.length ?? 0;
  const activeItem = selectedItemId === itemId;
  const { messages, Localise } = React.useContext(I18NContext);

  const handleProposalPrint = useReactToPrint({
    content: () => ref.current,
  });

  const handleLabelsPrint = () => {
    downloadEventLabels(data);
  };

  const handleModify = (action) => {
    const eventDetails = itemData?.find((x) => x?.eventId === itemId);
    const { memberCode, locations } = eventDetails;
    const { orderIds = [] } = eventDetails?.customer?.details;
    dispatch(
      setPageData({
        eventDetails,
      })
    );
    dispatch(setCurrentPage("create-event"));
    orderIds.forEach((id, index) => {
      dispatch(
        fetchEventOrder({
          orderId: id,
          deliveryMethod: locations[index].deliveryType,
          memberCode,
        })
      );
    });

    navigation.dispatch(
      CommonActions.navigate({
        name: "create-event",
        params: {
          id: itemId || name,
          action: "edit",
          smc: storeOrigin,
          meatballAction: action,
        },
      })
    );
  };

  const handleDelete = () => {
    request("delete-event", { eventId: itemId })
      .then((response) => {
        ToasterHandler(
          "nice",
          Localise(messages, "Your event deleted successfully")
        );
        dispatch(setPageInitialize());
      })
      .catch((error) => {
        console.log("error: " + error);
        ToasterHandler(
          "uh oh",
          Localise(messages, "Failed to delete the event.")
        );
      });
  };

  const handleUpdate = (action) => {
    const {
      startAndEndDate,
      _id,
      __v,
      createdOn,
      updatedOn,
      startTime,
      proposalInfo,
      paymentDetails,
      actionItem,
      ...other
    } = data;
    const proposal =
      action === "accept"
        ? other.proposal.status === "DECLINED"
          ? {
              ...other.proposal,
              fullLegalName: "",
              status: "APPROVED",
              date: moment().format("MM/DD/YYYY"),
            }
          : {
              ...other.proposal,
              status: "APPROVED",
              date: other.proposal.date || moment().format("MM/DD/YYYY"),
            }
        : action === "reject"
        ? other.proposal.status === "APPROVED"
          ? {
              ...other.proposal,
              fullLegalName: "",
              status: "DECLINED",
              date: moment().format("MM/DD/YYYY"),
            }
          : {
              ...other.proposal,
              status: "DECLINED",
              date: other.proposal.date || moment().format("MM/DD/YYYY"),
            }
        : action === "complete"
        ? other.proposal
        : { status: "DRAFT" };
    request("update-event", {
      reqObj: {
        ...omit(other, ["showError"]),
        status: StatusSuggestions[action],
        proposal,
      },
    })
      .then((response) => {
        ToasterHandler(
          "nice",
          Localise(messages, "Your event updated successfully")
        );
        dispatch(setPageInitialize());
      })
      .catch((error) => {
        console.log("error: " + error);
        ToasterHandler(
          "uh oh",
          Localise(messages, "Failed to update the event")
        );
      });
  };

  const getStatus = (status, index) => {
    const imageDimensions = DIMENSIONS[StatusIcons[status?.toLowerCase()]];

    return (
      <View
        style={[
          styles.record,
          {
            borderColor: EventStatusColorCodes[status?.toLowerCase()],
            alignItems: "center",
          },
        ]}
        key={index}
      >
        <View
          style={[
            tw("justify-between items-center"),
            styles.recordItemView,
            styles.status,
            { minWidth: 150 },
          ]}
        >
          <View
            style={[
              tw(`flex flex-row items-center w-full flex-wrap justify-between`),
            ]}
          >
            <View style={{ width: "auto" }}>
              <Text
                numberOfLines={2}
                style={{
                  ...styles.recordItem,
                  textAlign: "left",
                  fontSize: 13,
                  fontWeight: "600",
                  marginRight: 30,
                  textTransform: "capitalize",
                }}
              >
                {Localise(
                  messages,
                  StatusLabel[status?.toLowerCase()] || status?.toLowerCase()
                )}
              </Text>
            </View>
            <View style={styles.statusIcon} testID={`${status}`}>
              <Image
                style={{
                  width: imageDimensions?.imageWidth || 20,
                  height: imageDimensions?.imageHeight || 20,
                }}
                source={IMAGES[StatusIcons[status?.toLowerCase()]]}
              />
            </View>
          </View>
        </View>
      </View>
    );
  };

  const status = data["status"]?.toLowerCase();
  const isInactive = ["completed", "cancelled"].includes(status);
  const isPrintEligible = ["pending", "approved", "rejected"].includes(status);
  const hasOrders = data?.customer?.details.orderIds?.length > 0;

  return (
    <>
      <TouchableOpacity
        activeOpacity={0.6}
        onPress={() => handleModify()}
        style={{
          minWidth: 300,
        }}
        testID={itemId}
        accessibilityLabel={itemId}
      >
        <View
          style={[styles.container, activeItem && styles.activeRowContainer]}
          fsClass="fs-unmask"
        >
          <View
            style={[styles.row, activeItem && styles.activeRow, style]}
            fsClass="fs-unmask"
          >
            {columns.map(({ column, style, type }, index) => {
              return column === "status" ? (
                getStatus(data[column], index)
              ) : (
                <Text
                  numberOfLines={1}
                  style={[styles.column, { ...style }]}
                  key={index}
                  fsClass={
                    column === "phone" ||
                    column === "email" ||
                    column === "address" ||
                    type === "area"
                      ? "fs-exclude"
                      : "fs-unmask"
                  }
                >
                  {type === "date"
                    ? moment(data[column]).format("MM/DD/YYYY")
                    : type === "time"
                    ? moment(data[column]).format("hh:mm A")
                    : data[column]}
                </Text>
              );
            })}

            <PopupMenu
              containerStyle={styles.menuContainer}
              optionsContainerStyle={{
                width: 150,
                padding: 5,
              }}
              menuOptions={
                isInactive
                  ? EligibleStatusList(hasOrders)["inactive"]
                  : (EligibleStatusList(hasOrders)[status] || [])
                      .concat(isPrintEligible ? printActions : [])
                      .concat(defaultActionsList)
              }
              onSelect={(val = "") => {
                const action = val?.toLowerCase();
                action === "printproposal"
                  ? handleProposalPrint()
                  : action === "printlabels"
                  ? handleLabelsPrint()
                  : action === "modify"
                  ? handleModify()
                  : ["complete", "cancel"].includes(action)
                  ? handleModify(StatusSuggestions[action]?.toLowerCase())
                  : action === "delete"
                  ? handleDelete()
                  : handleUpdate(action);
              }}
              testID="list_item_menu"
              accessibilityLabel="list_item_menu"
            >
              <Image
                style={{
                  width: 22,
                  height: 22,
                }}
                resizeMode="contain"
                source={IMAGES[`three-dots`]}
              />
            </PopupMenu>
          </View>
        </View>
      </TouchableOpacity>
      <ProposalInfo
        values={{ ...data, customerInfo: data?.customer?.details }}
        componentRef={ref}
      />
    </>
  );
};

export default Item;
