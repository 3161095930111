import { all, fork } from "redux-saga/effects";

import onGoing from "./ongoing";
import views from "./views";
import session from "./session";

// Redux Saga: Root Saga
export function* rootSaga() {
  yield all([
    ...onGoing.map((saga) => fork(saga)),
    ...views.map((saga) => fork(saga)),
    fork(session),
  ]);
}

export default rootSaga;
