import { createSlice } from "@reduxjs/toolkit";

import { SLICE_NAME as sliceName } from "./constants";
import { SLICE_NAME as parentSlice } from "../constants";

import get from "lodash/get";
import set from "lodash/set";

export const initialState = {
  addonsList: { content: [], loading: false },
  addonsDetails: { content: [] },
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: `view::${parentSlice}::${sliceName}`,
  initialState,
  reducers: {
    setApiResponse: (state, action) => {
      const { path, content, isPatch = false, contentType } = action.payload;
      if (isPatch) {
        if (contentType === "object") {
          const prevContent = get(state, `${path}.content`, {});
          set(state, `${path}.content`, {
            ...prevContent,
            ...content,
          });
        } else {
          const prevContent = get(state, `${path}.content`, []);
          set(state, `${path}.content`, [...prevContent, ...content]);
        }
      } else {
        set(state, `${path}.content`, content);
      }
    },
    setApiLoading: (state, action) => {
      const { path, loading = false } = action.payload;
      set(state, `${path}.loading`, loading);
    },

    fetchFloristSubscrptionsList: () => {},
    saveAddonFeatureSettings: () => {},
  },
});

export const {
  fetchFloristSubscrptionsList,
  saveAddonFeatureSettings,
  setApiResponse,
  setApiLoading,
} = slice.actions;

export default { [sliceName]: slice.reducer };
