import React from "react";
import { Text } from "react-native-elements";
import { ActivityIndicator, View, TouchableOpacity, Image } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import tw from "tailwind-rn";
import IMAGES from "static/assets/images";
import { fonts, colors, backgroundColors, theme } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import UserProfileContext from "library/contexts/userProfile";
import { selectDSRequestActionProcessing } from "library/sagas/views/home/drawer/delivery-tasks/selector";
import { setDSRequestModifying } from "library/sagas/views/home/drawer/delivery-tasks/slice";
import { useFormikContext } from "formik";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { PrintIframeAction, PrintIframeRenderer } from "components/elements";
import { getPrintHTML } from "./helper";

const TakeAction = ({
  DSActions = {},
  isSmallScreen = false,
  actionHandler = () => {},
  dsRequestDetails = {},
  setValidationOnChange = {},
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const isActionProcessing = useSelector(selectDSRequestActionProcessing);
  const isPrinted = false;
  const dispatch = useDispatch();
  const { resetForm, isValid, values, initialValues, setErrors } =
    useFormikContext();
  const { userProfile: { firstName: operator } = {} } =
    React.useContext(UserProfileContext);

  const currentValues = cloneDeep(values);
  let initialValuesObject = cloneDeep(initialValues);
  delete initialValuesObject.timeWindow;
  delete initialValuesObject.injectTimings;
  delete initialValuesObject.pickupUpdatedTime;
  delete initialValuesObject.deliveryProvider;
  delete initialValuesObject.pickupTimeOptions;
  delete initialValuesObject.fulfillmentType;
  delete initialValuesObject.recipientDetails.geoLocation;

  let currentValuesObject = cloneDeep(currentValues);
  delete currentValuesObject.timeWindow;
  delete currentValuesObject.injectTimings;
  delete currentValuesObject.pickupUpdatedTime;
  delete currentValuesObject.deliveryProvider;
  delete currentValuesObject.pickupTimeOptions;
  delete currentValuesObject.fulfillmentType;
  delete currentValuesObject.recipientDetails.geoLocation;

  const isFormChanged = !isEqual(initialValuesObject, currentValuesObject);

  const disableSave = !isFormChanged || !isValid;

  return (
    <View
      style={{
        ...tw("flex-row px-3 py-4 "),
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
        backgroundColor: backgroundColors.secondary,
        opacity: 1,
      }}
      pointerEvents={"auto"}
    >
      {isActionProcessing ? (
        <View
          style={{
            ...tw("flex-row justify-between items-center my-1"),
            width: "auto",
          }}
        >
          <Text style={{ fontWeight: "600", marginRight: 5 }}>
            {Localise(messages, "Processing")}
          </Text>
          <ActivityIndicator color={colors.activityIndicator} />
        </View>
      ) : (
        <View style={tw("w-full")}>
          <View style={tw("flex-row items-center justify-between")}>
            <View
              style={{
                ...tw("flex-row items-center flex-wrap "),
                width: "90%",
              }}
            >
              <>
                {!!DSActions.length && (
                  <View style={tw("mr-2 mt-1")}>
                    <Text
                      style={{
                        ...fonts.heading4,
                        color: backgroundColors.primary,
                        ...(isSmallScreen && { marginBottom: 7 }),
                      }}
                    >
                      {Localise(messages, "Take Action")}
                    </Text>
                  </View>
                )}
                {DSActions.map((action) => {
                  return (
                    <View
                      key={action.key}
                      style={{
                        ...tw("flex-row flex-wrap"),
                        ...theme.Button.secondaryButtonStyle,
                        ...(isSmallScreen && { marginBottom: 7 }),
                        ...tw("mr-1 mt-1"),
                        paddingHorizontal: 10,
                        paddingVertical: 5,
                      }}
                    >
                      <Text
                        style={{
                          ...theme.Button.secondaryTitleStyle,
                          color: backgroundColors.primary2,
                          fontSize: 13,
                          ...(disableSave &&
                            action.key === "save-order-changes" && {
                              opacity: 0.5,
                              pointerEvents: "none",
                            }),
                        }}
                        testID={action.key}
                        accessibilityLabel={action.key}
                        onPress={() => {
                          if (action.key == "modify-request") {
                            dispatch(setDSRequestModifying(true));
                          } else if (action.key == "nevermind") {
                            dispatch(setDSRequestModifying(false));
                            setValidationOnChange(false);
                            setErrors({});
                            resetForm();
                          } else if (action.key === "save-order-changes") {
                            if (isFormChanged && isValid) {
                              actionHandler(action.key);
                            }
                          } else {
                            actionHandler(action.key);
                          }
                        }}
                      >
                        {Localise(messages, action.label)}
                      </Text>
                    </View>
                  );
                })}
              </>
            </View>

            <View
              style={{
                ...tw("flex-row"),
                ...(isSmallScreen && { width: "10%" }),
                opacity: 1,
              }}
            >
              <>
                <PrintIframeRenderer />
                <TouchableOpacity
                  activeOpacity={0.5}
                  onPress={() => {
                    PrintIframeAction(
                      getPrintHTML(
                        dsRequestDetails,
                        operator,
                        messages,
                        Localise
                      ),
                      () => {}
                    );
                  }}
                  testID="print"
                  accessibilityLabel="print"
                >
                  <Image
                    style={{
                      width: 26,
                      height: 26,
                    }}
                    source={IMAGES[isPrinted ? "printAgain" : "print"]}
                  />
                </TouchableOpacity>
              </>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

export default TakeAction;
