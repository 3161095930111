import { put } from "redux-saga/effects";

import { setApiResponse } from "../slice";

export function* handleFetchSiteContent() {
  yield put(
    setApiResponse({
      path: "siteContent",
      content: "Site Content",
    })
  );
}
