/* eslint-disable no-underscore-dangle, no-use-before-define */

import React from "react";
import {
  Text,
  Clipboard,
  StyleSheet,
  TouchableOpacity,
  View,
  Platform,
} from "react-native";
import { MessageText, MessageImage, Time } from "react-native-gifted-chat";

import { fonts } from "styles/theme";

export default class Bubble extends React.Component {
  constructor(props) {
    super(props);
    this.onLongPress = this.onLongPress.bind(this);
  }

  onLongPress() {
    if (this.props.onLongPress) {
      this.props.onLongPress(this.context, this.props.currentMessage);
    } else {
      if (this.props.currentMessage.text) {
        const options = ["Copy Text", "Cancel"];
        const cancelButtonIndex = options.length - 1;
        this.context.actionSheet().showActionSheetWithOptions(
          {
            options,
            cancelButtonIndex,
          },
          (buttonIndex) => {
            switch (buttonIndex) {
              case 0:
                Clipboard.setString(this.props.currentMessage.text);
                break;
            }
          }
        );
      }
    }
  }

  renderMessageText() {
    if (this.props.currentMessage.text) {
      const {
        containerStyle,
        wrapperStyle,
        messageTextStyle,
        ...messageTextProps
      } = this.props;
      if (this.props.renderMessageText) {
        return this.props.renderMessageText(messageTextProps);
      }
      return (
        <MessageText
          {...messageTextProps}
          textStyle={{
            left: [
              styles.standardFont,
              styles.slackMessageText,
              messageTextProps.textStyle,
              messageTextStyle,
            ],
          }}
        />
      );
    }
    return null;
  }

  renderMessageImage() {
    if (this.props.currentMessage.image) {
      const { containerStyle, wrapperStyle, ...messageImageProps } = this.props;
      if (this.props.renderMessageImage) {
        return this.props.renderMessageImage(messageImageProps);
      }
      return (
        <MessageImage
          {...messageImageProps}
          imageStyle={[styles.slackImage, messageImageProps.imageStyle]}
        />
      );
    }
    return null;
  }

  renderTicks() {
    const { currentMessage } = this.props;
    if (this.props.renderTicks) {
      return this.props.renderTicks(currentMessage);
    }
    if (currentMessage.user._id !== this.props.user._id) {
      return null;
    }
    if (currentMessage.sent || currentMessage.received) {
      return (
        <View style={[styles.headerItem, styles.tickView]}>
          {currentMessage.sent && (
            <Text
              style={[styles.standardFont, styles.tick, this.props.tickStyle]}
            >
              ✓
            </Text>
          )}
          {currentMessage.received && (
            <Text
              style={[styles.standardFont, styles.tick, this.props.tickStyle]}
            >
              ✓
            </Text>
          )}
        </View>
      );
    }
    return null;
  }

  renderUsername() {
    const username = this.props.currentMessage.user.name;
    if (username) {
      const { containerStyle, wrapperStyle, ...usernameProps } = this.props;
      if (this.props.renderUsername) {
        return this.props.renderUsername(usernameProps);
      }
      return (
        <Text
          style={[
            styles.standardFont,
            styles.headerItem,
            styles.username,
            this.props.usernameStyle,
          ]}
        >
          {username}
        </Text>
      );
    }
    return null;
  }

  renderTime() {
    if (this.props.currentMessage.createdAt) {
      const { containerStyle, wrapperStyle, ...timeProps } = this.props;
      if (this.props.renderTime) {
        return this.props.renderTime(timeProps);
      }
      return (
        <Time
          {...timeProps}
          containerStyle={{ left: [styles.timeContainer] }}
          textStyle={{
            left: [
              styles.standardFont,
              styles.headerItem,
              styles.time,
              timeProps.textStyle,
            ],
          }}
        />
      );
    }
    return null;
  }

  renderCustomView() {
    if (this.props.renderCustomView) {
      return this.props.renderCustomView(this.props);
    }
    return null;
  }

  render() {
    const isOutBound = this.props.currentMessage.direction === "OUTBOUND";
    const messageHeader = (
      <View
        style={[
          styles.headerView,
          { alignItems: isOutBound ? "flex-end" : "flex-start" },
        ]}
      >
        {this.renderUsername()}
        {this.renderTime()}
        {this.renderTicks()}
      </View>
    );
    const content = (
      <View style={[styles.wrapper, this.props.wrapperStyle]}>
        <View style={[{ alignItems: isOutBound ? "flex-end" : "flex-start" }]}>
          {this.renderCustomView()}
          {messageHeader}
          <View style={styles.innerWrapper}>
            {this.renderMessageImage()}
            {this.renderMessageText()}
          </View>
        </View>
      </View>
    );
    return (
      <View
        style={[
          styles.container,
          this.props.containerStyle,
          { alignItems: isOutBound ? "flex-end" : "flex-start" },
        ]}
      >
        {Platform.OS !== "web" ? (
          <TouchableOpacity
            onLongPress={this.onLongPress}
            accessibilityTraits="text"
            {...this.props.touchableProps}
          >
            {content}
          </TouchableOpacity>
        ) : (
          <>{content}</>
        )}
      </View>
    );
  }
}

// Note: Everything is forced to be "left" positioned with this component.
// The "right" position is only used in the default Bubble.
const styles = StyleSheet.create({
  standardFont: {
    fontSize: 12,
    fontFamily: fonts.fontFamily.default,
  },
  slackMessageText: {
    marginLeft: 0,
    marginRight: 0,
  },
  container: {
    flex: 1,
    alignItems: "flex-end",
    marginHorizontal: 5,
  },
  wrapper: {
    marginRight: 10,
    minHeight: 20,
    justifyContent: "flex-end",
    width: "100%",
  },
  innerWrapper: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 3,
    padding: 10,
    marginTop: 10,
    maxWidth: "95%",
  },
  username: {
    fontWeight: "600",
  },
  time: {
    textAlign: "left",
    fontSize: 12,
  },
  timeContainer: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
  },
  headerItem: {
    marginRight: 10,
    marginLeft: 10,
  },
  headerView: {
    // Try to align it better with the avatar on Android.
    marginTop: Platform.OS === "android" ? -2 : 0,
    flexDirection: "column",
  },
  tick: {
    backgroundColor: "transparent",
    color: "white",
  },
  tickView: {
    flexDirection: "row",
  },
  slackImage: {
    borderRadius: 3,
    marginLeft: 0,
    marginRight: 0,
  },
});

Bubble.defaultProps = {
  touchableProps: {},
  onLongPress: null,
  renderMessageImage: null,
  renderMessageText: null,
  renderCustomView: null,
  renderTime: null,
  currentMessage: {
    text: null,
    createdAt: null,
    image: null,
  },
  nextMessage: {},
  previousMessage: {},
  containerStyle: {},
  wrapperStyle: {},
  tickStyle: {},
  containerToNextStyle: {},
  containerToPreviousStyle: {},
};
Bubble.contextTypes = {
  actionSheet: () => {},
};
