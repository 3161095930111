import React from "react";
import { View, Text, Platform } from "react-native";
import { useFormikContext } from "formik";
import { Image } from "react-native-elements";

import get from "lodash/get";

import { Tooltip } from "components/elements";
import { FormField, FormFieldCheckBox } from "components/elements/forms";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

import { fonts, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";

const WebsiteInput = ({ data }) => {
  const { formFieldType, ...inputProps } = data;
  return formFieldType === "Label" ? (
    <LabelField {...inputProps} />
  ) : formFieldType === "Text" ? (
    <TextField {...inputProps} />
  ) : formFieldType === "Image" ? (
    <ImageField {...inputProps} />
  ) : formFieldType === "CustomAddress" ? (
    <AddressField {...inputProps} />
  ) : formFieldType === "CustomUrls" ? (
    <RedirectUrlField {...inputProps} />
  ) : null;
};

const CheckBoxField = ({ name }) => {
  const { values, setFieldValue } = useFormikContext();
  const isChecked = get(values, name, false);
  const { Localise, messages } = React.useContext(I18NContext);

  return (
    <View style={tw("flex flex-row items-center")}>
      <FormFieldCheckBox
        iconRight={false}
        name={"checkbox"}
        size={20}
        title={""}
        isChecked={isChecked}
        onChangehandler={() => setFieldValue(name, !isChecked)}
        inputContainerStyle={{ marginRight: 0 }}
      />
      <Text
        style={[
          fonts.heading5,
          { marginBottom: Platform.OS !== "web" ? 20 : 0 },
        ]}
      >
        {Localise(messages, "Show On Website")}
      </Text>
    </View>
  );
};

const LabelField = ({ title, formFieldProps }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  return (
    <View style={formFieldProps.style}>
      <Text style={fonts.heading3}>{Localise(messages, title)}</Text>
    </View>
  );
};

const TextField = ({ name, title, formFieldProps, checkBoxAddIn }) => {
  const { isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);

  return (
    <>
      <View
        style={tw(
          `flex flex-row justify-start items-center pb-2 items-${
            Platform.OS !== "web" ? "start" : "baseline"
          }`
        )}
      >
        <Text
          style={{
            height: 40,
            width: isMobile ? "25%" : "16%",
            paddingTop: Platform.OS !== "web" && isMobile ? 10 : 0,
            ...fonts.heading5,
          }}
        >
          {Localise(messages, title)}
        </Text>
        <View
          style={[
            tw("flex flex-row justify-start items-center pb-2 flex-wrap"),
            { width: isMobile ? "75%" : "84%" },
          ]}
        >
          <FormField
            name={name}
            {...formFieldProps}
            containerStyle={{
              width: isMobile ? "100%" : "70%",
              marginRight: 20,
            }}
            inputContainerStyle={{
              backgroundColor: formFieldProps.editable
                ? "auto"
                : backgroundColors.greyColor,
              height: 35,
              ...formFieldProps.inputContainerStyle,
            }}
          />
          {checkBoxAddIn && <CheckBoxField name={checkBoxAddIn} />}
        </View>
      </View>
    </>
  );
};

const ImageField = ({ name, title }) => {
  const {
    values: { [name]: logo },
  } = useFormikContext();
  const { isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);

  return (
    <View style={tw(`flex flex-row justify-start items-center pb-2`)}>
      <Text style={{ width: isMobile ? "25%" : "16%", ...fonts.heading5 }}>
        {Localise(messages, title)}
      </Text>
      <View
        style={[
          tw("flex flex-row justify-start items-center pb-2 flex-wrap"),
          { width: isMobile ? "75%" : "84%" },
        ]}
      >
        <View
          style={[
            tw("flex flex-row items-center"),
            { width: isMobile ? "90%" : "70%", paddingLeft: 5 },
          ]}
        >
          {logo !== "" ? (
            <Tooltip text={logo}>
              <Image
                style={{
                  width: 100,
                  height: 100,
                  borderWidth: 0.5,
                  borderColor: "#CCCCCC",
                }}
                resizeMode={"contain"}
                source={{
                  uri: logo,
                }}
              />
            </Tooltip>
          ) : (
            <Text
              style={[
                tw("flex flex-row justify-center items-center"),
                {
                  borderWidth: 1,
                  borderColor: "#CCC",
                  height: 100,
                  width: 100,
                  fontSize: 12,
                },
              ]}
            >
              {Localise(messages, "No Image")}
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};

const AddressField = ({ name, title, formInputFields, checkBoxAddIn }) => {
  const { isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);
  return (
    <View
      style={tw(
        `flex flex-row justify-start items-${
          Platform.OS !== "web" ? "start" : "baseline"
        } pb-2`
      )}
    >
      <Text
        style={{
          height: 40,
          width: isMobile ? "25%" : "16%",
          paddingTop: Platform.OS !== "web" && isMobile ? 10 : 0,
          ...fonts.heading5,
        }}
      >
        {Localise(messages, title)}
      </Text>
      <View
        style={[
          tw(
            `flex flex-row justify-start items-${
              Platform.OS !== "web" ? "center" : "baseline"
            } pb-2 flex-wrap`
          ),
          { width: isMobile ? "75%" : "84%" },
        ]}
      >
        <View
          style={[
            tw("flex flex-row flex-wrap"),
            { width: isMobile ? "100%" : "70%", marginRight: 20 },
          ]}
        >
          {formInputFields.map(({ name: field, formFieldProps }) => {
            const path = `${name}.${field}`;
            const width = isMobile
              ? {
                  streetAddress: "100%",
                  aptNumber: "55%",
                  city: "45%",
                  state: "50%",
                  zip: "50%",
                }
              : {
                  streetAddress: "60%",
                  aptNumber: "40%",
                  city: "50%",
                  state: "25%",
                  zip: "25%",
                };
            return (
              <FormField
                name={path}
                key={path}
                containerStyle={{
                  width: width[field],
                }}
                inputContainerStyle={{
                  backgroundColor: formFieldProps.editable
                    ? "auto"
                    : backgroundColors.greyColor,
                }}
                {...formFieldProps}
              />
            );
          })}
        </View>
        {checkBoxAddIn && <CheckBoxField name={checkBoxAddIn} />}
      </View>
    </View>
  );
};

const RedirectUrlField = ({ name, title, formFieldProps }) => {
  const {
    values: { [name]: redirectUrls = [] },
  } = useFormikContext();
  const { isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);
  return (
    redirectUrls.length > 0 && (
      <View
        style={tw(
          `flex flex-row justify-start items-${
            Platform.OS !== "web" && isMobile ? "start" : "baseline"
          } pb-2`
        )}
      >
        <Text
          style={{
            height: 40,
            width: isMobile ? "25%" : "16%",
            paddingTop: Platform.OS !== "web" && isMobile ? 10 : 0,
            ...fonts.heading5,
          }}
        >
          {Localise(messages, title)}
        </Text>

        <View
          style={[
            tw("flex flex-row justify-start items-center pb-2 flex-wrap"),
            { width: isMobile ? "75%" : "84%" },
          ]}
        >
          <View
            style={[
              tw("flex flex-col"),
              { width: isMobile ? "90%" : "70%", marginRight: 20 },
            ]}
          >
            {redirectUrls.map((url, index) => {
              const path = `redirectUrls.${index}`;
              return (
                <FormField
                  name={path}
                  key={path}
                  inputContainerStyle={{
                    backgroundColor: formFieldProps.editable
                      ? "auto"
                      : backgroundColors.greyColor,
                  }}
                  {...formFieldProps}
                />
              );
            })}
          </View>
        </View>
      </View>
    )
  );
};

export default WebsiteInput;
