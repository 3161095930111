// import moment from "moment";
// import toUpper from "lodash/toUpper";
import toLower from "lodash/toLower";
import capitalize from "lodash/capitalize";

export const replacePlaceHolders = (
  text = "",
  settings = {},
  noTranslate
  // shopCities = []
  // hours = {}
) => {
  if (!text) return "";

  const {
    shopName,
    shopPhone,
    shopAddress: { addressLine1, city: shopCity, state: shopState } = {},
    shopTollFree: tollFree,
    websitePhoneNumber,
    // website: shopWebsite,
    websiteEmail,
    displayShopAddress,
    displayShopTollFree,
    displayWebsitePhoneNumber,
    displayWebsiteEmail,
  } = settings;

  const shopTollFree = displayShopTollFree ? tollFree : "";
  const shopEmail = displayWebsiteEmail ? websiteEmail : "";
  const shopAddressLine = displayShopAddress ? addressLine1 : "";
  const shopWebsitePhone = displayWebsitePhoneNumber
    ? websitePhoneNumber || shopPhone
    : "";
  const formattedShopName = toLower(shopName || "")
    .split(" ")
    .map(capitalize)
    .join(" "); //special characters are being replaced with startCase

  // const shopActiveCities =
  //   shopCities?.reduce((cities, cityInfo) => {
  //     if (cityInfo?.status === "Y") {
  //       cities.push(startCase(toLower(cityInfo?.city)));
  //     }
  //     return cities;
  //   }, []) || [];

  // const shopHours = getShopHoursHTML(hours);
  // let replacedText = text.replace(
  //   new RegExp("#ADDITIONALCITYNAMES#", "mg"),
  //   shopActiveCities?.join(", ")
  // );

  function translate(string) {
    if (noTranslate) return string;

    return `<span translate="no">${string}</span>`;
  }

  let replacedText = text;

  if (settings.shopName) {
    return (
      replacedText
        .replace(
          new RegExp("#FLORISTNAME#|#FLORIST_SHOP_NAME#", "mg"),
          translate(formattedShopName)
        )
        .replace(
          new RegExp("#FLORIST_SHOP_EMAIL#|#FLORISTEMAIL#", "mg"),
          translate(shopEmail)
        )
        //.replace(new RegExp("#FLORISTHOURS#", "mg"), shopHours)
        //.replace(new RegExp("#FLORISTWEBSITE#", "mg"), shopWebsite)
        .replace(
          new RegExp("#FLORIST_SHOP_PHONE_NUMBER#|#FLORISTPHONE#", "mg"),
          translate(shopWebsitePhone)
        )
        .replace(
          new RegExp("#FLORIST_SHOP_TOLL_FREE#|#FLORISTTOLLFREE#", "mg"),
          translate(shopTollFree)
        )
        .replace(
          new RegExp("#FLORIST_SHOP_CITY#|#FLORISTCITY#", "mg"),
          translate(shopCity)
        )
        .replace(
          new RegExp("#FLORIST_SHOP_STATE#|#FLORISTSTATE#", "mg"),
          translate(shopState)
        )
        .replace(
          new RegExp("#FLORIST_SHOP_ADDRESS#|#FLORISTADDRESS#", "mg"),
          translate(shopAddressLine)
        )
    );
  }
  return replacedText;
};

// const getShopHoursHTML = (hours = {}) => {
//   const formatTime = ({ open, close, openedFlag }) => {
//     if (openedFlag === "F") return "CLOSED";

//     return `<span class="shop-hours-open">${moment(open).format(
//       "hh:mm A"
//     )}<span>
//     <span class="shop-hours-delim">-</span>
//     <span class="shop-hours-close">${moment(close).format("hh:mm A")}<span>`;
//   };

//   const dayOfWeek = (day, data) => {
//     return `<div class="shop-hours-row">
//     <span class="shop-hours-day">${toUpper(day)}</span>
//     <span class="shop-hours-timing">
//       ${formatTime(data)}
//     </span>
//   </div>`;
//   };

//   const hoursHTML = [
//     "monday",
//     "tuesday",
//     "wednesday",
//     "thursday",
//     "friday",
//     "saturday",
//     "sunday",
//   ]
//     .map((d) => dayOfWeek(d, hours[d]))
//     .join("");

//   return `<style>
//       .shop-hours {
//           max-width: 300px;
//           padding: 10px 0px;
//           display: flex;
//           flex-direction: column;
//       }
//       .shop-hours-row {
//           display: flex;
//           flex-direction: row;
//           justify-content: space-between;
//       }
//       .shop-hours-day {
//           text-align: left;
//       }
//       .shop-hours-timing {
//           text-align: right;
//           display: flex;
//           flex-direction: row;
//       }
//       .shop-hours-open,
//       .shop-hours-close {
//           text-align: center;
//       }
//       .shop-hours-delim {
//           width: 20px;
//           text-align: center;
//       }
//     </style>

//     <div class="shop-hours">
//       ${hoursHTML}
//     </div>`;
// };
