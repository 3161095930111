import React, { useContext } from "react";
import { Text, View } from "react-native";
import { colors } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import tw from "tailwind-rn";
import { advOptimiseFee } from "components/views/drawer/shop-settings/addons/ui-config";

export const OptimizeMessage = ({
  localPermissions = {},
  isPastRoute,
  isOptimized,
  routeHasEdits,
  style = {},
}) => {
  const { messages, Localise } = useContext(I18NContext);

  return (
    <View style={style}>
      {localPermissions?.multiShopRoute &&
        !localPermissions.isCFROptimiseEnabled &&
        !isPastRoute &&
        isOptimized && (
          <View style={tw("flex flex-row justify-start mt-1 mb-2")}>
            <Text
              style={{
                color: colors.primary,
              }}
            >
              {Localise(
                messages,
                "Route optimized. Please make additional adjustments before starting the route."
              )}
            </Text>
          </View>
        )}
      {!isPastRoute && !isOptimized && (
        <View style={tw("flex flex-row justify-start mt-1 mb-2")}>
          <Text
            style={{
              color: colors.primary,
            }}
          >
            {Localise(
              messages,
              routeHasEdits
                ? "This route has not been optimized since the last order update."
                : "This route has not been optimized."
            )}
          </Text>
        </View>
      )}
      {localPermissions.isCFROptimiseEnabled && (
        <View style={{ paddingBottom: 10 }}>
          <Text
            style={{
              color: colors.primary,
              textAlign: "justify",
            }}
          >
            {Localise(
              messages,
              `Advanced optimization incurs a fee of $${advOptimiseFee} on any optimized route with timed deliveries, multi-shop pickup points, and/or routes with 25-60 stops.`
            )}
          </Text>
        </View>
      )}
    </View>
  );
};
