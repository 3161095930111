import { createStyles, minWidth } from "react-native-media-queries";
import { colors } from "styles/theme";

const base = {
  container: {
    paddingHorizontal: 6,
    paddingVertical: 10,
    borderWidth: 1,
    borderColor: colors.grayScaleLight,
    marginVertical: 5,
    flexDirection: "column",
  },
  datesContainer: {
    flexDirection: "column",
    width: "100%",
    paddingLeft: 12,
    paddingTop: 10,
  },
  labelContainer: {
    paddingLeft: 12,
  },
  datesSection: {
    paddingBottom: 12,
  },
  dateFieldsError: {
    color: colors.error,
    fontFamily: "Arial",
    fontSize: 12,
    fontWeight: "600",
    lineHeight: 18,
    margin: 0,
    padding: 5,
    paddingLeft: 5,
    width: 115,
  },
};

const tabletStyles = {
  container: {
    flexDirection: "column",
  },
  datesContainer: {
    paddingLeft: 12,
    flexWrap: "wrap",
    width: "100%",
    flexDirection: "row",
  },
  datesSection: {
    paddingBottom: 0,
  },
};

const laptopStyles = {
  container: {
    flexDirection: "row",
  },
  datesContainer: {
    flexDirection: "row",
  },
  datesSection: {
    paddingBottom: 0,
  },
};

const largeDevices = {
  container: {
    flexDirection: "row",
  },
  datesSection: {
    paddingBottom: 0,
  },
};

export default createStyles(
  base,
  minWidth(767, tabletStyles),
  minWidth(1300, laptopStyles),
  minWidth(1700, largeDevices)
);
