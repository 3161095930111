import React, { useMemo } from "react";
import { View, Text } from "react-native";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import moment from "moment";
import { ActiveActionItems } from "../../config";

const Confirmation = (props) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { text = "", date = "", fullLegalName = "" } = props;

  return useMemo(() => {
    const statusText = (
      ActiveActionItems[text.toLowerCase()]?.label || text
    )?.toLowerCase();
    const name = fullLegalName || `Florist ${statusText}`;
    return (
      <View
        style={[
          tw("flex flex-row items-center justify-between w-full"),
          { marginTop: 15, paddingVertical: 15, paddingHorizontal: 18 },
        ]}
      >
        <Text
          style={{ width: "50%", fontSize: 16, textTransform: "capitalize" }}
        >{`${Localise(messages, statusText)} ${
          date ? moment(date).format("MM/DD/YY") : ""
        }`}</Text>

        <Text
          style={{
            textDecorationLine: "underline",
            width: "50%",
            fontStyle: "italic",
            fontSize: 16,
            textTransform: "capitalize",
          }}
        >
          {`     ${name}     `}
        </Text>
      </View>
    );
  }, [Localise, messages, date, fullLegalName, text]);
};

export default Confirmation;
