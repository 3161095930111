import IMAGES from "static/assets/images";
import UserProfileStorage from "library/storage/userProfile";
import { convertToTimeZone } from "library/utils/deliveryService";
import moment from "moment";
import startCase from "lodash/startCase";
import { phoneNumberFormatter } from "library/utils/formatter";

export const getPrintHTML = (
  dsRequestDetails,
  operatorName,
  messages = {},
  Localise = () => {}
) => {
  const {
    delivery = {},
    pickup = {},
    referenceId: orderNumber = "",
    source = "",
    siteId: shopCode = "",
    vendor = {},
    items = [],
  } = dsRequestDetails;

  const orderSource =
    source === "MHQ_ORDERS"
      ? "Orders"
      : ["MHQ_DELIVERY_TASKS", "MHQ_BULK_IMPORT_DELIVERY_TASKS"].includes(
          source
        )
      ? "Delivery Service Request"
      : "";

  const shopTimeZone =
    UserProfileStorage.getShopTimeZone(shopCode) || "America/Chicago";

  const shopPhone = UserProfileStorage.getShopPhoneNumber(shopCode) || "";

  const { timeWindow: pickupTimeWindow, contact: pickupContact } = pickup;

  const {
    notes = "",
    address: deliveryAddress,
    timeWindow: deliveryTimeWindow,
    contact: deliveryContact = {},
  } = delivery;

  const { start: pickupStartTime = "" } = pickupTimeWindow;

  const { start: deliveryDate = "" } = deliveryTimeWindow;

  const { firstName: shopFirstName = "", lastName: shopLastName = "" } =
    pickupContact;

  const shopName = shopFirstName + shopLastName;

  const {
    addressLine1: deliveryAddressLine = "",
    city: deliveryCity = "",
    firstName = "",
    lastName = "",
    state: deliveryState = "",
    zip: deliveryZip = "",
    locationType: deliveryLocationType = "",
  } = deliveryAddress;

  const { phone: deliveryPhone = "" } = deliveryContact;

  const { name: vendorName } = vendor;

  const deliveryProviders = {
    roadie: "Roadie Direct",
    doordash: "DoorDash Direct",
    "vida-doordash": "Legacy Doordash",
    vida: "Legacy Roadie",
    burq: "FTD Delivery Service",
  };

  const deliveryProviderName =
    deliveryProviders[vendorName.toLowerCase()] || "";

  const deliveryCustomerName = firstName + " " + lastName;

  const cityStateAndZip = `${
    deliveryCity && deliveryState
      ? `${deliveryCity}, ${deliveryState}`
      : deliveryCity || deliveryState
  } ${deliveryZip}`;

  const { cost: totalPriceValue = "", description: productDesciption = "" } =
    items[0];

  const checkBoxPlaceholder = () =>
    `<img src="${IMAGES["checkbox-unchecked-dark"]}" width="13" height="13">`;

  const header = () =>
    `<div class="table-responsive">
        <table class="table">
          <tbody>
            <tr>
              <td class="orderTypeHeading" style="width:82% !important">
              ${Localise(messages, "Mercury HQ Delivery Service Detail")}
             <td style="font-size:18px;font-weight:bold !important;text-align: center;">
              ${Localise(messages, "Review")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-responsive">
        <table class="table">
          <tbody>
            <tr style="font-size:14px !important;font-weight:bold !important">
            <td>
                <label>${Localise(messages, "Source")}</label>
              </td>
              <td>${orderSource}</td>
              <td>${checkBoxPlaceholder()}</td>
            </tr>
            <tr style="font-size:14px !important;">
              <td>
                <label>${Localise(messages, "Order Number")}</label>
              </td>
              <td>${orderNumber}</td>
            </tr>
             <tr style="font-size:14px !important;">
                <td>
                  <label>${Localise(messages, "Shop Code")}</label>
                </td>
                <td>${shopCode} - ${shopName}</td>
              </tr>
              <tr style="font-size:14px !important;">
                <td>
                  <label>${Localise(messages, "Phone")}</label>
                </td>
                <td>${phoneNumberFormatter(shopPhone)}</td>
              </tr>

            <tr style="font-size:14px !important;">
              <td><label>${Localise(messages, "Operator")}</label></td>
              <td>${operatorName}</td>
              <td></td>
            </tr>
            <tr style="font-size:14px !important;">
              <td><label>${Localise(messages, "Day & Time")}</label></td>
              <td>${moment().format("ddd MMM DD YYYY  hh:mm A ")}</td>
            </tr>
          </tbody>
        </table>
        <hr />
      </div>`;

  return `<!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml">
  
  <head><title>Mercury HQ</title></head>
  
  <body onload="window.parent.postMessage({ action: 'iframe-loaded' }, '*');">
    <div style="display: none">
      <style type="text/css" media="print,screen">
        @page {
        font-family: Helvetica, sans-serif;
        size: auto;
        margin: 3px;
        -webkit-margin-before: 20px;
        -webkit-margin-after: 50px;
        /* this affects the margin in the printer settings */
        }
        .printOrderContainer td:first-child {
        text-align: left;
        width: 22%;
        font-weight: bold;
        }
        .printOrderContainer td:nth-child(2) {
        text-align: left;
        width: 64%;
        word-break: break-word;
        }
        .printOrderContainer td:nth-child(3) {
        text-align: center;
        justify-Items:center;
        width: 14%;
        padding-right:30px !important;
        }
        .orderTypeHeading {
        font-size: 20px;
        }
        .printOrderContainer .table tr>td,
        .printOrderContainer .table tr>th {
        border-top: 0px;
        padding: 1px 2px;
        border-bottom: 0px;
        }
        @media print {
        html,
        body {
        height: auto;
        -ms-overflow-style: none;
        }
        .noPrint {
        display: none;
        }
        .page-break {
        display: block;
        page-break-after: always;
        }
        .page-break-flow-root {
        display: flow-root;
        page-break-after: always;
        }
        .table-responsive {
        min-height: .01%;
        overflow-x: auto;
        }
        .table {
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        margin-bottom: 10px;
        border-spacing: 2px;
        }
        thead {
        display: table-header-group;
        }
        tr {
        text-align: left;
        display: table-row;
        vertical-align: left;
        border-color: inherit;
        }
        .table>thead>tr>th {
        text-align: left;
        display: table-cell;
        vertical-align: bottom;
        }
        .table>tbody>tr>td,
        .table>tbody>tr>th,
        .table>tfoot>tr>td,
        .table>tfoot>tr>th,
        .table>thead>tr>td,
        .table>thead>tr>th {
        padding: 8px;
        line-height: 1.3;
        }
        .table>tbody>tr>td,
        .table>tbody>tr>th,
        .table>tfoot>tr>td,
        .table>tfoot>tr>th,
        .table>thead>tr>td,
        .table>thead>tr>th {
        padding: 8px;
        line-height: 1.3;
        vertical-align: top;
        border-top: 1px solid #ddd;
        }
        tbody {
        display: table-row-group;
        border-color: inherit;
        }  
        }
      </style>
    </div>
    <div class="printOrderContainer">
      <div style="margin: 10px; background-color: white; padding:10px;" class="page-break-flow-root">
        <div style="margin-top: -25px !important"></div>
        ${header()}
        <div class="table-responsive">
          <table class="table">
            <tbody>
                <tr style="font-size:14px !important;">
                  <td style="font-weight:bold !important">
                    <label>${Localise(messages, "Delivery Date")}*</label>
                  </td>
                  <td>
                    <span style="font-weight:bold !important">
                    ${moment(deliveryDate, "YYYY-MM-DD ").format(
                      "dddd, MMMM D, YYYY "
                    )}</span>
                  </td>
                  <td>${checkBoxPlaceholder()}</td>
                </tr>
                <tr style="font-size:14px !important;">
                  <td><label>${Localise(messages, "Deliver To")}</label></td>
                  <td>${deliveryCustomerName}</td>
                </tr>
                <tr style="font-size:14px !important;">
                  <td><label>${Localise(messages, "Location Type")}</label></td>
                  <td>${startCase(deliveryLocationType)}</td>
                  <td>${checkBoxPlaceholder()}</td>
                </tr>
                <tr style="font-size:14px !important;">
                  <td><label>${Localise(messages, "Address")}</label></td>
                  <td><span>${deliveryAddressLine} </span><br/> <span>${cityStateAndZip}</span></td>
                </tr>

                <tr style="font-size:14px !important;">
                  <td><label>${Localise(messages, "Phone")}</label></td>
                  <td><span>${phoneNumberFormatter(deliveryPhone)}</span></td>
                </tr>
                <tr style="font-size:14px !important;font-weight:bold !important">
                  <td><label>${Localise(
                    messages,
                    "Delivery Instructions"
                  )}</label></td>
                  <td>${notes}</td>
                  <td>${checkBoxPlaceholder()}</td>
                </tr>
            </tbody>
          </table>
          <hr />
        </div>
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr style="font-size:14px !important;">
                <td>
                  <label>${Localise(messages, "Total Order Price")}</label>
                </td>
                <td>$${parseFloat(totalPriceValue.value).toFixed(2) || ``}</td>
              </tr>
              <tr style="font-size:14px !important;">
                <td><label>${Localise(messages, "Product")}</label></td>
                <td>${productDesciption}</td>
                <td>${checkBoxPlaceholder()}</td>
              </tr>
            </tbody>
          </table>
          <hr />
        </div>
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr style="font-size:14px !important;">
                <td>
                  <label>${Localise(messages, "Pickup Time")}</label>
                </td>
                <td>
                    <span style="font-weight:bold !important">
                        ${moment(
                          convertToTimeZone(pickupStartTime, shopTimeZone)
                        ).format("hh:mm A ")}
                    </span>
                <td>
              </tr>
              <tr style="font-size:14px !important;">
                <td><label>${Localise(
                  messages,
                  "Delivery Service Provider"
                )}</label></td>
                <td>${Localise(messages, deliveryProviderName)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </body>
  </html>`;
};
