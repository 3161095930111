import React, { useEffect, useRef } from "react";
import {
  convertFromHTML,
  getSafeBodyFromHTML,
  ContentState,
  DefaultDraftBlockRenderMap,
  EditorState,
  Modifier,
} from "draft-js";
import Immutable from "immutable";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useStateIfMounted from "library/utils/useStateIfMounted";
import "./draft.css";

const RichTextEditor = ({
  value = "",
  setValue,
  toolbarOptions,
  testID = "",
  readOnly,
  persistStyling = false,
  onEditorStateChange = () => {},
  clearEditor = false,
  setClearEditor,
  editorClassName = "",
  ...props
}) => {
  const [editorState, setEditorState] = useStateIfMounted();
  const myEditor = useRef(null);

  const blockRenderMap = Immutable.Map({
    br: {
      element: "br",
    },
    unstyled: {
      element: "div",
      aliasedElements: ["p"],
    },
  });
  const extendedBlockRenderMap =
    DefaultDraftBlockRenderMap.merge(blockRenderMap);

  useEffect(() => {
    if (clearEditor) {
      setEditorState(EditorState.createEmpty());
      setClearEditor(false);
    }
  }, [clearEditor]);

  const prependHttpsToHrefs = (htmlSnippet) => {
    // Regex pattern to match href attributes
    const regex = /<a\s+[^>]*?href=["']([^"']*)["'][^>]*>/g;
    const modifiedHtml = htmlSnippet.replace(regex, function (_match, val) {
      const originalUrl = val;
      const newUrl =
        originalUrl.includes("http") || originalUrl.includes("https")
          ? originalUrl.replace(/#/g, "$")
          : `https://${originalUrl.replace(/#/g, "$")}`;
      return `<a href="${newUrl}">`;
    });
    return modifiedHtml;
   };

  useEffect(() => {
    document.addEventListener("onMockRichTextEdit", handleMockRTEdit);
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(
            prependHttpsToHrefs(value),
            getSafeBodyFromHTML,
            extendedBlockRenderMap
          )
        )
      )
    );
    return () => {
      document.removeEventListener("onMockRichTextEdit", handleMockRTEdit);
    };
  }, []);

  const handleMockRTEdit = (event) => {
    const { id, content } = event.detail;
    id === testID && !readOnly && setValue(content);
  };

  const handlePastedText = (text, html, editorState) => {
    const pastedBlocks = ContentState.createFromBlockArray(
      convertFromHTML(
        prependHttpsToHrefs(text),
        getSafeBodyFromHTML,
        extendedBlockRenderMap
      )
    ).blockMap;

    const newState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      pastedBlocks
    );

    const newEditorState = EditorState.push(
      editorState,
      newState,
      "insert-fragment"
    );

    setEditorState(newEditorState);
    return "handled";
  };

  const setContentState = () => {
    const domParser = new DOMParser();
    const myDom = myEditor.current.children[0];
    const htmlDoc = domParser.parseFromString(myDom.innerHTML, "text/html");
    const editorContentDoc = htmlDoc.querySelector('[data-contents="true"]');
    const textContent = editorContentDoc.textContent;
    const htmlContent = editorContentDoc.outerHTML
      .replace(/\s*data-\S*="[^"]*"/gm, "")
      .replace(/\s*class="[^"]*"/gm, "");

    setValue(textContent ? htmlContent.replace("  ", " ") : "");
  };

  return (
    <div ref={myEditor} data-testid={testID}>
      <Editor
        wrapperStyle={{
          width: "100%",
          marginTop: 0,
          marginBottom: 12,
          ...props.wrapperStyle,
        }}
        toolbarStyle={{
          flex: 1,
          flexDirection: "row-reverse",
          ...props.toolbarStyle,
        }}
        editorStyle={{
          padding: 5,
          paddingLeft: 10,
          ...props.editorStyle,
        }}
        editorClassName={editorClassName}
        editorState={editorState}
        onEditorStateChange={(state) => {
          setEditorState(state);
          onEditorStateChange(state);
        }}
        handlePastedText={handlePastedText}
        toolbar={{
          ...toolbarOptions,
          link: {
            linkCallback: (link) => {
              setTimeout(() => {
                setContentState();
              }, 500);
              return link;
            },
          },
        }}
        onBlur={() => {
          setContentState();
        }}
        spellCheck={true}
        readOnly={readOnly}
        {...props}
      />
    </div>
  );
};

export default RichTextEditor;
