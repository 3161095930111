import { put, takeLatest } from "redux-saga/effects";

import {
  setPageInitialize,
  setPageInitFailure,
  setPageInitSuccess,
  setApiResponse,
  setPageAction,
  setPageSearch,
  handleRefund,
  fetchOrderDetails,
} from "./slice";

import {
  handleFetchAllRefunds,
  handleUIRefresh,
  handleRefundPayment,
  handleFetchOrderDetails,
} from "./listing-screen";

function* handlePageIntialize() {
  try {
    yield put(setPageInitSuccess());
  } catch (error) {
    yield put(setPageInitFailure());
  }
}

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeLatest(setPageInitialize.type, handlePageIntialize);
  yield takeLatest(setPageSearch.type, handleFetchAllRefunds);
  yield takeLatest(setApiResponse.type, handleUIRefresh);
  yield takeLatest(setPageAction.type, handleUIRefresh);
  yield takeLatest(handleRefund.type, handleRefundPayment);
  yield takeLatest(fetchOrderDetails.type, handleFetchOrderDetails);
}

export default watchSaga;
