import React, { useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { View, Platform } from "react-native";
import { Input, Icon } from "react-native-elements";
import moment from "moment";
import { useFormikContext } from "formik";
import get from "lodash/get";
import isNil from "lodash/isNil";
import { getFormattedDate } from "library/utils/datetime";
import "react-datepicker/dist/react-datepicker.css";
import "./form-field-date-picker.css";
import { theme, colors } from "styles/theme";
import I18NContext from "library/contexts/i18N";

// Custom input component to handle date formatting
// eslint-disable-next-line react/display-name
const CustomDateInput = React.forwardRef(
  ({ value, onClick, onChange, placeholder, ...props }, ref) => {
    const handleInputChange = (e) => {
      let inputValue = e.target.value;

      // Remove trailing slash if backspace is pressed
      if (e.nativeEvent.inputType === "deleteContentBackward") {
        if (inputValue.endsWith("/")) {
          inputValue = inputValue.slice(0, -1);
        }
      } else {
        // Automatically add slashes when entering date
        if (/^\d{2}$/.test(inputValue)) {
          inputValue = `${inputValue}/`;
        } else if (/^\d{2}\/\d{2}$/.test(inputValue)) {
          inputValue = `${inputValue}/`;
        }
      }

      onChange({ target: { value: inputValue } });
    };

    return (
      <Input
        {...props}
        value={value}
        onChange={handleInputChange}
        onClick={onClick}
        placeholder={placeholder}
        ref={ref}
      />
    );
  }
);

// eslint-disable-next-line react/display-name
export const FormFieldDatePicker = React.memo(
  ({
    dateFormat = "MM/dd/yyyy",
    containerStyle,
    dateValueFormat,
    label,
    name = "",
    placeholder,
    path,
    iconName,
    iconType = "simple-line-icon",
    iconSize = 15,
    minDate,
    maxDate = null,
    alignIconRight = false,
    onValueChange,
    errorStyle = {},
    popperPlacement,
    popperModifiersOverride = null,
    editable = true,
    isRequired = false,
    labelStyle = {},
    focusTo = false,
    focusCallBack = () => {},
    defaultValue = "",
    allowClear = false,
    ...otherProps
  }) => {
    const { errors, setFieldTouched, setFieldValue, touched, values } =
      useFormikContext();
    const {
      disabled,
      inputContainerStyle: inputContStyle,
      customInputContainerStyle = {},
    } = otherProps;
    const key = (path && `${path}.${name}`) || name;
    const fieldValue = defaultValue || get(values, key, "");
    const touchedVal = get(touched, key);
    const errorMsg = get(errors, key);
    const date = fieldValue ? moment(fieldValue).toDate() : null;

    const { messages, Localise } = React.useContext(I18NContext);

    const handleChange = (date) => {
      onValueChange && onValueChange(date);

      key && setFieldTouched(key);
      if (allowClear && !date) {
        setFieldValue(key, "");
      } else {
        const momentDate = moment(
          getFormattedDate(date || moment().toDate()),
          "MM/DD/YYYY hh:mm:ss"
        );

        if (key) {
          !isNil(dateValueFormat)
            ? setFieldValue(key, momentDate.format(dateValueFormat))
            : setFieldValue(key, momentDate.format("YYYY-MM-DDTHH:mm:ss"));
        }
      }
    };

    let datepicker = useRef(null);

    const inputContainerStyle = {
      ...inputContStyle,
      paddingVertical: 5,
      paddingHorizontal: 10,
    };

    const errorInputContainerStyle = {
      ...inputContainerStyle,
      borderColor: colors.error,
    };

    // Used when we need to focus on dateTimePicker dynamically.
    useEffect(() => {
      if (Platform.OS === "web" && !!focusTo) {
        datepicker.current?.setOpen(true);
        focusCallBack(); // Can be used to reset state once field focused
      }
    }, [focusTo]);

    return (
      <View style={[theme.DatePicker.containerStyle, containerStyle]}>
        <DatePicker
          dateFormat={dateFormat}
          minDate={minDate ? minDate : moment().toDate()}
          maxDate={maxDate ? maxDate : null}
          onChange={handleChange}
          placeholderText={Localise(messages, placeholder)}
          popperModifiers={{
            ...{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: false,
                escapeWithReference: false,
              },
              hide: { enabled: false },
            },
            ...(popperModifiersOverride && popperModifiersOverride),
          }}
          selected={date}
          popperPlacement={popperPlacement && popperPlacement}
          customInput={
            <CustomDateInput
              inputContainerStyle={
                touchedVal && errorMsg && errorMsg.length
                  ? errorInputContainerStyle
                  : inputContainerStyle
              }
              label={`${Localise(messages, label)}${isRequired ? "*" : ""}`}
              testID={name}
              containerStyle={customInputContainerStyle}
              accessibilityLabel={name}
              placeholder={Localise(messages, placeholder)}
              value={fieldValue}
              inputStyle={{ borderWidth: 0 }}
              errorMessage={(touchedVal && errorMsg) || ""}
              leftIconContainerStyle={{ height: iconSize }}
              errorStyle={{ ...(errorStyle && errorStyle) }}
              leftIcon={
                !alignIconRight && iconName ? (
                  <Icon
                    containerStyle={{
                      width: iconSize + 2,
                      cursor: "pointer",
                    }}
                    disabled={disabled}
                    disabledStyle={{
                      cursor: disabled ? "not-allowed" : "pointer",
                    }}
                    name={iconName}
                    type={iconType}
                    color="rgba(110, 120, 170, 1)"
                    size={iconSize}
                    onPress={() => datepicker.current?.setOpen(true)}
                  />
                ) : undefined
              }
              rightIconContainerStyle={{ height: iconSize }}
              rightIcon={
                alignIconRight && iconName ? (
                  <Icon
                    disabled={disabled}
                    disabledStyle={{
                      cursor: disabled ? "not-allowed" : "pointer",
                    }}
                    name={iconName}
                    type={iconType}
                    color="rgba(110, 120, 170, 1)"
                    size={iconSize}
                    onPress={() => datepicker.current?.setOpen(true)}
                  />
                ) : undefined
              }
              editable={editable}
              labelStyle={labelStyle}
            />
          }
          ref={datepicker}
          {...otherProps}
        />
      </View>
    );
  }
);
// eslint-disable-next-line react/display-name
