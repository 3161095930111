import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  View,
  Platform,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { Text, Icon, Divider, Button, Image } from "react-native-elements";
import { useFormikContext } from "formik";
import moment from "moment";

import { request } from "library/utils/request";
import { ToasterHandler } from "components/elements";

import toUpper from "lodash/toUpper";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";

import {
  Form,
  FormField,
  FormFieldAutoComplete,
  SubmitButton,
} from "components/elements/forms";
import { Accordion, CustomModal } from "components/elements";

import {
  InitialValues,
  ValidationSchema,
  PermissionsForEditAccess,
  PermissionsForShopsExport,
  PermissionsForDataMigration,
  PermissionsForShopAccess,
} from "../config";
import {
  getFloristAccount,
  onFormSubmit,
  getFloristSearch,
  syncFloristAccount,
  deleteShopgroup,
  resetPassword,
  exportShops,
} from "../helper";
import DataMigration from "./data-migration";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import useStateIfMounted from "library/utils/useStateIfMounted";

import { setSideCar } from "library/sagas/views/home/drawer/ftd-admin/member-settings/common/slice";
import {
  setMemberCode,
  setJobId,
  setGroupName,
} from "library/sagas/views/home/drawer/ftd-admin/member-settings/manage-groups/slice";

import IMAGES from "static/assets/images";
import { fonts, backgroundColors, colors, theme } from "styles/theme";
import tw from "tailwind-rn";

// eslint-disable-next-line react/display-name
const GroupMembers = React.memo(({ hasEditAccess, hasShopAccess }) => {
  const { isDesktop } = useContext(DeviceContext);
  const dispatch = useDispatch();
  const {
    values: {
      showSyncLoading,
      floristAccountRes,
      profileRes,
      shopGroupRes,
      apiMemberCode,
    },
    setFieldValue,
  } = useFormikContext();

  const { lastSync, shopMappings } = floristAccountRes;
  const { memberCodes: epMemberCodes = [] } = shopGroupRes;
  const [modalVisible, setModalVisibile] = useStateIfMounted(false);
  const modalContent = {
    content: (
      <Text style={[tw("p-4"), fonts.heading3]}>
        Are you sure you want to delete this group along with the associated
        user accounts?
      </Text>
    ),
    buttons: [
      { type: "secondary", title: "Cancel" },
      { type: "primary", title: "Delete" },
    ],
  };
  const hideModal = () => {
    setModalVisibile(false);
  };
  const removeItem = () => {
    deleteShopgroup(
      apiMemberCode,
      profileRes,
      shopGroupRes,
      setFieldValue,
      hideModal
    );
  };

  const shopSyncedColor = "#23a587";
  const shopNotSyncedColor = "#e5175e";

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const windowPreferences = isElectronApp
    ? ["_blank", "width=1400,height=700"]
    : [];

  return (
    <>
      {modalVisible && (
        <CustomModal
          modalVisible={modalVisible}
          modalContent={modalContent}
          primaryhandler={removeItem}
          secondaryhandler={hideModal}
          contentStyle={tw("border border-black")}
        ></CustomModal>
      )}
      <Text style={[fonts.heading4, tw("mb-4")]}>Group Members</Text>
      {shopMappings.map((shop, index) => {
        const shopStatusInMCloud = shop.status.toLowerCase();
        const highlightColor =
          shopStatusInMCloud === "active" &&
          !epMemberCodes.includes(shop.memberCode)
            ? shopNotSyncedColor
            : shopStatusInMCloud === "inactive" &&
              epMemberCodes.includes(shop.memberCode)
            ? shopNotSyncedColor
            : shopStatusInMCloud === "deleted" &&
              epMemberCodes.includes(shop.memberCode)
            ? shopNotSyncedColor
            : shopSyncedColor;
        return (
          <View
            key={index}
            style={{
              paddingLeft: shop.type === "Branch" ? 10 : 0,
            }}
          >
            <TouchableOpacity
              testID="open_shop_preferences"
              accessibilityLabel="open_shop_preferences"
              onPress={() => {
                dispatch(setSideCar("shop-preferences"));
                dispatch(setMemberCode(shop.memberCode));
                dispatch(setJobId(""));
              }}
            >
              <View
                style={[
                  {
                    borderWidth: 1,
                    borderColor: colors.grayScaleLight,
                    padding: 8,
                    marginBottom: 5,
                  },
                  tw("flex flex-row w-full items-center justify-between"),
                ]}
              >
                <View
                  style={[
                    {
                      borderLeftWidth: 5,
                      borderLeftColor: highlightColor,
                    },
                    tw("flex flex-row pl-2 items-center justify-between"),
                  ]}
                >
                  <Text>
                    {highlightColor === shopSyncedColor
                      ? `Synced`
                      : `Not Synced`}
                  </Text>
                </View>
                <Image
                  style={{ width: 20, height: 20 }}
                  source={
                    highlightColor === shopSyncedColor
                      ? IMAGES[`synced`]
                      : IMAGES[`notSynced`]
                  }
                />
                <Text
                  style={{
                    ...fonts.heading5,
                    minWidth: shop.type === "Branch" ? 140 : 150,
                    paddingLeft: 10,
                  }}
                >
                  {shop.storeName}
                </Text>
                <Text style={{ minWidth: 80 }}>{shop.memberCode}</Text>
                {shop.status.toLowerCase() !== "deleted" ? (
                  <Text style={{ minWidth: 70 }}>{shop.type}</Text>
                ) : (
                  <Text style={{ minWidth: 70 }}>-</Text>
                )}
                <Text
                  style={{
                    minWidth: 50,
                    textTransform: "capitalize",
                  }}
                >
                  {shop.status.toLowerCase()}
                </Text>
                {hasShopAccess &&
                isDesktop &&
                shop.status.toLowerCase() === "active" ? (
                  <Text
                    style={{ ...fonts.link1, minWidth: 80 }}
                    onPress={() =>
                      Platform.OS === "web" &&
                      window &&
                      window.open(
                        `/?shop=${shop.memberCode}`,
                        ...windowPreferences
                      )
                    }
                  >
                    Access Shop
                  </Text>
                ) : (
                  <Text style={{ ...fonts.link1, minWidth: 80 }}></Text>
                )}
              </View>
            </TouchableOpacity>
          </View>
        );
      })}
      {hasEditAccess && (
        <View style={tw("flex flex-row justify-between mt-4")}>
          <Button
            titleStyle={theme.Button.secondaryTitleStyle}
            buttonStyle={theme.Button.secondaryButtonStyle}
            title="Delete Group"
            testID="delete_group"
            accessibilityLabel="delete_group"
            onPress={() => {
              setModalVisibile(true);
            }}
          />
          <Button
            titleStyle={theme.Button.secondaryTitleStyle}
            buttonStyle={theme.Button.secondaryButtonStyle}
            loading={!!showSyncLoading}
            loadingProps={{ color: theme.Button.secondaryTitleStyle.color }}
            icon={
              <Icon
                name={"refresh"}
                size={14}
                type="font-awesome"
                iconStyle={{ color: colors.button, marginRight: 6.5 }}
              />
            }
            title="Sync Group"
            testID="sync_group"
            accessibilityLabel="sync_group"
            onPress={() => {
              syncFloristAccount(
                floristAccountRes,
                profileRes,
                shopGroupRes,
                apiMemberCode,
                setFieldValue
              );
            }}
          />
        </View>
      )}
      <Text style={tw("flex flex-row justify-end mt-4")}>
        {`Last synced ${moment
          .utc(lastSync)
          .tz(moment.tz.guess())
          .format("MM/DD/YY [at] h:mm A")}`}
      </Text>
    </>
  );
});

// eslint-disable-next-line react/display-name
const GroupDetails = React.memo(
  ({ hasEditAccess, hasDataMigrationAccess, hasShopAccess }) => {
    const {
      values: { showGroupsLoading, profileRes, floristAccountRes },
      resetForm,
    } = useFormikContext();

    const { accountId, cldDownStatus = "" } = floristAccountRes;
    const { messages, Localise } = React.useContext(I18NContext);
    const [disableDownCld, setDisableDownCld] = useState(false);

    const showCreateGroup =
      profileRes.createMode && showGroupsLoading === "done";
    return (
      <View style={tw("px-8 py-4")}>
        <Text style={[fonts.heading4, tw("mb-4")]}>Group Details</Text>
        <View style={tw("flex flex-row items-center")}>
          <Text style={[fonts.heading5, { minWidth: 130 }]}>Group Name</Text>
          <View style={tw("flex-1")}>
            <FormField
              autoCapitalize="none"
              autoCorrect={false}
              name={"profileRes.accountName"}
              placeholder="Group Name"
              editable={hasEditAccess}
            />
          </View>
        </View>
        <View style={tw("flex flex-row items-center")}>
          <Text style={[fonts.heading5, { minWidth: 130 }]}>
            Admin First Name
          </Text>
          <View style={tw("flex-1")}>
            <FormField
              autoCapitalize="none"
              autoCorrect={false}
              name={"profileRes.firstName"}
              placeholder="Admin First Name"
              editable={hasEditAccess}
            />
          </View>
        </View>
        <View style={tw("flex flex-row items-center")}>
          <Text style={[fonts.heading5, { minWidth: 130 }]}>
            Admin Last Name
          </Text>
          <View style={tw("flex-1")}>
            <FormField
              autoCapitalize="none"
              autoCorrect={false}
              name={"profileRes.lastName"}
              placeholder="Admin Last Name"
              editable={hasEditAccess}
            />
          </View>
        </View>
        <View style={tw("flex flex-row items-center")}>
          <Text style={[fonts.heading5, { minWidth: 130 }]}>Admin Email</Text>
          <View style={tw("flex-1 justify-center")}>
            <FormField
              autoCapitalize="none"
              autoCorrect={false}
              name={"profileRes.emailId"}
              placeholder="Admin Email"
              editable={hasEditAccess}
            />
            {hasEditAccess && !showCreateGroup && (
              <Text
                onPress={() => {
                  resetPassword(profileRes.emailId, Localise, messages);
                }}
                style={[tw("absolute pr-3 right-0"), fonts.link1]}
              >
                Reset Password
              </Text>
            )}
          </View>
        </View>
        <View style={tw("flex flex-row items-center")}>
          <Text style={[fonts.heading5, { minWidth: 130 }]}>IP Address</Text>
          <View style={tw("flex-1")}>
            <FormField
              autoCapitalize="none"
              autoCorrect={false}
              name={"clientIp"}
              placeholder="IP Address"
              editable={hasEditAccess}
              multiline={true}
              numberOfLines={2}
              maxNumberOfLines={5}
              spellCheck={true}
            />
          </View>
        </View>

        {showCreateGroup ? (
          <View style={tw("flex flex-row justify-end items-center")}>
            <Text
              onPress={() => {
                resetForm(InitialValues);
              }}
              style={fonts.link1}
            >
              Cancel
            </Text>
            {hasEditAccess && (
              <SubmitButton
                title={"Create Group"}
                containerStyle={{ marginRight: 5 }}
                buttonStyle={tw("px-4 py-2")}
              />
            )}
          </View>
        ) : (
          <>
            {hasEditAccess && (
              <View style={tw("flex flex-row justify-end items-center")}>
                {cldDownStatus === "" && (
                  <TouchableOpacity
                    disabled={disableDownCld}
                    onPress={() => {
                      {
                        request("down-cloud", {
                          accountId: accountId,
                          decommissionStatus: "YET_TO_BE_DONE",
                          syncStatus: "YES",
                        })
                          .then(() => {
                            ToasterHandler(
                              "success",
                              Localise(messages, `Success`)
                            );
                            setDisableDownCld(true);
                          })
                          .catch((error) => {
                            console.log("Error occurred :>> ", error);
                            ToasterHandler(
                              "uh oh",
                              Localise(messages, `Failed. Please try again`)
                            );
                            setDisableDownCld(false);
                          });
                      }
                    }}
                  >
                    <View
                      style={[
                        {
                          borderWidth: 1,
                          ...(disableDownCld
                            ? {
                                borderColor: colors.grayScaleLight,
                              }
                            : {
                                borderColor: colors.button,
                              }),
                        },
                      ]}
                    >
                      <Text
                        style={[
                          fonts.heading3,
                          tw("px-4 py-2"),
                          {
                            color: disableDownCld
                              ? colors.light
                              : colors.button,
                          },
                        ]}
                      >
                        {Localise(messages, "Down Cloud")}
                      </Text>
                    </View>
                  </TouchableOpacity>
                )}
                <SubmitButton
                  title={"Save Group"}
                  containerStyle={{ marginRight: 5 }}
                  buttonStyle={tw("px-4 py-2")}
                />
              </View>
            )}
            <Divider style={{ marginVertical: 30 }} />
            <GroupMembers
              hasEditAccess={hasEditAccess}
              hasShopAccess={hasShopAccess}
            />
            <Divider style={{ marginVertical: 10 }} />
            {hasDataMigrationAccess && <DataMigration />}
          </>
        )}
      </View>
    );
  }
);

// eslint-disable-next-line react/display-name
const GroupsHeader = React.memo(({ isAccordionOpen, hasShopAccess }) => {
  const dispatch = useDispatch();
  const { isDesktop } = useContext(DeviceContext);
  const {
    values: { profileRes, showGroupsLoading, floristAccountRes },
  } = useFormikContext();

  const groupName = profileRes.initialAccountName;

  useEffect(() => {
    dispatch(setGroupName(groupName));
  }, [groupName]);

  const showCreateGroup = profileRes.createMode && showGroupsLoading === "done";

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const windowPreferences = isElectronApp
    ? ["_blank", "width=1400,height=700"]
    : [];

  return (
    <View
      style={[
        theme.Accordion.titleContainer,
        tw("flex flex-row w-full justify-between px-2"),
      ]}
    >
      {showCreateGroup ? (
        <>
          <Text style={fonts.heading3}>Create Group</Text>
          <Image
            style={{
              width: 20,
              height: 20,
            }}
            source={
              IMAGES[isAccordionOpen ? "expanded-section" : "collapsed-section"]
            }
          />
        </>
      ) : (
        <>
          <Text style={fonts.heading3}>
            Group Name: {profileRes.initialAccountName}
          </Text>
          <Text style={fonts.heading5}>
            Members: {floristAccountRes.shopMappings.length}
          </Text>
          <Text style={fonts.heading5}>
            Mcloud: {floristAccountRes.accountStatus}
          </Text>
          <View style={tw("flex flex-row justify-between")}>
            {hasShopAccess && isDesktop && (
              <Text
                onPress={() => {
                  Platform.OS === "web" &&
                    window &&
                    window.open(
                      `/?group=${encodeURIComponent(
                        profileRes.initialAccountName
                      )}`,
                      ...windowPreferences
                    );
                }}
                style={[
                  fonts.heading3,
                  {
                    borderWidth: 1,
                    borderColor: colors.button,
                    color: colors.button,
                  },
                  tw("px-4 py-2 mr-3"),
                ]}
              >
                Access Group
              </Text>
            )}
            <Image
              style={{
                width: 20,
                height: 20,
              }}
              source={
                IMAGES[
                  isAccordionOpen ? "expanded-section" : "collapsed-section"
                ]
              }
            />
          </View>
        </>
      )}
    </View>
  );
});

// eslint-disable-next-line react/display-name
const AccordionGroups = React.memo(
  ({ hasEditAccess, hasDataMigrationAccess, hasShopAccess }) => {
    return (
      <Accordion
        defaultOpen={true}
        labelStyle={{
          backgroundColor: colors.secondary,
          borderColor: colors.grayScaleLight,
          borderWidth: 1,
          marginRight: 5,
          paddingVertical: 10,
        }}
        handleOnPress={() => {}}
        headerContent={(isAccordionOpen) => (
          <GroupsHeader
            isAccordionOpen={isAccordionOpen}
            hasShopAccess={hasShopAccess}
          />
        )}
        contentStyle={{
          borderColor: colors.grayScaleLight,
          borderWidth: 1,
          borderTopWidth: 0,
          marginRight: 5,
        }}
        openStyle={{
          borderWidth: 1,
          borderBottomWidth: 0,
        }}
        containerStyle={{
          marginVertical: 3,
        }}
      >
        <GroupDetails
          hasEditAccess={hasEditAccess}
          hasDataMigrationAccess={hasDataMigrationAccess}
          hasShopAccess={hasShopAccess}
        />
      </Accordion>
    );
  }
);

const ManageGroups = () => {
  const dispatch = useDispatch();
  const { userRole } = useContext(UserProfileContext);
  const [floristSearchData, setFloristSearchData] = useStateIfMounted([]);

  const hasEditAccess = PermissionsForEditAccess[toUpper(userRole)];
  const hasExportAccess = PermissionsForShopsExport[toUpper(userRole)];
  const hasDataMigrationAccess = PermissionsForDataMigration[toUpper(userRole)];
  const hasShopAccess = PermissionsForShopAccess[toUpper(userRole)];

  return (
    <Form
      initialValues={InitialValues}
      onSubmit={onFormSubmit}
      validationSchema={ValidationSchema}
      render={({ values, setFieldValue, setFieldError, setValues }) => {
        const finalValues = cloneDeep(values);
        const {
          showGroupsLoading,
          inputMemberCode,
          showAllShopsExportLoading,
          showSingleShopExportLoading,
        } = finalValues;

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          getFloristAccount(setFieldValue, setFieldError, inputMemberCode);
        }, [inputMemberCode]);

        return (
          <View
            style={{
              borderColor: colors.light,
              borderWidth: 1,
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.1,
              shadowRadius: 5,
              borderRadius: 5,
            }}
          >
            <View
              style={[
                {
                  height: 50,
                  backgroundColor: backgroundColors.navBar,
                },
                tw("flex flex-row justify-between items-center px-4"),
              ]}
            >
              <Text style={fonts.heading3}></Text>
              {hasExportAccess && (
                <View style={[tw("flex flex-row")]}>
                  {showAllShopsExportLoading && (
                    <ActivityIndicator
                      color={colors.activityIndicator}
                      style={tw("mr-2")}
                    />
                  )}
                  <TouchableOpacity
                    onPress={() => {
                      exportShops("all", setFieldValue);
                    }}
                    testID="export_all_shop_groups"
                    accessibilityLabel="export_all_shop_groups"
                  >
                    <Image
                      style={{
                        width: 25,
                        height: 25,
                        marginRight: 10,
                      }}
                      source={IMAGES[`export`]}
                    />
                  </TouchableOpacity>
                </View>
              )}
            </View>
            <View style={tw("p-4")}>
              <View style={tw("flex flex-row items-center mb-2")}>
                <Text style={[fonts.heading4, tw("mr-2")]}>Search Groups</Text>
                {showGroupsLoading === "load" && (
                  <ActivityIndicator color={colors.activityIndicator} />
                )}
              </View>
              <FormFieldAutoComplete
                name="MTACMemberSettingsCode"
                autoCapitalize="none"
                autoCorrect={false}
                data={floristSearchData}
                onChangeText={(text) => {
                  // clearing previous search data so that auto suggestions will not appear again with previous results when user give space for next time search.
                  if (text.length === 0) {
                    setFloristSearchData([]);
                  }

                  getFloristSearch(setFieldError, setFloristSearchData, text);
                }}
                placeholder="Search by Member Code or Shop Name"
                outerContainerStyle={{
                  marginLeft: -6,
                  zIndex: 1,
                }}
                updateOnBlur={true}
                clearTextOnBackTab={false}
                listDisplayValues={["memberCode", "shopName", "city", "state"]}
                onSelect={(selectedValue) => {
                  set(
                    finalValues,
                    "MTACMemberSettingsCode",
                    `${selectedValue.memberCode} ${selectedValue.shopName} ${selectedValue.city} ${selectedValue.state}`
                  );
                  set(finalValues, "inputMemberCode", selectedValue.memberCode);
                  setValues(finalValues);

                  // Closing sidecar when we are searching with different member code
                  dispatch(setSideCar(""));
                }}
              />
              {showGroupsLoading === "done" && (
                <>
                  <View
                    style={tw(
                      "flex flex-row justify-between items-center mb-2 mt-4"
                    )}
                  >
                    <Text style={fonts.heading5}>
                      Search Results for : {inputMemberCode}
                    </Text>
                    {hasExportAccess && (
                      <View style={[tw("flex flex-row")]}>
                        {showSingleShopExportLoading && (
                          <ActivityIndicator
                            color={colors.activityIndicator}
                            style={tw("mr-2")}
                          />
                        )}
                        <TouchableOpacity
                          onPress={() => {
                            exportShops(inputMemberCode, setFieldValue);
                          }}
                          testID="export_current_shop_group"
                          accessibilityLabel="export_current_shop_group"
                        >
                          <Image
                            style={{
                              width: 25,
                              height: 25,
                              marginRight: 20,
                            }}
                            source={IMAGES[`export`]}
                          />
                        </TouchableOpacity>
                      </View>
                    )}
                  </View>
                  <AccordionGroups
                    hasEditAccess={hasEditAccess}
                    hasDataMigrationAccess={hasDataMigrationAccess}
                    hasShopAccess={hasShopAccess}
                  />
                </>
              )}
            </View>
          </View>
        );
      }}
    />
  );
};

export default ManageGroups;
