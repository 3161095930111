import moment from "moment";

const fetchOrders = (
  listingType,
  ordersInfo = {},
  statusList = {},
  initData,
  fetchData,
  callBack = () => {}
) => {
  const { set1 = { status: [] }, set2 = {} } = statusList;
  const { timeStamp, actions = {}, originalData = [] } = ordersInfo;

  const startDate =
    set1?.start === "1w"
      ? moment().subtract(1, "week").format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");

  const endDate =
    set1?.end === "1d"
      ? moment().subtract(1, "days").format("YYYY-MM-DD")
      : undefined;

  const originalDataLocal = timeStamp ? originalData : [];
  const actionsLocal = timeStamp ? actions : {};

  set1?.status?.length > 0 &&
    fetchData({
      params: {
        startDate,
        endDate,
        fetchStatuses: set1?.status?.join(","),
        memberCodes: undefined,
        deltaOrders: !!timeStamp,
        lastSyncTime: timeStamp || "",
        set2,
      },
      actions: actionsLocal,
      originalData: originalDataLocal,
      timeStamp,
      initData,
      listingType,
      resolve: () => {
        callBack && callBack();
      },
    });
};

export default fetchOrders;
