export const fetchStatuses = [
  "ACKNOWLEDGED",
  "PRINTED",
  "DESIGN",
  "DESIGNED",
  "OUT_FOR_DELIVERY",
  "ACKNOWLEDGE_PRINT",
  "CANCELLED",
  "DELIVERED",
];
