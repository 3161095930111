import React from "react";
import { Text } from "react-native-elements";
import { View, Image } from "react-native";
import IMAGES from "static/assets/images";
import { colors } from "styles/theme";
import tw from "tailwind-rn";
import { getConfig, getDisplayEligible } from "./helper";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

const DSRequestBreadcrumb = ({ dsRequestDetails = {} }) => {
  const { messages: locMessages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;

  const { state: DSRequestState, notes = [] } = dsRequestDetails;

  const config = getConfig(DSRequestState.toLowerCase(), isSmallScreen, notes);

  return (
    <View
      style={{
        ...tw("flex flex-row items-center justify-between"),
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
        borderLeftWidth: 0,
      }}
    >
      {Object.keys(config).map((item, index) => {
        const {
          displayStatus,
          iconExists,
          iconName,
          styleConfig = {},
        } = config[item] || {};

        const eligibleStatuses = getDisplayEligible(dsRequestDetails) || [];
        const isTileActive = eligibleStatuses.includes(item);

        return (
          <View
            key={index}
            style={{
              ...tw("items-center flex-row px-3"),
              ...(isSmallScreen && tw("flex-col justify-center p-1")),
              width: "25%",
              height: 50,
              borderBottomWidth: 5,
              borderBottomColor: styleConfig.stripColor,
              backgroundColor: styleConfig.blockBgColor,
              borderLeftColor: "#c4c4c4",
              borderLeftWidth: 1,
              opacity: isTileActive ? 1 : 0.2,
              flexWrap: isSmallScreen ? "nowrap" : "wrap",
            }}
          >
            {iconExists && IMAGES[iconName] && (
              <View style={{ minWidth: 30 }}>
                <Image
                  style={{
                    width: styleConfig.iconWidth,
                    height: styleConfig.iconHeight,
                  }}
                  source={IMAGES[iconName]}
                />
              </View>
            )}
            <Text
              style={{
                fontSize: styleConfig.labelSize,
                color: styleConfig.labelColor,
                fontWeight: "700",
                paddingLeft: isSmallScreen ? 0 : 5,
                textAlign: "center",
              }}
            >
              {Localise(locMessages, displayStatus)}
            </Text>
          </View>
        );
      })}
    </View>
  );
};

export default DSRequestBreadcrumb;
