import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { View, Platform } from "react-native";
import { Text, Badge, Image } from "react-native-elements";
import isEmpty from "lodash/isEmpty";
import I18NContext from "library/contexts/i18N";
import { PopupMenu, Tooltip } from "components/elements";
import { DeviceContext } from "library/contexts/appSettings";

import IMAGES from "static/assets/images";
import tw from "tailwind-rn";

const Sort = ({
  icon,
  iconSize = 18,
  text,
  options,
  selectors: { selectValue },
  actions: { setAction },
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const option = useSelector(selectValue);
  const { isDesktop } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const { Localise, messages } = React.useContext(I18NContext);

  const onSort = (entry) => {
    const { key, label } = options.find((e) => e.key === entry);
    dispatch(setAction({ type: "sort", value: { label, value: key } }));
  };

  return (
    <PopupMenu
      menuOptions={options}
      optionsContainerStyle={{
        width: 215,
        padding: 5,
      }}
      onSelect={onSort}
      selectedValue={option?.value}
      testID="sort"
      accessibilityLabel="sort"
      {...otherProps}>
      <View
        style={tw(
          `${
            isSmallScreen ? "flex-col" : "flex-row"
          } justify-around items-center`,
        )}>
        <Tooltip text={Localise(messages, "Sort")} renderForWebOnly = {true}>
          <Image
            style={{
              width: iconSize,
              height: iconSize,
            }}
            resizeMode="cover"
            source={IMAGES[icon]}
          />
        </Tooltip>
        {!isSmallScreen && <Text style={{ paddingLeft: 2 }}>{text}</Text>}
        {!isEmpty(option) && (
          <Badge
            containerStyle={[
              {
                right: !isSmallScreen ? -18 : -10,
                ...tw("mb-4"),
                position: "absolute",
              },
              Platform.OS !== "web" && { top: -10 },
            ]}
            badgeStyle={{ backgroundColor: "#e5175e" }}
            value={1}
          />
        )}
      </View>
    </PopupMenu>
  );
};

export default Sort;
