import moment from "moment";

export const getDeliveryTimeMinAndMaxTime = ({
  isStorePickupOrder = false,
  deliveryDate,
}) => {
  const minTime = isStorePickupOrder
    ? moment(deliveryDate).isSame(moment(), "day")
      ? new Date()
      : new Date(deliveryDate).setHours(0, 0)
    : deliveryDate && moment(deliveryDate).isSame(moment(), "day")
    ? moment(roundUpTime(30 * 60 * 1000, new Date())).toDate()
    : deliveryDate && !moment(deliveryDate).isSame(moment(), "day")
    ? moment().startOf("day").toDate()
    : new Date();
  const maxTime = isStorePickupOrder
    ? new Date(deliveryDate).setHours(23, 59)
    : deliveryDate
    ? moment().endOf("day").toDate()
    : new Date();

  return { minTime, maxTime };
};

// rounding time to nearest 15min to show it in pickup time drop down
export const roundUpTime = (intervalMilliseconds, datetime) => {
  datetime = datetime || new Date();
  var modTicks = datetime.getTime() % intervalMilliseconds;
  var delta = modTicks === 0 ? 0 : datetime.getTime() - modTicks;
  delta += intervalMilliseconds;
  return new Date(delta);
};

export const frequency = [
  {
    label: "1 week",
    value: "ONE_WEEK",
  },
  {
    label: "2 weeks",
    value: "TWO_WEEK",
  },
  {
    label: "3 weeks",
    value: "THREE_WEEK",
  },
  {
    label: "1 month",
    value: "ONE_MONTH",
  },
  {
    label: "1 year",
    value: "ONE_YEAR",
  },
];

export const subscriptionEnds = [
  {
    label: "3 months",
    value: "THREE_MONTH",
  },
  {
    label: "6 months",
    value: "SIX_MONTH",
  },
  {
    label: "1 year",
    value: "ONE_YEAR",
  },
  {
    label: "2 years",
    value: "TWO_YEAR",
  },
  {
    label: "Custom Date",
    value: "CUSTOM_DATE",
  },
];
