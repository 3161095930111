import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text } from "react-native";
import { Spinner } from "components/elements";
import { SecondaryButton, FilterResults } from "components/wrappers";
import { Widget, SortPicker } from "../helper";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import {
  setViewMore,
  setAction,
} from "library/sagas/views/home/drawer/product-catalog/dashboard/slice";
import {
  selectPageData,
  selectCollectionSortValue,
  selectCollectionLimit,
  selectDashboardFilters,
} from "library/sagas/views/home/drawer/product-catalog/dashboard/selector";
import Filters from "components/containers/listing-new/header/controls/filters";
import { CollectionPageFilters } from "../ui-config";
import { fonts } from "styles/theme";
import tw from "tailwind-rn";

const Collections = () => {
  const dispatch = useDispatch();
  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile, isDesktop } = React.useContext(DeviceContext);

  const {
    collections: { error, data, status },
  } = useSelector(selectPageData);

  const currentLimit = useSelector(selectCollectionLimit);

  const baseLimit = isMobile ? 4 : 12;
  const showViewMore = currentLimit < data.length;

  useEffect(() => {
    if (currentLimit === 0) dispatch(setViewMore(baseLimit));
  }, [currentLimit]);

  const collectionsSlice = data
    .filter((collection) => collection.handle !== "addons")
    .slice(0, currentLimit);

  const displayCollections = collectionsSlice
    .concat(Array.from(Array(baseLimit)).map(() => ({})))
    .slice(0, currentLimit);

  const filterProps = {
    width: "5%",
    icon: "filter",
    options: {
      "Filter By": CollectionPageFilters(Localise, messages),
    },
    selectors: {
      selectValue: selectDashboardFilters,
    },
    actions: {
      setAction: setAction,
    },
  };

  return (
    <View style={tw("flex flex-col my-3")}>
      <View style={tw("flex flex-row justify-between items-center pb-3")}>
        <Text style={fonts.heading3}>
          {Localise(messages, "Collections")} ({data.length})
        </Text>

        <View style={tw(`flex flex-row items-center`)}>
          <View style={tw("flex flex-row mr-5 items-center")}>
            {isDesktop ? (
              <FilterResults
                input={selectDashboardFilters}
                action={setAction}
              />
            ) : null}
            <Filters {...filterProps} />
          </View>
          <SortPicker
            type={"collection"}
            selectSort={selectCollectionSortValue}
            setSort={({ value }) =>
              setAction({ type: "collectionSortBy", value })
            }
            style={{ width: 115 }}
          />
        </View>
      </View>
      {collectionsSlice.length == 0 ? (
        error ? (
          <Text style={{ ...fonts.error, textAlign: "left" }}>
            {Localise(messages, "Unable to pull collections at this moment")}
          </Text>
        ) : status === "progress" ? (
          <View style={{ minHeight: 150 }}>
            <Spinner size="large" />
          </View>
        ) : (
          <Text style={{ ...fonts.default, textAlign: "left" }}>
            {Localise(messages, "No matching collections found")}
          </Text>
        )
      ) : (
        <View style={tw("flex flex-row flex-wrap")}>
          {displayCollections.map((collection, index) => (
            <Widget
              key={index}
              style={{ marginRight: 6 }}
              type="collection"
              {...collection}
            />
          ))}
        </View>
      )}
      {showViewMore && (
        <View
          style={tw(`flex flex-row-reverse justify-between items-center py-4`)}
        >
          <SecondaryButton
            title={"View More"}
            action={() => dispatch(setViewMore(currentLimit))}
          />
        </View>
      )}
    </View>
  );
};

export default Collections;
