import actionTypes from "library/actions/userAuth";

const initState = {
  authToken: null,
  keepMeSignedIn: false,
  ssoUser: null,
};

const UserAuthReducer = (prevState = initState, action) => {
  //console.log("UserAuthReducer: action=", JSON.stringify(action));
  switch (action.type) {
    case actionTypes.SIGN_IN:
      return {
        ...prevState,
        authToken: action.token,
        keepMeSignedIn: true,
      };
    case actionTypes.SIGN_OUT:
      return {
        ...prevState,
        authToken: null,
        keepMeSignedIn: false,
      };
    case actionTypes.ENABLE_SSO_LOGIN:
      return {
        ...prevState,
        ssoUser: action.ssoUser,
      };
  }
};

export default UserAuthReducer;
