import React, { useContext } from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import { useSelector } from "react-redux";

import tw from "tailwind-rn";

import { LogoImage } from "components/elements";

import { DeviceContext } from "library/contexts/appSettings";
import {
  selectShopDetails,
  selectShopLogoData,
  selectShopPreferences,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import I18NContext from "library/contexts/i18N";
import { getContactInfoPlacements } from "../card-settings/helper";
import { fonts, colors } from "styles/theme";
import { isValidJSON } from "library/utils/formatter";

const EnclosureCard = ({ previewInfo, img, setError, removeError, error }) => {
  const shopDetails = useSelector(selectShopDetails);
  const shopLogoData = useSelector(selectShopLogoData);
  const shopSettings = useSelector(selectShopPreferences);
  const { isDesktop, isTablet } = useContext(DeviceContext);
  const { Localise, messages } = useContext(I18NContext);
  const {
    logoInfo: { uri: logoImageURL = "" } = {},
    bannerInfo: { uri: bannerImageURL = "" } = {},
  } = shopLogoData || {};

  let floristShopContactSettings =
    shopSettings["florist_contact_information_settings"];
  if (
    (!isValidJSON(floristShopContactSettings) &&
      floristShopContactSettings === "JDE") ||
    floristShopContactSettings == ""
  ) {
    floristShopContactSettings =
      '{"shopName":"","streetAddress":"","aptSuite":"","city":"","state":"","zip":"","phone":"","email":"","website":"","additionalMessage":"","contactName":"","printed":[],"web":[],"network":[]}';
  }
  const recipientAddress = {
    name: "Test User",
    street: "Address Line1",
    phone: "800-669-1000",
    city: "New York",
    state: "NY",
    zip: "60515",
  };
  const senderMessage = Localise(
    messages,
    "Sender message appears here if saved to order"
  );
  const {
    name: recName,
    phone: recPhone,
    street,
    city,
    state,
    zip,
  } = recipientAddress;
  const {
    logoOrientation,
    bannerOrientation,
    floristContactInfo,
    orderInfo,
    floristMessage,
    floristWebsite,
    logoPosition = logoOrientation ? logoOrientation : "Top Left",
    logoPlacement = bannerOrientation,
    contactInfoTopHalf: section3PlacementSelection = "Florist Info",
  } = previewInfo;

  const { contactInfoTopHalf, contactInfoBottomHalf } =
    getContactInfoPlacements(section3PlacementSelection);

  let {
    businessName: name,
    websites = [],
    email = "",
    phone = "",
    address: {
      addressLine1,
      city: floristCity,
      state: floristState,
      zip: floristZip,
    } = {},
  } = shopDetails;

  let isWebsiteOverridden = false;
  let {
    shopName: shopNameContact = "",
    streetAddress: streetAddressContact = "",
    aptSuite: aptSuiteContact = "",
    city: cityContact = "",
    state: stateContact = "",
    zip: zipContact = "",
    phone: phoneContact = "",
    email: emailContact = "",
    website: websiteContact = "",
    printed = [],
  } = JSON.parse(floristShopContactSettings);

  if (printed.includes("shopName")) {
    name = shopNameContact;
  }
  if (printed.includes("streetAddress")) {
    addressLine1 = streetAddressContact;
    floristCity = cityContact;
    floristState = stateContact;
    floristZip = zipContact;
  } else {
    aptSuiteContact = "";
  }
  if (printed.includes("phone")) {
    phone = phoneContact;
  }
  if (printed.includes("email")) {
    email = emailContact;
  }
  if (printed.includes("website")) {
    isWebsiteOverridden = true;
  }

  let imageURL = ["Top", "Bottom"].includes(logoPlacement)
    ? bannerImageURL
    : logoImageURL;
  const isFloristInfoTop = contactInfoTopHalf === "Florist Info";

  // Order For this option is Recipient Info top & Delivery Info bottom
  const hasDeliveryInfo = contactInfoBottomHalf === "Delivery Info";

  const placements = logoPosition.split(" ");
  let isBottom = placements[0] === "Bottom";
  const isRight = placements[1] === "Right";
  const isLogo = placements?.length == 2;
  if (["Bottom"].includes(logoPlacement)) {
    isBottom = true;
  }
  if (["None"].includes(logoPlacement) && ["None"].includes(logoPosition)) {
    imageURL = "";
  }
  const RecipientInfo = () => {
    return (
      <View
        style={[
          tw("p-2 items-center justify-center"),
          {
            borderWidth: 1,
            borderColor: colors.light,
            minHeight: 120,
            width: "80%",
          },
        ]}
      >
        <Text style={[fonts.heading3, tw("mb-2")]}>{recName}</Text>
        <Text style={tw("mb-2")} fsClass="fs-exclude">
          {recPhone}
        </Text>
        <Text style={tw("mb-2")}>{street}</Text>
        <Text>{`${city}, ${state}, ${zip}`}</Text>
      </View>
    );
  };

  const DeliveryInfo = () => {
    const orderDetails = [
      { type: "Delivery Date", label: "Delivery Date:", value: "xx/xx/xxxx" },
      { type: "Delivery Zone", label: "Delivery Zone:", value: "xxxxx" },
      { type: "Delivery Time", label: "Delivery Time:", value: "xx:xx" },
      { type: "Order No", label: "Order No:", value: "xxxxxxxxxxx" },
    ];
    return (
      <View
        style={[
          tw("p-2 items-center justify-center flex-row"),
          {
            borderWidth: 1,
            borderColor: colors.light,
            minHeight: 120,
            width: "80%",
          },
        ]}
      >
        <View style={tw("flex flex-row flex-wrap w-full")}>
          {orderDetails.map((detail) =>
            orderInfo.includes(detail.type) ? (
              <View key={detail.type} style={tw("flex flex-row mb-1 w-6/12")}>
                <Text style={{ fontWeight: "bold" }}>{detail.label}:</Text>
                <Text>{detail.value}</Text>
              </View>
            ) : null
          )}
        </View>
      </View>
    );
  };

  return (
    <View
      style={{ ...tw("items-center"), ...(isTablet && { padding: 10 }) }}
      fsClass="fs-unmask"
    >
      <View style={{ borderColor: colors.light, borderWidth: 1, width: 390 }}>
        <View
          style={[tw("p-4 justify-center"), { minHeight: 300 }]}
          onLayout={(event) => {
            var { height } = event.nativeEvent.layout;
            if (height > 303) setError("section1");
          }}
        >
          {!isBottom && !!imageURL && (
            <LogoImage
              img={imageURL}
              isRight={isRight}
              isLogo={isLogo}
              isSmallScreen={!isDesktop}
              logoPlacement={logoPlacement}
            />
          )}
          <View style={tw("items-center my-5 p-5 justify-center")}>
            <Text style={{ textAlign: "center" }}>{senderMessage}</Text>
          </View>
          {isBottom && (
            <LogoImage
              img={imageURL}
              isRight={isRight}
              isLogo={isLogo}
              isSmallScreen={!isDesktop}
              logoPlacement={logoPlacement}
            />
          )}
        </View>

        <View
          style={[
            tw("p-2 items-center"),
            {
              borderTopWidth: 1,
              borderTopColor: colors.light,
              minHeight: 300,
            },
          ]}
          onLayout={(event) => {
            var { height } = event.nativeEvent.layout;
            height > 303
              ? !error.keys.includes("section2") && setError("section2")
              : removeError("section2");
          }}
        >
          <Text style={{ textAlign: "center" }}>{floristMessage}</Text>
        </View>

        <View
          style={[
            tw("p-2 items-center"),
            {
              borderTopWidth: 1,
              borderTopColor: colors.light,
              minHeight: 300,
            },
          ]}
          onLayout={(event) => {
            var { height } = event.nativeEvent.layout;
            height > 330
              ? !error.keys.includes("section3") && setError("section3")
              : removeError("section3");
          }}
        >
          {!isFloristInfoTop && <RecipientInfo />}
          {!!floristContactInfo?.length && !hasDeliveryInfo && (
            <View
              style={[
                tw("p-2 m-2 items-center justify-center"),
                {
                  borderWidth: 1,
                  borderColor: colors.light,
                  minHeight: 120,
                  width: "80%",
                },
              ]}
            >
              {floristContactInfo.includes("Florist Name") && (
                <Text style={[fonts.heading3, tw("mb-2")]}>{name}</Text>
              )}
              {floristContactInfo.includes("Florist Website") && (
                <Text style={tw("mb-2")}>
                  {isWebsiteOverridden
                    ? websiteContact
                    : floristWebsite || websites[0] || ""}
                </Text>
              )}
              {floristContactInfo.includes("Florist Email") && (
                <Text style={tw("mb-2")}>{email}</Text>
              )}
              {floristContactInfo.includes("Florist Phone") && (
                <Text style={tw("mb-2")} fsClass="fs-exclude">
                  {phone}
                </Text>
              )}
              {floristContactInfo.includes("Florist Address") && (
                <>
                  <Text style={tw("mb-2")}>
                    {`${addressLine1}`}
                    {aptSuiteContact != "" && (
                      <Text
                        style={[{ textAlign: "left" }]}
                      >{` ${aptSuiteContact}`}</Text>
                    )}
                  </Text>
                  <Text>{` ${floristCity}, ${floristState}, ${floristZip}`}</Text>
                </>
              )}
            </View>
          )}
          {hasDeliveryInfo && <DeliveryInfo />}
          {isFloristInfoTop && <RecipientInfo />}
        </View>
      </View>
    </View>
  );
};

export default EnclosureCard;
