/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useContext } from "react";
import { View, Image, TouchableOpacity } from "react-native";
import { Text } from "react-native-elements";

import { theme, backgroundColors, fonts, colors } from "styles/theme";
import IMAGES from "static/assets/images";
import tw from "tailwind-rn";
import * as Yup from "yup";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";

import { ToasterHandler } from "components/elements";
import { Form, FormFieldPicker, SubmitButton } from "components/elements/forms";
import { startNavigation } from "components/views/drawer/order-details/helper";
import { adhocStopActions } from "./config";

const RouteAdhocStopDetails = ({
  shopCode,
  recordId: stopId = "",
  selectedRecordId: routeId = "",
  stopData = {},
  onAction,
  onClose,
  isSmallScreen,
}) => {
  if (!stopId) return;
  const {
    stopName = "",
    stopDescription = "",
    status = "",
    recipientAddressLine = "",
    recipientCityOnly = "",
    recipientCountry = "",
    recipientState = "",
    recipientLocation: { latitude = "", longitude = "" } = {},
  } = stopData;

  const [onChangeValidation, setOnChangeValidation] = useStateIfMounted(false);
  const { messages, Localise } = useContext(I18NContext);
  const { isMobile } = useContext(DeviceContext);
  const stopStatusUpdateFormRef = useRef();
  const submitButtonRef = useRef();
  const disableForm = ["COMPLETED", "INCOMPLETE"].includes(status);

  const validationSchema = Yup.object().shape({
    stopStatus: Yup.string().required(
      Localise(messages, "Please select status")
    ),
  });

  useEffect(() => {
    submitButtonRef.current.disableButton(disableForm);
  }, [disableForm]);

  const formSubmitTrigger = (values, formikBag) => {
    const { stopStatus = "" } = values;
    request("update-route-stop-status", {
      shopCode,
      stopId,
      routeId,
      body: {
        status: stopStatus,
      },
    })
      .then((res) => {
        onAction(
          stopStatus === "COMPLETED"
            ? "delivery-confirmation"
            : "delivery-exception"
        );
      })
      .catch((error) => {
        let errorMessage = "Something went wrong. Please try again!";
        if (
          !["ERROR", "CATCH ALL ERROR", "null"].includes(error) &&
          error.length > 0
        ) {
          errorMessage = error;
        }
        ToasterHandler("uh oh", Localise(messages, errorMessage));
      })
      .finally(() => {
        formikBag.setSubmitting(false);
      });
  };

  return (
    <View
      style={{
        backgroundColor: backgroundColors.sideCar,
      }}
    >
      <View
        style={[
          tw(`px-3`),
          {
            backgroundColor: colors.secondary,
            borderWidth: 1,
            borderColor: colors.grayScaleLight,
            borderRadius: 5,
            padding: isMobile ? 10 : 15,
          },
        ]}
      >
        <View style={[tw(`flex flex-row justify-between`)]}>
          <View
            style={[
              {
                backgroundColor: backgroundColors.secondary,
                minHeight: 30,
                width: "90%",
              },
            ]}
          >
            <Text
              numberOfLines={1}
              style={{
                ...fonts.heading4,
                color: colors.highlighter,
                ...(isMobile && {
                  fontSize: 15,
                }),
              }}
            >
              {Localise(messages, "Stop")} - {stopName}
            </Text>
            <Text
              numberOfLines={1}
              style={{
                ...tw("py-1"),
                color: colors.highlighter,
                ...(isMobile && {
                  fontSize: 15,
                }),
              }}
            >
              {stopDescription}
            </Text>
          </View>
          <View>
            <TouchableOpacity
              onPress={() => {
                onClose();
              }}
              testID="close"
            >
              <Image
                style={{ width: 20, height: 20 }}
                resizeMode="cover"
                source={IMAGES["close"]}
              />
            </TouchableOpacity>
          </View>
        </View>

        <View style={[tw("flex flex-row items-center")]}>
          {adhocStopActions.map((action) => {
            return (
              <View
                key={action.key}
                style={{
                  ...tw("flex-row flex-wrap"),
                  ...theme.Button.secondaryButtonStyle,
                  ...(isSmallScreen && { marginBottom: 7 }),
                  ...tw("mr-1 mt-1"),
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  width: 130,
                }}
              >
                <Text
                  style={{
                    ...theme.Button.secondaryTitleStyle,
                    color: colors.highlighter,
                    fontSize: 13,
                  }}
                  testID={action.key}
                  accessibilityLabel={action.key}
                  onPress={() => {
                    if (action.key === "navigation") {
                      startNavigation(
                        {
                          addressLine1: recipientAddressLine,
                          city: recipientCityOnly,
                          country: recipientCountry,
                          state: recipientState,
                          latitude,
                          longitude,
                        },
                        messages,
                        Localise
                      );
                    }
                  }}
                >
                  {Localise(messages, action.label)}
                </Text>
              </View>
            );
          })}
        </View>
      </View>

      <View
        style={{
          backgroundColor: backgroundColors.secondary,
          borderColor: colors.grayScaleLight,
          borderWidth: 1,
          borderRadius: 5,
          marginVertical: 10,
          padding: 20,
        }}
      >
        <Form
          initialValues={{
            stopStatus: status !== "NEW" ? status : "COMPLETED",
          }}
          innerRef={stopStatusUpdateFormRef}
          validationSchema={validationSchema}
          validateOnChange={onChangeValidation}
          validateOnBlur={onChangeValidation}
          onSubmit={(values, formikBag) => formSubmitTrigger(values, formikBag)}
          render={({ submitCount }) => {
            //eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              if (submitCount > 0) {
                setOnChangeValidation(true);
              }
            }, [submitCount]);

            return (
              <View style={tw("flex flex-col")}>
                <View style={tw("flex flex-row my-1")}>
                  <FormFieldPicker
                    containerStyle={{
                      width: "100%",
                    }}
                    placeHolderExists={false}
                    data={[
                      {
                        label: "Completed",
                        value: "COMPLETED",
                      },
                      {
                        label: "Not Completed",
                        value: "INCOMPLETE",
                      },
                    ]}
                    fsClass="fs-exclude"
                    name="stopStatus"
                    disabled={disableForm}
                  />
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <SubmitButton
                    containerStyle={{
                      flexDirection: "row-reverse",
                      margin: 5,
                    }}
                    title={"Submit"}
                    refFromProp={submitButtonRef}
                  />
                </View>
              </View>
            );
          }}
        />
      </View>
    </View>
  );
};

export default React.memo(RouteAdhocStopDetails);
