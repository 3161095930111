import { request } from "library/utils/request";
import UserAuthStorage from "library/storage/userAuth";
import UserProfileStorage from "library/storage/userProfile";
import DeviceInfo from "react-native-device-info";
import { Platform } from "react-native";
import * as Keychain from "react-native-keychain";
import * as LocalAuthentication from "expo-local-authentication";
import EnvironmentStorage from "library/storage/environment";
import Environment from "library/utils/environment";
import PageStorage from "library/storage/pageStorage";

let fcmService;

if (Platform.OS !== "web") {
  fcmService = require("library/utils/PushNotifications/FCMService").fcmService;
}

const getDeviceType = () => {
  let deviceType = Platform.OS;

  const isElectronApp =
    deviceType === "web" &&
    document.getElementById("isElectronApp").value === "true";

  if (isElectronApp) deviceType = "desktop";

  return deviceType;
};

const logIn = async (data) => {
  return request("login", {
    ...data,
    deviceType: getDeviceType(),
    mobileTokenId: Platform.OS === "web" ? "" : await getDeviceToken(),
  }).then((res) => {
    try {
      if (Platform.OS === "web") {
        const isSSOEnabled = Environment.get("ENABLE_SSO_LOGIN", false);

        if (isSSOEnabled) {
          if ("loginType" in res) {
            //For SSO login
            const validateSSO = res.loginType;
            if (validateSSO === "SSO") {
              request("oneLogin");
            } else {
              UserAuthStorage.setSSOUser(validateSSO);
            }
          } else {
            //For Manual password login
            const { access_token: accessToken, refresh_token: refreshToken } =
              res;
            UserAuthStorage.setAuthToken({
              accessToken,
              refreshToken,
              keepMeSignedIn: false,
            });
          }
        } else {
          const { access_token: accessToken, refresh_token: refreshToken } =
            res;
          UserAuthStorage.setAuthToken({
            accessToken,
            refreshToken,
            keepMeSignedIn: data.keepMeSignedIn,
          });
        }
      } else {
        const { access_token: accessToken, refresh_token: refreshToken } = res;
        UserAuthStorage.setAuthToken({
          accessToken,
          refreshToken,
          keepMeSignedIn: false,
        });
      }
      // Saving logged in username & password in mobile device keychain, we will use them to perform silent authentication when user fingerprint is authenticated.
      if (Platform.OS !== "web") {
        saveUserCredentialsInKeychain(data);
      }

      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject("Invalid token response", JSON.stringify(err));
    }
  });
};

const logOut = () => {
  return request("logout")
    .then(() => {
      UserProfileStorage.clear();
      UserAuthStorage.clear();
      PageStorage.clear();
    })
    .catch(() => {
      console.log("logout failed but still clearing storage");
      UserProfileStorage.clear();
      UserAuthStorage.clear();
      PageStorage.clear();
    })
    .finally(() => {
      if (
        Platform.OS === "web" &&
        document.getElementById("isElectronApp").value === "true"
      ) {
        document.getElementById("blnOrderPrefs").value = JSON.stringify({
          bloomlink_enabled: "false",
          bln_enabled_member_code: "",
          accessToken: "",
          request_context: "",
        });
      }
    });
};

const saveUserCredentialsInKeychain = async (data) => {
  const username = data.userId;
  const password = data.password;
  const appPackageName = DeviceInfo.getBundleId();

  const hasBiometric = await LocalAuthentication.hasHardwareAsync();
  const isBiometricEnrolled = await LocalAuthentication.isEnrolledAsync();

  if (hasBiometric && isBiometricEnrolled) {
    try {
      await Keychain.setInternetCredentials(
        appPackageName,
        username,
        password,
        {
          accessControl: Keychain.ACCESS_CONTROL.BIOMETRY_ANY,
          accessible: Keychain.ACCESSIBLE.WHEN_UNLOCKED,
          //accessGroup: appPackageName,
          securityLevel: Keychain.SECURITY_LEVEL.SECURE_HARDWARE,
        }
      );
    } catch (error) {
      console.log(
        "Saving credentials in device keychain failed bcoz of " + error.message
      );
    }
  }
};

const getDeviceToken = async () => {
  let deviceToken = EnvironmentStorage.getFCMDeviceToken();

  // if device token is not generated when app is opened due to any reason then we are trying one more time to get it from FCM while user is log in into app.
  if (deviceToken === null || deviceToken === "") {
    deviceToken = await fcmService.getDeviceToken();

    EnvironmentStorage.setFCMDeviceToken(deviceToken);
  }

  return deviceToken;
};

export default {
  logIn,
  logOut,
  getDeviceType,
};
