import React, { useContext } from "react";
import { Platform, View, Linking, ScrollView } from "react-native";
import { Text } from "react-native-elements";
import { CustomModal, HoverableOpacity } from "components/elements";
import { DeviceContext } from "library/contexts/appSettings";
import { fonts, backgroundColors, colors } from "styles/theme";
import tw from "tailwind-rn";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { PrivacyPolicyContent } from "components/views/login/privacy-policy";
import I18NContext from "library/contexts/i18N";

export const TermsAndConditionsLink = () => {
  const { isDesktop } = React.useContext(DeviceContext);
  const [showModal, setModalVisibile] = useStateIfMounted(false);
  const [showPrivacyModal, setPrivacyModalVisibile] = useStateIfMounted(false);
  const { messages, Localise } = React.useContext(I18NContext);
  return (
    <>
      {isDesktop ? (
        <Text
          style={{
            ...fonts.link1,
            color: "#414141",
            fontWeight: "400",
            fontSize: 14,
            textDecorationLine: "none",
          }}
        >
          Mercury HQ{" "}
          <HoverableOpacity
            onPress={() => window.open("/terms")}
            hoverStyle={{ textDecorationLine: "underline" }}
          >
            <Text
              style={{
                cursor: "pointer",
                color: colors.primary2,
                fontWeight: "400",
                fontSize: 14,
              }}
            >
              {Localise(messages, "Terms & Conditions")}
            </Text>
          </HoverableOpacity>{" "}
          {Localise(messages, "and")}{" "}
          <HoverableOpacity
            onPress={() => window.open("/privacy")}
            hoverStyle={{ textDecorationLine: "underline" }}
          >
            <Text
              style={{
                cursor: "pointer",
                color: colors.primary2,
                fontWeight: "400",
                fontSize: 14,
              }}
            >
              {Localise(messages, "Privacy Policy")}
            </Text>
          </HoverableOpacity>
        </Text>
      ) : (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              ...fonts.link1,
              fontWeight: "400",
              fontSize: 11,
            }}
            onPress={() =>
              Platform.OS === "web"
                ? window.open("/terms")
                : setModalVisibile(true)
            }
          >
            {Localise(messages, "Terms & Conditions")}
          </Text>
          <Text
            style={{
              color: "#414141",
              fontWeight: "400",
              fontSize: 11,
              textDecorationLine: "none",
            }}
          >
            {" "}
            {Localise(messages, "and")}{" "}
          </Text>
          <Text
            style={{
              ...fonts.link1,
              fontSize: 11,
              fontWeight: "400",
            }}
            onPress={() =>
              Platform.OS === "web"
                ? window.open("/privacy")
                : setPrivacyModalVisibile(true)
            }
          >
            {Localise(messages, "Privacy Policy")}
          </Text>
        </View>
      )}

      {showModal && (
        <TermsandConditionsModal
          showModal={showModal}
          onClose={() => setModalVisibile(false)}
        />
      )}

      {showPrivacyModal && (
        <PrivacyPolicyModal
          showPrivacyModal={showPrivacyModal}
          onClose={() => setPrivacyModalVisibile(false)}
        />
      )}
    </>
  );
};

export const TermsandConditionsModal = ({
  showModal,
  onClose,
  setModalVisibile,
}) => {
  const modalContent = {
    content: <TermsandConditionsContent setModalVisibile={setModalVisibile} />,
    buttons: [
      { type: "primary", title: "I Accept" },
      { type: "primary", title: "I Do Not Accept" },
    ],
  };

  return (
    <CustomModal
      modalVisible={showModal}
      modalContent={modalContent}
      primaryhandler={onClose}
      contentStyle={[
        tw("border border-black"),
        {
          backgroundColor: "white",
          height: "60%",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        },
      ]}
      modalStyle={{
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        paddingHorizontal: 20,
        backgroundColor: "#00000070",
        color: "#FFFFFF",
      }}
    ></CustomModal>
  );
};

export const PrivacyPolicyModal = ({ showPrivacyModal, onClose }) => {
  const modalContent = {
    content: <PrivacyPolicyContent />,
    buttons: [{ type: "primary", title: "OK" }],
  };

  return (
    <CustomModal
      modalVisible={showPrivacyModal}
      modalContent={modalContent}
      primaryhandler={onClose}
      contentStyle={[
        tw("border border-black"),
        { backgroundColor: "white", height: "60%", width: "100%" },
      ]}
      modalStyle={{
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        paddingHorizontal: 20,
        backgroundColor: "#00000070",
        color: "#FFFFFF",
      }}
    ></CustomModal>
  );
};

export const TermsandConditionsContent = () => {
  const { isDesktop } = useContext(DeviceContext);
  return (
    <>
      <View
        style={{
          ...tw("w-full p-3 mb-2"),
          backgroundColor: backgroundColors.primary,
        }}
        fsClass="fs-unmask"
      >
        <Text style={{ ...fonts.heading3, ...tw("text-white text-center") }}>
          MERCURY HQ TERMS AND CONDITIONS OF USE
        </Text>
      </View>
      <ScrollView
        style={{ paddingHorizontal: !isDesktop ? 10 : 0 }}
        fsClass="fs-unmask"
      >
        <View style={tw("py-1")}>
          <Text style={tw("p-2 text-justify")}>
            PLEASE CAREFULLY READ THE FOLLOWING TERMS AND CONDITIONS OF USE
            (“TERMS AND CONDITIONS OF USE”) FOR THE MERCURY HQ SYSTEM
            (“SYSTEM”). BY CLICKING THE I ACCEPT BUTTON BELOW OR USING THE
            SYSTEM YOU ARE AGREEING TO ACCEPT THE TERMS AND CONDITIONS OF USE.
            IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS OF USE, CLICK ON THE
            I DO NOT ACCEPT BUTTON BELOW AND DO NOT USE THE SYSTEM. {"\n\n"}
            THE TERMS AND CONDITIONS OF USE ARE SUBJECT TO REVISION BY FTD, LLC
            (“FTD”, “WE”, OR “US”) AND YOUR USE OF THE SYSTEM IS SUBJECT TO THE
            MOST CURRENT VERSION OF THE TERMS AND CONDITIONS OF USE POSTED ON
            THE SYSTEM AT THE TIME OF USE. YOU SHOULD REVIEW THE TERMS AND
            CONDITIONS OF USE FROM TIME TO TIME.
          </Text>
          <Text
            style={{ ...fonts.heading3, ...tw("p-2 "), color: colors.primary }}
          >
            1. ACCURACY OF YOUR REGISTRATION INFORMATION
          </Text>
          <Text style={tw("p-2 text-justify")}>
            You confirm that the registration information (“Registration
            Information”) you have provided to FTD is complete and accurate. If
            corrections or changes to your Registration Information are
            required, notify FTD before using the System.
          </Text>
          <Text
            style={{ ...fonts.heading3, ...tw("p-2"), color: colors.primary }}
          >
            2. YOUR RIGHT TO USE THE SYSTEM
          </Text>
          <Text style={tw("p-2 text-justify")}>
            The System is a service offered by FTD for the benefit of qualified
            florist customers (“Members”). Provided that you comply with the
            Terms and Conditions of Use, you may use the System to (a) view
            various materials, including all information, documents, and other
            content on the System (collectively, the “Materials”), and (b)
            subject to any other requirements that FTD may specify, access the
            services (“Services”) provided and products (“Products”) offered by
            FTD on the System. FTD reserves the right to restrict, suspend or
            terminate your access to all or part of the System, or to require
            you to immediately destroy any Materials you have downloaded,
            printed or otherwise received from or through the System, at any
            time for any reason, without prior notice or liability, and at FTD’s
            sole discretion.
          </Text>
          <Text
            style={{ ...fonts.heading3, ...tw("p-2"), color: colors.primary }}
          >
            3. LOGIN & PASSWORD
          </Text>
          <Text style={tw("p-2 text-justify")}>
            You have been issued with a link to set a password to access the
            System. You are entirely responsible for maintaining the
            confidentiality of your login credentials. You agree to notify FTD
            immediately of any unauthorized use of your account. Until you
            notify FTD, you are responsible for any and all activities that
            occur under your account. If FTD changes logins and/or passwords for
            security or other reasons, FTD will send an e-mail to the client
            contact specified in your Registration Information informing you of
            the new login and/or password.
          </Text>
          <Text
            style={{ ...fonts.heading3, ...tw("p-2"), color: colors.primary }}
          >
            4. FEES & CHARGES
          </Text>
          <Text style={tw("p-2 text-justify")}>
            Orders placed through the System are subject to various charges
            described on the Mercury HQ Confirmation and Authorization form. You
            agree to pay any such charges and any applicable taxes to FTD on the
            terms set out therein. FTD may at any time make changes to the
            charges or the payment terms upon not less than 30 days’ notice to
            you. You are responsible for all telecommunications charges and
            other charges made by your local communications company, including,
            without limitation, all charges incurred for installing and
            maintaining the local communication resources to the System. All
            dollar amounts payable by you to FTD will be in United States funds,
            unless otherwise specified by FTD.
          </Text>
          <Text
            style={{ ...fonts.heading3, ...tw("p-2"), color: colors.primary }}
          >
            5. SYSTEM INFORMATION
          </Text>
          <Text style={tw("p-2 text-justify")}>
            In addition to your Registration Information, FTD may request that
            you provide certain technical information in order to provide you
            with access to the System (“System Information”), including FTD
            directory code numbers, shop configurations, hardware or software
            components or configuration, or other information. System
            Information can only be changed during regularly scheduled network
            updates. You are responsible for promptly notifying FTD departments
            and personnel and other appropriate parties, as directed by FTD, of
            any changes or additions to System Information. You are solely
            responsible for notifying any other service provider you use of
            changes or additions to the technical information they require to
            establish communications with you through the System. FTD disclaims
            all responsibility for your failure to notify FTD or any other
            service provider of the changes or additions referred to herein.
          </Text>
          <Text
            style={{ ...fonts.heading3, ...tw("p-2"), color: colors.primary }}
          >
            6. SYSTEM SOFTWARE
          </Text>
          <Text style={tw("p-2 text-justify")}>
            FTD may periodically update the software used to operate the System,
            including the software you download to access the System. FTD does
            not make, and hereby disclaims any representations or warranties as
            to the compatibility of System software with third party services or
            software or the System's ability to communicate and operate in
            combination with such third party services and/or software.
          </Text>
          <Text
            style={{ ...fonts.heading3, ...tw("p-2"), color: colors.primary }}
          >
            7. OWNERSHIP OF MATERIALS ON THE SYSTEM
          </Text>
          <Text style={tw("p-2 text-justify")}>
            Materials on the System are owned by FTD and its affiliates. They
            are copyrighted and protected by United States, Canadian and
            international intellectual property laws, treaties and conventions.
            You may not copy, adapt, modify, display, perform, transmit, frame,
            distribute, resell, commercially exploit or otherwise use the
            Materials, except as expressly permitted herein, without the prior
            written permission of the relevant rights holder(s). You also may
            not create a hyperlink directly to a page on the System, other than
            the default, index or home page found at{" "}
            <Text
              style={{ ...fonts.link1, color: "#0077cc" }}
              onPress={() =>
                Platform.OS === "web"
                  ? window.open("https://mercuryhq.com")
                  : Linking.openURL("https://mercuryhq.com")
              }
            >
              https://mercuryhq.com
            </Text>{" "}
            without FTD’s prior written permission. The Mercury trademark and
            others used on the System are the property of FTD. You may not use
            any trademark displayed on the System without the written permission
            of FTD. Your use of the System is conditioned on your abiding by all
            notices of copyright, trademark and other proprietary rights
            appearing in the Materials. You are forbidden from removing or
            altering any such notices in full or in part from Materials that you
            print or download.
          </Text>
          <Text
            style={{ ...fonts.heading3, ...tw("p-2"), color: colors.primary }}
          >
            8. CONFIDENTIALITY OF INFORMATION / LICENSE TO UTILIZE INFORMATION
          </Text>
          <Text style={tw("p-2 text-justify")}>
            When you place an order using the System, the order information
            (“Order Information”) you provide will be confidential and be
            disclosed only to the Member that fills the order or service
            providers who facilitate operation of the System, subject to the
            remainder of this paragraph. FTD will not disclose Order Information
            to any other party unless required to do so by applicable law,
            regulation, legal process or compulsory governmental request. All
            information other than Order Information that you send to FTD,
            except as specified in the Privacy Statement located at{" "}
            <Text
              style={{ ...fonts.link1, color: "#0077cc" }}
              onPress={() =>
                Platform.OS === "web"
                  ? window.open("/privacy")
                  : Linking.openURL("https://mercuryhq.com/privacy")
              }
            >
              https://mercuryhq.com/privacy
            </Text>
            , will be considered non-proprietary and non-confidential, and FTD
            will be free to use such information on an unrestricted basis for
            any purposes. Except as specified in the Privacy Statement, you
            grant FTD an irrevocable license to utilize, modify and display all
            information provided to the System by you, including without
            limitation, Order Information, for the purposes of operating the
            System, providing improvements and enhancements to the System,
            conducting business planning and analytics, and sharing such
            information with third party providers for the foregoing purposes.
            You are prohibited from sending FTD or any other user of the System
            any unlawful, threatening, libelous, or defamatory communications,
            or any other communications that would violate any law or the rights
            of others under applicable law.
          </Text>
          <Text
            style={{ ...fonts.heading3, ...tw("p-2"), color: colors.primary }}
          >
            9. DISCLAIMER
          </Text>
          <Text style={tw("p-2 text-justify")}>
            Processing of orders placed using the System is subject to FTD’s
            then-current network availability and transmission priority
            schedules. FTD DISCLAIMS ALL LIABILITY FOR AND YOU AGREE THAT FTD
            SHALL NOT BE HELD LIABLE FOR FAILURE OF OR DELAY IN TRANSMISSION OF
            ORDERS OR LOSS OF ORDERS RESULTING FROM MALFUNCTIONS OF THE SYSTEM,
            DISRUPTION OF COMMON CARRIER LINES, LOSS OF POWER, NATURAL DISASTERS
            OR ANY AND ALL OTHER CAUSES BEYOND FTD’S CONTROL. EXCEPT AS
            OTHERWISE NOTED HEREIN, FTD PROVIDES THE MATERIALS AND SERVICES ON
            THIS SYSTEM AS IS, WITHOUT ANY WARRANTIES OR REPRESENTATIONS OF ANY
            KIND, INCLUDING WARRANTIES AND CONDITIONS OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT OF
            INTELLECTUAL PROPERTY. Applicable laws may not allow the exclusion
            of implied warranties, so the above exclusion may not apply to you.
            To the extent permissible, any implied warranties or representations
            are limited to the fullest extent permitted by law. FTD makes no
            warranties, representations, or conditions of any kind regarding the
            systems, services, products, programs or other offerings of Third
            Parties referenced on or accessible by means of the System. FTD also
            takes no responsibility for viruses that may damage your computer
            system or data resulting from your access to or use of the System.
            Although reasonable efforts are used to ensure that the System will
            be current and will contain no errors or inaccuracies, FTD does not
            warrant or represent the accuracy, currency or completeness of the
            Materials on the System. FTD may make changes to the Materials on
            the System at any time without notice. FTD does not guarantee that
            all Products referenced on the System will be available at all
            times.
          </Text>
          <Text
            style={{ ...fonts.heading3, ...tw("p-2"), color: colors.primary }}
          >
            10. LIMITATION OF LIABILITIES
          </Text>
          <Text style={tw("p-2 text-justify")}>
            IN NO EVENT WILL FTD, ITS RELATED AND AFFILIATED COMPANIES AND
            PERSONS OR ITS OR THEIR SUPPLIERS, BE LIABLE FOR ANY DAMAGES OR
            LOSSES WHATSOEVER ARISING OUT OF THE USE, INABILITY TO USE, OR THE
            RESULTS OF USE OF THIS SYSTEM, ANY THIRD PARTY SYSTEMS, OR THE
            MATERIALS, SERVICES, OR PRODUCTS CONTAINED OR PROVIDED THROUGH ANY
            OR ALL SUCH SYSTEMS, WHETHER BASED ON WARRANTY, CONTRACT, TORT OR
            ANY OTHER LEGAL THEORY AND WHETHER OR NOT FTD IS ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. IF YOUR USE OF THE MATERIALS OR
            SERVICES ON THE SYSTEM RESULTS IN THE NEED FOR SERVICING, REPAIR OR
            CORRECTION OF EQUIPMENT OR DATA, YOU ASSUME ALL COSTS THEREOF.
            APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF DAMAGES,
            SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
          </Text>
          <Text
            style={{ ...fonts.heading3, ...tw("p-2"), color: colors.primary }}
          >
            11. INDEMNIFICATION
          </Text>
          <Text style={tw("p-2 text-justify")}>
            You agree to defend, indemnify and hold harmless FTD and its
            affiliates, representatives, officers, directors and employees from
            all liabilities, claims, costs and expenses, including reasonable
            attorney fees, that arise out of or in connection with your use of
            the System, your viewing or downloading of Materials from the
            System, your use of Services or acquisition of Products on the
            System, or your Communications to the System.
          </Text>
          <Text
            style={{ ...fonts.heading3, ...tw("p-2"), color: colors.primary }}
          >
            12. RESOLUTION OF DISPUTES
          </Text>
          <Text style={tw("p-2 text-justify")}>
            These Terms and Conditions of Use shall be governed in all respects
            by and construed in accordance with the laws, exclusive of the
            choice of laws rules, of the state of Illinois. All disputes arising
            out of or otherwise relating to the Terms and Conditions of Use may
            only be brought in a federal or state court located in or having
            jurisdiction over matters arising exclusively in Illinois, and you
            hereby consent to the jurisdiction of such courts and waive to the
            fullest extent allowed by law any objection to venue in such courts
            and further waive the right to trial by jury. The parties expressly
            exclude the application of the United Nations Convention on
            Contracts for the International Sale of Goods.
          </Text>
          <Text
            style={{ ...fonts.heading3, ...tw("p-2"), color: colors.primary }}
          >
            13. UNENFORCEABLE PROVISIONS
          </Text>
          <Text style={tw("p-2 text-justify")}>
            If any provision of the Terms and Conditions of Use is found to be
            unlawful, void or unenforceable, then such provision is deemed
            severable and will not affect the validity or enforceability of any
            of the remaining provisions. This transaction is void wherever
            prohibited.
          </Text>
          <Text
            style={{ ...fonts.heading3, ...tw("p-2"), color: colors.primary }}
          >
            14. CHOICE OF LANGUAGE.
          </Text>
          <Text style={tw("p-2 text-justify")}>
            Les parties ont exigé que le présent contrat et tous les documents
            connexes soient rédigés en anglais {"\n\n"}The parties have required
            that this agreement and all related documents be drafted in English.{" "}
            {"\n\n"}As partes têm exigido que este acordo e todos os documentos
            relacionados ser redigido em Inglês. {"\n\n"}Las partes han
            requerido que este acuerdo y todos los documentos relacionados sean
            redactados en Inglés.
          </Text>
        </View>
      </ScrollView>
    </>
  );
};
