import Storage from "library/utils/storage";
import jwtDecode from "jwt-decode";

const AuthTokenCache = new Storage("AuthTokenCache", {
  throttleWait: 0,
  //ttl: 24 * 60 * 60 * 1000, //PAR-2635 - issue when session is more than 24 hours
});

const load = () => AuthTokenCache.load();

const getAuthToken = () => ({
  access_token: AuthTokenCache.get("accessToken"),
  refresh_token: AuthTokenCache.get("refreshToken"),
});

const setAuthToken = (jwt) => {
  const accessToken = jwtDecode(jwt.accessToken);
  const accessTokenTTL = accessToken.expiry * 1000 - new Date().getTime();

  //const refreshToken = jwtDecode(jwt.refreshToken);
  //const refreshTokenTTL = refreshToken.expiry * 1000 - new Date().getTime();

  /**
   * As per MSOL-13426 >> If we have ttl expiry time for accessToken and refreshToken, when users re-opened mobile apps after accessToken expiry time then we won't get hat value and users will be logged out as we are depending on authToken in src/components/containers/navigator/index.js#325 to navigate users to Dashboard or Login page. So we are removing ttl while storing accessToken and refreshToken in local storage in order to save accessToken & refreshToken forever so that users won't be logged out.
   */
  AuthTokenCache.put("accessToken", jwt.accessToken, {
    //ttl: accessTokenTTL,
  });

  AuthTokenCache.put("refreshToken", jwt.refreshToken, {
    //ttl: refreshTokenTTL,
  });

  setKeepMeSignedIn(jwt?.keepMeSignedIn || false);

  return accessTokenTTL;
};

const setSSOUser = (val) => {
  const ssoUser = val;
  AuthTokenCache.put("ssoUser", ssoUser);
  return ssoUser;
};
const getSSOUser = () => {
  return AuthTokenCache.get("ssoUser");
};
const clearSSOUser = () => {
  return AuthTokenCache.put("ssoUser", "");
};

const getAccessToken = () => {
  return AuthTokenCache.get("accessToken");
};

const clearAccessToken = () => {
  return AuthTokenCache.put("accessToken", "");
};

const getRefreshToken = () => {
  return AuthTokenCache.get("refreshToken");
};

const clearRefreshToken = () => {
  return AuthTokenCache.put("refreshToken", "");
};

const setRefreshTokenCalled = () => {
  return AuthTokenCache.put("refreshTokenCall", "true", {
    ttl: 300000, //Setting this flag for 5 minutes to avoid multiple refreshtoken calls
  });
};

const getRefreshTokenCalled = () => {
  return AuthTokenCache.get("refreshTokenCall");
};

const clearRefreshTokenCalled = () => {
  return AuthTokenCache.put("refreshTokenCall", "");
};

const setKeepMeSignedIn = (keepMeSignedIn) => {
  AuthTokenCache.put("keepMeSignedIn", keepMeSignedIn);
};

const getKeepMeSignedIn = () => AuthTokenCache.get("keepMeSignedIn") || false;

const clear = () => {
  AuthTokenCache.clear();
};

export default {
  getAuthToken,
  setAuthToken,
  getAccessToken,
  clearAccessToken,
  getRefreshToken,
  clearRefreshToken,
  getRefreshTokenCalled,
  setRefreshTokenCalled,
  clearRefreshTokenCalled,
  clear,
  load,
  setSSOUser,
  getSSOUser,
  clearSSOUser,
  setKeepMeSignedIn,
  getKeepMeSignedIn,
};
