import React, { useMemo, useState, useRef, useEffect } from "react";
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ActivityIndicator,
} from "react-native";
import { useDispatch } from "react-redux";
import tw from "tailwind-rn";
import omit from "lodash/omit";
import get from "lodash/get";
// import startCase from "lodash/startCase";
import I18NContext from "library/contexts/i18N";
import UserProfileContext from "library/contexts/userProfile";
import { FormFieldPicker, FormField } from "components/elements/forms";
import { shapes } from "styles/theme";
import IMAGES from "static/assets/images";
import { colors, backgroundColors } from "styles/theme";
import UserProfileStorage from "library/storage/userProfile";
import { Tooltip } from "components/elements";
import { request } from "library/utils/request";
import { ToasterHandler, PopupMenu } from "components/elements";
import { useNavigation } from "@react-navigation/native";
import {
  EventTypes,
  Styles,
  printActions,
  ActionItemsWithIcons,
  downloadEventLabels,
} from "../config";
import {
  EligibleStatusList,
  StatusSuggestions,
} from "../../events/listing-screen/ui-config";
import moment from "moment";
import { setSearchText } from "library/sagas/views/home/drawer/events/slice";
import ProposalInfo from "../proposal-and-payment/proposal";
import { useReactToPrint } from "react-to-print";
import { ConfirmModal } from "components/wrappers";
import { Picker } from "components/elements";
import Environment from "library/utils/environment";

const EventBasicInfo = (props) => {
  const {
    isDesktop,
    currentTabIndex,
    values,
    initialValues,
    memberCodes,
    isProposalPage,
    setFieldValue,
    meatballAction,
    eventOrdersStatusInfo,
    setSideCarInfo,
    ordersInfo,
  } = props;
  const ref = useRef();
  const dispatch = useDispatch();
  const { messages, Localise } = React.useContext(I18NContext);
  const { userProfile: { firstName: operator } = {} } =
    React.useContext(UserProfileContext);
  const shopNames = UserProfileStorage.getAllShopNames();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const [showWarningOnComplete, setShowWarningOnComplete] = useState(false);
  const [showCancelEventsWarning, setShowCancelEventsWarning] = useState(false);
  const [showFullRefundConfirmation, setShowFullRefundConfirmation] =
    useState(false);

  const handleProposalPrint = useReactToPrint({
    content: () => ref.current,
  });

  const { completedOrders, unpaidBalance } = eventOrdersStatusInfo;

  const refundEligibleOrderIds = ordersInfo
    .filter((order) => {
      const paymentMethodType = get(
        order,
        "paymentDetails.paymentMethod.0.paymentMethodType"
      );
      return paymentMethodType !== "PAY_LATER";
    })
    .map((ord) => ord.orderItems[0].orderItemId);

  useEffect(() => {
    if (meatballAction) {
      if (completedOrders?.length >= 0) {
        handleAction(meatballAction);
      } else setLoading(true);
    }
  }, [completedOrders?.length, meatballAction]);

  const handleAction = (action) => {
    if (action === "cancelled" && orderIds.length > 0) {
      setShowCancelEventsWarning(true);
    } else if (action === "completed") {
      if (completedOrders?.length === values.locations?.length) {
        if (parseFloat(unpaidBalance) > 0) {
          setShowWarningOnComplete(true);
        } else handleUpdate(action);
      } else {
        setLoading(false);
      }
    } else handleUpdate(action);
  };

  const handleUpdate = (
    action,
    redirectToEvents = true,
    showRefundPopup = false
  ) => {
    const {
      startAndEndDate,
      _id,
      __v,
      createdOn,
      updatedOn,
      startTime,
      customerInfo,
      hasCustomerInfo,
      actualCustomerInfo,
      proposalInfo,
      paymentDetails,
      showPayment,
      isPaymentRequired,
      amountPaid,
      refunded,
      unpaidBalance,
      completedOrders,
      orderIds,
      orderItems,
      orderAmounts,
      actionItem,
      ...other
    } = values;
    setLoading(true);
    const proposal =
      action === "approved"
        ? other.proposal.status === "DECLINED"
          ? {
              ...other.proposal,
              fullLegalName: "",
              status: "APPROVED",
              date: moment().format("MM/DD/YYYY"),
            }
          : {
              ...other.proposal,
              status: "APPROVED",
              date: other.proposal.date || moment().format("MM/DD/YYYY"),
            }
        : action === "rejected"
        ? other.proposal.status === "APPROVED"
          ? {
              ...other.proposal,
              fullLegalName: "",
              status: "DECLINED",
              date: moment().format("MM/DD/YYYY"),
            }
          : {
              ...other.proposal,
              status: "DECLINED",
              date: other.proposal.date || moment().format("MM/DD/YYYY"),
            }
        : action === "completed"
        ? other.proposal
        : { status: "DRAFT" };

    request("update-event", {
      reqObj: {
        ...omit(other, ["showError"]),
        status: action?.toUpperCase(),
        proposal,
      },
    })
      .then((response) => {
        ToasterHandler(
          "nice",
          Localise(messages, "Your event updated successfully")
        );
        dispatch(setSearchText(values.name));
        if (redirectToEvents) {
          navigation.navigate("events");
        } else {
          showRefundPopup &&
            refundEligibleOrderIds?.length > 0 &&
            setTimeout(() => {
              setShowFullRefundConfirmation(true);
            }, 2000);
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        ToasterHandler(
          "uh oh",
          Localise(messages, "Failed to update the event")
        );
      })
      .finally(() => setLoading(false));
  };

  const status = values?.status?.toLowerCase();
  const ActionItems =
    ActionItemsWithIcons(!!values?.orderIds?.length)[status] || {};

  const statusIndex = Object.keys(ActionItems).findIndex(
    (item) => item === status
  );

  const eligibleMemberCodes = Environment.get(
    "ENABLE_EVENTS_PLANNER",
    ""
  ).split(",");

  const filteredMemberCodes = eligibleMemberCodes.includes("ALL")
    ? memberCodes
    : memberCodes.filter((member) => eligibleMemberCodes.includes(member));

  const shopCodes = filteredMemberCodes.map((code) => {
    return { label: `${code} ${shopNames[code]}`, value: code };
  });
  let isEligibleToCompleteAction =
    completedOrders?.length === values?.locations?.length;

  const eligibleStatusList =
    EligibleStatusList(!!values?.orderIds?.length, isEligibleToCompleteAction)[
      status
    ] || [];

  const orderIds = values.orderIds;

  return useMemo(() => {
    return (
      <View style={tw("flex items-center w-full")}>
        <View style={[tw("flex justify-center w-full")]}>
          <View
            style={tw("flex flex-row-reverse items-center justify-between")}
          >
            <View
              style={[
                tw("flex flex-row"),
                memberCodes?.length > 1 ? {} : { marginBottom: 10 },
              ]}
            ></View>
            {memberCodes.length > 1 && (
              <FormFieldPicker
                placeholder={{ label: "Select Shop", value: "" }}
                containerStyle={{
                  width: isDesktop ? "40%" : "100%",
                  paddingLeft: 0,
                }}
                data={shopCodes}
                name="memberCode"
                label=""
                disabled={!!values?.eventId || currentTabIndex === 3}
              />
            )}
          </View>
          {values?.eventId && (
            <View
              style={[
                shapes.sectionBorder,
                { marginBottom: 10 },
                tw("flex flex-row items-center"),
              ]}
            >
              {Object.keys(ActionItems).map((item, index) => {
                const { label, icon } = ActionItems[item];
                const isCurrentStatus = item === status;
                let isEligibleAction = true;
                if (label?.toLowerCase() === "completed") {
                  isEligibleAction =
                    completedOrders?.length === values.locations?.length;
                }
                return (
                  <TouchableOpacity
                    key={index}
                    activeOpacity={0.5}
                    onPress={() => {
                      handleAction(item, isEligibleAction);
                    }}
                    testID={`breadcrumb-btn-${label}`}
                    accessibilityLabel={`breadcrumb-btn-${label}`}
                    style={{
                      ...tw("items-center justify-center flex-row m-1"),
                      height: 32,
                      width: isCurrentStatus ? "auto" : 32,
                      backgroundColor: isCurrentStatus
                        ? Styles.blockBgColor
                        : backgroundColors.secondary,
                      borderWidth: 1,
                      borderColor: colors.grayScaleLight,
                      borderRadius: 2,
                      flexWrap: isDesktop ? "wrap" : "nowrap",
                      padding: 5,
                      ...(index <= statusIndex && {
                        pointerEvents: "none",
                      }),
                    }}
                  >
                    <Tooltip
                      text={
                        isEligibleAction
                          ? label
                          : "Some orders are yet to be completed"
                      }
                    >
                      <View
                        style={{
                          ...tw("flex flex-row items-center justify-center"),
                        }}
                      >
                        <Image
                          style={{
                            width: Styles.iconWidth,
                            height: Styles.iconHeight,
                          }}
                          source={IMAGES[icon]}
                        />

                        {isCurrentStatus && (
                          <Text
                            style={{
                              fontSize: 13,
                              color:
                                item === "rejected" ? "#6a6a6a" : "#114258",
                              fontWeight: "700",
                              paddingLeft: 5,
                              textAlign: "center",
                            }}
                          >
                            {label}
                          </Text>
                        )}
                      </View>
                    </Tooltip>
                  </TouchableOpacity>
                );
              })}
              {loading && (
                <ActivityIndicator
                  style={{ marginLeft: 5 }}
                  color={colors.activityIndicator}
                  testID={"loader"}
                />
              )}
              <View
                style={[
                  tw("flex flex-row justify-center items-center"),
                  { marginLeft: "auto" },
                ]}
              >
                {eligibleStatusList.length > 0 && (
                  <Picker
                    containerStyle={{
                      width: 120,
                      height: "auto",
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                    }}
                    items={eligibleStatusList.map((each) => ({
                      label: each.label,
                      value: each.key,
                    }))}
                    placeholder={{ label: "Actions", value: "" }}
                    onValueChange={(val) => {
                      handleAction(
                        StatusSuggestions[val?.toLowerCase()]?.toLowerCase()
                      );
                    }}
                  ></Picker>
                )}

                {status !== "new" && (
                  <React.Fragment>
                    <View style={tw(`pl-2 flex-row justify-end items-center`)}>
                      <Image
                        style={{
                          width: 24,
                          height: 24,
                        }}
                        resizeMode="cover"
                        source={IMAGES["print"]}
                      />
                    </View>

                    <PopupMenu
                      menuOptions={printActions}
                      containerStyle={tw(`flex-row justify-end items-center`)}
                      optionsContainerStyle={{
                        width: 110,
                        padding: 5,
                      }}
                      onSelect={(label) =>
                        label === "PrintProposal"
                          ? handleProposalPrint()
                          : downloadEventLabels(values)
                      }
                      testID="sort"
                      accessibilityLabel="sort"
                    >
                      <View
                        style={tw(`px-2 flex-row justify-end items-center`)}
                      >
                        <Image
                          style={{
                            width: 18,
                            height: 18,
                          }}
                          resizeMode="cover"
                          source={IMAGES["collapsed-section"]}
                        />
                      </View>
                    </PopupMenu>
                  </React.Fragment>
                )}
              </View>
            </View>
          )}
          {![3, 4].includes(currentTabIndex) && (
            <View style={[shapes.sectionBorder]}>
              <View style={isDesktop && tw("flex flex-row")}>
                <FormField
                  name="name"
                  containerStyle={{
                    width: isDesktop ? "65%" : "100%",
                  }}
                  placeholder={Localise(messages, "Event Name")}
                  label={Localise(messages, "Event Name")}
                  labelStyle={{
                    fontWeight: "normal",
                  }}
                  testID="event_name"
                  accessibilityLabel="event_name"
                  // handleOnBlur={(text) => {
                  //   setFieldValue("name", startCase(text));
                  // }}
                />
                <FormFieldPicker
                  placeholder={{ label: "Select Event Type" }}
                  containerStyle={{
                    width: isDesktop ? "35%" : "100%",
                  }}
                  data={EventTypes}
                  name="type"
                  label={Localise(messages, "Event Type")}
                  labelStyle={{ fontWeight: "normal" }}
                  testID="evnet_type"
                  accessibilityLabel="evnet_type"
                />
              </View>
              <FormField
                name="description"
                containerStyle={{
                  width: isDesktop ? "100%" : "100%",
                }}
                placeholder={Localise(messages, "Event Description")}
                label={Localise(messages, "Event Description")}
                labelStyle={{ fontWeight: "normal" }}
                testID="evnet_description"
                accessibilityLabel="evnet_description"
                multiline={true}
                numberOfLines={3}
              />
            </View>
          )}
        </View>
        <ProposalInfo
          values={values}
          initialValues={initialValues}
          componentRef={ref}
          isProposalPage={isProposalPage}
          setFieldValue={setFieldValue}
          eventOrdersStatusInfo={eventOrdersStatusInfo}
          setSideCarInfo={setSideCarInfo}
        />
        <ConfirmModal
          modalVisible={showWarningOnComplete}
          handlePrimary={() => {
            setShowWarningOnComplete(false);
            setLoading(false);
            handleUpdate("completed");
          }}
          contentStyle={{ maxWidth: 340 }}
          handleSecondary={() => {
            setShowWarningOnComplete(false);
            setLoading(false);
          }}
          data={{
            modal: {
              primary: "Continue",
              secondary: "Nevermind",
              content: Localise(
                messages,
                `There is an outstanding payment for this event. Do you still want to mark Event as Completed?`
              ),
            },
          }}
        />
        <ConfirmModal
          modalVisible={showCancelEventsWarning}
          handlePrimary={() => {
            setLoading(true);
            setShowCancelEventsWarning(false);
            const cancelRequests = orderIds.map((recordId, index) => {
              return request("order-requests", {
                reason: "Other",
                recordId,
                deliveryMethod: values?.locations[index]?.deliveryType,
                requestType: "cancel",
                operator,
                sourceMemberCode: values.memberCode,
                comments: "",
              });
            });

            Promise.all(cancelRequests)
              .then(() => {
                handleUpdate(
                  "cancelled",
                  !refundEligibleOrderIds?.length,
                  true
                );
              })
              .catch((error) => {
                ToasterHandler(
                  "uh oh",
                  Localise(messages, `Failed to Cancel the orders. ${error}`)
                );
                setLoading(false);
              });
          }}
          contentStyle={{ maxWidth: 340 }}
          handleSecondary={() => {
            setLoading(false);
            setShowCancelEventsWarning(false);
          }}
          data={{
            modal: {
              primary: "Continue",
              secondary: "Nevermind",
              content: Localise(
                messages,
                `Canceling the event cancels all related active orders.`
              ),
            },
          }}
        />
        <ConfirmModal
          modalVisible={showFullRefundConfirmation}
          handlePrimary={() => {
            setLoading(true);
            setShowFullRefundConfirmation(false);

            const lockOrders = refundEligibleOrderIds.map((recordId, index) => {
              return request("lock-order", {
                recordId,
                deliveryMethod: values?.locations[index]?.deliveryType,
              });
            });

            Promise.all(lockOrders)
              .then(() => {
                const refundRequests = refundEligibleOrderIds.map(
                  (orderId, index) => {
                    return request("initiate-refund", {
                      isPartial: false,
                      orderId,
                      reason: "Other",
                      deliveryMethod: values?.locations[index]?.deliveryType,
                    });
                  }
                );
                Promise.all(refundRequests)
                  .then(() => {
                    ToasterHandler(
                      "nice",
                      Localise(messages, `Refunds initiated successfully.`)
                    );
                  })
                  .catch((error) => {
                    ToasterHandler(
                      "uh oh",
                      Localise(
                        messages,
                        `Failed to refund the orders. ${error}`
                      )
                    );
                  })
                  .finally(() => {
                    setLoading(false);
                    navigation.navigate("events");
                  });
              })
              .catch((error) => {
                setLoading(false);
                navigation.navigate("events");
                ToasterHandler(
                  "uh oh",
                  Localise(messages, `Failed to refund the orders. ${error}`)
                );
              });
          }}
          contentStyle={{ maxWidth: 340 }}
          handleSecondary={() => {
            setShowFullRefundConfirmation(false);
            navigation.navigate("events");
          }}
          data={{
            modal: {
              primary: "Yes",
              secondary: "No",
              content: Localise(
                messages,
                `Do you want to issue full refund for recently canceled event orders?`
              ),
            },
          }}
        />
      </View>
    );
  }, [
    Localise,
    isDesktop,
    messages,
    values,
    currentTabIndex,
    memberCodes,
    loading,
    navigation,
    shopNames,
    handleProposalPrint,
    isProposalPage,
    setFieldValue,
    eventOrdersStatusInfo,
  ]);
};

export default EventBasicInfo;
