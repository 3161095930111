//Driver Dashboard - Route Actions
export const ListOfRouteActions = {
  DRAFT: [
    { key: "PLANNED", label: "Ready To Go", actionType: "update" },
    { key: "INPROGRESS", label: "Start Route", actionType: "update" },
    { key: "EDIT", label: "Edit Route", actionType: "edit" },
  ],
  PLANNED: [
    { key: "INPROGRESS", label: "Start Route", actionType: "update" },
    { key: "EDIT", label: "Edit Route", actionType: "edit" },
  ],
  INPROGRESS: [
    { key: "COMPLETED", label: "End Route", actionType: "update" },
    { key: "EDIT", label: "Edit Route", actionType: "edit" },
  ],
  COMPLETED: [],
  INCOMPLETE: [],
};
