import React, { useEffect, useContext } from "react";
import { Text } from "react-native-elements";
import {
  View,
  Platform,
  ActivityIndicator,
  KeyboardAvoidingView,
} from "react-native";
import { fonts, colors } from "styles/theme";
import tw from "tailwind-rn";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { Form, FormField, FormFieldSwitch } from "components/elements/forms";
import { Picker } from "components/elements";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import I18NContext from "library/contexts/i18N";
import isEmpty from "lodash/isEmpty";
import toUpper from "lodash/toUpper";
import moment from "moment";
import {
  PermissionsForDeliveryProviderSettings,
  PermissionsForNoServiceZoneSettings,
  deliveryProviders,
  deliveryPickupTime,
} from "../../config";
import { validationSchema } from "./helper";
import {
  defaultDeliveryProvider,
  isBurqProvider,
} from "library/utils/deliveryService";
import { SaveCancelButtons } from "components/wrappers";

const DeliveryServiceSettings = ({
  shopSettings,
  saveSettings,
  loadingKey,
  hideAutoSendToDS,
}) => {
  const { messages, Localise } = useContext(I18NContext);
  const [deliveryServiceSettings, setDeliveryServiceSettings] =
    useStateIfMounted({
      delivery_service: shopSettings["delivery_service"],
      no_delivery_service_zone:
        shopSettings["no_delivery_service_zone"] || "false",
      delivery_provider:
        shopSettings["delivery_provider"] || defaultDeliveryProvider,
      auto_send_to_delivery_service:
        shopSettings["auto_send_to_delivery_service"],
      delivery_pickup_time: shopSettings["delivery_pickup_time"],
      deliveryServiceActiveSince: shopSettings["deliveryServiceActiveSince"],
    });
  const { userRole, proxyUser } = useContext(UserProfileContext);
  const { isMobile } = useContext(DeviceContext);
  const hasDeliveryProviderEditAccess =
    PermissionsForDeliveryProviderSettings[toUpper(userRole)] && proxyUser;
  const hasNoServiceZoneEditAccess =
    PermissionsForNoServiceZoneSettings[toUpper(userRole)] && proxyUser;

  useEffect(() => {
    if (isEmpty(shopSettings)) return;
    setDeliveryServiceSettings({
      delivery_service: shopSettings["delivery_service"],
      no_delivery_service_zone:
        shopSettings["no_delivery_service_zone"] || "false",
      delivery_provider:
        shopSettings["delivery_provider"] || defaultDeliveryProvider,
      auto_send_to_delivery_service:
        shopSettings["auto_send_to_delivery_service"],
      delivery_pickup_time:
        shopSettings["delivery_pickup_time"].length > 1
          ? shopSettings["delivery_pickup_time"]
          : "Every 30 minutes",
      deliveryServiceActiveSince: shopSettings["deliveryServiceActiveSince"],
      delivery_service_pickup_instructions:
        shopSettings["delivery_service_pickup_instructions"],
    });
  }, [shopSettings]);

  //To show the delivery messgae based on service provider choosed
  const deliveryMessage =
    Localise(messages, "Delivery Distance Covered") +
    " " +
    "15" +
    " " +
    Localise(messages, "miles");

  const serviceZoneMessage =
    "There are no service providers currently operating in your area.";

  const noServiceZone =
    deliveryServiceSettings["no_delivery_service_zone"] === "true";

  function getMessage(text, style) {
    return (
      <View
        style={{
          marginTop: isMobile ? 10 : 0,
          ...style,
        }}
      >
        <Text>{Localise(messages, text)}</Text>
      </View>
    );
  }

  const getFilteredPickupTimes = () => {
    if (isBurqProvider(shopSettings["delivery_provider"])) {
      return [{ label: "Every 30 minutes", value: "Every 30 minutes" }];
    } else {
      return deliveryPickupTime;
    }
  };

  function savePickupInstructions(values) {
    const { pickupInstructions = "" } = values;
    let payload = {
      preferences: [
        {
          id: "delivery_service_pickup_instructions",
          values: [pickupInstructions.trim()],
        },
      ],
    };

    saveSettings(payload, true, true);
  }

  return (
    <React.Fragment>
      <KeyboardAvoidingView
        style={tw("flex-1")}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        enabled
      >
        <View
          style={[
            tw("flex flex-row flex-wrap items-center"),
            {
              marginTop: 4,
              marginBottom: isMobile
                ? 5
                : noServiceZone
                ? hasNoServiceZoneEditAccess
                  ? 0
                  : 15
                : 15,
            },
          ]}
        >
          {/* Delivery Service Toggle */}
          <View
            style={[
              tw("flex flex-row items-center mr-5"),
              {
                marginTop: 4,
                marginBottom: isMobile ? 15 : 5,
              },
            ]}
          >
            <View style={{ paddingRight: 20 }}>
              <Text style={fonts.heading5}>
                {Localise(messages, "Delivery Service")}:
              </Text>
            </View>
            <FormFieldSwitch
              value={deliveryServiceSettings["delivery_service"] === "true"}
              disabled={noServiceZone}
              onValueChange={(val) => {
                if (noServiceZone) return;
                let payload = {
                  preferences: [
                    {
                      id: "delivery_service",
                      values: [val ? "true" : "false"],
                    },
                  ],
                };
                if (val && !shopSettings["delivery_provider"]) {
                  payload.preferences.push({
                    id: "delivery_provider",
                    values: [defaultDeliveryProvider],
                  });
                  payload.preferences.push({
                    id: "delivery_pickup_time",
                    values: ["Every 30 minutes"],
                  });
                }
                if (
                  val &&
                  !deliveryServiceSettings["deliveryServiceActiveSince"]
                ) {
                  const currentUTCTime = moment.utc().toISOString();
                  payload.preferences.push({
                    id: "deliveryServiceActiveSince",
                    values: [currentUTCTime],
                  });
                }
                saveSettings(payload, true, true);
              }}
              testID="delivery_service_setting"
            />
            <View style={{ paddingHorizontal: 20 }}>
              <Text>
                {deliveryServiceSettings["delivery_service"] === "true"
                  ? Localise(messages, "Enabled")
                  : Localise(messages, "Disabled")}
              </Text>
            </View>
            {loadingKey === "delivery_service" && (
              <ActivityIndicator
                color={colors.activityIndicator}
                testID={"delivery_service_loader"}
              />
            )}
          </View>
          {noServiceZone &&
            (isMobile || !hasNoServiceZoneEditAccess) &&
            getMessage(serviceZoneMessage, { marginBottom: isMobile ? 5 : 0 })}

          {/* No Service Zone Toggle */}
          {hasNoServiceZoneEditAccess ? (
            <View
              style={[
                tw("flex flex-row items-center"),
                { marginTop: 4, marginBottom: isMobile ? 15 : 5 },
              ]}
            >
              <View style={{ paddingRight: 20 }}>
                <Text style={fonts.heading5}>
                  {Localise(messages, "No Service Zone")}:
                </Text>
              </View>
              <FormFieldSwitch
                value={
                  deliveryServiceSettings["no_delivery_service_zone"] === "true"
                }
                disabled={!hasNoServiceZoneEditAccess}
                onValueChange={(val) => {
                  if (!hasNoServiceZoneEditAccess) return;
                  let payload = {
                    preferences: [
                      {
                        id: "no_delivery_service_zone",
                        values: [val ? "true" : "false"],
                      },
                    ],
                  };
                  if (val) {
                    payload.preferences.push({
                      id: "delivery_service",
                      values: ["false"],
                    });
                  }
                  saveSettings(payload, true, true);
                }}
                testID="no_service_zone_setting"
              />
              <View style={{ paddingHorizontal: 20 }}>
                <Text>
                  {deliveryServiceSettings["no_delivery_service_zone"] ===
                  "true"
                    ? Localise(messages, "Enabled")
                    : Localise(messages, "Disabled")}
                </Text>
              </View>
              {loadingKey === "no_delivery_service_zone" && (
                <ActivityIndicator
                  color={colors.activityIndicator}
                  testID={"no_delivery_service_zone_loader"}
                />
              )}
            </View>
          ) : null}
        </View>
        {noServiceZone &&
          !isMobile &&
          hasNoServiceZoneEditAccess &&
          getMessage(serviceZoneMessage, { marginBottom: 15, marginTop: 10 })}

        {/* Delivery Service Provider picker */}
        <View style={[tw("flex flex-row items-center")]}>
          <View style={{ paddingRight: 15 }}>
            <Text style={fonts.heading5}>
              {Localise(messages, "Delivery Provider")}:
            </Text>
          </View>
          <Picker
            containerStyle={{
              paddingBottom: 0,
              ...(isMobile ? { width: "45%" } : { paddingRight: 20 }),
            }}
            items={deliveryProviders.map((item) => {
              return {
                value: item.value,
                label: Localise(messages, item.label),
              };
            })}
            placeholder={{}}
            value={deliveryServiceSettings["delivery_provider"]}
            disabled={
              !hasDeliveryProviderEditAccess ||
              deliveryServiceSettings["delivery_service"] === "false"
            }
            onValueChange={(val) => {
              if (
                hasDeliveryProviderEditAccess &&
                deliveryServiceSettings["delivery_service"] === "true"
              ) {
                setDeliveryServiceSettings({
                  ...deliveryServiceSettings,
                  delivery_provider: val,
                });
                let payload = {
                  preferences: [
                    {
                      id: "delivery_provider",
                      values: [val],
                    },
                  ],
                };
                //Keeping Every 30 minutes as default delivery_pickup_time for all providers
                payload.preferences.push({
                  id: "delivery_pickup_time",
                  values: ["Every 30 minutes"],
                });

                saveSettings(payload, true, true);
              }
            }}
            testID="delivery_provider_setting"
          />
          {!isMobile && getMessage(deliveryMessage, { marginRight: 10 })}
          {loadingKey === "delivery_provider" && (
            <ActivityIndicator
              color={colors.activityIndicator}
              testID={"delivery_provider_loader"}
            />
          )}
        </View>
        {isMobile && getMessage(deliveryMessage)}

        {/* Delivery Service Pickup window picker */}
        <View style={[tw("flex flex-row items-center mt-4")]}>
          <View style={{ paddingRight: 15 }}>
            <Text style={fonts.heading5}>
              {Localise(messages, "Pickup Time")}:
            </Text>
          </View>
          <View>
            <Picker
              containerStyle={{ paddingBottom: 0 }}
              items={getFilteredPickupTimes().map((item) => ({
                value: item.value,
                label: Localise(messages, item.label),
              }))}
              placeholder={{}}
              value={deliveryServiceSettings["delivery_pickup_time"]}
              disabled={
                !hasDeliveryProviderEditAccess ||
                deliveryServiceSettings["delivery_service"] === "false"
              }
              onValueChange={(val) => {
                if (
                  hasDeliveryProviderEditAccess &&
                  deliveryServiceSettings["delivery_service"] === "true"
                ) {
                  setDeliveryServiceSettings({
                    ...deliveryServiceSettings,
                    delivery_pickup_time: val,
                  });
                  let payload = {
                    preferences: [
                      {
                        id: "delivery_pickup_time",
                        values: [val],
                      },
                    ],
                  };
                  saveSettings(payload, true, true);
                }
              }}
              testID="delivery_pickup_time_setting"
            ></Picker>
          </View>
          {loadingKey === "delivery_pickup_time" && (
            <ActivityIndicator
              color={colors.activityIndicator}
              testID={"delivery_pickup_time_loader"}
            />
          )}
        </View>

        {/* Auto Send to Delivery Service Toggle */}
        {!hideAutoSendToDS && (
          <View style={[tw("flex flex-row items-center mt-4")]}>
            <View style={{ paddingRight: 20, maxWidth: 200 }}>
              <Text style={fonts.heading5}>
                {Localise(messages, "Auto Send to Delivery Service:")}
              </Text>
            </View>
            <FormFieldSwitch
              value={
                deliveryServiceSettings["auto_send_to_delivery_service"] ===
                "true"
              }
              onValueChange={(val) => {
                let payload = {
                  preferences: [
                    {
                      id: "auto_send_to_delivery_service",
                      values: [val ? "true" : "false"],
                    },
                  ],
                };
                saveSettings(payload, true, true);
              }}
              testID="auto_send_to_delivery_service_setting"
            />
            <View style={{ paddingHorizontal: 20 }}>
              <Text>
                {deliveryServiceSettings["auto_send_to_delivery_service"] ===
                "true"
                  ? Localise(messages, "Enabled")
                  : Localise(messages, "Disabled")}
              </Text>
            </View>
            {loadingKey === "auto_send_to_delivery_service" && (
              <ActivityIndicator
                color={colors.activityIndicator}
                testID={"auto_send_to_delivery_service_loader"}
              />
            )}
          </View>
        )}

        {/*Pickup Instructions */}
        <View>
          <Form
            initialValues={{
              pickupInstructions:
                deliveryServiceSettings["delivery_service_pickup_instructions"],
            }}
            onSubmit={(values, formikBag) =>
              savePickupInstructions(values, formikBag)
            }
            validationSchema={validationSchema(Localise, messages)}
            render={({
              submitForm,
              resetForm,
              errors: { pickupInstructions: error },
              dirty,
            }) => {
              return (
                <View
                  style={[
                    tw(
                      `flex flex-row flex-wrap ${
                        error ? "items-baseline" : "items-center"
                      } ${isMobile ? "justify-between" : "justify-start"} mt-2`
                    ),
                  ]}
                >
                  <View
                    style={{ paddingRight: Platform.OS === "web" ? 15 : 0 }}
                  >
                    <Text style={fonts.heading5}>
                      {Localise(messages, "Pickup Instructions")}:
                    </Text>
                  </View>
                  <View style={{ width: isMobile ? "63%" : "35%" }}>
                    <FormField
                      name="pickupInstructions"
                      placeholder={Localise(
                        messages,
                        "Enter pickup instructions"
                      )}
                      inputContainerStyle={{
                        alignItems: "center",
                        marginTop: 8,
                      }}
                      isUpdateOnChange={true}
                      autoCapitalize="none"
                      autoCorrect={false}
                      autoComplete="new-password" //hack for autoComplete off
                      fsClass="fs-exclude"
                    />
                  </View>
                  <View
                    style={[
                      tw("justify-end flex-row"),
                      {
                        ...(isMobile
                          ? {
                              width: "100%",
                              paddingRight: 5,
                            }
                          : {}),
                      },
                    ]}
                  >
                    <SaveCancelButtons
                      buttonTitle={Localise(messages, "Save")}
                      cancelTestId={"cancel_pickup_instructions"}
                      disableOnDirty={!dirty}
                    />
                  </View>
                </View>
              );
            }}
          />
        </View>
      </KeyboardAvoidingView>
    </React.Fragment>
  );
};

export default DeliveryServiceSettings;
