import * as Yup from "yup";

export const getValidationSchema = (Localise, messages) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required(Localise(messages, "Please enter first name"))
      .test(
        "ftdsubstringcheck",
        Localise(messages, "FTD cannot be part of the users name"),
        (val) => val && !val.toLowerCase().includes("ftd")
      ),
    lastName: Yup.string()
      .required(Localise(messages, "Please enter last name"))
      .test(
        "ftdsubstringcheck",
        Localise(messages, "FTD cannot be part of the users name"),
        (val) => val && !val.toLowerCase().includes("ftd")
      ),
    roles: Yup.string().required(Localise(messages, "Select Role")),
    employmentStatus: Yup.string().required(
      Localise(messages, "Please select Employment Type")
    ),
    shopCodes: Yup.string().required(
      Localise(messages, "Select at least one shop code")
    ),
    emailId: Yup.string()
      .email(Localise(messages, "Enter a valid email"))
      .required(Localise(messages, "Enter Email")),
    phone: Yup.string()
      .required(Localise(messages, "Please enter phone number"))
      .matches(
        new RegExp(
          "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
        ),
        Localise(messages, "Please enter a valid phone number")
      ),
    newPassword: Yup.string()
      .when("passwordOptions", {
        is: (value) => value === "MANUALLY_RESET",
        then: Yup.string().required(
          Localise(messages, "Please enter new password")
        ),
      })
      .matches(
        /^(?=\S)(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*\S$)[A-Za-z0-9\s!@#$%^&*]{8,32}$/,
        Localise(messages, "Enter Password")
      ),
  });
};
