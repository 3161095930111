import React, { useContext, useMemo } from "react";
import {
  Image,
  Text,
  TouchableOpacity,
  View,
  ActivityIndicator,
} from "react-native";
import { Button } from "react-native-elements";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import { COMMON, SHOP_SETTINGS, ERROR_MESSAGES } from "library/constants";
import tw from "tailwind-rn";
import { fonts, shapes, theme, colors } from "styles/theme";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPromoCodeslist,
  selectShopCode,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import {
  Form,
  FormFieldSwitch,
  FormFieldDatePicker,
  SubmitButton,
} from "components/elements/forms";
import { ToasterHandler } from "components/elements";
import { LabelField, ConfirmModal } from "components/wrappers";
import { IMAGES } from "static/assets/images";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import useStateIfMounted from "library/utils/useStateIfMounted";
import {
  updatePromoCode,
  fetchPromoCodes,
  deletePromoCode,
} from "library/sagas/views/home/drawer/shop-settings/common/slice";
import { promoParams } from "./helper";

const PromoCodesList = () => {
  const { messages, Localise } = useContext(I18NContext);
  const { isMobile, isDesktop } = useContext(DeviceContext);
  const dispatch = useDispatch();
  const memberCode = useSelector(selectShopCode);
  const promoCodes = useSelector(selectPromoCodeslist(memberCode));

  const [viewMoreCounts, setViewMoreCounts] = useStateIfMounted(10);
  const [showConfirmModal, setShowConfirmModal] = useStateIfMounted(false);
  const [promoActions, setPromoActions] = useStateIfMounted({});
  const [promoCodeToEdit, setPromoCodeToEdit] = useStateIfMounted("");

  const formatDate = (date) => moment(date).format("MM/DD/YYYY");

  const showViewMore = useMemo(() => {
    return promoCodes.length > 10 && promoCodes.length > viewMoreCounts;
  }, [promoCodes, viewMoreCounts]);

  const modifyPromoCode = ({ promoCodeID, memberCode, isActive }) => {
    dispatch(
      updatePromoCode({
        params: {
          memberCode,
          promoCodeID,
          action: isActive ? "ACTIVATE" : "DEACTIVATE",
        },
        resolve: () => {
          dispatch(
            fetchPromoCodes({
              params: promoParams({ memberCode }),
              resolve: () => {
                ToasterHandler(
                  "success",
                  Localise(
                    messages,
                    isActive
                      ? SHOP_SETTINGS.PROMO_CODE_ACTIVATED
                      : SHOP_SETTINGS.PROMO_CODE_DEACTIVATED
                  )
                );
                setPromoActions({});
              },
              reject: () => {
                //Displaying success toaster even if fetch call fails
                ToasterHandler(
                  "success",
                  Localise(
                    messages,
                    isActive
                      ? SHOP_SETTINGS.PROMO_CODE_ACTIVATED
                      : SHOP_SETTINGS.PROMO_CODE_DEACTIVATED
                  )
                );
                setPromoActions({});
              },
            })
          );
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(messages, ERROR_MESSAGES.GENERAL_ERROR)
          );
          setPromoActions({});
        },
      })
    );
  };

  const deleteHandler = ({ promoCodeID, memberCode }) => {
    dispatch(
      deletePromoCode({
        params: { promoCodeID, memberCode },
        resolve: () => {
          dispatch(
            fetchPromoCodes({
              params: promoParams({ memberCode }),
              resolve: () => {
                ToasterHandler(
                  "success",
                  Localise(messages, SHOP_SETTINGS.PROMO_DELETION_SUCCESS)
                );
                setPromoActions({});
              },
              reject: () => {
                //Displaying success toaster even if fetch call fails
                ToasterHandler(
                  "success",
                  Localise(messages, SHOP_SETTINGS.PROMO_DELETION_SUCCESS)
                );
                setPromoActions({});
              },
            })
          );
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(messages, ERROR_MESSAGES.GENERAL_ERROR)
          );
          setPromoActions({});
        },
      })
    );
  };

  const editPromoValidity = (params) => {
    dispatch(
      updatePromoCode({
        params,
        resolve: () => {
          dispatch(
            fetchPromoCodes({
              params: promoParams({ memberCode: params.memberCode }),
              resolve: () => {
                ToasterHandler(
                  "success",
                  Localise(messages, SHOP_SETTINGS.PROMO_CODE_MODIFIED)
                );
                setPromoCodeToEdit("");
              },
              reject: () => {
                //Displaying success toaster even if fetch call fails
                ToasterHandler(
                  "success",
                  Localise(messages, SHOP_SETTINGS.PROMO_CODE_MODIFIED)
                );
                setPromoCodeToEdit("");
              },
            })
          );
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(messages, ERROR_MESSAGES.GENERAL_ERROR)
          );
        },
      })
    );
  };

  return (
    <View>
      <View style={{ width: isDesktop ? "65%" : "100%" }}>
        {promoCodes.slice(0, viewMoreCounts).map((item, index) => {
          const {
            promoCode,
            memberCode,
            promoCodeID,
            discountType,
            discountValue,
            startDate,
            endDate,
            isActive,
          } = item;

          const isExpired = moment().isAfter(moment(endDate), "day");

          const amount =
            discountType === COMMON.DOLLAR
              ? `$${discountValue.toFixed(2)}`
              : `${discountValue.toFixed(2)}%`;

          const canEditStartDate = moment().isBefore(moment(startDate), "day");

          return (
            <View
              key={`${promoCode}-${index}`}
              style={[
                tw(`flex flex-row flex-wrap w-full p-2 rounded-sm my-1`),
                shapes.sectionBorder,
                { zIndex: viewMoreCounts - index },
              ]}
            >
              <View
                style={[
                  tw(`flex flex-row`),
                  { width: isMobile ? "100%" : "30%" },
                ]}
              >
                <Text
                  style={[tw(`self-center`), { width: "70%" }, fonts.default]}
                  testID={`promo_code_${promoCode}`}
                  accessibilityLabel={`promo_code_${promoCode}`}
                >
                  {promoCode}
                </Text>
                <View
                  style={[
                    tw(`flex justify-center items-center`),
                    { width: "30%" },
                  ]}
                >
                  <Text
                    style={fonts.default}
                    testID="promo_code_value"
                    accessibilityLabel="promo_code_value"
                  >
                    {amount}
                  </Text>
                </View>
              </View>

              {promoCodeToEdit === promoCodeID ? (
                <Form
                  initialValues={{
                    startDate,
                    endDate,
                  }}
                  onSubmit={(values) => {
                    editPromoValidity({ ...item, ...values });
                  }}
                  render={() => {
                    return (
                      <View
                        style={tw(
                          `flex flex-row flex-wrap justify-between ${
                            isMobile ? "my-2 w-full" : "w-1/2"
                          }`
                        )}
                      >
                        <View
                          style={tw(
                            `${
                              isMobile ? "w-1/2" : "w-3/5"
                            } flex flex-row flex-wrap justify-between`
                          )}
                        >
                          <FormFieldDatePicker
                            dateValueFormat="YYYY-MM-DD"
                            name="startDate"
                            placeholder="MM/DD/YYYY"
                            containerStyle={{
                              width: isMobile ? "98%" : "48%",
                              marginTop: 0,
                              justifyContent: "center",
                            }}
                            minDate={new Date()}
                            customInputContainerStyle={{
                              paddingHorizontal: 0,
                            }}
                            errorStyle={{ paddingBottom: 0 }}
                            alignIconRight={true}
                            iconName="calendar"
                            iconType="font-awesome"
                            editable={canEditStartDate}
                            disabled={!canEditStartDate}
                            testID="start_date"
                            accessibilityLabel="start_date"
                          />
                          <FormFieldDatePicker
                            dateValueFormat="YYYY-MM-DD"
                            name="endDate"
                            placeholder="MM/DD/YYYY"
                            containerStyle={{
                              width: isMobile ? "98%" : "48%",
                              marginTop: 0,
                              paddingHorizontal: 0,
                              justifyContent: "center",
                            }}
                            minDate={new Date()}
                            alignIconRight={true}
                            iconName="calendar"
                            iconType="font-awesome"
                            errorStyle={{ paddingBottom: 0 }}
                            customInputContainerStyle={{
                              paddingHorizontal: 0,
                            }}
                            testID="end_date"
                            accessibilityLabel="end_date"
                          />
                        </View>

                        <View
                          style={tw(
                            `flex flex-row justify-center items-center ${
                              isMobile ? "w-1/2" : "w-2/5"
                            }`
                          )}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              setPromoCodeToEdit("");
                            }}
                            style={[tw("flex justify-center")]}
                            testID={"clear"}
                            accessibilityLabel={"clear"}
                          >
                            <View>
                              <Text style={fonts.link1}>
                                {Localise(messages, "Cancel")}
                              </Text>
                            </View>
                          </TouchableOpacity>
                          <SubmitButton
                            disableOnDirty={true}
                            title="Save"
                            containerStyle={{
                              marginVertical: 0,
                            }}
                          />
                        </View>
                      </View>
                    );
                  }}
                />
              ) : (
                <View
                  style={[
                    tw(
                      `flex flex-row ${
                        isMobile
                          ? "w-full justify-start my-2"
                          : "w-1/2 justify-center"
                      }  items-center`
                    ),
                    { gap: 10 },
                  ]}
                >
                  <Text
                    style={{ ...fonts.default, width: "70%" }}
                    testID="promo_code_date_range"
                    accessibilityLabel="promo_code_date_range"
                  >
                    {formatDate(startDate)} - {formatDate(endDate)}
                  </Text>
                  {isActive && !isExpired ? (
                    <TouchableOpacity
                      onPress={() => {
                        setPromoCodeToEdit(promoCodeID);
                      }}
                      testID={`edit_${promoCode}_${startDate}_${endDate}`}
                      accessibilityLabel={`edit_${promoCode}_${startDate}_${endDate}`}
                      style={[
                        tw("flex justify-center items-center"),
                        { width: isMobile ? "30%" : "10%" },
                      ]}
                    >
                      <Image style={tw("w-5 h-5")} source={IMAGES["edit"]} />
                    </TouchableOpacity>
                  ) : (
                    <View style={{ width: "10%" }}></View>
                  )}
                </View>
              )}
              <View
                style={[
                  tw(
                    `flex flex-row ${
                      isMobile ? "w-full justify-start" : "w-1/5"
                    }`
                  ),
                  { zIndex: -1 },
                ]}
              >
                {promoActions?.promoCodeID !== promoCodeID ? (
                  <>
                    <View
                      style={[
                        tw(`flex flex-row justify-start items-center`),
                        { width: isMobile ? "70%" : "80%" },
                      ]}
                    >
                      {!isExpired && (
                        <View style={tw("pr-1")}>
                          <FormFieldSwitch
                            value={isActive}
                            onValueChange={(isActive) => {
                              setPromoActions({ promoCodeID, memberCode });
                              modifyPromoCode({
                                promoCodeID,
                                memberCode,
                                isActive,
                              });
                            }}
                            disabled={!isEmpty(promoActions)}
                            testID={`toggle_${promoCode}_${startDate}_${endDate}`}
                            accessibilityLabel={`toggle_${promoCode}_${startDate}_${endDate}`}
                          />
                        </View>
                      )}
                      <LabelField
                        text={Localise(
                          messages,
                          isExpired
                            ? COMMON.EXPIRED
                            : isActive
                            ? COMMON.ACTIVE
                            : COMMON.INACTIVE
                        )}
                        style={tw("pb-0")}
                        testID={`status_${promoCode}_${startDate}_${endDate}`}
                        accessibilityLabel={`status_${promoCode}_${startDate}_${endDate}`}
                      />
                    </View>
                    <TouchableOpacity
                      onPress={() => {
                        setPromoActions({ promoCodeID, memberCode });
                        setShowConfirmModal(true);
                      }}
                      testID={`delete_${promoCode}_${startDate}_${endDate}`}
                      accessibilityLabel={`delete_${promoCode}_${startDate}_${endDate}`}
                      style={[
                        tw("flex justify-center items-center"),
                        { width: isMobile ? "30%" : "20%" },
                      ]}
                      disabled={!isEmpty(promoActions)}
                    >
                      <Image style={tw("w-5 h-5")} source={IMAGES["delete"]} />
                    </TouchableOpacity>
                  </>
                ) : (
                  <View style={tw("flex justify-center items-center w-full")}>
                    <ActivityIndicator
                      style={tw("mx-2")}
                      color={colors.activityIndicator}
                    />
                  </View>
                )}
              </View>
            </View>
          );
        })}
        {showViewMore && (
          <Button
            titleStyle={theme.Button.secondaryTitleStyle}
            buttonStyle={{
              ...theme.Button.secondaryButtonStyle,
              paddingHorizontal: 10,
              paddingVertical: 6,
              width: 95,
              height: 35,
            }}
            containerStyle={tw("items-end mx-0")}
            title={Localise(messages, "View More")}
            onPress={() => {
              setViewMoreCounts(viewMoreCounts + 10);
            }}
            testID="promo_code_view_more"
            accessibilityLabel="promo_code_view_more"
          />
        )}
      </View>

      {isEmpty(promoCodes) && (
        <Text
          style={tw("flex justify-center items-center")}
          testID="empty_promo_codes"
          accessibilityLabel="empty_promo_codes"
        >
          {Localise(messages, SHOP_SETTINGS.NO_PROMO_CODES)}
        </Text>
      )}
      <ConfirmModal
        modalVisible={showConfirmModal}
        handlePrimary={() => {
          deleteHandler(promoActions);
          setShowConfirmModal(false);
        }}
        handleSecondary={() => {
          setPromoActions({});
          setShowConfirmModal(false);
        }}
        data={{
          modal: {
            primary: COMMON.YES,
            secondary: COMMON.NO,
            content: Localise(
              messages,
              SHOP_SETTINGS.PROMO_CONFIRM_MODAL_CONTENT
            ),
          },
        }}
        contentStyle={tw("p-0 text-center")}
        isReduceBtnSpacing={true}
      />
    </View>
  );
};

export default PromoCodesList;
