import React, { useEffect } from "react";
import { Text } from "react-native-elements";
import { View, ActivityIndicator, TouchableOpacity, Image } from "react-native";
import { connect, useSelector } from "react-redux";
import tw from "tailwind-rn";
import toLower from "lodash/toLower";
import isNil from "lodash/isNil";
import moment from "moment";
import { ToasterHandler } from "components/elements";

import useStateIfMounted from "library/utils/useStateIfMounted";
import {
  fonts,
  backgroundColors,
  colors,
  orderStatusColorCodes,
} from "styles/theme";
import IMAGES from "static/assets/images";
import styles from "../styles";
import { generateContent } from "../helper";
import TransferForm from "components/views/drawer/order-details/order-head/take-action/transfer-form";
import { request } from "library/utils/request";
import { getMembersInfo } from "library/utils/createOrder";
import I18NContext from "library/contexts/i18N";
import { selectRecordData } from "library/sagas/ongoing/current-orders/selector";
import { phoneNumberFormatter } from "library/utils/formatter";

const FulfillmentInfo = ({
  index,
  triggerAction,
  recordAction = "",
  onConfirm,
  isSmallScreen,
  setFulfillmentPos,
  doNotFill,
  isTransferEligible,
  currentproduct,
  isPickUpOrder,
}) => {
  const orderDetailResponse = useSelector(selectRecordData);

  const orderDetails = orderDetailResponse.orderItems[index];
  const {
    status,
    sendingMember,
    receivingMember,
    lineItems,
    deliveryInfo: { deliveryDate, deliveryMethod },
    recipientInfo: { zip, addressLine1, suite, city, state, country },
    hasFulfillmentError = false,
    orderItemId,
    price,
    orderSource = "",
    createdOn: createdOnUTC,
    messages = [],
    direction,
    externalPartnerBusinessName = "",
    partnerId = "",
  } = orderDetails;

  const transferMessage =
    messages.find(
      (message) =>
        message.messageType === "Transfer" && message.status !== "ERROR"
    ) || {};
  const { forwardedMember = "" } = transferMessage;
  const [loading, setLoading] = useStateIfMounted(false);
  const [showTransferForm, setShowTransferForm] = useStateIfMounted(false);
  const [florists, setFlorists] = useStateIfMounted([]);
  const { messages: locMessages, Localise } = React.useContext(I18NContext);

  let orderSourceCategory;
  if (["FTD", "FTD_COM", "FTD_CA", "Retrans"].includes(orderSource)) {
    orderSourceCategory = "Incoming";
  }
  if (["MOL"].includes(orderSource)) {
    orderSourceCategory = "My Website";
  }
  // Currently API is sending member code as orderSource for Florist to Florist (F2F) orders so we are checking orderSource is member code or not using RegEx
  else if (orderSource.match(/^\d/)) {
    orderSourceCategory = "Incoming";
  } else {
    orderSourceCategory = orderSource;
  }

  const receivedDateAndTimeObj = createdOnUTC ? moment(createdOnUTC) : "";

  const isLocalOrder = deliveryMethod === "FLORIST_DELIVERED";
  const isStorePickupOrder = deliveryMethod === "STORE_PICKUP";
  const isWalkInOrder = deliveryMethod === "WALK_IN";
  const isPhoneOutOrder = deliveryMethod === "PHONE_OUT";
  const isMigratedFolMolOrder = partnerId === "FOL";
  //const isMOLOrder = orderSource === "MOL";
  if (isMigratedFolMolOrder) {
    orderSourceCategory = "My Website";
  }
  const orderSourceList = [
    "Bloomnet",
    "Teleflora",
    "TEL eFlorist",
    "Email Order",
    "FOL",
  ];

  const sendingMemberDetails = {
    "Member Code": sendingMember.memberCode,
    "Shop Name": sendingMember.businessName,
    Phone: phoneNumberFormatter(sendingMember.phone),
  };

  const actionInfo = recordAction.split("_");
  const showConfirmFlorist =
    actionInfo.length > 0
      ? actionInfo[0] === "floristSelected" && actionInfo[2] === orderItemId
      : false;

  const fillerDetails = showConfirmFlorist
    ? JSON.parse(actionInfo[1])
    : receivingMember;

  const {
    addressLine1: fillerAddressLine1 = "",
    city: fillerCity = "",
    state: fillterState = "",
    zip: fillerZip = "",
  } = fillerDetails;

  const deliveredByData = {
    Name: isNil(orderDetails.deliveryInfo.driver)
      ? isNil(orderDetails.deliveryInfo.operator)
        ? ""
        : orderDetails.deliveryInfo.operator
      : orderDetails.deliveryInfo.driver,
    Business:
      !isNil(orderDetails.deliveryInfo.deliveryType) &&
      orderDetails.deliveryInfo.deliveryType === "Delivery Service"
        ? "Delivery Service" // if Delivery Service delivered the order, then we should show “Delivery Service” as the business name
        : fillerDetails.businessName || fillerDetails.name,
  };

  const isOrderDelivered = ["DELIVERED"].includes(status);
  const isPastDate = moment(deliveryDate).isBefore(moment().add(-1, "days"));

  const updatedFlorists = florists.filter(
    (florist) => florist.memberCode !== sendingMember.memberCode
  );

  const { actualPrice } = lineItems.length ? lineItems[0] : {};
  const { value: updatedPrice } = price.length
    ? price.find((obj) => obj.name === "orderTotal")
    : {};

  useEffect(() => {
    if (!showTransferForm || florists.length) return;
    const requestParams = {
      startDeliveryDate: deliveryDate,
      endDeliveryDate: deliveryDate,
      productCode: currentproduct?.productId || "ARR",
      zipCode: zip,
      addressLine1: encodeURIComponent(addressLine1),
      suite: encodeURIComponent(suite),
      city: encodeURIComponent(city),
      state: state,
      country: country,
      sendingMemberCode: fillerDetails.memberCode,
      actualPrice: actualPrice || updatedPrice,
      updatedPrice,
    };
    setLoading(true);
    request("get-florists-new", requestParams)
      .then((res) => {
        setLoading(false);
        const { membersInfo } = getMembersInfo(res || []);
        setFlorists(
          membersInfo.map((memberInfo) => {
            const { name, memberCode } = memberInfo;
            return {
              memberCode,
              name,
            };
          })
        );
      })
      .catch((error) => {
        setFlorists([]);
        setLoading(false);
        console.log("Error: " + error);
      });
  }, [showTransferForm]);

  const customStyle = { ...fonts.style1, fontSize: 14, paddingVertical: 2 };

  return (
    <View
      onLayout={(event) => {
        setFulfillmentPos(showConfirmFlorist ? event.nativeEvent.layout.y : 0);
      }}
      pointerEvents={doNotFill ? "none" : "auto"}
      fsClass="fs-unmask"
    >
      <Text
        style={{
          fontSize: 15,
          color: colors.primary,
          fontWeight: "bold",
          paddingVertical: 5,
        }}
        testID="fulfillment_main_title"
      >
        {Localise(locMessages, "Fulfillment")}
      </Text>

      <View
        style={{
          backgroundColor: backgroundColors.secondary,
        }}
        fsClass="fs-unmask"
      >
        <View style={tw("flex flex-row mb-1 mt-1")}>
          <Text
            style={{ fontWeight: "bold", ...customStyle }}
            testID="fulfillment_sender_title"
          >
            {Localise(locMessages, "Sender")}
          </Text>
        </View>
        <View style={tw("flex flex-row")}>
          <Text
            style={{ ...fonts.default, ...styles.labelText, ...customStyle }}
          >
            {Localise(locMessages, "Order Source")}
          </Text>
          <View style={styles.valueText}>
            {!isPickUpOrder ? (
              <Text
                style={{
                  ...(orderSourceList.includes(orderSource) && {
                    textTransform: "none",
                  }),
                  ...customStyle,
                }}
              >
                {orderSourceList.includes(orderSource)
                  ? isMigratedFolMolOrder
                    ? orderSourceCategory
                    : orderSource
                  : isLocalOrder || isStorePickupOrder || isWalkInOrder
                  ? sendingMember.businessName
                  : orderSourceCategory}
              </Text>
            ) : (
              <View style={[tw("flex flex-row")]}>
                <Image
                  style={{
                    width: orderSource === "DoorDash" ? 120 : 90,
                    height: 15,
                  }}
                  resizeMode="cover"
                  source={IMAGES[`${toLower(orderSource)}`]}
                />
              </View>
            )}
          </View>
        </View>
        <View style={tw("flex flex-row")}>
          <Text
            style={{ ...fonts.default, ...styles.labelText, ...customStyle }}
          >
            {Localise(locMessages, "Date & Time Received")}
          </Text>
          <View style={styles.valueText}>
            <Text style={{ textTransform: "none", ...customStyle }}>
              {moment(receivedDateAndTimeObj).format("MM/DD/YY [at] hh:mm A")}
            </Text>
          </View>
        </View>
        {!isStorePickupOrder && !isWalkInOrder && (
          <View>
            {generateContent(
              sendingMemberDetails,
              true,
              true,
              {},
              {},
              customStyle,
              customStyle
            )}
          </View>
        )}
        <View style={tw("flex flex-row mb-1 mt-2")}>
          <Text
            style={{ fontWeight: "bold", ...customStyle }}
            testID="fulfillment_member_title"
          >
            {Localise(locMessages, "Filler")}
          </Text>
        </View>
        {!isPhoneOutOrder && (
          <View style={tw("flex flex-row items-center")}>
            <Text
              style={{ ...fonts.default, ...styles.labelText, ...customStyle }}
            >
              {Localise(locMessages, "Member Code")}
            </Text>
            <View style={[styles.valueText, tw("flex flex-row items-center")]}>
              <Text style={customStyle} testID="filler_member_code">
                {fillerDetails.memberCode}
              </Text>
              {isTransferEligible && !showTransferForm && (
                <TouchableOpacity
                  onPress={() => {
                    setShowTransferForm(true);
                  }}
                  style={tw("ml-4")}
                  testID="tansferIcon"
                  accessibilityLabel="tansferIcon"
                >
                  <Image
                    style={{ width: 18, height: 18 }}
                    resizeMode="cover"
                    source={IMAGES[`transfer`]}
                  />
                </TouchableOpacity>
              )}
              {loading && (
                <ActivityIndicator
                  style={{ marginLeft: 10 }}
                  color={colors.activityIndicator}
                />
              )}
            </View>
          </View>
        )}
        <View style={tw("flex flex-row")} fsClass="fs-unmask">
          <Text
            style={{ ...fonts.default, ...customStyle, ...styles.labelText }}
          >
            {Localise(locMessages, "Shop Name")}
          </Text>
          <View
            style={{
              ...tw(`flex flex-${isSmallScreen ? "col" : "row"}`),
              ...styles.valueText,
            }}
          >
            <Text style={customStyle}>
              {isPhoneOutOrder
                ? externalPartnerBusinessName
                : fillerDetails.businessName || fillerDetails.name || ""}
            </Text>
            {hasFulfillmentError && (
              <>
                {!showConfirmFlorist ? (
                  <View
                    style={[
                      tw("flex flex-row"),
                      !isSmallScreen ? tw("ml-4") : tw("my-1"),
                    ]}
                  >
                    <Image
                      style={{ width: 14, height: 14 }}
                      resizeMode="cover"
                      source={IMAGES[`replace-filler`]}
                    />
                    <Text
                      style={[
                        fonts.link1,
                        tw("ml-1"),
                        {
                          color: orderStatusColorCodes["fulfillment error"],
                        },
                      ]}
                      onPress={() => {
                        const sideCarInfo = {
                          memberCodes: [sendingMember.memberCode],
                          deliveryInfo: { deliveryDate },
                          lineItems,
                          recipientInfo: {
                            zip,
                            addressLine1,
                            suite,
                            city,
                            state,
                            country,
                          },
                          price,
                        };
                        if (!isPastDate) {
                          triggerAction({
                            action: `selectNewFlorist_${JSON.stringify(
                              sideCarInfo
                            )}_`,
                          });
                        } else {
                          ToasterHandler(
                            "uh oh",
                            Localise(
                              messages,
                              "Please change the delivery date before sending to a new florist"
                            )
                          );
                        }
                      }}
                    >
                      {Localise(locMessages, "Select New Florist")}
                    </Text>
                  </View>
                ) : (
                  <View
                    style={[
                      tw("flex flex-row"),
                      !isSmallScreen ? tw("ml-4") : tw("mt-1"),
                    ]}
                  >
                    <Text
                      style={[
                        fonts.link1,
                        tw("ml-1"),
                        {
                          color: orderStatusColorCodes["fulfillment error"],
                        },
                      ]}
                      onPress={() => {
                        onConfirm(status === "ERROR");
                      }}
                    >
                      {Localise(locMessages, "Confirm Florist")}
                    </Text>
                    <Text
                      style={[fonts.link1, tw("ml-4")]}
                      onPress={() => {
                        triggerAction({
                          action: `cancel`,
                        });
                      }}
                    >
                      {Localise(locMessages, "Cancel")}
                    </Text>
                  </View>
                )}
              </>
            )}
          </View>
        </View>
        {showTransferForm &&
          !loading &&
          (updatedFlorists.length > 0 ? (
            <TransferForm
              styles={styles}
              setShowTransferForm={setShowTransferForm}
              onSubmit={triggerAction}
              isSmallScreen={isSmallScreen}
              florists={updatedFlorists}
              loading={loading}
            />
          ) : (
            <View style={[tw("flex flex-row")]}>
              <Text style={{ ...fonts.default, ...styles.labelText }}></Text>
              <View style={styles.valueText}>
                <Text
                  style={[
                    {
                      ...fonts.error,
                      marginVertical: 0,
                      marginBottom: 8,
                      textAlign: "left",
                      ...customStyle,
                    },
                  ]}
                >
                  {Localise(locMessages, "No florists available for Transfer")}
                </Text>
              </View>
            </View>
          ))}
        {!!forwardedMember && direction === "INBOUND" && (
          <View
            style={[tw("flex flex-row"), { zIndex: 1 }]}
            fsClass="fs-unmask"
          >
            <Text
              style={{ ...fonts.default, ...styles.labelText, ...customStyle }}
            >
              {Localise(locMessages, "Transferred To")}
            </Text>
            <View style={styles.valueText}>
              <Text style={customStyle}>{forwardedMember}</Text>
            </View>
          </View>
        )}
        {!isPhoneOutOrder && (
          <View
            style={[tw("flex flex-row"), { zIndex: -1 }]}
            fsClass="fs-exclude"
          >
            <Text
              style={{ ...fonts.default, ...styles.labelText, ...customStyle }}
            >
              {Localise(locMessages, "Phone")}
            </Text>
            <View style={styles.valueText}>
              <Text style={customStyle}>
                {phoneNumberFormatter(fillerDetails.phone)}
              </Text>
            </View>
          </View>
        )}
        {!isPhoneOutOrder && (
          <View
            style={[tw("flex flex-row"), { zIndex: -1 }]}
            fsClass="fs-unmask"
          >
            <Text
              style={{ ...fonts.default, ...styles.labelText, ...customStyle }}
            >
              {Localise(locMessages, "Address")}
            </Text>
            <View style={styles.valueText}>
              <Text style={customStyle}>{fillerAddressLine1}</Text>
              <Text
                style={customStyle}
              >{`${fillerCity}, ${fillterState} ${fillerZip}`}</Text>
            </View>
          </View>
        )}
        {isOrderDelivered && (
          <View fsClass="fs-unmask">
            <View style={tw("flex flex-row mb-1 mt-2")}>
              <Text style={{ ...fonts.heading4, ...customStyle }}>
                {Localise(locMessages, "Delivered By")}
              </Text>
            </View>
            <View style={tw("mb-1 mt-0")}>
              {generateContent(
                deliveredByData,
                true,
                true,
                {},
                {},
                customStyle,
                customStyle
              )}
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    currentproduct: state.mhq.ongoing.global.currentproduct,
  };
};

export default connect(mapStateToProps, null)(FulfillmentInfo);
