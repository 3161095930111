import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import {
  Form,
  FormField,
  FormFieldPicker,
  SubmitButton,
} from "components/elements/forms";
import I18NContext from "library/contexts/i18N";
import SessionInfo from "../session-info";
import tw from "tailwind-rn";
import * as Yup from "yup";
import moment from "moment";
import {
  createOrEndSession,
  createTransaction,
} from "library/sagas/session/slice";
import { ToasterHandler } from "components/elements";
import UserProfileStorage from "library/storage/userProfile";
import { selectCreateOrderShopCode } from "library/sagas/views/home/drawer/create-order/selector";
import { memberEntitlements } from "library/utils/entitlements";

const currentTimeZone = moment.tz(moment.tz.guess()).format("z");

const OpenSession = (props) => {
  const { createOrEndSession, createTransaction, operator, memberCodes } =
    props;
  const { messages, Localise } = React.useContext(I18NContext);

  const standardMemberCodes = UserProfileStorage.getEntitlementAllMembers(
    memberEntitlements.MHQ_STANDARD
  );
  const essentialsMemberCodes = UserProfileStorage.getEntitlementAllMembers(
    memberEntitlements.MHQ_ESSENTIALS
  );
  const premiumMemberCodes = UserProfileStorage.getEntitlementAllMembers(
    memberEntitlements.MHQ_PREMIUM
  );
  const mergedMemberCodes = [
    ...standardMemberCodes,
    ...essentialsMemberCodes,
    ...premiumMemberCodes,
  ];

  const memberCodeOptions = mergedMemberCodes.map((memberCode) => {
    return { label: memberCode, value: memberCode };
  });

  const isMultimemberAccount = memberCodes.length > 1;
  const shopCode = useSelector(selectCreateOrderShopCode);

  const ValidationSchema = Yup.object().shape({
    openingBalance: Yup.string()
      .required(Localise(messages, "Enter Opening Balance"))
      .matches(/^\d+\.?\d*$/, Localise(messages, "Invalid Price")),
    memberId: Yup.string().required(Localise(messages, "Please select shop")),
  });

  const [validationOnChange, setValidationOnChange] = useState(false);

  return (
    <React.Fragment>
      <SessionInfo />
      <Form
        initialValues={{
          openingBalance: "",
          memberId: isMultimemberAccount ? shopCode : memberCodes[0],
        }}
        validateOnChange={validationOnChange}
        validateOnBlur={validationOnChange}
        onSubmit={(values, formikBag) => {
          const macAddress = document.getElementById("macAddress").value;
          createOrEndSession({
            params: {
              ...values,
              currentBalance: values.openingBalance,
              openedBy: operator,
              macAddress,
              status: "active",
            },
            resolve: (resp) => {
              const { openingBalance, memberId, openTimeStamp = "" } = resp;
              const eventTime = moment(openTimeStamp).format("hh:mm A");
              //Create an event to know session started
              createTransaction({
                params: {
                  amount: "0",
                  type: "Other",
                  memberId,
                  macAddress,
                  user: operator,
                  userNote: `Session started and opening balance of $${parseFloat(
                    openingBalance
                  ).toFixed(
                    2
                  )} entered by ${operator} at ${eventTime} ${currentTimeZone}`,
                  orderId: "",
                },
                resolve: (resp) => {
                  console.log(`Transaction created`);
                },
                reject: () => {
                  console.log(
                    `Failed to create transaction. Please try again.`
                  );
                },
              });
              ToasterHandler("success", Localise(messages, `Session created`));
            },
            reject: () => {
              ToasterHandler(
                "uh oh",
                Localise(messages, `Failed to create Session. Please try again`)
              );
              formikBag.setSubmitting(false);
            },
          });
        }}
        validationSchema={ValidationSchema}
        render={({ submitCount }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (submitCount > 0) {
              setValidationOnChange(true);
            }
          }, [submitCount]);
          return (
            <View style={tw("mt-8")}>
              {isMultimemberAccount && (
                <FormFieldPicker
                  placeholder={{ label: "Select Shop", value: "" }}
                  containerStyle={{ width: 200 }}
                  data={memberCodeOptions}
                  name="memberId"
                  label=""
                />
              )}
              <View style={tw("flex-row items-center")}>
                <FormField
                  name="openingBalance"
                  label={"Opening Balance"}
                  testID="opening_balance"
                  accessibilityLabel="opening_balance"
                  containerStyle={{
                    width: 120,
                  }}
                  isPrice={true}
                  iconType="material-community"
                  iconName="currency-usd"
                  iconSize={13}
                  inputContainerStyle={{
                    padding: 6,
                  }}
                  leftIconContainerStyle={{ paddingRight: 0 }}
                  keyboardType="numeric"
                  placeholder={Localise(messages, "0")}
                />
                <SubmitButton
                  title={Localise(messages, "Enter")}
                  containerStyle={{
                    maxWidth: 100,
                    margin: 0,
                    marginTop: 12,
                    marginLeft: 10,
                  }}
                  testID="Enter"
                  accessibilityLabel="Enter"
                />
              </View>
              <View>
                <Text style={{ fontStyle: "italic", paddingLeft: 5 }}>
                  {Localise(messages, "Enter Balance to start session")}
                </Text>
              </View>
            </View>
          );
        }}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOrEndSession,
      createTransaction,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(OpenSession);
