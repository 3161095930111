import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Image } from "react-native-elements";
import isEmpty from "lodash/isEmpty";
import I18NContext from "library/contexts/i18N";

import { fonts, backgroundColors } from "styles/theme";
import IMAGES from "static/assets/images";
import Environment from "../../../library/utils/environment";
import useStateIfMounted from "library/utils/useStateIfMounted";

const MarkerPopup = ({ popupTitle, lat, lng, setter, recipientAddress }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  return (
    <View style={styles.markerPopup}>
      <View style={{ width: "90%", justifyContent: "center" }}>
        <Text style={styles.markerPopupContent}>
          {Localise(messages, popupTitle)}:
        </Text>
        <View>
          {!isEmpty(recipientAddress) && (
            <>
              <Text style={styles.markerPopupContent}>
                {recipientAddress.addressLine1}
              </Text>
              <Text style={styles.markerPopupContent}>
                {recipientAddress.addressLine2}
              </Text>
            </>
          )}
          <Text style={styles.markerPopupContent}>
            {lat}, {lng}
          </Text>
        </View>
      </View>
      <View style={{ width: "10%" }}>
        <TouchableOpacity
          onPress={() => setter(false)}
          testID="close"
          accessibilityLabel="close"
        >
          <Image
            style={{ width: 20, height: 20 }}
            resizeMode="cover"
            source={IMAGES["close"]}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const FloristMarker = ({ content = "" }) => (
  <View style={styles.floristMarker}>
    <Text style={styles.floristContent}>{content}</Text>
  </View>
);

const ShopMarker = ({
  marker = "",
  lat,
  lng,
  recipientAddress,
  showMarkerPopup,
}) => {
  if (marker !== "map-delivery-location") {
    return (
      <View style={styles.recipientMarker}>
        <Image style={styles.recipientContent} source={IMAGES[marker]} />
      </View>
    );
  }

  /* eslint-disable react-hooks/rules-of-hooks */
  const [showShopMarkerPopup, setShowShopMarkerPopup] =
    useStateIfMounted(false);

  return (
    <>
      <TouchableOpacity
        onPress={() => setShowShopMarkerPopup(true)}
        testID="close"
        accessibilityLabel="close"
      >
        <View style={styles.recipientMarker}>
          <Image style={styles.recipientContent} source={IMAGES[marker]} />
        </View>
      </TouchableOpacity>
      {showShopMarkerPopup && showMarkerPopup && (
        <MarkerPopup
          popupTitle="Recipient Location"
          lat={lat}
          lng={lng}
          recipientAddress={recipientAddress}
          setter={setShowShopMarkerPopup}
        />
      )}
    </>
  );
};

const DriverMarker = ({ marker = "", lat, lng, showMarkerPopup }) => {
  const [showDriverPopup, setShowDriverPopup] = useStateIfMounted(false);

  return (
    <>
      <TouchableOpacity
        onPress={() => setShowDriverPopup(true)}
        testID="close"
        accessibilityLabel="close"
      >
        <View style={styles.driverMarker}>
          <Image style={styles.driverContent} source={IMAGES[marker]} />
        </View>
      </TouchableOpacity>
      {showDriverPopup && showMarkerPopup && (
        <MarkerPopup
          popupTitle="Device Location"
          lat={lat}
          lng={lng}
          setter={setShowDriverPopup}
        />
      )}
    </>
  );
};

const Maps = ({
  mapMarkers,
  zoomLevel = 10,
  mapHeight = 400,
  recipientAddress,
  showMarkerPopup = false,
}) => {
  const {
    locations = [],
    recipientLatLong = {},
    driverLatLong = {},
  } = mapMarkers;

  const { recipientLatitude, recipientLongitude } = recipientLatLong;
  const { driverLatitude, driverLongitude } = driverLatLong;

  const { latitude: firstShopLatitude, longitude: firstShopLongitude } =
    locations[0];
  const [showTouchableView, setShowTouchableView] = useState(true);
  const [touchableAlpha, setTouchableAlpa] = useState(0);

  const googleMapsAPIKey = Environment.get(
    "GOOGLE_MAPS_API_KEY",
    "AIzaSyAPMdYXaLpwQM1nZIjr_GJyNKLe5ZLKzFU"
  );

  return (
    <>
      <View
        style={{ ...styles.mapsContainer, height: mapHeight }}
        onMouseLeave={() => {
          setShowTouchableView(true);
          setTouchableAlpa(0);
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleMapsAPIKey }}
          center={{
            lat: recipientLatitude ? recipientLatitude : firstShopLatitude,
            lng: recipientLongitude ? recipientLongitude : firstShopLongitude,
          }}
          defaultZoom={zoomLevel}
        >
          {locations.length > 0 &&
            locations.map(
              ({ latitude, longitude, isFillingFlorist = false }, index) => {
                return isFillingFlorist ? (
                  <ShopMarker
                    key={index}
                    lat={latitude}
                    lng={longitude}
                    marker="map-shop"
                  />
                ) : (
                  <FloristMarker
                    key={index}
                    lat={latitude}
                    lng={longitude}
                    content={index + 1}
                  />
                );
              }
            )}
          {!!(recipientLatitude && recipientLongitude) && (
            <ShopMarker
              lat={recipientLatitude}
              lng={recipientLongitude}
              recipientAddress={recipientAddress}
              marker="map-delivery-location"
              showMarkerPopup={showMarkerPopup}
            />
          )}
          {!!(driverLatitude && driverLongitude) && (
            <DriverMarker
              lat={driverLatitude}
              lng={driverLongitude}
              marker="map-delivery-device-location"
              showMarkerPopup={showMarkerPopup}
            />
          )}
        </GoogleMapReact>
      </View>
      {showTouchableView && (
        <TouchableOpacity
          style={[
            styles.mapsContainer,
            {
              height: mapHeight,
              backgroundColor: "black",
              opacity: touchableAlpha,
              position: "absolute",
              justifyContent: "center",
            },
          ]}
          onMouseEnter={() => setTouchableAlpa(0.5)}
          onMouseLeave={() => setTouchableAlpa(0)}
          onPress={() => setShowTouchableView(false)}
        >
          <Text
            style={{
              fontSize: 40,
              fontWeight: "600",
              color: backgroundColors.secondary,
              textAlign: "center",
            }}
          >
            Click Here To Drag
          </Text>
        </TouchableOpacity>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  mapsContainer: { height: 400, width: "100%" },
  floristMarker: {
    height: 25,
    width: 25,
    backgroundColor: backgroundColors.primary2,
    borderRadius: 15,
  },
  floristContent: {
    color: backgroundColors.secondary,
    textAlign: "center",
    lineHeight: 25,
    fontFamily: fonts.fontFamily.default,
  },
  recipientMarker: {
    height: 45,
    width: 45,
  },
  recipientContent: { width: 45, height: 45 },
  driverMarker: {
    width: 45,
    height: 45,
  },
  driverContent: { width: 45, height: 45 },
  markerPopup: {
    backgroundColor: backgroundColors.secondary,
    borderWidth: 1,
    borderColor: backgroundColors.primary,
    flexDirection: "row",
    width: 240,
    height: 100,
    padding: 10,
    position: "absolute",
    left: 50,
    top: -70,
  },
  markerPopupContent: {
    color: backgroundColors.primary,
    fontSize: 16,
  },
});

export default Maps;
