import React, { useContext } from "react";
import { View } from "react-native";
import I18NContext from "library/contexts/i18N";
import { useFormikContext } from "formik";
import { DeviceContext } from "library/contexts/appSettings";
import { Section } from "./helper";
import {
  singleDSRequestCreateSections,
  DSRequestDetailsSections,
} from "./ui-config";

const DeliveryTaskInfo = ({
  screen = "modify",
  modify = true,
  copy = false,
}) => {
  const { messages, Localise } = useContext(I18NContext);
  const { isDesktop } = useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const eligibleSections =
    screen === "create"
      ? singleDSRequestCreateSections
      : DSRequestDetailsSections;
  const { setFieldValue, values, setValues } = useFormikContext();

  return (
    <>
      {eligibleSections.map((section, index) => {
        const { Component, name, accordionWrap, overrideZindex } = section;
        const DetailsComponent = (
          <Component
            Localise={Localise}
            messages={messages}
            setFieldValue={setFieldValue}
            isSmallScreen={isSmallScreen}
            values={values}
            setValues={setValues}
            modify={modify}
            copy={copy}
          />
        );
        if (accordionWrap)
          return (
            <Section
              title={Localise(messages, name)}
              overrideZindex={overrideZindex}
              modify={modify}
              key={index}
            >
              {DetailsComponent}
            </Section>
          );
        return (
          <View
            key={index}
            style={{
              padding: 0,
              zIndex: overrideZindex ? overrideZindex : -1,
            }}
            pointerEvents={modify ? "auto" : "none"}
          >
            {DetailsComponent}
          </View>
        );
      })}
    </>
  );
};

export default DeliveryTaskInfo;
