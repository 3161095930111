import React from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";
import tw from "tailwind-rn";
import moment from "moment";

import I18NContext from "library/contexts/i18N";
import { digitalGiftCardProducts } from "library/utils/giftCardProducts";
import { generateDetailContent } from "../helper";

import { selectRecordData } from "library/sagas/ongoing/current-orders/selector";

const WalkInOrderInfo = ({ index, doNotFill }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const orderDetailResponse = useSelector(selectRecordData);
  const orderDetails = orderDetailResponse.orderItems[index];

  const {
    deliveryInfo: { deliveryDate },
    pickupInfo = {},
    lineItems = [],
  } = orderDetails;

  const isGiftCardDigitalProduct = digitalGiftCardProducts.includes(
    lineItems[0]?.productFirstChoiceCode
  );

  const { storePickupDateTime = "" } = pickupInfo;

  const date = deliveryDate
    ? moment(storePickupDateTime || deliveryDate)
    : moment();

  const walkInOrderData = {
    Date: { label: "Date", value: date.format("MM/DD/YY") },
    DeliveryType: {
      label: "Delivery Type",
      value: Localise(
        messages,
        isGiftCardDigitalProduct ? "Digital" : "Walk-In"
      ),
    },
    Time: { label: "Time", value: date.format("h:mm A") },
  };

  return (
    <View
      // style={{
      //   opacity: doNotFill ? 0.4 : 1,
      // }}
      pointerEvents={doNotFill ? "none" : "auto"}
    >
      <View style={tw("m-1")}>
        {generateDetailContent(walkInOrderData, true)}
      </View>
    </View>
  );
};

export default WalkInOrderInfo;
