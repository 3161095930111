import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text } from "react-native";

import { ToasterHandler, Spinner } from "components/elements";
import { Form } from "components/elements/forms";
import {
  HTMLTextEditor,
  SEOTextEditor,
  BannerMetaData,
  AnnouncementBanner,
  SaveCancelButtons,
  SortableNavigation,
  BotDetectionRules,
  ShopsNotInGlobalSettings,
} from "../helper";
import {
  SecondaryButton,
  PrimaryButton,
  ConfirmModal,
} from "components/wrappers";
import { getValidationSchema } from "./yup";
import I18NContext from "library/contexts/i18N";

import {
  selectApiResponse,
  selectActions,
  selectScheduledContentScreen,
} from "library/sagas/views/home/drawer/ftd-admin/mol-settings/selector";
import {
  fetchMiddlewareData,
  saveMiddlewareData,
  setScheduledContentScreenTabs,
  setComponentStatus,
  deleteMiddlewareData,
} from "library/sagas/views/home/drawer/ftd-admin/mol-settings/slice";

import { theme } from "styles/theme";
import tw from "tailwind-rn";
import moment from "moment";

const Input = ({
  component,
  context,
  assetId,
  title,
  fieldType,
  fieldProps,
}) => {
  const dispatch = useDispatch();
  const {
    fetching,
    component: current,
    content,
    error,
    scheduledContent = "",
  } = useSelector(selectApiResponse);
  const [showModal, setShowModal] = useState(false);

  const {
    components: scheduledContentComponents,
    activeTab,
    tabs = [],
  } = useSelector(selectScheduledContentScreen);

  const isFutureTabExists =
    tabs.findIndex((val) => val.key === "scheduled-content") > -1;
  const { messages, Localise } = React.useContext(I18NContext);
  const { selectedMembers = [], selectAll = false } =
    useSelector(selectActions);

  const validationSchema = getValidationSchema(
    component,
    title,
    Localise,
    messages,
    activeTab
  );

  useEffect(() => {
    dispatch(fetchMiddlewareData({ component, context, assetId }));
    dispatch(setScheduledContentScreenTabs({ value: "default-content" }));
  }, [component]);

  if (fetching || component != current)
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );

  const onSubmit = (formValues, formikBag) => {
    const { scheduledContent, assetId = "" } = formValues;
    let values = formValues;
    if (assetId === "json") {
      const { startDate = "", endDate = "" } = JSON.parse(scheduledContent);
      values =
        activeTab === "scheduled-content" && !startDate && !endDate
          ? {
              ...formValues,
              scheduledContent: JSON.stringify({
                ...JSON.parse(scheduledContent),
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
              }),
            }
          : formValues;
    }

    dispatch(
      saveMiddlewareData({
        params: {
          values,
          selectedMembers,
          type:
            activeTab === "scheduled-content"
              ? "scheduled-content"
              : "default-content",
        },
        resolve: () => {
          ToasterHandler(
            "looks good",
            Localise(messages, `${title} content has been updated.`)
          );
          formikBag.setSubmitting(false);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  };

  return (
    <Form
      initialValues={{
        component,
        context,
        assetId,
        content,
        scheduledContent: scheduledContent === "" ? content : scheduledContent,
        error,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      render={({
        dirty,
        initialValues: {
          content: initialDefaultContent,
          scheduledContent: initialScheduledContent,
        },
        values,
        ...formikBag
      }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          dispatch(setComponentStatus({ component, dirty }));
        }, [dirty]);
        const {
          content: changedDefaultContent,
          scheduledContent: changedScheduledContent,
        } = values;
        const triggerScheduledContentModal =
          initialScheduledContent !== changedScheduledContent &&
          (activeTab === "default-content" || activeTab === "select-shops");
        const triggerActiveContentModal =
          initialDefaultContent !== changedDefaultContent &&
          (activeTab === "scheduled-content" || activeTab === "select-shops");

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (
            (triggerActiveContentModal || triggerScheduledContentModal) &&
            dirty
          )
            setShowModal(!showModal);
        }, [triggerActiveContentModal, triggerScheduledContentModal, dirty]);
        return (
          <>
            <View style={tw("flex flex-col")}>
              {activeTab !== "select-shops" && (
                <>
                  {scheduledContentComponents.includes(component) &&
                    scheduledContent === "" &&
                    activeTab === "default-content" &&
                    !isFutureTabExists && (
                      <SecondaryButton
                        title={"Create Temporary Content"}
                        action={() => {
                          dispatch(
                            setScheduledContentScreenTabs({
                              type: "add",
                              value: {
                                key: "scheduled-content",
                                title: "Temporary Content",
                              },
                            })
                          );
                        }}
                        style={tw("flex flex-row items-start justify-end pb-1")}
                      />
                    )}
                  {activeTab === "scheduled-content" && (
                    <SecondaryButton
                      title={"Delete Temporary Content"}
                      action={() => {
                        dispatch(
                          deleteMiddlewareData({
                            params: {
                              component,
                              context,
                              assetId,
                              type: "scheduled-content",
                            },
                            resolve: () => {
                              ToasterHandler(
                                "looks good",
                                Localise(
                                  messages,
                                  `Temporary Content is deleted successfully`
                                )
                              );
                            },
                            reject: () => {
                              ToasterHandler(
                                "uh oh",
                                Localise(
                                  messages,
                                  "We were unable to process your request, please try again."
                                )
                              );
                            },
                          })
                        );
                        dispatch(
                          setScheduledContentScreenTabs({
                            type: "delete",
                            value: {
                              key: "scheduled-content",
                              title: "Temporary Content",
                            },
                          })
                        );
                      }}
                      style={tw("flex flex-row items-start justify-end pb-1")}
                    />
                  )}

                  {fieldType === "HTMLText" && (
                    <HTMLTextEditor field="content" />
                  )}
                  {fieldType === "BannerMetaData" && (
                    <BannerMetaData
                      field={
                        activeTab === "scheduled-content"
                          ? "scheduledContent"
                          : "content"
                      }
                      props={fieldProps}
                    />
                  )}
                  {fieldType === "Navigation" && (
                    <SortableNavigation
                      field={
                        activeTab === "scheduled-content"
                          ? "scheduledContent"
                          : "content"
                      }
                      props={fieldProps}
                      context={context}
                    />
                  )}
                  {fieldType === "bot-detection-rules" && (
                    <BotDetectionRules field="content" />
                  )}
                  {fieldType === "SEOText" && <SEOTextEditor field="content" />}
                  {fieldType === "AnnouncementBanner" && (
                    <AnnouncementBanner
                      field={
                        activeTab === "scheduled-content"
                          ? "scheduledContent"
                          : "content"
                      }
                      context={context}
                    />
                  )}
                  <Text style={theme.CheckBox.errorStyle}>
                    {Localise(messages, error)}
                  </Text>
                  <SaveCancelButtons
                    saveScreen={activeTab === "select-shops"}
                    context={context}
                  />
                </>
              )}
              {activeTab === "select-shops" && (
                <>
                  <ShopsNotInGlobalSettings />
                  <PrimaryButton
                    title={"Revert To Global"}
                    style={tw(
                      "flex flex-row items-start justify-end ml-5 my-5 pb-1"
                    )}
                    disabled={selectedMembers.length === 0}
                    action={() => {
                      dispatch(
                        deleteMiddlewareData({
                          params: {
                            component,
                            context,
                            assetId,
                            selectedMembers: selectAll
                              ? ["*"]
                              : selectedMembers,
                            type: "revert-to-global",
                          },
                          resolve: () => {
                            ToasterHandler(
                              "looks good",
                              Localise(messages, `content has set FTD default.`)
                            );
                          },
                          reject: () => {
                            ToasterHandler(
                              "uh oh",
                              Localise(
                                messages,
                                "We were unable to process your request, please try again."
                              )
                            );
                          },
                        })
                      );
                    }}
                  />
                </>
              )}
              <ConfirmModal
                modalVisible={!!showModal}
                handlePrimary={() => {
                  activeTab === "scheduled-content" &&
                    dispatch(
                      setScheduledContentScreenTabs({
                        type: "delete",
                        value: {
                          key: "scheduled-content",
                          title: "Temporary Content",
                        },
                      })
                    );
                  dispatch(
                    setScheduledContentScreenTabs({
                      value:
                        activeTab === "default-content"
                          ? "scheduled-content"
                          : "default-content",
                    })
                  );
                  setShowModal(!showModal);
                }}
                handleSecondary={() => {
                  setShowModal(!showModal);
                  formikBag.resetForm();
                }}
                data={{
                  modal: {
                    primary: "Save",
                    content: `Please save the content before you switch to next tab`,
                  },
                }}
              />
            </View>
          </>
        );
      }}
    />
  );
};

export default Input;
