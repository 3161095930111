import * as React from "react";
import { View } from "react-native";
import { Text, Image } from "react-native-elements";
import { PopupMenu } from "components/elements";
import tw from "tailwind-rn";
import IMAGES from "static/assets/images";
import { sortOptions } from "./config";
import I18NContext from "library/contexts/i18N";
import useStateIfMounted from "library/utils/useStateIfMounted";

const ProductCatalogSortComponent = ({
  isSmallScreen,
  products: productsData,
  updateProducts,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const [selectedOption, setSelectedOption] = useStateIfMounted("");
  const products = [...productsData];
  const onSort = (sortOption) => {
    setSelectedOption(sortOption);
    const { order, fieldName } = sortOptions.find(
      (option) => option.key === sortOption
    );
    if (sortOption.includes("Price")) {
      let temp = products.sort((a, b) => {
        return parseFloat(a[fieldName]) - parseFloat(b[fieldName]);
      });
      updateProducts(order === "Asc" ? temp : temp.reverse());
    } else if (sortOption.includes("Product Name")) {
      let temp = products.sort((a, b) => {
        const nameA = a[fieldName];
        const nameB = b[fieldName];
        return nameA.localeCompare(nameB);
      });

      updateProducts(order === "Asc" ? temp : temp.reverse());
    } else if (sortOption.includes("Product Code")) {
      const temp = products.sort((a, b) => {
        const pidA = a[fieldName];
        const pidB = b[fieldName];
        return pidA.localeCompare(pidB, "en", { numeric: true });
      });
      updateProducts(order === "Asc" ? temp : temp.reverse());
    }
  };

  return (
    <PopupMenu
      menuOptions={sortOptions}
      containerStyle={tw(`flex-row justify-end items-center`)}
      optionsContainerStyle={{
        width: 215,
        padding: 5,
      }}
      onSelect={onSort}
      testID="sort"
      accessibilityLabel="sort"
      selectedValue={selectedOption}
    >
      <View style={tw(`flex-row justify-end items-center`)}>
        <Image
          style={{
            width: 18,
            height: 18,
          }}
          resizeMode="cover"
          source={IMAGES["sort"]}
        />
        {!isSmallScreen && <Text>{Localise(messages, "Sort")}</Text>}
      </View>
    </PopupMenu>
  );
};

export default ProductCatalogSortComponent;
