import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";
import uniq from "lodash/uniq";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectApiResponse = createSelector(
  selectState,
  ({ apiResponse }) => apiResponse
);

export const selectExtendedScreen = createSelector(
  selectState,
  ({ extendedScreen }) => extendedScreen
);
export const selectAllCollections = createSelector(
  selectState,
  ({ collections }) => collections
);

export const selectScheduledContentScreen = createSelector(
  selectState,
  ({ scheduledContentScreen }) => scheduledContentScreen
);

export const selectIsScheduledContentComponent = (component) =>
  createSelector(selectScheduledContentScreen, ({ components }) =>
    components.includes(component)
  );

export const selectLandingPageComponents = createSelector(
  selectState,
  ({ landingPageComponents = [] }) => landingPageComponents
);

export const selectIsLandingPageComponent = (component) =>
  createSelector(selectLandingPageComponents, (components) => {
    return components.includes(component);
  });

export const selectActions = createSelector(
  selectScheduledContentScreen,
  ({ actions = {} }) => actions
);

export const selectComponentStatus = createSelector(
  selectState,
  ({ componentStatus = {} }) => componentStatus
);

export const selectLanguages = createSelector(
  selectState,
  ({ languages = [] }) => ({ languages })
);

export const selectTranslatableComponents = createSelector(
  selectState,
  ({ landingPageComponents = [], translatableComponents = {} }) =>
    uniq(Object.keys(translatableComponents).concat(landingPageComponents))
);

export const selectIsTranslatableComponent = (component) =>
  createSelector(selectTranslatableComponents, (components) => {
    return components.includes(component);
  });

export const selectTranslatableKeys = (component) =>
  createSelector(selectState, ({ translatableComponents = {} }) => {
    const name = Object.keys(translatableComponents).find((key) => {
      const modifiedComponent =
        component === "sympathyCollections" ? "funeralSympathy" : component;
      return modifiedComponent.includes(key);
    });
    return translatableComponents[name];
  });

export const selectSearchText = createSelector(
  selectActions,
  ({ searchText = "" }) => searchText
);

export const selectStoreSync = createSelector(
  selectState,
  ({ storeSync = {} }) => storeSync
);
export const selectSemRush = createSelector(
  selectState,
  ({ semRush = {} }) => semRush
);

export const selectSyncStoreStatus = createSelector(
  selectStoreSync,
  ({ syncStatus = "" }) => syncStatus
);

export const selectAppInstallationStatus = createSelector(
  selectStoreSync,
  ({ appStatus = "" }) => appStatus
);

export const selectCheckoutAppInstallationStatus = createSelector(
  selectStoreSync,
  ({ checkoutAppStatus = "" }) => checkoutAppStatus
);

export const selectAppInstallationUrl = createSelector(
  selectStoreSync,
  ({ appInstallUrl = "" }) => appInstallUrl
);

export const selectCheckoutAppInstallUrl = createSelector(
  selectStoreSync,
  ({ checkoutAppInstallUrl = "" }) => checkoutAppInstallUrl
);

export const selectMemberResults = createSelector(
  selectStoreSync,
  ({ memberResults = [] }) => memberResults
);
export const selectMemberSearchText = createSelector(
  selectStoreSync,
  ({ memberSearchText = "" }) => memberSearchText
);

export const selectMemberSearchError = createSelector(
  selectStoreSync,
  ({ memberSearchError = "" }) => memberSearchError
);
export const selectSemRushMemberResults = createSelector(
  selectSemRush,
  ({ memberResults = [] }) => memberResults
);
export const selectSemRushMemberSearchText = createSelector(
  selectSemRush,
  ({ memberSearchText = "" }) => memberSearchText
);

export const selectSemRushMemberSearchError = createSelector(
  selectSemRush,
  ({ memberSearchError = "" }) => memberSearchError
);
export const selectSemRushStatus = createSelector(
  selectSemRush,
  ({ status = "" }) => status
);

export const selectFuneralPageData = createSelector(
  selectState,
  ({ funeralSympathyPage = {} }) => funeralSympathyPage
);

export const selectFuneralAccordions = createSelector(
  selectFuneralPageData,
  ({ accordions = [] }) => accordions
);

export const selectFuneralTabs = createSelector(
  selectFuneralPageData,
  ({ tabs = "" }) => tabs
);

export const selectWeddingPageData = createSelector(
  selectState,
  ({ wedding = {} }) => wedding
);

export const selectWeddingPageAccordions = createSelector(
  selectWeddingPageData,
  ({ accordions = [] }) => accordions
);

export const selectWeddingTabs = createSelector(
  selectWeddingPageData,
  ({ tabs = "" }) => tabs
);

export const selectParentComponent = (component) =>
  createSelector(selectLandingPageComponents, (landingPages) =>
    landingPages.includes(component)
      ? component === "funeralSympathy" || component === "wedding"
        ? ""
        : component.includes("funeral") || component.includes("sympathy")
        ? "funeralSympathy"
        : "wedding"
      : ""
  );

export const selectLandingPageAccordions = (component) =>
  component.includes("funeral") || component.includes("sympathy")
    ? selectFuneralAccordions
    : selectWeddingPageAccordions;

export const selectLandingPageAccordionContent = (component) =>
  createSelector(selectLandingPageAccordions(component), (accordions) =>
    accordions.find((val) => val.component === component)
  );
