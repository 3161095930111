import React, { useState, useEffect } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { ToasterHandler } from "components/elements";
import { DeviceContext } from "library/contexts/appSettings";
import {
  setProductSelection,
  setViewMore,
} from "library/sagas/views/home/drawer/product-catalog/catalog/slice";
import {
  setProductsAction,
  resetProductsAction,
} from "library/sagas/views/home/drawer/product-catalog/collection/slice";

import {
  selectCatalogLimit,
  selectPageData,
  selectProductsSelected,
} from "library/sagas/views/home/drawer/product-catalog/catalog/selector";
import { selectProductsSortOrder } from "library/sagas/views/home/drawer/product-catalog/collection/selector";
import { DraggableProductsContainer } from "../collection/products";
import { Widget } from "../helper";
import I18NContext from "library/contexts/i18N";

import tw from "tailwind-rn";

const Products = ({ handle = "", loading, setLoading }) => {
  const dispatch = useDispatch();
  const { Localise, messages } = React.useContext(I18NContext);

  const data = useSelector(selectPageData);

  const selectedProducts = useSelector(selectProductsSelected);
  const { sortOrder } = useSelector(selectProductsSortOrder);

  const { isMobile, isDesktop, isTablet } = React.useContext(DeviceContext);
  const draggableUI =
    !isMobile && handle === "addons" && sortOrder === "manually";
  const maxWidth = isDesktop ? 996 : isTablet ? 676 : 332;

  const currentLimit = useSelector(selectCatalogLimit);
  const [baseLimit, setBaseLimit] = useState(0);

  const catalogSlice = data.slice(0, currentLimit);
  const displayProducts = catalogSlice
    .concat(Array.from(Array(baseLimit)).map(() => ""))
    .slice(0, currentLimit);

  useEffect(() => {
    if (sortOrder === "manually" && handle === "addons") {
      setBaseLimit(data.length);
    } else {
      setBaseLimit(isMobile ? 8 : 24);
    }
  }, [sortOrder, data.length]);

  useEffect(() => {
    dispatch(setViewMore({ actual: baseLimit }));
  }, [baseLimit]);

  return (
    <View style={[tw(`flex flex-col justify-between`), { minHeight: 55 }]}>
      <View fsClass="fs-unmask" style={{ maxWidth }}>
        <View style={tw("flex flex-col")}>
          <View
            style={[
              tw(`flex flex-row flex-wrap`),
              { opacity: loading ? 0.5 : 1 },
            ]}
          >
            {draggableUI ? (
              <DraggableProductsContainer
                handle={handle}
                data={displayProducts}
                containerWidth={maxWidth}
                loading={loading}
                selected={selectedProducts}
                onSelect={(e) =>
                  dispatch(setProductSelection({ productId: e }))
                }
                onDragEnd={({ newOrder }) => {
                  setLoading(true);
                  dispatch(
                    setProductsAction({
                      section: "current",
                      type: "sortOrder",
                      value: "manually",
                      extraValue: `dragToPos::${newOrder}`,
                      resolve: (message) => {
                        ToasterHandler(
                          "nice",
                          Localise(
                            messages,
                            message
                              ? message
                              : "Sort order updated successfully"
                          )
                        );

                        setLoading(false);

                        dispatch(
                          resetProductsAction({
                            section: "current",
                            type: "sortOrder",
                            value: "manually",
                          })
                        );
                      },
                      reject: (message) => {
                        ToasterHandler(
                          "oh oh",
                          Localise(
                            messages,
                            message
                              ? message
                              : "We were unable to process your request, please try again."
                          )
                        );

                        setLoading(false);

                        dispatch(
                          resetProductsAction({
                            section: "current",
                            type: "sortOrder",
                            value: "manually",
                          })
                        );
                      },
                    })
                  );
                }}
              />
            ) : (
              <>
                {displayProducts.map((productId, index) => {
                  const selected = selectedProducts.find(
                    (p) => p === productId
                  );
                  return (
                    <Widget
                      key={index}
                      type="product"
                      productId={productId}
                      style={{ marginRight: 6 }}
                      selected={selected}
                      onSelect={(e) =>
                        dispatch(setProductSelection({ productId: e }))
                      }
                    />
                  );
                })}
              </>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default Products;
