/* eslint-disable no-underscore-dangle, no-use-before-define */

import React from "react";
import { View, StyleSheet } from "react-native";

import { Avatar, Day } from "react-native-gifted-chat";
import Bubble from "./chat-bubble";

export default class Message extends React.Component {
  getInnerComponentProps() {
    const { containerStyle, ...props } = this.props;
    return {
      ...props,
      position: "left",
    };
  }

  renderDay() {
    if (this.props.currentMessage.createdAt) {
      const dayProps = this.getInnerComponentProps();
      if (this.props.renderDay) {
        return this.props.renderDay(dayProps);
      }
      return <Day {...dayProps} />;
    }
    return null;
  }

  renderBubble() {
    const bubbleProps = this.getInnerComponentProps();
    if (this.props.renderBubble) {
      return this.props.renderBubble(bubbleProps);
    }
    return <Bubble {...bubbleProps} />;
  }

  renderAvatar() {
    const avatarProps = this.getInnerComponentProps();
    return (
      <Avatar
        {...avatarProps}
        imageStyle={{
          left: [styles.slackAvatar, avatarProps.imageStyle],
          right: [styles.slackAvatar, avatarProps.imageStyle],
        }}
      />
    );
  }

  render() {
    const isOutBound = this.props.currentMessage.direction === "OUTBOUND";

    return (
      <View>
        {this.renderDay()}
        <View
          style={[
            styles.container,
            this.props.containerStyle,
            {
              flexDirection: isOutBound ? "row-reverse" : "row",
            },
          ]}
        >
          {this.renderAvatar()}
          {this.renderBubble()}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    //flexDirection: "row-reverse",
    alignItems: "flex-start",
    marginBottom: 15,
  },
  slackAvatar: {
    // The bottom should roughly line up with the first line of message text.
    height: 40,
    width: 40,
    borderRadius: 20,
  },
});

Message.defaultProps = {
  renderAvatar: undefined,
  renderBubble: null,
  renderDay: null,
  currentMessage: {},
  nextMessage: {},
  previousMessage: {},
  user: {},
  containerStyle: {},
};
