import React from "react";
import { View, Image } from "react-native";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";

import IMAGES from "static/assets/images";
import { Tooltip } from "components/elements";

import I18NContext from "library/contexts/i18N";
import Environment from "library/utils/environment";
import { DeviceContext } from "library/contexts/appSettings";

const AddressVerificationIndicator = ({
  containerStyle,
  isVerified,
  verifiedText = "",
  unVerfifiedText = "",
}) => {
  const { isDesktop } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const showAvsIndicator = Environment.get("SHOW_AVS_INDICATOR", false);
  const isSmallScreen = !isDesktop;

  if (!showAvsIndicator) return null;

  return isVerified ? (
    <View style={[tw("flex flex-row items-center"), containerStyle]}>
      <Tooltip
        text={"FTD has verified this address"}
        isSmallScreen={isSmallScreen}
      >
        <View style={tw("flex justify-center flex-row")}>
          <Image
            containerStyle={tw("flex")}
            style={{ width: 17, height: 17 }}
            resizeMode="cover"
            source={IMAGES["check-mark-green-fill-color"]}
          />
          <Text
            style={
              (tw("ml-1"), { color: "#4aa779", paddingLeft: 4, paddingTop: 1 })
            }
          >
            {Localise(messages, verifiedText || "Address FTD Verified")}
          </Text>
        </View>
      </Tooltip>
    </View>
  ) : (
    <View style={[tw("flex flex-row items-center"), containerStyle]}>
      <Tooltip
        text={Localise(
          messages,
          "FTD was not able to verify this address. Please confirm address with the florist partner, customer, or recipient"
        )}
        isSmallScreen={isSmallScreen}
        height={160}
      >
        <View style={tw("flex flex-row items-center")}>
          <Image
            containerStyle={tw("absolute")}
            style={{ width: 17, height: 17 }}
            resizeMode="cover"
            source={require("static/assets/address-not-verified-exclamation.png")}
          />
          <Text style={tw("ml-1")}>
            {Localise(messages, unVerfifiedText || "Confirm address")}
          </Text>
        </View>
      </Tooltip>
    </View>
  );
};

export default AddressVerificationIndicator;
