import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ScrollView, View, Platform } from "react-native";
import { Button, CheckBox, Text, Image, Divider } from "react-native-elements";
import { Menu, MenuOptions, MenuTrigger } from "react-native-popup-menu";
import moment from "moment";
import { fetchCustomerActivityDetails } from "library/sagas/views/home/drawer/customer-directory/slice";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import IMAGES from "static/assets/images";
import { fonts } from "styles/theme";
import tw from "tailwind-rn";
import DateRangeFilter from "./dateRangeFilter";

const Filters = ({
  icon,
  text,
  startDate: filteredStartDate,
  endDate: filteredEndDate,
  feedType: initialFeedType,
}) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const dispatch = useDispatch();

  const [dateRangeSelected, setDateRangeSelected] = useState(
    !!(startDate && endDate)
  );
  const [startDate, setStartDate] = useState(
    filteredStartDate || moment().subtract(1, "week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    filteredEndDate || moment().format("YYYY-MM-DD")
  );
  const [feedType, setFeedType] = useState(initialFeedType);

  const filtersMenuRef = React.useRef();
  const onClose = () => {
    filtersMenuRef?.current?.close();
  };
  const onCancel = () => {
    setDateRangeSelected(!!(startDate && endDate));
    onClose();
  };

  const onApply = () => {
    dispatch(
      fetchCustomerActivityDetails({
        feedType: feedType,
        ...(dateRangeSelected
          ? { startDate: startDate, endDate: endDate }
          : {}),
      })
    );
    onClose();
  };

  const onDateRangeSelect = (value) => {
    setStartDate(value?.startDate);
    setEndDate(value?.endDate);
    setFeedType(value?.feedType);
  };

  return (
    <Menu ref={filtersMenuRef} onClose={onCancel}>
      <MenuTrigger testID={text} accessibilityLabel={text}>
        <View style={tw("flex-row justify-around items-center")}>
          <Image
            style={{ width: 20, height: 20 }}
            resizeMode="cover"
            source={IMAGES[icon]}
          />
          {!isSmallScreen && <Text style={{ paddingLeft: 2 }}>{text}</Text>}
        </View>
      </MenuTrigger>
      <MenuOptions
        optionsContainerStyle={{
          width: 300,
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
          maxHeight: "80%",
        }}
      >
        <View style={{ flex: 1 }}>
          <ScrollView
            style={{
              flexGrow: 0,
              maxHeight: isDesktop ? "80%" : "60%",
            }}
          >
            <CustomDateRangeFilter
              onSelect={onDateRangeSelect}
              setDateRangeSelected={setDateRangeSelected}
              dateRangeSelected={dateRangeSelected}
              startDate={startDate}
              endDate={endDate}
              feedType={feedType}
            />
          </ScrollView>
          <View
            style={[
              tw("flex flex-row items-baseline justify-between m-2"),
              Platform.OS !== "web" && tw("pb-12"),
            ]}
          >
            <View>
              <Text style={[fonts.link1, tw("m-2")]} onPress={() => onCancel()}>
                {Localise(messages, "Close")}
              </Text>
            </View>
            <Button
              title={Localise(messages, "Apply")}
              onPress={() => onApply()}
              testID={"Apply"}
              accessibilityLabel={"Apply"}
            />
          </View>
        </View>
      </MenuOptions>
    </Menu>
  );
};

export const CustomDateRangeFilter = ({
  onSelect,
  setDateRangeSelected,
  dateRangeSelected,
  startDate,
  endDate,
  feedType,
  isEditable = true,
  hideType = false,
  minDate,
  maxDate,
  popperPlacement,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);

  const handleSelect = () => {
    if (!dateRangeSelected) {
      setDateRangeSelected(true);
    } else {
      setDateRangeSelected(false);
    }
  };

  return (
    <View>
      {isEditable && (
        <React.Fragment>
          <View style={tw("flex flex-col w-full")}>
            <Text
              style={{
                ...fonts.heading4,
              }}
            >
              {Localise(messages, "Filter by")}
            </Text>
            <Divider style={{ marginTop: 5, marginBottom: 10 }} />
          </View>
          <View style={tw("flex flex-row items-center")}>
            <CheckBox
              containerStyle={[
                {
                  paddingRight: 3,
                  paddingLeft: 10,
                  borderWidth: 0,
                  padding: 0,
                  marginTop: 4,
                  marginLeft: 5,
                  marginRight: 3,
                  zIndex: -1,
                },
              ]}
              size={18}
              checked={dateRangeSelected}
              onPress={handleSelect}
              testID={`Date Range`}
              accessibilityLabel={`Date Range`}
            />
            <Text onPress={handleSelect}>
              {Localise(messages, "Date Range")}
            </Text>
          </View>
        </React.Fragment>
      )}

      <View style={tw("flex flex-col justify-center items-center")}>
        <DateRangeFilter
          onSelect={onSelect}
          checked={dateRangeSelected}
          startDate={startDate}
          endDate={endDate}
          feedType={feedType}
          hideType={hideType}
          maxDate={maxDate}
          minDate={minDate}
          popperPlacement={popperPlacement}
        />
      </View>
    </View>
  );
};
export default Filters;
