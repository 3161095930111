import { backgroundColors } from "styles/theme";

export const adhocStopObject = ({
  values,
  stopId,
  stopType = "CUSTOM",
  addressVerificationInfo,
  displayDeliveryDate,
  stopStatus,
  routeId,
  deliveryMethod,
  siteId,
}) => {
  const {
    stopName = "",
    stopAddress: {
      addressLine1 = "",
      suite,
      city = "",
      state = "",
      zip = "",
      country = "US",
    },
    stopDescription = "",
    stopLatitude = "",
    stopLongitude = "",
  } = values;

  const stopGeoLocation = {
    latitude: stopLatitude,
    longitude: stopLongitude,
  };
  const adhocStop = {
    stopType,
    stopAdditionMethod: "MANUAL",
    orderItemId: stopId,
    messageNumber: stopName,
    stopName,
    ...(["PICKUP"].includes(stopType) && {
      receivingMember: { memberCode: siteId },
    }),
    recipientAddressLine: addressLine1,
    recipientAddressLine2: suite,
    recipientCity: city + "," + state,
    recipientCityOnly: city,
    recipientState: state,
    recipientZip: zip,
    zip,
    recipientCountry: country,
    recipientLocation: stopGeoLocation,
    status: stopStatus,
    stopDescription: stopDescription,
    routeId: routeId,
    imageName: "map-pin",
    statusColor: backgroundColors.primary,
    displayOrderStatus: "Added Stop",
    displayDeliveryDate,
    productFirstChoiceDescription: stopDescription,
    addressVerificationInfo,
  };

  return adhocStop;
};
