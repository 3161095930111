export const getUIConfig = (collectionData) => {
  return {
    name: "",
    handle: "",
    seoUrl: "",
    seoDisplayUrl: "",
    seoHeader: "",
    seoFooter: "",
    seoPageTitle: "",
    seoMetaDescription: "",
    bannerImage: "",
    group: "florist",
    status: "inactive",
    sortOption: "",
    ...collectionData,
  };
};

export const SortOrderOptions = (Localise, messages) => [
  {
    label: Localise(messages, "Best Selling"),
    value: "",
    actual: "Best Selling",
  },
  {
    label: Localise(messages, "Product Name: A-Z"),
    value: "name::asc",
    actual: "Product Title A-Z",
  },
  {
    label: Localise(messages, "Product Name: Z-A"),
    value: "name::desc",
    actual: "Product Title Z-A",
  },
  {
    label: Localise(messages, "Highest Price"),
    value: "price::desc",
    actual: "Highest Price",
  },
  {
    label: Localise(messages, "Lowest Price"),
    value: "price::asc",
    actual: "Lowest Price",
  },
  {
    label: Localise(messages, "Newest"),
    value: "none::asc",
    actual: "Newest",
  },
  {
    label: Localise(messages, "Oldest"),
    value: "none::desc",
    actual: "Oldest",
  },
  {
    label: Localise(messages, "Manually"),
    value: "manually",
    actual: "Manual",
  },
];

export const ManualSortOrderOptions = (Localise, messages) => [
  {
    value: "",
    label: Localise(messages, "Choose Move to"),
  },
  {
    value: "moveToTop",
    label: Localise(messages, "Move to Top"),
  },
  {
    value: "moveToBottom",
    label: Localise(messages, "Move to Bottom"),
  },
  {
    value: "moveToPos",
    label: Localise(messages, "Move to Position"),
  },
];

export const AddNewProductsFilters = (Localise, messages) => [
  {
    label: Localise(messages, "My Catalog"),
    value: "local",
  },
  {
    label: Localise(messages, "Global Catalog"),
    value: "global",
  },
  {
    label: Localise(messages, "Active"),
    value: "active",
  },
  {
    label: Localise(messages, "Inactive"),
    value: "inactive",
  },
  {
    label: Localise(messages, "Sold Out"),
    value: "soldOut",
  },
  {
    label: Localise(messages, "Call For Price"),
    value: "callForPrice",
  },
  {
    label: Localise(messages, "In-Store Pickup Only"),
    value: "instore",
  },
  {
    label: Localise(messages, "Rush Delivery"),
    value: "rushDelivery",
  },
  {
    label: Localise(messages, "Local Delivery Only"),
    value: "localDelOnly",
  },
  {
    label: Localise(messages, "Dropship"),
    value: "dropship",
  },
  // {
  //   label: Localise(messages, "Codified"),
  //   value: "codified",
  // },
];
