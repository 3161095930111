import * as React from "react";
import ListingScreen from "components/containers/listing";
import StaffList from "../../staff-listing/staff-list";
import StaffProfileScreen from "components/views/drawer/staff-profile";
import {
  InitData,
  ListOfColumns,
  ListOfFilters,
  ListOfSortOptions,
  PermissionsforCreateUser,
} from "./config";
import UserProfileContext from "library/contexts/userProfile";
import toUpper from "lodash/toUpper";
import get from "lodash/get";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  setRecordId,
  setActions,
  setRecordAction,
  fetchFtdAdminData as fetchData,
  setLoading,
  resetBasicInfo,
} from "library/sagas/ongoing/staff-listing/slice";
import CreateStaffProfileScreen from "components/views/drawer/create-ftd-staff-profile";

const FTDUserSettings = ({
  navigation: { setParams },
  route,
  staffListingInfo,
  resetBasicInfo,
  ...userActions
}) => {
  const { userRole } = React.useContext(UserProfileContext);
  const showaction = PermissionsforCreateUser[toUpper(userRole)];
  const filterValues = get(ListOfFilters, toUpper(userRole), undefined);
  const { params = {} } = route;

  React.useEffect(() => {
    setParams({ filters: [], shops: [] }); // To eliminate the unwanted data in the URL when the user is from dashboard
    return () => {
      resetBasicInfo();
    };
  }, []);

  return (
    <ListingScreen
      listingType="ftd-user-accounts"
      initData={InitData}
      columnsList={ListOfColumns}
      sortOptions={ListOfSortOptions}
      filterOptions={filterValues}
      title={"User Settings"}
      placeholder={"Search Users"}
      route={"ftd-create-user"}
      actionName={"Create User"}
      showaction={showaction}
      List={StaffList}
      Details={StaffProfileScreen}
      listingInfo={staffListingInfo}
      userActions={userActions}
      params={params}
      CreateUser={CreateStaffProfileScreen}
    />
  );
};

const mapStateToProps = (state = {}) => {
  return {
    staffListingInfo: state.mhq.ongoing.staffListing,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRecordId,
      setRecordAction,
      setActions,
      fetchData,
      setLoading,
      resetBasicInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FTDUserSettings);
