import React, { useEffect } from "react";
import { View } from "react-native";
import { useRoute, useNavigation } from "@react-navigation/native";
import useStateIfMounted from "library/utils/useStateIfMounted";
import get from "lodash/get";
import set from "lodash/set";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import { backgroundColors } from "styles/theme";
import AddressVerificationModal from "components/views/drawer/create-order/address-verification-modal";
import {
  FormField,
  FormFieldPicker,
  FormFieldAutoComplete,
} from "components/elements/forms";
import states from "static/data/states.json";
import { request } from "library/utils/request";
import cloneDeep from "lodash/cloneDeep";
import UserProfileStorage from "library/storage/userProfile";
import { useFormikContext } from "formik";
import { locationList } from "./config";
import { DistanceLimitMapping } from "../../config";
import I18NContext from "library/contexts/i18N";
import { verifyAddress } from "library/utils/createOrder";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { updateAvsInfo } from "library/sagas/views/home/drawer/delivery-tasks/slice";
import AddressVerificationIndicator from "components/views/drawer/order-details/delivery-info/component/address-indicator";
import { selectAddressVerificationInfo } from "library/sagas/views/home/drawer/delivery-tasks/selector";
import { phoneNumberFormatter } from "library/utils/formatter";

let addressSuggestionsController;

const RecipientDetails = ({ isSmallScreen, values, setValues, modify }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { setParams } = navigation;
  const { params: { redeliver = false, formValues = {} } = {} } = useRoute();

  const addressVerificationInfo = useSelector(selectAddressVerificationInfo);
  const [addressSuggestions, setAddressSuggestions] = useStateIfMounted([]);
  const [loading, setLoading] = useStateIfMounted(false);
  const [verifiedAddress, setVerifiedAddress] = useStateIfMounted();
  const [invalidAddress, setInvalidAddress] = useStateIfMounted();

  const locationTypeRef = React.useRef(null);
  const updatedValues = cloneDeep(values);
  const path = "recipientDetails";
  const { messages, Localise } = React.useContext(I18NContext);

  const { setFieldError, setFieldValue, setErrors } = useFormikContext();
  const { shopCode, distanceThreshold, deliveryProvider } = values;

  const shopLocations = UserProfileStorage.getAllShopLocation();
  let recipientInfo = get(values, `${path}`);
  let index = 0;
  const {
    addressLine1: shopAddressLine1 = "",
    city: shopCity = "",
    state: shopState = "",
    zip: shopZip = "",
  } = shopLocations[shopCode] || {};
  const { addressLine1, addressLine2, city, state, zipcode, country } =
    recipientInfo;
  const {
    isAvsPerformed = "",
    avsConfidence = "",
    isAvsSuggestedAddress = "",
  } = addressVerificationInfo || {};

  const isAvsVerified =
    isAvsPerformed === "Y" &&
    isAvsSuggestedAddress === "Y" &&
    avsConfidence === "HIGH";

  const createAvsInfo = (
    isAvsSuggestedAddresses,
    confidence,
    isAvsPerformed = "Y"
  ) => {
    dispatch(
      updateAvsInfo({
        isAvsPerformed,
        avsConfidence: confidence,
        isAvsSuggestedAddress: isAvsSuggestedAddresses ? "Y" : "N",
        timeStamp: moment().toDate(),
      })
    );
  };

  //update formik where suggested address are choosen
  useEffect(() => {
    if (!verifiedAddress) return;
    let updatedValues = recipientInfo;

    //update formik where suggested addresses are selected.
    const isAvsSuggestedAddress = verifiedAddress
      ? verifiedAddress.selectedIndex === 1
      : false;

    const { addressLine1, city, country, postalCode, state } =
      verifiedAddress.suggestedAddress;
    if (isAvsSuggestedAddress) {
      updatedValues = {
        ...updatedValues,
        addressLine1,
        city,
        country,
        state,
        zipcode: country === "US" ? postalCode?.substring(0, 5) : postalCode,
        addressVerificationInfo: {
          isAvsPerformed: "Y",
          avsConfidence: verifiedAddress.confidence,
          isAvsSuggestedAddress: isAvsSuggestedAddress ? "Y" : "N",
        },
      };
    } else {
      createAvsInfo(isAvsSuggestedAddress, verifiedAddress.confidence);
    }
    setFieldValue(`${path}`, updatedValues);
  }, [verifiedAddress]);

  useEffect(() => {
    if (!!shopCode && deliveryProvider && modify) {
      setFieldValue(
        "distanceThreshold",
        DistanceLimitMapping[deliveryProvider?.toLowerCase()] || 15
      );
      getDistance();
    }
  }, [shopCode, deliveryProvider]);

  useEffect(() => {
    if (modify) {
      getLatLongAndDistanceIf(true);
    }
    if (
      ["US", "CA"].includes(country) &&
      addressLine1 &&
      city &&
      state &&
      zipcode
    ) {
      const verifyAddressPayload = {
        addressLine1: addressLine1,
        country: country,
        city: city,
        state: state,
        zip: zipcode,
      };
      verifyAddress(verifyAddressPayload, index).then((result) => {
        if (!result.isValid) {
          setInvalidAddress(result);
        } else {
          createAvsInfo(true, result.confidence);
        }
      });
    } else {
      createAvsInfo(true, "Low", "N");
    }
  }, [addressLine1, city, state, zipcode, country]);

  const isRedelivery = () => {
    const omitKeys = [
      "firstName",
      "lastName",
      "phone",
      "locationType",
      "locationName",
    ];
    return (
      redeliver &&
      isEqual(
        omit(recipientInfo, [...omitKeys, "geoLocation"]),
        omit(formValues.recipientDetails, omitKeys)
      )
    );
  };

  const getLatLongAndDistanceIf = (getDistanceAlso) => {
    if (isRedelivery()) {
      setLoading(false);
      return;
    }

    /* Mark DSRequest as new request if address changed by user - reset "redeliver" param */
    setParams({
      formValues: undefined,
      redeliver: undefined,
      previousShipmentId: undefined,
    });

    if (
      zipcode &&
      zipcode.length >= 3 &&
      addressLine1 &&
      city &&
      state &&
      country
    ) {
      setLoading(true);
      request("get-lat-long", {
        zipCode: zipcode,
        addressLine1: encodeURIComponent(addressLine1),
        suite: encodeURIComponent(addressLine2),
        city: encodeURIComponent(city),
        state: state,
        country: country,
      })
        .then((res) => {
          const {
            latitude: recipientLatitude = "",
            longitude: recipientLongitude = "",
          } = res || {};
          setFieldValue(`${path}.geoLocation`, {
            latitude: recipientLatitude,
            longitude: recipientLongitude,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          if (getDistanceAlso && !!shopCode) {
            getDistance();
          } else {
            setLoading(false);
          }
        });
    }
  };

  const getDistance = () => {
    if (isRedelivery()) {
      setLoading(false);
      return;
    }

    /* Mark DSRequest as new request if address changed by user - reset "redeliver" param */
    setParams({
      formValues: undefined,
      redeliver: undefined,
      previousShipmentId: undefined,
    });

    if (["US"].includes(country) && addressLine1 && city && state && zipcode) {
      setLoading(true);
      const reqPayload = {
        shopCode: shopCode,
        origins: encodeURIComponent(
          [shopAddressLine1, shopCity, shopState, shopZip].join(", ")
        ),
        destinations: encodeURIComponent(
          [addressLine1, city, state, zipcode].join(", ")
        ),
      };
      request("distance-matrix", reqPayload)
        .then((res) => {
          let distance = get(res, "rows.0.elements.0.distance.value", 0);
          distance = distance / 1609;
          setFieldValue("estimatedDistance", parseFloat(distance).toFixed(5));
          if (
            get(res, "rows.0.elements.0.distance.value", 0) >
              distanceThreshold * 1609.344 ||
            res.rows[0].elements[0].status !== "OK"
          ) {
            setFieldError(
              `${path}.addressLine1`,
              Localise(messages, `Delivery is beyond`) +
                " " +
                distanceThreshold +
                " " +
                Localise(messages, `miles`)
            );
          } else {
            setErrors({});
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getAddressSuggestions = (text) => {
    if (text.trim().length < 3) {
      setAddressSuggestions([]);
      return;
    }

    const payload = {
      addressLine1: text,
      aptCode: "",
      cityLocality: get(values, `${path}.city`),
      stateProvince: get(values, `${path}.state`),
      postalCode: get(values, `${path}.zipcode`),
      maxRecords: "10",
      resultDetails: [],
      countryRegion: get(values, `${path}.country`),
    };

    addressSuggestionsController && addressSuggestionsController.abort();
    addressSuggestionsController = new AbortController();

    setLoading(true);
    request("get-address-suggestions", payload, addressSuggestionsController)
      .then((res) => {
        //ToDo- Filter Unique data
        if (res?.addresses?.length) {
          setAddressSuggestions(res.addresses);
        } else setAddressSuggestions([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const other = country === "US" ? { maxLength: 5 } : {};

  return (
    <View
      style={{
        flexDirection: "row",
        flexWrap: "wrap",
        marginLeft: -5,
        paddingTop: 5,
        paddingRight: isSmallScreen ? 5 : 0,
      }}
    >
      <FormField
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="new-password"
        name="firstName"
        label={"Recipient First Name"}
        placeholder={"Enter First Name"}
        path={path}
        isRequired
        editable={modify}
        grayedOutOnDisable={true}
        containerStyle={{ width: isSmallScreen ? "100%" : "35%" }}
        testID="recipient_name"
        accessibilityLabel="recipient_name"
        isUpdateOnChange={true}
      />
      <FormField
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="new-password"
        name="lastName"
        label={"Recipient Last Name"}
        placeholder={"Enter Last Name"}
        path={path}
        isRequired
        editable={modify}
        grayedOutOnDisable={true}
        containerStyle={{ width: isSmallScreen ? "100%" : "35%" }}
        isUpdateOnChange={true}
      />
      <FormField
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="new-password"
        name="phone"
        label={"Recipient Phone"}
        placeholder={"123-456-7890"}
        inputStyle={{ width: "100%" }}
        containerStyle={{ width: isSmallScreen ? "100%" : "30%" }}
        path={path}
        isRequired
        editable={modify}
        grayedOutOnDisable={true}
        keyboardType="phone-pad"
        maxLength={18}
        fsClass="fs-exclude"
        testID="phone"
        accessibilityLabel="phone"
        isUpdateOnChange={true}
        transformText={(text = "") => {
          return phoneNumberFormatter(text);
        }}
      />
      <View
        style={{
          flexWrap: "wrap",
          flexDirection: "row",
          width: isSmallScreen ? "100%" : "70%",
          zIndex: 12,
        }}
      >
        <FormFieldAutoComplete
          autoCapitalize="none"
          autoCorrect={false}
          name="addressLine1"
          fsClass="fs-exclude"
          label={"Street Address *"}
          loading={loading}
          placeholder={"Street Address"}
          path={path}
          editable={modify && !redeliver}
          isRequired
          data={addressSuggestions}
          onChangeText={(text) => {
            getAddressSuggestions(text);
          }}
          outerContainerStyle={{
            flex: 1,
            zIndex: 12,
          }}
          inputStyle={{
            backgroundColor:
              modify && !redeliver ? "transparent" : backgroundColors.greyColor,
          }}
          focusTo={locationTypeRef}
          listDisplayValues={["addressLine1", "cityLocality", "stateProvince"]}
          updateOnBlur={true}
          popperPlacement={"bottom"}
          onSelect={(selectedValue) => {
            set(updatedValues, path, {
              ...get(updatedValues, path),
              addressLine1: selectedValue.addressLine1,
              city: selectedValue.cityLocality,
              zipcode: selectedValue.postalCode,
              state: selectedValue.stateProvince,
              country: selectedValue.countryRegion,
            });
            setValues(updatedValues);
          }}
        />
        {addressLine1 && city && state && zipcode ? (
          <AddressVerificationIndicator
            containerStyle={{
              position: "absolute",
              right: 5,
            }}
            isVerified={isAvsVerified}
            isSmallScreen={isSmallScreen}
          />
        ) : null}
      </View>
      <AddressVerificationModal
        showModal={!!invalidAddress && modify && !redeliver}
        onClose={() => setInvalidAddress()}
        invalidAddresses={invalidAddress ? [invalidAddress] : []}
        onContinue={(addresses) => {
          setVerifiedAddress(addresses[0]);
          setInvalidAddress();
        }}
      />

      <FormField
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="new-password"
        name="addressLine2"
        label={"Apt/Suite/Unit"}
        fsClass="fs-exclude"
        placeholder={"Apt/Suite/Unit"}
        inputStyle={{ width: "100%" }}
        containerStyle={{ width: isSmallScreen ? "50%" : "30%" }}
        path={path}
        editable={modify && !redeliver}
        grayedOutOnDisable={true}
        isUpdateOnChange={true}
      />

      <FormField
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="new-password"
        name="city"
        label={"City"}
        fsClass="fs-exclude"
        placeholder={"City"}
        containerStyle={{ width: isSmallScreen ? "50%" : "25%" }}
        path={path}
        editable={modify && !redeliver}
        grayedOutOnDisable={true}
        testID="city"
        accessibilityLabel="city"
        isUpdateOnChange={false}
        isRequired
      />

      <FormFieldPicker
        containerStyle={{
          width: isSmallScreen ? "50%" : "30%",
        }}
        innerContainerStyle={{
          backgroundColor:
            modify && !redeliver ? "transparent" : backgroundColors.greyColor,
        }}
        placeholder={{ label: "Select State" }}
        data={states["US"] || []}
        name="state"
        fsClass="fs-exclude"
        label={"State"}
        path={path}
        disabled={!modify || redeliver}
        customDisabledStyle
        isRequired
      />
      <FormField
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="new-password"
        name="zipcode"
        label={"Postal Code"}
        fsClass="fs-exclude"
        placeholder={"Zip"}
        containerStyle={{ width: isSmallScreen ? "50%" : "15%" }}
        path={path}
        editable={modify && !redeliver}
        grayedOutOnDisable={true}
        testID="zip"
        accessibilityLabel="zip"
        isUpdateOnChange={false}
        isRequired
        {...other}
      />
      <FormFieldPicker
        containerStyle={{
          width: isSmallScreen ? "100%" : "30%",
        }}
        innerContainerStyle={{
          backgroundColor: backgroundColors.greyColor,
        }}
        isRequired
        placeholder={{
          label: "Select Country",
        }}
        data={[
          {
            value: "US",
            label: "United States",
          },
        ]}
        disabled // disable by default as we support only US
        customDisabledStyle
        name="country"
        fsClass="fs-exclude"
        label={"Country"}
        path={path}
      />
      <FormFieldPicker
        containerStyle={{
          width: isSmallScreen ? "100%" : "33%",
          paddingTop: isSmallScreen ? 10 : 0,
        }}
        innerContainerStyle={{
          backgroundColor:
            modify && !redeliver ? "transparent" : backgroundColors.greyColor,
        }}
        placeholder={{
          label: "Select Location Type",
        }}
        data={locationList}
        name="locationType"
        isRequired
        label={"Location Type"}
        path={path}
        disabled={!modify || redeliver}
        customDisabledStyle
        pickerRef={locationTypeRef}
      />
      <FormField
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="new-password"
        name="locationName"
        isRequired={
          ["residence", undefined].includes(
            values?.recipientDetails?.locationType
          )
            ? false
            : true
        }
        label={"Location / Business Name"}
        placeholder={"Location or Business Name"}
        containerStyle={{
          width: isSmallScreen ? "100%" : "67%",
          paddingTop: isSmallScreen ? 15 : 0,
        }}
        path={path}
        editable={modify && !redeliver}
        grayedOutOnDisable={true}
        isUpdateOnChange={true}
      />
    </View>
  );
};
export default RecipientDetails;
