import React, { useEffect, useContext } from "react";
import { View, Platform } from "react-native";
import { useSelector } from "react-redux";
import moment from "moment";
import tw from "tailwind-rn";

import { theme, backgroundColors } from "styles/theme";
import {
  isDeliveryService,
  convertToTimeZone,
  roundUpTime,
  handlePickupTimeWindow,
  isDoordashProvider,
} from "../helper";
import { deliveryPickupTimeWindow } from "components/views/drawer/delivery/config";

import {
  FormField,
  FormFieldDatePicker,
  FormFieldPicker,
  SubmitButton,
} from "components/elements/forms";

import { selectRecordData } from "library/sagas/ongoing/current-orders/selector";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";
import { processShopSettingsResponse } from "library/utils/shopSettings";
import {
  getPickupTimeOptions,
  getMinPickupDate,
  DS_OPENING_HOUR,
  DS_CLOSING_HOUR,
  cutOffTimeInterval,
  defaultDeliveryProvider,
  isBurqProvider,
  undeliverableActions,
} from "library/utils/deliveryService";

//eslint-disable-next-line
const DSFormContent = React.memo(
  ({
    index = 0,
    props: { submitCount, values, setFieldValue } = {},
    isDoordash,
    setIsDoordashProvider,
    orderSetFieldValue,
    setOnChangeValidation,
    isDSSubmitted,
    deliveryMethod,
  }) => {
    const orderDetailResponse = useSelector(selectRecordData) || {};
    const orderDetails = orderDetailResponse.orderItems[index] || {};
    const {
      receivingMember: {
        memberCode: fillerMemberCode = "",
        timezone = "",
      } = {},
      latestDSRequestedStatus = "",
    } = orderDetails;

    //contexts
    const { messages: locMessages, Localise } = useContext(I18NContext);
    const { isMobile } = useContext(DeviceContext);

    const [pickUpTimeWindow, setPickUpTimeWindow] = useStateIfMounted([]);
    const [pickupTimeOptions, setPickupTimeOptions] = useStateIfMounted([]);
    const floristTimeZone = moment.tz(timezone).format("z");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (submitCount > 0) {
        setOnChangeValidation(true);
      }
    }, [submitCount]);

    const { deliveryProvider, newPickUpDate, pickUpDateTime } = values;

    // Calculating min date by adding (as we need buffer time) w.r.t filler timezone here in order to set it for pickup date calendar
    let dsMinDate = convertToTimeZone(
      moment
        .utc()
        .add(cutOffTimeInterval[deliveryProvider], "minutes")
        .format(),
      timezone
    );

    let currentTime = moment(moment(dsMinDate).format("HH:mm:ss"), "HH:mm:ss");

    // If filler current time (after including buffer time) is after last available time
    // then we are not allowing him to select todays date from pickup date calendar
    // in order to avoid issue with pickup timer selection
    if (fillerMemberCode && timezone)
      dsMinDate = getMinPickupDate(
        fillerMemberCode,
        timezone,
        dsMinDate,
        pickUpTimeWindow
      );

    // Here we are updating open & close hours based on selected pickup date while submitting order to DS
    const updateDSPickupTime = (date) => {
      let dsPickUpTimings = {
        open: moment(date)
          .set({
            hour: 7,
            minute: 0,
            second: 0,
          })
          .toDate(),
        close: moment(date)
          .set({
            hour: 20,
            minute: 0,
            second: 0,
          })
          .toDate(),
      };

      let currentFloristTime = moment(
        convertToTimeZone(moment.utc().format(), timezone)
      ).add(cutOffTimeInterval[deliveryProvider], "minutes");

      let isSameDay = moment(date).isSame(currentFloristTime, "day");

      if (isSameDay) {
        if (currentFloristTime.isAfter(dsPickUpTimings.open)) {
          let dsBufferTime = moment(
            convertToTimeZone(
              moment
                .utc()
                .add(cutOffTimeInterval[deliveryProvider], "minutes")
                .format(),
              timezone
            )
          );

          if (dsBufferTime.isBefore(dsPickUpTimings.close)) {
            dsPickUpTimings.open = moment(
              roundUpTime(30 * 60 * 1000, dsBufferTime.toDate())
            ).toDate();
            if (!isDSSubmitted && !pickUpTimeWindow.length) {
              setFieldValue(
                "pickUpDateTime",
                moment(dsPickUpTimings.open).format("YYYY-MM-DDTHH:mm:ss")
              );
            }
          } else {
            dsMinDate = moment(
              convertToTimeZone(
                moment().utc().add(1, "days").format(),
                timezone
              )
            );

            dsPickUpTimings = {
              open: dsMinDate
                .set({
                  hour: 7,
                  minute: 0,
                  second: 0,
                })
                .toDate(),
              close: dsMinDate
                .set({
                  hour: 20,
                  minute: 0,
                  second: 0,
                })
                .toDate(),
            };
            if (!pickUpTimeWindow.length) {
              setFieldValue(
                "newPickUpDate",
                moment(dsPickUpTimings.open).format("YYYY-MM-DD")
              );
            }
          }
        } else if (!pickUpTimeWindow.length) {
          setFieldValue(
            "pickUpDateTime",
            moment(dsPickUpTimings.open).format("YYYY-MM-DDTHH:mm:ss")
          );
        }
      } else if (!isDSSubmitted && !pickUpTimeWindow.length) {
        setFieldValue(
          "pickUpDateTime",
          moment(dsPickUpTimings.open).format("YYYY-MM-DDTHH:mm:ss")
        );
      }
      if (pickUpTimeWindow.length) {
        const injectTiming = pickUpTimeWindow?.map((time) => {
          return moment(newPickUpDate)
            .set({ hour: time, minute: 0, second: 0 })
            .toDate();
        });
        let dsMinDate = convertToTimeZone(
          moment
            .utc()
            .add(cutOffTimeInterval[deliveryProvider], "minutes")
            .format(),
          timezone
        );
        let currentTime = moment(
          moment(dsMinDate).format("HH:mm:ss"),
          "HH:mm:ss"
        );
        let pickUpDate = moment(date).format("MM/DD/YYYY");
        let updatedPickUpDateTime = moment(injectTiming[0]).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
        let timeMorning = moment(pickUpTimeWindow[0] + ":00:00", "HH:mm:ss");
        let timeAfternoon = moment(pickUpTimeWindow[1] + ":00:00", "HH:mm:ss");

        let isSameDay = moment(pickUpDate).isSame(dsMinDate, "day");
        if (isSameDay && currentTime.isAfter(timeMorning)) {
          if (
            pickUpTimeWindow.length === 1 ||
            (pickUpTimeWindow.length === 2 &&
              currentTime.isAfter(timeAfternoon))
          ) {
            updatedPickUpDateTime = "";
          } else if (currentTime.isSameOrBefore(timeAfternoon)) {
            let newTiming = [
              moment(newPickUpDate)
                .set({
                  hour: pickUpTimeWindow[1],
                  minute: 0,
                  second: 0,
                })
                .toDate(),
            ];
            updatedPickUpDateTime = moment(newTiming[0]).format(
              "YYYY-MM-DDTHH:mm:ss"
            );
          }
        }
        setFieldValue("pickUpDateTime", updatedPickUpDateTime);
        setFieldValue("injectSpecificTimings", injectTiming);
      }
      setFieldValue("dsPickUpTimings", dsPickUpTimings);
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (newPickUpDate && !isDSSubmitted && deliveryProvider) {
        if (isDoordash !== "true")
          updateDSPickupTime(newPickUpDate, setFieldValue);
        else if (isDoordash === "true") {
          let dsMinDate = convertToTimeZone(
            moment
              .utc()
              .add(cutOffTimeInterval[deliveryProvider], "minutes")
              .format(),
            timezone
          );
          let isSameDay = moment(newPickUpDate).isSame(dsMinDate, "day");
          let pickupTimeOptions = getPickupTimeOptions(
            pickUpTimeWindow,
            isSameDay || !newPickUpDate,
            newPickUpDate,
            dsMinDate,
            pickUpDateTime
          );

          setPickupTimeOptions(pickupTimeOptions);
          //default pickupTime for Doordash
          setFieldValue(
            "pickUpDateTime",
            pickupTimeOptions.find((option) => option.enabled)?.value
          );
          setFieldValue(
            "fulfillmentType",
            pickupTimeOptions.find((option) => option.enabled)?.secondaryLabel
          );
        }
      }
    }, [newPickUpDate, pickUpTimeWindow]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!isDSSubmitted && isDoordash !== "true") {
        // Handling the picker didChange for iOS only
        const isPickerChanged = values?.pickupTimeDidChanged ?? false;
        const isPickupTimeUpdated = values?.pickupTimeDidConfirmed ?? false;
        if (Platform.OS === "ios") {
          setFieldValue("pickupTimeDidChanged", false, true);
          setFieldValue("pickupTimeDidConfirmed", false, true);
        }

        if (
          pickUpTimeWindow.length &&
          pickUpDateTime &&
          newPickUpDate &&
          Platform.OS !== "web"
        ) {
          handlePickupTimeWindow(
            pickUpTimeWindow,
            newPickUpDate,
            dsMinDate,
            currentTime,
            setFieldValue,
            pickUpDateTime,
            timezone
          );
        } else if (
          !pickUpTimeWindow.length &&
          pickUpDateTime &&
          newPickUpDate
        ) {
          let pickUpTimeOnly = moment(
            pickUpDateTime,
            "YYYY-MM-DDTHH:mm:ss"
          ).format("HH:mm:ss");

          let selectedTime = moment(
            `${newPickUpDate}T${pickUpTimeOnly}`,
            "YYYY-MM-DDTHH:mm:ss"
          );

          // when user selecting pickup hours as before open/after closing hours then we are pre filling nearest open/closing hour based on selected value
          let openingTime = moment(
            `${newPickUpDate}T${DS_OPENING_HOUR}`,
            "YYYY-MM-DDTHH:mm:ss"
          );

          let closingTime = moment(
            `${newPickUpDate}T${DS_CLOSING_HOUR}`,
            "YYYY-MM-DDTHH:mm:ss"
          );

          let currentFloristTime = moment(
            convertToTimeZone(moment.utc().format(), timezone)
          );

          let isSameDay = moment(newPickUpDate).isSame(
            currentFloristTime,
            "day"
          );

          if (
            Platform.OS !== "ios" && // We added this check as iOS users can't select time which is after shop closing hours
            selectedTime.isSameOrAfter(closingTime)
          ) {
            pickUpTimeOnly = DS_CLOSING_HOUR;
          } else {
            if (isSameDay) {
              if (currentFloristTime.isAfter(openingTime)) {
                let dsBufferTime = moment(
                  roundUpTime(
                    30 * 60 * 1000,
                    convertToTimeZone(
                      moment
                        .utc()
                        .add(cutOffTimeInterval[deliveryProvider], "minutes")
                        .format(),
                      timezone
                    )
                  )
                );

                if (selectedTime.isAfter(dsBufferTime)) {
                  pickUpTimeOnly = moment(
                    pickUpDateTime,
                    "YYYY-MM-DDTHH:mm:ss"
                  ).format("HH:mm:ss");
                } else {
                  pickUpTimeOnly = moment(dsBufferTime).format("HH:mm:ss");
                }
              } else {
                if (selectedTime.isAfter(openingTime)) {
                  pickUpTimeOnly = moment(
                    pickUpDateTime,
                    "YYYY-MM-DDTHH:mm:ss"
                  ).format("HH:mm:ss");
                } else {
                  pickUpTimeOnly = DS_OPENING_HOUR;
                }
              }
            } else {
              if (Platform.OS !== "ios") {
                if (selectedTime.isSameOrBefore(openingTime)) {
                  pickUpTimeOnly = DS_OPENING_HOUR;
                }
              } else {
                if (isPickupTimeUpdated) {
                  const diffInMs =
                    new Date(currentFloristTime.format("YYYY-MM-DD")) -
                    new Date(selectedTime.format("YYYY-MM-DD"));
                  const daysDiff = diffInMs / (1000 * 60 * 60 * 24);

                  if (
                    daysDiff === -1 &&
                    selectedTime.isAfter(openingTime) &&
                    selectedTime.isBefore(closingTime)
                  ) {
                    const selectedMinutes = selectedTime.minutes();
                    pickUpTimeOnly = moment(
                      pickUpDateTime,
                      "YYYY-MM-DDTHH:mm:ss"
                    )
                      .subtract(
                        selectedMinutes >= 30
                          ? selectedMinutes - 30
                          : selectedMinutes,
                        "minutes"
                      )
                      .format("HH:mm:ss");
                  } else if (!isPickerChanged) {
                    pickUpTimeOnly = DS_OPENING_HOUR;
                  }
                }
              }
            }
          }

          /**
           * In iOS app, when user click on Confirm button without choosing time from options then individual minutes will come instead of
           * 30min interval so rounding the selected time to next upper 30min.
           *
           * In web user will enter time manually but Delivery Service will allow only 15min interval and as per requirements,
           * we are rounding manually entered value to next upper 30min.*/
          if (
            (Platform.OS === "ios" || Platform.OS === "web") &&
            pickUpTimeOnly.indexOf(":00:") === -1 &&
            pickUpTimeOnly.indexOf(":30:") === -1
          ) {
            if (Platform.OS === "ios" && !isPickerChanged && isSameDay) {
              const selectedMinutes = selectedTime.minutes();
              pickUpTimeOnly = moment(pickUpDateTime, "YYYY-MM-DDTHH:mm:ss")
                .subtract(
                  selectedMinutes >= 30
                    ? selectedMinutes - 30
                    : selectedMinutes,
                  "minutes"
                )
                .format("HH:mm:ss");
            } else {
              selectedTime = moment(
                roundUpTime(30 * 60 * 1000, moment(pickUpDateTime).toDate())
              ).format();

              pickUpTimeOnly = moment(
                selectedTime,
                "YYYY-MM-DDTHH:mm:ss"
              ).format("HH:mm:ss");
            }
          }
          let formattedPickupTimeWithDate = `${moment(newPickUpDate).format(
            "YYYY-MM-DD"
          )}T${pickUpTimeOnly}`;

          setFieldValue("pickUpDateTime", formattedPickupTimeWithDate);
        }
      }
    }, [pickUpDateTime]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (isDeliveryService(deliveryMethod ?? "")) {
        request("get-shop-settings", {
          ids: ["delivery_pickup_time", "delivery_provider"],
          memberCode: fillerMemberCode,
        })
          .then((res) => {
            if (res && res.preferences && res.preferences.length) {
              const result = processShopSettingsResponse(res);
              const {
                delivery_pickup_time: deliveryPickupTime,
                delivery_provider: deliveryProvider,
              } = result;
              const deliveryPickupTimeVal =
                deliveryPickupTime || "Every 30 minutes";

              setFieldValue("pickUpDateTime", "");
              setFieldValue(
                "deliveryProvider",
                deliveryProvider || defaultDeliveryProvider
              );
              orderSetFieldValue(
                "deliveryProvider",
                deliveryProvider || defaultDeliveryProvider
              );
              if (isDoordashProvider(deliveryProvider.toLowerCase())) {
                setIsDoordashProvider("true");
              } else {
                setIsDoordashProvider("false");
                setFieldValue(
                  "fulfillmentType",
                  isBurqProvider(deliveryProvider) ? "EXPRESS" : "STANDARD"
                );
              }

              deliveryPickupTimeWindow?.map((timeWindow) => {
                if (timeWindow.key === deliveryPickupTimeVal) {
                  setPickUpTimeWindow(timeWindow.value);
                  const injectTiming = timeWindow.value?.map((time) => {
                    return moment(values.newPickUpDate)
                      .set({ hour: time, minute: 0, second: 0 })
                      .toDate();
                  });
                  setFieldValue("injectSpecificTimings", injectTiming);
                }
              });
            }
          })
          .catch((err) => {
            console.log("unable to fetch shop-settings", err);
          });
      }
    }, [deliveryMethod]);

    return (
      <View style={{ zIndex: -1, marginLeft: -5 }}>
        {!isDSSubmitted && (
          <>
            <View style={[tw("flex flex-row items-baseline"), { zIndex: -1 }]}>
              <FormFieldDatePicker
                dateValueFormat="YYYY-MM-DD"
                name="newPickUpDate"
                placeholder="New Pickup Date"
                label={Localise(locMessages, "Pickup Date")}
                isRequired
                containerStyle={{
                  width: isMobile ? "50%" : "30%",
                }}
                labelStyle={{
                  fontSize: 14,
                  fontWeight: "normal",
                }}
                minDate={moment(dsMinDate).toDate()}
                disabled={isDSSubmitted}
                editable={!isDSSubmitted}
              />
              {isDoordash === "true" ? (
                <FormFieldPicker
                  name="pickUpDateTime"
                  label={`${Localise(locMessages, "Pickup Time")}${
                    floristTimeZone ? ` (${floristTimeZone})` : ""
                  }`}
                  labelStyle={{
                    fontSize: 14,
                    fontWeight: "normal",
                  }}
                  placeholder={{
                    label: "Pickup Time",
                    value: null,
                  }}
                  data={(pickupTimeOptions?.length
                    ? pickupTimeOptions
                    : getPickupTimeOptions(pickUpTimeWindow)
                  ).map((item) => {
                    const {
                      value: itemValue,
                      label,
                      enabled = true,
                      secondaryLabel = "",
                    } = item;

                    return {
                      value: itemValue,
                      label:
                        pickUpDateTime === itemValue && secondaryLabel
                          ? `${Localise(locMessages, label)} ${Localise(
                              locMessages,
                              secondaryLabel
                            )}`
                          : Localise(locMessages, label),
                      enabled: enabled,
                    };
                  })}
                  isRequired
                  innerContainerStyle={{
                    backgroundColor: backgroundColors.greyColor,
                  }}
                  containerStyle={{
                    width: isMobile ? "50%" : "30%",
                    marginLeft: -5,
                    padding: 0,
                    margin: 0,
                    zIndex: 9,
                  }}
                  onChange={(val) => {
                    let updatedtime = "";
                    let fulfillmentType = "";
                    ["Standard", "Express"].forEach((type) => {
                      if (val.includes(type)) {
                        fulfillmentType = type;
                        updatedtime = val.split(" ")[0].trim();
                      }
                    });
                    setFieldValue("fulfillmentType", fulfillmentType);
                    setFieldValue("doordashPickUpTime", updatedtime);
                  }}
                />
              ) : isDoordash === "false" ? (
                <FormFieldDatePicker
                  dateFormat="h:mm a"
                  name="pickUpDateTime"
                  placeholder="Select Pickup Time"
                  label={`${Localise(
                    locMessages,
                    "Pickup Time"
                  )} (${floristTimeZone})`}
                  isRequired
                  containerStyle={{
                    width: isMobile ? "50%" : "30%",
                  }}
                  labelStyle={{
                    fontSize: 14,
                    fontWeight: "normal",
                  }}
                  showTimeSelect={true}
                  showTimeSelectOnly={true}
                  timeIntervals={30}
                  timeCaption="PickUp Time"
                  minTime={values.dsPickUpTimings.open}
                  maxTime={values.dsPickUpTimings.close}
                  {...(Platform.OS !== "web"
                    ? {
                        dateValueFormat: "YYYY-MM-DDTHH:mm:ss",
                        minDate: values.dsPickUpTimings.open,
                        maxDate: values.dsPickUpTimings.close,
                        onChange: () =>
                          setFieldValue("pickupTimeDidChanged", true),
                        onValueChange: () =>
                          setFieldValue("pickupTimeDidConfirmed", true),
                      }
                    : values?.injectSpecificTimings?.length
                    ? {
                        includeTimes: values?.injectSpecificTimings,
                      }
                    : {})}
                  disabled={isDSSubmitted}
                  editable={Platform.OS === "web" ? false : !isDSSubmitted}
                />
              ) : (
                <></>
              )}
            </View>

            <View
              style={[
                tw("flex flex-row flex-wrap items-baseline my-2"),
                { zIndex: -2 },
              ]}
            >
              <FormField
                autoCapitalize={"none"}
                autoCorrect={false}
                name={"deliveryDetailsToDS"}
                placeholder={"Special Delivery Instructions"}
                label={`${Localise(
                  locMessages,
                  "Special Delivery Instructions"
                )} (${values.specialInstructionsCharCount}/500)`}
                labelStyle={{
                  fontSize: 14,
                  fontWeight: "normal",
                }}
                containerStyle={{
                  width: isMobile ? "100%" : "70%",
                }}
                editable={!isDSSubmitted}
                grayedOutOnDisable={isDSSubmitted}
                maxLength={500}
                transformText={(val) => {
                  setFieldValue("specialInstructionsCharCount", val.length);
                  return val;
                }}
              />
              <FormFieldPicker
                name="undeliverableAction"
                placeholder={{
                  label: "Select Undeliverable Action",
                }}
                disabled={isDSSubmitted}
                innerContainerStyle={{
                  backgroundColor: !isDSSubmitted
                    ? "transparent"
                    : backgroundColors.greyColor,
                }}
                labelStyle={{
                  fontSize: 14,
                  fontWeight: "normal",
                }}
                isRequired
                data={undeliverableActions}
                label="Undeliverable Action"
                containerStyle={{
                  width: isMobile ? "100%" : "30%",
                }}
              />
            </View>
          </>
        )}
        <View
          style={[
            tw("flex flex-row flex-wrap items-center justify-end mt-2"),
            { zIndex: -2 },
          ]}
        >
          {!isDSSubmitted && (
            <SubmitButton
              title={
                latestDSRequestedStatus === "NOT_REQUESTED"
                  ? "Submit"
                  : "Resubmit"
              }
              buttonStyle={{
                ...theme.Button.buttonStyle,
                paddingHorizontal: 25,
              }}
              containerStyle={{
                margin: 0,
              }}
            />
          )}
        </View>
      </View>
    );
  }
);

export default DSFormContent;
