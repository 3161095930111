import pick from "lodash/pick";

// If user manually clears all the customer related fields in UI then we should allow user to place order without customer info.
// To verify the above scenario we are doing check for UI customer fields.
export const isEmptyCustomerInfo = (customerInfo) => {
  const isBusinessProfile = customerInfo?.customerType === "Business" || false;
  const uiCustomerFields = pick(customerInfo, [
    "customerType",
    "email",
    ...(isBusinessProfile ? ["businessName"] : ["firstName", "lastName"]),
    "phone",
  ]);

  return Object.values(uiCustomerFields).filter((val) => !!val).length === 0;
};
