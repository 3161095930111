import React from "react";
import { useFormikContext } from "formik";
import { Image } from "react-native-elements";
import set from "lodash/set";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { TouchableOpacity } from "react-native";
import IMAGES from "static/assets/images";

const FormFieldRemoveIcon = ({
  path,
  productPricePath,
  index,
  canRemoveAnyItem = false,
  containerStyle,
  markAsRemove = false,
}) => {
  const { values, setValues, setFieldValue, setFieldTouched } =
    useFormikContext();
  const id = `remove_${index}`;
  return (
    <>
      <TouchableOpacity
        style={containerStyle}
        testID={id}
        accessibilityLabel={id}
        onPress={() => {
          const finalValues = cloneDeep(values);
          const data = get(values, path, []);
          if (!markAsRemove) {
            data.splice(index, 1);
            set(
              finalValues,
              path,
              canRemoveAnyItem ? data : index !== 0 ? data : []
            );

            const prices = get(values, productPricePath, []);
            prices.splice(index, 1);
            set(
              finalValues,
              productPricePath,
              canRemoveAnyItem ? prices : index !== 0 ? prices : []
            );
            setValues(finalValues);
          } else {
            // Do not remove each.
            data.forEach((each, idx) => {
              if (idx === index) {
                if (each.newlyAdded === true) {
                  data.splice(index, 1);
                  setFieldValue(`${path}`, data);
                  // updating price array for newly added products
                  const prices = get(values, productPricePath, []);
                  prices.splice(index, 1);
                  setFieldValue(`${productPricePath}`, prices);
                } else {
                  setFieldValue(`${path}.${idx}.isRemoved`, true);
                  setFieldValue(`${productPricePath}.${idx}.newPrice`, "");
                  setFieldValue(
                    `${productPricePath}.${idx}.discountedValue`,
                    0
                  );
                  setFieldTouched(`${path}.${idx}.isRemoved`);
                }
              }
            });
          }
        }}
      >
        <Image
          style={{
            width: 20,
            height: 20,
          }}
          source={IMAGES["cancel"]}
        />
      </TouchableOpacity>
    </>
  );
};

export default React.memo(FormFieldRemoveIcon);
