import * as Yup from "yup";
import moment from "moment";

export const getValidationSchema = (Localise, messages) => {
  Yup.addMethod(
    Yup.string,
    "inValidSuspensionDate",
    function (type, errorMessage) {
      return this.test(type, errorMessage, function (value) {
        const { path, createError, parent } = this;

        let { startDate, endDate } = parent;

        if (type === "invalid-start-date") startDate = value;

        if (type === "invalid-end-date") endDate = value;

        if (moment(startDate).isAfter(endDate)) {
          return createError({
            path: path.replace("startDate", "endDate"),
            message: errorMessage,
          });
        }

        return createError({ path, message: "" });
      });
    }
  );

  Yup.addMethod(
    Yup.string,
    "inValidSuspensionTime",
    function (type, errorMessages) {
      return this.test(type, errorMessages, function () {
        const [timeInPastError, timeInvalidError] = errorMessages;
        const { path, createError, parent } = this;
        const {
          type: suspensionType,
          startTime,
          endTime,
          startDate,
          endDate,
        } = parent;
        const isStartField = path.includes("startTime");
        const skipTimeInPast = suspensionType === "suspended";
        const startDateTime = moment(
          `${startDate}T${startTime.split("T")[1]}`
        ).utc();
        const endDateTime = moment(`${endDate}T${endTime.split("T")[1]}`).utc();

        if (
          !skipTimeInPast &&
          moment(startDateTime).isBefore(moment().utc()) &&
          isStartField
        )
          return createError({ path, message: timeInPastError });

        if (moment(endDateTime).isBefore(moment().utc()) && !isStartField)
          return createError({ path, message: timeInPastError });

        if (
          moment(startDateTime).isAfter(moment(endDateTime).utc()) ||
          moment(startDateTime).isSame(moment(endDateTime).utc())
        )
          return createError({
            path: path.replace("startTime", "endTime"),
            message: timeInvalidError,
          });

        return true;
      });
    }
  );

  return Yup.object().shape({
    activeSuspensions: Yup.array().of(
      Yup.object().shape({
        startDate: Yup.string().inValidSuspensionDate(
          "invalid-start-date",
          Localise(messages, "Suspend start date is after end date")
        ),
        endDate: Yup.string().inValidSuspensionDate(
          "invalid-end-date",
          Localise(messages, "Suspend end date is before start date")
        ),
        startTime: Yup.string().inValidSuspensionTime("invalid-start-time", [
          Localise(messages, "Suspend needs to start after current time"),
          Localise(messages, "Suspend start time is after end time"),
        ]),
        endTime: Yup.string().inValidSuspensionTime("invalid-end-time", [
          Localise(messages, "Suspend end time is before current time"),
          Localise(messages, "Suspend needs to end after current time"),
        ]),
      })
    ),
  });
};
