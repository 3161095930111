import * as Yup from "yup";

export const getValidationSchema = (
  Localise,
  messages,
  isMHQNonCoreUser,
  listingType
) => {
  const isDriverDconForm = listingType === "delivery";
  return Yup.object().shape({
    ...(isDriverDconForm && {
      deliveryStatus: Yup.string()
        .label("deliveryStatus")
        .required(Localise(messages, "Please select delivery status")),
    }),
    ...(!isDriverDconForm &&
      !isMHQNonCoreUser && {
        deliveryDate: Yup.string()
          .label("deliveryDate")
          .required(Localise(messages, "Select delivery date")),
        deliveryTime: Yup.string()
          .label("deliveryTime")
          .required(Localise(messages, "Select delivery time")),
      }),
    ...(!isDriverDconForm &&
      isMHQNonCoreUser && {
        deliveryStatus: Yup.string()
          .label("deliveryStatus")
          .required(Localise(messages, "Please select delivery status")),
        deliveryDate: Yup.string()
          .label("deliveryDate")
          .required(Localise(messages, "Select delivery date")),
        deliveryTime: Yup.string()
          .label("deliveryTime")
          .required(Localise(messages, "Select delivery time")),
      }),
  });
};
