import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text, TouchableOpacity, View } from "react-native";

import I18NContext from "library/contexts/i18N";
import { backgroundColors, colors, fonts } from "styles/theme";
import tw from "tailwind-rn";

const FilterResults = ({ input, action }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const dispatch = useDispatch();
  const filters = useSelector(input);

  const onClear = (index) => {
    let result = [];
    if (index >= 0) {
      result = [...filters];
      result.splice(index, 1);
    }
    dispatch(action({ type: "filters", value: result }));
  };

  return filters.length > 0 ? (
    <View
      style={{
        ...tw("flex-row flex-wrap items-center "),
        flexGrow: 1,
      }}
    >
      <Text style={fonts.heading4}>{Localise(messages, "Filters")}:</Text>
      {filters.map(({ label, value }, index) => {
        return (
          <View
            key={index}
            style={{
              ...tw("flex-row justify-between items-center mb-2 ml-2"),
              backgroundColor: backgroundColors.navBar,
              borderColor: "#a9a9a9",
              borderWidth: 0.5,
              borderRadius: 3,
              padding: 10,
              marginRight: 10,

              shadowColor: colors.medium,
              shadowOffset: {
                width: 0,
                height: 0.5,
              },
              shadowRadius: 1,
              shadowOpacity: 0.5,
            }}
          >
            <TouchableOpacity
              testID={value}
              accessibilityLabel={value}
              onPress={() => onClear(index)}
            >
              <Text
                style={{
                  paddingRight: 5,
                  color: colors.highlighter,
                  fontWeight: "bold",
                  fontSize: 8,
                }}
              >
                X
              </Text>
            </TouchableOpacity>
            <Text
              style={{
                color: colors.highlighter,
                fontWeight: "bold",
                fontSize: 11,
              }}
            >
              {label}
            </Text>
          </View>
        );
      })}
      <Text
        style={fonts.link1}
        onPress={onClear}
        testID={"clear_all"}
        accessibilityLabel={"clear_all"}
      >
        {Localise(messages, "Clear")}
      </Text>
    </View>
  ) : null;
};

export default FilterResults;
