import React from "react";
import { Platform } from "react-native";
import { useSelector } from "react-redux";

import {
  memberEntitlements,
  isMHQNonCoreMember,
  isCoreConnectMember,
} from "library/utils/entitlements";
import {
  selectShopCode,
  selectShopDetails,
  selectShopPreferences,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";

import { AccordionWithBorder } from "components/elements";

import OrderSettings from "./order-settings";
import IntegrationSettings from "./integration-settings";
import PrinterSettings from "./printer-settings";
import Environment from "library/utils/environment";

const Orders = ({ loadingKey, saveSettings }) => {
  const shopCode = useSelector(selectShopCode);
  const shopDetails = useSelector(selectShopDetails);
  const shopSettings = useSelector(selectShopPreferences);
  const isMHQNonCoreShop = isMHQNonCoreMember(shopCode);
  const isCoreConnectShop = isCoreConnectMember(shopCode);
  const isDeliveryServiceOnlyUser =
    shopDetails.memberEntitlements?.memberEntitlementName ===
    memberEntitlements.DELIVERY_SERVICE;
  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const isPrintManagementOn = Environment.get(
    "PRINT_MANAGEMENT_ENABLED",
    false
  );

  return (
    <>
      {!isDeliveryServiceOnlyUser && (
        <AccordionWithBorder title={"Order Settings"}>
          <OrderSettings
            shopSettings={shopSettings}
            loadingKey={loadingKey}
            saveSettings={saveSettings}
          />
        </AccordionWithBorder>
      )}
      {(isMHQNonCoreShop || isCoreConnectShop) && (
        <AccordionWithBorder title={"Integration Settings"}>
          <IntegrationSettings
            shopSettings={shopSettings}
            loadingKey={loadingKey}
            saveSettings={saveSettings}
          />
        </AccordionWithBorder>
      )}
      {isPrintManagementOn && isElectronApp && (
        <AccordionWithBorder title={"Printer Settings"}>
          <PrinterSettings
            shopCode={shopCode}
            shopSettings={shopSettings}
            saveSettings={saveSettings}
            loadingKey={loadingKey}
          />
        </AccordionWithBorder>
      )}
    </>
  );
};

export default Orders;
