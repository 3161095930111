import { createStyles, minWidth } from "react-native-media-queries";
import { colors, backgroundColors } from "styles/theme";

const base = {
  labelText: {
    width: "40%",
  },
  valueText: {
    width: "60%",
    textTransform: "capitalize",
  },
  recordContainer: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: colors.grayScaleLight,
    borderRadius: 3,
    minHeight: 50,
    marginBottom: 5,
    padding: 5,
    justifyContent: "space-between",
  },
  activeRecordContainer: {
    borderWidth: 1,
    borderColor: backgroundColors.primary,
    backgroundColor: "#FAFAFA",
  },
  activeRecord: {
    color: backgroundColors.primary,
    fontWeight: "600",
  },
  menuContainer: {
    width: "10%",
    paddingRight: 10,
    alignItems: "flex-end",
  },
  record: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "baseline",
    flexWrap: "wrap",
    borderLeftWidth: 8,
    width: "90%",
    paddingVertical: 6,
    paddingRight: 10,
    paddingLeft: 5,
  },
  recordItem: {
    textTransform: "capitalize",
  },
  subRecordItem: {
    width: 0,
    height: 0,
    opacity: 0,
  },
  recordItemView: {
    paddingRight: 5,
    paddingVertical: 4,
    overflow: "hidden",
  },
  statusText: {
    width: "auto",
    marginRight: 10,
  },
  statusIcon: {
    width: "auto",
  },
  /* Order Listing page widths */
  productFirstChoiceDescription: {
    width: "70%",
  },
  recipientCity: {
    width: "100%",
  },
  zip: {
    width: "55%",
  },
  recipientName: {
    width: "100%",
  },
  stopName: {
    width: "100%",
  },
  locationType: {
    width: "40%",
  },
  messageNumber: {
    width: "100%",
  },
  displayPrice: {
    width: "30%",
  },
  status: {
    width: "70%",
  },
  displayDeliveryDate: {
    width: "100%",
  },
  deliveryInstructions: {
    width: "100%",
  },
  stopDescription: {
    width: "100%",
  },
  items: {
    width: "30%",
  },
  /* Staff page widths */
  name: {
    width: 200,
  },
  role: {
    width: 150,
  },
  phone: {
    width: 90,
  },
  emailId: {
    width: 200,
  },
  employmentStatus: {
    width: 75,
  },
  department: {
    width: 150,
  },
  labelTextContainer: {
    width: "40%",
  },
};

const desktopStyles = {
  labelText: {
    width: "25%",
  },
  valueText: {
    width: "75%",
    textTransform: "capitalize",
  },
};

export default createStyles(base, minWidth(1550, desktopStyles));
