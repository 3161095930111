import React, { useContext } from "react";

import { DeviceContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";

import Desktop from "./desktop";
import NonDesktop from "./non-desktop";

const SplitScreen = ({
  ScreenOne,
  ScreenTwo,
  CustomHeader,
  headerProps = {},
  rootNavigation,
  navHeaderProps = {},
  screenOneRef,
  screenTwoRef,
  showSecondScreen = false,
}) => {
  const { isDesktop, isTablet } = useContext(DeviceContext);
  const { proxyUser, suspendedMembers = [] } =
    React.useContext(UserProfileContext);
  const hasBanner = !!proxyUser || suspendedMembers.length > 0;

  return isDesktop ? (
    <Desktop
      ScreenOne={ScreenOne}
      ScreenTwo={ScreenTwo}
      CustomHeader={CustomHeader}
      headerProps={headerProps}
      hasBanner={hasBanner}
      screenOneRef={screenOneRef}
      screenTwoRef={screenTwoRef}
      showSecondScreen={showSecondScreen}
    />
  ) : (
    <NonDesktop
      ScreenOne={ScreenOne}
      ScreenTwo={ScreenTwo}
      CustomHeader={CustomHeader}
      headerProps={headerProps}
      rootNavigation={rootNavigation}
      navHeaderProps={navHeaderProps}
      hasBanner={hasBanner}
      screenOneRef={screenOneRef}
      screenTwoRef={screenTwoRef}
      showSecondScreen={showSecondScreen}
      isTablet={isTablet}
    />
  );
};

export default SplitScreen;
