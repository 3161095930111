import React from "react";
import { View, Image } from "react-native";
import { Text } from "react-native-elements";
import { useSelector } from "react-redux";

import tw from "tailwind-rn";
import moment from "moment";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import isEmpty from "lodash/isEmpty";

import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { formatPrice } from "library/utils/formatter";
import { selectApiResponse } from "library/sagas/ongoing/order-details/selector";
import { selectIsEditOrder } from "library/sagas/views/home/drawer/create-order/selector";
import { selectCurrentPage } from "library/sagas/ongoing/global-data/selector";

import { hasPaymentProcessingError } from "components/views/drawer/order-details/helper";

import IMAGES from "static/assets/images";
import { fonts, colors, shapes } from "styles/theme";
import styles from "../../styles";

const keyMap = {
  CASH_OR_CHECK: "Cash/Check",
  INVOICE: "Invoice",
  PAID_ELSEWHERE: "Paid Elsewhere",
  CREDIT_CARD: "CC",
  SAVED_CARD: "Saved Payment",
  CREDIT_BALANCE: "Credit Balance",
  GIFT_CARD: "Gift Card",
  PAY_LATER: "Pay Later",
  CREDIT_MEMO: "Credit Memo",
};

export const getSortedChargesList = (paymentMethods) => {
  let chargesList = [];

  paymentMethods.map((each) => {
    const {
      paymentMethodType,
      invoiceOrderPaymentInfo: { payments = [] } = {},
      paymentMethodDetails: {
        authorizationDetails,
        refundDetails,
        creditCardType,
        creditCardNumber,
        note = "",
        tenderedAmount = 0,
        gcLastChars = "",
        settlementDetails = {},
        paymentType = "",
      },
      paymentStatus = "",
    } = each;

    const hasProcessingError = hasPaymentProcessingError({
      settlementDetails,
      paymentStatus,
    });

    if (paymentMethodType === "INVOICE") {
      payments.map((payment) => {
        chargesList.push({
          paymentMethodType,
          paymentReceivedType: payment.paymentReceivedType,
          transactionDate: payment.paymentReceivedDate,
          transactionAmount: payment?.paymentAmount || 0,
          chargeType: "receivePayment",
          gcLastChars,
        });
      });
    }

    if (!isEmpty(refundDetails)) {
      refundDetails.map((each) => {
        chargesList.push({
          paymentMethodType,
          creditCardType,
          creditCardNumber,
          transactionDate: each.refundDate,
          transactionAmount: each.amount,
          chargeType: "refund",
          refundCode: each?.refundCode,
          note,
          tenderedAmount,
          gcLastChars,
          paymentType,
          testID: `${paymentMethodType}-${each.amount}`,
        });
      });
    }

    if (!isEmpty(authorizationDetails)) {
      chargesList.push({
        paymentMethodType,
        creditCardType,
        creditCardNumber,
        transactionDate:
          authorizationDetails?.find(
            (obj) => obj.name === "authorizationRequestDate"
          )?.value ||
          authorizationDetails?.find((obj) => obj.name === "authorizationDate")
            ?.value,
        transactionAmount: authorizationDetails?.find(
          (obj) => obj.name === "amount"
        )?.value,
        chargeType: "",
        note,
        tenderedAmount,
        gcLastChars,
        hasProcessingError,
        paymentType,
      });
    }
  });

  chargesList = sortBy(chargesList, "transactionDate");

  return chargesList;
};

const ChargesComponent = ({ isCoreConnectShop = false }) => {
  const orderDetailResponse = useSelector(selectApiResponse);
  const isEditOrder = useSelector(selectIsEditOrder);
  const currentPage = useSelector(selectCurrentPage);
  const { isMobile } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);

  if (isEmpty(orderDetailResponse)) return null;

  const { paymentDetails = {} } = orderDetailResponse;
  if (isEmpty(paymentDetails)) return null;

  const paymentMethods = get(paymentDetails, "paymentMethod", []);

  const invoiceOrderDetails =
    paymentMethods?.find((obj) => obj?.invoiceOrderPaymentInfo) ||
    paymentMethods[0];

  const {
    paymentMethodType = "",
    invoiceOrderPaymentInfo: {
      isOrderCancelled = false,
      isPaymentCompleted = false,
      // orderTotalAmount = 0,
      invoiceUnpaidAmount = 0,
      invoicePaidAmount = 0,
    } = {},
  } = invoiceOrderDetails;

  const hasSettlementError = get(
    orderDetailResponse,
    "orderItems.0.hasSettlementError",
    false
  );

  const chargesList = getSortedChargesList(paymentMethods);

  const isEditOrderFlow = isEditOrder && currentPage === "create-order";

  const baseStyle = {
    ...fonts.default,
    paddingLeft: 0,
  };

  const subContainerStyle = {
    width: "50%",
  };

  const dateStyle = {
    ...baseStyle,
    width: "40%",
    ...(isEditOrderFlow && {
      width: "auto",
      minWidth: 60,
    }),
  };
  const paymentInfoStyle = {
    ...baseStyle,
    width: "60%",
    ...(isEditOrderFlow &&
      !isMobile && {
        width: "auto",
      }),
  };
  let paidInFullDate = "";
  if (paymentMethodType === "INVOICE") {
    paidInFullDate = chargesList.length
      ? moment(chargesList[chargesList.length - 1].transactionDate).format(
          "MM/DD/YY"
        )
      : "";
  }

  const ChargesBlock = () => (
    <>
      {chargesList &&
        chargesList.map((each, index) => {
          const {
            paymentMethodType,
            paymentReceivedType = "",
            creditCardType,
            creditCardNumber,
            transactionDate,
            transactionAmount = 0,
            chargeType,
            note,
            tenderedAmount,
            refundCode = "",
            gcLastChars = "",
            hasProcessingError = false,
            paymentType = "",
          } = each || {};

          return (
            <View key={index}>
              {[
                "CASH_OR_CHECK",
                "PAID_ELSEWHERE",
                "INVOICE",
                "CREDIT_BALANCE",
                "GIFT_CARD",
                "PAY_LATER",
                "PAYPAL",
                "DIGITAL_PAYMENT",
              ].includes(paymentMethodType) && (
                <View style={tw("flex flex-row my-1 items-center")}>
                  <View
                    style={{
                      ...tw("flex flex-row items-center"),
                      ...subContainerStyle,
                      ...(isEditOrderFlow &&
                        !hasSettlementError && { width: "75%" }),
                      ...(isEditOrderFlow &&
                        hasSettlementError && { width: "55%" }),
                    }}
                  >
                    <Text style={{ ...dateStyle }}>
                      {moment(transactionDate).format("MM/DD/YY")}
                    </Text>
                    {isEditOrderFlow && <Text>{"    "}</Text>}
                    {paymentMethodType === "DIGITAL_PAYMENT" ? (
                      <View style={paymentInfoStyle}>
                        {paymentType === "APPLEPAY" && (
                          <Image
                            style={{ width: 55, height: 35 }}
                            resizeMode={"cover"}
                            source={IMAGES["apple-pay"]}
                          />
                        )}
                      </View>
                    ) : (
                      <Text style={paymentInfoStyle}>
                        {Localise(
                          messages,
                          paymentMethodType === "GIFT_CARD"
                            ? `${`Gift Card`} **${gcLastChars}`
                            : paymentMethodType === "PAY_LATER"
                            ? "Pay Later"
                            : paymentMethodType === "CASH_OR_CHECK"
                            ? "Cash/Check"
                            : paymentMethodType === "INVOICE"
                            ? chargeType === "receivePayment"
                              ? `Received Payment - ${keyMap[paymentReceivedType]}`
                              : "Invoice"
                            : paymentMethodType === "PAYPAL"
                            ? "PayPal"
                            : "Paid Elsewhere"
                        )}
                        {paymentMethodType === "PAID_ELSEWHERE" &&
                          note !== "" &&
                          `- ${note}`}
                        {paymentMethodType === "PAY_LATER" &&
                          note !== "" &&
                          `- ${note}`}
                        {paymentMethodType === "CASH_OR_CHECK" &&
                          tenderedAmount !== 0 &&
                          chargeType !== "refund" &&
                          `- ${Localise(
                            messages,
                            "Tendered Amount"
                          )}: $${formatPrice(tenderedAmount)}`}
                      </Text>
                    )}
                  </View>
                  <Text
                    style={{
                      ...styles.valueText,
                      width: "15%",
                      ...(isMobile && { minWidth: 60 }),
                      paddingLeft: 4,
                      ...(chargeType === "refund" && { color: colors.red }),
                      ...(isEditOrderFlow &&
                        !hasSettlementError && { width: "25%" }),
                      ...(isEditOrderFlow &&
                        hasSettlementError && { width: "20%" }),
                    }}
                    testID={each?.testID || ""}
                  >
                    {refundCode === "A40" && !isEditOrderFlow
                      ? `($${formatPrice(transactionAmount || 0)})*`
                      : chargeType === "refund"
                      ? `($${formatPrice(transactionAmount || 0)})`
                      : `$${formatPrice(transactionAmount || 0)}`}
                  </Text>
                  {hasSettlementError && hasProcessingError && (
                    <Text
                      style={{
                        ...styles.valueText,
                        width: isEditOrderFlow ? "25%" : "35%",
                        color: colors.red,
                        textTransform: "none",
                      }}
                    >
                      {Localise(
                        messages,
                        `${
                          isCoreConnectShop
                            ? "MHQ will not support Processing Payment. Once payment is completed for this order at your end, select Paid Elsewere to remove this order from Needs Action."
                            : paymentMethodType === "INVOICE"
                            ? "Invoice processing error. Select new payment method"
                            : "Processing Error"
                        }`
                      )}
                    </Text>
                  )}
                </View>
              )}
              {paymentMethodType === "CREDIT_CARD" && (
                <View style={tw("flex flex-row my-1")}>
                  <View
                    style={{
                      ...tw("flex flex-row"),
                      ...subContainerStyle,
                      ...(isEditOrderFlow &&
                      !(hasSettlementError && index === 0)
                        ? {
                            width: "75%",
                          }
                        : { width: "50%" }),
                    }}
                  >
                    <Text style={{ ...dateStyle }}>
                      {moment(transactionDate).format("MM/DD/YY")}
                    </Text>
                    {isEditOrderFlow && <Text>{"    "}</Text>}
                    <Text style={paymentInfoStyle}>
                      {creditCardType} ****{creditCardNumber}
                    </Text>
                  </View>
                  <Text
                    style={{
                      ...styles.valueText,
                      width: "15%",
                      ...(isMobile && { minWidth: 60 }),
                      paddingLeft: 4,
                      ...(isEditOrderFlow &&
                        !hasSettlementError && { width: "25%" }),
                      ...(isEditOrderFlow &&
                        hasSettlementError && { width: "20%" }),
                      ...(chargeType === "refund" && { color: colors.red }),
                    }}
                    testID={each?.testID || ""}
                  >
                    {chargeType === "refund"
                      ? `($${formatPrice(transactionAmount || 0)})${
                          refundCode === "A40" && !isEditOrderFlow ? "*" : ""
                        }`
                      : `$${formatPrice(transactionAmount || 0)}`}
                  </Text>
                  {hasSettlementError && hasProcessingError && (
                    <Text
                      style={{
                        ...styles.valueText,
                        width: isEditOrderFlow ? "25%" : "35%",
                        color: colors.red,
                        textTransform: "none",
                      }}
                    >
                      {Localise(
                        messages,
                        `${
                          isCoreConnectShop
                            ? "MHQ will not support Processing Payment. Once payment is completed for this order at your end, select Paid Elsewere to remove this order from Needs Action."
                            : "Credit Card processing error. Select new payment method"
                        }`
                      )}
                    </Text>
                  )}
                </View>
              )}
            </View>
          );
        })}

      {paymentMethodType === "INVOICE" && (
        <View style={tw("flex flex-row my-1")}>
          <View
            style={{
              ...tw("flex flex-row"),
              ...subContainerStyle,
              ...(isEditOrderFlow && { width: "75%" }),
            }}
          >
            <Text style={{ ...dateStyle }}>
              {`${isPaymentCompleted ? paidInFullDate : ""}`}
            </Text>
            {isEditOrderFlow && <Text>{"    "}</Text>}
            <Text style={paymentInfoStyle}>
              {Localise(
                messages,
                isPaymentCompleted
                  ? "Invoice Paid in Full"
                  : isOrderCancelled
                  ? "Invoice Canceled"
                  : "Invoice Unpaid Balance"
              )}
            </Text>
          </View>
          {!isOrderCancelled && (
            <Text
              style={{
                ...styles.valueText,
                paddingLeft: 4,
                width: "55%",
                ...(isEditOrderFlow && {
                  width: "25%",
                }),
              }}
            >
              {`$${parseFloat(
                isPaymentCompleted
                  ? invoicePaidAmount
                  : invoiceUnpaidAmount < 0
                  ? 0
                  : invoiceUnpaidAmount
              ).toFixed(2)}`}
            </Text>
          )}
        </View>
      )}
    </>
  );

  return (
    <>
      {currentPage !== "create-order" && (
        <>
          <View style={tw("flex flex-row mt-4 mb-1")}>
            <Text style={{ ...fonts.heading5 }}>
              {Localise(messages, "Charges")}
            </Text>
          </View>
          <ChargesBlock />
        </>
      )}
      {isEditOrderFlow && (
        <View
          style={{
            ...shapes.sectionBorder,
            paddingHorizontal: 17,
            paddingVertical: 15,
            marginTop: 15,
          }}
        >
          <View style={[tw("flex flex-col")]}>
            <Text
              style={{
                ...fonts.sectionHeading,
                ...tw("mr-3"),
                color: colors.highlighter,
                marginBottom: 10,
              }}
            >
              {Localise(messages, "Previous Charges")}
            </Text>
            <ChargesBlock />
          </View>
        </View>
      )}
    </>
  );
};

export default ChargesComponent;
