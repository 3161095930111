/* eslint-disable no-useless-escape */
import React, { useContext, useEffect, useRef } from "react";
import { View, Text, Platform, ActivityIndicator } from "react-native";
import { Button } from "react-native-elements";
import tw from "tailwind-rn";

import { PrintIframeAction } from "components/elements";
import { backgroundColors, theme, colors, fonts } from "styles/theme";
import QuillEditor from "components/elements/react-quill-editor";
import { ToasterHandler } from "components/elements";
import I18NContext from "library/contexts/i18N";
import { getSingleCardPrintHTML as getPrintHTML } from "components/views/drawer/order-details/trifold-print-helper";

import { request } from "library/utils/request";
import UserProfileContext from "library/contexts/userProfile";
import UserProfileStorage from "library/storage/userProfile";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { isValidJSON } from "library/utils/formatter";
import { fetchOrderData } from "library/sagas/ongoing/order-details/slice";
import { selectRecordData } from "library/sagas/ongoing/current-orders/selector";
import { getOrderPatchUpdates } from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import EditCard from "./edit-card";
import { get } from "lodash";
import { customFind } from "library/utils/customHighOrderFunctions";
import { generateQRCode } from "library/sagas/ongoing/order-details/slice";
import {
  getZoneDetails,
  printTrigger,
} from "../../order-head/take-action/helper";
import { selectImages } from "library/sagas/ongoing/global-data/selector";
const EnclosureCard = ({
  recipientInfo,
  receivingMember,
  updatedCardMessage,
  setUpdatedCardMessage,
  updatedRecipient,
  setUpdatedRecipient,
  fileInfo,
  cardSettings,
  isSmallScreen,
  isPickUpOrder,
  orderSource,
  sourceMemberCode,
  variant = "",
  pickUpBy = "",
  occasion = "",
  recordId,
  deliveryMethod,
  ...otherProps
}) => {
  const currentOrderTileRecordIdRef1 = useRef("");
  const reactQuillCardRef = useRef(null);

  if (currentOrderTileRecordIdRef1.current !== recordId)
    currentOrderTileRecordIdRef1.current = recordId;
  const isWeb = Platform.OS === "web";
  const isElectronApp =
    isWeb && document.getElementById("isElectronApp").value === "true";

  const { messages: locMessages, Localise } = React.useContext(I18NContext);
  const {
    trifold_card_print_settings: printPreferencesArray = [],
    additional_sympathy_card,
  } = UserProfileStorage.getShopPreferences(sourceMemberCode);
  const [clearEditor, setClearEditor] = useStateIfMounted(false);
  const [loading, setLoading] = useStateIfMounted(false);
  const [error, setError] = useStateIfMounted("");

  const dispatch = useDispatch();
  const shopPreferences =
    UserProfileStorage.getShopPreferences(sourceMemberCode);
  let floristShopContactSettings = "";
  floristShopContactSettings = get(
    shopPreferences,
    "florist_contact_information_settings",
    ""
  );

  if (
    (!isValidJSON(floristShopContactSettings) &&
      floristShopContactSettings === "JDE") ||
    floristShopContactSettings == ""
  ) {
    floristShopContactSettings =
      '{"shopName":"","streetAddress":"","aptSuite":"","city":"","state":"","zip":"","phone":"","email":"","website":"","additionalMessage":"","contactName":"","printed":[],"web":[],"network":[]}';
  }

  let {
    shopName: shopNameContact = "",
    streetAddress: streetAddressContact = "",
    aptSuite: aptSuiteContact = "",
    city: cityContact = "",
    state: stateContact = "",
    zip: zipContact = "",
    phone: phoneContact = "",
    email: emailContact = "",
    website: websiteContact = "",
    printed = [],
  } = JSON.parse(floristShopContactSettings);

  const macAddress = isElectronApp
    ? document.getElementById("macAddress").value
    : "";

  const printPreferences =
    (isElectronApp &&
      customFind(
        printPreferencesArray,
        (each) => each.macAddress === macAddress
      )) ||
    {};

  let {
    floristContactInfo,
    storeMessage = "",
    floristWebsite,
    contactInfoTopHalf = "Florist Info",
    orderInfo = [],
    contactInfoBottomHalf = "Recipient Info",
  } = cardSettings;

  const isFloristInfoTop = contactInfoTopHalf === "Florist Info";
  const isAdhocTriFold = variant === "adhoc-trifold";
  const needDeliveryZone =
    contactInfoBottomHalf === "Delivery Info" &&
    orderInfo.includes("Delivery Zone");

  const isLocalOrder = [
    "FLORIST_DELIVERED",
    "STORE_PICKUP",
    "WALK_IN",
  ].includes(deliveryMethod);

  let {
    businessName: name,
    phone,
    email,
    addressLine1,
    city: floristCity,
    state: floristState,
    zip: floristZip,
  } = receivingMember || {};

  if (printed.includes("shopName")) {
    name = shopNameContact;
  }
  if (printed.includes("streetAddress")) {
    addressLine1 = streetAddressContact;
    floristCity = cityContact;
    floristState = stateContact;
    floristZip = zipContact;
  } else {
    aptSuiteContact = "";
  }
  if (printed.includes("phone")) {
    phone = phoneContact;
  }
  if (printed.includes("email")) {
    email = emailContact;
  }
  if (printed.includes("website")) {
    floristWebsite = websiteContact;
  }
  const [isCardEditable, setIsCardEditable] = useStateIfMounted(false);
  const [isSubmit, setIsSubmit] = useStateIfMounted(true);
  const orderDetailResponse = useSelector(selectRecordData);
  const [defaultCardMessage, setDefaultCardMessage] =
    useStateIfMounted(updatedCardMessage);

  const onEditorFocus = () => {
    setIsCardEditable(true);
  };

  const onEditorChange = (value) => {
    if (isCardEditable) {
      isAdhocTriFold
        ? setUpdatedCardMessage(value)
        : setDefaultCardMessage(value);
      value !== updatedCardMessage ? setIsSubmit(false) : setIsSubmit(true);
    }
  };

  const updatedCardMessageHtml = (message) => {
    const escapedMessage = message
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    const htmlMessage = `<p>${escapedMessage}</p>`;
    return htmlMessage;
  };

  useEffect(() => {
    if (isCardEditable) {
      updatedCardMessageHtml(updatedCardMessage);
    }
  }, [isCardEditable]);

  useEffect(() => {
    if (error) {
      setIsSubmit(true);
    }
  }, [error]);
  const handleQuillRef = (quill) => {
    if (quill) {
      reactQuillCardRef.current = quill.getEditor();
    }
  };

  const handleQuillRef1 = (quill) => {
    if (quill) {
      const quill = quill?.getEditor();
    }
  };

  const handleCustomisedCard = (tokenId = "") => {
    setLoading(true);
    otherProps.values = {
      ...otherProps.values,
      orderItems: [
        {
          ...otherProps.values.orderItems[0],
          deliveryInfo: {
            ...otherProps.values.orderItems[0].deliveryInfo,
            cardMessage: defaultCardMessage,
          },
        },
      ],
    };

    const orderUpdatesReqObj = getOrderPatchUpdates(
      orderDetailResponse,
      otherProps.values,
      tokenId
    );
    if (orderUpdatesReqObj && orderUpdatesReqObj.length) {
      if (isLocalOrder) {
        request("lock-order", {
          recordId: recordId,
          deliveryMethod: deliveryMethod,
        })
          .then(() => {
            setLoading(false);
            modifyOrder(orderUpdatesReqObj);
          })
          .catch(() => {
            let errorMessage =
              "Card message modify request failed, please try again.";
            ToasterHandler("uh oh", Localise(locMessages, errorMessage));
            setLoading(false);
          });
      } else {
        setLoading(false);
        modifyOrder(orderUpdatesReqObj);
      }
    }
  };

  const modifyOrder = (orderUpdates) => {
    setLoading(true);
    request("modify-order", {
      recordId,
      deliveryMethod,
      orderUpdates,
      sourceMemberCode,
    })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          dispatch(
            fetchOrderData({
              recordId,
              deliveryMethod,
              sourceMemberCode,
              refreshDetails: true,
            })
          );
          ToasterHandler(
            "hooray",
            Localise(locMessages, "Card message modified successfully")
          );
          setIsCardEditable(false);
        }, 2500);
      })
      .catch(() => {
        setLoading(false);
        ToasterHandler(
          "uh oh",
          Localise(
            locMessages,
            "Card message modify request failed, please try again."
          )
        );
      });
  };

  const modules = {
    toolbar: [
      [{ font: ["arial", "georgia", "impact", "verdana", "tahoma", "times"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ color: [] }],
      [{ align: [] }],
    ],
  };

  const orderData = get(orderDetailResponse, "orderItems.0", {});
  const imagesData = useSelector(selectImages);
  const checkForPlatformAndAdhoc = Platform.OS === "web" && !isAdhocTriFold;
  const {
    memberCodes = [],
    userProfile: { firstName: operator } = {},
    userRole,
  } = useContext(UserProfileContext);
  const {
    hasSettlementError = false,
    hasFulfillmentError = false,
    hasDSFulfillmentError = false,
    status = "",
  } = orderData;
  const isQuillEditorEditable =
    hasSettlementError ||
    hasFulfillmentError ||
    hasDSFulfillmentError ||
    ["CANCELLED", "REJECTED", "FORFEITED", "ERROR"].includes(status);

  let isBottom = false;
  let isRight = false;
  let isLogo = false;

  const logoPlacement =
    cardSettings?.logoOrientation || cardSettings?.bannerOrientation;

  if (cardSettings?.isLogoOpted) {
    const positions = cardSettings?.logoOrientation.split(" ");
    isBottom = positions[0] !== "Top";
    isRight = positions[1] !== "Left";
    isLogo = true;
  } else if (cardSettings?.isBannerOpted) {
    isBottom = cardSettings?.bannerOrientation === "Bottom";
  }

  const { qr_code_global_enabled = "" } =
    UserProfileStorage.getShopPreferences(sourceMemberCode);
  const showQrCode = qr_code_global_enabled === "true";

  const { qr_code_trifold_enabled = "" } =
    UserProfileStorage.getShopPreferences(sourceMemberCode);
  const showTriFoldQrCode = showQrCode
    ? qr_code_trifold_enabled === "true"
    : false;

  const returnQRCode = (qrImageData, triFoldPrintTrigger) => {
    showTriFoldQrCode
      ? dispatch(
          generateQRCode({
            params: {
              qrPayload: [
                {
                  oid: orderData.orderItemId || "", // orderItemId
                  sm: sourceMemberCode || "", // sourceMemberCode based on orderDirection
                  dm: deliveryMethod || "", // order deliveryMethod
                },
              ],
            },
            type: "SINGLE",
            resolve: (resp) => {
              qrImageData = resp[orderData.orderItemId] || "";
              triFoldPrintTrigger(qrImageData);
            },
            reject: () => {
              triFoldPrintTrigger(qrImageData);
            },
          })
        )
      : triFoldPrintTrigger();
  };

  const printTrifoldCard = () =>
    printTrigger({
      selectedAction: "triCardPrint",
      orderDetails: orderData,
      memberCodes,
      customerInfo: orderDetailResponse?.customerInfo,
      paymentDetails: orderDetailResponse?.paymentDetails,
      operatorName: operator,
      messages: locMessages,
      Localise,
      dispatch,
      selectedproducts: [],
      userRole,
      cardInfo: orderDetailResponse?.cardInfo,
      imagesData,
      cardMessage: checkForPlatformAndAdhoc
        ? defaultCardMessage
        : updatedCardMessage,
      triggerAction: otherProps.triggerAction,
      orderDetailResponse,
      ordersList: false,
      deliveryInfo: get(orderDetailResponse, `orderItems.0.deliveryInfo`, {}),
      isAdhocTriFold,
      isCallFromEnclosureCard: true,
    });

  const printIframeFunction = async (qrImageData) => {
    const zoneDetails =
      !isAdhocTriFold && needDeliveryZone
        ? await getZoneDetails(orderData)
        : [];
    PrintIframeAction(
      getPrintHTML({
        recipientInfo: isAdhocTriFold ? updatedRecipient : recipientInfo,
        receivingMember,
        cardSettings,
        cardMessage:
          Platform.OS === "web" && !isAdhocTriFold
            ? defaultCardMessage
            : updatedCardMessage,
        fileInfo,
        isPickUpOrder,
        orderSource,
        isAdhocTriFold,
        pickUpBy,
        orderDetails: orderData,
        zoneDetails,
        qrImageData,
        numberOfPrints:
          additional_sympathy_card === "true" && occasion === "FUNERAL" ? 2 : 1,
      }),
      { printType: "trifold", preferences: printPreferences },
      () => {},
      "iframeId"
    );
  };
  return (
    <View>
      <View
        style={{
          marginVertical: 10,
          ...(!isAdhocTriFold &&
            Platform.OS === "web" && {
              borderColor: colors.grayScaleLight,
              borderWidth: 1,
              borderRadius: 10,
            }),
        }}
      >
        <View
          style={{
            ...(isAdhocTriFold && {
              borderColor: colors.light,
              borderWidth: 1,
              width: isSmallScreen ? "100%" : 650,
            }),
          }}
        >
          {Platform.OS === "web" ? (
            <QuillEditor
              key="cardMsgEditor"
              id="card_details_editor_cardMsg"
              placeholder={
                isAdhocTriFold
                  ? Localise(
                      locMessages,
                      "Type sender`s recipient message here"
                    )
                  : ""
              }
              value={!isAdhocTriFold ? defaultCardMessage : updatedCardMessage}
              onEditorChange={onEditorChange}
              setMsgValue={setUpdatedCardMessage}
              handleRef={handleQuillRef}
              onEditorFocus={onEditorFocus}
              nonEditable={!isCardEditable}
              imgUrl={fileInfo?.uri}
              logoPlacement={logoPlacement}
              isBottom={isBottom}
              isRight={isRight}
              isLogo={isLogo}
              minHeight={!isAdhocTriFold ? "auto" : 335}
              testID="card_details_editor_cardMsg"
              modules={modules}
              error={error}
              setError={setError}
              maxLength={
                isAdhocTriFold
                  ? fileInfo?.uri
                    ? 170
                    : 380
                  : fileInfo?.uri
                  ? 170
                  : 200
              }
              readOnly={!isAdhocTriFold ? isQuillEditorEditable : false}
              style={{
                opacity: !isAdhocTriFold && isQuillEditorEditable ? 0.5 : 1,
                pointerEvents:
                  !isAdhocTriFold && isQuillEditorEditable ? "none" : "",
              }}
              setDefaultTextCenterAligned={true}
            />
          ) : (
            <EditCard
              msgValue={updatedCardMessage}
              setMsgValue={setUpdatedCardMessage}
              fileInfo={fileInfo}
              cardSettings={cardSettings}
              isSmallScreen={isSmallScreen}
              isAdhocTriFold={isAdhocTriFold}
              variant="cardMsg"
              placeholder={
                isAdhocTriFold
                  ? Localise(
                      locMessages,
                      "Type sender`s recipient message here"
                    )
                  : ""
              }
              clearEditor={clearEditor}
              setClearEditor={setClearEditor}
            />
          )}
          {isAdhocTriFold && (
            <>
              <View
                style={[
                  tw("p-2 items-center"),
                  {
                    borderTopWidth: 1,
                    borderTopColor: colors.light,
                    minHeight: 300,
                  },
                ]}
              >
                <Text style={{ textAlign: "center" }}>
                  {storeMessage.replace(/<br\s*[\/]?>/gi, "\n")}
                </Text>
              </View>
              <View
                style={[
                  tw("p-2 items-center"),
                  {
                    borderTopWidth: 1,
                    borderTopColor: colors.light,
                    minHeight: 300,
                  },
                ]}
              >
                {!isFloristInfoTop && (
                  <View
                    style={[
                      tw("p-2"),
                      {
                        borderWidth: 1,
                        borderColor: colors.light,
                        minHeight: 120,
                        width: "80%",
                      },
                    ]}
                  >
                    {Platform.OS === "web" ? (
                      <QuillEditor
                        key="recipient_editor"
                        id="card_details_editor_recipient"
                        placeholder={Localise(
                          locMessages,
                          "Type recipient address here"
                        )}
                        value={updatedRecipient}
                        setMsgValue={setUpdatedRecipient}
                        handleRef={handleQuillRef1}
                        updateEditorOnChange={!isAdhocTriFold}
                        logoPlacement={logoPlacement}
                        isBottom={isBottom}
                        isRight={isRight}
                        isLogo={isLogo}
                        minHeight={220}
                        testID="recipient_editor"
                        maxLength={200}
                        toolbarId={`toolbar_${variant}`}
                        modules={modules}
                        error={error}
                        setError={setError}
                        height={!isAdhocTriFold ? 420 : ""}
                        setDefaultTextCenterAligned={true}
                      />
                    ) : (
                      <EditCard
                        msgValue={updatedRecipient}
                        setMsgValue={setUpdatedRecipient}
                        fileInfo={{ uri: "", imageUrl: "" }}
                        cardSettings={cardSettings}
                        isSmallScreen={isSmallScreen}
                        isAdhocTriFold={isAdhocTriFold}
                        variant="recipient"
                        placeholder={Localise(
                          locMessages,
                          "Type recipient address here"
                        )}
                        clearEditor={clearEditor}
                        setClearEditor={setClearEditor}
                      />
                    )}
                  </View>
                )}
                {!!floristContactInfo?.length && (
                  <View
                    style={[
                      tw("p-2 m-2 items-center justify-center"),
                      {
                        borderWidth: 1,
                        borderColor: colors.light,
                        minHeight: 120,
                        width: "80%",
                      },
                    ]}
                  >
                    {floristContactInfo.includes("Florist Name") && (
                      <Text style={[fonts.heading3, tw("mb-2")]}>{name}</Text>
                    )}
                    {floristContactInfo.includes("Florist Website") && (
                      <Text style={tw("mb-2")}>{floristWebsite || ""}</Text>
                    )}
                    {floristContactInfo.includes("Florist Email") && (
                      <Text style={tw("mb-2")}>{email}</Text>
                    )}
                    {floristContactInfo.includes("Florist Phone") && (
                      <Text style={tw("mb-2")} fsClass="fs-exclude">
                        {phone}
                      </Text>
                    )}
                    {floristContactInfo.includes("Florist Address") && (
                      <>
                        <Text style={tw("mb-2")}>
                          {`${addressLine1}`}
                          {aptSuiteContact != "" && (
                            <Text
                              style={[{ textAlign: "left" }]}
                            >{` ${aptSuiteContact}`}</Text>
                          )}
                        </Text>
                        <Text>{` ${floristCity}, ${floristState}, ${floristZip}`}</Text>
                      </>
                    )}
                  </View>
                )}
                {isFloristInfoTop && (
                  <View
                    style={[
                      tw("p-2"),
                      {
                        borderWidth: 1,
                        borderColor: colors.light,
                        minHeight: 120,
                        width: "80%",
                      },
                    ]}
                  >
                    {Platform.OS === "web" ? (
                      <QuillEditor
                        key="editor2"
                        id="card_details_editor_recipient"
                        placeholder={Localise(
                          locMessages,
                          "Type recipient address here"
                        )}
                        value={updatedRecipient}
                        setMsgValue={setUpdatedRecipient}
                        handleRef={handleQuillRef1}
                        updateEditorOnChange={!isAdhocTriFold}
                        logoPlacement={logoPlacement}
                        isBottom={isBottom}
                        isRight={isRight}
                        isLogo={isLogo}
                        minHeight={220}
                        testID="card_details_editor_recipient"
                        maxLength={200}
                        toolbarId={`toolbar_${variant}`}
                        modules={modules}
                        error={error}
                        setError={setError}
                        height={!isAdhocTriFold ? 420 : ""}
                        setDefaultTextCenterAligned={true}
                      />
                    ) : (
                      <EditCard
                        msgValue={updatedRecipient}
                        setMsgValue={setUpdatedRecipient}
                        fileInfo={{ uri: "", imageUrl: "" }}
                        cardSettings={cardSettings}
                        isSmallScreen={isSmallScreen}
                        isAdhocTriFold={isAdhocTriFold}
                        variant="recipient"
                        placeholder={Localise(
                          locMessages,
                          "Type recipient address here"
                        )}
                        clearEditor={clearEditor}
                        setClearEditor={setClearEditor}
                      />
                    )}
                  </View>
                )}
              </View>
            </>
          )}
        </View>
        <View
          style={[
            tw("flex-row justify-end"),
            { zIndex: -1 },
            {
              ...(!isAdhocTriFold && { padding: 8 }),
              ...(isAdhocTriFold && {
                paddingTop: 10,
                width: isSmallScreen ? "100%" : 650,
                justifyContent: "space-between",
              }),
            },
          ]}
        >
          {isAdhocTriFold && (
            <View
              style={{
                ...theme.Button.secondaryButtonStyle,
                ...tw("flex-row mr-1 mt-1"),
                paddingHorizontal: 10,
                paddingVertical: 5,
              }}
            >
              <Text
                style={{
                  ...theme.Button.secondaryTitleStyle,
                  color: backgroundColors.primary,
                  fontSize: 12,
                  fontWeight: "bold",
                }}
                testID="clear_card"
                accessibilityLabel="clear_card"
                onPress={() => {
                  setClearEditor(!clearEditor);
                  setUpdatedCardMessage("");
                  setUpdatedRecipient("");
                }}
              >
                {Localise(locMessages, "Clear Card")}
              </Text>
            </View>
          )}
          <View style={tw("flex flex-row items-center justify-end")}>
            {Platform.OS === "web" && !isAdhocTriFold && (
              <Button
                testID={"print-card-message"}
                accessibilityLabel={"print-card-message"}
                titleStyle={theme.Button.secondaryTitleStyle}
                buttonStyle={{
                  ...theme.Button.cancelButtonStyle,
                  paddingVertical: 5,
                  paddingHorizontal: 10,
                  marginRight: 2,
                }}
                containerStyle={{
                  justifyContent: "center",
                  alignItems: "flex-start",
                  marginVertical: 0,
                }}
                onPress={() => {
                  let qrImageData = "";
                  const triFoldPrintTrigger = async (qrImageData = "") => {
                    printTrifoldCard();
                  };
                  // This function will generate QR code first and then call the above print trigger function
                  returnQRCode(qrImageData, triFoldPrintTrigger);
                }}
                title={Localise(locMessages, "Print Tri-Fold Card")}
                disabled={isQuillEditorEditable}
              />
            )}
            <Button
              testID={"save-card-message"}
              accessibilityLabel={"save-card-message"}
              buttonStyle={{
                paddingVertical: 5,
                paddingHorizontal: 10,
                marginRight: 0,
              }}
              containerStyle={{
                justifyContent: "center",
                alignItems: "flex-start",
                marginRight: 0,
                margin: 0,
              }}
              onPress={() => {
                let qrImageData = "";
                const triFoldPrintTrigger = async (qrImageData = "") => {
                  !isAdhocTriFold && Platform.OS !== "web"
                    ? printTrifoldCard()
                    : printIframeFunction(qrImageData);
                };

                isAdhocTriFold || Platform.OS !== "web"
                  ? returnQRCode(qrImageData, triFoldPrintTrigger)
                  : isCardEditable && Platform.OS === "web"
                  ? handleCustomisedCard()
                  : "";
              }}
              title={
                Platform.OS === "web" && !isAdhocTriFold
                  ? Localise(locMessages, "Save")
                  : Localise(locMessages, "Print Tri-Fold Card")
              }
              disabled={
                Platform.OS === "web"
                  ? loading ||
                    (!isCardEditable && !isAdhocTriFold) ||
                    (isSubmit && !isAdhocTriFold) ||
                    error !== ""
                  : false
              }
            />
            {loading && (
              <ActivityIndicator
                style={{ marginLeft: 5 }}
                color={colors.activityIndicator}
                testID={"loader"}
              />
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default EnclosureCard;
