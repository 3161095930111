import React from "react";
import { Provider } from "react-redux";
import AppNavigator from "components/containers/navigator";
import AppSettingsProvider from "library/providers/appSettings";
import UserAuthProvider from "library/providers/userAuth";
import UserProfileProvider from "library/providers/userProfile";
import I18NProvider from "library/providers/i18N";
import { ToasterContainer } from "components/elements";
import "styles/scrollbar.css";
import store from "library/sagas/store";
import InternetConnectionAlert from "react-native-internet-connection-alert";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { FallBackWebComponent } from "./components/elements";
import { logError } from "./components/elements/fallback/helper";

export default function RenderUI() {
  return (
    <I18NProvider>
      <UserAuthProvider>
        <UserProfileProvider>
          <AppSettingsProvider>
            <Provider store={store}>
              <InternetConnectionAlert>
                <ReactErrorBoundary
                  FallbackComponent={FallBackWebComponent}
                  onError={logError}>
                  <AppNavigator />
                </ReactErrorBoundary>
              </InternetConnectionAlert>
              <ToasterContainer />
            </Provider>
          </AppSettingsProvider>
        </UserProfileProvider>
      </UserAuthProvider>
    </I18NProvider>
  );
}
