/* eslint-disable no-useless-escape */
import React, { useEffect, useContext, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Text } from "react-native-elements";
import { useNavigation, CommonActions } from "@react-navigation/native";
import {
  View,
  TouchableOpacity,
  Platform,
  Image,
  ActivityIndicator,
} from "react-native";

import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import tw from "tailwind-rn";
import moment from "moment";
import IMAGES from "static/assets/images";
import { backgroundColors, colors, fonts } from "styles/theme";
import {
  selectDSRequestDetails,
  selectDSRequestDetailsLoading,
  selectDSRequestModifying,
  selectShowApproveCreditForm,
} from "library/sagas/views/home/drawer/delivery-tasks/selector";
import {
  fetchDSRequestDetails,
  setDSRequestActionProcessing,
  setDSRequestModifying,
  setDSRequestDetails,
  setShowApproveCreditForm,
} from "library/sagas/views/home/drawer/delivery-tasks/slice";
import { ListOfActions } from "../config";
import { deliveryPickupTimeWindow } from "components/views/drawer/delivery/config";
import { request } from "library/utils/request";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { ToasterHandler, Accordion, CustomModal } from "components/elements";
import { Form } from "components/elements/forms";
import {
  buildDSRequestDetails,
  getReqObj,
  handlePickupTimeWindow,
} from "./helper";
import {
  convertToTimeZone,
  cutOffTimeInterval,
  getDSRequestRerouteStatus,
} from "library/utils/deliveryService";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import UserProfileStorage from "library/storage/userProfile";
import { processShopSettingsResponse } from "library/utils/shopSettings";
import { Entitlements } from "library/utils/entitlements";
import { getValidationSchema } from "./yup";

//components
import DSRequestBreadcrumb from "./ds-request-breadcrumb";
import TakeAction from "./take-action";
import ApproveCreditForm from "./approve-credit";
import DeliveryInfo from "./task-info";
import FulfillmentInfo from "./fulfillment";
import DSRequestJourney from "./ds-request-journey";

const DeliveryTaskDetails = ({
  setMenuAction,
  onComplete,
  onAction,
  handleOnAction,
  recordId,
  shopCode: selectedShopCode,
  setScreenToScroll,
  setAutoScroll,
  setScrollYCoordinate,
}) => {
  const { messages: locMessages, Localise } = useContext(I18NContext);
  const { permissions } = useContext(AppSettingsContext);
  const { isDesktop, isTablet } = useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const dsRequestLoading = useSelector(selectDSRequestDetailsLoading);
  const dsRequestDetails = useSelector(selectDSRequestDetails);
  const isDSRequestModifying = useSelector(selectDSRequestModifying);
  const showApproveCreditForm = useSelector(selectShowApproveCreditForm);
  const DSRequestDetailsFormRef = useRef();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const operatorRole = UserProfileStorage.getOperatorRole();
  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);
  const [showCancelDSModal, setShowCancelDSModal] = useStateIfMounted(false);

  const {
    state: DSRequestState,
    referenceId,
    notes,
    shipmentId,
    siteId: shopCode,
  } = dsRequestDetails;

  const selectedShopPermissions = get(permissions, shopCode, {});

  let localPermissions = {
    showUserActions: false,
    showCSRActions: false,
  };

  //Permission to show DS Deatils Actions
  localPermissions.showUserActions =
    Entitlements.LMDS_DS_MODIFY in selectedShopPermissions;

  //Permission to show Approve Credit Action
  localPermissions.showCSRActions =
    operatorRole === "FTD_ADMIN" || // Allow FTD Admin to Approve Credit Request
    Entitlements.LMDS_APPROVE_DS_CREDIT in selectedShopPermissions; //Permission to show Customer Service Approve Credit Action

  useEffect(() => {
    return () => {
      dispatch(setDSRequestDetails({}));
      UserProfileStorage.setCreditRequest({});
    };
  }, []);

  useEffect(() => {
    if (dsRequestDetails) {
      const details = buildDSRequestDetails(dsRequestDetails);

      const { firstName: operator = "" } = UserProfileStorage.getUser();
      const creditRequestData = {
        siteId: dsRequestDetails.siteId,
        shopName: details.shopName,
        shopPhone: get(dsRequestDetails, "pickup.contact.phone", ""),
        invoiceNumber: get(dsRequestDetails, "billing.invoiceNumber", ""),
        orderId: get(dsRequestDetails, "referenceId", ""),
        deliveryDate: moment(
          get(details, "deliveryDetails.deliveryDate", "")
        ).format("MM/DD/YYYY"),
        recipientName:
          get(dsRequestDetails, "delivery.contact.firstName", "") +
          " " +
          get(dsRequestDetails, "delivery.contact.lastName", ""),
        deliveryFee: get(dsRequestDetails, "billing.deliveryFee.value", ""),
        proofOfDelivery: get(dsRequestDetails, "imageDetails.delivery", ""),
        totalPrice: get(details, "items[0].totalItemValue", ""),
        operatorName: operator,
        operatorPhone: get(dsRequestDetails, "pickup.contact.phone", ""),
        operatorEmail: get(dsRequestDetails, "pickup.contact.email", ""),
        providerOrderId: get(dsRequestDetails, "vendor.vendorReferenceId", ""),
      };
      UserProfileStorage.setCreditRequest(creditRequestData);
    }
  }, [dsRequestDetails]);

  useEffect(() => {
    if (recordId && selectedShopCode) {
      dispatch(
        fetchDSRequestDetails({
          params: { shopCode: selectedShopCode, shipmentId: recordId },
        })
      );
    }
    dispatch(setDSRequestModifying(false));
    dispatch(setShowApproveCreditForm(false));
  }, [recordId]);

  const ACK_Config = {
    CANCELLED: "CANCELLED_ACK",
    RETURNED: "RETURNED_ACK",
    REJECTED: "REJECTED_ACK",
  };

  const handleError = (
    err,
    defaultMsg = "Something went wrong. Please try again!"
  ) => {
    let errorMessage = defaultMsg;
    if (!["ERROR", "CATCH ALL ERROR", "null"].includes(err) && err.length > 0) {
      errorMessage = err;
    }
    ToasterHandler("uh oh", Localise(locMessages, errorMessage));
    dispatch(setDSRequestActionProcessing(false));
  };

  const CancelReroutingDSModal = () => {
    const modalContent = {
      content: (
        <View style={{ textAlign: "center" }}>
          <Text
            style={{
              ...tw("px-4 pt-4"),
              fontSize: 15,
              color: colors.highlighter,
            }}
          >
            {Localise(
              locMessages,
              "This order has been scheduled with Delivery Service."
            )}
          </Text>
          <Text
            style={{
              ...tw("px-4 pb-4"),
              fontSize: 15,
              color: colors.highlighter,
            }}
          >
            {Localise(
              locMessages,
              "Click Continue to approve Delivery Service cancelation."
            )}
          </Text>
        </View>
      ),
      buttons: [
        { type: "secondary", title: Localise(locMessages, "Nevermind") },
        { type: "primary", title: Localise(locMessages, "Continue") },
      ],
    };

    return (
      <CustomModal
        modalVisible={showCancelDSModal}
        modalContent={modalContent}
        primaryhandler={() => {
          dispatch(setDSRequestActionProcessing(true));
          setShowCancelDSModal(false);
          cancelAction("cancel");
        }}
        secondaryhandler={() => {
          setShowCancelDSModal(false);
        }}
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: "white" },
        ]}
        modalStyle={
          Platform.OS !== "web"
            ? {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#00000070",
                color: "#FFFFFF",
              }
            : {
                width: "35%",
              }
        }
      />
    );
  };

  const cancelAction = (actionType) => {
    const acknowledgeAction = !!["CANCELLED", "RETURNED", "REJECTED"].includes(
      DSRequestState
    );
    request(
      acknowledgeAction
        ? "acknowledge-delivery-request"
        : "cancel-delivery-request",
      {
        shopCode,
        shipmentId,
        ...(acknowledgeAction && {
          body: { state: ACK_Config[DSRequestState] },
        }),
      }
    )
      .then((res) => {
        onAction(actionType, shipmentId, true);
        handleOnAction(actionType, shipmentId, true);
        ToasterHandler(
          "success",
          Localise(locMessages, "The delivery service request is canceled")
        );
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        dispatch(setDSRequestActionProcessing(false));
      });
  };

  const TakeActionHandler = (actionType) => {
    if (actionType === "credit-request") {
      if (!document.getElementById("creditrequest_verint_inline")) return;
      document.getElementById("creditrequest_verint_inline").click();
    } else if (actionType === "approve-credit") {
      setScreenToScroll(2);
      setScrollYCoordinate(30);
      setAutoScroll(false);
      setTimeout(() => {
        setAutoScroll(true);
      }, 0);
      dispatch(setShowApproveCreditForm(true));
    } else {
      dispatch(setDSRequestActionProcessing(true));
      if (actionType === "cancel") {
        // if DS Request reroute is Inprogress - show warning before canceling DS request
        if (getDSRequestRerouteStatus(dsRequestDetails, "rerouting")) {
          setShowCancelDSModal(true);
          dispatch(setDSRequestActionProcessing(false));
          return;
        } else {
          cancelAction(actionType);
        }
      } else if (actionType === "save-order-changes") {
        DSRequestDetailsFormRef.current.handleSubmit();
        setTimeout(() => {
          dispatch(setDSRequestActionProcessing(false));
        }, 2000);
      } else if (actionType === "copy-request") {
        if (
          ![
            "CANCELLED",
            "RETURNED",
            "REJECTED",
            "REJECTED_ACK",
            "CANCELLED_ACK",
            "RETURNED_ACK",
          ].includes(DSRequestState)
        ) {
          initialValues = {
            ...initialValues,
            referenceNumber: undefined,
          };
        }
        dispatch(setDSRequestActionProcessing(false));
        dispatch(setCurrentPage("create-delivery-service-request"));
        navigation.dispatch(
          CommonActions.navigate({
            name: "create-delivery-service-request",
            params: { formValues: initialValues },
          })
        );
      } else if (actionType === "re-deliver") {
        if (
          !["DELIVERED", "RETURNED", "RETURNED_ACK"].includes(DSRequestState)
        ) {
          return;
        }
        dispatch(setDSRequestActionProcessing(false));
        dispatch(setCurrentPage("create-delivery-service-request"));
        navigation.dispatch(
          CommonActions.navigate({
            name: "create-delivery-service-request",
            params: {
              formValues: { ...initialValues },
              redeliver: true,
              previousShipmentId: shipmentId,
            },
          })
        );
      } else if (actionType === "go-to-orders") {
        dispatch(setDSRequestActionProcessing(false));
        const search = {
          title: referenceId,
          value: referenceId,
        };
        const name = "orders";
        navigation.dispatch(
          CommonActions.navigate({
            name,
            params: {
              search: search,
            },
          })
        );
      } else {
        //until all Actions Implementation
        dispatch(setDSRequestActionProcessing(false));
      }
    }
  };

  if (dsRequestLoading) {
    return (
      <View
        style={{
          ...tw("px-3 py-3 flex flex-row items-center justify-between"),
          ...{ backgroundColor: backgroundColors.primary, minHeight: 55 },
          ...(isTablet && { margin: 10 }),
        }}
      >
        <View style={tw("flex flex-row items-center justify-start")}>
          <Text
            style={{
              ...fonts.heading2,
              ...(isSmallScreen && { fontSize: 14 }),
              ...tw("py-1 text-white"),
            }}
          >
            {Localise(locMessages, "Loading Delivery Service Request Details")}
          </Text>
          <ActivityIndicator
            style={{ marginLeft: 10 }}
            color={colors.activityIndicator}
          />
        </View>
        {!isTablet && (
          <View>
            <TouchableOpacity
              onPress={() => onComplete()}
              testID="close"
              accessibilityLabel="close"
            >
              <Image
                style={{ width: 20, height: 20 }}
                resizeMode="cover"
                source={IMAGES["close"]}
              />
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  } else if (isEmpty(dsRequestDetails)) {
    return null;
  }

  let initialValues = buildDSRequestDetails(dsRequestDetails);

  function onSubmit(values, formikBag) {
    setValidationOnChange(false);
    request(
      "modify-delivery-request",
      getReqObj({ values, edit: true, shipmentId, dsRequestDetails })
    )
      .then((res) => {
        onAction("modify-request", shipmentId, true);
        handleOnAction("modify-request", shipmentId, true);
        dispatch(setDSRequestModifying(false));
        ToasterHandler(
          "hooray",
          Localise(
            locMessages,
            "Delivery service request modified successfully."
          )
        );
      })
      .catch((err) => {
        handleError(err, "Modification request failed. \nPlease try again.");
      })
      .finally(() => {
        dispatch(setDSRequestActionProcessing(false));
        formikBag.setSubmitting(false);
      });
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      validationSchema={getValidationSchema(Localise, locMessages)}
      validateOnBlur={validationOnChange}
      validateOnChange={validationOnChange}
      innerRef={DSRequestDetailsFormRef}
      render={(props) => {
        let { pickUpDateTime } = props?.values?.deliveryDetails;
        let pickUpDate = props?.values?.deliveryDetails?.deliveryDate;
        let pickUpTimeWindow = props?.values?.timeWindow;
        let { shopCode, floristTimeZone, deliveryProvider } = props?.values;
        let { setFieldValue, submitCount } = props;

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (shopCode)
            request("get-shop-settings", {
              ids: ["delivery_pickup_time"],
              memberCode: shopCode,
            })
              .then((res) => {
                if (res && res.preferences && res.preferences.length) {
                  let result = processShopSettingsResponse(res);
                  let deliveryPickupTime = result.delivery_pickup_time;
                  if (deliveryPickupTime) {
                    deliveryPickupTimeWindow?.map((timeWindow) => {
                      if (timeWindow.key === deliveryPickupTime) {
                        setFieldValue("timeWindow", timeWindow.value);
                        const injectTimingMapper = timeWindow.value?.map(
                          (time) => {
                            return moment(new Date())
                              .set({ hour: time, minute: 0, second: 0 })
                              .toDate();
                          }
                        );
                        setFieldValue("injectTimings", injectTimingMapper);
                      }
                    });
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
        }, [shopCode, isDSRequestModifying]);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (
            shopCode &&
            deliveryProvider &&
            deliveryProvider?.toLowerCase() !== "doordash"
          ) {
            let dsMinDate = moment(
              convertToTimeZone(moment.utc().format(), floristTimeZone)
            ).add(
              cutOffTimeInterval[deliveryProvider?.toLowerCase()],
              "minutes"
            );

            let currentTime = moment(
              moment(dsMinDate).format("HH:mm:ss"),
              "HH:mm:ss"
            );
            if (
              Platform.OS !== "web" &&
              pickUpTimeWindow.length &&
              isDSRequestModifying &&
              pickUpDateTime &&
              pickUpDate
            ) {
              handlePickupTimeWindow(
                pickUpTimeWindow,
                pickUpDate,
                dsMinDate,
                currentTime,
                setFieldValue,
                pickUpDateTime,
                floristTimeZone
              );
            }
          } else if (
            Platform.OS === "web" &&
            deliveryProvider &&
            deliveryProvider?.toLowerCase() !== "doordash"
          ) {
            if (pickUpTimeWindow.length) {
              const injectTiming = pickUpTimeWindow?.map((timeWindow) => {
                return moment(pickUpDate)
                  .set({ hour: timeWindow, minute: 0, second: 0 })
                  .toDate();
              });
              setFieldValue("injectTimings", injectTiming);
            }
          }
        }, [
          pickUpDateTime,
          pickUpDate,
          pickUpTimeWindow,
          shopCode,
          deliveryProvider,
        ]);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (submitCount > 0) {
            setValidationOnChange(true);
          }
        }, [submitCount]);

        return (
          <View
            style={{
              width: "100%",
              backgroundColor: backgroundColors.sideCar,
            }}
          >
            <View
              style={{
                position: Platform.OS === "web" ? "sticky" : "relative",
                top: 0,
                zIndex: 99,
              }}
            >
              <View
                style={[
                  tw(`flex flex-row items-center justify-between px-3`),
                  {
                    backgroundColor: backgroundColors.primary,
                    minHeight: 55,
                    borderWidth: 1,
                    borderColor: colors.grayScaleLight,
                    borderBottomWidth: 0,
                  },
                ]}
              >
                <Text
                  style={{
                    ...fonts.heading2,
                    ...(isSmallScreen && { fontSize: 14 }),
                    ...tw("py-2 text-white"),
                    width: "95%",
                  }}
                >
                  {`${Localise(
                    locMessages,
                    "Delivery Service Details"
                  )} #${referenceId}`}
                </Text>
                <View>
                  <TouchableOpacity
                    onPress={() => {
                      onComplete();
                      setMenuAction("");
                    }}
                    testID="close"
                  >
                    <Image
                      style={{ width: 20, height: 20 }}
                      resizeMode="cover"
                      source={IMAGES["close"]}
                    />
                  </TouchableOpacity>
                </View>
              </View>
              <View
                style={{
                  borderColor: colors.grayScaleLight,
                  backgroundColor: backgroundColors.sideCar,
                }}
              >
                <DSRequestBreadcrumb dsRequestDetails={dsRequestDetails} />
                <TakeAction
                  dsRequestDetails={dsRequestDetails}
                  DSActions={
                    isDSRequestModifying
                      ? [
                          {
                            key: "save-order-changes",
                            label: "Save Changes",
                          },
                          { key: "nevermind", label: "Nevermind" },
                        ]
                      : ListOfActions(
                          DSRequestState,
                          notes,
                          dsRequestDetails,
                          localPermissions
                        )
                  }
                  actionHandler={TakeActionHandler}
                  setValidationOnChange={setValidationOnChange}
                />
              </View>
            </View>
            {showApproveCreditForm && (
              <ApproveCreditForm
                dsRequestDetails={dsRequestDetails}
                onAction={onAction}
                handleOnAction={handleOnAction}
                handleError={handleError}
              />
            )}
            <View
              style={{
                borderColor: colors.grayScaleLight,
                paddingTop: 10,
                marginBottom: 50,
              }}
            >
              {/**Delivery Info */}
              <View
                style={{
                  marginBottom: 10,
                  borderWidth: 1,
                  borderColor: colors.grayScaleLight,
                }}
              >
                <Accordion
                  title={Localise(locMessages, "Delivery Info")}
                  defaultOpen={true}
                  handleOnPress={() => {}}
                  labelStyle={{
                    marginTop: 0,
                    paddingHorizontal: 12,
                    height: 50,
                    borderBottomWidth: 1,
                    backgroundColor: backgroundColors.navBar,
                    // borderWidth: 1,
                    borderColor: colors.grayScaleLight,
                  }}
                  contentStyle={{
                    backgroundColor: backgroundColors.secondary,
                    paddingVertical: 20,
                    paddingHorizontal: 20,
                  }}
                  titleStyle={{
                    color: colors.primary,
                    paddingLeft: 10,
                  }}
                  iconStyle={{
                    color: colors.primary,
                  }}
                  imageProps={{
                    width: 27,
                    height: 18,
                    source: "delivery-status",
                  }}
                  separatorAlways={true}
                  testID={"deliveryInfo"}
                  accessibilityLabel={"deliveryInfo"}
                >
                  <DeliveryInfo modify={isDSRequestModifying} />
                </Accordion>
              </View>
              <FulfillmentInfo />
              <DSRequestJourney />
            </View>

            <CancelReroutingDSModal />
          </View>
        );
      }}
    />
  );
};

export default DeliveryTaskDetails;
