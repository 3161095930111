import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";
import { selectState as selectScreenState } from "../common/selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectPageStatus = createSelector(
  selectState,
  selectScreenState,
  ({ status }, { status: screenStatus }) =>
    screenStatus === "done" ? status : "progress"
);

export const selectPageError = createSelector(
  selectState,
  ({ error }) => error
);

export const selectPageData = createSelector(selectState, ({ data }) => data);

export const selectActions = createSelector(
  selectState,
  ({ actions }) => actions
);

export const selectSearchText = createSelector(
  selectActions,
  ({ searchText }) => searchText
);
export const selectAllCollectionsFilters = createSelector(
  selectActions,
  ({ filters }) => filters
);

export const selectCollectionLimit = createSelector(
  selectActions,
  ({ collectionLimit }) => collectionLimit
);

export const selectCollectionSortValue = createSelector(
  selectActions,
  ({ collectionSortBy }) => collectionSortBy || "name::asc"
);
