import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileDetailsInput from "./input";
import { InputFields } from "./ui-config";
import I18NContext from "library/contexts/i18N";
import { fetchUserRolesPermissions } from "library/sagas/ongoing/staff-listing/slice";
import { selectState } from "library/sagas/ongoing/staff-listing/selector";
import UserProfileContext from "library/contexts/userProfile";

const ProfileDetails = ({
  isCreateStaff,
  handlePasswordResetEmail,
  isFloristAdmin,
  values,
}) => {
  const dispatch = useDispatch();
  const { userRolesPermissions } = useSelector(selectState);
  const selectedShopCodes = values.shopCodes;

  useEffect(() => {
    dispatch(fetchUserRolesPermissions());
  }, []);

  const { messages, Localise } = React.useContext(I18NContext);
  const { memberCodes = [] } = React.useContext(UserProfileContext);

  return (
    <>
      {InputFields(
        isCreateStaff,
        Localise,
        messages,
        userRolesPermissions,
        memberCodes,
        handlePasswordResetEmail,
        isFloristAdmin,
        selectedShopCodes
      ).map((data, index) => (
        <ProfileDetailsInput data={data} key={index} />
      ))}
    </>
  );
};

export default ProfileDetails;
