import React from "react";
import { useSelector } from "react-redux";

import { getUIConfig } from "./ui-config";
import Listing from "components/containers/listing-new";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

const ListingScreen = () => {
  const { isDesktop, isTablet, isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);

  const UIConfig = getUIConfig({
    Localise,
    messages,
    isDesktop,
    isTablet,
    isMobile,
    useSelector,
  });

  const ListingComponent = Listing({ UIConfig });

  return ListingComponent?.props?.children;
};

export default ListingScreen;
