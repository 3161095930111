import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";

export const initialState = {
  apiResponse: {
    content: "",
    scheduledContent: "",
    component: "",
    fetching: true,
    error: "",
    context: "",
    actualContext: "",
    assetId: "",
    status: "",
    overriddenShops: {},
  },
  collections: {
    data: [],
  },
  storeSync: {
    syncStatus: "",
    appStatus: "",
    appInstallUrl: "",
    checkoutAppInstallUrl: "",
    checkoutAppStatus: "",
    memberSearchText: "",
    memberResults: [],
    memberSearchError: "",
    isCatalogSync: true,
    isStoreSync: true,
    setting: "",
    manualSyncStatus: "",
    manualSyncMemberSearchText: "",
  },
  semRush: {
    memberSearchText: "",
    status: "",
    memberResults: [],
    memberSearchError: "",
  },
  landingPageComponents: [
    "funeralSympathy",
    "funeralSympathyHome",
    "funeralSympathyService",
    "sympathyCollections",
    "wedding",
    "weddingReception",
    "weddingCeremony",
    "weddingCollections",
    "weddingScheduleConsultation",
    "weddingEventsPortfolio",
  ],
  funeralSympathyPage: {
    accordions: [
      {
        component: "funeralSympathy",
        title: "Funeral & Sympathy",
        activeTab: "banner",
        content: "",
        fetching: true,
        error: "",
        actualContext: "",
        assetId: "",
      },
      {
        component: "funeralSympathyHome",
        title: "For the Home",
        activeTab: "banner",
        content: "",
        fetching: true,
        error: "",
        actualContext: "",
        assetId: "",
      },
      {
        component: "funeralSympathyService",
        title: "For the Service",
        activeTab: "banner",
        content: "",
        fetching: true,
        error: "",
        actualContext: "",
        assetId: "",
      },
      {
        component: "sympathyCollections",
        title: "Sympathy Collection Sets",
        activeTab: "banner",
        content: "",
        fetching: true,
        error: "",
        actualContext: "",
        assetId: "",
      },
    ],
    tabs: [
      { key: "banner", title: "Banner" },
      { key: "collection_list", title: "Collection Sets" },
      { key: "featured_collection", title: "Featured Collections" },
      { key: "seo_content", title: "SEO Content" },
    ],
  },
  wedding: {
    accordions: [
      {
        component: "wedding",
        title: "Wedding",
        activeTab: "banner",
        content: "",
        fetching: true,
        error: "",
        actualContext: "",
        assetId: "",
      },
      {
        component: "weddingCeremony",
        title: "For the Ceremony",
        activeTab: "banner",
        content: "",
        fetching: true,
        error: "",
        actualContext: "",
        assetId: "",
      },
      {
        component: "weddingReception",
        title: "For the Reception",
        activeTab: "banner",
        content: "",
        fetching: true,
        error: "",
        actualContext: "",
        assetId: "",
      },
      {
        component: "weddingCollections",
        title: "Wedding Collection Sets",
        activeTab: "banner",
        content: "",
        fetching: true,
        error: "",
        actualContext: "",
        assetId: "",
      },
      {
        component: "weddingScheduleConsultation",
        title: "Schedule Consultation",
        activeTab: "header",
        content: "",
        fetching: true,
        error: "",
        actualContext: "",
        assetId: "",
      },
      {
        component: "weddingEventsPortfolio",
        title: "Wedding Events Portfolio",
        activeTab: "banner",
        content: "",
        fetching: true,
        error: "",
        actualContext: "",
        assetId: "",
      },
    ],
    tabs: [
      { key: "banner", title: "Banner" },
      { key: "collection_list", title: "Collections" },
      { key: "featured_collection", title: "Featured Collections" },
      { key: "seo_content", title: "SEO" },
      { key: "schedule-consultation", title: "Schedule Consultation" },
      { key: "events-portfolio", title: "Events Portfolio" },
      { key: "create_portfolio_event", title: "Create Portfolio Event" },
    ],
  },
  extendedScreen: {
    component: "",
    title: "",
    fieldType: "",
  },
  scheduledContentScreen: {
    components: [
      "mainNavigation",
      "announcementBannerText",
      "homepageBannerMetadata",
    ],
    tabs: [
      { key: "default-content", title: "Default Content" },
      { key: "select-shops", title: "Overridden Shops" },
    ],
    activeTab: "default-content",
    actions: {
      selectAll: false,
      searchText: "",
      selectedMembers: [],
    },
  },
  componentStatus: {},
  translatableComponents: {
    homepageBannerMetadata: ["title", "subTitle", "buttonLabel", "altText"],
    mainNavigation: ["title"],
    footerNavigation: ["title"],
    announcementBannerText: ["title"],
    homepageSEO: [
      "title",
      "entry",
      "homepageMetaTitle",
      "homepageMetaDescription",
    ],
    checkoutCustomizations: [
      "specialInstructionsPlaceholder",
      "deliveryToolTipLabel",
      "deliveryToolTipText",
    ],
    funeralSympathy: [
      "title",
      "subTitle",
      "buttonLabel",
      "buttonText",
      "altText",
      "pageTitle",
      "pageDescription",
      "entry",
      "description",
    ],
    wedding: [
      "title",
      "subTitle",
      "buttonLabel",
      "buttonText",
      "altText",
      "pageTitle",
      "pageDescription",
      "entry",
      "description",
      "question",
    ],
  },
  languages: [
    {
      code: "en",
      title: "English",
      selected: true,
      readOnly: true,
    },
    {
      code: "fr",
      title: "French",
      selected: true,
    },
    {
      code: "es",
      title: "Spanish",
      selected: true,
    },
  ],
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: `view::${sliceName}`,
  initialState,
  reducers: {
    setPageReset: (state) => {
      Object.assign(state, initialState);
    },
    setApiResponse: (state, action) => {
      const { component } = action.payload;

      if (state.landingPageComponents.includes(component)) {
        const section = component.includes("wedding")
          ? "wedding"
          : "funeralSympathyPage";
        state[section].accordions = state[section].accordions.map((item) =>
          item.component === component
            ? { ...item, ...action.payload, fetching: false }
            : item
        );
      } else {
        state.apiResponse = {
          ...state.apiResponse,
          ...action.payload,
          fetching: false,
          status: "done",
        };
      }
    },
    setCollectionResponse: (state, action) => {
      const { content, error } = action.payload;
      if (content) state.collections.data = content;

      if (error) {
        state.collections.error = error;
      }
    },

    setExtendedScreen: (state, action) => {
      state.extendedScreen = action.payload || initialState.extendedScreen;
    },
    setComponentStatus: (state, action) => {
      state.componentStatus = action.payload;
    },
    fetchMiddlewareData: (state, action) => {
      state.apiResponse = {
        ...action.payload,
        content: "",
        error: "",
        fetching: true,
      };
    },
    saveMiddlewareData: (state) => {
      state.apiResponse = {
        ...state.apiResponse,
        error: "",
      };
    },
    deleteMiddlewareData: (state, action) => {
      const {
        params: { type = "" },
      } = action.payload;
      state.apiResponse.status = "progress";
      state.apiResponse.fetching = true;

      if (type === "revert-to-global") {
        state.scheduledContentScreen.actions.selectAll = false;
        state.scheduledContentScreen.actions.selectedMembers = [];
      }
    },
    fetchAllCollections: () => {},
    setScheduledContentScreenTabs: (state, action) => {
      const { type = "", value = "", operation = "" } = action.payload;
      const index = state.scheduledContentScreen.tabs.findIndex(
        (value) => value.key === "scheduled-content"
      );
      if (type === "add") {
        if (index === -1) state.scheduledContentScreen.tabs.splice(1, 0, value);
        if (operation !== "update")
          state.scheduledContentScreen.activeTab = value.key;
      } else if (type === "delete") {
        if (index !== -1) state.scheduledContentScreen.tabs.splice(1, 1);
        state.scheduledContentScreen.activeTab = "default-content";
      } else state.scheduledContentScreen.activeTab = value;
    },
    triggerStoreSync: (state, action) => {
      const {
        params: { polling },
      } = action.payload;
      if (!polling) {
        state.storeSync.appStatus = "";
        state.storeSync.checkoutAppStatus = "";
        state.storeSync.appInstallUrl = "";
        state.storeSync.checkoutAppInstallUrl = "";
        state.storeSync.syncStatus = "inprogress";
      }
    },
    triggerAppInstall: (state) => {
      state.storeSync.appStatus = "inprogress";
      state.storeSync.checkoutAppStatus = "inprogress";
      state.storeSync.syncStatus = "";
    },
    triggerManualSync: (state) => {
      state.storeSync.manualSyncStatus = "inprogress";
    },
    setStoreSync: (state, action) => {
      state.storeSync = { ...state.storeSync, ...action.payload };
    },
    resetStoreSync: (state) => {
      state.storeSync = initialState.storeSync;
    },
    setSemRushStatus: (state, action) => {
      const { status } = action.payload;
      state.semRush.status = status;
    },
    createSEMRushProject: (state) => {
      state.semRush.status = "inprogress";
    },
    setMemberResults: (state, action) => {
      const { section, value } = action.payload;

      state[section].memberResults = value;
    },
    setMemberSearchError: (state, action) => {
      const { section, value } = action.payload;
      state[section].memberSearchError = value;
    },
    setMemberSearchText: (state, action) => {
      const { section, value, type = "" } = action.payload;
      if (type === "manual") {
        state[section].manualSyncMemberSearchText = value;
      } else state[section].memberSearchText = value;
    },
    setOverriddenShopsAction: (state, action) => {
      const { type, value } = action.payload;
      if (type === "search")
        state.scheduledContentScreen.actions.searchText = value;
      else if (type === "selection" && value !== "all") {
        state.scheduledContentScreen.actions.selectAll = false;
        if (!value) {
          state.scheduledContentScreen.actions.selectedMembers = [];
        } else if (
          state.scheduledContentScreen.actions.selectedMembers.includes(value)
        ) {
          const index =
            state.scheduledContentScreen.actions.selectedMembers.findIndex(
              (memberCode) => memberCode === value
            );
          state.scheduledContentScreen.actions.selectedMembers.splice(index, 1);
        } else {
          state.scheduledContentScreen.actions.selectedMembers = [
            ...state.scheduledContentScreen.actions.selectedMembers,
            value,
          ];
        }
      } else if (value === "all") {
        const memberCodes = Object.keys(state.apiResponse.overriddenShops);
        state.scheduledContentScreen.actions.selectedMembers = memberCodes;
        state.scheduledContentScreen.actions.selectAll = true;
      }
    },
    setLanguages: (state, action) => {
      state.languages = action.payload;
    },
    saveLanguages: () => {},
    setTranslationCorrections: () => {},
  },
});

export const {
  setPageReset,
  setCollectionResponse,
  setApiResponse,
  setExtendedScreen,
  fetchMiddlewareData,
  saveMiddlewareData,
  deleteMiddlewareData,
  fetchAllCollections,
  setStoreSync,
  triggerAppInstall,
  createSEMRushProject,
  setSemRushStatus,
  triggerStoreSync,
  setMemberResults,
  setMemberSearchText,
  setMemberSearchError,
  setTranslationCorrections,
  setScheduledContentScreenTabs,
  setOverriddenShopsAction,
  setComponentStatus,
  triggerManualSync,
  setLanguages,
  saveLanguages,
  resetStoreSync,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
