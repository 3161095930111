import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { View, Text, TouchableOpacity, ActivityIndicator } from "react-native";
import { Button } from "react-native-elements";
import { useNavigation } from "@react-navigation/native";
import { selectNoop } from "library/sagas/views/home/drawer/customer-directory/selector";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";

import I18NContext from "library/contexts/i18N";
import { Accordion } from "components/elements";
import DefaultItem from "./item";

import { theme, fonts, colors, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";
import isArray from "lodash/isArray";

const List = ({
  sectionName,
  itemComponent,
  heading,
  headingWithCount,
  labelStyle,
  contentStyle,
  createNew,
  viewMore = {},
  noRecordsMessage = "No Records Found",
  noRecordsHelpLink = "",
  selectors: {
    dataSelector,
    limitSelector,
    accordionSelector,
    selectIsPageLoading,
  },
  actions: { createNewAction, setPageAction } = {},
  onComplete,
}) => {
  const dispatch = useDispatch();
  const items = useSelector(dataSelector);
  const currentLimit = useSelector(limitSelector);
  const accordionStatus = useSelector(accordionSelector);
  const showLoader = useSelector(selectIsPageLoading || selectNoop);

  const { Localise, messages } = React.useContext(I18NContext);
  const navigation = useNavigation();

  const itemsCount = isArray(items) ? items.length : -1;
  const defaultOpen = accordionStatus === "expand";
  const hideListing = accordionStatus === "hide";

  const {
    label: viewMoreText,
    value: increment,
    initial: initialLimit = 0,
  } = viewMore;
  const showViewMore = itemsCount > currentLimit;
  const showFooter = showViewMore || createNew;

  const { name: CustomItem, props: itemProps } = itemComponent;
  const Item = CustomItem || DefaultItem;

  const onCreateNew = () => createNewAction && dispatch(createNewAction());

  const onViewMore = () => {
    const newLimit =
      currentLimit === 0 ? initialLimit : currentLimit + increment;
    dispatch(
      setPageAction({
        type: "limit",
        section: sectionName,
        value: newLimit,
      })
    );
  };

  useEffect(() => {
    if (currentLimit === 0) onViewMore();
  }, [currentLimit]);

  if (hideListing) return null;

  const ListWrapper = accordionStatus ? WithAccordion : NoAccordion;

  if (showLoader) return <ActivityIndicator color={colors.activityIndicator} />;

  return (
    <View
      style={{
        opacity: 1,
        marginBottom: 10,
      }}
    >
      <ListWrapper
        heading={heading}
        headingWithCount={headingWithCount}
        itemsCount={itemsCount}
        defaultOpen={defaultOpen}
        labelStyle={labelStyle}
        contentStyle={contentStyle}
      >
        <View
          style={[
            tw("flex flex-col"),
            { backgroundColor: backgroundColors.secondary },
          ]}
          testID={sectionName}
        >
          {itemsCount === 0 ? (
            <View style={tw("flex flex-1 items-center justify-center py-2")}>
              <Text style={[fonts.default, { fontSize: 13 }]}>
                {Localise(messages, noRecordsMessage)}
              </Text>
              {!!noRecordsHelpLink && (
                <View
                  style={[
                    tw("flex flex-1 flex-row items-center justify-center py-2"),
                    fonts.default,
                    { fontSize: 13 },
                  ]}
                >
                  <Text>{Localise(messages, noRecordsHelpLink)}</Text>
                  <TouchableOpacity
                    style={{ marginLeft: 5, textDecorationLine: "underline" }}
                    onPress={() => {
                      dispatch(setCurrentPage("help"));
                      navigation.navigate("help");
                    }}
                  >
                    <Text>{Localise(messages, "here")}</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          ) : itemsCount > 0 ? (
            items
              .slice(0, currentLimit)
              .map((item, index) => (
                <Item
                  key={index}
                  data={item}
                  index={index}
                  {...itemProps}
                  onComplete={onComplete}
                />
              ))
          ) : (
            <Item data={items} {...itemProps} />
          )}
          {showFooter ? (
            <View
              style={tw(
                `flex flex-row-reverse ${
                  showViewMore ? `justify-between` : `justify-end`
                } items-center py-4`
              )}
            >
              {showViewMore ? (
                <FooterButton title={viewMoreText} action={onViewMore} />
              ) : null}
              {createNew ? (
                <FooterButton title={createNew} action={onCreateNew} />
              ) : null}
            </View>
          ) : null}
        </View>
      </ListWrapper>
    </View>
  );
};

const NoAccordion = ({ children }) => children;

const WithAccordion = ({
  heading,
  headingWithCount,
  itemsCount,
  defaultOpen,
  labelStyle,
  contentStyle,
  children,
}) => (
  <Accordion
    title={`${headingWithCount ? `${heading} (${itemsCount})` : heading}`}
    defaultOpen={defaultOpen}
    handleOnPress={() => {}}
    labelStyle={{
      marginTop: 0,
      height: 50,
      backgroundColor: backgroundColors.navBar,
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderBottomWidth: 1,
      borderColor: colors.grayScaleLight,
      paddingHorizontal: 12,
      ...labelStyle,
    }}
    contentStyle={{
      ...contentStyle,
    }}
    closeStyle={{
      borderBottomWidth: 1,
      borderColor: colors.grayScaleLight,
    }}
    titleStyle={{
      color: colors.primary,
      paddingLeft: 10,
    }}
    iconStyle={{
      color: colors.primary,
    }}
  >
    {children}
  </Accordion>
);

const FooterButton = ({ title, action }) => {
  return (
    <Button
      titleStyle={theme.Button.secondaryTitleStyle}
      buttonStyle={{
        ...theme.Button.secondaryButtonStyle,
        paddingVertical: 5,
        paddingHorizontal: 5,
      }}
      containerStyle={{
        marginVertical: 0,
        marginHorizontal: 0,
        justifyContent: "center",
      }}
      onPress={action}
      title={title}
      testID={title}
      accessibilityLabel={title}
    />
  );
};

export default List;
