import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";
import { SLICE_NAME as parentSlice } from "../constants";
import toUpper from "lodash/toUpper";

export const initialState = {
  activeTab: "",
  sideCar: "",
  sideCarTitle: "",
  enableSharedCatalogs: true,
  tabs: [
    { key: "manage-groups", title: "Manage Groups" },
    { key: "bulk-accounts", title: "Bulk Accounts Creation" },
    { key: "invalidate-sessions", title: "Invalidate Sessions" },
    { key: "shared-catalogs", title: "Shared Catalogs" },
  ],
};

const slice = createSlice({
  name: `view::${parentSlice}::${sliceName}`,
  initialState,
  reducers: {
    setInit: (state, action) => {
      state.status = "progress";

      const { userRole } = action.payload;
      const CustomerServiceRole = "FTD_CSR";

      //Show only "Manage Groups" Tab for Customer Service Role
      if (toUpper(userRole) === CustomerServiceRole) {
        state.tabs = state.tabs.filter((tab) => tab.key === "manage-groups");
      }
    },
    setReset: (state) => {
      Object.assign(state, initialState);
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
      state.sideCar = "";
    },
    setSideCar: (state, action) => {
      state.sideCar = action.payload;
    },
    setSideCarTitle: (state, action) => {
      state.sideCarTitle = action.payload;
    },
  },
});

export const { setInit, setReset, setActiveTab, setSideCar, setSideCarTitle } =
  slice.actions;

export default {
  [sliceName]: slice.reducer,
};
