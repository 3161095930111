import React from "react";
import { View, ScrollView } from "react-native";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";
import { fonts } from "styles/theme";
import I18NContext from "library/contexts/i18N";

export default function Loader({ message }) {
  const { messages, Localise } = React.useContext(I18NContext);
  return (
    <ScrollView>
      <View style={tw("flex justify-center h-64 items-center")}>
        <Text style={fonts.heading3}>
          {message || Localise(messages, "We are processing your request")}
        </Text>
      </View>
    </ScrollView>
  );
}
