import { put, call, select, all } from "redux-saga/effects";

import { request } from "library/utils/request";
import { isMHQNonCoreMember } from "library/utils/entitlements";
import { formatPrice } from "library/utils/formatter";
import UserProfileStorage from "library/storage/userProfile";

import get from "lodash/get";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import toLower from "lodash/toLower";
import startCase from "lodash/startCase";
import moment from "moment";

import {
  setApiResponse,
  setApiError,
  fetchCitiesZipcodes,
  saveCitiesZipcodes,
  savecitiesplaceIDs,
  clearCitiesZipcodes,
  setDeliveryZoneMapReload,
} from "../slice";
import { setApiResponse as setShopPreferences } from "../../common/slice";
import { selectShopCode, selectShopPreferences } from "../../common/selector";
import { selectFeesCoverage } from "../selector";

export function* handleFetchGlobalFees() {
  const serviceRequest = (params) => request("get-site-fees", params);
  const shopCode = yield select(selectShopCode);
  const shopPreferences = yield select(selectShopPreferences);
  const taxDeliveryFee =
    shopPreferences?.tax_delivery_fee?.toLowerCase() ?? "false";
  const retailDeliveryFee =
    shopPreferences?.retail_delivery_fee?.toLowerCase() ?? "0.00";
  const taxRetailDeliveryFee =
    shopPreferences?.tax_retail_delivery_fee?.toLowerCase() ?? "false";
  const relayFee = shopPreferences?.relay_fee?.toLowerCase() ?? "0.00";
  const rush_submitted_by = shopPreferences?.rush_submitted_by ?? "";
  const rush_delivered_by = shopPreferences?.rush_delivered_by ?? "";
  const localOrderTaxSettings =
    shopPreferences?.local_order_tax_settings ?? "point_of_origin";
  const wireOrderTaxSettings =
    shopPreferences?.wired_order_tax_settings ?? "point_of_origin";
  const rushFeeEnabled =
    shopPreferences?.rush_fee_enabled?.toLowerCase() ?? "false";

  try {
    const response = yield call(serviceRequest, { shopCode });
    const content = processGlobalFeesResponse(
      {
        ...response?.siteMappingFees,
        taxDeliveryFee,
        retailDeliveryFee,
        taxRetailDeliveryFee,
        relayFee,
      } || {}
    );
    const rushDeliveryFee = response?.siteMappingFees?.rushDeliveryFee ?? "0";
    const {
      isEnabled: enableExpeditedFees = false,
      shipFee: expeditedFees = "0.00",
      cutOffTime: dailyCutoffTime = "00:00:00",
    } = response?.siteMappingFees?.shipMethodInfos?.find(
      (e) => e?.shipMethod === "EXPEDITED"
    ) || {};
    const {
      isEnabled: enableGroundFees = false,
      shipFee: groundFees = "0.00",
    } =
      response?.siteMappingFees?.shipMethodInfos?.find(
        (e) => e?.shipMethod === "GROUND"
      ) || {};

    const isAdminUser = UserProfileStorage.getRole() === "ADMIN";
    const isNonCoreMember = isMHQNonCoreMember(shopCode);
    const showTaxDeliveryFee = isNonCoreMember && isAdminUser;
    const localDeliveryFeeTax =
      shopPreferences?.tax_on_local_delivery_fee ||
      shopPreferences?.tax_delivery_fee;
    const outsideLocalDeliveryFeeTax =
      shopPreferences?.tax_on_outside_local_delivery_fee ||
      shopPreferences?.tax_delivery_fee;
    const serviceFeeTax =
      shopPreferences?.tax_on_service_fee || shopPreferences?.tax_delivery_fee;
    const relayFeeTax =
      shopPreferences?.tax_on_relay_fee || shopPreferences?.tax_delivery_fee;

    yield put(
      setApiResponse({
        path: "feesCoverage.siteFees",
        content: {
          ...content,
          enableRushFee: rushFeeEnabled === "true",
          rushDeliveryFee,
          rush_submitted_by,
          rush_delivered_by,
          enableExpeditedFees,
          enableGroundFees,
          expeditedFees,
          groundFees,
          dailyCutoffTime: moment(dailyCutoffTime, "HH:mm:ss").format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          ...(showTaxDeliveryFee && {
            localOrderTaxSettings,
            wireOrderTaxSettings,
          }),
          localDeliveryFeeTax: localDeliveryFeeTax === "true",
          outsideLocalDeliveryFeeTax: outsideLocalDeliveryFeeTax === "true",
          serviceFeeTax: serviceFeeTax === "true",
          relayFeeTax: relayFeeTax === "true",
        },
      })
    );
  } catch (error) {
    yield put(
      setApiError({
        path: "feesCoverage.siteFees",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleSaveGlobalFees(action = {}) {
  const { resolve, reject, params } = get(action, "payload", {});
  const saveSiteFees = (payload) => request("save-site-fees", payload);
  const saveShopSettings = (payload) => request("save-shop-settings", payload);

  const shopCode = yield select(selectShopCode);
  const shopSettings = yield select(selectShopPreferences);
  const {
    taxDeliveryFee,
    relayFee: relay_fee,
    showTaxDeliveryFee,
    showRelayFee,
    enableRushFee = false,
    rush_delivered_by = "",
    rush_submitted_by = "",
    localOrderTaxSettings,
    wireOrderTaxSettings,
    enableExpeditedFees = false,
    enableGroundFees = false,
    expeditedFees = "",
    groundFees = "",
    dailyCutoffTime = "",
    localDeliveryFeeTax,
    outsideLocalDeliveryFeeTax,
    serviceFeeTax,
    relayFeeTax,
    ...rest
  } = params;

  const tax_delivery_fee = taxDeliveryFee ?? false ? "true" : "false";
  const rush_fee_enabled = enableRushFee ?? false ? "true" : "false";
  const tax_on_local_delivery_fee =
    localDeliveryFeeTax ?? false ? "true" : "false";
  const tax_on_outside_local_delivery_fee =
    outsideLocalDeliveryFeeTax ?? false ? "true" : "false";
  const tax_on_service_fee = serviceFeeTax ?? false ? "true" : "false";
  const tax_on_relay_fee = relayFeeTax ?? false ? "true" : "false";

  try {
    if (
      showTaxDeliveryFee ||
      showRelayFee ||
      localOrderTaxSettings ||
      wireOrderTaxSettings ||
      rush_fee_enabled
    ) {
      let preferences = [];

      preferences.push({
        id: "rush_fee_enabled",
        values: [rush_fee_enabled],
      });

      if (showTaxDeliveryFee) {
        preferences.push({
          id: "tax_on_local_delivery_fee",
          values: [tax_on_local_delivery_fee],
        });
        preferences.push({
          id: "tax_on_outside_local_delivery_fee",
          values: [tax_on_outside_local_delivery_fee],
        });
        preferences.push({
          id: "tax_on_service_fee",
          values: [tax_on_service_fee],
        });
        preferences.push({
          id: "tax_on_relay_fee",
          values: [tax_on_relay_fee],
        });
      }
      if (showRelayFee) {
        preferences.push({
          id: "relay_fee",
          values: [relay_fee],
        });
      }

      if (enableRushFee) {
        preferences.push({
          id: "rush_submitted_by",
          values: [`${moment(rush_submitted_by).format("HH:mm:ss")}`],
        });

        preferences.push({
          id: "rush_delivered_by",
          values: [`${moment(rush_delivered_by).format("HH:mm:ss")}`],
        });
      }

      if (localOrderTaxSettings) {
        preferences.push({
          id: "local_order_tax_settings",
          values: [localOrderTaxSettings],
        });
      }

      if (wireOrderTaxSettings) {
        preferences.push({
          id: "wired_order_tax_settings",
          values: [wireOrderTaxSettings],
        });
      }

      yield call(saveShopSettings, {
        memberCode: shopCode,
        preferences,
      });

      yield put(
        setShopPreferences({
          path: "shopPreferences",
          content: {
            ...shopSettings,
            tax_delivery_fee,
            tax_on_local_delivery_fee,
            tax_on_outside_local_delivery_fee,
            tax_on_service_fee,
            tax_on_relay_fee,
            relay_fee,
            rush_delivered_by,
            rush_submitted_by,
            local_order_tax_settings: localOrderTaxSettings,
            wired_order_tax_settings: wireOrderTaxSettings,
            rush_fee_enabled,
          },
        })
      );

      const shopPreferences = UserProfileStorage.getShopPreferences(shopCode);
      UserProfileStorage.setShopPreferences(shopCode, {
        ...shopPreferences,
        tax_delivery_fee,
        tax_on_local_delivery_fee,
        tax_on_outside_local_delivery_fee,
        tax_on_service_fee,
        tax_on_relay_fee,
        relay_fee,
        local_order_tax_settings: localOrderTaxSettings,
        wired_order_tax_settings: wireOrderTaxSettings,
        rush_fee_enabled,
        rush_delivered_by,
        rush_submitted_by,
      });
    }

    const requestPayload = {
      siteMappingFees: {
        ...rest,
        rushDeliveryFee: enableRushFee
          ? formatPrice(rest.rushDeliveryFee)
          : "0",
        shipMethodInfos: [
          {
            shipMethod: "EXPEDITED",
            cutOffTime: moment(dailyCutoffTime).format("HH:mm:ss"),
            shipFee: expeditedFees,
            isEnabled: enableExpeditedFees,
          },
          {
            shipMethod: "GROUND",
            cutOffTime: moment(dailyCutoffTime).format("HH:mm:ss"),
            shipFee: groundFees,
            isEnabled: enableGroundFees,
          },
        ],
      },
    };

    yield call(saveSiteFees, {
      shopCode,
      requestPayload,
    });
    yield call(handleFetchGlobalFees);

    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        path: "feesCoverage.siteFees",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleFetchCitiesFees() {
  const serviceRequest = (params) => request("get-cities-fees", params);
  const shopCode = yield select(selectShopCode);
  try {
    const response = yield call(serviceRequest, { shopCode });
    const content = processCitiesFeesResponse(response);

    if (content && content.length > 0) {
      yield put(clearCitiesZipcodes());
      yield all(
        content.map((_, i) =>
          put(
            fetchCitiesZipcodes({
              city: content[i].city,
              countryId: content[i].countryId,
              state: content[i].state,
            })
          )
        )
      );
      yield put(setDeliveryZoneMapReload(true));
    }

    yield put(
      setApiResponse({
        path: "feesCoverage.citiesFees",
        content,
      })
    );
  } catch (error) {
    yield put(
      setApiError({
        path: "feesCoverage.citiesFees",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleFetchCitiesZipcodes(action = {}) {
  const { payload: { city, countryId, state } = {} } = action;
  const { siteFees: { content: { localFee = "" } = {} } = {} } = yield select(
    selectFeesCoverage
  );
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (params) => request("get-cityzipcodes-fees", params);

  try {
    const response = yield call(serviceRequest, {
      shopCode,
      city,
      countryId,
      state,
    });
    const content = processCityZipcodesFeesResponse(response, localFee);

    let placeIDs = [];

    try {
      for (let i = 0; i < content.length; i++) {
        if (content[i].zipCode && content[i].status === "Y") {
          const googleMapsServiceRequest = (params) =>
            request("google-place-id", params);

          const googleMapsServiceResponse = yield call(
            googleMapsServiceRequest,

            {
              zip: content[i].zipCode,
            }
          );
          if (googleMapsServiceResponse.results[0]) {
            placeIDs.push(googleMapsServiceResponse.results[0].place_id);
          }
        }
      }
    } catch (error) {
      console.log("error >>", error);
    }

    yield put(savecitiesplaceIDs(placeIDs));
    yield put(saveCitiesZipcodes({ content }));
  } catch (error) {
    yield put(
      setApiError({
        path: "feesCoverage.citiesZipcodes",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleSaveCitiesFees(action = {}) {
  const {
    resolve,
    reject,
    params: { values = [], initialValues = [], skipGetCityFeeCall } = {},
  } = get(action, "payload", {});
  const shopCode = yield select(selectShopCode);

  const requestPayload = preparePatchPayload(values, initialValues);
  const serviceRequest = (payload) => request("save-cities-fees", payload);

  try {
    yield call(serviceRequest, { shopCode, requestPayload: requestPayload });

    if (!skipGetCityFeeCall) yield call(handleFetchCitiesFees);
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        path: "feesCoverage.citiesFees",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleFetchCityZipcodesFees(action = {}) {
  const { payload: { params: { city, countryId, state } = {} } = {} } = action;
  const { siteFees: { content: { localFee = "" } = {} } = {} } = yield select(
    selectFeesCoverage
  );
  const shopCode = yield select(selectShopCode);

  const serviceRequest = (params) => request("get-cityzipcodes-fees", params);

  try {
    const response = yield call(serviceRequest, {
      shopCode,
      city,
      countryId,
      state,
    });
    const content = processCityZipcodesFeesResponse(response, localFee);

    yield put(
      setApiResponse({
        path: "feesCoverage.cityZipcodesFees",
        content,
      })
    );
  } catch (error) {
    yield put(
      setApiError({
        path: "feesCoverage.cityZipcodesFees",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleClearZipCodeFeesData() {
  yield put(
    setApiResponse({
      path: "feesCoverage.cityZipcodesFees",
      content: [],
    })
  );
}

export function* handleSaveCityZipcodesFees(action = {}) {
  const {
    resolve,
    reject,
    params: { values = [], initialValues = [] } = {},
  } = get(action, "payload", {});
  const requestPayload = preparePatchPayload(values, initialValues, true);
  const { city, state, countryId } = get(requestPayload, "0", {});
  const shopCode = yield select(selectShopCode);

  const serviceRequest = (payload) =>
    request("save-cityzipcodes-fees", payload);

  try {
    yield call(serviceRequest, {
      shopCode,
      requestPayload: requestPayload,
      city,
      state,
      countryId,
    });
    yield call(handleFetchCitiesFees);
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        path: "feesCoverage.cityZipcodesFees",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleFetchCities(action = {}) {
  const { payload: { params: { countryId, state } = {} } = {} } = action;

  const shopCode = yield select(selectShopCode);
  const serviceRequest = (params) => request("get-cities", params);
  try {
    const response = yield call(serviceRequest, {
      shopCode,
      countryId,
      state,
    });
    const content = processCitiesResponse(response?.cities);
    yield put(
      setApiResponse({
        path: "feesCoverage.cities",
        content,
      })
    );
  } catch (error) {
    yield put(
      setApiError({
        path: "feesCoverage.cities",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export const isValidFeeValue = (fee) => {
  const isValid = (fee || fee === 0 || fee === "0") && !isNaN(fee);
  return isValid;
};

export const isMaxDecimalsExceeded = (fee) => {
  const maxDecimalsReached = fee.split(".")[1]?.length > 2;
  return maxDecimalsReached;
};

export const roundTheFees = (fee, prevText, isOnChange) => {
  const isValidFee = isValidFeeValue(fee);
  const roundedFee = isValidFee
    ? isOnChange
      ? isMaxDecimalsExceeded(fee)
        ? prevText
        : fee
      : fee % 1 !== 0
      ? (+fee).toFixed(2)
      : parseInt(fee).toString()
    : fee.replace(/[^0-9.]/g, "");
  return roundedFee.toString();
};

const processCitiesFeesResponse = (response) => {
  return response
    .filter((cityData) => cityData.city)
    .map((data) => ({
      ...data,
      fee: roundTheFees(data.fee),
    }));
};

const processCityZipcodesFeesResponse = (response = [], localFee) => {
  let cityFeeData = {};
  const cityFeeDataIndex = response?.findIndex((row) => !row.zipCode);

  if (cityFeeDataIndex < 0) {
    cityFeeData = {
      city: get(response, "0.city", ""),
      countryId: get(response, "0.countryId", ""),
      state: get(response, "0.state", ""),
      fee: localFee,
      status: "N",
      cityFeeAddedManually: true,
    };
  } else {
    cityFeeData = response.splice(cityFeeDataIndex, 1)[0];
  }
  const sortedZipcodes = response.sort((a, b) =>
    b.status?.localeCompare(a.status)
  );
  sortedZipcodes.unshift(cityFeeData);
  return sortedZipcodes.map((data) => ({
    ...data,
    status: data.status || "N",
    fee: roundTheFees(data.fee || localFee),
  }));
};

const processGlobalFeesResponse = ({
  localFee = "",
  outsideLocalFee = "",
  serviceFee = "",
  isActive = "false",
  taxDeliveryFee = "false",
  retailDeliveryFee = "",
  taxRetailDeliveryFee = "false",
  relayFee = "",
} = {}) => {
  return {
    localFee: localFee ? formatPrice(roundTheFees(localFee)) : "0.00",
    outsideLocalFee: outsideLocalFee
      ? formatPrice(roundTheFees(outsideLocalFee))
      : "0.00",
    isActive,
    serviceFee:
      isActive === "true" ? formatPrice(roundTheFees(serviceFee)) : "0.00",
    taxDeliveryFee: taxDeliveryFee === "true",
    retailDeliveryFee: retailDeliveryFee
      ? formatPrice(roundTheFees(retailDeliveryFee))
      : "0.00",
    taxRetailDeliveryFee: taxRetailDeliveryFee === "true",
    relayFee: relayFee ? formatPrice(roundTheFees(relayFee)) : "0.00",
  };
};

const preparePatchPayload = (updatedData, initialData, isZipcodeFees) => {
  const isZipCodescityFeeAddedManually =
    isZipcodeFees && get(initialData, "0.cityFeeAddedManually", false);

  if (isZipCodescityFeeAddedManually) {
    const [updated, intial] = [[...updatedData], [...initialData]];
    intial.shift();
    const cityObj = { ...updated.shift() };
    delete cityObj["cityFeeAddedManually"];
    updated.unshift(cityObj);
    return differenceWith(updated, intial, isEqual);
  } else {
    return differenceWith(updatedData, initialData, isEqual);
  }
};

const processCitiesResponse = (response) => {
  return response.map((city) => ({
    label: startCase(toLower(city)),
    value: city,
  }));
};
