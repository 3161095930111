import { useEffect } from 'react';
import { compareVersions } from "library/utils/formatter";
import UtilStorage from "library/storage/utilStorage";

const ReloadTabs = () => {
    useEffect(() => {
        const channel = new BroadcastChannel('reload-channel');

        channel.onmessage = (event) => {

                try {
                    const data = JSON.parse(event.data);
                    if (data.text === 'NewReleaseVersion') {
                        const currentVersion = UtilStorage.getReleaseVersion("CurrentVersion");
                        const latestVersion = data.version;
                        // If the current version is outdated or not set, reload the tab
                        if (compareVersions(latestVersion, currentVersion) !== 0) {
                            if (document.visibilityState !== 'visible') {
                                window.location.reload();
                            }
                            // Update to the latest version after reloading
                            UtilStorage.setReleaseVersion(latestVersion);
                        }
                    }
                } catch (e) {
                    console.error('Error parsing message data:', e);
                }
        };

        return () => {
            channel.close(); // Clean up on unmount
        };
    }, []);

    return null; // This component doesn't render anything
};


export default ReloadTabs;
