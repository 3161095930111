import { configureStore } from "@reduxjs/toolkit";
import { Platform } from "react-native";
import createSagaMiddleware from "redux-saga";
import rootReducer from "library/sagas/root-reducer";
import rootSaga from "library/sagas/root-saga";

let releaseChannel = "prod";
if (Platform.OS === "web") {
  const { env = "qa" } = window.__env;
  releaseChannel = env;
}

const isProd =
  process.env.NODE_ENV === "production" && releaseChannel === "prod";
const isDevToolsEnabled = !isProd && Platform.OS === "web" && process.browser;

const reduxDevToolsOptions = {
  trace: true,
  traceLimit: 30,
};

const sagaMiddleware = createSagaMiddleware();

// Redux: Store
const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
  devTools: isDevToolsEnabled ? reduxDevToolsOptions : false,
});

sagaMiddleware.run(rootSaga);

export default store;
