import React, { useEffect } from "react";
import { View } from "react-native";
import { Spinner } from "components/elements";
import { saveAuthToken } from "./helper";

const Authorize = () => {
  useEffect(() => {
    saveAuthToken();
    window.location.reload();
  }, []);

  return (
    <View style={{ minHeight: 150 }}>
      <Spinner size="large" />
    </View>
  );
};

export default Authorize;
