import React, { useEffect } from "react";
import { View, Platform } from "react-native";
import { Text } from "react-native-elements";
import { fonts } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import { fetchMarketingEmails } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import {
  selectShopPreferences,
  selectFromEmailVerificationStatus,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { useDispatch, useSelector } from "react-redux";

import {
  setActiveTab,
  setActiveAccordion,
} from "library/sagas/views/home/drawer/shop-settings/common/slice";
import tw from "tailwind-rn";
import { isValidJSON } from "library/utils/formatter";
import Environment from "library/utils/environment";

const fromEmailVerificationText =
  "Please note that there will be a 24-hour turnaround time for setup completion. Thank you for your patience! If you still see this message after 24 hrs, please contact support.";

const MarketingEmailStatusInfo = () => {
  const { messages, Localise } = React.useContext(I18NContext);
  const dispatch = useDispatch();
  const shopSettings = useSelector(selectShopPreferences);

  const TouchableComponent =
    Platform.OS === "web"
      ? require("react-native").TouchableOpacity
      : require("react-native-gesture-handler").TouchableOpacity;

  let marketingEmail = "";
  let accountId = "";
  if (isValidJSON(shopSettings["florist_contact_information_settings"])) {
    const contactSettings = JSON.parse(
      shopSettings["florist_contact_information_settings"] || "{}"
    );
    marketingEmail = contactSettings?.marketingEmail;
    accountId = contactSettings?.ctctAccountId;
  }

  const navigateToCustomerInfo = () => {
    dispatch(setActiveTab("shop-details"));
    dispatch(setActiveAccordion("shop-information"));
  };

  const NoEmailText = () => {
    return (
      <View
        style={[
          { marginHorizontal: 30, marginTop: 10 },
          tw("flex flex-row items-center"),
        ]}
      >
        <Text>
          {Localise(
            messages,
            "Marketing Email is not set for your shop. Click "
          )}
          <TouchableComponent
            onPress={navigateToCustomerInfo}
            testID={"customer_info"}
            accessibilityLabel={"customer_info"}
          >
            <Text style={[fonts.link1]}>{Localise(messages, "here")}</Text>
          </TouchableComponent>
          <Text>{Localise(messages, " to set it.")}</Text>
        </Text>
      </View>
    );
  };

  const EditEmailText = () => {
    return (
      <View
        style={[
          tw("flex flex-row items-center"),
          { marginHorizontal: 30, marginTop: 10 },
        ]}
      >
        <Text>
          {Localise(messages, "Mercury HQ is going to use ")}
          {marketingEmail}
          <Text style={{ marginRight: 5 }}>
            {Localise(messages, " as your marketing email to send reminders.")}
          </Text>
          <TouchableComponent
            onPress={navigateToCustomerInfo}
            testID={"edit"}
            accessibilityLabel={"edit"}
          >
            <Text style={fonts.link1}>{Localise(messages, "Edit")}</Text>
          </TouchableComponent>
        </Text>
      </View>
    );
  };

  const FromEmailVericationInfo = () => {
    const fromEmailVerificationStatus = useSelector(
      selectFromEmailVerificationStatus
    );
    if (
      fromEmailVerificationStatus &&
      fromEmailVerificationStatus !== "UNCONFIRMED"
    )
      return null;

    return (
      <View
        style={[
          tw("flex flex-row items-center"),
          { marginHorizontal: 30, marginTop: 10 },
        ]}
      >
        <Text>{Localise(messages, fromEmailVerificationText)}</Text>
      </View>
    );
  };

  useEffect(() => {
    if (!accountId) return;
    const fromEmail = Environment.get(
      "MARKETING_FROM_EMAIL",
      "florist@ema.floristmail.com"
    );
    dispatch(
      fetchMarketingEmails({
        accountId,
        marketingEmail: fromEmail,
        isFromEmail: true,
      })
    );
  }, [accountId, dispatch]);

  useEffect(() => {
    if (accountId && marketingEmail) {
      dispatch(fetchMarketingEmails({ accountId, marketingEmail }));
    }
  }, [dispatch, accountId, marketingEmail]);

  return (
    <View>
      {marketingEmail ? (
        <>
          <EditEmailText />
          <FromEmailVericationInfo />
        </>
      ) : (
        <NoEmailText />
      )}
    </View>
  );
};

export default MarketingEmailStatusInfo;
