import React, { useEffect, useContext } from "react";
import { View, Text, ScrollView, Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-native-elements";
import { useFormikContext } from "formik";

import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import I18NContext from "library/contexts/i18N";
import useStateIfMounted from "library/utils/useStateIfMounted";
import Entitlements from "library/utils/entitlements";
import UserProfileStorage from "library/storage/userProfile";
import {
  selectDeliveryDashboardData,
  selectDashboardCurrentTabKey,
  selectZonesData,
  selectNonRoutedOrders,
} from "library/sagas/views/home/drawer/delivery/selector";
import { fetchNonRoutedOrders } from "library/sagas/views/home/drawer/delivery/slice";

import { theme, colors, backgroundColors } from "styles/theme";
import get from "lodash/get";
import startCase from "lodash/startCase";
import moment from "moment";
import tw from "tailwind-rn";

import DeliveryDashboardListItem from "./list-item";
import { initViewMoreCounts } from "./config";

import { macAddressBannerExists } from "library/utils/formatter";
import { Spinner, CustomModal } from "components/elements";
import { extractTimeFromDI } from "components/views/drawer/create-order/helper";

const DeliveryDashboardTab = ({ loading, filteredShopCodes }) => {
  const dispatch = useDispatch();
  const { isDesktop } = useContext(DeviceContext);
  const { proxyUser, suspendedMembers = [] } = useContext(UserProfileContext);
  const { values } = useFormikContext();
  const { shopCode, startDate, endDate } = values;
  const proxyHeaderExists = proxyUser ? true : false;
  const suspendedHeaderExists = suspendedMembers.length > 0;
  const [accessModalVisible, setAccessModalVisible] = useStateIfMounted(false);
  const [viewMoreCounts, setViewMoreCounts] =
    useStateIfMounted(initViewMoreCounts);
  const deliveryDashboardData = useSelector(selectDeliveryDashboardData);
  const currentDashboardKey = useSelector(selectDashboardCurrentTabKey);
  const nonRoutedOrders = useSelector(selectNonRoutedOrders);
  const zonesData = useSelector(selectZonesData);

  const { permissions } = useContext(AppSettingsContext);
  const { messages, Localise } = useContext(I18NContext);

  const selectedShopPermissions = get(
    permissions,
    shopCode || UserProfileStorage.getSelectedShopCode(),
    {}
  );
  const considerLocalOrders = selectedShopPermissions[
    Entitlements.DELIVERY
  ]?.includes(Entitlements.DELIVERY_PAGE.ROUTE_CONSIDER_LOCAL_ORDERS);

  const selectedTab = currentDashboardKey?.split("-")[1];

  const dashboardRoutesData = Object.assign(
    {},
    ...deliveryDashboardData["routes"]
  );

  const processsedZonesData = () =>
    Object.keys(zonesData)
      ?.sort()
      ?.map((eachShop) => zonesData[eachShop])
      ?.flat()
      ?.map((eachZone) => {
        const {
          autoRouteId: zoneId,
          name: displayName,
          siteId: shopCode,
        } = eachZone;
        const fmt = "YYYY-MM-DDTHH:mm:ss[Z]";
        const filteredOrders = nonRoutedOrders?.filter(
          (order) =>
            [
              "ACKNOWLEDGED",
              "ACKNOWLEDGE_PRINT",
              "PRINTED",
              "DESIGN",
              "DESIGNED",
              "OUT_FOR_DELIVERY",
            ].includes(order.status) && // to get undelivered orders
            order.deliveryZoneId &&
            order.deliveryZoneId === zoneId && // to get orders within zone
            (order.isRushOrder || !!order.orderDeliveryTime) && // to get timed orders
            order.routeId && // get only routed Orders
            order.deliveryInstructions &&
            order.deliveryInstructions
              ?.toLowerCase()
              .includes("Deliver by ".toLowerCase())
        );
        const deliverByTimeMap = {};
        const routeIdInZoneList = [];
        filteredOrders?.map((order) => {
          const {
            deliveryInstructions = "",
            deliveryDate = "",
            orderItemId,
            routeId = "",
            orderDeliveryTime = "",
            isRushOrder = false,
          } = order;
          if (routeId) {
            routeIdInZoneList.push(routeId);
          }

          const deliverByTime = moment(
            orderDeliveryTime ||
              (isRushOrder && extractTimeFromDI(deliveryInstructions)), //For old rush orders before timed delivery feature
            "h:mm A"
          ).format("hh:mmA");

          // const rushTime = moment(
          //   deliveryInstructions.replace("Deliver by ", "").split("\n")[0],
          //   "hh:mm A"
          // ).format("hh:mmA");

          const deliverByDateTime = moment(
            deliveryDate + " " + deliverByTime,
            "YYYY-MM-DD hh:mmA"
          ).format(fmt);
          if (moment(moment(deliverByDateTime), fmt, true).isValid()) {
            deliverByTimeMap[moment.parseZone(deliverByDateTime)] = orderItemId;
          }
          return moment(deliverByDateTime);
        });

        let deliverBy = "";
        let isDelayed = false;

        const timedDeliveryMap = Object.keys(deliverByTimeMap).map((each) =>
          moment(each)
        );

        const earliestTimedDelivery = timedDeliveryMap?.length
          ? moment.min(timedDeliveryMap)
          : "";

        const orderIdwithEarliestTimedDelivery =
          earliestTimedDelivery &&
          deliverByTimeMap[moment.parseZone(earliestTimedDelivery)];

        const routeIdwithEarliestTimedDelivery = filteredOrders.find((ord) => {
          return ord.orderItemId === orderIdwithEarliestTimedDelivery;
        })?.routeId;

        routeIdInZoneList?.map((routeId) => {
          const { isDelayed: routeHasDelayed = false } =
            dashboardRoutesData[routeId] || {};
          isDelayed = isDelayed || routeHasDelayed;
        });

        if (routeIdwithEarliestTimedDelivery) {
          const { deliverBy: RoutedDeliveryBy = "" } =
            dashboardRoutesData[routeIdwithEarliestTimedDelivery] || {};
          deliverBy = RoutedDeliveryBy;
        }

        return {
          displayName,
          deliverBy,
          shopCode,
          isDelayed,
          ...get(deliveryDashboardData["zones"], `${shopCode}.${zoneId}`, {}),
        };
      });

  const dashboardData =
    selectedTab === "routes"
      ? deliveryDashboardData["routes"]
      : selectedTab === "zones"
      ? processsedZonesData()
      : [];

  const dashboardDataInView =
    dashboardData?.slice(0, viewMoreCounts[selectedTab]) || [];

  useEffect(() => {
    dispatch(
      fetchNonRoutedOrders({
        shopCode,
        startDate,
        endDate,
        considerLocalOrders:
          shopCode === "All Shops" ? true : considerLocalOrders,
      })
    );
  }, [JSON.stringify(shopCode), startDate, endDate]);

  const modalContent = {
    content: (
      <Text
        style={{
          ...tw("p-4"),
          fontSize: 15,
          color: colors.highlighter,
          textAlign: "center",
        }}
      >
        {Localise(
          messages,
          "You do not have permission to view orders from some Shops on this route."
        )}
      </Text>
    ),
    buttons: [
      {
        type: "secondary",
        title: Localise(messages, "OK"),
      },
    ],
  };
  const modal = (
    <CustomModal
      modalVisible={accessModalVisible}
      modalContent={modalContent}
      primaryhandler={() => {}}
      secondaryhandler={() => {
        setAccessModalVisible(false);
      }}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: backgroundColors.secondary },
      ]}
      modalStyle={
        Platform.OS !== "web"
          ? {
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor: "#00000070",
              color: colors.secondary,
            }
          : { width: "35%" }
      }
    />
  );

  if (loading && !dashboardDataInView?.length) {
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );
  }

  const macAddressBannerHeight = macAddressBannerExists() ? 50 : 0;

  return (
    <ScrollView
      key={"content"}
      contentContainerStyle={{
        paddingTop: 10,
        minHeight: 100,
      }}
      style={{
        ...(isDesktop
          ? {
              maxHeight:
                proxyHeaderExists || suspendedHeaderExists
                  ? `calc(100vh - 345px - ${macAddressBannerHeight}px)`
                  : `calc(100vh - 295px - ${macAddressBannerHeight}px)`,
            }
          : {
              height: "96%",
            }),
      }}
    >
      <View
        fsClass="fs-unmask"
        style={{
          maxWidth: isDesktop ? 840 : "100%",
        }}
      >
        {dashboardDataInView?.length ? (
          <>
            {modal}
            {dashboardDataInView?.map((eachDateData, index) => (
              <View key={index}>
                <DeliveryDashboardListItem
                  itemData={eachDateData}
                  deliveryDashboardTab={selectedTab}
                  filteredShopCodes={filteredShopCodes}
                  setAccessModalVisible={setAccessModalVisible}
                />
              </View>
            ))}
          </>
        ) : (
          <View>
            <Text style={{}}>
              {Localise(
                messages,
                "No " +
                  startCase(selectedTab) +
                  " have been created for this shop"
              )}
            </Text>
          </View>
        )}
      </View>
      {dashboardData?.length > viewMoreCounts[selectedTab] && (
        <Button
          titleStyle={theme.Button.secondaryTitleStyle}
          buttonStyle={{
            ...theme.Button.secondaryButtonStyle,
            paddingHorizontal: 10,
            paddingVertical: 6,
            width: 95,
            height: 35,
          }}
          containerStyle={{
            alignItems: "flex-end",
            marginHorizontal: 5,
          }}
          title={Localise(messages, "View More")}
          onPress={() => {
            setViewMoreCounts({
              ...viewMoreCounts,
              [selectedTab]:
                viewMoreCounts[selectedTab] + initViewMoreCounts[selectedTab],
            });
          }}
          testID={`${selectedTab}_view_more`}
          accessibilityLabel={`${selectedTab}_view_more`}
        />
      )}
    </ScrollView>
  );
};

export default DeliveryDashboardTab;
