export const getInitialValues = (details) => {
  const initalValues = {
    stopName: undefined,
    stopAddress: {
      addressLine1: undefined,
      suite: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
      country: "US",
    },
    stopDescription: undefined,
    stopLatitude: undefined,
    stopLongitude: undefined,
  };
  return {
    ...initalValues,
    ...details,
  };
};
