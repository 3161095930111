import Storage from "library/utils/storage";

const AutomationCache = new Storage("AutomationCache", {
  throttleWait: 0,
});

const load = () => AutomationCache.load();

const clear = () => {
  AutomationCache.clear();
};

const getAutomationQrCode = () => {
  return AutomationCache.get("qrCode") || "";
};

export default {
  clear,
  load,
  getAutomationQrCode,
};
