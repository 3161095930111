import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { TouchableOpacity } from "react-native";
import { Text, Image } from "react-native-elements";
import { useNavigation, CommonActions } from "@react-navigation/native";

import { DeviceContext } from "library/contexts/appSettings";

import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";

import IMAGES from "static/assets/images";
import tw from "tailwind-rn";

const CreateNew = ({
  icon,
  text,
  route,
  params,
  name,
  setAction,
  iconSize = 18,
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { isDesktop } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;

  useEffect(() => {
    return () => {
      setAction && dispatch(setAction({ type: "create", value: undefined }));
    };
  }, []);

  const onClick = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: route,
        params,
      })
    );
    if (route === "create-order") {
      dispatch(setCurrentPage(route));
    }
  };

  return (
    <TouchableOpacity
      style={tw("flex-row justify-around items-center")}
      onPress={() =>
        setAction
          ? dispatch(setAction({ type: "create", value: name }))
          : onClick()
      }
      testID="create_new"
      accessibilityLabel="create_new"
    >
      <Image
        style={{
          width: iconSize,
          height: iconSize,
        }}
        resizeMode="cover"
        source={IMAGES[icon]}
      />
      {!isSmallScreen && <Text style={{ paddingLeft: 2 }}>{text}</Text>}
    </TouchableOpacity>
  );
};

export default CreateNew;
