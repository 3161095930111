import { createStyles, minWidth } from "react-native-media-queries";
import { fonts } from "styles/theme";

const base = {
  eachBlock: {
    width: "100%",
    height: 70,
    flexDirection: "column",
    borderLeftWidth: 1,
    borderLeftColor: "#c4c4c4",
    padding: 10,
  },
  eachBlockIcon: {
    marginTop: 0,
  },
  countText: {
    fontSize: 25,
    fontFamily: fonts.fontFamily.bold,
    fontWeight: "600",
    paddingBottom: 0,
    paddingLeft: 5,
  },
  routeRowContainer: {
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    width: "100%",
    flexDirection: "column",
  },
  orderStatus: {
    paddingLeft: 5,
  },
  additionalBlock: {
    width: 150,
  },
};

const tabletStyles = {
  eachBlock: {
    width: "16.7%",
    height: 110,
  },
  eachBlockIcon: {
    marginTop: 10,
  },
  countText: {
    fontSize: 25,
    fontFamily: fonts.fontFamily.bold,
    fontWeight: "600",
    paddingBottom: 0,
    paddingLeft: 0,
  },
  routeRowContainer: {
    shadowOffset: { width: -2, height: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    width: "90%",
    flexDirection: "row",
  },
  orderStatus: {
    paddingLeft: 0,
  },
  additionalBlock: {
    width: 150,
  },
};

const laptopStyles = {
  eachBlock: {
    width: "16.7%",
    height: 110,
  },
  eachBlockIcon: {
    marginTop: 10,
  },
  countText: {
    fontSize: 25,
    fontFamily: fonts.fontFamily.bold,
    fontWeight: "600",
    paddingLeft: 0,
    paddingBottom: 0,
  },
  routeRowContainer: {
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    width: "100%",
    flexDirection: "row",
  },
  orderStatus: {
    paddingLeft: 0,
  },
  additionalBlock: {
    width: 180,
  },
};

const largeDevices = {
  eachBlock: {
    width: 175,
    height: 110,
  },
  eachBlockIcon: {
    marginTop: 10,
  },
  countText: {
    fontSize: 25,
    fontFamily: fonts.fontFamily.bold,
    fontWeight: "600",
    paddingLeft: 0,
    paddingBottom: 0,
  },
  routeRowContainer: {
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    width: 1050,
    flexDirection: "row",
  },
  orderStatus: {
    paddingLeft: 0,
  },
  additionalBlock: {
    width: 180,
  },
};

export default createStyles(
  base,
  minWidth(767, tabletStyles),
  minWidth(1300, laptopStyles),
  minWidth(1700, largeDevices)
);
