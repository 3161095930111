import { createStyles, minWidth } from "react-native-media-queries";

const base = {
  labelText: {
    width: "30%",
  },
  valueText: {
    width: "70%",
  },
};

const desktopStyles = {
  labelText: {
    width: "30%",
  },
  valueText: {
    width: "70%",
  },
};

export default createStyles(base, minWidth(1550, desktopStyles));
