export const featureNames = Object.freeze({
  ADV_ROUTE_OPTIMISE: "Advanced Route Optimization",
  DELIVERY_DASHBOARD: "Delivery Dashboard Module",
  DESIGN_CENTER: "Design Center Module",
  QR_CODE_SCAN: "QR Code Scanning Module",
  QUICK_BOOKS: "QuickBooks Integration",
  EVENT_PLANNER: "Event Planner Module",
  OCCASION_REMINDER: "Occasion Reminder Module",
});

export const roles = [
  {
    key: "Admin",
    label: "Admin",
    editable: false,
  },
  {
    key: "Manager",
    label: "Manager",
  },
  {
    key: "Delivery Manager",
    label: "Delivery Manager",
  },
  {
    key: "Driver",
    label: "Driver",
  },
  {
    key: "Designer",
    label: "Designer",
  },
  {
    key: "Sales",
    label: "Sales",
  },
  {
    key: "Website",
    label: "Website",
  },
];

export const planTypes = (plan, feature) => {
  const plansConfig = {
    MONTHLY: "month",
    PER_USAGE:
      feature === featureNames.ADV_ROUTE_OPTIMISE ? "optimization" : "",
  };
  return plansConfig[plan];
};

export const instructionsConfig = (feature) => {
  if (feature === featureNames.QUICK_BOOKS) {
    return [
      {
        type: "text",
        content: `How to Integrate with QuickBooks`,
        level: 0,
        childContent: [
          {
            type: "text",
            content: `Go to Additional Settings to connect your QuickBooks Plus account.`,
            level: 1,
          },
          {
            type: "textWithLinks",
            contentBeforeLink:
              "Log into your existing QuickBooks Plus account. If you do not have a QuickBooks account, you can sign up for a QuickBooks Plus account.",
            link: {
              text: "Click here",
              target: "https://quickbooks.intuit.com/pricing/",
            },
            contentAfterLink: "to see QuickBooks Plus Account Pricing.",
            level: 1,
          },
        ],
      },
    ];
  }
  return [];
};

export const settingsNavigationRoute = {
  [featureNames.QR_CODE_SCAN]: {
    page: "shop-settings",
    screenRoute: "shop-details",
    params: { openPrintPreference: true },
  },
  [featureNames.QUICK_BOOKS]: {
    page: "shop-settings",
    screenRoute: "payments",
    params: { openQuickBooksIntegration: true },
  },
  [featureNames.OCCASION_REMINDER]: {
    page: "shop-settings",
    screenRoute: "notifications",
    params: { openOccasionReminder: true },
  },
};

export const customHelpDocNames = {
  [featureNames.ADV_ROUTE_OPTIMISE]: featureNames.ADV_ROUTE_OPTIMISE,
  [featureNames.DELIVERY_DASHBOARD]: "Delivery Dashboard",
  [featureNames.DESIGN_CENTER]: "Design Center",
  [featureNames.QR_CODE_SCAN]: "Scanner",
  [featureNames.QUICK_BOOKS]: featureNames.QUICK_BOOKS,
  [featureNames.EVENT_PLANNER]: "Event Planner",
  [featureNames.OCCASION_REMINDER]: "Occasion Reminder",
};
