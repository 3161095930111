import React from "react";
import { Text, Image } from "react-native-elements";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { fonts } from "styles/theme";
import I18NContext from "library/contexts/i18N";

export default function PopupMenu({
  menuOptions = [],
  containerStyle = {},
  optionsContainerStyle = {},
  customStyles = {},
  onSelect,
  children,
  selectedValue,
  menuOptionsTitle = "",
  ...props
}) {
  const { messages, Localise } = React.useContext(I18NContext);

  return (
    <Menu style={containerStyle}>
      <MenuTrigger {...props}>{children}</MenuTrigger>
      <MenuOptions
        optionsContainerStyle={optionsContainerStyle}
        customStyles={{
          optionWrapper: {
            padding: 5,
            flexDirection: "row",
            justifyContent: "space-between",
            ...customStyles.optionWrapper,
          },
          optionText: { ...customStyles.optionText },
        }}
      >
        {menuOptionsTitle?.length > 0 && (
          <Text
            style={[
              fonts.heading5,
              {
                fontSize: 12,
                margin: 5,
              },
            ]}
          >
            {Localise(messages, menuOptionsTitle)}
          </Text>
        )}
        {menuOptions.map((option) => {
          const selectedOption =
            selectedValue === option.key || option.selected;
          return (
            <MenuOption
              key={option.key}
              onSelect={() => onSelect(option.key)}
              testID={option.key}
              accessibilityLabel={option.key}
            >
              <Text>{Localise(messages, option.label)}</Text>
              {selectedOption && (
                <Image
                  style={{ width: 15, height: 15 }}
                  resizeMode="cover"
                  source={require("static/assets/confirm.png")}
                />
              )}
            </MenuOption>
          );
        })}
      </MenuOptions>
    </Menu>
  );
}
