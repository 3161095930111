import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Platform, Linking, View, Text } from "react-native";

import { getUIConfig } from "./ui-config";
import { Spinner } from "components/elements";

import I18NContext from "library/contexts/i18N";
import UserProfileStorage from "library/storage/userProfile";
import UserProfileContext from "library/contexts/userProfile";
import {
  selectSiteContent,
  selectShopCode,
} from "library/sagas/views/home/drawer/mercury-online/selector";
import { setExtendedScreen } from "library/sagas/views/home/drawer/mercury-online/slice";
import { LabelField } from "components/wrappers";
import { DeviceContext } from "library/contexts/appSettings";

import { fonts, colors } from "styles/theme";
import tw from "tailwind-rn";

const SiteContent = () => {
  const { content } = useSelector(selectSiteContent);
  const shopCode = useSelector(selectShopCode);
  const dispatch = useDispatch();
  const { isDesktop } = React.useContext(DeviceContext);

  const { memberCodes = [] } = React.useContext(UserProfileContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const UIConfig = getUIConfig(Localise, messages);

  const links = UIConfig.reduce((accum, data) => {
    if (!accum[data.category]) accum[data.category] = [];
    accum[data.category].push(data);
    return accum;
  }, {});

  const onPress = (link) => {
    if (link.fieldType === "Link" && link.uri) {
      const { shopify_store_url: shopifyAdmin } =
        UserProfileStorage.getShopPreferences(
          shopCode === "all" ? memberCodes[0] : shopCode
        );
      const url = `${shopifyAdmin}${link.uri}`;

      Platform.OS === "web" ? window.open(url) : Linking.openURL(url);
    } else dispatch(setExtendedScreen({ type: "siteContent", data: link }));
  };

  return content ? (
    <View
      style={[
        tw("flex flex-col mt-2"),
        {
          borderColor: colors.grayScaleLight,
          borderWidth: 1,
          borderRadius: 10,
          width: isDesktop ? "60%" : "100%",
        },
      ]}
    >
      <View
        style={[
          {
            backgroundColor: "#B8DBE3",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
        ]}
      >
        <View style={[tw("flex flex-col m-5")]}>
          <LabelField heading={true} style={fonts.heading4} text={"Settings"} />
          <Text style={{ ...fonts.style2, paddingTop: 4, paddingBottom: 8 }}>
            {Localise(
              messages,
              "Click the links below to edit the content on your site."
            )}
          </Text>
        </View>
      </View>
      {Object.keys(links).map((category, index, array) => (
        <View key={index}>
          <View style={[tw("flex flex-col my-4 mx-5")]}>
            <View key={`${category}-${index}`} style={tw("mb-2 flex-col")}>
              <Text style={fonts.heading4}>{category}</Text>
              <Text
                style={{ ...fonts.style2, paddingTop: 4, paddingBottom: 8 }}
              >
                {links[category][0].description}
              </Text>
            </View>
            {links[category].map((link, index) => (
              <View
                key={`${link.component}-${index}`}
                style={tw("mb-2 flex-row items-center")}
              >
                <Text style={fonts.link2} onPress={() => onPress(link)}>
                  {link?.title}
                </Text>
              </View>
            ))}
          </View>
          <View
            style={{
              borderBottomColor: colors.grayScaleLight,
              borderBottomWidth: index !== array.length - 1 ? 1 : 0,
              marginHorizontal: 20,
            }}
          ></View>
        </View>
      ))}
    </View>
  ) : (
    <View style={{ minHeight: 150 }}>
      <Spinner size="large" />
    </View>
  );
};

export default SiteContent;
