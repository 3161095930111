import React, { useEffect } from "react";
import { View, Platform, Text, PermissionsAndroid, Image } from "react-native";
import { Button } from "react-native-elements";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import pick from "lodash/pick";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as ImagePicker from "react-native-image-picker";
import * as DocumentPicker from "expo-document-picker";
import { useActionSheet } from "@expo/react-native-action-sheet";

import { ToasterHandler, CustomModal } from "components/elements";
import {
  Form,
  FormField,
  FormFieldPicker,
  FormFieldDatePicker,
  SubmitButton,
} from "components/elements/forms";
import { theme, backgroundColors, colors, fonts } from "styles/theme";
import { useFormikContext } from "formik";
import tw from "tailwind-rn";

import UserProfileContext from "library/contexts/userProfile";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { request } from "library/utils/request";
import { LocationManager } from "library/utils/locationManager";
import useStateIfMounted from "library/utils/useStateIfMounted";
import {
  setIsShowSignatureModal,
  setSignatureImage,
} from "library/sagas/ongoing/global-data/slice";
import { setDconStatus } from "library/sagas/ongoing/current-orders/slice";
import { selectSignatureImage } from "library/sagas/ongoing/global-data/selector";
import {
  selectRecordData,
  selectDconFormType,
} from "library/sagas/ongoing/current-orders/selector";
import { getValidationSchema } from "./helper";
import { orderDsRequestRerouting } from "library/utils/orderListing";
import { OrderDSCancelFailModal } from "library/utils/deliveryService";
import CancelDSModal from "components/views/drawer/order-details/order-head/take-action/cancelDSModal";

// eslint-disable-next-line react/display-name
const DconInfo = React.memo(
  ({
    listingType,
    showDconForm,
    index,
    doNotFill,
    actionCallback,
    sourceMemberCode,
    setShowDconForm,
    isMHQNonCoreUser,
    setActionProcessing,
    isActionProcessing,
    isPreview,
    menuAction,
    setMenuAction,
    isPickupOrder,
  }) => {
    const dispatch = useDispatch();

    const orderDetailResponse = useSelector(selectRecordData);
    const dconType = useSelector(selectDconFormType) || "delivery-confirmation";

    const { setFieldValue } = useFormikContext();

    const orderDetails = orderDetailResponse.orderItems[index];
    const {
      status: orderStatus = "",
      orderItemId,
      hasDSFulfillmentError,
      direction,
    } = orderDetails;
    const fillerMemberCode = get(
      orderDetails,
      "receivingMember.memberCode",
      ""
    );

    const dsRerouteInProgress = orderDsRequestRerouting(
      pick(orderDetails, ["latestDSRequestedStatus"])
    );

    // showing dcon form based on meatball menuActions.
    if (
      !isPickupOrder &&
      [
        `delivery-confirmation${orderItemId}`,
        `delivery-exception${orderItemId}`,
      ].includes(menuAction)
    ) {
      setShowDconForm(true);
    }

    const isDriverDconForm = listingType === "delivery";
    const { messages: locMessages, Localise } = React.useContext(I18NContext);
    const [onChangeValidation, setOnChangeValidation] =
      useStateIfMounted(false);
    const [pickerResponse, setPickerResponse] = useStateIfMounted(null);
    const [modalVisible, setModalVisibile] = useStateIfMounted(false);
    const [modalCount, setModalCount] = useStateIfMounted(0);
    const [standardDeliveryImageResponse, setStandardDeliveryImageResponse] =
      useStateIfMounted(null);
    const [standardSignatureResponse, setStandardSignatureResponse] =
      useStateIfMounted(null);
    const [userLocation, setuserLocation] = useStateIfMounted({});
    const [isCancelDSModal, setShowCancelDSModal] = useStateIfMounted(false);
    const [showDSCancelFailModal, setShowDSCancelFailModal] = useStateIfMounted(
      {
        modalVisible: false,
        errorMessage: "",
      }
    );
    const DSCancelRef = React.useRef(0);

    const { showActionSheetWithOptions } = useActionSheet();
    const deliveryImageBase64 =
      pickerResponse?.assets && pickerResponse.assets[0].base64;
    const signatureImageBase64 = useSelector(selectSignatureImage);
    const isWeb = Platform.OS === "web";
    const { userProfile: { firstName: operator } = {} } =
      React.useContext(UserProfileContext);
    const DConRef = React.useRef();
    const { deliveryDate: orderDeliveryDate, deliveryMethod } =
      orderDetails.deliveryInfo;
    const calendarMinDate = moment.utc(orderDetails.createdOn).toDate();
    const today = moment().toDate();

    const title = "Choose An Action";
    const cancelButtonIndex = 2;
    const geofenceRange = 0.15 * 1.609344; // multiplying by 1.60933 for mile to km conversion
    const { isTablet, isMobile } = React.useContext(DeviceContext);

    const isDesktopBrowser = () => {
      if (isWeb && window?.screen?.availWidth >= 1024) {
        return true;
      }
      return false;
    };

    const pickDocument = async (setter) => {
      let result = await DocumentPicker.getDocumentAsync({
        type: "image/*",
      });

      // Image size should be less than 3MB - Ref: MSOL-2571
      if (result.size < 3000000) {
        const selectedImage = result.uri.replace(
          /^data:image\/[a-z]+;base64,/,
          ""
        );
        setter(selectedImage);
      } else {
        ToasterHandler(
          "error",
          Localise(locMessages, "Image size should be less than 3MB.")
        );
      }
    };

    const updateSheetOptions = (setter) => {
      // https://github.com/expo/react-native-action-sheet
      showActionSheetWithOptions(
        {
          options: ["Camera", "Library", "Cancel"],
          cancelButtonIndex,
          title,
        },
        (buttonIndex) => {
          if (buttonIndex === 0) {
            onCameraPress(setter);
          } else if (buttonIndex === 1) {
            onImageLibraryPress(setter);
          }
        }
      );
    };

    const onImageLibraryPress = (setter) => {
      const options = {
        selectionLimit: 1,
        mediaType: "photo",
        includeBase64: true,
        maxWidth: 750,
        maxHeight: 750,
        quality: 0.8,
      };
      ImagePicker.launchImageLibrary(options, setter);
    };

    const onCameraPress = async (setter) => {
      if (Platform.OS === "ios") {
        launchCamera(setter);
      } else if (Platform.OS === "android") {
        try {
          const permissionGranted = await PermissionsAndroid.check(
            PermissionsAndroid.PERMISSIONS.CAMERA
          );

          if (permissionGranted) {
            launchCamera(setter);
          } else {
            const result = await PermissionsAndroid.request(
              PermissionsAndroid.PERMISSIONS.CAMERA,
              {
                title: Localise(locMessages, "Camera Permission Required"),
                message: Localise(
                  locMessages,
                  "Mercury HQ would like to use your camera."
                ),
                buttonPositive: Localise(locMessages, "OK"),
              }
            );

            if (result === PermissionsAndroid.RESULTS.GRANTED) {
              launchCamera(setter);
            } else {
              ToasterHandler(
                "error",
                Localise(
                  locMessages,
                  "Camera permission not granted, please give  permission."
                )
              );
            }
          }
        } catch (err) {
          ToasterHandler(
            "error",
            Localise(
              locMessages,
              "An error occurred while capturing the delivery status image, please try again."
            )
          );
        }
      }
    };

    const launchCamera = (setter) => {
      const options = {
        saveToPhotos: false,
        mediaType: "photo",
        includeBase64: true,
        maxWidth: 750,
        maxHeight: 750,
        quality: 0.8,
      };

      ImagePicker.launchCamera(options, setter);
    };

    const validationSchema = getValidationSchema(
      Localise,
      locMessages,
      isMHQNonCoreUser,
      listingType
    );

    const checkWithinGeofence = (userGeoCoords, recipientGeoCoords) => {
      var ky = 40000 / 360;
      var kx = Math.cos((Math.PI * recipientGeoCoords.latitude) / 180.0) * ky;
      var dx = Math.abs(recipientGeoCoords.longitude - userGeoCoords.lng) * kx;
      var dy = Math.abs(recipientGeoCoords.latitude - userGeoCoords.lat) * ky;
      return Math.sqrt(dx * dx + dy * dy) <= geofenceRange;
    };

    const getLocationDetails = async () => {
      try {
        const permissionGranted = await LocationManager.requestPermission(
          Localise,
          locMessages
        );

        if (permissionGranted) {
          setuserLocation(await LocationManager.getCurrentLocation());
        }
      } catch (exc) {
        console.log("User not given location permissions ", exc);
      }
    };

    useEffect(() => {
      dispatch(setSignatureImage(""));
      if (!isPreview) getLocationDetails();
      return () => {
        if (Platform.OS !== "web") {
          dispatch(setIsShowSignatureModal(false));
        }
      };
    }, []);

    const handleContinue = () => {
      setModalVisibile(false);
      DConRef.current.handleSubmit();
    };

    const handleCancel = () => {
      setModalVisibile(false);
    };

    const DconAndcancelDSRequest = (callBack = () => {}) => {
      const dsCancelPayload = {
        orderItemId,
        deliveryMethod,
        memberCode: fillerMemberCode,
      };
      request("delivery-service-cancel", dsCancelPayload)
        .then(() => {
          ToasterHandler(
            "success",
            Localise(locMessages, "The delivery service request is canceled")
          );
          callBack();
        })
        .catch(({ errors = {} }) => {
          const errorMessage = get(
            errors,
            "0.message",
            "Something went wrong, please try again."
          );
          DSCancelRef.current = 1;
          setShowDSCancelFailModal({
            modalVisible: true,
            errorMessage,
          });
        });
    };

    const formSubmitTrigger = (values, formikBag) => {
      if (formikBag.isSubmitting) return;

      let dconAtDoorCheck = false;
      if (userLocation?.success) {
        dconAtDoorCheck = checkWithinGeofence(
          userLocation,
          orderDetails.recipientInfo
        );
      }
      if (
        modalCount === 0 &&
        (isMobile || isTablet) &&
        !isDesktopBrowser() &&
        !dconAtDoorCheck &&
        values.deliveryStatus === "delivery-confirmation"
      ) {
        setModalVisibile(true);
        formikBag.setSubmitting(false);
        setModalCount(1);
        return;
      }

      if (
        values.deliveryStatus === "delivery-confirmation" &&
        dsRerouteInProgress &&
        DSCancelRef.current == 0
      ) {
        formikBag.setSubmitting(false);
        setShowCancelDSModal(true);
        DSCancelRef.current = 1;
        return;
      }

      const reqObj = {
        operator: operator,
        comments: values.notes,
        deliveryDate: orderDeliveryDate,
        deliveredDate: moment(values.deliveryDate).format("YYYY-MM-DD"),
        deliveryTime: moment(values.deliveryTime).format("HH:mm"),
        driver: values.driver || null,
        invoiceNumber: values.invoiceNo,
        productDescription: values.productDescription,
        deviceLatitude: (userLocation && userLocation?.lat) || "",
        deviceLongitude: (userLocation && userLocation?.lng) || "",
        ...(isDriverDconForm &&
          !isWeb && {
            ...(values.deliveryStatus === "delivery-confirmation" && {
              photo: deliveryImageBase64,
              signature: signatureImageBase64,
            }),
          }),
        ...(values.deliveryStatus === "delivery-confirmation" &&
          !isDriverDconForm && {
            photo: !isEmpty(standardDeliveryImageResponse)
              ? isWeb
                ? standardDeliveryImageResponse
                : standardDeliveryImageResponse?.assets &&
                  standardDeliveryImageResponse.assets[0].base64
              : "",
            signature: !isEmpty(standardSignatureResponse)
              ? isWeb
                ? standardSignatureResponse
                : standardSignatureResponse?.assets &&
                  standardSignatureResponse.assets[0].base64
              : "",
          }),
        dconAtDoor: dconAtDoorCheck,
        isMobile: (isMobile || isTablet) && !isDesktopBrowser(),
      };

      setActionProcessing(true);

      request("order-actions", {
        recordId: orderItemId,
        deliveryMethod,
        action: values.deliveryStatus,
        sourceMemberCode: sourceMemberCode,
        ...reqObj,
      })
        .then((res) => {
          //mark as read if the order has DS fulfillment error
          if (hasDSFulfillmentError && deliveryMethod === "FLORIST_DELIVERED")
            request("order-message-actions", {
              recordId: orderItemId,
              deliveryMethod,
              markAsRead: true,
              isStructured: true,
              isUnstructured: true,
              sourceMemberCode,
            })
              .then(() => {
                actionCallback(values.deliveryStatus, orderItemId);
                setMenuAction("");
                setShowDconForm(false);
              })
              .catch((err) => {
                console.log("error:", err);
              });
          else {
            actionCallback(values.deliveryStatus, orderItemId);
            setMenuAction("");
            setShowDconForm(false);
          }
          setActionProcessing(false);
        })
        .catch((err) => {
          console.log("error:", err);
          setActionProcessing(false);
          ToasterHandler(
            "uh oh",
            Localise(locMessages, "Order request failed, please try again.")
          );
          formikBag.setSubmitting(false);
          DSCancelRef.current = 0;
        });
    };

    if (["DELIVERED"].includes(orderStatus) || !showDconForm) return null;

    return (
      <View
        style={{
          zIndex: 1,
          borderWidth: 1,
          borderColor: colors.grayScaleLight,
          opacity: doNotFill ? 0.4 : 1,
        }}
        pointerEvents={doNotFill || !showDconForm ? "none" : "auto"}
      >
        <View
          style={{ backgroundColor: backgroundColors.secondary, padding: 20 }}
        >
          <Form
            initialValues={
              isDriverDconForm
                ? {
                    deliveryStatus: "delivery-confirmation",
                    notes: "",
                  }
                : {
                    deliveryDate: today,
                    deliveryTime: today,
                    driver: Platform.OS !== "web" ? operator : "",
                    productDescription: "",
                    invoiceNo: "",
                    notes: "",
                    deliveryStatus: dconType,
                  }
            }
            innerRef={DConRef}
            validationSchema={validationSchema}
            validateOnChange={onChangeValidation}
            validateOnBlur={onChangeValidation}
            onSubmit={(values, formikBag) =>
              formSubmitTrigger(values, formikBag)
            }
            enableReinitialize={false}
            render={({
              submitCount,
              values,
              setFieldValue: dconSetFieldValue,
            }) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                if (submitCount > 0) {
                  setOnChangeValidation(true);
                }
              }, [submitCount]);

              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                dconSetFieldValue("deliveryStatus", dconType);
              }, [dconType]);

              return (
                <View style={tw("flex flex-col")}>
                  {isDriverDconForm ? (
                    <>
                      <FormFieldPicker
                        containerStyle={{
                          width: "100%",
                        }}
                        placeholder={{
                          label: "Select Delivery Status",
                          value: "",
                        }}
                        data={[
                          {
                            value: "delivery-confirmation",
                            label: "Delivered",
                          },
                          {
                            value: "delivery-exception",
                            label: "Attempted Delivery",
                          },
                        ]}
                        fsClass="fs-exclude"
                        name="deliveryStatus"
                        disabled={false}
                      />
                      <FormField
                        inputStyle={{ height: 80 }}
                        containerStyle={{
                          width: "100%",
                          padding: 0,
                          margin: 0,
                        }}
                        placeholder="Optional Notes"
                        name="notes"
                        multiline={true}
                        numberOfLines={3}
                      />
                      {(!isEmpty(signatureImageBase64) ||
                        !isEmpty(deliveryImageBase64)) &&
                        !isWeb && (
                          <View style={{ paddingVertical: 10 }}>
                            {!isEmpty(signatureImageBase64) && (
                              <View style={tw("flex flex-row")}>
                                <Image
                                  containerStyle={tw("flex")}
                                  style={{ width: 17, height: 17 }}
                                  resizeMode="cover"
                                  source={require("static/assets/address-verification-check-mark-fill.png")}
                                />
                                <Text style={{ paddingLeft: 10 }}>
                                  {Localise(
                                    locMessages,
                                    "Signature Image Added Successfully"
                                  )}
                                </Text>
                              </View>
                            )}
                            {!isEmpty(deliveryImageBase64) && (
                              <View style={tw("flex flex-row")}>
                                <Image
                                  containerStyle={tw("flex")}
                                  style={{ width: 17, height: 17 }}
                                  resizeMode="cover"
                                  source={require("static/assets/address-verification-check-mark-fill.png")}
                                />
                                <Text style={{ paddingLeft: 10 }}>
                                  {Localise(
                                    locMessages,
                                    "Delivery Status Image Added Successfully"
                                  )}
                                </Text>
                              </View>
                            )}
                          </View>
                        )}
                      <View style={tw("flex flex-row-reverse justify-between")}>
                        {values &&
                          values.deliveryStatus === "delivery-confirmation" &&
                          Platform.OS !== "web" && (
                            <View style={tw("flex flex-row")}>
                              <Button
                                title={Localise(locMessages, "Add Signature")}
                                titleStyle={theme.Button.secondaryTitleStyle}
                                buttonStyle={{
                                  ...theme.Button.secondaryButtonStyle,
                                  paddingVertical: 8,
                                  paddingHorizontal: 10,
                                }}
                                containerStyle={{
                                  margin: 5,
                                  justifyContent: "center",
                                }}
                                onPress={() =>
                                  dispatch(setIsShowSignatureModal(true))
                                }
                                disabled={!isEmpty(signatureImageBase64)}
                              />
                              <Button
                                title={Localise(locMessages, "Upload Photo")}
                                titleStyle={theme.Button.secondaryTitleStyle}
                                buttonStyle={{
                                  ...theme.Button.secondaryButtonStyle,
                                  paddingVertical: 8,
                                  paddingHorizontal: 10,
                                }}
                                containerStyle={{
                                  margin: 5,
                                  justifyContent: "center",
                                }}
                                onPress={() =>
                                  updateSheetOptions(setPickerResponse)
                                }
                                disabled={!isEmpty(deliveryImageBase64)}
                              />
                            </View>
                          )}
                      </View>
                      <SubmitButton
                        disableOnDirty={isActionProcessing}
                        containerStyle={{
                          flexDirection: "row-reverse",
                          margin: 5,
                        }}
                        title="Submit"
                        error={""}
                      />
                    </>
                  ) : (
                    <View style={tw("m-1")}>
                      <View
                        style={[
                          tw("flex flex-row my-1 items-center"),
                          { zIndex: 20 },
                        ]}
                      >
                        <Text style={{ ...fonts.heading5, width: 125 }}>
                          {Localise(locMessages, "Delivery Status")}
                        </Text>
                        <View style={{ width: 195 }}>
                          <FormFieldPicker
                            containerStyle={{
                              width: "100%",
                            }}
                            placeholder={{
                              label: "Select Delivery Status",
                              value: "",
                            }}
                            data={[
                              {
                                value: "delivery-confirmation",
                                label: "Delivered",
                              },
                              {
                                value: "delivery-exception",
                                label: "Attempted Delivery",
                              },
                            ]}
                            fsClass="fs-exclude"
                            name="deliveryStatus"
                            disabled={false}
                          />
                        </View>
                      </View>
                      {((values &&
                        values.deliveryStatus === "delivery-confirmation") ||
                        !isMHQNonCoreUser) && (
                        <>
                          <View
                            style={[
                              tw("flex flex-row my-1 items-center"),
                              { zIndex: 20 },
                            ]}
                          >
                            <Text style={{ ...fonts.heading5, width: 125 }}>
                              {Localise(locMessages, "Delivery Date")}
                            </Text>
                            <View style={{ width: 195 }}>
                              <FormFieldDatePicker
                                dateFormat={
                                  Platform.OS === "web"
                                    ? "MM/dd/yy EEEE"
                                    : "MM/DD/YY dddd"
                                }
                                name="deliveryDate"
                                placeholder="Delivery Date"
                                iconName="calendar"
                                alignIconRight={true}
                                containerStyle={{
                                  zIndex: 10,
                                }}
                                minDate={calendarMinDate}
                                maxDate={today}
                                popperClassName="dconCalendar"
                              />
                            </View>
                          </View>
                          <View
                            style={[
                              tw("flex flex-row my-1 items-center"),
                              { zIndex: 15 },
                            ]}
                          >
                            <Text style={{ ...fonts.heading5, width: 125 }}>
                              {Localise(locMessages, "Delivery Time")}
                            </Text>
                            <View style={{ width: 195 }}>
                              <FormFieldDatePicker
                                dateFormat="h:mm a"
                                name="deliveryTime"
                                timeCaption="Delivery Time"
                                placeholder="Delivery Time"
                                iconName="clock"
                                alignIconRight={true}
                                showTimeSelect={true}
                                showTimeSelectOnly={true}
                                containerStyle={{
                                  zIndex: 10,
                                }}
                                timeIntervals={15}
                              />
                            </View>
                          </View>

                          <View style={tw("flex flex-row my-1 items-center")}>
                            <Text style={{ ...fonts.heading5, width: 125 }}>
                              {Localise(locMessages, "Driver")}
                            </Text>
                            <View style={{ width: 195 }}>
                              <FormField
                                autoCapitalize="none"
                                autoCorrect={false}
                                name="driver"
                                placeholder="Driver Name"
                              />
                            </View>
                          </View>

                          <View style={tw("flex flex-row my-1 items-center")}>
                            <Text style={{ ...fonts.heading5, width: 125 }}>
                              {Localise(locMessages, "Product Description")}
                            </Text>
                            <View style={{ width: 195 }}>
                              <FormField
                                autoCapitalize="none"
                                autoCorrect={false}
                                name="productDescription"
                                placeholder="Product Description"
                                multiline={true}
                                numberOfLines={2}
                              />
                            </View>
                          </View>

                          <View style={tw("flex flex-row my-1 items-center")}>
                            <Text style={{ ...fonts.heading5, width: 125 }}>
                              {Localise(locMessages, "Invoice No.")}
                            </Text>
                            <View style={{ width: 195 }}>
                              <FormField
                                autoCapitalize="none"
                                autoCorrect={false}
                                name="invoiceNo"
                                placeholder={"Invoice No."}
                              />
                            </View>
                          </View>
                        </>
                      )}
                      <View style={tw("flex flex-row my-1 items-center")}>
                        <Text style={{ ...fonts.heading5, width: 125 }}>
                          {Localise(locMessages, "Notes")}
                        </Text>
                        <View style={{ width: 195 }}>
                          <FormField
                            autoCapitalize="none"
                            autoCorrect={false}
                            name="notes"
                            placeholder="Add additional notes if needed"
                            multiline={true}
                            numberOfLines={2}
                            maxLength={310}
                            showRemainingChars={true}
                          />
                        </View>
                      </View>
                      {values &&
                        values.deliveryStatus === "delivery-confirmation" && (
                          <>
                            <View style={tw("flex flex-row my-1 items-center")}>
                              <Text style={{ ...fonts.heading5, width: 125 }}>
                                {Localise(locMessages, "Delivery Photo")}
                              </Text>
                              <View>
                                <Button
                                  title={Localise(
                                    locMessages,
                                    isEmpty(standardDeliveryImageResponse)
                                      ? "Upload Photo"
                                      : "Replace Photo"
                                  )}
                                  titleStyle={theme.Button.secondaryTitleStyle}
                                  buttonStyle={{
                                    ...theme.Button.secondaryButtonStyle,
                                    paddingVertical: 8,
                                    paddingHorizontal: 10,
                                  }}
                                  containerStyle={{
                                    margin: 5,
                                    justifyContent: "center",
                                  }}
                                  onPress={() =>
                                    Platform.OS === "web"
                                      ? pickDocument(
                                          setStandardDeliveryImageResponse
                                        )
                                      : updateSheetOptions(
                                          setStandardDeliveryImageResponse
                                        )
                                  }
                                />
                              </View>
                            </View>
                            <View style={tw("flex flex-row my-1 items-center")}>
                              <Text style={{ ...fonts.heading5, width: 125 }}>
                                {Localise(locMessages, "Delivery Signature")}
                              </Text>
                              <View>
                                <Button
                                  title={Localise(
                                    locMessages,
                                    isEmpty(standardSignatureResponse)
                                      ? "Upload Signature"
                                      : "Replace Signature"
                                  )}
                                  titleStyle={theme.Button.secondaryTitleStyle}
                                  buttonStyle={{
                                    ...theme.Button.secondaryButtonStyle,
                                    paddingVertical: 8,
                                    paddingHorizontal: 10,
                                  }}
                                  containerStyle={{
                                    margin: 5,
                                    justifyContent: "center",
                                  }}
                                  onPress={() =>
                                    Platform.OS === "web"
                                      ? pickDocument(
                                          setStandardSignatureResponse
                                        )
                                      : updateSheetOptions(
                                          setStandardSignatureResponse
                                        )
                                  }
                                />
                              </View>
                            </View>
                          </>
                        )}
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                        testID="delivery-confirmation-action-row"
                      >
                        <View style={tw("mx-5")}>
                          <Text
                            style={fonts.heading4}
                            onPress={() => {
                              setShowDconForm(false);
                              // resetting menuAction
                              setMenuAction("");
                              // resetting takeAction dropdown
                              setFieldValue("actionItem", "");
                              //reset delivery status prefill for form
                              dispatch(setDconStatus("delivery-confirmation"));
                            }}
                          >
                            {Localise(locMessages, "Nevermind")}
                          </Text>
                        </View>

                        <SubmitButton
                          disableOnDirty={isActionProcessing}
                          containerStyle={{ margin: 5 }}
                          title="Confirm"
                          error={""}
                        />
                      </View>
                    </View>
                  )}
                </View>
              );
            }}
          />
        </View>

        {/* Modals */}
        <ConfirmActionModal
          modalVisible={modalVisible}
          handlePrimary={handleContinue}
          handleSecondary={handleCancel}
        />
        <CancelDSModal
          isOrderReject={false}
          isCancelDSModal={isCancelDSModal}
          setShowCancelDSModal={setShowCancelDSModal}
          customMessage={
            "Click Continue to approve Delivery Service cancelation and Confirm Delivery"
          }
          triggerAction={() => {
            if (dsRerouteInProgress && direction === "INBOUND") {
              DconAndcancelDSRequest(() => DConRef.current.handleSubmit());
            }
          }}
          secondaryhandler={() => {
            DSCancelRef.current = 0;
          }}
        />
        {showDSCancelFailModal?.modalVisible ? (
          <OrderDSCancelFailModal
            modalVisible={showDSCancelFailModal?.modalVisible}
            errorMessage={showDSCancelFailModal?.errorMessage}
            confirmMessage={
              "Do you still want to proceed with Confirm Delivery"
            }
            primaryhandler={() => {
              setShowDSCancelFailModal({
                modalVisible: false,
                errorMessage: "",
              });
              //DCON
              DConRef.current.handleSubmit();
            }}
            secondaryhandler={() => {
              setShowDSCancelFailModal({
                modalVisible: false,
                errorMessage: "",
              });
              DSCancelRef.current = 0;
            }}
            Localise={Localise}
            messages={locMessages}
          />
        ) : null}
      </View>
    );
  }
);

const ConfirmActionModal = ({
  modalVisible,
  handlePrimary,
  handleSecondary,
  data = {},
}) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const {
    modal: {
      primary: textPrimary = "Confirm",
      secondary: textSecondary = "Nevermind",
      content:
        textContent = "It appears you’re at the wrong address. Do you want to confirm delivery?",
    } = {},
  } = data;

  const modalContent = {
    content: (
      <Text
        style={{
          ...tw("p-4"),
          fontSize: 15,
          color: colors.highlighter,
          textAlign: "center",
        }}
      >
        {Localise(messages, textContent)}
      </Text>
    ),
    buttons: [
      { type: "secondary", title: Localise(messages, textSecondary) },
      { type: "primary", title: Localise(messages, textPrimary) },
    ],
  };

  return (
    <CustomModal
      modalVisible={modalVisible}
      modalContent={modalContent}
      primaryhandler={handlePrimary}
      secondaryhandler={handleSecondary}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: "white" },
      ]}
      modalStyle={
        Platform.OS !== "web" && {
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          backgroundColor: "#00000070",
          color: "#FFFFFF",
        }
      }
    />
  );
};

export default DconInfo;
