import React from "react";
import { View, Text } from "react-native";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { selectAPIError } from "library/sagas/views/home/drawer/product-catalog/collection/selector";
import { navigateScreen } from "library/sagas/views/home/drawer/product-catalog/common/slice";
import { selectIsCatalogManager } from "library/sagas/views/home/drawer/product-catalog/common/selector";

import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

import { FormField, FormFieldSwitch } from "components/elements/forms";
import { LabelField, SaveCancelButtons } from "components/wrappers";
import { BannerImageField, PreviewUrl } from "../helper";
import { DeleteCollectionButton } from "./helper";

import { fonts } from "styles/theme";
import tw from "tailwind-rn";

const Settings = ({ isCreateCollection }) => {
  const {
    values: {
      status = "inactive",
      group,
      seoDisplayUrl,
      totalActiveProductsCount = 0,
    },
    setFieldValue,
  } = useFormikContext();
  const catalogManager = useSelector(selectIsCatalogManager);
  const dispatch = useDispatch();
  const error = useSelector(selectAPIError);
  const { isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);

  const isMOLCollection = group === "mol";
  const isStatusEditable = isCreateCollection
    ? true
    : status === "inactive"
    ? totalActiveProductsCount > 0
    : true;

  return (
    <View style={tw("flex flex-col")}>
      <View>
        <View style={[tw("flex flex-col"), { minWidth: isMobile ? 312 : 500 }]}>
          <FormField
            name={"name"}
            label={"Name"}
            labelStyle={fonts.heading4}
            containerStyle={{ width: "100%" }}
            editable={!isMOLCollection}
            disabled={isMOLCollection}
          />

          <View style={tw("flex flex-row items-center pl-1 my-3")}>
            <LabelField
              style={{ ...fonts.heading4, marginRight: 16 }}
              text={"Status"}
            />

            <FormFieldSwitch
              name={"status"}
              value={status === "active"}
              onValueChange={() =>
                setFieldValue(
                  "status",
                  status === "active" ? "inactive" : "active"
                )
              }
              disabled={!isStatusEditable}
            />

            <LabelField
              style={{
                ...fonts.heading5,
                paddingLeft: 8,
                textTransform: "capitalize",
              }}
              text={status}
            />
          </View>

          {!isCreateCollection && <PreviewUrl url={seoDisplayUrl} />}

          <BannerImageField
            title="Page Banner"
            name="bannerImage"
            dimensions={"1920x1280"}
          />

          <FormField
            name={"seoHeader"}
            label={"Description (shown above Products)"}
            labelStyle={fonts.heading4}
            containerStyle={{ width: "100%", marginTop: isMobile ? 8 : 0 }}
            multiline={true}
            numberOfLines={7}
          />

          <FormField
            name={"seoFooter"}
            label={"Summary (shown below Products)"}
            labelStyle={fonts.heading4}
            containerStyle={{ width: "100%", marginTop: 8 }}
            multiline={true}
            numberOfLines={7}
          />

          <FormField
            name={"seoPageTitle"}
            label={"SEO Page Title"}
            labelStyle={fonts.heading4}
            containerStyle={{ width: "100%", marginTop: 8 }}
            multiline={true}
            placeholder={"Maximum limit is 70 Characters"}
            numberOfLines={2}
            maxLength={71}
          />

          <FormField
            name={"seoMetaDescription"}
            label={"SEO Meta Description (Preview Text)"}
            labelStyle={fonts.heading4}
            containerStyle={{ width: "100%", marginTop: 8 }}
            multiline={true}
            placeholder={"Maximum limit is 320 Characters"}
            numberOfLines={7}
            maxLength={321}
          />
        </View>
      </View>
      <View>
        <Text style={{ ...fonts.error, textAlign: "right" }}>
          {error &&
            Localise(messages, "Something went wrong, please try again")}
        </Text>
      </View>
      <View
        style={tw(
          `flex ${
            isMobile
              ? "flex-col items-end"
              : `flex-row justify-${
                  catalogManager ? "between" : "end"
                } pl-1 items-center`
          } `
        )}
      >
        {catalogManager && <DeleteCollectionButton />}
        <SaveCancelButtons
          buttonTitle={isCreateCollection ? "Create" : "Save"}
          onCancel={() =>
            dispatch(
              navigateScreen({
                name: "dashboard",
                params: {
                  handle: "",
                },
              })
            )
          }
          showCancelAlways={true}
        />
      </View>
    </View>
  );
};

export default Settings;
