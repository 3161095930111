import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectPageStatus = createSelector(
  selectState,
  ({ status }) => status
);

export const selectApiError = createSelector(
  selectState,
  ({ apiError }) => apiError
);

export const selectIsPageLoading = createSelector(
  selectPageStatus,
  (status) => status === "progress"
);

export const selectIsPageFailed = createSelector(
  selectPageStatus,
  (status) => status === "fail"
);

export const selectIsPageLoaded = createSelector(
  selectPageStatus,
  (status) => status === "done"
);

export const selectApiResponse = createSelector(
  selectState,
  ({ apiResponse }) => apiResponse
);

export const selectPageData = createSelector(selectState, ({ data }) => data);

export const selectTotalNumberOfRecords = createSelector(
  selectPageData,
  ({ totalRecords }) => totalRecords
);

export const selectEvents = createSelector(
  selectPageData,
  ({ events }) => events
);

export const selectDraftEvents = createSelector(
  selectPageData,
  ({ drafts }) => drafts
);

export const selectNeedsActionEvents = createSelector(
  selectPageData,
  ({ needsAction }) => needsAction
);

export const selectNeedsActionAccordionSelector = createSelector(
  selectPageData,
  ({ needsAction }) => (needsAction?.length > 0 ? "expand" : "no-expand")
);
export const selectActiveAccordionSelector = createSelector(
  selectPageData,
  ({ active }) => (active?.length > 0 ? "expand" : "no-expand")
);
export const selectDraftsAccordionSelector = createSelector(
  selectPageData,
  ({ drafts }) => (drafts?.length > 0 ? "expand" : "no-expand")
);

export const selectCompletedAccordionSelector = createSelector(
  selectState,
  ({ data, actions }) =>
    actions.search && data?.completed?.length > 0 ? "expand" : "no-expand"
);
export const selectCancelledAccordionSelector = createSelector(
  selectState,
  ({ data, actions }) =>
    actions.search && data?.cancelled?.length > 0 ? "expand" : "no-expand"
);
export const selectInactiveAccordionSelector = createSelector(
  selectState,
  ({ data, actions }) =>
    actions.search && data?.inactive?.length > 0 ? "expand" : "no-expand"
);

export const selectActiveEvents = createSelector(
  selectPageData,
  ({ active }) => active
);

export const selectFuneralLogEvents = createSelector(
  selectPageData,
  ({ funeralLog }) => funeralLog
);

export const selectFuneralLogActualEvents = createSelector(
  selectPageData,
  ({ funeralLogActual }) => funeralLogActual
);

export const selectSelectedFuneralLogs = createSelector(
  selectPageData,
  ({ selectedFuneralLogs }) => selectedFuneralLogs
);

export const selectCancelledEvents = createSelector(
  selectPageData,
  ({ cancelled }) => cancelled
);

export const selectCompletedEvents = createSelector(
  selectPageData,
  ({ completed }) => completed
);

export const selectInactiveEvents = createSelector(
  selectPageData,
  ({ inactive }) => inactive
);

export const selectEventDetails = createSelector(
  selectPageData,
  ({ eventDetails }) => eventDetails
);

export const selectOrdersInfo = createSelector(
  selectPageData,
  ({ orders }) => orders
);

export const selectExtendedScreenData = createSelector(
  selectState,
  ({ extendedScreen }) => extendedScreen
);

export const selectShowUpsert = createSelector(
  selectExtendedScreenData,
  ({ showUpsert }) => showUpsert
);

export const selectCurrentEventId = createSelector(
  selectExtendedScreenData,
  ({ eventId }) => {
    return eventId;
  }
);

export const selectPageActions = createSelector(
  selectState,
  ({ actions }) => actions
);

export const selectAppliedLimits = createSelector(
  selectPageActions,
  ({ limit }) => limit.events
);

export const selectAppliedActiveLimits = createSelector(
  selectPageActions,
  ({ limit }) => limit.active
);

export const selectAppliedFuneralLogLimits = createSelector(
  selectPageActions,
  ({ limit }) => limit.funeralLog
);

export const selectAppliedNeedsActionLimits = createSelector(
  selectPageActions,
  ({ limit }) => limit.needsAction
);

export const selectAppliedDraftsLimits = createSelector(
  selectPageActions,
  ({ limit }) => limit.drafts
);

export const selectAppliedCompletedLimits = createSelector(
  selectPageActions,
  ({ limit }) => limit.completed
);

export const selectAppliedInactiveLimits = createSelector(
  selectPageActions,
  ({ limit }) => limit.inactive
);

export const selectAppliedCancelledLimits = createSelector(
  selectPageActions,
  ({ limit }) => limit.cancelled
);

export const selectSearchText = createSelector(
  selectPageActions,
  ({ search }) => search
);

export const selectSortOption = createSelector(
  selectPageActions,
  ({ sort }) => sort
);

export const selectAppliedShops = createSelector(
  selectPageActions,
  ({ shops }) => shops
);

export const selectAppliedFilters = createSelector(
  selectPageActions,
  ({ filters }) => filters
);

export const selectNoop = createSelector(selectState, () => "");

export const selectStoreOrigin = createSelector(
  selectExtendedScreenData,
  ({ storeOrigin }) => storeOrigin
);
