import React from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";

import { useSelector } from "react-redux";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { fonts } from "styles/theme";
import tw from "tailwind-rn";

const ListingHeader = ({ selectors }) => {
  const {
    dataSelector,
    limitSelector,
    selectTotalNumberOfRecords,
    selectPageActions,
  } = selectors;
  const itemData = useSelector(dataSelector);
  const currentLimit = useSelector(limitSelector);
  const pageActions = useSelector(selectPageActions);
  const totalRecords = useSelector(selectTotalNumberOfRecords);
  const totalLimit = itemData?.length ?? 0;
  const showingItems = currentLimit >= totalLimit ? totalLimit : currentLimit;

  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const showHeaderText = false; //Yet to decide on header. So keeping false for now.

  if (!showHeaderText) return <View style={{ marginBottom: 20 }}></View>;

  return (
    <View
      style={[
        tw("flex flex-row justify-between"),
        {
          paddingVertical: 5,
          paddingHorizontal: isDesktop ? 27 : 5,
          backgroundColor: "#fff",
        },
      ]}
    >
      <View style={{ justifyContent: "center", marginBottom: 10 }}>
        <Text>
          <Text style={fonts.heading4}>{Localise(messages, "Showing")}:</Text>{" "}
          {pageActions?.search?.length > 0
            ? `${showingItems} ${
                totalRecords > 10
                  ? `${Localise(messages, "of")} ${totalRecords}`
                  : ``
              } ${Localise(messages, "results for")} ${pageActions?.search}`
            : `${showingItems} ${Localise(
                messages,
                "of"
              )} ${totalRecords} ${Localise(messages, "Events")}`}
        </Text>
      </View>
    </View>
  );
};

export default ListingHeader;
