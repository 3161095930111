import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

export const createRecipeList = (specialInstructions = "") => {
  let reclist = [];

  if (!specialInstructions) return reclist;

  //Making sure parsing won't break the application
  try {
    specialInstructions
      .split("\\n")
      .join("\n")
      .replace("RECIPE:", "")
      .split(",")
      .map((ele) => {
        const [f1, ...other] = ele.trim().split(" ");
        reclist.push({
          quantity: f1,
          item: other.join(" "),
        });
      });
  } catch (e) {
    console.log("createRecipeList: Failure in parsing recipe info");
  }
  return reclist;
};
export const attachHeader = (list, isFTDOrder) => {
  !!list.length &&
    list.unshift({
      quantity: "Qty",
      color: !isFTDOrder ? "Color" : undefined,
      item: "Item",
      css: "font-bold",
    });

  return list;
};

const dimensionval = (value = "") => {
  try {
    const keywordsBeforeHeight = value.includes('"H')
      ? value.split('"H')[0].split(" ")
      : [];

    const keywordsBeforeWidth = value.includes('"W')
      ? value.split('"W')[0].split(" ")
      : [];
    const height = keywordsBeforeHeight[keywordsBeforeHeight.length - 1] || "";
    const width = keywordsBeforeWidth[keywordsBeforeWidth.length - 1] || "";

    /* jshint ignore:start*/
    // eslint-disable-next-line no-useless-escape
    if (height && width) return `${height}\"H x ${width}\"W`;
    // eslint-disable-next-line no-useless-escape
    else if (height) return `${height}\"H`;
    // eslint-disable-next-line no-useless-escape
    else if (width) return `${width}\"W`;
    /* jshint ignore:end */ else return "";
  } catch (e) {
    console.error("Unable to parse dimnesions.");
    return "";
  }
};

export const processSelectedProductResponse = (records = []) => {
  if (!records.length) return [];

  const {
    id,
    name,
    floristAttributes,
    description = [],
    assets,
    prices = [],
    categories = [],
    productAttributes = [],
  } = records[0];
  const productType =
    productAttributes.find((attr) => attr.name === "productType")?.values[0]
      .value || "";
  const productSubType =
    productAttributes.find((attr) => attr.name === "productSubType")?.values[0]
      .value || "";
  const category =
      productAttributes.find((attr) => attr.name === "category")?.values[0]
        .value || "";
  const details = description.find((ele) => ele.type === "details") || {};
  const priceInfo = prices.find((ele) => ele.type === "regular");
  const categoryInfo = categories.find((ele) => ele.category) || {};
  const firstCategoryInfo = get(categoryInfo, "category.0", {});

  let reclist = [];
  let isFTDOrder = false;
  try {
    reclist = JSON.parse(floristAttributes.recipe);
  } catch (error) {
    isFTDOrder = true;
    reclist = createRecipeList(floristAttributes.recipe);
  }

  return {
    productId: id,
    productName: floristAttributes.productName || name,
    productType,
    productSubType,
    category,
    dimension: dimensionval(details.value?.toUpperCase() || ""),
    primaryImg:
      assets?.images?.find((ele) => (ele.type = "primary"))?.url || "",
    secondImg: assets?.images?.find((ele) => (ele.type = "alt"))?.url || "",
    actualPrice: priceInfo ? priceInfo.value : "",
    recipeList: reclist.length ? attachHeader(reclist, isFTDOrder) : [],
    displayName: firstCategoryInfo.displayName || "",
    categoryName: firstCategoryInfo.name || "",
    isFTDOrder,
  };
};

export const processSelectedMOLProductResponse = (response = {}) => {
  if (isEmpty(response)) return [];

  const { productId = "", media } = response;

  return {
    productId,
    media,
  };
};
