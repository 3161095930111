import React, { useContext } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import { Tooltip } from "components/elements";

import {
  fonts,
  colors,
  backgroundColors,
  routeDashboardColorCodes,
} from "styles/theme";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import UserProfileStorage from "library/storage/userProfile";

import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import { resetBasicInfo } from "library/sagas/ongoing/current-orders/slice";

import tw from "tailwind-rn";
import get from "lodash/get";
import every from "lodash/every";
import moment from "moment";

import { IMAGES, DIMENSIONS } from "static/assets/images";
import styles from "./styles";
import {
  UIConfig,
  orderStatusColor,
  orderStatusIcon,
  displayStatus,
} from "../config";
import { RouteStatusDisplayNames } from "../../config";

const DeliveryDashboardListItem = ({
  itemData,
  deliveryDashboardTab = "routes",
  filteredShopCodes,
  setAccessModalVisible,
}) => {
  const { messages, Localise } = useContext(I18NContext);
  const { isDesktop, isMobile } = useContext(DeviceContext);
  const { values } = useFormikContext();
  const { startDate, endDate, isSingleShop } = values;
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const shopNames = UserProfileStorage.getAllShopNames();

  const isSmallScreen = !isDesktop;
  const config = UIConfig[deliveryDashboardTab];
  const {
    shopCodes: shopList = [],
    routeId = "",
    zoneId = "",
    date = "",
    displayName = "",
    totalOrders = 0,
    isDelayed = false,
    deliverBy = "",
    routeMemberCodes = [],
  } = itemData;

  const hasEditRouteAccess =
    every(routeMemberCodes, (each) => filteredShopCodes.includes(each)) ||
    false;

  //filter orders for selected dateRange
  const customRangeFilter = (date = "") => {
    return {
      section: "Date Range",
      title: "Custom Range",
      value: {
        startDate: date || moment(startDate).format("YYYY-MM-DD"),
        endDate: date || moment(endDate).format("YYYY-MM-DD"),
        dateType: "DELIVERY_DATE",
      },
    };
  };

  const RedirectToOrdersWithFilters = (itemFilters = []) => {
    const filters = [];
    const shops = [];

    //filter by ordersStatus/RouteId
    filters.push(...itemFilters);

    //skip filterByShop for "Not Assigned to Route" details
    const skipShopFilter = deliveryDashboardTab === "routes" && !routeId;

    //filter orders for selected shopCode(s)
    const filterByShop = !isSingleShop && !skipShopFilter;

    if (filterByShop) {
      shopList?.map((eachShopCode) => {
        shops.push({
          section: "Shop Codes",
          title: `${eachShopCode} ${shopNames[eachShopCode]}`,
          value: eachShopCode,
        });
      });
    }

    const name = "orders"; // Orders Listing Page
    dispatch(resetBasicInfo());
    dispatch(setCurrentPage(name));

    //redirect to Orders listing page with filters applied
    navigation.dispatch(
      CommonActions.navigate({
        name,
        params: {
          filters,
          shops,
          shopFilter: !!shops.length,
        },
      })
    );
  };

  return (
    <View
      fsClass="fs-unmask"
      style={{
        marginBottom: 20,
      }}
    >
      <View
        style={
          isSmallScreen && {
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.4,
            shadowRadius: 4,
            elevation: 3,
            borderWidth: 1,
            borderColor: "#FFFFFF44",
          }
        }
      >
        <View style={[tw(`flex ${!isMobile ? "flex-row" : "flex-col"}`)]}>
          <View style={[styles.routeRowContainer, { zIndex: 99 }]}>
            <TouchableOpacity
              style={{
                ...styles.eachBlock,
                backgroundColor:
                  deliveryDashboardTab === "routes"
                    ? routeDashboardColorCodes[
                        itemData["routeStatus"]?.toLowerCase()
                      ] || "#aadec6"
                    : backgroundColors.header,
                justifyContent: !isMobile ? "flex-start" : "center",
                paddingTop: 10,
              }}
              onPress={() => {
                if (deliveryDashboardTab === "routes" && displayName) {
                  if (routeId) {
                    if (hasEditRouteAccess) {
                      const redirectTo = "edit-route";
                      UserProfileStorage.setSelectedRouteId();
                      UserProfileStorage.setAutoRouteId(undefined);
                      UserProfileStorage.setRouteShopList([
                        ...routeMemberCodes,
                      ]);
                      dispatch(setCurrentPage(redirectTo));

                      navigation.dispatch(
                        CommonActions.navigate({
                          name: redirectTo,
                          params: {
                            ...(routeId && { routeId }),
                            memberCodes: routeMemberCodes.join(","),
                            routeDate: date,
                            accessByDriver: "false",
                          },
                        })
                      );
                    } else {
                      setAccessModalVisible(true);
                    }
                  } else {
                    // Not Assigned to Route main tile
                    const redirectTo = "create-route";
                    UserProfileStorage.setSelectedRouteId();
                    UserProfileStorage.setAutoRouteId(undefined);
                    UserProfileStorage.setRouteShopList([...shopList]);
                    dispatch(setCurrentPage(redirectTo));
                    const isPastDate = moment(startDate).isBefore(
                      moment().add(-1, "days")
                    );

                    navigation.dispatch(
                      CommonActions.navigate({
                        name: redirectTo,
                        params: {
                          memberCodes: shopList.join(","),
                          routeDate:
                            !startDate || isPastDate
                              ? moment().format("YYYY-MM-DD")
                              : startDate,
                        },
                      })
                    );
                  }
                } else if (deliveryDashboardTab === "zones" && displayName) {
                  const itemFilters = [];
                  itemFilters.push(customRangeFilter());
                  itemFilters.push({
                    section: "ZoneId",
                    title: displayName,
                    value: zoneId || undefined,
                  });
                  RedirectToOrdersWithFilters(itemFilters);
                }
              }}
            >
              <View
                style={{
                  display: "flex",
                  ...(!isMobile
                    ? { flexDirection: "column" }
                    : {
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }),
                }}
              >
                <Text
                  numberOfLines={2}
                  style={{
                    ...styles.countText,
                    marginBottom: zoneId ? 7 : 5,
                    fontSize: 15,
                    color: !["Draft", "Deleted"].includes(
                      RouteStatusDisplayNames[`${itemData["routeStatus"]}`]
                    )
                      ? colors.secondary
                      : colors.highlighter,
                  }}
                >
                  {Localise(messages, displayName)}
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    marginBottom: zoneId ? 2 : 1,
                    color: !["Draft", "Deleted"].includes(
                      RouteStatusDisplayNames[`${itemData["routeStatus"]}`]
                    )
                      ? colors.secondary
                      : colors.highlighter,
                  }}
                >
                  {Localise(
                    messages,
                    RouteStatusDisplayNames[`${itemData["routeStatus"]}`]
                  )}
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    marginBottom: zoneId ? 2 : 1,
                    color: !displayName
                      ? colors.highlighter
                      : !["Draft", "Deleted"].includes(
                          RouteStatusDisplayNames[`${itemData["routeStatus"]}`]
                        )
                      ? colors.secondary
                      : colors.highlighter,
                  }}
                >
                  {totalOrders}{" "}
                  {Localise(messages, totalOrders === 1 ? "Order" : "Orders")}
                </Text>
                <View
                  style={[
                    tw("flex flex-row justify-start items-center flex-wrap"),
                  ]}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      color:
                        RouteStatusDisplayNames[`${itemData["routeStatus"]}`] !=
                        "Draft"
                          ? colors.secondary
                          : colors.highlighter,
                    }}
                  >
                    {deliverBy}
                  </Text>
                  {deliverBy && isDelayed ? (
                    <Tooltip
                      text={Localise(
                        messages,
                        "Timed delivery alert: Initiate route or address it promptly to ensure on-time delivery"
                      )}
                      isSmallScreen={isSmallScreen}
                      height={60}
                    >
                      <Image
                        style={{
                          width: 16,
                          height: 16,
                          backgroundColor: colors.secondary,
                          borderRadius: "50%",
                          marginHorizontal: 5,
                        }}
                        resizeMode="cover"
                        source={require("static/assets/exclamation-alert-white-border.png")}
                      />
                    </Tooltip>
                  ) : null}
                </View>
              </View>
            </TouchableOpacity>
            {config.status.map((orderStatus, index) => {
              const iconName = orderStatusIcon[orderStatus];
              const { count = 0, hasRushOrders = false } =
                itemData[orderStatus] || {};
              return (
                <TouchableOpacity
                  key={index}
                  style={{
                    ...styles.eachBlock,
                    borderBottomWidth: 4,
                    borderBottomColor: orderStatusColor[orderStatus],
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    const itemFilters = [];
                    const statusFilter = {
                      section: "Order Status",
                      title: orderStatus.includes("Not Started")
                        ? "Accepted"
                        : orderStatus.includes("Delivery")
                        ? "Delivery"
                        : orderStatus,
                      value: `${
                        orderStatus.includes("Not Started")
                          ? "accepted"
                          : orderStatus.includes("Delivery")
                          ? "delivery"
                          : orderStatus?.replace(" ", "-")?.toLowerCase()
                      }__deliveryDashboard`,
                    };
                    if (deliveryDashboardTab === "routes" && displayName) {
                      itemFilters.push(
                        customRangeFilter(routeId ? date : undefined)
                      );
                      itemFilters.push(
                        {
                          section: "RouteId",
                          title: displayName,
                          value: routeId || undefined,
                        },
                        statusFilter
                      );
                    } else if (
                      deliveryDashboardTab === "zones" &&
                      displayName
                    ) {
                      itemFilters.push(customRangeFilter());
                      itemFilters.push(
                        {
                          section: "ZoneId",
                          title: displayName,
                          value: zoneId || undefined,
                        },
                        statusFilter
                      );
                    }
                    displayName && RedirectToOrdersWithFilters(itemFilters);
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      ...(!isMobile
                        ? { flexDirection: "column" }
                        : {
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }),
                    }}
                  >
                    <Text
                      style={{
                        ...styles.countText,
                        color: colors.highlighter,
                      }}
                    >
                      {count}
                    </Text>
                    <Text
                      style={{
                        ...styles.orderStatus,
                        color: colors.highlighter,
                      }}
                    >
                      {Localise(messages, orderStatus)}
                    </Text>
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      {IMAGES[iconName] ? (
                        <Image
                          style={{
                            width: 30,
                            height: 30,
                            ...styles.eachBlockIcon,
                          }}
                          source={IMAGES[iconName]}
                        />
                      ) : null}
                      {!!hasRushOrders && IMAGES["timed-delivery-progress"] ? (
                        <Image
                          style={{
                            width: 35,
                            height: 30,
                            marginLeft: 15,
                            ...styles.eachBlockIcon,
                          }}
                          source={IMAGES[`timed-delivery-progress`]}
                        />
                      ) : null}
                    </View>
                  </View>
                </TouchableOpacity>
              );
            })}
          </View>
          <View
            style={[
              tw("flex flex-row justify-around flex-wrap"),
              {
                borderColor: "#ebebeb",
                borderWidth: 1,
                backgroundColor: backgroundColors.contentBG,
                padding: 10,
                borderRadius: 6,
                height: 110,
              },
            ]}
          >
            {config.additional.map((eachCol, index) => {
              return (
                <View
                  key={index}
                  style={[
                    tw(
                      `flex flex-col flex-wrap ${
                        deliveryDashboardTab === "routes"
                          ? `justify-around`
                          : `justify-between py-1`
                      }`
                    ),
                    {
                      backgroundColor: backgroundColors.contentBG,
                      borderRadius: 6,
                    },
                  ]}
                >
                  {eachCol.map((orderType, index) => {
                    const { count = 0 } = get(
                      itemData,
                      `additional.${orderType}`,
                      {}
                    );
                    const iconName = orderStatusIcon[orderType];
                    const { imageWidth = 30, imageHeight = 30 } =
                      DIMENSIONS[iconName] || {};
                    const additionalDisplayStatus = displayStatus[orderType];
                    return (
                      <TouchableOpacity
                        key={index}
                        style={[
                          tw(
                            "flex flex-row justify-between items-center px-2 mx-2"
                          ),
                          additionalDisplayStatus
                            ? {
                                height: 35,
                                borderColor: "#c4c4c4",
                                borderWidth: 1,
                                backgroundColor: backgroundColors.secondary,
                                borderRadius: 5,
                                ...styles.additionalBlock,
                              }
                            : {
                                height: 35,
                                opacity: "0",
                                ...styles.additionalBlock,
                              },
                        ]}
                        onPress={() => {
                          const itemFilters = [];
                          const statusFilter = {
                            section:
                              additionalDisplayStatus === "Timed"
                                ? "Categories"
                                : "Additional Status",
                            title: additionalDisplayStatus + " " + "Orders",
                            value:
                              additionalDisplayStatus === "Timed"
                                ? "timedOrders"
                                : additionalDisplayStatus
                                    ?.replace(" ", "-")
                                    ?.toLowerCase(),
                          };
                          if (
                            deliveryDashboardTab === "routes" &&
                            displayName
                          ) {
                            itemFilters.push(
                              customRangeFilter(routeId ? date : undefined)
                            );
                            itemFilters.push(
                              {
                                section: "RouteId",
                                title: displayName,
                                value: routeId || undefined,
                              },
                              statusFilter
                            );
                          } else if (
                            deliveryDashboardTab === "zones" &&
                            displayName
                          ) {
                            itemFilters.push(customRangeFilter());
                            itemFilters.push(
                              {
                                section: "ZoneId",
                                title: displayName,
                                value: zoneId || undefined,
                              },
                              statusFilter
                            );
                          }
                          displayName &&
                            RedirectToOrdersWithFilters(itemFilters);
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 20,
                            fontFamily: fonts.fontFamily.bold,
                            fontWeight: "600",
                            color: colors.highlighter,
                          }}
                        >
                          {count}
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            color: colors.highlighter,
                          }}
                        >
                          {Localise(messages, additionalDisplayStatus)}
                        </Text>
                        {IMAGES[iconName] && (
                          <Image
                            style={{
                              width:
                                additionalDisplayStatus === "Timed"
                                  ? 35
                                  : imageWidth,
                              height: imageHeight,
                            }}
                            source={IMAGES[iconName]}
                          />
                        )}
                      </TouchableOpacity>
                    );
                  })}
                </View>
              );
            })}
          </View>
        </View>
      </View>
    </View>
  );
};

export default React.memo(DeliveryDashboardListItem);
