/* eslint-disable react/display-name */
/* eslint-disable react-hooks/rules-of-hooks */

import React, {
  useEffect,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  View,
  TouchableOpacity,
  ScrollView,
  Platform,
  Image,
} from "react-native";
import { Text } from "react-native-elements";
import { FieldArray } from "formik";
import {
  useNavigation,
  CommonActions,
  useRoute,
} from "@react-navigation/native";

import { useDispatch, useSelector } from "react-redux";

import tw from "tailwind-rn";

import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

import { fonts, backgroundColors, colors, theme, shapes } from "styles/theme";
import IMAGES from "static/assets/images";

import ScreenHeader from "components/containers/header/screen";
import {
  TabBar,
  ToasterHandler,
  CustomModal,
  PopupMenu,
} from "components/elements";
import { Form, SubmitButton } from "components/elements/forms";
import { formatPrice, formatPhoneForPayload } from "library/utils/formatter";

import ShopSelection from "./shop-selection";
import SideCar from "./side-car";
import CustomerProfileInfo from "./customer-profile-info";
import OrderPayment from "./order-payment";
import TerminalSessionScreen from "components/views/drawer/terminal-session";

import {
  validationSchema,
  initialVals,
  preloadInitialVals,
  basicPaymentInfo,
  subscriptionInitialVals,
} from "./config";

import {
  createOrderObject,
  editOrderObject,
  getPrintReceiptContent,
  getNoOfReceiptPrints,
  getProductObj,
  getPriceObj,
  updateFuneralMapping,
  isDeliveryDateModified,
} from "./helper";

import { keyMap } from "../order-details/helper";
import { isOrderItemTotalsMatching } from "./product-info/helper";

import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

import UserProfileStorage from "library/storage/userProfile";

import { request } from "library/utils/request";
import {
  ValidateAndCreateOrder,
  getError,
  voidPayments,
} from "library/utils/createOrder";

import useStateIfMounted from "library/utils/useStateIfMounted";
import Environment from "library/utils/environment";
import {
  Entitlements,
  isMHQNonCoreMember,
  isCoreMember,
  isCoreConnectMember,
} from "library/utils/entitlements";

import {
  TokenizeAndAuthorizePayment,
  sendCommandToPAXTerminalV1,
  TransactionTypes,
  initiateRefundThroughTerminal,
  authorizeGiftCardPayment,
  isOrderTypeWired,
} from "library/utils/payment-options";
import { processShopSettingsResponse } from "library/utils/shopSettings";

import {
  fetchAddons,
  setCurrentPage,
  resetCurrentProduct,
  setYOffSetScroll,
  setShowSessionInfo,
  setRecalcTaxAndFees,
} from "library/sagas/ongoing/global-data/slice";
import {
  selectShowSessionInfo,
  selectUserAction,
  selectState as selectGlobalState,
} from "library/sagas/ongoing/global-data/selector";
import {
  selectSelectedFuneralLogs,
  selectFuneralLogActualEvents,
} from "library/sagas/views/home/drawer/events/selector";
import {
  setCreateOrderShopCode,
  setActiveOrderItemTab,
  setPageInitialize,
  setNavParams,
  fetchGlobalFees,
  setOrderItemTabs,
  resetOrderItemTabs,
  setGiftCardsData,
  setRedeemedGcData,
  setPaymentDetails,
  saveDraftOrder,
  modifyDraftOrder,
  deleteDraftOrder,
  fetchDraftOrders,
  setOrderItemTabDetails,
  setShopCodeForDraftOrder,
  setDraftsToDelete,
  setEditProducts,
  setSubscriptionData,
  setSubscriptionCustomer,
  resetSubscriptionData,
} from "library/sagas/views/home/drawer/create-order/slice";
import {
  selectIsEditOrder,
  selectIsCopyOrder,
  selectOrderItemId,
  selectActualDeliveryMethod,
  selectOrderItemTabs,
  selectActiveItemTab,
  selectRedeemedGiftCardsData,
  selectCalculatingTax,
  selectPaymentDetails,
  selectDraftOrders,
  selectDraftsToDelete,
  selectIsSubscription,
  selectIsCreateSubscription,
  selectIsEditSubscription,
  selectIsCopySubscription,
  selectIsEditProducts,
  selectSubscriptionData,
  selectSubscriptionCustomer,
} from "library/sagas/views/home/drawer/create-order/selector";
import { setPageReset as resetOrderDetailsPage } from "library/sagas/ongoing/order-details/slice";
import { selectApiResponse } from "library/sagas/ongoing/order-details/selector";
import {
  setPageData as setCustomerData,
  setScrolltoSubscriptions,
} from "library/sagas/views/home/drawer/customer-directory/slice";
import { selectCustomerDetails } from "library/sagas/views/home/drawer/customer-directory/selector";

import {
  createTransaction,
  fetchAvailableSessions,
} from "library/sagas/session/slice";
import {
  selectSessionId,
  selectMemberId,
} from "library/sagas/session/selector";

import { Breadcrumb } from "components/containers/header/bread-crumb";
import { getCurrentPOSSettings } from "components/views/drawer/shop-settings/helper";
import Processing from "./processing";
import FormReloadCache from "library/storage/formReloadStorage";
import Logger from "library/utils/logger";
import { hasDraftOrderFeature } from "library/utils/featureAvailability";
import { setIsSidecarOpen } from "library/sagas/ongoing/order-details/slice";
import OpenSubscription from "./open-subscription";
import OpenQuickSaleArea from "./open-quick-sale-area";
import OpenAdditionalInfoArea from "./open-additional-info-area";
import AddNewOrderItem from "./add-new-order-item";
import OrderItem from "./order-item";
import { ORDERS } from "library/constants";

const CreateOrderScreen = (props) => {
  const dispatch = useDispatch();
  let orderItemIds = [];

  const showSessionInfo = useSelector(selectShowSessionInfo);
  const selectedFuneralEvents = useSelector(selectSelectedFuneralLogs);
  const availableFuneralEvents = useSelector(selectFuneralLogActualEvents);
  const orderDetailsResponse = useSelector(selectApiResponse);
  const redeemedGiftCardsData = useSelector(selectRedeemedGiftCardsData);
  const calculatingTax = useSelector(selectCalculatingTax);
  const customerDetails = useSelector(selectCustomerDetails);
  const draftOrders = useSelector(selectDraftOrders);
  const draftsToDelete = useSelector(selectDraftsToDelete);
  const currentTab = useSelector(selectActiveItemTab);
  const isEditOrder = useSelector(selectIsEditOrder);
  const isCopyOrder = useSelector(selectIsCopyOrder);
  const isSubscription = useSelector(selectIsSubscription);
  const isCreateSubscription = useSelector(selectIsCreateSubscription);
  const isEditSubscription = useSelector(selectIsEditSubscription);
  const isCopySubscription = useSelector(selectIsCopySubscription);
  const isEditProducts = useSelector(selectIsEditProducts);
  const editOrderItemId = useSelector(selectOrderItemId) || "";
  const editActualDeliveryMethod =
    useSelector(selectActualDeliveryMethod) || "";
  const tabNames = useSelector(selectOrderItemTabs);
  const selectedUserAction = useSelector(selectUserAction);
  const { status: orderPaymentStatus } = useSelector(selectPaymentDetails);
  const global = useSelector(selectGlobalState);
  const sessionId = useSelector(selectSessionId);
  const memberId = useSelector(selectMemberId);
  const subscriptionDetails = useSelector(selectSubscriptionData);
  const preloadSubscriptionCustomer = useSelector(selectSubscriptionCustomer);

  const { permissions } = React.useContext(AppSettingsContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop, isTablet } = useContext(DeviceContext);

  const isSmallScreen = !isDesktop;
  const buttonRef = useRef(null);
  const CreateEditOrderFormRef = useRef();
  const sendingMemberRef = useRef("");
  const terminalTxnCancellingRef = useRef(false);
  const houseAccountFormRef = useRef(null);
  const showRecalcTaxPopupRef = useRef(false);

  const navigation = useNavigation();
  const { setParams } = navigation;

  const { firstName: operator = "" } = UserProfileStorage.getUser();
  const shopNames = UserProfileStorage.getAllShopNames();

  const [showSideCar, setShowSideCar] = useStateIfMounted(
    isDesktop ? "additional_info" : ""
  );
  const [sideCarInfo, setSideCarInfo] = useStateIfMounted({});
  const [selectedFlorist, setSelectedFlorist] = useStateIfMounted({});
  const [selectedProduct, setSelectedProduct] = useStateIfMounted({});
  const [isFormSubmitting, setFormSubmitting] = useStateIfMounted(false);
  const [orderPositions, setOrderPositions] = useStateIfMounted([]);
  const [scrollEnabled, setScrollEnabled] = useStateIfMounted(false);
  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useStateIfMounted("");
  const [innerFormLoading, setInnerFormLoading] = useStateIfMounted(false);
  const [isGcAdded, setIsGcAdded] = useStateIfMounted([]);
  const [houseAccountCreditLimit, setHouseAccountCreditLimit] =
    useStateIfMounted("");
  const [authorizeGcPaymentMethod, setAuthorizeGcPaymentMethod] =
    useStateIfMounted(false);
  const [isGcAuthorizationSuccess, setIsGcAuthorizationSuccess] =
    useStateIfMounted(false);
  const [paymentOptions, setPaymentOptions] = useStateIfMounted([]);
  const [modalVisible, setModalVisibile] = useStateIfMounted(false);
  const [productModalVisible, setProductModalVisible] =
    useStateIfMounted(false);
  const [showDraftConfirmModal, setShowDraftConfirmModal] =
    useStateIfMounted(false);
  const [isSavingDraft, setIsSavingDraft] = useStateIfMounted(false);

  // Memoize the callbacks
  const memoizedSetOrderPositions = useCallback((positions) => {
    setOrderPositions(positions);
  }, []);

  const memoizedSetScrollEnabled = useCallback((value) => {
    setScrollEnabled(value);
  }, []);

  const memoizedSetSelectedFlorist = useCallback((florist) => {
    setSelectedFlorist(florist);
  }, []);

  const memoizedSetShowSideCar = useCallback((value) => {
    setShowSideCar(value);
  }, []);

  const memoizedSetProductModalVisible = useCallback((value) => {
    setProductModalVisible(value);
  }, []);

  const isRecalcTaxFeesPopupEnabled = Environment.get(
    "SHOW_RECALCULATE_TAX_FEES_POPUP",
    true
  );

  const hideModal = () => {
    if (showRecalcTaxPopupRef.current) {
      showRecalcTaxPopupRef.current = false;
      dispatch(setRecalcTaxAndFees(false));
      setModalVisibile(false);
    } else {
      setModalVisibile(false);
      setProductModalVisible(false);
    }
  };

  const {
    params: {
      action = "",
      smc = "",
      dm: actualDeliveryMethod = "",
      id = "",
      customerDetails: subscriptionCustomer = {},
      subscriptionData,
      type,
    } = {},
  } = useRoute();

  const {
    addons = [],
    addressVerificationInfo = {},
    isAutoFill,
    shopCode,
    createOrderShopCode,
  } = global;

  const modalContent = {
    content: (
      <Text
        style={[
          tw("p-4 text-center"),
          {
            fontSize: 15,
            color: colors.highlighter,
          },
        ]}
      >
        {Localise(
          messages,
          "Are you sure you want to remove this item from your order?"
        )}
      </Text>
    ),
    buttons: [
      { type: "secondary", title: Localise(messages, "Cancel") },
      { type: "primary", title: Localise(messages, "Remove") },
    ],
  };

  const gcModalContent = {
    content: (
      <Text
        testID={"gc_product_error"}
        style={[
          tw("p-4 text-center"),
          {
            fontSize: 15,
            color: colors.highlighter,
          },
        ]}
      >
        {Localise(
          messages,
          "Cannot add Product and Gift Card in the same order. Please create a new order to proceed."
        )}
      </Text>
    ),
    buttons: [{ type: "primary", title: Localise(messages, "Ok") }],
  };

  // Memoize memberCodes to prevent it from changing on every render
  const memberCodes = useMemo(() => {
    const codes = [];
    Object.keys(permissions).forEach((memberCode) => {
      if (Entitlements.CREATE_ORDER in permissions[memberCode]) {
        codes.push(memberCode);
      }
    });
    return codes;
  }, [permissions]); // Dependency array

  const selectedShopPermissions = get(permissions, memberCodes[0], {});

  const isStandAloneShop = memberCodes.length === 1;

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const sendingMemberShopCode =
    memberId && memberCodes.includes(memberId)
      ? memberId
      : createOrderShopCode || shopCode;

  const orderChannel =
    Platform.OS === "web" ? (isTablet ? "Tablet" : "Web") : "Mobile";

  const screenOneScrollViewRef = useRef();
  //const screenTwoScrollViewRef = useRef();
  const arrayHelpersRef = useRef(null);
  const orderItemRemoveIndex = useRef(0);

  const showSideCarArr = showSideCar ? showSideCar.split("-") : [];
  const sideCarKey = showSideCarArr[0];

  const formIKPath = `orderItems.${currentTab}`;
  const setSelectedRecord = useMemo(() => {
    return sideCarKey === "florist_selection"
      ? setSelectedFlorist
      : setSelectedProduct;
  }, [sideCarKey]);

  const isActionDraft = action === "draft";

  const orderDetails = get(orderDetailsResponse, `orderItems.0`, {});
  const orderType = get(orderDetailsResponse, `orderType`, "");
  const isWiredOrder = isOrderTypeWired(orderType);

  const isLocalOrder = (dm) =>
    ["FLORIST_DELIVERED", "STORE_PICKUP", "WALK_IN", "PHONE_OUT"].includes(dm);

  const {
    deliveryInfo: { deliveryMethod = "" } = {},
    multiProductEligible = true,
    hasSettlementError = false,
    orderSource = "",
  } = orderDetails;
  const isCoreConnectUser = isCoreConnectMember(sendingMemberShopCode);
  const canModifyOrder = isEditOrder
    ? ![
        "FLORIST_PARTNER",
        "MOL_FLORIST_DELIVERED",
        "FOL_FLORIST_DELIVERED",
      ].includes(deliveryMethod) && !isCoreConnectUser
    : true;

  const setSideCarOpen = useCallback((key, value = {}) => {
    setShowSideCar(key);
    setSideCarInfo(value);
  }, []);

  const setRouteParams = ({
    action = undefined,
    id = undefined,
    smc = undefined,
    dm = undefined,
    type = undefined,
  } = {}) => {
    const params = { action, id, smc, dm, type };
    dispatch(setNavParams(params));
    setParams(params);
  };

  const hasDraftFeature = hasDraftOrderFeature(permissions);

  useEffect(() => {
    !addons.length && dispatch(fetchAddons());
    dispatch(setPageInitialize(props.route));
    dispatch(setCurrentPage("create-order"));

    if (!(isEditOrder || isCopyOrder))
      dispatch(setCreateOrderShopCode(createOrderShopCode || shopCode));

    if (isElectronApp) {
      if (!sessionId) {
        const timer = setInterval(() => {
          const macAddress = document.getElementById("macAddress").value;

          if (macAddress) {
            clearInterval(timer);
            dispatch(
              fetchAvailableSessions({
                params: { macAddress: encodeURIComponent(macAddress) },
              })
            );
          }
        }, 500);
      }
    }

    if (!isEmpty(subscriptionCustomer)) {
      dispatch(setCustomerData({ customerDetails: subscriptionCustomer }));
      dispatch(setSubscriptionCustomer(subscriptionCustomer));
      setParams({ customerDetails: undefined });
    }

    if (!isEmpty(subscriptionData)) {
      dispatch(setSubscriptionData(subscriptionData));
      setParams({ subscriptionData: undefined });
    }

    return () => {
      setRouteParams();
      dispatch(setNavParams({ id: "", dm: "", smc: "", action: "", type: "" }));
      dispatch(setShowSessionInfo(false));
      dispatch(resetOrderItemTabs());
      dispatch(setShopCodeForDraftOrder(shopCode));
      dispatch(setEditProducts(false));
      dispatch(resetOrderDetailsPage());
      dispatch(resetSubscriptionData());

      // resetting in case of component unmount, If edit order is already set to navigation screen title then we need to reset it back.
      Platform.OS === "web" &&
        navigation.setOptions({
          title: "Create Order",
        });
    };
  }, []);

  useEffect(() => {
    if (orderPaymentStatus === "pending" && Platform.OS === "web") {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = ""; // Chrome requires a string to be returned
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [orderPaymentStatus]);

  // setting page/tab titles
  useEffect(() => {
    Platform.OS === "web" &&
      navigation.setOptions({
        title: isEditOrder
          ? "Edit Order"
          : isCreateSubscription || isCopySubscription
          ? "Create Subscription"
          : isEditSubscription
          ? "Edit Subscription"
          : "Create Order",
      });
  }, [
    isEditOrder,
    isCreateSubscription,
    isCopySubscription,
    isEditSubscription,
  ]);

  useEffect(() => {
    return () => {
      // unlocking the order - If user is navigating away from the edit order page.
      if (action === "edit" && isLocalOrder(actualDeliveryMethod)) {
        request("unlock-order", {
          recordId: id,
          deliveryMethod: actualDeliveryMethod,
        });
      }
    };
  }, []);

  useEffect(() => {
    if (isEditOrder) {
      if (isRecalcTaxFeesPopupEnabled) {
        if (
          ["TEL eFlorist", "Email Order"].includes(orderSource) &&
          selectedUserAction !== "refund"
        ) {
          setModalVisibile(true);
          showRecalcTaxPopupRef.current = true;
        }
      } else {
        dispatch(setRecalcTaxAndFees(true));
      }
    }
  }, [orderDetailsResponse]);

  const getUpdatedInitialValues = (initialValues = undefined) => {
    const initialFormValues = initialValues
      ? initialValues
      : initialVals({
          memberCodes,
          shopCode: sendingMemberShopCode,
          orderChannel,
          shopNames,
        });

    const selectedShopPermissions = get(permissions, memberCodes[0], {});
    const isPaymentEntitlementEnabled = selectedShopPermissions[
      Entitlements.CREATE_ORDER
    ]?.includes(Entitlements.CREATE_ORDER_PAGE.PAYMENT_SECTION);

    set(
      initialFormValues,
      "paymentDetails.paymentMethod.0.enablePayment",
      isPaymentEntitlementEnabled
    );

    //To preselect Payment Terminal option in case of Desktop app and single membercode
    if (isElectronApp && isStandAloneShop) {
      let paymentTerminalIndex = paymentOptions.findIndex(
        (paymentOption) => paymentOption.value === "PAYMENT_TERMINAL"
      );

      if (paymentTerminalIndex >= 0) {
        set(
          initialFormValues,
          "paymentDetails.paymentMethod.0.paymentMethodType",
          "PAYMENT_TERMINAL"
        );
      }

      return initialFormValues;
    } else {
      return initialFormValues;
    }
  };

  const showError = (error, formikBag, showToaster = true) => {
    const errorMessage = getError(error);
    setFormSubmitting(false);
    formikBag && formikBag.setSubmitting(false);
    showToaster && ToasterHandler("uh oh", Localise(messages, errorMessage));
  };

  const modifyOrder = (
    orderUpdates,
    formikBag,
    values,
    isHardwareTerminal = false
  ) => {
    const paymentStatus = get(values, "paymentDetails.paymentStatus", "");
    const amountDue = get(values, "paymentDetails.amountDue", "");
    const isPaymentPending = paymentStatus.toLowerCase() === "pending";

    if (isPaymentPending && amountDue === "0.00") {
      setFormSubmitting(false);
      formikBag.setSubmitting(false);
      ToasterHandler(
        "uh oh",
        Localise(messages, "Amount due should be greater than 0")
      );
    } else {
      request("modify-order", {
        recordId: editOrderItemId,
        deliveryMethod: editActualDeliveryMethod,
        orderUpdates,
        sourceMemberCode: smc,
      })
        .then((response) => {
          const {
            customerInfo: {
              firstName = "",
              lastName = "",
              isBusinessProfile,
              businessName = "",
            } = {},
            sendingMember,
            orderItems,
          } = values;

          updateFuneralMapping({
            sendingMember,
            orderItems,
            response: { orderId: editOrderItemId },
            selectedFuneralEvents,
            availableFuneralEvents,
            onError: () => {
              ToasterHandler(
                "uh oh",
                Localise(
                  messages,
                  "Failed to map or unmpa orders with Funeral log"
                )
              );
            },
          });
          if (sessionId && memberId === sendingMember) {
            const { isRefundDue, newTotalAmount } =
              values?.paymentDetails || {};

            const paymentInfo = get(
              values,
              "paymentDetails.paymentMethod.0",
              ""
            );
            const {
              paymentMethodType: paymentType,
              paymentMethodDetails: {
                tenderedAmount = "",
                changeDueAmount = "",
              },
            } = paymentInfo;

            const isCashOrCheck = paymentType === "CASH_OR_CHECK";
            let userNote = {
              payment: keyMap[paymentType],
              customerName: isBusinessProfile
                ? businessName
                : `${firstName} ${lastName}`,
            };
            if (isCashOrCheck) {
              userNote = {
                ...userNote,
                tenderedAmount,
                changeDueAmount,
              };
            }
            dispatch(
              createTransaction({
                params: {
                  amount: isRefundDue ? newTotalAmount * -1 : newTotalAmount,
                  type: isCashOrCheck ? "Cash" : "Non-Cash",
                  memberId: sessionId,
                  macAddress: document.getElementById("macAddress").value,
                  user: operator,
                  userNote: JSON.stringify(userNote),
                  orderId: editOrderItemId,
                },
                resolve: (resp) => {
                  console.log(
                    "Success - ",
                    `Successfully created ${paymentType} transaction`
                  );
                },
                reject: () => {
                  console.log("Error - ", "Failed to create transaction");
                },
              })
            );
          }
          formikBag.setSubmitting(false);
          setFormSubmitting(false);
          dispatch(setRedeemedGcData([]));

          isPaymentPending && amountDue > 0
            ? ToasterHandler("hooray", Localise(messages, "Payment Successful"))
            : ToasterHandler(
                "hooray",
                Localise(
                  messages,
                  `Order modified successfully.${
                    Platform.OS === "web"
                      ? "\nFilters may need updating for order to appear."
                      : ""
                  }`
                )
              );

          redirectToOrders();
        })
        .catch((error) => {
          if (error === "NO_LOCK_FOUND_DURING_MODIFY_ORDER") {
            request("lock-order", {
              recordId: editOrderItemId,
              deliveryMethod: editActualDeliveryMethod,
              sourceMemberCode: smc,
            })
              .then(() => {
                modifyOrder(
                  orderUpdates,
                  formikBag,
                  values,
                  isHardwareTerminal
                );
              })
              .catch(() => {
                let errorMessage =
                  "Order modify request failed, please try again.";

                setFormSubmitting(false);
                formikBag.setSubmitting(false);
                ToasterHandler("uh oh", Localise(messages, errorMessage));
              });
          } else {
            if (
              error === "CATCH ALL ERROR" ||
              error === "Gateway Timeout" ||
              error === "Gateway Timeout, exceeded the processing time."
            ) {
              redeemedGiftCardsData?.length > 0 &&
                authorizeGiftCardPayment({
                  values,
                  redeemedGiftCardsData,
                  action: "REINSTATE",
                });
            }

            // We are initiating refunds always (if it is terminal payment) as we are in a catch block that indicates order modification is not successful
            if (isHardwareTerminal) {
              const authorizationDetails = get(
                values,
                "paymentDetails.paymentMethod.0.paymentMethodDetails.authorizationDetails",
                []
              );

              const merchantReferenceId =
                authorizationDetails.find(
                  (obj) => obj.name === "merchantReferenceId"
                )?.value || "";

              const transactionId =
                authorizationDetails.find(
                  (obj) => obj.name === "transactionDetailsId"
                )?.value || "";

              initiateRefundThroughTerminal({
                sendingMember: values.sendingMember,
                merchantReferenceId,
                transactionId,
                resolve: ({ message }) => {
                  setFormSubmitting(false);
                  formikBag.setSubmitting(false);

                  ToasterHandler("uh oh", Localise(messages, message));
                },
              });
            } else {
              let errorMessage =
                "Order modify request failed, please try again.";
              if (error == "No modifications are made to the order") {
                errorMessage = "No modifications were made to the order";
              }
              setFormSubmitting(false);
              formikBag.setSubmitting(false);
              ToasterHandler("uh oh", Localise(messages, errorMessage));
            }
          }
        });
    }
  };

  const updateOrder = (createOrderReqObj, formikBag, values, tokenId = "") => {
    const lineAmoutsArr = get(values, "orderItems.0.lineItems", []);
    const originalValues = get(values, `orderItems.0.oldValues`, {});
    const oldLineItems = get(
      orderDetailsResponse,
      `orderItems.0.lineItems`,
      []
    );

    const allowModify = isWiredOrder
      ? isOrderItemTotalsMatching({
          values: lineAmoutsArr,
          oldValues: oldLineItems,
        })
      : true;

    if (allowModify) {
      setFormSubmitting(true);

      const editOrderReqObject = editOrderObject(
        redeemedGiftCardsData,
        createOrderReqObj,
        orderDetailsResponse,
        values,
        tokenId
      );

      const { isHardwareTerminal = false } = get(
        createOrderReqObj,
        "paymentDetails.paymentMethod.0",
        {}
      );

      modifyOrder(editOrderReqObject, formikBag, values, isHardwareTerminal);
    } else {
      formikBag && formikBag.setSubmitting(false);
      setFormSubmitting(false);
      ToasterHandler(
        "uh oh",
        Localise(
          messages,
          `Amounts should be equal to $${formatPrice(originalValues?.subTotal)}`
        )
      );
    }
  };

  const redirectToOrders = () => {
    // Resetting the url params to prevent pre-populating the old copy order details.
    setParams({
      action: undefined,
      id: undefined,
      smc: undefined,
      dm: undefined,
      type: undefined,
    });
    setRouteParams();

    if (isEditOrder) {
      dispatch(setIsSidecarOpen(true));
    }
    navigation.dispatch(
      CommonActions.navigate({
        name: "orders",
      })
    );
    dispatch(setCurrentPage("orders"));
  };

  // Modify order functionality
  const saveOrderModifications = (values, formikBag, createOrderReqObj) => {
    const { savePayment, paymentMethodType = "" } = get(
      values,
      "paymentDetails.paymentMethod.0",
      {}
    );
    setIsGcAdded(redeemedGiftCardsData);

    paymentMethodType !== "CREDIT_CARD" &&
      redeemedGiftCardsData?.length === 0 &&
      setFormSubmitting(true);

    const merchantReferenceId = get(
      orderDetailsResponse,
      "paymentDetails.paymentMethod.0.merchantReferenceId",
      ""
    );

    const successCallback = (tokenId) => {
      let gcRedeemedAmt = 0;
      if (redeemedGiftCardsData?.length > 0) {
        for (let i = 0; i < redeemedGiftCardsData?.length; i++) {
          gcRedeemedAmt += Number(redeemedGiftCardsData[i]?.gcAmount);
        }
      }
      const orderTotal =
        Number(values?.paymentDetails?.amountDue) - gcRedeemedAmt;
      const amount = [
        {
          name: "amount",
          value: formatPrice(orderTotal),
        },
      ];
      set(
        createOrderReqObj,
        "paymentDetails.paymentMethod.0.paymentMethodDetails.authorizationDetails",
        amount
      );
      if (savePayment || paymentMethodType === "SAVED_CARD") {
        set(
          createOrderReqObj,
          "paymentDetails.paymentMethod.0.paymentMethodDetails.tokenId",
          tokenId
        );
      }
      setCurrentPaymentMethod("");
      setFormSubmitting(true);
      updateOrder(createOrderReqObj, formikBag, values, tokenId);
      setIsGcAuthorizationSuccess(false);
    };

    const errorCallback = (error, isFormikError) => {
      setCurrentPaymentMethod("");
      if (isFormikError) {
        formikBag.setErrors(error);
        setFormSubmitting(false);
        formikBag.setSubmitting(false);
      } else {
        showError(
          error || "Payment failed to process, please try again.",
          formikBag
        );
      }
      setIsGcAuthorizationSuccess(false);
      redeemedGiftCardsData?.length > 0 &&
        authorizeGiftCardPayment({
          values,
          redeemedGiftCardsData,
          action: "REINSTATE",
        });
    };

    const onEditGcSuccessCallback = (orderTotal) => {
      setAuthorizeGcPaymentMethod(false);
      if (paymentMethodType === "PAYMENT_TERMINAL") {
        setIsGcAuthorizationSuccess(true);
        setFormSubmitting(true);
        setCurrentPaymentMethod("PAYMENT_TERMINAL");
        handleTerminalPayment();
      } else if (
        !(
          paymentMethodType === "CREDIT_CARD" ||
          paymentMethodType === "SAVED_CARD"
        )
      ) {
        setFormSubmitting(true);
        updateOrder(createOrderReqObj, formikBag, values);
      } else {
        setIsGcAuthorizationSuccess(true);
        TokenizeAndAuthorizePayment(
          values,
          merchantReferenceId,
          values.sendingMember,
          orderTotal,
          successCallback,
          errorCallback,
          true
        );
      }
    };

    const onEditGcErrorCallback = (error, isFormikError) => {
      setCurrentPaymentMethod("");
      setAuthorizeGcPaymentMethod(false);
      if (isFormikError) {
        formikBag.setErrors(error);
        setFormSubmitting(false);
        formikBag.setSubmitting(false);
      } else {
        showError(
          error || "Payment failed to process, please try again.",
          formikBag
        );
      }
    };
    // this method is used to handle terminal payment in case of edit order,
    const handleTerminalPayment = () => {
      let gcRedeemedAmt = 0;
      if (redeemedGiftCardsData?.length > 0) {
        for (let i = 0; i < redeemedGiftCardsData?.length; i++) {
          gcRedeemedAmt += Number(redeemedGiftCardsData[i]?.gcAmount);
        }
      }

      const amountDue = get(values, "paymentDetails.amountDue", 0);
      const isRefundDue = get(values, "paymentDetails.isRefundDue");
      const amountDueTotal = Number(amountDue) - gcRedeemedAmt;

      if (
        (isRefundDue !== undefined && isRefundDue) ||
        parseFloat(amountDueTotal) === 0
      ) {
        updateOrder(createOrderReqObj, formikBag, values);
      } else {
        setCurrentPaymentMethod(paymentMethodType);

        const isSavePaymentSelected = get(
          values,
          "paymentDetails.paymentMethod.0.savePayment",
          false
        );

        sendCommandToPAXTerminalV1({
          transactionType: TransactionTypes.AUTHORIZATION,
          sendingMember: values.sendingMember,
          merchantReferenceId,
          amount: amountDueTotal,
          requestMultiUseToken: isSavePaymentSelected,
          callback: ({ terminalResponse, paymentMethodDetails = {} }) => {
            setCurrentPaymentMethod("");

            if (terminalResponse.ResponseCode === "00") {
              paymentMethodDetails = {
                ...paymentMethodDetails,
                authPerformed: true,
              };

              // Setting paymentMethodDetails in values also in order to access them while initiating void when order modification failed and returned 412
              set(
                values,
                "paymentDetails.paymentMethod.0.paymentMethodDetails",
                paymentMethodDetails
              );

              set(
                createOrderReqObj,
                "paymentDetails.paymentMethod.0.paymentMethodDetails",
                paymentMethodDetails
              );

              set(
                createOrderReqObj,
                "paymentDetails.paymentMethod.0.merchantReferenceId",
                merchantReferenceId
              );

              updateOrder(
                createOrderReqObj,
                formikBag,
                values,
                terminalResponse?.Token || ""
              );
            } else {
              showError(terminalResponse.ResponseText, formikBag);
              // Incase of terminal error then reinstate the gift card.
              redeemedGiftCardsData?.length > 0 &&
                authorizeGiftCardPayment({
                  values: createOrderReqObj,
                  redeemedGiftCardsData,
                  action: "REINSTATE",
                });
            }
          },
          cancelBtnHandler: ({ txnStatus }) => {
            setCurrentPaymentMethod(txnStatus);
          },
        });
      }
    };

    const hasGCApplied = redeemedGiftCardsData?.length !== 0;
    const hasTerminalPayment = paymentMethodType === "PAYMENT_TERMINAL";

    if (hasGCApplied) {
      setAuthorizeGcPaymentMethod(true);
      authorizeGiftCardPayment({
        values,
        redeemedGiftCardsData,
        onEditGcSuccessCallback,
        onEditGcErrorCallback,
        action: "REDEEMED",
        isEditOrder,
      });
    } else if (hasTerminalPayment) {
      handleTerminalPayment();
    } else if (["CREDIT_CARD", "SAVED_CARD"].includes(paymentMethodType)) {
      setCurrentPaymentMethod(paymentMethodType);

      const orderTotal =
        createOrderReqObj.orderAmounts.find(
          (obj) => obj.name === "amountChargedToCustomer"
        )?.value || 0;

      TokenizeAndAuthorizePayment(
        values,
        merchantReferenceId,
        values.sendingMember,
        orderTotal,
        successCallback,
        errorCallback,
        true
      );
    } else {
      updateOrder(createOrderReqObj, formikBag, values);
    }
  };

  const handleClick = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: "orders",
        params: {
          orderIds: orderItemIds.join(),
        },
      })
    );
    orderItemIds = [];
  };

  const createOrderSuccessCallback = (
    response,
    formikBag,
    createOrderReqObj,
    orderItems
  ) => {
    if (response) {
      const isSubscriptionResponse =
        Object.keys(response).includes("subscription");
      orderItemIds = response?.orderItems?.map((item) => item?.orderItemId);
      setValidationOnChange(false);
      setFormSubmitting(false);
      formikBag.setSubmitting(false);
      dispatch(setShopCodeForDraftOrder(shopCode));
      if (isActionDraft) {
        dispatch(fetchDraftOrders());
      }

      // Resetting the url params to prevent pre-populating the old copy order details.
      setRouteParams();

      dispatch(resetCurrentProduct());
      formikBag.resetForm(getUpdatedInitialValues());

      dispatch(resetOrderItemTabs());
      dispatch(setRedeemedGcData([]));
      dispatch(setPaymentDetails({ status: "", values: {} }));
      isSubscriptionResponse && dispatch(resetSubscriptionData());

      setScrollEnabled(true);
      setOrderPositions([0]);

      !isSubscriptionResponse &&
        updateFuneralMapping({
          sendingMember: createOrderReqObj.sendingMember,
          orderItems,
          response,
          selectedFuneralEvents,
          availableFuneralEvents,
          onError: () => {
            ToasterHandler(
              "uh oh",
              Localise(messages, "Failed to map order with Funeral log")
            );
          },
        });

      if (isElectronApp) {
        const {
          customerInfo: {
            firstName = "",
            lastName = "",
            isBusinessProfile,
            businessName = "",
          } = {},
        } = createOrderReqObj;

        const { orderAmounts } = isSubscriptionResponse
          ? response?.subscription?.orderTemplate
          : response;
        const memberCode = createOrderReqObj.sendingMember;

        if (sessionId && memberId === memberCode) {
          const amountChargedToCustomer =
            orderAmounts?.find((obj) => obj.name === "amountChargedToCustomer")
              ?.value || 0;

          const paymentInfo = get(
            isSubscriptionResponse
              ? response?.subscription?.orderTemplate
              : response,
            "paymentDetails.paymentMethod.0",
            ""
          );

          const {
            paymentMethodType: paymentType,
            paymentMethodDetails: { tenderedAmount = "", changeDueAmount = "" },
          } = paymentInfo;

          const isCashOrCheck = paymentType === "CASH_OR_CHECK";
          let userNote = {
            payment: keyMap[paymentType],
            customerName: isBusinessProfile
              ? businessName
              : `${firstName} ${lastName}`,
          };
          if (isCashOrCheck) {
            userNote = {
              ...userNote,
              tenderedAmount,
              changeDueAmount,
            };
          }

          // For Electron app, handle print if necessary (only for non-subscription)
          if (!isSubscriptionResponse) {
            const content = getPrintReceiptContent(response, createOrderReqObj);
            const noOfPrints = getNoOfReceiptPrints(response);
            const printContent =
              noOfPrints === 1 ? [content] : [content, content];

            const { ipcRenderer } = window.require("electron");
            ipcRenderer.send(
              "printOrderInfo",
              noOfPrints,
              printContent,
              isCashOrCheck
            );
          }

          dispatch(
            createTransaction({
              params: {
                amount: amountChargedToCustomer,
                type: isCashOrCheck ? "Cash" : "Non-Cash",
                memberId: memberCode,
                macAddress: document.getElementById("macAddress").value,
                user: operator,
                userNote: JSON.stringify(userNote),
                orderId: isSubscriptionResponse
                  ? response?.subscription?.id
                  : response?.orderId,
              },
              resolve: (resp) => {
                console.log(
                  "Success - ",
                  `Successfully created ${paymentType} transaction`
                );
              },
              reject: () => {
                console.log("Error - ", "Failed to create transaction");
              },
            })
          );
        }
      }

      let deliveryDateModified = false;
      if (subscriptionDetails?.isModify) {
        deliveryDateModified = isDeliveryDateModified(
          formInitialValues.orderItems,
          orderItems
        );
      }

      ToasterHandler(
        "nice",
        Localise(
          messages,
          isSubscriptionResponse
            ? subscriptionDetails?.isModify
              ? deliveryDateModified
                ? "Your subscription was modified successfully. To avoid duplicates, consider canceling the created order for old delivery date."
                : "Your subscription has been modified successfully"
              : "Your subscription was submitted"
            : "Your order was submitted"
        ),
        {
          showLink: false, // hiding this feature temporarily MSOL-18042
          handleOnClickEvent: handleClick,
          linkText: "Open your order(s)",
        }
      );
      if (isSubscriptionResponse) {
        dispatch(setCurrentPage("customer-directory"));
        const storeOrigin = get(
          createOrderReqObj,
          "customerInfo.storeOrigin",
          ""
        );
        dispatch(setScrolltoSubscriptions(true));
        navigation.dispatch(
          CommonActions.navigate({
            name: "customer-directory",
            params: {
              customerId: response?.subscription?.customerId || "",
              storeOrigin,
            },
          })
        );
      }
    } else {
      showError(
        `Failed to ${isEditSubscription ? "modify" : "create"} the ${
          isSubscription ? "subscription" : "order"
        }. Please try again.`,
        formikBag
      );
    }
  };

  const createOrderErrorCallback = ({
    error,
    formikBag,
    createOrderReqObj,
    doRefund = false,
  }) => {
    if (
      error === "Gateway Timeout" ||
      error === "Gateway Timeout, exceeded the processing time."
    ) {
      redeemedGiftCardsData?.length > 0 &&
        authorizeGiftCardPayment({
          values: createOrderReqObj,
          redeemedGiftCardsData,
          action: "REINSTATE",
        });
    }
    const paymentMethodInfo = get(
      createOrderReqObj,
      "paymentDetails.paymentMethod",
      []
    );
    const TerminalProcessedPaymentIndices = [];
    !isEmpty(paymentMethodInfo) &&
      paymentMethodInfo.map((eachPayment, index) => {
        if (
          ["CREDIT_CARD"].includes(eachPayment.paymentMethodType) &&
          eachPayment?.isHardwareTerminal
        ) {
          TerminalProcessedPaymentIndices.push(index);
        }
      });
    // We are initiating refunds always (if it is terminal payment) as we are in a catch block that indicates order creation is not successful
    if (TerminalProcessedPaymentIndices.length > 0 && doRefund) {
      const voidTerminalPayments = ({ index, processingIndex }) => {
        const authorizationDetails = get(
          paymentMethodInfo[processingIndex],
          "paymentMethodDetails.authorizationDetails",
          []
        );

        const merchantReferenceId =
          authorizationDetails.find((obj) => obj.name === "merchantReferenceId")
            ?.value || "";

        const transactionId =
          authorizationDetails.find(
            (obj) => obj.name === "transactionDetailsId"
          )?.value || "";

        initiateRefundThroughTerminal({
          sendingMember: createOrderReqObj.sendingMember,
          merchantReferenceId,
          transactionId,
          resolve: ({ message }) => {
            setFormSubmitting(false);
            formikBag.setSubmitting(false);
            formikBag.setFieldValue(
              `paymentDetails.paymentMethod.${processingIndex}.paymentProcessed`,
              false
            );
            formikBag.setFieldValue(
              `paymentDetails.paymentMethod.${processingIndex}.paymentFailed`,
              false
            );
            ToasterHandler("uh oh", Localise(messages, message));
            if (index < TerminalProcessedPaymentIndices?.length - 1) {
              voidTerminalPayments({
                index: index + 1,
                processingIndex: TerminalProcessedPaymentIndices[index + 1],
              });
            }
          },
        });
      };
      voidTerminalPayments({
        index: 0,
        processingIndex: TerminalProcessedPaymentIndices[0],
      });
    } else {
      showError(error, formikBag);
    }
  };

  const validateAndSubmitOrder = async (values, formikBag) => {
    if (formikBag.isSubmitting || isFormSubmitting) return;
    if (values.phone) formatPhoneForPayload(values.phone);
    if (get(values, "showError", false)) {
      formikBag.setSubmitting(false);
      return;
    }
    if (isActionDraft) {
      values.draftOrderID = id;
    }

    setIsGcAdded(redeemedGiftCardsData);

    const hasCreditCardPayment = get(
      values,
      "paymentDetails.paymentMethod",
      []
    )?.some((eachPayment) => {
      const { paymentMethodType } = eachPayment;
      return ["CREDIT_CARD", "SAVED_CARD"].includes(paymentMethodType);
    });

    !hasCreditCardPayment &&
      redeemedGiftCardsData?.length === 0 &&
      setFormSubmitting(true);

    const memberTimezone = UserProfileStorage.getShopTimeZone(
      values.sendingMember || memberCodes[0]
    );

    const floristTechnology = UserProfileStorage.getFloristTechnology(
      values.sendingMember || memberCodes[0]
    );

    const createOrderReqObj = await createOrderObject({
      memberCodes,
      orderValues: values,
      addressVerificationInfo,
      memberTimezone,
      floristTechnology,
      redeemedGiftCardsData,
      allowMultipleProducts: !isEditOrder ? true : multiProductEligible,
      supportEdit: isEditOrder,
      isSubscriptionPage: isSubscription,
    });

    if (!isEditOrder) {
      ValidateAndCreateOrder({
        values,
        createOrderReqObj,
        redeemedGiftCardsData,
        setCurrentPaymentMethod,
        formikBag,
        setAuthorizeGcPaymentMethod,
        showError,
        createOrderSuccessCallback: (response, formikBag) => {
          createOrderSuccessCallback(
            response,
            formikBag,
            createOrderReqObj,
            values.orderItems
          );
        },
        createOrderErrorCallback: ({ error, formikBag, doRefund = false }) => {
          createOrderErrorCallback({
            error,
            formikBag,
            createOrderReqObj,
            doRefund,
          });
        },
        setFormSubmitting,
        setIsGcAuthorizationSuccess,
        isEditOrder,
        setPaymentStatusDetails: (values) => {
          dispatch(setPaymentDetails({ status: "pending", values }));
        },
        dispatch,
        isSubscription,
        subscriptionData: subscriptionDetails,
      });
    } else {
      saveOrderModifications(values, formikBag, createOrderReqObj);
    }
  };

  const removeTabs = () => {
    const key = orderItemRemoveIndex.current;
    const updatedTabs = tabNames
      .filter((tab) => tab.key !== key)
      .map((eachTab, index) => {
        return {
          ...eachTab,
          key: index,
          title: eachTab.defaultTitle ? `Order ${index + 1}` : eachTab.title,
        };
      });
    dispatch(setOrderItemTabs(updatedTabs));
    dispatch(setActiveOrderItemTab(updatedTabs.length - 1));

    //Removing item from the formik & view
    arrayHelpersRef.current.remove(key);
    setModalVisibile(false);
    isDesktop && setSideCarOpen("additional_info");
  };

  const waitMessage = terminalTxnCancellingRef.current
    ? "Canceling transaction."
    : isSubscription
    ? subscriptionDetails?.isModify
      ? "We are saving your edits. Just a moment."
      : "Creating your subscription. Just a moment."
    : isEditOrder
    ? "We are saving your edits. Just a moment."
    : isSavingDraft
    ? isActionDraft
      ? ORDERS.DRAFT_MODIFYING_MESSAGE
      : ORDERS.DRAFT_CREATING_MESSAGE
    : "Creating your order(s). Just a moment.";

  const formInitialValues = useMemo(() => {
    const orderDetails = isActionDraft
      ? draftOrders[smc]?.find((draft) => draft.draftOrderID === id)?.order
      : orderDetailsResponse;

    if (isActionDraft) {
      //Resetting orderItemTabs when switching between single draft and multi draft.
      if (tabNames.length > 1) {
        dispatch(resetOrderItemTabs());
      }
      //To pre-populate the multi-order draft order items
      if (orderDetails?.orderItems?.length > 1) {
        // Initializing the OrderItemTabs in case of multi draft order items
        dispatch(
          setOrderItemTabDetails({
            Localise,
            messages,
            orderItems: orderDetails?.orderItems,
          })
        );
      }
    }
    // resetting the validations on change of initial values
    setValidationOnChange(false);
    const shouldPreload =
      ["copy", "edit", "draft"].includes(action) && !isEmpty(orderDetails);
    return {
      ...(["create", "edit", "copy"].includes(action) && type === "subscription"
        ? subscriptionInitialVals({
            memberCodes,
            shopCode,
            orderChannel,
            shopNames,
            permissions,
            customerDetails:
              subscriptionCustomer || preloadSubscriptionCustomer,
            subscriptionDetails: subscriptionData || subscriptionDetails,
            global,
          })
        : shouldPreload
        ? preloadInitialVals({
            memberCodes,
            orderChannel,
            shopNames,
            preloadOrderDetails: orderDetails,
            global,
            action,
            type,
            permissions,
            shopCode,
          })
        : initialVals({
            memberCodes,
            shopCode: sendingMemberShopCode,
            orderChannel,
            shopNames,
          })),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, orderDetailsResponse, sendingMemberShopCode, id]); // Empty dependency array means it will only recompute when the component mounts.

  const isSplitPaymentEnabled = Environment.get("ENABLE_SPLIT_PAYMENT", false);

  const enableSplitPayment =
    isSplitPaymentEnabled &&
    !isEditOrder && // not supporting split payments for Edit order
    selectedShopPermissions[Entitlements.CREATE_ORDER]?.includes(
      Entitlements.CREATE_ORDER_PAGE.SPLIT_PAYMENT
    ) &&
    !isSubscription;

  const saveDraftHandler = async (formikBag) => {
    setIsSavingDraft(true);
    const { values, resetForm, setValues, setErrors } = formikBag;
    const memberTimezone = UserProfileStorage.getShopTimeZone(
      values.sendingMember || memberCodes[0]
    );
    const floristTechnology = UserProfileStorage.getFloristTechnology(
      values.sendingMember || memberCodes[0]
    );
    const { paymentDetails, ...rest } = values;

    const createDraftReqObj = await createOrderObject({
      memberCodes,
      orderValues: rest,
      addressVerificationInfo,
      memberTimezone,
      floristTechnology,
      isDraftOrder: true,
    });

    let params = createDraftReqObj;
    if (isActionDraft) {
      params.draftOrderID = id;
    }

    const updatedInitialValues = getUpdatedInitialValues();
    const handleSuccess = (message) => {
      dispatch(setShopCodeForDraftOrder(shopCode));
      ToasterHandler("success", Localise(messages, message));
      setIsSavingDraft(false);
      if (isCopyOrder || isActionDraft) {
        setRouteParams();
      }
      setTimeout(() => {
        dispatch(resetCurrentProduct());
        resetForm(updatedInitialValues);
        dispatch(resetOrderItemTabs());
        setValues(updatedInitialValues);
        setScrollEnabled(true);
        setOrderPositions([0]);
        setValidationOnChange(false);
        setErrors({});
      }, 20);
    };

    const handleError = (message) => {
      setIsSavingDraft(false);
      ToasterHandler("uh oh", Localise(messages, message));
    };

    const dispatchAction = isActionDraft ? modifyDraftOrder : saveDraftOrder;
    dispatch(
      dispatchAction({
        params,
        resolve: () =>
          handleSuccess(
            isActionDraft
              ? ORDERS.DRAFT_MODIFY_SUCCESS_MESSAGE
              : ORDERS.DRAFT_CREATE_SUCCESS_MESSAGE
          ),
        reject: () =>
          handleError(
            isActionDraft
              ? ORDERS.DRAFT_MODIFY_FAILURE_MESSAGE
              : ORDERS.DRAFT_CREATE_FAILURE_MESSAGE
          ),
      })
    );
  };

  const deleteDraftHandler = async (formikBag) => {
    const {
      resetForm,
      values: { sendingMember },
    } = formikBag;
    dispatch(
      deleteDraftOrder({
        params: {
          draftOrderID: id,
          sendingMember,
        },
        resolve: (response) => {
          dispatch(
            setDraftsToDelete(
              draftsToDelete.filter((draftOrderID) => id !== draftOrderID)
            )
          );
          ToasterHandler(
            "success",
            Localise(
              messages,
              response?.message
                ? response?.message
                : ORDERS.DRAFT_DELETE_SUCCESS_MESSAGE
            )
          );
          dispatch(resetOrderItemTabs());
          setRouteParams();
          resetForm();
          setScrollEnabled(true);
          setOrderPositions([0]);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(messages, ORDERS.DRAFT_DELETE_FAILURE_MESSAGE)
          );
        },
      })
    );
  };

  const taxModalContent = {
    content: (
      <Text style={tw("p-4")}>
        {Localise(messages, "Would you like to recalculate the fees & taxes?")}
      </Text>
    ),
    buttons: [
      {
        type: "primary",
        title: Localise(messages, "Yes"),
      },
      { type: "secondary", title: Localise(messages, "No") },
    ],
  };

  const recalcTax = () => {
    dispatch(setRecalcTaxAndFees(true));
    showRecalcTaxPopupRef.current = false;
    setModalVisibile(false);
  };

  const primaryHandlerAction = () => {
    if (showRecalcTaxPopupRef.current) {
      recalcTax();
    } else {
      removeTabs();
    }
  };

  const customModalContent = showRecalcTaxPopupRef.current
    ? taxModalContent
    : modalContent;

  const screenTitle = useMemo(() => {
    if (isEditOrder) return Localise(messages, "Edit Order");
    if (isCreateSubscription || isCopySubscription)
      return Localise(messages, "Create Subscription");
    if (isEditSubscription) return Localise(messages, "Edit Subscription");
    return Localise(messages, "Create Order");
  }, [
    isEditOrder,
    isCreateSubscription,
    isEditSubscription,
    isCopySubscription,
    messages,
    Localise,
  ]);

  return (
    <>
      {modalVisible && (
        <CustomModal
          modalVisible={modalVisible}
          modalContent={customModalContent}
          primaryhandler={primaryHandlerAction}
          secondaryhandler={hideModal}
          contentStyle={[
            tw("border border-black p-4"),
            { backgroundColor: "white" },
          ]}
          modalStyle={
            Platform.OS !== "web" && {
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor: "#00000070",
              color: "#FFFFFF",
            }
          }
          isReduceBtnSpacing={true}
        ></CustomModal>
      )}
      {productModalVisible && (
        // Error popup for cross combination in an order(i.e, gift-card and products)
        <CustomModal
          modalVisible={productModalVisible}
          modalContent={gcModalContent}
          // primaryhandler={removeTabs}
          primaryhandler={hideModal}
          contentStyle={[
            tw("border border-black p-4"),
            { backgroundColor: "white" },
          ]}
          modalStyle={
            Platform.OS !== "web" && {
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor: "#00000070",
              color: "#FFFFFF",
            }
          }
          isReduceBtnSpacing={true}
        ></CustomModal>
      )}
      <View style={{ height: "100%" }} fsClass="fs-unmask">
        <ScreenHeader
          title={screenTitle}
          settingsRoute={{
            page: "shop-settings",
            screen: "order-settings",
          }}
          sideCarIdentifier={showSideCar}
          setSideCarIdentifier={memoizedSetShowSideCar}
        />

        <View
          style={[
            tw("flex flex-row w-full h-full"),
            {
              backgroundColor:
                showSideCar && isSmallScreen
                  ? backgroundColors.sideCar
                  : backgroundColors.contentBG,
            },
          ]}
        >
          <View
            style={[
              { width: "100%" },
              isSmallScreen ? { paddingBottom: 100 } : { paddingBottom: 25 },
            ]}
          >
            <Processing
              authorizeGcPaymentMethod={authorizeGcPaymentMethod}
              currentPaymentMethod={currentPaymentMethod}
              setCurrentPaymentMethod={setCurrentPaymentMethod}
              isFormSubmitting={isFormSubmitting}
              setFormSubmitting={setFormSubmitting}
              isGcAdded={isGcAdded}
              isGcAuthorizationSuccess={isGcAuthorizationSuccess}
              waitMessage={waitMessage}
              formRef={CreateEditOrderFormRef}
              sendingMemberRef={sendingMemberRef}
              terminalTxnCancellingRef={terminalTxnCancellingRef}
              isSavingDraft={isSavingDraft}
            />
            {showSessionInfo ? (
              <TerminalSessionScreen operator={operator} />
            ) : (
              <Form
                initialValues={formInitialValues}
                innerRef={CreateEditOrderFormRef}
                onSubmit={(values, formikBag) => {
                  if (houseAccountFormRef.current) {
                    formikBag.setSubmitting(false); // Just to inform outer form is not submitted.
                    houseAccountFormRef.current.handleSubmit();
                  } else validateAndSubmitOrder(values, formikBag);
                }}
                validationSchema={validationSchema(
                  messages,
                  Localise,
                  redeemedGiftCardsData,
                  enableSplitPayment,
                  customerDetails,
                  houseAccountCreditLimit,
                  isEditOrder,
                  isActionDraft
                )}
                validateOnChange={true}
                validateOnBlur={validationOnChange}
                render={(formikBag) => {
                  const {
                    errors,
                    initialValues,
                    resetForm,
                    setValues,
                    status,
                    submitCount,
                    values,
                    setFieldValue,
                    touched,
                    setErrors,
                  } = formikBag;
                  const {
                    orderItems,
                    hasMultipleShops = false,
                    sendingMember,
                  } = values;

                  const amountDue = get(values, "paymentDetails.amountDue", 0);

                  const sendingMemberCode = hasMultipleShops
                    ? sendingMember || memberCodes[0]
                    : memberCodes[0];

                  // const isMHQNonCoreUser =
                  //   isMHQNonCoreMember(sendingMemberCode);
                  const isCoreUser = isCoreMember(sendingMember);
                  const isCoreConnectUser = isCoreConnectMember(sendingMember);

                  // const showTerminalIcon = isMHQNonCoreUser && isElectronApp;

                  const selectedShopPermissions = get(
                    permissions,
                    sendingMemberCode,
                    {}
                  );
                  const isPaymentEntitlementEnabled = selectedShopPermissions[
                    Entitlements.CREATE_ORDER
                  ]?.includes(Entitlements.CREATE_ORDER_PAGE.PAYMENT_SECTION);

                  const isCPSEntitlementEnabled = selectedShopPermissions[
                    Entitlements.CREATE_ORDER
                  ]?.includes(Entitlements.CREATE_ORDER_PAGE.CUSTOMER_SEARCH);

                  const isWalkInEntEnabled = selectedShopPermissions[
                    Entitlements.CREATE_ORDER
                  ]?.includes(Entitlements.CREATE_ORDER_PAGE.DM_WALK_IN);

                  const isSubscriptionsEnabled =
                    Entitlements.CUSTOMER_DIRECTORY in selectedShopPermissions;

                  const maxScrollViewHeight =
                    Platform.OS === "web" ? `calc(100% - 80px)` : "100%";

                  const isInvoiceAsPaymentMethod =
                    get(
                      orderDetailsResponse,
                      "paymentDetails.paymentMethod",
                      []
                    ).filter(
                      (item) =>
                        (item?.paymentMethodType ?? "") === "INVOICE" && item
                    ).length > 0;

                  useEffect(() => {
                    if (isEditOrder && isInvoiceAsPaymentMethod) {
                      const filteredPaymentOptions = paymentOptions.filter(
                        (option) => option === "INVOICE"
                      );
                      setPaymentOptions(filteredPaymentOptions);
                    }
                  }, [isEditOrder, isInvoiceAsPaymentMethod]);

                  useEffect(() => {
                    const isMHQNonCoreShop = isMHQNonCoreMember(sendingMember);

                    if (sendingMember && isMHQNonCoreShop) {
                      request("get-shop-settings", {
                        ids: [
                          "cash_or_check",
                          "credit_card",
                          "paid_elsewhere",
                          "house_account_invoice",
                          "payment_terminal",
                          "delivery_fee",
                          "tax_delivery_fee",
                          "retail_delivery_fee",
                          "tax_retail_delivery_fee",
                          "relay_fee",
                          "rush_fee_enabled",
                          "rush_submitted_by",
                          "rush_delivered_by",
                          "local_order_tax_settings",
                          "wired_order_tax_settings",
                          "gift_cards",
                          "pay_later",
                          "tax_on_local_delivery_fee",
                          "tax_on_outside_local_delivery_fee",
                          "tax_on_service_fee",
                          "tax_on_relay_fee",
                          "email_ha_invoices_notification_settings",
                        ],
                        memberCode: sendingMember,
                      })
                        .then(async (res) => {
                          if (
                            res &&
                            res.preferences &&
                            res.preferences.length
                          ) {
                            let result = processShopSettingsResponse(res);

                            orderItems.forEach((_each, index) => {
                              setFieldValue(
                                `orderItems.${index}.applyTaxOnDF`,
                                result["tax_delivery_fee"].toLowerCase() ===
                                  "true"
                              );

                              setFieldValue(
                                `orderItems.${index}.applyTaxOnRDF`,
                                result[
                                  "tax_retail_delivery_fee"
                                ]?.toLowerCase() === "true"
                              );
                            });

                            let paymentOptions = [];

                            if (
                              (isEditOrder && !isInvoiceAsPaymentMethod) ||
                              hasSettlementError ||
                              !isEditOrder
                            ) {
                              if (isElectronApp) {
                                const paymentTerminalEnabledForMembercode =
                                  result["payment_terminal"] === "true";

                                const enablePaymentTerminal = Environment.get(
                                  "ENABLE_PAYMENT_TERMINAL",
                                  ""
                                ).split(",");

                                const hasAccessToPaymentTerminal =
                                  enablePaymentTerminal.includes("ALL") ||
                                  enablePaymentTerminal.includes(sendingMember);

                                if (
                                  paymentTerminalEnabledForMembercode &&
                                  hasAccessToPaymentTerminal
                                ) {
                                  const { terminal_settings = [] } =
                                    UserProfileStorage.getShopPreferences(
                                      sendingMember
                                    );

                                  const { currentPOSSettings = {} } =
                                    await getCurrentPOSSettings(
                                      terminal_settings
                                    );

                                  if (currentPOSSettings?.serial_number) {
                                    paymentOptions.unshift({
                                      label: Localise(
                                        messages,
                                        "Payment Terminal"
                                      ),
                                      value: "PAYMENT_TERMINAL",
                                    });

                                    setFieldValue(
                                      "paymentDetails.paymentMethod.0.paymentMethodType",
                                      "PAYMENT_TERMINAL"
                                    );
                                  }
                                }
                              }

                              result["cash_or_check"] === "true" &&
                                paymentOptions.push({
                                  label: Localise(messages, "Cash or Check"),
                                  value: "CASH_OR_CHECK",
                                });

                              result["credit_card"] === "true" &&
                                paymentOptions.push({
                                  label: Localise(messages, "Credit Card"),
                                  value: "CREDIT_CARD",
                                });

                              result["paid_elsewhere"] === "true" &&
                                paymentOptions.push({
                                  label: Localise(messages, "Paid Elsewhere"),
                                  value: "PAID_ELSEWHERE",
                                });
                            }

                            setPaymentOptions(paymentOptions);

                            UserProfileStorage.setShopPreferences(
                              sendingMember,
                              {
                                cash_or_check: result["cash_or_check"] || "",
                                credit_card: result["credit_card"] || "",
                                paid_elsewhere: result["paid_elsewhere"] || "",
                                house_account_invoice:
                                  result["house_account_invoice"] || "",
                                delivery_fee: result["delivery_fee"] || "0.00",
                                tax_delivery_fee:
                                  result["tax_delivery_fee"] || "false",
                                retail_delivery_fee:
                                  result["retail_delivery_fee"] || "0.00",
                                tax_retail_delivery_fee:
                                  result["tax_retail_delivery_fee"] || "false",
                                relay_fee: result["relay_fee"] || "0.00",
                                rush_fee_enabled:
                                  result["rush_fee_enabled"] || "false",
                                rush_submitted_by: result["rush_submitted_by"]
                                  ? moment(
                                      result["rush_submitted_by"],
                                      "HH:mm:ss"
                                    ).format("YYYY-MM-DDTHH:mm:ss")
                                  : "",
                                rush_delivered_by: result["rush_delivered_by"]
                                  ? moment(
                                      result["rush_delivered_by"],
                                      "HH:mm:ss"
                                    ).format("YYYY-MM-DDTHH:mm:ss")
                                  : "",
                                local_order_tax_settings:
                                  result["local_order_tax_settings"] ||
                                  "point_of_origin",
                                wired_order_tax_settings:
                                  result["wired_order_tax_settings"] ||
                                  "point_of_origin",
                                gift_cards: result["gift_cards"] || "",
                                pay_later: result["pay_later"] || "",
                                tax_on_local_delivery_fee:
                                  result["tax_on_local_delivery_fee"] || "",
                                tax_on_outside_local_delivery_fee:
                                  result["tax_on_outside_local_delivery_fee"] ||
                                  "",
                                tax_on_service_fee:
                                  result["tax_on_service_fee"] || "",
                                tax_on_relay_fee:
                                  result["tax_on_relay_fee"] || "",
                                email_ha_invoices_notification_settings:
                                  result[
                                    "email_ha_invoices_notification_settings"
                                  ] || "",
                              }
                            );
                          }
                        })
                        .catch((err) => {
                          console.log(
                            "Error occurred while getting shop preferences :>> ",
                            err
                          );
                        });
                    } else {
                      setFieldValue("orderItems.0.deliveryFee", "0.00");
                      setFieldValue("orderItems.0.applyTaxOnDF", false);
                      setFieldValue("orderItems.0.retailDeliveryFee", "0.00");
                      setFieldValue("orderItems.0.applyTaxOnRDF", false);
                      setFieldValue("orderItems.0.relayFee", "0.00");
                      setPaymentOptions([]);
                      // For core members resetting tabs to single tab - If we select standard to core from crossEntitlements then we need below line to reset orderItems array.
                      // Commented this as we don't have any crossEntitlements in LIVE & as it impacting copy functionality for core members.
                      // setFieldValue("orderItems", [basicOrderInfo]);
                      dispatch(resetOrderItemTabs());
                      dispatch(setActiveOrderItemTab(0));
                    }
                    dispatch(fetchGlobalFees(sendingMember));

                    if (sendingMember && !isEditOrder && !isEditSubscription) {
                      const selectedShopPermissions = get(
                        permissions,
                        sendingMember,
                        {}
                      );
                      const isPaymentEntitlementEnabled =
                        selectedShopPermissions[
                          Entitlements.CREATE_ORDER
                        ]?.includes(
                          Entitlements.CREATE_ORDER_PAGE.PAYMENT_SECTION
                        );

                      // Adding this because of https://ftdcorp.atlassian.net/browse/MSOL-10821 - On shop selection create-order form is re-rendering with initial Values again.
                      // TODO: Need to stop the multiple re-renders for the form.
                      setTimeout(() => {
                        setFieldValue(
                          "paymentDetails.paymentMethod.0.enablePayment",
                          isPaymentEntitlementEnabled
                        );
                      }, 500);
                    }

                    sendingMemberRef.current = sendingMember;
                  }, [sendingMember]);

                  useEffect(() => {
                    const ordersCount = values.orderItems.length;
                    if (
                      ordersCount === orderPositions.length &&
                      scrollEnabled
                    ) {
                      screenOneScrollViewRef &&
                        screenOneScrollViewRef.current.scrollTo({
                          x: 0,
                          y: orderPositions[ordersCount - 1],
                          animated: true,
                        });
                      setScrollEnabled(false);
                    }
                  }, [orderPositions]);

                  useEffect(() => {
                    if (
                      !isEmpty(selectedFlorist)
                      //!isEmpty(selectedFlorist.florist)
                    ) {
                      const data = get(values, selectedFlorist.formIKPath);
                      setFieldValue(selectedFlorist.formIKPath, {
                        ...data,
                        floristInfo: !isEmpty(selectedFlorist.florist)
                          ? {
                              ...selectedFlorist.florist,
                            }
                          : "",
                      });
                    }
                  }, [selectedFlorist]);

                  useEffect(() => {
                    if (!isEmpty(selectedProduct)) {
                      const {
                        actionType,
                        product: {
                          pid,
                          price,
                          name,
                          img,
                          refNumberId,
                          quantity = "1",
                          type = "Product",
                          siteId = "",
                        },
                        formIKPricePath,
                        formIKPath,
                      } = selectedProduct;

                      const actualCustomerInfo = get(
                        values,
                        "actualCustomerInfo"
                      );

                      const isAddOrReplace = ["Add", "Replace"].includes(
                        actionType
                      );

                      let products = get(values, formIKPath, []);
                      let prices = get(values, formIKPricePath, []);

                      const productIndex = products.findIndex(
                        (prod) => prod.productFirstChoiceCode === pid
                      );

                      if (isAddOrReplace) {
                        if (["Add"].includes(actionType)) {
                          if (productIndex < 0) {
                            products = [
                              ...products,
                              getProductObj(
                                pid,
                                name,
                                refNumberId,
                                img,
                                price,
                                quantity,
                                type,
                                siteId
                              ),
                            ];

                            const priceObj = getPriceObj(
                              price,
                              quantity,
                              actualCustomerInfo?.discountPercentage
                            );

                            prices = [
                              ...prices,
                              {
                                ...priceObj,
                                ...(isEditOrder && {
                                  newPrice: priceObj.discountedValue,
                                }),
                              },
                            ];
                          } else {
                            products[productIndex].quantity =
                              products[productIndex].quantity + quantity;
                          }
                        } else {
                          if (
                            (isCoreUser || isCoreConnectUser) &&
                            products.length
                          ) {
                            products.splice(0, 1);
                            prices.splice(0, 1);
                          }

                          products = [
                            getProductObj(
                              pid,
                              name,
                              refNumberId,
                              img,
                              price,
                              quantity,
                              type,
                              siteId
                            ),
                            ...products,
                          ];

                          const priceObj = getPriceObj(
                            price,
                            quantity,
                            actualCustomerInfo?.discountPercentage
                          );

                          prices = [
                            {
                              ...priceObj,
                              ...(isEditOrder && {
                                newPrice: priceObj.discountedValue,
                              }),
                            },
                            ...prices,
                          ];
                        }
                      } else {
                        if (!isCoreUser && !isCoreConnectUser) {
                          products.splice(productIndex, 1);
                          prices.splice(productIndex, 1);
                        } else {
                          products = [];
                          prices = [];
                        }
                      }

                      setFieldValue(formIKPath, products);
                      setFieldValue(formIKPricePath, prices);

                      actionType && !isSmallScreen
                        ? setShowSideCar("additional_info")
                        : setShowSideCar("");
                    }
                  }, [selectedProduct]);

                  useEffect(() => {
                    if (submitCount > 0) {
                      setValidationOnChange(true);
                    }
                  }, [submitCount, status]);

                  const hasLineItems = orderItems?.[0]?.lineItems?.length > 0;
                  const recipientInfo = orderItems?.[0]?.recipientInfo;

                  useEffect(() => {
                    if (!hasLineItems) {
                      setFieldValue("paymentDetails", basicPaymentInfo);

                      orderItems.forEach((_each, index) => {
                        setFieldValue(`orderItems.${index}.taxInfo`, {
                          taxAmount: 0,
                          taxRate: 0.0,
                        });
                      });
                    }

                    /**
                     * Updating enablePayment in formik on product/add-on adding or removing so that we have proper data in formik always, we are
                     * depending on this enablePayment while doing form validations and also while preparing request object to inject order amounts
                     **/
                    setFieldValue(
                      "paymentDetails.paymentMethod.0.enablePayment",
                      isPaymentEntitlementEnabled
                    );

                    dispatch(
                      setGiftCardsData({
                        actionName: "clear",
                      })
                    );
                  }, [hasLineItems]);

                  useEffect(() => {
                    const {
                      country,
                      deliveryMethod,
                      hasRecipientInfo: prevHasRecipientInfo,
                      ...other
                    } = recipientInfo;

                    const hasRecipientInfo =
                      Object.values(other).filter((val) => !!val).length > 0;

                    if (prevHasRecipientInfo !== hasRecipientInfo) {
                      setFieldValue("orderItems.0.recipientInfo", {
                        ...recipientInfo,
                        hasRecipientInfo,
                      });
                    }
                  }, [recipientInfo]);

                  useEffect(() => {
                    // resetting paymentType in case add or remove order items
                    setFieldValue(
                      "paymentDetails.paymentMethod.0.paymentMethodType",
                      ""
                    );
                    setValidationOnChange(false);
                  }, [tabNames.length]);

                  useEffect(() => {
                    if (Platform.OS !== "web") return;

                    if (isEditOrder && isEditProducts) {
                      const isCPSEntitlementEnabled = selectedShopPermissions[
                        Entitlements.CREATE_ORDER
                      ]?.includes(
                        Entitlements.CREATE_ORDER_PAGE.CUSTOMER_SEARCH
                      );

                      screenOneScrollViewRef &&
                        screenOneScrollViewRef.current.scrollTo({
                          x: 0,
                          y: isCPSEntitlementEnabled ? 500 : 100,
                          animated: true,
                        });
                    }

                    const timer = setTimeout(() => {
                      if (FormReloadCache.getFormValues("CreateOrder")) {
                        // Conditionally clear the cache based on isEditOrder flag
                        if (isEditOrder) {
                          // Wait for orderDetailsResponse to have values
                          if (orderDetailsResponse) {
                            const savedValues =
                              FormReloadCache.getFormValues("CreateOrder");
                            if (
                              savedValues &&
                              Object.keys(savedValues).length > 0
                            ) {
                              CreateEditOrderFormRef.current.setValues(
                                savedValues
                              );
                            }
                            FormReloadCache.clear();
                          }
                        } else {
                          if (FormReloadCache.getFormValues("CreateOrder")) {
                            const savedValues =
                              FormReloadCache.getFormValues("CreateOrder");
                            if (
                              savedValues &&
                              Object.keys(savedValues).length > 0
                            ) {
                              CreateEditOrderFormRef.current.setValues(
                                savedValues
                              );
                            }
                            FormReloadCache.clear();
                          }
                        }
                      }
                    }, 5000); // 10 seconds delay

                    const channel = new BroadcastChannel(
                      "reload-channel-SaveDraft"
                    );

                    channel.onmessage = (event) => {
                      if (event.data === "SaveDraft") {
                        // Get the current form values from the form reference
                        const updatedInitialValues =
                          CreateEditOrderFormRef.current.values;
                        // Check if the item exists in local storage before attempting to remove it
                        if (FormReloadCache.getFormValues("CreateOrder")) {
                          FormReloadCache.clear();
                        }
                        // Save the updated form values into local storage
                        FormReloadCache.setFormValues(
                          "CreateOrder",
                          updatedInitialValues
                        );
                      }
                    };

                    return () => {
                      channel.close();
                      clearTimeout(timer);
                    };
                  }, [orderDetailsResponse, isEditOrder]);

                  useEffect(() => {
                    if (
                      values?.isQuickSaleEnabled &&
                      values?.orderItems.length > 1
                    ) {
                      // resetting the multi order tabs
                      dispatch(resetOrderItemTabs());

                      // Resetting orderItems array - Selecting the current focused orderItem and copying those values to quicksale
                      const updatedValues = cloneDeep(values);
                      updatedValues.orderItems = [];
                      updatedValues.orderItems[0] =
                        values.orderItems[currentTab];
                      // setting Walk-in attributes
                      set(updatedValues, "orderItems.0.recipientInfo", {
                        firstName: "",
                        lastName: "",
                        addressLine1: "",
                        suite: "",
                        city: "",
                        state: "",
                        zip: "",
                        country: "US",
                        locationType: "",
                        locationName: "",
                        phone: "",
                        deliveryMethod: "WALK_IN",
                        hasRecipientInfo: false,
                      });

                      set(
                        updatedValues,
                        `orderItems.0.deliveryMethod`,
                        "WALK_IN"
                      );
                      set(
                        updatedValues,
                        `orderItems.0.deliveryInfo.deliveryMethod`,
                        "WALK_IN"
                      );

                      set(updatedValues, `orderItems.0.deliveryFee`, 0);
                      set(updatedValues, `orderItems.0.retailDeliveryFee`, 0);
                      set(updatedValues, `orderItems.0.relayFee`, 0);
                      setValues(updatedValues);
                    }
                  }, [values?.isQuickSaleEnabled]);

                  const isMHQNonCoreShop =
                    isMHQNonCoreMember(sendingMemberCode);

                  const isMultiOrderEligible =
                    isMHQNonCoreShop &&
                    !isEditOrder &&
                    !values?.isQuickSaleEnabled;
                  const showDraftOrderActions =
                    Platform.OS === "web" &&
                    !isEditOrder &&
                    !!sendingMember &&
                    hasDraftFeature &&
                    !isSubscription;

                  return (
                    <View style={[tw("flex flex-row justify-center h-full")]}>
                      <View
                        style={[
                          showSideCar
                            ? isDesktop
                              ? tw("w-1/2")
                              : tw("p-0 w-0 h-0 opacity-0")
                            : isDesktop
                            ? tw("w-1/2")
                            : tw("w-full h-full"),
                          {
                            maxHeight: maxScrollViewHeight,
                            opacity: isFormSubmitting ? 0.25 : 1,
                            paddingHorizontal: isDesktop ? 20 : 10,
                          },
                        ]}
                      >
                        {!isDesktop && (
                          <>
                            <View style={[tw("flex-col p-3")]}>
                              <View
                                style={tw(
                                  "flex flex-row justify-between items-center"
                                )}
                              >
                                <Text
                                  style={{
                                    ...fonts.heading3,
                                    fontWeight: "bold",
                                    ...(isTablet && {
                                      fontWeight: "400",
                                    }),
                                  }}
                                >
                                  {screenTitle}
                                </Text>
                                <View style={tw("flex flex-row items-center")}>
                                  <OpenSubscription
                                    isSubscriptionsEnabled={
                                      isSubscriptionsEnabled
                                    }
                                  />
                                  {isWalkInEntEnabled &&
                                    !isEditOrder &&
                                    !isSubscription && <OpenQuickSaleArea />}

                                  <OpenAdditionalInfoArea
                                    setSideCarOpen={setSideCarOpen}
                                    sideCarKey={sideCarKey}
                                  />
                                </View>
                              </View>
                              <Breadcrumb />
                            </View>
                          </>
                        )}

                        <ScrollView
                          style={{
                            paddingHorizontal: 15,
                            paddingTop: isSmallScreen ? 5 : 20,
                          }}
                          ref={screenOneScrollViewRef}
                          onScroll={(e) => {
                            isAutoFill &&
                              dispatch(
                                setYOffSetScroll(e.nativeEvent.contentOffset.y)
                              );
                          }}
                        >
                          <ShopSelection
                            isSmallScreen={isSmallScreen}
                            memberCodes={memberCodes}
                            setSideCarOpen={setSideCarOpen}
                            sideCarKey={sideCarKey}
                            isWalkInEntEnabled={isWalkInEntEnabled}
                            isEditOrder={isEditOrder}
                            isActionDraft={isActionDraft}
                            isSubscriptionsEnabled={isSubscriptionsEnabled}
                          />

                          <View
                            style={{
                              zIndex: 5,
                            }}
                            key={"CustomerProfileInfo"}
                          >
                            {isEditOrder && (
                              <View
                                style={[
                                  {
                                    ...shapes.sectionBorder,
                                    marginTop: 15,
                                  },
                                ]}
                              >
                                <View
                                  style={[
                                    tw("flex flex-row"),
                                    { paddingHorizontal: 5 },
                                  ]}
                                >
                                  <Text
                                    style={[
                                      fonts.sectionHeading,
                                      { color: colors.highlighter },
                                    ]}
                                  >
                                    {`${Localise(messages, "Order")} #${
                                      ["STORE_PICKUP", "WALK_IN"].includes(
                                        editActualDeliveryMethod
                                      )
                                        ? orderDetails?.erosOrderNumber
                                        : orderDetails?.messageNumber || ""
                                    }`}
                                  </Text>
                                </View>
                              </View>
                            )}
                            {!values.isQuickSaleEnabled && (
                              <CustomerProfileInfo
                                isSmallScreen={isSmallScreen}
                                title={"Customer"}
                                sendingMemberCode={sendingMemberCode}
                                canModifyOrder={
                                  canModifyOrder && !isEditSubscription
                                }
                                setSideCarOpen={setSideCarOpen}
                                errors={errors}
                                isActionDraft={isActionDraft}
                              />
                            )}
                          </View>
                          <View
                            style={[
                              {
                                marginTop: 15,
                              },
                              isMultiOrderEligible && {
                                ...shapes.sectionBorder,
                              },
                            ]}
                          >
                            {isMultiOrderEligible && !isSubscription && (
                              <View style={tw("flex-row justify-between")}>
                                <View style={{ width: "90%" }}>
                                  <TabBar
                                    tabNames={tabNames}
                                    currentTab={currentTab}
                                    setCurrentTab={(key) =>
                                      dispatch(setActiveOrderItemTab(key))
                                    }
                                    scrollTabBar={true}
                                    showsScrollIndicators={true}
                                    showBorder={false}
                                    tabStyle={{
                                      paddingTop: 10,
                                      paddingBottom: 8,
                                    }}
                                    containerStyle={tw("w-full")}
                                    removeTab={tabNames.length > 1}
                                    setTabsCount={(key) => {
                                      orderItemRemoveIndex.current = key;
                                      setModalVisibile(true);
                                    }}
                                  />
                                </View>
                                <AddNewOrderItem
                                  tabNames={tabNames}
                                  arrayHelpersRef={arrayHelpersRef}
                                  sendingMemberCode={sendingMemberCode}
                                />
                              </View>
                            )}
                            <FieldArray
                              name="orderItems"
                              render={(arrayHelpers) => {
                                arrayHelpersRef.current = arrayHelpers;
                                return (
                                  <View>
                                    {values.orderItems?.map((order, index) => {
                                      if (index === currentTab) {
                                        return (
                                          <OrderItem
                                            key={`order-${index}`}
                                            index={index}
                                            isSmallScreen={isSmallScreen}
                                            orderInfo={order}
                                            setOrderPositions={
                                              memoizedSetOrderPositions
                                            }
                                            orderPositions={orderPositions}
                                            setSideCarOpen={setSideCarOpen}
                                            setScrollEnabled={
                                              memoizedSetScrollEnabled
                                            }
                                            setSelectedFlorist={
                                              memoizedSetSelectedFlorist
                                            }
                                            addons={addons}
                                            isAutoFill={isAutoFill}
                                            sideCarKey={sideCarKey}
                                            memberCodes={memberCodes}
                                            sendingMemberCode={
                                              sendingMemberCode
                                            }
                                            canModifyOrder={canModifyOrder}
                                            isQuickSaleEnabled={
                                              values?.isQuickSaleEnabled
                                            }
                                            isMultiOrderEligible={
                                              isMultiOrderEligible
                                            }
                                            actualDeliveryMethod={
                                              actualDeliveryMethod
                                            }
                                            submitCount={submitCount}
                                          />
                                        );
                                      }
                                    })}
                                  </View>
                                );
                              }}
                            />
                          </View>
                          <OrderPayment
                            sendingMemberCode={sendingMemberCode}
                            paymentOptions={paymentOptions}
                            amountDue={amountDue}
                            enableSplitPayment={enableSplitPayment}
                            setValidationOnChange={setValidationOnChange}
                            onHouseAccountUpdateSuccess={() => {
                              setInnerFormLoading(false);
                              validateAndSubmitOrder(values, formikBag);
                            }}
                            onHouseAccountCreditLimitChange={(val) => {
                              setHouseAccountCreditLimit(val);
                            }}
                            onHouseAccountUpdateReject={() => {
                              setInnerFormLoading(false);
                            }}
                            houseAccountFormRef={houseAccountFormRef}
                            setInnerFormLoading={setInnerFormLoading}
                            subscriptionDetails={
                              subscriptionData || subscriptionDetails
                            }
                          />
                          <View
                            style={[
                              tw(
                                "flex flex-row items-center flex-wrap justify-between"
                              ),
                              { marginTop: 15, zIndex: -1 },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  ...fonts.heading4,
                                  fontSize: 15,
                                  fontWeight: "normal",
                                },
                              ]}
                            >
                              {isSmallScreen && memberCodes.length > 1
                                ? `* ${Localise(messages, "Required Field")}`
                                : ""}
                            </Text>

                            <View style={[tw("flex flex-col flex-wrap")]}>
                              {!isEmpty(errors) && validationOnChange && (
                                <Text style={theme.SubmitButton.errorStyle}>
                                  {Localise(
                                    messages,
                                    "Please complete required fields to submit this order"
                                  )}
                                </Text>
                              )}

                              <View
                                style={[
                                  tw("flex flex-row items-center justify-end"),
                                ]}
                              >
                                <View style={tw("flex flex-row items-center")}>
                                  <View
                                    style={{
                                      ...theme.Button.cancelButtonStyle,
                                      width: isDesktop ? 72 : 80,
                                      height: 35,
                                    }}
                                  >
                                    <TouchableOpacity
                                      onPress={() => {
                                        const cancelCreateOrder = () => {
                                          if (isEditOrder || isCopyOrder) {
                                            // redirecting to orders page & removing the lock in the component unmount.
                                            redirectToOrders();
                                          } else {
                                            dispatch(resetCurrentProduct());
                                            dispatch(resetSubscriptionData());
                                            const updatedInitialValues =
                                              getUpdatedInitialValues(
                                                !isSubscription
                                                  ? initialValues
                                                  : undefined
                                              );
                                            resetForm(updatedInitialValues);
                                            dispatch(resetOrderItemTabs());
                                            setValues(updatedInitialValues);
                                            setScrollEnabled(true);
                                            setOrderPositions([0]);
                                            setValidationOnChange(false);
                                            setErrors({});
                                            setRouteParams();
                                          }
                                          isDesktop &&
                                            [
                                              "florist_selection",
                                              "product_selection",
                                              "abbreviation_codes",
                                            ].includes(sideCarKey) &&
                                            setSideCarOpen("additional_info");
                                        };
                                        const paymentMethods = get(
                                          values,
                                          "paymentDetails.paymentMethod",
                                          []
                                        );
                                        const hasProcessedPayments =
                                          paymentMethods.filter(
                                            (eachPayment) => {
                                              return (
                                                !![
                                                  "CREDIT_CARD",
                                                  "SAVED_CARD",
                                                  "PAYMENT_TERMINAL",
                                                ].includes(
                                                  eachPayment.paymentMethodType
                                                ) &&
                                                !!eachPayment.paymentProcessed
                                              );
                                            }
                                          ) || [];

                                        if (
                                          isSplitPaymentEnabled &&
                                          hasProcessedPayments.length > 0
                                        ) {
                                          voidPayments({
                                            values,
                                            resolve: () => {
                                              dispatch(
                                                setPaymentDetails({
                                                  status: "",
                                                  values: {},
                                                })
                                              );
                                              cancelCreateOrder();
                                              ToasterHandler(
                                                "nice",
                                                Localise(
                                                  messages,
                                                  `Refund initiated for successful transaction(s)`
                                                )
                                              );
                                            },
                                            reject: () => {
                                              Logger.info(
                                                "reject of voidPayments in createOrder :>> "
                                              );
                                              ToasterHandler(
                                                "uh oh",
                                                Localise(
                                                  messages,
                                                  "Something went wrong. Please try again!"
                                                )
                                              );
                                            },
                                          });
                                        } else if (isActionDraft) {
                                          setRouteParams();
                                          dispatch(resetOrderItemTabs());
                                          resetForm();
                                          setScrollEnabled(true);
                                          setOrderPositions([0]);
                                        } else {
                                          cancelCreateOrder();
                                        }
                                      }}
                                      testID="cancel_order"
                                      accessibilityLabel="cancel_order"
                                    >
                                      <Text
                                        style={{
                                          ...theme.Button.secondaryTitleStyle,
                                          color: colors.highlighter,
                                          fontSize: 12,
                                        }}
                                      >
                                        {Localise(messages, "Cancel")}
                                      </Text>
                                    </TouchableOpacity>
                                  </View>
                                  {showDraftOrderActions && isActionDraft && (
                                    <PopupMenu
                                      menuOptions={[
                                        {
                                          key: "deleteDraft",
                                          label: "Delete Draft",
                                        },
                                      ]}
                                      onSelect={() =>
                                        deleteDraftHandler(formikBag)
                                      }
                                      containerStyle={[
                                        tw("items-center"),
                                        { marginLeft: 3 },
                                      ]}
                                      optionsContainerStyle={[
                                        {
                                          marginLeft: -75,
                                          width: 100,
                                          marginTop: 40,
                                        },
                                        tw("p-1"),
                                      ]}
                                    >
                                      <View
                                        style={{
                                          ...theme.Button.cancelButtonStyle,
                                          width: 25,
                                          height: 35,
                                          marginVertical: 2,
                                          paddingHorizontal: 0,
                                          paddingVertical: 0,
                                        }}
                                        testID="cancel_order_options"
                                      >
                                        <Image
                                          style={{ width: 12, height: 9 }}
                                          resizeMode="contain"
                                          source={
                                            IMAGES["arrow-drop-down-blue"]
                                          }
                                        />
                                      </View>
                                    </PopupMenu>
                                  )}
                                </View>
                                <View style={tw("flex flex-row items-center")}>
                                  <TouchableOpacity ref={buttonRef}>
                                    <SubmitButton
                                      containerStyle={{
                                        width: isDesktop ? 78 : 90,
                                        height: 35,
                                        margin: isSmallScreen ? 10 : 2,
                                        marginLeft: 12,
                                      }}
                                      isSubmitOnEnter={true}
                                      title={"Submit"}
                                      buttonRef={buttonRef}
                                      disabled={calculatingTax}
                                      isLoading={innerFormLoading}
                                    />
                                  </TouchableOpacity>
                                  {showDraftOrderActions && (
                                    <PopupMenu
                                      menuOptions={[
                                        {
                                          key: "saveDraft",
                                          label: "Save Draft",
                                        },
                                      ]}
                                      onSelect={async (val) => {
                                        if (val === "saveDraft") {
                                          const {
                                            paymentDetails: {
                                              paymentMethod = [],
                                            },
                                          } = values;
                                          const isPaymentDetailsEntered =
                                            paymentMethod.some(
                                              (payment) =>
                                                !isEmpty(
                                                  payment.paymentMethodType
                                                )
                                            );

                                          if (
                                            isPaymentDetailsEntered ||
                                            !isEmpty(redeemedGiftCardsData)
                                          ) {
                                            setShowDraftConfirmModal(true);
                                          } else {
                                            await saveDraftHandler(formikBag);
                                          }
                                        }
                                      }}
                                      containerStyle={tw("items-center")}
                                      optionsContainerStyle={[
                                        {
                                          marginLeft: -80,
                                          width: 105,
                                          marginTop: 40,
                                        },
                                        tw("p-1"),
                                      ]}
                                    >
                                      <View
                                        style={{
                                          backgroundColor: colors.button,
                                          width: 25,
                                          height: 35,
                                          justifyContent: "center",
                                          alignItems: "center",
                                          borderRadius: 2,
                                          marginVertical: 2,
                                        }}
                                        testID="submit_order_options"
                                      >
                                        <Image
                                          style={{ width: 12, height: 9 }}
                                          resizeMode="contain"
                                          source={IMAGES["arrow-drop-down"]}
                                        />
                                      </View>
                                    </PopupMenu>
                                  )}
                                  {showDraftConfirmModal && (
                                    <CustomModal
                                      modalVisible={showDraftConfirmModal}
                                      modalContent={{
                                        content: (
                                          <Text
                                            style={[
                                              tw("p-4 text-center"),
                                              {
                                                fontSize: 15,
                                                color: colors.highlighter,
                                              },
                                            ]}
                                          >
                                            {Localise(
                                              messages,
                                              ORDERS.DRAFT_CONFIRM_MODAL_CONTENT
                                            )}
                                          </Text>
                                        ),
                                        buttons: [
                                          {
                                            type: "secondary",
                                            title: Localise(messages, "Cancel"),
                                            testID: "cancel_save_draft",
                                          },
                                          {
                                            type: "primary",
                                            title: Localise(
                                              messages,
                                              "Continue"
                                            ),
                                            testID: "continue_to_save_draft",
                                          },
                                        ],
                                      }}
                                      primaryhandler={() => {
                                        saveDraftHandler(formikBag);
                                        setShowDraftConfirmModal(false);
                                      }}
                                      secondaryhandler={() => {
                                        setShowDraftConfirmModal(false);
                                      }}
                                      contentStyle={[
                                        tw("border border-black p-4"),
                                        {
                                          backgroundColor:
                                            backgroundColors.secondary,
                                        },
                                      ]}
                                      isReduceBtnSpacing={true}
                                      modalStyle={tw("w-1/2")}
                                    />
                                  )}
                                </View>
                              </View>
                            </View>
                          </View>
                        </ScrollView>
                      </View>

                      {showSideCar ? (
                        <View
                          style={[
                            {
                              backgroundColor: backgroundColors.sideCar,
                              width: isSmallScreen
                                ? "100%"
                                : [
                                    "product_selection",
                                    "florist_selection",
                                    "funeral_log",
                                  ].includes(sideCarKey)
                                ? "40%"
                                : "25%",
                            },
                          ]}
                        >
                          <View
                            contentContainerStyle={{
                              opacity: isFormSubmitting ? 0.25 : 1,
                              padding: isDesktop ? 20 : 15,
                            }}
                            style={[
                              {
                                maxHeight: maxScrollViewHeight,
                              },
                            ]}
                          >
                            {isSmallScreen && (
                              <>
                                <View style={[tw("flex flex-row my-1")]}>
                                  <OpenAdditionalInfoArea
                                    setSideCarOpen={setSideCarOpen}
                                    sideCarKey={sideCarKey}
                                  />
                                </View>
                              </>
                            )}

                            <SideCar
                              index={currentTab}
                              setShowSideCar={memoizedSetShowSideCar}
                              formValues={values}
                              sideCarKey={sideCarKey}
                              sideCarInfo={sideCarInfo}
                              formIKPath={formIKPath}
                              setSelectedRecord={setSelectedRecord}
                              isSmallScreen={isSmallScreen}
                              memberCodes={memberCodes}
                              sendingMemberCode={sendingMemberCode}
                              isAutoFill={isAutoFill}
                              enableCPS={isCPSEntitlementEnabled}
                              canModifyOrder={canModifyOrder}
                              setSideCarOpen={setSideCarOpen}
                              setFieldValue={setFieldValue}
                              touched={touched}
                              setProductModalVisible={
                                memoizedSetProductModalVisible
                              }
                            />
                          </View>
                        </View>
                      ) : null}
                    </View>
                  );
                }}
              />
            )}
          </View>
        </View>
      </View>
    </>
  );
};

export default CreateOrderScreen;
