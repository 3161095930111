import React, { useContext, useEffect, useRef } from "react";
import { View, KeyboardAvoidingView, Platform } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";
import { PrintIframeRenderer, Picker } from "components/elements";

import SingleScreen from "components/containers/single-screen";
import ScreenHeader from "components/containers/header/screen";
import EnclosureCard from "components/views/drawer/order-details/product-details/order-details/enclosure-card";
import { Breadcrumb } from "components/containers/header/bread-crumb";
import { fonts } from "styles/theme";

import UserProfileStorage from "library/storage/userProfile";
import UserProfileContext from "library/contexts/userProfile";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { request } from "library/utils/request";
import { processShopSettingsResponse } from "library/utils/shopSettings";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";

import { selectCreateCardShopCode } from "library/sagas/views/home/drawer/create-card/selector";
import { selectImages } from "library/sagas/ongoing/global-data/selector";
import { fetchImageData } from "library/sagas/ongoing/global-data/slice";
import { setCreateCardShopCode } from "library/sagas/views/home/drawer/create-card/slice";
import { selectDashboardShopCode } from "library/sagas/ongoing/global-data/selector";

/* eslint-disable react-hooks/rules-of-hooks */
export default function CreateEnclosureCardScreen() {
  const dispatch = useDispatch();

  const { isDesktop, isTablet } = useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const { memberCodes = [] } = React.useContext(UserProfileContext);
  const currentShopCode = useSelector(selectCreateCardShopCode);
  const dashboardShopCode = useSelector(selectDashboardShopCode);
  const imagesData = useSelector(selectImages);
  const imageUrlRef = useRef("");

  const [updatedCardMessage, setUpdatedCardMessage] = useStateIfMounted("");
  const [updatedRecipient, setUpdatedRecipient] = useStateIfMounted("");
  const [cardSettings, setCardSettings] = useStateIfMounted("");
  const [fileInfo, setFileInfo] = useStateIfMounted({ uri: "", name: "" });

  const shopLocations = UserProfileStorage.getAllShopLocation();
  const shopLocation = shopLocations[currentShopCode] || {};
  const { countryCode, ...rest } = shopLocation || {};

  const receivingMember = {
    memberCode: currentShopCode,
    businessName: UserProfileStorage.getShopName(currentShopCode),
    phone: UserProfileStorage.getShopPhoneNumber(currentShopCode) || "",
    email: UserProfileStorage.getShopEmail(currentShopCode) || "",
    timezone: UserProfileStorage.getShopTimeZone(currentShopCode),
    ...rest,
    country: countryCode,
  };

  useEffect(() => {
    if (dashboardShopCode !== "all") {
      dispatch(setCreateCardShopCode(dashboardShopCode));
    }
    if (memberCodes.length === 1) {
      dispatch(setCreateCardShopCode(memberCodes[0]));
    }
    return () => {
      dispatch(setCreateCardShopCode(""));
    };
  }, []);

  useEffect(() => {
    if (!["", "all"].includes(currentShopCode)) {
      const preferences =
        UserProfileStorage.getShopPreferences(currentShopCode);
      let { card_settings = "{}" } = preferences;

      if (!isObject(card_settings)) {
        card_settings =
          (!isEmpty(card_settings) && JSON.parse(card_settings)) || {};
      }

      //Fetching card settings if they are not available in UserProfileStorage
      if (isEmpty(card_settings)) {
        request("get-shop-settings", {
          ids: ["card_settings"],
          memberCode: currentShopCode,
        }).then((res) => {
          if (res && res.preferences && res.preferences.length) {
            let result = processShopSettingsResponse(res);
            const cardSettingsResult = get(result, "card_settings", "{}");
            setCardSettings(
              JSON.parse(
                !isEmpty(cardSettingsResult) ? cardSettingsResult : "{}"
              )
            );
            UserProfileStorage.setShopPreferences(currentShopCode, {
              ...preferences,
              ...result,
            });
          }
        });
      } else {
        setCardSettings(card_settings);
      }
    }
  }, [currentShopCode]);

  useEffect(() => {
    const { isLogoOpted, isBannerOpted, logoImageURL, bannerImageURL } =
      cardSettings;
    let imageUrl = "";

    if (isLogoOpted) {
      imageUrl = logoImageURL;
    } else if (isBannerOpted) {
      imageUrl = bannerImageURL;
    }
    imageUrlRef.current = imageUrl;

    if (imageUrl) {
      if (!imagesData[imageUrl]) {
        dispatch(fetchImageData(imageUrl));
      } else {
        const imageData = imagesData[imageUrlRef.current];
        setFileInfo({
          uri: imageData ? `data:image/png;base64,${imageData}` : "",
          name: imageUrlRef.current,
        });
      }
    } else {
      setFileInfo({ uri: "", name: "" });
    }
  }, [cardSettings]);

  // Once ImagesData is updated then set the fileInfo
  useEffect(() => {
    if (imageUrlRef.current) {
      const imageData = imagesData[imageUrlRef.current];
      setFileInfo({
        uri: imageData ? `data:image/png;base64,${imageData}` : "",
        name: imageUrlRef.current,
      });
    }
  }, [imagesData]);

  return (
    <KeyboardAvoidingView
      style={tw("flex-1")}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      enabled
    >
      <>
        <ScreenHeader
          title={Localise(messages, "Enclosure Card")}
          settingsRoute={{
            page: "shop-settings",
            screen: "shop-details",
          }}
        />
        <SingleScreen>
          {!isDesktop && (
            <View style={tw("pb-4")}>
              <Text
                style={{
                  ...fonts.heading3,
                  fontWeight: "bold",
                  ...(isTablet && {
                    fontWeight: "400",
                  }),
                }}
              >
                {Localise(messages, "Enclosure Card")}
              </Text>
              <Breadcrumb />
            </View>
          )}
          <View style={tw("pb-10 mb-10")}>
            {memberCodes.length > 1 && (
              <View style={tw("flex flex-col")}>
                <Text style={{ fontSize: 12, paddingBottom: 6 }}>
                  {Localise(messages, "Shop Selection")}
                </Text>
                <Picker
                  containerStyle={{
                    width: 180,
                    height: "auto",
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                  }}
                  items={memberCodes.map((each) => ({
                    label: each,
                    value: each,
                  }))}
                  placeholder={{ label: "Select Shop", value: "" }}
                  value={currentShopCode}
                  onValueChange={(shop) =>
                    dispatch(setCreateCardShopCode(shop))
                  }
                ></Picker>
              </View>
            )}
            {!["all", ""].includes(currentShopCode) && (
              <EnclosureCard
                updatedCardMessage={updatedCardMessage}
                setUpdatedCardMessage={(message) => {
                  setUpdatedCardMessage(message);
                }}
                updatedRecipient={updatedRecipient}
                setUpdatedRecipient={(message) => {
                  setUpdatedRecipient(message);
                }}
                fileInfo={fileInfo}
                cardSettings={cardSettings}
                isSmallScreen={!isDesktop}
                isPickUpOrder={false}
                orderSource={""}
                receivingMember={receivingMember}
                recipientInfo={{
                  firstName: "Test",
                  lastName: "User",
                  addressLine1: "Address Line1",
                  phone: "800-669-1000",
                  city: "New York",
                  state: "NY",
                  zip: "60515",
                  country: "US",
                }}
                sourceMemberCode={currentShopCode}
                variant="adhoc-trifold"
              />
            )}
            <PrintIframeRenderer testID={"adhoc-trifold-card-frame"} />
            {!isDesktop && <View style={{ height: 150 }}></View>}
          </View>
        </SingleScreen>
      </>
    </KeyboardAvoidingView>
  );
}
