import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, TouchableOpacity, Image, Platform, Linking } from "react-native";
import { Text, CheckBox } from "react-native-elements";

import { TabBar, Spinner, ToasterHandler, Picker } from "components/elements";
import {
  LabelField,
  Section,
  CollectionSearch,
  ConfirmModal,
} from "components/wrappers";
import { Form, FormFieldSwitch } from "components/elements/forms";

import UserProfileContext from "library/contexts/userProfile";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import {
  selectApiResponse,
  selectExtendedScreen,
  selectWeddingPageAccordions,
  selectWeddingTabs,
  selectAllCollections,
} from "library/sagas/views/home/drawer/ftd-admin/mol-settings/selector";
import {
  setApiResponse,
  fetchMiddlewareData,
  saveMiddlewareData,
  setComponentStatus,
} from "library/sagas/views/home/drawer/ftd-admin/mol-settings/slice";
import {
  selectShopCode,
  selectAllowSyndicate,
} from "library/sagas/views/home/drawer/mercury-online/selector";
import UserProfileStorage from "library/storage/userProfile";

import { fonts, theme } from "styles/theme";
import tw from "tailwind-rn";
import isArray from "lodash/isArray";
import { useFormikContext } from "formik";
import IMAGES from "static/assets/images";

import { getValidationSchema } from "../yup";
import {
  BannerMetaData,
  ImageUploadField,
  CustomRTE,
  CustomInput,
  SaveCancelButtons,
} from "../../helper";

const WeddingPageAccordions = ({
  page,
  defaultContext,
  defaultAssetId,
  overrideHeader,
  weddingPageContent,
}) => {
  const dispatch = useDispatch();
  const allowSyndicate = useSelector(selectAllowSyndicate);
  const apiResponse = useSelector(selectApiResponse);
  const { context = defaultContext, assetId = defaultAssetId } =
    useSelector(selectExtendedScreen);
  const tabNames = useSelector(selectWeddingTabs);
  const { isMobile } = useContext(DeviceContext);
  const { userRole } = React.useContext(UserProfileContext);
  const { Localise, messages } = useContext(I18NContext);
  const sectionRef = React.useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  const {
    component,
    fetching,
    content,
    error,
    title,
    activeTab,
    actualContext,
  } = page;

  const displayTabNames = tabNames.map((t) => ({
    ...t,
    title:
      component === "wedding" && t.title === "Collection Sets"
        ? "Collections"
        : t.title,
  }));

  const displayFilteredTabs = ![
    "wedding",
    "weddingEventsPortfolio",
    "weddingScheduleConsultation",
  ].includes(component)
    ? displayTabNames.filter(
        (val) =>
          !["events-portfolio", "create_portfolio_event"].includes(val.key)
      )
    : component === "weddingEventsPortfolio"
    ? displayTabNames.filter((val) =>
        [
          "banner",
          "seo_content",
          "schedule-consultation",
          ...(userRole !== "FTD_ADMIN" ? ["create_portfolio_event"] : []),
        ].includes(val.key)
      )
    : component === "weddingScheduleConsultation"
    ? [
        { key: "header", title: "Banner" },
        { key: "consultation_form", title: "Consultation Form" },
        { key: "events-portfolio", title: "Events Portfolio" },
        { key: "seo_content", title: "SEO" },
      ]
    : displayTabNames.filter(
        (val) => !["create_portfolio_event"].includes(val.key)
      );

  const validationSchema = getValidationSchema(
    component,
    title,
    Localise,
    messages
  );

  const onSubmit = (values, formikBag) => {
    dispatch(
      saveMiddlewareData({
        params: { values },
        resolve: () => {
          ToasterHandler(
            "looks good",
            Localise(messages, `${title} content has been updated.`)
          );
          formikBag.setSubmitting(false);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  };

  useEffect(() => {
    dispatch(fetchMiddlewareData({ component, context, assetId }));
  }, []);

  if (fetching || apiResponse?.component !== "landingPageControls")
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );

  return (
    <Form
      initialValues={{
        component,
        context,
        assetId,
        content,
        error,
        pageControls: apiResponse?.content ?? "",
      }}
      onSubmit={allowSyndicate ? () => setShowPopup(!showPopup) : onSubmit}
      validationSchema={validationSchema}
      render={({ values, ...formikBag }) => {
        const pageControls =
          values?.pageControls && JSON.parse(values.pageControls);
        const { wedding } = pageControls;
        const pageStatus =
          component !== "wedding"
            ? pageControls?.wedding?.[component]?.status ?? false
            : true;

        const apiContent =
          apiResponse?.content && JSON.parse(apiResponse?.content);

        const showSection =
          component !== "wedding"
            ? apiContent?.wedding?.[component]?.status ?? false
            : true;

        const setValue = (field, value) => {
          formikBag.setFieldValue(field, JSON.stringify(value));
        };
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          dispatch(setComponentStatus({ component, dirty: formikBag.dirty }));
        }, [formikBag.dirty]);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          !showSection && sectionRef.current.setAccordion(false);
        }, [showSection]);

        return (
          <Section
            title={title}
            defaultOpen={false}
            disableAccordion={!showSection}
            parentRef={sectionRef}
          >
            {overrideHeader &&
              overrideHeader({
                component,
                actualContext,
                context,
                assetId,
                title,
              })}
            {component !== "wedding" && (
              <View style={tw("flex flex-row items-center px-2 pt-1 pb-2")}>
                <Text
                  style={{
                    ...fonts.heading4,
                    marginRight: 10,
                  }}
                >
                  {Localise(messages, `Enable ${title} Landing Page`)}
                </Text>
                <FormFieldSwitch
                  value={pageStatus}
                  onValueChange={(val) => {
                    const collections = weddingPageContent.collectionSets.map(
                      (set) =>
                        component === set.id ? { ...set, show: val } : set
                    );
                    dispatch(
                      setApiResponse({
                        component: "wedding",
                        content: JSON.stringify({
                          ...weddingPageContent,
                          collectionSets: collections,
                        }),
                      })
                    );
                    setValue("pageControls", {
                      ...pageControls,
                      wedding: {
                        ...wedding,
                        [component]: { status: val },
                      },
                    });
                  }}
                />
                {!isMobile && (
                  <LabelField
                    text={
                      pageStatus
                        ? Localise(messages, "Active")
                        : Localise(messages, "Inactive")
                    }
                    style={{
                      ...fonts.heading5,
                      paddingHorizontal: 10,
                      marginTop: 3,
                    }}
                  />
                )}
              </View>
            )}
            <View style={tw("flex flex-col px-2 py-3")}>
              <TabBar
                containerStyle={tw("w-full")}
                scrollTabBar
                showBorder={false}
                scrollContainerStyle={tw(
                  Platform.OS === "web" ? "mb-4" : "mb-1"
                )}
                showsScrollIndicators
                showsScrollButtons
                tabNames={displayFilteredTabs}
                currentTab={activeTab}
                style={{
                  marginTop: -10,
                  justifyContent: "space-between",
                }}
                setCurrentTab={(activeTab) =>
                  dispatch(
                    setApiResponse({
                      component,
                      activeTab,
                    })
                  )
                }
              />
              {component !== "weddingScheduleConsultation" &&
              activeTab === "banner" ? (
                <BannerMetaData field={"content"} group={"banner"} />
              ) : activeTab === "collection_list" ? (
                component === "wedding" ? (
                  <WeddingPageCollectionTiles />
                ) : (
                  <WeddingCollectionSets />
                )
              ) : activeTab === "featured_collection" ? (
                <FeaturedCollection />
              ) : activeTab === "seo_content" ? (
                <SEOContent />
              ) : activeTab === "schedule-consultation" ? (
                <ScheduleConsultation />
              ) : activeTab === "events-portfolio" ? (
                (component === "wedding" ||
                  component === "weddingScheduleConsultation") && (
                  <BannerMetaData
                    field={"content"}
                    group={"eventsPortfolio"}
                    allowVideoBanner={true}
                  />
                )
              ) : activeTab === "header" ? (
                <BannerMetaData
                  field={"content"}
                  group={"banner"}
                  singleBanner={true}
                  buttonRequired={false}
                />
              ) : activeTab === "create_portfolio_event" ? (
                <ImageGallery />
              ) : (
                <ConsultationForm />
              )}
            </View>
            <SaveCancelButtons />
            <ConfirmModal
              modalVisible={!!showPopup}
              handlePrimary={() => {
                onSubmit(values, formikBag);
                setShowPopup(!showPopup);
              }}
              handleSecondary={() => {
                setShowPopup(!showPopup);
                formikBag.setSubmitting(false);
              }}
              data={{
                modal: {
                  primary: "Update",
                  content: Localise(
                    messages,
                    "This change will apply to All shops and will override any local shop changes."
                  ),
                },
              }}
            />
          </Section>
        );
      }}
    />
  );
};

const ImageGallery = () => {
  const shopCode = useSelector(selectShopCode);
  const { Localise, messages } = useContext(I18NContext);

  const openURL = (uri) => {
    const { memberCodes = [] } = UserProfileStorage.getAuthGroup();
    const { shopify_store_url: shopifyAdmin } =
      UserProfileStorage.getShopPreferences(
        shopCode === "all" ? memberCodes[0] : shopCode
      );

    const link = `${shopifyAdmin}${uri}`;

    Platform.OS === "web" ? window.open(link) : Linking.openURL(link);
  };

  return (
    <View style={tw("flex flex-row flex-1 flex-wrap pl-1 my-3")}>
      <Text style={fonts.heading4}>
        {Localise(messages, "Click to customize the")}{" "}
      </Text>
      <Text
        style={fonts.link1}
        onPress={() => openURL("/admin/articles/new")}
        numberOfLines={1}
        ellipsizeMode={"tail"}
      >
        {Localise(messages, "Create Portfolio")}
      </Text>
      <Text style={fonts.heading4}>
        {" "}
        {Localise(messages, "on your Shopify website")}
      </Text>
    </View>
  );
};

const ConsultationForm = () => {
  const { Localise, messages } = useContext(I18NContext);
  const { setFieldValue, values, errors, dirty } = useFormikContext();
  const { isMobile, isTablet } = useContext(DeviceContext);

  const actualContent = JSON.parse(values.content);
  const { consultationForm } = actualContent;
  const { fields = [] } = consultationForm;
  const [currentLimit, setCurrentLimit] = useState(0);
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    viewMore && setCurrentLimit(fields.length);
    !dirty && setCurrentLimit(4);
  }, [dirty, fields.length]);

  const fieldsQuestions = fields.slice(0, currentLimit);
  const setValue = (field, value) => {
    setFieldValue(field, JSON.stringify(value));
  };

  return (
    <>
      <Text
        style={{
          ...fonts.heading4,
          padding: 5,
          paddingLeft: 5,
        }}
      >
        {Localise(messages, "Add, Remove or Edit Questions")}
      </Text>
      <Text
        style={{
          ...fonts.style2,
          paddingTop: 4,
          paddingLeft: 5,
          paddingBottom: 8,
        }}
      >
        {Localise(
          messages,
          "Select the checkbox for mandatory fields in the form"
        )}
      </Text>
      {fields.length === 0 ? (
        <>
          <CustomInput
            input={""}
            containerStyle={{ width: 280 }}
            onChangeText={(_, val) => {
              fields[0] = { ...fields[0], question: val };
              setValue("content", {
                ...actualContent,
                consultationForm: {
                  ...consultationForm,
                  fields,
                },
              });
            }}
            placeholder={Localise(messages, "Enter Questions")}
          />
        </>
      ) : (
        <>
          {fieldsQuestions.map((value, index) => {
            return (
              <View
                style={tw("flex flex-row w-full items-center flex-wrap")}
                key={index}
              >
                <Text style={{ marginRight: 10 }}>
                  {index < 9 ? `${index + 1}  ` : index + 1}
                </Text>
                <CustomInput
                  input={value.question}
                  containerStyle={{ width: 280 }}
                  onChangeText={(_, val) => {
                    fields[index] = { ...fields[index], question: val };
                    setValue("content", {
                      ...actualContent,
                      consultationForm: {
                        ...consultationForm,
                        fields,
                      },
                    });
                  }}
                  placeholder={Localise(messages, "Enter Questions")}
                />
                <View
                  style={tw(
                    `flex flex-row flex-wrap items-center justify-center ${
                      isMobile ? "ml-2" : ""
                    }`
                  )}
                >
                  <Picker
                    containerStyle={{
                      paddingHorizontal: 0,
                      paddingLeft: isMobile ? 0 : 10,
                      paddingTop: isTablet ? 10 : 0,
                      width: 150,
                      marginLeft: 20,
                    }}
                    items={[
                      ...(value.inputType === "email"
                        ? [{ label: "Email", value: "email" }]
                        : [
                            { label: "Text", value: "text" },
                            { label: "Number", value: "number" },
                            { label: "Phone", value: "tel" },
                            { label: "URL", value: "url" },
                            { label: "Text Area", value: "textarea" },
                            { label: "Date", value: "date" },
                          ]),
                    ]}
                    placeholder={{
                      label: Localise(messages, "Select input type"),
                      value: "",
                    }}
                    disabled={value.inputType === "email"}
                    value={value.inputType}
                    onValueChange={(val) => {
                      fields[index] = { ...fields[index], inputType: val };
                      setValue("content", {
                        ...actualContent,
                        consultationForm: {
                          ...consultationForm,
                          fields,
                        },
                      });
                    }}
                  />
                  <CheckBox
                    containerStyle={[
                      theme.CheckBox.inputContainerStyle,
                      {
                        padding: 0,
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 0,
                      },
                    ]}
                    disabled={value.inputType === "email"}
                    size={20}
                    checked={!!value.isRequired}
                    onPress={() => {
                      fields[index] = {
                        ...fields[index],
                        isRequired: !value.isRequired,
                      };
                      setValue("content", {
                        ...actualContent,
                        consultationForm: {
                          ...consultationForm,
                          fields,
                        },
                      });
                    }}
                    checkedIcon={
                      <Image
                        source={IMAGES["checkbox-checked"]}
                        style={{ width: 20, height: 20 }}
                      />
                    }
                    uncheckedIcon={
                      <Image
                        source={IMAGES["checkbox-unchecked"]}
                        style={{ width: 20, height: 20 }}
                      />
                    }
                  />
                  {value.inputType !== "email" && (
                    <TouchableOpacity
                      onPress={() => {
                        fields.splice(index, 1);
                        setValue("content", {
                          ...actualContent,
                          consultationForm: {
                            ...consultationForm,
                            fields,
                          },
                        });
                      }}
                      testID={"Remove_Question_Type"}
                      style={tw("pl-1")}
                    >
                      <Image
                        style={{
                          width: 17,
                          height: 17,
                          transform: [{ rotate: "90deg" }],
                        }}
                        resizeMode={"cover"}
                        source={IMAGES["close"]}
                      />
                    </TouchableOpacity>
                  )}
                </View>
              </View>
            );
          })}
        </>
      )}

      <View style={tw("flex flex-row items-center justify-between")}>
        <TouchableOpacity
          testID={"addNewQuestion"}
          accessibilityLabel={"addNewQuestion"}
          style={{ padding: 5 }}
        >
          <Text
            style={fonts.link1}
            onPress={() => {
              fields.push("");
              setValue("content", {
                ...actualContent,
                consultationForm: {
                  ...consultationForm,
                  fields,
                },
              });
              setViewMore(true);
            }}
          >
            {"[+] "}
            {Localise(messages, "Add a question")}
          </Text>
        </TouchableOpacity>
        {currentLimit < fields.length && currentLimit !== fields.length && (
          <TouchableOpacity
            testID={"viewMore"}
            accessibilityLabel={"viewMore"}
            style={{ padding: 5 }}
          >
            <Text
              style={fonts.link1}
              onPress={() => {
                setCurrentLimit(currentLimit + 2);
              }}
            >
              {"[+] "}
              {Localise(messages, "View More")}
            </Text>
          </TouchableOpacity>
        )}
      </View>
      {errors ? (
        <Text style={theme.CheckBox.errorStyle}>{errors.content}</Text>
      ) : null}
    </>
  );
};

const WeddingPageCollectionTiles = () => {
  const { Localise, messages } = useContext(I18NContext);
  const { isMobile } = useContext(DeviceContext);
  const { setFieldValue, values, errors } = useFormikContext();

  const actualContent = JSON.parse(values.content);
  const { collectionSets } = actualContent;
  const pageControls = values?.pageControls && JSON.parse(values.pageControls);
  const { wedding } = pageControls;
  const collectionFields = {
    image: "",
    title: "",
    subTitle: "",
    altText: "",
    aspectRatio: "",
    buttonLabel: "",
    buttonLink: "",
    alignment: "center center",
    show: true,
  };
  const collectionsList = isArray(collectionSets)
    ? collectionSets
    : [collectionFields, collectionFields, collectionFields];

  const setValue = (field, value) => {
    setFieldValue(field, JSON.stringify(value));
  };

  return (
    <>
      {collectionsList.map((collection, index) => {
        const pageStatus = wedding?.[collection?.id ?? ""]?.status ?? false;
        const excludeCheck = ![
          "weddingEventsPortfolio",
          "weddingScheduleConsultation",
        ].includes(collection?.id);

        return (
          <React.Fragment key={index}>
            <View style={tw("pb-4")}>
              <View style={tw("flex flex-row pb-2")}>
                <Text
                  style={{
                    ...fonts.heading4,
                    paddingLeft: 5,
                    paddingRight: 10,
                  }}
                >
                  {Localise(messages, "Enable")}{" "}
                  {Localise(messages, `${collection?.title ?? ""}`)}
                </Text>
                <FormFieldSwitch
                  value={pageStatus}
                  onValueChange={(val) => {
                    const collections = collectionsList.map((set, i) =>
                      i === index ? { ...set, show: val } : set
                    );

                    excludeCheck &&
                      setValue("content", {
                        ...actualContent,
                        collectionSets: collections,
                      });
                    setValue("pageControls", {
                      ...pageControls,
                      wedding: {
                        ...wedding,
                        [collection?.id ?? ""]: { status: val },
                      },
                    });
                  }}
                />
                {!isMobile && (
                  <LabelField
                    text={
                      pageStatus
                        ? Localise(messages, "Active")
                        : Localise(messages, "Inactive")
                    }
                    style={{
                      ...fonts.heading5,
                      paddingHorizontal: 10,
                      marginTop: 3,
                    }}
                  />
                )}
              </View>
              {collection?.id !== "weddingEventsPortfolio" &&
                collection?.id !== "weddingScheduleConsultation" && (
                  <>
                    <Text
                      style={{
                        ...fonts.heading4,
                        padding: 5,
                        paddingLeft: 5,
                      }}
                    >
                      {Localise(messages, "Title")}
                    </Text>
                    <CustomInput
                      input={collection?.title ?? ""}
                      onChangeText={(_, val) => {
                        const collections = collectionsList.map((set, i) =>
                          i === index ? { ...set, title: val } : set
                        );
                        setValue("content", {
                          ...actualContent,
                          collectionSets: collections,
                        });
                      }}
                      placeholder={Localise(messages, "Enter Title")}
                    />
                    <ImageUploadField
                      field={"image"}
                      title={"Image"}
                      imageType={"image"}
                      input={collection?.image ?? ""}
                      setImageValue={(name, val) => {
                        const collections = collectionsList.map((set, i) =>
                          i === index ? { ...set, [name]: val } : set
                        );
                        setValue("content", {
                          ...actualContent,
                          collectionSets: collections,
                        });
                      }}
                      dimensions={"1024x1024"}
                      dimensionsInfo={
                        "Recommended Square Image size is 1024px x 1024px"
                      }
                      imageResizeMode={"contain"}
                    />
                    <View style={tw("my-2")}>
                      <Text
                        style={{
                          ...fonts.heading4,
                          padding: 5,
                          paddingLeft: 5,
                        }}
                      >
                        {Localise(messages, "SEO Text")}
                      </Text>
                      <CustomInput
                        input={collection?.description ?? ""}
                        onChangeText={(_, val) => {
                          const collections = collectionsList.map((set, i) =>
                            i === index ? { ...set, description: val } : set
                          );
                          setValue("content", {
                            ...actualContent,
                            collectionSets: collections,
                          });
                        }}
                        placeholder={Localise(messages, "Enter Text")}
                      />
                    </View>
                    <CustomInput
                      field={"buttonLabel"}
                      title={"Button Label"}
                      input={collection?.buttonText}
                      onChangeText={(_, value) => {
                        const collections = collectionsList.map((set, i) =>
                          i === index ? { ...set, buttonText: value } : set
                        );
                        setValue("content", {
                          ...actualContent,
                          collectionSets: collections,
                        });
                      }}
                    />
                    <CustomInput
                      field={"buttonLink"}
                      title={"Button Link"}
                      input={collection?.buttonLink}
                      onChangeText={(_, value) => {
                        const collections = collectionsList.map((set, i) =>
                          i === index ? { ...set, buttonLink: value } : set
                        );
                        setValue("content", {
                          ...actualContent,
                          collectionSets: collections,
                        });
                      }}
                    />

                    {index < collectionsList.length - 1 && (
                      <View
                        style={{
                          borderBottomColor: "#bbbbbb",
                          borderBottomWidth: 1,
                          padding: 10,
                        }}
                      />
                    )}
                  </>
                )}
            </View>
          </React.Fragment>
        );
      })}
      {errors ? (
        <Text style={theme.CheckBox.errorStyle}>{errors.content}</Text>
      ) : null}
    </>
  );
};

const WeddingCollectionSets = () => {
  const { data: collectionData, error } = useSelector(selectAllCollections);
  const { setFieldValue, values, errors, submitCount } = useFormikContext();
  const { Localise, messages } = useContext(I18NContext);

  const actualContent = JSON.parse(values.content);
  const { collectionSets } = actualContent;
  const { collections = [] } = collectionSets;

  const setValue = (field, value) => {
    setFieldValue(field, JSON.stringify(value));
  };

  return (
    <>
      <Text
        style={{
          ...fonts.heading4,
          padding: 5,
          paddingLeft: 5,
        }}
      >
        {Localise(messages, "Header Text")}
      </Text>
      <CustomInput
        input={collectionSets?.title ?? ""}
        onChangeText={(_, val) => {
          setValue("content", {
            ...actualContent,
            collectionSets: { ...collectionSets, title: val },
          });
        }}
        placeholder={Localise(messages, "Enter Title")}
      />
      {collections.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Text
              style={{
                ...fonts.heading4,
                padding: 5,
                paddingLeft: 5,
              }}
            >
              {Localise(messages, `Collection ${index + 1}`)}
            </Text>
            <View style={tw("flex flex-row items-center")}>
              <CollectionSearch
                setInputData={(val) => {
                  collections[index] = val;
                  setValue("content", {
                    ...actualContent,
                    collectionSets: {
                      ...collectionSets,
                      collections,
                    },
                  });
                }}
                selectorData={collectionData}
                error={error}
                inputValue={item ?? ""}
                type={"handle"}
                referenceData={collections.map((handle) => {
                  return {
                    handle,
                  };
                })}
                popperPlacement={"top"}
              />
              <TouchableOpacity
                onPress={() => {
                  collections.splice(index, 1);
                  setValue("content", {
                    ...actualContent,
                    collectionSets: {
                      ...collectionSets,
                      collections,
                    },
                  });
                }}
                testID={"Remove_Collection_Type"}
                style={tw("pl-1 mb-3")}
              >
                <Image
                  style={{
                    width: 17,
                    height: 17,
                    transform: [{ rotate: "90deg" }],
                  }}
                  resizeMode={"cover"}
                  source={IMAGES["close"]}
                />
              </TouchableOpacity>
            </View>
          </React.Fragment>
        );
      })}
      <TouchableOpacity
        testID={"AddNewCollection"}
        accessibilityLabel={"AddNewCollection"}
        style={{ padding: 5 }}
      >
        <Text
          style={fonts.link1}
          onPress={() => {
            collections.push("");
            setValue("content", {
              ...actualContent,
              collectionSets: {
                ...collectionSets,
                collections,
              },
            });
          }}
        >
          {"[+] "}
          {Localise(messages, "Add New Collection")}
        </Text>
      </TouchableOpacity>
      {errors && submitCount > 0 ? (
        <Text style={theme.CheckBox.errorStyle}>{errors.content}</Text>
      ) : null}
    </>
  );
};

const FeaturedCollection = () => {
  const { data: collectionData, error } = useSelector(selectAllCollections);
  const { Localise, messages } = useContext(I18NContext);
  const { setFieldValue, values } = useFormikContext();

  const actualContent = JSON.parse(values.content);
  const { featuredCollection } = actualContent;

  const setValue = (field, value) => {
    setFieldValue(field, JSON.stringify(value));
  };

  return (
    <>
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Header Text")}
      </Text>
      <CustomInput
        input={featuredCollection?.title ?? ""}
        onChangeText={(_, val) => {
          setValue("content", {
            ...actualContent,
            featuredCollection: { ...featuredCollection, title: val },
          });
        }}
        placeholder={Localise(messages, "Enter Header Text")}
      />
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Collection")}
      </Text>
      <CollectionSearch
        setInputData={(val) =>
          setValue("content", {
            ...actualContent,
            featuredCollection: {
              ...featuredCollection,
              collectionHandle: val,
            },
          })
        }
        selectorData={collectionData}
        error={error}
        type={"handle"}
        inputValue={featuredCollection?.collectionHandle ?? ""}
        referenceData={[
          {
            handle: featuredCollection?.collectionHandle,
          },
        ]}
        popperPlacement={"top"}
      />
    </>
  );
};

const SEOContent = () => {
  const { Localise, messages } = useContext(I18NContext);
  const { setFieldValue, values } = useFormikContext();
  const { contentStatus } = values;

  const actualContent = JSON.parse(values.content);
  const { seo } = actualContent;

  const setValue = (field, value) => {
    setFieldValue(field, JSON.stringify(value));
  };

  return (
    <>
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Title")}
      </Text>
      <CustomInput
        input={seo?.footer?.title ?? ""}
        onChangeText={(_, val) => {
          setValue("content", {
            ...actualContent,
            seo: {
              ...seo,
              footer: {
                ...seo.footer,
                title: val,
              },
            },
          });
        }}
        placeholder={Localise(messages, "Enter SEO Title")}
      />
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Description")}
      </Text>
      <CustomRTE
        value={seo?.footer?.entry ?? ""}
        placeholder={Localise(messages, "Enter SEO Description")}
        setValue={(val) => {
          setValue("content", {
            ...actualContent,
            seo: {
              ...seo,
              footer: {
                ...seo.footer,
                entry: val,
              },
            },
          });
        }}
        onEditorStateChange={() => {
          if (contentStatus) return;
          setFieldValue("contentStatus", true);
        }}
      />
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Page Title")}
      </Text>
      <CustomInput
        input={seo?.pageTitle ?? ""}
        onChangeText={(_, val) =>
          setValue("content", {
            ...actualContent,
            seo: {
              ...seo,
              pageTitle: val,
            },
          })
        }
        placeholder={Localise(messages, "Enter Page Title")}
      />
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Page Meta Description")}
      </Text>
      <CustomInput
        input={seo?.pageDescription ?? ""}
        onChangeText={(_, val) =>
          setValue("content", {
            ...actualContent,
            seo: {
              ...seo,
              pageDescription: val,
            },
          })
        }
        placeholder={Localise(messages, "Enter Page Meta Description")}
        multiline={true}
        numberOfLines={4}
        inputStyle={{
          ...theme.Input.inputStyle,
          minHeight: "auto",
          height: "auto",
        }}
        inputContainerStyle={{
          minHeight: "auto",
          height: "auto",
        }}
      />
    </>
  );
};

const ScheduleConsultation = () => {
  // title entry buttonLabel Button Link
  const { Localise, messages } = useContext(I18NContext);
  const { setFieldValue, values, errors } = useFormikContext();
  const { contentStatus } = values;
  const actualContent = JSON.parse(values.content);
  const { scheduleConsultation } = actualContent;
  const setValue = (field, value) => {
    setFieldValue(field, JSON.stringify(value));
  };

  return (
    <>
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Title")}
      </Text>
      <CustomInput
        input={scheduleConsultation?.title ?? ""}
        onChangeText={(_, val) => {
          setValue("content", {
            ...actualContent,
            scheduleConsultation: {
              ...scheduleConsultation,
              title: val,
            },
          });
        }}
        placeholder={Localise(messages, "Enter SEO Title")}
      />
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Description")}
      </Text>
      <CustomRTE
        value={scheduleConsultation?.entry ?? ""}
        placeholder={Localise(messages, "Enter SEO Description")}
        setValue={(val) => {
          setValue("content", {
            ...actualContent,
            scheduleConsultation: {
              ...scheduleConsultation,
              entry: val,
            },
          });
        }}
        onEditorStateChange={() => {
          if (contentStatus) return;
          setFieldValue("contentStatus", true);
        }}
      />
      <CustomInput
        field={"buttonLabel"}
        title={"Button Label"}
        input={scheduleConsultation?.buttonLabel}
        onChangeText={(_, value) => {
          setValue("content", {
            ...actualContent,
            scheduleConsultation: {
              ...scheduleConsultation,
              buttonLabel: value,
            },
          });
        }}
      />
      <CustomInput
        field={"buttonLink"}
        title={"Button Link"}
        input={scheduleConsultation?.buttonLink}
        onChangeText={(_, value) => {
          setValue("content", {
            ...actualContent,
            scheduleConsultation: {
              ...scheduleConsultation,
              buttonLink: value,
            },
          });
        }}
      />
      {errors ? (
        <Text style={theme.CheckBox.errorStyle}>{errors.content}</Text>
      ) : null}
    </>
  );
};

const Wedding = ({ defaultContext, defaultAssetId, overrideHeader }) => {
  const dispatch = useDispatch();
  const { context = defaultContext, assetId = defaultAssetId } =
    useSelector(selectExtendedScreen);
  const accordions = useSelector(selectWeddingPageAccordions);

  const weddingPageData =
    accordions.length > 0 &&
    accordions
      .map((item) => {
        return item.component === "wedding" && item.content;
      })
      .filter(Boolean)[0];
  const weddingPageContent = weddingPageData && JSON.parse(weddingPageData);

  useEffect(() => {
    dispatch(
      fetchMiddlewareData({
        component: "landingPageControls",
        context,
        assetId,
      })
    );
  }, []);

  return accordions.map((page, index) => (
    <WeddingPageAccordions
      key={index}
      page={page}
      defaultContext={defaultContext}
      defaultAssetId={defaultAssetId}
      overrideHeader={overrideHeader}
      weddingPageContent={weddingPageContent}
    />
  ));
};

export default Wedding;
