import { selectNoop } from "library/sagas/views/home/drawer/customer-directory/selector";
import {
  selectScannedOrders,
  selectAppliedLimits,
} from "library/sagas/views/home/drawer/qr-scan/selector";
import {
  setMeatBallMenuActions,
  setPageAction,
  removeTileById,
} from "library/sagas/views/home/drawer/qr-scan/slice";

import Listing from "components/containers/listing-new";
import { OrderListItem } from "components/views/drawer/order-listing/orders-list";
import {
  ListOfColumns,
  ListOfMobileColumns,
  ListOfSubColumns,
  ListOfActions,
  ListOfSubActions,
} from "components/views/drawer/order-listing/current-orders/config";

export const getUIConfig = (Localise, messages, isDesktop, isMobile) => ({
  sections: [
    {
      section: Localise(messages, "Scanned Orders"),
      component: {
        name: Listing,
        props: {
          showHeader: false,
          scrollContainerStyles: {
            padding: 0,
          },
          selectors: null,
          sections: [
            {
              section: "orders",
              component: {
                props: {
                  noRecordsMessage: Localise(messages, "No Orders Yet!"),
                  viewMore: {
                    label: Localise(messages, "View More"),
                    value: 10,
                    initial: 10,
                  },
                  selectors: {
                    dataSelector: selectScannedOrders,
                    limitSelector: selectAppliedLimits,
                    accordionSelector: selectNoop,
                  },
                  actions: { setPageAction },
                  itemComponent: {
                    name: OrderListItem,
                    props: {
                      countDownTimer: false,
                      readOnlyTile: true,
                      removeTile: true,
                      removeTileById,
                      columnsList: isMobile
                        ? ListOfMobileColumns
                        : ListOfColumns,
                      subColumnsList: ListOfSubColumns,
                      actionsList: ListOfActions,
                      subActionsList: ListOfSubActions,
                      isSmallScreen: !isDesktop,
                      isMobile,
                      listingType: "customerOrders",
                      setMeatBallMenuActions,
                    },
                  },
                },
              },
            },
          ],
        },
      },
    },
  ],
});
