import actionTypes from "library/actions/appSettings";

const initState = {
  menuRole: null,
  allowedMenus: [],
  permissions: {},
  isInactiveUser: false,
};

export const AppSettingsReducer = (prevState = initState, action) => {
  //console.log("AppSettingsReducer: action=", JSON.stringify(action));
  switch (action.type) {
    case actionTypes.SET_MENU:
      return {
        ...prevState,
        allowedMenus: action.menus,
        menuRole: action.role,
      };
    case actionTypes.RESET_MENU:
      return {
        ...prevState,
        menuRole: null,
        allowedMenus: [],
      };
    case actionTypes.SET_PERMISSIONS:
      return {
        ...prevState,
        permissions: action.permissions,
      };
    case actionTypes.RESET_PERMISSIONS:
      return {
        ...prevState,
        permissions: {},
      };
    case actionTypes.IS_INACTIVE_USER:
      return {
        ...prevState,
        isInactiveUser: action.isInactiveUser,
      };
  }
};

export default AppSettingsReducer;
