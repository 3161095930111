import React, { useEffect } from "react";
import { addLeadingZeros, toTimeSpan } from "library/utils/datetime";
import { Text } from "react-native-elements";
import { orderStatusColorCodes, colors } from "styles/theme";
import moment from "moment";
import useStateIfMounted from "library/utils/useStateIfMounted";

import Environment from "library/utils/environment";

const CountDownTimer = ({
  targetTime,
  setTimeLeftInMins,
  pauseCountDownTimer,
  countDownPauseTime,
  textStyle,
  isPickUpOrder = false,
  overrideColor = true,
  isOrderDetailCounter = false,
}) => {
  const [timeLeft, setTimeLeft] = useStateIfMounted("");
  const orderAcceptanceInterval = Environment.get(
    "ORDER_ACCEPTANCE_INTERVAL",
    110
  );
  useEffect(() => {
    const timer = setInterval(() => {
      let diff = targetTime
        ? targetTime.diff(!pauseCountDownTimer ? moment() : countDownPauseTime)
        : 0;
      if (diff / 60000 > orderAcceptanceInterval)
        diff = orderAcceptanceInterval * 60 * 1000;
      if (diff > 0 && !pauseCountDownTimer) setTimeLeft(diff);
      else {
        if (pauseCountDownTimer) setTimeLeft(diff);
        clearInterval(timer);
      }
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  if (!timeLeft || timeLeft < 0) return null;

  const truncate = timeLeft >= 0 ? Math.floor : Math.ceil;
  const timeLeftInMins = truncate(timeLeft / (1000 * 60));
  setTimeLeftInMins && setTimeLeftInMins(timeLeftInMins);

  const formattedTime = toTimeSpan(timeLeft);
  return (
    <Text
      style={{
        ...textStyle,
        ...(overrideColor && {
          color:
            timeLeftInMins < 10 && !isPickUpOrder
              ? orderStatusColorCodes.expiring
              : isOrderDetailCounter
              ? colors.highlighter
              : "",
        }),
      }}
    >
      {addLeadingZeros(formattedTime.hours) +
        ":" +
        addLeadingZeros(formattedTime.minutes) +
        ":" +
        addLeadingZeros(formattedTime.seconds)}
    </Text>
  );
};

export default CountDownTimer;
