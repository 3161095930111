import React, { useEffect, useMemo } from "react";
import moment from "moment-timezone";
import { ThemeProvider } from "react-native-elements";
import { MenuProvider } from "react-native-popup-menu";

import AppSettingsReducer from "library/reducers/appSettings";
import AppSettingsUtils from "library/utils/appSettings";
import AppSettingsStorage from "library/storage/appSettings";
import {
  AppSettingsContext,
  DeviceContext,
} from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import Environment from "library/utils/environment";
import { getDeviceInfo } from "library/utils/appSettings";
import { theme } from "styles/theme";
import toUpper from "lodash/toUpper";

export default function AppSettingsProvider({ children }) {
  const { proxyUser, userRole } = React.useContext(UserProfileContext);
  const isMountedRef = React.useRef(null);

  const [state, dispatch] = React.useReducer(AppSettingsReducer, {
    menuRole: AppSettingsStorage.getRole(),
    allowedMenus: AppSettingsStorage.getMenus(),
    permissions: AppSettingsStorage.getAllPermissions(),
    isInactiveUser: AppSettingsStorage.getIsInactiveUser(),
  });
  const { menuRole } = state;

  const deviceInfo = getDeviceInfo();
  const { deviceType, deviceOrientaion } = deviceInfo;

  // console.log("deviceOrientaion", deviceOrientaion);
  // console.log("windowWidth", windowWidth);
  // console.log("windowHeight", windowHeight);
  // console.log("dimensionMetric", dimensionMetric);
  // console.log("deviceType", deviceType);

  moment.tz.setDefault(Environment.get("DEFAULT_TIME_ZONE", ""));

  useEffect(() => {
    isMountedRef.current = true;

    if (proxyUser && userRole !== "ADMIN") {
      dispatch({ type: "RESET_MENU" });
      dispatch({ type: "RESET_PERMISSIONS" });
    }

    if (menuRole !== userRole) {
      if (userRole) {
        // load menus from role
        AppSettingsUtils.requestMenus(userRole)
          .then((res) => {
            const isFTDUser = [
              "FTD_ADMIN",
              "FTD_POWER_USER",
              "FTD_VIEW_ONLY",
              "FTD_CSR",
            ].includes(toUpper(userRole));

            if (!isFTDUser) {
              AppSettingsUtils.requestPermissionGroups()
                .then((permissionsGroups = {}) => {
                  let permissions = {};

                  Object.keys(permissionsGroups).map((memberCode) => {
                    permissions = {
                      ...permissions,
                      [memberCode]: permissionsGroups[memberCode]?.permissions,
                    };
                  });

                  AppSettingsStorage.setAllPermissions(permissions);

                  if (isMountedRef.current) {
                    dispatch({ type: "RESET_PERMISSIONS" });
                    dispatch({
                      type: "SET_PERMISSIONS",
                      permissions,
                    });
                  }
                })
                .finally(() => {
                  AppSettingsStorage.setMenus(res);
                  AppSettingsStorage.setRole(userRole);

                  if (isMountedRef.current) {
                    dispatch({ type: "RESET_MENU" });
                    dispatch({ type: "SET_MENU", menus: res, role: userRole });
                  }
                });
            } else {
              AppSettingsStorage.setMenus(res);
              AppSettingsStorage.setRole(userRole);

              if (isMountedRef.current) {
                dispatch({ type: "RESET_MENU" });
                dispatch({ type: "SET_MENU", menus: res, role: userRole });
              }
            }
          })
          .catch((error) => {
            // Handling inactivated member
            if (isMountedRef.current && error === "INACTIVE_ACCOUNT") {
              dispatch({ type: "IS_INACTIVE_USER", isInactiveUser: true });
              AppSettingsStorage.setIsInactiveUser(true);
            }
          });
      } else {
        // clear cache on signout
        AppSettingsStorage.clear();

        if (isMountedRef.current) {
          dispatch({ type: "RESET_MENU" });
          dispatch({ type: "RESET_PERMISSIONS" });
          dispatch({ type: "IS_INACTIVE_USER", isInactiveUser: false });
        }
      }
    }

    return () => (isMountedRef.current = false);
  }, [proxyUser, userRole]);

  const context = useMemo(
    () => ({
      setPermissions: (updatedPermissions) => {
        AppSettingsStorage.setAllPermissions(updatedPermissions);
        dispatch({ type: "RESET_PERMISSIONS" });
        dispatch({
          type: "SET_PERMISSIONS",
          permissions: updatedPermissions,
        });
      },
    }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <AppSettingsContext.Provider value={{ ...context, ...state }}>
        <DeviceContext.Provider
          value={{
            isMobile: deviceType === "mobile",
            isDesktop: deviceType === "desktop",
            isTablet: deviceType === "tablet",
            isPotrait: deviceOrientaion === "potrait",
            isLandscape: deviceOrientaion === "landscape",
          }}
        >
          <MenuProvider>{children}</MenuProvider>
        </DeviceContext.Provider>
      </AppSettingsContext.Provider>
    </ThemeProvider>
  );
}
