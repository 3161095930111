import { put, takeLatest, takeEvery, select } from "redux-saga/effects";

import {
  setPageInitialize,
  setPageInitFailure,
  fetchCitiesZipcodes,
  fetchCitiesFees,
  fetchAutoRoutes,
  deleteAutoRoute,
  fetchNonRoutedOrders,
  fetchDeliveryDashboardData,
  fetchRouteDetail,
  fetchZones,
  getOrderFromScan,
  getOrderForRoute,
  setZipInitialize,
  fetchCitiesZipcodesForShop,
  fetchCitiesFeesForShop,
} from "./slice";
import {
  handleFetchAutoRoutes,
  handleFetchCitiesZipcodes,
  handleFetchCitiesFees,
  handleDeleteAutoRoute,
  handleFetchNonRoutedOrders,
} from "./delivery";
import {
  handleFetchDeliveryDashboardData,
  handleFetchRouteDetail,
  handleFetchZones,
} from "./dashboard";
import {
  handlegetOrderFromScan,
  handleFetchOrdersInRoute,
  handleFetchZipCoverage,
  handleFetchCitiesFeesForShop,
  handleFetchCitiesZipcodesforShop,
} from "./route";

import { selectShopCode } from "./selector";

function* handlePageIntialize(action = {}) {
  const { deliveryDate, considerLocalOrders } = action.payload;
  try {
    yield put(fetchCitiesFees());
    const shopCode = yield select(selectShopCode);
    if (deliveryDate && shopCode)
      yield put(
        fetchNonRoutedOrders({ shopCode, deliveryDate, considerLocalOrders })
      );
  } catch (error) {
    yield put(setPageInitFailure());
  }
}

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeLatest(setPageInitialize.type, handlePageIntialize);
  yield takeEvery(fetchCitiesZipcodes.type, handleFetchCitiesZipcodes);
  yield takeLatest(fetchCitiesFees.type, handleFetchCitiesFees);
  yield takeLatest(deleteAutoRoute.type, handleDeleteAutoRoute);
  yield takeLatest(fetchAutoRoutes.type, handleFetchAutoRoutes);
  yield takeLatest(fetchNonRoutedOrders.type, handleFetchNonRoutedOrders);
  yield takeLatest(
    fetchDeliveryDashboardData.type,
    handleFetchDeliveryDashboardData
  );
  yield takeEvery(fetchRouteDetail.type, handleFetchRouteDetail);
  yield takeEvery(fetchZones.type, handleFetchZones);
  yield takeEvery(getOrderFromScan.type, handlegetOrderFromScan);
  yield takeLatest(getOrderForRoute.type, handleFetchOrdersInRoute);

  yield takeLatest(setZipInitialize.type, handleFetchZipCoverage);
  yield takeEvery(
    fetchCitiesZipcodesForShop.type,
    handleFetchCitiesZipcodesforShop
  );
  yield takeEvery(fetchCitiesFeesForShop.type, handleFetchCitiesFeesForShop);
}

export default watchSaga;
