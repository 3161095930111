import { createContext } from "react";
import noop from "lodash/noop";

/**
 * The context used for storing user access information
 *
 * @see 'providers/i18N'
 * @see 'reducers/i18N'
 */
const I18NContext = createContext({
  /**
   * The currently selected locale code.
   */
  currentLocale: "en",
  /**
   * The default locale code.
   */
  defaultLocale: "en",
  /**
   * The set of allowed locales.
   *
   * @type {Array}
   */
  allowedLocales: [],
  /**
   * Sets the current locale to the provided locale code.
   * @param locale the new locale code
   */
  setCurrentLocale: noop,
});

export default I18NContext;
