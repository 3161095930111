import * as Yup from "yup";

export const getApproveCreditFormValidationSchema = (
  Localise,
  messages,
  maxCreditAmount
) =>
  Yup.object().shape({
    approvedCreditAmount: Yup.string()
      .label("approvedCreditAmount")
      .required(Localise(messages, "Please Enter Amount"))
      .matches(/^\d+\.?\d*$/, Localise(messages, "Invalid Price"))
      .isValidCreditAmount(
        `${Localise(
          messages,
          `Amount should not be greater than`
        )} $${maxCreditAmount}`
      ),
  });

Yup.addMethod(Yup.string, "isValidCreditAmount", function (errorMessage) {
  return this.test(
    `is-valid-credit-amount`,
    errorMessage,
    function (value = "") {
      if (!value) return true;
      const { approvedCreditAmount, maxCreditAmount } = this.parent;
      const hasInvalidamount =
        parseFloat(approvedCreditAmount) > parseFloat(maxCreditAmount);

      return !hasInvalidamount;
    }
  );
});
