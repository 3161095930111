import { createStyles, minWidth } from "react-native-media-queries";
import { colors, backgroundColors } from "styles/theme";

export default createStyles(
  {
    recordContainer: {
      flexDirection: "column",
      justifyContent: "space-between",
      borderWidth: 1,
      borderBottomWidth: 8,
      borderColor: colors.grayScaleLight,
      borderRadius: 3,
      minHeight: 50,
      marginBottom: 5,
      marginRight: 5,
      padding: 10,
    },
    activeRecordContainer: {
      borderWidth: 1,
      borderColor: backgroundColors.primary,
      backgroundColor: "#FAFAFA",
    },
    activeRecord: {
      color: backgroundColors.primary,
      fontWeight: "600",
    },
    menuContainer: {
      width: "10%",
      paddingRight: 10,
      alignItems: "flex-end",
    },
    record: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "baseline",
      flexWrap: "wrap",
      borderbottomwidth: 8,
      paddingVertical: 6,
      paddingRight: 10,
    },
    recordWithMeatBalls: {
      width: "90%",
    },
    recordWithNoMeatBalls: {
      width: "100%",
    },
    recordItemView: {
      minHeight: 10,
      paddingRight: 5,
      overflow: "hidden",
    },
    statusText: {
      width: "auto",
    },
    statusIcon: {
      width: "auto",
    },
    /* Routes Listing page widths - start*/
    routeStatusView: {
      width: "100%",
    },
    deliveryDate: {
      width: "100%",
    },
    routeName: {
      width: "100%",
      fontWeight: "700",
    },
    driverName: {
      width: "100%",
    },
    completed: {
      width: "100%",
      fontWeight: "700",
    },
    capacity: {
      width: "100%",
    },
    /* Routes Listing page widths - end*/
  },
  minWidth(767, {
    record: {
      justifyContent: "space-around",
      paddingVertical: 3,
      flexWrap: "nowrap",
      alignItems: "baseline",
    },
    menuContainer: {
      width: "7%",
    },
    recordWithMeatBalls: {
      width: "93%",
    },
    recordWithNoMeatBalls: {
      width: "100%",
    },
    noMenuContainer: {
      width: 0,
    },
    statusIcon: {
      width: "auto",
    },
    /* Routes Listing page widths - start*/
    routeStatusView: {
      width: "100%",
    },
    deliveryDate: {
      width: "100%",
    },
    routeName: {
      width: 150,
      fontWeight: "700",
    },
    driverName: {
      width: "100%",
    },
    completed: {
      width: "100%",
      fontWeight: "700",
    },
    /* Routes Listing page widths - end*/
  }),
  minWidth(1100, {
    record: {
      justifyContent: "space-around",
      paddingVertical: 3,
      flexWrap: "nowrap",
      alignItems: "baseline",
    },
    recordWithMeatBalls: {
      width: "97%",
    },
    recordWithNoMeatBalls: {
      width: "100%",
    },
    menuContainer: {
      width: "3%",
    },
    noMenuContainer: {
      width: 0,
    },
    statusText: {
      width: "auto",
    },
    statusIcon: {
      width: "auto",
    },
  })
);
