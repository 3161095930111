import React from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import * as Yup from "yup";
import { Form, FormField, SubmitButton } from "components/elements/forms";
import UserProfileContext from "library/contexts/userProfile";
import UserAuthContext from "library/contexts/userAuth";
import { ToasterHandler } from "components/elements";
import { request } from "library/utils/request";
import tw from "tailwind-rn";
import { useNavigation } from "@react-navigation/native";
import I18NContext from "library/contexts/i18N";
import { fonts } from "styles/theme";

export default function ChangePasswordScreen({
  onSuccess,
  onCancel,
  nonce,
  actionType = "change-password",
  showOldPassword = true,
  isUpdatePassword = false,
  buttonTitle = "Change Password",
  email = "",
}) {
  const { messages, Localise } = React.useContext(I18NContext);

  const { userProfile: { authGroups } = {} } =
    React.useContext(UserProfileContext);
  const { signOut } = React.useContext(UserAuthContext);
  const { goBack } = useNavigation();

  const memberCode = get(authGroups, "0.memberCodes.0", "");

  const validationSchema = Yup.object().shape({
    ...(showOldPassword && {
      oldPassword: Yup.string()
        .label("oldpassword")
        .required(Localise(messages, "Enter Old Password"))
        .min(8, Localise(messages, "Password must have at least 8 characters"))
        .max(
          32,
          Localise(messages, "Maximum Password length is 32 characters")
        ),
    }),
    newPassword: Yup.string()
      .label("newpassword")
      .required(Localise(messages, "Enter New Password"))
      .matches(
        /^(?=\S)(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*\S$)[A-Za-z0-9\s!@#$%^&*]{8,32}$/,
        Localise(messages, "Enter Password")
      ),
    confirmPassword: Yup.string()
      .label("confirmpassword")
      .required(Localise(messages, "Confirm new password"))
      .oneOf(
        [Yup.ref("newPassword"), null],
        Localise(messages, "Should match new password")
      ),
  });

  const onFormSubmit = (values, formikBag) => {
    const body = isEmpty(email)
      ? { ...values, memberCode, nonce }
      : { ...values, email };

    request(actionType, body)
      .then((res) => {
        if (res) {
          ToasterHandler(
            "success",
            Localise(
              messages,
              "Your password has been updated – please login with your new password"
            )
          );
          setTimeout(() => {
            isUpdatePassword ? goBack() : signOut();
            onSuccess && onSuccess();
          }, 2000);
        }
      })
      .catch((err) => {
        if (err) {
          err
            .then(() => {
              ToasterHandler(
                "uh oh",
                Localise(
                  messages,
                  "We were unable to update your password – please try again"
                )
              );
            })
            .catch(() => {
              ToasterHandler(
                "error",
                Localise(
                  messages,
                  "Unable to change password, please try again."
                )
              );
            });
          formikBag.setSubmitting(false);
        } else {
          ToasterHandler(
            "error",
            Localise(messages, "Unable to change password, please try again.")
          );
        }
        formikBag.setSubmitting(false);
      });
  };

  return (
    <>
      <View
        style={{
          paddingVertical: 20,
        }}
      >
        <Form
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          onSubmit={onFormSubmit}
          validationSchema={validationSchema}
          render={({ dirty }) => (
            <View>
              {showOldPassword && (
                <View
                  style={[tw(`flex flex-row justify-start items-center pb-2`)]}
                >
                  <Text
                    style={{
                      width: "20%",
                      ...fonts.heading5,
                    }}
                  >
                    {Localise(messages, "Old Password")}
                  </Text>
                  <View
                    style={[
                      tw("flex flex-row justify-start flex-wrap"),
                      { width: "75%" },
                    ]}
                  >
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      autoComplete="old-password"
                      name="oldPassword"
                      placeholder="Enter Old Password"
                      secureTextEntry
                      textContentType="password"
                      fsClass="fs-exclude"
                    />
                  </View>
                </View>
              )}

              <View style={tw("mt-3")}></View>

              <View
                style={[tw(`flex flex-row justify-start items-center pb-2`)]}
              >
                <Text
                  style={{
                    width: "20%",
                    ...fonts.heading5,
                  }}
                >
                  {Localise(messages, "Enter New Password")}
                </Text>
                <View
                  style={[
                    tw("flex flex-row justify-start flex-wrap"),
                    { width: "75%" },
                  ]}
                >
                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    autoComplete="new-password"
                    name="newPassword"
                    placeholder="Enter your new password"
                    secureTextEntry
                    textContentType="password"
                    fsClass="fs-exclude"
                  />
                </View>
              </View>

              <View
                style={[tw(`flex flex-row justify-start items-center pb-2`)]}
              >
                <Text
                  style={{
                    width: "20%",
                    ...fonts.heading5,
                  }}
                >
                  {Localise(messages, "Confirm New Password")}
                </Text>
                <View
                  style={[
                    tw("flex flex-row justify-start flex-wrap"),
                    { width: "75%" },
                  ]}
                >
                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    autoComplete="confirm-password"
                    name="confirmPassword"
                    placeholder="Re-enter your new password"
                    secureTextEntry
                    textContentType="password"
                    fsClass="fs-exclude"
                  />
                </View>
              </View>

              <View style={[tw(`flex flex-row justify-start items-center`)]}>
                <Text
                  style={{
                    width: "19%",
                    ...fonts.heading5,
                  }}
                ></Text>
                <View
                  style={[
                    tw("flex flex-row justify-start flex-wrap items-center"),
                    { width: "75%" },
                  ]}
                >
                  <SubmitButton title={buttonTitle} disableOnDirty={!dirty} />
                  <Text onPress={onCancel ? onCancel : () => goBack()}>
                    {Localise(messages, "Cancel")}
                  </Text>
                </View>
              </View>

              <View
                style={[tw(`flex flex-row justify-start items-center pb-2`)]}
              >
                <Text
                  style={{
                    width: "20%",
                    ...fonts.heading5,
                  }}
                ></Text>
                <View
                  style={[
                    tw("flex flex-row justify-start flex-wrap"),
                    { width: "75%" },
                  ]}
                >
                  <Text style={tw("py-5 italic")}>
                    {Localise(
                      messages,
                      "Your password must be a min 8 and max 32 characters without spaces, and include 1 uppercase, 1 number, and 1 special character. The password cannot match your three previous passwords."
                    )}
                  </Text>
                </View>
              </View>
            </View>
          )}
        />
      </View>
    </>
  );
}
