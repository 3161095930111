import React from "react";
import { View } from "react-native";
import { Accordion } from "components/elements";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { colors, backgroundColors } from "styles/theme";

export const ZoneAccordion = ({
  title,
  children,
  defaultOpen,
  accordionBorderColor,
  handleOnPress = () => {},
  headerContent,
}) => {
  const { isMobile } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  return (
    <View
      style={{
        marginTop: 10,
      }}
    >
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <View
          style={{
            backgroundColor: accordionBorderColor || "white",
            opacity: 0.5,
            width: 5,
          }}
        ></View>
        <View
          style={{
            flex: 1,
            borderWidth: 1,
            borderColor: colors.grayScaleLight,
            justifyContent: "flex-end",
          }}
        >
          <Accordion
            headerContent={headerContent}
            title={Localise(messages, title)}
            defaultOpen={defaultOpen}
            handleOnPress={handleOnPress}
            labelStyle={{
              marginTop: 0,
              height: 40,
              backgroundColor: backgroundColors.navBar,
              borderBottomWidth: 1,
              borderColor: colors.grayScaleLight,
            }}
            closeStyle={{
              borderBottomWidth: 0,
            }}
            titleStyle={{
              color: colors.primary,
              paddingLeft: 10,
            }}
            iconStyle={{
              color: colors.primary,
            }}
          >
            <View
              style={{
                backgroundColor: backgroundColors.secondary,
                padding: isMobile ? 10 : 20,
              }}
            >
              {children}
            </View>
          </Accordion>
        </View>
      </View>
    </View>
  );
};

export default ZoneAccordion;
