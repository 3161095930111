import { request as fetch } from "library/utils/request";
import UserProfileStorage from "library/storage/userProfile";

const request = (service = "", params = {}) => {
  const role = UserProfileStorage.getRole();

  if (role !== "FTD_ADMIN") {
    if (params?.shopCode === "all") {
      delete params.shopCode;
    } else {
      params.memberCode = params?.shopCode;
    }
  }

  return fetch(service, params);
};

export default request;
