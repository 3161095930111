import * as Yup from "yup";

// To apply validation when late fee amount is greater than or equal to 9999.99
Yup.addMethod(Yup.string, "lateFeeAmountRules", function (errorMessage) {
  return this.test(`is-late-fee-entered`, errorMessage, function (value = "") {
    return value > 9999.99 ? false : true;
  });
});

export const houseAccountLateFeeAmountSchema = (messages, Localise) => {
  return Yup.object().shape({
    lateFeesAmount: Yup.string()
      .required(Localise(messages, "Please enter Amount"))
      .lateFeeAmountRules(
        Localise(messages, "Amount should be less than 9999.99")
      ),
  });
};
