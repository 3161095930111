import React, { useEffect, useContext } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { Settings } from "components/views/drawer/product-catalog/common/settings.js";
import { AccordionWithBorder, Spinner } from "components/elements";

import {
  setInit,
  setReset,
} from "library/sagas/views/home/drawer/product-catalog/common/slice";
import { selectPageStatus } from "library/sagas/views/home/drawer/product-catalog/common/selector";

import { setSideCar } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import {
  selectSideCar,
  selectShopCode,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

const Catalog = () => {
  const dispatch = useDispatch();

  const { Localise, messages } = React.useContext(I18NContext);
  const { isDesktop } = useContext(DeviceContext);

  const status = useSelector(selectPageStatus);
  const { type: sideCar } = useSelector(selectSideCar);
  const shopCode = useSelector(selectShopCode);

  useEffect(() => {
    shopCode && dispatch(setInit(shopCode));
  }, [shopCode]);

  useEffect(() => {
    return () => {
      dispatch(setReset());
    };
  }, []);

  return (
    <View style={{ width: isDesktop ? (sideCar ? "100%" : "60%") : "100%" }}>
      {status === "done" ? (
        <AccordionWithBorder
          title={Localise(messages, "Catalog Settings")}
          defaultOpen={true}
        >
          <Settings sideCar={setSideCar({ type: "priceMinimumProducts" })} />
        </AccordionWithBorder>
      ) : (
        <View style={{ minHeight: 70 }}>
          <Spinner size="large" />
        </View>
      )}
    </View>
  );
};

export default Catalog;
