import React, { useEffect } from "react";
import { Form, FormFieldAutoComplete } from "components/elements/forms";
import * as Yup from "yup";
import I18NContext from "library/contexts/i18N";
import { AppSettingsContext } from "library/contexts/appSettings";
import { Entitlements } from "library/utils/entitlements";
import UserProfileStorage from "library/storage/userProfile";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentPage } from "library/sagas/ongoing/global-data/selector";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import { selectSearchText } from "library/sagas/views/home/drawer/mol-refunds/selector";
import {
  setPageAction,
  setPageSearch,
} from "library/sagas/views/home/drawer/mol-refunds/slice";
import { Text } from "react-native-elements";
import { fonts } from "styles/theme";

const ShopCodeAutoComplete = () => {
  const currentPage = useSelector(selectCurrentPage);
  const searchText = useSelector(selectSearchText);

  const dispatch = useDispatch();

  const { messages, Localise } = React.useContext(I18NContext);
  const { permissions } = React.useContext(AppSettingsContext);
  const shopNames = UserProfileStorage.getAllShopNames();
  const [selectShopQuery, setSelectShopQuery] = useStateIfMounted("");

  let entitlementKeyword = "";
  if (currentPage === "mercury-online-refunds") {
    entitlementKeyword = Entitlements.MOL_REFUNDS;
  }

  const filteredShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (entitlementKeyword in permissions[memberCode])
      filteredShopCodes.push(memberCode);
  });

  const sendingOriginData = filteredShopCodes.map((each) => {
    const shopName = shopNames[each] || "";
    return { code: each, shopName: shopName };
  });

  const validationSchema = Yup.object().shape({
    senderOrigin: Yup.string().required("Select a shop"),
  });

  let currentShopCode = "";
  if (sendingOriginData.length === 1) {
    currentShopCode = `${sendingOriginData[0].code}, ${sendingOriginData[0].shopName}`;

    dispatch(
      setPageAction({ type: "shopCode", value: sendingOriginData[0].code })
    );
  }

  return (
    <>
      {sendingOriginData.length > 1 ? (
        <Form
          initialValues={{ senderOrigin: "" }}
          onSubmit={() => {}}
          validationSchema={validationSchema}
          render={({ values, setValues, setFieldValue, setFieldError }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              if (searchText !== "" && values.senderOrigin === "") {
                setFieldError("senderOrigin", "Select a shop");
              } else {
                setFieldError("senderOrigin", "");
              }
            }, [searchText]);

            return (
              <FormFieldAutoComplete
                autoCapitalize="none"
                name="senderOrigin"
                placeholder={Localise(messages, "Select Shop")}
                data={sendingOriginData.filter((each) => {
                  const { code = "", shopName = "" } = each;
                  return (
                    code
                      .toLowerCase()
                      .includes(selectShopQuery.toLowerCase()) ||
                    shopName
                      .toLowerCase()
                      .includes(selectShopQuery.toLowerCase())
                  );
                })}
                onChangeText={(text) => {
                  setSelectShopQuery(text);
                }}
                outerContainerStyle={{
                  width: "100%",
                  zIndex: 1,
                }}
                showOnFocus={true}
                clearTextOnBackTab={true}
                isMultiSelect={false}
                listDisplayValues={["code", "shopName"]}
                popperPlacement={"bottom"}
                onSelect={(selectedValue) => {
                  const updatedValues = cloneDeep(values);

                  if (selectedValue) {
                    const { code, shopName } = selectedValue || {};

                    set(updatedValues, "sendingMember", code);
                    set(updatedValues, "senderOrigin", `${code} ${shopName}`);

                    dispatch(setPageAction({ type: "shopCode", value: code }));

                    dispatch(
                      setPageSearch({ type: "search", value: searchText })
                    );
                  } else {
                    set(updatedValues, "sendingMember", "");
                    set(updatedValues, "senderOrigin", "");

                    setFieldError("senderOrigin", "");
                  }

                  setValues(updatedValues);
                  setSelectShopQuery("");
                }}
                onClear={() => {
                  setFieldError("senderOrigin", "");

                  dispatch(setPageAction({ type: "shopCode", value: "" }));
                }}
                handleOnBlur={(text) => {
                  if (text === "") {
                    setFieldValue("sendingMember", "");
                    setFieldValue("senderOrigin", "");

                    setFieldError("senderOrigin", "");
                  }
                }}
              />
            );
          }}
        />
      ) : (
        <Text
          style={{
            ...fonts.heading4,
            height: 30,
            width: "100%",
            marginTop: 10,
          }}
        >
          {`${Localise(messages, "Shop")}: ${currentShopCode}`}
        </Text>
      )}
    </>
  );
};

export default ShopCodeAutoComplete;
