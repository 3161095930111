import React, { useEffect } from "react";
import { View, Text } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { useIsFocused } from "@react-navigation/native";

import {
  setReset,
  setProductsControl,
  fetchCollection,
  setProductsAction,
  createCollection,
  updateCollection,
  revertCollectionToGlobal,
} from "library/sagas/views/home/drawer/product-catalog/collection/slice";
import {
  selectPageStatus,
  selectAPIResponse,
  selectAPIError,
  selectSearchText,
} from "library/sagas/views/home/drawer/product-catalog/collection/selector";
import {
  selectScreenTitle,
  selectScreenParam,
  selectShopCode,
  selectAllowSyndicate,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";
import {
  setScreenTitle,
  navigateScreen,
} from "library/sagas/views/home/drawer/product-catalog/common/slice";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { ToasterHandler, Spinner } from "components/elements";
import { Form } from "components/elements/forms";

import {
  Settings as CatalogSettings,
  PriceMinimumProducts,
} from "../common/settings";
import { ProductsInCollection, AddProductsToCollection } from "./products";
import { ScreenComponent, OverrideHeader } from "../helper";
import { Section, ConfirmModal } from "components/wrappers";
import { PageHeader } from "../header";
import Settings from "./settings";

import { getUIConfig, SortOrderOptions } from "./ui-config";
import { getValidationSchema } from "./yup";
import { fonts } from "styles/theme";

const Collection = ({ route: { name, params }, rootNavigation }) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const status = useSelector(selectPageStatus);
  const data = useSelector(selectAPIResponse);
  const error = useSelector(selectAPIError);
  const shopCode = useSelector(selectShopCode);
  const allowSyndicate = useSelector(selectAllowSyndicate);

  const { Localise, messages } = React.useContext(I18NContext);
  const { isDesktop, isTablet } = React.useContext(DeviceContext);
  const [showPopup, setShowPopup] = React.useState(false);

  const { handle } = params;

  let isCreateCollection, collectionHandle;
  if (handle === "create_collection") {
    isCreateCollection = true;
  } else collectionHandle = handle;

  const initialValues =
    data.handle === collectionHandle ? getUIConfig(data) : {};
  const validationSchema = getValidationSchema(Localise, messages);

  useEffect(() => {
    if (!isFocused) return;
    if (collectionHandle) dispatch(fetchCollection(collectionHandle));

    dispatch(navigateScreen({ name, params, reduxOnly: true }));
    dispatch(
      setProductsControl({
        section: "current",
        type: "sortOptions",
        value: SortOrderOptions(Localise, messages),
      })
    );
    return () => {
      dispatch(setReset());
    };
  }, [isFocused, handle, shopCode]);

  const onSubmit = (values, formikBag) => {
    const { name: collectionName, handle } = values;
    const method = handle ? updateCollection : createCollection;

    dispatch(
      method({
        params: values,
        resolve: () => {
          ToasterHandler(
            "looks great",
            Localise(
              messages,
              `${collectionName} ${Localise(
                messages,
                `has been ${handle ? "updated" : "created"}`
              )}`
            )
          );

          formikBag.setSubmitting(false);
        },

        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              `${collectionName} wasn't ${
                handle ? "updated" : "created"
              }, please try again. `
            )
          );

          formikBag.setSubmitting(false);
        },
      })
    );
  };

  const CollectionMain = () => {
    const entityName = useSelector(selectScreenTitle(handle));
    const {
      values: { name: newName, handle, overridden, group },
    } = useFormikContext();

    useEffect(() => {
      if (entityName !== newName) {
        dispatch(setScreenTitle({ handle, value: newName }));
      }
    }, [newName]);

    return (
      <View style={{ maxWidth: isDesktop ? 996 : isTablet ? 676 : 332 }}>
        <PageHeader
          selectSearchText={selectSearchText("current")}
          setSearchText={(payload) =>
            setProductsAction({ ...payload, section: "current" })
          }
        />
        {group === "mol" && (
          <OverrideHeader
            id={handle}
            action={revertCollectionToGlobal}
            isOverrideHeader={overridden}
          />
        )}
        {status === "progress" && (
          <View style={{ minHeight: 150 }}>
            <Spinner size="large" />
          </View>
        )}

        {error && (
          <Text style={{ ...fonts.error, textAlign: "left" }}>
            {Localise(messages, error)}
          </Text>
        )}

        {isCreateCollection && !error && (
          <Section title={"Collection Settings"} defaultOpen={true}>
            <Settings isCreateCollection={isCreateCollection} />
          </Section>
        )}

        {status === "done" && !error && (
          <ProductsInCollection isCreateCollection={isCreateCollection} />
        )}
      </View>
    );
  };

  const CollectionSideCar = () => {
    const sideCar = useSelector(selectScreenParam("sideCar"));
    const [showModal, setShowModal] = React.useState(false);

    const ConfirmationModal = ({ values, formikBag }) => (
      <ConfirmModal
        modalVisible={!!showModal}
        handlePrimary={() => {
          onSubmit(values, formikBag);
          setShowModal(!showModal);
        }}
        handleSecondary={() => {
          setShowModal(!showModal);
          formikBag.setSubmitting(false);
        }}
        data={{
          modal: {
            primary: "Update",
            content: Localise(
              messages,
              "This change will apply to All shops and will override any local shop changes."
            ),
          },
        }}
      />
    );

    return sideCar === "collectionSettings" ? (
      <Form
        initialValues={initialValues}
        onSubmit={allowSyndicate ? () => setShowModal(!showModal) : onSubmit}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={false}
        render={({ values, ...formikBag }) => (
          <>
            <Settings />
            <ConfirmationModal values={values} formikBag={formikBag} />
          </>
        )}
      />
    ) : sideCar === "addProductsToCollection" ? (
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={false}
        render={() => <AddProductsToCollection />}
      />
    ) : sideCar === "catalogSettings" ? (
      <CatalogSettings />
    ) : sideCar === "priceMinimumProducts" ? (
      <PriceMinimumProducts />
    ) : null;
  };

  return (
    <ScreenComponent rootNavigation={rootNavigation}>
      <Form
        initialValues={initialValues}
        onSubmit={allowSyndicate ? () => setShowPopup(!showPopup) : onSubmit}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={false}
        render={({ values, ...formikBag }) => (
          <>
            <CollectionMain />
            <ConfirmModal
              modalVisible={!!showPopup}
              handlePrimary={() => {
                onSubmit(values, formikBag);
                setShowPopup(!showPopup);
              }}
              handleSecondary={() => {
                setShowPopup(!showPopup);
                formikBag.setSubmitting(false);
              }}
              data={{
                modal: {
                  primary: "Update",
                  content: Localise(
                    messages,
                    "This change will apply to All shops and will override any local shop changes."
                  ),
                },
              }}
            />
          </>
        )}
      />
      <CollectionSideCar />
    </ScreenComponent>
  );
};

export default Collection;
