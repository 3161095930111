export const headerTypes = (messageType = "all") => {
  const successTypes = [
    "success",
    "nice",
    "excellent",
    "done",
    "looking good",
    "looks good",
    "looks great",
    "thanks",
    "your team is growing",
    "until we meet again",
    "they’re back",
    "hooray",
  ];
  const errorTypes = ["error", "uh oh", "oops"];

  if (messageType === "success") {
    return successTypes;
  } else if (messageType === "error") {
    return errorTypes;
  } else {
    return successTypes.concat(errorTypes);
  }
};
