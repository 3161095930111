import React, { useEffect, useRef } from "react";
import { useFormikContext } from "formik";
import { View, Platform, Text } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useIsFocused } from "@react-navigation/native";

import { ToasterHandler, Spinner } from "components/elements";
import { Form } from "components/elements/forms";
import {
  Section,
  SaveCancelButtons,
  ConfirmModal,
  Currency,
} from "components/wrappers";

import { ScreenComponent } from "../helper";

import { Settings, PriceMinimumProducts } from "../common/settings";
import { PageHeader } from "../header";
import { OverrideHeader } from "../helper";
import ProductDetails from "./details";
import Availability from "./availability";
import Variations from "./variations";
import SuggestedAddons from "./suggested-addons";
import ProductActivity from "./product-activity";
import RushDeliveryFeesInput from "../../shop-settings/delivery-pickup/fees-coverage/input/fees";
//import WebsiteContent from "./website-content";

import {
  setReset,
  fetchProduct,
  createProduct,
  updateProduct,
  revertToGlobal,
  setBulkAction,
} from "library/sagas/views/home/drawer/product-catalog/product/slice";
import {
  selectPageStatus,
  selectData,
  selectAPIError,
  selectBulkActionType,
} from "library/sagas/views/home/drawer/product-catalog/product/selector";
import {
  selectAllAddons,
  selectScreenParam,
  selectScreenTitle,
  selectEnableProductActivity,
  selectShopCode,
  selectAllowSyndicate,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";
import {
  setScreenTitle,
  navigateScreen,
} from "library/sagas/views/home/drawer/product-catalog/common/slice";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { getUIConfig, getProductVariants, getAddonVariants } from "./ui-config";
import { getValidationSchema } from "./yup";
import { fonts } from "styles/theme";
import tw from "tailwind-rn";

const Product = ({ route: { name, params } = {}, rootNavigation }) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const error = useSelector(selectAPIError);
  const data = useSelector(selectData);
  const status = useSelector(selectPageStatus);
  const allAddons = useSelector(selectAllAddons);
  const allowSyndicate = useSelector(selectAllowSyndicate);
  const { value: bulkActionType = "" } = useSelector(selectBulkActionType);
  const [isLoading, setIsLoading] = React.useState(false);

  const { Localise, messages } = React.useContext(I18NContext);
  const { isDesktop, isTablet } = React.useContext(DeviceContext);
  const shopCode = useSelector(selectShopCode);
  const screenRef = React.useRef(null);

  const { handle = "" } = params;
  const isCreateProductHandle = ["create_product", "create_addon"].includes(
    handle
  );

  let isCreateProduct, productId;
  if (isCreateProductHandle) {
    isCreateProduct = true;
  } else if (handle.match(/^clone_/)) {
    productId = handle.substring(6);
    isCreateProduct = true;
  } else {
    productId = handle;
    isCreateProduct = false;
  }
  const productData = {
    ...data,
    ...(isCreateProductHandle
      ? { productType: handle.substring("create_".length) }
      : {}),
  };

  useEffect(() => {
    if (!isFocused) return;
    if (productId) dispatch(fetchProduct(productId));
    dispatch(navigateScreen({ name, params, reduxOnly: true }));
  }, [isFocused, handle, shopCode]);

  useEffect(() => {
    return () => {
      dispatch(setReset());
    };
  }, [isCreateProduct]);

  useEffect(() => {
    !isFocused && dispatch(setBulkAction(""));
  }, [isFocused]);

  const initialValues =
    productId === productData.productId
      ? getUIConfig(productData, isCreateProduct, shopCode, allAddons)
      : {};

  const onSubmit = (values, formikBag) => {
    const { name: productName, productId } = values;
    const method = isCreateProduct ? createProduct : updateProduct;

    dispatch(
      method({
        params: {
          ...values,
          prevCollections: initialValues.collections,
          ...(!isCreateProduct
            ? { bulkOperation: bulkActionType.split("::::")[1] }
            : {}),
        },
        resolve: () => {
          ToasterHandler(
            "looks great",
            Localise(
              messages,
              `${productName} ${Localise(
                messages,
                `has been ${
                  productId && !isCreateProduct ? "updated" : "created"
                }`
              )}`
            )
          );
          dispatch(setBulkAction(""));
          isCreateProduct &&
            setTimeout(() => {
              dispatch(
                navigateScreen({
                  name: "product",
                  params: {
                    handle: productId,
                  },
                })
              );
            }, 1000);
          formikBag.setSubmitting(false);
          setIsLoading(false);
        },

        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              `${productName} wasn't ${
                productId && !isCreateProduct ? "updated" : "created"
              }, please try again`
            )
          );
          dispatch(setBulkAction(""));
          formikBag.setSubmitting(false);
          setIsLoading(false);
        },
      })
    );
  };

  const ProductMainScreen = () => {
    const [showPopup, setShowPopup] = React.useState(false);
    const currency = Currency(shopCode);
    const validationSchema = getValidationSchema(Localise, messages, currency);
    const showFields =
      status !== "progress"
        ? isCreateProduct
          ? true
          : error && error.includes("no product")
          ? false
          : !!initialValues.name
        : false;

    return (
      <View style={{ maxWidth: isDesktop ? 996 : isTablet ? 676 : 332 }}>
        <PageHeader />
        {status === "progress" && (
          <View style={{ minHeight: 150 }}>
            <Spinner size="large" />
          </View>
        )}
        {error && (
          <View style={tw("my-2")}>
            <Text style={{ ...fonts.error, textAlign: "left" }}>
              {Localise(messages, error)}
            </Text>
          </View>
        )}
        {showFields && (
          <Form
            initialValues={initialValues}
            onSubmit={
              allowSyndicate ? () => setShowPopup(!showPopup) : onSubmit
            }
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={false}
            focusError={Localise(
              messages,
              "Please complete required fields to create this product"
            )}
            screenRef={screenRef}
            render={({ values, ...formikBag }) => {
              const { siteSellable = [], productId, group = "" } = values;
              const isOverrideHeader = !siteSellable.includes("mol");

              const isMOLProduct = group === "mol";

              return (
                <>
                  {isMOLProduct ? (
                    <OverrideHeader
                      values={values}
                      isOverrideHeader={isOverrideHeader}
                      action={revertToGlobal}
                      id={productId}
                    />
                  ) : null}
                  <ProductFields />
                  <ConfirmModal
                    modalVisible={!!showPopup}
                    handlePrimary={() => {
                      onSubmit(values, formikBag);
                      setShowPopup(!showPopup);
                    }}
                    handleSecondary={() => {
                      setShowPopup(!showPopup);
                      formikBag.setSubmitting(false);
                    }}
                    data={{
                      modal: {
                        primary: "Update",
                        content: Localise(
                          messages,
                          "This change will apply to All shops and will override any local shop changes."
                        ),
                      },
                    }}
                  />
                </>
              );
            }}
          />
        )}
      </View>
    );
  };

  const ProductFields = () => {
    const entityName = useSelector(selectScreenTitle(handle));
    const enableProductActivity = useSelector(selectEnableProductActivity);
    const { values, setFieldValue, submitCount } = useFormikContext();
    const {
      name: newName,
      productType,
      productId,
      addNewVariant,
      addons,
    } = values;

    const isAddon = productType === "addon";
    const submitBtnRef = useRef(null);
    const updateDisableStatus = (param) => {
      if (submitBtnRef?.current) {
        submitBtnRef.current.disableButton(param);
      }
    };

    useEffect(() => {
      bulkActionType !== "" && submitCount > 0 && setIsLoading(true);
    }, [bulkActionType, submitCount]);

    useEffect(() => {
      if (entityName !== newName && !isCreateProduct) {
        dispatch(setScreenTitle({ handle, value: newName }));
      }
    }, [newName]);

    useEffect(() => {
      if (productType) {
        if (isAddon) {
          const addonVariants = getAddonVariants(values, isCreateProduct);
          setFieldValue("variants", addonVariants);
          setFieldValue("addons", []);
        } else {
          setFieldValue(
            "variants",
            getProductVariants(values, isCreateProduct)
          );

          if (isCreateProduct && !addons?.length)
            setFieldValue(
              "addons",
              allAddons.filter((a) => a.group === "mol").map((a) => a.productId)
            );
        }
      }
    }, [productType, productId, addNewVariant]);

    return (
      <View>
        <Section
          title={`${productType === "addon" ? "Add-On" : "Product"} Details`}
          defaultOpen={true}
          overrideZindex={20}
        >
          <ProductDetails
            isCreateProduct={isCreateProduct}
            updateDisableStatus={updateDisableStatus}
          />
        </Section>
        {productType ? (
          <Section
            title={`${
              productType === "addon" ? "Add-On" : "Product"
            } Variations`}
          >
            <Variations isCreateProduct={isCreateProduct} />
          </Section>
        ) : null}
        {!["addon", "dropship"].includes(productType) ? (
          <Section title={"Suggested Add-Ons"}>
            <SuggestedAddons />
          </Section>
        ) : null}
        <Section title={"Availability"} overrideZindex={10}>
          <Availability />
        </Section>
        {/* <Section title={"Website Content"}>
              <WebsiteContent isCreateProduct={isCreateProduct} />
            </Section> */}
        {!isCreateProduct && enableProductActivity ? (
          <Section title={"Activity"}>
            <ProductActivity isCreateProduct={isCreateProduct} />
          </Section>
        ) : null}
        <View
          style={tw(
            `flex flex-col justify-end items-end mb-${
              Platform.OS === "web" ? "0" : "10"
            }`
          )}
        >
          <Text style={{ ...fonts.error, textAlign: "right" }}>
            {error && Localise(messages, error)}
          </Text>
          <View style={tw("justify-end flex-row")}>
            <SaveCancelButtons
              buttonTitle={Localise(
                messages,
                `${isCreateProduct ? "Create" : "Save"} ${
                  isAddon ? "Add-on" : "Product"
                }`
              )}
              isLoading={isLoading}
              buttonRef={submitBtnRef}
              onCancel={() =>
                dispatch(
                  navigateScreen({
                    name: "dashboard",
                    params: {
                      handle: "",
                    },
                  })
                )
              }
              showCancelAlways={true}
            />
          </View>
        </View>
      </View>
    );
  };

  const ProductSideCar = () => {
    const sideCar = useSelector(selectScreenParam("sideCar"));
    return sideCar === "catalogSettings" ? (
      <Settings />
    ) : sideCar === "priceMinimumProducts" ? (
      <PriceMinimumProducts />
    ) : sideCar === "rushDeliverySettings" ? (
      <RushDeliveryFeesInput />
    ) : null;
  };

  return (
    <ScreenComponent rootNavigation={rootNavigation} screenRef={screenRef}>
      <ProductMainScreen />
      <ProductSideCar />
    </ScreenComponent>
  );
};

export default Product;
