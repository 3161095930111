import React from "react";

import { MHQReleaseNotes } from "./release-notes";

const ReleaseNotes = ({ currentSubTab }) => {
  const component =
    currentSubTab === "mhq-sub-tab"
      ? "Mercury HQ Releases"
      : "Mercury Online Releases";

  return <MHQReleaseNotes component={component} />;
};

export default ReleaseNotes;
