import {
  selectNoop,
  selectShowUpsert,
  selectIsPageLoading,
  selectCustomers,
  selectAppliedLimits,
  selectCurrentCustomerId,
  selectSearchText,
  selectAppliedShops,
  selectAppliedFilters,
  selectSortOption,
  selectTotalNumberOfRecords,
  selectPageActions,
} from "library/sagas/views/home/drawer/customer-directory/selector";

import {
  setPageAction,
  setCurrentCustomer,
  fetchAllCustomersBySearch,
  setStoreOrigin,
} from "library/sagas/views/home/drawer/customer-directory/slice";

import Item from "./list-item";
import ListingHeader from "./listing-header";

export const getUIConfig = (
  Localise,
  messages,
  isDesktop,
  isTablet,
  isMobile
) => ({
  heading: Localise(messages, "Customer Directory"),
  ListingHeader,
  selectors: {
    showSecondScreen: selectShowUpsert,
    showLoading: selectIsPageLoading,
    dataSelector: selectCustomers,
    limitSelector: selectAppliedLimits,
    selectTotalNumberOfRecords,
    selectPageActions,
  },
  sections: [
    {
      section: "customers",
      component: {
        props: {
          defaultOpen: () => {},
          showAccordion: false,
          viewMore: {
            label: Localise(messages, "View More"),
            value: 10,
            initial: 10,
          },
          selectors: {
            dataSelector: selectCustomers,
            limitSelector: selectAppliedLimits,
            accordionSelector: selectNoop,
            selectTotalNumberOfRecords,
          },
          actions: { setPageAction },
          itemComponent: {
            name: Item,
            props: {
              id: "customerId",
              columns: getColumns(isMobile, isTablet),
              style: {},
              selectors: {
                dataSelector: selectCustomers,
                selectActiveItem: selectCurrentCustomerId,
                accordionSelector: selectNoop,
              },
              actions: {
                setActiveItem: setCurrentCustomer,
                setStoreOrigin,
              },
            },
          },
        },
      },
    },
  ],
  controls: [
    {
      name: "search",
      props: {
        width: isDesktop ? "33%" : "",
        icon: "search",
        placeholder: Localise(
          messages,
          "Enter Customer’s Name, Phone, or Email to search records"
        ),
        inputStyle: {
          width: "100%",
        },
        selectors: {
          selectValue: selectSearchText,
        },
        actions: {
          setAction: fetchAllCustomersBySearch,
        },
      },
    },
    {
      name: "shops",
      props: {
        width: isDesktop ? "5%" : "",
        icon: "home",
        text: Localise(messages, "Shops"),
        selectors: {
          selectValue: selectAppliedShops,
        },
        actions: {
          setAction: setPageAction,
        },
      },
    },
    {
      name: "filters",
      props: {
        width: isDesktop ? "5%" : "",
        icon: "filter",
        text: Localise(messages, "Filters"),
        options: {
          "Filter By": [
            {
              label: Localise(messages, "Business Profiles"),
              value: "businessProfiles",
            },
            {
              label: Localise(messages, "Individual Profiles"),
              value: "individualProfiles",
            },
            {
              label: Localise(messages, "Subscription Profiles"),
              value: "subscriptionProfiles",
            },
            {
              label: Localise(messages, "Outstanding Balance"),
              value: "outstandingBalance",
            },
          ],
        },
        selectors: {
          selectValue: selectAppliedFilters,
        },
        actions: {
          setAction: setPageAction,
        },
      },
    },
    {
      name: "sort",
      props: {
        width: isDesktop ? "5%" : "",
        icon: "sort",
        text: Localise(messages, "Sort"),
        options: [
          {
            label: Localise(messages, "View A-Z"),
            key: "name::asc",
          },
          {
            label: Localise(messages, "View Z-A"),
            key: "name::desc",
          },
        ],
        selectors: {
          selectValue: selectSortOption,
        },
        actions: {
          setAction: setPageAction,
        },
      },
    },
    {
      name: "create-new",
      props: {
        icon: "create-order",
        text: Localise(messages, "Create Customer Profile"),
        route: "create-customer",
        params: {},
      },
    },
  ],
  // Providing Static height Temporarily to fix height issue if we have banners upto 3. In future when listing screen ui changes similar to Orders listing screen, issue will resolve and we can delete this css
  scrollContainerStyles: { minHeight: 700 },
});

const getColumns = (isMobile, isTablet) => {
  return [
    {
      column: "name",
      style: {
        fontWeight: "600",
        width: isMobile ? "50%" : isTablet ? 250 : "25%",
        textTransform: "capitalize",
      },
    },
    {
      column: "phone",
      style: {
        width: isMobile ? "50%" : isTablet ? 65 : "25%",
        textTransform: "capitalize",
      },
    },
    {
      column: "email",
      style: {
        width: isMobile ? "50%" : isTablet ? 65 : "25%",
      },
    },
    {
      column: "address",
      style: {
        width: isMobile ? "50%" : isTablet ? 65 : "25%",
        textTransform: "capitalize",
      },
    },
    {
      column: ["state", "city"],
      type: "area",
      style: {
        width: isMobile ? "50%" : isTablet ? 65 : "25%",
        textTransform: "capitalize",
      },
    },
  ];
};
