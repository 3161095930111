import React, { useRef } from "react";
import { Formik } from "formik";
import ScrollToError from "library/utils/scrollToError";

export default function Form({
  formReinitialize = true, //to reinitialize form on parent component re-render
  focusError = "", //to scroll the view to the field having error
  /* eslint-disable react-hooks/rules-of-hooks */
  screenRef = useRef(),
  initialValues,
  onSubmit,
  validationSchema,
  render,
  ...otherProps
}) {
  return (
    <Formik
      enableReinitialize={formReinitialize}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {(props) => {
        return (
          <React.Fragment>
            {focusError.length > 0 && (
              <ScrollToError focusError={focusError} screenRef={screenRef} />
            )}
            {render(props)}
          </React.Fragment>
        );
      }}
    </Formik>
  );
}
