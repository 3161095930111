import { useContext } from "react";
import { Platform } from "react-native";
import { getUIConfig, getFuneralLogUIConfig } from "./ui-config";
import Listing from "components/containers/listing-new";
import UserProfileContext from "library/contexts/userProfile";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

const ListingScreen = (props = {}) => {
  const { isFuneralLog, onComplete } = props;
  const { isDesktop, isTablet, isMobile } = useContext(DeviceContext);
  const { proxyUser, suspendedMembers = [] } = useContext(UserProfileContext);
  const { Localise, messages } = useContext(I18NContext);

  const suspendedHeaderExists = suspendedMembers.length > 0;
  const headerHeight = 150;
  const actionsAndShowResultsHeight = 110;
  let bannerHeight = proxyUser || suspendedHeaderExists ? 50 : 0;

  const maxScrollViewHeight =
    Platform.OS === "web"
      ? `calc(100vh - ${headerHeight}px - ${bannerHeight}px - ${actionsAndShowResultsHeight}px)`
      : "100%";

  const maxScrollFuneralViewHeight =
    Platform.OS === "web" ? `calc(100% - 90px)` : "100%";

  const UIConfig = isFuneralLog
    ? getFuneralLogUIConfig(
        Localise,
        messages,
        isDesktop,
        isTablet,
        isMobile,
        maxScrollFuneralViewHeight
      )
    : getUIConfig(
        Localise,
        messages,
        isDesktop,
        isTablet,
        isMobile,
        maxScrollViewHeight
      );

  const ListingComponent = Listing({ UIConfig, onComplete });
  return ListingComponent?.props?.children;
};

export default ListingScreen;
