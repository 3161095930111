import { Platform } from "react-native";
import { getDeviceInfo } from "library/utils/appSettings";

const deviceInfo = getDeviceInfo();
const isDesktop = deviceInfo.deviceType === "desktop";

const colors = {
  primary: "#414141",
  secondary: "#ffffff",
  light: "#dddddd",
  dark: "#000000",
  medium: "#aaaaaa",
  error: isDesktop ? "#e5175e" : "#d00808",
  warning: "orange",
  success: "green",
  info: "blue",
  navBarActive: "#d0eaed",
  highlighter: "#114258",
  button: "#164158",
  buttonText: "#ffffff",
  secondaryButton: "#ffffff",
  secondaryButtonText: "#164158",
  grayScaleLight: "#c7c7c7",
  activityIndicator: "#1976d2",
  primary2: "#3a7d8d",
  red: "#e5175e",
  active: "#80C777",
  inactive: "#F2F2F2",
  ftd: "#3d4619",
  priceBanner: "#4ec2a7",
  sectionTitle: "#264b62",
  disabled: "#c4c4c4",
  lightBorder: "#707070",
};

const backgroundColors = {
  primary: "#114258",
  secondary: "#ffffff",
  black: "#000000",
  light: "#eeeeee",
  dark: "#aaaaaa",
  medium: "#dddddd",
  header: "#114258",
  transparent: "transparent",
  primary2: "#3a7d8d",
  navBar: "#f8fdfd",
  contentBG: "#fafafa",
  sideCar: "#f4f4f4",
  greyColor: "#f4f4f4",
  separatorBG: "#fdffff",
};

const orderStatusColorCodes = {
  incoming: "#e2f7ee",
  "incoming-pickup": "#e2f7ee",
  outgoing: "#e2f7ee",
  expiring: "#e5175e",
  expired: "#e5175e",
  "approve-request": "#b50d81",
  "date approved": "#4aa779",
  "price approved": "#4aa779",
  "date denied": colors.red,
  "price denied": colors.red,
  "view-responses": "#b50d81",
  paymentError: "#6c0057",
  accepted: "#aadec6",
  "accepted-pickup": "#aadec6",
  "in-design": "#23a587",
  designed: "#037c77",
  "designed-pickup": "#037c77",
  "design-pickup": "#23a587",
  delivery: "#11838e",
  "delivery-pickup": "#11838e",
  completed: "#114258",
  "completed-pickup": "#114258",
  cancelled: "#c4c4c4",
  "cancelled-pickup": "#c4c4c4",
  rejected: "#c4c4c4",
  "fulfillment error": "#6c0057",
  "payment error": "#6c0057",
  forfeited: "#c4c4c4",
  error: "#c4c4c4",
  paymentPending: "#6c0057",
};

const datePriceChangeColors = {
  Pending: "#b50d81",
  Approved: "#4aa779",
  Denied: colors.red,
};

const routeStatusColorCodes = {
  draft: "#aadec6",
  planned: "#23a587",
  inprogress: "#037c77",
  completed: "#114258",
  incomplete: "#6c0057",
  deleted: "#c4c4c4",
};

const routeDashboardColorCodes = {
  draft: "#aadec6",
  planned: "#31aa92",
  inprogress: "#037c77",
  completed: "#114258",
  incomplete: "#114258",
  nonrouted: "#114258",
};

const DSRequestsColorCodes = {
  Scheduled: "#23a587",
  Pickup: "#23a587",
  HurryPickup: "#e8145c",
  Delivery: "#037c77",
  "Fulfillment Error": "#6c0057",
  Cancelled: "#c4c4c4",
  Completed: "#114258",
};

const shapes = {
  square: {
    height: 16,
    width: 16,
    backgroundColor: colors.light,
  },
  round: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: colors.light,
  },
  sectionBorder: {
    backgroundColor: backgroundColors.secondary,
    borderColor: colors.grayScaleLight,
    borderWidth: 1,
    borderRadius: 6,
    padding: 15,
  },
};

const fontWeights = {
  normal: "500",
  semiBold: "600",
  bold: "700",
};

const fontFamily = {
  // bold: "Montserrat-Bold",
  // light: "Montserrat-Light",
  // medium: "Montserrat-Medium",
  // regular: "Montserrat-Regular",
  bold: "Arial",
  regular: "Arial",
};

const fonts = {
  fontColor: colors.primary,
  fontSize: 12,
  fontFamily: {
    default: fontFamily.regular,
    bold: fontFamily.bold,
    // light: fontFamily.light,
    // medium: fontFamily.medium,
    regular: fontFamily.regular,
  },
  heading1: {
    fontSize: 22,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamily.regular,
    color: colors.primary,
  },
  heading2: {
    fontSize: 18,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamily.regular,
    color: colors.primary,
  },
  heading3: {
    fontSize: 16,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamily.regular,
    color: colors.primary,
  },
  heading4: {
    fontSize: 13,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamily.regular,
    color: colors.primary,
  },
  heading5: {
    fontSize: 12,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamily.regular,
    color: colors.primary,
  },
  heading6: {
    fontSize: 11,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamily.regular,
    color: colors.primary,
  },
  default: {
    fontSize: 12,
    fontFamily: fontFamily.regular,
    color: colors.primary,
  },
  style1: {
    fontSize: 11,
    fontFamily: fontFamily.regular,
    color: colors.primary,
  },
  style2: {
    fontSize: 11,
    fontFamily: fontFamily.regular,
    fontStyle: "italic",
    color: colors.primary,
  },
  style3: {
    fontSize: 12,
    fontFamily: fontFamily.regular,
    textDecorationLine: "underline",
    color: colors.primary,
  },
  style4: {
    fontSize: 12,
    fontFamily: fontFamily.regular,
    fontStyle: "italic",
    color: colors.primary,
  },
  link: {
    fontSize: Platform.OS === "ios" ? 14 : 12,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamily.regular,
    color: colors.primary2,
    cursor: "pointer",
  },
  link1: {
    fontSize: Platform.OS === "ios" ? 14 : 12,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamily.regular,
    textDecorationLine: "underline",
    color: colors.primary2,
  },
  link2: {
    fontSize: Platform.OS === "ios" ? 14 : 12,
    fontFamily: fontFamily.regular,
    textDecorationLine: "underline",
    color: colors.primary2,
  },
  link3: {
    fontSize: Platform.OS === "ios" ? 12 : 11,
    fontFamily: fontFamily.regular,
    textDecorationLine: "underline",
    color: colors.primary2,
  },
  error: {
    color: colors.error,
    fontSize: 12,
    fontFamily: fontFamily.regular,
    textAlign: "center",
    marginVertical: 8,
  },
  success: {
    color: colors.success,
    fontSize: 12,
    fontFamily: fontFamily.regular,
    textAlign: "center",
    marginVertical: 8,
  },
  sectionHeading: {
    fontSize: 15,
    fontWeight: fontWeights.bold,
    fontFamily: fontFamily.regular,
    color: colors.sectionTitle,
  },
};

const theme = {
  Accordion: {
    labelStyle: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: 40,
      paddingHorizontal: 10,
      backgroundColor: backgroundColors.primary,
      marginTop: 5,
      marginBottom: 0,
    },
    titleStyle: {
      ...fonts.heading3,
      color: backgroundColors.secondary,
    },
    iconStyle: {
      backgroundColor: "transparent",
      color: backgroundColors.secondary,
    },
    secondaryLabelStyle: {
      backgroundColor: colors.navBarActive,
    },
    secondaryTitleStyle: {
      ...fonts.heading4,
      color: colors.primary,
    },
    secondaryIconStyle: {
      backgroundColor: "transparent",
      color: colors.primary,
    },
    openStyle: {
      borderWidth: 0,
    },
    closeStyle: {
      borderBottomColor: colors.grayScaleLight,
      borderBottomWidth: 1,
    },
    contentStyle: {
      paddingVertical: 5,
    },
    titleContainer: {
      flexDirection: "row",
      alignItems: "center",
    },
    errorStyle: {
      color: colors.error,
      fontSize: 12,
      fontWeight: fontWeights.semiBold,
      fontFamily: fonts.fontFamily.default,
      marginVertical: 0,
      marginHorizontal: 10,
    },
  },
  Text: {
    style: {
      color: colors.primary,
      fontSize: 12,
      fontFamily: fonts.fontFamily.default,
      paddingLeft: 0,
    },
  },
  Header: {
    containerStyle: {
      backgroundColor: backgroundColors.primary,
      borderBottomColor: colors.light,
      height: 70,
      paddingHorizontal: 0,
    },
  },
  Input: {
    containerStyle: {
      paddingHorizontal: 5,
    },
    inputStyle: {
      ...(Platform.OS === "web" ? { outlineStyle: "none" } : {}),
      color: colors.primary,
      fontSize: 12,
      fontFamily: fonts.fontFamily.default,
      minHeight: 35,
      height: 35,
      width: "100%",
    },
    leftIconContainerStyle: {
      paddingRight: 5,
      marginVertical: 0,
      height: "auto",
    },
    errorStyle: {
      color: colors.error,
      fontSize: 12,
      paddingBottom: 8,
      lineHeight: 24,
      margin: 0,
      fontWeight: fontWeights.semiBold,
      fontFamily: fonts.fontFamily.default,
    },
    inputContainerStyle: {
      borderColor: colors.light,
      borderWidth: 1,
      borderRadius: 2,
      padding: 10,
      height: 35,
    },
    labelStyle: {
      color: colors.primary,
      ...fonts.heading5,
      paddingBottom: 6,
    },
    placeholderTextColor: colors.medium,
  },
  Button: {
    titleStyle: {
      color: colors.buttonText,
      fontSize: 14,
      fontWeight: fontWeights.semiBold,
      fontFamily: fonts.fontFamily.default,
    },
    buttonStyle: {
      backgroundColor: colors.button,
      borderRadius: 2,
      justifyContent: "center",
      alignItems: "center",
      paddingVertical: 8,
      paddingHorizontal: 20,
    },
    secondaryTitleStyle: {
      color: colors.secondaryButtonText,
      fontSize: 14,
      fontWeight: fontWeights.semiBold,
      fontFamily: fonts.fontFamily.default,
    },
    secondaryButtonStyle: {
      backgroundColor: colors.secondaryButton,
      borderWidth: 1,
      borderColor: "#708090",
      borderRadius: 2,
      justifyContent: "center",
      alignItems: "center",
      paddingVertical: 8,
      paddingHorizontal: 20,
    },
    regularTitleStyle: {
      color: colors.secondaryButtonText,
      fontSize: 12,
      fontFamily: fonts.fontFamily.default,
    },
    regularButtonStyle: {
      backgroundColor: colors.secondaryButton,
      borderWidth: 1,
      borderColor: "#708090",
      borderRadius: 2,
      justifyContent: "center",
      alignItems: "center",
      paddingVertical: 5,
      paddingHorizontal: 5,
    },
    containerStyle: {
      margin: 10,
    },
    cancelButtonStyle: {
      backgroundColor: colors.secondary,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: "#114258",
      justifyContent: "center",
      alignItems: "center",
      paddingVertical: 8,
      paddingHorizontal: 20,
    },
    // disabledTitleStyle: {
    //   color: colors.grayScaleLight,
    // },
  },
  ListItem: {
    containerStyle: {
      backgroundColor: backgroundColors.secondary,
      borderColor: colors.light,
      borderRadius: 2,
    },
    titleStyle: {
      color: colors.primary,
      fontFamily: fonts.fontFamily.default,
    },
    subtitleStyle: {
      color: colors.medium,
    },
  },
  Icon: {
    closeIcon: {
      borderWidth: 1,
      borderColor: colors.light,
      borderRadius: 7,
    },
    iconStyle: {
      color: colors.primary,
      backgroundColor: backgroundColors.transparent,
    },
    containerStyle: {
      justifyContent: "center",
      alignItems: "center",
    },
    disabledStyle: {
      backgroundColor: "transparent",
    },
  },
  Radio: {
    containerStyle: {
      padding: 5,
    },
    inputStyle: {
      height: 16,
      width: 16,
      marginTop: 4,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: colors.medium,
      alignItems: "center",
      justifyContent: "center",
    },
    selectedStyle: {
      width: 10,
      height: 10,
      borderRadius: 7,
      backgroundColor: colors.button,
    },
    labelStyle: {
      color: colors.primary,
      paddingBottom: 6,
      ...fonts.heading5,
    },
    errorStyle: {
      color: colors.error,
      fontSize: 12,
      paddingBottom: 8,
      lineHeight: 24,
      margin: 0,
      fontWeight: fontWeights.semiBold,
      fontFamily: fonts.fontFamily.default,
    },
  },
  CheckBox: {
    textStyle: {
      color: colors.primary,
      fontWeight: "500",
      fontSize: fonts.fontSize,
      fontFamily: fonts.fontFamily.default,
      paddingLeft: 6,
      marginLeft: 0,
      marginRight: 0,
    },
    containerStyle: {
      padding: 5,
    },
    inputContainerStyle: {
      borderWidth: 0,
      backgroundColor: backgroundColors.secondary,
      margin: 0,
      padding: 0,
      marginTop: 4,
      marginLeft: 0,
      marginRight: 10,
      zIndex: -1,
    },
    labelStyle: {
      color: colors.primary,
      paddingBottom: 6,
      ...fonts.heading5,
    },
    errorStyle: {
      color: colors.error,
      fontSize: 12,
      paddingBottom: 8,
      lineHeight: 24,
      margin: 0,
      fontWeight: fontWeights.semiBold,
      fontFamily: fonts.fontFamily.default,
    },
    checkedColor: colors.primary,
  },
  Picker: {
    containerStyle: {
      paddingHorizontal: 5,
      paddingBottom: 8,
    },
    innerContainerStyle: {
      borderColor: colors.light,
      borderRadius: 2,
      borderWidth: 1,
    },
    inputStyle: {
      inputWeb: {
        padding: 6,
        borderRight: "5px solid transparent",
        fontSize: 12,
        fontFamily: fonts.fontFamily.default,
        height: isDesktop ? 33 : 35,
        color: colors.primary,
        borderColor: "transparent",
        // outlineColor: colors.light,
        // outlineWidth: 0,
        backgroundColor: backgroundColors.secondary,
      },
      inputIOS: {
        padding: 9,
        borderRight: "10px solid transparent",
        fontSize: 12,
        fontFamily: fonts.fontFamily.default,
        color: colors.primary,
        backgroundColor: backgroundColors.secondary,
      },
      inputAndroid: {
        padding: 9,
        borderRight: "10px solid transparent",
        height: 35,
        fontSize: 12,
        fontFamily: fonts.fontFamily.default,
        color: colors.primary,
        backgroundColor: backgroundColors.secondary,
      },
    },
    disabledInputStyle: {
      inputWeb: {
        padding: 6,
        borderRight: "5px solid transparent",
        fontSize: 12,
        fontFamily: fonts.fontFamily.default,
        height: isDesktop ? 33 : 35,
        color: colors.primary,
        borderColor: "transparent",
        backgroundColor: backgroundColors.greyColor,
      },
      inputIOS: {
        padding: 9,
        borderRight: "10px solid transparent",
        fontSize: 12,
        fontFamily: fonts.fontFamily.default,
        color: colors.primary,
        backgroundColor: backgroundColors.greyColor,
      },
      inputAndroid: {
        padding: 9,
        borderRight: "10px solid transparent",
        height: 35,
        fontSize: 12,
        fontFamily: fonts.fontFamily.default,
        color: colors.primary,
        backgroundColor: backgroundColors.greyColor,
      },
    },
    iconContainerStyle: {
      top: Platform.OS === "web" ? 4 : Platform.OS === "android" ? 4 : 1,
      right: Platform.OS === "web" ? 5 : 0,
      paddingVertical: 10,
      paddingRight: Platform.OS === "web" ? 10 : 5,
      paddingLeft: Platform.OS === "web" ? 10 : 2,
      backgroundColor: backgroundColors.secondary,
    },
    iconStyle: {
      color: colors.primary,
    },
    labelStyle: {
      color: colors.primary,
      paddingBottom: 6,
      ...fonts.heading5,
    },
    labelFieldSetStyle: {
      flexDirection: "row",
      justifyContent: "space-between",
      // height: 20,
    },
    errorStyle: {
      color: colors.error,
      fontSize: 12,
      lineHeight: 24,
      margin: 0,
      fontWeight: fontWeights.semiBold,
      fontFamily: fonts.fontFamily.default,
    },
  },
  AutoComplete: {
    labelStyle: {
      color: colors.primary,
      ...fonts.heading5,
      paddingBottom: 6,
    },
    outerContainerStyle: {
      paddingHorizontal: 5,
    },
    containerStyle: {
      marginBottom: 8,
      position: "absolute",
      width: "100%",
    },
    innerContainerStyle: {
      borderColor: colors.light,
      borderRadius: 2,
      borderWidth: 1,
    },
    listContainerStyle: {
      zIndex: 1,
      backgroundColor: backgroundColors.secondary,
      maxHeight: 180,
      cursor: "pointer",
    },
    inputStyle: {
      ...(Platform.OS === "web" ? { outlineStyle: "none" } : {}),
      padding: 0,
      color: colors.primary,
      fontFamily: fonts.fontFamily.default,
      fontSize: 12,
      height: isDesktop ? 33 : 35,
      paddingLeft: 10,
    },
    textStyle: {
      paddingLeft: 10,
      padding: 10,
      fontFamily: fonts.fontFamily.default,
      fontSize: 12,
    },
    renderSeparatorstyle: {
      borderTopWidth: 1,
      borderTopColor: colors.light,
    },
    activeOptionStyle: {
      backgroundColor: colors.light,
    },
    errorStyle: {
      color: colors.error,
      fontSize: 12,
      paddingBottom: 8,
      lineHeight: 24,
      margin: 0,
      fontWeight: fontWeights.semiBold,
      fontFamily: fonts.fontFamily.default,
    },
  },
  DatePicker: {
    containerStyle: {
      paddingHorizontal: 0,
      marginTop: -3,
    },
  },
  Divider: {
    style: {
      height: 1,
      marginVertical: 4,
      backgroundColor: colors.light,
    },
  },
  FieldSet: {
    containerStyle: {
      borderWidth: 1,
      borderRadius: 2,
      marginTop: 16,
      marginHorizontal: 5,
      borderColor: colors.light,
    },
    labelContainerStyle: {
      height: 0,
      paddingHorizontal: 5,
    },
    labelStyle: {
      backgroundColor: colors.secondary,
      paddingHorizontal: 3,
      color: colors.primary,
      ...fonts.heading6,
      fontSize: 10,
    },
    contentStyle: {
      padding: 0,
      margin: 0,
      zIndex: 1,
    },
  },
  SubmitButton: {
    errorStyle: {
      color: colors.error,
      fontSize: 12,
      paddingBottom: 8,
      lineHeight: 24,
      margin: 0,
      fontWeight: fontWeights.semiBold,
      fontFamily: fonts.fontFamily.default,
    },
  },
};

const globalStyles = {
  fontFamily: fonts.fontFamily.default,
};

export {
  globalStyles,
  theme,
  fonts,
  shapes,
  colors,
  backgroundColors,
  orderStatusColorCodes,
  datePriceChangeColors,
  routeStatusColorCodes,
  routeDashboardColorCodes,
  DSRequestsColorCodes,
  fontWeights,
};
