import React, { memo } from "react";
import { View } from "react-native";

import cloneDeep from "lodash/cloneDeep";

import { PrintIframeRenderer } from "components/elements";
import { giftCardProducts } from "library/utils/giftCardProducts";

import AdditionalInfoArea from "../additional-info-area";
import ProductSelection from "../product-selection";
import FloristSelection from "../florist-selection";
import AbbreviationCodes from "../abbreviation-codes";
import CustomerInsights from "../customer-insights";
import FuneralLog from "../funeral-log";
import get from "lodash/get";
import { ScrollView } from "react-native-gesture-handler";

//Todo - Move ScrollView inside each component so that search and filters will be applied easily
const SideCar = ({
  index,
  setShowSideCar,
  sideCarKey,
  sideCarInfo,
  formValues,
  formIKPath,
  setSelectedRecord,
  isSmallScreen,
  memberCodes,
  sendingMemberCode,
  isAutoFill,
  enableCPS,
  canModifyOrder = true,
  setSideCarOpen,
  setFieldValue,
  touched,
  setProductModalVisible,
  ...other
}) => {

  const productFirstChoiceCode = get(
    formValues,
    `orderItems.${index}.lineItems.0.productFirstChoiceCode`,
    ""
  );

  return (
    <View style={{ height: "100%" }}>
      {sideCarKey === "additional_info" && (
        <ScrollView>
          <AdditionalInfoArea
            index={index}
            formIKPath={formIKPath}
            formValues={cloneDeep(formValues)}
            isSmallScreen={isSmallScreen}
            sendingMemberCode={sendingMemberCode}
            isAutoFill={isAutoFill}
            enableCPS={enableCPS}
            canModifyOrder={canModifyOrder}
            setFieldValue={setFieldValue}
            touched={touched}
          />
        </ScrollView>
      )}
      {sideCarKey === "florist_selection" && (
        <ScrollView>
          <FloristSelection
            onComplete={() =>
              !isSmallScreen
                ? setShowSideCar("additional_info")
                : setShowSideCar("")
            }
            sideCarInfo={sideCarInfo}
            formValues={cloneDeep(formValues)}
            formIKPath={formIKPath}
            onSelect={(florist) => {
              !isSmallScreen
                ? setShowSideCar("additional_info")
                : setShowSideCar("");
              setSelectedRecord({
                florist,
                formIKPath: `${formIKPath}.deliveryInfo`,
              });
            }}
            sendingMemberCode={sendingMemberCode}
          />
        </ScrollView>
      )}
      {sideCarKey === "product_selection" && (
        <ScrollView>
          <ProductSelection
            onComplete={() =>
              !isSmallScreen
                ? setShowSideCar("additional_info")
                : setShowSideCar("")
            }
            sideCarInfo={sideCarInfo}
            formValues={formValues}
            formIKPath={formIKPath}
            onSelect={(product, actionType) => {
              if (
                giftCardProducts.includes(productFirstChoiceCode) ||
                (productFirstChoiceCode?.length &&
                  giftCardProducts.includes(product?.pid))
              ) {
                setProductModalVisible(true);
              } else {
                setSelectedRecord({
                  product,
                  actionType,
                  formIKPath: `${formIKPath}.lineItems`,
                  formIKPricePath: `${formIKPath}.price`,
                  quantity: 1,
                });
              }
            }}
            isSmallScreen={isSmallScreen}
            memberCodes={memberCodes}
            sendingMemberCode={sendingMemberCode}
            {...other}
          />
        </ScrollView>
      )}
      {sideCarKey === "abbreviation_codes" && (
        <ScrollView>
          <AbbreviationCodes
            onComplete={() =>
              !isSmallScreen
                ? setShowSideCar("additional_info")
                : setShowSideCar("")
            }
            sideCarInfo={sideCarInfo}
          />
        </ScrollView>
      )}
      {sideCarKey === "customer_insights" && (
        <ScrollView>
          <CustomerInsights setSideCarOpen={setSideCarOpen} />
        </ScrollView>
      )}
      {sideCarKey === "funeral_log" && (
        <FuneralLog
          onComplete={() =>
            !isSmallScreen
              ? setShowSideCar("additional_info")
              : setShowSideCar("")
          }
          setSideCarOpen={setSideCarOpen}
        />
      )}

      <PrintIframeRenderer testID={"product-recipe-print"} />
    </View>
  );
};

export default memo(SideCar);
