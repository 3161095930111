export const getDisplayEligible = (msgDirection = "INBOUND") => {
  const isIncoming = msgDirection === "INBOUND";

  return {
    incoming: ["incoming"],
    outgoing: ["outgoing"],
    accepted: [isIncoming ? "incoming" : "outgoing", "accepted"],
    design: [isIncoming ? "incoming" : "outgoing", "accepted", "design"],
    designed: [
      isIncoming ? "incoming" : "outgoing",
      "accepted",
      "design",
      "designed",
    ],
    delivery: [
      isIncoming ? "incoming" : "outgoing",
      "accepted",
      "design",
      "designed",
      "delivery",
    ],
    completed: [
      isIncoming ? "incoming" : "outgoing",
      "accepted",
      "design",
      "designed",
      "delivery",
      "completed",
    ],
    cancelled: [isIncoming ? "incoming" : "outgoing", "cancelled"],
    rejected: [isIncoming ? "incoming" : "outgoing", "rejected"],
    forfeited: [isIncoming ? "incoming" : "outgoing", "forfeited"],
    error: [isIncoming ? "incoming" : "outgoing", "error"],
    "incoming-pickup": ["incoming"],
    "accepted-pickup": ["incoming", "accepted"],
    "design-pickup": ["incoming", "accepted", "design"],
    "designed-pickup": ["incoming", "accepted", "design", "designed"],
    "delivery-pickup": [
      "incoming",
      "accepted",
      "design",
      "designed",
      "delivery",
    ],
    "completed-pickup": [
      "incoming",
      "accepted",
      "design",
      "designed",
      "delivery",
      "completed",
    ],
    refunded: [isIncoming ? "incoming" : "outgoing", "refunded"],
  };
};

export const getConfig = (
  msgDirection = "INBOUND",
  status,
  isSmallScreen,
  hasPaymentError = false,
  enableDesignedStatus = false
) => {
  const getCurrentStatus = (hasPaymentError) => {
    if (hasPaymentError) {
      return {
        error: {
          displayStatus: "Error",
          iconExists: true,
          iconName: "error",
          inActiveIcon: "error",
          styleConfig: {
            labelSize: isSmallScreen ? 12 : 13,
            labelColor: "#6a6a6a",
            stripColor: "#c4c4c4",
            blockBgColor: "#fff",
            iconWidth: isSmallScreen ? 19 : 23,
            iconHeight: isSmallScreen ? 19 : 23,
          },
        },
      };
    } else {
      return {
        ...(status.toUpperCase() !== "CANCELLED" &&
          status.toUpperCase() !== "REJECTED" &&
          status.toUpperCase() !== "FORFEITED" &&
          status.toUpperCase() !== "ERROR" &&
          status.toUpperCase() !== "REFUNDED" && {
            accepted: {
              displayStatus: "Accepted",
              iconExists: true,
              iconName: "accepted",
              inActiveIcon: "orders",
              actionKey: "accept",
              styleConfig: {
                labelSize: isSmallScreen ? 12 : 13,
                labelColor: "#114258",
                stripColor: "#aadec6",
                activeBorder: "#84d0ad",
                blockBgColor: "rgba(170, 222, 198, 0.2)",
                iconWidth: isSmallScreen ? 21 : 25,
                iconHeight: isSmallScreen ? 21 : 25,
              },
            },
          }),
        ...(status.toUpperCase() === "CANCELLED" && {
          cancelled: {
            displayStatus: "Cancelled",
            iconExists: true,
            iconName: "cancelled",
            inActiveIcon: "cancelled",
            styleConfig: {
              labelSize: isSmallScreen ? 12 : 13,
              labelColor: "#6a6a6a",
              stripColor: "#c4c4c4",
              blockBgColor: "#fff",
              iconWidth: isSmallScreen ? 19 : 23,
              iconHeight: isSmallScreen ? 19 : 23,
            },
          },
        }),
        ...(status.toUpperCase() === "REFUNDED" && {
          refunded: {
            displayStatus: "Refunded",
            iconExists: true,
            iconName: "cancelled",
            inActiveIcon: "cancelled",
            styleConfig: {
              labelSize: isSmallScreen ? 12 : 13,
              labelColor: "#6a6a6a",
              stripColor: "#c4c4c4",
              blockBgColor: "#fff",
              iconWidth: isSmallScreen ? 19 : 23,
              iconHeight: isSmallScreen ? 19 : 23,
            },
          },
        }),
        ...(status.toUpperCase() === "REJECTED" && {
          rejected: {
            displayStatus: "Rejected",
            iconExists: true,
            iconName: "rejected",
            inActiveIcon: "rejected",
            styleConfig: {
              labelSize: isSmallScreen ? 12 : 13,
              labelColor: "#6a6a6a",
              stripColor: "#c4c4c4",
              blockBgColor: "#fff",
              iconWidth: isSmallScreen ? 19 : 23,
              iconHeight: isSmallScreen ? 19 : 23,
            },
          },
        }),
        ...(status.toUpperCase() === "FORFEITED" && {
          forfeited: {
            displayStatus: "Forfeited",
            iconExists: true,
            iconName: "forfeited",
            inActiveIcon: "forfeited",
            styleConfig: {
              labelSize: isSmallScreen ? 12 : 13,
              labelColor: "#6a6a6a",
              stripColor: "#c4c4c4",
              blockBgColor: "#fff",
              iconWidth: isSmallScreen ? 19 : 23,
              iconHeight: isSmallScreen ? 19 : 23,
            },
          },
        }),
        ...(status.toUpperCase() === "ERROR" && {
          error: {
            displayStatus: "Error",
            iconExists: true,
            iconName: "error",
            inActiveIcon: "error",
            styleConfig: {
              labelSize: isSmallScreen ? 12 : 13,
              labelColor: "#6a6a6a",
              stripColor: "#c4c4c4",
              blockBgColor: "#fff",
              iconWidth: isSmallScreen ? 19 : 23,
              iconHeight: isSmallScreen ? 19 : 23,
            },
          },
        }),
      };
    }
  };
  return {
    ...(msgDirection === "INBOUND" &&
      status !== "FORWARDED" && {
        incoming: {
          displayStatus: "Incoming",
          iconExists: true,
          iconName: "incoming",
          inActiveIcon: "incoming",
          styleConfig: {
            labelSize: isSmallScreen ? 12 : 13,
            labelColor: "#114258",
            stripColor: "#e2f7ee",
            activeBorder: "#90cebf",
            blockBgColor: "rgba(226, 247, 238, 0.25)",
            iconWidth: isSmallScreen ? 20 : 24,
            iconHeight: isSmallScreen ? 20 : 24,
          },
        },
      }),
    ...((msgDirection === "OUTBOUND" ||
      (msgDirection === "INBOUND" && status === "FORWARDED")) && {
      outgoing: {
        displayStatus: "Outgoing",
        iconExists: true,
        iconName: "outgoing",
        inActiveIcon: "outgoing",
        styleConfig: {
          labelSize: isSmallScreen ? 12 : 13,
          labelColor: "#114258",
          stripColor: "#e2f7ee",
          activeBorder: "#90cebf",
          blockBgColor: "#fff",
          iconWidth: isSmallScreen ? 20 : 24,
          iconHeight: isSmallScreen ? 20 : 24,
        },
      },
    }),
    ...getCurrentStatus(hasPaymentError),
    design: {
      displayStatus: "In Design",
      iconExists: true,
      iconName: "design",
      inActiveIcon: "design-black",
      actionKey: "design",
      styleConfig: {
        labelSize: isSmallScreen ? 12 : 13,
        labelColor: "#114258",
        stripColor: "#31aa92",
        activeBorder: "#23a587",
        blockBgColor: "rgba(35, 165, 135, 0.07)",
        iconWidth: isSmallScreen ? 21 : 26,
        iconHeight: isSmallScreen ? 21 : 26,
      },
    },
    ...(enableDesignedStatus && {
      designed: {
        displayStatus: "Designed",
        iconExists: true,
        iconName: "designed",
        inActiveIcon: "designed-blue",
        actionKey: "designed",
        styleConfig: {
          labelSize: isSmallScreen ? 12 : 13,
          labelColor: "#114258",
          stripColor: "#037c77",
          activeBorder: "#037C77",
          blockBgColor: "rgba(35, 165, 135, 0.07)",
          iconWidth: isSmallScreen ? 21 : 26,
          iconHeight: isSmallScreen ? 21 : 26,
        },
      },
    }),
    delivery: {
      displayStatus: "Delivery",
      iconExists: true,
      iconName: "delivery",
      inActiveIcon: "delivery-black",
      actionKey: "out-for-delivery",
      styleConfig: {
        labelSize: isSmallScreen ? 12 : 13,
        labelColor: "#114258",
        stripColor: "#11838e",
        activeBorder: "#037c77",
        blockBgColor: "rgba(3, 124, 119, 0.07)",
        iconWidth: isSmallScreen ? 23 : 27,
        iconHeight: isSmallScreen ? 14 : 18,
      },
    },
    completed: {
      displayStatus: "Completed",
      iconExists: true,
      iconName: "completed",
      inActiveIcon: "completed",
      actionKey: "delivery-confirmation",
      styleConfig: {
        labelSize: isSmallScreen ? 12 : 13,
        labelColor: "#114258",
        stripColor: "#114258",
        activeBorder: "#114258",
        blockBgColor: "rgba(0, 133, 193, 0.06)",
        iconWidth: isSmallScreen ? 20 : 24,
        iconHeight: isSmallScreen ? 16 : 20,
      },
    },
  };
};

export const getOrderStatus = (order) => {
  const { status, isPickUpOrder } = order;

  if (isPickUpOrder) {
    if (["NEW", "VIEWED", "PRINTED"].includes(status)) return "incoming-pickup";

    if (["DESIGN"].includes(status)) return "design-pickup";

    if (["DESIGNED"].includes(status)) return "designed-pickup";

    if (status === "OUT_FOR_DELIVERY") return "delivery-pickup";

    if (["DELIVERED"].includes(status)) return "completed-pickup";

    if (["ACKNOWLEDGED", "ACKNOWLEDGE_PRINT"].includes(status))
      return "accepted-pickup";

    if (status === "CANCELLED") return "cancelled-pickup";
  }

  if (["NEW", "VIEWED", "PRINTED"].includes(status)) return "incoming";

  if (["DESIGN", "DS_REQUESTED", "DS_REQUEST_PENDING"].includes(status))
    return "design";

  if (["DESIGNED"].includes(status)) return "designed";

  if (status === "OUT_FOR_DELIVERY") return "delivery";

  if (["DELIVERED"].includes(status)) return "completed";

  if (["SENT", "FORWARDED"].includes(status)) return "outgoing";

  if (status === "CANCELLED") return "cancelled";

  if (status === "REJECTED") return "rejected";

  if (status === "FORFEITED") return "forfeited";

  if (status === "ERROR") return "error";

  return "accepted";
};

export const actionMap = {
  accepted: "accept",
  assign: "assign-to-designer",
  design: "design",
  designed: "designed",
  delivery: "out-for-delivery",
  completed: "delivery-confirmation",
};
