import React, { useState, useEffect } from "react";
import { View, Text } from "react-native";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Form,
  FormField,
  FormFieldPicker,
  SubmitButton,
} from "components/elements/forms";
import I18NContext from "library/contexts/i18N";
import tw from "tailwind-rn";
import * as Yup from "yup";
import { createTransaction } from "library/sagas/session/slice";
import { ToasterHandler } from "components/elements";
import { getSessionReceiptContent } from "components/views/drawer/create-order/helper";

const options = [
  { label: "Paid In", value: "Paid-In" },
  { label: "Paid Out", value: "Paid-Out" },
];

Yup.addMethod(
  Yup.string,
  "isValidSessionAmount",
  function (currentBalance, errorMessage) {
    return this.test(`is-valid-sesion-amount`, errorMessage, function (value) {
      return parseFloat(currentBalance) >= parseFloat(value);
    });
  }
);

export const printTheRecipt = (resp) => {
  const printContent = getSessionReceiptContent(resp);
  const { ipcRenderer } = window.require("electron");
  ipcRenderer.send("printOrderInfo", 1, [printContent]);
};

const PaidInOut = (props) => {
  const { sessionInfo, createTransaction, operator } = props;
  const { messages, Localise } = React.useContext(I18NContext);
  const [validationOnChange, setValidationOnChange] = useState(false);

  const ValidationSchema = Yup.object().shape({
    amount: Yup.string()
      .required(Localise(messages, "Enter Amount"))
      .matches(/^\d+\.?\d*$/, Localise(messages, "Invalid Price"))
      .when("type", {
        is: (value) => value === "Paid-Out",
        then: Yup.string().isValidSessionAmount(
          sessionInfo.currentBalance,
          Localise(messages, "Insufficient Balance")
        ),
      }),

    type: Yup.string().required(Localise(messages, "Select Type")),
  });

  return (
    <Form
      initialValues={{ amount: "", type: "", notes: "" }}
      onSubmit={(values, formikBag) => {
        const { memberId, macAddress } = sessionInfo;
        createTransaction({
          params: {
            ...values,
            memberId,
            macAddress,
            user: operator,
            userNote: values.notes,
            orderId: "",
          },
          resolve: (resp) => {
            printTheRecipt(resp);
            setValidationOnChange(false);
            ToasterHandler(
              "success",
              Localise(messages, `Transaction created`)
            );
            formikBag.setSubmitting(false);
            formikBag.resetForm();
          },
          reject: () => {
            ToasterHandler(
              "uh oh",
              Localise(
                messages,
                `Failed to create transaction. Please try again.`
              )
            );
            formikBag.setSubmitting(false);
          },
        });
      }}
      validateOnBlur={validationOnChange}
      validateOnChange={validationOnChange}
      validationSchema={ValidationSchema}
      render={({ values, submitCount }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (submitCount > 0) {
            setValidationOnChange(true);
          }
        }, [submitCount]);
        return (
          <View>
            <View style={{ margin: 5, marginTop: 20 }}>
              <Text>{Localise(messages, "Paid In / Out")}</Text>
            </View>
            <View style={tw("flex-row items-center")}>
              <FormField
                name="amount"
                containerStyle={{
                  width: 150,
                }}
                isPrice={true}
                iconType="material-community"
                iconName="currency-usd"
                iconSize={13}
                inputContainerStyle={{
                  padding: 6,
                }}
                leftIconContainerStyle={{ paddingRight: 0 }}
                keyboardType="numeric"
                placeholder={Localise(messages, "0")}
              />
              <FormFieldPicker
                placeholder={{ label: "Type", value: "" }}
                containerStyle={{ width: 170 }}
                data={options}
                name="type"
                label=""
              />
            </View>
            <View style={tw("flex-row items-center")}>
              <FormField
                name="notes"
                label={""}
                placeholder={"Notes"}
                editable={true}
                testID="notes"
                accessibilityLabel="notes"
                containerStyle={{ width: 220 }}
                multiline={true}
                maxLength={200}
                numberOfLines={1}
                maxNumberOfLines={5}
              />
              <SubmitButton
                title={Localise(messages, "Submit")}
                containerStyle={{
                  width: 85,
                  margin: 0,
                  marginLeft: 10,
                  height: 44,
                }}
                testID="Submit"
                accessibilityLabel="Submit"
              />
            </View>
          </View>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createTransaction,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(PaidInOut);
