import React from "react";
import { View, Text } from "react-native";

import { fonts } from "styles/theme";

import I18NContext from "library/contexts/i18N";

const FeeCoverageError = ({ errorsArray = [], title }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  return (
    errorsArray.length > 0 && (
      <>
        <View>
          <Text
            style={[
              fonts.error,
              { marginLeft: 5, textAlign: "left", fontWeight: "800" },
            ]}
          >
            {Localise(messages, title) ||
              Localise(messages, "Please enter Fees for the fields below")}
          </Text>
        </View>
        {errorsArray.map((field, i) => {
          return (
            <View key={i}>
              <Text
                style={[
                  fonts.error,
                  { marginLeft: 10, textAlign: "left", fontWeight: "800" },
                ]}
              >
                {`\u2022  ${Localise(messages, field)}`}
              </Text>
            </View>
          );
        })}
      </>
    )
  );
};

export default FeeCoverageError;
