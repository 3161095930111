import React from "react";
import { View } from "react-native";
import { Input, Button } from "react-native-elements";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { theme } from "styles/theme";
import useStateIfMounted from "library/utils/useStateIfMounted";

const FormFieldUpdate = ({
  buttonTitle,
  containerStyle,
  inputContainerStyle,
  name,
  placeholder,
  handleOnPress,
  fieldVal,
  ...otherProps
}) => {
  const [value, setValue] = useStateIfMounted(fieldVal);
  const { messages, Localise } = React.useContext(I18NContext);

  return (
    <View style={[tw("flex flex-row items-baseline mt-2")]}>
      <Input
        autoCapitalize="none"
        autoCorrect={false}
        placeholder={Localise(messages, placeholder)}
        name={name}
        testID={name}
        accessibilityLabel={name}
        containerStyle={containerStyle}
        inputContainerStyle={inputContainerStyle}
        onChangeText={setValue}
        value={value}
        {...otherProps}
      />
      <Button
        title={Localise(messages, buttonTitle)}
        style={{ width: 60 }}
        buttonStyle={{
          ...theme.Button.regularButtonStyle,
          paddingVertical: 3,
        }}
        titleStyle={theme.Button.regularTitleStyle}
        onPress={() => handleOnPress(value)}
      />
    </View>
  );
};

export default React.memo(FormFieldUpdate);
