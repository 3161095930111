import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Platform, ScrollView, View } from "react-native";
import { Text } from "react-native-elements";

import toLower from "lodash/toLower";
import startCase from "lodash/startCase";
import get from "lodash/get";

import { ToasterHandler, Spinner } from "components/elements";
import { Form, FormField, FormFieldCheckBox } from "components/elements/forms";
import { SaveCancelButtons, Currency } from "components/wrappers";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import {
  selectSideCar,
  selectShopCode,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import {
  selectFeesCoverage,
  selectSearchZipcodeText,
} from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/selector";
import { setSideCar } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import {
  saveCityZipcodesFees,
  fetchCityZipcodesFees,
} from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/slice";
import {
  roundTheFees,
  isValidFeeValue,
} from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/sections/fee-coverage";

import { getCityZipcodesValidationSchema } from "../yup";
import SearchFilter from "./searchFilter";
import FeeCoverageError from "../errors";
import FeesCoverageModal from "../input/alert";
import { FormFieldProps } from "../ui-config";

import { fonts, fontWeights, backgroundColors, colors } from "styles/theme";
import tw from "tailwind-rn";

const CityZipCodes = () => {
  const dispatch = useDispatch();
  const shopCode = useSelector(selectShopCode);
  const { data } = useSelector(selectSideCar);
  const { messages, Localise } = React.useContext(I18NContext);
  const { isMobile } = React.useContext(DeviceContext);
  const {
    cityZipcodesFees: { content: cityZipcodesCoverage = [] },
  } = useSelector(selectFeesCoverage);
  const searchZip = useSelector(selectSearchZipcodeText);

  const currency = Currency(shopCode);
  const [showModal, setShowModal] = useState({});

  useEffect(() => {
    dispatch(fetchCityZipcodesFees({ params: data }));
  }, [data.city]);

  const onSubmit = (values, formikBag) => {
    dispatch(
      saveCityZipcodesFees({
        params: { values, initialValues: cityZipcodesCoverage },
        resolve: () => {
          ToasterHandler(
            "success",
            Localise(
              messages,
              "Your Fee and Coverage Settings have been updated"
            )
          );
          dispatch(setSideCar());
          formikBag.setSubmitting(false);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          dispatch(setSideCar());
          formikBag.setSubmitting(false);
        },
      })
    );
  };

  if (!cityZipcodesCoverage.length)
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );

  const address = `${startCase(toLower(cityZipcodesCoverage[0].city))} ${
    cityZipcodesCoverage[0].state
  }`;

  return (
    <>
      <Form
        initialValues={cityZipcodesCoverage}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
        validationSchema={getCityZipcodesValidationSchema(messages, Localise)}
        validateOnChange={true}
        render={({ setFieldValue, values, errors }) => {
          const [{ status: cityStatus, fee: cityFee, city }, ...cityZipcodes] =
            values;
          const errorsArrayIndexes = Object.keys(errors);
          const errorsArray = errorsArrayIndexes.map((errorIndex) =>
            get(
              values,
              `${errorIndex}.${errorIndex === "0" ? "city" : "zipCode"}`
            )
          );
          return (
            <>
              <View style={tw("flex-row items-center")}>
                <View style={{ maxHeight: 40, overflow: "hidden" }}>
                  <FormFieldCheckBox
                    iconRight={false}
                    name={"0.status"}
                    size={20}
                    title={address}
                    isChecked={cityStatus === "Y"}
                    textStyle={{
                      fontFamily: fonts.fontFamily.bold,
                      fontWeight: fontWeights.bold,
                    }}
                    onChangehandler={() => {
                      setFieldValue("0.status", cityStatus === "Y" ? "N" : "Y");
                    }}
                  />
                </View>
                <FormField
                  name={"0.fee"}
                  {...FormFieldProps}
                  handleOnBlur={(val) => {
                    const fee = roundTheFees(val);
                    isValidFeeValue(fee) &&
                      fee !== cityFee &&
                      setShowModal({
                        title: `${city} ${Localise(messages, "Zip Codes")}`,
                        primaryAction: () => {
                          setFieldValue("0.fee", fee);
                          cityZipcodes.map((data, i) => {
                            setFieldValue(`${i + 1}.fee`, fee);
                          });
                          setShowModal({});
                        },
                        secondaryAction: () => {
                          setFieldValue("0.fee", cityFee);
                          setShowModal({});
                        },
                      });
                  }}
                  iconType={"material-community"}
                  iconName={"currency-usd"}
                  iconSize={14}
                  currency={currency}
                />
              </View>
              <SearchFilter data={cityZipcodes} />
              {showModal.title && (
                <FeesCoverageModal
                  title={Localise(messages, showModal.title)}
                  primaryAction={showModal.primaryAction}
                  secondaryAction={showModal.secondaryAction}
                />
              )}
              <View>
                <ScrollView
                  contentContainerStyle={tw(
                    "flex flex-row flex-wrap items-start"
                  )}
                  {...(Platform.OS === "android" && {
                    nestedScrollEnabled: true,
                  })}
                >
                  {cityZipcodes.map(({ zipCode, status, fee }, i) => {
                    const isFieldChecked = status === "Y";
                    if (!toLower(zipCode).startsWith(toLower(searchZip)))
                      return null;
                    const index = i + 1;

                    return (
                      <View
                        key={index}
                        style={[
                          tw(
                            `flex-row items-center mb-3 border py-1 ${
                              isMobile ? "mr-1" : "mr-3"
                            }`
                          ),
                          {
                            backgroundColor: backgroundColors.navBar,
                            borderColor: colors.light,
                          },
                        ]}
                      >
                        <View style={{ maxHeight: 40, overflow: "hidden" }}>
                          <FormFieldCheckBox
                            iconRight={false}
                            name={`${index}.status`}
                            size={20}
                            title={""}
                            isChecked={isFieldChecked}
                            inputContainerStyle={{
                              marginRight: 0,
                            }}
                            onChangehandler={() => {
                              !isFieldChecked &&
                                !isValidFeeValue(fee) &&
                                setFieldValue(`${index}.fee`, cityFee);
                              setTimeout(() => {
                                setFieldValue(
                                  `${index}.status`,
                                  isFieldChecked ? "N" : "Y"
                                );
                              }, 0);
                            }}
                          />
                        </View>
                        <Text
                          style={{
                            backgroundColor: backgroundColors.navBar,
                            ...fonts.heading4,
                          }}
                        >
                          {zipCode}
                        </Text>
                        <FormField
                          name={`${index}.fee`}
                          {...FormFieldProps}
                          editable={isFieldChecked}
                          inputContainerStyle={{
                            ...FormFieldProps.inputContainerStyle,
                            backgroundColor: isFieldChecked
                              ? backgroundColors.secondary
                              : backgroundColors.greyColor,
                          }}
                          iconType={"material-community"}
                          iconName={"currency-usd"}
                          iconSize={14}
                          currency={currency}
                        />
                      </View>
                    );
                  })}
                </ScrollView>
              </View>
              <FeeCoverageError errorsArray={errorsArray} />
              <SaveCancelButtons
                buttonTitle={Localise(messages, "Save")}
                customContainerStyles={{
                  width: 100,
                  marginLeft: 0,
                }}
              />
            </>
          );
        }}
      />
    </>
  );
};

export default CityZipCodes;
