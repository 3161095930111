import React, { useEffect, useRef } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useIsFocused } from "@react-navigation/native";

import {
  setInit,
  setReset,
  setAction,
} from "library/sagas/views/home/drawer/product-catalog/dashboard/slice";
import { selectSearchText } from "library/sagas/views/home/drawer/product-catalog/dashboard/selector";
import {
  selectScreenParam,
  selectShopCode,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";
import { navigateScreen } from "library/sagas/views/home/drawer/product-catalog/common/slice";
import { DeviceContext } from "library/contexts/appSettings";

import { ScreenComponent } from "../helper";
import { PageHeader } from "../header";
import Catalogs from "./catalogs";
import Collections from "./collections";
import { Settings, PriceMinimumProducts } from "../common/settings";

const CatalogDashboard = ({ route: { name, params = {} }, rootNavigation }) => {
  const dispatch = useDispatch();
  const shopCode = useSelector(selectShopCode);

  const { isDesktop, isTablet, isMobile } = React.useContext(DeviceContext);
  const { handle } = params;
  const screenRef = useRef();
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused) return;
    dispatch(setInit());
    dispatch(navigateScreen({ name, params, reduxOnly: true }));
  }, [isFocused, shopCode]);

  useEffect(() => {
    return () => {
      dispatch(setReset());
    };
  }, []);

  useEffect(() => {
    if (handle === "collections") {
      setTimeout(
        () =>
          screenRef.current.scrollTo({
            y: isMobile ? 660 : 350,
            animated: true,
          }),
        500
      );
    }
  }, [handle]);

  const DashboardMain = () => {
    return (
      <View
        fsClass="fs-unmask"
        style={{ maxWidth: isDesktop ? 996 : isTablet ? 676 : 332 }}
      >
        <PageHeader
          selectSearchText={selectSearchText}
          setSearchText={setAction}
        />
        <Catalogs />
        <Collections />
      </View>
    );
  };

  const DashboardSideCar = () => {
    const sideCar = useSelector(selectScreenParam("sideCar"));
    return sideCar === "catalogSettings" ? (
      <Settings />
    ) : sideCar === "priceMinimumProducts" ? (
      <PriceMinimumProducts />
    ) : null;
  };

  return (
    <ScreenComponent screenRef={screenRef} rootNavigation={rootNavigation}>
      <DashboardMain />
      <DashboardSideCar />
    </ScreenComponent>
  );
};

export default CatalogDashboard;
