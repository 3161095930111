import { put, select, takeEvery } from "redux-saga/effects";
import { getOrderDetailsFromScan } from "./slice";
import { fetchAllOrdersData } from "library/sagas/ongoing/order-details/slice";
import { selectRecordById } from "library/sagas/ongoing/current-orders/selector";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import UserProfileStorage from "library/storage/userProfile";
import { processOrderResponse } from "library/utils/orderListing";
import { setScannedOrders } from "./slice";

export function* handleGetOrderDetailsFromScan(action = {}) {
  const { params = {}, resolve, reject } = get(action, "payload", {});
  const memberCodes = UserProfileStorage.getProfileMemberCodes();

  try {
    const {
      dm: deliveryMethod = "",
      sm: sourceMemberCode = "",
      oid: orderItemId = "",
      fetchFromDetails = false,
    } = params;

    if (deliveryMethod && sourceMemberCode && orderItemId) {
      if (memberCodes.includes(sourceMemberCode)) {
        const recordData = yield select(selectRecordById(orderItemId));
        if (!isEmpty(recordData[0]) && !fetchFromDetails) {
          yield put(setScannedOrders(recordData[0]));
          resolve && resolve({ foundInListing: true });
        } else {
          yield put(
            fetchAllOrdersData({
              recordId: orderItemId,
              deliveryMethod,
              sourceMemberCode,
              resolve: (orderDetails) => {
                if (!isEmpty(orderDetails)) {
                  if (!isEmpty(recordData)) {
                    resolve &&
                      resolve({
                        orderDetails,
                        tileRecord: recordData[0],
                        foundInListing: false,
                      });
                  } else {
                    const {
                      orderCollection: {
                        scannedOrders: { orders },
                      },
                    } = processOrderResponse(
                      [orderDetails?.orderItems[0]],
                      {
                        scannedOrders: { orders: [] },
                      },
                      "scannedOrders"
                    );
                    resolve &&
                      resolve({
                        orderDetails,
                        tileRecord: orders[0],
                        foundInListing: false,
                      });
                  }
                }
              },
              reject: (err) => {
                reject && reject(err);
              },
            })
          );
        }

        // Redirecting to orders page.
      } else {
        reject && reject("You don’t have access to this order");
      }
    } else {
      reject && reject("QR code is not from MercuryHQ");
    }
  } catch (error) {
    reject &&
      reject("Failed to open the order from scan, Please try again!", error);
  }
}

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeEvery(getOrderDetailsFromScan.type, handleGetOrderDetailsFromScan);
}

export default watchSaga;
