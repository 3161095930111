import { Platform, PermissionsAndroid } from "react-native";
import { ToasterHandler } from "components/elements";
import { Buffer } from "buffer";
import FileViewer from "react-native-file-viewer";

let RNFS;
if (Platform.OS !== "web") {
  RNFS = require("react-native-fs");
}

export const handleSavingReport = (
  messages,
  Localise,
  res,
  fileName,
  folderName
) => {
  if (Platform.OS === "web") {
    var tempAnchorTag = document.createElement("a"); // creating temp anchor tag
    tempAnchorTag.href =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      res.source;
    tempAnchorTag.download = fileName;
    tempAnchorTag.click(); // triggering click event in order to download sample file automatically as we alreday clicked on sample file link
  } else if (Platform.OS === "ios") {
    saveFileOnDevice(res, messages, Localise, fileName, folderName);
  } else if (Platform.OS === "android") {
    checkPermissionsAndDownloadFile(
      res,
      messages,
      Localise,
      fileName,
      folderName
    );
  }
};

const saveFileOnDevice = (res, messages, Localise, fileName, folderName) => {
  var directoryType =
    Platform.OS === "android"
      ? RNFS.DownloadDirectoryPath
      : RNFS.DocumentDirectoryPath;
  var fileDirPath = `${directoryType}/${folderName}`;
  const decodedString = Buffer.from(res.source, "base64").toString();
  RNFS.mkdir(fileDirPath)
    .then(() => {
      var filePath = `${fileDirPath}/${fileName}`;
      RNFS.writeFile(filePath, decodedString)
        .then(async () => {
          FileViewer.open(filePath)
            .then(() => {})
            .catch(() => {
              ToasterHandler(
                "success",
                Localise(messages, "Download complete")
              );
            });
        })
        .catch(() => {
          ToasterHandler(
            "error",
            Localise(messages, "Download failed, please try again")
          );
        });
    })
    .catch(() => {
      ToasterHandler(
        "error",
        Localise(messages, "Download failed, please try again")
      );
    });
};

const checkPermissionsAndDownloadFile = async (
  res,
  messages,
  Localise,
  fileName,
  filePath
) => {
  try {
    const granted = await PermissionsAndroid.check(
      PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
    );

    // Asking for storage permissions on Android 13 is no longer needed
    if (granted || Platform.Version >= 33) {
      saveFileOnDevice(res, messages, Localise, fileName, filePath);
    } else {
      const result = await PermissionsAndroid.requestMultiple(
        [
          PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
          PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
        ],
        {
          title: Localise(messages, ""),
          message: Localise(
            messages,
            "Please approve Mercury HQ access to download"
          ),
        }
      );

      if (
        result["android.permission.READ_EXTERNAL_STORAGE"] &&
        result["android.permission.WRITE_EXTERNAL_STORAGE"] ===
          PermissionsAndroid.RESULTS.GRANTED
      ) {
        saveFileOnDevice(res, messages, Localise, fileName, filePath);
      } else {
        ToasterHandler(
          "error",
          Localise(messages, "Please approve Mercury HQ access to download")
        );
      }
    }
  } catch (err) {
    ToasterHandler(
      "error",
      Localise(messages, "Download failed, please try again")
    );
  }
};
