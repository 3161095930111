import React, { useContext, useCallback, memo } from "react";
import { TouchableOpacity, Image } from "react-native";
import I18NContext from "library/contexts/i18N";
import IMAGES from "static/assets/images";
import { Tooltip } from "components/elements";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import {
  setNavParams,
  resetOrderItemTabs,
  resetSubscriptionData,
} from "library/sagas/views/home/drawer/create-order/slice";
import {
  selectIsEditOrder,
  selectIsEditSubscription,
} from "library/sagas/views/home/drawer/create-order/selector";

const OpenSubscription = ({ isSubscriptionsEnabled }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const isEditOrder = useSelector(selectIsEditOrder);
  const isEditSubscription = useSelector(selectIsEditSubscription);

  const {
    values: { isSubscription },
  } = useFormikContext();
  const { messages, Localise } = useContext(I18NContext);

  const handlePress = useCallback(() => {
    // resetting the multi order tabs
    dispatch(resetOrderItemTabs());
    if (!isSubscription) {
      dispatch(setNavParams({ action: "create", type: "subscription" }));
      navigation.navigate({
        name: "create-order",
        params: { action: "create", type: "subscription" },
      });
    } else {
      dispatch(setNavParams({ action: "", type: "" }));
      navigation.navigate({
        name: "create-order",
        params: { action: undefined, type: undefined },
      });
      dispatch(resetSubscriptionData());
    }
  }, [isSubscription, dispatch, navigation]);

  const imageSource = isSubscription
    ? IMAGES["subscription-enable"]
    : IMAGES["subscription"];

  if (isEditOrder || isEditSubscription || !isSubscriptionsEnabled) return null;

  return (
    <TouchableOpacity
      onPress={handlePress}
      key={`OpenSubscription`}
      testID="open_subscription"
      accessibilityLabel="open_subscription"
    >
      <Tooltip
        text={Localise(messages, "Create Subscription Order")}
        renderForWebOnly={true}
      >
        <Image
          style={{ width: 29, height: 27 }}
          resizeMode="cover"
          source={imageSource}
        />
      </Tooltip>
    </TouchableOpacity>
  );
};

export default memo(OpenSubscription);
