import React from "react";
import { View, ActivityIndicator } from "react-native";
import styles from "./styles";

const SpinnerComp = ({ size = "small" }) => (
  <View style={styles.loading}>
    <ActivityIndicator size={size} />
  </View>
);

export default SpinnerComp;
