export const getInitialValues = (details) => {
  const initalValues = {
    giftCardType: "false",
    giftCardNumber: "",
    openingBalance: "",
    currentBalance: "",
    issueDate: "",
    expirationDate: "",
    status: "",
    createdBy: "",
    multiUse: "",
    lastChars: "",
    id: "",
    recipientInfo: {
      giftCodeId: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
    },
    recipientEmail: "",
    recipientName: "",
  };
  return {
    ...initalValues,
    ...details,
  };
};
