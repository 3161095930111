import { createContext } from "react";
import noop from "lodash/noop";

/**
 * The context used for storing user access information
 *
 * @see 'providers/userAuth'
 * @see 'reducers/userAuth'
 */
const UserAuthContext = createContext({
  signIn: noop,
  signOut: noop,
  authToken: null,
  keepMeSignedIn: false,
});

export default UserAuthContext;
