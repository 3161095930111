/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Image } from "react-native-elements";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import tw from "tailwind-rn";

import { Accordion, Tooltip } from "components/elements";
import { backgroundColors, colors, fonts, theme } from "styles/theme";
import IMAGES from "static/assets/images";
import Environment from "library/utils/environment";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import {
  setEnableLassoTool,
  setZipInitialize,
  clearRouteCitiesZipcodes,
  setCanProgrammaticallyZoom,
} from "library/sagas/views/home/drawer/delivery/slice";
import {
  selectEnableLassoTool,
  selectRouteCitiesZipcodes,
  selectCitiesPlaceIds,
  selectZipCoverageLoading,
  selectCanProgrammaticallyZoom,
} from "library/sagas/views/home/drawer/delivery/selector";
import UserProfileStorage from "library/storage/userProfile";
import { OptimizeMessage } from "components/views/drawer/delivery/routes-dashboard/upsert-route/take-action/helper";

const RoutedMarker = ({
  content = "",
  orderItemId,
  markerAction,
  enableAction,
}) => {
  return (
    <>
      {enableAction ? (
        <TouchableOpacity
          onPress={() => {
            markerAction({
              action: "remove",
              id: orderItemId,
              mapAction: true,
            });
          }}
        >
          <View style={styles.routedMarker}>
            <Text style={styles.routedContent}>{content}</Text>
          </View>
        </TouchableOpacity>
      ) : (
        <View style={styles.routedMarker}>
          <Text style={styles.routedContent}>{content}</Text>
        </View>
      )}
    </>
  );
};

const NonRoutedMarker = ({
  content = "",
  isAvsVerified = true,
  orderItemId,
  markerAction,
  enableAction,
}) => {
  return (
    <>
      {enableAction ? (
        <TouchableOpacity
          onPress={() => {
            markerAction({
              action: "add",
              id: orderItemId,
              mapAction: true,
              stopAdditionMethod: "MAPS",
            });
          }}
        >
          <View
            style={
              !isAvsVerified
                ? styles.nonVerfiedNonroutedMarker
                : styles.nonroutedMarker
            }
          >
            <Text style={styles.nonroutedContent}>{content}</Text>
          </View>
        </TouchableOpacity>
      ) : (
        <View
          style={
            !isAvsVerified
              ? styles.nonVerfiedNonroutedMarker
              : styles.nonroutedMarker
          }
        >
          <Text style={styles.nonroutedContent}>{content}</Text>
        </View>
      )}
    </>
  );
};

const ShopMarker = ({ marker = "" }) => {
  return (
    <View style={styles.shopMarker}>
      <Image style={styles.shopContent} source={IMAGES[marker]} />
    </View>
  );
};

const zipStyleOptions = {
  strokeColor: "#808080",
  strokeOpacity: 1,
  strokeWeight: 3,
  fillColor: "#808080",
  fillOpacity: 0.2,
};
const enableStyleOptions = {
  strokeColor: "#519342",
  strokeOpacity: 1,
  strokeWeight: 3,
  fillColor: "#519342",
  fillOpacity: 0.2,
};
const disableStyleOptions = {
  strokeColor: "#808080",
  strokeOpacity: 1,
  strokeWeight: 3,
  fillColor: "#808080",
  fillOpacity: 0.5,
};

const DirectionsMap = React.memo(
  ({
    mapKey = "",
    mapsContainerHeight = 800,
    nonRoutedOrders,
    routes = [],
    ordersInRoute,
    shopLocation = {},
    shopLocations = [],
    showDirections = false,
    zoomLevel = 11,
    accordionWrap = true,
    isPastRoute = false,
    isEditRoute = false,
    routeData = {},
    localPermissions = {},
    markerAction = () => {},
    loading = false,
    isSmallScreen = false,
    autoRoutesData = [],
    autoRouteData = {},
    hasLimitedRouteAccess = false,
    selectedShopList = [],
    multiSelectedShopCodes = [],
    page = "",
    routeHasEdits = false,
  }) => {
    if (
      isEmpty(routes) &&
      isEmpty(ordersInRoute) &&
      isEmpty(nonRoutedOrders) &&
      !showDirections
    )
      return null;
    const dispatch = useDispatch();
    const { messages, Localise } = React.useContext(I18NContext);
    const [mapReference, setMapReference] = useStateIfMounted(null);
    const [mapsReference, setMapsReference] = useStateIfMounted(null);
    const [fitAllCoords, setFitAllCoords] = useStateIfMounted([]);
    const [polygonCoordinates, setPolygonCoordinates] = useStateIfMounted([]);
    const [polygonControllers, setPolygonControllers] = useStateIfMounted();
    const [zoneArea, setZoneArea] = useStateIfMounted(null);
    const [zonePolygonControllers, setZonePolygonControllers] =
      useStateIfMounted([]);
    /* eslint-disable-next-line no-unused-vars */
    const [showZipLayer, setShowZipLayer] = useStateIfMounted(false);
    const [showShopSelection, setshowShopSelection] = useStateIfMounted(false);
    const [circleRef, setCircleRef] = useStateIfMounted({});
    //const [dropDownQuery, setDropDownQuery] = useState();
    //eslint-disable-next-line
    const [bulkValue, setBulkValue] = useState(
      selectedShopList?.length === 1 ? selectedShopList : []
    );

    const isLassoToolEnabled = useSelector(selectEnableLassoTool);
    const citiesZipcodes = useSelector(selectRouteCitiesZipcodes) || [];
    const citiesplaceIds = useSelector(selectCitiesPlaceIds);
    const zipCoverageLoading = useSelector(selectZipCoverageLoading);
    const canProgrammaticallyZoom = useSelector(selectCanProgrammaticallyZoom);

    const { latitude: shopLatitude = "", longitude: shopLongitude = "" } =
      shopLocation;
    const renderObjects = useRef([]);
    const isProgrammaticZoom = useRef(false);
    const { isOptimized = false } = routes[0]?.route || {};
    const shopDetails = UserProfileStorage.getAllShopLocation();

    const googleMapsAPIKey = Environment.get(
      "GOOGLE_MAPS_API_KEY",
      "AIzaSyAPMdYXaLpwQM1nZIjr_GJyNKLe5ZLKzFU"
    );
    // const googleMapsAPPID = Environment.get(
    //   "GOOGLE_MAPS_APP_ID",
    //   "ae81b3064704ddac"
    // );

    const circleRadiusRange = 15 * 1.609344 * 1000; // multiplying by 1.60933 for mile to km conversion and 1000 for convertion to meters

    const closeFullScreen = () => {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else if (document.webkitFullscreenElement) {
        // For WebKit-based browsers
        document.webkitExitFullscreen();
      } else if (document.mozFullScreenElement) {
        // For Mozilla-based browsers
        document.mozCancelFullScreen();
      } else if (document.msFullscreenElement) {
        // For Microsoft Edge
        document.msExitFullscreen();
      }
    };

    useEffect(() => {
      if (mapReference && mapsReference) {
        const handleZoomChangeEvent = debounce(() => {
          if (isProgrammaticZoom.current) {
            isProgrammaticZoom.current = false; // Reset the flag
            return; // Ignore programmatic zoom changes
          }

          console.log("Manual zoom_changed");
          // Manual zoom detected, disable programmatic zoom
          dispatch(setCanProgrammaticallyZoom(false));
        }, 100); // Debounce delay

        const zoomChangeListener = mapsReference.event.addListener(
          mapReference,
          "zoom_changed",
          handleZoomChangeEvent
        );

        return () => {
          mapsReference.event.removeListener(zoomChangeListener);
        };
      }
    }, [mapReference, mapsReference]);

    let featureLayer = null;
    const enableZipCoverage = false; // disable ZipCode coverage

    const reloadFeatureLayer = () => {
      if (mapReference && mapsReference) {
        featureLayer = mapReference.getFeatureLayer("POSTAL_CODE");
        featureLayer.style = (options) => {
          return showZipLayer
            ? enableZipCoverage
              ? citiesplaceIds?.includes(options.feature.placeId)
                ? enableStyleOptions
                : disableStyleOptions
              : zipStyleOptions
            : {};
        };
      } else {
        featureLayer = null;
      }
    };

    const createCircle = (shopCode) => {
      const { latitude, longitude } = shopDetails[shopCode];
      return new mapsReference.Circle({
        clickable: true,
        strokeColor: "blue",
        strokeWeight: 0.2,
        fillColor: "blue",
        fillOpacity: 0.1,
        visible: false,
        map: mapReference,
        center: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
        radius: circleRadiusRange,
      });
    };

    const loadCircles = () => {
      if (selectedShopList?.length > 1) {
        let circleRefObj = {};
        multiSelectedShopCodes.map((each) => {
          circleRefObj[each] = circleRef[each]
            ? circleRef[each]
            : createCircle(each);
        });
        Object.keys(circleRefObj).map((shop) => {
          circleRefObj[shop] &&
            circleRefObj[shop].setVisible(
              showShopSelection && multiSelectedShopCodes.includes(shop)
            );
        });
        setCircleRef(circleRefObj);
      } else if (selectedShopList[0]) {
        Object.keys(circleRef).map((shop) => {
          circleRef[shop] && circleRef[shop].setVisible(false);
        });
        if (circleRef[selectedShopList[0]]) {
          circleRef[selectedShopList[0]].setVisible(showShopSelection);
        } else {
          let singleShopCircle = createCircle(selectedShopList[0]);
          singleShopCircle.setVisible(showShopSelection);
          setCircleRef({
            ...circleRef,
            [selectedShopList[0]]: singleShopCircle,
          });
        }
        setBulkValue(selectedShopList);
      }
    };

    useEffect(() => {
      mapsReference && mapReference && reloadFeatureLayer();
    }, [citiesZipcodes]);

    useEffect(() => {
      if (
        routes.length > 0 &&
        routes[0]?.route?.deliveryDate === mapKey.split(":")[1] &&
        !!mapsReference
      ) {
        routes.forEach((routeInfo) => {
          const { route: { routeMap } = {} } = routeInfo;
          const directionsRenderer = new mapsReference.DirectionsRenderer({
            suppressMarkers: true,
            preserveViewport: true,
          });
          directionsRenderer.setDirections(convertAPIResponseForMaps(routeMap));
          if (showDirections && localPermissions.tbtDirections) {
            directionsRenderer.setPanel(document.getElementById("directions"));
          }
          directionsRenderer.setMap(mapReference);
          renderObjects.current.push(directionsRenderer);
        });
      } else {
        for (var i in renderObjects.current) {
          renderObjects.current[i].setMap(null);
        }
      }
    }, [routes[0]?.route, mapsReference, mapKey]);

    useEffect(() => {
      mapsReference && displayZoneDetails();
    }, [autoRoutesData, autoRouteData, mapsReference]);

    useEffect(() => {
      if (mapsReference && !isEmpty(polygonCoordinates)) {
        const ordersOnMap = ordersInRoute.concat(nonRoutedOrders);
        let newOrdersList = {
          ordersInRoute: [...ordersInRoute],
          nonRoutedOrders: [...nonRoutedOrders],
        };
        ordersOnMap?.length > 0 &&
          ordersOnMap.map((order) => {
            const {
              recipientLocation: { latitude = "", longitude = "" } = {},
            } = order;
            const coordinates = new mapsReference.LatLng(latitude, longitude);
            const drawnPolygon = new mapsReference.Polygon({
              paths: polygonCoordinates,
            });

            const isInside = mapsReference?.geometry?.poly?.containsLocation(
              coordinates,
              drawnPolygon
            );
            if (isInside) {
              newOrdersList.ordersInRoute.indexOf(order) === -1 &&
                newOrdersList.ordersInRoute.push({
                  ...order,
                  stopAdditionMethod: "MAPS",
                });
              newOrdersList.nonRoutedOrders =
                newOrdersList.nonRoutedOrders.filter(
                  (eachOrder) => eachOrder.orderItemId !== order.orderItemId
                );
            }
          });
        markerAction({
          action: "drawOnMap",
          ordersList: newOrdersList,
          mapAction: true,
        });
        setTimeout(() => {
          closeFullScreen();
        }, 1000);
        setTimeout(() => {
          dispatch(setEnableLassoTool(false));
          clearMap();
        }, 3000);
      }
    }, [polygonCoordinates]);

    useEffect(() => {
      const mapMarkers = [];

      if (nonRoutedOrders?.length > 0) {
        nonRoutedOrders.forEach(
          ({ recipientLocation: { latitude = "", longitude = "" } = {} }) => {
            mapMarkers.push({
              lat: parseFloat(+latitude),
              lng: parseFloat(+longitude),
            });
          }
        );
      }

      if (ordersInRoute?.length > 0) {
        ordersInRoute.forEach(
          ({ recipientLocation: { latitude = "", longitude = "" } = {} }) => {
            mapMarkers.push({
              lat: parseFloat(+latitude),
              lng: parseFloat(+longitude),
            });
          }
        );
      }

      if (routes?.length < 1) {
        mapMarkers.push({
          lat: parseFloat(+shopLatitude),
          lng: parseFloat(+shopLongitude),
        });
      }
      if (shopLocations?.length > 0) {
        shopLocations.forEach(({ latitude = "", longitude = "" } = {}) => {
          mapMarkers.push({
            lat: parseFloat(+latitude),
            lng: parseFloat(+longitude),
          });
          mapMarkers.push({
            lat: parseFloat(+latitude + 0.1),
            lng: parseFloat(+longitude + 0.1),
          });
          mapMarkers.push({
            lat: parseFloat(+latitude - 0.1),
            lng: parseFloat(+longitude - 0.1),
          });
        });
      }

      if (autoRoutesData?.length) {
        autoRoutesData?.map((individualRouteData) => {
          individualRouteData.wayPoints?.map(({ lat, lng }) => {
            mapMarkers.push({ lat, lng });
          });
        });
      } else if (autoRouteData?.wayPoints) {
        autoRouteData.wayPoints?.map(({ lat, lng }) => {
          mapMarkers.push({ lat, lng });
        });
      }
      setFitAllCoords(mapMarkers);
    }, [
      JSON.stringify(routes),
      JSON.stringify(nonRoutedOrders),
      JSON.stringify(ordersInRoute),
      JSON.stringify(multiSelectedShopCodes),
      JSON.stringify(autoRouteData),
      JSON.stringify(autoRoutesData),
    ]);

    useEffect(() => {
      if (fitAllCoords.length > 0 && !!mapReference && !!mapsReference) {
        handleMapBounds(mapReference, mapsReference, fitAllCoords);
      }
    }, [
      mapReference,
      mapsReference,
      JSON.stringify(fitAllCoords),
      JSON.stringify(routes),
      JSON.stringify(nonRoutedOrders),
      showShopSelection,
      circleRef,
      JSON.stringify(multiSelectedShopCodes),
    ]);

    //* Commenting as we removed Shop selection for 15 mile circle for MVP */
    // useEffect(() => {
    //   if (page === "routeDetail") {
    //     setBulkValue(
    //       bulkValue.filter((each) => multiSelectedShopCodes.includes(each))
    //     );
    //   }
    // }, [multiSelectedShopCodes]);

    useEffect(() => {
      if (page === "routeDetail" && mapReference && mapsReference) {
        reloadFeatureLayer();
      }
    }, [mapReference, mapsReference, showZipLayer]);

    useEffect(() => {
      if (
        enableZipCoverage &&
        showZipLayer &&
        page === "routeDetail" &&
        mapReference &&
        mapsReference
      ) {
        dispatch(clearRouteCitiesZipcodes());
        dispatch(setZipInitialize({ shopList: multiSelectedShopCodes }));
      }
    }, [JSON.stringify(multiSelectedShopCodes)]);

    useEffect(() => {
      if (page === "routeDetail" && mapReference && mapsReference) {
        loadCircles();
      }
    }, [
      mapReference,
      mapsReference,
      showShopSelection,
      multiSelectedShopCodes,
    ]);

    const getMapBounds = (map, maps, locations) => {
      const bounds = new maps.LatLngBounds();
      if (locations?.length > 0) {
        const includeShop = [
          ...locations,
          ...[
            { lat: parseFloat(+shopLatitude), lng: parseFloat(+shopLongitude) },
          ],
        ];
        includeShop.forEach(({ lat, lng }) => {
          if (!!lat && !!lng) {
            bounds.extend(new maps.LatLng(lat, lng));
          }
        });
      } else {
        bounds.extend(
          new maps.LatLng(parseFloat(+shopLatitude), parseFloat(+shopLongitude))
        );
      }
      if (
        (multiSelectedShopCodes?.length > 0 || selectedShopList?.length == 1) &&
        showShopSelection
      ) {
        const shopsList = [
          ...(selectedShopList?.length == 1
            ? selectedShopList
            : multiSelectedShopCodes),
        ];
        shopsList.map((each) => {
          if (multiSelectedShopCodes.includes(each) && circleRef[each]) {
            bounds.union(circleRef[each].getBounds());
          }
        });
      }
      return bounds;
    };

    const displayZoneDetails = () => {
      if (autoRoutesData?.length) {
        if (zonePolygonControllers?.length) {
          zonePolygonControllers?.map((individualZone) =>
            individualZone.setMap(null)
          );
        }
        let listOfAllZonePolygons = [];
        autoRoutesData?.map((autoRouteData) => {
          const zonePolygon = new mapsReference.Polygon({
            strokeColor: autoRouteData?.colorCode,
            strokeWeight: 0.5,
            fillColor: autoRouteData?.colorCode,
            fillOpacity: 0.5,
            map: mapReference,
            path: autoRouteData.wayPoints,
          });
          listOfAllZonePolygons.push(zonePolygon);
        });
        setZonePolygonControllers(listOfAllZonePolygons);
      } else if (autoRouteData?.autoRouteId && !zoneArea) {
        const autoRoutePolygon = new mapsReference.Polygon({
          clickable: true,
          strokeColor: autoRouteData?.colorCode,
          strokeWeight: 0.5,
          fillColor: autoRouteData?.colorCode,
          fillOpacity: 0.5,
          map: mapReference,
          path: autoRouteData.wayPoints,
        });
        setZoneArea(autoRoutePolygon);
      }
    };

    const handleMapBounds = (map, maps, locations) => {
      if (map && canProgrammaticallyZoom) {
        const bounds = getMapBounds(map, maps, locations);
        isProgrammaticZoom.current = true;
        map.fitBounds(bounds);
      }
    };

    const convertAPIResponseForMaps = React.useCallback((apiResponse) => {
      let apiResponseInstance = { ...apiResponse };
      let routes = apiResponseInstance?.routes?.map((response) => {
        const bounds = new mapsReference.LatLngBounds(
          response.bounds.southwest,
          response.bounds.northeast
        );
        let overview_path = "";
        if (response.overview_polyline?.points)
          overview_path = mapsReference?.geometry?.encoding.decodePath(
            response.overview_polyline?.points
          );
        let legs = response.legs.map((leg) => {
          let start_location = new mapsReference.LatLng(
            leg.start_location.lat,
            leg.start_location.lng
          );
          let end_location = new mapsReference.LatLng(
            leg.end_location.lat,
            leg.end_location.lng
          );
          let steps = leg.steps.map((step) => {
            let path = mapsReference?.geometry?.encoding.decodePath(
              step.polyline.points
            );
            let start_location = new mapsReference.LatLng(
              step.start_location.lat,
              step.start_location.lng
            );
            let end_location = new mapsReference.LatLng(
              step.end_location.lat,
              step.end_location.lng
            );
            let instructions = step.html_instructions;
            return {
              ...step,
              path,
              start_location,
              end_location,
              instructions,
            };
          });
          return { ...leg, start_location, end_location, steps };
        });

        return { ...response, bounds, overview_path, legs };
      });

      return {
        ...apiResponseInstance,
        routes,
        ...{ request: { travelMode: "DRIVING" } },
      };
    });

    const drawFreeHand = () => {
      const poly = new mapsReference.Polyline({
        clickable: false,
        map: mapReference,
        strokeColor: "#114258",
        strokeWeight: 3,
      });
      // Added mousemove listener to track the user's mouse movement
      const mapMoveListener = mapsReference.event.addListener(
        mapReference,
        "mousemove",
        (e) => {
          poly.getPath().push(e.latLng);
        }
      );

      let circleMoveListener = [];
      if (
        (multiSelectedShopCodes?.length > 0 || selectedShopList?.length == 1) &&
        showShopSelection
      ) {
        Object.values(circleRef).map((each) => {
          const drawListener = mapsReference.event.addListener(
            each,
            "mousemove",
            (e) => {
              poly.getPath().push(e.latLng);
            }
          );
          circleMoveListener.push(drawListener);
        });
      }

      let autoRoutePolygonListener = [];
      let polygonListener = null;
      if (zonePolygonControllers?.length) {
        zonePolygonControllers?.map((zoneDetails) => {
          const polygonListenerforZone = mapsReference.event.addListener(
            zoneDetails,
            "mousemove",
            (e) => {
              poly.getPath().push(e.latLng);
            }
          );
          autoRoutePolygonListener.push(polygonListenerforZone);
        });
      } else if (zoneArea) {
        polygonListener = mapsReference.event.addListener(
          zoneArea,
          "mousemove",
          (e) => {
            poly.getPath().push(e.latLng);
          }
        );
      }
      // Added mouseup listener to check when the user releases the mouse button
      mapsReference.event.addDomListenerOnce(
        mapReference.getDiv(),
        "mouseup",
        (e) => {
          mapsReference.event.removeListener(mapMoveListener);
          if (zonePolygonControllers?.length) {
            autoRoutePolygonListener?.map((individualPolygonListener) => {
              mapsReference.event.removeListener(individualPolygonListener);
            });
          } else if (zoneArea) {
            mapsReference.event.removeListener(polygonListener);
          }
          if (circleMoveListener?.length) {
            circleMoveListener?.map((individualPolygonListener) => {
              mapsReference.event.removeListener(individualPolygonListener);
            });
          }
          const path = poly.getPath();
          poly.setMap(null);
          const polygon = new mapsReference.Polygon({
            clickable: false,
            fillColor: "#114258",
            fillOpacity: 0.25,
            geodesic: true,
            map: mapReference,
            path,
            strokeColor: "#114258",
            strokeWeight: 3,
          });
          polyComplete(polygon);
        }
      );
    };

    // Helper method to get the coordinates across the polygon line
    const polyComplete = (poly) => {
      setPolygonControllers(poly);
      let bounds = [];
      const paths = poly.getPaths();
      paths.forEach((path) => {
        const ar = path.getArray();
        for (let i = 0, l = ar.length; i < l; i++) {
          const lat = ar[i].lat();
          const lng = ar[i].lng();
          bounds.push({ lng: lng, lat: lat });
        }
        // Appending the first coords as last to make a complete polygon
        if (ar[0]) {
          bounds.push({ lng: ar[0].lng(), lat: ar[0].lat() });
        }
      });
      if (!isEmpty(bounds)) {
        setPolygonCoordinates(bounds);
        mapsReference.event.clearListeners(mapReference.getDiv(), "mousedown");
      }
    };

    const handleDrawEvent = () => {
      // Clear the old Listeners if exits and attach a new 'mousedown' listener on Map
      if (mapsReference) {
        mapsReference.event.clearListeners(mapReference.getDiv(), "mousedown");
        mapsReference.event.clearListeners(mapReference.getDiv(), "mousemove");
        mapsReference.event.clearListeners(mapReference.getDiv(), "mouseup");
        mapsReference.event.clearListeners(mapReference, "bounds_changed");

        //Listen for zoom changed
        mapsReference?.event.addListener(mapReference, "bounds_changed", () => {
          drawFreeHand;
        });

        mapsReference.event.addDomListener(
          mapReference.getDiv(),
          "mousedown",
          drawFreeHand
        );
      }
    };

    const clearMap = () => {
      setPolygonCoordinates([]);
      if (polygonControllers) {
        polygonControllers.setMap(null);
      }
    };

    const updateCursor = (isLassoToolEnabled) => {
      if (zonePolygonControllers?.length) {
        zonePolygonControllers?.map((individualZone) => {
          individualZone?.setOptions({
            cursor: isLassoToolEnabled ? "crosshair" : "pointer",
          });
        });
      } else if (zoneArea) {
        zoneArea?.setOptions({
          cursor: isLassoToolEnabled ? "crosshair" : "pointer",
        });
      }
      Object.values(circleRef).map((each) => {
        each.setOptions({
          cursor: isLassoToolEnabled ? "crosshair" : "pointer",
        });
      });
    };

    useEffect(() => {
      if (isLassoToolEnabled) {
        clearMap();
        handleDrawEvent();
        updateCursor(isLassoToolEnabled);
      } else {
        clearMap();
        updateCursor(isLassoToolEnabled);
      }
    }, [isLassoToolEnabled]);

    const MapContent = (
      <GoogleMapReact
        bootstrapURLKeys={{
          key: googleMapsAPIKey,
          version: "beta",
          region: "US",
          libraries: ["geometry", "drawing"],
        }}
        center={{
          lat: parseFloat(+shopLatitude),
          lng: parseFloat(+shopLongitude),
        }}
        defaultZoom={zoomLevel}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => {
          setMapReference(map);
          setMapsReference(maps);
          handleMapBounds(map, maps, fitAllCoords);
        }}
        options={{
          // mapId: googleMapsAPPID, // remove zip outline
          draggableCursor: isLassoToolEnabled ? "crosshair" : "pointer",
          draggingCursor: isLassoToolEnabled ? "crosshair" : "pointer",
        }}
        draggable={!isLassoToolEnabled}
      >
        {ordersInRoute?.length > 0 &&
          ordersInRoute.map((order, index) => {
            const {
              recipientLocation: { latitude = "", longitude = "" } = {},
              orderItemId = "",
              orderIndex = index,
            } = order;
            return (
              <RoutedMarker
                key={index}
                lat={latitude}
                lng={longitude}
                content={orderIndex + 1}
                enableAction={localPermissions.mapRemoveRoute}
                markerAction={markerAction}
                orderItemId={orderItemId}
              />
            );
          })}
        {nonRoutedOrders?.length > 0 &&
          nonRoutedOrders.map((order, index) => {
            const {
              recipientLocation: { latitude = "", longitude = "" } = {},
              addressVerificationInfo = {},
              orderItemId = "",
              orderIndex = index,
            } = order;

            const {
              isAvsPerformed = "",
              avsConfidence = "",
              isAvsSuggestedAddress = "",
            } = addressVerificationInfo;

            const isAvsVerified =
              isAvsPerformed === "Y" &&
              isAvsSuggestedAddress === "Y" &&
              avsConfidence === "HIGH";

            return (
              <NonRoutedMarker
                key={index}
                lat={latitude}
                lng={longitude}
                content={orderIndex + 1}
                isAvsVerified={isAvsVerified}
                enableAction={localPermissions.mapAddRoute}
                markerAction={markerAction}
                orderItemId={orderItemId}
              />
            );
          })}
        {shopLocations?.length > 0 &&
          !hasLimitedRouteAccess &&
          shopLocations?.map(({ latitude = "", longitude = "" } = {}, i) => {
            return (
              <ShopMarker
                key={i}
                lat={parseFloat(+latitude)}
                lng={parseFloat(+longitude)}
                marker="map-shop"
              />
            );
          })}
      </GoogleMapReact>
    );

    //eslint-disable-next-line
    const allLabel = Localise(messages, "All");

    return (
      <>
        <View
          style={{
            borderWidth: 1,
            borderColor: colors.grayScaleLight,
          }}
        >
          {accordionWrap && (
            <Accordion
              title={Localise(messages, "Map")}
              defaultOpen={true}
              handleOnPress={() => {}}
              headerContent={(openAccordion) => (
                <View
                  style={[
                    tw("flex flex-row items-center justify-between w-full"),
                  ]}
                >
                  <View style={theme.Accordion.titleContainer}>
                    <Image
                      style={{ width: 25, height: 25 }}
                      source={IMAGES["route-pin"]}
                    />
                    <Text
                      style={{
                        ...theme.Accordion.titleStyle,
                        color: colors.primary,
                        paddingLeft: 10,
                      }}
                    >
                      {Localise(messages, "Map")}
                    </Text>
                    {zipCoverageLoading && (
                      <ActivityIndicator
                        style={{ marginLeft: 10 }}
                        color={colors.activityIndicator}
                        testID={"loader"}
                      />
                    )}
                  </View>

                  <View style={[tw("flex flex-row items-center")]}>
                    {/* ZipCode layer - Hide */}
                    {/* <Tooltip
                      text={`${Localise(messages, "Zip Outline")}`}
                      isSmallScreen={isSmallScreen}
                      height={60}
                    >
                      <TouchableOpacity
                        testID="mapFolded"
                        accessibilityLabel="mapFolded"
                        style={{ marginRight: 10 }}
                        onPress={() => {
                          setShowZipLayer(!showZipLayer);
                          if (enableZipCoverage) {
                            dispatch(clearRouteCitiesZipcodes());
                            !showZipLayer &&
                              dispatch(
                                setZipInitialize({
                                  shopList: multiSelectedShopCodes,
                                })
                              );
                          }
                        }}
                      >
                        <Image
                          style={{ width: 30, height: 30 }}
                          source={IMAGES["mapFolded"]}
                        />
                      </TouchableOpacity>
                    </Tooltip> */}

                    {/* selection for 15 mile circle */}
                    <Tooltip
                      text={`${Localise(messages, "15 mile radius")}`}
                      isSmallScreen={isSmallScreen}
                      height={60}
                    >
                      <TouchableOpacity
                        testID="layerIcon"
                        accessibilityLabel="layerIcon"
                        style={{ marginRight: 10 }}
                        onPress={() => setshowShopSelection(!showShopSelection)}
                      >
                        <Image
                          style={{ width: 25, height: 25 }}
                          source={IMAGES["layerIcon"]}
                        />
                      </TouchableOpacity>
                    </Tooltip>

                    {localPermissions.mapDrawTool &&
                      !hasLimitedRouteAccess &&
                      !(
                        loading ||
                        isPastRoute ||
                        (isEditRoute &&
                          !["DRAFT", "PLANNED", "INPROGRESS"].includes(
                            routeData.status
                          ))
                      ) && (
                        <Tooltip
                          text={`${Localise(
                            messages,
                            "Lasso Tool"
                          )} \n${Localise(
                            messages,
                            "Use the lasso tool to select and add orders to the Route"
                          )}`}
                          isSmallScreen={isSmallScreen}
                          height={60}
                        >
                          <TouchableOpacity
                            testID="map-draw"
                            accessibilityLabel="map-draw"
                            style={{
                              marginRight: 10,
                              opacity: isLassoToolEnabled ? 0.5 : 1,
                            }}
                            onPress={() =>
                              dispatch(setEnableLassoTool(!isLassoToolEnabled))
                            }
                          >
                            <Image
                              style={{
                                width: 25,
                                height: 25,
                              }}
                              source={IMAGES["map-draw"]}
                            />
                          </TouchableOpacity>
                        </Tooltip>
                      )}
                    <Image
                      style={{ width: 20, height: 20 }}
                      source={
                        IMAGES[
                          openAccordion
                            ? "expanded-section"
                            : "collapsed-section"
                        ]
                      }
                    />
                  </View>
                </View>
              )}
              labelStyle={{
                marginTop: 0,
                height: 50,
                backgroundColor: backgroundColors.navBar,
                borderBottomWidth: 1,
                borderColor: colors.grayScaleLight,
                paddingHorizontal: 12,
              }}
              titleStyle={{
                color: colors.primary,
                paddingLeft: 10,
              }}
              iconStyle={{
                color: colors.primary,
              }}
              contentStyle={{ paddingVertical: 0 }}
              imageProps={{
                width: 25,
                height: 25,
                source: "route-pin",
              }}
              testID="map"
            >
              {/* Hiding Shop selection for MVP */}
              {/* { showShopSelection && selectedShopList?.length > 1 ? (
                <>
                  <AutoComplete
                    testID="membercode-radius"
                    placeholder={
                      bulkValue.length > 0
                        ? `${bulkValue.length} ${Localise(
                            messages,
                            "Selected"
                          )}`
                        : Localise(messages, "Enter Member Code")
                    }
                    onChangeText={(val) => setDropDownQuery(val)}
                    data={selectedShopList.filter((e) =>
                      toUpper(e.label).includes(toUpper(dropDownQuery))
                    )}
                    initialDataLength={selectedShopList?.length}
                    listDisplayValues={["label"]}
                    onSelect={(selectedValue) => {
                      let newValues = [...bulkValue];
                      if (selectedValue.label === allLabel) {
                        if (selectedValue.value.length === bulkValue?.length) {
                          newValues = [];
                        } else {
                          newValues = [...selectedValue.value];
                        }
                      } else {
                        const index = newValues.indexOf(selectedValue.value);
                        const unSelected = index >= 0;
                        unSelected
                          ? newValues.splice(index, 1)
                          : newValues.push(selectedValue.value);
                      }
                      setBulkValue(newValues);
                    }}
                    value={dropDownQuery}
                    selectedValues={bulkValue}
                    isMultiSelect={true}
                    showOnFocus={true}
                    setFocusBack={true}
                    onBlur={() => {
                      loadCircles();
                    }}
                    onOptionChange={() => {}}
                    outerContainerStyle={{
                      marginTop: 5,
                      paddingLeft: 5,
                      zIndex: 1,
                    }}
                  />
                </>
              ) : null} */}
              <View
                style={{
                  ...styles.mapsContainer,
                  ...{ height: mapsContainerHeight },
                }}
              >
                {MapContent}
              </View>
            </Accordion>
          )}
          {!accordionWrap && (
            <View
              style={{
                ...styles.mapsContainer,
                ...{ height: mapsContainerHeight },
              }}
            >
              {MapContent}
            </View>
          )}
        </View>

        {isEditRoute && localPermissions?.isOptimizeEnabled ? (
          <OptimizeMessage
            {...{
              localPermissions,
              isPastRoute,
              isOptimized,
              routeHasEdits,
            }}
            style={{ padding: 10 }}
          />
        ) : null}

        {showDirections &&
          routes?.length > 0 &&
          localPermissions.tbtDirections && (
            <View style={styles.directionsContainer}>
              <Accordion
                title={Localise(messages, "Directions")}
                defaultOpen={true}
                handleOnPress={() => {}}
                labelStyle={{
                  marginTop: 0,
                  height: 50,
                  backgroundColor: backgroundColors.navBar,
                  borderBottomWidth: 1,
                  borderColor: colors.grayScaleLight,
                  paddingHorizontal: 12,
                }}
                titleStyle={{
                  color: colors.primary,
                  paddingLeft: 10,
                }}
                iconStyle={{
                  color: colors.primary,
                }}
                imageProps={{
                  width: 25,
                  height: 25,
                  source: "route-pin",
                }}
                testID="directions"
              >
                <View style={{ padding: 10 }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: routes[0]?.route?.directions,
                    }}
                    style={webStyles.directionsContent}
                  />
                </View>
              </Accordion>
            </View>
          )}
      </>
    );
  }
);

const webStyles = {
  directionsContent: {
    fontFamily: fonts.fontFamily.default,
    lineHeight: "25px",
    fontSize: "15px",
  },
};

const styles = StyleSheet.create({
  mapsContainer: { height: 800, width: "100%" },
  routedMarker: {
    height: 25,
    width: 25,
    backgroundColor: "#114258",
    borderRadius: 15,
  },
  routedContent: {
    color: "white",
    textAlign: "center",
    lineHeight: 25,
    fontFamily: fonts.fontFamily.default,
  },
  nonroutedMarker: {
    height: 25,
    width: 25,
    backgroundColor: "#B50D81",
    borderRadius: 15,
  },
  nonVerfiedNonroutedMarker: {
    height: 25,
    width: 25,
    backgroundColor: "#E5175E",
    borderRadius: 15,
  },
  nonroutedContent: {
    color: "white",
    textAlign: "center",
    lineHeight: 25,
    fontFamily: fonts.fontFamily.default,
  },
  shopMarker: {
    height: 35,
    width: 35,
    left: -5,
    top: -5,
  },
  shopContent: { width: 35, height: 35 },
  directionsContainer: {
    marginTop: 10,
    borderWidth: 1,
    borderColor: colors.grayScaleLight,
  },
});

export default DirectionsMap;
