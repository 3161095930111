import React from "react";
import { View, Image, Platform, Linking } from "react-native";
import { Text } from "react-native-elements";
import { IMAGES } from "static/assets/images";
import { fonts } from "styles/theme";
import I18NContext from "library/contexts/i18N";

export const Template = ({
  childContent = [],
  isDesktop,
  style,
  listType = "bulletList",
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const onPress = (uri) => {
    Platform.OS === "web" ? window.open(uri) : Linking.openURL(uri);
  };
  const defaultStyle = { paddingLeft: isDesktop ? 30 : 10 };

  return (
    <View style={style || defaultStyle}>
      {childContent.length > 0 &&
        childContent.map((each, index) => (
          <View key={index}>
            <View
              style={{
                flexDirection: "row",
                alignItems:
                  listType === "numberedList" ? "flex-start" : "center",
              }}
            >
              {each.type !== "image" && each.level > 0 && (
                <Text
                  style={{ fontSize: listType === "numberedList" ? 15 : 20 }}
                >
                  {listType === "numberedList" ? `${index + 1}. ` : "\u2022 "}
                </Text>
              )}
              {each.type === "text" && (
                <Text
                  style={[
                    { flex: 1, paddingVertical: 5 },
                    each.level === 0 && !each.isDefaultFont
                      ? { ...fonts.heading3, paddingTop: 15 }
                      : {},
                    listType === "numberedList" ? { paddingTop: 0 } : {},
                  ]}
                >
                  {Localise(messages, each.content)}
                </Text>
              )}
              {each.type === "textWithLinks" && (
                <Text
                  style={{
                    flex: 1,
                    padding: 5,
                    ...(listType === "numberedList"
                      ? { paddingTop: 0, fontSize: 14 }
                      : {}),
                  }}
                >
                  {Localise(messages, each.contentBeforeLink)}&nbsp;
                  <Text
                    style={{
                      ...fonts.link1,
                      paddingHorizontal: 5,
                    }}
                    onPress={() => onPress(each.link.target)}
                  >
                    {Localise(messages, each.link.text)}
                  </Text>
                  &nbsp;
                  {Localise(messages, each.contentAfterLink)}
                </Text>
              )}
              {each.type === "image" && (
                <Image
                  source={IMAGES[each.content]}
                  style={{
                    ...(Platform.OS === "web" && {
                      width: each.width,
                      height: each.height,
                      ...(each.webCustomStyle || {}),
                    }),
                    ...(Platform.OS !== "web" && {
                      width: "100%",
                      resizeMode: "contain",
                      aspectRatio: 1.8,
                      alignItems: "flex-start",
                      ...(each.mobileCustomStyle || {}),
                    }),
                    padding: 5,
                  }}
                />
              )}
            </View>
            {each.childContent && (
              <Template childContent={each.childContent} listType={listType} />
            )}
          </View>
        ))}
    </View>
  );
};
