import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { Input } from "react-native-elements";
import { Form, FormField, SubmitButton } from "components/elements/forms";
import I18NContext from "library/contexts/i18N";
import tw from "tailwind-rn";
import { createTransaction } from "library/sagas/session/slice";
import { ToasterHandler } from "components/elements";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { theme, colors } from "styles/theme";
import * as Yup from "yup";

const UserBalanceCheck = (props) => {
  const {
    sessionInfo: { currentBalance, memberId, macAddress },
    operator,
    createTransaction,
    setShowUserBalanceCheck,
  } = props;
  const { messages, Localise } = React.useContext(I18NContext);

  const ValidationSchema = Yup.object().shape({
    balanceCheck: Yup.string()
      .required(Localise(messages, "Enter Amount"))
      .matches(/^\d+\.?\d*$/, Localise(messages, "Invalid Price")),
  });

  return (
    <React.Fragment>
      <Form
        initialValues={{
          currentBalance: parseFloat(currentBalance).toFixed(2),
          overOrUnder: currentBalance,
          balanceCheck: 0,
        }}
        validationSchema={ValidationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={(values, formikBag) => {
          const { currentBalance, balanceCheck } = values;
          const diffAmount = parseFloat(balanceCheck - currentBalance);
          createTransaction({
            params: {
              amount: diffAmount >= 0 ? diffAmount : -1 * diffAmount,
              type: diffAmount >= 0 ? "Paid-In" : "Paid-Out",
              macAddress,
              memberId,
              user: operator,
              userNote: "$$BALANCE_CHECK$$",
              orderId: "",
            },
            resolve: () => {
              setShowUserBalanceCheck(false);
              formikBag.setSubmitting(false);
              formikBag.resetForm();
              ToasterHandler(
                "Success",
                Localise(messages, `Balance checked successfully`)
              );
            },
            reject: () => {
              ToasterHandler(
                "uh oh",
                Localise(
                  messages,
                  `Failed to update the Balance. Please try again`
                )
              );
              formikBag.setSubmitting(false);
            },
          });
        }}
        render={({ values }) => {
          const { currentBalance, balanceCheck } = values;
          const overOrUnder =
            (isNaN(balanceCheck) ? 0 : balanceCheck) - currentBalance;
          const diffValue = parseFloat(
            overOrUnder < 0 ? -1 * overOrUnder : overOrUnder
          ).toFixed(2);

          return (
            <React.Fragment>
              <View>
                <Text style={tw("mb-4 ml-1 font-bold")}>
                  {`${Localise(messages, "Balance Check")} (${Localise(
                    messages,
                    "Cash & Checks only"
                  )})`}
                </Text>
              </View>
              <View style={tw("flex-row items-center justify-center mt-2")}>
                <FormField
                  name="currentBalance"
                  label={"Current Balance"}
                  testID="current_balance"
                  accessibilityLabel="current_balance"
                  containerStyle={{
                    width: 120,
                  }}
                  isPrice={true}
                  iconType="material-community"
                  iconName="currency-usd"
                  iconSize={13}
                  inputContainerStyle={{
                    padding: 6,
                  }}
                  leftIconContainerStyle={{ paddingRight: 0 }}
                  keyboardType="numeric"
                  placeholder={Localise(messages, "0")}
                  editable={false}
                  grayedOutOnDisable={true}
                />
                <FormField
                  name="balanceCheck"
                  label={"Balance Check"}
                  testID="balance_check"
                  accessibilityLabel="balance_check"
                  containerStyle={{
                    width: 120,
                  }}
                  isPrice={true}
                  iconType="material-community"
                  iconName="currency-usd"
                  iconSize={13}
                  inputContainerStyle={{
                    padding: 6,
                  }}
                  leftIconContainerStyle={{ paddingRight: 0 }}
                  keyboardType="numeric"
                  placeholder={Localise(messages, "0")}
                />
                <Input
                  containerStyle={{
                    width: 100,
                  }}
                  label="Over/Under"
                  leftIcon={{
                    type: "material-community",
                    name: "currency-usd",
                    size: 13,
                  }}
                  inputContainerStyle={{
                    padding: 6,
                  }}
                  leftIconContainerStyle={{ paddingRight: 0 }}
                  style={
                    overOrUnder < 0
                      ? {
                          color: colors.error,
                        }
                      : overOrUnder > 0
                      ? { color: colors.active }
                      : {}
                  }
                  value={overOrUnder < 0 ? `(${diffValue})` : diffValue}
                  disabled={true}
                />
              </View>
              <View style={tw("flex-row items-center mt-2")}>
                <TouchableOpacity
                  onPress={() => {
                    setShowUserBalanceCheck(false);
                    createTransaction({
                      params: {
                        amount: "0",
                        type: "Other",
                        memberId,
                        macAddress,
                        user: operator,
                        userNote: `Balance Check Canceled`,
                        orderId: "",
                      },
                      resolve: (resp) => {
                        console.log(`Cancel Balance check is logged`);
                      },
                      reject: () => {
                        console.log(
                          `Failed to create transaction. Please try again.`
                        );
                      },
                    });
                  }}
                  style={{
                    ...theme.Button.secondaryButtonStyle,
                    paddingVertical: 9,
                    paddingHorizontal: 9,
                    marginVertical: 0,
                    marginHorizontal: 5,
                    width: 100,
                  }}
                  testID={"cancel"}
                  accessibilityLabel={"cancel"}
                >
                  <Text style={theme.Button.secondaryTitleStyle}>
                    {Localise(messages, "Cancel")}
                  </Text>
                </TouchableOpacity>
                <SubmitButton
                  title={Localise(messages, "Submit")}
                  containerStyle={{
                    width: 120,
                    margin: 0,
                    marginLeft: 10,
                    height: 35,
                  }}
                  testID="Submit"
                  accessibilityLabel="Submit"
                />
              </View>
            </React.Fragment>
          );
        }}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createTransaction,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(UserBalanceCheck);
