import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";
import { selectState as selectScreenState } from "../common/selector";
import get from "lodash/get";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectPageStatus = createSelector(
  selectState,
  selectScreenState,
  ({ status }, { status: screenStatus }) =>
    screenStatus === "done" ? status : "progress"
);

export const selectIsPageLoading = createSelector(
  selectPageStatus,
  (status) => status === "progress"
);

export const selectIsPageFailed = createSelector(
  selectPageStatus,
  (status) => status === "fail"
);

export const selectIsPageLoaded = createSelector(
  selectPageStatus,
  (status) => status === "done"
);

export const selectAPIResponse = createSelector(selectState, ({ api }) => api);

export const selectAPIError = createSelector(selectState, ({ error }) => error);

export const selectProducts = createSelector(
  selectState,
  ({ products }) => products
);

export const selectProductsSection = (section) =>
  createSelector(selectProducts, (products) => products[section]);

export const selectProductsData = (section) =>
  createSelector(selectProductsSection(section), ({ data }) => data);

export const selectProductsAction = (section) =>
  createSelector(
    selectProductsSection(section),
    ({ actions }) => actions || {}
  );

export const selectProductsControls = (section) =>
  createSelector(selectProductsSection(section), ({ controls }) => controls);

export const selectSearchText = (section) =>
  createSelector(selectProductsAction(section), ({ searchText }) => searchText);

export const selectAppliedFilters = (section) =>
  createSelector(
    selectProductsAction(section),
    ({ filters = [] }) => filters || []
  );

export const selectBulkActionType = (section) =>
  createSelector(
    selectProductsAction(section),
    ({ bulkActionType }) => bulkActionType
  );

export const selectProductsSelected = (section) =>
  createSelector(
    selectProductsAction(section),
    ({ selectedProducts }) => selectedProducts || []
  );

export const selectProductsSortOrder = createSelector(
  selectProductsAction("current"),
  ({ sortOrder, manualSortOrder }) => ({
    sortOrder: sortOrder || "",
    manualSortOrder: manualSortOrder || "",
  })
);

export const selectShopifySortOption = createSelector(
  selectProductsAction("current"),
  selectProductsControls("current"),
  ({ sortOrder = "" }, { sortOptions = [] }) =>
    get(
      sortOptions.find((o) => o.value === sortOrder),
      "actual",
      "Best Selling"
    )
);

export const selectMHQSortOption = (sortOrder) =>
  createSelector(selectProductsControls("current"), ({ sortOptions = [] }) =>
    get(
      sortOptions.find((o) => o.actual === sortOrder),
      "value",
      ""
    )
  );

export const selectProductsViewMore = (section) =>
  createSelector(
    selectProductsAction(section),
    ({ productLimit }) => productLimit
  );

export const selectSideCar = createSelector(
  selectState,
  ({ sideCar }) => sideCar
);
