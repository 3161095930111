import React from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./toast-messages.css";
import { colors, fonts, fontWeights } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import toLower from "lodash/toLower";
import startCase from "lodash/startCase";
import { headerTypes } from "./config";

const ToasterMessage = ({ type, message, linkProps }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const isError = headerTypes("error").includes(toLower(type));

  const {
    handleOnClickEvent = () => {},
    showLink = false,
    linkText = "",
    customLinkStyle = {},
  } = linkProps;

  return (
    <>
      <div style={styles.containerStyle}>
        <img
          width="60"
          height="60"
          src={
            isError
              ? require("static/assets/alert.png")
              : require("static/assets/completed.png")
          }
        />
      </div>
      <div style={styles.messageContainer}>
        <p style={styles.headerMsg}>{Localise(messages, startCase(type))}!</p>
        <p style={styles.innerMsg}>
          {message}
          {showLink && (
            <>
              <br />
              <a
                href="#"
                onClick={handleOnClickEvent}
                style={{ ...styles.link, ...customLinkStyle }}
              >
                {Localise(messages, linkText)}
              </a>
            </>
          )}
        </p>
      </div>
    </>
  );
};

export const ToasterContainer = () => {
  return (
    <ToastContainer
      transition={Slide}
      position="bottom-center"
      hideProgressBar={true}
      limit={1}
    />
  );
};

export const ToasterHandler = (
  type,
  message,
  linkProps = {},
  dismissPrevious = true,
  autoClose = 8000
) => {
  dismissPrevious && toast.dismiss();
  return toast(
    <ToasterMessage type={type} message={message} linkProps={linkProps} />,
    {
      autoClose,
      position: toast.POSITION.BOTTOM_CENTER,
    }
  );
};

const styles = {
  containerStyle: {
    fontSize: "50px",
    display: "inline-block",
    width: "70px",
    height: "100px",
    lineHeight: "100px",
    float: "left",
  },
  messageContainer: {
    display: "inline-block",
    width: "205px",
  },
  headerMsg: {
    fontFamily: fonts.fontFamily.bold,
    color: colors.primary,
  },
  innerMsg: {
    fontFamily: fonts.fontFamily.default,
    color: colors.primary,
  },
  link: {
    fontSize: 14,
    fontWeight: fontWeights.semiBold,
    fontFamily: fonts.fontFamily.regular,
    textDecorationLine: "underline",
    color: colors.primary2,
  },
};
