import React from "react";
import { Icon } from "react-native-elements";
import { useNavigation, DrawerActions } from "@react-navigation/native";
import { DeviceContext } from "library/contexts/appSettings";
import { colors } from "styles/theme";

const MenuComponent = ({ iconCustomStyle = {} }) => {
  const navigation = useNavigation();
  const { isMobile } = React.useContext(DeviceContext);

  if (!isMobile) return null;

  return (
    <Icon
      name="menu"
      onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}
      iconStyle={{ color: colors.primary, ...iconCustomStyle }}
      accessibilityLabel="nav_menu"
    />
  );
};

export default MenuComponent;
