import { put, call, select } from "redux-saga/effects";
import isEmpty from "lodash/isEmpty";

import { setApiResponse, setApiError, setPageData } from "../slice";
import { selectApiResponse, selectPageActions } from "../selector";

import { request } from "library/utils/request";

import cloneDeep from "lodash/cloneDeep";
import orderBy from "lodash/orderBy";
import toLower from "lodash/toLower";

export function* handleFetchAllGiftCards() {
  const serviceRequest = (offset, limit, searchText) =>
    // changing for local api dev, will stash changes once gift-listing call issues are resolved
    request("get-gift-cards", {
      offset,
      limit,
      searchText,
      includeTotalCount: true,
    });
  const section = "giftCards";
  try {
    const { search = "" } = yield select(selectPageActions);
    const {
      giftCards,
      pageable: { totalRecords },
    } = yield call(serviceRequest, 0, 50, search);

    yield put(
      setApiResponse({
        section,
        giftCards,
      })
    );
    yield put(setPageData({ totalRecords }));
  } catch (error) {
    yield put(
      setApiError({
        section,
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleUIRefresh() {
  const origData = yield select(selectApiResponse);
  const actions = yield select(selectPageActions);
  const newData = applyPageActions(origData, actions);
  yield put(setPageData(newData));
}

const applyPageActions = (data, actions) => {
  const {
    shops = [],
    sort: { value: sortBy = "name::asc" },
    search = "",
  } = actions;

  const [sortField, sortOrder] = sortBy.split(/::/);

  const applyControls = (data = []) =>
    data.filter((entry) => {
      let matchesFilter = true;

      // Filtering the orders based on the search query
      if (!isEmpty(search) && matchesFilter) {
        matchesFilter =
          toLower(search).includes(toLower(entry.lastChars)) ||
          toLower(entry.recipientEmail).includes(toLower(search)) ||
          toLower(entry.recipientName).includes(toLower(search))
            ? true
            : false;
      }

      if (shops.length) {
        if (shops.filter((item) => item.value === entry.storeOrigin).length)
          matchesFilter = true;
        else matchesFilter = false;
      }

      return matchesFilter;
    });

  let result = cloneDeep(data);

  result = Object.keys(result).reduce((accum, section) => {
    accum[section] = orderBy(
      applyControls(result[section]),
      (e) => toLower(e[sortField]),
      sortOrder
    );
    return accum;
  }, {});

  return result;
};
