import * as Yup from "yup";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import omit from "lodash/omit";
import cloneDeep from "lodash/cloneDeep";

import {
  formatLineItems,
  getBillingInfo,
} from "components/views/drawer/create-order/helper";

import {
  getPaymentValidationSchema,
  getCardType,
  ccdRegex,
  ccdErrors,
} from "library/utils/payment-options";
import { Entitlements } from "library/utils/entitlements";
import { formatPrice } from "library/utils/formatter";

const isStorePickup = (value) => value === "STORE_PICKUP";
const isWalkIn = (value) => value === "WALK_IN";

// const isDigitalInvoice = (value) => value === "DigitalInvoice";
// const isMainInvoice = (value) => value === "MailInvoice";
// const isPaypal = (value) => value === "Paypal";

export const validationSchema = (messages, Localise) => {
  Yup.addMethod(Yup.string, "isValidCard", function (errorMessage) {
    return this.test(`is-valid-card`, errorMessage, function (value = "") {
      const trimmedValue = value.split(" ").join("");
      if (trimmedValue.substring(0, 3) === "***") return true;
      if (isNaN(trimmedValue)) return false;
      var cardType = getCardType(trimmedValue);
      if (!cardType) return false;
      const { path, createError } = this;
      var regEx = new RegExp(ccdRegex[cardType]);

      return (
        regEx.test(trimmedValue) ||
        createError({
          path,
          message:
            Localise(messages, ccdErrors[cardType]) ||
            Localise(messages, "Invalid Card Number"),
        })
      );
    });
  });

  Yup.addMethod(Yup.string, "isValidAmount", function (errorMessage) {
    return this.test(`is-valid-amount`, errorMessage, function (value) {
      const { tenderedAmount, orderTotal } = this.parent;
      return !(
        isNaN(tenderedAmount) || parseFloat(tenderedAmount) < orderTotal
      );
    });
  });

  return Yup.object({
    buyerName: Yup.string()
      .label("buyerName")
      .min(
        2,
        Localise(messages, "Customer Name must have atleast 2 characters")
      ),
    sendingMemberField: Yup.string()
      .label("selectedShop")
      .when("hasMultipleShops", {
        is: (value) => value === true,
        then: Yup.string().required(Localise(messages, "Select a Shop")),
      }),
    customerInfo: Yup.object().when("savePayment", {
      is: (savePayment) => !!savePayment,
      then: Yup.object().shape(
        {
          firstName: Yup.string().when("customerType", {
            is: (value) => value === "Individual",
            then: Yup.string().required(
              Localise(messages, "Please enter First Name")
            ),
          }),
          lastName: Yup.string().when("customerType", {
            is: (value) => value === "Individual",
            then: Yup.string().required(
              Localise(messages, "Please enter Last Name")
            ),
          }),
          businessName: Yup.string().when("customerType", {
            is: (value) => value === "Business",
            then: Yup.string().required(
              Localise(messages, "Please enter Business Name")
            ),
          }),
          email: Yup.string()
            .email(Localise(messages, "Enter valid Email"))
            .when("phone", {
              is: (phone) => !phone || phone.length === 0,
              then: Yup.string().required(
                Localise(messages, "Please enter Customer Email")
              ),
            }),
          phone: Yup.string()
            .matches(
              new RegExp(
                "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
              ),
              Localise(messages, "Enter valid Phone")
            )
            .when("email", {
              is: (email) => !email || email.length === 0,
              then: Yup.string().required(
                Localise(messages, "Please enter Customer Phone")
              ),
            }),
          customerNotes: Yup.string().isValidNotes(
            Localise(messages, "Credit Limit has been exceeded")
          ),
          customerType: Yup.string()
            .label("customerType")
            .required(Localise(messages, "Please select Customer Type")),
        },
        [["email", "phone"]]
      ),
      otherwise: Yup.object().when("hasCustomerInfo", {
        is: (hasCustomerInfo) => !!hasCustomerInfo,
        then: Yup.object().shape(
          {
            firstName: Yup.string().when("customerType", {
              is: (value) => value === "Individual",
              then: Yup.string().required(
                Localise(messages, "Please enter First Name")
              ),
            }),
            lastName: Yup.string().when("customerType", {
              is: (value) => value === "Individual",
              then: Yup.string().required(
                Localise(messages, "Please enter Last Name")
              ),
            }),
            businessName: Yup.string().when("customerType", {
              is: (value) => value === "Business",
              then: Yup.string().required(
                Localise(messages, "Please enter Business Name")
              ),
            }),
            email: Yup.string()
              .email(Localise(messages, "Enter valid Email"))
              .when("phone", {
                is: (phone) => !phone || phone.length === 0,
                then: Yup.string().required(
                  Localise(messages, "Please enter Customer Email")
                ),
              }),
            phone: Yup.string()
              .matches(
                new RegExp(
                  "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
                ),
                Localise(messages, "Enter valid Phone")
              )
              .when("email", {
                is: (email) => !email || email.length === 0,
                then: Yup.string().required(
                  Localise(messages, "Please enter Customer Phone")
                ),
              }),
            customerNotes: Yup.string().isValidNotes(
              Localise(messages, "Credit Limit has been exceeded")
            ),
            customerType: Yup.string()
              .label("customerType")
              .required(Localise(messages, "Please select Customer Type")),
          },
          [["email", "phone"]]
        ),
      }),
    }),
    subscriptionInfo: Yup.object().shape({
      firstDeliveryDate: Yup.date().required(
        Localise(messages, "Enter First Delivery Date")
      ),
      // .min(
      //   moment().add(-1, "days"),
      //   Localise(messages, "Enter valid First Delivery Date")
      // ),
      frequency: Yup.string()
        .label("Frequency")
        .required(Localise(messages, "Please select subscription frequency")),
      // deliveryInstructions: Yup.string()
      //   .label("deliveryInstructions")
      //   .max(
      //     100,
      //     Localise(
      //       messages,
      //       "Delivery instructions cannot exceed 100 characters"
      //     )
      //   ),
    }),
    paymentDetails: getPaymentValidationSchema(),
    orderItems: Yup.array().of(
      Yup.object().shape({
        productInfo: Yup.string()
          .label("productInfo")
          .when("lineItems", {
            is: (value) => value && value.length === 0,
            then: Yup.string()
              .test(
                "is-valid-product",
                Localise(messages, "Please select a valid product"),
                function () {
                  const { isProductSearched } = get(
                    this,
                    "from.1.value",
                    false
                  );
                  const { lineItems } = this.parent;
                  if (isProductSearched && lineItems?.length === 0) {
                    return false;
                  } else {
                    return true;
                  }
                }
              )
              .required(
                Localise(
                  messages,
                  "Select product from the dropdown list or from Product Catalog"
                )
              ),
          }),
        price: Yup.array().of(
          Yup.object().shape({
            value: Yup.string()
              .label("price")
              .required(Localise(messages, "Invalid Price"))
              .matches(/^\d+\.?\d*$/, Localise(messages, "Invalid Price")),
            discount: Yup.string()
              .label("discount")
              .when("discountType", {
                is: (value) => !!value,
                then: Yup.string().matches(
                  /^\d+\.?\d*$/,
                  Localise(messages, "Invalid Discount")
                ),
              }),
          })
        ),
        recipientInfo: Yup.object().shape({
          firstName: Yup.string()
            .label("Recipient First Name")
            .min(
              2,
              Localise(
                messages,
                "Recipient First Name must have at least 2 characters"
              )
            )
            .when("deliveryMethod", {
              is: (value) => (value && isWalkIn(value)) || isStorePickup(value),
              then: Yup.string().when("hasRecipientInfo", {
                is: (value) => value,
                then: Yup.string().required(
                  Localise(messages, "Enter Recipient First Name")
                ),
              }),
              otherwise: Yup.string().required(
                Localise(messages, "Enter Recipient First Name")
              ),
            }),
          lastName: Yup.string()
            .label("Recipient Last Name")
            .min(
              2,
              Localise(
                messages,
                "Recipient Last Name must have at least 2 characters"
              )
            )
            .when("deliveryMethod", {
              is: (value) => (value && isWalkIn(value)) || isStorePickup(value),
              then: Yup.string().when("hasRecipientInfo", {
                is: (value) => value,
                then: Yup.string().required(
                  Localise(messages, "Enter Recipient Last Name")
                ),
              }),
              otherwise: Yup.string().required(
                Localise(messages, "Enter Recipient Last Name")
              ),
            }),
          addressLine1: Yup.string()
            .label("addressLine1")
            .min(2, Localise(messages, "Invalid Street Address"))
            .max(1000, Localise(messages, "Invalid Street Address"))
            .when("deliveryMethod", {
              is: (value) => (value && isWalkIn(value)) || isStorePickup(value),
              then: Yup.string().when("hasRecipientInfo", {
                is: (value) => value,
                then: Yup.string().required(
                  Localise(messages, "Enter Street Address")
                ),
              }),
              otherwise: Yup.string().required(
                Localise(messages, "Enter Street Address")
              ),
            }),
          suite: Yup.string()
            .label("suite")
            .min(1, Localise(messages, "Invalid suite")),
          city: Yup.string()
            .label("city")
            .min(2, Localise(messages, "Invalid City Name"))
            .max(256, Localise(messages, "Invalid City Name"))
            .when("deliveryMethod", {
              is: (value) => (value && isWalkIn(value)) || isStorePickup(value),
              then: Yup.string().when("hasRecipientInfo", {
                is: (value) => value,
                then: Yup.string().required(
                  Localise(messages, "Enter City name")
                ),
              }),
              otherwise: Yup.string().required(
                Localise(messages, "Enter City name")
              ),
            }),
          country: Yup.string().required(
            Localise(messages, "Country Required")
          ),
          state: Yup.string()
            .label("state")
            .when("country", {
              is: (value) => (value && value === "US") || value === "CA",
              then: Yup.string().matches(
                new RegExp("^[a-zA-Z]{2}$"),
                Localise(messages, "Invalid State")
              ),
            })
            .when("deliveryMethod", {
              is: (value) => value && (isStorePickup(value) || isWalkIn(value)),
              then: Yup.string().when("hasRecipientInfo", {
                is: (value) => value,
                then: Yup.string().required(
                  Localise(messages, "State required")
                ),
              }),
              otherwise: Yup.string().required(
                Localise(messages, "State required")
              ),
            }),
          zip: Yup.string()
            .label("zip")
            .when("country", {
              is: (value) => value && value === "US",
              then: Yup.string().matches(
                new RegExp("^[0-9]{5}$"),
                Localise(messages, "Invalid Postal Code")
              ),
              otherwise: Yup.string().when("country", {
                is: (value) => value && value === "CA",
                then: Yup.string().matches(
                  new RegExp("^[A-Za-z]\\d[A-Za-z][ -]?(\\d[A-Za-z]\\d)?\\s*$"),
                  Localise(messages, "Invalid Postal Code")
                ),
              }),
            })
            .when("deliveryMethod", {
              is: (value) => value && (isStorePickup(value) || isWalkIn(value)),
              then: Yup.string().when("hasRecipientInfo", {
                is: (value) => value,
                then: Yup.string().required(
                  Localise(messages, "Postal Code required")
                ),
              }),
              otherwise: Yup.string().required(
                Localise(messages, "Postal Code required")
              ),
            }),
          locationName: Yup.string()
            .label("locationName")
            .min(
              3,
              Localise(
                messages,
                "Location Name must have at least 3 characters"
              )
            )
            .when("deliveryMethod", {
              is: (value) => value && !isStorePickup(value) && !isWalkIn(value),
              then: Yup.string().when(["deliveryMethod", "locationType"], {
                is: (deliveryMethod, locationType) =>
                  deliveryMethod &&
                  locationType !== undefined &&
                  locationType !== "Residence" &&
                  locationType !== "Other",
                then: Yup.string().required(
                  Localise(messages, "Enter Location Name")
                ),
              }),
            }),
          phone: Yup.string()
            .label("phone")
            .when(["deliveryMethod", "country"], {
              is: (deliveryMethod, country) => deliveryMethod && country,
              then: Yup.string()
                //.required("Please enter a Phone Number")
                .when("country", {
                  is: (country) => country === "US" || country === "CA",
                  then: Yup.string().matches(
                    new RegExp(
                      "^((\\+)?\\d{1,2}(\\s)?)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
                    ),
                    Localise(messages, "Please enter a valid Phone Number")
                  ),
                  otherwise: Yup.string().matches(
                    new RegExp(
                      "^((\\+)?\\d{1,2}(\\s)?)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?(\\d{3}[\\s.-])?\\d{4,9}$"
                    ),
                    Localise(messages, "Please enter a valid Phone Number")
                  ),
                }),
            }),
        }),
        deliveryInfo: Yup.object().shape({
          // deliveryInstructions: Yup.string()
          //   .label("deliveryInstructions")
          //   .max(
          //     100,
          //     Localise(
          //       messages,
          //       "Delivery instructions cannot exceed 100 characters"
          //     )
          //   ),
          occasion: Yup.string()
            .label("occasion")
            .when("deliveryMethod", {
              is: (value) => value && value !== "",
              then: Yup.string().required(
                Localise(messages, "Select an Occasion")
              ),
            }),
        }),
        deliveryFee: Yup.string()
          .label("deliveryFee")
          .matches(
            /^\d{1,4}\.?\d*$/,
            Localise(messages, "Invalid Delivery Fee")
          ),
      })
    ),
  });
};

Yup.addMethod(Yup.string, "isValidNotes", function (errorMessage) {
  return this.test(`is-valid-notes`, errorMessage, function (value) {
    const isPaymentTypeInvoice =
      get(
        this,
        "from.1.value.paymentDetails.paymentMethod.0.paymentMethodType",
        ""
      ) === "INVOICE";
    if (!isPaymentTypeInvoice) return true;
    const {
      houseAccountInfo: {
        creditLimit = 500,
        orderTotal = 0,
        houseAccountBalance: { totalBalance = 0 } = {},
      } = {},
    } = this.parent;
    return orderTotal <= creditLimit - totalBalance;
  });
});

Yup.addMethod(Yup.string, "isValidExpDate", function (errorMessage) {
  return this.test(`is-valid-expDate`, errorMessage, function (value) {
    if (!value) return false;
    const d = new Date();
    const currentYear = d.getFullYear();
    const currentMonth = d.getMonth() + 1;
    const [expMonth, expYear] = value.split("/") || [];
    const year = parseInt(expYear) + 2000;
    const month = parseInt(expMonth);
    return (
      year > currentYear || (year === currentYear && month >= currentMonth)
    );
  });
});

export const basicOrderInfo = {
  lineItems: [],
  price: [],
  recipientInfo: {
    firstName: "",
    lastName: "",
    addressLine1: "",
    suite: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
    locationType: "",
    locationName: "",
    phone: "",
    deliveryMethod: "FLORIST_DELIVERED",
    hasRecipientInfo: false,
  },
  addressVerificationInfo: {
    isAvsPerformed: "N",
    avsConfidence: "",
    isAvsSuggestedAddress: "N",
  },
  deliveryInfo: {
    cardMessage: "",
    deliveryDate: "",
    pickUpDateTime: "",
    deliveryMethod: "FLORIST_DELIVERED",
    pickUpBy: "",
    deliveryInstructions: "",
    occasion: "",
    floristInfo: "",
    shopDayTimings: {},
  },
  isRushOrder: "",
  noAutoForward: false,
  deliveryFee: 0.0,
  retailDeliveryFee: 0.0,
};

export const basicCustomerInfo = {
  name: "",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  customerType: "",
  customerNotes: "",
  businessName: "",
  taxExemptCode: "",
  customerContacts: [],
};

export const basicPaymentInfo = (enablePayment) => ({
  currencyCode: "USD",
  paymentMethod: [
    {
      paymentMethodType: "",
      enablePayment,
      savePayment: false,
      billingInformation: {
        addressLine1: "",
        suite: "",
        city: "",
        state: "",
        zip: "",
        country: "US",
        email: "",
        emailOptIn: false,
      },
      paymentMethodDetails: {
        name: "",
        cardNumber: "",
        cvv: "",
        expDate: "",
        tenderedAmount: "",
        changeDueAmount: "",
        amount: "",
        note: "",
      },
    },
  ],
});

export const initialVals = ({
  memberCodes = [],
  shopCode,
  orderChannel,
  shopNames = [],
  permissions,
  customerDetails = {},
  subscriptionDetails = {},
  global,
}) => {
  const shopsList = memberCodes.map((code) => {
    return { label: code, value: code };
  });

  const shopName = shopNames[shopCode] || "";

  let customerInfo = { ...basicCustomerInfo };
  const selectedShopPermissions = get(permissions, shopCode, {});

  const isPaymentEntitlementEnabled = selectedShopPermissions[
    Entitlements.CREATE_ORDER
  ]?.includes(Entitlements.CREATE_ORDER_PAGE.PAYMENT_SECTION);

  if (!isEmpty(customerDetails)) {
    const hasPaymentInfo =
      Object.values(
        omit(customerDetails.paymentInfo, [
          "billingAddress",
          "sameAsMailingAddress",
        ])
      ).filter((val) => !!val).length > 0;

    const billingAddress = get(customerDetails, "address", {});
    let updatedBillingInfo = {};

    if (!isEmpty(billingAddress)) {
      updatedBillingInfo = getBillingInfo(billingAddress);
    }

    const customerContacts = get(customerDetails, "customerContacts", []);
    const contactId = get(subscriptionDetails, "contactId", "");
    const selectedContact = customerContacts?.find(
      (contact) => contact?.contactId === contactId
    );

    customerInfo = {
      ...customerDetails,
      customerType:
        customerDetails.customerType === "true" ? "Business" : "Individual",
      firstPaymentInfo: hasPaymentInfo ? customerDetails.paymentInfo || {} : {},
      billingInformation: updatedBillingInfo,
      houseAccountInfo: {
        orderTotal: customerDetails?.houseAccountInfo
          ? get(customerDetails, "houseAccountInfo.orderTotal", 0)
          : 0,
        ...customerDetails?.houseAccountInfo,
      },
      selectContact: selectedContact ? JSON.stringify(selectedContact) : "",
    };
  }

  const initialObj = {
    actualCustomerInfo: customerInfo,
    customerInfo,
    hasCustomerInfo: true,
    hasMultipleShops: shopsList.length > 1,
    sendingMember:
      shopsList.length > 1
        ? shopCode !== "all"
          ? shopCode
          : ""
        : memberCodes[0],
    sendingMemberField: shopCode !== "all" ? `${shopCode} ${shopName}` : "",
    orderChannel,
    orderType: "Local",
    orderOrigin: "MHQ",
    savePayment: true,
    paymentDetails: basicPaymentInfo(isPaymentEntitlementEnabled),
    createHouseAccount: "",
    nameOnCard: "",
    cardNumber: "",
    expDate: "",
    securityCode: "",
    billingZip: "",
    coupon: "",
    isBillingAndCutomerAdddressSame: "",
    isSubscription: true,
  };

  let initialValues = {};

  if (isEmpty(subscriptionDetails)) {
    initialValues = {
      ...initialObj,
      isModify: false,
      orderItems: [
        {
          ...basicOrderInfo,
        },
      ],
      subscriptionInfo: {
        firstDeliveryDate: "",
        frequency: "",
        ends: "NEVER",
        deliveryInstructions: "",
      },
    };
  } else {
    const orderItems = get(subscriptionDetails, "orderTemplate.orderItems", [
      {
        ...basicOrderInfo,
      },
    ]);

    const sendingMember = get(orderItems, "0.sendingMember.memberCode", "");

    const updatedOrderItems = cloneDeep(orderItems);

    if (updatedOrderItems && updatedOrderItems.length) {
      updatedOrderItems.map((item, index) => {
        const formattedLineItems = formatLineItems(
          orderItems[index]?.lineItems,
          global,
          true,
          subscriptionDetails.isModify,
          false,
          sendingMember // reusing sending member code here as subscription orders are local orders i.e. sender & receiver are same
        );

        const { updatedLineItems = [], updatedLineItemAmounts = [] } =
          formattedLineItems;

        set(updatedOrderItems, `${index}.lineItems`, updatedLineItems);
        set(updatedOrderItems, `${index}.price`, updatedLineItemAmounts);

        if (item.deliveryInfo?.cardMessage === "N/A") {
          set(item, "deliveryInfo.cardMessage", "");
        }

        if (!subscriptionDetails.isModify) {
          item.price.map((priceVal, idx) => {
            set(
              priceVal,
              `discount`,
              customerDetails.discountPercentage
                ? formatPrice(customerDetails.discountPercentage)
                : 0
            );

            set(
              priceVal,
              `discountType`,
              customerDetails.discountPercentage ? "Percentage" : null
            );
          });
        }
      });
    }

    const subscriptionInfo = get(subscriptionDetails, "schedule.repeat.0");

    initialValues = {
      ...initialObj,
      sendingMember,
      sendingMemberField: `${sendingMember} ${shopNames[sendingMember] || ""}`,
      isModify: subscriptionDetails.isModify,
      orderItems: updatedOrderItems,
      subscriptionInfo: {
        firstDeliveryDate: subscriptionDetails.isModify
          ? subscriptionInfo?.firstDeliveryDate
          : "",
        frequency: subscriptionInfo?.frequency,
        ends: subscriptionInfo?.ends,
        deliveryInstructions: subscriptionInfo?.deliveryInstructions || "",
      },
    };
  }

  return initialValues;
};
