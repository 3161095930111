import * as Yup from "yup";
import {
  FormFieldSwitch,
  FormField,
  FormFieldPicker,
} from "components/elements/forms";

const protocolTypeOptions = [
  { label: "Pop3", value: "Pop3" },
  { label: "Imap", value: "Imap" },
];
const encryptionTypeOptions = [
  { label: "DES", value: "DES" },
  { label: "RC2", value: "RC2" },
  { label: "Rijndael", value: "Rijndael" },
  { label: "TripleDES", value: "TripleDES" },
];

export const IntegrationSettingsKeys = {
  telOrders: [
    "teleflora_enabled",
    "teleflora_memberCode",
    "teleflora_userName",
    "teleflora_password",
  ],
  emailOrders: [
    "eoi_is_enabled",
    "eoi_mail_server_type",
    "eoi_order_mail_username",
    "eoi_order_mail_password",
    "eoi_error_mail_username",
    "eoi_mail_server_port",
    "eoi_mail_protocol_type",
    "eoi_mail_encryption_type",
    "eoi_mail_encryption_key",
    "eoi_order_mail_subject",
  ],
};
export const keyMappings = {
  telOrders: {
    isEnabled: "teleflora_enabled",
    telMemberNumber: "teleflora_memberCode",
    telUserName: "teleflora_userName",
    telPassword: "teleflora_password",
  },
  emailOrders: {
    isEnabled: "eoi_is_enabled",
    mailServer: "eoi_mail_server_type",
    email: "eoi_order_mail_username",
    password: "eoi_order_mail_password",
    errorEmail: "eoi_error_mail_username",
    port: "eoi_mail_server_port",
    protocolType: "eoi_mail_protocol_type",
    encryptionType: "eoi_mail_encryption_type",
    encryptionKey: "eoi_mail_encryption_key",
    subject: "eoi_order_mail_subject",
  },
};

export const credentialsFields = {
  telOrders: ["telUserName", "telPassword"],
  emailOrders: ["mailServer", "email", "password", "protocolType"],
};

export const getUIConfig = () => ({
  telOrders: {
    title: "Teleflora Orders",
    showDivider: true,
    fields: [
      {
        field: FormFieldSwitch,
        title: "Integrate TEL Orders",
        props: {
          name: "isEnabled",
          testID: "integrate_tel_orders",
        },
      },
      {
        field: FormField,
        title: "TEL Member Number",
        props: {
          name: "telMemberNumber",
          placeholder: "Enter Member Number",
          testID: "tel_member_number",
        },
      },
      {
        field: FormField,
        title: "TEL User Name",
        props: {
          name: "telUserName",
          placeholder: "Enter User Name",
          autoComplete: "new-password",
          testID: "tel_user_name",
        },
      },
      {
        field: FormField,
        title: "TEL Password",
        props: {
          name: "telPassword",
          placeholder: "Enter Password",
          secureTextEntry: true,
          autoComplete: "new-password",
          testID: "tel_password",
        },
      },
    ],
  },
  emailOrders: {
    title: "Email Orders",
    showDivider: false,
    fields: [
      {
        field: FormFieldSwitch,
        title: "Email Orders",
        props: {
          name: "isEnabled",
          testID: "email_orders_switch",
        },
      },
      {
        field: FormField,
        title: "Server",
        props: {
          name: "mailServer",
          placeholder: "Mail server",
          testID: "mail_server",
        },
      },
      {
        field: FormField,
        title: "Account",
        props: {
          name: "email",
          placeholder: "Enter Email",
          testID: "email",
        },
      },
      {
        field: FormField,
        title: "Password",
        props: {
          name: "password",
          placeholder: "Enter Password",
          secureTextEntry: true,
          testID: "password",
        },
      },
      {
        field: FormField,
        title: "Send Errors To",
        props: {
          name: "errorEmail",
          placeholder: "Enter Error Email",
          testID: "error_email",
        },
      },
      {
        field: FormField,
        title: "Port",
        props: {
          name: "port",
          placeholder: "Enter Port",
          testID: "port",
        },
      },
      {
        field: FormFieldPicker,
        title: "Protocol",
        props: {
          name: "protocolType",
          data: protocolTypeOptions,
          placeholder: {
            label: "Select Protocol Type",
            value: "",
            testID: "protocol_type",
          },
        },
      },
      {
        field: FormFieldPicker,
        title: "Encryption",
        props: {
          name: "encryptionType",
          data: encryptionTypeOptions,
          placeholder: {
            label: "Select Encryption Type",
            value: "",
            testID: "encryption_type",
          },
        },
      },
      {
        field: FormField,
        title: "Encryption P/W",
        props: {
          name: "encryptionKey",
          placeholder: "Enter Encryption Key",
          testID: "encryption_key",
        },
      },
      {
        field: FormField,
        title: "Subject",
        props: {
          name: "subject",
          placeholder: "Enter Subject",
          testID: "subject",
        },
      },
    ],
  },
});

export const getInitialValues = (shopSettings) => {
  return {
    telOrders: {
      isEnabled: shopSettings["teleflora_enabled"] === "true",
      telMemberNumber: shopSettings["teleflora_memberCode"],
      telUserName: shopSettings["teleflora_userName"],
      telPassword: shopSettings["teleflora_password"],
    },
    emailOrders: {
      isEnabled: shopSettings["eoi_is_enabled"] === "true",
      mailServer: shopSettings["eoi_mail_server_type"],
      email: shopSettings["eoi_order_mail_username"],
      password: shopSettings["eoi_order_mail_password"],
      errorEmail: shopSettings["eoi_error_mail_username"],
      port: shopSettings["eoi_mail_server_port"],
      protocolType: shopSettings["eoi_mail_protocol_type"],
      encryptionType: shopSettings["eoi_mail_encryption_type"],
      encryptionKey: shopSettings["eoi_mail_encryption_key"],
      subject: shopSettings["eoi_order_mail_subject"],
    },
  };
};

export const getValidationSchema = (Localise, messages) => {
  return {
    telOrders: Yup.object().shape({
      telMemberNumber: Yup.string().required(
        Localise(messages, "Please enter Member Number")
      ),
      telUserName: Yup.string().required(
        Localise(messages, "Please enter Username")
      ),
      telPassword: Yup.string().required(
        Localise(messages, "Please enter Password")
      ),
    }),
    emailOrders: Yup.object().shape({
      mailServer: Yup.string().required(
        Localise(messages, "Please enter Mail Server")
      ),
      email: Yup.string()
        .email(Localise(messages, "Enter a valid email"))
        .required(Localise(messages, "Please enter Email")),
      password: Yup.string().required(
        Localise(messages, "Please enter Password")
      ),
      errorEmail: Yup.string()
        .email(Localise(messages, "Enter a valid email"))
        .required(Localise(messages, "Please enter Error Email")),
      protocolType: Yup.string().required(
        Localise(messages, "Please select Protocol Type")
      ),
      subject: Yup.string().required(
        Localise(messages, "Please enter Subject")
      ),
    }),
  };
};
