import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";
import { SLICE_NAME as parentSlice } from "../constants";

export const initialState = {
  memberCode: "",
  jobId: "",
  groupName: "",
};

const slice = createSlice({
  name: `view::${parentSlice}::${sliceName}`,
  initialState,
  reducers: {
    setMemberCode: (state, action) => {
      state.memberCode = action.payload;
    },
    setJobId: (state, action) => {
      state.jobId = action.payload;
    },
    setGroupName: (state, action) => {
      state.groupName = action.payload;
    },
  },
});

export const { setMemberCode, setJobId, setGroupName } = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
