import React, { useCallback, useEffect } from "react";
import { View, Text } from "react-native";
import { Image, Input } from "react-native-elements";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import debounce from "lodash/debounce";
import tw from "tailwind-rn";
import { fonts, colors } from "styles/theme";
import IMAGES from "static/assets/images";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { setSearchFieldText } from "library/sagas/ongoing/global-data/slice";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import I18NContext from "library/contexts/i18N";

// eslint-disable-next-line react/display-name
const SearchComponent = React.memo(
  ({
    isSmallScreen,
    setSearch,
    unsetSearch,
    placeholder,
    search,
    currentPage,
    searchFieldText,
    setSearchFieldText,
    enableSearch,
    showFiltersTitle,
  }) => {
    if (!enableSearch) return null;
    const searchMenuRef = React.useRef();

    const onSearch = (e) => {
      if (e) setSearch(e, e);
      else unsetSearch();
    };

    const onClose = () => {
      searchMenuRef?.current?.close();
    };

    return isSmallScreen ? (
      <Menu ref={searchMenuRef}>
        <MenuTrigger>
          <View style={tw("flex-col justify-around items-center")}>
            <Image
              style={{ width: 20, height: 20 }}
              resizeMode="cover"
              source={IMAGES["search"]}
            />
          </View>
        </MenuTrigger>
        <MenuOptions
          optionsContainerStyle={{
            width: "85%",
          }}
        >
          <MenuOption onSelect={() => false}>
            <SearchBox
              isSmallScreen={true}
              onSearch={onSearch}
              onClose={onClose}
              search={search}
              placeholder={placeholder}
              currentPage={currentPage}
              searchFieldText={searchFieldText}
              setSearchFieldText={setSearchFieldText}
              unsetSearch={unsetSearch}
            />
          </MenuOption>
        </MenuOptions>
      </Menu>
    ) : (
      <SearchBox
        onSearch={onSearch}
        search={search}
        placeholder={placeholder}
        currentPage={currentPage}
        searchFieldText={searchFieldText}
        setSearchFieldText={setSearchFieldText}
        unsetSearch={unsetSearch}
        showFiltersTitle={showFiltersTitle}
      />
    );
  }
);

const SearchBox = ({
  isSmallScreen,
  onClose,
  onSearch,
  search,
  placeholder,
  currentPage,
  searchFieldText,
  setSearchFieldText,
  unsetSearch,
  showFiltersTitle,
}) => {
  const [userQuery, setUserQuery] = useStateIfMounted(!!search && search.value);
  const { messages, Localise } = React.useContext(I18NContext);

  const updateQuery = () => {
    userQuery && onSearch(userQuery);
    setSearchFieldText({ [currentPage]: userQuery });
  };

  const delayedQuery = useCallback(debounce(updateQuery, 2000), [userQuery]); //adding 2 second delay to trigger search action

  const onChange = (e) => {
    setUserQuery(e.nativeEvent.text);
  };

  useEffect(() => {
    delayedQuery();
    // Cancel the debounce on useEffect cleanup.

    return delayedQuery.cancel;
  }, [userQuery, delayedQuery]);

  useEffect(() => {
    if (!searchFieldText[currentPage] && !!userQuery) {
      setUserQuery("");
    }
  }, [searchFieldText]);

  return (
    <Input
      style={{
        width: isSmallScreen ? "100%" : "33%",
        fontFamily: fonts.fontFamily.default,
        fontSize: 12,
        height: 35,
      }}
      placeholderTextColor={showFiltersTitle ? colors.medium : colors.primary}
      onChange={onChange}
      onBlur={onClose}
      value={userQuery || searchFieldText.currentPage || ""}
      placeholder={Localise(messages, placeholder)}
      testID="search"
      containerStyle={{
        width: isSmallScreen ? "100%" : showFiltersTitle ? "75%" : "100%",
        height: 35,
      }}
      inputStyle={{ width: "100%" }}
      inputContainerStyle={{
        height: 35,
        alignItems: "center",
      }}
      rightIcon={
        userQuery ? (
          <Text
            style={{
              paddingRight: 5,
              fontWeight: "bold",
            }}
            onPress={() => unsetSearch()}
          >
            X
          </Text>
        ) : undefined
      }
      accessibilityLabel="search"
    />
  );
};

const mapStateToProps = (state = {}) => {
  return {
    searchFieldText: state.mhq.ongoing.global.searchFieldText,
    currentPage: state.mhq.ongoing.global.currentPage,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setSearchFieldText }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent);
