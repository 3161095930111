import React, { useContext } from "react";
import { Text, View, Platform } from "react-native";
import { colors } from "styles/theme";
import tw from "tailwind-rn";

import { CustomModal } from "components/elements";
import I18NContext from "library/contexts/i18N";

const QRScanErrorModal = ({
  modalVisible,
  handlePrimary,
  handleSecondary,
  errorText = "",
}) => {
  const { Localise, messages } = useContext(I18NContext);

  const modalContent = {
    content: (
      <View>
        <Text
          style={{
            fontSize: 15,
            fontWeight: "bold",
            color: colors.highlighter,
            textAlign: "center",
          }}
        >
          {Localise(messages, "QR Code Scan")}
        </Text>
        <Text
          style={{
            ...tw("pt-4 px-4"),
            fontSize: 15,
            color: colors.primary,
            textAlign: "center",
          }}
        >
          {Localise(messages, errorText)}
        </Text>
      </View>
    ),
    buttons: [{ type: "secondary", title: Localise(messages, "OK") }],
  };

  return (
    <CustomModal
      modalVisible={modalVisible}
      modalContent={modalContent}
      primaryhandler={handlePrimary}
      secondaryhandler={handleSecondary}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: "white" },
      ]}
      modalStyle={
        Platform.OS !== "web" && {
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          backgroundColor: "#00000070",
          color: "#FFFFFF",
        }
      }
    ></CustomModal>
  );
};

export default QRScanErrorModal;
