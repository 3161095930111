import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";
import { isCustomDateRangeToggle } from "library/utils/formatter";

export const initialState = {
  data: [],
  recordId: "",
  recordAction: "",
  actions: {
    appliedFilters: [],
    filters: [],
    selectedShops: [],
    shops: [],
    search: {},
    sort: {},
  },
  loading: true,
  userRolesPermissions: [],
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setActions: (state, action) => {
      state.actions = { ...state.actions, ...action.payload };

      const currentFilters = action.payload.appliedFilters;
      const prevFilters = state.actions.appliedFilters;

      const changeInDateRange = currentFilters
        ? isCustomDateRangeToggle(prevFilters, currentFilters)
        : false;

      if (changeInDateRange) state.data = [];
    },
    setData: (state, action) => {
      const { data } = action.payload;
      state.data = data;
      state.loading = false;
    },
    setRecordId: (state, action) => {
      state.recordId = action.payload;
    },
    setRecordAction: (state, action) => {
      state.recordAction = action.payload;
    },
    resetBasicInfo: (state) => {
      Object.assign(state, {
        ...state,
        recordId: "",
        recordAction: "",
        actions: {
          appliedFilters: [],
          filters: [],
          selectedShops: [],
          shops: [],
          search: {},
          sort: {},
        },
      });
    },
    reset: (state) => {
      Object.assign(state, initialState);
    },
    setRolesPermissions: (state, action) => {
      const { data } = action.payload;
      state.userRolesPermissions = data;
    },

    // noop - only actions
    fetchData: () => {},
    fetchFtdAdminData: () => {},
    fetchUserRolesPermissions: () => {},
  },
});

export const {
  setLoading,
  setActions,
  setData,
  setRecordId,
  setRecordAction,
  resetBasicInfo,
  reset,
  fetchData,
  fetchFtdAdminData,
  fetchUserRolesPermissions,
  setRolesPermissions,
} = slice.actions;

export default { [sliceName]: slice.reducer };
