import Logger from "library/utils/logger";

let errorMessage = "";
export const logError = (error, info) => {
  if (error && error.message) {
    errorMessage = error?.message.replace(
      // eslint-disable-next-line
      /[&\/\\#,+()$~%.'":*?<>{}]/g,
      ""
    );
    Logger.error("Error details : ", errorMessage);
  }
};

export const getErrorLogMessage = () => {
  return errorMessage;
};
