import React, { useCallback, useEffect, useContext } from "react";
import { ActivityIndicator, TouchableOpacity, View } from "react-native";
import { Button, Icon, Image, Text } from "react-native-elements";
import { useDispatch } from "react-redux";

import { Accordion } from "components/elements";
import { PopupMenu } from "components/elements";

import { request } from "library/utils/request";
import {
  InitFloristsData,
  ListOfColumns,
  ListOfActions,
  UIConfig,
} from "./config";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { setSideCar } from "library/sagas/views/home/drawer/shop-settings/common/slice";

import { IMAGES, DIMENSIONS } from "static/assets/images";
import toLower from "lodash/toLower";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import tw from "tailwind-rn";
import { fonts, colors, backgroundColors } from "styles/theme";

import styles from "./styles";
import { getMembersInfo } from "./helper";

const PreferencesListing = ({ florists, isDesktop, submitPreferences }) => {
  const { messages, Localise } = useContext(I18NContext);
  const isSmallScreen = !isDesktop;

  return UIConfig.map((section) => {
    const { key, title, displayStatus = "close" } = section;
    const shops = florists[key];
    const openAccordion = displayStatus === "open";

    return (
      <Accordion
        key={key}
        title={`${Localise(messages, title)} (${shops.length})`}
        titleStyle={{
          ...fonts.heading3,
          fontWeight: "600",
          color: colors.primary,
        }}
        labelStyle={{
          marginVertical: 5,
          backgroundColor: backgroundColors.secondary,
          paddingHorizontal: 0,
        }}
        iconStyle={{
          color: colors.primary,
        }}
        defaultOpen={openAccordion}
        handleOnPress={() => {}}
      >
        {shops.length > 0 &&
          shops.map((shop, index) => (
            <React.Fragment key={index}>
              <PreferenceListItem
                shop={shop}
                submitAction={submitPreferences}
                columnsList={ListOfColumns}
                isSmallScreen={isSmallScreen}
              />
            </React.Fragment>
          ))}
      </Accordion>
    );
  });
};

const PreferenceListItem = ({
  shop,
  submitAction,
  columnsList,
  isSmallScreen,
}) => {
  const { memberCode: recordId, partnerPreference = "" } = shop.basicInfo;
  const menuOptions = get(ListOfActions, toLower(partnerPreference), []);

  return (
    <React.Fragment key={recordId}>
      <ListItem
        columnsList={columnsList}
        item={shop.basicInfo}
        itemKey={"memberCode"}
        isSmallScreen={isSmallScreen}
        menuOptions={menuOptions}
        shop={shop}
        submitAction={(preference) => submitAction(preference, recordId)}
      />
    </React.Fragment>
  );
};

const ListItem = React.memo(
  ({
    item,
    columnsList,
    subColumnsList = [],
    isSelectedRecord,
    menuOptions,
    submitAction,
    isSmallScreen,
    shop,
  }) => {
    const dispatch = useDispatch();
    const { messages, Localise } = useContext(I18NContext);
    const {
      statusColor,
      imageName,
      isDigitalAdAvailable = false,
      isMasterFlorist = false,
      isPremierFlorist = false,
    } = item;
    const { imageWidth = 20, imageHeight = 20 } = DIMENSIONS[imageName] || {};

    return (
      <TouchableOpacity
        activeOpacity={0.6}
        onPress={() => {
          dispatch(setSideCar({ type: "florist_partner_details", data: shop }));
        }}
        style={{
          minWidth: 250,
        }}
        testID="select_shop"
        accessibilityLabel="select_shop"
      >
        <View
          style={[
            styles.recordContainer,
            isSelectedRecord && styles.activeRecordContainer,
          ]}
        >
          <View
            style={[
              styles.record,
              {
                borderColor: statusColor || "transparent",
              },
            ]}
          >
            <View
              style={[
                tw("justify-between items-center"),
                styles.recordItemView,
                styles.status,
              ]}
            >
              <View
                style={tw(
                  `flex flex-row items-center w-full ${
                    isSmallScreen ? "justify-between" : "justify-evenly"
                  }`
                )}
              >
                <Text
                  style={{
                    ...styles.recordItem,
                    ...styles.statusText,
                    ...{ textAlign: isSmallScreen ? "left" : "center" },
                  }}
                >
                  {Localise(messages, "Shop")}
                </Text>
                <Image
                  style={{
                    width: imageWidth,
                    height: imageHeight,
                  }}
                  containerStyle={{
                    marginHorizontal: 10,
                    marginVertical: 5,
                  }}
                  source={IMAGES[imageName]}
                />
              </View>
            </View>

            {columnsList.map((column) => (
              <View
                key={column}
                style={[styles.recordItemView, styles[column]]}
              >
                <Text
                  numberOfLines={1}
                  style={{
                    ...styles.recordItem,
                    textAlign: "left",
                    ...(isSelectedRecord && {
                      color: backgroundColors.primary,
                    }),
                    fontWeight: isSelectedRecord ? "600" : "500",
                  }}
                  title={item[column]}
                >
                  {capitalize(item[column])}
                </Text>
                <Text
                  numberOfLines={1}
                  style={{
                    ...styles.subRecordItem,
                    ...(isSelectedRecord && {
                      color: backgroundColors.primary,
                    }),
                    fontWeight: isSelectedRecord ? "600" : "500",
                  }}
                >
                  {item[`${subColumnsList[column]}`]}
                </Text>
              </View>
            ))}
            <View
              style={[
                tw("justify-between items-center"),
                styles.subscription,
                styles.recordItemView,
              ]}
            >
              <View style={tw(`flex flex-row items-center w-full flex-wrap`)}>
                {isMasterFlorist && (
                  <Image
                    style={{
                      width: 35,
                      height: 35,
                    }}
                    containerStyle={{
                      marginHorizontal: 10,
                      marginVertical: 5,
                    }}
                    source={IMAGES["master-florist"]}
                  />
                )}
                {isPremierFlorist && (
                  <Image
                    style={{
                      width: 35,
                      height: 35,
                    }}
                    containerStyle={{
                      marginHorizontal: 10,
                      marginVertical: 5,
                    }}
                    source={IMAGES["premier-florist"]}
                  />
                )}
                {isDigitalAdAvailable && (
                  <Image
                    style={{
                      width: 35,
                      height: 35,
                    }}
                    containerStyle={{
                      marginHorizontal: 10,
                      marginVertical: 5,
                    }}
                    source={IMAGES["florist-ad"]}
                  />
                )}
              </View>
            </View>
          </View>
          <PopupMenu
            containerStyle={styles.menuContainer}
            optionsContainerStyle={{
              width: 175,
              padding: 5,
            }}
            menuOptions={menuOptions}
            onSelect={submitAction}
          >
            <Icon name={"more-horiz"} size={20} color={"#000"} />
          </PopupMenu>
        </View>
      </TouchableOpacity>
    );
  }
);

const PreferencesListingContainer = ({
  isDesktop,
  shopCode,
  refreshPreferences,
  setRefreshPreferences,
}) => {
  const dispatch = useDispatch();
  const { messages, Localise } = useContext(I18NContext);

  const [florists, setFlorists] = useStateIfMounted(InitFloristsData);
  const [loading, setLoading] = useStateIfMounted(false);

  useEffect(() => {
    if (refreshPreferences) {
      setLoading(true);
      request("get-preferences-florists", {
        memberCodes: [shopCode],
        preference: "all",
      })
        .then((res) => {
          const { membersInfo } = getMembersInfo(
            (res && res) || {},
            false,
            true
          );
          setFlorists(membersInfo);
          setLoading(false);
          setRefreshPreferences(false);
        })
        .catch(() => {
          setLoading(false);
          setRefreshPreferences(false);
        });
    }
  }, [refreshPreferences, shopCode]);

  const submitPreferences = useCallback(
    (preference, targetMemberCode) => {
      request("save-florist-preferences", {
        memberCode: shopCode,
        preference: preference,
        targetMemberCode: targetMemberCode,
      }).then((res) => {
        setRefreshPreferences(true);
      });
    },
    [shopCode]
  );

  return (
    <View style={styles.preferencesContainer}>
      <View style={styles.preferencesHeader}>
        <Text style={{ ...fonts.heading2, ...tw("p-4") }}>
          {Localise(messages, "Florist Partner Preferences")}
        </Text>
        {loading && <ActivityIndicator color={colors.activityIndicator} />}
      </View>
      <View style={styles.preferencesList}>
        <PreferencesListing
          florists={florists}
          isDesktop={isDesktop}
          submitPreferences={submitPreferences}
        />
        <View style={styles.searchPreferences}>
          <Button
            testID="search_florist"
            accessibilityLabel="search_florist"
            title={Localise(messages, "Search Florist Partners")}
            onPress={() => {
              dispatch(setSideCar({ type: "search_florist_partners" }));
            }}
            containerStyle={{ marginHorizontal: 0 }}
          />
        </View>
      </View>
    </View>
  );
};

export default PreferencesListingContainer;
