import { takeLatest } from "redux-saga/effects";
import * as Navigation from "library/utils/navigation";
import { setActiveTab } from "./slice";
import get from "lodash/get";

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */

function* handleNavigation(action = {}) {
  const name = get(action, "payload", "");
  yield Navigation.navigate({
    name,
  });
}

/**
 * Will be using when integrating UI with backend
 */
export function* watchSaga() {
  yield takeLatest(setActiveTab.type, handleNavigation);
}

export default watchSaga;
