import { combineReducers } from "redux";
import { SLICE_NAME as sliceName } from "./constants";

import drawerReducer from "./drawer/reducer";

const reducer = {
  [sliceName]: combineReducers({
    ...drawerReducer,
  }),
};

export default reducer;
