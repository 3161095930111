import {
  put,
  takeLatest,
  fork,
  cancel,
  delay,
  call,
  select,
} from "redux-saga/effects";

import {
  setPageInitialize,
  setPageInitSuccess,
  setPageInitFailure,
  fetchSiteContent,
  fetchAnalytics,
  fetchWebsiteStatus,
  deleteSuspension,
  saveSuspension,
  fetchWebsiteSettings,
  saveWebsiteSettings,
  setWebsiteStatusPollStart,
  setWebsiteStatusPollStop,
} from "./slice";

import {
  handleFetchWebsiteStatus,
  handleSaveSuspension,
  handleDeleteSuspension,
  handleWebsiteActiveSuspension,
} from "./website-status";

import {
  handleFetchWebsiteSettings,
  handleSaveWebsiteSettings,
} from "./website-settings";

import { handleFetchAnalytics } from "./analytics";
import { handleFetchSiteContent } from "./site-content";

import { WEBSITE_STATUS_POLL_INTERVAL } from "./constants";

import { selectAllowSyndicate } from "./selector";

function* handlePageIntialize() {
  const allowSyndicate = yield select(selectAllowSyndicate);
  try {
    if (!allowSyndicate) {
      yield put(fetchWebsiteStatus());
      yield put(fetchWebsiteSettings());
    }
    yield put(fetchSiteContent());
    yield put(fetchAnalytics());
    yield put(setPageInitSuccess());
  } catch (error) {
    yield put(setPageInitFailure());
  }
}

/**
 * POLLING
 */
function* pollWebsiteStatusSagaWorker() {
  while (true) {
    try {
      yield call(handleWebsiteActiveSuspension);
      yield delay(WEBSITE_STATUS_POLL_INTERVAL);
    } catch (err) {
      console.log("Product Catalog Polling failed");
    }
  }
}

function* forkWebsiteStatusPollSagaWorker() {
  const pollTask = yield fork(pollWebsiteStatusSagaWorker);
  yield pollWebsiteStatusCancelSagaWatcher(pollTask);
}

function* cancelWebsiteStatusPollSagaWorker(pollTask) {
  yield cancel(pollTask);
}

/**
 * WATCHERS
 */
export function* pollWebsiteStatusCancelSagaWatcher(pollingTask) {
  yield takeLatest(setWebsiteStatusPollStop.type, () =>
    cancelWebsiteStatusPollSagaWorker(pollingTask)
  );
}

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeLatest(setPageInitialize.type, handlePageIntialize);

  yield takeLatest(fetchWebsiteStatus.type, handleFetchWebsiteStatus);
  yield takeLatest(saveSuspension.type, handleSaveSuspension);
  yield takeLatest(deleteSuspension.type, handleDeleteSuspension);

  yield takeLatest(fetchSiteContent.type, handleFetchSiteContent);
  yield takeLatest(fetchAnalytics.type, handleFetchAnalytics);

  yield takeLatest(fetchWebsiteSettings.type, handleFetchWebsiteSettings);
  yield takeLatest(saveWebsiteSettings.type, handleSaveWebsiteSettings);

  yield takeLatest(
    setWebsiteStatusPollStart.type,
    forkWebsiteStatusPollSagaWorker
  );
}

export default watchSaga;
