import React, { useContext } from "react";
import { Platform, View, Linking, ScrollView } from "react-native";
import { Text } from "react-native-elements";
import { DeviceContext } from "library/contexts/appSettings";
import { fonts, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";

export const PrivacyPolicyContent = () => {
  const { isDesktop } = useContext(DeviceContext);
  return (
    <>
      <View
        style={{
          ...tw("w-full p-3 mb-2"),
          backgroundColor: backgroundColors.primary,
        }}
        fsClass="fs-unmask"
      >
        <Text style={{ ...fonts.heading3, ...tw("text-white text-center") }}>
          MERCURY HQ SYSTEM PRIVACY STATEMENT
        </Text>
      </View>
      <ScrollView
        style={{ paddingHorizontal: !isDesktop ? 10 : 0 }}
        fsClass="fs-unmask"
      >
        <View style={tw("py-1")}>
          <Text style={tw("p-2 text-justify")}>
            This Privacy Statement is incorporated by reference in the Terms and
            Conditions of Use for Mercury HQ and applies to your use of the
            System. Unless otherwise noted, capitalized terms have the
            definitions set out in the Terms and Conditions of Use.
          </Text>
        </View>
        <View style={tw("py-1")}>
          <Text style={tw("p-2 text-justify")}>
            In the course of approving you as a Member of the System, FTD
            collects a variety of information about you, including Registration
            Information and System Information, and specifically including your
            business name, business address, contact telephone number, contact
            individual name, hours of operation, delivery and other charges,
            minimum pricing, and other similar information (collectively,
            “Company Information”).
          </Text>
        </View>
        <View style={tw("py-1")}>
          <Text style={tw("p-2 text-justify")}>
            To the extent that Company Information includes any information that
            is personally identifiable to an individual (“Personal Information”)
            subject to protection under applicable privacy laws, then your
            voluntary disclosure of Personal Information indicates your consent
            to our collection, use and disclosure of such information on the
            terms and for the purposes we describe at the point the information
            is collected. If we do not describe these terms and purposes at the
            point of collection, then our “use” of any of your Personal
            Information shall be deemed to be for the purposes of {"\n\n"}
            (1) Determining whether you qualify as a Member; {"\n"}(2)
            Communicating with you regarding your membership, including your
            obligations and entitlements under the Member Agreement; {"\n"}(3)
            Publishing certain information about you on the System for reference
            by other Members; {"\n"}(4) Enabling you to access the System;{" "}
            {"\n"}(5) Internal business planning within FTD; {"\n"}(6)
            Improvements and enhancements to the System. Where not otherwise
            specified, “disclosure” of any of your Personal Information will be
            restricted to FTD employees and FTD’s third party providers who
            facilitate operation of the System or assist with FTD’s internal
            business planning, unless disclosure to any other third party is
            necessary to comply with any applicable law, regulation, legal
            process or compulsory governmental request. If you do not wish to
            provide Personal Information to us, you may still be able to access
            most or all of the System. {"\n"}
          </Text>
        </View>
        <View style={tw("py-1")}>
          <Text style={tw("p-2 text-justify")}>
            We track and record the Internet Protocol addresses of Members who
            use the System and analyze the data in aggregate form for trends and
            statistics, which we may share or sell to affiliates, licensees or
            partners, but the tracked data will never include Personal
            Information, except for Internet Protocol addresses. We may also
            send "cookies" to your computer to better serve you with more
            tailored information when you return to the System, but you are not
            required to accept cookies in order to use the System.
          </Text>
        </View>
        <View>
          <Text style={tw("px-2 text-justify")}>
            Sound credits{" "}
            <Text
              style={{ ...fonts.link1, color: "#0077cc" }}
              onPress={() =>
                Platform.OS === "web"
                  ? window.open(
                      "https://creativecommons.org/licenses/by/4.0/legalcode"
                    )
                  : Linking.openURL(
                      "https://creativecommons.org/licenses/by/4.0/legalcode"
                    )
              }
            >
              Click Here
            </Text>
          </Text>
        </View>
        <View style={tw("py-1")}>
          <Text style={tw("p-2 text-justify")}>
            If you have any questions regarding our privacy statement, or wish
            to review, correct or remove your Personal Information in our
            possession, please drop an email to{" "}
            <Text
              style={{ ...fonts.link1, color: "#0077cc" }}
              onPress={() => Linking.openURL("mailto:MercSupport@ftdi.com")}
            >
              MercSupport@ftdi.com
            </Text>
            .
          </Text>
        </View>
      </ScrollView>
    </>
  );
};
