import React, { useCallback, useEffect, useMemo, useContext } from "react";
import {
  View,
  Platform,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";

import { Form, FormFieldPicker } from "components/elements/forms";
import IMAGES from "static/assets/images";
import ListItem from "components/views/drawer/list-item";
import { Button, CheckBox } from "react-native-elements";
import {
  getTimeLeftInMins,
  getPickupTimeLeftInMins,
} from "library/utils/datetime";
import { request } from "library/utils/request";
import UserProfileContext from "library/contexts/userProfile";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import uniq from "lodash/uniq";
import has from "lodash/has";
import pick from "lodash/pick";
import Environment from "library/utils/environment";
import {
  theme,
  fonts,
  colors,
  backgroundColors,
  orderStatusColorCodes,
} from "styles/theme";
import { getActionObj } from "components/views/drawer/order-details/helper";
import { Accordion, ToasterHandler, CustomModal } from "components/elements";
import useStateIfMounted from "library/utils/useStateIfMounted";
import {
  getOrderInternalStatus,
  orderDsRequestRerouting,
} from "library/utils/orderListing";
import moment from "moment";
import I18NContext from "library/contexts/i18N";

import UserProfileStorage from "library/storage/userProfile";
import tw from "tailwind-rn";
import * as Yup from "yup";

import { processSelectedProductResponse } from "library/utils/productDetails";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import {
  selectDesignerInfo,
  selectImages,
} from "library/sagas/ongoing/global-data/selector";
import {
  selectRecordData,
  selectIsDesignersAreaEnabled,
  selectedLoader,
} from "library/sagas/ongoing/current-orders/selector";
import { setEditOrder } from "library/sagas/views/home/drawer/create-order/slice";
import { setShowOrderUnpaidModal } from "library/sagas/ongoing/order-details/slice";
import { setDconStatus } from "library/sagas/ongoing/current-orders/slice";
import { getShopSettings } from "library/sagas/views/home/drawer/shop-settings/common/slice";

import {
  selectShowOrderUnpaidModal,
  selectIsSideCarOpen,
} from "library/sagas/ongoing/order-details/selector";
import PageStorage from "library/storage/pageStorage";
import { FieldArray } from "formik";
import { AppSettingsContext } from "library/contexts/appSettings";
import { filterDesignerSuggestions } from "components/views/drawer/bulk-scan/helper";
import { Entitlements } from "library/utils/entitlements";
import {
  getMemberCodesWithFeature,
  hasDesignCenterFeature,
} from "library/utils/featureAvailability";
import fetchOrders from "library/utils/fetchOrders";
import { ERROR_MESSAGES } from "library/constants";
import { isMHQNonCoreMember } from "library/utils/entitlements";

import {
  getExtendedMenuOptions,
  printTrigger,
  showPrintToaster,
} from "../../order-details/order-head/take-action/helper";
import {
  PrinterSettingsKeys,
  nonStandardEligiblePrinterSettings,
} from "components/views/drawer/shop-settings/orders/printer-settings/config";

//eslint-disable-next-line
export const OrdersList = React.memo(
  ({
    hasMoreOrders,
    isCustomRangeApplied,
    data = [],
    listingInfo,
    statusList,
    initData,
    fetchData,
    setActions,
    config,
    columnsList,
    subColumnsList,
    onAction,
    actionsList,
    subActionsList,
    recordId,
    setRecordId,
    setDeliveryMethod,
    setSourceMemberCode,
    isSmallScreen,
    isMobile,
    memberCodes,
    operator,
    filterActions,
    setMenuAction,
    initViewMoreCounts,
    loading,
    getData,
    listingType,
    minDeliveryDate = "",
    maxDeliveryDate = "",
    shops,
  }) => {
    const dispatch = useDispatch();
    const { messages, Localise } = useContext(I18NContext);
    const { permissions } = useContext(AppSettingsContext);
    const navigation = useNavigation();
    const imagesData = useSelector(selectImages);
    const [displayEligibleBucket, setDisplayEligibleBucket] = useStateIfMounted(
      []
    );
    const [searchFiltered, setSearchFiltered] = useStateIfMounted(false);

    const [selectedOrderDetails, setSelectedOrderDetails] = useStateIfMounted(
      {}
    );
    const orderDetailResponse = useSelector(selectRecordData);
    const showOrderUnpaidModal = useSelector(selectShowOrderUnpaidModal);
    const isSideCarOpen = useSelector(selectIsSideCarOpen);
    const {
      sendingMember = {},
      receivingMember = {},
      orderItemId = "",
      deliveryInfo = {},
      direction = "",
    } = orderDetailResponse?.orderItems?.length > 0 &&
    orderDetailResponse?.orderItems[0];

    const sourceMemberCode =
      direction === "INBOUND"
        ? receivingMember?.memberCode
        : sendingMember?.memberCode;

    const isMHQNonCoreUser = isMHQNonCoreMember(sourceMemberCode);

    const isProcessing = useSelector(selectedLoader);
    // showing list of designers in order assign screen
    const designersInfo = useSelector(selectDesignerInfo);
    const designerActions = [
      {
        label: `Assign to Designer`,
        value: `assign-to-designer`,
      },
      {
        label: `Remove Designer`,
        value: `remove-designer`,
      },
    ];
    const { userRole } = useContext(UserProfileContext);

    //Getting the member codes that have the Design Center feature.
    const designCenterMembers = getMemberCodesWithFeature(
      permissions,
      Entitlements.ORDERS,
      Entitlements.ORDERS_PAGE.DESIGNER
    );
    const dcMemberCodes =
      designCenterMembers.length === 1
        ? designCenterMembers
        : filterActions?.selectedShops.map((shop) => shop["Shop Codes"].value);

    //Getting the common designers for the given member codes.
    const filteredSuggestions = filterDesignerSuggestions(
      designersInfo,
      dcMemberCodes
    );

    const listOfDesigners = filteredSuggestions.map(
      ({ fullName = "", id = "" }) => ({
        label: fullName,
        value: id,
      })
    );

    // To get Design Center selections from session storage
    const getValuesFromStorage = (path = "designCenterSelections") =>
      PageStorage.getOrdersPageActions(`${path}`);

    // To set Design Center selections in session storage
    const setValuesToStorage = (path = "designCenterSelections", value) =>
      PageStorage.setOrdersPageActions(`${path}`, value);

    const viewMoreCountDetails = getValuesFromStorage("viewMoreCounts");

    const [viewMoreCounts, setViewMoreCounts] = useStateIfMounted(
      isEmpty(viewMoreCountDetails) ? initViewMoreCounts : viewMoreCountDetails
    );

    useEffect(() => {
      if (showDesignersSection) {
        setValuesToStorage("viewMoreCounts", viewMoreCounts);
      }
    }, [viewMoreCounts]);

    const showDesignersSection = useSelector(selectIsDesignersAreaEnabled);

    if (showDesignersSection) {
      config = config.slice(-2);
    } else config = config.slice(0, -2);

    useEffect(() => {
      const displayCategories = [];
      const searchCategories = [];
      filterActions?.appliedFilters?.map((filter) => {
        if (Object.keys(filter)[0] === "Categories") {
          const categoryValue = Object.values(filter)[0].value;
          ![
            "INBOUND",
            "OUTBOUND",
            "active-orders",
            "rush",
            "timedOrders",
          ].includes(categoryValue) && displayCategories.push(categoryValue);
        }
      });

      !isEmpty(filterActions?.search) && searchCategories.push("Search Text");
      if (displayCategories.length)
        setDisplayEligibleBucket(uniq(displayCategories));
      else setDisplayEligibleBucket([]);

      if (searchCategories.length) setSearchFiltered(true);
      else setSearchFiltered(false);
    }, [filterActions]);

    useEffect(() => {
      if (isSideCarOpen) {
        setRecordId(orderItemId);
        setDeliveryMethod && setDeliveryMethod(deliveryInfo?.deliveryMethod);
        setSourceMemberCode && setSourceMemberCode(sourceMemberCode);
        setTimeout(() => {
          pullOrders();
        }, 2000);
      }
    }, [orderDetailResponse]);

    const triggerAction = useCallback(
      (
        action,
        deliveryDate,
        recordId,
        deliveryMethod,
        sourceMemberCode,
        isDSSubmitEligible = false,
        remotePrint = false,
        remotePrinterName
      ) => {
        const reqObj = getActionObj(deliveryDate, action, "");
        const isMarkRead = action === "markAsRead";
        const requestMethod = isMarkRead
          ? "order-message-actions"
          : "order-actions";

        const commonParams = {
          recordId,
          deliveryMethod,
          sourceMemberCode,
          ...(remotePrint && { remotePrintRequest: true }),
        };
        const reqParams = {
          ...commonParams,
          ...(requestMethod === "order-message-actions"
            ? {
                markAsRead: isMarkRead,
                isStructured: true,
                isUnstructured: true,
              }
            : {
                action,
                ...reqObj,
                operator: operator,
              }),
        };
        request(requestMethod, reqParams).then((res) => {
          remotePrint &&
            showPrintToaster({
              remotePrint,
              printerName: remotePrinterName,
              artifactName: "Order Details",
              Localise,
              messages,
            });
          onAction(action, recordId);
          isDSSubmitEligible && setRecordId(recordId);
        });
      }
    );

    const triggerCopySale = useCallback(
      (recordId, deliveryMethod, sourceMemberCode) => {
        request("order-details", {
          recordId,
          deliveryMethod,
          sourceMemberCode,
        })
          .then((res) => {
            if (res && res.orderItems) {
              dispatch(setCurrentPage("create-order"));
              navigation.dispatch(
                CommonActions.navigate({
                  name: "create-order",
                  params: {
                    id: recordId,
                    action: "copy",
                    smc: sourceMemberCode,
                    dm: deliveryMethod,
                  },
                })
              );
            }
          })
          .catch(function (error) {
            console.log("error", error);
          });
      }
    );
    const pullOrders = () => {
      fetchOrders(
        "current",
        {
          ...listingInfo,
          timeStamp: moment().subtract(2, "minute").utc().format(),
        },
        statusList,
        initData,
        fetchData
      );
    };
    const triggerPrint = useCallback(
      (
        action,
        deliveryDate,
        recordId,
        deliveryMethod,
        sourceMemberCode,
        isDSSubmitEligible = false
      ) => {
        request("order-details", {
          recordId,
          deliveryMethod,
          sourceMemberCode,
        })
          .then((res) => {
            // order detail print trigger
            if (res && res.orderItems) {
              const {
                orderSource,
                multiProductEligible = false,
                lineItems,
                deliveryInfo: { deliveryMethod, cardMessage = "" },
              } = res.orderItems[0];

              const products = multiProductEligible
                ? lineItems.filter((lineItem) => lineItem.type === "Product")
                : lineItems;

              if (products.length) {
                let promises = [];
                products.forEach((product) => {
                  const productId = product?.productFirstChoiceCode || "";
                  if (productId) {
                    const productResp = request("get-product-details", {
                      productId: productId.toUpperCase(),
                      siteId: sourceMemberCode,
                      orderSource:
                        orderSource === "FLORIST" &&
                        deliveryMethod !== "FLORIST_PARTNER"
                          ? "LOCAL"
                          : orderSource,
                    });
                    promises.push(productResp);
                  }
                });
                Promise.all(promises).then((responses = []) => {
                  let productsInfo = [];

                  responses.map((productResp) => {
                    if (productResp?.products?.length > 0) {
                      const productInfo = processSelectedProductResponse(
                        (productResp && productResp.products) || []
                      );
                      productsInfo.push(productInfo);
                    }
                  });
                  if (
                    [
                      "accept-print",
                      "print",
                      "triCardPrint",
                      "invoice",
                      "designerWorksheetPrint",
                    ].includes(action)
                  ) {
                    // getting the latest printer-settings to support remote print
                    dispatch(
                      getShopSettings({
                        params: {
                          memberCode: sourceMemberCode,
                          ids: isMHQNonCoreUser
                            ? PrinterSettingsKeys
                            : nonStandardEligiblePrinterSettings,
                          updateStorage: true,
                        },
                        resolve: () => {
                          printTrigger({
                            selectedAction: action,
                            orderDetails: res.orderItems[0],
                            memberCodes,
                            operatorName: operator,
                            messages,
                            Localise,
                            customerInfo: res?.customerInfo,
                            paymentDetails: res?.paymentDetails,
                            dispatch,
                            selectedproducts: productsInfo,
                            cardInfo: res?.cardInfo,
                            imagesData,
                            cardMessage,
                            triggerAction,
                            orderDetailResponse,
                            ordersList: true,
                            deliveryInfo: get(
                              res?.orderItems[0],
                              `deliveryInfo`,
                              {}
                            ),
                            recordId,
                            isDSSubmitEligible,
                            userRole,
                          });
                        },
                      })
                    );

                    setTimeout(() => {
                      pullOrders();
                    }, 2000);
                  }
                });
              }
            }
          })
          .catch(function (error) {
            console.log("error", error);
          });
      }
    );

    const submitAction = useCallback(
      (
        action,
        deliveryDate,
        recordId,
        deliveryMethod,
        sourceMemberCode,
        isPickUpOrder,
        isDSSubmitEligible,
        hasPendingPayLaterPayment
      ) => {
        setSelectedOrderDetails({ recordId, deliveryMethod, sourceMemberCode });
        if (
          ["delivery-confirmation", "delivery-exception"].includes(action) &&
          hasPendingPayLaterPayment
        ) {
          dispatch(setShowOrderUnpaidModal(true));
        } else if (
          ["out-for-delivery", "transfer", "assign", "markAsRead"].includes(
            action
          ) ||
          (isPickUpOrder && ["delivery-confirmation"].includes(action))
        ) {
          triggerAction(
            action,
            deliveryDate,
            recordId,
            deliveryMethod,
            sourceMemberCode
          );
        } else if (["accept", "design", "designed"].includes(action)) {
          triggerAction(
            action,
            deliveryDate,
            recordId,
            deliveryMethod,
            sourceMemberCode,
            isDSSubmitEligible
          );
        } else if (action === "accept-print") {
          triggerPrint(
            action,
            deliveryDate,
            recordId,
            deliveryMethod,
            sourceMemberCode,
            isDSSubmitEligible
          );
        } else if (action === "copy-order") {
          triggerCopySale(recordId, deliveryMethod, sourceMemberCode);
        } else if (action === "printOrder") {
          triggerPrint(
            "print",
            deliveryDate,
            recordId,
            deliveryMethod,
            sourceMemberCode
          );
        } else if (
          ["triCardPrint", "invoice", "designerWorksheetPrint"].includes(action)
        ) {
          triggerPrint(
            action,
            deliveryDate,
            recordId,
            deliveryMethod,
            sourceMemberCode
          );
        }
        // else if (action === "cancel") {
        //   const orderReq = {
        //     operator,
        //     comments: "Cancelling",
        //     reason: "Customer canceled the order",
        //     deliveryDate,
        //   };
        //   triggerRequest(recordId, orderReq, "cancel");
        // }
        else if (
          [
            "delivery-confirmation",
            "delivery-exception",
            "cancel",
            "reject",
            "markAsUnread",
            // "markAsRead",
            "adjustment",
            "assign-to-designer",
          ].includes(action)
        ) {
          // To open order details in side car
          setRecordId(recordId);

          // Sending selected menu action to order details screen
          setMenuAction(action + recordId);

          // Updating deliveryMethod in redux as we need it now to make get order details API in sidecar
          setDeliveryMethod && setDeliveryMethod(deliveryMethod);

          // Updating sourceMemberCode in redux as we need to make get order details API by sending exact member code instead of first member code always
          setSourceMemberCode && setSourceMemberCode(sourceMemberCode);

          //
          if (
            ["delivery-confirmation", "delivery-exception"].includes(action)
          ) {
            dispatch(setDconStatus(action));
          }
        }
      }
    );

    if (isEmpty(data)) {
      return (
        <View
          style={{
            flex: 1,
            marginTop: Platform.OS === "web" ? 50 : 20,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <>
              <Image
                style={{
                  width: Platform.OS === "web" ? 400 : 250,
                  height: Platform.OS === "web" ? 400 : 250,
                }}
                resizeMode="cover"
                source={IMAGES["preparing-order-vase"]}
              />
              <Text
                style={{
                  width: 200,
                  textAlign: "center",
                  fontSize: 20,
                  fontWeight: "400",
                }}
              >
                {Localise(messages, "Buttercup is prepping your orders")}
              </Text>
            </>
          ) : (
            <Text
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "400",
                marginTop: 50,
              }}
            >
              {Localise(
                messages,
                "This request is taking too long to respond. Please"
              )}{" "}
              <Text
                style={{ textDecorationLine: "underline" }}
                onPress={() => {
                  getData();
                }}
              >
                {Localise(messages, "refresh")}
              </Text>{" "}
              {Localise(messages, "the page.")}
            </Text>
          )}
        </View>
      );
    }

    let filteredData = data;

    const ordersContent = (
      bucketsList,
      arrayHelpers,
      designCenterValues = {}
    ) => {
      return bucketsList.map((section) => {
        const { key, title, displayStatus = "close", countDownTimer } = section;

        if (
          !filteredData[key] ||
          (displayEligibleBucket.length && !displayEligibleBucket.includes(key))
        )
          return null;

        const { orders } = filteredData[key];

        let filteredOrders = orders;
        //Filtering the orders in the AssignedOrders bucket that are assigned to common designers when the user selects more than one shop in a multi-member shop
        if (dcMemberCodes.length > 1 && key === "assignedOrders") {
          filteredOrders = orders.filter((order) => {
            const designerId = order?.designer?.id || "";
            return filteredSuggestions.some(({ id }) => id === designerId);
          });
        }

        const openAccordion = displayEligibleBucket.includes(key)
          ? true
          : searchFiltered
          ? searchFiltered
          : filterActions?.appliedFilters?.length > 0 ||
            filterActions.orderIds?.length > 0
          ? true
          : displayStatus === "open";
        return (
          <Accordion
            key={key}
            title={`${Localise(messages, title)} (${filteredOrders.length})`}
            titleStyle={{
              ...fonts.heading3,
              fontWeight: "600",
              color: colors.primary,
            }}
            labelStyle={{
              marginVertical: 5,
              backgroundColor: backgroundColors.secondary,
              paddingHorizontal: 0,
            }}
            iconStyle={{
              color: colors.primary,
            }}
            defaultOpen={filteredOrders.length > 0 ? openAccordion : false}
            handleOnPress={() => {}}
            testID={title}
          >
            <>
              {filteredOrders
                .slice(0, viewMoreCounts[key])
                .map((order, index) => (
                  <React.Fragment key={index}>
                    <OrderListItem
                      order={cloneDeep(order)}
                      index={index}
                      submitAction={submitAction}
                      countDownTimer={countDownTimer}
                      columnsList={columnsList}
                      subColumnsList={subColumnsList}
                      actionsList={actionsList}
                      subActionsList={subActionsList}
                      selectedOrderId={recordId}
                      setRecordId={setRecordId}
                      setDeliveryMethod={setDeliveryMethod}
                      setSourceMemberCode={setSourceMemberCode}
                      isSmallScreen={isSmallScreen}
                      isMobile={isMobile}
                      listingType={listingType}
                      designersList={filteredSuggestions}
                      designCenterValues={designCenterValues}
                      arrayHelpers={arrayHelpers}
                    />
                  </React.Fragment>
                ))}
              {filteredOrders.length > 10 &&
                filteredOrders.length > viewMoreCounts[key] && (
                  <Button
                    titleStyle={theme.Button.secondaryTitleStyle}
                    buttonStyle={{
                      ...theme.Button.secondaryButtonStyle,
                      paddingHorizontal: 10,
                      paddingVertical: 6,
                      width: 95,
                      height: 35,
                    }}
                    containerStyle={{
                      alignItems: "flex-end",
                      marginHorizontal: 0,
                    }}
                    title={Localise(messages, "View More")}
                    onPress={() => {
                      setViewMoreCounts({
                        ...viewMoreCounts,
                        [key]: viewMoreCounts[key] + 10,
                      });
                    }}
                    testID="view_more"
                    accessibilityLabel="view_more"
                  />
                )}
            </>
          </Accordion>
        );
      });
    };

    const DesignAssignments = () => {
      const [displayScroll, setDisplayScroll] = useStateIfMounted(false);

      const [onChangeValidation, setOnChangeValidation] =
        useStateIfMounted(false);
      const [isLoading, setIsLoading] = useStateIfMounted(false);

      const assignedAndUnassignedOrders = data?.assignedOrders?.orders.concat(
        data?.unassignedOrders?.orders
      );

      const assignedOrders = assignedAndUnassignedOrders?.filter(
        (order) => !!order?.designer?.id
      );

      const designersDataMap = assignedOrders.reduce((map, order) => {
        const { designer: { name = "", id = "" } = {}, status } = order;

        if (!id) return map;

        //Displaying only active common designer tiles if multiple shops are selected.
        if (
          dcMemberCodes.length > 1 &&
          !filteredSuggestions.some((obj) => obj.id === id)
        ) {
          return map;
        }

        if (!map[id]) {
          const [firstName = "", lastName = ""] = name.split(" ");
          map[id] = {
            designerName: name,
            firstName,
            lastName,
            assigned: [],
            designed: [],
          };
        }

        const designerData = map[id];
        designerData.assigned.push(order);

        if (["DELIVERED", "DESIGNED", "OUT_FOR_DELIVERY"].includes(status)) {
          designerData.designed.push(order);
        }

        return map;
      }, {});
      const designersData = Object.values(designersDataMap);

      const { appliedFilters } = filterActions;
      let quickFilters = [];

      const onQuickFilterPress = (value) => {
        let designerValues = {
          value: [
            {
              title: value,
              value: value,
            },
          ],
        };

        const designerFilters = appliedFilters?.length
          ? Object.values(appliedFilters).filter((datat) => {
              return !Object.keys(datat).includes("FilterByDesignerName");
            })
          : appliedFilters;

        designerValues.value.map((each) => {
          const filterExists = quickFilters.some((filter) => {
            return (
              filter.FilterByDesignerName &&
              filter.FilterByDesignerName.value[0].title === each.title &&
              filter.FilterByDesignerName.value[0].value === each.value
            );
          });

          if (!filterExists) {
            quickFilters.push({
              ["FilterByDesignerName"]: {
                value: [
                  {
                    title: each.title,
                    value: each.value,
                  },
                ],
              },
            });
          }
        });
        dispatch(
          setActions({
            appliedFilters: [...quickFilters, ...designerFilters],
            filters: [...quickFilters, ...designerFilters],
          })
        );
      };

      const hasDesignerFilter = appliedFilters?.some((filter) =>
        has(filter, "FilterByDesignerName")
      );

      const scrollLayout = (
        <ScrollView
          contentContainerStyle={{
            flexDirection: "row",
            width: Platform.OS === "web" ? "100%" : "auto",
          }}
          horizontal={true}
          showsHorizontalScrollIndicator={!isSmallScreen ? displayScroll : true}
        >
          {designersData.map((batch, index) => {
            const {
              designerName = "",
              firstName = "",
              lastName = "",
              assigned = [],
              designed = [],
            } = batch;

            return (
              <View
                key={index}
                style={[
                  tw("flex justify-center items-start"),
                  {
                    height: 120,
                  },
                ]}
              >
                <View
                  style={[
                    tw("flex justify-between"),
                    {
                      height: 100,
                      width: 120,
                      marginLeft: 0,
                      marginBottom: 0,
                      paddingLeft: 10,
                      paddingVertical: 12,
                      borderRadius: 8,
                      borderWidth: 1,
                      marginHorizontal: 20,
                      borderColor: "#114258",
                      backgroundColor: "#AADEC633",
                    },
                  ]}
                >
                  <View>
                    <TouchableOpacity
                      style={{
                        textAlign: "left",
                        color: "#414141",
                        fontFamily: "HelveticaNeue",
                        fontSize: 10,
                      }}
                      onPress={() => onQuickFilterPress(designerName)}
                      disabled={hasDesignerFilter} //Restricting the user from clicking the designer tile again after clicking it once.
                      testID={`${designerName}_tile`}
                      accessibilityLabel={`${designerName}_tile`}
                    >
                      <Text
                        style={{
                          fontWeight: "600",
                          marginBottom: 6,
                        }}
                      >
                        {`${firstName}\n${lastName}`}
                      </Text>
                      <Text
                        style={{
                          marginBottom: 4,
                        }}
                      >
                        {`${assigned?.length} ${Localise(
                          messages,
                          "Assigned"
                        )}`}
                      </Text>
                      <Text>
                        {`${designed?.length} ${Localise(
                          messages,
                          "Designed"
                        )}`}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            );
          })}
        </ScrollView>
      );

      const assignedBucket = config.filter(
        ({ key }) => key === "assignedOrders"
      );
      const unAssignedBucket = config.filter(
        ({ key }) => key === "unassignedOrders"
      );

      //To get the values from session storage when the page is refreshed if the Design Center is enabled.
      const initialValues = useMemo(() => {
        const designCenterSelections = getValuesFromStorage();

        return {
          assignForDesign: designCenterSelections?.assignForDesign || "",
          designerAction: hasDesignerFilter
            ? ""
            : designCenterSelections?.designerAction || "assign-to-designer",
          bulkSelect: designCenterSelections?.bulkSelect || false,
          selectedOrders: designCenterSelections?.selectedOrders || [],
        };
      }, []);

      const validationSchema = Yup.object().shape({
        assignForDesign: Yup.string().required("Select designer"),
      });

      const formSubmitTrigger = async ({ values, setFieldValue }) => {
        setIsLoading(true);
        const { assignForDesign = "", designerAction, selectedOrders } = values;
        const timeStampLocal = moment().subtract(2, "minute").utc().format();
        const designerName =
          designersInfo?.find((obj) => obj.id === assignForDesign)?.fullName ||
          "";

        if (selectedOrders?.length > 0) {
          try {
            const fulfilledResults = [];
            // Processing each member's orders sequentially in a loop
            for (const member of dcMemberCodes) {
              const orders = selectedOrders.filter(
                (order) => order.memberCode === member
              );
              if (!isEmpty(orders)) {
                try {
                  const result = await request("assign-designer", {
                    designer: { id: assignForDesign, name: designerName },
                    orders: orders.map(({ memberCode, ...rest }) => rest),
                    memberCode: member,
                  });
                  fulfilledResults.push(...result);
                } catch (err) {
                  console.error(
                    `Failed to assign orders for member ${member}:`,
                    err
                  );
                  // Continue with the loop even if one request fails
                }
              }
            }

            if (fulfilledResults.length > 0) {
              fetchOrders(
                "current",
                { ...listingInfo, timeStamp: timeStampLocal },
                statusList,
                initData,
                fetchData
              );
              ToasterHandler(
                "success",
                Localise(
                  messages,
                  designerAction === "remove-designer"
                    ? `Designer has been removed from ${fulfilledResults.length} order(s).`
                    : `${fulfilledResults.length} order(s) have been assigned to a designer.`
                )
              );
              // Removing the updated orders (assigned/unassigned) from the selected orders.
              const updatedSelectedOrders = selectedOrders.filter(
                (selectedOrder) =>
                  !fulfilledResults.some(
                    (order) => order.orderItemId === selectedOrder.orderItemId
                  )
              );

              setFieldValue("selectedOrders", updatedSelectedOrders);
              setFieldValue("bulkSelect", false);
              setFieldValue("assignForDesign", "");

              const storageValues = getValuesFromStorage();
              setValuesToStorage("designCenterSelections", {
                ...storageValues,
                assignForDesign: "",
                selectedOrders: updatedSelectedOrders,
                bulkSelect: false,
              });
            } else {
              ToasterHandler(
                "uh oh",
                Localise(messages, ERROR_MESSAGES.GENERAL_ERROR)
              );
            }
          } catch (error) {
            console.error("Error during assignment:", error);
            ToasterHandler(
              "uh oh",
              Localise(messages, ERROR_MESSAGES.GENERAL_ERROR)
            );
          } finally {
            setIsLoading(false);
          }
        } else {
          ToasterHandler(
            "error",
            Localise(
              messages,
              designerAction === "remove-designer"
                ? "Cannot Unassign Designer on these orders."
                : "Complete missing order information before assigning to a designer"
            )
          );
          setIsLoading(false);
        }
      };

      const SelectAll = ({ values: { bulkSelect }, setFieldValue }) => (
        <CheckBox
          containerStyle={[
            theme.CheckBox.inputContainerStyle,
            { paddingRight: 3, paddingBottom: 10 },
          ]}
          size={18}
          checked={bulkSelect}
          checkedIcon={
            <Image
              source={IMAGES["checkbox-checked"]}
              style={{ width: 20, height: 20 }}
            />
          }
          uncheckedIcon={
            <Image
              source={IMAGES["checkbox-unchecked-dark"]}
              style={{ width: 20, height: 20 }}
            />
          }
          testID={"designer_checkbox"}
          accessibilityLabel={"designer_checkbox"}
          onPress={() => {
            if (bulkSelect) {
              setFieldValue("selectedOrders", []);
            }
            setFieldValue("bulkSelect", !bulkSelect);
          }}
        />
      );

      const renderContent = (contentText) => (
        <>
          {!isProcessing && (
            <Text
              style={{
                ...fonts.heading4,
                color: colors.red,
                marginTop: 12,
              }}
            >
              {Localise(messages, `${contentText}`)}
            </Text>
          )}
          {ordersContent(hasDesignerFilter ? assignedBucket : config)}
        </>
      );

      return (
        <View>
          {isEmpty(dcMemberCodes) ? (
            renderContent("Select a shop to manage design assignments")
          ) : isEmpty(filteredSuggestions) ? (
            renderContent(
              "No designers are available to assign orders for the selected shops. Please deselect one shop."
            )
          ) : (
            <>
              {!isEmpty(designersData) && (
                <Text
                  style={{
                    ...fonts.heading3,
                    fontWeight: "600",
                    color: colors.primary,
                    marginTop: 12,
                  }}
                >
                  {Localise(messages, "Design Assignments")}
                </Text>
              )}
              <>
                <View
                  onMouseEnter={() => !isSmallScreen && setDisplayScroll(true)}
                  onMouseLeave={() => !isSmallScreen && setDisplayScroll(false)}
                >
                  <View
                    style={{
                      paddingVertical: 0,
                      paddingHorizontal: isSmallScreen ? 5 : 0,
                      flexDirection: "column",
                    }}
                  >
                    {scrollLayout}
                  </View>
                </View>
                {dcMemberCodes.length > 1 && (
                  <Text
                    style={[
                      fonts.default,
                      tw(
                        `flex flex-wrap text-justify pb-3 ${
                          isEmpty(designersData) ? "pt-3" : ""
                        }`
                      ),
                    ]}
                  >
                    {Localise(
                      messages,
                      "Displaying designers for selected shops."
                    )}
                  </Text>
                )}
                <Form
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validateOnChange={onChangeValidation}
                  validateOnBlur={onChangeValidation}
                  render={(formikBag) => {
                    const { values, submitCount, setFieldValue } = formikBag;
                    const {
                      designerAction = "",
                      assignForDesign = "",
                      selectedOrders,
                      bulkSelect = false,
                    } = values;

                    const isDisabled =
                      !designerAction ||
                      (designerAction === "remove-designer" &&
                        isEmpty(selectedOrders)) ||
                      (!assignForDesign &&
                        designerAction === "assign-to-designer") ||
                      isEmpty(selectedOrders);

                    const buckets = hasDesignerFilter
                      ? assignedBucket
                      : designerAction === "assign-to-designer"
                      ? unAssignedBucket
                      : designerAction === "remove-designer"
                      ? assignedBucket
                      : config;

                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                      if (submitCount > 0) {
                        setOnChangeValidation(true);
                      }
                    }, [submitCount]);

                    //To store the Design Center form values in session storage.
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                      let selections = values;
                      if (
                        designerAction === "remove-designer" ||
                        !designerAction
                      ) {
                        selections.assignForDesign = "";
                      }
                      setValuesToStorage("designCenterSelections", selections);
                    }, [assignForDesign, selectedOrders, bulkSelect]);

                    return (
                      <View style={tw("flex flex-col")}>
                        <View
                          style={
                            isSmallScreen
                              ? tw("flex")
                              : tw("flex flex-row items-center")
                          }
                          testID={"assign_for_design"}
                        >
                          {!isSmallScreen && (
                            <SelectAll
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          )}
                          <FormFieldPicker
                            placeholder={{
                              label: "Select",
                              value: "select",
                            }}
                            containerStyle={{
                              width: isSmallScreen ? "100%" : "20%",
                              paddingTop: 7,
                            }}
                            data={designerActions}
                            name="designerAction"
                            label=""
                            defaultValue={""}
                            onChange={(action) => {
                              setValuesToStorage(
                                "designCenterSelections.designerAction",
                                action
                              );
                              // Clearing bulk select and selected orders if we change the dropdown value when no designer is selected.
                              if (!hasDesignerFilter) {
                                setFieldValue("selectedOrders", []);
                                setFieldValue("bulkSelect", false);
                                const storageValues = getValuesFromStorage();
                                setValuesToStorage("designCenterSelections", {
                                  ...storageValues,
                                  bulkSelect: false,
                                  selectedOrders: [],
                                });
                                setViewMoreCounts(initViewMoreCounts);
                              }
                            }}
                          />
                          {designerAction === "assign-to-designer" && (
                            <FormFieldPicker
                              placeholder={{
                                label: "Select",
                                value: "select",
                              }}
                              containerStyle={{
                                width: isSmallScreen ? "100%" : "20%",
                                paddingLeft: 5,
                                paddingTop: 7,
                              }}
                              data={listOfDesigners}
                              name="assignForDesign"
                              label=""
                              defaultValue={""}
                            />
                          )}
                          <Button
                            title={Localise(messages, "Apply")}
                            onPress={() => formSubmitTrigger(formikBag)}
                            testID={"Apply"}
                            accessibilityLabel={"Apply"}
                            disabled={isDisabled}
                            loading={isLoading}
                          />
                          {isSmallScreen && (
                            <SelectAll
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          )}
                        </View>
                        {bulkSelect && (
                          <View>
                            <Text style={[fonts.default, tw("text-justify")]}>
                              {Localise(
                                messages,
                                "This action applies only to the orders visible below. Click on view more to add more orders"
                              )}
                            </Text>
                          </View>
                        )}
                        <FieldArray
                          name="selectedOrders"
                          render={(arrayHelpers) =>
                            ordersContent(buckets, arrayHelpers, values)
                          }
                        />
                      </View>
                    );
                  }}
                />
              </>
            </>
          )}
        </View>
      );
    };

    const dateRange = !isCustomRangeApplied
      ? `( ${moment(minDeliveryDate).format("MM/DD/YYYY")} - ${moment(
          maxDeliveryDate
        ).format("MM/DD/YYYY")} ) `
      : "";
    const isOrdersPage = ["current", "completed"].includes(listingType);

    const Content = () => {
      return (
        <View
          style={{
            maxWidth: isSmallScreen ? "100%" : 600,
            maxHeight: isSmallScreen ? "100%" : 400,
            margin: "auto",
            backgroundColor: backgroundColors.secondary,
            borderColor: "rgba(0, 0, 0, 0.1)",
          }}
        >
          <View
            style={[
              tw("p-3 flex flex-row items-center justify-between"),
              { backgroundColor: backgroundColors.primary },
            ]}
          >
            <Text style={{ ...fonts.heading1, ...tw("text-white") }}>
              {Localise(messages, "Order Is Unpaid")}
            </Text>
          </View>
          <View style={isSmallScreen ? {} : tw("mx-20")}>
            <Text style={[tw("p-5"), { fontSize: 14 }]}>
              {Localise(messages, "Do you want to complete the payment?")}
            </Text>
          </View>
        </View>
      );
    };

    const modalContent = {
      content: <Content />,
      buttons: [
        {
          type: "primary",
          title: Localise(messages, "Ok"),
        },
        { type: "secondary", title: Localise(messages, "Cancel") },
      ],
    };

    const isLocalOrder = [
      "FLORIST_DELIVERED",
      "STORE_PICKUP",
      "WALK_IN",
    ].includes(
      selectedOrderDetails?.deliveryMethod || deliveryInfo?.deliveryMethod
    );

    const handleProcessPayment = () => {
      dispatch(setEditOrder());
      isLocalOrder &&
        request("lock-order", {
          recordId: selectedOrderDetails?.recordId || orderItemId,
          deliveryMethod:
            selectedOrderDetails?.deliveryMethod ||
            deliveryInfo?.deliveryMethod,
        });
      navigation.dispatch(
        CommonActions.navigate({
          name: "create-order",
          params: {
            id: selectedOrderDetails?.recordId || orderItemId,
            action: "edit",
            smc: selectedOrderDetails?.sourceMemberCode || sourceMemberCode,
            dm:
              selectedOrderDetails?.deliveryMethod ||
              deliveryInfo?.deliveryMethod,
          },
        })
      );
      dispatch(setCurrentPage("create-order"));
      dispatch(setShowOrderUnpaidModal(false));
    };
    const onCancel = () => {
      dispatch(setShowOrderUnpaidModal(false));
    };

    return (
      <View style={{ marginVertical: 10 }}>
        {hasMoreOrders ? (
          <View style={tw("flex flex-row items-center")}>
            <Text style={[fonts.default, { fontSize: 14 }]}>
              {`Showing max results ${dateRange}– adjust search criteria or date range to refine your results`}
            </Text>
          </View>
        ) : (
          isOrdersPage &&
          !isCustomRangeApplied && (
            <View style={tw("flex flex-row items-center")}>
              <Text style={[fonts.default, { fontSize: 14 }]}>
                Showing results from{" "}
              </Text>
              <Text style={{ fontWeight: "500" }}>
                {moment(minDeliveryDate).format("MM/DD/YYYY")}
              </Text>
              {!!maxDeliveryDate && (
                <>
                  <Text> to </Text>
                  <Text style={{ fontWeight: "500" }}>
                    {moment(maxDeliveryDate).format("MM/DD/YYYY")}
                  </Text>
                </>
              )}
            </View>
          )
        )}
        {showDesignersSection ? <DesignAssignments /> : ordersContent(config)}
        {showOrderUnpaidModal && (
          <CustomModal
            modalVisible={showOrderUnpaidModal}
            modalContent={modalContent}
            primaryhandler={() => handleProcessPayment()}
            secondaryhandler={() => onCancel()}
            contentStyle={{ backgroundColor: "white" }}
            modalStyle={
              Platform.OS !== "web"
                ? {
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    backgroundColor: "#00000070",
                    color: backgroundColors.secondary,
                  }
                : { padding: 0 }
            }
            isReduceBtnSpacing={true}
          ></CustomModal>
        )}
      </View>
    );
  }
);

// eslint-disable-next-line react/display-name
export const OrderListItem = React.memo(
  ({
    order,
    submitAction,
    countDownTimer,
    columnsList,
    subColumnsList,
    actionsList,
    subActionsList,
    selectedOrderId,
    setRecordId,
    setDeliveryMethod,
    setSourceMemberCode,
    isSmallScreen,
    isMobile,
    listingType,
    index,
    data = {},
    setCurrentCustomer,
    setOrderMeatBallAction,
    designersList,
    setMeatBallMenuActions,
    readOnlyTile = false,
    removeTile = false,
    removeTileById,
    designCenterValues = {},
    arrayHelpers,
  }) => {
    const navigation = useNavigation();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();
    const { permissions } = useContext(AppSettingsContext);

    //Getting Design Center form state values.
    const { selectedOrders = [], bulkSelect = false } = designCenterValues;

    const showDesignersSection = useSelector(selectIsDesignersAreaEnabled);

    const redirectToCreateOrder = (response = {}) => {
      const {
        orderItemId = "",
        receivingMember = {},
        sendingMember = {},
        direction = "",
        deliveryInfo = {},
      } = response?.orderItems[0];

      const sourceMemberCode =
        direction === "INBOUND"
          ? receivingMember?.memberCode
          : sendingMember?.memberCode;
      const { deliveryMethod = "" } = deliveryInfo;

      dispatch(setCurrentPage("create-order"));
      navigation.dispatch(
        CommonActions.navigate({
          name: "create-order",
          params: {
            id: orderItemId,
            action: "copy",
            smc: sourceMemberCode,
            dm: deliveryMethod,
          },
        })
      );
    };
    // Sending data object from listing-new (customer profile)
    if (!isEmpty(data)) {
      const profileMemberCodes = UserProfileStorage.getProfileMemberCodes();
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { messages, Localise } = useContext(I18NContext);
      order = cloneDeep(data);
      const {
        deliveryMethod = "",
        sendingMember: { memberCode: sendingCode = "" },
        receivingMember: { memberCode: receivingCode = "" },
      } = order;
      const code = [
        "MOL_FLORIST_DELIVERED",
        "MOL_CUSTOMER_PICKUP",
        "FOL_FLORIST_DELIVERED",
      ].includes(deliveryMethod)
        ? receivingCode
        : sendingCode;
      const isActionAllowed = profileMemberCodes.includes(code);
      setRecordId = (id) =>
        isActionAllowed
          ? dispatch(setCurrentCustomer({ type: "orderId", value: id }))
          : ToasterHandler(
              "error",
              Localise(
                messages,
                "Action not allowed, Order Details unavailable for this membercode"
              )
            );
      submitAction = (e, d, recordId) =>
        isActionAllowed
          ? dispatch(
              setOrderMeatBallAction({
                type: [
                  "event",
                  "recordId",
                  "hasPendingPayLaterPayment",
                  "deliveryMethod",
                  "sourceMemberCode",
                  "messages",
                  "Localise",
                  "redirectToCreateOrder",
                  "dispatch",
                ],
                value: [
                  e,
                  recordId,
                  hasPendingPayLaterPayment,
                  deliveryMethod,
                  sourceMemberCode,
                  messages,
                  Localise,
                  redirectToCreateOrder,
                  dispatch,
                ],
              })
            )
          : ToasterHandler(
              "error",
              Localise(
                messages,
                "Action not allowed, Order Details unavailable for this membercode"
              )
            );
    }

    const {
      countDownEndTime,
      countDownPauseTime,
      orderItemId: recordId,
      omsOrderId = "",
      deliveryDate,
      direction = "",
      countDownTimerEndTime,
      fillerMemberCode,
      memberTimeZones,
      sourceMemberCode,
      isPickupOrder,
      storePickupDateTime,
      dsEligible = false,
      latestDSRequestedStatus = "",
      autoSendToDS,
      orderSource,
      deliveryMethod,
      hasDSFulfillmentError = false,
      hasFulfillmentError = false,
      hasSettlementError = false,
      hasPendingPayLaterPayment = false,
      status,
      receivingMember = {},
      sendingMember = {},
      isInvoiceDetailsPrinted,
      isTrifoldDetailsPrinted,
      isDesignerWorkSheetPrinted,
      isPrinted,
    } = order;

    const shopCode =
      direction === "INBOUND"
        ? receivingMember?.memberCode
        : sendingMember?.memberCode;

    const isDSSubmitEligible =
      dsEligible &&
      autoSendToDS &&
      !["SUCCESS", "REROUTING"].includes(latestDSRequestedStatus);

    const [timeLeftInMins, setTimeLeftInMins] = useStateIfMounted(
      countDownTimerEndTime
        ? getTimeLeftInMins(
            countDownTimerEndTime,
            memberTimeZones[fillerMemberCode]
          )
        : !countDownTimerEndTime
        ? getTimeLeftInMins(countDownEndTime, memberTimeZones[fillerMemberCode])
        : 0
    );
    const [pickupTimeLeftInMins, setPickupTimeLeftInMins] = useStateIfMounted(
      isPickupOrder ? getPickupTimeLeftInMins(storePickupDateTime) : 0
    );

    const expiringTimeInterval = Environment.get(
      "ORDER_ACCEPTANCE_EXPIRING_INTERVAL",
      10
    );

    if (
      (countDownTimerEndTime || countDownEndTime) &&
      timeLeftInMins < expiringTimeInterval &&
      !isPickupOrder
    ) {
      if (timeLeftInMins > 0) order.internalStatusOverride = "expiring";
      else if (order.status === "FORFEITED")
        order.internalStatusOverride = "expired";

      order.displayOrderStatus =
        order.internalStatusOverride === "expired" ? "Forfeited" : "Hurry!";
      order.statusColor = orderStatusColorCodes.expiring;
      order.imageName =
        order.numOfUnreadChatMessages > 0
          ? "expiring-chat"
          : order.internalStatusOverride === "expired"
          ? "forfeited-incoming"
          : "expiring";
    }

    if (isPickupOrder && !hasSettlementError) {
      //Adding the below condition as order tile state is not getting updated when coming from Dashboard
      if (
        (getPickupTimeLeftInMins(storePickupDateTime) < 31 &&
          (orderSource === "DoorDash" ||
            orderSource === "UberEats" ||
            deliveryMethod === "MOL_CUSTOMER_PICKUP") &&
          [
            "NEW",
            "VIEWED",
            "PRINTED",
            "ACKNOWLEDGE_PRINT",
            "ACKNOWLEDGED",
          ].includes(order.status)) ||
        (pickupTimeLeftInMins < 31 &&
          ["NEW", "VIEWED", "PRINTED"].includes(order.status))
      ) {
        order.displayOrderStatus = "Pickup!";
        order.statusColor = orderStatusColorCodes.expiring;
        order.imageName = "expiring";
      } else if (["NEW", "VIEWED", "PRINTED"].includes(order.status)) {
        order.displayOrderStatus = "Pickup";
        order.statusColor = orderStatusColorCodes.incoming;
        order.imageName = "incoming";
      }
    }

    const dsRerouteInProgress = orderDsRequestRerouting(
      pick(order, ["latestDSRequestedStatus"])
    );

    const isSelectedRecord = selectedOrderId === recordId;

    const hasDesignCenter = hasDesignCenterFeature(permissions, shopCode);

    // Resetting actions list for non-standard or non-premium user
    const coreActionsList = {};

    if (shopCode?.length && !hasDesignCenter) {
      // Removing "designed" objects from the each status array
      Object.values(actionsList).forEach((value, index) => {
        const updatedArray = value.filter(
          (item) => item.key !== "designed" && item.key !== "designed-pickup"
        );
        coreActionsList[Object.keys(actionsList)[index]] = updatedArray;
      });
    }

    const finalActionsList = hasDesignCenter ? actionsList : coreActionsList;
    const actions = get(
      finalActionsList,
      isPickupOrder
        ? getOrderInternalStatus(order, isPickupOrder)
        : direction === "INBOUND" || deliveryMethod === "PHONE_OUT"
        ? hasDSFulfillmentError || dsRerouteInProgress
          ? "dsfullfillmenterror"
          : order.status !== "FORWARDED"
          ? hasSettlementError
            ? []
            : order.internalStatus === "in-design"
            ? "design"
            : order.internalStatus
          : []
        : order.internalStatus !== "rejected" &&
          order.internalStatus !== "cancelled"
        ? "outgoing"
        : "",
      []
    );
    if (
      order.numOfUnreadChatMessages > 0 ||
      order.numOfUnreadStructuredResponses > 0
    )
      order.internalSubStatus = "view-chats";
    else if (order.numOfReadChatMessages > 0)
      order.internalSubStatus = "review-chats";
    const orderSubActions = get(subActionsList, order.internalSubStatus, []);
    const subActions = cloneDeep(orderSubActions);

    const isLocalOrder = [
      "FLORIST_DELIVERED",
      "STORE_PICKUP",
      "WALK_IN",
      "PHONE_OUT",
    ].includes(deliveryMethod);

    const assignDesigner = ![
      "DELIVERED",
      "DESIGNED",
      "OUT_FOR_DELIVERY",
    ].includes(status);

    if (
      order.internalStatusOverride === "replace-filler" ||
      hasDSFulfillmentError ||
      hasSettlementError ||
      hasFulfillmentError
    ) {
      const markAsUnreadIndex = subActions.findIndex(
        (action) => action.key === "markAsUnread"
      );
      subActions.splice(markAsUnreadIndex, 1);
    }
    let showAdjustmentaction =
      moment().isBefore(moment(order.deliveryDate).add(90, "days")) &&
      moment(order.deliveryDate).month() !== moment().month();

    const rejectAction =
      [
        "incoming-pickup",
        "accepted-pickup",
        "design-pickup",
        "designed-pickup",
        "delivery-pickup",
      ].includes(order.internalStatus) &&
      !isLocalOrder &&
      orderSource !== "MOL"
        ? [{ key: "reject", label: "Reject" }]
        : [];

    const adjustmentAction =
      showAdjustmentaction &&
      order.direction === "OUTBOUND" &&
      order.deliveryMethod !== "PHONE_OUT" &&
      !["CANCELLED", "REJECTED", "FORFEITED", "ERROR"].includes(order.status)
        ? [{ key: "adjustment", label: "Adjustment" }]
        : [];

    const isGiftCard = ["DIGITAL_GIFT_CARDS", "PHYSICAL_GIFT_CARDS"].includes(
      order.giftCardType
    );

    const cancelAction =
      ["FLORIST_DELIVERED", "STORE_PICKUP", "WALK_IN", "PHONE_OUT"].includes(
        deliveryMethod
      ) &&
      order.status !== "CANCELLED" &&
      isLocalOrder &&
      !isGiftCard
        ? [{ key: "cancel", label: "Cancel" }]
        : [];

    const hasDesigner = order?.designer && !!order?.designer?.id;
    const assignToDesigner =
      direction === "INBOUND" &&
      hasDesignCenter &&
      ["accepted", "in-design", "accepted-pickup", "design-pickup"].includes(
        order.internalStatus
      ) &&
      !hasDesigner &&
      !hasSettlementError
        ? [{ key: "assign-to-designer", label: "Assign to Designer" }]
        : [];

    let finalActions = cloneDeep(actions);

    if (
      orderSource === "MOL" ||
      deliveryMethod === "FLORIST_DELIVERED" ||
      deliveryMethod === "PHONE_OUT"
    ) {
      const rejectActionIndex = finalActions.findIndex(
        (action) => action.key === "reject"
      );

      if (rejectActionIndex !== -1) finalActions.splice(rejectActionIndex, 1);

      // For MOL_CUSTOMER_PICKUP orders orderSource will be MOL
      if (
        deliveryMethod === "MOL_CUSTOMER_PICKUP" &&
        ["NEW", "VIEWED", "PRINTED"].includes(order.status)
      ) {
        // Adding accept button
        // finalActions.unshift({ key: "accept", label: "Accept" });
        // Removing below 3 actions as order is not accepted. Doing it here as we don't have config for unaccepted pickup orders.
        finalActions = finalActions.filter(
          (each) =>
            ![
              "accept-print",
              "design",
              "designed",
              "out-for-delivery",
              "delivery-confirmation",
              "delivery-exception",
            ].includes(each.key)
        );
      }
    } else if (orderSource === "DoorDash" || orderSource === "UberEats") {
      const copySaleIndex = finalActions.findIndex(
        (action) => action.key === "copy-order"
      );
      if (copySaleIndex !== -1) finalActions.splice(copySaleIndex, 1);
    }

    const dsErrorAction =
      direction === "INBOUND" &&
      (hasDSFulfillmentError || dsRerouteInProgress) &&
      order.status !== "DELIVERED"
        ? [{ key: "delivery-confirmation", label: "Confirm Delivery" }]
        : [];

    const settlementAction =
      hasSettlementError ||
      ["CANCELLED", "REJECTED", "FORFEITED"].includes(order.status)
        ? [{ key: "copy-order", label: "Copy Order" }]
        : [];

    const menuOptions = finalActions.concat(
      subActions,
      adjustmentAction,
      rejectAction,
      cancelAction,
      dsErrorAction,
      assignToDesigner,
      settlementAction
    );

    useEffect(() => {
      setMeatBallMenuActions &&
        dispatch(
          setMeatBallMenuActions({
            recordId,
            menuOptions,
            hasPendingPayLaterPayment,
          })
        );
    }, []);

    const printOrderOption = [
      {
        key: "printOrder",
        label: order.isPrinted ? "Reprint Order" : "Print Order",
      },
    ];

    //Adding printOrder instead of config as there are many statuses in completed orders screen

    //To check whether an order is selected in the Design Center.
    const isOrderSelectedinDC = (id) =>
      selectedOrders.some((item) => item.orderItemId === id);

    const selectedOrdersIndex = (id) =>
      selectedOrders.findIndex((item) => item.orderItemId === id);

    //To add the selected order to the Design Center form state.
    const onSelect = () => {
      if (isOrderSelectedinDC(recordId)) {
        arrayHelpers?.remove(selectedOrdersIndex(recordId));
      } else {
        arrayHelpers?.push({
          orderId: omsOrderId,
          orderItemId: recordId,
          deliveryMethod: deliveryMethod,
          // Adding the key to differentiate between the orders in case the user selects more than one shop.
          memberCode: receivingMember?.memberCode,
        });
      }
    };
    useEffect(() => {
      if (assignDesigner) {
        if (bulkSelect) {
          if (!isOrderSelectedinDC(recordId)) {
            arrayHelpers?.push({
              orderId: omsOrderId,
              orderItemId: recordId,
              deliveryMethod: deliveryMethod,
              // Adding the key to differentiate between the orders in case the user selects more than one shop.
              memberCode: receivingMember?.memberCode,
            });
          }
        }
      }
    }, [bulkSelect]);

    return (
      <React.Fragment>
        <View style={tw("flex flex-row items-center")}>
          {showDesignersSection &&
            // Showing checkbox to bulk assign orders to a designer
            !isEmpty(designersList) && (
              <CheckBox
                key={index}
                containerStyle={[
                  theme.CheckBox.inputContainerStyle,
                  tw("flex flex-row items-center"),
                  { paddingRight: 3, paddingBottom: 10 },
                ]}
                size={18}
                checked={
                  assignDesigner && (isOrderSelectedinDC(recordId) || false)
                }
                checkedIcon={
                  <Image
                    source={IMAGES["checkbox-checked"]}
                    style={{ width: 20, height: 20 }}
                  />
                }
                uncheckedIcon={
                  <Image
                    source={
                      assignDesigner
                        ? IMAGES["checkbox-unchecked-dark"]
                        : IMAGES["checkbox-unchecked"]
                    }
                    style={{ width: 20, height: 20 }}
                  />
                }
                disabled={!assignDesigner}
                testID={"designer_checkbox"}
                accessibilityLabel={"designer_checkbox"}
                onPress={onSelect}
              />
            )}
          <ListItem
            item={cloneDeep(order)}
            itemKey={"orderItemId"}
            isSelectedRecord={isSelectedRecord}
            isSmallScreen={isSmallScreen}
            isMobile={isMobile}
            columnsList={columnsList}
            subColumnsList={subColumnsList}
            recordId={recordId}
            setRecordId={setRecordId}
            setDeliveryMethod={setDeliveryMethod}
            setSourceMemberCode={setSourceMemberCode}
            submitAction={(e) =>
              submitAction(
                e,
                deliveryDate,
                recordId,
                deliveryMethod,
                sourceMemberCode,
                isPickupOrder,
                isDSSubmitEligible,
                hasPendingPayLaterPayment
              )
            }
            targetTime={countDownTimerEndTime || countDownEndTime}
            showCountDownTimer={countDownTimer} //removed timeLeftInMins check as we doing in CountDownTimer component where actual state is being set
            pauseCountDownTimer={!countDownTimerEndTime}
            countDownPauseTime={countDownPauseTime}
            setTimeLeftInMins={setTimeLeftInMins}
            pickupTimeLeftInMins={pickupTimeLeftInMins}
            setPickupTimeLeftInMins={setPickupTimeLeftInMins}
            menuOptions={
              (order.status !== "NEW" && order.status !== "VIEWED") ||
              isPickupOrder
                ? [...menuOptions, ...printOrderOption]
                : menuOptions
            }
            listingType={listingType}
            index={index}
            readOnly={readOnlyTile}
            removeTile={removeTile}
            removeTileById={removeTileById}
            getExtendedMenuOptions={getExtendedMenuOptions(
              isPrinted,
              isDesignerWorkSheetPrinted,
              isTrifoldDetailsPrinted,
              isInvoiceDetailsPrinted,
              true,
              sourceMemberCode,
              false,
              [],
              [],
              listingType === "customerOrders" ? false : true
            )}
          />
        </View>
      </React.Fragment>
    );
  }
);

export default OrdersList;
