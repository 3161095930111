import { select, call, put, takeLatest } from "redux-saga/effects";
import { request } from "library/utils/request";
import { setInit, setSideCar, setSideCarTitle } from "../common/slice";

import {
  setCatalogs,
  setCurrentCatalog,
  setMemberMappings,
  setError,
  fetchSharedCatalogs,
  fetchCatalogMemberMappings,
  saveCatalogMemberMappings,
  setAction,
  setFilteredMemberMappings,
} from "./slice";
import {
  selectMemberMappings,
  selectMembersSelected,
  selectActions,
  selectCurrentCatalog,
  selectCatalogLookup,
} from "./selector";

import get from "lodash/get";
import uniq from "lodash/uniq";
import orderBy from "lodash/orderBy";
import toLower from "lodash/toLower";
import difference from "lodash/difference";
import intersection from "lodash/intersection";
import { selectEnableSharedCatalogs } from "../common/selector";

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
function* handleInit(action = {}) {
  const enableSharedCatalogs = yield select(selectEnableSharedCatalogs);
  const { userRole = "" } = action.payload;
  const CustomerServiceRole = "FTD_CSR";

  if (enableSharedCatalogs && userRole !== CustomerServiceRole) {
    yield put(fetchSharedCatalogs());
    yield put(fetchCatalogMemberMappings());
  }
}

function* handleSharedCatalogs() {
  const serviceRequest = (params) => request("get-shared-catalogs", params);

  try {
    const response = yield call(serviceRequest, {
      shopCode: "mol",
    });
    const finalResponse = orderBy(
      response.map((x) => {
        return {
          ...x,
          name: x.name["en-US"],
          imageUrl:
            x.imageUrl && x.imageUrl.includes("cdn.shopify.com")
              ? x.imageUrl
                  .replace(".jpg", "_preset_mol-mx-tile-wide-sv-new_160x.jpg")
                  .replace(".jpeg", "_preset_mol-mx-tile-wide-sv-new_160x.jpeg")
              : x.imageUrl,
        };
      }),
      (e) =>
        e.groupId === "my_catalog" ? 0 : e.groupId === "global_catalog" ? 1 : 2,
      "asc"
    );
    if (!response) throw "error";

    yield put(setCatalogs(finalResponse));
  } catch (error) {
    yield put(setError("something went wrong"));
  }
}

function* handleFetchCatalogMemberMappings() {
  const serviceRequest = (params) =>
    request("get-shared-catalog-member-mappings", params);

  try {
    const response = yield call(serviceRequest, {
      shopCode: "mol",
    });
    if (!response && !response.length) throw "error";

    const catalogLookup = yield select(selectCatalogLookup);
    const results = processCatalogMemberMappings(response, catalogLookup);
    yield put(setMemberMappings(results));
    yield call(handleMemberListingUIRefresh);
  } catch (err) {
    yield put(setError("something went wrong"));
  }
}

function* handleCatalogSelection(action = {}) {
  const { name } = get(action, "payload", {});

  yield put(setSideCar("shared-catalog-details"));
  yield put(setSideCarTitle(`${name} Details`));
}

function* handleMemberListingUIRefresh() {
  try {
    const actions = yield select(selectActions);
    const memberMappings = yield select(selectMemberMappings);
    const { groupId: selectedCatalog, isRestricted } = yield select(
      selectCurrentCatalog
    );
    const selectedMembers = yield select(selectMembersSelected);

    if (isRestricted) {
      const results = applyPageActions(
        memberMappings,
        actions,
        selectedCatalog,
        selectedMembers
      );
      yield put(setFilteredMemberMappings(results));
    }
  } catch (err) {
    yield put(setError("something went wrong"));
  }
}

const processCatalogMemberMappings = (data, catalogLookup) => {
  return data.map((e) => ({
    allowedCatalogs:
      e.allowedCatalogs && e.allowedCatalogs.length
        ? uniq(intersection(e.allowedCatalogs, Object.keys(catalogLookup)))
        : [],
    memberCode: e.memberCode || "",
    businessName: e.businessName || "",
    packageInfo: e.packageInfo || "",
  }));
};

const applyPageActions = (
  data,
  { searchText = "", filters = [] },
  selectedCatalog,
  selectedMembers
) => {
  const results = orderBy(
    data.filter((entry) => {
      let matchesFilter = true;

      if (searchText) {
        if (
          toLower(entry.businessName).includes(toLower(searchText)) ||
          toLower(entry.memberCode).includes(toLower(searchText))
        )
          matchesFilter = true;
        else matchesFilter = false;
      }

      if (matchesFilter && filters.length) {
        const filterValues = filters.map((e) => e.value);

        if (
          matchesFilter &&
          (filterValues.includes("core") ||
            filterValues.includes("standard") ||
            filterValues.includes("premium") ||
            filterValues.includes("essentials"))
        ) {
          if (
            filterValues.includes("core") &&
            entry.packageInfo.includes("MHQ CORE")
          )
            matchesFilter = true;
          else if (
            filterValues.includes("standard") &&
            entry.packageInfo.includes("MHQ STANDARD")
          )
            matchesFilter = true;
          else if (
            filterValues.includes("premium") &&
            entry.packageInfo.includes("MHQ PREMIUM")
          )
            matchesFilter = true;
          else if (
            filterValues.includes("essentials") &&
            entry.packageInfo.includes("MHQ ESSENTIALS")
          )
            matchesFilter = true;
          else matchesFilter = false;
        }

        if (
          matchesFilter &&
          (filterValues.includes("enabled") ||
            filterValues.includes("notEnabled"))
        ) {
          if (
            filterValues.includes("enabled") &&
            selectedMembers.includes(entry.memberCode)
          )
            matchesFilter = true;
          else if (
            filterValues.includes("notEnabled") &&
            !selectedMembers.includes(entry.memberCode)
          )
            matchesFilter = true;
          else matchesFilter = false;
        }
      }

      return matchesFilter;
    }),
    ["businessName"],
    "asc"
  );

  return [
    ...results.filter((e) => e.allowedCatalogs.includes(selectedCatalog)),
    ...results.filter((e) => !e.allowedCatalogs.includes(selectedCatalog)),
  ];
};

function* handleSaveCatalogMemberMappings(action = {}) {
  const { resolve, reject } = get(action, "payload", {});

  const serviceRequest = (params) =>
    request("update-shared-catalog-member-mappings", params);

  try {
    const { groupId: selectedCatalog } = yield select(selectCurrentCatalog);
    const selectedMembers = yield select(selectMembersSelected);
    const memberMappings = yield select(selectMemberMappings);

    const prevSelectedMembersData = memberMappings.filter((m) =>
      m.allowedCatalogs.includes(selectedCatalog)
    );
    const prevSelectedMembers = prevSelectedMembersData.map(
      (m) => m.memberCode
    );

    const addCatalogToMembers = difference(
      selectedMembers,
      prevSelectedMembers
    );

    const removeCatalogFromMembers = difference(
      prevSelectedMembers,
      selectedMembers
    );

    let payload = [];

    memberMappings.forEach((m) => {
      if (addCatalogToMembers.includes(m.memberCode)) {
        payload.push({
          ...m,
          allowedCatalogs: [...m.allowedCatalogs, selectedCatalog],
        });
      }

      if (removeCatalogFromMembers.includes(m.memberCode)) {
        const final = m.allowedCatalogs.filter((c) => c !== selectedCatalog);
        payload.push({
          ...m,
          allowedCatalogs: final.length ? final : ["."],
        });
      }
    });

    yield call(serviceRequest, { shopCode: "mol", payload });

    yield put(fetchCatalogMemberMappings());

    resolve && resolve();
  } catch (err) {
    reject && reject();
  }
}

/**
 * Will be using when integrating UI with backend
 */
export function* watchSaga() {
  yield takeLatest(setInit.type, handleInit);
  yield takeLatest(setCurrentCatalog.type, handleCatalogSelection);
  yield takeLatest(fetchSharedCatalogs.type, handleSharedCatalogs);
  yield takeLatest(
    fetchCatalogMemberMappings.type,
    handleFetchCatalogMemberMappings
  );
  yield takeLatest(
    saveCatalogMemberMappings.type,
    handleSaveCatalogMemberMappings
  );
  yield takeLatest(setAction.type, handleMemberListingUIRefresh);
  yield takeLatest(setCurrentCatalog.type, handleMemberListingUIRefresh);
}

export default watchSaga;
