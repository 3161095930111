export const giftCardProducts = [
  "PGC",
  "DGC",
  "GIFT_CARDS",
  "GIFT_CARDD",
  "GIFT_CARDP",
  "GIFT_CARDE",
];

export const digitalGiftCardProducts = [
  "DGC",
  "GIFT_CARDS",
  "GIFT_CARDD",
  "GIFT_CARDP",
  "GIFT_CARDE",
];

export const physicalGiftCardProducts = ["PGC"];
