import qs from "query-string";

export const getRouteDetails = () => {
  if (window) {
    return {
      pathArray: window?.location?.pathname.split("/") || [],
      params: qs.parse(window?.location?.search),
    };
  }
};

export const getRouteScreenName = () => {
  if (window) {
    const pathArray = window?.location?.pathname.split("/");
    return pathArray.length > 1 && pathArray[1];
  }
};
