import React, { useMemo } from "react";
import { Switch } from "components/elements";

const FormFieldSwitch = ({
  value,
  onValueChange,
  disabled = false,
  circleSize = 16,
  backgroundActiveColor = "#164158",
  backgroundInactiveColor = "#C7C7C7",
  circleActiveColor = "#ffffff",
  circleInActiveColor = "#ffffff",
  testID = "",
  accessibilityLabel = "",
}) => {
  return useMemo(() => {
    return (
      <Switch
        value={value}
        onValueChange={onValueChange}
        disabled={disabled}
        circleSize={circleSize}
        backgroundActive={backgroundActiveColor}
        backgroundInactive={backgroundInactiveColor}
        circleActiveColor={circleActiveColor}
        circleInActiveColor={circleInActiveColor}
        changeValueImmediately={true} // if rendering inside circle, change state immediately or wait for animation to complete
        renderActiveText={false}
        renderInActiveText={false}
        switchLeftPx={1.25} // denominator for logic when sliding to TRUE position. Higher number = more space from RIGHT of the circle to END of the slider
        switchRightPx={1.25} // denominator for logic when sliding to FALSE position. Higher number = more space from LEFT of the circle to BEGINNING of the slider
        switchWidthMultiplier={2.5} // multipled by the `circleSize` prop to calculate total width of the Switch
        switchBorderRadius={30} // Sets the border Radius of the switch slider. If unset, it remains the circleSize.
        testID={testID}
        accessibilityLabel={accessibilityLabel}
      />
    );
  }, [value, onValueChange]);
};

export default React.memo(FormFieldSwitch);
