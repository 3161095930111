import React from "react";
import { View, TouchableOpacity, Image } from "react-native";
import { Text } from "react-native-elements";
import { IMAGES } from "static/assets/images";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import { exportDSRequests } from "./helper";
import { fontWeights } from "styles/theme";
import tw from "tailwind-rn";
import moment from "moment";
import omit from "lodash/omit";

const ListingHeader = ({
  data,
  minDeliveryDate,
  maxDeliveryDate,
  deliveryStartDate,
  deliveryEndDate,
  isCustomRangeApplied,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);

  return (
    <View
      style={[
        tw(
          `flex flex-row flex-wrap ${
            isCustomRangeApplied || !moment(minDeliveryDate).isValid()
              ? `justify-end`
              : `justify-between`
          } items-center`
        ),
        {
          paddingVertical: 5,
          paddingHorizontal: isDesktop ? 15 : 5,
          backgroundColor: "#fff",
        },
      ]}
    >
      {!isCustomRangeApplied && moment(minDeliveryDate).isValid() ? (
        <View
          style={[tw("flex flex-row flex-wrap items-center"), { width: "50%" }]}
        >
          <Text>{Localise(messages, "Showing results from")} </Text>
          <Text>
            <Text style={{ fontWeight: fontWeights.bold }}>
              {moment(minDeliveryDate).format("MM/DD/YYYY")}
            </Text>
            {!!maxDeliveryDate && (
              <>
                <Text> to </Text>
                <Text style={{ fontWeight: fontWeights.bold }}>
                  {moment(maxDeliveryDate).format("MM/DD/YYYY")}
                </Text>
              </>
            )}
          </Text>
        </View>
      ) : (
        <></>
      )}
      <TouchableOpacity
        testID={"DSListing_Download_Results"}
        accessibilityLabel="DSListing_Download_Results"
        onPress={() =>
          exportDSRequests(
            omit(data, ["approachingDrivers"]),
            deliveryStartDate,
            deliveryEndDate,
            messages,
            Localise
          )
        }
      >
        <Image
          style={{
            width: 25,
            height: 25,
          }}
          resizeMode="cover"
          source={IMAGES["download"]}
        />
      </TouchableOpacity>
    </View>
  );
};

export default ListingHeader;
