import React, { memo, useMemo, useEffect } from "react";
import { Text, Image } from "react-native-elements";
import { View, Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { colors, theme } from "styles/theme";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import IMAGES from "static/assets/images";
import { PopupMenu, Tooltip } from "components/elements";
import { addToRouteOptions } from "./config";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { selectQrScanVariant } from "library/sagas/ongoing/global-data/selector";
import { setQrScanVariant } from "library/sagas/ongoing/global-data/slice";
import { showOrderNumberModalSelector } from "library/sagas/views/home/drawer/delivery/selector";
import { setShowONAddtoRouteModal } from "library/sagas/views/home/drawer/delivery/slice";
import { scrollToAddStopForm } from "components/views/drawer/delivery/routes-dashboard/upsert-route/helper";

const TouchableComponent =
  Platform.OS === "web"
    ? require("react-native").TouchableOpacity
    : require("react-native-gesture-handler").TouchableOpacity;

// eslint-disable-next-line react/display-name
const AddToRouteIcon = memo(({ availableOptions }) => {
  const { messages, Localise } = React.useContext(I18NContext);

  return (
    <Tooltip text={Localise(messages, "Add To Route")} renderForWebOnly={true}>
      <View style={[tw("flex flex-row"), { marginVertical: 2 }]}>
        <View
          style={{
            ...theme.Button.secondaryButtonStyle,
            height: 35,
            paddingHorizontal: 10,
            paddingVertical: 10,
            marginRight: 5,
            marginVertical: 2,
          }}
        >
          <Text
            style={{
              ...theme.Button.secondaryTitleStyle,
              color: colors.highlighter,
              fontSize: 14,
            }}
          >
            {Localise(messages, "Add To Route")}
          </Text>
        </View>
        {availableOptions?.length > 1 ? (
          <View
            style={{
              ...theme.Button.secondaryButtonStyle,
              width: 25,
              height: 35,
              marginVertical: 2,
              paddingHorizontal: 0,
              paddingVertical: 0,
            }}
            testID="add_order_to_route_options"
          >
            <Image
              style={{ width: 12, height: 9 }}
              resizeMode="contain"
              source={IMAGES["arrow-drop-down-blue"]}
            />
          </View>
        ) : null}
      </View>
    </Tooltip>
  );
});

const AddStopToRoute = ({
  addStopAction,
  localPermissions,
  isAddStopFormOpen,
  setIsAddStopFormOpen,
  setAutoScroll,
  addStopViewRef,
  setAddStopYCoordinate,
}) => {
  const dispatch = useDispatch();
  const qrScanVariant = useSelector(selectQrScanVariant);
  const showModal = useSelector(showOrderNumberModalSelector);
  const [addStopselected, setAddStopSelected] = useStateIfMounted(false);

  const availableOptions = useMemo(
    () =>
      addToRouteOptions({
        localPermissions,
        isAddStopFormOpen,
        qrScanVariant,
        showModal,
      }),
    [localPermissions, isAddStopFormOpen, qrScanVariant, showModal]
  );

  const handleSelect = (selectedKey) => {
    if (selectedKey === "addStopAction") {
      if (isAddStopFormOpen) {
        setIsAddStopFormOpen(false);
      } else {
        addStopAction();
      }
      setAddStopSelected(!isAddStopFormOpen);
    } else if (selectedKey === "qrScan") {
      dispatch(
        setQrScanVariant(
          qrScanVariant !== "order-route-scan" ? "order-route-scan" : ""
        )
      );
    } else if (selectedKey === "byOrderNumber") {
      dispatch(setShowONAddtoRouteModal(!showModal));
    }
  };

  useEffect(() => {
    scrollToAddStopForm({
      isAddStopFormOpen,
      setAutoScroll,
      addStopViewRef,
      setAddStopYCoordinate,
      addStopselected,
    });
  }, [isAddStopFormOpen]);

  return (
    <View
      style={[
        tw("flex flex-row items-center"),
        { paddingVertical: 5, paddingHorizontal: 5 },
      ]}
    >
      {availableOptions?.length > 1 ? (
        <PopupMenu
          menuOptions={availableOptions}
          onSelect={handleSelect}
          containerStyle={tw("items-center")}
          optionsContainerStyle={[tw("p-1"), { width: 130, marginTop: 40 }]}
          testID={"Add to Route Action"}
        >
          <AddToRouteIcon {...{ availableOptions }} />
        </PopupMenu>
      ) : (
        <TouchableComponent
          onPress={() => {
            handleSelect(availableOptions[0]?.key);
          }}
        >
          <AddToRouteIcon {...{ availableOptions }} />
        </TouchableComponent>
      )}
    </View>
  );
};

export default memo(AddStopToRoute);
