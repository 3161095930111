import * as React from "react";

import Environment from "library/utils/environment";
import UserAuthContext from "library/contexts/userAuth";
import UserProfileContext from "library/contexts/userProfile";
import { Platform } from "react-native";
import UserProfileUtils from "library/utils/userProfile";
import UserProfileStorage from "library/storage/userProfile";
import UserProfileReducer from "library/reducers/userProfile";

import get from "lodash/get";
import moment from "moment";

export default function UserProfileProvider({ children }) {
  const { authToken } = React.useContext(UserAuthContext);

  const [state, dispatch] = React.useReducer(UserProfileReducer, {
    userProfile: UserProfileStorage.getUser(),
    userRole: UserProfileStorage.getRole(),
    userType: UserProfileStorage.getUserType(),
    suspendedMembers: UserProfileStorage.getSuspendedMembers(),
    floristTypes: UserProfileStorage.getFloristTypes(),
    memberCodes: UserProfileStorage.getProfileMemberCodes(),
  });
  const { userRole } = state;
  const proxyUser = authToken && UserProfileUtils.isProxyUserRequest();
  const shopGroups = UserProfileStorage.getAllShopGroups();

  // Adding the below code for checking the password expire duration
  const minExpireDays = Environment.get(
    "PASSWORD_EXPIRATION_ALERT_MIN_DAYS",
    7
  );
  const userProfileObj = UserProfileStorage.getUser();
  const startDate = moment(userProfileObj.passwordSetAt).format("YYYY-MM-DD");
  const endDate = moment().format("YYYY-MM-DD");
  const passwordSetDays = moment
    .duration(moment(endDate).diff(moment(startDate)))
    .asDays();
  const remainingPasswordExpDays = userProfileObj.passwordAge - passwordSetDays;
  const isPasswordAboutToExpire = remainingPasswordExpDays <= minExpireDays;

  const context = React.useMemo(
    () => ({
      setUserRole: (role) => {
        UserProfileStorage.setRole(role);
        dispatch({ type: "SET_ROLE", role });
      },
      setUserProfile: (profile) => {
        UserProfileStorage.setUser(profile);
        dispatch({
          type: "SET_PROFILE",
          profile,
          role: get(profile, "authGroups.0.roles.0"),
          userType: UserProfileStorage.getUserType(),
        });
      },
      setSuspendedMembers: (suspendedMembers) => {
        UserProfileStorage.setSuspendedMembers(suspendedMembers);
        dispatch({
          type: "SET_SUSPENDED_MEMBERS",
          suspendedMembers,
        });
      },
      setFloristTypes: (floristTypes) => {
        UserProfileStorage.setFloristTypes(floristTypes);
        dispatch({
          type: "SET_FLORIST_TYPES",
          floristTypes: floristTypes,
        });
      },
      setProfileMemberCodes: (memberCodes) => {
        UserProfileStorage.setProfileMemberCodes(memberCodes);
        dispatch({
          type: "SET_PROFILE_MEMBER_CODES",
          memberCodes,
        });
      },
      setAccountMemberCodes: (memberCodes) => {
        UserProfileStorage.setAccountMemberCodes(memberCodes);
        dispatch({
          type: "SET_ACCOUNT_MEMBER_CODES",
          memberCodes,
        });
      },
    }),
    []
  );

  React.useEffect(() => {
    // clear user storage cache on signout
    !authToken &&
      userRole &&
      (() => {
        UserProfileStorage.clear();
        dispatch({ type: "RESET_PROFILE" });
      })();

    // load profile information one time
    authToken &&
      !userRole &&
      UserProfileUtils.getUserProfile()
        .then((res) => {
          if (res) {
            res &&
              dispatch({
                type: "SET_PROFILE",
                profile: res,
                role: UserProfileStorage.getRole(),
                userType: UserProfileStorage.getUserType(),
              });

            // We are updating user profile member codes in context by fetching member codes from storage so that they will available in all other components
            dispatch({
              type: "SET_PROFILE_MEMBER_CODES",
              memberCodes:
                UserProfileStorage.getRole() === "ADMIN"
                  ? UserProfileStorage.getAccountMemberCodes()
                  : UserProfileStorage.getProfileMemberCodes(),
            });
          }
        })
        .catch(() => {
          UserProfileStorage.clear();
          dispatch({ type: "RESET_PROFILE" });
        });

    // setup proxy user
    if (authToken && userRole && proxyUser && userRole !== "ADMIN") {
      UserProfileStorage.setOperatorRole(userRole);
      UserProfileUtils.setupProxyUserProfile().then((proxyProfile) => {
        proxyProfile &&
          dispatch({
            type: "SET_PROFILE",
            profile: proxyProfile,
            role: UserProfileStorage.getRole(),
            userType: UserProfileStorage.getUserType(),
          });

        proxyProfile &&
          dispatch({
            type: "SET_PROFILE_MEMBER_CODES",
            memberCodes: UserProfileStorage.getProfileMemberCodes(),
          });
      });
    }
  }, [authToken]);

  React.useEffect(() => {
    const enableAppcues = Environment.get("APPCUES_ENABLED", true);
    try {
      if (
        Platform.OS === "web" &&
        userProfileObj &&
        Object.keys(userProfileObj).length &&
        typeof Appcues !== "undefined" &&
        // eslint-disable-next-line no-undef
        window.Appcues &&
        window.Appcues.identify &&
        enableAppcues
      ) {
        if (userProfileObj.memberCode === "FTD") {
          window.Appcues.identify(
            userProfileObj.id, // unique, required
            {
              email: userProfileObj.email, // Current user's email
              memberId: userProfileObj.memberCode,
              createdAt: Date.now(),
              role: UserProfileStorage.getRole(), // Current user’s role or permissions
            }
          );
        }
        // eslint-disable-next-line no-undef
        Appcues.track();
      }
    } catch (error) {
      console.error("Error tracking appcues", error);
    }
  }, [userProfileObj.id]);

  return (
    <UserProfileContext.Provider
      value={{
        ...context,
        ...state,
        ...{ shopGroups },
        ...{ proxyUser, isPasswordAboutToExpire, remainingPasswordExpDays },
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
}
