/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useContext } from "react";

import UserProfileContext from "library/contexts/userProfile";
import UserProfileStorage from "library/storage/userProfile";

import { useDispatch } from "react-redux";
import {
  sendEmailInvoices,
  fetchAutoEmailInvoiceOrders,
  sendAutoEmailInvoiceOrders,
} from "library/sagas/ongoing/current-orders/slice";

import I18NContext from "library/contexts/i18N";
import Environment from "library/utils/environment";
import { generateQRCode } from "library/sagas/ongoing/order-details/slice";
import { getSingleInvoicePrintHTML } from "components/views/drawer/order-details/invoice-print-helper";

import Logger from "library/utils/logger";

const AutoEmailInvoice = () => {
  const dispatch = useDispatch();
  const { messages, Localise } = useContext(I18NContext);
  const { memberCodes = [], userRole } = useContext(UserProfileContext);

  const triggerInvoiceEmails = (response) => {
    let emailSent = [];

    const triggerPostAutoEmailInvoiceOrders = () => {
      dispatch(
        sendAutoEmailInvoiceOrders({
          payload: {
            orderIds: emailSent,
          },
          resolve: () => {},
          reject: (err) => {
            console.log(
              "Failed to Post order Ids auto send email invoices >>",
              err
            );
            //Trigger Post call (to mark orders as email invoices sent) if it fails
            triggerPostAutoEmailInvoiceOrders();
          },
        })
      );
    };

    if (Array.isArray(response) && response.length > 0) {
      const dispatchPromises = response?.map(async (order) => {
        const {
          paymentDetails = {},
          orderAmounts = [],
          customerInfo: {
            houseAccountInfo = {},
            email = "",
            customerId = "",
          } = {},
          orderItems: [orderInfo = {}] = [],
        } = order;

        const { ...props } = orderInfo;
        const {
          orderItemId = "",
          erosOrderNumber = "",
          deliveryInfo: { deliveryMethod },
          sendingMember = {},
          receivingMember = {},
          direction = "",
        } = props;

        const orderDetails = {
          ...props,
          orderAmounts,
        };

        const sourceMemberCode =
          direction === "INBOUND"
            ? receivingMember?.memberCode
            : sendingMember?.memberCode;

        // Get auto send invoice email settings
        const isEnabledCustomerAutoSendInvoices =
          houseAccountInfo?.autoEmailInvoices || false;

        const shopPreferences =
          UserProfileStorage.getShopPreferences(sourceMemberCode);

        const { email_ha_invoices_notification_settings = "" } =
          shopPreferences;

        const isEnabledGlobalAutoSendInvoices =
          !email_ha_invoices_notification_settings
            ? false
            : JSON.parse(email_ha_invoices_notification_settings)?.emailToggle;

        Logger.info(
          `EMAIL PREFERENCE for : >> ${orderItemId}`,
          `<< GLOBAL =>,${isEnabledGlobalAutoSendInvoices}, << CUSTOMER =>,${isEnabledCustomerAutoSendInvoices}`
        );

        const isAutoSendInvoices =
          isEnabledCustomerAutoSendInvoices && isEnabledGlobalAutoSendInvoices;

        if (!isAutoSendInvoices) {
          emailSent.push(orderItemId);
        }

        // get QR code details
        const { qr_code_global_enabled = "" } =
          UserProfileStorage.getShopPreferences(sourceMemberCode);

        const showQrCode = qr_code_global_enabled === "true";
        const qrData = [
          {
            oid: orderItemId || "", // orderItemId
            sm: sourceMemberCode || "", // sourceMemberCode based on orderDirection
            dm: deliveryMethod || "", // order deliveryMethod
          },
        ];
        let qrImageData = "";
        let htmlContent = "";

        if (email?.length > 0 && isAutoSendInvoices) {
          // invoice print trigger
          const invoicePrintTrigger = async () => {
            htmlContent = getSingleInvoicePrintHTML({
              orderDetails,
              memberCodes,
              messages,
              Localise,
              customerInfo: order?.customerInfo,
              paymentDetails,
              userRole,
              zoneDetails: [],
              qrImageData,
            });

            if (htmlContent?.length) {
              return new Promise((resolve, reject) => {
                dispatch(
                  sendEmailInvoices({
                    payload: {
                      requestPayload: {
                        isAutoSendEmailInvoice: true, // True for auto send email invoice
                        orderId: orderItemId,
                        orderNumber: erosOrderNumber,
                        recipients: [email],
                        deliveryMethod: deliveryMethod,
                        html: htmlContent,
                        ...(customerId && {
                          customerId: customerId,
                        }),
                      },
                      shopCode: sourceMemberCode,
                      recordId: orderItemId,
                    },
                    resolve: (res) => {
                      if (res) {
                        emailSent.push(orderItemId);
                      }
                      resolve(); // Mark this as done
                    },
                    reject: (error) => {
                      reject(error); // Mark as failed if any error occurs
                      console.log(
                        "Failed to send on demand auto invoice order >>",
                        error
                      );
                    },
                  })
                );
              });
            }
          };

          const triggerQrCodeOrPrint = async () => {
            if (showQrCode) {
              try {
                const resp = await dispatch(
                  generateQRCode({
                    params: { qrPayload: qrData },
                    type: "SINGLE",
                  })
                );
                qrImageData = resp[orderItemId] || "";
              } catch (error) {
                console.log(
                  `QR code generation failed for order: ${orderItemId}`
                );
              }
            }
            // Ensure `invoicePrintTrigger` is always called, but return outside finally
            return invoicePrintTrigger();
          };

          // Call the function and return the result (for Promise.allSettled to wait)
          return triggerQrCodeOrPrint();
        }
      });

      // Now Promise.allSettled will wait for all inner dispatches to finish (resolve / reject)
      Promise.allSettled(dispatchPromises)
        .then(() => {
          if (emailSent?.length > 0) {
            triggerPostAutoEmailInvoiceOrders(); // Trigger this function
          }
        })
        .catch((error) => {
          // Handle errors if any of the dispatches fail
          console.error("Error in dispatch:", error);
        });
    }
  };

  const getAutoEmailInvoiceOrdersByMember = () => {
    dispatch(
      fetchAutoEmailInvoiceOrders({
        params: { shopCode: "" },
        resolve: (response) => {
          triggerInvoiceEmails(response);
        },
        reject: (err) => {
          console.log("Failed to fetch auto invoice orders >>", err);
        },
      })
    );
  };
  let pollTimerForAutoEmailInvoice = useRef(null);

  const pollIntervalForAutoEmailInvoice = Environment.get(
    "AUTO_EMAIL_INVOICE_POLL_INTERVAL",
    3 * 60 * 1000
  );

  useEffect(() => {
    if (pollTimerForAutoEmailInvoice)
      clearInterval(pollTimerForAutoEmailInvoice);

    pollTimerForAutoEmailInvoice = setInterval(
      () => getAutoEmailInvoiceOrdersByMember(),
      pollIntervalForAutoEmailInvoice
    );

    getAutoEmailInvoiceOrdersByMember();

    return () => {
      clearInterval(pollTimerForAutoEmailInvoice);
    };
  }, []);

  // Component doesn't return anything
  return null;
};

export default AutoEmailInvoice;
