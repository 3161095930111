import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  Platform,
  // Linking,
  TouchableOpacity,
  View,
  // Image,
  // ScrollView,
} from "react-native";
import { Text, Input } from "react-native-elements";
import { useRoute, useNavigation } from "@react-navigation/native";
import tw from "tailwind-rn";
// import IMAGES from "static/assets/images";

import I18NContext from "library/contexts/i18N";
import { fonts } from "styles/theme";
// import Feedback from "components/views/drawer/feedback";
// import CommonQuestions from "../common-questions";
import { getMHQUIConfig, getMOLUIConfig } from "./ui-config";
import { DeviceContext } from "library/contexts/appSettings";

import { AccordionWithBorder } from "components/elements";
// import { Accordion } from "components/elements";
import DownloadLinks from "components/views/drawer/help/download-links";
import useStateIfMounted from "library/utils/useStateIfMounted";

import { selectActiveHelpSubTab } from "library/sagas/views/home/drawer/help/selector";
import UserAuthContext from "library/contexts/userAuth";

const TrainingMaterials = ({ setShowPreview, setContent }) => {
  const { params: { search = "" } = {} } = useRoute();
  const { setParams } = useNavigation();

  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const { authToken } = React.useContext(UserAuthContext);
  const [searchText, setSearchText] = useStateIfMounted(search);
  const [uiConfig, setUiConfig] = useStateIfMounted([]);

  const currentSubTab = useSelector(selectActiveHelpSubTab);

  const initConfig = currentSubTab == "mhq-sub-tab" ? getMHQUIConfig(isDesktop) : getMOLUIConfig();

  useEffect(() => {
    return () => {
      setParams({ search: undefined });
    };
  }, []);

  useEffect(() => {
    let finalConfig = [];
    finalConfig = initConfig.filter((option) => {
      let subTitleslist = [];

      // Search w.r.t PDF link
      option?.subAccordians?.forEach((each) => {
        each.subTitle?.toLowerCase().includes(searchText?.toLowerCase()) &&
          subTitleslist.push(each);
      });

      return (
        option.title?.toLowerCase().includes(searchText?.toLowerCase()) ||
        subTitleslist?.length > 0
      );
    });

    // filter links in the section w.r.t search text
    let filterFinalConfig = [];
    finalConfig?.map((eachObj) => {
      let filteredSubArrayList = [];
      eachObj?.subAccordians?.forEach((each) => {
        each.subTitle?.toLowerCase().includes(searchText?.toLowerCase()) &&
          filteredSubArrayList.push(each);
      });
      filterFinalConfig.push({
        ...eachObj,
        subAccordians: filteredSubArrayList,
        defaultOpen: true,
      });
    });

    setUiConfig(filterFinalConfig);
  }, [searchText]);

  const finalUIconfig =
    searchText?.length > 0
      ? uiConfig?.length > 0
        ? uiConfig
        : []
      : initConfig;

  return (
    <View style={{ paddingHorizontal: 10 }}>
        <>
          {Platform.OS === "web" && currentSubTab === "mhq-sub-tab" && (
            <View style={[tw("flex flex-row"), { paddingBottom: 10 }]}>
              <Input
                autoCapitalize="none"
                autoCorrect={false}
                style={{
                  fontFamily: fonts.fontFamily.default,
                  fontSize: 12,
                  height: 35,
                }}
                onChangeText={(text) => {
                  setSearchText(text);
                }}
                value={searchText}
                placeholder={"Search"}
                testID="searchPDF"
                containerStyle={{
                  height: 35,
                  width: "30%",
                  paddingHorizontal: 0,
                }}
                inputContainerStyle={{
                  height: 35,
                  alignItems: "center",
                }}
                accessibilityLabel="searchPDF"
              />
            </View>
          )}
          {finalUIconfig.map(
            ({ title = "", subAccordians = [], defaultOpen = false }, id) => {
              return (!authToken || Platform.OS !== "web") &&
                title !== "Legal" ? null : (
                <AccordionWithBorder
                  title={`${title} (${subAccordians?.length})`}
                  key={id}
                  defaultOpen={defaultOpen}
                >
                  {subAccordians &&
                    subAccordians.map(
                      (
                        {
                          subTitle,
                          content,
                          fileName,
                          needEncoding = false,
                          key,
                        },
                        index
                      ) => (
                        <View key={key}>
                          {title === "Legal" ? (
                            <View style={tw("pt-4")}>
                              <TouchableOpacity
                                onPress={() => {
                                  setShowPreview(true);
                                  setContent(content);
                                }}
                                testID={key}
                                accessibilityLabel={key}
                              >
                                <View style={tw("pt-2 pb-2")}>
                                  <Text style={fonts.link1}>
                                    {Localise(messages, subTitle)}
                                  </Text>
                                </View>
                              </TouchableOpacity>
                            </View>
                          ) : (
                            <View key={index}>
                              <DownloadLinks
                                subTitle={subTitle}
                                fileName={fileName}
                                needEncoding={needEncoding}
                              />
                            </View>
                          )}
                        </View>
                        // <Accordion
                        //   type="secondary"
                        //   key={index}
                        //   title={Localise(messages, subTitle)}
                        //   contentStyle={{
                        //     backgroundColor: "white",
                        //     borderWidth: 0,
                        //     paddingHorizontal: 15,
                        //   }}
                        //   titleStyle={{
                        //     ...fonts.heading4,
                        //     fontWeight: "600",
                        //   }}
                        //   labelStyle={{
                        //     marginVertical: 5,
                        //     backgroundColor: backgroundColors.secondary,
                        //     marginTop: 0,
                        //     paddingHorizontal: 15,
                        //   }}
                        //   containerStyle={{
                        //     borderWidth: 1,
                        //     borderColor: colors.light,
                        //     marginTop: 10,
                        //   }}
                        //   iconStyle={{
                        //     color: colors.primary,
                        //   }}
                        //   closeStyle={{ borderBottomWidth: 0 }}
                        //   handleOnPress={() => {}}
                        // >
                        //   <DownloadLinks
                        //     fileName={fileName}
                        //     needEncoding={needEncoding}
                        //   />
                        //   {content?.length > 0 &&
                        //     content.map(({ image, imgWidth, imgHeight }, index) => (
                        //       <View key={index} style={{ alignItems: "center" }}>
                        //         <Image
                        //           style={{ width: imgWidth, height: imgHeight }}
                        //           resizeMode="cover"
                        //           source={IMAGES[image]}
                        //         />
                        //       </View>
                        //     ))}
                        // </Accordion>
                      )
                    )}
                </AccordionWithBorder>
              );
            }
          )}
        </>
    </View>
  );
};

export default TrainingMaterials;
