import React from "react";
import { Image, TouchableOpacity, View } from "react-native";

import tw from "tailwind-rn";

import { backgroundColors } from "styles/theme";
import { IMAGES } from "static/assets/images";

import { Maps } from "components/elements";

import { DeviceContext } from "library/contexts/appSettings";

const MapModalContent = ({ trackingDetails, setModalName }) => {
  const { isDesktop } = React.useContext(DeviceContext);

  const {
    deviceLatitude = "",
    deviceLongitude = "",
    recipientInfo: {
      addressLine1 = "",
      city = "",
      state = "",
      zip = "",
      latitude = "",
      longitude = "",
    } = {},
  } = trackingDetails?.events?.[0] || {};

  const recipientAddress = {
    addressLine1: addressLine1,
    addressLine2: `${city}, ${state} ${zip}`,
  };

  return (
    <>
      <View style={[tw("flex-row items-center justify-end")]}>
        <TouchableOpacity
          testID="close_zoomed_map"
          accessibilityLabel="close_zoomed_map"
          onPress={() => {
            setModalName("");
          }}
        >
          <Image
            style={{
              width: 30,
              height: 30,
            }}
            resizeMode="cover"
            source={IMAGES["close"]}
          />
        </TouchableOpacity>
      </View>

      <View
        style={[
          {
            borderColor: backgroundColors.medium,
            borderWidth: 1,
            borderStyle: "solid",
            marginHorizontal: 5,
            marginVertical: 8,
          },
        ]}
      >
        <Maps
          mapMarkers={{
            locations: [
              {
                latitude: "",
                longitude: "",
              },
            ],
            ...{
              recipientLatLong: {
                recipientLatitude: parseFloat(latitude),
                recipientLongitude: parseFloat(longitude),
              },
            },
            ...{
              driverLatLong: {
                driverLatitude:
                  (deviceLatitude && parseFloat(deviceLatitude)) || "",
                driverLongitude:
                  (deviceLongitude && parseFloat(deviceLongitude)) || "",
              },
            },
          }}
          showMarkerPopup={true}
          recipientAddress={recipientAddress}
          zoomLevel={18}
          mapHeight={isDesktop ? 600 : 450}
        />
      </View>
    </>
  );
};

export default MapModalContent;
