import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectDSRequestModifying = createSelector(
  selectState,
  ({ isDSRequestModifying }) => isDSRequestModifying
);
export const selectAddressVerificationInfo = createSelector(
  selectState,
  ({ addressVerificationInfo }) => addressVerificationInfo
);
export const selectOrderLocked = createSelector(
  selectState,
  ({ isTaskLocked }) => isTaskLocked
);

export const selectRecordId = createSelector(
  selectState,
  ({ recordId }) => recordId
);

export const selectAddressVerificationStatus = createSelector(
  selectState,
  ({ addressVerificationStatus }) => addressVerificationStatus
);

export const selectDSRequestDetails = createSelector(
  selectState,
  ({ DSRequestDetails }) => DSRequestDetails
);

export const selectDSRequestDetailsLoading = createSelector(
  selectState,
  ({ DSRequestLoading }) => DSRequestLoading
);

export const selectDSRequestActionProcessing = createSelector(
  selectState,
  ({ DSRequestActionProcessing }) => DSRequestActionProcessing
);

export const selectShowApproveCreditForm = createSelector(
  selectState,
  ({ showApproveCreditForm }) => showApproveCreditForm
);
