import omit from "lodash/omit";
import get from "lodash/get";
import isArray from "lodash/isArray";
import capitalize from "lodash/capitalize";
import lowerCase from "lodash/lowerCase";
import { request } from "library/utils/request";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import set from "lodash/set";
import camelCase from "lodash/camelCase";
import sum from "lodash/sum";
import cloneDeep from "lodash/cloneDeep";

import { formatPrice } from "./formatter";
import { isToday } from "library/utils/datetime";
import moment from "moment";
import {
  getCardType,
  getRandomInt,
  TokenizeAndAuthorizePaymentV2,
  TerminalPayment,
  authorizeGiftCardPayment,
  initiateRefundThroughTerminal,
  isAllPaymentsProcessed,
} from "library/utils/payment-options";
import states from "static/data/states.json";
import {
  validateOrder,
  upsertSubscription,
} from "library/sagas/views/home/drawer/create-order/slice";

export const inEligibleSplitPaymentOptions = ["PAY_LATER", "INVOICE"];

export const processQuickPicks = (records) => {
  if (!records || !records.length) return [];

  return records.map((record) => {
    const { description: name, img, productType: pid, category} = record;

    return {
      name,
      img,
      pid,
      type: "Product",
      category,
    };
  });
};

export const getAllProducts = (records) => {
  if (!records || !records.length) return [];
  let products = [];
  records.forEach((record) => {
    products.push(...(record.products || []));
  });
  return products;
};

export const processProductResponse = (records) => {
  if (!records || !records.length) return [];

  let products = [];
  records.forEach((record) => {
    const temp = record.products.map((ele) => {
      //ele.occasion = record.suggestion;
      return ele;
    });
    products.push(...temp);
  });
  return products.map((product) => {
    const {
      name,
      img,
      pid,
      refNumberId,
      pricing: { prices = [] } = {},
      occasion,
      siteId,
    } = product;
    const priceObj = prices.find((price) => price.type === "regular") || {};
    return {
      name: name || pid,
      img,
      price: parseFloat(priceObj.value || 0).toFixed(2),
      pid,
      refNumberId: refNumberId || pid,
      occasion,
      siteId,
      type: "Product",
    };
  });
};

export const processAddonsResponse = (records = []) => {
  if (!records.length) return [];

  return records.map((record) => {
    const { name, pid, primaryImage: img, prices = [], siteId = "" } = record;
    const priceObj = prices.find((price) => price.type === "regular") || {};
    return {
      name: name || pid,
      img,
      price: parseFloat(priceObj.value || 0).toFixed(2),
      pid,
      type: "Addon",
      addonType: `${siteId === "ftd" ? "Global" : "My"} Add-on`,
    };
  });
};

export const processLocalAddonsResponse = (records = []) => {
  if (!records.length) return [];

  return records.map((record) => {
    const {
      name,
      id,
      assets: { images = [] },
      prices = [],
      productAttributes = [],
    } = record;
    const priceObj = prices.find((price) => price.type === "regular") || {};
    const category =
    productAttributes.find((attr) => attr.name === "category")?.values[0]
      .value || "";
    return {
      name: name || id,
      img: images?.find((ele) => (ele.type = "primary"))?.url || "",
      price: parseFloat(priceObj.value || 0).toFixed(2),
      pid: id,
      type: "Addon",
      category,
    };
  });
};

export const getPrice = (price = "") => {
  if (!price.toString().length || isNaN(price)) return 0;
  return price;
};

export const getDiscount = (
  discount = 0,
  value = 0,
  discountType = "Dollar",
  quantity = "1"
) => {
  if (!discount || isNaN(discount) || !discountType) return 0;

  const discountVal =
    discountType !== "Dollar"
      ? (value * parseInt(quantity) * discount) / 100
      : discount;

  return parseFloat(formatPrice(discountVal));
};

export const formatData = (data, listDisplayValues, delimiter) => {
  if (!data || !Object.keys(data).length) return "";
  const arr = [];
  listDisplayValues.forEach((e) => {
    let val = data[e];
    if (val) {
      if (e === "price") val = `$${val}`;
      else if (e === "distance") val = `${val}`;
      arr.push(val);
    }
  });
  return arr.join((delimiter && delimiter === "space" && "    ") || ", ");
};

export const getMembersInfo = (
  members = {},
  onlyBasicInfo = true,
  isPreferencesScreen = false,
  onlyAvailableFlorists = false
) => {
  const {
    availableFlorists = [],
    unAvailableFlorists = [],
    doNotUse = [],
    florists = [],
    firstChoiceFlorists = [], //For Preferences Screen
    preferredFlorists = [], //For Preferences Screen
    doNotUseFlorists = [], //For Preferences Screen
    latitude: senderLatitude,
    longitude: senderLongitude,
    actualNumberOfRecords = 0,
  } = members;

  const senderLatLong = {
    senderLatitude: parseFloat(senderLatitude),
    senderLongitude: parseFloat(senderLongitude),
  };

  const searchResultsCount = actualNumberOfRecords;

  const errorFloristsCount = unAvailableFlorists.length + doNotUse.length;

  const allMembersInfo = [
    ...availableFlorists,
    ...unAvailableFlorists,
    ...doNotUse,
    ...florists,
    ...firstChoiceFlorists,
    ...preferredFlorists,
    ...doNotUseFlorists,
  ];
  if (allMembersInfo.length < 1) return { membersInfo: [] };

  const membersRequiredInfo = onlyAvailableFlorists
    ? availableFlorists
    : allMembersInfo;

  const membersBasicInfo = membersRequiredInfo.map((florist) => {
    const {
      memberId: memberCode,
      businessName,
      phoneNumber,
      rating,
      orderMinimum: floristMinimum,
      distanceInMiles: radialDistanceFromRecipientInMiles,
    } = florist;

    return {
      name: businessName,
      memberCode,
      rating,
      minPrice: `$${parseFloat(floristMinimum).toFixed(2)}`,
      distance: `${radialDistanceFromRecipientInMiles || 0} miles`,
      phone: phoneNumber,
    };
  });

  if (onlyBasicInfo) return { membersInfo: membersBasicInfo, senderLatLong };

  const membersInfo = membersRequiredInfo.map((florist) => {
    const {
      address,
      businessName,
      displayAd = {},
      distanceInMiles: radialDistanceFromRecipientInMiles,
      email,
      floristName,
      floristNotes,
      hours,
      isAvailable,
      latitude = "",
      longitude = "",
      memberId: memberCode,
      noteFromFlorist,
      orderMinimum: floristMinimum,
      partnerPreference = "NORMAL",
      phoneNumber,
      subscriptions,
      unavailabilityReason = "",
      floristContactPreference = "Name,Phone,Email",
      floristShopContactSettings = "",
    } = florist;
    let floristShopContactOverrideSettings = floristShopContactSettings;
    if (
      floristShopContactOverrideSettings == "JDE" ||
      floristShopContactOverrideSettings == ""
    ) {
      floristShopContactOverrideSettings =
        '{"shopName":"","streetAddress":"","aptSuite":"","city":"","state":"","zip":"","phone":"","email":"","website":"","additionalMessage":"","contactName":"","printed":[],"web":[],"network":[]}';
    }

    let isShopNameOverridden = false;
    let isContactNameOverridden = false;
    let isPhoneOverridden = false;
    let isShopEmailOverridden = false;
    let shopBusinessName = businessName;

    let {
      shopName = "",
      contactName = "",
      phone = "",
      email: contactEmail = "",
      network = [],
    } = JSON.parse(floristShopContactOverrideSettings);

    if (network.includes("shopName") && shopName != "") {
      isShopNameOverridden = true;
    }
    if (network.includes("contactName") && contactName != "") {
      isContactNameOverridden = true;
    }
    if (network.includes("phone") && phone != "") {
      isPhoneOverridden = true;
    }
    if (network.includes("email") && contactEmail != "") {
      isShopEmailOverridden = true;
    }
    if (isShopNameOverridden == true) {
      shopBusinessName = shopName;
    }

    const displayInfo = floristContactPreference?.split(",") || [];

    const {
      streetAddress,
      streetAddress2 = "",
      cityName,
      state,
      zip,
    } = address;

    const basicInfo = {
      name: shopBusinessName,
      memberCode,
      minPrice: `$${parseFloat(floristMinimum || 0).toFixed(2)}`,
      distance: `${radialDistanceFromRecipientInMiles || "unknown"} miles`,
      phone: phoneNumber,
      addressLine1: streetAddress,
      city: cityName,
      state,
      zip,
    };

    const fieldSetLabel =
      lowerCase(partnerPreference) === "first choice"
        ? "First Choice"
        : lowerCase(partnerPreference) === "preferred"
        ? "Preferred"
        : lowerCase(partnerPreference) === "do not use"
        ? "Do Not Use"
        : "";

    const preferenceLabel =
      lowerCase(partnerPreference) === "first choice"
        ? "First Choice"
        : lowerCase(partnerPreference) === "preferred"
        ? "Preferred"
        : lowerCase(partnerPreference) === "do not use"
        ? "Do Not Use"
        : "Normal";

    let memberDisplayData = {
      "Partner Preference": preferenceLabel,
    };

    if (displayInfo.includes("Name")) {
      if (isContactNameOverridden == true) {
        memberDisplayData["Contact Name"] = contactName || "";
      } else {
        memberDisplayData["Contact Name"] = floristName || "";
      }
    }
    if (displayInfo.includes("Phone")) {
      if (isPhoneOverridden == true) {
        memberDisplayData["Contact Phone"] = phone || "";
      } else {
        memberDisplayData["Contact Phone"] = phoneNumber || "";
      }
    }
    if (displayInfo.includes("Email")) {
      if (isShopEmailOverridden == true) {
        memberDisplayData["Contact Email"] = contactEmail || "";
      } else {
        memberDisplayData["Contact Email"] = email || "";
      }
    }

    if (displayInfo.includes("shopName")) {
      memberDisplayData["Business Name"] = shopBusinessName || "";
    }
    memberDisplayData = {
      ...memberDisplayData,
      Address: {
        line1: `${streetAddress}, ${streetAddress2}`,
        line2: `${cityName}, ${state}, ${zip}`,
      },
      Hours: hours,
      "Current Availability": isAvailable ? "Yes" : "No",
      "Note from Florist": noteFromFlorist,
    };

    if (isPreferencesScreen)
      memberDisplayData = omit(memberDisplayData, [
        "Current Availability",
        "Partner Preference",
      ]);
    const { imageData = "" } = displayAd;
    const isMasterFlorist = isArray(subscriptions)
      ? subscriptions.includes("MASTER_FLORIST")
      : subscriptions === "MASTER_FLORIST";
    const isPremierFlorist = isArray(subscriptions)
      ? subscriptions.includes("PREMIER_FLORIST")
      : subscriptions === "PREMIER_FLORIST";
    const isDigitalAdAvailable = imageData !== "";

    const memberInfoData = {
      fieldSetLabel,
      floristNotes,
      isAvailable,
      imageData,
      isMasterFlorist,
      isPremierFlorist,
      isDigitalAdAvailable,
      latitude,
      longitude,
      partnerPreference,
      subscriptions,
      unavailabilityReason: capitalize(
        unavailabilityReason?.split(/_/).join(" ")
      ),
    };

    return { basicInfo, memberDisplayData, memberInfoData };
  });

  const locations = membersRequiredInfo.map((florist) => {
    const { latitude = 0, longitude = 0 } = florist;
    return { latitude: parseFloat(latitude), longitude: parseFloat(longitude) };
  });

  return {
    membersInfo,
    shopLocations: { senderLatLong, locations },
    searchResultsCount,
    errorFloristsCount,
  };
};

export const verifyAddress = async (address, index) => {
  const { addressLine1, city, state, zip, country } = address;
  return await request("verify-address", {
    address: {
      addressLine1,
      stateProvince: state,
      cityLocality: city,
      postalCode: zip,
      countryRegion: country,
      bussinessName: "FTD",
    },
    filter: {
      confidence: "ALL",
    },
  }).then((response = {}) => {
    const avsAddress = get(response, "addresses.0", {});
    const {
      cityLocality,
      stateProvince,
      postalCode,
      addressChanged,
      confidence,
      countryRegion,
    } = avsAddress;
    if (addressChanged) {
      return Promise.resolve({
        isValid: false,
        confidence,
        suggestedAddress: {
          ...avsAddress,
          city: cityLocality,
          zip: postalCode,
          state: stateProvince,
          country: countryRegion,
        },
        originalAddress: address,
        index,
        selectedIndex: 0, //0 for original address and 1 for suggested address selection
      });
    } else
      return Promise.resolve({
        isValid: true,
        confidence,
      });
  });
};

export const getTotalPrice = (orders, orderIndex = "") => {
  let orderTotal = 0;
  let subTotal = 0;
  let orderSubTotal = 0;
  let feesTotal = 0; // tax, df, rdf, rf
  function orderItemSum(orderInfo) {
    !isEmpty(orderInfo.price) &&
      orderInfo.price.forEach((price, index) => {
        const { value, discount, discountType } = price;
        const { quantity = 1, isRemoved = false } =
          orderInfo.lineItems[index] || {};

        if (!isRemoved) {
          subTotal =
            subTotal +
            parseFloat(getPrice(value * quantity)) -
            parseFloat(getDiscount(discount, value, discountType, quantity));
        }
      });

    orderSubTotal = subTotal;

    const deliveryMethod = get(orderInfo, "deliveryInfo.deliveryMethod", "");

    const isEmailOrder =
      get(orderInfo, "deliveryInfo.orderSource", "") === "Email Order";

    const showRelayFee =
      ["FLORIST_PARTNER", "PHONE_OUT"].includes(deliveryMethod) || isEmailOrder;

    feesTotal +=
      parseFloat(getPrice(orderInfo?.taxInfo?.taxAmount)) +
      parseFloat(getPrice(orderInfo?.deliveryFee)) +
      parseFloat(getPrice(orderInfo?.retailDeliveryFee)) +
      parseFloat(getPrice(showRelayFee ? orderInfo?.relayFee : 0)) + // Adding RelayFee only if delivery method is selected
      parseFloat(getPrice(orderInfo?.serviceFee)) +
      parseFloat(getPrice(orderInfo?.retransFee));

    orderTotal = orderSubTotal + feesTotal;
  }

  if (orderIndex !== "") {
    orderItemSum(orders[orderIndex]);
  } else {
    for (let orderInfo of orders) {
      orderItemSum(orderInfo);
    }
  }

  orderTotal = parseFloat(orderTotal).toFixed(2);
  orderSubTotal = parseFloat(orderSubTotal).toFixed(2);

  return { orderTotal, orderSubTotal };
};

export const getGrandTotalPrice = (amountToPay, giftCardsResponse = []) => {
  let grandTotal = 0;
  let redeemedAmount = 0;
  let totalRedeemedAmount = 0;
  let gcRedeemedData = []; //gc = Gift card
  let redeemedGcbalance = [];
  let availableGcBalance = [];

  giftCardsResponse?.forEach((each, index) => {
    const { giftCardBalance = 0, id = "", giftCodeId = "" } = each;
    availableGcBalance.push(giftCardBalance);
    gcRedeemedData.push({ gcNumber: id, giftCodeId: giftCodeId });
  });

  availableGcBalance?.forEach((eachGcBal, index) => {
    let total =
      index === 0 ? amountToPay : amountToPay - availableGcBalance[index - 1];

    redeemedAmount =
      total >= eachGcBal ? eachGcBal : eachGcBal - (eachGcBal - total);

    redeemedGcbalance.push(parseFloat(eachGcBal - redeemedAmount).toFixed(2));
    gcRedeemedData[index].gcAmount = parseFloat(redeemedAmount).toFixed(2);
  });

  for (let i = 0; i < gcRedeemedData?.length; i++) {
    totalRedeemedAmount += Number(gcRedeemedData[i]?.gcAmount);
  }

  grandTotal = amountToPay - totalRedeemedAmount;
  grandTotal = parseFloat(grandTotal).toFixed(2);

  return { grandTotal, gcRedeemedData, redeemedGcbalance };
};

export const getOrderSubTotal = (orderInfo, includeDiscounts = true) => {
  let orderSubTotal = 0;

  orderInfo.price.forEach((price, index) => {
    const { value, discount, discountType } = price;
    const { quantity = 1, isRemoved = false } =
      orderInfo.lineItems[index] || {};

    if (!isRemoved) {
      const originalPrice = parseFloat(getPrice(value) * quantity);
      let finalPrice = originalPrice;

      if (includeDiscounts) {
        const discountAmount = parseFloat(
          getDiscount(discount, value, discountType, quantity)
        );
        finalPrice = originalPrice - discountAmount;
      }

      orderSubTotal += finalPrice;
    }
  });

  return orderSubTotal;
};

export const filterFromList = (list, query) => {
  const key = query.toLocaleLowerCase().trim();
  if (key === "") return list;
  const results = list.filter(
    (item) =>
      item.name.toLocaleLowerCase().includes(key) ||
      item.pid.toLocaleLowerCase().includes(key)
  );
  return results || [];
};

//Compare two objects with some properties
export const isSameInfo = (object1, object2, props) => {
  const keys = props || Object.keys(object1);

  let obj1 = {};
  let obj2 = {};
  keys.forEach((x) => {
    obj1 = {
      ...obj1,
      [x]: object1[x],
    };
    obj2 = {
      ...obj2,
      [x]: object2[x],
    };
  });

  return isEqual(obj1, obj2);
};

// Compares only specific properties of address fields
export const isSameAddress = (address1, address2) => {
  const obj1 = {
    firstName: address1.firstName,
    lastName: address1.lastName,
    addressLine1: address1.addressLine1,
    addressLine2: address1.addressLine2 || "",
    city: address1.city,
    state: address1.state,
    country: address1.country,
    zip: address1.zip,
    phone: address1.phone || "",
    locationName: address1.locationName || "",
    locationType: address1.locationType || "",
  };

  const obj2 = {
    firstName: address2.firstName,
    lastName: address2.lastName,
    addressLine1: address2.addressLine1,
    addressLine2: address2.addressLine2 || "",
    city: address2.city,
    state: address2.state,
    country: address2.country,
    zip: address2.zip,
    phone: address2.phone || "",
    locationName: address2.locationName || "",
    locationType: address2.locationType || "",
  };
  return isEqual(obj1, obj2);
};

export const isEligibleForRush = (deliveryDate, rushSubmitBy) => {
  if (!deliveryDate || !rushSubmitBy) return false;
  return isToday(deliveryDate)
    ? moment().isBefore(rushSubmitBy)
      ? true
      : false
    : true;
};

export const deliveryMethodsKeyToValue = {
  FLORIST_DELIVERED: "Florist Delivered",
  STORE_PICKUP: "Pickup",
  WALK_IN: "Walk-In",
  PARTNER_STORE_PICKUP: "Partner Store Pickup",
  DELIVERY_SERVICE: "Delivery Service",
  FLORIST_PARTNER: "Wire Out",
  MOL_FLORIST_DELIVERED: "MOL Florist Delivered",
  MOL_CUSTOMER_PICKUP: "MOL Customer Pickup",
  FOL_FLORIST_DELIVERED: "FOL Florist Delivered",
  PHONE_OUT: "Phone Out",
};

export const getCountyForGivenAddress = async (address, index) => {
  const { addressLine1, city, state, zipCode, country } = address;

  let formattedAddress = `${encodeURIComponent(
    addressLine1
  )},${encodeURIComponent(city)},${state},${zipCode},${country}`;

  return await request("google-geocode", { address: formattedAddress }).then(
    (response = {}) => {
      let county = "";

      const address_components =
        (response?.results &&
          response?.results.length > 0 &&
          response?.results[0]?.address_components) ||
        [];

      address_components.map((item) => {
        item?.types?.map((type) => {
          if (["administrative_area_level_2"].includes(type)) {
            county = item.long_name ? item.long_name : item.short_name;
          }
        });
      });

      county = county && county.replace("County", "");

      return Promise.resolve({
        index,
        county: county.trim(),
      });
    }
  );
};

export const getTaxAmounts = (
  taxes,
  orderItemTotal,
  formattedDeliveryFee,
  formattedRelayFee,
  deliveryMethod,
  shopPreferences
) => {
  const { taxSplits = [] } = taxes;
  const {
    tax_on_local_delivery_fee,
    tax_on_outside_local_delivery_fee,
    tax_on_relay_fee,
  } = shopPreferences;

  const applyTaxOnDF = get(shopPreferences, "tax_delivery_fee", "false");

  let applyTaxOnLocalDF = tax_on_local_delivery_fee || applyTaxOnDF;
  applyTaxOnLocalDF = applyTaxOnLocalDF === "true";

  let applyTaxOnOutsideLocalDF =
    tax_on_outside_local_delivery_fee || applyTaxOnDF;
  applyTaxOnOutsideLocalDF = applyTaxOnOutsideLocalDF === "true";

  let applyTaxOnRelayFee = tax_on_relay_fee || applyTaxOnDF;
  applyTaxOnRelayFee = applyTaxOnRelayFee === "true";

  let taxAmounts = [];

  let deliveryFeeTax = 0;
  let relayFeeTax = 0;
  let totalDeliveryFeeTax = 0;
  let totalRelayFeeTax = 0;

  if (taxSplits.length > 0) {
    taxAmounts =
      taxSplits?.map((taxInfo) => {
        let newObj = {};
        for (let prop of Object.keys(taxInfo)) {
          let taxInfoRate = taxInfo["rate"];

          let productTax = parseFloat(
            (taxInfoRate * orderItemTotal).toFixed(5)
          );

          let feeTax = parseFloat((taxInfo["amount"] - productTax).toFixed(5));

          let calcLocalDFTax = applyTaxOnLocalDF
            ? parseFloat((taxInfoRate * formattedDeliveryFee).toFixed(5))
            : 0;

          let calcOutsideLocalDFTax = applyTaxOnOutsideLocalDF
            ? parseFloat((taxInfoRate * formattedDeliveryFee).toFixed(5))
            : 0;

          deliveryFeeTax = ["FLORIST_DELIVERED"].includes(deliveryMethod)
            ? calcLocalDFTax
            : ["FLORIST_PARTNER", "PHONE_OUT"].includes(deliveryMethod)
            ? calcOutsideLocalDFTax
            : 0;

          relayFeeTax = applyTaxOnRelayFee
            ? parseFloat((taxInfoRate * formattedRelayFee).toFixed(5))
            : 0;

          newObj = {
            ...newObj,
            [camelCase(`tax ${prop}`)]: taxInfo[prop],
            productTaxAmount: productTax,
            feeTaxAmount: feeTax,
            deliveryFeeTaxAmount: deliveryFeeTax,
            relayFeeTaxAmount: relayFeeTax,
          };
        }
        totalDeliveryFeeTax += deliveryFeeTax;
        totalRelayFeeTax += relayFeeTax;

        return newObj;
      }) || [];
  } else {
    taxAmounts = [
      {
        taxType: "State",
        taxRate: 0,
        taxAmount: 0,
        taxDescription: "State tax",
        productTaxAmount: 0,
        feeTaxAmount: 0,
        deliveryFeeTaxAmount: 0,
        relayFeeTaxAmount: 0,
      },
      {
        taxType: "County",
        taxRate: 0,
        taxAmount: 0,
        taxDescription: "County tax",
        productTaxAmount: 0,
        feeTaxAmount: 0,
        deliveryFeeTaxAmount: 0,
        relayFeeTaxAmount: 0,
      },
      {
        taxType: "City",
        taxRate: 0,
        taxAmount: 0,
        taxDescription: "City tax",
        productTaxAmount: 0,
        feeTaxAmount: 0,
        deliveryFeeTaxAmount: 0,
        relayFeeTaxAmount: 0,
      },
      {
        taxType: "Zip",
        taxRate: 0,
        taxAmount: 0,
        taxDescription: "Zip tax",
        productTaxAmount: 0,
        feeTaxAmount: 0,
        deliveryFeeTaxAmount: 0,
        relayFeeTaxAmount: 0,
      },
    ];
  }
  return {
    taxAmounts,
    totalDeliveryFeeTax: parseFloat(totalDeliveryFeeTax.toFixed(5)),
    totalRelayFeeTax: parseFloat(totalRelayFeeTax.toFixed(5)),
  };
};

export const getFeesInfo = (
  formattedRetailDeliveryFee,
  formattedRelayFee,
  formattedDeliveryFee,
  orderItemTotalFee
) => {
  return {
    applicableCharges: {
      surCharges: [
        {
          id: 0,
          name: "retailDeliveryFee",
          type: "Additional",
          feeType: "SC",
          value: parseFloat(formattedRetailDeliveryFee),
          valueType: "FIXED",
          promotionId: 1,
          adjustmentId: 1,
          adjustmentCode: "",
          category: "",
        },
        {
          id: 0,
          name: "relayFee",
          type: "Additional",
          feeType: "SC",
          value: parseFloat(formattedRelayFee),
          valueType: "FIXED",
          promotionId: 1,
          adjustmentId: 1,
          adjustmentCode: "",
          category: "",
        },
      ],
      deliveryCharges: [
        {
          id: 0,
          name: "vendor",
          type: "Standard",
          feeType: "DC",
          value: parseFloat(formattedDeliveryFee),
          valueType: "FIXED",
          promotionId: 1,
          adjustmentId: 1,
          adjustmentCode: "",
        },
        {
          id: 0,
          name: "morning",
          type: "Additional",
          feeType: "DC",
          value: 0.0,
          valueType: "FIXED",
          promotionId: 1,
          adjustmentId: 1,
          adjustmentCode: "",
        },
      ],
      totalApplicableCharges: formatPrice(orderItemTotalFee),
    },
    discountedCharges: {
      surCharges: [],
      deliveryCharges: [],
      totalDiscountedCharges: 0.0,
    },
  };
};

export const getLineItemAmounts = (value, quantity, discountAmount) => {
  return [
    {
      name: "retailProductAmount",
      value: formatPrice(value * quantity),
    },
    {
      name: "saleProductAmount",
      value: formatPrice(value * quantity),
    },
    {
      name: "discountedProductAmount",
      value: formatPrice(parseFloat(value * quantity) - discountAmount),
    },
    {
      name: "accessoryAmount",
      value: "0.00",
    },
    {
      name: "discountedAccessoryAmount",
      value: "0.00",
    },
    {
      name: "discountAmount",
      value: formatPrice(discountAmount),
    },
    {
      name: "productDiscountAmount",
      value: formatPrice(discountAmount),
    },
    {
      name: "accessoryDiscountAmount",
      value: "0.00",
    },
    {
      name: "savings",
      value: "0.00",
    },
    {
      name: "amountChargedToCustomer",
      value: formatPrice(parseFloat(value * quantity) - discountAmount),
    },
  ];
};

export const ValidateAndCreateOrder = ({
  values,
  createOrderReqObj,
  redeemedGiftCardsData,
  setCurrentPaymentMethod,
  formikBag,
  setAuthorizeGcPaymentMethod,
  showError,
  createOrderSuccessCallback,
  createOrderErrorCallback,
  setFormSubmitting,
  setIsGcAuthorizationSuccess = () => {},
  isEditOrder = false,
  setPaymentStatusDetails = () => {},
  dispatch,
  isSubscription = false,
  subscriptionData,
}) => {
  const hasCreditCardPayment = get(
    values,
    "paymentDetails.paymentMethod",
    []
  )?.some((eachPayment) => {
    const { paymentMethodType } = eachPayment;
    return ["CREDIT_CARD", "SAVED_CARD"].includes(paymentMethodType);
  });

  const hasGCApplied = redeemedGiftCardsData?.length !== 0;

  const hasTerminalPayment = get(
    values,
    "paymentDetails.paymentMethod",
    []
  )?.some((eachPayment) => {
    const { paymentMethodType } = eachPayment;
    return ["PAYMENT_TERMINAL"].includes(paymentMethodType);
  });

  const paymentMethods = get(
    createOrderReqObj,
    "paymentDetails.paymentMethod",
    []
  );
  const isAllCCProcessed = isAllPaymentsProcessed(paymentMethods);

  // GC Payment success callback
  const gcSuccessCallback = ({
    validateResp,
    hasCreditCardPayment,
    hasTerminalPayment,
    CCSuccessCallback = () => {},
    CCErrorCallback = () => {},
  }) => {
    setAuthorizeGcPaymentMethod(false);

    if (hasTerminalPayment && !isAllCCProcessed) {
      setFormSubmitting(true);
      setIsGcAuthorizationSuccess(true);

      TerminalPayment({
        values,
        paymentMethods: get(
          createOrderReqObj,
          "paymentDetails.paymentMethod",
          []
        ),
        successCallback: createOrderSuccessCallback,
        errorCallback: createOrderErrorCallback,
        orderId: validateResp.orderId,
        setCurrentPaymentMethod,
        createOrderReqObj,
        formikBag,
        showError,
        isGcFlow: true,
        redeemedGiftCardsData,
        setPaymentStatusDetails,
        dispatch,
      });
      // handleTerminalPayment({ validateResp });
    } else if (hasCreditCardPayment && !isAllCCProcessed) {
      setIsGcAuthorizationSuccess(true);
      TokenizeAndAuthorizePaymentV2({
        values,
        orderId: get(values, `merchantReferenceId`, validateResp.orderId),
        paymentMethods: get(
          createOrderReqObj,
          "paymentDetails.paymentMethod",
          []
        ),
        successCallback: CCSuccessCallback,
        errorCallback: CCErrorCallback,
        onlyTokenize: false,
        setCurrentPaymentMethod,
        formikBag,
      });
    } else {
      setFormSubmitting(true);
      createOrder(
        createOrderReqObj,
        (response, formikBag) => {
          createOrderSuccessCallback(response, formikBag, createOrderReqObj);
        },
        ({ error, formikBag }) => {
          createOrderErrorCallback({ error, formikBag, createOrderReqObj });
        },
        formikBag
      );
    }
  };

  // GC Payment error callback
  const gcErrorCallback = ({ error, isFormikError = false }) => {
    setCurrentPaymentMethod("");
    setAuthorizeGcPaymentMethod(false);
    if (isFormikError) {
      formikBag.setErrors(error);
      setFormSubmitting(false);
      formikBag.setSubmitting(false);
    } else {
      showError(
        error || "Gift Card Payment failed to process, please try again.",
        formikBag
      );
    }
  };

  // CC Payment success callback
  const CCSuccessCallback = ({
    index = 0,
    orderId,
    authorizedPaymentDetails = {},
    createOrder: doCreateOrder = false,
    stopPaymentProcessing = false,
    formikBag,
  }) => {
    const { authDetails, tokenId } = authorizedPaymentDetails;

    formikBag.setFieldValue(
      `paymentDetails.paymentMethod.${index}.paymentProcessed`,
      true
    );
    formikBag.setFieldValue(
      `paymentDetails.paymentMethod.${index}.paymentFailed`,
      false
    );
    formikBag.setFieldValue(
      `paymentDetails.paymentMethod.${index}.paymentMethodDetails.authorizationDetails`,
      authDetails
    );
    set(
      createOrderReqObj,
      `paymentDetails.paymentMethod.${index}.paymentMethodDetails.authorizationDetails`,
      authDetails
    );
    const { paymentMethodType, savePayment } = get(
      createOrderReqObj,
      `paymentDetails.paymentMethod.${index}`,
      {}
    );
    if (savePayment || paymentMethodType === "SAVED_CARD") {
      tokenId !== "" &&
        set(
          createOrderReqObj,
          `paymentDetails.paymentMethod.${index}.paymentMethodDetails.tokenId`,
          tokenId
        );
    }
    //store processed payments in state to warn when user navigates away before creating order
    const updatedValues = cloneDeep(values);
    set(
      updatedValues,
      `paymentDetails.paymentMethod.${index}.paymentProcessed`,
      true
    );
    set(
      updatedValues,
      `paymentDetails.paymentMethod.${index}.paymentFailed`,
      false
    );
    set(
      updatedValues,
      `paymentDetails.paymentMethod.${index}.paymentMethodDetails.authorizationDetails`,
      authDetails
    );

    setPaymentStatusDetails({
      ...updatedValues,
      merchantReferenceId: orderId,
    });

    if (stopPaymentProcessing) {
      //Processing payment modal will be hidden
      setCurrentPaymentMethod("");
    }

    if (doCreateOrder && !isSubscription) {
      setIsGcAuthorizationSuccess(false);
      setFormSubmitting(true);
      createOrder(
        createOrderReqObj,
        (response, formikBag) => {
          createOrderSuccessCallback(response, formikBag, createOrderReqObj);
        },
        ({ error, formikBag }) => {
          createOrderErrorCallback({ error, formikBag, createOrderReqObj });
        },
        formikBag
      );
    } else if (isSubscription) {
      // getting the paymentMethodType from the values because in the values we will be having the original value SAVED_CARD / CREDIT_CARD
      const creditCardId = get(
        values,
        "customerInfo.firstPaymentInfo.creditCardId",
        ""
      );

      setCurrentPaymentMethod("");
      setFormSubmitting(true);
      dispatch(
        upsertSubscription({
          params: {
            creditCardId,
            createOrderReqObj,
            subscriptionData,
          },
          resolve: (response) => {
            createOrderSuccessCallback(response, formikBag, createOrderReqObj);
          },
          reject: (err) => {
            createOrderErrorCallback({ err, formikBag, createOrderReqObj });
          },
        })
      );
    }
  };

  // CC Payment error callback
  const CCErrorCallback = ({
    index = 0,
    error = isFormikError ? {} : "",
    isFormikError = false,
    stopPaymentProcessing = false,
    formikBag,
  }) => {
    if (stopPaymentProcessing) {
      setCurrentPaymentMethod("");
    }
    setIsGcAuthorizationSuccess(false);
    redeemedGiftCardsData?.length > 0 &&
      authorizeGiftCardPayment({
        values,
        redeemedGiftCardsData,
        action: "REINSTATE",
      });

    if (isFormikError) {
      formikBag.setErrors(error);
      setFormSubmitting(false);
      formikBag.setSubmitting(false);
    } else {
      formikBag.setFieldValue(
        `paymentDetails.paymentMethod.${index}.paymentProcessed`,
        false
      );
      formikBag.setFieldValue(
        `paymentDetails.paymentMethod.${index}.paymentFailed`,
        true
      );
      showError(
        error || "Payment failed to process, please try again.",
        formikBag,
        false
      );
    }
  };

  // GC Payment authorization
  const handleAuthorizeGCPayment = ({
    validateResp,
    hasCreditCardPayment,
    hasTerminalPayment,
  }) => {
    setAuthorizeGcPaymentMethod(true);
    authorizeGiftCardPayment({
      values,
      redeemedGiftCardsData,
      gcSuccessCallback,
      gcErrorCallback,
      validateResp,
      action: "REDEEMED",
      isEditOrder,
      hasCreditCardPayment,
      hasTerminalPayment,
      CCSuccessCallback,
      CCErrorCallback,
    });
  };

  const triggerPostValidate = (validateResp) => {
    const merchantReferenceId = !isSubscription
      ? validateResp?.orderId
      : `${moment()}${getRandomInt(0, 9)}`;
    if (!get(values, `merchantReferenceId`, "")) {
      formikBag.setFieldValue(`merchantReferenceId`, merchantReferenceId);
    }

    // If we have merchantRefId already earlier we are using same by fetching it from formik values or else we are using that we received in validate api response
    const firstMerchantRefId = get(
      values,
      `merchantReferenceId`,
      merchantReferenceId
    );

    //setting merchantReferenceId/orderId for all paymentMethods
    if (paymentMethods?.length > 0) {
      for (let i = 0; i < paymentMethods?.length; i++) {
        set(
          createOrderReqObj,
          `paymentDetails.paymentMethod.${i}.merchantReferenceId`,
          firstMerchantRefId
        );
      }
    }

    if (hasGCApplied) {
      handleAuthorizeGCPayment({
        validateResp,
        hasCreditCardPayment,
        hasTerminalPayment,
      });
    } else if (hasTerminalPayment && !isAllCCProcessed) {
      TerminalPayment({
        values,
        paymentMethods,
        successCallback: createOrderSuccessCallback,
        errorCallback: createOrderErrorCallback,
        orderId: firstMerchantRefId,
        setCurrentPaymentMethod,
        createOrderReqObj,
        formikBag,
        showError,
        setPaymentStatusDetails,
        dispatch,
        isSubscription,
        subscriptionData,
      });
    } else if (hasCreditCardPayment && !isAllCCProcessed) {
      TokenizeAndAuthorizePaymentV2({
        values,
        paymentMethods,
        orderId: firstMerchantRefId,
        successCallback: CCSuccessCallback,
        errorCallback: CCErrorCallback,
        onlyTokenize: isSubscription ? true : false,
        setCurrentPaymentMethod,
        formikBag,
      });
    } else {
      setFormSubmitting(true);
      !isSubscription
        ? createOrder(
            createOrderReqObj,
            createOrderSuccessCallback,
            createOrderErrorCallback,
            formikBag
          )
        : dispatch(
            upsertSubscription({
              params: {
                createOrderReqObj,
                subscriptionData,
              },
              resolve: (response) => {
                setCurrentPaymentMethod("");
                createOrderSuccessCallback(
                  response,
                  formikBag,
                  createOrderReqObj
                );
              },
              reject: (err) => {
                createOrderErrorCallback({ err, formikBag, createOrderReqObj });
              },
            })
          );
    }
  };

  if (!isSubscription) {
    dispatch(
      validateOrder({
        params: {
          requestPayload: createOrderReqObj,
        },
        resolve: (validateResp = {}) => {
          triggerPostValidate(validateResp);
        },
        reject: (error) => {
          createOrderErrorCallback({ error, formikBag });
        },
      })
    );
  } else {
    triggerPostValidate();
  }
};

export const createOrder = async (
  createOrderReqObj,
  successCallback = () => {},
  errorCallback = () => {},
  formikBag
) => {
  request("create-order", {
    createOrderReqObj,
  })
    .then((response) => {
      successCallback(response, formikBag);
    })
    .catch((error) => {
      errorCallback({ error, formikBag, doRefund: true });
    });
};

export const getError = (error) => {
  let errorMessage = "";

  if (error === "CREDIT_CARD_PROCESS_FAILURE") {
    errorMessage =
      "There was a credit card processing error. Contact us at (800) 788-9000 to resolve the issue.";
  } else if (error === "CUSTOMER_ALREADY_EXISTS") {
    errorMessage =
      "Customer already exists. Please select customer from the Customer Directory to proceed.";
  } else if (error === "ERROR" || error === "CATCH ALL ERROR") {
    errorMessage = "Failed to submit the order(s). Please try again.";
  } else {
    errorMessage = error || "Failed to submit the order(s). Please try again.";
  }
  return errorMessage;
};

export const getPaymentInfo = (values = {}, paymentMethod = {}, index = 0) => {
  try {
    let {
      paymentMethodType,
      paymentMethodDetails,
      paymentProcessed = false,
    } = paymentMethod ||
    get(values, `paymentDetails.paymentMethod.${index}`, {});

    let details = {};
    if (paymentMethodType === "CREDIT_CARD") {
      const {
        actualCardNumber = "",
        expDate,
        name,
        authorizationDetails = {},
      } = paymentMethodDetails;
      const [expirationMonth, expirationYear] = expDate.split("/");
      const trimmedCardNumber = actualCardNumber.split(" ").join("");
      details = {
        nameOnCard: name,
        creditCardType: getCardType(actualCardNumber)?.toUpperCase(),
        creditCardNumber: trimmedCardNumber.substring(
          trimmedCardNumber.length - 4
        ),
        creditCardExpireMonth: expirationMonth,
        creditCardExpireYear: expirationYear,
        creditCardEncrypted: false,
        ...(!isEmpty(authorizationDetails) && { authorizationDetails }),
      };
    } else if (paymentMethodType === "SAVED_CARD") {
      const { cardNumber = "", authorizationDetails = {} } =
        paymentMethodDetails;
      const { cardType, expirationYear, expirationMonth, nameOnCard } = get(
        values,
        "customerInfo.firstPaymentInfo",
        {}
      );
      details = {
        ...details,
        nameOnCard,
        creditCardType: cardType,
        creditCardNumber: cardNumber.substring(cardNumber.length - 4),
        creditCardExpireMonth: expirationMonth,
        creditCardExpireYear: expirationYear,
        creditCardEncrypted: false,
        ...(!isEmpty(authorizationDetails) && { authorizationDetails }),
      };
    } else if (paymentMethodType === "CASH_OR_CHECK") {
      const { changeDueAmount, tenderedAmount } = paymentMethodDetails;
      details = {
        tenderedAmount,
        changeDueAmount,
      };
    } else if (
      paymentMethodType === "PAID_ELSEWHERE" ||
      paymentMethodType === "PAY_LATER"
    ) {
      const { amount, note } = paymentMethodDetails;
      details = {
        amount,
        note,
      };
    }

    return {
      paymentMethodType,
      paymentMethodDetails:
        paymentProcessed && paymentMethodType === "PAYMENT_TERMINAL"
          ? paymentMethodDetails
          : details,
    };
  } catch (error) {
    console.log("GetPaymentInfo Error -- ", error);
  }
};

export const getRefundAmount = (paymentDetails) => {
  let refundAmount = 0;
  paymentDetails?.paymentMethod?.forEach((paymentType, index) => {
    const { refundDetails = [] } = paymentType?.paymentMethodDetails;
    refundDetails.forEach((each, idx) => {
      const { processFlag: refundStatusLocal = "", amount } =
        refundDetails[idx] || {};
      if (refundStatusLocal === "PROCESSED" || refundStatusLocal === "NEW") {
        refundAmount += amount;
      }
    });
  });
  return refundAmount;
};

export const getPaidAndUnpaidAmounts = (paymentDetails) => {
  let paymentsReceived = 0;
  let unpaidAmount = 0;

  const paymentMethods = get(paymentDetails, "paymentMethod", []);
  const invoiceOrderDetails =
    paymentMethods?.find((obj) => obj?.invoiceOrderPaymentInfo) ||
    paymentMethods[0];

  const {
    paymentMethodType = "",
    invoiceOrderPaymentInfo: {
      // isOrderCancelled = false,
      // isPaymentCompleted = false,
      payments = [],
      orderTotalAmount = 0,
      refunds = [],
    } = {},
  } = invoiceOrderDetails;

  const refundAmount = refunds.reduce(
    (acc, cur) => acc + parseFloat(cur.refundedAmount),
    0
  );

  if (paymentMethodType === "INVOICE") {
    paymentsReceived = payments.reduce(
      (acc, cur) => acc + parseFloat(cur.paymentAmount),
      0
    );
  }

  unpaidAmount = orderTotalAmount - refundAmount - paymentsReceived;
  return { paymentsReceived, unpaidAmount };
};

export const getAddressForTax = (
  address,
  floristAddress,
  local_order_tax_settings,
  deliveryType
) => {
  let addressForTax = {
    ...address,
    zipCode: address.zip,
    county: address.county || null,
  };

  if (
    deliveryType !== "FLORIST_DELIVERED" ||
    local_order_tax_settings === "point_of_origin"
  ) {
    const {
      addressLine1: floristAddressLine1 = "",
      city: flroistCity = "",
      state: floristState = "",
      country: floristCountry = "",
      county: floristCounty = null,
      zip: floristZip = "",
    } = floristAddress;
    addressForTax = {
      addressLine1: floristAddressLine1,
      city: flroistCity,
      state: floristState,
      country: floristCountry,
      zipCode: floristZip,
      county: floristCounty,
    };
  }
  return addressForTax;
};

export const hasSplitAmountError = ({ values, amounts = [], grandTotal }) => {
  return (
    get(values, "paymentDetails.paymentMethod.0.enablePayment", false) &&
    amounts?.length > 0 &&
    !isNaN(sum(amounts)) &&
    Number(formatPrice(sum(amounts))) != Number(grandTotal) &&
    grandTotal != 0.0 &&
    !inEligibleSplitPaymentOptions.includes(
      get(values, "paymentDetails.paymentMethod.0.paymentMethodType", "")
    )
  );
};

//Retrun Promise for voidCCAuthorization request
export const voidCCPayment = ({ values = {}, index = 0 }) => {
  const authorizationDetails = get(
    values,
    `paymentDetails.paymentMethod.${index}.paymentMethodDetails.authorizationDetails`,
    []
  );
  const actualCardNumber = get(
    values,
    `paymentDetails.paymentMethod.${index}.paymentMethodDetails.actualCardNumber`,
    ""
  );
  let cardType = "";
  if (
    get(
      values,
      `paymentDetails.paymentMethod.${index}.paymentMethodType`,
      ""
    ) === "SAVED_CARD"
  ) {
    const { cardType: customerCardType = "" } = get(
      values,
      "customerInfo.firstPaymentInfo",
      {}
    );
    cardType = customerCardType;
  }

  const reqObj = {
    requestPayload: {
      //dynamic attributes
      amount: get(values, `paymentDetails.paymentMethod.${index}.amount`, ""),
      merchantReferenceId: get(values, `merchantReferenceId`, ""),
      partnerCode:
        get(values, `sendingMember`, "") || get(values, `memberCode`, ""),
      authorizationTransactionId: authorizationDetails.find(
        (detail) => detail.name === "authorizationTransactionId"
      )?.value,
      paymentType: getCardType(actualCardNumber)?.toUpperCase() || cardType,
      //static attributes
      currency: "USD",
      isRetry: true,
      processorAccount: "MHQ",
    },
  };
  return request("void-credit-card-payment", reqObj);
};

export const voidTerminalPayment = ({
  values = {},
  TerminalProcessedPaymentIndices,
}) => {
  const returnStatus = [];
  const voidPayment = ({ index, processingIndex }) => {
    const isHardwareTerminal = get(
      values,
      `paymentDetails.paymentMethod.${processingIndex}.isHardwareTerminal`,
      true
    );

    if (isHardwareTerminal) {
      const authorizationDetails = get(
        values,
        `paymentDetails.paymentMethod.${processingIndex}.paymentMethodDetails.authorizationDetails`,
        []
      );

      const merchantReferenceId =
        authorizationDetails.find((obj) => obj.name === "merchantReferenceId")
          ?.value || "";

      const transactionId =
        authorizationDetails.find((obj) => obj.name === "transactionDetailsId")
          ?.value || "";

      initiateRefundThroughTerminal({
        sendingMember: values.sendingMember,
        merchantReferenceId,
        transactionId,
        resolve: ({ message }) => {
          console.log("message - Refund success :>> ", message);
          returnStatus.push(Promise.resolve());
          if (index < TerminalProcessedPaymentIndices?.length - 1) {
            voidPayment({
              index: index + 1,
              processingIndex: TerminalProcessedPaymentIndices[index + 1],
            });
          }
        },
      });
    }
  };
  voidPayment({
    index: 0,
    processingIndex: TerminalProcessedPaymentIndices[0],
  });
  // returning the final promise
  return returnStatus;
};

export const voidPayments = async ({
  values = {},
  resolve = () => {},
  reject = () => {},
}) => {
  const paymentMethods = get(values, `paymentDetails.paymentMethod`, []);
  const CCProcessedPaymentIndices = [];
  const TerminalProcessedPaymentIndices = [];

  paymentMethods.map((eachPayment, index) => {
    if (
      ["CREDIT_CARD", "SAVED_CARD"].includes(eachPayment.paymentMethodType) &&
      eachPayment.paymentProcessed
    ) {
      CCProcessedPaymentIndices.push(index);
    } else if (
      ["PAYMENT_TERMINAL"].includes(eachPayment.paymentMethodType) &&
      eachPayment.paymentProcessed
    ) {
      TerminalProcessedPaymentIndices.push(index);
    }
  });
  let voidRequests;
  if (CCProcessedPaymentIndices.length > 0) {
    voidRequests = CCProcessedPaymentIndices.map((_, index) => {
      return voidCCPayment({ values, index });
    });
  } else if (TerminalProcessedPaymentIndices.length > 0) {
    voidRequests = await voidTerminalPayment({
      values,
      TerminalProcessedPaymentIndices,
    });
  }
  Promise.all(voidRequests)
    .then(() => {
      resolve();
    })
    .catch(() => {
      reject();
    });
};

// Set recipient state value depending on country selected
export const getRecipientState = (state, country) => {
  const isStateExists = !!states[country]?.some((obj) => obj.value === state);
  const response = ["US", "CA"].includes(country)
    ? isStateExists
      ? state
      : ""
    : country;
  return response;
};
