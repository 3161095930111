import React, { useEffect, useContext, useRef } from "react";
import { View, ScrollView } from "react-native";
import { useRoute } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";

import { ImageLink, Picker, TabBar } from "components/elements";

import { fonts, backgroundColors } from "styles/theme";

import {
  setactiveNotificationTab,
  fetchDefaultNotifications,
} from "library/sagas/views/home/drawer/shop-settings/common/slice";

import {
  setActiveHelpTab,
  setActiveHelpSubTab,
} from "library/sagas/views/home/drawer/help/slice";

import { customFindHelpDocNames } from "./ui-config";

import {
  selectShopCode,
  selectActiveNotificationTab,
  selectNotificationTabNames,
  selectNotificationSettings,
  selectDefaultOLCNotifications,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import SmsSettings from "./sms-settings";
import EmailSettings from "./email-settings";
import { SHOP_SETTINGS } from "library/constants";
import I18NContext from "library/contexts/i18N";
import useStateIfMounted from "library/utils/useStateIfMounted";
import UserProfileContext from "library/contexts/userProfile";
import { getMaxHeight } from "library/utils/formatter";
import MarketingEmailStatusInfo from "./marketing-email-status-info";

const options = [];
for (let i = 0; i <= 99; i++) {
  options.push({ label: i.toString(), value: i.toString() });
}

const Notifications = ({ saveSettings }) => {
  const dispatch = useDispatch();
  const { params: { openOccasionReminder = false } = {} } = useRoute();

  const scrollViewRef = useRef();
  const { messages, Localise } = React.useContext(I18NContext);
  const { proxyUser, suspendedMembers = [] } = useContext(UserProfileContext);
  const shopCode = useSelector(selectShopCode);
  const currentNotificationTab = useSelector(selectActiveNotificationTab);
  const notificationTabs = useSelector(selectNotificationTabNames);
  const notificationSettings = useSelector(selectNotificationSettings);
  const defaultOLCNotifications = useSelector(selectDefaultOLCNotifications);
  const [defaultNotification, setDefaultNotification] = useStateIfMounted({});
  const [notificationStatus, setNotificationStatus] = useStateIfMounted({});
  const [remindInDays, setRemindInDays] = useStateIfMounted(5);

  useEffect(() => {
    if (shopCode) {
      dispatch(fetchDefaultNotifications(shopCode));
    }
  }, [shopCode]);

  const customBorder = () => {
    return (
      <View style={{ marginRight: 10 }}>
        <Text
          style={{
            width: 6,
            height: "100%",
            backgroundColor: backgroundColors.primary,
            border: 0,
            borderTopRightRadius: "90% 40%",
          }}
        ></Text>
        <Text
          style={{
            width: 6,
            height: "100%",
            backgroundColor: backgroundColors.primary,
            border: 0,
            borderBottomRightRadius: "90% 40%",
          }}
        ></Text>
      </View>
    );
  };

  const setCurrentTab = (name) => {
    dispatch(setactiveNotificationTab(name));
  };

  useEffect(() => {
    if (openOccasionReminder) {
      setCurrentTab("birthday_occasion_notification_settings");
      scrollViewRef.current?.scrollTo({
        y: 0,
        animated: true,
      });
    }
  }, [openOccasionReminder]);

  const selectedTabTitle =
    notificationTabs.find((val) => val?.key === currentNotificationTab)
      ?.title || "";

  useEffect(() => {
    const value =
      notificationSettings?.find((obj) => obj?.id === currentNotificationTab)
        ?.values || {};
    setNotificationStatus(value);
    setRemindInDays(value?.remindInDays || 5);

    const defaultValue =
      defaultOLCNotifications?.find((obj) => obj?.id === currentNotificationTab)
        ?.values || {};
    setDefaultNotification(defaultValue);
  }, [currentNotificationTab, notificationSettings, defaultOLCNotifications]);

  const handleOnChange = (value) => {
    if (value === remindInDays) return;
    setRemindInDays(value);
    let preferences = [
      {
        id: currentNotificationTab,
        values: [
          JSON.stringify({
            ...notificationStatus,
            remindInDays: value,
          }),
        ],
      },
    ];

    saveSettings({ preferences }, true, false, () => {
      dispatch(fetchDefaultNotifications(shopCode));
    });
  };

  const maxScrollViewHeight = getMaxHeight(proxyUser, suspendedMembers) - 200;

  const excludedNotificationTabs = [
    "event_proposal_notification_settings",
    "email_ha_invoices_notification_settings",
    "occasion_notification_settings",
  ];

  const isSmsSectionEnabled = !excludedNotificationTabs.includes(
    currentNotificationTab
  );

  const isOccasionNotificaton = [
    "Birthday Reminder",
    "Anniversary Reminder",
  ].includes(selectedTabTitle);

  const isSelectedEmailHaInvoiceTab =
    currentNotificationTab === "email_ha_invoices_notification_settings";

  const displayEmailMsg = isSelectedEmailHaInvoiceTab
    ? SHOP_SETTINGS.EMAIL_HA_INVOICE_TEXT
    : SHOP_SETTINGS.EMAIL_INVOICE_TEXT;

  return (
    <View style={[tw("flex flex-row")]}>
      <TabBar
        tabNames={notificationTabs}
        subTabNames={[
          { key: "sms", title: "SMS" },
          { key: "email", title: "Email" },
        ]}
        subTabImages={{
          enabled: "customised-notification",
          disabled: "default-notification",
        }}
        activeSubTabStyle={{
          color: backgroundColors.primary,
          size: "font-bold",
          margin: 16,
        }}
        inactiveSubTabStyle={{ color: "#c7c7c7" }}
        currentTab={currentNotificationTab}
        setCurrentTab={setCurrentTab}
        scrollTabBar={true}
        showScrollBar={false}
        showsScrollIndicators={true}
        showBorder={true}
        isColumnView={true}
        customBorder={customBorder}
        activeTabStyle={{ size: "font-bold", margin: 16 }}
        inactiveTabStyle={{ color: backgroundColors.primary }}
        tabStyle={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          minHeight: 35,
          marginVertical: 2,
        }}
        style={{
          borderColor: "#c7c7c7",
          backgroundColor: "#fafafa",
          borderWidth: 1,
        }}
        notificationTabs={true}
        notificationSettings={notificationSettings}
      />
      <View
        style={[
          tw("flex"),
          {
            maxHeight: maxScrollViewHeight,
            width: "45%",
          },
        ]}
      >
        <ScrollView
          showsScrollIndicators={true}
          showScrollBar={true}
          scrollEnabled={true}
          ref={scrollViewRef}
        >
          <View style={[tw("flex flex-row items-center justify-between")]}>
            <Text
              style={[
                fonts.sectionHeading,
                { marginHorizontal: 30, marginTop: 30 },
              ]}
            >
              {Localise(messages, selectedTabTitle)}
            </Text>
            {isSelectedEmailHaInvoiceTab && (
              <ImageLink
                {...{
                  imageName: "help",
                  tooltipMsg: "Help",
                  navigationRoute: {
                    page: "help",
                    params: {
                      search: customFindHelpDocNames[selectedTabTitle],
                    },
                  },
                  onPress: () => {
                    dispatch(setActiveHelpTab("training-materials"));
                    dispatch(setActiveHelpSubTab("mhq-sub-tab"));
                  },
                  testID: `${currentNotificationTab}_help_link`,
                  accessibilityLabel: `${currentNotificationTab}_help_link`,
                  customImageStyle: { marginHorizontal: 30, marginTop: 30 },
                }}
              />
            )}
          </View>
          {isOccasionNotificaton && (
            <React.Fragment>
              <View
                style={[
                  tw("flex flex-row items-center"),
                  { marginHorizontal: 30, marginTop: 15 },
                ]}
              >
                <Text style={{ marginRight: 10 }}>
                  {Localise(messages, "Send Reminder")}
                </Text>
                <Picker
                  containerStyle={{
                    width: 60,
                    height: "auto",
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    marginRight: 10,
                  }}
                  items={options}
                  placeholder={{}}
                  value={remindInDays}
                  onValueChange={handleOnChange}
                ></Picker>
                <Text>{Localise(messages, "days before occasion date")}</Text>
              </View>
              <MarketingEmailStatusInfo />
            </React.Fragment>
          )}

          {isSelectedEmailHaInvoiceTab && (
            <Text style={[{ marginHorizontal: 30, marginTop: 15 }]}>
              {Localise(messages, displayEmailMsg)}
            </Text>
          )}

          {isSmsSectionEnabled && (
            <SmsSettings
              defaultNotification={defaultNotification}
              notificationStatus={notificationStatus}
              saveSettings={saveSettings}
              isOccasionNotificaton={isOccasionNotificaton}
            />
          )}
          <EmailSettings
            defaultNotification={defaultNotification}
            notificationStatus={notificationStatus}
            saveSettings={saveSettings}
          />
        </ScrollView>
      </View>
    </View>
  );
};

export default Notifications;
