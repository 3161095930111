import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { View } from "react-native";

import SubHeader from "components/containers/header/sub-header";
import SingleScreen from "components/containers/single-screen";
import UpsertScreen from "components/views/drawer/customer-directory/upsert-screen";

import { setCurrentCustomer } from "library/sagas/views/home/drawer/customer-directory/slice";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

import tw from "tailwind-rn";

const CreateCustomer = () => {
  const dispatch = useDispatch();
  const { isDesktop } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);

  useEffect(() => {
    dispatch(setCurrentCustomer());
  }, []);

  return (
    <>
      {!isDesktop && <SubHeader />}
      <SingleScreen title={Localise(messages, "Create Customer Profile")}>
        <View style={[tw("mt-2"), { maxWidth: 768 }]}>
          <UpsertScreen action="create" />
        </View>
      </SingleScreen>
    </>
  );
};

export default CreateCustomer;
