export const getUIConfig = (Localise, messages) => [
  {
    category: "Global Navigation",
    component: "mainNavigation",
    description: Localise(
      messages,
      `Changing these navigation will change settings on all sites that allow global access`
    ),
    assetId: "json",
    context: "global",
    title: Localise(messages, "Global Main Menu"),
    subTitle2: Localise(
      messages,
      `${"Changes made in this screen do not auto-apply to these accounts unless selected below"}`
    ),
    subTitle1: Localise(
      messages,
      `${"Changing the details below will change the settings on all sites that allow global access as well as any selected shop list from the tab"}`
    ),
    fieldType: "Navigation",
    fieldProps: {
      menuDepth: 3,
      showCollectionsField: true,
      useGlobalCollectionsData: true,
    },
  },
  {
    category: "Global Navigation",
    component: "footerNavigation",
    description: Localise(
      messages,
      `Changing these navigation will change settings on all sites that allow global access`
    ),
    assetId: "json",
    context: "global",
    title: Localise(messages, "Global Footer Menu"),
    fieldType: "Navigation",
    fieldProps: {
      menuDepth: 1,
    },
  },
  {
    category: "Global Theme Settings",
    component: "homepageBannerMetadata",
    description: Localise(
      messages,
      `These templates offer customers a generic look for their site that they can change at their account level`
    ),
    assetId: "json",
    context: "global",
    title: Localise(messages, "Global Homepage Banner"),
    subTitle2: Localise(
      messages,
      "Changes made in this screen do not auto-apply to these accounts unless selected below"
    ),
    subTitle1: Localise(
      messages,
      "Changing the details below will change the settings on all sites that allow global access as well as any selected shop list from the tab"
    ),
    fieldType: "BannerMetaData",
    fieldProps: {
      dimensions: "1920x1280",
    },
  },
  {
    category: "Global Theme Settings",
    component: "homepageSEO",
    assetId: "json",
    context: "global",
    title: Localise(messages, "Global Homepage SEO"),
    fieldType: "SEOText",
  },
  {
    category: "Global Theme Settings",
    component: "announcementBannerText",
    assetId: "json",
    context: "global",
    title: Localise(messages, "Global Announcement Banner"),
    subTitle2: Localise(
      messages,
      "Changes made in this screen do not auto-apply to these accounts unless selected below"
    ),
    subTitle1: Localise(
      messages,
      "Changing the details below will change the settings on all sites that allow global access as well as any selected shop list from the tab"
    ),
    fieldType: "AnnouncementBanner",
  },
  {
    category: "Global Content Pages",
    description: Localise(
      messages,
      `These are the templates that apply to user sites and they can change at their account level`
    ),
    component: "custom",
    assetId: "json",
    context: "global",
    title: Localise(messages, "Funeral & Sympathy Landing pages"),
    fieldType: "funeralSympathy",
  },
  {
    category: "Global Content Pages",
    component: "custom",
    assetId: "json",
    context: "global",
    title: Localise(messages, "Wedding Landing pages"),
    fieldType: "wedding",
  },
  {
    category: "Global Content Pages",
    component: "about-us",
    assetId: "html",
    context: "global",
    title: Localise(messages, "About Us"),
    fieldType: "HTMLText",
  },
  {
    category: "Global Content Pages",
    component: "contact",
    assetId: "html",
    context: "global",
    title: Localise(messages, "Contact"),
    fieldType: "HTMLText",
  },
  {
    category: "Global Content Pages",
    component: "our-guarantee",
    assetId: "html",
    context: "global",
    title: Localise(messages, "Our Guarantee"),
    fieldType: "HTMLText",
  },
  {
    category: "Global Content Pages",
    component: "privacy-policy",
    assetId: "html",
    context: "global",
    title: Localise(messages, "Privacy Policy"),
    fieldType: "HTMLText",
  },
  {
    category: "Global Content Pages",
    component: "refund-policy",
    assetId: "html",
    context: "global",
    title: Localise(messages, "Refund Policy"),
    fieldType: "HTMLText",
  },
  {
    category: "Global Content Pages",
    component: "substitutionPolicy",
    assetId: "html",
    context: "global",
    title: Localise(messages, "Substitution Policy"),
    fieldType: "HTMLText",
    fieldProps: {
      link: "",
    },
  },
  {
    category: "Global Content Pages",
    component: "terms-of-service",
    assetId: "html",
    context: "global",
    title: Localise(messages, "Terms Of Service"),
    fieldType: "HTMLText",
  },
  {
    category: "MOL Operations Support",
    component: "custom",
    assetId: "custom",
    context: "global",
    title: Localise(messages, "MOL Onboarding (Initial Store Sync)"),
    subTitle1: Localise(
      messages,
      "Enter a FTD Member Number below to sync the theme, products, collections and store settings."
    ),
    fieldType: "sync-store",
  },
  {
    category: "MOL Operations Support",
    component: "custom",
    assetId: "custom",
    context: "global",
    title: Localise(messages, "MOL Operations & Support (Resync)"),
    subTitle1: Localise(
      messages,
      "Enter a FTD Member Number below to resync products and collections, theme or store settings."
    ),
    fieldType: "manual-sync",
  },
  {
    category: "MOL Operations Support",
    component: "botDetectionRules",
    assetId: "json",
    context: "global",
    title: Localise(messages, "Bot Detection Rules (DEV Use Only)"),
    fieldType: "bot-detection-rules",
  },
];
