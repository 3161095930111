import React, { useEffect, useState } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import Search from "components/containers/search";
import { Picker, ToasterHandler, NumericCounter } from "components/elements";
import { BulkActionHeader } from "../header";
import { DeleteCollectionButton } from "./helper";
import {
  PrimaryButton,
  LabelField,
  SearchResults,
  FilterResults,
  SelectAll,
  ConfirmModal,
} from "components/wrappers";
import {
  SortOrderOptions,
  ManualSortOrderOptions,
  AddNewProductsFilters,
} from "./ui-config";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import {
  selectSearchText,
  selectAppliedFilters,
  selectAPIResponse,
  selectProductsSortOrder,
  selectProductsSelected,
  selectBulkActionType,
} from "library/sagas/views/home/drawer/product-catalog/collection/selector";
import { selectProductsSelected as selectAddonsProductsSelection } from "library/sagas/views/home/drawer/product-catalog/catalog/selector";
import {
  setProductsSelection,
  setProductsAction,
  resetProductsAction,
} from "library/sagas/views/home/drawer/product-catalog/collection/slice";
import { setSideCar } from "library/sagas/views/home/drawer/product-catalog/common/slice";
import {
  selectIsCatalogManager,
  selectAllowSyndicate,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";

import { fonts } from "styles/theme";
import tw from "tailwind-rn";
import Filters from "components/containers/listing-new/header/controls/filters";

export const ProductsInCollectionHeader = ({
  positionMaxLimit,
  isCreateCollection,
  loading,
  setLoading,
}) => {
  const catalogManager = useSelector(selectIsCatalogManager);
  const dispatch = useDispatch();
  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile } = React.useContext(DeviceContext);
  const { handle, name: collectionName } = useSelector(selectAPIResponse);

  const buttonProps = {
    width: 150,
    paddingLeft: 10,
    paddingBottom: 6,
  };

  return (
    <View
      style={[
        tw(`flex flex-col justify-between`),
        { minHeight: 55, zIndex: 30 },
      ]}
    >
      <View
        style={tw(
          `flex flex-row  ${
            isMobile ? "flex-wrap" : ""
          } justify-between items-center my-2`
        )}
      >
        <Text style={fonts.heading3}>
          {`${Localise(messages, "Products in Collection")} ${
            positionMaxLimit ? `(${positionMaxLimit})` : ""
          }`}
        </Text>

        {loading && (
          <View
            style={[
              tw("flex flex-row items-center justify-between"),
              { width: 75 },
            ]}
          >
            <ActivityIndicator size={"small"} />
            <Text style={fonts.heading5}>{"Saving..."}</Text>
          </View>
        )}

        {!isCreateCollection && catalogManager && (
          <View style={tw(`flex flex-row ${isMobile ? "mt-2" : ""}`)}>
            <DeleteCollectionButton />

            <PrimaryButton
              title={"Add Products"}
              action={() => dispatch(setSideCar("addProductsToCollection"))}
              style={buttonProps}
            />
          </View>
        )}
      </View>
      <View style={tw("flex flex-row flex-wrap justify-between")}>
        <BulkActionHeader
          setBulkAction={(payload) =>
            setProductsAction({ ...payload, section: "current" })
          }
          selectBulkActionType={selectBulkActionType("current")}
          setProductSelection={(payload) =>
            setProductsSelection({ productId: payload, section: "current" })
          }
          selectProductsSelected={selectProductsSelected("current")}
          screen={"collection"}
          handle={handle}
          name={collectionName}
        />
        <BulkSortControl
          positionMaxLimit={positionMaxLimit}
          setSpinner={setLoading}
        />
      </View>
    </View>
  );
};

export const AddToCollectionsHeader = () => {
  const { isDesktop } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);

  const selectAddNewSearchText = selectSearchText("new");
  const setAddNewSearchText = (payload) =>
    setProductsAction({ ...payload, section: "new" });

  const setNewProductsAction = (payload) =>
    setProductsAction({ ...payload, section: "new" });

  const searchProps = {
    type: "fullWidth",
    containerStyle: {
      marginBottom: isDesktop ? 0 : 6,
      paddingRight: 10,
      paddingLeft: 0,
      width: "100%",
    },
    icon: "search",
    placeholder: Localise(messages, "Search Products"),
    selectors: {
      selectValue: selectAddNewSearchText,
    },
    actions: {
      setAction: setAddNewSearchText,
    },
  };

  const filterProps = {
    width: "5%",
    icon: "filter",
    options: {
      "Filter By": AddNewProductsFilters(Localise, messages),
    },
    selectors: {
      selectValue: selectAppliedFilters("new"),
    },
    actions: {
      setAction: setNewProductsAction,
    },
  };

  return (
    <View style={tw("flex flex-col")}>
      <View style={tw("flex flex-col")}>
        <Search {...searchProps} />

        <SearchResults
          selectSearchText={selectAddNewSearchText}
          setSearchText={setAddNewSearchText}
        />
      </View>
      <View style={tw("flex flex-row flex-wrap")}>
        <BulkAddProductsToCollectionControl
          setBulkAction={(payload) =>
            setProductsAction({ ...payload, section: "new" })
          }
          setProductSelection={(payload) =>
            setProductsSelection({ productId: payload, section: "new" })
          }
          selectProductsSelected={selectProductsSelected("new")}
        />
        <View style={{ marginTop: 25, marginLeft: 10, marginRight: 25 }}>
          <Filters {...filterProps} />
        </View>
        {isDesktop ? (
          <View style={tw("flex flex-row")}>
            <FilterResults
              input={selectAppliedFilters("new")}
              action={setNewProductsAction}
            />
          </View>
        ) : null}
      </View>
    </View>
  );
};

const BulkAddProductsToCollectionControl = ({
  setBulkAction,
  setProductSelection,
  selectProductsSelected,
}) => {
  const dispatch = useDispatch();
  const allowSyndicate = useSelector(selectAllowSyndicate);
  const { handle, name: collectionName } = useSelector(selectAPIResponse);
  const selectedProducts = useSelector(selectProductsSelected);
  const { Localise, messages } = React.useContext(I18NContext);
  const [selectedAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const resetHeader = () => {
    setLoading(false);
    setSelectAll(false);
    dispatch(setProductSelection(""));
  };

  const onApply = () => {
    setLoading(true);

    dispatch(
      setBulkAction({
        type: "bulkActionType",
        value: `collections::${handle}::add`,
        resolve: (message) => {
          resetHeader();

          ToasterHandler(
            "nice",
            Localise(
              messages,
              message ? message : `${collectionName} has been updated`
            )
          );
        },
        reject: (message) => {
          resetHeader();

          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              message ? message : `Your update didn‘t save, please try again.`
            )
          );
        },
      })
    );
  };

  return (
    <View style={tw("flex flex-row items-center my-5")}>
      <SelectAll
        value={!!selectedAll}
        onChange={(value) => {
          setSelectAll(!selectedAll);
          dispatch(setProductSelection(value ? "all" : ""));
        }}
      />
      {selectedProducts.length > 0 && (
        <Text
          style={{ ...fonts.link1, marginLeft: 6 }}
          onPress={() => {
            dispatch(setProductSelection(""));
            setSelectAll(false);
          }}
        >
          Deselect
        </Text>
      )}
      <PrimaryButton
        style={{ paddingHorizontal: 5, marginLeft: 10 }}
        title={Localise(messages, "Add To Collection")}
        action={allowSyndicate ? () => setShowPopup(!showPopup) : onApply}
        disabled={!selectedProducts.length}
        loading={loading}
      />
      <ConfirmModal
        modalVisible={!!showPopup}
        handlePrimary={() => {
          onApply();
          setShowPopup(!showPopup);
        }}
        handleSecondary={() => {
          setShowPopup(!showPopup);
        }}
        data={{
          modal: {
            primary: "Update",
            content: Localise(
              messages,
              "This change will apply to All shops and will override any local shop changes."
            ),
          },
        }}
      />
    </View>
  );
};

export const BulkSortControl = ({
  positionMaxLimit,
  setSpinner,
  handle = "",
}) => {
  const dispatch = useDispatch();
  const allowSyndicate = useSelector(selectAllowSyndicate);

  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile, isDesktop } = React.useContext(DeviceContext);
  const { sortOrder } = useSelector(selectProductsSortOrder);

  const selectedProducts =
    handle !== "addons"
      ? useSelector(selectProductsSelected("current"))
      : useSelector(selectAddonsProductsSelection);

  const sortOptions = SortOrderOptions(Localise, messages);
  const manualSortOptions = ManualSortOrderOptions(Localise, messages);

  const [moveType, setMoveType] = useState("");
  const [position, setMovePosition] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showOrder, setShowOrder] = useState(sortOrder);
  const [showPopup, setShowPopup] = useState(false);

  const sortText = `Sort order below is${
    !sortOrder || sortOrder.includes("none") ? " not" : ""
  } shown on shopify website`;

  useEffect(() => {
    if (!loading) {
      dispatch(setProductsSelection({ section: "current", productId: "" }));
      setMoveType("");
      setMovePosition("");
    }
    setSpinner(loading);
  }, [loading]);

  useEffect(() => {
    if (showOrder === sortOrder) return;
    allowSyndicate ? setShowPopup(!showPopup) : onSort();
  }, [showOrder]);

  useEffect(() => {
    if (handle === "addons" && showOrder !== sortOrder) {
      setShowOrder(sortOrder);
    }
  }, [handle, sortOrder]);

  const onSort = () => {
    setLoading(true);

    const value = showOrder;
    const extraValue = `${moveType}${
      moveType === "moveToPos" ? `::${position}` : ""
    }`;

    dispatch(
      setProductsAction({
        section: "current",
        type: "sortOrder",
        value,
        extraValue,
        resolve: (message) => {
          setLoading(false);

          ToasterHandler(
            "nice",
            Localise(
              messages,
              message ? message : "Sort order updated successfully"
            )
          );

          if (value === "manually") {
            dispatch(
              resetProductsAction({
                section: "current",
                type: "sortOrder",
                value,
              })
            );
          }
        },
        reject: (message) => {
          setLoading(false);

          ToasterHandler(
            "oh oh",
            Localise(
              messages,
              message ? message : `Sort order failed, please try again.`
            )
          );

          if (value === "manually") {
            dispatch(
              resetProductsAction({
                section: "current",
                type: "sortOrder",
                value,
              })
            );
          }
        },
      })
    );
  };

  return (
    <View style={[tw("flex flex-col flex-wrap"), { zIndex: -1 }]}>
      <View
        style={tw(
          `flex ${
            isMobile ? "flex-col items-start" : "flex-row  items-center mt-5"
          } `
        )}
      >
        <View style={tw("flex flex-row items-center")}>
          <Text style={[tw(` ${isMobile ? "pr-2" : "pr-4"}`), fonts.default]}>
            {Localise(messages, "Sort Order")}
          </Text>
          <Picker
            containerStyle={{
              paddingHorizontal: 0,
              paddingBottom: 0,
              width: 160,
              height: 35,
            }}
            items={sortOptions}
            placeholder={{}}
            value={showOrder}
            onValueChange={(e) => {
              setShowOrder(e);
            }}
          ></Picker>
        </View>
        <View
          style={tw(
            `flex flex-row  items-center ${
              isMobile ? "mt-1 justify-between" : ""
            }`
          )}
        >
          {sortOrder === "manually" && (
            <>
              <Picker
                containerStyle={{
                  paddingHorizontal: 0,
                  paddingBottom: 0,
                  width: isMobile ? 175 : 145,
                  marginHorizontal: 4,
                  marginLeft: isMobile ? 0 : 8,
                }}
                items={manualSortOptions}
                placeholder={{}}
                value={moveType}
                onValueChange={(e) => setMoveType(e)}
                disabled={selectedProducts.length === 0}
              ></Picker>
              {moveType === "moveToPos" && (
                <>
                  <NumericCounter
                    isMobile={isMobile}
                    isDesktop={isDesktop}
                    minValue={1}
                    maxValue={positionMaxLimit}
                    value={position}
                    editable={!!selectedProducts.length}
                    onChange={(val) => setMovePosition(val)}
                  />
                  <Text style={fonts.default}> of {positionMaxLimit}</Text>
                </>
              )}

              <View style={[tw(`flex flex-row items-center px-1`)]}>
                <PrimaryButton
                  style={{
                    paddingLeft: isMobile ? 0 : 10,
                    width: 90,
                  }}
                  disabled={selectedProducts.length === 0 || moveType === ""}
                  action={onSort}
                  title={Localise(messages, "Move")}
                  loading={loading}
                />
              </View>
            </>
          )}
        </View>
      </View>
      <View style={[tw("flex mt-1 flex-wrap"), { maxWidth: 253 }]}>
        <LabelField text={sortText} style={fonts.style2} numberOfLines={2} />
      </View>
      <ConfirmModal
        modalVisible={!!showPopup}
        handlePrimary={() => {
          onSort();
          setShowPopup(!showPopup);
        }}
        handleSecondary={() => {
          setShowPopup(!showPopup);
        }}
        data={{
          modal: {
            primary: "Update",
            content: Localise(
              messages,
              "This change will apply to All shops and will override any local shop changes."
            ),
          },
        }}
      />
    </View>
  );
};
