import React from "react";
import { View } from "react-native";
import tw from "tailwind-rn";
import { FormField } from "components/elements/forms";
import { FieldArray } from "formik";
import { useRoute } from "@react-navigation/native";

const ProductDetails = ({
  Localise,
  messages,
  isSmallScreen,
  values,
  modify,
}) => {
  const { params: { redeliver = false } = {} } = useRoute();

  return (
    <FieldArray
      name="items"
      render={(arrayHelpers) =>
        values.items.map((item, index) => {
          const productPath = `items.${index}`;
          return (
            <View
              key={`taskItem-${index}`}
              style={{ paddingRight: isSmallScreen ? 0 : 5 }}
            >
              <View style={tw("justify-between flex-row flex-wrap")}>
                <FormField
                  name="referenceNumber"
                  label={Localise(messages, "Your Order Number")}
                  placeholder={Localise(messages, "Start typing Order Number")}
                  isRequired
                  autoCapitalize="none"
                  autoCorrect={false}
                  autoComplete="new-password" //hack for autoComplete off
                  fsClass="fs-exclude"
                  containerStyle={{
                    width: isSmallScreen ? "100%" : "40%",
                    marginLeft: -5,
                    padding: 0,
                    margin: 0,
                  }}
                  editable={modify && !redeliver}
                  grayedOutOnDisable={true}
                  isUpdateOnChange={true}
                />
                <FormField
                  name="NoOfItems"
                  label={Localise(messages, "# of Items")}
                  placeholder={Localise(messages, "Enter Number of Items")}
                  path={productPath}
                  isRequired
                  grayedOutOnDisable={true}
                  autoCapitalize="none"
                  autoCorrect={false}
                  autoComplete="new-password" //hack for autoComplete off
                  fsClass="fs-exclude"
                  keyboardType="numeric"
                  editable={false}
                  containerStyle={{
                    width: isSmallScreen ? "100%" : "40%",
                    marginLeft: -5,
                    padding: 0,
                    margin: 0,
                  }}
                  isUpdateOnChange={true}
                />
                <FormField
                  name="totalItemValue"
                  label={Localise(messages, "Item(s) Value")}
                  placeholder={parseFloat(0).toFixed(2)}
                  path={productPath}
                  isRequired
                  editable={modify}
                  grayedOutOnDisable={true}
                  containerStyle={{
                    width: isSmallScreen ? "100%" : "20%",
                    marginLeft: -5,
                    paddingRight: isSmallScreen ? 5 : 0,
                    padding: 0,
                    margin: 0,
                  }}
                  keyboardType="numeric"
                  iconType="material-community"
                  iconName="currency-usd"
                  iconSize={14}
                  inputContainerStyle={{
                    padding: 6,
                  }}
                  leftIconContainerStyle={{ paddingRight: 0 }}
                  isPrice={true}
                  iconPosition={true}
                  isUpdateOnChange={false}
                />
              </View>

              <FormField
                name="itemDescription"
                isRequired
                label={Localise(messages, "Item Description")}
                placeholder={Localise(messages, "Enter Item Description")}
                path={productPath}
                autoCapitalize="none"
                autoCorrect={false}
                autoComplete="new-password" //hack for autoComplete off
                containerStyle={{
                  width: isSmallScreen ? "100%" : "70%",
                  marginLeft: isSmallScreen ? 0 : 0,
                  padding: 0,
                  paddingLeft: 0,
                  paddingRight: isSmallScreen ? 10 : 5,
                  margin: 0,
                }}
                multiline={true}
                numberOfLines={2}
                maxNumberOfLines={5}
                spellCheck={true}
                editable={modify}
                grayedOutOnDisable={true}
                isUpdateOnChange={true}
              />
            </View>
          );
        })
      }
    />
  );
};

export default ProductDetails;
