import React, { useEffect, useRef, useContext } from "react";
import { ActivityIndicator, View } from "react-native";
import { useSelector } from "react-redux";
import { Text } from "react-native-elements";

import { request } from "library/utils/request";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import useStateIfMounted from "library/utils/useStateIfMounted";
import Environment from "library/utils/environment";
import I18NContext from "library/contexts/i18N";
import {
  selectShopCode,
  selectSideCar,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";

import { fonts, colors } from "styles/theme";
import tw from "tailwind-rn";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import toLower from "lodash/toLower";

import styles from "./styles";
import MemberSuspends from "./member-suspends";
import PreferencesListingContainer from "./florist-preferences/preferences-listing";

const MercuryNetwork = ({ setRefreshPreferences, refreshPreferences }) => {
  const shopCode = useSelector(selectShopCode);
  const { type: showSideCar } = useSelector(selectSideCar);
  const { messages, Localise } = useContext(I18NContext);
  const { isDesktop, isMobile } = useContext(DeviceContext);
  const {
    userProfile: { firstName: operator },
    floristTypes = [],
    setSuspendedMembers,
  } = useContext(UserProfileContext);

  const [settings, setSettings] = useStateIfMounted([{ type: "F2F" }]);
  const [loading, setLoading] = useStateIfMounted(true);
  const [refreshSuspends, setRefreshSuspends] = useStateIfMounted(true);
  const [refreshGoToSuspends, setRefreshGoToSuspends] = useStateIfMounted(true);
  const [suspendProcessing, setSuspendProcessing] = useStateIfMounted(true);
  const [GoToSuspendProcessing, setGoToSuspendProcessing] =
    useStateIfMounted(true);
  const [floristTimeStamp, setFloristTimeStamp] = useStateIfMounted();
  const [gotoTimeStamp, setGotoTimeStamp] = useStateIfMounted();
  const [showF2FError, SetShowF2FError] = useStateIfMounted(false);
  const [showGOTOError, SetShowGOTOError] = useStateIfMounted(false);

  const shopFloristType = floristTypes.find((x) => x[shopCode]);
  const floristType = shopFloristType ? toLower(shopFloristType[shopCode]) : "";
  const isGoToFlorist = floristType === "goto";
  const floristSuspendTime = Environment.get("FLORIST_SUSPENDS_TIMEOUT", 6);
  const gotoSuspendTime = Environment.get("GOTO_SUSPENDS_TIMEOUT", 10);
  const prevShopCode = usePrevious(shopCode);
  const startDate = moment().toISOString();
  const todayDate = moment().format("YYYY-MM-DD");

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    //setLoading(true);
    if (refreshSuspends || prevShopCode !== shopCode) {
      //After changing the member code using the dropdown
      if (prevShopCode !== shopCode) {
        setLoading(true);
        setRefreshPreferences(true);
        SetShowF2FError(false);
        SetShowGOTOError(false);
      }

      const isOnTime =
        moment().diff(floristTimeStamp, "minutes") < floristSuspendTime;

      if (!floristTimeStamp || isOnTime) {
        // Saving the time for first get call on initial page load
        !floristTimeStamp && setFloristTimeStamp(moment());

        request("get-member-settings", {
          memberCodes: [shopCode],
          startDate,
        }).then((res = {}) => {
          if (
            res &&
            !isEmpty(res.status) &&
            (res.status === "SUSPEND_PENDING" ||
              res.status === "RESUME_PENDING")
          ) {
            setSuspendProcessing(true);
            setSettings([
              { type: "F2F", partnersSubscribed: res.partnersSubscribed || [] },
            ]);
          } else if (res && !isEmpty(res.details)) {
            if (res && res.details) {
              setSettings([
                {
                  ...res.details[0],
                  partnersSubscribed: res.partnersSubscribed,
                },
              ]);
            }
            pullShopSuspendDetails();
            setSuspendProcessing(false);
          } else {
            setSettings([
              { type: "F2F", partnersSubscribed: res.partnersSubscribed || [] },
            ]);
            pullShopSuspendDetails();
            setSuspendProcessing(false);
          }
          setLoading(false);
          setRefreshSuspends(false);
        });
      } else {
        setFloristTimeStamp();
        SetShowF2FError(true);
        setRefreshSuspends(false);
        setSuspendProcessing(false);
      }
    }
  }, [refreshSuspends, shopCode]);

  useEffect(() => {
    let refreshTimer;
    if (suspendProcessing) {
      refreshTimer = setInterval(() => {
        setRefreshSuspends(true);
      }, 5000);
    }
    return () => {
      clearInterval(refreshTimer);
    };
  }, [suspendProcessing]);

  useEffect(() => {
    if (isGoToFlorist && (refreshGoToSuspends || prevShopCode !== shopCode)) {
      const isOnTime =
        moment().diff(gotoTimeStamp, "minutes") < gotoSuspendTime;
      if (!gotoTimeStamp || isOnTime) {
        // Saving the time for first get call on initial page load
        !gotoTimeStamp && setGotoTimeStamp(moment());

        request("get-member-settings", {
          memberCodes: [shopCode],
          startDate,
          suspendType: floristType,
        }).then((res) => {
          if (
            res &&
            !isEmpty(res.status) &&
            (res.status === "GOTO_SUSPEND_PENDING" ||
              res.status === "GOTO_RESUME_PENDING")
          ) {
            setGoToSuspendProcessing(true);
          } else {
            res &&
              !isEmpty(res.status) &&
              res.status !== "GOTO_SUSPEND_MISMATCH" &&
              setRefreshSuspends(true);
            setGoToSuspendProcessing(false);
          }
          setRefreshGoToSuspends(false);
        });
      } else {
        setGotoTimeStamp();
        SetShowGOTOError(true);
        setRefreshGoToSuspends(false);
        setGoToSuspendProcessing(false);
      }
    }
  }, [refreshGoToSuspends, shopCode]);

  useEffect(() => {
    let refreshTimer;
    if (GoToSuspendProcessing) {
      refreshTimer = setInterval(() => {
        setRefreshGoToSuspends(true);
      }, 5000);
    }
    return () => {
      clearInterval(refreshTimer);
    };
  }, [GoToSuspendProcessing]);

  const pullShopSuspendDetails = () => {
    request("get-shop-suspend-details", { startDate: todayDate })
      .then((res) => {
        let suspendMembers = [];
        res &&
          res.forEach((shop) => {
            if (shop.suspendInfo && shop.suspendInfo.isSuspendActive) {
              suspendMembers.push(shop.memberCode);
            }
          });
        setSuspendedMembers(suspendMembers);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  return (
    <>
      <View style={{ ...tw("flex w-full"), ...{ zIndex: 1 } }}>
        <View style={isDesktop ? tw("flex w-full") : tw("flex w-full")}>
          <View style={styles.mainContainer}>
            <View style={styles.suspendsHeader}>
              <Text style={{ ...fonts.heading2, ...tw("p-4") }}>
                {Localise(messages, "Your Network Status")}
              </Text>
              {loading && (
                <ActivityIndicator color={colors.activityIndicator} />
              )}
            </View>
            <View
              style={[
                styles.suspendsList,
                {
                  width: isDesktop ? (showSideCar ? "50%" : "30%") : "100%",
                },
              ]}
            >
              {!isEmpty(settings) &&
                !loading &&
                settings.map((setting, index) => {
                  return (
                    <MemberSuspends
                      key={index}
                      index={index}
                      isDesktop={isDesktop}
                      operator={operator}
                      settings={setting}
                      setRefreshSuspends={setRefreshSuspends}
                      shopCode={shopCode}
                      isMobile={isMobile}
                      suspendProcessing={suspendProcessing}
                      setSuspendProcessing={setSuspendProcessing}
                      isGoToFlorist={isGoToFlorist}
                      GoToSuspendProcessing={GoToSuspendProcessing}
                      setRefreshGoToSuspends={setRefreshGoToSuspends}
                      setGoToSuspendProcessing={setGoToSuspendProcessing}
                      showF2FError={showF2FError}
                      showGOTOError={showGOTOError}
                      SetShowF2FError={SetShowF2FError}
                      SetShowGOTOError={SetShowGOTOError}
                      floristSuspendTime={floristSuspendTime}
                      gotoSuspendTime={gotoSuspendTime}
                      setFloristTimeStamp={setFloristTimeStamp}
                      setGotoTimeStamp={setGotoTimeStamp}
                    />
                  );
                })}
            </View>
          </View>
        </View>
      </View>
      <PreferencesListingContainer
        isDesktop={isDesktop}
        shopCode={shopCode}
        refreshPreferences={refreshPreferences}
        setRefreshPreferences={setRefreshPreferences}
      />
    </>
  );
};

export default MercuryNetwork;
