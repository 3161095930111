import { select, call, put, takeLatest } from "redux-saga/effects";
import * as Navigation from "library/utils/navigation.js";
import { setData, setAction, setInit } from "./slice";
import { selectActions, selectPageData } from "./selector";
import { setAPIResponse as setCommonAPIResponse } from "../common/slice";
import {
  selectSharedCatalogs,
  selectProductLookup,
  selectCollectionLookup,
} from "../common/selector";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import toLower from "lodash/toLower";
import intersection from "lodash/intersection";

function* handleUIRefresh() {
  const prevData = yield select(selectPageData);
  const sharedCatalogs = yield select(selectSharedCatalogs);
  const productLookup = yield select(selectProductLookup);
  const collectionLookup = yield select(selectCollectionLookup);
  const actions = yield select(selectActions);

  const newData = applyPageActions(productLookup, collectionLookup, actions);
  const dashboardData = processData(
    sharedCatalogs,
    productLookup,
    collectionLookup,
    actions,
    prevData,
    newData
  );

  yield put(setData(dashboardData));
}

function* handleDataRefresh() {
  const { name: screen } = yield Navigation.getCurrentRoute(true);
  if (!screen || screen === "dashboard") {
    yield call(handleUIRefresh);
  }
}

const applyPageActions = (
  productLookup,
  collectionLookup,
  { searchText = "", filters = [] }
) => {
  const applyControls = (section, lookup) => {
    const isCollection = section === "collections";
    return Object.keys(lookup).filter((key) => {
      let matchesFilter = true;
      const entry = lookup[key];

      if (searchText) {
        matchesFilter = isCollection
          ? toLower(entry.name).includes(toLower(searchText))
          : toLower(entry.name).includes(toLower(searchText)) ||
            toLower(entry.description).includes(toLower(searchText)) ||
            toLower(entry.productId).includes(toLower(searchText)) ||
            toLower(entry.variationDescription).includes(toLower(searchText)) ||
            toLower(entry.color).includes(toLower(searchText)) ||
            toLower(entry.flowerType).includes(toLower(searchText)) ||
            toLower(entry.products).includes(toLower(searchText));
      }

      if (filters.length && section === "collections") {
        const filterValues = filters.map((e) => e.value);

        if (
          matchesFilter &&
          (filterValues.includes("myCollections") ||
            filterValues.includes("globalCollections"))
        ) {
          if (
            filterValues.includes("myCollections") &&
            entry.group === "florist"
          )
            matchesFilter = true;
          else if (
            filterValues.includes("globalCollections") &&
            entry.group === "mol"
          )
            matchesFilter = true;
          else matchesFilter = false;
        }

        if (
          matchesFilter &&
          (filterValues.includes("active") || filterValues.includes("inactive"))
        ) {
          if (filterValues.includes("active") && entry.status === "active")
            matchesFilter = true;
          else if (
            filterValues.includes("inactive") &&
            entry.status === "inactive"
          )
            matchesFilter = true;
          else matchesFilter = false;
        }
      }

      return matchesFilter;
    });
  };

  const result = ["products", "collections"].reduce((accum, section) => {
    accum[section] = applyControls(
      section,
      section === "products" ? productLookup : collectionLookup
    );
    return accum;
  }, {});

  return result;
};

function processData(
  sharedCatalogs,
  productLookup,
  collectionLookup,
  actions,
  prevData,
  newData
) {
  const { catlogs: prevCatalogs, collections: prevCollections } = prevData;
  const { products, collections } = newData;

  const { collectionSortBy } = actions;
  const [sortField, sortOrder] = collectionSortBy.split(/::/);

  let collectionImages = [];
  let catalogImages = [];

  const findUniqueImage = (mappedProducts = [], exclusion = []) => {
    const pid =
      mappedProducts.find((p) => {
        const { image = "" } = productLookup[p] || {};
        return !!image && !exclusion.includes(image);
      }) ||
      mappedProducts.find((p) => {
        const { image = "" } = productLookup[p] || {};
        return !!image;
      });
    return get(productLookup[pid], "image", "");
  };

  return {
    catalogs: {
      ...prevCatalogs,
      data: orderBy(
        sharedCatalogs.map((sc) => {
          const common = intersection(sc.products, products);
          const image = findUniqueImage(common, catalogImages);
          catalogImages.push(image);

          return {
            ...sc,
            image,
            status: sc.isGroupActive ? "active" : "inactive",
            count: `${common.length} Products`,
          };
        }),
        (e) =>
          e.groupId === "all_catalogs"
            ? 0
            : e.groupId === "my_catalog"
            ? 1
            : e.groupId === "global_catalog"
            ? 2
            : e.groupId === "addons"
            ? 3
            : 4,
        "asc"
      ),
      status: sharedCatalogs.length ? "done" : "progress",
    },
    collections: {
      ...prevCollections,
      data: orderBy(
        collections.map((handle) => {
          const collection = collectionLookup[handle];
          const image = findUniqueImage(
            collection.productIds,
            collectionImages
          );
          collectionImages.push(image);
          return { ...collection, image };
        }),
        (e) => toLower(e[sortField]),
        sortOrder
      ),
      status: collections.length ? "done" : "progress",
    },
  };
}

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeLatest(setInit.type, handleUIRefresh);
  yield takeLatest(setAction.type, handleUIRefresh);
  yield takeLatest(setCommonAPIResponse.type, handleDataRefresh);
}

export default watchSaga;
