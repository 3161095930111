/* eslint-disable react-hooks/rules-of-hooks */
import {
  selectShowUpsert,
  selectIsPageLoading,
  selectRefunds,
  selectAppliedLimits,
  selectNoop,
  selectSearchText,
  selectNoRecordsMessage,
} from "library/sagas/views/home/drawer/mol-refunds/selector";

import {
  setPageSearch,
  setPageAction,
} from "library/sagas/views/home/drawer/mol-refunds/slice";
import { selectCurrentPage } from "library/sagas/ongoing/global-data/selector";

import Item from "./list-item";
import ShopCodeAutoComplete from "../shop-code-auto-complete";

export const getUIConfig = ({
  Localise,
  messages,
  isDesktop,
  isTablet,
  isMobile,
  useSelector,
}) => ({
  heading: Localise(messages, "Mercury Online Refunds"),
  headerTitleWidth: isDesktop ? 300 : "100%",
  selectors: {
    showSecondScreen: selectShowUpsert,
    showLoading: selectIsPageLoading,
    selectCurrentPage,
  },
  sections: [
    {
      section: "refunds",
      component: {
        props: {
          defaultOpen: selectNoop,
          showAccordion: false,
          viewMore: {
            label: Localise(messages, "View More"),
            value: 10,
            initial: 10,
          },
          selectors: {
            dataSelector: selectRefunds,
            limitSelector: selectAppliedLimits,
            accordionSelector: selectNoop,
          },
          actions: { setPageAction },
          noRecordsMessage: useSelector(selectNoRecordsMessage),
          itemComponent: {
            name: Item,
            props: {
              id: "omsOrderID",
              columns: getColumns(isMobile, isTablet),
              style: {},
              selectors: {
                dataSelector: selectRefunds,
                limitSelector: selectAppliedLimits,
                selectActiveItem: selectNoop,
                accordionSelector: selectNoop,
              },
              actions: {
                setActiveItem: () => {},
              },
            },
          },
        },
      },
    },
  ],
  controls: [
    {
      name: "select-shop",
      component: ShopCodeAutoComplete,
      props: {
        width: isDesktop ? "50%" : "100%",
        zIndex: 1,
        controlPosition: isDesktop ? "subHeader" : "header",
      },
    },
    {
      name: "search",
      props: {
        width: isDesktop ? "25%" : "100%",
        controlPosition: isDesktop ? "subHeader" : "header",
        icon: "search",
        placeholder: Localise(messages, "Enter Order ID"),
        inputStyle: {
          width: "100%",
        },
        selectors: {
          selectValue: selectSearchText,
        },
        actions: {
          setAction: setPageSearch,
        },
      },
    },
  ],
});

const getColumns = (isMobile, isTablet) => {
  return [
    {
      column: "variation",
      style: {
        fontWeight: "600",
        width: isMobile ? "50%" : isTablet ? 250 : "15%",
        textTransform: "capitalize",
      },
    },
    {
      column: "dateTime",
      style: {
        width: isMobile ? "50%" : isTablet ? 250 : "15%",
        textTransform: "capitalize",
      },
    },
    {
      column: "omsOrderID",
      style: {
        width: isMobile ? "50%" : isTablet ? 65 : "25%",
        textTransform: "capitalize",
      },
    },
    {
      column: "area",
      style: {
        width: isMobile ? "50%" : isTablet ? 65 : "20%",
        textTransform: "capitalize",
      },
    },
    {
      column: "name",
      style: {
        width: isMobile ? "50%" : isTablet ? 250 : "20%",
        textTransform: "capitalize",
      },
    },
    {
      column: "orderAmount",
      style: {
        width: isMobile ? "50%" : isTablet ? 250 : "15%",
        textTransform: "capitalize",
      },
    },
  ];
};
