import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { View } from "react-native";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";
import { LogoImage } from "components/elements";
import { fonts } from "styles/theme";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import "./index.css";

const QuillEditor = ({
  key = "",
  placeholder = "",
  value = "",
  onEditorChange,
  updateEditorOnChange = true,
  handleRef,
  nonEditable = false,
  onEditorFocus,
  imgUrl = "",
  minHeight = "auto",
  maxLength = 200,
  toolbarId = "toolbar",
  setMsgValue,
  modules,
  showMaxLengthError = true,
  error,
  setError = () => {},
  setDefaultTextCenterAligned = false,
  logoPlacement,
  isBottom = false,
  isRight = false,
  isLogo = false,
  blockTagName = "",
  height = "",
  testID = "text_editor",
  ...otherProps
}) => {
  const { isDesktop } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);

  const Font = Quill.import("formats/font");
  Font.whitelist = ["arial", "georgia", "impact", "verdana", "tahoma", "times"];
  Quill.register(Font, true);

  if (blockTagName) {
    const Block = Quill.import("blots/block");
    Block.tagName = blockTagName;
    Quill.register(Block, true);
  }

  const handleEditorStateChange = (html) => {
    if (updateEditorOnChange) {
      onEditorChange(html);
    } else {
      setMsgValue(html);
    }
  };

  // const defaultTextAlignment = "center"; // Change this to the desired default alignment: 'left', 'center', 'right', or 'justify'
  // const editorContents = document.querySelectorAll(".ql-editor");
  // editorContents?.forEach((editorContent) => {
  //   if (editorContent && setDefaultTextCenterAligned) {
  //     editorContent.style.textAlign = defaultTextAlignment;
  //   }
  // });

  return (
    <View
      style={[
        tw("p-2"),
        {
          width: "100%",
          minHeight: minHeight,
          margin: "auto",
          height: otherProps?.height ? otherProps?.height : "",
        },
      ]}
    >
      {!isBottom && !!imgUrl && (
        <View style={tw("p-2")}>
          <LogoImage
            img={imgUrl}
            isRight={isRight}
            isLogo={isLogo}
            logoPlacement={logoPlacement}
            isSmallScreen={!isDesktop}
          />
        </View>
      )}
      <View
        onLayout={(event) => {
          var { height } = event.nativeEvent.layout;
          if (showMaxLengthError) {
            setError(
              height > maxLength
                ? Localise(messages, "Message is too long, please edit.")
                : ""
            );
          }
        }}
      >
        <div id={toolbarId} />
        <ReactQuill
          id={key}
          theme={"snow"}
          testID={testID}
          onChange={handleEditorStateChange}
          modules={modules}
          value={value}
          setValue={setMsgValue}
          placeholder={placeholder}
          readOnly={nonEditable}
          onFocus={onEditorFocus}
          ref={(el) => {
            handleRef(el);
          }}
          {...otherProps}
        />
      </View>
      {!!error && showMaxLengthError && (
        <Text
          style={[
            {
              ...fonts.error,
              marginTop: 0,
              marginBottom: 12,
              textAlign: "left",
            },
          ]}
        >
          {error}
        </Text>
      )}
      {isBottom && !!imgUrl && showMaxLengthError && (
        <View style={[tw("p-2"), { zIndex: -1 }]}>
          <LogoImage
            img={imgUrl}
            isRight={isRight}
            isLogo={isLogo}
            logoPlacement={logoPlacement}
            isSmallScreen={!isDesktop}
          />
        </View>
      )}
    </View>
  );
};
export default QuillEditor;
