import { put, call, select } from "redux-saga/effects";

import { request } from "library/utils/request";

import get from "lodash/get";
import moment from "moment";

import { selectShopCode } from "../common/selector";
import { setApiResponse, setApiError, fetchShopHours } from "../common/slice";

export function* handleFetchShopHours(action = {}) {
  const { resolve, reject } = get(action, "payload", {});
  const serviceRequest = (params) => request("get-shop-hours", params);
  const shopCode = yield select(selectShopCode);

  try {
    const response = yield call(serviceRequest, { shopCode });

    if (
      !(
        response &&
        response.operationalHours &&
        response.operationalHours.openHours.length > 0
      )
    )
      throw "INVALID_RESPONSE";

    const content = processShopHoursResponse(response);

    yield put(
      setApiResponse({
        path: "shopHours",
        content,
      })
    );
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        path: "shopHours",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleSaveShopHours(action = {}) {
  const { resolve, reject, params } = get(action, "payload", {});
  const shopCode = yield select(selectShopCode);
  const updateParams = prepareShopHoursPayload(params, shopCode);
  const serviceRequest = (shopParams) =>
    request("update-shop-hours", shopParams);

  try {
    yield call(serviceRequest, { shopCode, ...updateParams });
    yield put(fetchShopHours({ resolve, reject }));
  } catch (error) {
    yield put(
      setApiError({
        path: "shopHours",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

const processShopHoursResponse = (response = {}) => {
  const openHours = get(response, "operationalHours.openHours", []);
  const openShopHours = {};

  const getTimeFormat = (time, fallback) => {
    const timeInHours = moment(time, "HH:mm:ss");
    const final = timeInHours.isValid()
      ? timeInHours
      : moment(fallback, "HH:mm:ss");
    return final.format("YYYY-MM-DDTHH:mm:ss");
  };

  openHours.length > 0 &&
    openHours.forEach((element) => {
      const { day, openTime, closeTime, openedFlag } = element;

      const defaultOpenTime = "09:00:00";
      const defaultCloseTime = ["SUNDAY", "SATURDAY"].includes(day)
        ? "13:00:00"
        : "17:00:00";

      const open = getTimeFormat(openTime, defaultOpenTime);
      const close = getTimeFormat(closeTime, defaultCloseTime);

      openShopHours[day.toLowerCase()] = {
        open,
        close,
        openedFlag,
      };
    });

  return openShopHours;
};

const prepareShopHoursPayload = (params = {}, shopCode) => {
  const openHours = [];

  const getTimeFormat = (time) => {
    // eslint-disable-next-line no-unused-vars
    const [date, hours, minutes, seconds] = time?.split(/[T:]/);
    return `${hours}:${minutes}`;
  };

  Object.keys(params).forEach((key) => {
    const { open, close, openedFlag } = params[key];

    openHours.push({
      day: key.toUpperCase(),
      openTime: getTimeFormat(open),
      closeTime: getTimeFormat(close),
      openedFlag,
    });
  });

  return {
    operationalHours: {
      channel: "MOL",
      openHours,
      shopCode,
    },
  };
};
