import React, { useEffect } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import SplitScreen from "components/containers/split-screen-new";
import { SideCar } from "components/wrappers";

import UserProfileStorage from "library/storage/userProfile";
import {
  setPageInitialize,
  setPageReset,
  setPageSearch,
  setPageAction,
  setExtendedScreen,
} from "library/sagas/views/home/drawer/mol-refunds/slice";
import {
  selectShowUpsert,
  selectSideCarTitle,
} from "library/sagas/views/home/drawer/mol-refunds/selector";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";

import ListingScreen from "./listing-screen";
import Refunds from "./refunds";

const MOLRefunds = ({ navigation: { setParams }, route }) => {
  const dispatch = useDispatch();
  const showUpsert = useSelector(selectShowUpsert);
  const { memberCodes = [] } = UserProfileStorage.getAuthGroup();

  const { params: { search: { value = "" } = {} } = {} } = route;

  useEffect(() => {
    dispatch(setPageInitialize());
    return () => {
      dispatch(setPageReset());
    };
  }, []);

  useEffect(() => {
    if (value.length > 0) {
      dispatch(setCurrentPage("mercury-online-refunds"));
      dispatch(setPageAction({ type: "shopCode", value: memberCodes[0] }));
      dispatch(setPageSearch({ type: "search", value: value }));
      setParams({ search: [] });
    }
  }, []);

  return (
    <SplitScreen
      ScreenOne={
        <>
          <View fsClass="fs-unmask">
            <ListingScreen />
          </View>
          <View fsClass="fs-unmask"></View>
        </>
      }
      ScreenTwo={
        <SideCar
          titleSelector={selectSideCarTitle}
          onCloseAction={() => setExtendedScreen({ showUpsert: false })}
        >
          <Refunds />
        </SideCar>
      }
      showSecondScreen={!!showUpsert}
    />
  );
};

export default MOLRefunds;
