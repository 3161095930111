export const ListOfFilters = {
  "Filter By": [
    {
      title: "FTD Catalog",
      value: "ftdCatalog",
    },
    {
      title: "My Catalog",
      value: "floristCatalog",
    },
    {
      title: "Add-ons",
      value: "Addons",
    },
    {
      title: "My Add-ons",
      value: "localAddons",
    },
  ],
};
