import Storage from "library/utils/storage";
import { Platform } from "react-native";
import get from "lodash/get";

const ApplicationContextCache = new Storage("ApplicationContextCache", {
  enableLocal: false,
  enableSession: Platform.OS === "web" ? true : false,
  throttleWait: 0,
  //ttl: 24 * 60 * 60 * 1000, //PAR-2635 - issue when session is more than 24 hours
});

const load = () => ApplicationContextCache.load();

const getRole = () => {
  return ApplicationContextCache.get("MenuRole");
};

const setRole = (role) => {
  return ApplicationContextCache.put("MenuRole", role);
};

const getIsInactiveUser = () => {
  return ApplicationContextCache.get("IsInactiveUser") || false;
};

const setIsInactiveUser = (isInactiveUser) => {
  return ApplicationContextCache.put("IsInactiveUser", isInactiveUser);
};

const getMenus = () => {
  return ApplicationContextCache.get("Menus") || [];
};

const setMenus = (menus) => {
  return ApplicationContextCache.put("Menus", menus);
};

const setAllPermissions = (permissions) =>
  ApplicationContextCache.put("Permissions", permissions);

const getAllPermissions = () =>
  ApplicationContextCache.get("Permissions") || {};

const setPermissions = (memberCode, permissions) => {
  ApplicationContextCache.put("Permissions", {
    ...getAllPermissions(),
    [memberCode]: permissions,
  });
};

const getPermissions = (memberCode) => {
  return get(ApplicationContextCache.get("Permissions"), memberCode, {});
};

const getActiveMenu = () => {
  return ApplicationContextCache.get("ActiveMenu");
};

const setActiveMenu = (menuItem) => {
  return ApplicationContextCache.put("ActiveMenu", menuItem);
};

const clear = () => {
  return ApplicationContextCache.clear();
};

const setMenuBadge = (badge) => {
  return ApplicationContextCache.put("NeedsActionBadge", badge);
};

const getMenuBadge = () => {
  return ApplicationContextCache.get("NeedsActionBadge") || 0;
};

const getLastRunTime = (service) => {
  return ApplicationContextCache.get(`LastRunTime::${service}`);
};

const setLastRunTime = (service) => {
  return ApplicationContextCache.put(
    `LastRunTime::${service}`,
    new Date().getTime()
  );
};

const setAppUpdateAlertDialogShown = (isShown) => {
  ApplicationContextCache.put("AppUpdateAlertDialogShown", isShown);
};

const getAppUpdateAlertDialogShown = () => {
  return ApplicationContextCache.get("AppUpdateAlertDialogShown");
};

const setLanguage = (language) => {
  return ApplicationContextCache.put("Language", language);
};

const getLanguage = () => {
  return ApplicationContextCache.get("Language") || "en";
};

export default {
  getRole,
  setRole,
  getMenus,
  setMenus,
  getIsInactiveUser,
  setIsInactiveUser,
  setAllPermissions,
  getAllPermissions,
  getPermissions,
  setPermissions,
  getActiveMenu,
  setActiveMenu,
  setMenuBadge,
  getMenuBadge,
  getLastRunTime,
  setLastRunTime,
  getLanguage,
  setLanguage,
  clear,
  load,
  setAppUpdateAlertDialogShown,
  getAppUpdateAlertDialogShown,
};
