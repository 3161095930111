import { put, call, takeLatest, select } from "redux-saga/effects";
import get from "lodash/get";
import { request } from "library/utils/request";
import {
  fetchFloristSubscrptionsList,
  saveAddonFeatureSettings,
  setApiResponse,
  setApiLoading,
} from "./slice";
import { selectShopCode } from "library/sagas/views/home/drawer/shop-settings/common/selector";

function* handleFetchFloristSubscriptions(action = {}) {
  const { reject = () => {} } = get(action, "payload", {});
  const shopCode = yield select(selectShopCode);

  /* Service requests */
  const getSubscriptionsList = (params) =>
    request("get-florist-subscriptions", params);
  const getSubscriptionsDetails = (params) =>
    request("get-florist-subscriptions-details", params);

  try {
    yield put(setApiLoading({ path: "addonsList", loading: true }));

    const response = yield call(getSubscriptionsList, { shopCode });
    yield put(setApiResponse({ path: "addonsList", content: response }));

    const deatilsResponse = yield call(getSubscriptionsDetails, { shopCode });
    yield put(
      setApiResponse({ path: "addonsDetails", content: deatilsResponse })
    );
  } catch (error) {
    console.log("failed to get florist subscriptions: ", error);
    reject(error);
  } finally {
    yield put(setApiLoading({ path: "addonsList", loading: false }));
  }
}

function* handleSaveAddonFeatureSettings(action = {}) {
  const {
    reqPayload = {},
    resolve = () => {},
    reject = () => {},
  } = get(action, "payload", {});
  const shopCode = yield select(selectShopCode);

  /* Service requests */
  const saveFloristSubscriptions = (params) =>
    request("save-florist-subscriptions-details", params);

  try {
    yield call(saveFloristSubscriptions, { shopCode, body: { ...reqPayload } });
    resolve();
  } catch (error) {
    reject(error);
  }
}

export function* watchSaga() {
  yield takeLatest(
    fetchFloristSubscrptionsList.type,
    handleFetchFloristSubscriptions
  );
  yield takeLatest(
    saveAddonFeatureSettings.type,
    handleSaveAddonFeatureSettings
  );
}

export default watchSaga;
