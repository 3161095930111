import { put, takeLatest, delay, takeEvery } from "redux-saga/effects";

import {
  setPageInitialize,
  setPageInitFailure,
  setApiResponse,
  setPageAction,
  setSideCarInitialize,
  setSideCarInitSuccess,
  setSideCarInitFailure,
  fetchAllEvents,
  fetchEventDetails,
  fetchAllEventsBySearch,
  fetchAllFuneralEventsBySearch,
  fetchEventOrder,
} from "./slice";

import {
  handleFetchAllEvents,
  handleUIRefresh,
  handleFetchAllEventsBySearch,
  handleFetchAllFuneralEventsBySearch,
  handleFetchEventOrder,
} from "./listing-screen";

import get from "lodash/get";

function* handlePageIntialize(action = {}) {
  const { eventType = "" } = get(action, "payload", {});
  try {
    yield put(fetchAllEvents({ eventType }));
  } catch (error) {
    yield put(setPageInitFailure());
  }
}

function* handleSideCarIntialize(action = {}) {
  const { storeOrigin } = get(action, "payload", {});
  try {
    yield put(fetchEventDetails({ storeOrigin }));

    yield delay(1000);
    yield put(setSideCarInitSuccess());
  } catch (error) {
    yield put(setSideCarInitFailure());
  }
}

/**m
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeLatest(setPageInitialize.type, handlePageIntialize);
  yield takeLatest(setSideCarInitialize.type, handleSideCarIntialize);
  yield takeLatest(setApiResponse.type, handleUIRefresh);
  yield takeLatest(setPageAction.type, handleUIRefresh);
  yield takeLatest(fetchAllEvents.type, handleFetchAllEvents);
  yield takeLatest(fetchAllEventsBySearch.type, handleFetchAllEventsBySearch);
  yield takeLatest(
    fetchAllFuneralEventsBySearch.type,
    handleFetchAllFuneralEventsBySearch
  );
  yield takeEvery(fetchEventOrder.type, handleFetchEventOrder);
}

export default watchSaga;
