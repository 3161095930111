import {
  selectNoop,
  selectShowUpsert,
  selectIsPageLoading,
  selectEvents,
  selectAppliedLimits,
  selectAppliedNeedsActionLimits,
  selectAppliedActiveLimits,
  selectAppliedDraftsLimits,
  selectAppliedCompletedLimits,
  selectAppliedCancelledLimits,
  selectAppliedInactiveLimits,
  selectNeedsActionAccordionSelector,
  selectActiveAccordionSelector,
  selectDraftsAccordionSelector,
  selectCompletedAccordionSelector,
  selectCancelledAccordionSelector,
  selectInactiveAccordionSelector,
  selectSearchText,
  selectAppliedFilters,
  selectAppliedShops,
  selectSortOption,
  selectTotalNumberOfRecords,
  selectPageActions,
  selectNeedsActionEvents,
  selectActiveEvents,
  selectFuneralLogEvents,
  selectCompletedEvents,
  selectCancelledEvents,
  selectInactiveEvents,
  selectDraftEvents,
} from "library/sagas/views/home/drawer/events/selector";
import moment from "moment";
import {
  setPageAction,
  setCurrentEvent,
  setStoreOrigin,
  fetchAllEventsBySearch,
  fetchAllFuneralEventsBySearch,
} from "library/sagas/views/home/drawer/events/slice";
import Item from "./list-item";
import ListingHeader from "./listing-header";
import { printActions } from "../../create-event/config";
import FuneralListItem from "./funeral-list-item";
import { FormField, FormFieldDatePicker } from "components/elements/forms";

export const StatusSuggestions = {
  //New: "NEW",
  //draft = "DRAFT",
  submit: "PENDING",
  accept: "APPROVED",
  reject: "REJECTED",
  complete: "COMPLETED",
  cancel: "CANCELLED",
};

export const defaultActionsList = [
  { key: "modify", label: "Modify" },
  { key: "delete", label: "Delete" },
];

const newOrDraft = [
  { key: "submit", label: "Submit" },
  { key: "cancel", label: "Cancel" },
];

export const EligibleStatusList = (
  hasOrders = false,
  isEligibleToComplete = true
) => {
  return {
    draft: newOrDraft,
    new: newOrDraft,
    pending: [
      { key: "accept", label: "Accept" },
      { key: "cancel", label: "Cancel" },
      { key: "reject", label: "Reject" },
    ],
    approved: isEligibleToComplete
      ? [
          { key: "complete", label: "Complete" },
          { key: "cancel", label: "Cancel" },
        ]
      : hasOrders
      ? [{ key: "cancel", label: "Cancel" }]
      : [
          { key: "cancel", label: "Cancel" },
          { key: "reject", label: "Reject" },
        ],
    rejected: [
      { key: "submit", label: "Submit" },
      { key: "accept", label: "Accept" },
      { key: "cancel", label: "Cancel" },
    ],
    inactive: [{ key: "delete", label: "Delete" }, ...printActions],
  };
};

export const EventStatusColorCodes = {
  pending: "#e2f7ee",
  new: "#e2f7ee",
  draft: "#e2f7ee",
  approved: "#aadec6",
  cancelled: "#c4c4c4",
  rejected: "#b50d81",
  completed: "#114258",
};

export const StatusIcons = {
  pending: "submitted-clock-green",
  new: "incoming",
  draft: "incoming",
  approved: "accepted-event",
  cancelled: "cancel",
  rejected: "rejected-back",
  completed: "completed",
  accept: "accepted-event",
  cancel: "cancel",
  reject: "rejected-back",
  complete: "completed",
};

export const StatusLabel = {
  pending: "Submitted",
  new: "Draft",
  approved: "Accepted",
  cancelled: "Canceled",
};

export const getUIConfig = (
  Localise,
  messages,
  isDesktop,
  isTablet,
  isMobile,
  maxScrollViewHeight
) => ({
  heading: Localise(messages, "Events"),
  createRoute: "create-event",
  showV2Header: true,
  ListingHeader,
  selectors: {
    showSecondScreen: selectShowUpsert,
    showLoading: selectIsPageLoading,
    dataSelector: selectEvents,
    limitSelector: selectAppliedLimits,
    selectTotalNumberOfRecords,
    selectPageActions,
  },
  sections: [
    {
      section: "needsAction",
      component: {
        props: {
          defaultOpen: () => {},
          heading: "Needs Action",
          headingWithCount: true,
          showAccordion: true,
          viewMore: {
            label: Localise(messages, "View More"),
            value: 10,
            initial: 10,
          },
          selectors: {
            dataSelector: selectNeedsActionEvents,
            limitSelector: selectAppliedNeedsActionLimits,
            accordionSelector: selectNeedsActionAccordionSelector,
            selectTotalNumberOfRecords,
          },
          actions: { setPageAction },
          itemComponent: {
            name: Item,
            props: {
              id: "eventId",
              columns: getColumns(isMobile, isTablet),
              selectors: {
                dataSelector: selectNeedsActionEvents,
                selectActiveItem: selectNoop,
                accordionSelector: selectNoop,
              },
              actions: {
                setActiveItem: setCurrentEvent,
                setStoreOrigin,
              },
            },
          },
        },
      },
    },
    {
      section: "active",
      component: {
        props: {
          defaultOpen: () => {},
          heading: "Active",
          headingWithCount: true,
          showAccordion: true,
          viewMore: {
            label: Localise(messages, "View More"),
            value: 10,
            initial: 10,
          },
          selectors: {
            dataSelector: selectActiveEvents,
            limitSelector: selectAppliedActiveLimits,
            accordionSelector: selectActiveAccordionSelector,
            selectTotalNumberOfRecords,
          },
          actions: { setPageAction },
          itemComponent: {
            name: Item,
            props: {
              id: "eventId",
              columns: getColumns(isMobile, isTablet),
              selectors: {
                dataSelector: selectActiveEvents,
                selectActiveItem: selectNoop,
                accordionSelector: selectNoop,
              },
              actions: {
                setActiveItem: setCurrentEvent,
                setStoreOrigin,
              },
            },
          },
        },
      },
    },
    {
      section: "drafts",
      component: {
        props: {
          defaultOpen: () => {},
          showAccordion: true,
          heading: "Drafts",
          headingWithCount: true,
          viewMore: {
            label: Localise(messages, "View More"),
            value: 10,
            initial: 10,
          },
          selectors: {
            dataSelector: selectDraftEvents,
            limitSelector: selectAppliedDraftsLimits,
            accordionSelector: selectDraftsAccordionSelector,
            selectTotalNumberOfRecords,
          },
          actions: { setPageAction },
          itemComponent: {
            name: Item,
            props: {
              id: "eventId",
              columns: getColumns(isMobile, isTablet),
              selectors: {
                dataSelector: selectDraftEvents,
                selectActiveItem: selectNoop,
                accordionSelector: selectNoop,
              },
              actions: {
                setActiveItem: setCurrentEvent,
                setStoreOrigin,
              },
            },
          },
        },
      },
    },
    {
      section: "completed",
      component: {
        props: {
          defaultOpen: () => {},
          showAccordion: true,
          heading: "Completed",
          headingWithCount: true,
          viewMore: {
            label: Localise(messages, "View More"),
            value: 10,
            initial: 10,
          },
          selectors: {
            dataSelector: selectCompletedEvents,
            limitSelector: selectAppliedCompletedLimits,
            accordionSelector: selectCompletedAccordionSelector,
            selectTotalNumberOfRecords,
          },
          actions: { setPageAction },
          itemComponent: {
            name: Item,
            props: {
              id: "eventId",
              columns: getColumns(isMobile, isTablet),
              selectors: {
                dataSelector: selectCompletedEvents,
                selectActiveItem: selectNoop,
                accordionSelector: selectNoop,
              },
              actions: {
                setActiveItem: setCurrentEvent,
                setStoreOrigin,
              },
            },
          },
        },
      },
    },
    {
      section: "cancelled",
      component: {
        props: {
          defaultOpen: () => {},
          showAccordion: true,
          heading: "Canceled",
          headingWithCount: true,
          viewMore: {
            label: Localise(messages, "View More"),
            value: 10,
            initial: 10,
          },
          selectors: {
            dataSelector: selectCancelledEvents,
            limitSelector: selectAppliedCancelledLimits,
            accordionSelector: selectCancelledAccordionSelector,
            selectTotalNumberOfRecords,
          },
          actions: { setPageAction },
          itemComponent: {
            name: Item,
            props: {
              id: "eventId",
              columns: getColumns(isMobile, isTablet),
              selectors: {
                dataSelector: selectCancelledEvents,
                selectActiveItem: selectNoop,
                accordionSelector: selectNoop,
              },
              actions: {
                setActiveItem: setCurrentEvent,
                setStoreOrigin,
              },
            },
          },
        },
      },
    },
    {
      section: "inactive",
      component: {
        props: {
          defaultOpen: () => {},
          showAccordion: true,
          heading: "Inactive",
          headingWithCount: true,
          viewMore: {
            label: Localise(messages, "View More"),
            value: 10,
            initial: 10,
          },
          selectors: {
            dataSelector: selectInactiveEvents,
            limitSelector: selectAppliedInactiveLimits,
            accordionSelector: selectInactiveAccordionSelector,
            selectTotalNumberOfRecords,
          },
          actions: { setPageAction },
          itemComponent: {
            name: Item,
            props: {
              id: "eventId",
              columns: getColumns(isMobile, isTablet),
              selectors: {
                dataSelector: selectInactiveEvents,
                selectActiveItem: selectNoop,
                accordionSelector: selectNoop,
              },
              actions: {
                setActiveItem: setCurrentEvent,
                setStoreOrigin,
              },
            },
          },
        },
      },
    },
  ],
  controls: [
    {
      name: "search",
      props: {
        width: isDesktop ? "33%" : "",
        icon: "search",
        placeholder: Localise(messages, "Search Events"),
        inputStyle: {
          width: "100%",
        },
        selectors: {
          selectValue: selectSearchText,
        },
        actions: {
          setAction: fetchAllEventsBySearch,
        },
      },
    },
    {
      name: "shops",
      props: {
        width: isDesktop ? "3%" : "",
        icon: "home",

        selectors: {
          selectValue: selectAppliedShops,
        },
        actions: {
          setAction: setPageAction,
        },
      },
    },
    {
      name: "filters",
      props: {
        width: isDesktop ? "3%" : "",
        icon: "filter",
        text: Localise(messages, "Filters"),
        hideText: true,
        options: {
          "Date Range": [
            { label: Localise(messages, "Today"), value: "date_0" },
            { label: Localise(messages, "Tomorrow"), value: "date_1" },
            { label: Localise(messages, "This Week"), value: "date_7" },
            { label: Localise(messages, "This Month"), value: "this-month" },
            // {
            //   title: Localise(messages, "Custom Range"),
            //   value: "custom-date-range",
            // },
          ],
          Status: [
            //{ label: Localise(messages, "New"), value: "NEW" },
            { label: Localise(messages, "Draft"), value: "DRAFT" },
            { label: Localise(messages, "Submitted"), value: "PENDING" },
            { label: Localise(messages, "Accepted"), value: "APPROVED" },
            { label: Localise(messages, "Rejected"), value: "REJECTED" },
            { label: Localise(messages, "Canceled"), value: "CANCELLED" },
            //{ label: Localise(messages, "Completed"), value: "COMPLETED" },
          ],
          "Event Type": [
            { label: Localise(messages, "Wedding"), value: "WEDDING" },
            { label: Localise(messages, "Funeral"), value: "FUNERAL" },
            { label: Localise(messages, "Party"), value: "PARTY" },
          ],
        },
        selectors: {
          selectValue: selectAppliedFilters,
        },
        actions: {
          setAction: setPageAction,
        },
      },
    },
    {
      name: "sort",
      props: {
        width: isDesktop ? "3%" : "",
        icon: "sort",
        options: [
          {
            label: Localise(messages, "Event Start Date: New to Old"),
            key: "deliveryDate::desc",
          },
          {
            label: Localise(messages, "Event Start Date: Old to New"),
            key: "deliveryDate::asc",
          },
          {
            label: Localise(messages, "View A-Z"),
            key: "name::asc",
          },
          {
            label: Localise(messages, "View Z-A"),
            key: "name::desc",
          },
        ],
        selectors: {
          selectValue: selectSortOption,
        },
        actions: {
          setAction: setPageAction,
        },
      },
    },
  ],
  scrollContainerStyles: { padding: 20, maxHeight: 700 },
  scrollStyle: {
    maxHeight: maxScrollViewHeight,
    paddingLeft: 5,
    paddingRight: 10,
  },
});

export const getFuneralLogUIConfig = (
  Localise,
  messages,
  isDesktop,
  isTablet,
  isMobile,
  maxScrollViewHeight
) => ({
  heading: Localise(messages, "Funeral Log"),
  createRoute: "create-event",
  showV2Header: isDesktop,
  showTitle: false,
  showCreate: true,
  showSelectedControls: false,
  ListingHeader,
  selectors: {
    showSecondScreen: selectShowUpsert,
    showLoading: selectIsPageLoading,
    dataSelector: selectEvents,
    limitSelector: selectAppliedLimits,
    selectTotalNumberOfRecords,
    selectPageActions,
  },
  sections: [
    {
      section: "active",
      component: {
        props: {
          defaultOpen: () => {},
          heading: "Active",
          headingWithCount: true,
          showAccordion: true,
          noRecordsMessage: "No Funerals Added",
          noRecordsHelpLink: "Learn how to add Funerals to MercuryHQ",
          viewMore: {
            label: Localise(messages, "View More"),
            value: 10,
            initial: 10,
          },
          selectors: {
            dataSelector: selectFuneralLogEvents,
            limitSelector: selectAppliedActiveLimits,
            accordionSelector: selectNoop,
            selectTotalNumberOfRecords,
            selectIsPageLoading: selectIsPageLoading,
          },
          actions: { setPageAction },
          itemComponent: {
            name: FuneralListItem,
            props: {
              id: "eventId",
              columns: getFuneralLogColumns(isMobile, isTablet),
              selectors: {
                dataSelector: selectFuneralLogEvents,
                selectActiveItem: selectNoop,
                accordionSelector: selectNoop,
              },
              actions: {
                setActiveItem: setCurrentEvent,
                setStoreOrigin,
              },
            },
          },
        },
      },
    },
  ],
  controls: [
    {
      name: "search",
      props: {
        width: isDesktop ? "80%" : "",
        icon: "search",
        placeholder: Localise(messages, "Search Funeral Log"),
        inputStyle: {
          width: "100%",
        },
        selectors: {
          selectValue: selectSearchText,
        },
        actions: {
          setAction: fetchAllFuneralEventsBySearch,
        },
      },
    },
    {
      name: "sort",
      props: {
        width: isDesktop ? "10%" : "",
        icon: "sort",
        iconSize: 28,
        options: [
          {
            label: Localise(messages, "Delivery Date: New to Old"),
            key: "deliveryDate::desc",
          },
          {
            label: Localise(messages, "Delivery Date: Old to New"),
            key: "deliveryDate::asc",
          },
        ],
        selectors: {
          selectValue: selectSortOption,
        },
        actions: {
          setAction: setPageAction,
        },
      },
    },
    {
      name: "create-new",
      props: {
        name: FuneralListItem,
        width: isDesktop ? "10%" : "",
        icon: "create-order",
        iconSize: 28,
        setAction: setPageAction,
      },
    },
  ],
  onCreateHandler: () => {},
  scrollContainerStyles: { padding: 0 },
  scrollStyle: {
    maxHeight: maxScrollViewHeight,
    paddingLeft: isDesktop ? 5 : 0,
    paddingRight: isDesktop ? 10 : 0,
  },
  controlsStyles: { paddingTop: isDesktop ? 20 : 0 },
});

const getFuneralLogColumns = (isMobile, isTablet) => {
  return [
    {
      column: "name",
      style: {
        fontWeight: "600",
        width: isMobile ? "50%" : isTablet ? 250 : "25%",
        textTransform: "capitalize",
      },
    },
  ];
};

const getColumns = (isMobile, isTablet) => {
  return [
    {
      column: "status",
      style: {
        fontWeight: "600",
        width: isMobile ? "50%" : isTablet ? 250 : "25%",
        textTransform: "capitalize",
      },
    },
    {
      column: "name",
      style: {
        fontWeight: "600",
        width: isMobile ? "50%" : isTablet ? 250 : "25%",
        textTransform: "capitalize",
      },
    },
    {
      column: "type",
      style: {
        width: isMobile ? "50%" : isTablet ? 65 : "25%",
        textTransform: "capitalize",
      },
    },
    {
      column: "startAndEndDate",
      style: {
        width: isMobile ? "50%" : isTablet ? 65 : "25%",
      },
      type: "date",
    },
    {
      column: "startTime",
      style: {
        width: isMobile ? "50%" : isTablet ? 65 : "25%",
      },
      type: "time",
    },
    {
      column: "proposalInfo",
      style: {
        width: isMobile ? "50%" : isTablet ? 65 : "25%",
        textTransform: "capitalize",
      },
    },
  ];
};

const TextFieldProps = {
  autoCapitalize: "none",
  autoCorrect: false,
  editable: true,
  multiline: false,
  numberOfLines: 1,
  labelStyle: { fontWeight: "normal" },
  width: "50%",
};

export const FuneralLogFields = (Localise, messages) => {
  return [
    {
      title: Localise(messages, "In Memory Of"),
      formFieldElement: FormField,
      formFieldProps: {
        ...TextFieldProps,
        name: "firstName",
        label: Localise(messages, "In Memory Of"),
        placeholder: Localise(messages, "First Name"),
        path: "celebrant",
      },
    },
    {
      title: Localise(messages, "Last Name"),
      formFieldElement: FormField,
      formFieldProps: {
        ...TextFieldProps,
        name: "lastName",
        label: Localise(messages, "Last Name"),
        placeholder: Localise(messages, "Last Name"),
        path: "celebrant",
      },
    },
  ];
};

const today = new Date();
const tonight = moment().add(1, "days");
export const FuneralLogAdditionalFields = (Localise, messages) => {
  return [
    {
      title: Localise(messages, ""),
      formFieldElement: FormFieldDatePicker,
      formFieldProps: {
        ...TextFieldProps,
        name: "deliveryDate",
        dateValueFormat: "YYYY-MM-DD",
        label: Localise(messages, "Delivery Date"),
        placeholder: Localise(messages, "Delivery Date"),
        path: "locations.0",
        popperPlacement: "top-start",
        width: "33.5%",
      },
    },
    {
      title: Localise(messages, ""),
      formFieldElement: FormFieldDatePicker,
      formFieldProps: {
        ...TextFieldProps,
        width: "33.5%",
        // minTime: today,
        // maxTime: tonight,
        timeIntervals: 30,
        iconName: "clock",
        alignIconRight: true,
        showTimeSelect: true,
        showTimeSelectOnly: true,
        dateFormat: "h:mm a",
        enabled: true,
        name: "deliveryTime",
        label: Localise(messages, "Delivery Time"),
        placeholder: Localise(messages, "Delivery Time"),
        path: "locations.0",
        popperPlacement: "top",
      },
    },
    {
      title: Localise(messages, ""),
      formFieldElement: FormFieldDatePicker,
      formFieldProps: {
        ...TextFieldProps,
        width: "33%",
        name: "visitationDate",
        dateValueFormat: "YYYY-MM-DD",
        label: Localise(messages, "Visitation Date"),
        placeholder: Localise(messages, "Visitation Date"),
        path: "locations.0",
        popperPlacement: "top-end",
      },
    },
    {
      title: Localise(messages, ""),
      formFieldElement: FormFieldDatePicker,
      formFieldProps: {
        ...TextFieldProps,
        width: "33.5%",
        minTime: today,
        maxTime: tonight,
        timeIntervals: 15,
        iconName: "clock",
        alignIconRight: true,
        showTimeSelect: true,
        showTimeSelectOnly: true,
        dateFormat: "h:mm a",
        enabled: true,
        name: "visitationTime",
        label: Localise(messages, "Visitation Time"),
        placeholder: Localise(messages, "Visitation Time"),
        path: "locations.0",
        popperPlacement: "top",
      },
    },
    {
      title: Localise(messages, ""),
      formFieldElement: FormFieldDatePicker,
      formFieldProps: {
        ...TextFieldProps,
        width: "33.5%",
        name: "serviceDate",
        dateValueFormat: "YYYY-MM-DD",
        label: Localise(messages, "Service Date"),
        placeholder: Localise(messages, "Service Date"),
        path: "locations.0",
        popperPlacement: "top",
      },
    },
    {
      title: Localise(messages, ""),
      formFieldElement: FormFieldDatePicker,
      formFieldProps: {
        ...TextFieldProps,
        width: "33%",
        minTime: today,
        maxTime: tonight,
        timeIntervals: 15,
        iconName: "clock",
        alignIconRight: true,
        showTimeSelect: true,
        showTimeSelectOnly: true,
        dateFormat: "h:mm a",
        enabled: true,
        name: "serviceTime",
        label: Localise(messages, "Service Time"),
        placeholder: Localise(messages, "Service Time"),
        path: "locations.0",
        popperPlacement: "top",
      },
    },
  ];
};
