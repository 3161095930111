import React from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import { useSelector } from "react-redux";

import tw from "tailwind-rn";
import isEmpty from "lodash/isEmpty";

import { fonts, shapes } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import { selectApiResponse } from "library/sagas/ongoing/order-details/selector";

export const FloristPartnerDisplay = ({
  index,
  title,
  isMultiOrderEligible,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const orderDetailsResponse = useSelector(selectApiResponse);
  if (isEmpty(orderDetailsResponse)) return null;
  const orderItem = orderDetailsResponse.orderItems[index];
  const { deliveryMethod = "" } = orderItem?.deliveryInfo || {};
  const { memberCode, businessName, phone } = orderItem?.receivingMember || {};

  const showFloristSelection = ["FLORIST_PARTNER", "transfer"].includes(
    deliveryMethod
  );
  return showFloristSelection ? (
    <View
      style={[
        {
          marginTop: 15,
        },
        !isMultiOrderEligible && { ...shapes.sectionBorder },
      ]}
    >
      <View style={[tw("flex flex-row pb-3"), { paddingHorizontal: 5 }]}>
        <Text style={[fonts.sectionHeading]}>{Localise(messages, title)}</Text>
      </View>
      <View style={tw("flex flex-row")}>
        <Text style={tw("pl-1 pr-2")}>{businessName}</Text>
        <Text style={tw("px-2")}>{memberCode}</Text>
        <Text style={tw("px-2")}>{phone}</Text>
      </View>
    </View>
  ) : null;
};

export default React.memo(FloristPartnerDisplay);
