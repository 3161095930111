import React, { useEffect, useContext, useCallback } from "react";
import {
  Dimensions,
  Image,
  StyleSheet,
  Text,
  View,
  ScrollView,
} from "react-native";
import DeviceInfo from "react-native-device-info";
import { Spinner } from "components/elements";
import { useDispatch, useSelector } from "react-redux";
import { defaultColors } from "./config";
import { backgroundColors, fonts } from "styles/theme";
import GoogleMapReact from "google-map-react";
import SingleScreen from "components/containers/single-screen";
import {
  selectCitiesZipcodes,
  selectReloadDeliveryZoneMap,
  selectAutoRoutes,
  selectIsDrawable,
  selectNewZoneCreationFailed,
  selectCreatedRouteZone,
  selectEditZoneIndex,
  selectIsEditableZone,
  selectIsEditCancelled,
  selectIsAutoRoutesLoading,
  selectIsCreateZoneEnabled,
  selectCoverageAreaCircleVisibilty,
  selectShopCode,
} from "library/sagas/views/home/drawer/delivery/selector";
import {
  setDeliveryZoneMapReload,
  setIsDrawable,
  setCreatedRouteZone,
  setNewZoneCreationFailed,
  resetIsCreateZoneEnabled,
  setIsCreateZoneEnabled,
  setEditedWaypoints,
  setEditZoneIndex,
  setIsEditCancelled,
  fetchAutoRoutes,
  setShopCodeSelected,
  resetCreatedRouteZone,
  setIsEditableZone,
} from "library/sagas/views/home/drawer/delivery/slice";
import { useFocusEffect } from "@react-navigation/native";
import I18NContext from "library/contexts/i18N";
import useStateIfMounted from "library/utils/useStateIfMounted";
import Environment from "library/utils/environment";
import UserProfileStorage from "library/storage/userProfile";
import isEmpty from "lodash/isEmpty";
import { IMAGES } from "static/assets/images";
import tw from "tailwind-rn";
import { SideCardElements } from "./../side-card";

const DeliverySettings = () => {
  const dispatch = useDispatch();
  const isDrawable = useSelector(selectIsDrawable);
  const citiesZipcodes = useSelector(selectCitiesZipcodes);
  const reloadDeliveryZoneMap = useSelector(selectReloadDeliveryZoneMap);
  const [mapReference, setMapReference] = useStateIfMounted(null);
  const [mapsReference, setMapsReference] = useStateIfMounted(null);
  const { messages, Localise } = useContext(I18NContext);
  const [polygonControllers, setPolygonControllers] = useStateIfMounted();
  const [polygonMarkerControllers, setPolygonMarkerControllers] =
    useStateIfMounted();

  const isAutoRoutesLoading = useSelector(selectIsAutoRoutesLoading);
  const [availableColors, setAvailableColors] = useStateIfMounted([]);
  const [circleRef, setCircleRef] = useStateIfMounted(null);
  const [polygonArrayControllers, setPolygonArrayControllers] =
    useStateIfMounted([]);
  const [polygonArrayMarkerControllers, setPolygonArrayMarkerControllers] =
    useStateIfMounted([]);
  const editZoneIndex = useSelector(selectEditZoneIndex);
  const isZoneEditable = useSelector(selectIsEditableZone);
  const isEditCancelled = useSelector(selectIsEditCancelled);
  const zoneCreationFailed = useSelector(selectNewZoneCreationFailed);
  const createdZoneData = useSelector(selectCreatedRouteZone);
  /* eslint-disable react-hooks/rules-of-hooks */
  const shopCode =
    UserProfileStorage.getSelectedShopCode() || useSelector(selectShopCode);
  const autoRoutes = useSelector(selectAutoRoutes);
  const isClickable = useSelector(selectIsCreateZoneEnabled);
  const [loading, setLoading] = useStateIfMounted(true);
  const pollInterval = 2 * 60 * 1000; // 2 mins

  const isCoverageAreaCircleVisible = useSelector(
    selectCoverageAreaCircleVisibilty
  );

  //Fetching isZoneEditable updated value from sideCar component. When tried to get the isZoneEditable value from saga and setting as 2nd argument in useFocusEffect
  // the whole screen is getting refreshed
  const [isEditable, setZoneEditable] = useStateIfMounted(false);
  const getIsZoneEditableFromChild = (data) => {
    setZoneEditable(data.payload);
  };
  const [osType, setOSType] = useStateIfMounted();
  DeviceInfo.getBaseOs().then((baseOs) => {
    setOSType(baseOs);
  });

  useFocusEffect(
    useCallback(() => {
      if (shopCode !== "all") {
        if (!isEditable) {
          var timerID = setInterval(async () => {
            setLoading(true);
            setTimeout(async () => {
              dispatch(setShopCodeSelected(shopCode));
              await dispatch(fetchAutoRoutes());
              setLoading(false);
            }, 2000); // Wait for 2000ms before dispatching fetchAutoRoutes()
          }, pollInterval);
        }
      }
      return () => {
        clearInterval(timerID);
      };
    }, [isEditable])
  );

  useEffect(() => {
    if (autoRoutes.length === 0) {
      getAutoRoutes();
    }
    return () => {
      dispatch(resetCreatedRouteZone());
      dispatch(resetIsCreateZoneEnabled());
      dispatch(setIsEditableZone(false));
    };
  }, []);

  const getAutoRoutes = () => {
    dispatch(setShopCodeSelected(shopCode));
    dispatch(fetchAutoRoutes());
  };

  const circleRadiusRange = 15 * 1.609344 * 1000; // multiplying by 1.60933 for mile to km conversion and 1000 for convertion to meters
  const enableStyleOptions = {
    strokeColor: "#519342",
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: "#519342",
    fillOpacity: 0.2,
  };
  const disableStyleOptions = {
    strokeColor: "#808080",
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: "#808080",
    fillOpacity: 0.5,
  };

  const { latitude: shopLatitude, longitude: shopLongitude } =
    UserProfileStorage.getShopLocation(shopCode);
  const googleMapsAPIKey = Environment.get(
    "GOOGLE_MAPS_API_KEY",
    "AIzaSyAPMdYXaLpwQM1nZIjr_GJyNKLe5ZLKzFU"
  );
  const googleMapsAPPID = Environment.get(
    "GOOGLE_MAPS_APP_ID",
    "ae81b3064704ddac"
  );
  const activeZipcodes = citiesZipcodes
    .map((item) => item.status === "Y" && item.zipCode)
    .filter(Boolean);

  let featureLayer = null;
  const createCircle = () => {
    const circle = new mapsReference.Circle({
      clickable: true,
      strokeColor: "blue",
      strokeWeight: 0.2,
      fillColor: "blue",
      fillOpacity: 0.2,
      visible: false,
      map: mapReference,
      center: {
        lat: parseFloat(+shopLatitude),
        lng: parseFloat(+shopLongitude),
      },
      radius: circleRadiusRange,
    });
    setCircleRef(circle);
  };

  useEffect(() => {
    if (mapReference && mapsReference) {
      reloadFeatureLayer();
      displayZoneDetails();
      createCircle();
    }
  }, [mapReference, mapsReference]);

  useEffect(() => {
    mapsReference &&
      mapReference &&
      citiesZipcodes.length > 0 &&
      reloadFeatureLayer();
  }, [citiesZipcodes]);

  useEffect(() => {
    if (isZoneEditable && editZoneIndex >= 0 && polygonArrayControllers) {
      polygonArrayControllers[editZoneIndex]?.setEditable(true);
    } else {
      if (editZoneIndex >= 0) {
        if (polygonArrayControllers && isEditCancelled) {
          displayZoneDetails();
          dispatch(setIsEditCancelled(false));
        }
        dispatch(setEditZoneIndex(""));
        dispatch(setEditedWaypoints([]));
      }
    }
  }, [isZoneEditable]);

  const enableDrawableHelper = () => {
    // Clear the old Listeners if exits and attach a new 'mousedown' listener on Map
    if (mapsReference) {
      mapsReference.event.clearListeners(mapReference.getDiv(), "mousedown");
      mapsReference.event.addDomListener(
        mapReference.getDiv(),
        "mousedown",
        drawFreeHand
      );
    }
  };

  useEffect(() => {
    if (circleRef && isCoverageAreaCircleVisible) {
      circleRef.setVisible(true);
    } else {
      if (circleRef) {
        circleRef.setVisible(false);
      }
    }
  }, [isCoverageAreaCircleVisible]);

  const drawFreeHand = () => {
    const poly = new mapsReference.Polyline({
      clickable: true,
      map: mapReference,
      strokeColor: "#114258",
      strokeWeight: 3,
    });
    // Added mousemove listener to track the user's mouse movement
    const move = mapsReference.event.addListener(
      mapReference,
      "mousemove",
      (e) => {
        poly.getPath().push(e.latLng);
      }
    );

    let moveCircle = null;
    if (circleRef) {
      moveCircle = mapsReference.event.addListener(
        circleRef,
        "mousemove",
        (e) => {
          poly.getPath().push(e.latLng);
        }
      );
    }

    // Added mouseup listener to check when the user releases the mouse button
    mapsReference.event.addDomListenerOnce(
      mapReference.getDiv(),
      "mouseup",
      (e) => {
        mapsReference.event.removeListener(move);
        if (circleRef) {
          mapsReference.event.removeListener(moveCircle);
        }
        const path = poly.getPath();
        poly.setMap(null);
        let colorCode = availableColors[0];
        const polygon = new mapsReference.Polygon({
          clickable: true,
          editable: true,
          fillColor: colorCode,
          fillOpacity: 0.3,
          geodesic: true,
          map: mapReference,
          path,
          zIndex: 6,
          strokeColor: colorCode,
          strokeOpacity: 0.3,
        });
        setPolygonControllers(polygon);
        let polypath = [];
        const paths = polygon.getPaths();
        //complete Polygon
        paths.forEach((path) => {
          const arr = path.getArray();
          for (let i = 0, l = arr.length; i < l; i++) {
            const lat = arr[i].lat();
            const lng = arr[i].lng();
            polypath.push({ lng: lng, lat: lat });
          }
          // Appending the first coords as last to make a complete polygon
          if (arr[0]) {
            polypath.push({ lng: arr[0].lng(), lat: arr[0].lat() });
          }
        });
        var boundspolygon = new mapsReference.LatLngBounds();
        for (let i = 0; i < polypath.length; i++) {
          boundspolygon.extend(polypath[i]);
        }
        let lengthOfAutoRoutes = parseInt(autoRoutes.length);
        let lastZoneNumber = lengthOfAutoRoutes
          ? parseInt(
              (autoRoutes[lengthOfAutoRoutes - 1]?.name).split(" ")[1]
                ? (autoRoutes[lengthOfAutoRoutes - 1]?.name).split(" ")[1]
                : lengthOfAutoRoutes
            ) + 1
          : lengthOfAutoRoutes + 1;
        let zoneName =
          "Zone " +
          (!isNaN(lastZoneNumber) ? lastZoneNumber : lengthOfAutoRoutes + 1);
        if (
          autoRoutes.length &&
          autoRoutes?.[lengthOfAutoRoutes - 1]?.name == zoneName
        ) {
          zoneName = "Zone " + (lengthOfAutoRoutes + 2);
        }

        let myLatlng = boundspolygon.getCenter();
        const marker = new mapsReference.Marker({
          position: myLatlng,
          map: mapReference,
          title: "FreehandDrawingMarker",
          label: {
            color: "black",
            fontWeight: "bold",
            fontSize: "15px",
            text: zoneName,
          },
          icon: {
            path: mapsReference.SymbolPath.CIRCLE,
            scale: 8.5,
            strokeColor: "#FFFFFF00",
            fillColor: "#FFFFFF00",
            fillOpacity: 0.3,
            strokeWeight: 1,
          },
        });
        setPolygonMarkerControllers(marker);
        const changeofcoords = () => {
          const nextPath = polygon
            .getPath()
            .getArray()
            .map((latLng) => {
              return { lat: latLng.lat(), lng: latLng.lng() };
            });
          dispatch(setEditedWaypoints(nextPath));
          var boundspolygonShape = new mapsReference.LatLngBounds();
          for (var i = 0; i < nextPath.length; i++) {
            boundspolygonShape.extend(nextPath[i]);
          }
          var myLatlng = boundspolygonShape.getCenter();
          marker.setPosition(myLatlng);
        };
        const createdPolypath = polygon.getPath();
        createdPolypath.addListener("set_at", changeofcoords);
        createdPolypath.addListener("insert_at", changeofcoords);
        createdPolypath.addListener("remove_at", changeofcoords);

        if (!isEmpty(polypath)) {
          dispatch(
            setCreatedRouteZone({
              wayPoints: polypath,
              name: zoneName,
              colorCode: colorCode,
              isNewZone: true,
              capacity: "5",
            })
          );
          dispatch(setIsDrawable(false));
        } else {
          dispatch(setIsDrawable(false));
          dispatch(resetIsCreateZoneEnabled());
        }
        mapsReference.event.clearListeners(mapReference.getDiv(), "mousedown");
      }
    );
  };
  const handleDrawEvent = () => {
    // Invoked when User clicks DRAW button
    enableDrawableHelper();
  };

  const handleResetDrawEvent = () => {
    clearMap();
    if (mapsReference) {
      mapsReference.event.addDomListener(
        mapReference.getDiv(),
        "mousedown",
        drawFreeHand
      );
    }
  };

  const clearMap = () => {
    if (polygonControllers) {
      polygonControllers.setMap(null);
    }
    if (polygonMarkerControllers) {
      polygonMarkerControllers.setMap(null);
    }
  };
  // Timeout is required to force unmount & mount the map to pick updated data
  useEffect(() => {
    reloadDeliveryZoneMap &&
      setTimeout(() => {
        dispatch(setDeliveryZoneMapReload(false));
      }, 2000);
  }, [reloadDeliveryZoneMap]);

  useEffect(() => {
    displayZoneDetails();
    let numberOfAutoRoutes = autoRoutes.length;
    if (numberOfAutoRoutes < 20) {
      let usedColors = [];
      autoRoutes.map((route) => {
        if (defaultColors.indexOf(route?.colorCode) != -1)
          usedColors.push(route?.colorCode);
      });
      const colorsAvailable = defaultColors.filter(
        (n) => !usedColors.includes(n)
      );
      setAvailableColors(colorsAvailable);
    } else if (numberOfAutoRoutes === 0) {
      setAvailableColors(defaultColors);
    } else if (numberOfAutoRoutes >= 20) {
      let randomColor = [];
      randomColor.push("#" + Math.floor(Math.random() * 16777215).toString(16));
      setAvailableColors(randomColor);
    }
  }, [autoRoutes]);

  useEffect(() => {
    if (zoneCreationFailed) {
      dispatch(setIsDrawable(false));
      clearMap();
      dispatch(setNewZoneCreationFailed(false));
    }
  }, [zoneCreationFailed]);

  useEffect(() => {
    if (isDrawable) {
      handleResetDrawEvent();
      handleDrawEvent();
    }
  }, [isDrawable]);

  const reloadFeatureLayer = () => {
    if (mapReference && mapsReference) {
      featureLayer = mapReference.getFeatureLayer("POSTAL_CODE");
      featureLayer.style = (options) => {
        return activeZipcodes.includes(options.feature.h)
          ? enableStyleOptions
          : disableStyleOptions;
      };
    }
  };
  const displayZoneDetails = () => {
    if (mapsReference && mapReference) {
      if (polygonArrayControllers.length) {
        polygonArrayControllers.map((polyController) => {
          polyController.setMap(null);
        });
      }
      if (polygonArrayMarkerControllers.length) {
        polygonArrayMarkerControllers.map((markerController) => {
          markerController.setMap(null);
        });
      }
      if (!createdZoneData?.name) {
        if (polygonControllers) {
          polygonControllers.setMap(null);
        }
        if (polygonMarkerControllers) {
          polygonMarkerControllers.setMap(null);
        }
      }

      let polygonArray = [];
      let markerArray = [];
      autoRoutes.map((route) => {
        const polygon = new mapsReference.Polygon({
          clickable: true,
          fillColor: route?.colorCode || "#30138C",
          fillOpacity: 0.5,
          geodesic: true,
          map: mapReference,
          path: route?.wayPoints,
          zIndex: 6,
          strokeColor: route?.colorCode || "#30138C",
          strokeOpacity: 0.5,
        });
        let polypath = [];
        const paths = polygon.getPaths();
        paths.forEach((path) => {
          const ar = path.getArray();
          for (let i = 0, l = ar.length; i < l; i++) {
            const lat = ar[i].lat();
            const lng = ar[i].lng();
            polypath.push({ lng: lng, lat: lat });
          }
        });
        var boundspolygon = new mapsReference.LatLngBounds();
        for (var i = 0; i < polypath.length; i++) {
          boundspolygon.extend(polypath[i]);
        }
        var myLatlng = boundspolygon.getCenter();
        const marker = new mapsReference.Marker({
          position: myLatlng,
          map: mapReference,
          title: "FreehandDrawingMarker",
          label: {
            color: "black",
            fontWeight: "bold",
            fontSize: "15px",
            text:
              route.name.length >= 20
                ? route.name.slice(0, 20) + "..."
                : route.name || "",
          },
          icon: {
            path: mapsReference.SymbolPath.CIRCLE,
            scale: 8.5,
            strokeColor: "#FFFFFF00",
            fillColor: "#FFFFFF00",
            fillOpacity: 0.8,
            strokeWeight: 1,
          },
        });
        const changeofcoords = () => {
          const nextPath = polygon
            .getPath()
            .getArray()
            .map((latLng) => {
              return { lat: latLng.lat(), lng: latLng.lng() };
            });
          dispatch(setEditedWaypoints(nextPath));
          //zones
          var boundspolygonShape = new mapsReference.LatLngBounds();
          for (var i = 0; i < nextPath.length; i++) {
            boundspolygonShape.extend(nextPath[i]);
          }
          var myLatlng = boundspolygonShape.getCenter();
          marker.setPosition(myLatlng);
          //end of marker
        };
        const polypathFreehand = polygon.getPath();
        polypathFreehand.addListener("set_at", changeofcoords);
        polypathFreehand.addListener("insert_at", changeofcoords);
        polypathFreehand.addListener("remove_at", changeofcoords);
        markerArray.push(marker);
        polygonArray.push(polygon);
      });
      setPolygonArrayControllers(polygonArray);
      setPolygonArrayMarkerControllers(markerArray);
      setLoading(false);
    }
  };

  const headerTextMessage = () => {
    let headerMessage;
    if (!isClickable && isDrawable && autoRoutes.length === 0) {
      headerMessage = `${Localise(messages, `Draw on Map to create Zone`)} 1`;
    }
    if (!isClickable && isDrawable) {
      let lengthOfAutoRoutes = parseInt(autoRoutes.length);
      let lastZoneNumber = lengthOfAutoRoutes
        ? parseInt(
            (autoRoutes[lengthOfAutoRoutes - 1]?.name).split(" ")[1]
              ? (autoRoutes[lengthOfAutoRoutes - 1]?.name).split(" ")[1]
              : lengthOfAutoRoutes
          ) + 1
        : lengthOfAutoRoutes + 1;
      let zoneName = `${Localise(messages, `Zone`)} ${
        !isNaN(lastZoneNumber) ? lastZoneNumber : lengthOfAutoRoutes + 1
      }`;
      if (
        autoRoutes.length &&
        autoRoutes?.[lengthOfAutoRoutes - 1]?.name == zoneName
      ) {
        zoneName = `${Localise(messages, `Zone`)} ${lengthOfAutoRoutes + 2}`;
      }

      headerMessage = `${Localise(
        messages,
        `Draw on Map to create Zone`
      )} ${lastZoneNumber}`;
    }
    if (isEditable) {
      headerMessage = `${Localise(messages, `Editing`)} ${
        autoRoutes[editZoneIndex]?.name
      }`;
    }
    return headerMessage;
  };

  return (
    <SingleScreen
      title={Localise(messages, "Delivery Zone Settings")}
      isLoading={loading || reloadDeliveryZoneMap}
    >
      {reloadDeliveryZoneMap ? (
        <View style={{ minHeight: 150 }}>
          <Spinner size="large" />
        </View>
      ) : (
        <View>
          {mapsReference && (
            <View
              style={[tw(`flex ${`flex-row`} flex-wrap items-center`)]}
            ></View>
          )}
          <View
            style={[
              {
                marginRight: 5,
                height: Dimensions.get("window").height,
                borderColor: backgroundColors.medium,
                borderWidth: 1,
                borderStyle: "solid",
              },
            ]}
          >
            {shopLatitude && shopLongitude ? (
              <View style={{ height: "100vh" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: googleMapsAPIKey,
                    version: "beta",
                    region: "US",
                    libraries: ["geometry", "drawing"],
                  }}
                  defaultCenter={{
                    lat: parseFloat(shopLatitude),
                    lng: parseFloat(shopLongitude),
                  }}
                  defaultZoom={13}
                  onGoogleApiLoaded={({ map, maps }) => {
                    setMapReference(map);
                    setMapsReference(maps);
                  }}
                  yesIWantToUseGoogleMapApiInternals={true}
                  options={{
                    mapId: googleMapsAPPID,
                    clickableIcons: false,
                    draggableCursor: isDrawable ? "crosshair" : "pointer",
                    draggingCursor: isDrawable ? "crosshair" : "pointer",
                  }}
                  draggable={!isDrawable}
                >
                  <View
                    style={styles.shopMarker}
                    lat={shopLatitude}
                    lng={shopLongitude}
                  >
                    <Image
                      style={styles.shopContent}
                      source={IMAGES["map-shop"]}
                    />
                  </View>
                </GoogleMapReact>
              </View>
            ) : (
              <Text style={{ textAlign: "center" }}>
                {Localise(messages, "Invalid latitude & longitude of florist!")}
              </Text>
            )}
            {isDrawable || isEditable ? (
              <View
                style={{
                  position: "sticky",
                  alignSelf: "center",
                  justifyContent: "center",
                  backgroundColor: "white",
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                  bottom: osType === "Mac OS" ? "90%" : "85%",
                }}
              >
                <Text style={[fonts.heading3]}>{headerTextMessage()}</Text>
              </View>
            ) : null}
            <View
              style={{
                position: "absolute",
                top: 70,
                right: 10,
                height: 570,
                width: 400,
              }}
            >
              <ScrollView style={{ flex: 1 }}>
                <SideCardElements
                  editableZone={getIsZoneEditableFromChild}
                  onPress={() => {
                    dispatch(setIsDrawable(true));
                    dispatch(setIsCreateZoneEnabled(false));
                  }}
                  disabled={!isClickable}
                  isClickable={isClickable}
                  isAutoRoutesLoading={isAutoRoutesLoading}
                ></SideCardElements>
              </ScrollView>
            </View>
          </View>
        </View>
      )}
    </SingleScreen>
  );
};

const styles = StyleSheet.create({
  shopMarker: {
    height: 35,
    width: 150,
    left: -5,
    top: -5,
  },
  shopContent: { width: 35, height: 35 },
});

export default DeliverySettings;
