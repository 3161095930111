import React from "react";
import { FormField } from "components/elements/forms";
// import { useDispatch, useSelector } from "react-redux";
// import I18NContext from "library/contexts/i18N";

const ProfileNotes = () => {
  //   const dispatch = useDispatch();
  //   const { content: initialValues } = useSelector(selectWebsiteSettings);
  //   const { messages, Localise } = React.useContext(I18NContext);

  return (
    <FormField
      autoCapitalize="none"
      autoCorrect={false}
      name="profileNotes"
      placeholder="Enter Profile Notes"
      label=""
      multiline={true}
      numberOfLines={4}
    />
  );
};

export default ProfileNotes;
