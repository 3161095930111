import { request } from "library/utils/request";

//Prepare all order details requests
export const getOrderDetailsRequests = (orderIds, sourceMemberCode) => {
  return orderIds.map((orderId) => {
    const [id, deliveryMethod = "FLORIST_DELIVERED"] = orderId.split("$");
    return request("order-details", {
      recordId: id,
      deliveryMethod,
      sourceMemberCode,
    });
  });
};

//Prepare all customer detail requests to fetch address details
export const getCustomerDetailsRequests = (ordersList) => {
  const requestedCustomerIds = [];
  const requestedCustomerDetails = [];
  ordersList.forEach((orderInfo) => {
    const { customerInfo: { customerId, storeOrigin } = {} } = orderInfo;
    if (customerId && !requestedCustomerIds.includes(customerId)) {
      requestedCustomerIds.push(customerId);
      requestedCustomerDetails.push(
        request("get-customer-details", {
          customerId,
          storeOrigin,
        })
      );
    }
  });
  return requestedCustomerDetails;
};

//Prepare each row in funeral-manifest
const getOrdersContent = (
  Localise,
  messages,
  ordersList = [],
  customersList = []
) => {
  const ordersContent = ordersList.map((order, index) => {
    const {
      customerInfo: {
        customerId = "",
        isBusinessProfile = false,
        businessName = "",
        firstName = "",
        lastName = "",
        phones = [],
      } = {},
      orderItems,
    } = order;
    const {
      lineItems,
      deliveryInfo: { cardMessage },
    } = orderItems[0];

    const customerAddresses = customerId
      ? customersList.find((customer) => customer.customerId === customerId)
          ?.addresses || []
      : [];

    const {
      addressLine1 = "",
      city = "",
      state = "",
      zipcode = "",
    } = customerAddresses.length > 0 ? customerAddresses[0] : {};
    const addressLine2 = `${city}, ${state} ${zipcode}`;

    const customerName = isBusinessProfile
      ? businessName
      : `${firstName} ${lastName}`;

    let totalItems = 0;
    let productNames = [];

    lineItems.forEach((item) => {
      const { quantity, productFirstChoiceDescription } = item;
      totalItems += quantity;
      productNames.push(productFirstChoiceDescription);
    });

    return `<tr style="font-size:14px !important;">
    <td text-align:center;padding:0px;">${index + 1}</td>
    <td>
      <table>
        <tr><td>${customerName}</td></tr>
        ${addressLine1 ? `<tr><td>${addressLine1}</td></tr>` : ``}
        ${addressLine2.length > 3 ? `<tr><td>${addressLine2}</td></tr>` : ``}
        <tr><td>${phones[0] || ""}</td></tr>
      </table>
    </td>
    <td>
      <table>
        <tr><td>${Localise(messages, "Total Items")}:${totalItems}</td></tr>
        ${productNames.map((name) => `<tr><td>${name}</td></tr>`)}
      </table>
    </td>  
    <td>${cardMessage}</td>              
  </tr>`;
  });
  return ordersContent.join("");
};

const getShopDetails = (shopInfo) => {
  const {
    floristAddress: { addressLine1, city, state, zip },
    businessName,
    floristPhone,
  } = shopInfo;
  const addressLine2 = `${city}, ${state} ${zip}`;
  return `<table style="font-size:14px !important;">
        ${businessName ? `<tr><td>${businessName}</td></tr>` : ``}
        ${addressLine1 ? `<tr><td>${addressLine1}</td></tr>` : ``}
        ${addressLine2 ? `<tr><td>${addressLine2}</td></tr>` : ``}
        ${floristPhone ? `<tr><td>${floristPhone}</td></tr>` : ``}
      </table>`;
};

const getFuneralInfo = (locationInfo) => {
  const { name, address = {} } = locationInfo;
  const { addressLine1, city, state, zip } = address;
  const addressLine2 = `${city}, ${state} ${zip}`;
  return `<table style="font-size:14px !important;">
        ${name ? `<tr><td>${name}</td></tr>` : ``}
        ${addressLine1 ? `<tr><td>${addressLine1}</td></tr>` : ``}
        ${addressLine2 ? `<tr><td>${addressLine2}</td></tr>` : ``}
      </table>`;
};

const getOrdersInfoPerShop = (props) => {
  const {
    Localise,
    messages,
    celebrant = {},
    locationInfo,
    shopInfo,
    ordersList,
    customersList,
  } = props;

  const { firstName, lastName } = celebrant;
  const name = `${firstName} ${lastName}`;

  return `<div class="page-break-flow-root">
      <div style="background-color: white;">
        <table class="table">
          <tbody>
              <tr>
                <td style="width: 25%; font-weight: bold;border:0px;">
                  <table style="font-size:14px !important;">
                    <tr><td>${name}</td></tr>
                    <tr><td>${Localise(
                      messages,
                      "Acknowlegment list"
                    )}</td></tr>
                  </table>  
                </td>
                <td style="width: 30%; font-weight: bold;border:0px;">${getFuneralInfo(
                  locationInfo
                )}</td>
                <td style="width: 45%;border:0px;">${getShopDetails(
                  shopInfo
                )}</td>
              </tr>
          </tbody>
        </table>
      </div>  
      <div>
        <table class="table">
          <thead>
              <tr>
                <th style="width: 3%;"></th>
                <th style="width: 22%;">${Localise(messages, "Customer")}</th>
                <th style="width: 30%;">${Localise(messages, "Product(s)")}</th>
                <th style="width: 45%;">${Localise(
                  messages,
                  "Card Message"
                )}</th>
              </tr>
          </thead>
          <tbody>
              ${getOrdersContent(Localise, messages, ordersList, customersList)}
          </tbody>
        </table>
      </div>
    </div>`;
};

export const getPrintHTML = async (props) => {
  return `<!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml">
  
  <head><title>Mercury HQ</title></head>
  
  <body onload="window.parent.postMessage({ action: 'iframe-loaded' }, '*');">
    <div style="display: none">
      <style type="text/css" media="print,screen">
        @page {
        font-family: Helvetica, sans-serif;
        size: auto;
        margin: 20px;
        -webkit-margin-before: 20px;
        -webkit-margin-after: 50px;
        /* this affects the margin in the printer settings */
        }
        
        html, body {
        height: auto;
        -ms-overflow-style: none;
        }
        .page-break-flow-root {
          display: flow-root;
          page-break-after: always;
        }
        .table {
          border-collapse: collapse;
          width: 100%;
        }
        .table th {
          border: 1px solid grey;
          padding: 8px;
          text-align: left;
        }
        .table > tbody > tr > td {
          border-top: 1px solid grey;
          border-bottom: 1px solid grey;
          padding: 8px;
        }
      </style>
    </div>
    ${getOrdersInfoPerShop(props)}
  </body>
  </html>`;
};
