import React from "react";
import { View, TouchableOpacity } from "react-native";
import { useSelector } from "react-redux";

import { Text } from "react-native-elements";
import tw from "tailwind-rn";
import { theme, backgroundColors, fonts } from "styles/theme";
import { CustomModal } from "components/elements";
import { Platform } from "react-native";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { selectCurrentPage } from "library/sagas/ongoing/global-data/selector";
import {
  selectOrderItemTabs,
  selectActiveItemTab,
} from "library/sagas/views/home/drawer/create-order/selector";
export const AddressVerificationModal = ({
  showModal,
  invalidAddresses = [],
  onContinue,
  isSmallScreen,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const currentPage = useSelector(selectCurrentPage);
  const tabNames = useSelector(selectOrderItemTabs);
  const activeItemTab = useSelector(selectActiveItemTab);
  const avsTitle = tabNames[activeItemTab].avsTitle || "";

  const Content = () => {
    return (
      <View
        style={{
          maxWidth: isSmallScreen ? "100%" : 600,
          maxHeight: isSmallScreen ? "100%" : 400,
          margin: "auto",
          backgroundColor: backgroundColors.secondary,
          borderColor: "rgba(0, 0, 0, 0.1)",
        }}
      >
        <View
          style={[
            tw("p-3 flex flex-row items-center justify-between"),
            { backgroundColor: backgroundColors.primary },
          ]}
        >
          <Text style={{ ...fonts.heading1, ...tw("text-white") }}>
            {currentPage === "create-order" && tabNames.length > 1
              ? `${Localise(messages, "Address Verification for")} ${avsTitle}`
              : Localise(messages, "Address Verification")}
          </Text>
        </View>
        <View style={isSmallScreen ? {} : tw("mx-20")}>
          <Text style={tw("p-5")}>
            {Localise(
              messages,
              "We were unable to verify the delivery address. Please select the correct address below"
            )}
          </Text>
        </View>
        {invalidAddresses.map((invalidAddress, idx) => {
          //eslint-disable-next-line
          const [selectedIndex, setSelectedIndex] = useStateIfMounted(
            invalidAddress.selectedIndex
          );
          return (
            <View style={tw("my-2")} key={idx}>
              {/* <Text style={tw("ml-5")}>{`${Localise(messages, "Order Item")} #${
                invalidAddress.index + 1
              }`}</Text> */}
              <View
                style={
                  isSmallScreen
                    ? tw("px-5")
                    : tw("flex flex-row justify-around px-5")
                }
              >
                <RadioField
                  isSmallScreen={isSmallScreen}
                  address={invalidAddress.originalAddress}
                  label={Localise(messages, "Use Original Address")}
                  index={0}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={() => {
                    setSelectedIndex(0);
                    invalidAddresses.splice(idx, 1, {
                      ...invalidAddress,
                      selectedIndex: 0,
                    });
                  }}
                />
                <RadioField
                  isSmallScreen={isSmallScreen}
                  address={invalidAddress.suggestedAddress}
                  label={Localise(messages, "Suggested Address")}
                  index={1}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={() => {
                    setSelectedIndex(1);
                    invalidAddresses.splice(idx, 1, {
                      ...invalidAddress,
                      selectedIndex: 1,
                    });
                  }}
                />
              </View>
            </View>
          );
        })}
      </View>
    );
  };

  const modalContent = {
    content: <Content />,
    buttons: [{ type: "primary", title: Localise(messages, "Confirm") }],
  };

  return (
    <CustomModal
      modalVisible={showModal}
      modalContent={modalContent}
      primaryhandler={() => onContinue(invalidAddresses)}
      contentStyle={{ backgroundColor: "white" }}
      modalStyle={
        Platform.OS !== "web"
          ? {
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor: "#00000070",
              color: backgroundColors.secondary,
            }
          : { padding: 0 }
      }
    ></CustomModal>
  );
};

const RadioField = ({
  isSmallScreen,
  address,
  index,
  selectedIndex,
  setSelectedIndex,
  label,
}) => {
  const { addressLine1, city, state, zip } = address;
  const id = `select_${index}`;
  return (
    <TouchableOpacity
      key={index}
      style={{
        ...tw("flex flex-row items-start mr-10 p-4"),
        ...(!isSmallScreen && { maxWidth: "50%" }),
      }}
      onPress={setSelectedIndex}
      testID={id}
      accessibilityLabel={id}
    >
      <View style={theme.Radio.inputStyle}>
        {selectedIndex === index && <View style={theme.Radio.selectedStyle} />}
      </View>
      <View style={tw("ml-2 mt-1 w-full")}>
        <Text style={tw("mb-2")}>{label}</Text>
        <Text>{addressLine1}</Text>
        <Text>{`${city}, ${state} ${zip}`}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default AddressVerificationModal;
