import { combineReducers } from "redux";
import { SLICE_NAME } from "./constants";

import molSettings from "./mol-settings/slice";
import memberSettings from "./member-settings/reducer";

const reducer = {
  [SLICE_NAME]: combineReducers({
    ...molSettings,
    ...memberSettings,
  }),
};

export default reducer;
