import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScrollView, View, TouchableOpacity, Platform } from "react-native";
import { Text, Button } from "react-native-elements";

import { ToasterHandler, Spinner } from "components/elements";
import { Form, FormField, FormFieldCheckBox } from "components/elements/forms";
import { Section, Currency, SaveCancelButtons } from "components/wrappers";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { setSideCar } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import { saveCitiesFees } from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/slice";
import { selectShopCode } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import {
  selectCitiesFees,
  selectSearchCityText,
} from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/selector";
import { isValidFeeValue } from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/sections/fee-coverage";

import SearchFilter from "../extended/searchFilter";
import FeeCoverageError from "../errors";
import FeesCoverageModal from "./alert";
import { FormFieldProps } from "../ui-config";
import { getCitiesFeesValidationSchema } from "../yup";

import { theme, colors, backgroundColors, fonts } from "styles/theme";
import tw from "tailwind-rn";
import toLower from "lodash/toLower";
import startCase from "lodash/startCase";

const CoverageInputFields = () => {
  const dispatch = useDispatch();
  const shopCode = useSelector(selectShopCode);
  const initialValues = useSelector(selectCitiesFees);
  const { messages, Localise } = React.useContext(I18NContext);
  const { isMobile } = React.useContext(DeviceContext);
  const [showModal, setShowModal] = useState({});

  const validationSchema = getCitiesFeesValidationSchema(messages, Localise);
  const submitBtnRef = useRef(null);
  const currency = Currency(shopCode);
  const updateBtnClickHandler = () => submitBtnRef.current.triggerSubmit();

  if (!initialValues) {
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );
  }

  const onSubmit = (values, formikBag) => {
    dispatch(
      saveCitiesFees({
        params: { values, initialValues },
        resolve: () => {
          ToasterHandler(
            "success",
            Localise(
              messages,
              "Your Fee and Coverage Settings have been updated"
            )
          );

          formikBag.setSubmitting(false);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  };

  return (
    <>
      <Section
        title={Localise(messages, "City & Zip Code Coverage")}
        titleStyle={{ fontWeight: "800" }}
      >
        <Form
          initialValues={initialValues}
          onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
          validationSchema={validationSchema}
          render={({ values, errors, setFieldValue }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const searchCity = useSelector(selectSearchCityText);
            const errorsArrayIndexes = Object.keys(errors);
            const errorsArray = errorsArrayIndexes.map(
              (errorIndex) => values[errorIndex].city
            );

            return (
              <>
                <View
                  style={[
                    tw(
                      "flex flex-row flex-wrap justify-between pr-3 mb-3 items-center"
                    ),
                  ]}
                >
                  <View style={[tw(`flex-1`)]}>
                    <SearchFilter
                      data={values}
                      isCitySearch={true}
                      label={Localise(messages, "Click city to edit zip codes")}
                    />
                  </View>
                </View>
                {showModal.title && (
                  <FeesCoverageModal
                    title={Localise(messages, showModal.title)}
                    primaryAction={showModal.primaryAction}
                    secondaryAction={showModal.secondaryAction}
                  />
                )}
                <View>
                  <ScrollView
                    contentContainerStyle={tw(
                      "flex flex-row flex-wrap items-start"
                    )}
                    {...(Platform.OS === "android" && {
                      nestedScrollEnabled: true,
                    })}
                  >
                    {values?.map((coverageArea, i) => {
                      const initFee = coverageArea.fee;
                      const status = coverageArea.status;
                      const isFieldChecked = status === "Y";

                      if (
                        !status ||
                        !toLower(coverageArea.city).startsWith(
                          toLower(searchCity)
                        )
                      )
                        return null;

                      return (
                        <View
                          key={i}
                          style={[
                            tw("flex-row items-center mb-3 mr-3 border p-2"),
                            {
                              backgroundColor: backgroundColors.navBar,
                              borderColor: colors.light,
                            },
                          ]}
                        >
                          <View style={{ maxHeight: 40, overflow: "hidden" }}>
                            <FormFieldCheckBox
                              iconRight={false}
                              name={`${i}.status`}
                              size={20}
                              title={""}
                              isChecked={isFieldChecked}
                              onChangehandler={() =>
                                setFieldValue(
                                  `${i}.status`,
                                  isFieldChecked ? "N" : "Y"
                                )
                              }
                            />
                          </View>
                          <TouchableOpacity
                            onPress={() => {
                              dispatch(
                                setSideCar({
                                  type: "city-zipcodes",
                                  data: coverageArea,
                                  Localise,
                                  messages,
                                })
                              );
                            }}
                            testID="city"
                            accessibilityLabel="city"
                          >
                            <Text
                              style={{ ...fonts.link1, width: 80 }}
                              ellipsizeMode="tail"
                              numberOfLines={1}
                            >
                              {startCase(toLower(coverageArea.city))}
                            </Text>
                          </TouchableOpacity>
                          <Text style={{ minWidth: 22 }}>
                            {coverageArea.state}
                          </Text>
                          <FormField
                            name={`${i}.fee`}
                            {...FormFieldProps}
                            containerStyle={FormFieldProps.containerStyle}
                            handleOnBlur={(newVal) => {
                              isValidFeeValue(newVal) &&
                                newVal !== initFee &&
                                setShowModal({
                                  title: `${coverageArea.city} ${Localise(
                                    messages,
                                    "Zip Code(s)"
                                  )}`,
                                  primaryAction: () => {
                                    updateBtnClickHandler();
                                    setShowModal({});
                                  },
                                  secondaryAction: () => {
                                    setFieldValue(`${i}.fee`, initFee);
                                    setShowModal({});
                                  },
                                });
                            }}
                            iconType={"material-community"}
                            iconName={"currency-usd"}
                            iconSize={14}
                            currency={currency}
                          />
                        </View>
                      );
                    })}
                  </ScrollView>
                </View>
                <FeeCoverageError
                  title={Localise(
                    messages,
                    "Please enter valid fees for cities below"
                  )}
                  errorsArray={errorsArray}
                />
                <View
                  style={tw(`justify-between flex-${isMobile ? "col" : "row"}`)}
                >
                  <Button
                    testID={"new_coverage_area"}
                    accessibilityLabel="new_coverage_area"
                    titleStyle={{
                      ...theme.Button.secondaryTitleStyle,
                    }}
                    buttonStyle={{
                      ...theme.Button.secondaryButtonStyle,
                    }}
                    containerStyle={{ marginLeft: 0 }}
                    title={Localise(messages, "New Coverage Area")}
                    onPress={() => {
                      dispatch(setSideCar({ type: "new-coverage" }));
                    }}
                  />
                  <SaveCancelButtons
                    buttonRef={submitBtnRef}
                    buttonTitle={Localise(messages, "Save")}
                  />
                </View>
              </>
            );
          }}
        />
      </Section>
    </>
  );
};

export default CoverageInputFields;
