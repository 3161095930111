import * as Yup from "yup";
import moment from "moment";
import { cutOffTimeInterval } from "library/utils/deliveryService";
import { isDeliveryService, convertToTimeZone, roundUpTime } from "./helper";

export const getValidationSchema = (Localise, messages, isEditRecipient) => {
  return isEditRecipient
    ? Yup.object().shape({
        firstName: Yup.string().required(
          Localise(messages, "Please enter first name")
        ),
        lastName: Yup.string().required(
          Localise(messages, "Please enter last name")
        ),
        phone: Yup.string().matches(
          new RegExp(
            "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
          ),
          Localise(messages, "Please enter a valid phone number")
        ),
      })
    : Yup.object().shape({
        addressLine1: Yup.string().required(
          Localise(messages, "Please enter Street Address")
        ),
        country: Yup.string().required(
          Localise(messages, "Please enter Country")
        ),
        city: Yup.string().required(Localise(messages, "Please enter City")),
        state: Yup.string().required(Localise(messages, "Please enter State")),
        zip: Yup.string().required(Localise(messages, "Please enter Zip Code")),
      });
};

Yup.addMethod(Yup.string, "invalidDSPickupTime", function (errorMessage) {
  return this.test(`invalid-ds-pickup-time`, errorMessage, function (value) {
    const { path, createError, parent } = this;

    // We are fetching formik values using Form ref as we are not getting latest values with parent
    const {
      newPickUpDate,
      pickUpDateTime,
      timezone,
      doordashPickUpTime,
      deliveryProvider,
    } = parent;
    let pickUpDateTimeValue = pickUpDateTime;
    if (deliveryProvider === "doordash" && doordashPickUpTime) {
      // Appended :00 seconds to go through the valid time validation
      pickUpDateTimeValue = `${newPickUpDate}T${doordashPickUpTime}:00`;
    }
    if (
      pickUpDateTimeValue.indexOf(":00:") === -1 &&
      pickUpDateTimeValue.indexOf(":30:") === -1
    ) {
      return createError({ path, message: errorMessage });
    }

    let isSameDay = moment(pickUpDateTimeValue).isSame(
      convertToTimeZone(moment.utc().format(), timezone),
      "day"
    );
    if (isSameDay) {
      const dsBufferTime = moment(
        roundUpTime(
          30 * 60 * 1000,
          convertToTimeZone(
            moment
              .utc()
              .add(cutOffTimeInterval[deliveryProvider], "minutes")
              .format(),
            timezone
          )
        )
      );
      let selectedPickUpTime = moment(pickUpDateTimeValue);
      if (dsBufferTime && selectedPickUpTime.isBefore(dsBufferTime)) {
        return createError({ path, message: errorMessage });
      }
    }
    return true;
  });
});

export const DSFormValidationSchema = (messages, Localise) => {
  return Yup.object().when("deliveryMethod", {
    is: isDeliveryService,
    then: Yup.object().shape({
      newPickUpDate: Yup.string().required(
        Localise(messages, "Select pickup Date")
      ),
      pickUpDateTime: Yup.string()
        .required(Localise(messages, "Select pickup Time"))
        .invalidDSPickupTime(
          Localise(messages, "Choose next available pickup time slot")
        ),
      undeliverableAction: Yup.string().required(
        Localise(messages, "Please select Undeliverable Action")
      ),
    }),
    otherwise: Yup.object().shape({}),
  });
};
