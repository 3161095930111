import { Platform } from "react-native";
import * as Yup from "yup";

export const getValidationSchema = (Localise, messages) => {
  Yup.addMethod(Yup.string, "validateBccEmails", function (errorMessage) {
    return this.test("validate-bcc-emails", errorMessage, function (value) {
      const { createError, path } = this;
      const enteredMails = value ? value.split(",") : [];

      const inValidEmails = enteredMails?.reduce((acc, val) => {
        const isValidEmail = Yup.string().email().isValidSync(val.trim());
        if (!isValidEmail) acc.push(val);
        return acc;
      }, []);

      return (
        !inValidEmails.length ||
        createError({
          path,
          message: `${errorMessage}"${inValidEmails.toString()}"`,
        })
      );
    });
  });

  Yup.addMethod(Yup.string, "isValidHTML", function (errorMessage) {
    return this.test("isValidHTML", errorMessage, function (value) {
      const { createError, path } = this;

      if (!value || Platform.OS !== "web") return true;
      const parser = new DOMParser();

      const doc = parser.parseFromString(value, "text/html");
      if (doc.documentElement.querySelector("parsererror")) {
        return createError({
          path,
          message: errorMessage,
        });
      } else {
        return true;
      }
    });
  });

  return Yup.object().shape({
    websiteEmail: Yup.string()
      .required(Localise(messages, "Enter Website email"))
      .email(Localise(messages, "Enter a valid email")),
    orderEmail: Yup.string()
      .required(Localise(messages, "Enter Order email"))
      .email(Localise(messages, "Enter a valid email")),
    bccEmails: Yup.string().validateBccEmails(
      Localise(messages, "Please correct email addresses: ")
    ),
    additionalScripts: Yup.string().isValidHTML(
      Localise(messages, "Invalid HTML Format")
    ),
  });
};
