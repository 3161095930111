import React from "react";
import { Text } from "react-native-elements";
import { Platform, View } from "react-native";
import { fonts, colors } from "styles/theme";
import tw from "tailwind-rn";
import { RichTextEditor, LogoImage } from "components/elements";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

const EditorContainer = ({
  msgValue = "",
  setMsgValue,
  fileInfo,
  cardSettings,
  isSmallScreen,
  isAdhocTriFold = false,
  variant = "cardMsg",
  placeholder = "",
  clearEditor,
  setClearEditor,
}) => {
  let isBottom = false;
  let isRight = false;
  let isLogo = false;

  const logoPlacement =
    cardSettings.logoOrientation || cardSettings.bannerOrientation;

  if (cardSettings.isLogoOpted) {
    const positions = cardSettings.logoOrientation.split(" ");
    isBottom = positions[0] !== "Top";
    isRight = positions[1] !== "Left";
    isLogo = true;
  } else if (cardSettings.isBannerOpted) {
    isBottom = cardSettings.bannerOrientation === "Bottom";
  }

  const { isDesktop } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const [error, setError] = useStateIfMounted("");

  return (
    <View
      style={[
        tw("p-2"),
        {
          width: "100%",
          minHeight: variant !== "cardMsg" ? 220 : 335,
          margin: "auto",
          ...(!isAdhocTriFold && {
            borderColor: colors.grayScaleLight,
            borderWidth: 1,
            borderRadius: 10,
          }),
        },
        fonts.style1,
      ]}
    >
      {!isBottom && !!fileInfo.uri && (
        <View style={tw("p-2")}>
          <LogoImage
            img={fileInfo.uri}
            isRight={isRight}
            isLogo={isLogo}
            logoPlacement={logoPlacement}
            isSmallScreen={isSmallScreen}
          />
        </View>
      )}
      <View
        onLayout={(event) => {
          var { height } = event.nativeEvent.layout;
          if (isDesktop && variant === "cardMsg") {
            setError(
              (!!fileInfo.uri && height > 170) || height > 380
                ? Localise(messages, "Message is too long, please edit.")
                : ""
            );
          } else if (isDesktop && variant === "recipient") {
            setError(
              height > 200
                ? Localise(messages, "Message is too long, please edit.")
                : ""
            );
          }
        }}
      >
        <RichTextEditor
          value={msgValue.replace(/\n/g, "<br />")}
          setValue={setMsgValue}
          style={{ height: fileInfo.uri ? 220 : 350 }}
          testID={`card_details_editor_${variant}`}
          toolbarOptions={
            Platform.OS === "web"
              ? {
                  options: [
                    "textAlign",
                    "colorPicker",
                    "fontSize",
                    "fontFamily",
                  ],
                  textAlign: {
                    inDropdown: true,
                    options: ["center", "left", "right", "justify"],
                  },
                }
              : ["bold", "italic", "bulletsList", "orderedList", "underline"]
          }
          toolbarStyle={
            isAdhocTriFold
              ? {
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }
              : { flexDirection: "row-reverse" }
          }
          persistStyling={isAdhocTriFold}
          placeholder={placeholder}
          clearEditor={clearEditor}
          setClearEditor={setClearEditor}
          editorClassName={isAdhocTriFold ? "adhoc-editor" : ""}
        />
      </View>
      {!!error && (
        <Text
          style={[
            {
              ...fonts.error,
              marginTop: 0,
              marginBottom: 12,
              textAlign: "left",
            },
          ]}
        >
          {error}
        </Text>
      )}

      {isBottom && !!fileInfo.uri && (
        <View style={[tw("p-2"),{zIndex: -1}]}>
          <LogoImage
            img={fileInfo.uri}
            isRight={isRight}
            isLogo={isLogo}
            logoPlacement={logoPlacement}
            isSmallScreen={isSmallScreen}
          />
        </View>
      )}
    </View>
  );
};

const EditCard = ({
  msgValue = "",
  setMsgValue,
  fileInfo,
  cardSettings,
  isSmallScreen,
  isAdhocTriFold,
  variant,
  placeholder,
  clearEditor,
  setClearEditor,
}) => {
  return (
    <View>
      <View style={[tw("flex w-full flex-row items-center justify-center")]}>
        <EditorContainer
          msgValue={msgValue}
          setMsgValue={setMsgValue}
          fileInfo={fileInfo}
          cardSettings={cardSettings}
          isSmallScreen={isSmallScreen}
          isAdhocTriFold={isAdhocTriFold}
          variant={variant}
          placeholder={placeholder}
          clearEditor={clearEditor}
          setClearEditor={setClearEditor}
        />
      </View>
    </View>
  );
};

export default EditCard;
