import * as Yup from "yup";

Yup.addMethod(Yup.string, "invalidOrderPrice", function (errorMessage) {
  return this.test(`invalid-order-price`, errorMessage, function (value) {
    const { path, createError, parent } = this;

    const { addonsTotalPrice } = parent;

    if (!value || isNaN(value)) {
      return createError({
        path,
        message: "Invalid Price",
      });
    }

    const itemPrice = parseFloat(value);

    if (addonsTotalPrice > 0 && itemPrice < parseFloat(addonsTotalPrice)) {
      return createError({
        path,
        message: `${errorMessage} $${addonsTotalPrice}`,
      });
    }

    return true;
  });
});

export const getValidationSchema = (Localise, messages) => {
  return Yup.object().shape({
    itemPrice: Yup.string()
      .required(Localise(messages, "Invalid Price"))
      .matches(/^\d+\.?\d*$/, Localise(messages, "Invalid Price"))
      .invalidOrderPrice(Localise(messages, "New price cannot be less than")),
  });
};
