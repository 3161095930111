import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectPageActions = createSelector(
  selectState,
  ({ actions }) => actions
);

export const selectOrderActions = createSelector(
  selectState,
  ({ orderActions }) => orderActions
);

export const selectAppliedLimits = createSelector(
  selectPageActions,
  ({ limit }) => limit.orders
);

export const selectScannedOrders = createSelector(
  selectState,
  ({ scannedOrders }) => scannedOrders
);

export const selectActionsQR = createSelector(
  selectState,
  ({ actionsQR }) => actionsQR
);

export const selectOrdersCount = createSelector(
  selectState,
  ({ ordersCount }) => ordersCount
);
