// This function Returns contactInfoTopHalf and contactInfoBottomHalf values based on formikValue
export const getContactInfoPlacements = (placement) => {
  if (placement === "") {
    return { contactInfoTopHalf: "", contactInfoBottomHalf: "" };
  } else if (placement === "Florist Info") {
    return {
      contactInfoTopHalf: "Florist Info",
      contactInfoBottomHalf: "Recipient Info",
    };
  } else if (placement === "Recipient Info") {
    return {
      contactInfoTopHalf: "Recipient Info",
      contactInfoBottomHalf: "Florist Info",
    };
  } else if (placement === "RI_DI") {
    return {
      contactInfoTopHalf: "Recipient Info",
      contactInfoBottomHalf: "Delivery Info",
    };
  }
};

// This Function returns formik value for the Florist / Recipient Info Placement
export const getFormikContactInfoPlacement = (
  contactInfoTopHalf = "",
  contactInfoBottomHalf = ""
) => {
  if (contactInfoTopHalf === "" && contactInfoBottomHalf === "") {
    return "";
  } else if (
    contactInfoTopHalf === "Florist Info" &&
    ["", "Recipient Info"].includes(contactInfoBottomHalf)
  ) {
    return "Florist Info";
  } else if (
    contactInfoTopHalf === "Recipient Info" &&
    ["", "Florist Info"].includes(contactInfoBottomHalf)
  ) {
    return "Recipient Info";
  } else if (
    contactInfoTopHalf === "Recipient Info" &&
    contactInfoBottomHalf === "Delivery Info"
  ) {
    return "RI_DI";
  }
};

export const triFoldOrderInfoFields = [
  "Delivery Date",
  "Delivery Time",
  "Delivery Zone",
  "Order No",
];
