import { put, takeLatest } from "redux-saga/effects";
import { setSideCarTitle } from "../common/slice";
import { setMemberCode } from "./slice";
import get from "lodash/get";

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
function* handleMemberCodeSelection(action = {}) {
  const memberCode = get(action, "payload", "");
  yield put(setSideCarTitle(`Shop #${memberCode}`));
}

/**
 * Will be using when integrating UI with backend
 */
export function* watchSaga() {
  yield takeLatest(setMemberCode.type, handleMemberCodeSelection);
}

export default watchSaga;
