export const sortOptions = [
  {
    key: "Price: Low to High",
    label: "Price: Low to High",
    fieldName: "price",
    order: "Asc",
  },
  {
    key: "Price: High to Low",
    label: "Price: High to Low",
    fieldName: "price",
    order: "Desc",
  },
  {
    key: "Product Name (A to Z)",
    label: "Product Name: A-Z",
    fieldName: "name",
    order: "Asc",
  },
  {
    key: "Product Name (Z to A)",
    label: "Product Name: Z-A",
    fieldName: "name",
    order: "Desc",
  },
  {
    key: "Product Code: Low to High",
    label: "Product Code: Low to High",
    fieldName: "pid",
    order: "Asc",
  },
  {
    key: "Product Code: High to Low",
    label: "Product Code: High to Low",
    fieldName: "pid",
    order: "Desc",
  },
];
