import React from "react";
import { View, ActivityIndicator, TouchableOpacity } from "react-native";
import { Text, Divider, Button } from "react-native-elements";
import tw from "tailwind-rn";
import { fonts, backgroundColors, colors, theme } from "styles/theme";
import { ErrorMessages, TYPE } from "./config";
import * as DocumentPicker from "expo-document-picker";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";
import { ToasterHandler } from "components/elements";

const BulkAccounts = ({ isDesktop, userRole }) => {
  const [mhqFile, setMhqFile] = useStateIfMounted({});
  const [mhqFileName, setMhqFileName] = useStateIfMounted("");
  const [mhqError, setMhqError] = useStateIfMounted("");
  const [showMhqLoading, setShowMhqLoading] = useStateIfMounted("");
  const [mhqResults, setMhqResults] = useStateIfMounted([]);
  const [showMhqSampleFileLoading, setShowMhqSampleFileLoading] =
    useStateIfMounted(false);

  const [mCloudFile, setMCloudFile] = useStateIfMounted({});
  const [mCloudFileName, setMCloudFileName] = useStateIfMounted("");
  const [mCloudError, setMCloudError] = useStateIfMounted("");
  const [showMCloudLoading, setShowMCloudLoading] = useStateIfMounted("");
  const [mCloudResults, setMCloudResults] = useStateIfMounted([]);
  const [showMCloudSampleFileLoading, setShowMCloudSampleFileLoading] =
    useStateIfMounted(false);

  const chooseFile = async (fileType) => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    if (result.type === "success") {
      if (fileType === TYPE.MHQ) {
        setMhqFile(result);
        setMhqError("");
      } else {
        setMCloudFile(result);
        setMCloudError("");
      }
    }
  };

  const handleSubmit = (fileType) => {
    if (fileType === TYPE.MHQ) {
      if (!mhqFile.name) {
        setMhqError(ErrorMessages.fileRequired);
      } else {
        setMhqFileName(mhqFile.name);

        setMhqError("");
        setShowMhqLoading("load");
        setMhqResults([]);

        const mhqBulkAccPayload = new FormData();
        mhqBulkAccPayload.append("bulkAccountCreation-mhq", mhqFile.file);

        request("create-bulk-accounts-mhq", mhqBulkAccPayload)
          .then((res) => {
            setMhqFile({});
            processMHQReponse(res);
          })
          .catch((err) => {
            setShowMhqLoading("");
            setMhqError(ErrorMessages.errorOccured);
          });
      }
    }

    if (fileType === TYPE.MCLOUD) {
      if (!mCloudFile.name) {
        setMCloudError(ErrorMessages.fileRequired);
      } else {
        setMCloudFileName(mCloudFile.name);

        setMCloudError("");
        setShowMCloudLoading("load");
        setMCloudResults([]);

        const mCloudBulkAccPayload = new FormData();
        mCloudBulkAccPayload.append(
          "bulkAccountCreation-mcloud",
          mCloudFile.file
        );

        request("create-bulk-accounts-mcloud", mCloudBulkAccPayload)
          .then((res) => {
            setMCloudFile({});
            processMCloudReponse(res);
          })
          .catch((err) => {
            setShowMCloudLoading("");
            setMCloudError(ErrorMessages.errorOccured);
          });
      }
    }
  };

  const processMHQReponse = (response = []) => {
    try {
      response.map((record) => {
        let remarks = "";

        if (record.isOnboardSuccess) {
          record.status = "Created";

          if (record.errorDescription) {
            remarks = record.errorDescription;
            record.colorCode = colors.warning;
          } else {
            remarks = "Account created successfully.";
            record.colorCode = colors.success;
          }
        } else {
          record.status = "Not Created";
          record.colorCode = colors.error;

          if (record.errorDescription) {
            remarks = record.errorDescription;
          }
        }

        record.remarks = remarks;
      });

      setMhqResults(response);
    } catch (err) {
      setMhqError(ErrorMessages.internalErrorOccured);

      setMhqResults([]);
    }

    setShowMhqLoading("done");
  };

  const processMCloudReponse = (response = []) => {
    try {
      response.map((record) => {
        let remarks = "";

        if (record.isOnboardSuccess) {
          remarks = "Account created successfully.";
          record.status = "Created";
          record.colorCode = colors.success;
        } else {
          record.status = "Not Created";
          record.colorCode = colors.error;

          if (record.errorDescription) {
            remarks = record.errorDescription;
          }
        }

        record.remarks = remarks;
      });

      setMCloudResults(response);
    } catch (err) {
      setMCloudError(ErrorMessages.internalErrorOccured);

      setMCloudResults([]);
    }

    setShowMCloudLoading("done");
  };

  const downloadSampleFile = (fileType) => {
    if (fileType === TYPE.MHQ) setShowMhqSampleFileLoading(true);
    else if (fileType === TYPE.MCLOUD) setShowMCloudSampleFileLoading(true);

    request("download-image", {
      imageName:
        fileType === TYPE.MHQ
          ? "bulkAccountCreation-mhq.xlsx"
          : "bulkAccountCreation-mcloud.xlsx",
      memberCode: "FTD",
    })
      .then((res) => {
        var tempAnchorTag = document.createElement("a"); // creating temp anchor tag
        tempAnchorTag.href =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          res.source;
        tempAnchorTag.download =
          fileType === TYPE.MHQ
            ? "mhq-sample-file.xlsx"
            : "mcloud-sample-file.xlsx";
        tempAnchorTag.click(); // triggering click event in order to download sample file automatically as we alreday clicked on sample file link

        if (fileType === TYPE.MHQ) setShowMhqSampleFileLoading(false);
        else if (fileType === TYPE.MCLOUD)
          setShowMCloudSampleFileLoading(false);
      })
      .catch(() => {
        if (fileType === TYPE.MHQ) setShowMhqSampleFileLoading(false);
        else if (fileType === TYPE.MCLOUD)
          setShowMCloudSampleFileLoading(false);

        ToasterHandler(
          "error",
          "Failed to download sample file, please try again later."
        );
      });
  };

  // eslint-disable-next-line react/display-name
  const GenerateUIForResults = React.memo(({ results = [] }) => {
    return (
      <View>
        {results.map((record, index) => {
          return (
            <View key={index}>
              <View
                style={[
                  {
                    borderWidth: 1,
                    borderColor: colors.grayScaleLight,
                    padding: 8,
                    marginBottom: 5,
                    height: "auto",
                    minHeight: 40,
                    width: "100%",
                  },
                  tw("flex flex-row w-full items-center"),
                ]}
              >
                <Text
                  style={{
                    ...fonts.heading5,
                    width: "10%",
                    paddingLeft: 10,
                  }}
                >
                  {record.memberCode}
                </Text>
                <Text
                  style={{
                    ...fonts.heading5,
                    width: "18%",
                    textAlign: "center",
                    color: `${record.colorCode}`,
                  }}
                >
                  {record.status}
                </Text>
                <Text
                  style={{
                    width: "72%",
                  }}
                >
                  {record.remarks}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    );
  });

  return (
    <View style={[tw("mt-5"), { zIndex: -1 }]}>
      <View
        style={{
          borderColor: colors.light,
          borderWidth: 1,
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.1,
          shadowRadius: 5,
          borderRadius: 5,
        }}
      >
        <View
          style={[
            {
              backgroundColor: backgroundColors.navBar,
            },
            tw("flex justify-center px-4 py-4"),
          ]}
        >
          <Text style={[fonts.heading3, tw("mb-2")]}>
            Bulk Accounts Creation
          </Text>
          <Text style={[{ ...fonts.default, color: colors.info }, tw("my-2")]}>
            Note: Currently, this feature supports creating Standalone shops
            only.
          </Text>
        </View>
        <View style={tw("px-4 pb-4")}>
          <View>
            <View style={tw("flex flex-row items-center")}>
              <Text style={[fonts.heading4, tw("mr-1")]}>Mercury HQ</Text>

              <Text>( </Text>
              <TouchableOpacity
                onPress={() => {
                  downloadSampleFile(TYPE.MHQ);
                }}
              >
                <Text style={[fonts.link1]}>Sample File</Text>
              </TouchableOpacity>
              <Text> )</Text>

              {showMhqSampleFileLoading && (
                <ActivityIndicator
                  color={colors.activityIndicator}
                  style={tw("ml-2")}
                />
              )}
            </View>

            <View style={tw("flex flex-row items-center mt-4")}>
              <Button
                testID={"choose_mhq_file"}
                accessibilityLabel="choose_mhq_file"
                titleStyle={{
                  ...theme.Button.secondaryTitleStyle,
                  ...fonts.heading5,
                }}
                buttonStyle={{
                  ...theme.Button.secondaryButtonStyle,
                  paddingVertical: 2,
                }}
                containerStyle={{ marginLeft: 0 }}
                title="Choose File"
                onPress={() => {
                  chooseFile(TYPE.MHQ);
                }}
              />
              <Text>{mhqFile.name || "No file chosen"}</Text>
            </View>

            <View style={tw("flex flex-row items-center my-5")}>
              <Button
                containerStyle={{
                  margin: 2,
                }}
                onPress={() => {
                  handleSubmit(TYPE.MHQ);
                }}
                title={"Submit"}
                disabled={mhqFile.name ? false : true}
              />

              <Text style={[theme.SubmitButton.errorStyle, tw("ml-2 mt-2")]}>
                {mhqError}
              </Text>

              {showMhqLoading === "load" && (
                <ActivityIndicator color={colors.activityIndicator} />
              )}
            </View>

            {showMhqLoading === "done" && (
              <View style={{ marginTop: 15 }}>
                <Text style={{ ...fonts.heading5, marginBottom: 10 }}>
                  Results of: {mhqFileName}
                </Text>

                <GenerateUIForResults results={mhqResults} />
              </View>
            )}
          </View>

          <Divider
            style={{
              marginVertical: 10,
              backgroundColor: backgroundColors.primary,
              opacity: 0.4,
            }}
          />

          <View style={tw("mt-5")}>
            <View style={tw("flex flex-row items-center")}>
              <Text style={[fonts.heading4, tw("mr-1")]}>Mercury Cloud</Text>

              <Text>( </Text>
              <TouchableOpacity
                onPress={() => {
                  downloadSampleFile(TYPE.MCLOUD);
                }}
              >
                <Text style={[fonts.link1]}>Sample File</Text>
              </TouchableOpacity>
              <Text> )</Text>

              {showMCloudSampleFileLoading && (
                <ActivityIndicator
                  color={colors.activityIndicator}
                  style={tw("ml-2")}
                />
              )}
            </View>

            <View style={tw("flex flex-row items-center mt-4")}>
              <Button
                testID={"choose_mcloud_file"}
                accessibilityLabel="choose_mcloud_file"
                titleStyle={{
                  ...theme.Button.secondaryTitleStyle,
                  ...fonts.heading5,
                }}
                buttonStyle={{
                  ...theme.Button.secondaryButtonStyle,
                  paddingVertical: 2,
                }}
                containerStyle={{ marginLeft: 0 }}
                title="Choose File"
                onPress={() => {
                  chooseFile(TYPE.MCLOUD);
                }}
              />
              <Text>{mCloudFile.name || "No file chosen"}</Text>
            </View>

            <View style={tw("flex flex-row items-center my-5")}>
              <Button
                containerStyle={{
                  margin: 2,
                }}
                onPress={() => {
                  handleSubmit(TYPE.MCLOUD);
                }}
                title={"Submit"}
                disabled={mCloudFile.name ? false : true}
              />

              <Text style={[theme.SubmitButton.errorStyle, tw("ml-2 mt-2")]}>
                {mCloudError}
              </Text>

              {showMCloudLoading === "load" && (
                <ActivityIndicator color={colors.activityIndicator} />
              )}
            </View>

            {showMCloudLoading === "done" && (
              <View style={{ marginTop: 15 }}>
                <Text style={{ ...fonts.heading5, marginBottom: 10 }}>
                  Results of: {mCloudFileName}
                </Text>

                <GenerateUIForResults results={mCloudResults} />
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default BulkAccounts;
