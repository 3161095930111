import { createContext } from "react";
import noop from "lodash/noop";

/**
 * The context used for storing user access information
 *
 * @see 'providers/appSettings'
 * @see 'reducers/appSettings'
 */
const AppSettingsContext = createContext({
  /**
   * Menu Context
   */
  allowedMenus: [],
  menuRole: null,
  permissions: {},
  isInactiveUser: false,
  /**
   * Updates permissions
   * @param permissions - new permissions
   */
  setPermissions: noop,
});

const DeviceContext = createContext({
  /**
   * Device Context
   */
  isMobile: false,
  isDesktop: false,
  isTablet: false,
  isPotrait: false,
  isLandscape: false,
});

export default AppSettingsContext;
export { AppSettingsContext, DeviceContext };
