import React, { useContext, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { request } from "library/utils/request";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import { Entitlements } from "library/utils/entitlements";
import Environment from "library/utils/environment";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { defaultDeliveryProvider } from "library/utils/deliveryService";
import UserProfileStorage from "library/storage/userProfile";

import {
  UIConfig,
  InitDSRequestsData,
  ListOfColumns,
  ListOfMobileColumns,
  ListOfSubColumns,
  InitViewMoreCounts,
  ListOfFilters,
  ListOfSortOptions,
  ListOfActions,
  ListOfSubActions,
  ListOfDeliveryTaskStates,
  ListOfDeliveryServiceSources,
  DSFetchInterval,
} from "./config";

//components
import ListingScreen from "components/containers/listing";
import List from "./listing-screen";
import DeliveryTaskDetails from "./upsert-screen";
import ListingHeader from "./listing-screen/list-header";

import {
  setRecordId,
  setActions,
  setRecordAction,
  fetchData,
  setShopCode,
  resetBasicInfo,
} from "library/sagas/views/home/drawer/delivery-tasks/slice";

const DeliveryTasks = ({
  deliveryTasksList,
  dashboardShopCode,
  loading,
  params = {},
  setLoading,
  setShowCreateDSRequest,
  ...userActions
}) => {
  const { isMobile } = useContext(DeviceContext);
  const { permissions } = useContext(AppSettingsContext);
  const [createDSEligible, setCreateDSEligible] = useStateIfMounted(false);
  const [DSPageLoading, setDSPageLoading] = useStateIfMounted(false);
  const operatorRole = UserProfileStorage.getOperatorRole();
  const { resetBasicInfo, setRecordId, setShopCode, setParams } = userActions;

  const CreateDSEntitledShops = [];
  Object.keys(permissions).map((memberCode) => {
    if (
      permissions[memberCode][Entitlements.DELIVERY_TASKS]?.includes(
        Entitlements.DELIVERY_TASKS_PAGE.CREATE_DS_REQUEST
      )
    )
      CreateDSEntitledShops.push(memberCode);
  });

  const isCustomerService = operatorRole === "FTD_CSR";

  const { data = [] } = deliveryTasksList;
  const { referenceNumber = "", applyParamFilters = false } = params;

  const DSRequests = Object.values(data)
    .map((section) => section.list)
    .flat();

  const eligibleProvidersList = Environment.get(
    "ELIGIBLE_DELIVERY_PROVIDERS",
    "roadie,doordash"
  ).split(",");

  const showCreateDSReq =
    !!CreateDSEntitledShops?.length && !!createDSEligible && !isCustomerService;

  useEffect(() => {
    setLoading && setLoading(true);
    setDSPageLoading(true);
    if (CreateDSEntitledShops?.length) {
      request("get-shop-details", { memberCodes: CreateDSEntitledShops }).then(
        (res) => {
          if (
            res.filter(
              (each) =>
                (eligibleProvidersList.includes(
                  each.preferences?.find(
                    (preference) => preference.name === "delivery_provider"
                  )?.value[0] || defaultDeliveryProvider
                ) &&
                  each.preferences?.find(
                    (preference) => preference.name === "delivery_service"
                  )?.value[0] === "true") ||
                ""
            )?.length
          ) {
            setCreateDSEligible(true);
          }
        }
      );
    }
    return () => {
      resetBasicInfo();
      setLoading && setLoading(true);
      setDSPageLoading(true);
      setParams({ referenceNumber: undefined, search: undefined });
    };
  }, []);

  useEffect(() => {
    setShowCreateDSRequest(showCreateDSReq);
  }, [showCreateDSReq]);

  useEffect(() => {
    if (
      referenceNumber &&
      DSRequests?.length &&
      DSRequests?.filter(
        (dsRequest) => dsRequest.referenceId === referenceNumber
      ).length === 1 &&
      DSRequests?.find((dsRequest) => dsRequest.referenceId === referenceNumber)
        ?.shipmentId
    ) {
      setShopCode(params?.shopCode);
      setRecordId(
        DSRequests?.find(
          (dsRequest) => dsRequest.referenceId === referenceNumber
        )?.shipmentId
      );
      setParams({ referenceNumber: "", search: {} });
    }
  }, [DSRequests]);

  return (
    <ListingScreen
      listingType="deliveryTasks"
      title={"Delivery Service"}
      showaction={
        !!CreateDSEntitledShops?.length &&
        !!createDSEligible &&
        !isCustomerService
      }
      actionName={"Create Delivery Service Request"}
      route={"create-delivery-service-request"}
      List={List}
      ListingHeader={ListingHeader}
      statusList={ListOfDeliveryTaskStates}
      Details={DeliveryTaskDetails}
      listingInfo={deliveryTasksList}
      userActions={{
        ...userActions,
        setLoading: (val) => {
          setLoading(val);
          setDSPageLoading(val);
        },
      }}
      params={
        (referenceNumber &&
          DSRequests?.length &&
          DSRequests?.filter(
            (dsRequest) => dsRequest.referenceId === referenceNumber
          ).length) ||
        applyParamFilters
          ? params
          : {}
      }
      initConfig={UIConfig}
      initData={InitDSRequestsData}
      deliverySourceList={ListOfDeliveryServiceSources}
      initViewMoreCounts={InitViewMoreCounts}
      pollInterval={DSFetchInterval}
      columnsList={isMobile ? ListOfMobileColumns : ListOfColumns}
      subColumnsList={ListOfSubColumns}
      actionsList={ListOfActions}
      subActionsList={ListOfSubActions}
      sortOptions={ListOfSortOptions}
      filterOptions={ListOfFilters}
      loading={loading || DSPageLoading}
      placeholder={"Search Requests"}
      enableFilters={true}
      enableSort={true}
      enableShopSelection={false}
      showDivider={false}
      showLoading={false}
      tabHeaderExists={true}
      hasStickyHeader={true}
      showDateType={false}
      customFiltersWidth={
        CreateDSEntitledShops?.length &&
        !!createDSEligible &&
        !isCustomerService
          ? 350
          : 150
      }
      range={31}
      showTitle={false}
    />
  );
};

const mapStateToProps = (state = {}) => {
  return {
    deliveryTasksList: state.mhq.views.home.drawer.deliveryTasks,
    dashboardShopCode: state.mhq.ongoing.global.shopCode,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRecordId,
      setRecordAction,
      setActions,
      fetchData,
      resetBasicInfo,
      setShopCode,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryTasks);
