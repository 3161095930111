import React from "react";
import { View } from "react-native";
import { Text, Divider } from "react-native-elements";
import { generateContent } from "../helper";
import { fonts } from "styles/theme";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";

const BuyerInfo = ({ buyerInfo, doNotFill }) => {
  const { messages, Localise } = React.useContext(I18NContext);

  const { name = "", phone = "", email = "", createdOn } = buyerInfo;

  const data = {
    "Order Date": createdOn?.split("T")[0],
    Buyer: name,
    "Buyer Phone": phone,
    "Buyer Email": email,
  };

  return (
    <View
      style={{
        ...tw("p-2"),
        // opacity: doNotFill ? 0.4 : 1
      }}
      pointerEvents={doNotFill ? "none" : "auto"}
    >
      <View>
        <Text style={fonts.heading3}>{Localise(messages, "Buyer Info")}</Text>
      </View>
      <View style={tw("m-1")}>{generateContent(data)}</View>
      <Divider />
    </View>
  );
};

export default BuyerInfo;
