import React from "react";
import { View, TouchableOpacity } from "react-native";
import { Text } from "react-native-elements";
import { useFormikContext } from "formik";
import toLower from "lodash/toLower";
import get from "lodash/get";
import { theme } from "styles/theme";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";

export const FormFieldRadioButtons = ({
  label,
  options,
  name,
  path,
  defaultValue = "",
  containerStyle,
  labelCustomStyle = {},
  labelOptionsDirection = "col",
  optionsDirection = "row",
}) => {
  const { messages, Localise } = React.useContext(I18NContext);

  const { errors, touched, setFieldValue, values } = useFormikContext();
  const key = (path && `${path}.${name}`) || name;
  const touchedVal = get(touched, key);
  const fieldValue = get(values, key) || defaultValue;
  const errorMsg = touchedVal && get(errors, key);

  return (
    <View
      style={[
        tw(`flex flex-${labelOptionsDirection}`),
        theme.Radio.containerStyle,
      ]}
    >
      {label && (
        <Text style={{ ...theme.Radio.labelStyle, ...labelCustomStyle }}>
          {Localise(messages, label)}
        </Text>
      )}
      <View
        style={[
          tw(
            `flex flex-${optionsDirection} flex-wrap justify-start items-${
              optionsDirection === "col" ? "start" : "center"
            }`
          ),
          containerStyle,
        ]}
      >
        {options.map((item, index) => {
          const id = `${name}_${index}`;
          return (
            <TouchableOpacity
              testID={id}
              accessibilityLabel={id}
              key={index}
              style={tw(
                `flex flex-row justify-between items-end mr-${
                  optionsDirection === "col" ? 0 : 10
                }`
              )}
              onPress={() => setFieldValue(key, item.label)}
            >
              <View style={theme.Radio.inputStyle}>
                {toLower(fieldValue) === toLower(item.label) && (
                  <View style={theme.Radio.selectedStyle} />
                )}
              </View>
              <Text style={{ marginLeft: 6 }}>
                {Localise(messages, item.value)}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
      <Text style={theme.Picker.errorStyle}>{errorMsg}</Text>
    </View>
  );
};

export default FormFieldRadioButtons;
