import React, { useEffect } from "react";
import { View, Text } from "react-native";
import toLower from "lodash/toLower";
import { useDispatch, useSelector } from "react-redux";

import {
  selectSearchZipcodeText,
  selectSearchCityText,
} from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/selector";
import {
  setSearchZipcode,
  setSearchCity,
} from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/slice";
import I18NContext from "library/contexts/i18N";

import Search from "components/containers/search";

import { fonts } from "styles/theme";
import tw from "tailwind-rn";

const SearchFilter = ({ data, isCitySearch, label }) => {
  const dispatch = useDispatch();
  const searchSelector = isCitySearch
    ? selectSearchCityText
    : selectSearchZipcodeText;
  const searchAction = isCitySearch ? setSearchCity : setSearchZipcode;
  const searchValue = useSelector(searchSelector);
  const { messages, Localise } = React.useContext(I18NContext);

  useEffect(() => {
    return () =>
      dispatch(
        setSearchZipcode({
          type: "search",
          value: "",
        })
      );
  }, []);

  return (
    <View style={{ flexDirection: "column" }}>
      <View style={tw("flex flex-row flex-wrap pb-3 mt-3 items-center")}>
        <Search
          type={"fullWidth"}
          selectors={{ selectValue: searchSelector }}
          actions={{ setAction: searchAction }}
          placeholder={
            isCitySearch
              ? Localise(messages, "Search Cities")
              : Localise(messages, "Search Zip Codes")
          }
          containerStyle={
            isCitySearch
              ? {
                  minWidth: 250,
                  paddingLeft: 0,
                  flex: 1,
                }
              : {
                  paddingLeft: 0,
                  paddingRight: 15,
                }
          }
        />

        {label && (
          <Text style={[fonts.h4, { width: 170 }, tw("italic pt-2")]}>
            {Localise(messages, label)}
          </Text>
        )}
      </View>
      <View style={[tw("flex-row items-center pb-3"), { width: 200 }]}>
        <Text style={fonts.heading4}>
          {
            data.filter(
              ({ zipCode, status, city }) =>
                (!isCitySearch || status) &&
                toLower(isCitySearch ? city : zipCode).startsWith(
                  toLower(searchValue)
                )
            ).length
          }{" "}
          {isCitySearch
            ? Localise(messages, `Cities`)
            : Localise(messages, `Zip Code(s)`)}{" "}
          {searchValue ? Localise(messages, " found") : ""}
        </Text>
      </View>
    </View>
  );
};

export default SearchFilter;
