import React from "react";
import { Text, Image } from "react-native-elements";
import { View, TouchableOpacity } from "react-native";
import tw from "tailwind-rn";
import { backgroundColors, colors } from "styles/theme";
import toLower from "lodash/toLower";
import startCase from "lodash/startCase";
import lowerCase from "lodash/lowerCase";
import get from "lodash/get";
import { IMAGES, DIMENSIONS } from "static/assets/images";
import I18NContext from "library/contexts/i18N";

import styles from "./styles";

const ListItem = React.memo(
  ({
    item,
    itemKey,
    columnsList,
    subColumnsList = [],
    setRecordId,
    setShopCode,
    isSelectedRecord,
    isSmallScreen,
    isMobile,
    index,
  }) => {
    const { messages, Localise } = React.useContext(I18NContext);
    const { imageWidth = 20, imageHeight = 20 } =
      DIMENSIONS["delivery-service"] || {};

    const {
      state,
      statusColor,
      referenceId,
      displayStatus,
      imageName,
      shipmentId,
      siteId,
      pickupTime,
    } = item;

    return (
      <TouchableOpacity
        key={index}
        activeOpacity={0.6}
        onPress={() => {
          setShopCode(siteId);
          setRecordId(shipmentId);
        }}
        style={{
          minWidth: 300,
        }}
        testID={`${referenceId}_${state}`}
        accessibilityLabel={`${referenceId}_${state}`}
      >
        <View
          style={[
            styles.recordContainer,
            isSelectedRecord && styles.activeRecordContainer,
            !isMobile && { flex: 1 },
          ]}
          fsClass="fs-unmask"
        >
          <View
            style={[
              styles.record,
              {
                borderColor: statusColor,
                alignItems: "center",
              },
            ]}
            fsClass="fs-unmask"
          >
            <View
              style={[
                tw("justify-between items-center"),
                styles.recordItemView,
                styles.status,
              ]}
            >
              <View
                style={tw(
                  `flex flex-row items-center w-full flex-wrap ${
                    isSmallScreen ? "justify-between" : "justify-start"
                  }`
                )}
              >
                <View style={styles.statusText}>
                  <Text
                    numberOfLines={2}
                    style={{
                      ...styles.recordItem,
                      textAlign: "left",
                      fontSize: 13,
                      fontWeight: "600",
                      width: isMobile ? "auto" : 85,
                      color:
                        displayStatus === "Cancelled"
                          ? colors.dark
                          : statusColor,
                    }}
                  >
                    {Localise(
                      messages,
                      startCase(
                        lowerCase(
                          displayStatus.replace("Cancelled", "Canceled")
                        )
                      )
                    )}
                  </Text>
                  {["Pickup"].includes(displayStatus) && (
                    <Text
                      numberOfLines={2}
                      style={{
                        ...styles.recordItem,
                        textAlign: "left",
                        fontSize: 13,
                        fontWeight: "600",
                        width: isMobile ? "auto" : 85,
                        color: statusColor,
                        textTransform: "none",
                      }}
                    >
                      {pickupTime}
                    </Text>
                  )}
                </View>
                <View style={styles.statusIcon}>
                  <Image
                    style={{
                      width: imageWidth,
                      height: imageHeight,
                    }}
                    containerStyle={{
                      marginHorizontal: isSmallScreen ? 5 : 10,
                      marginVertical: 5,
                    }}
                    source={IMAGES[imageName]}
                  />
                </View>
              </View>
            </View>
            {columnsList.map((column) => {
              return (
                <React.Fragment key={column.key}>
                  <View style={[styles.recordItemView, styles[column.key]]}>
                    <Text
                      numberOfLines={1}
                      style={{
                        ...styles.recordItem,
                        textTransform:
                          isMobile && column.key === "referenceId"
                            ? "none"
                            : "capitalize",
                        textAlign:
                          isMobile && column.key === "displayPrice"
                            ? "right"
                            : "left",
                        ...(isSelectedRecord && {
                          color: backgroundColors.primary,
                        }),
                        fontWeight: isSelectedRecord ? "600" : "500",
                      }}
                      title={item[column]}
                      fsClass={
                        toLower(column) === "phone" ? "fs-exclude" : "fs-unmask"
                      }
                    >
                      {Localise(messages, get(item, `${column.path}`))}
                    </Text>
                    <Text
                      numberOfLines={1}
                      style={{
                        ...styles.subRecordItem,
                        ...(isSelectedRecord && {
                          color: backgroundColors.primary,
                        }),
                        fontWeight: isSelectedRecord ? "600" : "500",
                      }}
                    >
                      {Localise(
                        messages,
                        get(item, `${subColumnsList[`${column.key}`]}`)
                      )}
                    </Text>
                  </View>
                </React.Fragment>
              );
            })}
          </View>
        </View>
      </TouchableOpacity>
    );
  }
);

export default ListItem;
