/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-useless-escape */
import React, { useCallback } from "react";
import { View, Platform, Linking } from "react-native";
import { GiftedChat } from "react-native-gifted-chat";
import ChatMessage from "./chat-message";
import { fonts, fontWeights } from "styles/theme";
import { DeviceContext } from "library/contexts/appSettings";

const Chat = ({
  containerStyle,
  messages,
  userId = "",
  userName = "",
  customMessageText,
  onSendCallback,
  placeholder = "Ask or answer a question",
  renderAvatar,
  renderTime,
  renderSend,
  renderInputToolbar,
  hideSendInput,
}) => {
  const onSend = useCallback((chatMessages = []) => {
    if (!!onSendCallback) onSendCallback(chatMessages);
  }, []);
  const { isDesktop } = React.useContext(DeviceContext);
  const renderMessage = (props) => {
    return <ChatMessage {...props} />;
  };

  return (
    <View style={containerStyle}>
      <GiftedChat
        parsePatterns={() => [
          {
            pattern:
              /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
            style: fonts.link1,
            onPress: (link) =>
              Platform.OS === "web"
                ? window.open(link, "_blank")
                : Linking.openURL(link),
          },
          {
            pattern: /#[^\s]+/g,
            style: { fontWeight: fontWeights.semiBold },
          },
        ]}
        alwaysShowSend={true}
        // maxComposerHeight={44} //same as minComposerHeight default value
        messages={messages}
        onSend={(chatMessages) => onSend(chatMessages)}
        placeholder={placeholder}
        textInputStyle={{
          fontSize: 12,
          fontFamily: fonts.fontFamily.default,
        }}
        renderAvatar={renderAvatar}
        renderMessageText={customMessageText}
        renderMessage={(props) => renderMessage(props)}
        renderSend={!hideSendInput ? renderSend : () => {}}
        renderTime={renderTime}
        renderInputToolbar={!hideSendInput ? renderInputToolbar : () => {}}
        user={{
          _id: userId,
          name: userName,
        }}
        showUserAvatar={true}
        showAvatarForEveryMessage={true}
        {...(hideSendInput && {
          minComposerHeight: 0,
          maxComposerHeight: 0,
          minInputToolbarHeight: 0,
        })}
        inverted={false}
        messagesContainerStyle={{ padding: isDesktop ? 5 : 10 }}
      />
    </View>
  );
};

export default Chat;
