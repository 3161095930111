import React, { useEffect } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import { colors } from "styles/theme";
import UserAuthUtils from "library/utils/userAuth";
import tw from "tailwind-rn";

const Expired = ({ redirect, hasLinkExpired, isNonceValid }) => {
  const redirectionTime = 3000;

  useEffect(() => {
    hasLinkExpired &&
      setTimeout(() => {
        UserAuthUtils.logOut();
        redirect & redirect();
      }, redirectionTime);
  }, []);

  return (
    <View>
      {isNonceValid === "error" ? (
        <Text style={tw("mb-4")}>Something went wrong, please try again..</Text>
      ) : (
        <React.Fragment>
          <Text style={tw("mb-4")}>
            The email link you are accessing is expired.{" "}
            {hasLinkExpired &&
              `Redirecting you to login
        page, please try forgot password option.`}
          </Text>
          {hasLinkExpired && (
            <ActivityIndicator color={colors.activityIndicator} />
          )}
        </React.Fragment>
      )}
    </View>
  );
};

export default Expired;
