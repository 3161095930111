import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";
import { SLICE_NAME as parentSlice } from "../constants";
import uniq from "lodash/uniq";

export const initialState = {
  catalogs: [],
  catalogLookup: {},
  current: {},
  memberMappings: [],
  filteredMemberMappings: [],
  actions: {
    selectAll: false,
    searchText: "",
    filters: [],
    initialSelectedMembers: [],
    selectedMembers: [],
  },
  status: "progress",
  error: false,
};

const slice = createSlice({
  name: `view::${parentSlice}::${sliceName}`,
  initialState,
  reducers: {
    setCatalogs: (state, action) => {
      state.catalogs = action.payload || [];
      state.catalogs.forEach((c) => {
        state.catalogLookup[c.groupId] = c.name;
      });
    },
    setMemberMappings: (state, action) => {
      state.memberMappings = action.payload;

      if (state.current.groupId) {
        state.actions.selectedMembers = uniq(
          state.memberMappings
            .filter((m) => m.allowedCatalogs.includes(state.current.groupId))
            .map((m) => m.memberCode)
        );
        state.actions.initialSelectedMembers = [
          ...state.actions.selectedMembers,
        ];
      }

      state.status = "done";
    },
    setFilteredMemberMappings: (state, action) => {
      state.filteredMemberMappings = action.payload;
    },
    setAction: (state, action) => {
      const { type, value } = action.payload;
      if (type === "search") state.actions.searchText = value;
      else {
        state.actions.filters = [...new Set([].concat(...value))];
      }
    },
    setSelection: (state, action) => {
      const selection = action.payload;
      if (selection === "all") {
        state.actions.selectedMembers = uniq([
          ...state.actions.selectedMembers,
          ...state.filteredMemberMappings.map((m) => m.memberCode),
        ]);
      } else if (selection !== "") {
        if (state.actions.selectedMembers.includes(selection)) {
          const index = state.actions.selectedMembers.findIndex(
            (m) => m === selection
          );
          state.actions.selectedMembers.splice(index, 1);
        } else {
          state.actions.selectedMembers = [
            ...state.actions.selectedMembers,
            selection,
          ];
        }
      } else {
        state.actions.selectedMembers = [];
      }
    },
    setResetSelectedMembers: (state) => {
      state.actions.selectedMembers = [...state.actions.initialSelectedMembers];
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setCurrentCatalog: (state, action) => {
      state.current = action.payload;
      state.actions = { ...initialState.actions };

      if (state.memberMappings.length) {
        state.actions.selectedMembers = uniq(
          state.memberMappings
            .filter((m) => m.allowedCatalogs.includes(action.payload.groupId))
            .map((m) => m.memberCode)
        );
        state.actions.initialSelectedMembers = [
          ...state.actions.selectedMembers,
        ];
      }
    },
    fetchSharedCatalogs: () => {},
    fetchCatalogMemberMappings: () => {},
    saveCatalogMemberMappings: () => {},
  },
});

export const {
  setCatalogs,
  setAction,
  setSelection,
  setError,
  setCurrentCatalog,
  setMemberMappings,
  setResetSelectedMembers,
  setFilteredMemberMappings,
  fetchSharedCatalogs,
  fetchCatalogMemberMappings,
  saveCatalogMemberMappings,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
