import toLower from "lodash/toLower";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import sortBy from "lodash/sortBy";

export const refineStaff = (
  staffList = [],
  { filters = [], shops = [], search = {}, sort = {} }
) => {
  const StaffDisplayNameToKeyMap = {
    "Name: Ascending": "name",
    "Name: Descending": "name",
    "Role: Ascending": "role",
    "Role: Descending": "role",
  };

  let sortField = "Name: Ascending";
  const filterCategoryMap = {};

  if (filters.length) {
    filters.map((e) => {
      const [type, { value }] = Object.entries(e).pop();
      filterCategoryMap[type] = filterCategoryMap[type]
        ? filterCategoryMap[type].concat(":" + value)
        : value;
    });
  }

  staffList =
    staffList &&
    staffList.filter((employee) => {
      let matchesFilter = true;

      // Filtering the list based on the search query
      if (!isEmpty(search) && matchesFilter) {
        // search in staffId, title, recipient name, city and status fields
        const val = toLower(search.value);
        matchesFilter =
          toLower(employee.name).includes(val) ||
          toLower(employee.role).includes(val) ||
          toLower(employee.phone).includes(val) ||
          toLower(employee.emailId).includes(val)
            ? true
            : false;
      }

      // commenting below code as we are getting users for given member codes from API itself.
      // Filtering list by shop codes
      // if (!isEmpty(shops) && matchesFilter) {
      //   const results = shops.filter((shop) => {
      //     const memberCode = shop["Shop Codes"].value;
      //     if (employee.memberCodes.includes(memberCode)) return true;
      //   });
      //   matchesFilter = results.length ? true : false;
      // }

      // Filtering list by filters
      if (filters.length && matchesFilter) {
        matchesFilter = Object.keys(filterCategoryMap).reduce(
          (matches, category) => {
            const value = filterCategoryMap[category];

            // filter list with role
            if (matches && category === "Role") {
              const multiValues = value.split(":");

              const results = multiValues.filter((value) => {
                return employee.role.includes(value);
              });

              matches = results.length ? true : false;
            }

            // filter users with status
            if (matches && category === "Status") {
              const multiValues = value.split(":");

              const results = multiValues.filter((value) => {
                return employee.employmentStatus === value;
              });

              matches = results.length ? true : false;
            }

            return matches;
          },
          true
        );
      }
      if (matchesFilter) return employee;
    });

  // sort matches all as it just sorts
  if (!isEmpty(sort)) {
    sortField = sort.value;
  }

  staffList = sortBy(staffList, (record) =>
    toLower(record[StaffDisplayNameToKeyMap[sortField]])
  );

  if (sortField === "Name: Descending" || sortField === "Role: Descending")
    staffList = staffList.reverse();

  return staffList;
};

export const processResponse = (record) => {
  return {
    name: `${get(record, "firstName", "")} ${get(record, "lastName", "")}`,
    role: get(record, "authGroups.0.roles", "").join(", "),
    memberCodes: get(record, "authGroups.0.memberCodes", []),
    phone: get(record, "phone", ""),
    emailId: get(record, "emailId", ""),
    status: get(record, "status", "ACTIVE"),
    employmentStatus: get(record, "employmentStatus", "Full Time"),
    id: get(record, "id", ""),
    authGroups: get(record, "authGroups", ""),
    firstName: get(record, "firstName", ""),
    lastName: get(record, "lastName", ""),
    partnerGroup: get(record, "partnerGroup", ""),
    profileNotes: get(record, "profileNotes", ""),
    memberCode: get(record, "memberCode", ""),
    primaryMemberCodeWithEmailId: `${get(record, "memberCode", "")}:${get(
      record,
      "emailId",
      ""
    )}`,
    isAccountLocked: get(record, "isAccountLocked", false),
  };
};

export const processStaffListResponse = (response) => {
  return response.map((record) => processResponse(record));
};
