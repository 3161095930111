import { takeLatest } from "redux-saga/effects";

import {
  fetchDeliveryCutoffTimes,
  saveDeliveryCutoffTimes,
  fetchCutoffExceptions,
  deleteCutoffExceptions,
  saveCutoffExceptions,
} from "./slice";

import {
  handleFetchDeliveryCutoffTimes,
  handleSaveDeliveryCutoffTimes,
} from "../delivery-cutoff-times/cutoff-times";
import {
  handleFetchCutoffExceptions,
  handleSaveCutoffExceptions,
  handleDeleteCutoffExceptions,
} from "../delivery-cutoff-times/cutoff-exceptions";

export function* watchSaga() {
  yield takeLatest(
    fetchDeliveryCutoffTimes.type,
    handleFetchDeliveryCutoffTimes
  );
  yield takeLatest(saveDeliveryCutoffTimes.type, handleSaveDeliveryCutoffTimes);
  yield takeLatest(fetchCutoffExceptions.type, handleFetchCutoffExceptions);
  yield takeLatest(saveCutoffExceptions.type, handleSaveCutoffExceptions);
  yield takeLatest(deleteCutoffExceptions.type, handleDeleteCutoffExceptions);
}

export default watchSaga;
