import React, { Component } from "react";
import { View, TextInput, StyleSheet } from "react-native";
import IMAGES from "static/assets/images";
import { Image } from "react-native-elements";
import { fonts } from "styles/theme";

export default class NumericCounter extends Component {
  static defaultProps = {
    borderColor: "#d4d4d4",
    textColor: "black",
    containerStyle: {},
    inputStyle: {},
    initValue: null,
    valueType: "integer",
    value: null,
    minValue: null,
    maxValue: null,
    step: 1,
    editable: true,
    validateOnBlur: true,
    onLimitReached: (isMax, msg) => {},
    isMobile: false,
    isDesktop: false,
  };

  constructor(props) {
    super(props);
    const noInitSent = props.initValue !== 0 && !props.initValue;
    this.state = {
      value: noInitSent ? (props.value ? props.value : 0) : props.initValue,
      lastValid: noInitSent ? (props.value ? props.value : 0) : props.initValue,
      stringValue: (noInitSent
        ? props.value
          ? props.value
          : 0
        : props.initValue
      ).toString(),
    };
    this.ref = null;
  }

  // this.props refers to the new props
  componentDidUpdate() {
    const initSent = !(this.props.initValue !== 0 && !this.props.initValue);

    // compare the new value (props.initValue) with the existing/old one (this.state.value)
    if (this.props.initValue !== this.state.value && initSent) {
      this.setState({
        value: this.props.initValue,
        lastValid: this.props.initValue,
        stringValue: this.props.initValue.toString(),
      });
    }
  }

  inc = () => {
    let value =
      this.props.value && typeof this.props.value === "number"
        ? this.props.value
        : this.state.value;
    if (
      this.props.maxValue === null ||
      value + this.props.step < this.props.maxValue
    ) {
      value = (value + this.props.step).toFixed(12);
      value =
        this.props.valueType === "real" ? parseFloat(value) : parseInt(value);
      this.setState({ value, stringValue: value.toString() });
    } else if (this.props.maxValue !== null) {
      this.props.onLimitReached(true, "Reached Maximum Value!");
      value = this.props.maxValue;
      this.setState({ value, stringValue: value.toString() });
    }
    if (value !== this.props.value)
      this.props.onChange && this.props.onChange(Number(value));
  };
  dec = () => {
    let value =
      this.props.value && typeof this.props.value === "number"
        ? this.props.value
        : this.state.value;
    if (
      this.props.minValue === null ||
      value - this.props.step > this.props.minValue
    ) {
      value = (value - this.props.step).toFixed(12);
      value =
        this.props.valueType === "real" ? parseFloat(value) : parseInt(value);
    } else if (this.props.minValue !== null) {
      this.props.onLimitReached(false, "Reached Minimum Value!");
      value = this.props.minValue;
    }
    if (value !== this.props.value)
      this.props.onChange && this.props.onChange(Number(value));
    this.setState({ value, stringValue: value.toString() });
  };
  isLegalValue = (value, mReal, mInt) =>
    value === "" ||
    (((this.props.valueType === "real" && mReal(value)) ||
      (this.props.valueType !== "real" && mInt(value))) &&
      (this.props.maxValue === null ||
        parseFloat(value) <= this.props.maxValue) &&
      (this.props.minValue === null ||
        parseFloat(value) >= this.props.minValue));

  realMatch = (value) =>
    value &&
    value.match(/-?\d+(\.(\d+)?)?/) &&
    value.match(/-?\d+(\.(\d+)?)?/)[0] ===
      value.match(/-?\d+(\.(\d+)?)?/).input;

  intMatch = (value) =>
    value &&
    value.match(/-?\d+/) &&
    value.match(/-?\d+/)[0] === value.match(/-?\d+/).input;

  onChange = (value) => {
    let currValue =
      typeof this.props.value === "number"
        ? this.props.value
        : this.state.value;
    if (
      (value.length === 1 && value === "-") ||
      (value.length === 2 && value === "0-")
    ) {
      this.setState({ stringValue: "-" });
      return;
    }
    if (
      (value.length === 1 && value === ".") ||
      (value.length === 2 && value === "0.")
    ) {
      this.setState({ stringValue: "0." });
      return;
    }
    if (value.charAt(value.length - 1) === ".") {
      this.setState({ stringValue: value });
      return;
    }
    let legal = this.isLegalValue(value, this.realMatch, this.intMatch);
    if (legal) {
      this.setState({ lastValid: value });
    }
    if (!legal && !this.props.validateOnBlur) {
      if (this.ref) {
        this.ref.blur();
        setTimeout(() => {
          this.ref.clear();
          setTimeout(() => {
            this.props.onChange && this.props.onChange(currValue - 1);
            this.setState({ value: currValue - 1 }, () => {
              this.setState({ value: currValue, legal });
              this.props.onChange && this.props.onChange(currValue);
            });
          }, 10);
        }, 15);
        setTimeout(() => this.ref.focus(), 20);
      }
    } else if (!legal && this.props.validateOnBlur) {
      this.setState({ stringValue: value });
      let parsedValue =
        this.props.valueType === "real" ? parseFloat(value) : parseInt(value);
      parsedValue = isNaN(parsedValue) ? 0 : parsedValue;
      if (parsedValue !== this.props.value)
        this.props.onChange && this.props.onChange(parsedValue);
      this.setState({
        value: parsedValue,
        legal,
        stringValue: parsedValue.toString(),
      });
    } else {
      this.setState({ stringValue: value });
      let parsedValue =
        this.props.valueType === "real" ? parseFloat(value) : parseInt(value);
      parsedValue = isNaN(parsedValue) ? 0 : parsedValue;
      if (parsedValue !== this.props.value)
        this.props.onChange && this.props.onChange(parsedValue);
      this.setState({
        value: parsedValue,
        legal,
        stringValue: parsedValue.toString(),
      });
    }
  };
  onBlur = () => {
    let match = this.state.stringValue.match(/-?[0-9]\d*(\.\d+)?/);
    let legal =
      match &&
      match[0] === match.input &&
      (this.props.maxValue === null ||
        parseFloat(this.state.stringValue) <= this.props.maxValue) &&
      (this.props.minValue === null ||
        parseFloat(this.state.stringValue) >= this.props.minValue);
    if (!legal) {
      if (
        this.props.minValue !== null &&
        parseFloat(this.state.stringValue) <= this.props.minValue
      ) {
        this.props.onLimitReached(true, "Reached Minimum Value!");
      }
      if (
        this.props.maxValue !== null &&
        parseFloat(this.state.stringValue) >= this.props.maxValue
      ) {
        this.props.onLimitReached(false, "Reached Maximum Value!");
      }
      if (this.ref) {
        this.ref.blur();
        setTimeout(() => {
          this.ref.clear();
          setTimeout(() => {
            this.props.onChange && this.props.onChange(this.state.lastValid);
            this.setState({ value: this.state.lastValid }, () => {
              this.setState({
                value: this.state.lastValid,
                stringValue: this.state.lastValid.toString(),
              });
              this.props.onChange && this.props.onChange(this.state.lastValid);
            });
          }, 10);
        }, 15);
        setTimeout(() => this.ref.focus(), 50);
      }
    }
    this.props.onBlur && this.props.onBlur();
  };

  onFocus = () => {
    this.setState({ lastValid: this.state.value });
    this.props.onFocus && this.props.onFocus();
  };

  render() {
    const editable = this.props.editable;
    const borderColor = this.props.borderColor;

    const inputContainerStyle = [
      style.inputContainerUpDown,
      {
        width: 55,
        height: 35,
        borderColor: borderColor,
        paddingRight: 2,
      },
      this.props.containerStyle,
    ];
    const inputStyle = [
      style.inputUpDown,
      {
        width: 35,
        height: 35,
        outline: "none",
      },
      fonts.default,
      this.props.inputStyle,
    ];
    const upDownStyle = [
      {
        alignItems: "center",
      },
    ];

    return (
      <View style={inputContainerStyle}>
        <TextInput
          editable={editable}
          returnKeyType="done"
          underlineColorAndroid="rgba(0,0,0,0)"
          keyboardType="numeric"
          value={this.state.stringValue}
          onChangeText={this.onChange}
          style={inputStyle}
          ref={(ref) => (this.ref = ref)}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
        />
        <View style={upDownStyle}>
          <Image
            onPress={this.inc}
            style={{
              width: 15,
              height: 15,
            }}
            source={IMAGES["expanded-section"]}
          />
          <Image
            onPress={this.dec}
            style={{
              width: 15,
              height: 15,
            }}
            source={IMAGES["collapsed-section"]}
          />
        </View>
      </View>
    );
  }
}

const style = StyleSheet.create({
  seprator: {
    backgroundColor: "grey",
    height: 80,
  },
  inputContainerUpDown: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 50,
    width: 35,
    borderColor: "grey",
    borderWidth: 1,
  },
  inputContainerPlusMinus: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
  },
  inputUpDown: {
    textAlign: "center",
    padding: 0,
  },
  inputPlusMinus: {
    textAlign: "center",
    padding: 0,
  },
  icon: {
    fontWeight: "900",
    backgroundColor: "rgba(0,0,0,0)",
  },
  upDown: {
    alignItems: "center",
    paddingRight: 15,
  },
});
