import React, { useEffect, useContext } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  Platform,
  ActivityIndicator,
} from "react-native";
import tw from "tailwind-rn";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "styles/theme";

import useStateIfMounted from "library/utils/useStateIfMounted";
import { CheckBox, Button } from "react-native-elements";
import IMAGES from "static/assets/images";
import I18NContext from "library/contexts/i18N";
import { PrintIframeAction, PrintIframeRenderer } from "components/elements";
import {
  qrScanActions,
  scanActionsMap,
} from "components/views/drawer/order-details/printHelper";
import { generateQRCode } from "library/sagas/ongoing/order-details/slice";
import { FormFieldSwitch } from "components/elements/forms";
import isEmpty from "lodash/isEmpty";
import { colors } from "styles/theme";
import { Divider as RNDivider } from "react-native-elements";
import { reArrangeArray } from "./helper";
import { fonts } from "styles/theme";
import { selectSideCar } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { Entitlements } from "library/utils/entitlements";
import AppSettingsContext from "library/contexts/appSettings";
import { selectShopDetails } from "library/sagas/views/home/drawer/shop-settings/common/selector";

const QRCodeShopActions = ({ shopSettings, saveSettings, loadingKey }) => {
  const dispatch = useDispatch();
  const [selectedActions, setSelectedActions] = useStateIfMounted([]);
  const { messages, Localise } = useContext(I18NContext);
  const sideCar = useSelector(selectSideCar);

  const [printPreferenceSettings, setPrintPreferenceSettings] =
    useStateIfMounted({});
  const { permissions } = React.useContext(AppSettingsContext);
  const shopDetails = useSelector(selectShopDetails);
  const hasAccessToQR =
    Entitlements.QR_SCAN in permissions[shopDetails?.memberCode];
  const qrCodeFields = {
    qr_code_global_enabled: "Include QR code on printed materials?",
    qr_code_invoice_enabled: "Invoice",
    qr_code_order_details_enabled: "Order Details",
    qr_code_worksheet_enabled: "Worksheet",
    qr_code_trifold_enabled: "Tri-Fold",
  };

  let printSettings = {
    qr_code_global_enabled: "",
    qr_code_trifold_enabled: "",
    qr_code_invoice_enabled: "",
    qr_code_worksheet_enabled: "",
    qr_code_order_details_enabled: "",
    house_account_statement_store_address_enabled: "",
  };

  useEffect(() => {
    if (isEmpty(shopSettings)) return;
    Object.entries(printSettings).map(([key]) => {
      printSettings[key] = shopSettings[key];
    });
    setPrintPreferenceSettings(printSettings);
  }, [shopSettings]);

  const scanActions = ["Select All", ...Object.keys(scanActionsMap)];

  let isGlobalQRCodeEnabled =
    printPreferenceSettings["qr_code_global_enabled"] === "true";

  const handleSelection = (title) => {
    const index = selectedActions.indexOf(title);

    if (index === -1) {
      title === "Select All"
        ? setSelectedActions([...scanActions])
        : setSelectedActions([...selectedActions, title]);
    } else {
      const data = [...selectedActions];
      data.splice(index, 1);
      if (data.includes("Select All")) {
        data.shift();
      }
      title === "Select All"
        ? setSelectedActions([])
        : setSelectedActions([...data]);
    }
  };

  return (
    <React.Fragment>
      {Platform.OS !== "web" && <View style={tw("my-2 flex-1")} />}

      {hasAccessToQR ? (
        <>
          <View style={tw("flex w-full flex-row")}>
            <View style={{ ...tw("flex flex-col"), width: "40%" }}>
              <View>
                <Text style={[fonts.heading3, tw("mb-2")]}>
                  {Localise(messages, "QR Codes on Printed Materials")}
                </Text>
              </View>

              <View
                style={[
                  tw("flex-1 items-start pb-2 flex-wrap"),
                  { height: 200 },
                ]}
                testID="shop_action_list"
              >
                {Object.entries(qrCodeFields).map(([key, value]) => {
                  return (
                    <View
                      style={[tw("justify-start pb-4")]}
                      key={key}
                      pointerEvents={
                        key != "qr_code_global_enabled" &&
                        !isGlobalQRCodeEnabled
                          ? "none"
                          : "auto"
                      }
                    >
                      <View
                        style={[tw("flex-row justify-start")]}
                        pointerEvents={
                          key != "qr_code_global_enabled" &&
                          !isGlobalQRCodeEnabled
                            ? "none"
                            : "auto"
                        }
                      >
                        <FormFieldSwitch
                          value={printPreferenceSettings[key] === "true"}
                          accessibilityLabel={key}
                          onValueChange={(val) => {
                            let payload = {
                              preferences: [
                                {
                                  id: key,
                                  values: [val ? "true" : "false"],
                                },
                              ],
                            };
                            if (key === "qr_code_global_enabled") {
                              if (!val) {
                                isGlobalQRCodeEnabled = false;
                              }
                              let saveAllPayload = {
                                preferences: [
                                  {
                                    id: "qr_code_global_enabled",
                                    values: [val],
                                  },
                                  {
                                    id: "qr_code_invoice_enabled",
                                    values: [val],
                                  },
                                  {
                                    id: "qr_code_worksheet_enabled",
                                    values: [val],
                                  },
                                  {
                                    id: "qr_code_order_details_enabled",
                                    values: [val],
                                  },
                                  {
                                    id: "qr_code_trifold_enabled",
                                    values: [val],
                                  },
                                ],
                              };
                              saveSettings(saveAllPayload);
                            } else {
                              saveSettings(payload);
                            }
                          }}
                          testID={key}
                        />
                        <View
                          style={{
                            paddingLeft: 20,
                            maxWidth: isEmpty(sideCar) ? 300 : 200,
                            minWidth: 140,
                          }}
                        >
                          <Text>{Localise(messages, value)}</Text>
                        </View>
                        {loadingKey === key && (
                          <ActivityIndicator
                            color={colors.activityIndicator}
                            testID={"loader"}
                          />
                        )}
                      </View>
                      {key === "qr_code_global_enabled" && (
                        <View style={[tw("mt-2")]}>
                          <Text>
                            {Localise(
                              messages,
                              "Which printed materials should show the QR code?"
                            )}
                          </Text>
                        </View>
                      )}
                    </View>
                  );
                })}
              </View>
            </View>

            <View
              style={{ display: "flex", flexDirection: "column", width: "60%" }}
            >
              <View>
                <Text style={[fonts.heading3, tw("mb-2")]}>
                  {Localise(messages, "QR Codes Shop Actions")}
                </Text>
              </View>
              <Text style={[tw("mb-4")]}>
                {Localise(
                  messages,
                  "What QR Codes do you need to print on the sheet?"
                )}
              </Text>

              <View
                style={[
                  tw("flex-1 items-start pb-2 flex-wrap"),
                  { height: isEmpty(sideCar) ? 200 : 300 },
                ]}
              >
                {scanActions.map((title, id) => {
                  return (
                    <View style={[tw("flex-row justify-start pb-4")]} key={id}>
                      <CheckBox
                        key={title}
                        testID={title}
                        accessibilityLabel={title}
                        Component={TouchableOpacity}
                        checked={selectedActions.includes(title)}
                        checkedIcon={
                          <Image
                            source={IMAGES["checkbox-checked"]}
                            style={{ width: 20, height: 20 }}
                          />
                        }
                        uncheckedIcon={
                          <Image
                            source={IMAGES["checkbox-unchecked"]}
                            style={{ width: 20, height: 20 }}
                          />
                        }
                        containerStyle={[
                          theme.CheckBox.inputContainerStyle,
                          tw("mr-6"),
                        ]}
                        iconRight={false}
                        onPress={() => handleSelection(title)}
                        size={20}
                        textStyle={{
                          ...theme.CheckBox.textStyle,
                          minWidth: 100,
                        }}
                        title={Localise(messages, title)}
                      />
                    </View>
                  );
                })}
              </View>
              <View style={tw("flex-row justify-start pb-4")}>
                <Button
                  onPress={() => {
                    const payload = [];
                    const qrPayload = [];
                    // Removing select all from the final selected actions.
                    let finalActions = [...selectedActions];
                    if (finalActions.includes("Select All")) {
                      finalActions.shift();
                    }
                    const resultArray = reArrangeArray(finalActions);

                    // generating object with key and label pattern.
                    resultArray.forEach((each) => {
                      qrPayload.push({ id: scanActionsMap[each] });
                      payload.push({ id: scanActionsMap[each], label: each });
                    });
                    let unique = qrPayload.reduce((acc, curr) => {
                      if (!acc.find((item) => item.id === curr.id))
                        acc.push(curr);
                      return acc;
                    }, []);

                    if (payload.length > 0) {
                      dispatch(
                        generateQRCode({
                          params: { qrPayload: unique },
                          type: "BULK",
                          resolve: (resp) => {
                            const qrImagesData = resp;
                            PrintIframeAction(
                              qrScanActions(payload, qrImagesData),
                              () => {}
                            );
                          },
                          reject: () => {
                            PrintIframeAction(qrScanActions(payload), () => {});
                          },
                        })
                      );
                    }
                  }}
                  disabled={selectedActions.length < 1}
                  title={Localise(messages, "Print")}
                  containerStyle={{ marginLeft: 0 }}
                  testID="print"
                  accessibilityLabel="print"
                />
              </View>
              <View style={tw("flex-row justify-start pb-4")}></View>
            </View>
          </View>
          <RNDivider style={tw("my-6")} />
        </>
      ) : null}

      <View>
        <Text style={[fonts.heading3, tw("mb-2")]}>
          {Localise(messages, "House Account Statements")}
        </Text>
      </View>
      <View style={[tw("flex-1 items-start pb-2 flex-wrap"), { height: 200 }]}>
        <View style={[tw("flex-row justify-start")]}>
          <FormFieldSwitch
            value={
              printPreferenceSettings[
                "house_account_statement_store_address_enabled"
              ] === "true"
            }
            accessibilityLabel="house_account_statement_store_address"
            onValueChange={(val) => {
              let payload = {
                preferences: [
                  {
                    id: "house_account_statement_store_address_enabled",
                    values: [val ? "true" : "false"],
                  },
                ],
              };
              saveSettings(payload);
            }}
            testID="house_account_statement_store_address_enabled"
          />
          <View style={{ paddingLeft: 20, maxWidth: 300, minWidth: 140 }}>
            <Text>
              {Localise(messages, "Show Store Address on left side of header")}
            </Text>
          </View>
          {loadingKey === "house_account_statement_store_address_enabled" && (
            <ActivityIndicator
              color={colors.activityIndicator}
              testID={"loader"}
            />
          )}
        </View>
      </View>

      <PrintIframeRenderer testID={"qrcode-shop-actions-print"} />
    </React.Fragment>
  );
};

export default QRCodeShopActions;
