/* eslint-disable no-unused-vars */
import { request } from "library/utils/request";
import { ErrorMessages } from "./config";
import isEmpty from "lodash/isEmpty";
//import get from "lodash/get";
import toUpper from "lodash/toUpper";
import first from "lodash/first";
import sortBy from "lodash/sortBy";
//import uniq from "lodash/uniq";
import { ToasterHandler } from "components/elements";
import moment from "moment";

const getProfileByEmail = (emailId, apiMemberCode) => {
  return request("get-staff-profile", { emailId, memberCode: apiMemberCode })
    .then((res = {}) => res)
    .catch(() => {});
};

// commenting below code as we may need this when we work on PAR-1603 i.e. Florist Admin - ability to be associated to multiple groups
/*const searchUsersByEmail = (emailId) => {
  return request("search-users-by-email", { emailId })
    .then((res = {}) => res)
    .catch(() => {});
};

const getProfileByMemberCode = (apiMemberCode) => {
  return request("get-staff-list", {
    memberCode: apiMemberCode,
  })
    .then(
      (res = []) => res[0]
      // commenting below code as we are getting only Admin account for given member code currently
      // if account exists in EP User Profile Collection else we will get empty array.
      //   res.find((e) =>
      //     e.authGroups.find(
      //       (e) =>
      //         e.roles.includes("ADMIN") &&
      //         e.shopGroups.length === 1 &&
      //         e.memberCodes.length === 1
      //     )
      //   )
    )
    .catch(() => {});
};*/

const getProfileByShopGroupName = (shopGroupName, memberCode) => {
  return request("get-staff-list-by-access", {
    memberCode: memberCode,
    selectedMemberCodes: "",
    shopGroupName: encodeURIComponent(shopGroupName),
    status: "ALL",
  })
    .then((res = []) => res[0])
    .catch(() => {});
};

const getShopGroupDetails = (apiMemberCode, groupName) => {
  return request("get-shop-group", {
    memberCode: apiMemberCode,
    shopGroupName: encodeURIComponent(groupName.trim()),
    partnerGroupName: "-",
    memberCodes: apiMemberCode,
  })
    .then((res = []) => res[0])
    .then((result = {}) => result)
    .catch(() => {});
};

const processAccountDetailsResponse = (response = {}) => {
  response.accountName = toUpper(response?.accountName);
  response.shopMappings = [];

  // we are using optional binding here to retrive mainMembers from response, if we received them then map will iterate and we will process further.
  // if we have not received mainMembers in response then we are returning shopMappings as empty to show proper error.
  if (response?.mainMembers) {
    response?.mainMembers.map((shop) => {
      const { member = {}, branchMembers = [] } = shop;

      if (member?.memberCode) {
        response.shopMappings.push({
          ...member,
          type: branchMembers.length ? "Main" : "Standalone",
        });

        branchMembers?.map((branchMember) => {
          branchMember?.memberCode &&
            response.shopMappings.push({ ...branchMember, type: "Branch" });
        });
      }
    });
  }

  return response;
};

const checkAndMergeAllMemberCodes = (accountDetailsResponse, shopGroupRes) => {
  const { shopMappings = [] } = accountDetailsResponse;
  const { memberCodes: epMemberCodes = [] } = shopGroupRes;

  epMemberCodes.map((memberCode) => {
    // we are looping shopMappings and comparing epMemberCodes with them, if ep member code is not found in shopMappings then we are assuming it's deleted in M-Cloud.
    const memberCodeFound = shopMappings.some(
      (shop) => shop.memberCode === memberCode
    );

    if (!memberCodeFound) {
      accountDetailsResponse.shopMappings.push({
        memberCode,
        status: "Deleted",
        storePhoneNumber: "",
        storeName: "",
        deliveryStatus: "",
        /**
         * We are marking deleted member code type as Standalone / Main in order to displaying it as parent in UI
         * as we are not maintaining relations b/w. member codes in ep database.
         */
        type: "Standalone",
      });
    }
  });
};

// commenting below code as we may need this when we work on PAR-1603 i.e. Florist Admin - ability to be associated to multiple groups
/*const getShopGroups = (userObj, newShopGroupName) => {
  let shopGroups = get(userObj, "authGroups.0.shopGroups", []);

  // If MTAC user modified shop group name and which is not same as old shop group name then only we are appending new shop group name here by removing
  // old one o/w. both old & new shop group names will appened here. If new shop group name is equal to old one then no need of append as it's already there.
  if (
    userObj.initialAccountName &&
    userObj.initialAccountName.toLowerCase() !== newShopGroupName.toLowerCase()
  ) {
    shopGroups = shopGroups.filter(
      (e) => e.toLowerCase() !== userObj.initialAccountName.toLowerCase()
    );
  }

  shopGroups.push(newShopGroupName);

  return uniq(shopGroups);
};

const getMemberCodes = (userObj, newMemberCode) => {
  let memberCodes = get(userObj, "authGroups.0.memberCodes", []);
  memberCodes.push(newMemberCode);
  return uniq(memberCodes);
};

const getUpdatedShopGroups = (userDetailsObj, shopGroupName) => {
  const oldShopGroups = get(userDetailsObj, "authGroups.0.shopGroups", []);

  let updatedShopGroups = oldShopGroups.filter(
    (sgName) => sgName.toLowerCase() !== shopGroupName.toLowerCase()
  );

  return updatedShopGroups;
};

const getUpdatedMemberCodes = (userDetailsObj, memberCode) => {
  const oldMemberCodes = get(userDetailsObj, "authGroups.0.memberCodes", []);

  let updatedMemberCodes = oldMemberCodes.filter(
    (mCode) => mCode !== memberCode
  );

  return updatedMemberCodes;
};*/

export const syncFloristAccount = (
  floristAccountRes,
  profileRes,
  shopGroupRes,
  apiMemberCode,
  setFieldValue
) => {
  const { shopMappings } = floristAccountRes;

  setFieldValue("showSyncLoading", "load");

  // commenting this below code as part of hotfix: MSOL-11759
  // let mainShopDeliveryStatus = "";
  // shopMappings.map((shop) => {
  //   const { type, deliveryStatus } = shop;
  //   if (type === "Main") {
  //     mainShopDeliveryStatus = deliveryStatus;
  //   }
  //   request("set-ds-eligibilty", {
  //     memberCode: shop.memberCode, // Modified this as memberCode is renamed to sourceMemberCode in services.js
  //     payload: {
  //       preferences: [
  //         {
  //           id: "delivery_service",
  //           values: [`${toUpper(mainShopDeliveryStatus) === "ACTIVE"}`],
  //         },
  //       ],
  //     },
  //   });
  // });

  const memberCodes = shopMappings
    .filter((e) => e.status.toLowerCase() === "active")
    .map((e) => e.memberCode);

  const shopGroupPayload = {
    ...shopGroupRes,
    name: shopGroupRes.name,
    memberCodes,
  };

  request("edit-shop-group", shopGroupPayload)
    .then((res) => {
      getShopGroupDetails(apiMemberCode, res.name).then((shopGroupRes) => {
        if (shopGroupRes && shopGroupRes.name) {
          // we are not showing deleted member codes after sync happened
          floristAccountRes.shopMappings = shopMappings.filter(
            (e) => e.status.toLowerCase() !== "deleted"
          );

          // After click on Sync Group, we are updating latest shopGroupRes in formik
          // in order to update Group Members sync status & color codes with latest status.
          setFieldValue("shopGroupRes", shopGroupRes);

          updateLastSyncTime(
            apiMemberCode,
            floristAccountRes,
            setFieldValue,
            true
          );

          ToasterHandler("nice", "This group is now synced");
          setFieldValue("showSyncLoading", "");
        }
      });
    })
    .catch((err) => {
      ToasterHandler(
        "uh oh",
        err.message ||
          " We ran into a problem syncing this group – please try again"
      );
      setFieldValue("showSyncLoading", "");
    });

  // Commenting below code as a back up, this is required if we need to update shop group name and admin details also when we click on Sync Group
  /*const createGroup = isEmpty(shopGroupRes);

  const accountName = profileRes.accountName.trim(); // to trim spaces in account name if user edited it before submitting form / sync group

  const shopGroupPayload = createGroup
    ? {
        name: accountName,
        partnerGroup: "-",
        memberCodes,
      }
    : {
        ...shopGroupRes,
        name: accountName,
        memberCodes,
      };

  const shopGroupPromise = request(
    createGroup ? "create-shop-group" : "edit-shop-group",
    shopGroupPayload
  )
    .then((res) => res)
    .catch((err) => {
      if (err === "SHOP_GROUP_ALREADY_EXISTS") {
        return {};
      } else throw err;
    });

  shopGroupPromise
    .then((shopGroupNewRes) => {
      getShopGroupDetails(apiMemberCode, shopGroupPayload.name).then(
        (shopGroupRes) => {
          if (shopGroupRes.name) {
            // we are not showing deleted member codes after sync happened
            floristAccountRes.shopMappings = shopMappings.filter(
              (e) => e.status.toLowerCase() !== "deleted"
            );

            // After click on Sync Group, we are updating latest shopGroupRes in formik
            // in order to update Group Members sync status & color codes with latest status.
            setFieldValue("shopGroupRes", shopGroupRes);

            const profilePayload = {
              ...profileRes,
              accountName: shopGroupRes.name,
              authGroups: [
                {
                  name: `ADMIN_ROLE`,
                  roles: ["ADMIN"],
                  shopGroups: [shopGroupRes.name],
                  memberCodes: [apiMemberCode],
                },
              ],
            };

            request("edit-staff-profile", profilePayload)
              .then(() => {
                getProfileByEmail(profilePayload.emailId, apiMemberCode).then(
                  (profileNewRes) => {
                    if (profileNewRes.firstName) {
                      setFieldValue("profileRes", {
                        ...profileNewRes,
                        accountName: shopGroupNewRes.name.trim(),
                        initialAccountName: shopGroupNewRes.name.trim(),
                      });
                      updateLastSyncTime(
                        apiMemberCode,
                        floristAccountRes,
                        setFieldValue,
                        true
                      );
                    } else {
                      throw "sync failed";
                    }
                  }
                );
              })
              .catch((err) => {
                throw err;
              });
          }

          ToasterHandler("success", "Account synced successfully.");
          setFieldValue("showSyncLoading", "");
        }
      );
    })
    .catch(() => {
      ToasterHandler("error", "Failed to sync the account.");
      setFieldValue("showSyncLoading", "");
    });*/
};

export const getFloristAccount = (setFieldValue, setFieldError, searchText) => {
  if (searchText.length) {
    setFieldValue("showGroupsLoading", "load");
    setFieldValue("floristAccountRes", {});
    setFieldValue("shopGroupRes", {});
    setFieldValue("profileRes", {});
    setFieldValue("clientIp", "");
    setFieldValue("oldUserDetails", {});

    // API Call to get florist account info from M-Cloud
    request("get-florist-account", { memberCode: searchText })
      .then((res) => processAccountDetailsResponse(res))
      .then((accountDetailsResponse = {}) => {
        if (accountDetailsResponse.accountName) {
          const {
            shopMappings = [],
            accountName,
            firstName,
            lastName,
            emailId,
            accountStatus,
          } = accountDetailsResponse;

          const noOfInactiveMembers = shopMappings.filter(
            (e) => e.status === "INACTIVE"
          );
          const isAllMembersInactive =
            shopMappings.length === noOfInactiveMembers.length;

          const { memberCode: mainMemberCode } =
            first(
              sortBy(
                shopMappings.filter((e) => e.type !== "Branch"),
                ["memberCode"]
              )
            ) || {};

          setFieldValue("apiMemberCode", mainMemberCode);
          setFieldValue("floristAccountRes", accountDetailsResponse);
          setFieldError("MTACMemberSettingsCode", "");

          getShopGroupDetails(mainMemberCode, "").then((shopGroupRes = {}) => {
            if (shopGroupRes.name) {
              const shopGroupName = shopGroupRes.name.trim();

              // As we are not receiving M-Cloud deleted member codes from API, we are handling them at our end
              checkAndMergeAllMemberCodes(accountDetailsResponse, shopGroupRes);

              setFieldValue("shopGroupRes", shopGroupRes);
              setFieldValue("clientIp", shopGroupRes.clientIp || "");

              getProfileByShopGroupName(shopGroupName, mainMemberCode).then(
                (profileRes = {}) => {
                  if (profileRes.firstName) {
                    setFieldValue("profileRes", {
                      ...profileRes,
                      initialEmailId: profileRes.emailId,
                      accountName: shopGroupName,
                      initialAccountName: shopGroupName,
                    });

                    // we are saving old profile details in oldProfileRes key in order to update old email account user by removing current shop group name
                    // when we assign current shop group to another user by changing admin email id while updating shop group details.
                    setFieldValue("oldUserDetails", profileRes);
                  } else {
                    // If shop group exists in Mercury HQ and it don't have associated admin then we are showing blank data for Admin first name, last name
                    // & email id instead of populating M-Cloud account details so that MTAC person knows shop group don't have any associated admin user.
                    setFieldValue("profileRes", {
                      firstName: "",
                      lastName: "",
                      emailId: "",
                      initialEmailId: "",
                      accountName: shopGroupName,
                      initialAccountName: shopGroupName,
                      createMode: true,
                    });
                  }

                  setFieldValue("showGroupsLoading", "done");
                }
              );
            } else {
              if (accountStatus === "ACTIVE" && !isAllMembersInactive) {
                setFieldValue("profileRes", {
                  firstName: firstName.trim(),
                  lastName: lastName.trim(),
                  emailId: emailId.trim(),
                  initialEmailId: "",
                  accountName: accountName.trim(),
                  initialAccountName: accountName.trim(),
                  createMode: true,
                });
                setFieldValue("showGroupsLoading", "done");
              } else {
                setFieldValue("showGroupsLoading", "", false);
                setFieldError(
                  "MTACMemberSettingsCode",
                  ErrorMessages.accountStatusInactive
                );
              }
            }
          });
        } else {
          setFieldValue("showGroupsLoading", "", false);
          setFieldError(
            "MTACMemberSettingsCode",
            ErrorMessages.memberCodeNotExists
          );
        }
      })
      .catch((err) => {
        setFieldValue("showGroupsLoading", "", false);
        setFieldError(
          "MTACMemberSettingsCode",
          ErrorMessages.errorWhileFetchingDetails
        );
      });
  } else {
    setFieldValue("showGroupsLoading", "");
    setFieldError("MTACMemberSettingsCode", "");
  }
};

// Commenting below code as we will push these new changes in next Release i.e. R5
/* export const onFormSubmit = (values, formikBag) => {
  const {
    apiMemberCode,
    profileRes,
    shopGroupRes,
    floristAccountRes,
    oldUserDetails,
  } = values;

  let createAccount = profileRes.createMode;
  const createGroup = isEmpty(shopGroupRes);

  const accountName = profileRes.accountName.trim(); // to trim spaces in account name if user edited it before submitting form / sync group

  const shopMappings = floristAccountRes.shopMappings
    ? floristAccountRes.shopMappings
    : [];

  const shopGroupPayload = createGroup
    ? {
        name: accountName,
        partnerGroup: "-",
        memberCodes: shopMappings
          .filter((e) => e.status.toLowerCase() === "active")
          .map((e) => e.memberCode),
      }
    : {
        ...shopGroupRes,
        name: accountName,
      };

  // While updating shop group, if MATC user not modified email then we are not checking whether that email is alreday associated with any other user or not.
  if (!createGroup && profileRes.initialEmailId === profileRes.emailId) {
    const profilePayload = {
      // In case of edit, we need to maintain all previous shop groups and member codes which user has.
      ...profileRes,
      accountName: accountName,
      authGroups: [
        {
          name: `ADMIN_ROLE`,
          roles: ["ADMIN"],
          shopGroups: getShopGroups(profileRes, accountName),
          memberCodes: getMemberCodes(profileRes, apiMemberCode),
        },
      ],
    };

    createOrUpdateShopGroupAndUser(
      formikBag,
      profileRes,
      createGroup,
      createAccount,
      apiMemberCode,
      shopGroupPayload,
      profilePayload,
      floristAccountRes
    );
  } else {
    // First we are checking any user is already exists with given email id
    searchUsersByEmail(profileRes.emailId).then((searchUserResponse = {}) => {
      if (searchUserResponse.firstName) {
        const existingUserRole = get(
          searchUserResponse,
          "authGroups.0.roles.0",
          ""
        );

        if (existingUserRole === "ADMIN") {
          // If user already existed with ADMIN role then we will append new shop group name to existing Admin user shopGroups which is inside authGroups
          // and will update the user by making PUT API Call.

          createAccount = false;

          const profilePayload = {
            ...searchUserResponse,
            accountName: accountName,
            firstName: profileRes.firstName.trim(),
            lastName: profileRes.lastName.trim(),
            emailId: profileRes.emailId.trim(),
            authGroups: [
              {
                name: `ADMIN_ROLE`,
                roles: ["ADMIN"],
                shopGroups: getShopGroups(searchUserResponse, accountName),
                memberCodes: getMemberCodes(searchUserResponse, apiMemberCode),
              },
            ],
          };

          // while submitting form if MTAC user modified admin email id then we need to remove shop group from old email user account and then we need to
          // update that user profile. After removing shop group if old email user don't have any other shop group then we are deleting that user.
          if (
            oldUserDetails &&
            oldUserDetails.emailId &&
            oldUserDetails.emailId !== profileRes.emailId
          ) {
            const updatedOldUserShopGroups = getUpdatedShopGroups(
              oldUserDetails,
              profileRes.initialAccountName
            );

            if (updatedOldUserShopGroups.length > 0) {
              const updatedOldUserAuthGroup = [
                {
                  name: `ADMIN_ROLE`,
                  roles: ["ADMIN"],
                  shopGroups: updatedOldUserShopGroups,
                  memberCodes: getUpdatedMemberCodes(
                    oldUserDetails,
                    apiMemberCode
                  ),
                },
              ];

              const oldProfilePayload = {
                ...oldUserDetails,
                authGroups: updatedOldUserAuthGroup,
              };

              request("edit-staff-profile", oldProfilePayload)
                .then((res) => {
                  formikBag.setFieldValue("oldUserDetails", {
                    ...oldUserDetails,
                    authGroups: updatedOldUserAuthGroup,
                  });

                  createOrUpdateShopGroupAndUser(
                    formikBag,
                    profileRes,
                    createGroup,
                    createAccount,
                    apiMemberCode,
                    shopGroupPayload,
                    profilePayload,
                    floristAccountRes
                  );
                })
                .catch((err) => {
                  throw err;
                });
            } else {
              request("delete-staff-profile", {
                memberCode: apiMemberCode,
                emailId: oldUserDetails.emailId,
              })
                .then((res) => {
                  formikBag.setFieldValue("oldUserDetails", {});

                  createOrUpdateShopGroupAndUser(
                    formikBag,
                    profileRes,
                    createGroup,
                    createAccount,
                    apiMemberCode,
                    shopGroupPayload,
                    profilePayload,
                    floristAccountRes
                  );
                })
                .catch((err) => {
                  throw err;
                });
            }
          } else {
            createOrUpdateShopGroupAndUser(
              formikBag,
              profileRes,
              createGroup,
              createAccount,
              apiMemberCode,
              shopGroupPayload,
              profilePayload,
              floristAccountRes
            );
          }
        } else {
          // If user already existed with Non ADMIN role then we will show user already exists error.
          formikBag.setSubmitting(false);

          ToasterHandler("error", `User already exists with this email`);
        }
      } else {
        // If no user is already exists with given email id then we will create new user with ADMIN role for this new shop group by making POST API Call.
        createAccount = true;

        const profilePayload = {
          ...profileRes,
          accountName: accountName,
          memberCode: apiMemberCode,
          phone: "",
          partnerGroup: "-",
          employmentStatus: "FULLTIME",
          status: "ACTIVE",
          profileNotes: "",
          authGroups: [
            {
              name: `ADMIN_ROLE`,
              roles: ["ADMIN"],
              shopGroups: [accountName],
              memberCodes: [apiMemberCode],
            },
          ],
        };

        // while submitting form if MTAC user modified admin email id then we need to remove shop group from old email user account and then we need to
        // update that user profile. After removing shop group if old email user don't have any other shop group then we are deleting that user.
        if (
          oldUserDetails &&
          oldUserDetails.emailId &&
          oldUserDetails.emailId !== profileRes.emailId
        ) {
          const updatedOldUserShopGroups = getUpdatedShopGroups(
            oldUserDetails,
            profileRes.initialAccountName
          );

          if (updatedOldUserShopGroups.length > 0) {
            const updatedOldUserAuthGroup = [
              {
                name: `ADMIN_ROLE`,
                roles: ["ADMIN"],
                shopGroups: updatedOldUserShopGroups,
                memberCodes: getUpdatedMemberCodes(
                  oldUserDetails,
                  apiMemberCode
                ),
              },
            ];

            const oldProfilePayload = {
              ...oldUserDetails,
              authGroups: updatedOldUserAuthGroup,
            };

            request("edit-staff-profile", oldProfilePayload)
              .then((res) => {
                formikBag.setFieldValue("oldUserDetails", {
                  ...oldUserDetails,
                  authGroups: updatedOldUserAuthGroup,
                });

                createOrUpdateShopGroupAndUser(
                  formikBag,
                  profileRes,
                  createGroup,
                  createAccount,
                  apiMemberCode,
                  shopGroupPayload,
                  profilePayload,
                  floristAccountRes
                );
              })
              .catch((err) => {
                throw err;
              });
          } else {
            request("delete-staff-profile", {
              memberCode: apiMemberCode,
              emailId: oldUserDetails.emailId,
            })
              .then((res) => {
                formikBag.setFieldValue("oldUserDetails", {});

                createOrUpdateShopGroupAndUser(
                  formikBag,
                  profileRes,
                  createGroup,
                  createAccount,
                  apiMemberCode,
                  shopGroupPayload,
                  profilePayload,
                  floristAccountRes
                );
              })
              .catch((err) => {
                throw err;
              });
          }
        } else {
          createOrUpdateShopGroupAndUser(
            formikBag,
            profileRes,
            createGroup,
            createAccount,
            apiMemberCode,
            shopGroupPayload,
            profilePayload,
            floristAccountRes
          );
        }
      }
    });
  }
};

const createOrUpdateShopGroupAndUser = (
  formikBag,
  profileRes,
  createGroup,
  createAccount,
  apiMemberCode,
  shopGroupPayload,
  profilePayload,
  floristAccountRes
) => {
  request(
    createGroup ? "create-shop-group" : "edit-shop-group",
    shopGroupPayload
  )
    .then((shopGroupNewRes) => {
      formikBag.setFieldValue("shopGroupRes", shopGroupNewRes);

      request(
        createAccount ? "create-staff-profile" : "edit-staff-profile",
        profilePayload
      )
        .then(() => {
          searchUsersByEmail(profilePayload.emailId).then(
            (profileNewRes = {}) => {
              if (profileNewRes.firstName) {
                formikBag.setFieldValue("profileRes", {
                  ...profileNewRes,
                  initialEmailId: profileRes.emailId,
                  accountName: profileRes.accountName.trim(),
                  initialAccountName: profileRes.accountName.trim(),
                });

                formikBag.setFieldValue("oldUserDetails", profileNewRes);
                formikBag.setSubmitting(false);

                ToasterHandler(
                  "success",
                  `Member account ${
                    createAccount ? "created" : "updated"
                  } successfully.`
                );
                if (createGroup) {
                  updateLastSyncTime(
                    apiMemberCode,
                    floristAccountRes,
                    formikBag.setFieldValue,
                    true
                  );
                }
              } else {
                formikBag.setSubmitting(false);
                ToasterHandler(
                  "error",
                  `Unable to fetch admin user details after ${
                    createAccount ? "creating" : "updating"
                  } for this shop group.`
                );
              }
            }
          );
        })
        .catch((err) => {
          formikBag.setSubmitting(false);

          if (err === "USER_ALREADY_EXISTS")
            ToasterHandler("error", `User already exists with this email`);
          else
            ToasterHandler(
              "error",
              `Unable to ${
                createAccount ? "create" : "update"
              }  member, please try later.`
            );
        });
    })
    .catch((err) => {
      formikBag.setFieldValue("profileRes", {
        ...profileRes,
        initialEmailId: "",
        accountName: profileRes.initialAccountName.trim(),
      });

      formikBag.setSubmitting(false);

      err === "SHOP_GROUP_ALREADY_EXISTS"
        ? ToasterHandler("error", "Shop group already exists with same name.")
        : ToasterHandler(
            "error",
            `Unable to ${
              createGroup ? "create" : "update"
            } shop group, please try later.`
          );
    });
};*/

export const onFormSubmit = (values, formikBag) => {
  const {
    apiMemberCode,
    profileRes,
    shopGroupRes,
    floristAccountRes,
    oldUserDetails,
    clientIp,
  } = values;

  const createAccount = profileRes.createMode;
  const createGroup = isEmpty(shopGroupRes);

  // we are taking copy of shopGroupRes here in order to use it later to rollback shop group details when shop group admin user updation fails.
  const shopGroupPreState = shopGroupRes;

  const shopMappings = floristAccountRes.shopMappings
    ? floristAccountRes.shopMappings
    : [];

  const formattedClientIP = clientIp
    .split("\n")
    .join(",")
    .split(",,")
    .join(",");

  const shopGroupPayload = createGroup
    ? {
        name: profileRes.accountName.trim(),
        partnerGroup: "-",
        clientIp: formattedClientIP,
        memberCodes: shopMappings
          .filter((e) => e.status.toLowerCase() === "active")
          .map((e) => e.memberCode),
      }
    : {
        ...shopGroupRes,
        name: profileRes.accountName.trim(),
        clientIp: formattedClientIP,
      };

  request(
    createGroup ? "create-shop-group" : "edit-shop-group",
    shopGroupPayload
  )
    .then((shopGroupNewRes) => {
      formikBag.setFieldValue("shopGroupRes", shopGroupNewRes);

      const profilePayload = createAccount
        ? {
            ...profileRes,
            accountName: profileRes.accountName.trim(), // to trim spaces in account name if user edited it before submitting form / sync group
            memberCode: apiMemberCode,
            phone: "",
            partnerGroup: "-",
            employmentStatus: "FULLTIME",
            status: "ACTIVE",
            profileNotes: "",
            authGroups: [
              {
                name: `ADMIN_ROLE`,
                roles: ["ADMIN"],
                shopGroups: [profileRes.accountName.trim()],
                memberCodes: [apiMemberCode],
              },
            ],
          }
        : {
            ...profileRes,
            accountName: profileRes.accountName.trim(), // to trim spaces in account name if user edited it before submitting form / sync group
            authGroups: [
              {
                name: `ADMIN_ROLE`,
                roles: ["ADMIN"],
                shopGroups: [profileRes.accountName.trim()],
                memberCodes: [apiMemberCode],
              },
            ],
          };

      request(
        createAccount ? "create-staff-profile" : "edit-staff-profile",
        profilePayload
      )
        .then(() => {
          getProfileByEmail(profilePayload.emailId, apiMemberCode).then(
            (profileNewRes = {}) => {
              if (profileNewRes.firstName) {
                formikBag.setFieldValue("profileRes", {
                  ...profileNewRes,
                  accountName: profileRes.accountName.trim(),
                  initialAccountName: profileRes.accountName.trim(),
                });

                formikBag.setFieldValue("oldUserDetails", profileNewRes);

                formikBag.setSubmitting(false);

                ToasterHandler(
                  "nice",
                  `This group has been ${createAccount ? "created" : "updated"}`
                );

                if (createAccount) {
                  updateLastSyncTime(
                    apiMemberCode,
                    floristAccountRes,
                    formikBag.setFieldValue,
                    true
                  );
                }
              } else {
                formikBag.setSubmitting(false);
                ToasterHandler(
                  "error",
                  `Unable to ${
                    createAccount ? "create" : "update"
                  }  member, please try later.`
                );
              }
            }
          );
        })
        .catch((err) => {
          formikBag.setSubmitting(false);

          if (err === "USER_ALREADY_EXISTS") {
            ToasterHandler(
              "oops",
              `We’re seeing double – this user already has an account`
            );

            if (createAccount) {
              /**
               * we are deleting recently created shop group otherwise it will become as orphan shop group. Also we are sending
               * isOrphanShopGroup as true here because no user created for this new shop group by this time.
               * */
              request("delete-shop-group", {
                memberCode: apiMemberCode,
                shopGroupId: encodeURIComponent(shopGroupNewRes.id),
                isOrphanShopGroup: true,
              })
                .then(() => {
                  formikBag.setFieldValue("profileRes", {
                    ...profileRes,
                    initialEmailId: "",
                    createMode: true,
                  });
                  formikBag.setFieldValue("shopGroupRes", {});
                  formikBag.setFieldValue("oldUserDetails", {});
                })
                .catch(() => {});
            } else {
              // we are rollbacking shop group details with it's previous state
              request("edit-shop-group", { ...shopGroupPreState })
                .then((shopGroupNewRes) => {
                  formikBag.setFieldValue("shopGroupRes", shopGroupNewRes);
                })
                .catch(() => {});
            }
          } else {
            ToasterHandler(
              "error",
              `Unable to ${
                createAccount ? "create" : "update"
              }  member, please try later.`
            );

            if (createAccount) {
              /**
               * we are deleting recently created shop group otherwise it will become as orphan shop group. Also we are sending isOrphanShopGroup as false
               * here so that users also will be deleted in DB bcoz after creating shop group & admin user, if some thing happened at back end (like PODs
               * restart etc.) then we will receive status code other than 200 that's why we are rollbacking everything by deleting both shop group & user.
               * */
              request("delete-shop-group", {
                memberCode: apiMemberCode,
                shopGroupId: encodeURIComponent(shopGroupNewRes.id),
                isOrphanShopGroup: false,
              })
                .then(() => {
                  formikBag.setFieldValue("profileRes", {
                    ...profileRes,
                    initialEmailId: "",
                    createMode: true,
                  });
                  formikBag.setFieldValue("shopGroupRes", {});
                  formikBag.setFieldValue("oldUserDetails", {});
                })
                .catch(() => {});
            } else {
              // we are rollbacking both shop group & admin user details with their previous state
              request("edit-shop-group", { ...shopGroupPreState })
                .then((shopGroupNewRes) => {
                  formikBag.setFieldValue("shopGroupRes", shopGroupNewRes);

                  request("edit-staff-profile", {
                    ...oldUserDetails,
                  })
                    .then(() => {})
                    .catch(() => {});
                })
                .catch(() => {});
            }
          }
        });
    })
    .catch((err) => {
      formikBag.setSubmitting(false);

      if (err === "SHOP_GROUP_ALREADY_EXISTS") {
        ToasterHandler(
          "uh oh",
          "It looks like a shop group with that name already exists"
        );
      } else {
        formikBag.setFieldValue("profileRes", {
          ...profileRes,
          accountName: profileRes.initialAccountName.trim(),
        });

        ToasterHandler(
          "error",
          `Unable to ${
            createGroup ? "create" : "update"
          } shop group, please try later.`
        );
      }
    });
};

export const getFloristSearch = (setFieldError, setFloristSearchData, text) => {
  // Clearing the textfield error when ever user types inside the textfield
  setFieldError("MTACMemberSettingsCode", "");

  if (text.trim().length >= 3) {
    let payload = {};

    // checking wether the given string is memberCode or shopName
    if (text.match(/^\d/)) {
      payload = {
        shopCode: text.trim(),
      };
    } else {
      payload = {
        businessName: text.trim(),
      };
    }

    const requestObj = { memberCode: "FTD", ...payload };

    request("get-florist-search", requestObj)
      .then((res) => {
        setFloristSearchData(res);
      })
      .catch(() => {});
  }
};

export const deleteShopgroup = (
  apiMemberCode,
  profileRes,
  shopGroupRes,
  setFieldValue,
  hideModal
) => {
  request("delete-shop-group", {
    memberCode: apiMemberCode,
    shopGroupId: encodeURIComponent(shopGroupRes.id),
    isOrphanShopGroup: false,
  })
    .then(() => {
      hideModal();
      setFieldValue("profileRes", {
        ...profileRes,
        initialEmailId: "",
        createMode: true,
      });
      setFieldValue("shopGroupRes", {});
      setFieldValue("oldUserDetails", {});
      ToasterHandler("nice", " You deleted that shop group");

      // After deleting shop group, we are making Sync api call by sending MHQFlorist = false so that florist console type will be marked as MCloud in DB.
      updateLastSyncTime(apiMemberCode, null, setFieldValue, false);
    })
    .catch(() => {
      ToasterHandler(
        "uh oh",
        "We were unable to delete that shop group – please try again"
      );
    });
};

export const resetPassword = (email, Localise, messages) => {
  request("initiate-reset-password", {
    email,
    type: "reset",
  })
    .then(() => {
      ToasterHandler(
        "nice",
        Localise(messages, "Password reset email has been sent")
      );
    })
    .catch((err) => {
      if (err === "INVALID_ACCOUNT")
        ToasterHandler(
          "uh oh",
          Localise(
            messages,
            "We couldn’t find that email – please try again or confirm the email"
          )
        );
      else ToasterHandler("error", "Something went wrong, please try again.");
    });
};

export const updateLastSyncTime = (
  memberCode,
  floristAccountRes,
  setFieldValue,
  isMHQFlorist
) => {
  request("update-last-sync-time", {
    memberCode,
    isMHQFlorist,
  })
    .then(() => {
      if (floristAccountRes !== null) {
        setFieldValue("floristAccountRes", {
          ...floristAccountRes,
          lastSync: moment.utc().toDate(),
        });
      }
    })
    .catch(() => {});
};

export const exportShops = (memberCode, setFieldValue) => {
  if (memberCode === "all") setFieldValue("showAllShopsExportLoading", true);
  else setFieldValue("showSingleShopExportLoading", true);

  request("export-shops", {
    listAll: memberCode === "all",
    memberCode: memberCode === "all" ? "FTD" : memberCode,
    memberCodes: memberCode === "all" ? "" : memberCode,
  })
    .then((res) => {
      // Creating blob link to download
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${memberCode}_shops.csv`);

      // Force download
      link.click();

      if (memberCode === "all")
        setFieldValue("showAllShopsExportLoading", false);
      else setFieldValue("showSingleShopExportLoading", false);
    })
    .catch((err) => {
      if (memberCode === "all")
        setFieldValue("showAllShopsExportLoading", false);
      else setFieldValue("showSingleShopExportLoading", false);

      console.log("Error occured while exporting shops " + JSON.stringify(err));
    });
};
