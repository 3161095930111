import { combineReducers } from "redux";
import { SLICE_NAME as sliceName } from "./constants";
import common from "./common/slice";
import catalog from "./catalog/slice";
import dashboard from "./dashboard/slice";
import product from "./product/slice";
import collection from "./collection/slice";
import listCollections from "./list-collections/slice";

export default {
  [sliceName]: combineReducers({
    ...common,
    ...catalog,
    ...dashboard,
    ...product,
    ...collection,
    ...listCollections,
  }),
};
