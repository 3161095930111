export const getDisplayEligible = () => {
  return {
    draft: ["draft"],
    planned: ["draft", "planned"],
    inprogress: ["draft", "planned", "inprogress"],
    completed: ["draft", "planned", "inprogress", "completed"],
    incomplete: ["draft", "planned", "inprogress", "incomplete"],
  };
};

export const getConfig = (status, isSmallScreen) => {
  return {
    draft: {
      displayStatus: "Draft",
      iconExists: true,
      iconName: "accepted",
      styleConfig: {
        labelSize: isSmallScreen ? 12 : 13,
        labelColor: "#114258",
        stripColor: "#aadec6",
        blockBgColor: "#fff",
        iconWidth: isSmallScreen ? 21 : 25,
        iconHeight: isSmallScreen ? 21 : 25,
      },
    },
    planned: {
      displayStatus: "Ready To Go",
      iconExists: true,
      iconName: "design",
      styleConfig: {
        labelSize: isSmallScreen ? 12 : 13,
        labelColor: "#114258",
        stripColor: "#31aa92",
        blockBgColor: "#fff",
        iconWidth: isSmallScreen ? 21 : 26,
        iconHeight: isSmallScreen ? 21 : 26,
      },
    },
    inprogress: {
      displayStatus: "Out for Delivery",
      iconExists: true,
      iconName: "delivery",
      styleConfig: {
        labelSize: isSmallScreen ? 12 : 13,
        labelColor: "#114258",
        stripColor: "#11838e",
        blockBgColor: "#fff",
        iconWidth: isSmallScreen ? 23 : 27,
        iconHeight: isSmallScreen ? 14 : 18,
      },
    },
    ...(status.toUpperCase() !== "INCOMPLETE" && {
      completed: {
        displayStatus: "Completed",
        iconExists: true,
        iconName: "completed",
        styleConfig: {
          labelSize: isSmallScreen ? 12 : 13,
          labelColor: "#114258",
          stripColor: "#114258",
          blockBgColor: "#fff",
          iconWidth: isSmallScreen ? 20 : 24,
          iconHeight: isSmallScreen ? 16 : 20,
        },
      },
    }),
    ...(status.toUpperCase() === "INCOMPLETE" && {
      incomplete: {
        displayStatus: "Incomplete",
        iconExists: true,
        iconName: "incompleted",
        styleConfig: {
          labelSize: isSmallScreen ? 12 : 13,
          labelColor: "#6c0057",
          stripColor: "#6c0057",
          blockBgColor: "#fff",
          iconWidth: isSmallScreen ? 19 : 23,
          iconHeight: isSmallScreen ? 19 : 23,
        },
      },
    }),
  };
};
