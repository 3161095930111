import React, { memo } from "react";
import { View } from "react-native";
import { Text, Icon } from "react-native-elements";
import tw from "tailwind-rn";
import moment from "moment";
import { colors } from "styles/theme";
import { getDaySuffix } from "./helper";

// Helper function to format recurrence text based on frequency and date
const getRecurrenceText = (frequency, firstDeliveryDate, ends) => {
  const dayName = moment(firstDeliveryDate).format("dddd"); // Extract the day name
  const dayOfMonth = moment(firstDeliveryDate).date(); // Extract the day
  const month = moment(firstDeliveryDate).format("MMMM"); // Extract the month name

  // Handle yearly frequency with shorter end periods (3 months, 6 months, or 1 year)
  if (
    frequency === "ONE_YEAR" &&
    ["THREE_MONTH", "SIX_MONTH", "ONE_YEAR"].includes(ends)
  ) {
    return `Delivers ${dayOfMonth}${getDaySuffix(
      dayOfMonth
    )} of ${month} this year`;
  }

  const recurrenceMap = {
    ONE_WEEK: `Delivers ${dayName} every 1 week`,
    TWO_WEEK: `Delivers ${dayName} every 2 weeks`,
    THREE_WEEK: `Delivers ${dayName} every 3 weeks`,
    ONE_MONTH: `Delivers ${dayOfMonth}${getDaySuffix(
      dayOfMonth
    )} of every month`,
    ONE_YEAR: `Delivers ${dayOfMonth}${getDaySuffix(
      dayOfMonth
    )} of ${month} every year`,
  };

  return recurrenceMap[frequency] || null;
};

// Helper function to handle the end period text
const appendEndPeriod = (recurrenceText, ends, endDate, frequency) => {
  // Define conflicting cases based on frequency and end periods
  const invalidEndPeriod = {
    ONE_YEAR: ["THREE_MONTH", "SIX_MONTH", "ONE_YEAR"], // Invalid end periods for yearly frequency
  };

  // Check if the end period conflicts with the recurrence frequency
  if (invalidEndPeriod[frequency]?.includes(ends)) {
    return recurrenceText; // Return recurrence text without appending the end period
  }

  const endPeriods = {
    THREE_MONTH: " for the next 3 months",
    SIX_MONTH: " for the next 6 months",
    ONE_YEAR: " for the next 1 year",
    TWO_YEAR: " for the next 2 years",
    NEVER: " until canceled",
  };

  if (endPeriods[ends]) {
    return `${recurrenceText}${endPeriods[ends]}`;
  } else if ((!ends || ends === "CUSTOM_DATE") && endDate) {
    return `${recurrenceText} until ${moment(endDate).format("MM/DD/YYYY")}`;
  }
  return recurrenceText;
};

const RecurrenceText = ({
  firstDeliveryDate,
  ends,
  frequency,
  endDate,
  containerStyle = {},
  variant = "",
}) => {
  if (!firstDeliveryDate || !frequency) return null;

  // If ends is an empty string, treat it as "NEVER"
  const normalizedEnds = ends === "" ? "NEVER" : ends;

  let recurrenceText = getRecurrenceText(
    frequency,
    firstDeliveryDate,
    normalizedEnds
  );
  if (!recurrenceText) return null;

  recurrenceText = appendEndPeriod(
    recurrenceText,
    variant === "listing" ? ends : normalizedEnds,
    endDate,
    frequency
  );

  return (
    <View
      style={{ ...tw("flex flex-row"), width: "100%", ...containerStyle }}
      testID="recurrence_text_container"
    >
      <Icon
        name="info"
        type="simple-line-icon"
        color={colors.primary}
        size={12}
      />
      <Text style={{ paddingLeft: 5 }} testID="recurrence_text">
        {recurrenceText}
      </Text>
    </View>
  );
};

export default memo(RecurrenceText);
