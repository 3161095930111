import React, { useEffect, useState } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useIsFocused } from "@react-navigation/native";

import { ToggleButton, ConfirmModal } from "components/wrappers";
import { ToasterHandler, Spinner } from "components/elements";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import {
  setInit,
  setReset,
  setAction,
  setViewMore,
  setProductSelection,
  setQuickAction,
  fetchCatalog,
} from "library/sagas/views/home/drawer/product-catalog/catalog/slice";
import {
  fetchCollection,
  setProductsControl,
} from "library/sagas/views/home/drawer/product-catalog/collection/slice";
import {
  selectCatalogLimit,
  selectSearchText,
  selectPageData,
  selectProductsSelected,
  selectSortValue,
  selectBulkActionType,
  selectAPIResponse,
  selectAPIError,
  selectPageStatus,
} from "library/sagas/views/home/drawer/product-catalog/catalog/selector";
import { selectAPIError as selectAddonCollectionError } from "library/sagas/views/home/drawer/product-catalog/collection/selector";
import { navigateScreen } from "library/sagas/views/home/drawer/product-catalog/common/slice";
import {
  selectScreenParam,
  selectSharedCatalogTitles,
  selectShopCode,
  selectSharedCatalogs,
  selectAllowSyndicate,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";

import { PageHeader, BulkActionHeader } from "../header";
import { SortPicker, ScreenComponent } from "../helper";
import { SecondaryButton } from "components/wrappers";
import { Settings, PriceMinimumProducts } from "../common/settings";
import Products from "./products";
import { SortOrderOptions } from "../collection/ui-config";
import { BulkSortControl } from "../collection/header";

import { fonts } from "styles/theme";
import tw from "tailwind-rn";

const Catalog = ({ route: { name, params = {} }, rootNavigation }) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const shopCode = useSelector(selectShopCode);
  const screenTitles = useSelector(selectSharedCatalogTitles);
  const sharedCatalogs = useSelector(selectSharedCatalogs);
  const allowSyndicate = useSelector(selectAllowSyndicate);

  const status = useSelector(selectPageStatus);
  const error =
    useSelector(selectAPIError) || useSelector(selectAddonCollectionError);
  const [showPopup, setShowPopup] = React.useState(false);
  const { Localise, messages } = React.useContext(I18NContext);
  const { isDesktop, isTablet, isMobile } = React.useContext(DeviceContext);

  const { handle = "" } = params;
  const baseLimit = isMobile ? 8 : 24;

  useEffect(() => {
    if (!isFocused || !sharedCatalogs.length) return;
    if (handle) dispatch(fetchCatalog(handle));
    if (handle === "addons") {
      dispatch(fetchCollection(handle));
      dispatch(
        setProductsControl({
          section: "current",
          type: "sortOptions",
          value: SortOrderOptions(Localise, messages),
        })
      );
    }
    dispatch(setInit({ handle, baseLimit }));
    dispatch(navigateScreen({ name, params, reduxOnly: true }));

    return () => {
      dispatch(setReset());
    };
  }, [handle, shopCode, isFocused, sharedCatalogs]);

  const CatalogMainScreen = () => {
    const dispatch = useDispatch();
    const catalog = useSelector(selectAPIResponse);
    const data = useSelector(selectPageData);
    const [loading, setLoading] = useState(false);
    const currentLimit = useSelector(selectCatalogLimit);
    const showViewMore = currentLimit < data.length;
    //TODO:- Will enable once catalog team is ready
    const canUpdate = catalog.groupScope !== "reserved" && false;

    const onStatusChange = () =>
      dispatch(
        setQuickAction({
          params: {
            id: catalog.groupId,
            patch: {
              todo: "status",
              data: {
                status: catalog.isGroupActive ? "inactive" : "active",
              },
            },
          },
          resolve: () =>
            ToasterHandler(
              "looks great",
              `${Localise(messages, "Catalog")} "${catalog.name}" ${Localise(
                messages,
                catalog.isGroupActive
                  ? "has been de-activated"
                  : "has been activated"
              )}`
            ),
          reject: () =>
            ToasterHandler(
              "error",
              "We were unable to process your request, please try again."
            ),
        })
      );

    return (
      <View style={{ maxWidth: isDesktop ? 996 : isTablet ? 676 : 332 }}>
        <PageHeader
          selectSearchText={selectSearchText}
          setSearchText={(payload) => setAction({ ...payload })}
        />
        {error ? (
          <Text style={{ ...fonts.error, textAlign: "left" }}>
            {Localise(
              messages,
              error.includes("no catalog")
                ? error
                : "Unable to pull catalog, try again"
            )}
          </Text>
        ) : status === "progress" ? (
          <View style={{ minHeight: 150 }}>
            <Spinner size="large" />
          </View>
        ) : (
          <>
            <View style={[tw("flex flex-col my-3"), { zIndex: 30 }]}>
              <View
                style={tw(
                  `flex flex-row  ${
                    isMobile ? "flex-wrap" : ""
                  } justify-between items-center my-2`
                )}
              >
                <Text style={fonts.heading3}>
                  {Localise(messages, screenTitles[handle])} ({data.length})
                </Text>

                {loading && (
                  <View
                    style={[
                      tw("flex flex-row items-center justify-between"),
                      { width: 75 },
                    ]}
                  >
                    <ActivityIndicator size={"small"} />
                    <Text style={fonts.heading5}>{"Saving..."}</Text>
                  </View>
                )}
                <View style={tw(`flex flex-row ${isMobile ? "mt-2" : ""}`)}>
                  {canUpdate && (
                    <ToggleButton
                      value={catalog.isGroupActive}
                      onValueChange={
                        allowSyndicate
                          ? () => setShowPopup(!showPopup)
                          : onStatusChange
                      }
                      timeOut={2000}
                    />
                  )}
                </View>
              </View>
              <View
                style={[
                  tw(
                    `flex ${
                      isMobile
                        ? "flex-col mb-2"
                        : "flex-row flex-wrap justify-between items-center mb-2"
                    }`
                  ),
                ]}
              >
                <View
                  style={[
                    tw(
                      `flex ${
                        isMobile
                          ? "flex-col items-start justify-center"
                          : "flex-row flex-wrap items-center justify-between"
                      }`
                    ),
                    { minHeight: 55 },
                  ]}
                >
                  <BulkActionHeader
                    setBulkAction={(payload) => setAction({ ...payload })}
                    selectBulkActionType={selectBulkActionType}
                    setProductSelection={(payload) =>
                      setProductSelection({
                        productId: payload,
                      })
                    }
                    selectProductsSelected={selectProductsSelected}
                    screen={"catalog"}
                    handle={handle}
                  />
                </View>
                {handle === "addons" ? (
                  <BulkSortControl
                    positionMaxLimit={data.length}
                    setSpinner={setLoading}
                    handle={handle}
                  />
                ) : (
                  <SortPicker
                    selectSort={selectSortValue}
                    setSort={(payload) => setAction({ ...payload })}
                    style={{ width: 160 }}
                  />
                )}
              </View>
            </View>
            <Products
              handle={handle}
              loading={loading}
              setLoading={setLoading}
            />
            {showViewMore && (
              <View
                style={tw(
                  `flex flex-row-reverse w-full justify-between items-center py-4`
                )}
              >
                <SecondaryButton
                  title={"View More"}
                  action={() => dispatch(setViewMore({ currentLimit }))}
                />
              </View>
            )}
          </>
        )}
        <ConfirmModal
          modalVisible={!!showPopup}
          handlePrimary={() => {
            onStatusChange();
            setShowPopup(!showPopup);
          }}
          handleSecondary={() => {
            setShowPopup(!showPopup);
          }}
          data={{
            modal: {
              primary: "Update",
              content: Localise(
                messages,
                "This change will apply to All shops and will override any local shop changes."
              ),
            },
          }}
        />
      </View>
    );
  };

  const CatalogSideCar = () => {
    const sideCar = useSelector(selectScreenParam("sideCar"));

    return sideCar === "catalogSettings" ? (
      <Settings />
    ) : sideCar === "priceMinimumProducts" ? (
      <PriceMinimumProducts />
    ) : null;
  };

  return (
    <ScreenComponent rootNavigation={rootNavigation}>
      <CatalogMainScreen />
      <CatalogSideCar />
    </ScreenComponent>
  );
};

export default Catalog;
