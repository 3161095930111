import React from "react";
import { View } from "react-native";
import { FormField, FormFieldPicker } from "components/elements/forms";
import states from "static/data/states.json";
import countryList from "static/data/countries.json";
import get from "lodash/get";
import I18NContext from "library/contexts/i18N";

const BillingInfo = (props) => {
  const {
    isSmallScreen,
    path,
    values,
    isSavedCard,
    paymentProcessed = false,
    labelStyle = {},
  } = props;
  const { messages, Localise } = React.useContext(I18NContext);

  const data = get(values, path);
  return (
    <View
      style={{
        width: "100%",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          marginTop: 10,
        }}
      >
        <FormField
          fsClass="fs-exclude"
          name="addressLine1"
          label={Localise(messages, "Billing Address")}
          labelStyle={labelStyle}
          placeholder={Localise(messages, "Billing Address")}
          inputStyle={{ width: "100%" }}
          containerStyle={{
            width: isSmallScreen ? "100%" : "80%",
          }}
          path={path}
          editable={!isSavedCard && !paymentProcessed}
          grayedOutOnDisable={true}
        />
        <FormField
          autoCapitalize="none"
          autoCorrect={false}
          autoComplete="new-password"
          name="suite"
          fsClass="fs-exclude"
          placeholder={"Apt / Suite"}
          label={"Apt / Suite"}
          labelStyle={labelStyle}
          inputStyle={{ width: "100%" }}
          containerStyle={{
            width: isSmallScreen ? "50%" : "20%",
          }}
          path={path}
          editable={!isSavedCard && !paymentProcessed}
          grayedOutOnDisable={true}
        />
        <FormField
          name="city"
          label={"City"}
          labelStyle={labelStyle}
          placeholder={"City"}
          fsClass="fs-exclude"
          containerStyle={{
            width: isSmallScreen ? "50%" : "30%",
          }}
          path={path}
          testID="city"
          accessibilityLabel="city"
          editable={!isSavedCard && !paymentProcessed}
          grayedOutOnDisable={true}
        />
        <FormFieldPicker
          containerStyle={{
            width: isSmallScreen ? "100%" : "30%",
          }}
          fsClass="fs-exclude"
          placeholder={{ label: "State" }}
          data={states[data?.country] || []}
          name="state"
          label={"State"}
          labelStyle={labelStyle}
          path={path}
          disabled={isSavedCard || paymentProcessed}
          disabledFieldTouch={true}
        />
        <FormFieldPicker
          containerStyle={{
            width: isSmallScreen ? "100%" : "40%",
          }}
          placeholder={{}}
          data={countryList}
          fsClass="fs-exclude"
          name="country"
          label={"Country"}
          labelStyle={labelStyle}
          path={path}
          disabled={isSavedCard || paymentProcessed}
          disabledFieldTouch={true}
        />
      </View>
    </View>
  );
};

export default BillingInfo;
