import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { DeviceContext } from "library/contexts/appSettings";
import ResetPassword from "./reset-password";
import SetPassword from "./set-password";
import ForgotPassword from "./forgot-password";
import UnlockPassword from "./unlock-password";
import { request } from "library/utils/request";
import useStateIfMounted from "library/utils/useStateIfMounted";

const Stack = createStackNavigator();

const User = ({ nonce, expiry }) => {
  const { isDesktop } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const [isNonceValid, setIsNonceValid] = useStateIfMounted("loading");

  const onSuccess = () => {
    if (window) window.location.href = "/login";
  };

  const onCancel = () => {
    if (window) window.location.href = "/login";
  };

  React.useEffect(() => {
    request("validate-nonce", { nonce })
      .then((res) => {
        setIsNonceValid(res?.status);
      })
      .catch((err) => {
        setIsNonceValid("error");
        console.log("Nonce validation error :>> ", err);
      });
  }, []);

  return (
    <Stack.Navigator headerMode="none">
      <Stack.Screen
        name="reset-password"
        options={{ title: "Mercury HQ - Reset Password" }}
      >
        {(props) => (
          <ResetPassword
            {...props}
            nonce={nonce}
            expiry={expiry}
            isNonceValid={isNonceValid}
            isSmallScreen={isSmallScreen}
            onSuccess={onSuccess}
            onCancel={onCancel}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name="forgot-password"
        options={{ title: "Mercury HQ - Forgot Password" }}
      >
        {(props) => (
          <ForgotPassword
            {...props}
            nonce={nonce}
            expiry={expiry}
            isNonceValid={isNonceValid}
            isSmallScreen={isSmallScreen}
            onSuccess={onSuccess}
            onCancel={onCancel}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name="unlock-password"
        options={{ title: "Mercury HQ - Unlock Password" }}
      >
        {(props) => (
          <UnlockPassword
            {...props}
            nonce={nonce}
            expiry={expiry}
            isNonceValid={isNonceValid}
            isSmallScreen={isSmallScreen}
            onSuccess={onSuccess}
            onCancel={onCancel}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name="set-password"
        options={{ title: "Mercury HQ - Set Password" }}
      >
        {(props) => (
          <SetPassword
            {...props}
            nonce={nonce}
            expiry={expiry}
            isNonceValid={isNonceValid}
            isSmallScreen={isSmallScreen}
            onSuccess={onSuccess}
            onCancel={onCancel}
          />
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
};

export default User;
